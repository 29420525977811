import exerciseCardWhiteStyles from "./exercise-card-white.module.less";
import exerciseCardDarkStyles from "./exercise-card-dark.module.less";
import {
    Image,
    message,
    Button,
    Card,
    Col,
    ConfigProvider,
    Layout,
    Pagination,
    Row,
    Tabs,
    Table,
    Space,
    Tooltip,
    Popconfirm
} from 'antd';
import {ArrowRightOutlined } from '@ant-design/icons'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { PageHeader } from "../common/page-header/header";
import React, { useEffect, useRef, useState } from "react";
import PageFooter from "../common/page-footer/footer";
import Search from 'antd/lib/input/Search';
import zhCN from 'antd/lib/locale/zh_CN'
import { Tag } from 'antd';
import Cookies from 'js-cookie';
import { Checkbox } from 'antd';
import { Radio } from 'antd';
import * as constants from '../../constants/constants';
import { UpOutlined, DownOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';

import {
    DragDropContext,
    Droppable,
    Draggable
  } from 'react-beautiful-dnd';

const CheckboxGroup = Checkbox.Group;

import remarkMath from 'remark-math'
import gfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import rehypeKatex from 'rehype-katex'
import 'katex/dist/katex.min.css' // `rehype-katex` does not import the CSS for you


import Meta from 'antd/lib/card/Meta';
import { sendHttpPostRequest } from "../../api/http-request";
import MenuOutlined from "@ant-design/icons/lib/icons/MenuOutlined";
import {checkIsTeacher} from "../../component/modal/teacher-authenticate/teacher-authenticate";
const { CheckableTag } = Tag;

const { Content } = Layout
const {TabPane} = Tabs

interface Exercise {
    id: number,
    name: string,
    author: string,
    difficulty: number, //难度
    channel: number, //
    type: number, //题型
    status: number, //状态
    create_time: string,//创建时间
    modify_time: string,//修改时间
    source_page: number, // 来源页面
    source_key: number, // 来源页面key
}


/* 排序题 */

export interface Props {exercise:any,
    renderType:string, //渲染的主题类型，"dark","white"
    teacherOnly:boolean,  // 仅老师
    editorMode:boolean,//是否可编辑模式，
    showAnser:boolean,//是否默认显示答案
  }

export const ExerciseCardSortSelect: React.FC<Props> = (props) => {

    const {exercise,renderType,teacherOnly,editorMode,showAnser} = props
    
    const options = (typeof exercise.options === 'string') ? JSON.parse(exercise.options) : exercise.options
    const user_options = exercise?.submission_info?.submission_answer;//用户的排序
    exercise.options_answer = (typeof exercise.options_answer === 'string') ? JSON.parse(exercise.options_answer) : exercise.options_answer

    //console.log(user_options);
    //console.log(user_options.map(optionId=>({id:String(optionId), options: options.filter((option)=>(option.id == optionId))[0].options})));
    const [optionsList,setOptionsList] = useState(user_options?.length > 0 ? user_options.map(optionId=>({id:String(optionId), options: options.filter((option)=>(option.id == optionId))[0].options})): options)//显示的选项
    // const [answerList, setAnswerList] = useState([] as any[]);  // 选择的答案
    const [answerList, setAnswerList] = useState(exercise?.submission_info?.submission_answer.length == 0 ? exercise?.options : exercise?.submission_info?.submission_answer);  // 选择的答案
    const [running, setRunning] = useState(false);  // 运行中状态

    const [answervisible,setAnswerVisible] = useState(showAnser)
    const [answerParsevisible,setAnswerParseVisible] = useState(false) //答案解析是否可见
    const exerciseCardStyles = renderType == "white" ? exerciseCardWhiteStyles: exerciseCardDarkStyles
    
    const [currentStatus, setCurrentStatus] = useState(null as any)

    
    useEffect(()=>{
        
    },[])
   
    // 习题运行回调
    const runCodeCallback = (response: any) => {
        message.destroy();
        if (response.code == -100) {
            // 未登录
            document.getElementById("show-login-modal")!.click();
            // window.location.href = `/oam_login?next=oam_preview_exercise?exercise_id=${exercise.id}`;
        } else if (response.code == 0) {
            // 成功
            // TODO: 答题卡片渲染执行结果，不在这里弹提示
            if (response.data.status == true) {
                //message.success(`回答正确`);
                setCurrentStatus(true);
            } else {
                //message.error(`回答错误`);
                setCurrentStatus(false);
            }
        } else if (response.code == -501) {
            // 权限不足
            document.getElementById("show-login-modal")!.click();
            // window.location.href = `/oam_login?next=oam_preview_exercise?exercise_id=${exercise.id}`;
        } else {
            message.error(response.message);
        }
        setRunning(false);
    }

    //点击了提交按钮
    const exerciseSubmitBtnClick = ()=>{
        // if (teacherOnly && !checkIsTeacher()) {
        //     document.getElementById("show-teacher-authenticate")!.click();
        // } else {
        let _params = {
            sessionid: Cookies.get("sessionid"),
            exercise_id: exercise.id,
            answer_list: JSON.stringify(answerList),
            source_page: exercise.source_page,
            source_key: exercise.source_key,
            course_id: exercise.course_id,
            chapter_id: exercise.chapter_id,
            knowcell_id: exercise.knowcell_id,
        }
        if ([3, 4].includes(exercise.source_page)) {
            // 作业、考试
            if (answerList != exercise.options) {
                setRunning(true);

                if (exercise.exam_id) {
                    _params["exam_id"] = exercise.exam_id;   // 考试传考试id
                } else if (exercise.homework_id) {
                    _params["homework_id"] = exercise.homework_id;   // 作业传作业id
                }

                sendHttpPostRequest(
                    'exercise/api/exercise_run/',
                    _params,
                    runCodeCallback
                )
            } else {
                // console.log("排序题没有拖拽")
            }
        } else {
            setRunning(true);

            sendHttpPostRequest(
                'exercise/api/exercise_run/',
                _params,
                runCodeCallback
            )
        }
        // }

    }

    //点击了答案解析按钮
    const exerciseAnswerParseBtnClick = ()=>{
        if (exercise.options_answer.length == 0) {
            // 查询答案
            sendHttpPostRequest(
                'exercise/api/query_exercise_answer/',
                {
                    sessionid: Cookies.get("sessionid"),
                    sid: Cookies.get("sessionid"),
                    exercise_id: exercise.id
                },
                queryExerciseParseCallback
            )
        } else {
            setAnswerParseVisible(!answerParsevisible);
        }
    }

    // 看答案回调
    const queryExerciseAnswerCallback = (response: any) => {
        message.destroy();
        if (response.code == -100) {
            // 未登录
            document.getElementById("show-login-modal")!.click();
        } else if (response.code == 0) {
            // 成功
            if (response.exercise_answer) {
                exercise.options_answer = response.exercise_answer.options_answer;
                exercise.answer_parse = response.exercise_answer.answer_parse;
                setAnswerVisible(true);
            }
        } else if (response.code == -501) {
            // 权限不足
            // document.getElementById("show-login-modal")!.click();
        } else {
            message.error(response.message);
        }
    }

    // 查看解析回调
    const queryExerciseParseCallback = (response: any) => {
        message.destroy();
        if (response.code == -100) {
            // 未登录
            document.getElementById("show-login-modal")!.click();
        } else if (response.code == 0) {
            // 成功
            if (response.exercise_answer) {
                exercise.options_answer = response.exercise_answer.options_answer;
                exercise.answer_parse = response.exercise_answer.answer_parse;
                setAnswerParseVisible(true);
            }
        } else if (response.code == -501) {
            // 权限不足
            // document.getElementById("show-login-modal")!.click();
        } else {
            message.error(response.message);
        }
    }

    //点击了参考答案按钮
    const exerciseAnswerBtnClick = ()=>{
        if (exercise.options_answer.length == 0) {
            // 查询答案
            sendHttpPostRequest(
                'exercise/api/query_exercise_answer/',
                {
                    sessionid: Cookies.get("sessionid"),
                    sid: Cookies.get("sessionid"),
                    exercise_id: exercise.id
                },
                queryExerciseAnswerCallback
            )
        } else {
            setAnswerVisible(!answervisible);
        }
    }

    // 渲染 查看答案按钮
    const renderAnswerButton = () => {
        if (0 == exercise.options_answer.length) {
            if (![3, 4].includes(exercise.source_page)) {
                return (
                    <Popconfirm placement="bottom" title={'您确定查看答案吗？'} onCancel={(e: any) => {
                        e.stopPropagation();
                    }} onConfirm={(e: any) => {
                        e.stopPropagation();
                        exerciseAnswerBtnClick()
                    }} okText="确认" cancelText="取消">
                        <Button type='link' style={{width: 80, marginRight: 10, borderRadius: 20}}>
                            <span>参考答案</span><DownOutlined/> </Button>
                    </Popconfirm>
                )
            }
        } else {
            if (![3, 4].includes(exercise.source_page)) {
                return (
                    <Button onClick={exerciseAnswerBtnClick} type='link'
                            style={{width: 80, marginRight: 10, borderRadius: 20}}>{!answervisible ? <span>参考答案</span> :
                        <span>折叠收起</span>}{!answervisible ? <DownOutlined/> : <UpOutlined/>}</Button>
                )
            }
        }
    }

    // 渲染 解析
    const renderParseButton = () => {
        if (0 == exercise.answer_parse.length) {
            if (![3, 4].includes(exercise.source_page)) {
                return (
                    <Popconfirm placement="bottom" title={'您确定查看解析吗？'} onCancel={(e: any) => {
                        e.stopPropagation();
                    }} onConfirm={(e: any) => {
                        e.stopPropagation();
                        exerciseAnswerParseBtnClick()
                    }} okText="确认" cancelText="取消">
                        <Button type='link' style={{width: 80, marginRight: 10, borderRadius: 20}}>
                            <span>答案解析</span><DownOutlined/> </Button>
                    </Popconfirm>
                )
            }
        } else {
            if (![3, 4].includes(exercise.source_page)) {
                return (
                    <Button onClick={exerciseAnswerParseBtnClick} type='link' style={{
                        width: 80,
                        marginRight: 10,
                        borderRadius: 20
                    }}><span>答案解析</span>{!answerParsevisible ? <DownOutlined/> : <UpOutlined/>}</Button>
                )
            }
        }
    }

    //重排序
    const reorder = (list:Array<any>, startIndex:number, endIndex:number) => {
        // result 代表选项
        // _answerList 用户提交的答案只需要一个选项id的列表
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)

        // 组织用户提交的答案id列表 而不是选项排序列表
        const _answerList = [] as any[];
        for (let _index in result) {
            let _id = parseInt(result[_index].id);
            _answerList.push(_id);
        }
        setCurrentStatus(null as any) //让测试按钮回复默认
        setAnswerList(_answerList);

        return result
    }


    const getItemStyleDark = (isDragging:boolean, draggableStyle:any) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        margin: `0 0 ${12}px 0`,

        // change background colour if dragging
        background: "#555555",
        padding:3,
        border:'dashed',
        borderRadius:5,
        borderWidth:'1px',

        borderColor:isDragging ? "#07C160" : "#555555",
        // styles we need to apply on draggables
        ...draggableStyle
    });

    const getListStyleDark = (isDraggingOver:boolean) => ({
        background: "#343434",
        padding: `16px 16px 4px 16px`,
        //border: "dashed" ,
        borderRadius:5,
        //borderWidth:1,
        marginBottom:10,
        marginLeft:20,
        //borderColor:isDraggingOver ? "#07C160" : "#343434"
    });

  
    const getItemStyleWhite = (isDragging:boolean, isAnswer:boolean,draggableStyle:any, isRight:any) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        margin: `0 0 ${8}px 0`,

        // change background colour if dragging
        background: isDragging ? "#eefff6" : (isRight == true ? "#E5F9EF" :(isRight == false ? "#FFECED" : "#fff")),
        padding:3,
        border:isAnswer? 'dashed': 'dashed',
        borderRadius:5,
        borderWidth:'1px',

        borderColor:isDragging ? "#07C160" : "#cccccc",
        // styles we need to apply on draggables
        ...draggableStyle
    });

    const getListStyleWhite = (isDraggingOver:boolean) => ({
        //background: "#e9e9e9",
        padding: 0,
        //border: "dashed" ,
        //borderRadius:5,
        //borderWidth:1,
        marginBottom:0,
        marginLeft:20,
        //borderColor:isDraggingOver ? "#07C160" : "#343434"
    });

    const onDragEnd = (result:any) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            optionsList,
            result.source.index,
            result.destination.index
        )
        // 拖拽以后直接保存排序，排序改变选项显示顺序和提交的答案顺序
        setOptionsList(items)
    }

    const getRunnigStatusBtn = () => {
        if(currentStatus == true){
            return <Button hidden={exercise?.hint_submit_btn == 2} id={"exercise-run-" + exercise.id} onClick={exerciseSubmitBtnClick}  type='default' style={{paddingLeft:5,borderRadius:3,width:100,color:"#07c160",borderColor:"#07c160"}} className="exercise-run-button"> <CheckOutlined />{[3, 4].includes(exercise.source_page) ? "已提交" : " 正 确 "}</Button>
        }else if (currentStatus == false) {
            return  <Button hidden={exercise?.hint_submit_btn == 2} id={"exercise-run-" + exercise.id} onClick={exerciseSubmitBtnClick}   danger type='default' style={{paddingLeft:5,color:"#ff4b50", borderRadius:3,width:100}} className="exercise-run-button"> <CloseOutlined />错 误</Button>
        }else {
            return <Button hidden={exercise?.hint_submit_btn == 2} id={"exercise-run-" + exercise.id} onClick={exerciseSubmitBtnClick} loading={running}  type='primary' style={{borderRadius:3,width:100}} className="exercise-run-button">{[3, 4].includes(exercise.source_page) ? " 提 交 " : " 测 试 "}</Button>
        }
    }

    return (
        <div style={{marginBottom:20,textAlign:'left'}} className={exerciseCardStyles.wrapper}>
            <div style={{fontSize:14,textOverflow:"ellipsis",wordWrap:"break-word"}}>{"【排序】" + exercise.steps}
            <div style={{marginTop:10,marginBottom:10}}>
                <ConfigProvider locale={zhCN}>
                    <Row justify="center" align="middle" gutter={[10,10]}>
                    {exercise?.images?.map((image:any)=>{
                        return <Col key={image.name} span={6}>
                            <Image
                            width={360}
                            height={270}
                            preview={false}
                            src={constants.BACKEND_URL + image.path + image.name}
                            />
                    </Col>
                    })}
                    </Row>
                </ConfigProvider>
             </div>
             </div>

             <div style={{textAlign:"right",marginBottom:0}}>
                 {renderAnswerButton()}
             </div>
            
            <Row align="top" gutter={[16,8]}>

            <Col span={answervisible? 12: 24}>
             { editorMode == true ? <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided:any, snapshot:any) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={ renderType == "white" ? getListStyleWhite(snapshot.isDraggingOver) : getListStyleDark(snapshot.isDraggingOver)}
                    >
                      {optionsList?.map((answer:any, index:number) => (
                        <Draggable key={String(exercise.id) + answer.id} draggableId={String(exercise.id) + answer.id} index={index}>
                          {(provided:any, snapshot:any) => (
                            <div 
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={renderType == "white" ?  getItemStyleWhite(snapshot.isDragging,false,provided.draggableProps.style, null) :
                              getItemStyleDark(snapshot.isDragging,provided.draggableProps.style)}
                            >
                              <Row align="middle" className={exerciseCardStyles.sortItem}>
                                <Col flex="20px"><Tooltip title="拖拽排序"><MenuOutlined style = {{marginLeft:5,marginRight: 5}}/></Tooltip></Col>
                                <Col flex="auto" style={{textAlign:'left',width:"100px",textOverflow:"ellipsis",wordWrap:"break-word"}} >
                                <ReactMarkdown  
                                    className={exerciseCardStyles.reactmarkdown}
                                    remarkPlugins={[gfm,remarkMath]}
                                    rehypePlugins={[rehypeKatex,rehypeRaw]}
                                  >
                                      {answer.options}
                                  </ReactMarkdown>
                                </Col>
                              </Row>

                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              :
              <div style={{marginTop:0,padding:0,borderRadius:5}} className={exerciseCardStyles.answerParse888} >
                
               {optionsList?.map((answer:any,index)=> (
                  <div  key= {answer.id} style={renderType == "white" ? getItemStyleWhite(false,true,{}, answervisible == false ? null : ( answer.id == exercise.options_answer[index])):getItemStyleDark(false,{})}>
                        <ReactMarkdown
                            className={exerciseCardStyles.reactmarkdown}
                            remarkPlugins={[gfm,remarkMath]}
                            rehypePlugins={[rehypeKatex,rehypeRaw]}
                            
                        >
                        { "  " + (index+1) + "." + options.filter((option)=>(option.id == answer.id))[0].options} 
                        </ReactMarkdown>
                        
                    </div> 
               ))}
                  </div>
                }
     
            </Col>


            {  answervisible &&  //参考答案
            <Col span={12}>
              <div style={{marginTop:0,padding:0,borderRadius:5}} className={exerciseCardStyles.answerParse888} >
                
               {exercise.options_answer.map((optionId,index)=> (
                  <div  key= {optionId} style={renderType == "white" ? getItemStyleWhite(false,true,{}, null):getItemStyleDark(false,{})}>
                        <ReactMarkdown
                            className={exerciseCardStyles.reactmarkdown}
                            remarkPlugins={[gfm,remarkMath]}
                            rehypePlugins={[rehypeKatex,rehypeRaw]}
                        >
                        { "  " + (index+1) + "." + options.filter((option)=>(option.id == optionId))[0].options} 
                        </ReactMarkdown>
                        
                    </div> 
               ))}
                  </div>
             </Col>
          
            }

            </Row>

 
        <Row justify="start" align="middle" gutter={[8,8]} style={{marginTop:20}}>
              <Col flex={"100px"} style={{textAlign:"left"}} >
              {getRunnigStatusBtn()}
              </Col>
              <Col flex={"auto"} style={{textAlign:"left"}}>
                
              </Col>
              <Col flex={"100px"} style={{textAlign:"right"}}>
                  {renderParseButton()}
                {/*<Button  onClick={exerciseAnswerParseBtnClick}  type='link' style={{width:80,marginRight:10,borderRadius:20}}> 解 析{answerParsevisible ? <UpOutlined />: <DownOutlined />  } </Button>*/}
              </Col>
            </Row>


            { answerParsevisible && 
            
            <div style={{marginTop:20,padding:10,borderRadius:5}} className={exerciseCardStyles.answerParse}
            >
            <ReactMarkdown  
                className={exerciseCardStyles.reactmarkdown}
                remarkPlugins={[gfm,remarkMath]}
                rehypePlugins={[rehypeKatex,rehypeRaw]}
            >
            {exercise.answer_parse}
            </ReactMarkdown>
            </div>
            

            }
        </div>
    )

}

export default ExerciseCardSortSelect