// 时间转为毫秒 
export const timeToSec = (time)=> {
  var hour = time.split(':')[0]
  var min = time.split(':')[1]
  var sec = time.split(':')[2]
  var s = Number(hour * 3600) + Number(min * 60) + Number(sec)
  return s * 1000
}

// 将秒转化成时分秒
export const secTotime=(s)=> {
  var t = '';
  if(s > -1){
    var hour = Math.floor(s/3600)
    var min = Math.floor(s/60) % 60
    var sec = s % 60
    if(hour < 10) {
      t = '0'+ hour + ":"
    } else { 
      t = hour + ":"
    } 
    if(min < 10){
      t += "0"
    } 
    t += min + ":"
    if(sec < 10){
      t += "0"
    } 
    t += sec.toFixed(0)
  } 
  return t
}
