import React, { useEffect, useRef, useState } from 'react'
import {
  Popconfirm,
  Input,
  Table,
  Switch,
  Layout,
  message,
  Card,
  Col,
  Row,
  ConfigProvider,
  Pagination,
  Tabs,
  Button,
  Tag,
  Checkbox,
  Modal,
  DatePicker,
  Select,
  Badge,
} from 'antd'
import { PageHeader } from '../../common/page-header/header'
import { sendHttpPostRequest } from '../../../api/http-request'
import teacherStudentStyles from './course-main-teacher-student.module.less'
import '../course.module.css'
import zhCN from 'antd/lib/locale/zh_CN'
import * as constants from '../../../constants/constants'
import PageFooter from '../../common/page-footer/footer'
import LoginRegisterModal from '../../../component/modal/login-register/login-register'
import { checkUserLogin } from '../../common/user-center/user-info'
import {
  DeleteOutlined,
  SearchOutlined,
  ClockCircleOutlined,
  VideoCameraOutlined,
  CodeOutlined,
  FormOutlined,
  UserOutlined,
  RightOutlined,
} from '@ant-design/icons'
import GlobalCss from '@/global.module.less'

import Draggable from 'react-draggable'
import courseMainStyles from '../course-main.module.less'
import Cookies from 'js-cookie'
import { getCollegeId } from '../../../component/modal/teacher-authenticate/teacher-authenticate'
import { rejects } from 'assert'
import ModalDrag from 'component/ModaDrag/ModalDrag'

const { CheckableTag } = Tag

const { TextArea } = Input
const { TabPane } = Tabs
const { Search } = Input
const { Option } = Select
interface Course {
  course_id: number
  name: string
  cover: string
}

export interface Props {
  course_id: any
  is_course_teacher: any
}
/** 教师课程主页面 */
export const CourseMainTeacherStudent: React.FC<Props> = (props) => {
  const { course_id, is_course_teacher } = props
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([])
  const [studentList, setStudentList] = useState([] as any[])
  const [applyList, setApplyList] = useState([] as any[])
  const [searchStuList, setSearchStuList] = useState([] as any[])
  const [showStudentList, setShowStudentList] = useState(true)
  const [totalCount, setTotalCount] = useState(0)
  const [totalSum, setTotalSum] = useState(0)
  const [collegeId, setCollegeId] = useState(0)
  const [tableHidden, setTableHidden] = useState(true)
  const [importVisible, setImportVisible] = useState(false)
  const [importSuccessVisible, setImportSuccessVisible] = useState(false)
  const queryContent = useRef('') // 搜索关键字

  const [selectedApplyRows, setSelectedApplyRows] = React.useState([]) //申请列表选择的行

  const page = useRef(1) // 记录当前的页数
  const pageSize = useRef(10) // 记录当前每页展示数量

  const draggleRef = useRef<HTMLDivElement | null>(null)
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
  const [disabled, setDisabled] = useState(true)
  const [resetPasswordModalVisible, setResetPasswordModalVisible] =
    useState(false)

  const onStart = (event: any, uiData: any) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement
    const targetRect = draggleRef?.current?.getBoundingClientRect()
    if (targetRect) {
      setBounds({
        left: -targetRect?.left + uiData?.x,
        right: clientWidth - (targetRect?.right - uiData?.x),
        top: -targetRect?.top + uiData?.y,
        bottom: clientHeight - (targetRect?.bottom - uiData?.y),
      })
    }
  }

  useEffect(() => {
    sendHttpPostRequest(
      'account/api/query_teacher_authenticate',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
      },
      (response: any) => {
        if (response?.college_info?.college_id) {
          setCollegeId(response.college_info.college_id)
        }
      }
    )
    queryCourseStudent()
    queryCourseApply()
  }, [])

  const queryCourseStudentCallback = (response: any) => {
    message.destroy()
    if (response.code == -100) {
      // 没登录
      document.getElementById('show-login-modal')!.click()
      // window.location.href = '/login?next=course';
    } else if (response.code == -501) {
      // 没权限
      message.error('权限不足')
      // window.location.href = '/login?next=course';
    } else if (response.is_ok == true) {
      setStudentList(response.query_list)
      setTotalCount(response.query_list.length)
      setTotalSum(response.total_sum)
    } else {
      message.error(response.message)
    }
  }

  const queryCourseStudent = () => {
    sendHttpPostRequest(
      'course/api/user_query_course_user',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        college_id: getCollegeId(),
        course_id: course_id,
        type: 0,
        page: page.current,
        pagesize: pageSize.current,
      },
      queryCourseStudentCallback
    )
  }

  const queryCourseApplyCallback = (response: any) => {
    message.destroy()
    if (response.code == -100) {
      // 没登录
      document.getElementById('show-login-modal')!.click()
      // window.location.href = '/login?next=course';
    } else if (response.code == -501) {
      // 没权限
      message.error('权限不足')
      // window.location.href = '/login?next=course';
    } else if (response.is_ok == true) {
      setApplyList(response.query_list)
      setTotalCount(response.query_list.length)
    } else {
      message.error(response.message)
    }
  }

  //页码和页数发生了变化
  const onPageChange = (newPage: number, newPageSize?: number | undefined) => {
    page.current = newPage ? newPage : 1
    if (newPageSize) {
      pageSize.current = newPageSize
    }
    queryCourseStudent()
  }

  const queryCourseApply = () => {
    sendHttpPostRequest(
      'course/api/user_query_course_user',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        college_id: getCollegeId(),
        course_id: course_id,
        type: 1,
      },
      queryCourseApplyCallback
    )
  }

  const onAddCourseUserClick = (userId: number, userRole: string) => {
    console.log(userId, userRole)
    sendHttpPostRequest(
      'course/api/teacher_add_course_user',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        course_id: course_id,
        college_id: collegeId,
        user_list: JSON.stringify([
          { user_id: userId, user_role: parseInt(userRole) },
        ]),
      },
      (response: any) => {
        message.destroy()
        if (response.code == -100) {
          // 没登录
          document.getElementById('show-login-modal')!.click()
          // window.location.href = '/login?next=course';
        } else if (response.code == -501) {
          // 没权限
          message.error('权限不足')
          // window.location.href = '/login?next=course';
        } else if (response.is_ok) {
          message.success('添加成功')
          queryCourseStudent()
          const newSearchStuList = searchStuList.filter(
            (item) => item.user_id !== userId
          )
          setSearchStuList(newSearchStuList)
        } else {
          message.error(response.message)
        }
      }
    )
  }

  const importStuList = [
    {
      id: 1,
      name: '张三',
      phone: '133****0987',
      class: '2021计算机班',
      studentID: '200909098',
      inClassTime: '2021-01-10 11:03',
    },
    {
      id: 2,
      name: '张三',
      phone: '133****0987',
      class: '2021计算机班',
      studentID: '200909098',
      inClassTime: '2021-01-10 11:03',
    },
    {
      id: 3,
      name: '张三',
      phone: '133****0987',
      class: '2021计算机班',
      studentID: '200909098',
      inClassTime: '2021-01-10 11:03',
    },
    {
      id: 4,
      name: '张三',
      phone: '133****0987',
      class: '2021计算机班',
      studentID: '200909098',
      inClassTime: '2021-01-10 11:03',
    },
    {
      id: 5,
      name: '张三',
      phone: '133****0987',
      class: '2021计算机班',
      studentID: '200909098',
      inClassTime: '2021-01-10 11:03',
    },
    {
      id: 6,
      name: '张三',
      phone: '133****0987',
      class: '2021计算机班',
      studentID: '200909098',
      inClassTime: '2021-01-10 11:03',
    },
    {
      id: 7,
      name: '张三',
      phone: '133****0987',
      class: '2021计算机班',
      studentID: '200909098',
      inClassTime: '2021-01-10 11:03',
    },
    {
      id: 8,
      name: '张三',
      phone: '133****0987',
      class: '2021计算机班',
      studentID: '200909098',
      inClassTime: '2021-01-10 11:03',
    },
  ]

  // 删除成员
  const onRemoveCourseUserClick = (user_id: any) => {
    sendHttpPostRequest(
      'course/api/teacher_remove_course_user',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        course_id: course_id,
        college_id: collegeId,
        user_id_list: JSON.stringify([user_id]),
      },
      (response: any) => {
        message.destroy()
        if (response.code == -100) {
          // 没登录
          document.getElementById('show-login-modal')!.click()
          // window.location.href = '/login?next=course';
        } else if (response.code == -501) {
          // 没权限
          message.error('权限不足')
          // window.location.href = '/login?next=course';
        } else if (response.is_ok == true) {
          if (response.fail_list.length > 0) {
            let _errorMessage = `以下成员删除失败：`
            for (let _failItem in response.fail_list) {
              _errorMessage +=
                ' 用户ID：' +
                response.fail_list[_failItem]['user_id'] +
                '，失败原因： '
              _errorMessage += response.fail_list[_failItem]['fail_reason']
              _errorMessage += `；`
            }
            message.error(_errorMessage)
            setTimeout(function () {
              queryCourseStudent()
            }, 2000)
          } else {
            message.success('删除成功')
            setTimeout(function () {
              queryCourseStudent()
            }, 2000)
          }
        } else {
          message.error(response.message)
        }
      }
    )
  }

  // 批量删除成员
  const onRemoveCourseUserList = (user_id_list: any) => {
    sendHttpPostRequest(
      'course/api/teacher_remove_course_user',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        course_id: course_id,
        college_id: collegeId,
        user_id_list: JSON.stringify(user_id_list),
      },
      (response: any) => {
        message.destroy()
        if (response.code == -100) {
          // 没登录
          document.getElementById('show-login-modal')!.click()
          // window.location.href = '/login?next=course';
        } else if (response.code == -501) {
          // 没权限
          message.error('权限不足')
          // window.location.href = '/login?next=course';
        } else if (response.is_ok == true) {
          if (response.fail_list.length > 0) {
            let _errorMessage = `以下成员删除失败：`
            for (let _failItem in response.fail_list) {
              _errorMessage +=
                ' 用户ID：' +
                response.fail_list[_failItem]['user_id'] +
                '，失败原因： '
              _errorMessage += response.fail_list[_failItem]['fail_reason']
              _errorMessage += `；`
            }
            message.error(_errorMessage)
            setTimeout(function () {
              queryCourseStudent()
            }, 2000)
          } else {
            message.success('删除成功')
            setTimeout(function () {
              queryCourseStudent()
            }, 2000)
          }
        } else {
          message.error(response.message)
        }
      }
    )
  }

  // 点击拒绝
  const rejectStudentApplys = (user_id_list: any) => {
    sendHttpPostRequest(
      'course/api/teacher_reject_user_course_apply',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        course_id: course_id,
        user_id_list: JSON.stringify(user_id_list),
      },
      (response: any) => {
        message.destroy()
        if (response.code == -100) {
          // 没登录
          document.getElementById('show-login-modal')!.click()
          // window.location.href = '/login?next=course';
        } else if (response.code == -501) {
          // 没权限
          message.error('权限不足')
          // window.location.href = '/login?next=course';
        } else if (response.is_ok == true) {
          const newApplyList = applyList.filter(
            (item) => user_id_list.indexOf(item.user_id) < 0
          )
          setApplyList(newApplyList)
          setSelectedApplyRows([])
          message.success('已拒绝')
        } else {
          message.error(response.message)
        }
      }
    )
  }

  // 点击通过
  const approveStudentApplys = (user_id_list: any) => {
    sendHttpPostRequest(
      'course/api/teacher_approve_user_course_apply',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        course_id: course_id,
        user_id_list: JSON.stringify(user_id_list),
      },
      (response: any) => {
        message.destroy()
        if (response.code == -100) {
          // 没登录
          document.getElementById('show-login-modal')!.click()
          // window.location.href = '/login?next=course';
        } else if (response.code == -501) {
          // 没权限
          message.error('权限不足')
          // window.location.href = '/login?next=course';
        } else if (response.is_ok == true) {
          const newApplyList = applyList.filter(
            (item) => user_id_list.indexOf(item.user_id) < 0
          )
          setApplyList(newApplyList)
          setSelectedApplyRows([])
          message.success('已同意')
        } else {
          message.error(response.message)
        }
      }
    )
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: (keys) => {
      setSelectedRowKeys(keys)
    },
  }

  const rowApplySelection = {
    selectedApplyRows,
    onChange: (keys, rows) => {
      console.log(rows)
      setSelectedApplyRows(rows)
    },
  }

  const resetPassWordOk = () => {
    let url = 'course/api/batch_reset_user_password'
    let params = {
      user_id_list: JSON.stringify(selectedRowKeys),
    }
    sendHttpPostRequest(url, params, (res) => {
      if (res.code == 0) {
        message.success('重置成功~')
      } else {
        message.error(res.msg)
      }
    })
    setResetPasswordModalVisible(false)
  }

  const renderChoose = (e) => {
    return (
      <>
        <span style={{ marginLeft: 5 }}>{e < 10 ? '0' + e : e}</span>
      </>
    )
  }

  // 渲染操作
  const renderStudentActions = (text: any, record: any) => {
    return (
      <Popconfirm
        placement="top"
        title={'确认删除成员"' + record.name + '"吗？'}
        okText="确认"
        cancelText="取消"
        onConfirm={onRemoveCourseUserClick.bind(this, record.user_id)}
      >
        <DeleteOutlined className={teacherStudentStyles.actionBtn} />
      </Popconfirm>
    )
  }

  const studentCols: any = [
    {
      title: '全选',
      dataIndex: 'user_id',
      key: 'user_id',
      align: 'left',
      width: 80,
      render: (text, record, index) =>
        (page.current - 1) * pageSize.current + index + 1,
    },
    {
      title: '姓名',
      dataIndex: 'real_name',
      key: 'real_name',
      align: 'left',
      width: 100,
      render: (text, record, index) => {
        if (text == '') {
          return record.name
        } else {
          return text
        }
      },
    },
    {
      title: '学号/工号',
      dataIndex: 'number',
      key: 'number',
      align: 'left',
      width: 100,
    },
    {
      title: '手机号',
      dataIndex: 'phone',
      key: 'phone',
      align: 'left',
      width: 100,
    },
    {
      title: '角色',
      dataIndex: 'role',
      key: 'role',
      align: 'center',
      width: 80,
    },
    {
      title: '加入时间',
      dataIndex: 'join_time',
      key: 'join_time',
      align: 'left',
      width: 120,
    },
    /*{
        title: '注册时间',
        dataIndex: 'register_time',
        key: 'register_time',
        align: 'center',
        width: '15%',
      },*/
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: 80,
      render: renderStudentActions,
    },
  ]

  const renderAction = (text: any, record: any) => {
    return (
      <>
        {is_course_teacher && (
          <Button
            type="primary"
            size="small"
            onClick={onAddCourseUserClick.bind(
              this,
              record.user_id,
              record.role
            )}
            style={{ borderRadius: 20, width: 50, fontSize: 12 }}
          >
            {' '}
            添加{' '}
          </Button>
        )}
      </>
    )
  }

  const searchStuCols: any = [
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'id',
      align: 'center',
      width: '15%',
    },
    {
      title: '手机号',
      dataIndex: 'phone',
      key: 'phone',
      align: 'center',
      width: '20%',
    },
    {
      title: '角色',
      dataIndex: 'role_str',
      key: 'role_str',
      align: 'center',
      width: '15%',
    },
    {
      title: '学号',
      dataIndex: 'number',
      key: 'number',
      align: 'center',
      width: '15%',
    },
    {
      title: '注册时间',
      dataIndex: 'join_time',
      key: 'join_time',
      align: 'center',
      width: '20%',
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: '15%',
      render: renderAction,
    },
  ]

  const importCols: any = [
    {
      title: '全选',
      dataIndex: 'user_id',
      key: 'user_id',
      align: 'left',
      width: '8%',
      render: renderChoose,
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      width: '15%',
    },
    {
      title: '手机号',
      dataIndex: 'phone',
      key: 'phone',
      align: 'center',
      width: '20%',
    },
    {
      title: '班级',
      dataIndex: 'class',
      key: 'class',
      align: 'center',
      width: '20%',
    },
    {
      title: '学号',
      dataIndex: 'number',
      key: 'number',
      align: 'center',
      width: '16%',
    },
    {
      title: '注册时间',
      dataIndex: 'inClassTime',
      key: 'inClassTime',
      align: 'center',
      width: '20%',
    },
  ]

  // 渲染操作
  const renderApplyActions = (text: any, record: any) => {
    return (
      <div>
        {is_course_teacher && (
          <Button
            className={teacherStudentStyles.refuseBtn}
            style={{ marginRight: 5 }}
            size="small"
            type="link"
            onClick={() => {
              rejectStudentApplys([record.user_id])
            }}
          >
            {' '}
            拒绝{' '}
          </Button>
        )}
        {is_course_teacher && (
          <Button
            className={teacherStudentStyles.actionBtn}
            style={{ marginRight: 5 }}
            size="small"
            type="link"
            onClick={() => {
              approveStudentApplys([record.user_id])
            }}
          >
            {' '}
            同意{' '}
          </Button>
        )}
      </div>
    )
  }

  const applyCols: any = [
    {
      title: '全选',
      dataIndex: 'user_id',
      key: 'user_id',
      align: 'left',
      width: 80,
      render: (text, record, index) => index + 1,
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      width: '22.5%',
    },
    {
      title: '手机号',
      dataIndex: 'phone',
      key: 'phone',
      align: 'center',
      width: '22.5%',
    },
    {
      title: '申请时间',
      dataIndex: 'apply_time',
      key: 'apply_time',
      align: 'center',
      width: '22.5%',
    },

    {
      title: '操作',
      dataIndex: 'actions',
      key: 'actions',
      width: '22.5%',
      ellipsis: false,
      align: 'center',
      render: renderApplyActions,
    },
  ]

  const handleStudentChange = (checked) => {
    //setSelectedRowKeys([])
    setShowStudentList(true)
    queryCourseStudent()
  }

  const handleApplyChange = (checked) => {
    //setSelectedRowKeys([])
    setShowStudentList(false)
    queryCourseApply()
  }

  const onSearchValueChange = (event: any) => {
    queryContent.current = event.target.value
  }

  const onSearchBtn = (e: any) => {
    sendHttpPostRequest(
      'course/api/user_query_college_user',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        college_id: collegeId,
        course_id: course_id,
        query: queryContent.current,
        is_classroom_query: 1,
      },
      (response: any) => {
        message.destroy()
        if (response.code == -100) {
          // 没登录
          document.getElementById('show-login-modal')!.click()
          // window.location.href = '/login?next=course';
        } else if (response.code == -501) {
          // 没权限
          message.error('权限不足')
          // window.location.href = '/login?next=course';
        } else if (response.is_ok == true) {
          setSearchStuList(response.query_list)
          setTableHidden(false)
        } else {
          message.error(response.message)
        }
      }
    )
  }

  return (
    <div className={courseMainStyles.tabpaneWrapper}>
      <div className={teacherStudentStyles.studentSearch}>
        <Row
          justify="start"
          align="middle"
          gutter={[4, 4]}
          style={{ marginBottom: 20 }}
        >
          {is_course_teacher && (
            <Col style={{ textAlign: 'left' }} span={18}>
              <Input
                onPressEnter={onSearchBtn}
                placeholder="手机号/用户名"
                style={{ float: 'left', borderRadius: 20, width: 200 }}
                suffix={<SearchOutlined style={{ color: '#666666' }} />}
                onChange={onSearchValueChange}
              />
            </Col>
          )}
          {is_course_teacher && (
            <Col style={{ textAlign: 'right' }} span={6}>
              <Button
                type="primary"
                style={{ display: 'none', borderRadius: 20, width: 80 }}
                onClick={() => {
                  setImportVisible(true)
                }}
              >
                {' '}
                导入{' '}
              </Button>
            </Col>
          )}
        </Row>
        <div className={teacherStudentStyles.tableWrapper}>
          <Table
            dataSource={searchStuList}
            columns={searchStuCols}
            pagination={false}
            size="small"
          />
        </div>
      </div>

      <div style={{ marginTop: 25, marginBottom: 10, textAlign: 'left' }}>
        <Col style={{ width: 200, textAlign: 'left', display: 'inline-block' }}>
          <CheckableTag
            key={'student'}
            checked={showStudentList}
            onChange={handleStudentChange}
            style={{ fontSize: 14 }}
          >
            {' '}
            成员列表
          </CheckableTag>
          <div
            style={{
              width: 2,
              height: 14,
              backgroundColor: '#07c160',
              display: 'inline-block',
              marginTop: 5,
              marginRight: 6,
            }}
          ></div>
          <Badge
            style={{ backgroundColor: '#ff4b50' }}
            count={applyList?.length}
            size="small"
            offset={[0, 0]}
          >
            <CheckableTag
              key={'apply'}
              checked={!showStudentList}
              onChange={handleApplyChange}
              style={{ fontSize: 14 }}
            >
              {' '}
              申请列表
            </CheckableTag>
          </Badge>
        </Col>
        <Col
          style={{
            textAlign: 'right',
            display: 'inline-block',
            float: 'right',
          }}
        >
          <Button style={{ display: 'none' }} type="link">
            {' '}
            导出Excel{' '}
          </Button>
        </Col>
        <Col
          style={{ textAlign: 'left', display: 'inline-block', float: 'right' }}
        >
          <Input
            onPressEnter={(event: any) => {}}
            placeholder="手机号/用户名"
            style={{
              display: 'none',
              float: 'left',
              borderRadius: 20,
              width: 200,
            }}
            suffix={<SearchOutlined style={{ color: '#666666' }} />}
          />
        </Col>
      </div>
      <div
        style={{
          fontSize: 10,
          textAlign: 'left',
          marginLeft: 9,
          marginBottom: 10,
        }}
      >
        共<span style={{ color: '#07c160', margin: 4 }}>{totalSum}</span>人 已选
        <span style={{ color: '#07c160', margin: 4 }}>
          {showStudentList
            ? selectedRowKeys?.length
            : selectedApplyRows?.length}
        </span>
        人
      </div>

      {showStudentList && (
        <>
          <Row align={'middle'} justify={'end'} style={{ gap: '10px' }}>
            <Col>
              <Button
                disabled={selectedRowKeys?.length == 0}
                type="primary"
                style={{ marginBottom: 10, borderRadius: 20, width: 88 }}
                onClick={() => {
                  setResetPasswordModalVisible(true)
                  // onRemoveCourseUserList(selectedRowKeys)
                }}
              >
                重置密码
              </Button>
            </Col>
            <Col>
              <Button
                disabled={selectedRowKeys?.length == 0}
                type="primary"
                style={{ marginBottom: 10, borderRadius: 20, width: 88 }}
                onClick={() => {
                  onRemoveCourseUserList(selectedRowKeys)
                }}
              >
                批量删除
              </Button>
            </Col>
          </Row>
          <div className={teacherStudentStyles.tableWrapper}>
            <ConfigProvider locale={zhCN}>
              <Table
                dataSource={studentList}
                columns={studentCols}
                size="small"
                rowKey={(record) => record.user_id}
                pagination={false}
                rowSelection={rowSelection}
                // onChange={onTableChange}
              />
              <Pagination
                className={teacherStudentStyles.pagination}
                style={{ marginTop: 20 }}
                size={'default'}
                defaultCurrent={1}
                total={totalSum}
                hideOnSinglePage={true}
                current={page.current}
                onChange={onPageChange}
                //onChange={}
                defaultPageSize={pageSize.current}
              />
            </ConfigProvider>
          </div>
        </>
      )}

      {!showStudentList && (
        <>
          <Row gutter={[10, 10]} justify="end">
            <Col>
              <Button
                disabled={selectedApplyRows?.length == 0}
                type="primary"
                className={teacherStudentStyles.refuse}
                style={{
                  backgroundColor: '#FF4B50',
                  borderColor: '#FF4B50',
                  marginBottom: 10,
                  borderRadius: 20,
                  width: 50,
                  height: 29,
                  padding: '0 4px',
                }}
                onClick={() => {
                  rejectStudentApplys(
                    selectedApplyRows?.map((item: any) => item.user_id)
                  )
                }}
              >
                {' '}
                拒绝{' '}
              </Button>
            </Col>
            <Col>
              <Button
                disabled={selectedApplyRows?.length == 0}
                type="primary"
                style={{
                  marginBottom: 10,
                  borderRadius: 20,
                  width: 50,
                  height: 29,
                  padding: '0 4px',
                }}
                onClick={() => {
                  approveStudentApplys(
                    selectedApplyRows?.map((item: any) => item.user_id)
                  )
                }}
              >
                同意{' '}
              </Button>
            </Col>
          </Row>
          <div style={{ textAlign: 'right' }}> </div>
          <div className={teacherStudentStyles.tableWrapper}>
            <ConfigProvider locale={zhCN}>
              <Table
                dataSource={applyList}
                columns={applyCols}
                size="small"
                rowKey={(record) => record.user_id}
                pagination={false}
                rowSelection={rowApplySelection}
                // onChange={onTableChange}
              />
            </ConfigProvider>
          </div>
        </>
      )}

      <ModalDrag
        width={460}
        open={resetPasswordModalVisible}
        modalContent={
          <Row
            align={'middle'}
            justify={'center'}
            style={{ width: '300px', margin: '0 auto', height: '80px' }}
            wrap={true}
          >
            <Col>
              确定要重置选中的{' '}
              <span className={GlobalCss.hlText}>{selectedRowKeys.length}</span>{' '}
              个学生的密码为
            </Col>
            <Col>
              默认密码 <span className={GlobalCss.hlText}>123456</span> ？
            </Col>
          </Row>
        }
        modalTitle={'提示'}
        onOk={resetPassWordOk}
        onCancel={() => setResetPasswordModalVisible(false)}
      />

      <Modal
        visible={importVisible}
        title={
          <div
            style={{ width: '100%', cursor: 'move' }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false)
              }
            }}
            onMouseOut={() => {
              setDisabled(true)
            }}
            onFocus={() => {}}
            onBlur={() => {}}
          >
            导入学员
          </div>
        }
        width={800}
        closable={false}
        className={teacherStudentStyles.addModal}
        modalRender={(modal) => (
          <div>
            <Draggable
              disabled={disabled}
              bounds={bounds}
              onStart={(event, uiData) => onStart(event, uiData)}
            >
              <div ref={draggleRef}>{modal}</div>
            </Draggable>{' '}
          </div>
        )}
        footer={
          <div>
            <Pagination
              className={teacherStudentStyles.pagination}
              total={importStuList.length}
              pageSize={10}
              // hideOnSinglePage={true}
              style={{ float: 'left' }}
            />
            <Button
              style={{
                borderColor: '#07c160',
                color: '#07c160',
                borderRadius: 5,
                width: 80,
                backgroundColor: '#f5faf7',
              }}
              onClick={() => {
                setImportVisible(false)
              }}
            >
              取消
            </Button>
            <Button
              style={{ borderRadius: 5, width: 80 }}
              type="primary"
              onClick={() => {
                setImportVisible(false)
                setImportSuccessVisible(true)
              }}
            >
              确认
            </Button>
          </div>
        }
      >
        <Row style={{ marginBottom: 20 }}>
          <Col>
            按注册时间选择
            <DatePicker.RangePicker
              placeholder={['开始日期', '结束日期']}
              style={{ width: 220, margin: '0 20px 0 10px' }}
            />
          </Col>
          <Col>
            按班级名称选择
            <Select
              defaultValue={'2019计算机'}
              style={{ width: 150, margin: '0 20px 0 10px' }}
            >
              <Option value="2019计算机">2019计算机</Option>
              <Option value="2018计算机">2018计算机</Option>
            </Select>
          </Col>
          <Col style={{ marginLeft: 8, paddingTop: 5 }}>
            共<span style={{ color: '#07c160', margin: 5 }}>15</span>人 已选
            <span style={{ color: '#07c160', margin: 5 }}>2</span>人
          </Col>
        </Row>
        <div className={teacherStudentStyles.tableWrapper}>
          <Table
            dataSource={importStuList}
            columns={importCols}
            pagination={false}
            size="small"
            rowKey={(record) => record.id}
            rowSelection={rowSelection}
          />
        </div>
      </Modal>
      <Modal
        title="提示"
        visible={importSuccessVisible}
        closable={false}
        footer={
          <div style={{ textAlign: 'center' }}>
            <Button
              style={{ borderRadius: 5, width: 80 }}
              type="primary"
              onClick={() => {
                setImportSuccessVisible(false)
              }}
            >
              确认
            </Button>
          </div>
        }
      >
        <div style={{ textAlign: 'center' }}>
          <div style={{ textAlign: 'center', padding: 10 }}>
            已成共导入<span style={{ margin: 5, color: '#07c160' }}>23</span>
            名学生，失败<span style={{ margin: 5, color: '#FF4B50' }}>2</span>名
          </div>
          <div style={{ textAlign: 'center', padding: 10 }}>
            失败原因：
            <span style={{ margin: 5, color: '#FF4B50' }}>
              已在成员列表或未注册
            </span>
          </div>
          <div style={{ textAlign: 'center', padding: 10 }}>
            失败名单：
            <span style={{ margin: 5, color: '#FF4B50' }}>张佳宁、曾子心</span>
            <Button
              style={{ border: 0, color: '#07c160', fontSize: 12, padding: 4 }}
              type="link"
            >
              {' '}
              展开
              <RightOutlined style={{ marginLeft: 0 }} />
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default CourseMainTeacherStudent
