import { Popover, Upload, message } from 'antd'
import React from 'react'
import { PluginComponent } from 'react-markdown-editor-lite'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import InsertEmojiStyle from './insertemoji.module.less'
import * as constants from '../../../constants/constants'
import type { UploadProps } from 'antd'
import Cookies from 'js-cookie'

const UpLoadCom = () => {
  const props: UploadProps = {
    accept: '.png,.jpg',
    name: '',
    multiple: false,
    showUploadList: false,
    action: constants.BACKEND_API_URL + '',
  }
}

interface ImgUploadProps {
  uploadData: any
  name: string
  actionUrl: string,
  uploadImageList: any,
  setUploadImageList: any
}

export default class InsertImgPlugin extends PluginComponent<ImgUploadProps> {
  // 这里定义插件名称，注意不能重复
  static pluginName = 'img-upload'
  // 定义按钮被放置在哪个位置，默认为左侧，还可以放置在右侧（right）
  static align = 'left'
  // 如果需要的话，可以在这里定义默认选项
  // static defaultConfig = {
  //   start: 0,
  // }

  constructor(props: any) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
    this.onChange = this.onChange.bind(this)
    this.state = {
      uploadData: this.getConfig('uploadData'),
      name: this.getConfig('name'),
      actionUrl: this.getConfig('actionUrl'),
      uploadImageList: this.getConfig('uploadImageList'),
      setUploadImageList: this.getConfig('setUploadImageList'),
    }
  }

  handleClick() {
    // 调用API，往编辑器中插入一个数字
    // this.editor.insertText(":wink:");
    // 更新一下自身的state
  }

  onChange(info) {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList)
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} 上传成功~`)
      if(!info.file.response.code && info.file.response.is_ok){
        this.editor.insertText(`![${info.file.response.image_name}](${constants.BACKEND_URL + info.file.response.image_url})`);
        if (this.state.uploadImageList) {
          let newUploadImageList:any = this.state.uploadImageList
          newUploadImageList.push(`${info.file.response.hash_id}`)
          this.state.setUploadImageList(newUploadImageList)
        }
      }
      
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} 上传失败.`)
    }
  }

  render() {
    return (
      <span className="button button-type-counter" title="插入图片">
        <Upload
          accept=".png,.jpg"
          name={this.state.name}
          multiple={false}
          showUploadList={false}
          action={constants.BACKEND_API_URL + this.state.actionUrl}
          data={this.state.uploadData}
          onChange={this.onChange}
        >
          <span
            style={{ fontSize: '18px' }}
            className="iconfont icon_jintupian"
          ></span>
        </Upload>
      </span>
    )
  }
}
