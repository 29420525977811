import exerciseCardWhiteStyles from "./exercise-card-white.module.less";
import exerciseCardDarkStyles from "./exercise-card-dark.module.less";
import {
    Image,
    Input,
    message,
    Button,
    Card,
    Col,
    ConfigProvider,
    Layout,
    Pagination,
    Row,
    Tabs,
    Table,
    Space,
    Popconfirm,
    Popover
} from 'antd';
import { EyeOutlined, ForkOutlined, FilePdfOutlined, DeleteOutlined } from '@ant-design/icons'
import { PageHeader } from "../common/page-header/header";
import React, { useEffect, useRef, useState } from "react";
import PageFooter from "../common/page-footer/footer";
import Search from 'antd/lib/input/Search';
import zhCN from 'antd/lib/locale/zh_CN'
import { Tag } from 'antd';
import Cookies from 'js-cookie';
import { Checkbox } from 'antd';
import { Radio } from 'antd';
import { UpOutlined, DownOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import * as constants from '../../constants/constants';

import remarkMath from 'remark-math'
import gfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import rehypeKatex from 'rehype-katex'
import 'katex/dist/katex.min.css' // `rehype-katex` does not import the CSS for you
import img1 from "../../static/image/course/计算机视觉.jpg";
import img2 from "../../static/image/course/深度学习.jpg";
import img3 from "../../static/image/course/数据科学导论.jpg";
import img4 from "../../static/image/course/数据可视化.jpg";
import formatIcon from '../../static/image/formatIcon.png';

const {TextArea} = Input;

const CheckboxGroup = Checkbox.Group;
import rehypeHighlight from 'rehype-highlight'

import Meta from 'antd/lib/card/Meta';
import { sendHttpPostRequest } from "../../api/http-request";
import {checkIsTeacher} from "../../component/modal/teacher-authenticate/teacher-authenticate";
const { CheckableTag } = Tag;

const { Content } = Layout
const {TabPane} = Tabs

interface Exercise {
    id: number,
    name: string,
    author: string,
    difficulty: number, //难度
    channel: number, //
    type: number, //题型
    status: number, //状态
    create_time: string,//创建时间
    modify_time: string,//修改时间
    source_page: number, // 来源页面
    source_key: number, // 来源页面key
}


/* 主观题 */

const optionMap = new Map([["1","A"],["2","B"],["3","C"],["4","D"]])
export interface Props {exercise:any,
    renderType:string, //渲染的主题类型，"dark","white"
    teacherOnly:boolean,  // 仅老师
    editorMode:boolean,//是否可编辑模式，
    showAnser:boolean,//是否默认显示答案
  }

export const ExerciseCardSubjective: React.FC<Props> = (props) => {

    const {exercise,renderType,teacherOnly,editorMode,showAnser} = props
    
    const options = (typeof exercise.options === 'string') ? JSON.parse(exercise.options) : exercise.options
    const [userCode, setUserCode] = useState(exercise?.submission_info?.submission_code);
    const [running, setRunning] = useState(false);  // 运行中状态
    const [answervisible,setAnswerVisible] = useState(showAnser)
    console.log(exercise?.submission_info?.submission_code);
    const [currentStatus, setCurrentStatus] = useState(null as any)
    const [outputs, setOutputs] = useState(null as any)  //运行的输出结果

    const [similarity, setSimilarity] = useState(null as any)

    const exerciseCardStyles = renderType == "white" ? exerciseCardWhiteStyles: exerciseCardDarkStyles

    const imgs = [
        {
          id:1,
          src: img1,
        },{
          id:2,
          src:img2,
        },{
          id:3,
          src:img3,
        },
{
          id:4,
          src:img4
        }
      ]
  
      const ppt =[{name:'大数据与人工智能.pdf',size:'30M'},{name:'数据可视化.pdf',size:'20M'}]
    
    useEffect(()=>{
        
    },[])

    const runCodeCallback = (response: any) => {
        message.destroy();
        if (response.code == -100) {
            // 未登录
            document.getElementById("show-login-modal")!.click();
            // window.location.href = `/oam_login?next=oam_preview_exercise?exercise_id=${exercise.id}`;
        } else if (response.code == 0) {
            // 成功
            // TODO: 答题卡片渲染执行结果，不在这里弹提示
            // TODO: 填空题有的输出图片，需要在卡片渲染出来
            if (response.data.status == true) {
                //message.success(`回答正确${response.data.output}`);

                setOutputs(response.data.output)
                setCurrentStatus(true)
                setSimilarity(response.data.similarity)
            } else {
                setOutputs(response.data.output)
                setCurrentStatus(false)
                setSimilarity(response.data.similarity)
            }
        } else if (response.code == -501) {
            // 权限不足
            document.getElementById("show-login-modal")!.click();
            // window.location.href = `/oam_login?next=oam_preview_exercise?exercise_id=${exercise.id}`;
        } else {
            message.error(response.message);
        }
        setRunning(false);
    }

    //点击了提交按钮
    const exerciseSubmitBtnClick = ()=>{
        //if (teacherOnly && !checkIsTeacher()) {
            //document.getElementById("show-teacher-authenticate")!.click();
        //} else {
        let _params = {
            sessionid: Cookies.get("sessionid"),
            exercise_id: exercise.id,
            code: userCode,
            source_page: exercise.source_page,
            source_key: exercise.source_key,
            course_id: exercise.course_id,
            chapter_id: exercise.chapter_id,
            knowcell_id: exercise.knowcell_id,
        }
        if ([3, 4].includes(exercise.source_page)) {
            // 作业、考试
            if ("" != userCode) {
                setCurrentStatus(null as any);
                setRunning(true);

                if (exercise.exam_id) {
                    _params["exam_id"] = exercise.exam_id;   // 考试传考试id
                } else if (exercise.homework_id) {
                    _params["homework_id"] = exercise.homework_id;   // 作业传作业id
                }

                sendHttpPostRequest(
                    'exercise/api/exercise_run/',
                    _params,
                    runCodeCallback
                )
            } else {
                // console.log("主观题没填答案")
            }
        } else {
            setCurrentStatus(null as any);
            setRunning(true);
            
            sendHttpPostRequest(
                'exercise/api/exercise_run/',
                _params,
                runCodeCallback
            )
        }
        //}

    }

    //用户编辑代码发生改变
    const onUserCodeChange = (e) =>{
        setCurrentStatus(null as any)
        setUserCode(e.target.value);
    }

    // 看答案回调
    const queryExerciseAnswerCallback = (response: any) => {
        message.destroy();
        if (response.code == -100) {
            // 未登录
            document.getElementById("show-login-modal")!.click();
        } else if (response.code == 0) {
            // 成功
            if (response.exercise_answer) {
                exercise.answer_parse = response.exercise_answer.answer_parse;
                setAnswerVisible(true);
            }
        } else if (response.code == -501) {
            // 权限不足
            // document.getElementById("show-login-modal")!.click();
        } else {
            message.error(response.message);
        }
    }

    //点击了参考答案按钮
    const exerciseAnswerBtnClick = ()=>{
        if (exercise.answer_parse.length == 0) {
            // 查询答案
            sendHttpPostRequest(
                'exercise/api/query_exercise_answer/',
                {
                    sessionid: Cookies.get("sessionid"),
                    sid: Cookies.get("sessionid"),
                    exercise_id: exercise.id
                },
                queryExerciseAnswerCallback
            )
        } else {
            setAnswerVisible(!answervisible);
        }
    }

    // 渲染 查看答案按钮
    const renderAnswerButton = () => {
        if (0 == exercise.answer_parse.length) {
            if (![3, 4].includes(exercise.source_page)) {
                return (
                    <Popconfirm placement="bottom" title={'您确定查看答案吗？'} onCancel={(e: any) => {
                        e.stopPropagation();
                    }} onConfirm={(e: any) => {
                        e.stopPropagation();
                        exerciseAnswerBtnClick()
                    }} okText="确认" cancelText="取消">
                        <Button type='link' style={{width: 80, marginRight: 10, borderRadius: 20}}>
                            <span>答案解析</span><DownOutlined/> </Button>
                    </Popconfirm>
                )
            }
            // else {
            //     return (
            //         <Button onClick={exerciseAnswerBtnClick} type='link'
            //                 style={{width: 80, marginRight: 10, borderRadius: 20}}><span>答案解析</span>{!answervisible ?
            //         <DownOutlined/> : <UpOutlined/>}</Button>
            //     )
            // }
        } else {
            if (![3, 4].includes(exercise.source_page)) {
                return (
                    <Button onClick={exerciseAnswerBtnClick} type='link'
                            style={{width: 80, marginRight: 10, borderRadius: 20}}><span>答案解析</span>{!answervisible ?
                    <DownOutlined/> : <UpOutlined/>}</Button>
                )
            }
            // else {
            //     return (
            //         <Button onClick={exerciseAnswerBtnClick} type='link'
            //                 style={{width: 80, marginRight: 10, borderRadius: 20}}><span>答案解析</span>{!answervisible ?
            //         <DownOutlined/> : <UpOutlined/>}</Button>
            //     )
            // }
        }
    }

    const getRunnigStatusBtn = () => {
        if(currentStatus == true){
            return <Button hidden={exercise?.hint_submit_btn == 2} id={"exercise-run-" + exercise.id} onClick={exerciseSubmitBtnClick}  type='default' style={{paddingLeft:5,borderRadius:3,width:100,color:"#07c160",borderColor:"#07c160"}} className="exercise-run-button"> {similarity + "%"}</Button>
        }else if (currentStatus == false) {
            return  <Button hidden={exercise?.hint_submit_btn == 2} id={"exercise-run-" + exercise.id} onClick={exerciseSubmitBtnClick}    type='default' style={{paddingLeft:5,borderRadius:3,width:100,color:"#07c160",borderColor:"#07c160"}} className="exercise-run-button"> {similarity +"%"} </Button>
        }else {
            return <Button hidden={exercise?.hint_submit_btn == 2} id={"exercise-run-" + exercise.id} onClick={exerciseSubmitBtnClick}   type='primary' style={{borderRadius:3,width:100}} className="exercise-run-button">{[3, 4].includes(exercise.source_page) ? " 提 交 " : " 测 试 "}</Button>
        }
    }

    
    return (
        <div style={{marginBottom:20,textAlign:'left',textOverflow:"ellipsis",wordWrap:"break-word"}} className={exerciseCardStyles.wrapper}>
            <div style={{marginBottom:10,fontSize:14}}>
            <ReactMarkdown  
                className={exerciseCardStyles.reactmarkdownTitle}
                remarkPlugins={[gfm,remarkMath]}
                rehypePlugins={[rehypeHighlight,rehypeKatex]}
             >
                {"【主观题】" + exercise.steps}
             </ReactMarkdown>

             <div style={{marginTop:10,marginBottom:10}}>
                <ConfigProvider locale={zhCN}>
                    <Row justify="center" align="middle" gutter={[10,10]}>
                    {exercise?.images?.map((image:any)=>{
                        return <Col key={image.name} >
                            <Image
                            width={360}
                            height={270}
                            preview={false}
                            src={constants.BACKEND_URL + image.path + image.name}
                            />
                    </Col>
                    })}
                    </Row>
                </ConfigProvider>
             </div>

                </div>
            <div style={{paddingLeft:20,marginBottom:10}} >
                { editorMode == false &&
                <TextArea 
                    rows={4}
                    style={{width:"100%",borderRadius:4,borderColor:"#cccccc"}} 
                    value={userCode}
                   />
                }
                { editorMode == true &&
                <TextArea 
                    placeholder="请输入您的答案" 
                    rows={4}
                    style={{width:"100%",borderRadius:4,borderColor:"#cccccc"}} 
                    defaultValue={userCode}
                    onChange={onUserCodeChange}/>
                }
             </div>
      

            <Row justify="start" align="middle" gutter={[8,8]} style={{marginTop:10}} className="submitBtn">
                <Col flex={"100px"} style={{textAlign:"left",paddingLeft:20}} >
                {getRunnigStatusBtn()}
                </Col>
                <Col flex={"auto"} style={{textAlign:"left"}}>
                    
                </Col>
                <Col flex={"100px"} style={{textAlign:"right"}}>
                    {renderAnswerButton()}
                </Col>
            </Row>

            { answervisible && <div style={{marginTop:10,padding:10,borderRadius:5}} className={exerciseCardStyles.answerParse}
            >
            <ReactMarkdown  
                className={exerciseCardStyles.reactmarkdown}
                remarkPlugins={[gfm,remarkMath]}
                rehypePlugins={[rehypeKatex,rehypeRaw]}
            >
            {exercise?.answer_parse}
            </ReactMarkdown>
                                              
            </div>
            }
            
        </div>
    )

}

export default  ExerciseCardSubjective