import {Pagination, Layout, message, ConfigProvider, Input, Table, Modal, Button, Card, Row, Col, Popover} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useState, useRef, useEffect } from "react";
import Cookies from 'js-cookie';
import zhCN from 'antd/lib/locale/zh_CN';
import {sendHttpPostRequest} from "../../../api/http-request";
const { Search } = Input;
import TextArea from 'antd/lib/input/TextArea';
import OAMSider from "../oam-sider";
import {BACKEND_API_URL } from '../../../constants/constants';
import idataCourseTableStyles from "../../common/global-styles/idatacourse-table.module.less";
import Meta from 'antd/lib/card/Meta';

export interface Props {
    user_id: any,
}

export const UserCard : React.FC<Props> = (props)=>{
    const {user_id} = props


    const [currentUser, setCurrentUser] = useState(null as any);
    const [currenCollege, setCurrenCollege] = useState(null as any);

    useEffect(() => {
        queryUserInfoById(user_id);
    }, [])

    // 查询用户
    const queryUserInfoById = (user_id) => {
        const sessionid = Cookies.get("sessionid");
        const queryData = {
            user_id:user_id,
            sessionid: sessionid,
            sid: sessionid,
        };
        sendHttpPostRequest(
            'account/api/oam_query_user_info_by_id/',
            queryData,
            (response: any) => {
                // console.log(response);
                message.destroy();
                if (response.code == -100) {
                    // 未登录
                    window.location.href = '/oam_login?next=user_list';
                } else if (response.is_ok == true) {
                    // 成功
                    setCurrentUser(response.user_info);
                    setCurrenCollege(response.college_info);
                } else if (response.code == -501) {
                    // 权限不足
                    window.location.href = '/oam_login?next=user_list';
                } else {
                    message.error(response.message);
                }
            }
        )
    }

    
    return (
    <Card style={{minHeight:200, width: 300 }} bordered={false} bodyStyle={{padding:10}}>
        <Meta
        avatar={<img style={{width:40,height:40,borderRadius:20}} src={ currentUser?.avatar?.startsWith("http") ? currentUser?.avatar : BACKEND_API_URL + currentUser?.avatar} /> }
        title={currentUser?.real_name == ''? currentUser?.nick_name : currentUser?.real_name}
        description={currentUser?.introduction}
        />

        <Row  style={{marginTop:10}} wrap={false} justify='start' align="middle" >
            <Col flex="80px" style={{textAlign:"left", color:"#999"}} >性别：</Col>  
            <Col flex="auto" style={{textAlign:"left"}}>{currentUser?.sex}</Col> 
        </Row>
        <Row wrap={false} justify='start' align="middle" >
            <Col flex="80px" style={{textAlign:"left", color:"#999"}}>手机：</Col>  
            <Col flex="auto" style={{textAlign:"left"}} >{currentUser?.phone}</Col> 
        </Row>
        <Row wrap={false} justify='start' align="middle" >
            <Col flex="80px" style={{textAlign:"left", color:"#999"}} >学/工号：</Col>  
            <Col flex="auto" style={{textAlign:"left"}}>{currentUser?.number}</Col> 
        </Row>

        <Row wrap={false} justify='start' align="middle" >
            <Col flex="80px" style={{textAlign:"left", color:"#999"}} >当前院校：</Col>  
            <Col flex="auto" style={{textAlign:"left"}}> {currenCollege?.college_id > 0 ? <div>{currenCollege?.college_name}-{currenCollege?.is_college_teacher == 1 ? "教师" : "学生"}{currentUser?.college_info?.is_manager == 1?"-管理员":""}</div> :
                    <>无</>}</Col> 
        </Row>

        {currentUser?.college_id > 0 && 
            <Row wrap={false} justify='start' align="top" >
                <Col flex="80px" style={{textAlign:"left", color:"#999"}} >全部院校：</Col>  
                <Col flex="auto" style={{textAlign:"left"}}> 
                {currentUser?.college_list?.map(college=>{
                    return <div key={college?.college_id}>{college.college_name}-{college.is_college_teacher == 1 ? "教师" : "学生"}{college?.is_manager == 1?"-管理员":""}</div>
                    })}
                </Col> 
            </Row>
        }
        
    </Card>
    )
    
} 

export default UserCard

