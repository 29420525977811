import {
  Tabs,
  Pagination,
  DatePicker,
  Layout,
  message,
  ConfigProvider,
  Input,
  Table,
  Modal,
  Button,
  Row,
  Statistic,
  Col,
  Upload,
  InputNumber,
  Popconfirm,
} from 'antd'
import { Content } from 'antd/lib/layout/layout'
import React, { useState, useRef, useEffect } from 'react'
import Cookies from 'js-cookie'
import courseBasicInfoStyle from '../../course/course-edit-basicinfo.module.less'

import * as constants from '../../../constants/constants'

import courseCreateStyles from '../../course/course-create.module.less'
import courseEditSlidesCasesStyle from '../../course/course-edit-slides-cases.module.less'
import moduleStyles from '../module/module.module.less'
import zhCN from 'antd/lib/locale/zh_CN'
import { sendHttpPostRequest } from '../../../api/http-request'
const { Search } = Input
import TextArea from 'antd/lib/input/TextArea'
import OAMSider from '../oam-sider'
import {
  SearchOutlined,
  PlusCircleOutlined,
  FileImageOutlined,
  EditOutlined,
} from '@ant-design/icons'
import idataCourseTableStyles from '../../common/global-styles/idatacourse-table.module.less'

import ReactDOM from 'react-dom'
const { RangePicker } = DatePicker
const { TabPane } = Tabs

export interface Props {}
const statusMap = new Map([
  [1, '开放'],
  [2, '关闭'],
])

import { getRangeDate } from '../../common/utils/util'
import moment from 'moment'
import Draggable from 'react-draggable'

export const OAMCourseEdit: React.FC<Props> = (props) => {
  const courseId = parseInt(window.location.search.split('=')[1]) // 课程ID
  const [courseName, setCourseName] = useState('') //课程名称
  const [courseDescription, setCourseDescription] = useState('') //课程介绍
  const [courseCover, setCourseCover] = useState('') //课程封面路径

  const startTime = useRef(null as any) //课程开始时间
  const endTime = useRef(null as any) //课程结束时间
  const [lectureList, setLectureList] = useState(null as any) //课程课件列表
  const [caseList, setCaseList] = useState(null as any) //课程实验列表

  const [menuId, setMenuId] = useState(null as any) //课程大纲ID
  const dateFormat = 'YYYY-MM-DD HH:mm:ss'

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [addCaseVisible, setAddCaseVisible] = useState(false)
  const [addVideoVisible, setAddVideoVisible] = useState(false)
  const [cancelVideoVisible, setCancelVideoVisible] = useState(false)
  const caseQueryContent = useRef('') // 记录搜索串
  const casePage = useRef(1) // 记录当前的页数
  const casePageSize = useRef(10) // 记录当前每页展示数量
  const [caseTotalSum, setCaseTotalSum] = useState(0) // 搜索返回的结果数
  const queryCaseSource = useRef('2') // 搜索实验的来源， 来源 1 我的  2 系统
  const caseOrder = useRef('') // 排序升序降序
  const caseOrderBy = useRef('') // 排序列
  const [caseTotalCount, setCaseTotalCount] = useState(0) // 搜索返回的结果数
  const [caseData, setCaseData] = useState([] as any[])

  const selectedCaseRows = React.useRef([] as any) //选择的实验

  const selectedSlideId = React.useRef(null as any) //当前操作的课件
  const changedSlideName = React.useRef(null as any) //当前操作的课件名
  const [isChangeSlideNameModalVisible, setIsChangeSlideNameModalVisible] =
    useState(false) //修改课件名弹出框
  const draggleRef = useRef<HTMLDivElement | null>(null)

  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
  const [disabled, setDisabled] = useState(true)

  const onStart = (event: any, uiData: any) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement
    const targetRect = draggleRef?.current?.getBoundingClientRect()
    if (targetRect) {
      setBounds({
        left: -targetRect?.left + uiData?.x,
        right: clientWidth - (targetRect?.right - uiData?.x),
        top: -targetRect?.top + uiData?.y,
        bottom: clientHeight - (targetRect?.bottom - uiData?.y),
      })
    }
  }

  useEffect(() => {
    queryCourse()
    queryCaseList()
  }, [])

  // 查询课程信息
  const queryCourse = () => {
    const sessionid = Cookies.get('sessionid')
    const queryData = {
      course_id: courseId,
      sessionid: sessionid,
      sid: sessionid,
    }
    sendHttpPostRequest(
      'course/api/oam_query_course_by_id/',
      queryData,
      courseCallback
    )
  }

  // 创建一个新大纲
  const createMenu = () => {
    sendHttpPostRequest(
      'course/api/user_create_menu',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
      },
      (response: any) => {
        if (response.is_ok && response.menu_id) {
          setMenuId(response.menu_id)
          saveCourse()
        } else {
          message.destroy()
          message.error(`创建大纲失败：${response.message}`)
        }
      }
    )
  }

  // 删除课件
  const onAttachmentDelete = (slidesId: number) => {
    const newLectureList = lectureList.filter((item) => item.id !== slidesId)
    setLectureList(newLectureList)
    deleteSlides(slidesId)
  }

  const deleteSlides = (slidesId: any) => {
    sendHttpPostRequest(
      'course/api/user_delete_course_lecture',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        lecture_id: slidesId,
        course_id: courseId,
      },
      (response: any) => {
        if (response.code == -100) {
          // 没登录
          document.getElementById('show-login-modal')!.click()
        } else if (response.code == -501) {
          // 没权限
          message.error('权限不足')
          // window.location.href = '/login?next=course';
          document.getElementById('show-login-modal')!.click()
        } else if (response.is_ok == true) {
          message.success('课件删除成功~')
        } else {
          message.error(response.message)
        }
      }
    )
  }

  // 查询课程接口回调
  const courseCallback = (response: any) => {
    // console.log(response);
    message.destroy()
    if (response.code == -100) {
      // 未登录
      window.location.href = '/oam_login?next=/oam_course_list'
    } else if (response.is_ok == true) {
      // 成功
      setCourseName(response.query_info.name)
      setCourseDescription(response.query_info.description)
      setMenuId(response.query_info.syllabus_id)
      console.log(response.query_info.start_time.length)
      console.log(getRangeDate(0) + ' 00:00:00')
      response.query_info.start_time.length > 0
        ? (startTime.current = response.query_info.start_time)
        : (startTime.current = getRangeDate(0) + ' 00:00:00')
      response.query_info.end_time.length > 0
        ? (endTime.current = response.query_info.end_time)
        : (endTime.current = getRangeDate(180) + ' 23:59:59')
      setLectureList(response.query_info.lecture_list)
      setCaseList(response.query_info.case)
      setCourseCover(response.query_info.cover)

      if (response.query_info.syllabus_id == 0) {
        createMenu() //为课程创建一个新大纲
      }
    } else if (response.code == -501) {
      // 权限不足
      window.location.href = '/oam_login?next=oam_course_list'
    } else {
      message.error(response.message)
    }
  }

  /**
   * 创建课程回调
   * @param response
   */
  const saveCourseInfoCallback = (response: any) => {
    message.destroy()
    if (response.code == -100) {
      // 没登录
      window.location.href = '/oam_login?next=oam_course_list'
    } else if (response.code == -501) {
      // 没权限
      window.location.href = '/oam_login?next=oam_course_list'
    } else if (response.is_ok == true) {
      message.success('课程信息保存成功~')
    } else {
      message.error(response.message)
    }
  }

  /**
   * 点击保存课程按钮
   */
  const saveCourse = () => {
    sendHttpPostRequest(
      'course/api/oam_update_course/',
      {
        course_id: courseId,
        name: courseName,
        description: courseDescription,
        cover: courseCover,
        menu_id: menuId,
        start_time: startTime.current,
        end_time: endTime.current,
        case: JSON.stringify(caseList),
        lecture_list: JSON.stringify(lectureList),
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
      },
      saveCourseInfoCallback
    )
  }

  const disabledDate = (current: any) => {
    return false //return current && current > moment().endOf('day');
  }

  // 渲染操作
  const renderCourseSlidesActions = (text: any, record: any) => {
    return (
      <div>
        <Button
          style={{ borderRadius: 20, marginRight: 5, color: '#333' }}
          size="small"
          type="link"
          onClick={() => {
            window.open(
              record.url?.startsWith('http')
                ? record.url
                : constants.BACKEND_URL + record.url
            )
          }}
        >
          {' '}
          下载{' '}
        </Button>

        {/*<Button style={{borderRadius:20,marginRight:5,color:'#333'}} size='small' type='link' onClick={()=>{setIsChangeSlideNameModalVisible(true); selectedSlideId.current = record.id;changedSlideName.current=record.name}}> 改名 </Button> */}

        <Popconfirm
          placement="top"
          title={'确认删除课件"' + record.name + '"吗？'}
          onConfirm={onAttachmentDelete.bind(this, record.id)}
          okText="确认"
          cancelText="取消"
        >
          <Button
            style={{ borderRadius: 20, marginRight: 5, color: '#333' }}
            size="small"
            type="link"
          >
            {' '}
            删除{' '}
          </Button>
        </Popconfirm>
      </div>
    )
  }

  const slidesCols: any = [
    {
      title: '文件名称',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: '75%',
    },
    {
      title: '操作',
      dataIndex: 'actions',
      key: 'actions',
      width: '25%',
      ellipsis: false,
      align: 'center',
      //fixed: 'right',
      render: renderCourseSlidesActions,
    },
  ]

  const onPreviewCase = (id: string, event: any) => {
    event.preventDefault()
    window.open('/oam-case-preview?case_id=' + id)
  }

  // 渲染操作
  const renderCourseCaseActions = (text: any, record: any) => {
    return (
      <div>
        <Button
          onClick={onPreviewCase.bind(this, record.id)}
          style={{ borderRadius: 20, marginRight: 5, color: '#333' }}
          size="small"
          type="link"
        >
          {' '}
          查看{' '}
        </Button>

        <Popconfirm
          placement="top"
          title={'确认删除实验"' + record.name + '"吗？'}
          onConfirm={() => {
            setCaseList(caseList.filter((item) => item.id !== record.id))
            saveCourse()
          }}
          okText="确认"
          cancelText="取消"
        >
          <Button
            style={{ borderRadius: 20, marginRight: 5, color: '#333' }}
            size="small"
            type="link"
          >
            {' '}
            删除{' '}
          </Button>
        </Popconfirm>
      </div>
    )
  }

  /**
   * 保存课程回调
   * @param response
   */
  const saveCourseParamsInfoCallback = (response: any) => {
    message.destroy()
    if (response.code == -100) {
      // 没登录
      window.location.href = '/oam_login?next=oam_course_list'
    } else if (response.code == -501) {
      // 没权限
      window.location.href = '/oam_login?next=oam_course_list'
    } else if (response.is_ok == true) {
      message.success('课程信息保存成功~')
    } else {
      message.error(response.message)
    }
  }

  const saveCourseParams = (params: any) => {
    sendHttpPostRequest(
      'course/api/oam_update_course',
      params,
      saveCourseParamsInfoCallback
    )
  }

  const casesCols: any = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: '10%',
    },
    {
      title: '案例',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: '70%',
    },
    {
      title: '操作',
      dataIndex: 'actions',
      key: 'actions',
      width: '20%',
      ellipsis: false,
      align: 'center',
      //fixed: 'right',
      render: renderCourseCaseActions,
    },
  ]

  // 上传数据集传参
  const uploadData = {
    sessionid: Cookies.get('sessionid'),
    sid: Cookies.get('sessionid'),
    course_id: courseId,
  }

  // 上传图片
  const uploadImageProps = {
    accept: '.png,.jpg,jpeg,.gif',
    name: 'data_file',
    multiple: false,
    showUploadList: false,
    data: uploadData,
    action: constants.BACKEND_API_URL + 'course/api/course_upload_cover',
    onChange(info) {
      const { status } = info.file
      if (status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (status === 'done') {
        if (info.file.response.is_ok) {
          message.success(`图片${info.file.name}文件上传成功！`)
          setCourseCover(info.file.response.url)
          const params = {
            sessionid: Cookies.get('sessionid'),
            course_id: courseId,
            sid: Cookies.get('sessionid'),
            cover: info.file.response.url,
          }
          saveCourseParams(params)
        } else {
          if (info.file.response.code == -100) {
            // 未登录
            const localPath = window.location.href.split(
              window.location.host
            )[1]
            window.location.href = `/oam_login?next=${localPath}`
          } else if (info.file.response.code == -501) {
            // 权限不足
            const localPath = window.location.href.split(
              window.location.host
            )[1]
            window.location.href = `/oam_login?next=${localPath}`
          } else {
            message.error(info.file.response.message)
          }
        }
      } else if (status === 'error') {
        message.error(`图片${info.file.name}文件上传失败了:(`)
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files)
    },
  }

  const onDateRangeChange = (dates, dateStrings) => {
    startTime.current = dateStrings[0]
    endTime.current = dateStrings[1]
    //saveCourse();
  }

  // 查案例列表
  const queryCaseList = () => {
    sendHttpPostRequest(
      'case/api/oam_query_case_list',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        query: caseQueryContent.current,
        page: casePage.current,
        pagesize: casePageSize.current,
        source: queryCaseSource.current,
        order: caseOrder.current,
        order_by: caseOrderBy.current,
      },
      (response: any) => {
        if (response.is_ok && response.code == 0) {
          setCaseData(response.case_list)
          setCaseTotalSum(response.total_sum)
          setCaseTotalCount(response.total_count)
        } else {
          console.error('查询案例出错了')
        }
      }
    )
  }

  // 上传课件传参
  const uploadLectureData = {
    sessionid: Cookies.get('sessionid'),
    sid: Cookies.get('sessionid'),
    course_id: courseId,
  }

  // 上传课件
  const uploadLectureProps = {
    accept: '.mp4,.pdf',
    name: 'data_file',
    multiple: false,
    showUploadList: false,
    data: uploadLectureData,
    action: constants.BACKEND_API_URL + 'course/api/course_upload_lecture/',
    onChange(info) {
      const { status } = info.file
      if (status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (status === 'done') {
        message.destroy()
        if (info.file.response.is_ok) {
          message.success(`文件${info.file.name}上传成功！`)
          const newLectureList = [...lectureList]
          newLectureList.push({
            id: lectureList.length + 1,
            name: info.file.response.url.split('/')[
              info.file.response.url.split('/').length - 1
            ],
            url: info.file.response.url,
            open: 0,
          })
          console.log(newLectureList)
          setLectureList(newLectureList)
          saveCourse()
        } else {
          if (info.file.response.code == -100) {
            // 未登录
            // const localPath = window.location.href.split(window.location.host)[1];
            window.location.href = `/login?next=course`
          } else if (info.file.response.code == -501) {
            // 权限不足
            // const localPath = window.location.href.split(window.location.host)[1];
            window.location.href = `/login?next=course`
          } else {
            message.error(info.file.response.message)
          }
        }
      } else if (status === 'error') {
        message.error(`文件${info.file.name}上传失败了:(`)
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files)
    },
  }

  // 案例点击了搜索
  const onCaseSearchBtn = (e: any) => {
    casePage.current = 1
    caseQueryContent.current = e.target.value
    queryCaseList()
  }

  // 实验结果表格发生变化
  const onCaseTableChange: any = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: { currentDataSource: []; action: any }
  ) => {
    if (sorter.order) {
      //如果选择了排序列
      caseOrder.current = sorter.order
      caseOrderBy.current = sorter.field
    }

    casePage.current = 1
    queryCaseList()
  }

  // 选择实验
  const caseRowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      selectedCaseRows.current = selectedRows
    },
    getCheckboxProps: (record: any) => ({
      disabled: caseList.filter((item) => item.id == record.case_id).length > 0,
      //name: record.name,
    }),
  }

  const handleAddVideoOk = () => {
    setAddVideoVisible(false)
  }

  /**
   * 保存课程实验列表回调
   * @param response
   */
  const saveCourseCaseListInfoCallback = (response: any) => {
    message.destroy()
    if (response.code == -100) {
      // 没登录
      window.location.href = '/oam_login?next=oam_course_list'
    } else if (response.code == -501) {
      // 没权限
      window.location.href = '/oam_login?next=oam_course_list'
    } else if (response.is_ok == true) {
      //message.success("课程信息保存成功~")
    } else {
      message.error(response.message)
    }
  }
  /**
   * 保存课程实验按钮
   */
  const saveCourseCaseList = (caseList: any) => {
    sendHttpPostRequest(
      'course/api/oam_update_course',
      {
        course_id: courseId,
        case: JSON.stringify(caseList),
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
      },
      saveCourseCaseListInfoCallback
    )
  }

  /** 确认添加实验 */
  const handleAddCaseOk = () => {
    setAddCaseVisible(false)

    const newCaseList = [...caseList]

    selectedCaseRows.current.map((row) => {
      newCaseList.push({
        id: row.case_id,
        name: row.name,
        open: 0,
        token: row.token,
      })
    })

    setCaseList(newCaseList)

    saveCourseCaseList(newCaseList)
  }

  // 搜索切换Tab页
  const onCaseTabKeyChange = (key: string) => {
    queryCaseSource.current = key
    casePage.current = 1
    queryCaseList()
  }

  // 案例页码和页数发生了变化
  const onCasePageChange = (
    newPage: number,
    newPageSize?: number | undefined
  ) => {
    casePage.current = newPage ? newPage : 1
    if (newPageSize) {
      casePageSize.current = newPageSize
    }
    queryCaseList()
  }

  const renderCaseActions = (text: any, record: any) => {
    return (
      <Button
        className={courseCreateStyles.modalBtn}
        type="primary"
        size="small"
        style={{ height: 28, width: 60 }}
        onClick={onPreviewCase.bind(this, record.case_id, record.token)}
      >
        预览
      </Button>
    )
  }

  const caseModalCols: any = [
    {
      title: '序号',
      dataIndex: 'case_id',
      key: 'case_id',
      align: 'center',
      width: 80,
    },
    {
      title: '实验名称',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: 200,
    },
    {
      title: '领域',
      dataIndex: 'domain',
      key: 'domain',
      align: 'center',
      width: 50,
    },
    {
      title: '创建时间',
      dataIndex: 'create_time',
      key: 'create_time',
      align: 'center',
      width: 150,
      sorter: true,
      ellipsis: true,
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'descend',
    },
    {
      title: '操作',
      dataIndex: 'actions',
      key: 'actions',
      width: 100,
      ellipsis: false,
      align: 'center',
      fixed: 'right',
      render: renderCaseActions,
    },
  ]

  /** 修改了课件名 */
  const onSlideNameChange = (e: any) => {
    changedSlideName.current = e.target.value
  }
  const handleChangeSlideNameOk = () => {
    //确认修改了课件名
    setIsChangeSlideNameModalVisible(false)
    const newLectureList = [...lectureList]
    newLectureList.map((item) => {
      if (item.id == selectedSlideId.current) {
        item.name = changedSlideName.current
      }
    })
    console.log(newLectureList)
    setLectureList(newLectureList)
    saveCourse()
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <OAMSider keys={'31'} openKeys={['sub-course']}></OAMSider>
      <Layout style={{ backgroundColor: '#f9f9f9' }}>
        <Content
          style={{
            backgroundColor: '#fff',
            borderRadius: 5,
            minWidth: 400,
            margin: 30,
            paddingTop: 20,
          }}
        >
          <div style={{ paddingLeft: 30, paddingRight: 30 }}>
            <div
              className={'css.search1'}
              style={{ textAlign: 'left', paddingBottom: 20 }}
            >
              <Row align="middle" gutter={16}>
                <Col flex="auto" style={{ textAlign: 'right' }}>
                  <Button
                    type="primary"
                    style={{
                      float: 'right',
                      marginRight: 20,
                      borderRadius: 20,
                    }}
                    onClick={() => {
                      saveCourse()
                      message.success('课程信息保存成功~')
                    }}
                  >
                    保 存
                  </Button>
                </Col>
              </Row>
            </div>

            <Row
              gutter={[8, 8]}
              justify="start"
              align="middle"
              style={{ marginBottom: 20 }}
            >
              <Col
                flex="80px"
                style={{ color: '#00000073', textAlign: 'left' }}
              >
                课程名称
              </Col>
              <Col flex="auto" style={{ textAlign: 'left' }}>
                <Input
                  maxLength={30}
                  placeholder="请输入课程名称"
                  required={true}
                  value={courseName}
                  onChange={(e) => {
                    setCourseName(e.target.value)
                  }}
                  style={{ borderRadius: 3, width: '100%' }}
                />
              </Col>
            </Row>

            <Row
              gutter={[8, 8]}
              justify="start"
              align="middle"
              style={{ marginBottom: 20 }}
            >
              <Col
                flex="80px"
                style={{ color: '#00000073', textAlign: 'left' }}
              >
                课程介绍
              </Col>
              <Col flex="auto" style={{ textAlign: 'left' }}>
                <TextArea
                  showCount
                  maxLength={500}
                  placeholder="请输入课程介绍"
                  style={{ borderRadius: 3, width: '100%' }}
                  autoSize={{ minRows: 5, maxRows: 5 }}
                  value={courseDescription}
                  onChange={(e) => {
                    setCourseDescription(e.target.value)
                  }}
                />
              </Col>
            </Row>

            <Row
              gutter={[8, 8]}
              justify="start"
              align="middle"
              style={{ marginBottom: 20 }}
            >
              <Col
                flex="80px"
                style={{ color: '#00000073', textAlign: 'left' }}
              >
                课程封面
              </Col>
              <Col flex="auto" style={{ textAlign: 'left' }}>
                <Upload
                  className={courseBasicInfoStyle.uploadImage}
                  {...uploadImageProps}
                >
                  {courseCover ? (
                    <img
                      id="course-cover"
                      width={250}
                      height={130}
                      src={
                        courseCover?.startsWith('http')
                          ? courseCover
                          : constants.BACKEND_URL + courseCover
                      }
                    />
                  ) : (
                    <div
                      style={{
                        paddingTop: 50,
                        textAlign: 'center',
                        width: 250,
                        height: 130,
                        border: '1px solid #00000073',
                      }}
                    >
                      {' '}
                      <div className={courseBasicInfoStyle.uploadImageText}>
                        + 上传(点击或拖拽){' '}
                      </div>
                    </div>
                  )}
                </Upload>
              </Col>
            </Row>

            <Row
              gutter={[8, 8]}
              justify="start"
              align="middle"
              style={{ marginBottom: 20 }}
            >
              <Col
                flex="80px"
                style={{ color: '#00000073', textAlign: 'left' }}
              >
                项目大纲
              </Col>
              <Col flex="auto" style={{ textAlign: 'left' }}>
                <InputNumber
                  placeholder="请输入大纲ID"
                  required={true}
                  value={menuId}
                  onChange={(newValue) => {
                    setMenuId(newValue)
                  }}
                  style={{ maxWidth: 200, borderRadius: 3, width: '100%' }}
                />
                <Button
                  type="default"
                  style={{
                    border: '1px solid #3B6D74',
                    color: '#3B6D74',
                    marginLeft: 20,
                    borderRadius: 3,
                  }}
                  onClick={() => {
                    window.open('/oam_menu_edit?menu_id=' + menuId)
                  }}
                >
                  {' '}
                  <EditOutlined /> 编 辑{' '}
                </Button>
              </Col>
            </Row>

            <ConfigProvider locale={zhCN}>
              <div style={{ textAlign: 'left' }}>
                <span style={{ color: '#ff4b50', marginRight: 3 }}>*</span>
                开课时间
                {startTime.current && endTime.current && (
                  <RangePicker
                    onChange={onDateRangeChange}
                    style={{ marginLeft: 10 }}
                    format={dateFormat}
                    disabledDate={disabledDate}
                    defaultValue={[
                      moment(startTime.current, dateFormat),
                      moment(endTime.current, dateFormat),
                    ]}
                  />
                )}
              </div>
            </ConfigProvider>
            <div
              style={{ marginTop: 20 }}
              className={courseCreateStyles.slidesHeader}
            >
              <div
                style={{
                  backgroundColor: '#e8faef',
                  height: 48,
                  lineHeight: '48px',
                }}
              >
                <span
                  style={{
                    float: 'left',
                    marginLeft: 10,
                    fontSize: 18,
                    fontWeight: 500,
                  }}
                >
                  课件
                </span>
                <Upload {...uploadLectureProps}>
                  <Button
                    type="primary"
                    style={{
                      margin: 8,
                      marginRight: 10,
                      width: 80,
                      borderRadius: 5,
                    }}
                  >
                    <PlusCircleOutlined />
                    添加
                  </Button>
                </Upload>
              </div>
              <ConfigProvider locale={zhCN}>
                <Table
                  className={courseEditSlidesCasesStyle.slidesTable}
                  dataSource={lectureList}
                  columns={slidesCols}
                  size="small"
                  pagination={false}
                />
              </ConfigProvider>
            </div>
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <div
                style={{
                  backgroundColor: '#e8faef',
                  height: 48,
                  lineHeight: '48px',
                }}
              >
                <span
                  style={{
                    float: 'left',
                    marginLeft: 10,
                    fontSize: 18,
                    fontWeight: 500,
                  }}
                >
                  实验
                </span>
                <Button
                  onClick={() => {
                    setAddCaseVisible(true)
                  }}
                  type="primary"
                  style={{
                    float: 'right',
                    margin: 8,
                    marginRight: 10,
                    width: 80,
                    borderRadius: 5,
                  }}
                >
                  <PlusCircleOutlined />
                  添加
                </Button>
              </div>
              <ConfigProvider locale={zhCN}>
                <Table
                  className={courseEditSlidesCasesStyle.casesTable}
                  dataSource={caseList}
                  columns={casesCols}
                  size="small"
                  pagination={false}
                />
              </ConfigProvider>
            </div>
          </div>
        </Content>

        <ConfigProvider locale={zhCN}>
          <Modal
            title={
              <div
                style={{
                  width: '100%',
                  cursor: 'move',
                }}
                onMouseOver={() => {
                  if (disabled) {
                    setDisabled(false)
                  }
                }}
                onMouseOut={() => {
                  setDisabled(true)
                }}
                onFocus={() => {}}
                onBlur={() => {}}
                // end
              >
                修改课件名称
              </div>
            }
            visible={isChangeSlideNameModalVisible}
            onOk={handleChangeSlideNameOk}
            onCancel={() => {
              setIsChangeSlideNameModalVisible(false)
            }}
            bodyStyle={{ height: '120px' }}
            destroyOnClose={true}
            width={350}
            modalRender={(modal) => (
              <div className={courseCreateStyles.miniModal}>
                <Draggable
                  disabled={disabled}
                  bounds={bounds}
                  onStart={(event, uiData) => onStart(event, uiData)}
                >
                  <div ref={draggleRef}>{modal}</div>
                </Draggable>{' '}
              </div>
            )}
          >
            <Input
              defaultValue={changedSlideName.current}
              onChange={onSlideNameChange}
            ></Input>
          </Modal>
        </ConfigProvider>

        <ConfigProvider locale={zhCN}>
          <Modal
            title={
              <div
                style={{ width: '100%', cursor: 'move' }}
                onMouseOver={() => {
                  if (disabled) {
                    setDisabled(false)
                  }
                }}
                onMouseOut={() => {
                  setDisabled(true)
                }}
                onFocus={() => {}}
                onBlur={() => {}}
              >
                添加实验
              </div>
            }
            visible={addCaseVisible}
            width={800}
            bodyStyle={{ minHeight: 400, overflow: 'auto' }}
            //className={moduleStyles.addModal}
            onOk={handleAddCaseOk}
            onCancel={() => {
              setAddCaseVisible(false)
            }}
            destroyOnClose={true}
            closable={false}
            modalRender={(modal) => (
              <div className={moduleStyles.addModal}>
                <Draggable
                  disabled={disabled}
                  bounds={bounds}
                  onStart={(event, uiData) => onStart(event, uiData)}
                >
                  <div ref={draggleRef}>{modal}</div>
                </Draggable>{' '}
              </div>
            )}
          >
            <div>
              <Input
                onPressEnter={onCaseSearchBtn}
                placeholder="搜索实验（名称或ID）"
                style={{ borderRadius: 20, width: 300, marginBottom: 10 }}
                suffix={<SearchOutlined style={{ color: '#666666' }} />}
              />
            </div>

            <Tabs defaultActiveKey="2" onChange={onCaseTabKeyChange}>
              <TabPane tab="平台" key="2" className="">
                <div className={courseCreateStyles.tableWrapper}>
                  <ConfigProvider locale={zhCN}>
                    <Table
                      dataSource={caseData}
                      columns={caseModalCols}
                      rowKey={(record) => record.case_id}
                      pagination={false}
                      size="small"
                      //scroll={{ x: 600 }}
                      //bordered={true}
                      onChange={onCaseTableChange}
                      rowSelection={{
                        type: 'checkbox',
                        ...caseRowSelection,
                      }}
                    />
                  </ConfigProvider>
                </div>
              </TabPane>
              <TabPane tab="我的" key="1" className="">
                <div className={courseCreateStyles.tableWrapper}>
                  <ConfigProvider locale={zhCN}>
                    <Table
                      dataSource={caseData}
                      columns={caseModalCols}
                      rowKey={(record) => record.case_id}
                      pagination={false}
                      size="small"
                      //scroll={{ x: 600 }}
                      //bordered={true}
                      onChange={onCaseTableChange}
                      rowSelection={{
                        type: 'checkbox',
                        ...caseRowSelection,
                      }}
                    />
                  </ConfigProvider>
                </div>
              </TabPane>
            </Tabs>

            <ConfigProvider locale={zhCN}>
              <div
                style={{
                  marginBottom: 10,
                  paddingTop: 10,
                  textAlign: 'center',
                }}
              >
                <Pagination
                  className={moduleStyles.pagination}
                  total={caseTotalSum}
                  defaultCurrent={1}
                  defaultPageSize={10}
                  current={casePage.current}
                  onChange={onCasePageChange}
                  hideOnSinglePage={true}
                  style={{ textAlign: 'center' }}
                />
              </div>
            </ConfigProvider>
          </Modal>
        </ConfigProvider>
      </Layout>
    </Layout>
  )
}

export default OAMCourseEdit
