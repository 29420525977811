import { message,Button, Col, ConfigProvider, Layout, Pagination, Row } from 'antd';
import { RouteComponentProps } from 'react-router';
import { Content } from 'antd/lib/layout/layout';
import OAMSider from "../oam-sider";
import React, { useEffect, useState } from "react";
import zhCN from 'antd/lib/locale/zh_CN';

import MenuPreview from './menu-preview';
import { sendHttpPostRequest } from '../../../api/http-request';
import Cookies from 'js-cookie';



// 通过路由组件额外添加参数信息到表中
interface Props extends RouteComponentProps<{ menu_id: string }> {
    menu_id: string
  }


export const OAMMenuDetail: React.FC<Props> = (props: Props) => {
    const menuId = parseInt(props.location.search.split("=")[1])
    const [menu,setMenu]  = useState(null as any);//
    
    /**
     * 查询大纲
     */

     useEffect(() => {
        queryMenu();
       
    }, [])

     const queryMenu = () => {
        sendHttpPostRequest(
            'course/api/oam_query_menu_by_id',
            {
                sessionid: Cookies.get("sessionid"),
                menu_id: menuId,
            },
            queryMenuCallback
        )
    }
  
    /**
     * 查询大纲回调
     * @param response
     */
    const queryMenuCallback = (response: any) => {
        // console.log(response)
        //message.destroy();
        if (response.code == -100) {
            // 未登录
            window.location.href = '/oam_login?next=oam_menu';
        } else if (response.is_ok == true) {
            // 成功
            setMenu(response.query_info);

           
        } /*else if (response.code == -501) {
            权限不足
            window.location.href = '/oam_login?next=oam_menu';
        } */else {
            message.error(response.message);
        }
    }


      //点击了编辑按钮
  const onEditBtnClick = (event:any) => {
    //message.success('编辑数据' + event.currentTarget.value)
    event.preventDefault()
    window.open('/oam_menu_edit?menu_id=' + menuId)
  }



    return (
        <Layout style={{ minHeight: '100vh' }}>
        <OAMSider keys={"32"} openKeys={["sub-user"]} ></OAMSider>
        <div>
            <Content style={{ backgroundColor:'#fff',borderRadius:5,minWidth:1000,margin:30 ,paddingTop:20}}>
                <Row align="middle"  gutter={16}>
                    <Col flex='auto' style={{textAlign:'right'}}> <Button style={{borderRadius:20, width:100,marginRight:30}} type={'primary'} onClick={onEditBtnClick}>编 辑</Button></Col>
                </Row>
                <div style={{paddingLeft:30,paddingRight:30}}>
                    <MenuPreview init_menu={menu?.json} />
                </div>
            </Content>
        </div>
    </Layout>
    )

}

export default OAMMenuDetail

