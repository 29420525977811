

import React, {useEffect, useRef, useState} from "react";
import { Input,Table,Switch,Layout, message, Card, Col, Row, ConfigProvider, Pagination, Tabs, Button, Tag, Collapse , Modal} from 'antd';
import courseTeacherMenuStyles from "./course-main-teacher-menu.module.less";
import "../course.module.css";
import zhCN from 'antd/lib/locale/zh_CN'
import * as constants from '../../../constants/constants';

import courseMainStyles from "../course-main.module.less";

import LoginRegisterModal from "../../../component/modal/login-register/login-register";
import {checkUserLogin} from "../../common/user-center/user-info";
import { FilePdfOutlined ,BarsOutlined,VideoCameraOutlined,CodeOutlined,FormOutlined,UserOutlined,LockOutlined } from '@ant-design/icons';
import Cookies from "js-cookie";
import { sendHttpPostRequest } from "../../../api/http-request";
const { Panel } = Collapse;

import Draggable from "react-draggable";

export interface Props {
    menu_id:any,
}

/** 锁定的课程大纲标签页 */
export const LockedMenuPreview: React.FC<Props> = (props) => {
    const {menu_id} = props;

    const [menu,setMenu] = useState(null as any);

    /**
     * 查询大纲
     */

    useEffect(() => {
        queryMenu();  
    }, [])

     const queryMenu = () => {
        sendHttpPostRequest(
            'course/api/user_query_menu_by_id',
            {
                sessionid: Cookies.get("sessionid"),
                menu_id: menu_id,
            },
            queryMenuCallback
        )
    }
  
    /**
     * 查询大纲回调
     * @param response
     */
    const queryMenuCallback = (response: any) => {
        //if (response.code == -100) {
            // 未登录
            //document.getElementById("show-login-modal")!.click();;
        //} else 
        if (response.is_ok == true) {
            // 成功
            setMenu(response.query_info);
            
        } else {
            message.error(response.message);
        }
    }

    const onKnowledgeClick = (chapter_id: number, knowledge_id: number,chapterInd:number, knowledgeInd:number) => {

        if (!checkUserLogin()) {
            document.getElementById("show-login-modal")!.click();
        } else {
            
              
        }
    }

    return (
        <div className={courseTeacherMenuStyles.tabpaneWrapper}>

            {
                menu?.json?.map((chapter, chapterInd)=>(
                
                        <div key={'chapter' + chapter.id}>  

                            <div className={courseTeacherMenuStyles.chapterName} style={{width:'100%',textAlign:'left',fontWeight: 700}} > { "第" +  (chapterInd + 1)  + "章 " + chapter.name}
                            </div>

                            {chapter.tasks.map((module, moduleIndex) => (
                                <div key={module.id}  style={{cursor:"pointer",width:"100%"}} className={courseTeacherMenuStyles.modules} onClick={onKnowledgeClick.bind(this, chapter.id, module.id,chapterInd, moduleIndex)}>
                                    <Row  gutter={[4, 4]} justify='start' align='middle' style={{width:'100%'}}>
                                        <Col style={{marginLeft:30,textAlign:'left'}} flex="auto"> {(chapterInd + 1) + "-" + (moduleIndex+1) + " " + module.name}</Col>     

                                        <Col flex="100px" style={{textAlign:'right'}}>    
                                            <LockOutlined  className={courseTeacherMenuStyles.iconbuttons}/>                      
                                    
                                        </Col>


                                    </Row>  
                                </div>
                            ))
                            }
                        </div> 
                ))                 
            }


        </div>
    )

}

export default LockedMenuPreview

