import {
  Layout,
  Button,
  Input,
  message,
  Table,
  ConfigProvider,
  Tabs,
  Row,
  Col,
  Popconfirm,
  Statistic,
  Pagination,
  Modal,
  Select,
  Upload,
} from 'antd'
import { Content } from 'antd/lib/layout/layout'
import zhCN from 'antd/lib/locale/zh_CN'
import OAMSider from '../oam-sider'
import React, { useEffect, useRef, useState } from 'react'
import { sendHttpPostRequest } from '../../../api/http-request'
import Cookies from 'js-cookie'
import { PlusOutlined } from '@ant-design/icons'
import caseStyles from '../../case/case.module.less'
import Draggable from 'react-draggable'
import * as constants from '../../../constants/constants'

import idataCourseTableStyles from '../../common/global-styles/idatacourse-table.module.less'
import CommonModal from '../../../component/modal/CommonModal/CommonModal'

const { Search } = Input
const { TabPane } = Tabs
const { TextArea } = Input
const { Option } = Select

export interface Props {}

/** OAM 实验列表页 */
export const OAMCaseList: React.FC<Props> = (props) => {
  const [caseResult, setCaseResult] = useState([] as any[]) // 当前显示的结果页
  const [resultSum, setResultSum] = useState(0) // 搜索返回的结果数
  const [totalCount, setTotalCount] = useState(0) // 搜索返回的结果数
  const queryContent = useRef('') // 记录搜索串
  const querySource = useRef('2') // 来源 1 用户  2 系统
  const order = useRef('') // 排序升序降序
  const order_by = useRef('') // 排序列
  const page = useRef(1) // 记录当前的页数
  const pageSize = useRef(20) // 记录当前每页展示数量
  const userIsLogin = useRef(false) // 用户是否登录
  const typeFilters = useRef([]) // 类型过滤选项
  const difficultyFilters = useRef([]) // 难易程度过滤选项
  const createTime = useRef('') // 创建时间

  const [sysCount, setSysCount] = useState(0) //搜索返回的结果数
  const [userCount, setUserCount] = useState(0) //搜索返回的结果数
  const [onCount, setOnCount] = useState(0) //上架实验数据

  /* 创建实验相关 */
  const [isModalVisible, setIsModalVisible] = useState(false)
  const draggleRef = useRef<HTMLDivElement | null>(null)
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
  const [disabled, setDisabled] = useState(true)
  const [caseName, setCaseName] = useState('')
  const [caseDescription, setCaseDescription] = useState('')
  const [caseDomain, setCaseDomain] = useState('')
  const [domains1, setDomains1] = useState([] as string[]) //无全部的行业选择
  const [isOk, setIsOk] = useState('false')
  const [caseUrl, setCaseUrl] = useState('')

  const [cover, setCover] = useState(null as any) //封面
  const [coverFileList, setCoverfileList] = useState([])

  const [ipynb, setIpynb] = useState(null as any) //封面
  const [ipynbFileList, setIpynbfileList] = useState([])
  const [ipynbFileUploadModalVis, setIpynbFileUploadModalVis] = useState(false)
  const domainTag = '金融'
  const [actionCase, setActionCase] = useState<any>()

  useEffect(() => {
    queryCaseList()
    queryDoMain()
  }, [])

  /**
   * 查询实验列表
   */
  const queryCaseList = () => {
    sendHttpPostRequest(
      'case/api/oam_query_case_list',
      {
        sessionid: Cookies.get('sessionid'),
        query: queryContent.current,
        source: querySource.current,
        page: page.current,
        pagesize: pageSize.current,
        order: order.current,
        order_by: order_by.current,
      },
      queryCaseListCallback
    )
  }

  /**
   * 查询实验列表回调
   * @param response
   */
  const queryCaseListCallback = (response: any) => {
    // console.log(response)
    message.destroy()
    if (response.code == -100) {
      // 未登录
      window.location.href = '/oam_login?next=oam_case_list'
    } else if (response.code == 0) {
      // 成功
      setCaseResult(response.case_list)
      setResultSum(response.total_sum)
      setTotalCount(response.total_count)
      setSysCount(response.sys_count)
      setUserCount(response.user_count)
      setOnCount(response.online_count)
    } else if (response.code == -501) {
      // 权限不足
      window.location.href = '/oam_login?next=oam_case_list'
    } else {
      message.error(response.message)
    }
  }

  /**
   * 创建实验回调
   * @param response
   */
  const setUserInfoCallback = (response: any) => {
    message.destroy()
    if (response.code == -100) {
      // 没登录
      window.location.href = '/oam_login?next=oam_case_list'
    } else if (response.code == -501) {
      // 没权限
      window.location.href = '/oam_login?next=oam_case_list'
    } else if (response.code == 0) {
      if (response.exercise_id) {
        window.open(`/oam_case_list`)
      } else {
        message.error(response.message)
      }
    }
  }

  // 查询领域回调
  const queryDoMainCallback = (response: any) => {
    console.log(response.domain_list)
    if (response.code == -100) {
      // 未登录
      window.location.href = '/oam_login?next=case'
    } else if (response.code == 0) {
      // 成功
      console.log(response.domain_list)
      const arrnew = ['全部'].concat(response.domain_list)
      const arrnew1 = response.domain_list.slice(
        0,
        response.domain_list.length - 1
      )
      console.log(arrnew)
      // setDomains(arrnew);
      console.log(arrnew1)
      setDomains1(arrnew1)
    } else if (response.code == -501) {
      // 权限不足
      window.location.href = '/oam_login?next=case'
    } else {
      message.error(response.message)
    }
  }

  // 查询领域
  const queryDoMain = () => {
    message.destroy()
    const requestParams = {
      sessionid: Cookies.get('sessionid'),
    }
    sendHttpPostRequest(
      'case/api/query_domain_list',
      requestParams,
      queryDoMainCallback
    )
  }

  // 创建案例回调
  const createCaseCallback = (response: any) => {
    console.log(response)
    message.destroy()
    if (response.is_ok) {
      // window.open(response.url);
      message.success(response.message)
      setIsOk(response.is_ok)
      setCaseUrl(response.url)
      console.log(response.url)
      queryCaseList()
      // window.open(response.url)
      window.open('/oam-case-run?case_id=' + response?.case_info?.case_id)
    } else {
      message.error(`创建案例失败${response.message}`)
    }
  }

  /**
   * 点击创建实验
   */
  const createCase = () => {
    message.destroy()
    const requestParams = {
      sessionid: Cookies.get('sessionid'),
      name: caseName,
      description: caseDescription,
      domain: caseDomain,
      cover: cover,
      ipynb: ipynb,
      source: 2, // 2-系统
    }
    sendHttpPostRequest(
      'case/api/oam_create_case',
      requestParams,
      createCaseCallback
    )
  }

  const onStart = (event: any, uiData: any) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement
    const targetRect = draggleRef?.current?.getBoundingClientRect()
    if (targetRect) {
      setBounds({
        left: -targetRect?.left + uiData?.x,
        right: clientWidth - (targetRect?.right - uiData?.x),
        top: -targetRect?.top + uiData?.y,
        bottom: clientHeight - (targetRect?.bottom - uiData?.y),
      })
    }
  }

  const handleOk = () => {
    //确认创建实验
    message.destroy()
    if (caseName.trim() == '') {
      message.error('请输入实验名称')
      // } else if (caseDescription.trim() == "") {
      //     message.error("请输入实验描述");
      // } else if (caseDomain.trim() == "") {
      //     message.error("请选择实验领域");
    } else if (!cover) {
      message.error('请上传实验封面')
    } else {
      createCase()
      setIsModalVisible(false)
    }
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  // 案例名称改变
  const onNameChange = (key: string, event: any) => {
    setCaseName(event.target.value)
  }

  // 案例描述改变
  const onDescriptionChange = (key: string, event: any) => {
    setCaseDescription(event.target.value)
  }

  // 行业改变
  const onDomainChange = (key: string, event: any) => {
    setCaseDomain(event)
    console.log(event)
  }

  // 上传封面
  const onCoverChange = (info: any) => {
    setCoverfileList(info.fileList)
    const { status } = info.file
    if (status !== 'uploading') {
      //上传中
      console.log(info.file, info.fileList)
    }
    if (status === 'done') {
      //上传成功
      let new_cover = info.file.response.cover
      let code = info.file.response.code
      code == -100
        ? () => {}
        : document.getElementById('case-cover')?.setAttribute('src', new_cover)
      // image.current = info.file.response.img_url
      setCover(info.file.response.image_url)
    } else if (status === 'error') {
      //上传失败
      message.error(`${info.file.name} file upload failed.`)
    }
  }

  // 校验图片类型和大小
  function beforeCoverUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('请上传png或jpg格式的图片!')
    }
    const isLt5M = file.size / 1024 / 1024 < 5
    if (!isLt5M) {
      message.error('请上传小于5M的图片')
    }
    return isJpgOrPng && isLt5M
  }

  // 上传cover传参
  const uploadCoverData = {
    sessionid: Cookies.get('sessionid'),
    sid: Cookies.get('sessionid'),
  }

  const uploadCoverButton = (
    <div>
      {<PlusOutlined />}
      <div style={{ marginTop: 8 }}>上传封面</div>
    </div>
  )

  //  验证ipynb文件 - 提示太大风险 - 不阻止
  function beforeIpynbUpload(file) {
    const isLt5M = file.size / 1024 / 1024 < 15
    if (!isLt5M) {
      message.error('文件大小超过15M, 使用时加载慢, 建议优化')
    }
    return true
  }

  // 上传 ipynb 参数
  const uploadIpynbData = {
    sessionid: Cookies.get('sessionid'),
    sid: Cookies.get('sessionid'),
    id: actionCase?.case_id,
  }

  // 上传 ipynb
  const onIpynbChange = (info: any) => {
    setIpynbfileList(info.fileList)

    const { status } = info.file
    if (status !== 'uploading') {
      //上传中
      console.log(info.file, info.fileList)
    }
    if (status === 'done') {
      //上传成功
      //let new_cover = info.file.response.cover
      //let code = info.file.response.code
      //code == -100 ? ()=>{} : document.getElementById("case-cover")?.setAttribute("src", new_cover)
      // image.current = info.file.response.img_url
      if (info.file?.response.code == 0) {
        setIpynb(info.file)
      }
    } else if (status === 'error') {
      //上传失败
      message.error(`${info.file.name} file upload failed.`)
    }
  }

  const uploadIpynbButton = (
    <div>
      {<PlusOutlined />}
      <div style={{ marginTop: 8 }}>上传ipynb</div>
    </div>
  )

  //页码和页数发生了变化
  const onPageChange = (newPage: number, newPageSize?: number | undefined) => {
    page.current = newPage ? newPage : 1
    if (newPageSize) {
      pageSize.current = newPageSize
    }
    queryCaseList()
  }

  // 删除回调
  const onDeleteBtnClickCallback = (response: any) => {
    message.destroy()
    if (response.code == -100) {
      // 没登录
      window.location.href = '/oam_login?next=oam_case_list'
    } else if (response.code == -501) {
      // 没权限
      window.location.href = '/oam_login?next=oam_case_list'
    } else if (response.code == 0) {
      message.success(response.message)
      queryCaseList()
    } else {
      message.error(response.message)
      queryCaseList()
    }
  }

  // 点击 删除
  const onDeleteBtnClick = (caseId: string) => {
    sendHttpPostRequest(
      'case/api/oam_delete_case',
      {
        sessionid: Cookies.get('sessionid'),
        case_id: caseId,
      },
      onDeleteBtnClickCallback
    )
  }

  // 上下架回调
  const onPutOnOffBtnClickCallback = (response: any) => {
    message.destroy()
    if (response.code == -100) {
      // 没登录
      window.location.href = '/oam_login?next=oam_case_list'
    } else if (response.code == -501) {
      // 没权限
      window.location.href = '/oam_login?next=oam_case_list'
    } else if (response.code == 0) {
      message.success(response.message)
      queryCaseList()
    } else {
      message.error(response.message)
      queryCaseList()
    }
  }

  // 点了上下架按钮
  const onPutOnOffBtnClick = (caseId: string, statusStr: string) => {
    console.log(statusStr)
    sendHttpPostRequest(
      'case/api/oam_update_case_status',
      {
        sessionid: Cookies.get('sessionid'),
        case_id: caseId,
        status: statusStr == '上架' ? 1 : 2,
      },
      onPutOnOffBtnClickCallback
    )
  }

  // 渲染操作
  const renderActions = (text: any, record: any) => {
    if (record.source == '系统') {
      return (
        <Row
          justify="start"
          align="middle"
          style={{ gap: '10px' }}
          wrap={false}
        >
          <Col>
            <Button
              style={{ borderRadius: 20, fontSize: 14 }}
              size="small"
              id="editit"
              type="dashed"
              onClick={() => {
                window.open('/oam-case-preview?case_id=' + record.case_id)
              }}
            >
              {' '}
              预览
            </Button>
          </Col>
          <Col>
            <Button
              style={{ borderRadius: 20, fontSize: 14 }}
              size="small"
              id="editit"
              type="dashed"
              onClick={onEditBtnClick.bind(this, record.case_id)}
            >
              {' '}
              运行
            </Button>
          </Col>
          <Col>
            <Button
              style={{ borderRadius: 20, fontSize: 14 }}
              size="small"
              id="upload-it"
              type="dashed"
              onClick={onUpdateBtnClick.bind(
                this,
                record.case_id,
                record.token,
                record
              )}
            >
              {' '}
              上传ipynb
            </Button>
          </Col>
          {
            <Col>
              {' '}
              {record.status == '下架' ? (
                <Button
                  style={{ borderRadius: 20, fontSize: 12 }}
                  size="small"
                  id="puton"
                  value={record.case_id}
                  type="primary"
                  onClick={onPutOnOffBtnClick.bind(
                    this,
                    record.case_id,
                    record.status
                  )}
                >
                  {' '}
                  上 架{' '}
                </Button>
              ) : (
                <Popconfirm
                  placement="top"
                  title={'确认下架实验"' + record.name + '"吗？'}
                  onConfirm={onPutOnOffBtnClick.bind(
                    this,
                    record.case_id,
                    record.status
                  )}
                  okText="确认"
                  cancelText="取消"
                >
                  <Button
                    style={{ borderRadius: 20, fontSize: 12 }}
                    size="small"
                    id="putoff"
                    value={record.id}
                    type="primary"
                    danger
                  >
                    {' '}
                    下 架{' '}
                  </Button>
                </Popconfirm>
              )}
            </Col>
          }
          {record.status == '下架' ? (
            <Col>
              <Popconfirm
                placement="top"
                title={'确认删除实验"' + record.name + '"吗？'}
                onConfirm={onDeleteBtnClick.bind(this, record.case_id)}
                okText="确认"
                cancelText="取消"
              >
                <Button
                  style={{ borderRadius: 20, fontSize: 14 }}
                  size="small"
                  id="deleteexe"
                  type="dashed"
                  danger
                >
                  {' '}
                  删除
                </Button>
              </Popconfirm>
            </Col>
          ) : (
            ''
          )}
        </Row>
      )
    } else {
      return (
        <Row justify="start" align="middle" gutter={[8, 4]}>
          <Col span={24}>
            <Button
              style={{ borderRadius: 20, fontSize: 14 }}
              size="small"
              id="lookover"
              value={record.id}
              type="default"
            >
              {' '}
              预 览{' '}
            </Button>
          </Col>
        </Row>
      )
    }
  }

  //点击了编辑按钮
  const onEditBtnClick = (id: any, event: any) => {
    window.open('/oam-case-run?case_id=' + id)
  }

  //点击了编辑按钮
  const onUpdateBtnClick = (id: any, token: any, record: any, event: any) => {
    // window.open('/oam-case-edit?id=' + id + '&token=' + token)
    setActionCase(record)
    setIpynbFileUploadModalVis(true)
  }

  //点击了搜索按钮
  const onSearchBtn = (value: string) => {
    page.current = 1
    queryContent.current = value
    queryCaseList()
  }

  // 切换Tab页
  const onTabKeyChange = (key: string) => {
    console.log(key)
    querySource.current = key
    page.current = 1
    queryCaseList()
  }

  // 表格发生变化
  const onTableChange: any = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: { currentDataSource: []; action: any }
  ) => {
    if (sorter.order) {
      //如果选择了排序列
      order.current = sorter.order
      order_by.current = sorter.field
    }
    if (filters.type) {
      //如果选择了过滤列
      typeFilters.current = filters.type
    } else {
      typeFilters.current = []
    }
    if (filters.difficulty) {
      difficultyFilters.current = filters.difficulty
    } else {
      difficultyFilters.current = []
    }
    page.current = 1
    queryCaseList()
  }

  const columns: any = [
    {
      title: '序号',
      dataIndex: 'case_id',
      key: 'case_id',
      width: 80,
      align: 'left',
      render: (text, record, index) =>
        (page.current - 1) * pageSize.current + index + 1,
    },
    {
      title: 'ID',
      dataIndex: 'case_id',
      key: 'case_id',
      align: 'left',
      width: 100,
    },
    {
      title: '实验名称',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      align: 'left',
      width: 150,
    },

    {
      title: '数据集',
      dataIndex: 'dataset',
      key: 'dataset',
      align: 'center',
      width: 100,
      render: (text: any, record: any) => <div>{text?.length}</div>,
    },
    {
      title: 'folk数',
      dataIndex: 'fork_cnt',
      key: 'fork_cnt',
      align: 'center',
      width: 80,
    },
    {
      title: '浏览数',
      dataIndex: 'view_cnt',
      key: 'view_cnt',
      align: 'center',
      width: 80,
    },
    {
      title: '领域',
      dataIndex: 'domain',
      key: 'domain',
      align: 'center',
      width: 120,
    },
    {
      title: '创建者',
      dataIndex: 'user',
      key: 'user',
      align: 'center',
      width: 150,
    },
    {
      title: '创建时间',
      dataIndex: 'create_datetime',
      key: 'create_datetime',
      align: 'center',
      width: 180,
      sorter: true,
      ellipsis: true,
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'descend',
    },
    {
      title: '操作',
      dataIndex: 'actions',
      key: 'actions',
      width: 330,
      ellipsis: false,
      align: 'center',
      fixed: 'right',
      render: renderActions,
    },
  ]

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <OAMSider keys={['4']} openKeys={[]} />
      <Layout style={{ backgroundColor: '#f9f9f9' }}>
        <Content
          style={{
            backgroundColor: '#fff',
            borderRadius: 5,
            minWidth: 400,
            margin: 30,
            paddingTop: 20,
          }}
        >
          <div style={{ paddingLeft: 30, paddingRight: 30, paddingBottom: 30 }}>
            <Row align="middle" gutter={16}>
              <Col flex="80px">
                <Statistic title="总实验" value={totalCount} />
              </Col>
              <Col flex="80px">
                <Statistic title="系统" value={sysCount} />
              </Col>
              <Col flex="80px">
                <Statistic title="用户" value={userCount} />
              </Col>
              <Col flex="80px">
                <Statistic title="已上架" value={onCount} />
              </Col>
              <Col flex="250px">
                <Search
                  placeholder="搜索实验"
                  style={{ width: 350 }}
                  enterButton={true}
                  onSearch={onSearchBtn}
                />
              </Col>
              <Col flex="auto" style={{ textAlign: 'right' }}>
                <Button
                  type="primary"
                  style={{ float: 'right', marginRight: 20, borderRadius: 20 }}
                  onClick={showModal}
                >
                  创建实验
                </Button>
                <ConfigProvider locale={zhCN}>
                  <Modal
                    title={
                      <div
                        style={{
                          width: '100%',
                          cursor: 'move',
                        }}
                        onMouseOver={() => {
                          if (disabled) {
                            setDisabled(false)
                          }
                        }}
                        onMouseOut={() => {
                          setDisabled(true)
                        }}
                        onFocus={() => {}}
                        onBlur={() => {}}
                        // end
                      >
                        创建实验
                      </div>
                    }
                    closable={false}
                    visible={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    bodyStyle={{ minHeight: '300px' }}
                    destroyOnClose={true}
                    width={508}
                    modalRender={(modal) => (
                      <div className={caseStyles.createDiv}>
                        <Draggable
                          disabled={disabled}
                          bounds={bounds}
                          onStart={(event, uiData) => onStart(event, uiData)}
                        >
                          <div ref={draggleRef}>{modal}</div>
                        </Draggable>{' '}
                      </div>
                    )}
                  >
                    <Row
                      gutter={[8, 8]}
                      justify="start"
                      align="middle"
                      style={{ marginBottom: 20 }}
                    >
                      <Col
                        flex="100px"
                        style={{ textAlign: 'left', marginLeft: 50 }}
                      >
                        实验名称
                      </Col>
                      <Col flex="auto" style={{ textAlign: 'left' }}>
                        <Input
                          maxLength={40}
                          onChange={onNameChange.bind(this, 'name')}
                          className={caseStyles.caseName}
                          placeholder="请输入实验名称"
                          required={true}
                          style={{ width: '100%' }}
                        />
                      </Col>
                      <Col flex="100px"></Col>
                    </Row>

                    <Row
                      gutter={[8, 8]}
                      justify="start"
                      align="top"
                      style={{ marginBottom: 20, maxHeight: 120 }}
                    >
                      <Col
                        flex="100px"
                        style={{ textAlign: 'left', marginLeft: 50 }}
                      >
                        实验描述
                      </Col>
                      <Col flex="auto" style={{ textAlign: 'left' }}>
                        <TextArea
                          className={caseStyles.caseText}
                          showCount
                          maxLength={300}
                          rows={4}
                          onChange={onDescriptionChange.bind(
                            this,
                            'description'
                          )}
                          placeholder="请输入实验描述"
                          required={true}
                          style={{ width: '100%', maxHeight: 100 }}
                        />
                      </Col>
                      <Col flex="100px"></Col>
                    </Row>

                    <Row
                      gutter={[8, 8]}
                      justify="start"
                      align="middle"
                      style={{ marginBottom: 20 }}
                    >
                      <Col
                        flex="100px"
                        style={{ textAlign: 'left', marginLeft: 50 }}
                      >
                        领域
                      </Col>
                      <Col flex="auto" style={{ textAlign: 'left' }}>
                        <Select
                          onChange={onDomainChange.bind(this, 'domain')}
                          className={caseStyles.caseSelect}
                          style={{ width: '100%', height: 32, outline: 0 }}
                        >
                          {domains1.map((item, index) => {
                            // 2、利用map属性
                            return (
                              <Option value={item} key={index}>
                                {item}
                              </Option>
                            )
                          })}
                        </Select>
                      </Col>
                      <Col flex="100px"></Col>
                    </Row>
                    <Row
                      gutter={[8, 8]}
                      justify="start"
                      align="middle"
                      style={{ marginBottom: 20 }}
                    >
                      <Col
                        flex="100px"
                        style={{ textAlign: 'left', marginLeft: 50 }}
                      >
                        封 面
                      </Col>
                      <Col flex="auto" style={{ textAlign: 'left' }}>
                        <div className={caseStyles.uploadCover}>
                          <Upload
                            beforeUpload={beforeCoverUpload}
                            accept={'image/*'}
                            action={
                              constants.BACKEND_API_URL +
                              'case/api/upload_case_image'
                            }
                            listType="picture-card"
                            name="case_cover"
                            fileList={coverFileList}
                            onChange={onCoverChange}
                            data={uploadCoverData}
                            showUploadList={false}
                          >
                            {cover ? (
                              <img
                                id="case-cover"
                                style={{ width: '100%', height: '100%' }}
                                src={constants.BACKEND_URL + cover}
                              />
                            ) : (
                              uploadCoverButton
                            )}
                          </Upload>
                        </div>
                      </Col>
                    </Row>

                    {/* <Row
                    gutter={[8, 8]}
                    justify="start"
                    align="middle"
                    style={{ marginBottom: 20 }}
                  >
                    <Col
                      flex="100px"
                      style={{ textAlign: 'left', marginLeft: 50 }}
                    >
                      {' '}
                      ipynb文件{' '}
                    </Col>
                    <Col flex="auto" style={{ textAlign: 'left' }}>
                      <div className={caseStyles.uploadCover}>
                        <Upload
                          beforeUpload={beforeIpynbUpload}
                          accept={'*'}
                          action={
                            constants.BACKEND_API_URL +
                            'case/api/upload_case_ipynb'
                          }
                          listType="picture-card"
                          name="case_ipynb"
                          fileList={ipynbFileList}
                          onChange={onIpynbChange}
                          data={uploadIpynbData}
                          showUploadList={false}
                        >
                          {ipynb ? <span>ipynb</span> : uploadIpynbButton}
                        </Upload>
                      </div>
                    </Col>
                  </Row> */}
                  </Modal>
                </ConfigProvider>
              </Col>
            </Row>
            <Tabs defaultActiveKey="2" onChange={onTabKeyChange}>
              <TabPane tab="系统" key="2" className="">
                <div className={idataCourseTableStyles.tableWrapper}>
                  <ConfigProvider locale={zhCN}>
                    <Table
                      dataSource={caseResult}
                      columns={columns}
                      rowKey={(record) => record.case_id}
                      pagination={false}
                      scroll={{ x: 800 }}
                      //bordered={true}
                      onChange={onTableChange}
                    />
                  </ConfigProvider>
                </div>
              </TabPane>
              <TabPane tab="用户" key="1" className="">
                <div className={idataCourseTableStyles.tableWrapper}>
                  <ConfigProvider locale={zhCN}>
                    <Table
                      dataSource={caseResult}
                      columns={columns}
                      rowKey={(record) => record.case_id}
                      pagination={false}
                      scroll={{ x: 800 }}
                      //bordered={true}
                      onChange={onTableChange}
                    />
                  </ConfigProvider>
                </div>
              </TabPane>
            </Tabs>

            <ConfigProvider locale={zhCN}>
              <div
                style={{ marginBottom: 30, paddingTop: 30 }}
                className={'css.pagination'}
              >
                <Pagination
                  defaultCurrent={1}
                  total={resultSum}
                  defaultPageSize={20}
                  current={page.current}
                  hideOnSinglePage={true}
                  onChange={onPageChange}
                  showQuickJumper={true}
                  showTotal={(total) => `共 ${total} 实验`}
                  pageSizeOptions={['3', '20', '30', '50', '100']}
                />
              </div>
            </ConfigProvider>
          </div>
        </Content>
        <CommonModal
          onCancel={() => {
            setIpynbFileUploadModalVis(false)
            setIpynbfileList([])
            setIpynb(null)
          }}
          footer={null}
          modalContent={
            <div className={caseStyles.ModalUpload}>
              <Upload
                beforeUpload={beforeIpynbUpload}
                accept={'*'}
                action={
                  constants.BACKEND_API_URL +
                  // 'case/api/upload_case_ipynb'
                  'case/api/oam_update_case_ipynb'
                }
                listType="picture-card"
                name="case_ipynb"
                fileList={ipynbFileList}
                onChange={onIpynbChange}
                data={uploadIpynbData}
                showUploadList={false}
              >
                {ipynbFileList?.length > 0 ? (
                  <span>{ipynb?.name}</span>
                ) : (
                  uploadIpynbButton
                )}
              </Upload>
            </div>
          }
          open={ipynbFileUploadModalVis}
          modalTitle={'上传ipynb文件'}
        />
      </Layout>
    </Layout>
  )
}

export default OAMCaseList
