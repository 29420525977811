import React, { useEffect, useRef, useState } from 'react'
import { Upload, Modal, Button, message, Row, Col } from 'antd'
import GlobalCss from '@/global.module.less'
import styles from './dataset-upload-file-modal.module.less'
import Cookies from 'js-cookie'
import QS from 'qs'
import axios from 'axios'
import * as constants from '@/constants/constants'
import { CheckCircleFilled } from '@ant-design/icons'
import ModalDrag from '../../../../component/ModaDrag/ModalDrag'
import { sendHttpPostRequest } from '@/api/http-request'
const { Dragger } = Upload

const DatasetUploadFileModal = (props: any) => {
  const {
    actionCallback,
    open,
    mode,
    setOpen,
    uploadType,
    basePath,
    uploadDataProps,
    apiUrl,
  } = props

  const uploadData = Object.assign(uploadDataProps, {
    sessionid: Cookies.get('sessionid'),
    sid: Cookies.get('sessionid'),
    parent_path_id: basePath ? basePath : '',
  })

  const [fileList, setFileList] = useState<any>([])
  const [uploadStatus, setUploadStatus] = useState('')
  const [isUploading, setIsUploading] = useState(false)
  const [uploadPercent, setUploadPercent] = useState(0)
  const [uploadName, setUploadName] = useState('')
  const [makeSureModalVis, setMakeSureModalVis] = useState(false)
  const handleCancel = () => {
    if (fileList?.some((f) => f.status == 'uploading')) {
      setMakeSureModalVis(true)
    } else {
      setFileList([])
      setOpen(false)
      reIndex.current = 0
      result.current = []
      setIsUploading(false)
      actionCallback()
    }
  }
  useEffect(() => {
    return () => {
      setFileList([])
    }
  }, [])

  const reIndex = useRef(0)
  let result = useRef<any>([])

  const handleUploadRequest = (info?: any) => {
    setIsUploading(true)

    if (fileList.filter((f) => f.status == 'uploading').length > 4) {
    } else {
      _request()
    }
    async function _request() {
      if (reIndex.current >= fileList.length) {
        return
      }
      const i = reIndex.current
      reIndex.current++

      Object.assign(fileList[i], { status: 'uploading' })
      setFileList([...fileList])
      try {
        const formData = new FormData()

        formData.append(uploadType, fileList[i])
        Object.keys(uploadData).map((key) => {
          formData.append(key, uploadData[key])
        })

        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          url: constants.BACKEND_API_URL + apiUrl,
          data: formData,
          onUploadProgress: (progressEvent) => {
            fileList[i].percent = Number(
              ((progressEvent.loaded / progressEvent.total) * 100).toFixed(1)
            )
            setFileList([...fileList])
          },
        })
          .then((res) => {
            if (res.data.code == 0) {
              fileList[i].status = 'done'
              fileList[i].path_id = res.data.path_id
              setFileList([...fileList])
            } else {
              fileList[i].status = 'error'
              setFileList([...fileList])
              message.error(res.data.message)
            }
          })
          .catch((err) => {
            fileList[i].status = 'error'
            setFileList([...fileList])
          })
          .finally(() => {})

        result.current[i] = response
      } catch (error) {
        fileList[i].status = 'error'
        setFileList([...fileList])
        result.current[i] = error
      } finally {
        if (result.current.length < fileList.length) {
          _request()
        }
      }
    }
  }

  const uploadProps: any = {
    name: uploadType,
    multiple: true,
    showUploadList: false,
    customRequest: handleUploadRequest,
    // async ({ file, onSuccess, onError, onProgress }) => {
    //   const i = reIndex.current
    //   reIndex.current++
    //   try {
    //     const formData = new FormData()

    //     formData.append('dataset_file', file)
    //     Object.keys(uploadData).map((key) => {
    //       formData.append(key, uploadData[key])
    //     })

    //     // const response = await fetch(
    //     //   import.meta.env.VITE_BASE_API + '/' + apiUrl,
    //     //   {
    //     //     method: 'POST',
    //     //     body: formData,
    //     //   }
    //     // )
    //     // console.log(response)
    //     // result.current[i] = response
    //   } catch (error) {
    //     result.current[i] = error
    //   }
    //   finally{

    //   }
    //   console.log(result.current)
    //   // if (fileList.length < 5) {
    //   //   fileList[i] = file
    //   //   setFileList([...fileList])
    //   //   // handleUpload({ file, onSuccess, onError, onProgress })
    //   // }

    //   // setFileList([...fileList, file])

    //   // return new Promise((resolve, reject) => {
    //   //   let index = 0
    //   //   const result: any[] = []
    //   //   async function _request() {
    //   //     const i = index
    //   //     index++
    //   //     try {
    //   //       const formData = new FormData()
    //   //       formData.append('dataset_file', file)
    //   //       const response = await fetch(
    //   //         import.meta.env.VITE_BASE_API + '/' + apiUrl,
    //   //         Object.assign(uploadData, {
    //   //           dataset_file: formData.get('dataset_file'),
    //   //         })
    //   //       )
    //   //       console.log(response)
    //   //       result[i] = response
    //   //     } catch (error) {
    //   //       result[i] = error
    //   //     }
    //   //     console.log(result);
    //   //   }
    //   //   _request()
    //   //   _request()
    //   //   _request()
    //   // })
    // },
    beforeUpload: (file, FileList) => {
      setFileList([
        ...FileList.map((f) =>
          Object.assign(f, { status: 'waiting', percent: 0 })
        ),
      ])
      // console.log('bfore')
    },
    action: constants.BACKEND_API_URL + apiUrl,
    data: uploadData,
    // action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    // onChange(info) {
    //   // console.log("info",info);
    //   // setFileList(info.fileList.map((f) => ({ ...f, status: 'waiting' })))
    //   console.log(info.file)

    //   // setUploadStatus(
    //   //   info.fileList.some((file) => file.status == 'uploading')
    //   //     ? 'uploading'
    //   //     : 'done'
    //   // )
    //   setIsUploading(info.fileList.some((file) => file.status == 'uploading'))

    //   const { status } = info.file
    //   if (status == 'uploading') {
    //     // console.log(
    //     //   info.fileList.map((f, i) => {
    //     //     if (f.uid == info.file.uid) {
    //     //       f.status = info.file.status
    //     //     }
    //     //     return f
    //     //   })
    //     // )

    //     setFileList(
    //       info.fileList.map((f, i) => {
    //         if (f.uid == info.file.uid) {
    //           f.status = info.file.status
    //         }
    //         return f
    //       })
    //     )
    //     // console.log('uploading', info.file, info.fileList)
    //   }
    //   if (status !== 'uploading') {
    //     // console.log(info.file, info.fileList)
    //   }
    //   if (status === 'done') {
    //     message.success(`${info.file.name} file uploaded successfully.`)
    //   } else if (status === 'error') {
    //     message.error(`${info.file.name} file upload failed.`)
    //   }
    // },
    // onDrop(e) {
    //   console.log('Dropped files', e.dataTransfer.files)
    // },
  }

  const handleDelete = (f, i) => {
    let data = {
      path_id: f.path_id,
    }
    // if (f.status == 'error') {
    //   try {
    //     message.success('删除成功！')
    //     return
    //   } catch (error) {
    //     message.error('删除失败！')
    //     return
    //   }
    // }
    sendHttpPostRequest(
      'dataset/api/user_remove_dataset_file/',
      Object.assign(data, { dataset_id: uploadDataProps?.dataset_id }),
      (res) => {
        if (res.code == 0) {
          message.success('删除成功')
          Object.assign(f, { status: 'deleted' })
          setFileList([...fileList])
        } else {
          message.error(res.message)
        }
      }
    )
    // if (mode == 'oam') {
    //   if (uploadType == 'dataset_file') {
    //     deleteFileOAMDatasetApi(
    //       Object.assign(data, { dataset_id: uploadDataProps?.dataset_id })
    //     ).then((res: any) => {
    //       if (res.code == 0) {
    //         message.success('删除成功')
    //         Object.assign(f, { status: 'deleted' })
    //         setFileList([...fileList])
    //       } else {
    //         message.error(res.message)
    //       }
    //     })
    //   } else if (uploadType == 'experiment_file') {
    //     deleteFileOAMExperimentApi(
    //       Object.assign(data, { experiment_id: uploadDataProps?.experiment_id })
    //     ).then((res: any) => {
    //       if (res.code == 0) {
    //         message.success('删除成功')
    //         Object.assign(f, { status: 'deleted' })
    //         setFileList([...fileList])
    //       } else {
    //         message.error(res.message)
    //       }
    //     })
    //   }
    // } else {
    //   if (uploadType == 'dataset_file') {
    //     deleteFileDatasetApi(
    //       Object.assign(data, { dataset_id: uploadDataProps?.dataset_id })
    //     ).then((res: any) => {
    //       if (res.code == 0) {
    //         message.success('删除成功！')
    //         Object.assign(f, { status: 'deleted' })
    //         setFileList([...fileList])
    //       } else {
    //         message.error(res.message)
    //       }
    //     })
    //   } else if (uploadType == 'experiment_file') {
    //     deleteFileExperimentApi(
    //       Object.assign(data, { experiment_id: uploadDataProps?.experiment_id })
    //     ).then((res: any) => {
    //       if (res.code == 0) {
    //         message.success('删除成功！')
    //         Object.assign(f, { status: 'deleted' })
    //         setFileList([...fileList])
    //       } else {
    //         message.error(res.message)
    //       }
    //     })
    //   }
    // }
  }

  const uploadModalContent = (
    <div className={styles.modalContain}>
      <div
        // style={uploadStatus == 'done' ? { height: '285px' } : {}}
        className={styles.uploadContain}
      >
        <div
          // style={uploadStatus == 'done' ? { height: '285px' } : {}}
          className={styles.progressContain}
          hidden={!isUploading}
        >
          <Row
            align={'middle'}
            justify={'space-between'}
            style={{ height: '40px', borderBottom: '1px solid #eee' }}
          >
            <Col flex={'300px'}>文件名</Col>
            <Col flex={'100px'} style={{ textAlign: 'center' }}>
              状态
            </Col>
            <Col flex={'50px'}>操作</Col>
          </Row>
          <div className={styles.progress}>
            {fileList
              ?.filter((item) => item.status !== 'deleted')
              .map((f, i) => (
                <Row
                  key={f.uid}
                  align={'middle'}
                  justify={'space-between'}
                  style={{ height: '40px', width: '592px' }}
                >
                  <Col flex={'350px'} className={GlobalCss.oneLineTextOverflow}>
                    {f.name}
                    {/* {'  '} {uploadPercent != 100 && uploadPercent + '%'} */}
                  </Col>
                  <Col flex={'100px'} className={GlobalCss.ColFlex}>
                    <div
                      className={
                        f.status == 'done'
                          ? styles.uploadComplete
                          : f.status == 'waiting'
                          ? styles.uploadWaiting
                          : f.status == 'uploading'
                          ? styles.uploading
                          : f.status == 'error'
                          ? styles.uploadError
                          : ''
                      }
                    ></div>
                    <span>
                      {f.status == 'uploading' ? (
                        <span>
                          <span
                            style={{ fontSize: '16px', color: '#4285F4' }}
                            className="iconfont icon_zhengzaitingzhi"
                          ></span>{' '}
                          {'上传' + f.percent + '%'}
                        </span>
                      ) : f.status == 'waiting' ? (
                        '等待中'
                      ) : f.status == 'done' ? (
                        '上传成功'
                      ) : (
                        '上传失败'
                      )}
                    </span>
                  </Col>
                  <Col flex={'50px'} className={GlobalCss.ColFlex}>
                    {f.status !== 'uploading' && (
                      <div
                        id="deleteModalBtn"
                        className={GlobalCss.btnBox + ' ' + styles.deleIcon}
                        onClick={() => handleDelete(f, i)}
                      >
                        <span className={'iconfont icon-shanchu'}></span>
                      </div>
                    )}
                  </Col>
                </Row>
              ))}
            {/* {uploadStatus == 'uploading' && (
              <Progress
                showInfo={false}
                percent={uploadPercent}
                status={
                  uploadStatus == 'uploading'
                    ? 'active'
                    : uploadStatus == 'done'
                    ? 'success'
                    : 'exception'
                }
              />
            )} */}
          </div>
        </div>

        <div hidden={isUploading}>
          <Dragger {...uploadProps}>
            <Button className={GlobalCss.pribtn} type="primary">
              上传
            </Button>
            <h2>将文件拖到此处，或点击上传</h2>
            <span className={styles.tip}>
              单次上传文件数量最大为100个，总大小不超过50GB
            </span>
          </Dragger>
        </div>
      </div>
    </div>
    // <Dragger {...props}>
    //   <Button>选择文件</Button>
    // </Dragger>
  )
  return (
    <div>
      {/* <Button onClick={showModal}>上传文件</Button> */}
      <ModalDrag
        width={720}
        style={{ minHeight: '500px' }}
        bodyStyle={{ minHeight: '400px' }}
        open={open}
        footer={null}
        modalTitle={
          <Row align={'middle'} style={{ gap: '5px' }}>
            <Col className={GlobalCss.modalTitle}>
              <span>添加文件</span>
            </Col>
            <Col>
              {fileList.length > 0 ? (
                fileList?.every((f) => f.status == 'done') ? (
                  <CheckCircleFilled
                    style={{ fontSize: '15px', color: '#4BB04F' }}
                  />
                ) : (
                  <span
                    style={
                      fileList?.some((f) => f.status == 'uploading')
                        ? {
                            fontSize: '15px',
                            color: '#4285F4',
                          }
                        : {
                            fontSize: '15px',
                            color: '#F24957',
                          }
                    }
                    className={
                      'iconfont ' +
                      (fileList?.some((f) => f.status == 'uploading')
                        ? 'icon_zhengzaitingzhi'
                        : fileList?.some((f) => f.status == 'error')
                        ? 'icon_tingzhi-20'
                        : '')
                    }
                  ></span>
                )
              ) : (
                ''
              )}
            </Col>
          </Row>
        }
        maskClosable={false}
        destroyOnClose={true}
        // onOk={handleOk}
        onCancel={handleCancel}
        modalContent={uploadModalContent}
      />
      <ModalDrag
        width={460}
        modalContent={
          <div
            style={{
              margin: '0 auto',
              textAlign: 'center',
              lineHeight: '100px',
            }}
          >
            关闭弹窗后正在运行的任务将会终止，确认关闭？
          </div>
        }
        open={makeSureModalVis}
        centered
        bodyStyle={{ minHeight: '100px' }}
        onCancel={() => setMakeSureModalVis(false)}
        onOk={() => {
          setMakeSureModalVis(false)
          setFileList([])
          setOpen(false)
          reIndex.current = 0
          result.current = []
          setIsUploading(false)
          actionCallback()
          message.success('已停止上传')
          // document.getElementById('deleteModalBtn')?.click()
        }}
        modalTitle={<span className={GlobalCss.modalTitle}>提示</span>}
      />
    </div>
  )
}

export default DatasetUploadFileModal
