import {
  Breadcrumb,
  Button,
  Card,
  Col,
  ConfigProvider,
  Dropdown,
  Empty,
  Input,
  Layout,
  message,
  Pagination,
  Row,
  Table,
  Tabs,
  Tooltip,
} from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { Content } from 'antd/lib/layout/layout'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import css from './case-detail.module.less'
import GlobalCss from '@/global.module.less'
import type { MenuProps } from 'antd'
import zhCN from 'antd/lib/locale/zh_CN'
import SearchInputCom from '@/component/SearchInputCom/SearchInputCom'
import { formatBytesSize } from '@/utils/utils'
import DatasetUploadFileModal from '@/pages/case/components/DatasetUploadFileModal/DatasetUploadFileModal'
import ModalDrag from '@/component/ModaDrag/ModalDrag'
import DatasetUploadZipModal from '@/pages/case/components/DatasetUploadZipModal/DatasetUploadZipModal'
import { sendHttpPostRequest } from '@/api/http-request'
import CopyToClipboard from 'react-copy-to-clipboard'

const items: MenuProps['items'] = [
  {
    key: '1',
    label: <div>添加文件</div>,
  },
  {
    key: '2',
    label: <div>添加文件夹</div>,
  },
]

export const FileList = (props: any) => {
  const { datasetInfo, mode, actionPath, requestType } = props
  const location = useLocation()
  const state: any = location?.state
  const history = useHistory()
  const orderBy = useRef('')
  const order = useRef('')
  const page = useRef(1)
  const pageSize = useRef(10)
  const queryContent = useRef('')
  const [total, setTotal] = useState(0)
  const taskType = state?.type && state?.type == 'task'

  const [addFileModalVis, setAddFileModalVis] = useState(false)
  const [addZipModalVis, setAddZipModalVis] = useState(false)
  const [actionItem, setActionItem] = useState<any>({})
  const [deleteFolderModalVis, setDeleteFolderModalVis] = useState(false)
  const [folderFileList, setFolderFileList] = useState([] as any)
  const [copyText, setCopyText] = useState('')
  const copyRef: any = useRef()

  const filePathCurrent = useRef(
    state?.filePath?.length > 1
      ? state?.filePath
      : [
          {
            title: datasetInfo?.title,
            key: datasetInfo?.id,
          },
        ]
  )
  const [filePath, setFilePath] = useState<any[]>(
    state?.filePath?.length > 1
      ? state.filePath.map((f, i) => ({
          title: datasetInfo?.title,
          key: f.key,
        }))
      : [
          {
            key: datasetInfo?.id,
            title: datasetInfo?.title,
          },
        ]
  )

  useEffect(() => {
    if (state?.filePath?.length > 1) {
      getDatasetFolderInfo({
        path_id: state?.filePath[state?.filePath?.length - 1].key,
      })
    } else {
      getDatasetFolderInfo()
    }
    return () => {}
  }, [datasetInfo])

  // 向文件夹下操作

  const addFolderFileToRoot = (e) => {
    // setActionItem({})
    // 向根目录 添加文件夹
    if (e.key == '2') {
      // folderFileList.push()
      setFolderFileList([
        {
          key: Math.random() * 1000,
          name: '',
          update_time: '',
          type: 1,
          mode: 'create',
        },
        ...folderFileList,
      ])
    } else if (e.key == '1') {
      // 向根目录 添加文件
      setAddFileModalVis(true)
    }
  }

  const handleBreadCrumbClick = (key?: any, type?: any) => {
    console.log(key)

    if (type && type == 2) {
      return
    }

    page.current = 1
    queryContent.current = ''
    if (key) {
      filePath.splice(
        filePath.map((f) => f.key).indexOf(key) + 1,
        filePath?.length - 1
      )

      filePathCurrent.current.splice(
        filePath.map((f) => f.key).indexOf(key) + 1,
        filePath?.length - 1
      )
      setFilePath([...filePath])
    } else {
      filePathCurrent.current = [
        {
          title: datasetInfo?.title,
          key: datasetInfo?.id,
        },
      ]
      setFilePath([
        {
          title: datasetInfo?.title,
          key: datasetInfo?.id,
        },
      ])
    }
    getDatasetFolderInfo(key ? { path_id: key } : null)

    // history.push(actionPath || '/oam_dataset_detail/' + datasetInfo?.id, {
    //   ...state,
    //   filePath: filePathCurrent.current,
    // })
    console.log(filePath, filePathCurrent.current)
  }

  // 查看文件夹文件列表请求
  const getDatasetFolderInfo = (customParams?: any) => {
    let params = {
      dataset_id: datasetInfo?.id || datasetInfo?.dataset_id,
      page: page.current,
      pagesize: pageSize.current,
      order: order.current,
      order_by: orderBy.current,
      query: queryContent.current,
    }
    if (customParams) {
      params = { ...params, ...customParams }
    }
    if (requestType == 'oam') {
      sendHttpPostRequest(
        'dataset/api/oam_query_dataset_folder/',
        params,
        (res) => {
          if (res.code == 0) {
            setFolderFileList(res.query_list)
            setTotal(res.total_sum)
          } else {
            message.error(res.message)
          }
        }
      )
    } else {
      sendHttpPostRequest(
        'dataset/api/user_query_dataset_folder/',
        params,
        (res) => {
          if (res.code == 0) {
            setFolderFileList(res.query_list)
            setTotal(res.total_sum)
          } else {
            message.error(res.message)
          }
        }
      )
    }
  }

  // TODO:查看文件夹
  const handleFolderPreview = (record) => {
    page.current = 1
    queryContent.current = ''
    filePath.push({
      title: record.name,
      key: record.id,
    })
    filePathCurrent.current.push({
      title: record.name,
      key: record.id,
    })
    console.log(filePath, filePathCurrent.current)

    setFilePath([...filePath])
    getDatasetFolderInfo({ path_id: record.id })

    // history.push(actionPath, {
    //   state: { ...state, filePath: filePathCurrent.current },
    // })
  }

  // TODO:文件文件夹命名

  const fileInputHandle = (record) => {
    let data = {
      dataset_id: datasetInfo?.id,
      path_id: record.id,
      new_name: record.name,
    }
    if (record.name.split('.')[0]?.length <= 0) {
      message.error('文件名称不能为空')
      record.mode = 'view'
      getDatasetFolderInfo(
        filePath?.length > 1
          ? { path_id: filePath[filePath.length - 1].key }
          : null
      )
      return
    }
    if (requestType == 'oam') {
      sendHttpPostRequest(
        'dataset/api/oam_rename_dataset_folder/',
        data,
        (res) => {
          if (res.code == 0) {
            getDatasetFolderInfo(
              filePath?.length > 1
                ? { path_id: filePath[filePath?.length - 1].key }
                : null
            )
          } else {
            message.error(res.message)
          }
        }
      )
    } else {
      sendHttpPostRequest(
        'dataset/api/user_rename_dataset_folder/',
        data,
        (res) => {
          if (res.code == 0) {
            getDatasetFolderInfo(
              filePath?.length > 1
                ? { path_id: filePath[filePath?.length - 1].key }
                : null
            )
          } else {
            message.error(res.message)
          }
        }
      )
    }
  }

  const addDatasetFolder = (record) => {
    if (record.name == '') {
      message.error('文件夹名称不能为空')
      record.mode = 'view'
      getDatasetFolderInfo(
        filePath?.length > 1
          ? { path_id: filePath[filePath?.length - 1].key }
          : null
      )
      return
    }
    let data: any
    if (record.type == 1) {
      data = {
        dataset_id: datasetInfo?.id,
      }
      if (filePath?.length > 1) {
        data = { ...data, parent_path_id: filePath[filePath.length - 1].key }
      }

      if (record.mode === 'create') {
        data = {
          ...data,
          name: record.name,
        }

        if (requestType == 'oam') {
          sendHttpPostRequest(
            'dataset/api/oam_add_dataset_folder/',
            data,
            (res) => {
              if (res.code == 0) {
                getDatasetFolderInfo({
                  path_id:
                    filePath?.length > 1
                      ? filePath[filePath?.length - 1].key
                      : null,
                })
              } else {
                message.error(res.message)
              }
            }
          )
        } else {
          sendHttpPostRequest(
            'dataset/api/user_add_dataset_folder/',
            data,
            (res) => {
              if (res.code == 0) {
                getDatasetFolderInfo({
                  path_id:
                    filePath?.length > 1
                      ? filePath[filePath?.length - 1].key
                      : null,
                })
              } else {
                message.error(res.message)
              }
            }
          )
        }
      } else if (record.mode == 'edit') {
        data = {
          ...data,
          new_name: record.name,
          path_id: record.id,
        }

        if (requestType == 'oam') {
          sendHttpPostRequest(
            'dataset/api/oam_rename_dataset_folder/',
            data,
            (res) => {
              if (res.code == 0) {
                getDatasetFolderInfo({
                  path_id:
                    filePath.length > 1
                      ? filePath[filePath.length - 1].key
                      : null,
                })
              } else {
                message.error(res.message)
              }
            }
          )
        } else {
          sendHttpPostRequest(
            'dataset/api/user_rename_dataset_folder/',
            data,
            (res) => {
              if (res.code == 0) {
                getDatasetFolderInfo({
                  path_id:
                    filePath.length > 1
                      ? filePath[filePath.length - 1].key
                      : null,
                })
              } else {
                message.error(res.message)
              }
            }
          )
        }
      }
    }
  }

  const onFolderFileEdit = (record) => {
    // let data
    record.mode = 'edit'
    setActionItem(record)
    if (record?.mode == 'edit') {
      return
    }
    setActionItem(record)
  }

  const onTableChange = (pag, filters, sorter) => {
    if (sorter.order) {
      order.current = sorter.order
      orderBy.current = sorter.field
    } else {
      order.current = ''
      orderBy.current = ''
    }
    getDatasetFolderInfo({
      path_id: filePath?.length > 1 ? filePath[filePath?.length - 1].key : null,
    })
  }

  const deleteFolderOk = () => {
    if (actionItem?.type == 2) {
      let data = {
        dataset_id: datasetInfo?.id,
        name: actionItem.name,
        path_id: actionItem.id,
      }

      sendHttpPostRequest(
        'dataset/api/oam_remove_dataset_file/',
        data,
        (res) => {
          if (res.code == 0) {
            // getDatasetInfo()
            message.success('删除成功~')
            getDatasetFolderInfo({
              path_id:
                filePath?.length > 1
                  ? filePath[filePath?.length - 1].key
                  : null,
            })
            setDeleteFolderModalVis(false)
          }
        }
      )
    } else if (actionItem?.type == 1) {
      let data = {
        dataset_id: datasetInfo?.id,
        name: actionItem.name,
        path_id: actionItem.id,
      }
      sendHttpPostRequest(
        'dataset/api/oam_remove_dataset_folder/',
        data,
        (res) => {
          if (res.code == 0) {
            message.success('删除成功~')
            // getDatasetInfo()
            getDatasetFolderInfo({
              path_id:
                state?.filePath?.length > 1
                  ? state?.filePath[state?.filePath?.length - 1].key
                  : null,
            })
            setDeleteFolderModalVis(false)
          }
        }
      )
    }
  }

  const copyBasePath = (value) => {
    message.success(`复制成功，路径:${value}`)
  }

  const columns: any = [
    {
      title: '文件',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: '40%',
      render: (text, record, index) => (
        // record.type == 1 ?
        <div style={{ marginLeft: '8px', display: 'inline-flex' }}>
          <span
            style={{ color: '#07C160', marginRight: '8px' }}
            className={
              'iconfont ' +
              // (record.type == 1 ? 'icon_wenjianjia' : 'icon_wenjian')
              (record.type == 1 ? 'icon_wenjianjia' : 'icon_wenjian')
            }
          ></span>
          {record?.mode !== 'edit' && record?.mode !== 'create' ? (
            <span
              className={GlobalCss.link + ' ' + GlobalCss.ColFlex}
              onClick={
                record.type == 1
                  ? handleFolderPreview.bind(this, record)
                  : () => false
              }
            >
              <span
                className={GlobalCss.oneLineTextOverflow}
                style={{
                  maxWidth: '360px',
                  display: 'inline-block',
                }}
              >
                {record.name.split('.').length > 1
                  ? record.name
                      .split('.')
                      .slice(0, record.name.split('.').length - 1)
                      .join()
                  : record.name}
              </span>
              {record.name.split('.').length > 1 && (
                <span>
                  {'.' +
                    record.name.split('.')[record.name.split('.')?.length - 1]}
                </span>
              )}
            </span>
          ) : record.type == 2 ? (
            <Row justify={'center'} align={'middle'} wrap={false}>
              <Input
                autoFocus
                value={record.name.split('.')[0]}
                onChange={(e) => {
                  if (record.name.split('.')?.length > 1) {
                    record.name =
                      e.target.value +
                      '.' +
                      record.name.split('.')[record.name.split('.')?.length - 1]
                    setFolderFileList([...folderFileList])
                  } else {
                    record.name = e.target.value
                  }
                }}
                onBlur={fileInputHandle.bind(this, record)}
                onPressEnter={fileInputHandle.bind(this, record)}
              />
              {record.type == 1 && record.name.split('.')?.length <= 1
                ? ''
                : '.' +
                  record.name.split('.')[record.name.split('.')?.length - 1]}
            </Row>
          ) : (
            <Input
              autoFocus
              value={record.name}
              onChange={(e) => {
                record.name = e.target.value
                setFolderFileList([...folderFileList])
              }}
              onBlur={addDatasetFolder.bind(this, record)}
              onPressEnter={addDatasetFolder.bind(this, record)}
            />
          )}
        </div>
      ),
    },
    {
      title: '大小',
      dataIndex: 'size',
      key: 'size',
      align: 'center',
      width: '20%',
      render: (t, r, i) => {
        return t == 0 ? '--' : <span>{formatBytesSize(t - 0)}</span>
      },
    },
    {
      title: '更新时间',
      dataIndex: 'update_time',
      key: 'update_time',
      align: 'left',
      width: '180px',
      sorter: true,
    },
    {
      title: '操作',
      dataIndex: 'action',
      width: '20%',
      align: 'center',
      key: 'action',
      fixed: 'right',
      render: (text, record, index) => {
        return (
          <div
            className={GlobalCss.ColFlex}
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            {record.type == 1 ? (
              <Tooltip title={'预览文件夹'} placement="top">
                <div
                  className={GlobalCss.btnBox}
                  onClick={handleFolderPreview.bind(this, record)}
                >
                  <span
                    style={
                      record?.mode == 'edit' || record?.mode == 'create'
                        ? {
                            color: '#bbbbbb',
                            fontSize: '18px',
                            cursor: 'not-allowed',
                          }
                        : {
                            color: '#07C160',
                            fontSize: '18px',
                          }
                    }
                    className="iconfont icon_xianshibiaoji"
                  ></span>
                </div>
              </Tooltip>
            ) : (
              <Tooltip title={'查看文件'} placement="top">
                <div
                  className={GlobalCss.btnBox}
                  // onClick={handleFilePreview.bind(this, record)}
                >
                  <span
                    style={
                      record?.can_view
                        ? {
                            color: '#07C160',
                            fontSize: '18px',
                          }
                        : {
                            color: '#bbbbbb',
                            fontSize: '18px',
                            cursor: 'not-allowed',
                          }
                    }
                    className="iconfont icon_xianshibiaoji"
                  ></span>
                </div>
              </Tooltip>
            )}

            {!taskType && mode !== 'monted' && (
              <Tooltip placement="top" title={'重命名'}>
                <div
                  className={GlobalCss.btnBox}
                  onClick={onFolderFileEdit.bind(this, record)}
                >
                  <span
                    style={
                      record?.mode == 'edit' || record?.mode == 'create'
                        ? {
                            color: '#bbbbbb',
                            fontSize: '18px',
                            cursor: 'not-allowed',
                          }
                        : { color: '#07C160', fontSize: '18px' }
                    }
                    className={'iconfont icon_zhongmingming'}
                  ></span>
                </div>
              </Tooltip>
            )}
            <Tooltip placement="top" title={'复制数据集路径'}>
              <div
                className={GlobalCss.btnBox}
                onClick={() => {
                  let data = {
                    path_id: record?.id,
                    dataset_id: datasetInfo?.id || datasetInfo?.dataset_id,
                  }
                  sendHttpPostRequest(
                    'dataset/api/user_get_dataset_file_visite_path/',
                    data,
                    (res) => {
                      if (res.code == 0) {
                        setCopyText(res.visited_path)
                        console.log(copyRef.current)
                        copyRef.current?.onClick()
                      }
                    }
                  )
                }}
                // onClick={onFolderFileEdit.bind(this, record)}
              >
                <span
                  style={{ color: '#4285F4', fontSize: '18px' }}
                  className={'iconfont icon_fuzhi'}
                ></span>
                <CopyToClipboard
                  ref={copyRef}
                  text={copyText}
                  onCopy={() => copyBasePath(copyText)}
                >
                  <div></div>
                </CopyToClipboard>
              </div>
            </Tooltip>
            {!taskType && mode !== 'monted' && (
              <Tooltip placement="top" title={'删除'}>
                <div
                  className={GlobalCss.btnBox}
                  onClick={() => {
                    setActionItem(record)
                    setDeleteFolderModalVis(true)
                  }}
                >
                  <span
                    style={
                      record?.mode == 'edit' || record?.mode == 'create'
                        ? {
                            color: '#bbbbbb',
                            fontSize: '18px',
                            cursor: 'not-allowed',
                          }
                        : { color: '#e50314', fontSize: '20px' }
                    }
                    className="iconfont icon_shanchu-20"
                  ></span>
                </div>
              </Tooltip>
            )}
          </div>
        )
      },
    },
    // : {},
  ]

  const deleteFolderModalContent = (
    <div style={{ margin: '0 auto' }}>
      <Row align={'middle'} justify={'center'} style={{ width: '100%' }}>
        <Col>
          确定要删除
          <span className={GlobalCss.hlText}> {actionItem?.name} </span>
          吗？
        </Col>
      </Row>
      {actionItem?.type == 1 && (
        <Row align={'middle'} justify={'center'} style={{ width: '100%' }}>
          <Col>删除后该文件夹中所有的文件将无法恢复。</Col>
        </Row>
      )}
    </div>
  )

  return (
    <div>
      <Row
        align={'middle'}
        justify={'space-between'}
        style={{ minHeight: '22px' }}
      >
        <Col className={css.breadContain}>
          {filePath && filePath?.length > 1 && (
            <Breadcrumb>
              {filePath?.map((file, i) => {
                return (
                  <Breadcrumb.Item
                    className={GlobalCss.link}
                    onClick={() => {
                      if (i == filePath.length - 1) {
                        return
                      } else {
                        handleBreadCrumbClick(i > 0 ? file.key : null)
                      }
                    }}
                    key={file.key}
                  >
                    {file.title}
                  </Breadcrumb.Item>
                )
              })}
            </Breadcrumb>
          )}
        </Col>
        <Col className={GlobalCss.ColFlex} style={{ gap: '10px' }}>
          {taskType && (
            <span
              className={GlobalCss.link + ' ' + GlobalCss.hlText}
              onClick={() =>
                window.open(
                  '/oam_preview_task?task_id=' + datasetInfo?.exercise_id
                )
              }
            >
              {datasetInfo?.exercise_name}
              {'>'}
            </span>
          )}
          <SearchInputCom
            placeholder="请输入文件名称搜索"
            onChange={(e) => {
              queryContent.current = e.target.value
            }}
            onSearch={() => {
              page.current = 1
              getDatasetFolderInfo(
                state?.filePath
                  ? {
                      path_id:
                        state?.filePath?.length > 1
                          ? state?.filePath[state?.filePath?.length - 1].key
                          : null,
                    }
                  : {}
              )
            }}
            width={220}
          />

          {mode !== 'monted' && (
            <Dropdown
              menu={{ items, onClick: addFolderFileToRoot }}
              placement="bottom"
              arrow={{ pointAtCenter: true }}
            >
              <Button className={GlobalCss.defBtn}>添加文件</Button>
            </Dropdown>
          )}
        </Col>
      </Row>
      {filePath?.length <= 1 && folderFileList?.length <= 0 ? (
        <div
          style={{
            width: '100%',
            height: '500px',
            lineHeight: '500px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {queryContent.current == '' && !actionPath ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <span style={{ color: '#999999' }}>
                  暂无数据 ，点击
                  <span
                    onClick={() => setAddZipModalVis(true)}
                    className={GlobalCss.link + ' ' + GlobalCss.hlText}
                  >
                    上传压缩包
                  </span>
                </span>
              }
            />
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <span style={{ color: '#999999' }}>
                  暂无数据
                  {/* ，点击
                  <span
                    onClick={() => setAddZipModalVis(true)}
                    className={GlobalCss.link + ' ' + GlobalCss.hlText}
                  >
                    上传压缩包
                  </span> */}
                </span>
              }
            />
          )}
        </div>
      ) : (
        <div
          className={css.tableTreeWapper}
          style={{ minHeight: '500px', width: '100%' }}
        >
          <ConfigProvider locale={zhCN}>
            <Table
              scroll={{ y: 500, x: 500 }}
              columns={columns}
              dataSource={folderFileList}
              pagination={false}
              onChange={onTableChange}
            />
          </ConfigProvider>
        </div>
      )}
      <div
        className={GlobalCss.pagination}
        style={{ textAlign: 'center', marginTop: '20px' }}
      >
        <ConfigProvider locale={zhCN}>
          <Pagination
            onChange={(nPage, nPageSize) => {
              page.current = nPage
              pageSize.current = nPageSize
              getDatasetFolderInfo({
                path_id:
                  filePath?.length > 1
                    ? filePath[filePath?.length - 1].key
                    : null,
              })
            }}
            pageSize={pageSize.current}
            hideOnSinglePage
            current={page.current}
            total={total}
          />
        </ConfigProvider>
      </div>
      <DatasetUploadFileModal
        uploadType={'dataset_file'}
        open={addFileModalVis}
        // mode={mode}
        setOpen={setAddFileModalVis}
        actionCallback={() =>
          getDatasetFolderInfo({
            path_id:
              filePath?.length > 1 ? filePath[filePath?.length - 1].key : null,
          })
        }
        basePath={
          filePath?.length > 1 ? filePath[filePath?.length - 1].key : null
        }
        apiUrl={
          // mode == 'oam'
          //   ? 'experiment/api/oam_add_experiment_file_by_upload/'
          //   : 'experiment/api/user_add_experiment_file_by_upload/'
          'dataset/api/oam_add_dataset_file_by_upload/'
        }
        uploadDataProps={{
          dataset_id: datasetInfo?.id || datasetInfo?.dataset_id,
        }}
      />

      <ModalDrag
        modalTitle={'删除'}
        modalContent={deleteFolderModalContent}
        open={deleteFolderModalVis}
        onOk={deleteFolderOk}
        onCancel={() => {
          setDeleteFolderModalVis(false)
        }}
        bodyStyle={{
          height: '130px',
          padding: '0px 60px',
          display: 'flex',
          alignItems: 'center',
        }}
        width={460}
      />
      <DatasetUploadZipModal
        uploadType={'dataset_zip'}
        open={addZipModalVis}
        setOpen={setAddZipModalVis}
        actionCallback={() =>
          getDatasetFolderInfo({
            path_id:
              filePath?.length > 1 ? filePath[filePath?.length - 1].key : null,
          })
        }
        apiUrl={'dataset/api/oam_upload_dataset_zip/'}
        uploadDataProps={{ dataset_id: datasetInfo?.id }}
      />
    </div>
  )
}
