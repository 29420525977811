import Cookies from "js-cookie";
import { sendHttpPostRequest } from "../../../api/http-request";

export const getRangeDate = ( range: number, type?: string ) => {

    const formatDate = ( time: any ) => {
        // 格式化日期，获取今天的日期
        const Dates = new Date( time );
        const year: number = Dates.getFullYear();
        const month: any = ( Dates.getMonth() + 1 ) < 10 ? '0' + ( Dates.getMonth() + 1 ) : ( Dates.getMonth() + 1 );
        const day: any = Dates.getDate() < 10 ? '0' + Dates.getDate() : Dates.getDate();
        return year + '-' + month + '-' + day;
    };

    const now = formatDate( new Date().getTime() ); // 当前时间
    const resultArr: Array<any> = [];
    let changeDate: string;

    if ( type ) {
        if ( type === 'one' ) {
            changeDate = formatDate( new Date().getTime() + ( 1000 * 3600 * 24 * range ) );
            return changeDate;
        }
        if ( type === 'more' ) {
            if ( range < 0 ) {
                for ( let i = Math.abs( range ); i >= 0; i-- ) {
                    resultArr.push( formatDate( new Date().getTime() + ( -1000 * 3600 * 24 * i ) ) );
                }
            } else {
                for ( let i = 1; i <= range; i++ ) {
                    resultArr.push( formatDate( new Date().getTime() + ( 1000 * 3600 * 24 * i ) ) );

                }
            }

            return resultArr;

        }
    } else {
        changeDate = formatDate( new Date().getTime() + ( 1000 * 3600 * 24 * range ) );
        return changeDate;

    }

}




export const getChapterLabels = (chapterIndex:number)=>
{
    let chapterInd = chapterIndex + 1
    let chnNum = ["零","一","二","三","四","五","六","七","八","九"];
    let chnNumUnit = ["","十","百","千"];
    let tmp = "";
    let chnString = "";
    let zero = true;
    let unitIndex = 0;
    let isTen = false;
    if(chapterInd > 9 && chapterInd < 20)
    {
        isTen = true;
    }
    while(chapterInd > 0)
    {
        let num = chapterInd % 10;
        if(num === 0)
        {
            if(!zero)
            {
                zero = true;
                chnString = chnNum[num] + chnString;
            }
        }
        else
        {
            zero = false;
            if(isTen && unitIndex == 1)
            {
                tmp = "";
            }
            else
            {
                tmp = chnNum[num];
            }
            tmp += chnNumUnit[unitIndex];
            chnString = tmp + chnString;
        }
        unitIndex++;
        chapterInd = Math.floor(chapterInd / 10);
    }
    return chnString;
}



    /**
     * 增加知识点学习数量
     */
    export const increaseModuleViewCount = (moduleId) => {
        sendHttpPostRequest(
            'course/api/increase_knowcell_view_count/',
            {
                sessionid: Cookies.get("sessionid"),    
                know_id: moduleId,

            },
            (response: any) => {
                // console.log(response)
                //message.destroy();
                if (response.code == -100) {
                    // 未登录
                    //window.location.href = '/login?next=lecture';
                } else if (response.is_ok == true){

                } else if (response.code == -501) {
                    // 权限不足
                    //window.location.href = '/login?next=lecture';
                } else {
                    //message.error(response.message);
                }
            }
        )
    }




export const download = (res, type, filename) => {
  // 创建blob对象，解析流数据
  const blob = new Blob([res], {
    // 如何后端没返回下载文件类型，则需要手动设置：type: 'application/pdf;chartset=UTF-8' 表示下载文档为pdf，如果是word则设置为msword，excel为excel
    type: type
  })
  console.log(blob);
  
  const a = document.createElement('a')
  // 兼容webkix浏览器，处理webkit浏览器中href自动添加blob前缀，默认在浏览器打开而不是下载
  const URL = window.URL || window.webkitURL
  // 根据解析后的blob对象创建URL 对象
  const herf = URL.createObjectURL(blob)
  // 下载链接
  a.href = herf
  // 下载文件名,如果后端没有返回，可以自己写a.download = '文件.pdf'
  a.download = filename
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  // 在内存中移除URL 对象
  window.URL.revokeObjectURL(herf)
}


