import exerciseCardWhiteStyles from './exercise-card-white.module.less'
import exerciseCardDarkStyles from './exercise-card-dark.module.less'

import {
  Image,
  message,
  Button,
  Card,
  Col,
  ConfigProvider,
  Layout,
  Pagination,
  Row,
  Tabs,
  Table,
  Popconfirm,
  Empty,
} from 'antd'

import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import {
  UpOutlined,
  DownOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons'
import { PageHeader } from '../common/page-header/header'
import React, { useEffect, useRef, useState } from 'react'
import PageFooter from '../common/page-footer/footer'
import Search from 'antd/lib/input/Search'
import zhCN from 'antd/lib/locale/zh_CN'
import { Tag } from 'antd'
import Cookies, { set } from 'js-cookie'
import { Checkbox } from 'antd'
import gfm from 'remark-gfm'
import remarkMath from 'remark-math'

import idataCourseTableDarkStyles from '../common/global-styles/idatacourse-table-dark.module.less'
import idataCourseTableWhiteStyles from '../common/global-styles/idatacourse-table.module.less'

import * as constants from '../../constants/constants'

import ReactMarkdown from 'react-markdown'
import rehypeKatex from 'rehype-katex'
import 'katex/dist/katex.min.css' // `rehype-katex` does not import the CSS for you

import rehypeRaw from 'rehype-raw'

import MonacoEditor from 'react-monaco-editor'
// import CodeMirror from 'react-codemirror2';
import { UnControlled as CodeMirror } from 'react-codemirror2'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/material-darker.css' // dark 模式
import 'codemirror/theme/xq-light.css' // light 模式

import 'codemirror/mode/python/python' // 代码提示
import 'codemirror/addon/hint/show-hint.css'
import 'codemirror/addon/hint/show-hint'

// import 'codemirror/addon/hint/python-hint';
import 'codemirror/addon/selection/active-line' // 选中行高亮
import 'codemirror/addon/selection/selection-pointer'
import 'codemirror/addon/selection/mark-selection'
import 'codemirror/addon/search/matchesonscrollbar.css'
import 'codemirror/addon/search/searchcursor'
import 'codemirror/addon/search/match-highlighter'

import 'codemirror/addon/comment/comment' // 支持ctrl(cmd) + / 快速(取消)注释
import 'codemirror/keymap/sublime' // sublime关键字

import 'codemirror/addon/edit/closebrackets' // 封闭括号
import 'codemirror/addon/edit/matchbrackets' // 匹配括号
// require('codemirror/mode/python/python');

import './exercise.css'
import clean1 from '../../static/image/exercise/clean1.png'
import exerciseStyles from '@/pages/exercise/exercise.module.less'

//import rehypeKatex from 'rehype-katex';
//import 'katex/dist/katex.min.css'; // `rehype-katex` does not import the CSS for you
//import remarkMath from 'remark-math';

const CheckboxGroup = Checkbox.Group
import * as monaco from 'monaco-editor'
import rehypeHighlight from 'rehype-highlight'
import Meta from 'antd/lib/card/Meta'
import { sendHttpPostRequest } from '../../api/http-request'
import { checkIsTeacher } from '../../component/modal/teacher-authenticate/teacher-authenticate'
const { CheckableTag } = Tag

const { Content } = Layout
const { TabPane } = Tabs

export interface Props {
  exercise: any
  renderType: string //渲染的主题类型，"dark","white"
  teacherOnly: boolean // 仅老师
  editorMode: boolean //是否可编辑模式，
  showAnser: boolean //是否默认显示答案
  showClean: boolean //是否显示清除按钮
}

/* 数据实操题 */
export const ExerciseCardDataCoding: React.FC<Props> = (props) => {
  const {
    exercise,
    renderType,
    teacherOnly,
    editorMode,
    showAnser,
    showClean,
  } = props
  // console.log(parseInt(exercise?.submission_info?.submission_id), exercise?.submission_info?.submission_code, exercise?.default_code);
  const defaultCode = exercise.default_code

  /** 调试切换相同题目  代码区默认展示内容 **/
  // const [userCode, setUserCode] = useState("");
  // const [userCode, setUserCode] = useState(parseInt(exercise?.submission_info?.submission_id) > 0 ? exercise?.submission_info?.submission_code : exercise?.default_code);
  const userCode = useRef(
    parseInt(exercise?.submission_info?.submission_id) > 0
      ? exercise?.submission_info?.submission_code
      : exercise?.default_code
  )
  // const userCode = useRef('');   // 调试切换相同题目 代码区默认展示内容
  const cachedExerciseId = useRef(exercise?.id)

  const [answervisible, setAnswerVisible] = useState(showAnser) //参考答案是否可见
  // const [cleanVisible, setCleanVisible] = useState(showClean)//清除按钮是否可见

  const [running, setRunning] = useState(false) // 运行中状态

  const [currentStatus, setCurrentStatus] = useState(null as any)

  const [outputs, setOutputs] = useState(null as any) //运行的输出结果
  const [isHtml, setIsHtml] = useState(false)
  const [variables, setVariables] = useState(null as any) //运行的输出变量列表
  const [figures, setFigures] = useState(null as any) // 运行输出的图
  const [activeKey, setActiveKey] = useState('') // 默认激活的tab页

  const exerciseCardStyles =
    renderType == 'white' ? exerciseCardWhiteStyles : exerciseCardDarkStyles
  const [isReload, setIsReload] = useState(false) // 每次点击重新渲染
  const [values, setValues] = useState(
    userCode.current && cachedExerciseId.current == exercise?.id
      ? userCode.current
      : parseInt(exercise?.submission_info?.submission_id) > 0
      ? exercise?.submission_info?.submission_code
      : exercise?.default_code
  )

  const editorOptions = {
    selectOnLineNumbers: true,
    fontSize: 14,
    minimap: { enabled: false },
    lineDecorationsWidth: 5,
    lineNumbersMinChars: 2,
    quickSuggestions: false,
    // scrollBeyondLastLine: false,  // 控制是否可以滚动到最后一行下面
    // scrollbar: {
    //     verticalScrollbarSize: 0,  // 垂直滚动条宽度
    //     horizontalScrollbarSize: 0,  // 水平滚动条宽度
    // },
    automaticLayout: true, // 拖拽分屏线时自适应宽度
  }

  const editorReadOnlyoptions = {
    readOnly: true,
    selectOnLineNumbers: true,
    fontSize: 14,
    minimap: { enabled: false },
    lineDecorationsWidth: 5,
    lineNumbersMinChars: 2,
    quickSuggestions: false,
    // scrollBeyondLastLine: false,  // 控制是否可以滚动到最后一行下面
    // scrollbar: {
    //     verticalScrollbarSize: 0,  // 垂直滚动条宽度
    //     horizontalScrollbarSize: 0,  // 水平滚动条宽度
    // },
    automaticLayout: true, // 拖拽分屏线时自适应宽度
  }

  const columns: any = [
    {
      title: '变量',
      dataIndex: 'var',
      key: 'var',
      align: 'left',
      width: 60,
    },
    {
      title: '类型',
      dataIndex: 'type',
      key: 'type',
      ellipsis: true,
      align: 'left',
      width: 60,
    },

    {
      title: '取值',
      dataIndex: 'value',
      key: 'value',
      align: 'left',
      width: 100,
      render: (text: any, record: any) =>
        text?.length > 50 ? text.slice(0, 50) : text,
    },
  ]
  useEffect(() => {
    if (cachedExerciseId.current != exercise?.id) {
      userCode.current = ''
      exercise.reference_answer = ''
      setValues('')
    }

    //setAnswerVisible(false);
  }, [])

  const runCodeCallback = (response: any) => {
    message.destroy()
    if (response.code == -100) {
      // 未登录
      document.getElementById('show-login-modal')!.click()
      // window.location.href = `/oam_login?next=oam_preview_exercise?exercise_id=${exercise.id}`;
    } else if (response.code == 0) {
      // 成功
      // TODO: 答题卡片渲染执行结果，不在这里弹提示
      // TODO: 填空题有的输出图片，需要在卡片渲染出来
      if (response.data.status == true) {
        if (response.data.is_html) {
          setOutputs(response.data.output)
          setIsHtml(true)
          setFigures('')
          const chartId = response.data.js_script.substr(
            response.data.js_script.indexOf("document.getElementById('") + 25,
            32
          )
          console.log(chartId)
          let checkResult = setInterval(function () {
            // console.log(document.getElementById(chartId))
            if (document.getElementById(chartId) != null) {
              try {
                setTimeout(function () {
                  eval(response.data.js_script)
                }, 1000)
                clearInterval(checkResult)
              } catch (e) {
                console.error(e)
                clearInterval(checkResult)
                try {
                  setTimeout(function () {
                    eval(response.data.js_script)
                  }, 1000)
                } catch (e) {
                  console.error(e)
                }
              }
            }
          }, 1500)
          // while (document.getElementById(chartId) != null) {
          //     try {
          //         setTimeout(function () {
          //             eval(response.data.js_script)
          //         }, 1000)
          //     } catch (e) {
          //         console.error(e)
          //         break
          //     }
          // }
          // try {
          //     setTimeout(function () {
          //         eval(response.data.js_script);
          //     }, 1000)
          // } catch (e) {
          //     console.error(e)
          //     setTimeout(function () {
          //         eval(response.data.js_script);
          //     }, 1500)
          // }
        } else {
          //message.success(`回答正确${response.data.output}`);
          let output = response.data.output.trimLeft().replaceAll('\n', '<br/>')
          setOutputs(output)
          setFigures(response.data.images)
        }
        setVariables(response.data.variables)
        setCurrentStatus(true)
        response.data.images.length > 0 || response.data.is_html
          ? setActiveKey('figures')
          : setActiveKey('outputs')
      } else {
        setOutputs(response.data.output)
        setVariables(response.data.variables)
        setCurrentStatus(false)
        setFigures(response.data.images)
        response.data.images.length > 0
          ? setActiveKey('figures')
          : setActiveKey('outputs')
      }
    } else if (response.code == -501) {
      // 权限不足
      document.getElementById('show-login-modal')!.click()
      // window.location.href = `/oam_login?next=oam_preview_exercise?exercise_id=${exercise.id}`;
    } else {
      message.error(response.message)
    }
    setRunning(false)
  }
  const [values2, setValues2] = useState('') // 用来保存提交的值

  //点击了提交按钮
  const exerciseSubmitBtnClick = () => {
    //if (teacherOnly && !checkIsTeacher()) {
    //document.getElementById("show-teacher-authenticate")!.click();
    //} else {
    setValues2(values)
    if (userCode.current == '') {
      userCode.current =
        parseInt(exercise?.submission_info?.submission_id) > 0
          ? exercise?.submission_info?.submission_code
          : exercise?.default_code
    }
    let _params = {
      sessionid: Cookies.get('sessionid'),
      exercise_id: exercise.id,
      code: userCode.current,
      source_page: exercise.source_page,
      source_key: exercise.source_key,
      course_id: exercise.course_id,
      chapter_id: exercise.chapter_id,
      knowcell_id: exercise.knowcell_id,
    }
    if ([3, 4].includes(exercise.source_page)) {
      // 作业、考试
      if (userCode.current != '' && userCode.current != exercise.default_code) {
        setCurrentStatus(null as any)
        setRunning(true)

        if (exercise.exam_id) {
          _params['exam_id'] = exercise.exam_id // 考试传考试id
        } else if (exercise.homework_id) {
          _params['homework_id'] = exercise.homework_id // 作业传作业id
        }
        sendHttpPostRequest(
          'exercise/api/exercise_run/',
          _params,
          runCodeCallback
        )
      } else {
        // console.log("实操题没输入答案")
      }
    } else {
      setCurrentStatus(null as any)
      setRunning(true)

      sendHttpPostRequest(
        'exercise/api/exercise_run/',
        _params,
        runCodeCallback
      )
    }
    //}
  }

  // 看答案回调
  const queryExerciseAnswerCallback = (response: any) => {
    message.destroy()
    if (response.code == -100) {
      // 未登录
      document.getElementById('show-login-modal')!.click()
    } else if (response.code == 0) {
      // 成功
      if (response.exercise_answer) {
        exercise.reference_answer = response.exercise_answer.reference_answer
        setAnswerVisible(true)
      }
    } else if (response.code == -501) {
      // 权限不足
      // document.getElementById("show-login-modal")!.click();
    } else {
      message.error(response.message)
    }
  }

  //点击了参考答案按钮
  const exerciseAnswerBtnClick = () => {
    if (exercise.reference_answer.length == 0) {
      // 查询答案
      // if (0 == exercise.reference_answer.length) {
      sendHttpPostRequest(
        'exercise/api/query_exercise_answer/',
        {
          sessionid: Cookies.get('sessionid'),
          sid: Cookies.get('sessionid'),
          exercise_id: exercise.id,
        },
        queryExerciseAnswerCallback
      )
      // }
    } else {
      setAnswerVisible(!answervisible)
    }
  }

  // 渲染 查看答案按钮
  const renderAnswerButton = () => {
    if (!answervisible) {
      if (exercise.reference_answer.length == 0) {
        if (![3, 4].includes(exercise.source_page)) {
          return (
            // <Popconfirm
            //   placement="bottom"
            //   title={'您确定查看答案吗？'}
            //   onCancel={(e: any) => {
            //     e.stopPropagation()
            //   }}
            //   onConfirm={(e: any) => {
            //     e.stopPropagation()
            //     exerciseAnswerBtnClick()
            //   }}
            //   okText="确认"
            //   cancelText="取消"
            // >
            <Button
              onClick={(e) => {
                e.stopPropagation()
                exerciseAnswerBtnClick()
              }}
              type="link"
              style={{ width: 80, marginRight: 10, borderRadius: 20 }}
            >
              <span>参考代码</span>
              <DownOutlined />{' '}
            </Button>
            // </Popconfirm>
          )
        }
      } else {
        if (![3, 4].includes(exercise.source_page)) {
          return (
            <Button
              onClick={exerciseAnswerBtnClick}
              type="link"
              style={{ width: 80, marginRight: 10, borderRadius: 20 }}
            >
              {' '}
              <span>参考代码</span>
              <DownOutlined />
            </Button>
          )
        }
      }
    } else {
      if (![3, 4].includes(exercise.source_page)) {
        return (
          <Button
            onClick={exerciseAnswerBtnClick}
            type="link"
            style={{ width: 80, marginRight: 10, borderRadius: 20 }}
          >
            <span>折叠收起</span>
            {!answervisible ? <DownOutlined /> : <UpOutlined />}
          </Button>
        )
      }
    }
  }

  //监听窗口事件
  useEffect(() => {
    const listener = (ev) => {
      if (userCode.current != values) {
        ev.returnValue = false
      }
    }
    window.addEventListener('beforeunload', listener)

    return () => {
      window.removeEventListener('beforeunload', listener)
    }
  }, [values2]) // 当values2的值改变时,重新进行监听

  const cleanAll = () => {
    message.destroy()
    userCode.current = exercise?.default_code
    setValues(exercise?.default_code)
    setIsReload(!isReload)
    message.success('清空成功')
  }

  //用户编辑代码发生改变
  const onUserCodeChange = (newValue: any) => {
    // e.preventDefault();
    console.log(newValue)
    setCurrentStatus(null as any)
    userCode.current = newValue
    setValues(newValue)
    if (cachedExerciseId.current != exercise?.id)
      cachedExerciseId.current = exercise?.id
    // console.log(cachedExerciseId.current, exercise?.id)
    // setUserCode(newValue)
  }

  const editorDidMount = (editor, monaco) => {
    console.log('editorDidMount', editor)
    //editor.focus();
  }

  const btnTrueBackgroundColor = (renderType: string) => {
    return renderType == 'white' ? '#fff' : '#07c160'
  }

  const btnTrueColor = (renderType: string) => {
    return renderType == 'white' ? '#07c160' : '#fff'
  }

  const btnBackgroundColor = (renderType: string) => {
    return renderType == 'white' ? '#fff' : '#ff4b50'
  }

  const btnColor = (renderType: string) => {
    return renderType == 'white' ? '#ff4b50' : '#fff'
  }

  const getRunnigStatusBtn = () => {
    // if (currentStatus == true) {
    //   return (
    //     <Button
    //       hidden={exercise?.hint_submit_btn == 2}
    //       id={'exercise-run-' + exercise.id}
    //       onClick={exerciseSubmitBtnClick}
    //       type="default"
    //       style={{
    //         paddingLeft: 5,
    //         borderRadius: 3,
    //         width: '75px',
    //         color: btnTrueColor(renderType),
    //         borderColor: '#07c160',
    //         backgroundColor: btnTrueBackgroundColor(renderType),
    //       }}
    //       className="exercise-run-button"
    //     >
    //       {' '}
    //       <CheckOutlined />
    //       {[3, 4].includes(exercise.source_page) ? '已提交' : ' 正 确 '}
    //     </Button>
    //   )
    // } else if (currentStatus == false) {
    //   return (
    //     <Button
    //       hidden={exercise?.hint_submit_btn == 2}
    //       id={'exercise-run-' + exercise.id}
    //       onClick={exerciseSubmitBtnClick}
    //       danger
    //       type="default"
    //       style={{
    //         paddingLeft: 5,
    //         color: btnColor(renderType),
    //         borderRadius: 3,
    //         width: '75px',
    //         backgroundColor: btnBackgroundColor(renderType),
    //       }}
    //       className="exercise-run-button"
    //     >
    //       {' '}
    //       <CloseOutlined />错 误
    //     </Button>
    //   )
    // } else {
    return (
      <Button
        disabled={running}
        hidden={exercise?.hint_submit_btn == 2}
        id={'exercise-run-' + exercise.id}
        onClick={exerciseSubmitBtnClick}
        type="primary"
        style={{ borderRadius: 3, width: '75px' }}
        className="exercise-run-button"
      >
        {[3, 4].includes(exercise.source_page) ? ' 提 交 ' : ' 测 试 '}
      </Button>
    )
    // }
  }

  // 追加页面上输入的词到代码提示
  const handleShowHint = (cmInstance, hintOptions) => {
    // console.log(cmInstance,hintOptions)
    let cursor = cmInstance.getCursor()
    let token = cmInstance.getTokenAt(cursor)
    let WORD = /[\w$]+/
    let RANGE = 500
    let word = (hintOptions && hintOptions.word) || WORD
    let range = (hintOptions && hintOptions.range) || RANGE
    let curLine = cmInstance.getLine(cursor.line)
    let end = cursor.ch,
      start = end
    while (start && word.test(curLine.charAt(start - 1))) --start
    let curWord = start != end && curLine.slice(start, end)

    let list = (hintOptions && hintOptions.list) || [],
      seen = {}
    let re = new RegExp(word.source, 'g')

    if (hintOptions && hintOptions.basicKeywords) {
      for (let idx = 0; idx < hintOptions.basicKeywords.length; idx += 1) {
        if (hintOptions.basicKeywords[idx].includes(curWord)) {
          list.push(hintOptions.basicKeywords[idx])
        }
      }
    }

    for (let dir = -1; dir <= 1; dir += 2) {
      let line = cursor.line,
        endLine =
          Math.min(
            Math.max(line + dir * range, cmInstance.firstLine()),
            cmInstance.lastLine()
          ) + dir
      for (; line != endLine; line += dir) {
        let text = cmInstance.getLine(line),
          m
        while ((m = re.exec(text))) {
          if (line == cursor.line && m[0] === curWord) continue
          if (
            (!curWord || m[0].lastIndexOf(curWord, 0) == 0) &&
            !Object.prototype.hasOwnProperty.call(seen, m[0])
          ) {
            if (!list.includes(m[0]) && !m[0].includes('___') && isNaN(m[0])) {
              seen[m[0]] = true
              list.push(m[0])
            }
          }
        }
      }
    }

    return {
      list: list,
      from: { ch: token.start, line: cursor.line },
      to: { ch: token.end, line: cursor.line },
    }
  }

  /** 渲染查看变量取值的习题 */
  const renderVariablePreview = (record: any) => {
    return <div>{record.value}</div>
  }

  return (
    <div
      style={{ marginBottom: 20, textAlign: 'left' }}
      className={exerciseCardStyles.wrapper}
    >
      <div style={{ marginBottom: 10, fontSize: 14 }}>{'【实操】'}</div>

      <div>
        <ReactMarkdown
          className={exerciseCardStyles.reactmarkdownTitle}
          remarkPlugins={[gfm, remarkMath]}
          rehypePlugins={[rehypeHighlight, rehypeKatex]}
        >
          {exercise.steps}
        </ReactMarkdown>

        <div
          className={exerciseCardDarkStyles.imgContainer}
          style={{ marginTop: 10, marginBottom: 10 }}
        >
          <ConfigProvider locale={zhCN}>
            <Row justify="center" align="middle" gutter={[10, 10]}>
              {exercise?.images?.map((image: any) => {
                return (
                  <Col key={image.name}>
                    <Image
                      width={360}
                      height={270}
                      preview={false}
                      src={constants.BACKEND_URL + image.path + image.name}
                    />
                  </Col>
                )
              })}
            </Row>
          </ConfigProvider>
        </div>
      </div>

      <div style={{ textAlign: 'right', marginBottom: 0 }}>
        {renderAnswerButton()}
      </div>
      <Row align="top" gutter={[8, 8]}>
        <Col span={answervisible == true ? 12 : 24}>
          <div
            className={exerciseCardStyles.userCode}
            style={{ height: 400, marginBottom: 10 }}
          >
            {/*<MonacoEditor*/}
            {/*    width="100%"*/}
            {/*    language="python"*/}
            {/*    theme={renderType == "white" ? "vs": "vs-dark"} // | "vs-dark" | "hc-black"*/}
            {/*    value={userCode.current}*/}
            {/*    options={editorMode ==true ? editorOptions : editorReadOnlyoptions}*/}
            {/*    onChange={onUserCodeChange}*/}
            {/*    editorDidMount={editorDidMount}*/}
            {/*/>*/}
            {/* <CodeMirror
              // onChange={onUserCodeChange}
              value={userCode.current}
              options={{
                mode: {
                  // 代码模式
                  name: 'python',
                  version: 3,
                  singleLineStringErrors: false,
                  // globalVars: true,
                  // extra_keywords: ["nonlocal", "False", "True", "None", "async", "await"],
                  // extra_builtins: ["ascii", "bytes", "exec", "print"],
                },
                readOnly: editorMode == true ? false : true, // 模式
                extraKeys: {
                  //配置按键
                  Alt: 'autocomplete', // 按下`alt`时出现代码提示
                },
                lineWrapping: false, // 自动换行
                styleActiveLine: true, // 选中行高亮
                matchBrackets: true, // 匹配括号
                selectionPointer: true,
                styleSelectedText: true,
                autoCloseBrackets: true, //键入时将自动关闭()[]{}''""

                smartIndent: true, // 自动缩进
                autofocus: true, // 自动获取焦点
                gutters: ['CodeMirror-lint-markers'],
                lint: true, // 代码出错提醒
                indentUnit: 4, // 缩进

                theme: renderType == 'white' ? 'xq-light' : 'material-darker', // 显示模式
                lineNumbers: true, // 显示行号

                highlightSelectionMatches: {
                  // 和选中词相同的都高亮
                  trim: true,
                  style: 'matchhighlight',
                  showToken: false,
                },
                hintOptions: {
                  // 代码提示选项
                  hint: handleShowHint.bind(this), // 自己写的方法
                  completeSingle: false, // 按空格不自动补全
                  basicKeywords: [
                    'abs',
                    'all',
                    'any',
                    'ascii',
                    'bin',
                    'bool',
                    'bytearray',
                    'bytes',
                    'callable',
                    'chr',
                    'classmethod',
                    'compile',
                    'complex',
                    'delattr',
                    'dict',
                    'dir',
                    'divmod',
                    'enumerate',
                    'eval',
                    'filter',
                    'float',
                    'format',
                    'frozenset',
                    'getattr',
                    'globals',
                    'hasattr',
                    'hash',
                    'help',
                    'hex',
                    'id',
                    'input',
                    'int',
                    'isinstance',
                    'issubclass',
                    'iter',
                    'len',
                    'list',
                    'locals',
                    'map',
                    'max',
                    'memoryview',
                    'min',
                    'next',
                    'object',
                    'oct',
                    'open',
                    'ord',
                    'pow',
                    'print',
                    'property',
                    'range',
                    'repr',
                    'reversed',
                    'round',
                    'set',
                    'setattr',
                    'slice',
                    'sorted',
                    'staticmethod',
                    'str',
                    'sum',
                    'super',
                    'tuple',
                    'type',
                    'vars',
                    'zip',
                    'NotImplemented',
                    'Ellipsis',
                    'as',
                    'assert',
                    'break',
                    'class',
                    'continue',
                    'def',
                    'del',
                    'elif',
                    'else',
                    'except',
                    'finally',
                    'for',
                    'from',
                    'global',
                    'if',
                    'import',
                    'lambda',
                    'pass',
                    'raise',
                    'return',
                    'try',
                    'while',
                    'with',
                    'yield',
                    'in',
                    'and',
                    'or',
                    'not',
                    'is',
                    'nonlocal',
                    'False',
                    'True',
                    'None',
                    'async',
                    'await',
                    'exec',
                    '__import__',
                    '__debug__',
                  ],
                },
                keyMap: 'sublime', // 代码编辑模式
              }}
              // onBeforeChange={(editor, data, value) => {
              //     console.log(value);
              //     userCode.current = value;
              // }}
              onKeyPress={(editor, event) => {
                // console.log(event, event.keyCode);
                editorMode == true && event.keyCode != 46
                  ? editor.showHint()
                  : ''
              }}
              onChange={(editor, data, value) => {
                // console.log('controlled', value);
                userCode.current = value != '' ? value : ' '
                // console.log(cachedExerciseId.current, exercise?.id);
                if (cachedExerciseId.current != exercise?.id)
                  cachedExerciseId.current = exercise?.id
                // onUserCodeChange.bind(this, value);
              }}
            /> */}
            <div
              style={{ textAlign: 'left' }}
              className={renderType == "dark" ? "" :'oam-exercise-code-editor'}
            >
              <MonacoEditor
                className={exerciseStyles.codeEditor}
                height="400px"
                width="100%"
                language={'python'}
                theme={renderType == 'white' ? 'vs' : 'vs-dark'} //"vs" | "vs-dark" | "hc-black"
                value={userCode.current}
                options={editorOptions}
                onChange={(value) => {
                  // console.log('controlled', value);
                  userCode.current = value != '' ? value : ' '
                  // console.log(cachedExerciseId.current, exercise?.id);
                  if (cachedExerciseId.current != exercise?.id)
                    cachedExerciseId.current = exercise?.id
                  // onUserCodeChange.bind(this, value);
                }}
                //editorDidMount={editorDidMount}
              />
            </div>
          </div>
        </Col>

        {answervisible == true && (
          <Col span={12}>
            <div
              className={exerciseCardStyles.userCode}
              style={{ height: 400, marginBottom: 10 }}
            >
              {/*<MonacoEditor */}
              {/*    width="100%"*/}
              {/*    language="python"*/}
              {/*    theme={renderType == "white" ? "vs": "vs-dark"} // | "vs-dark" | "hc-black"*/}
              {/*    value={exercise.reference_answer}*/}
              {/*    options={editorReadOnlyoptions}*/}
              {/*    //onChange={onUserCodeChange}*/}
              {/*    //editorDidMount={editorDidMount}*/}
              {/*/> */}
              {/* <CodeMirror
                // onChange={onUserCodeChange}
                value={exercise.reference_answer}
                options={{
                  mode: 'python',
                  readOnly: true,
                  lineWrapping: false, // 自动换行
                  styleActiveLine: true, // 选中行高亮
                  matchBrackets: true, // 匹配括号
                  smartIndent: true, //自动缩进
                  autofocus: true, //自动获取焦点
                  gutters: ['CodeMirror-lint-markers'],
                  lint: true, // 代码出错提醒
                  indentUnit: 4,
                  autoCloseBrackets: true, //键入时将自动关闭()[]{}''""
                  theme: renderType == 'white' ? 'xq-light' : 'material-darker',
                  lineNumbers: true,
                  highlightSelectionMatches: {
                    // 和选中词相同的都高亮
                    trim: true,
                    style: 'matchhighlight',
                    showToken: false,
                  },
                }}
              /> */}
              <div
                style={{ textAlign: 'left' }}
                className={renderType == "dark" ? "" :'oam-exercise-code-editor'}
              >
                <MonacoEditor
                  className={exerciseStyles.codeEditor}
                  height="400px"
                  width="100%"
                  language={'python'}
                  theme={renderType == 'white' ? 'vs' : 'vs-dark'} //"vs" | "vs-dark" | "hc-black"
                  value={exercise.reference_answer}
                  // options={editorOptions}
                  options={editorReadOnlyoptions}
                  // onChange={onUserCodeChange}
                  //editorDidMount={editorDidMount}
                />
              </div>
            </div>
          </Col>
        )}
      </Row>

      <Row
        justify="space-between"
        align="middle"
        gutter={[8, 8]}
        style={{ marginTop: 10, marginBottom: 20 }}
      >
        <Col>
          {showClean ? (
            <div
              className={exerciseCardStyles.imageHover}
              style={{ marginLeft: 10, width: 18, height: 18 }}
            >
              <Image
                alt=""
                src={clean1}
                className={exerciseCardStyles.codeClean}
                preview={false}
                onClick={cleanAll}
              />
            </div>
          ) : (
            ''
          )}
        </Col>
        <Col flex={'75px'} style={{ textAlign: 'left' }}>
          {getRunnigStatusBtn()}
        </Col>
      </Row>
      {/* {(currentStatus == true || currentStatus == false) && ( */}
      <div className={exerciseCardStyles.resultCardContainer}>
        <Row
          className={exerciseCardStyles.resultCardHeader}
          align={'middle'}
          justify={'start'}
        >
          {' '}
          <Col className={exerciseCardStyles.titleText}>测试结果</Col>
          <Col>
            {running ? (
              <span
                className="iconfont icon_zhengzaitingzhi"
                style={{ fontSize: '20px', color: '#4285F4' }}
              ></span>
            ) : currentStatus == true ? (
              <span
                className="iconfont icon_goujianchenggong"
                style={{ fontSize: '20px', color: '#07C160' }}
              ></span>
            ) : currentStatus == false ? (
              <span
                className="iconfont icon_tingzhi-20"
                style={{ fontSize: '20px', color: '#FF4B50' }}
              ></span>
            ) : (
              ''
            )}
          </Col>
        </Row>
        <Tabs type="card" defaultActiveKey={activeKey}>
          <TabPane tab="运行结果" key="outputs">
            <div style={{ marginBottom: 10, paddingTop: 0, borderRadius: 5 }}>
              {outputs ? (
                <ReactMarkdown
                  className={exerciseCardStyles.reactmarkdown}
                  remarkPlugins={[gfm, remarkMath]}
                  rehypePlugins={[rehypeKatex, rehypeRaw]}
                >
                  {outputs}
                </ReactMarkdown>
              ) : (
                <Empty
                  description={
                    <span className={exerciseCardStyles.emptyText}>
                      {'暂无运行结果'}
                    </span>
                  }
                />
              )}
            </div>
          </TabPane>
          <TabPane tab="变量信息" key="variables">
            <div style={{ marginBottom: 10, paddingTop: 0, borderRadius: 5 }}>
              {/*} <ReactJson
                            src={variables? variables : {}}
                            enableClipboard={false}
                            displayObjectSize={false}
                            displayDataTypes={false}
                            name={false}
                            quotesOnKeys={false}
                            iconStyle='triangle'
                            collapsed={true}
                            
                            //onEdit = {onFieldDesEdit}

                        />*/}
              <div
                className={
                  renderType == 'dark'
                    ? idataCourseTableDarkStyles.tableWrapper
                    : idataCourseTableWhiteStyles.tableWrapper
                }
              >
                <ConfigProvider locale={zhCN}>
                  <Table
                    dataSource={variables}
                    columns={columns}
                    rowKey={(record) => record.var}
                    pagination={false}
                    expandable={{
                      expandIconColumnIndex: 3,
                      expandedRowRender: renderVariablePreview,
                      rowExpandable: (record) =>
                        record.name !== 'Not Expandable',
                    }}
                    //scroll={{ x: 800 }}
                    //bordered={true}
                    //onChange={onTableChange}
                  />
                </ConfigProvider>
              </div>
            </div>
          </TabPane>

          <TabPane tab="图像输出" key="figures">
            <div style={{ marginBottom: 10, paddingTop: 0, borderRadius: 5 }}>
              {figures?.length > 0 ? (
                <div
                  className={
                    renderType == 'dark'
                      ? idataCourseTableDarkStyles.tableWrapper
                      : idataCourseTableWhiteStyles.tableWrapper
                  }
                >
                  {figures?.map((figure, index) => {
                    return (
                      <img
                        style={
                          renderType == 'dark'
                            ? { maxWidth: 630, background: '#f9f9f9' }
                            : { maxWidth: 630 }
                        }
                        key={index}
                        src={'data:image/jpg;base64,' + figure}
                      />
                    )
                  })}
                </div>
              ) : (
                <Empty
                  description={
                    <span className={exerciseCardStyles.emptyText}>
                      {'暂无运行结果'}
                    </span>
                  }
                />
              )}
            </div>
          </TabPane>
        </Tabs>
      </div>
      {/* )} */}
    </div>
  )
}

export default ExerciseCardDataCoding
