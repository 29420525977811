import React, {useEffect, useRef, useState} from "react";
import {Tooltip,Progress,Tree,Drawer,Layout, message, Card, Col, Row, ConfigProvider, Pagination, Tabs, Input, Upload, Button, Popconfirm, Select } from 'antd';
import { sendHttpPostRequest } from "../../api/http-request";
import logo from '../../static/image/college/logo.png';
import Cookies from 'js-cookie';
import zhCN from 'antd/lib/locale/zh_CN'
import * as constants from '../../constants/constants';
import LoginRegisterModal from "../../component/modal/login-register/login-register";
import UserInfo, {checkUserLogin} from "../common/user-center/user-info";
import { Content, Footer } from "antd/lib/layout/layout";
import collegeStyles from './college.module.less';
import courseIcon from '../../static/image/college/course.png';
import caseIcon from '../../static/image/college/case.png';
import projectIcon from '../../static/image/college/project.png';
import projectBg from '../../static/image/college/marketing/projectBg.png';
import course1 from '../../static/image/college/marketing/9.png';
import course2 from '../../static/image/college/marketing/4.png';
import course3 from '../../static/image/college/marketing/6.png';
import course4 from '../../static/image/college/marketing/8.png';
import course5 from '../../static/image/college/marketing/7.png';
import case1 from '../../static/image/college/marketing/3-1.png';
import case2 from '../../static/image/college/marketing/3-2.png';
import case3 from '../../static/image/college/marketing/3-3.png';
import case4 from '../../static/image/college/marketing/3-4.png';
import case5 from '../../static/image/college/marketing/3-5.png';

// 通过路由组件额外添加参数信息到表中
import { Link } from "react-router-dom";
import PageFooter from "./college-footer";
const { Header } = Layout;

interface Props  {
}

export const CollegeMarketing: React.FC<Props> = (props) => {
    const courseImgs = [
        {
            src: course1,
            title: '',
            id: 167,
        },
        {
            src: course2,
            title: '',
            id: 167,
        },
        {
            src: course3,
            title: '',
            id: 167,
        },
        {
            src: course4,
            title: '',
            id: 167,
        },
        {
            src: course5,
            title: '',
            id: 167,
        },
    ];
    const caseImgs = [
        {
            src: case1,
            title: '酒店预订需求可视化分析',
            url: "case-run?id=595&token=f31be09619dc284cfb2c31780ef603d5"
        },{
            src: case2,
            title: '中文商品评论情感判定',
            url: "case-run?id=604&token=cfc28b0482d7e8ae19e145f180e487a5"
        },{
            src: case3,
            title: '利用回归模型预测服装厂员工生产效率 ',
            url: "case-run?id=44&token=ed3f5a2f704cf8ee2f4c0550b8122770"
        },{
            src: case4,
            title: '使用线性判别分析来预测客户流失',
            url: "case-run?id=602&token=0b358820be82ff23b43d6b6e180233d9"
        },{
            src: case5,
            title: '房价预测模型',
            url: "case-run?id=603&token=81d31fe73d02f9ac798a1b4cfdad1393"
        },
    ];

    const project ={
        name: '电商数据分析项目',
        description: '电子商务的发展，极大的便利了人们的生活，如今，电子商务已经成为人们生活中不可缺少的一部分。对电子商务平台数据进行分析，可以充分了解客户的消费行为以及偏好，方便平台发掘潜在客户同时为现有客户提供个性化的服务。构建用户标签体系和用户画像，可以了解用户的价值，同时也为平台精准营销、用户研究、个性服务、业务决策提供支持。',
        ability: '数据清洗;时间序列;用户标签;用户画像',
        industry_name: '电商',
        practice_duration: '15小时',
        teaching_duration: '25课时',
    }

    const onStartProjectClick = () => {
        window.open('http://idatacoding.cn/project_main?project_id=7');
    }

    return (
        <Layout style={{minHeight: 750,backgroundColor: '#fff'}}>
            <Header style={{backgroundColor:'#fff',boxShadow:'0px 0px 10px rgba(0, 0, 0, 0.16)',height:80,paddingLeft:80,paddingTop:6}}>
                <Row justify="center" align="middle" wrap={true}>
                    <Col>
                        <Link to={"/college-project"}>
                            <img
                                key={"0"}
                                id={"menu-0"}
                                title="黄河交通学院"
                                alt="logo"
                                src={logo}
                                width={300}
                            ></img>
                        </Link>
                    </Col>
                    <Col flex="150px" style={{color:'#155ba5',fontSize:22,fontWeight:700}}>数字营销</Col>
                    <Col flex="auto"></Col>
                    <Col><UserInfo/></Col>
                </Row>
            </Header>

            <div className={collegeStyles.mainDiv}>
                <div className={collegeStyles.course}>
                    <div style={{height:60,paddingTop:25,fontSize:16,color:'#333'}}>
                        <img src={courseIcon} alt=""  className={collegeStyles.icon}/>
                        课程</div>
                    <div>
                        {courseImgs.map((courseImg)=>(
                            <Link to={"/course-main?course_id=" + courseImg.id} target={"_blank"} key={courseImg.src}>
                                <img src={courseImg.src} alt="" key={courseImg.src} className={collegeStyles.courseImg}/>
                            </Link>
                        ))                           
                        }
                    </div>
                </div>

                <div className={collegeStyles.case}>
                    <div style={{height:60,paddingTop:25,fontSize:16,color:'#333'}}>
                        <img src={caseIcon} alt=""  className={collegeStyles.icon}/>
                        实战案例
                    </div>
                    <div>
                        {caseImgs.map((caseImg)=>(
                            <Link to={caseImg.url} key={caseImg.src} target={"_blank"}>
                                <div key={caseImg.src} style={{backgroundImage:`url(${caseImg.src})`}} className={collegeStyles.caseDiv}>
                                    {caseImg.title}
                                </div>
                            </Link>
                        ))                           
                        }
                    </div>
                </div>

                <div className={collegeStyles.project}>
                    <div style={{height:60,paddingTop:25,fontSize:16,color:'#333'}}>
                        <img src={projectIcon} alt=""  className={collegeStyles.icon}/>
                        实战项目
                    </div>
                    <div
                                className={collegeStyles.projectCard}
                                style={{background:`url(${projectBg}) center center no-repeat`,backgroundSize:'cover'}}
                                
                            >
                                <div className={collegeStyles.projectContent}>
                                    <div className={collegeStyles.projectTitle}>
                                        {project.name}
                                    </div>
                                    <div className={collegeStyles.projectDescription} title={project.description}>{project.description}</div>
                                    <Row wrap={false} align="middle" justify="start" gutter={[12,12]} style={{overflow:"hidden"}}>
                                        {project.ability?.split(";")?.map((skill,skillInd)=> {
                                            return <Col key={skillInd} className={collegeStyles.list}>{skill}</Col>
                                        })
                                        }
                                    </Row>
                                    <Row wrap={false} align="middle" justify="start" gutter={[12,12]} style={{marginTop:40,position:'relative'}} className={collegeStyles.infoDiv}>
                                        <Col  style={{width:100,height:50,color:'#fff!important',marginRight:20,borderRight: '1px solid rgba(255, 255, 255, 0.2)'}}>
                                                <div>行业</div>
                                                <div style={{fontSize:18,textOverflow: "ellipsis"}}>{project.industry_name}</div>
                                        </Col>

                                        <Col  style={{width:100,height:50,color:'#fff!important',marginRight:20,borderRight: '1px solid rgba(255, 255, 255, 0.2)'}}>
                                                <div>实践课时</div>
                                                <div style={{fontSize:18}}>{project.practice_duration}</div>
                                        </Col>

                                        <Col style={{width:100,height:50,color:'#fff!important',marginRight:20,borderRight: '1px solid rgba(255, 255, 255, 0.2)'}}>
                                                <div>教学课时</div>
                                                <div style={{fontSize:18}}>{project.teaching_duration}</div>
                                        </Col>
                                    </Row>
                                </div>
                                
                                <div className={collegeStyles.startBtn} style={{transform: "translate(-50%,0)", position:'absolute',left:'50%',bottom:40,display:'none',zIndex:10}}>
                                    <Button onClick={onStartProjectClick} style={{borderRadius:27,backgroundColor:'#fff',width:120,height:40,color:'#3b6d74'}}>
                                        点击开始
                                    </Button>
                                </div>
                            </div>
                    
                </div>
            </div>

            <PageFooter/>
        </Layout>

    )

}

export default CollegeMarketing

