import { Popover } from 'antd'
import React from 'react'
import { PluginComponent } from 'react-markdown-editor-lite'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import InsertEmojiStyle from './insertemoji.module.less'
import zh from "../../../utils/zh.json"
interface CounterState {
  num: number
}

export default class InsertEmojiPlugin extends PluginComponent<CounterState> {
  // 这里定义插件名称，注意不能重复
  static pluginName = 'emoji'
  // 定义按钮被放置在哪个位置，默认为左侧，还可以放置在右侧（right）
  static align = 'left'
  // 如果需要的话，可以在这里定义默认选项
  static defaultConfig = {
    start: 0,
  }

  constructor(props: any) {
    super(props)

    this.handleClick = this.handleClick.bind(this)

    this.state = {
      num: this.getConfig('start'),
    }
  }

  handleClick() {
    // 调用API，往编辑器中插入一个数字
    // this.editor.insertText(":wink:");
    // 更新一下自身的state
  }
  content = () => (
    <div className={InsertEmojiStyle.customActiveColor}>
      <Picker
        data={data}
        onEmojiSelect={(emoji) => {
          console.log(emoji)
          this.editor.insertText(emoji.shortcodes)
        }}
        i18n={zh}
        previewPosition={'none'}
        searchPosition={'none'}
      />
    </div>
  )

  render() {
    return (
      <Popover
        overlayClassName={InsertEmojiStyle.popStyle}
        content={this.content}
        trigger="click"
      >
        <span
          className="button button-type-counter"
          title="emoji"
          onClick={this.handleClick}
        >
          <span
            style={{ fontSize: '18px' }}
            className="iconfont icon_liaotianbiaoqing"
          ></span>
        </span>
      </Popover>
    )
  }
}
