import React, {useEffect, useRef, useState} from "react";
import {Tooltip,Progress,Tree,Drawer,Layout, message, Card, Col, Row, ConfigProvider, Pagination, Tabs, Input, Upload, Button, Popconfirm, Select } from 'antd';
import { PageHeader } from "../common/page-header/header";
import { sendHttpPostRequest } from "../../api/http-request";
import logoSvg from '../../static/image/idclogo.png';
import pekinglogoSvg from '../../static/image/peking.png';
import {DownOutlined,ArrowLeftOutlined,FormOutlined,CodeOutlined,ProfileOutlined,LeftCircleOutlined,RightCircleOutlined} from '@ant-design/icons';
import Cookies from 'js-cookie';
import zhCN from 'antd/lib/locale/zh_CN'
import * as constants from '../../constants/constants';
import PageFooter from "../common/page-footer/footer";
import LoginRegisterModal from "../../component/modal/login-register/login-register";
import {checkUserLogin} from "../common/user-center/user-info";
import { UserOutlined } from '@ant-design/icons';
import { Content } from "antd/lib/layout/layout";
import Sider from "antd/lib/layout/Sider";
import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';
import {UploadOutlined,FileImageOutlined} from '@ant-design/icons';



// 通过路由组件额外添加参数信息到表中
import {RouteComponentProps} from "react-router";
import SplitPane from "react-split-pane";
import caseStyles from "./case.module.less";
import ReactDOM from "react-dom";
import { identifier } from "@babel/types";
const { TextArea } = Input;
const { Option } = Select;

interface Props extends RouteComponentProps<{ course_id: string }> {
    course_id: string
}

interface caseInfo {
    name: string,
    description: string,
    field: string
}



export const CaseCreate: React.FC<Props> = (props) => {
    const [currentCase, setCurrentCase] = useState((null as unknown) as caseInfo);  
    const [caseName,setCaseName] = useState('');
    const [caseDescription, setCaseDescription] = useState('');
    const [isOk,setIsOk] = useState('false');
    const [caseUrl, setCaseUrl] = useState('');
    const [caseField, setCaseField] = useState('');
    const [caseCover, setCaseCover] = useState(''); // 已绑定图片
    
    // const [doMain, setDoMain] = useState([] as any[]);

    // const fields = ['金融','教育','交通','医疗','化学','心理学'];
    const [fields,setFields] = useState([]);

    useEffect(()=> {
        // 示例代码请勿删除
        // createCase();
        queryDoMain();
    }, []);

    // 上传数据集传参
    const uploadData = {
        sessionid: Cookies.get("sessionid"),
        sid: Cookies.get("sessionid"),
        type: 1,
        source: 1
    }

     // 上传图片
     const uploadImageProps = {
        accept: '.png,.jpg,.gif',
        name: 'case_cover',
        multiple: false,
        showUploadList: false,
        data: uploadData,
        action: constants.BACKEND_API_URL + 'case/api/upload_case_image',
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                if (info.file.response.is_ok) {
                    message.success(`图片${info.file.name}文件上传成功！`);
                    console.log(info.file.response.cover)
                    setCaseCover(info.file.response.cover)
                    //info.file.response.update_only ? "" : addNewImage(info.file.response);
                } else {
                    if (info.file.response.code == -100) {
                        // 未登录
                        const localPath = window.location.href.split(window.location.host)[1];
                        window.location.href = `/oam_login?next=${localPath}`;
                    } else if (info.file.response.code == -501) {
                        // 权限不足
                        const localPath = window.location.href.split(window.location.host)[1];
                        window.location.href = `/oam_login?next=${localPath}`;
                    } else {
                        message.error(info.file.response.message);
                    }
                }

            } else if (status === 'error') {
                message.error(`图片${info.file.name}文件上传失败了:(`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    // 添加图片列表
    const addNewImage = (response: any) => {
        const imageHtml = (
            <div key={response.image_id} id={"image-" + response.image_id} style={{textAlign: 'left', margin: '10px 0'}}>
                <Col span={12} style={{display: 'inline-block', width: '50%'}} >{response.image_path}{response.image_name}</Col>
                <Col span={12} style={{display: 'inline-block', width: '50%'}} >
                    <Popconfirm placement="top"
                        title={'确认删除图片' + response.image_name + ' 吗？'}
                        okText="确认"
                        cancelText="取消"
                        onConfirm={(e)=>{}}
                        onCancel={()=>{return ;}}
                    >
                        <DeleteOutlined />
                    </Popconfirm>
                </Col>
            </div>
        )
        ReactDOM.render(imageHtml, document.getElementsByClassName('new-image')[0]);  // 渲染到指定位置
        document.getElementsByClassName('new-image')[0].classList.remove('new-image');  // 移除插入节点的class
        const newClass = document.createElement('div');
        newClass.classList.add('new-image');
        document.getElementById("add-image-div")!.before(newClass);  // 重设插入节点的class
    }

    const createCaseCallback = (response: any) => {
        console.log(response);
        // console.log(response.is_ok);
        if(response.is_ok) {
            message.success(response.message);
            // window.opener.location.reload();
            setIsOk(response.is_ok);
            setCaseUrl(response.url);
            console.log(response.url);
        }
        // if (response.code == -100) {
        //     // 未登录
        //     window.location.href = '/oam_login?next=case-create';
        // } else if (response.code == 0) {
        //     // 成功
        //     message.success(response.message);
        //     window.opener.location.reload();
        //     setCaseName(response.case_info.caseName);
        //     setCaseDescription(response.case_info.caseDescription);
        //     console.log(response.is_ok);
            
        // } else if (response.code == -501) {
        //     // 权限不足
        //     window.location.href = '/oam_login?next=case-create';
        // } else {
        //     message.error(response.message);
        // }
        // if(response.is_ok) {
        //     setIsOk(response.is_ok);
        //     setCaseUrl(response.url);
        //     console.log(setCaseUrl);
        // }
        // console.log(response.is_ok);
        // console.log(12+response.url);
        if(response.is_ok) {
            // console.log(response.is_ok);
            window.open(response.url);
        }
    }
        

    const createCase = () => {
        message.destroy();
        const requestParams = {  
            sessionid: Cookies.get("sessionid"),
            name: caseName,
            description:  caseDescription,
            field: caseField,
            cover: caseCover,
        };
        sendHttpPostRequest(
            'case/api/create_case',
            requestParams,
            createCaseCallback
        );
    }

    const queryDoMainCallback = (response: any) => {
        console.log(response);       
        if (response.code == -100) {
            // 未登录
            window.location.href = '/oam_login?next=case-create';
        } else if (response.code == 0) {
            // 成功
            message.success(response.message);
            window.opener.location.reload();
            const arrnew=  response.domain_list.map((obj,index) => {
                return obj["domain"];
            }).join(",").split(',');
            setFields(arrnew);
        } else if (response.code == -501) {
            // 权限不足
            window.location.href = '/oam_login?next=case-create';
        } else {
            message.error(response.message);
        }
    }

    const queryDoMain = () => {
        message.destroy();
        const requestParams = {
            sessionid: Cookies.get("sessionid"),
        };
        sendHttpPostRequest(
            'case/api/query_domain_list',
            requestParams,
            queryDoMainCallback
        );
    }

     // 删除图片回调
     const deleteImageCallback = (response: any) => {
        message.destroy();
        if (response.code == -100) {
            // 未登录
            const localPath = window.location.href.split(window.location.host)[1];
            window.location.href = `/oam_login?next=${localPath}`;
        } else if (response.code == -501) {
            // 权限不足
            const localPath = window.location.href.split(window.location.host)[1];
            window.location.href = `/oam_login?next=${localPath}`;
        } else if (response.code == 0) {
            message.success(`图片${response.image_name}删除成功！`)
            document.getElementById("image-" + response.image_id)!.remove();
        } else {
            message.error(response.message);
        }
    }

    // 删除图片
    const deleteImage = (imageName: string, event: any) => {
        sendHttpPostRequest(
            'exercise/api/delete_image/',
            {
                sessionid: Cookies.get("sessionid"),
                image_name: imageName
            },
            deleteImageCallback
        );
    }

    // 案例名称改变
    const onNameChange = (key: string, event: any) => {
        setCaseName(event.target.value);

    }

    // 案例描述改变
     const onDescriptionChange = (key: string, event: any) => {
        setCaseDescription(event.target.value);
    }

    // 案例名称改变
    const onFieldChange = (key: string, event: any) => {
        setCaseField(event);
    }


return (
        <Layout style={{minHeight: 750,backgroundColor: '#f9f9f9'}}>
            
                <div className={caseStyles.createDiv} style={{width:800,height:600}}>
                    <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:20}} >
                        <Col flex="100px" style={{textAlign:'left'}}>案例名称</Col>
                        <Col flex="auto" style={{textAlign:'left'}}>
                            <Input defaultValue={currentCase?.name} onChange={onNameChange.bind(this, "name")} className={caseStyles.caseName} placeholder="请输入案例名称" required={true} style={{borderRadius:6,width:"100%"}}/>
                        </Col>
                        <Col flex="200px">
                        </Col>
                    </Row>

                    <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:20}} >
                        <Col flex="100px" style={{textAlign:'left'}}>案例描述</Col>
                        <Col flex="auto" style={{textAlign:'left'}}>
                            <TextArea defaultValue={currentCase?.description} onChange={onDescriptionChange.bind(this, "description")} placeholder="请输入案例描述" required={true} style={{borderRadius:6,width:"100%"}}/>
                        </Col>
                        <Col flex="200px">
                        </Col>
                    </Row>

                    <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:20}} >
                        <Col flex="100px" style={{textAlign:'left'}}>领域</Col>
                        <Col flex="auto" style={{textAlign:'left'}}>
                            <Select onChange={onFieldChange.bind(this, "field")}  className={caseStyles.caseSelect} style={{width:"100%",height:32,outline:0}}>
                                {fields.map((item, index)=> {    // 2、利用map属性
                                    return <Option value={item} key={index}>{item}</Option>
                                })}
                            </Select>
                        </Col>
                        <Col flex="200px">
                        </Col>
                    </Row>
                    
                    <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:10}} >
                        <Col span={24} style={{textAlign:'center'}}>
                        <span style={{fontSize:12,color:"#00000073" }}>请上传以字母、数字命名的png和jpg等格式的图片 </span>
                        <Upload {...uploadImageProps}>
                            <div ><Button size="small" type="default" style={{width:100,borderRadius:20}}><UploadOutlined /> 传图</Button></div>
                        </Upload>
                        </Col>
                    </Row>
                    
                    {/* <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:images?.length > 0 ? 10:0}}>
                        <Col flex="100px"></Col>
                        <Col flex="auto" style={{borderRadius:6,borderStyle:'dashed',borderWidth:images?.length > 0 ? 1 :0,borderColor:"#e9e9e9",textAlign:'left'}}>
                            {images?.map((image:any, index) => {
                                return (
                                    <div key={image.id} id={"image-" + image.id} style={{textAlign: 'left', margin: '10px 0'}}>
                                        <Col span={24} style={{display: 'inline-block', width: '100%'}} >
                                        <div className={caseStyles.fileLink}><a><FileImageOutlined style={{marginRight:5}}/>{image?.name}</a>
                                        
                                            <Popconfirm placement="top"
                                                title={'确认删除图片' + image.name + ' 吗？'}
                                                okText="确认"
                                                cancelText="取消"
                                                onConfirm={(e)=>{deleteImage(image.name, this)}}
                                                onCancel={()=>{return ;}}
                                            >
                                                <DeleteOutlined  className={caseStyles.iconbuttonsdel}/>
                                            </Popconfirm>
                                        </div>
                                        </Col>
                                    </div>
                                )
                            })}
                            <div className={"new-image"}></div>
                            <div id={"add-image-div"}></div>
                        </Col>
                    </Row> */}

                    <Button type="primary"  style={{borderRadius:20}} onClick={createCase}>创建实验</Button>
                </div>

            
            
        </Layout>

    )

}

export default CaseCreate

