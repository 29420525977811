import React from 'react'
import MarkdownIt from 'markdown-it'
import MdEditor from 'react-markdown-editor-lite'
import MarkdownItKatex from '@iktakahiro/markdown-it-katex'
import InsertEmojiPlugin from './plugin/InsertEmojiPlugin'
import katex from 'katex'
import 'react-markdown-editor-lite/lib/index.css'
import InsertImgPlugin from './plugin/insertImagPlugin'
import Cookies from 'js-cookie'
import EnterControlInsertPlugin from './plugin/EnterControlInsertPlugin'
const emoji = require('markdown-it-emoji')

export const mdParser = new MarkdownIt({
  html: true,
  linkify: true,
  typographer: true,
})
mdParser.use(MarkdownItKatex, { katex })
mdParser.use(emoji)

export interface EditorProps {
  onBlur?: any
  placeholder?: any
  height?: number
  toolbar?: any
  preview?: boolean
  onChange?: any
  value?: any
  uploadOption?: any
  specialOptons?: any
  autoFocus?:boolean
}

export const MarkDownEditor: React.FC<EditorProps> = (props) => {
  const {
    onChange,
    preview,
    toolbar,
    onBlur,
    placeholder,
    height,
    value,
    uploadOption,
    specialOptons,
    autoFocus
  } = props
  MdEditor.use(InsertEmojiPlugin)
  MdEditor.use(EnterControlInsertPlugin)
  MdEditor.use(InsertImgPlugin, {
    name: uploadOption?.name,
    actionUrl: uploadOption?.actionUrl,
    uploadData: { sessionid: Cookies.get('sessionid') },
    uploadImageList: uploadOption?.uploadImageList,
    setUploadImageList: uploadOption?.setUploadImageList,
  })

  const plugins: any = ['emoji', 'img-upload', 'control-enter']
  const allPlugins: any = [
    'header',
    'font-bold',
    'emoji',
    'font-italic',
    'font-underline',
    'font-strikethrough',
    'list-unordered',
    'list-ordered',
    'block-quote',
    'block-wrap',
    'block-code-inline',
    'block-code-block',
    'table',
    // 'image',
    'img-upload',
    'link',
    'clear',
    'mode-toggle',
    'full-screen',
    'tab-insert',
  ]
  const mdConfig = {
    view: {
      menu: true,
      md: true,
      html: preview ? true : false,
      fullScreen: true,
      hideMenu: true,
    },
    table: {
      maxRow: 10,
      maxCol: 10,
    },
    syncScrollMode: ['leftFollowRight', 'rightFollowLeft'],
  }
  // const handleEditorChange = ({ html, text }) => {
  //   // console.log('handleEditorChange', html, text)
  //   console.log('handleEditorChange', text)
  // }

  // const handleImageUpload = (file) => {
  //   console.log("file======",file);

  //   return new Promise((resolve) => {
  //     const reader = new FileReader()
  //     reader.onload = (data) => {
  //       resolve(data.target?.result)
  //     }
  //     reader.readAsDataURL(file)
  //   })
  // }

  // const handleKeyCommand = (command, editor) => {
  //   if (command === 'shift-enter') {
  //     editor.insertText('\n')
  //     return true
  //   }
  //   return false
  // }

  const renderHTML = (text) => {
    // console.log('mdParser', text)
    return mdParser.render(text)
  }

  return (
    <MdEditor
      plugins={
        toolbar == 'all' ? allPlugins : toolbar == 'chat' ? plugins : toolbar
      }
      style={{ height: height ? height + 'px' : '200px' }}
      config={mdConfig}
      renderHTML={renderHTML}
      onChange={onChange}
      onFocus={(e) => console.log('focus', e)}
      onBlur={onBlur}
      placeholder={placeholder}
      value={value}
      autoFocus={autoFocus}
      id='md-editor-id'
    />
  )
}

export default MarkDownEditor
