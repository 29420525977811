import {
  Layout,
  Button,
  Input,
  message,
  Table,
  ConfigProvider,
  Tabs,
  Row,
  Col,
  Popconfirm,
  Statistic,
  Pagination,
  Breadcrumb,
  Skeleton,
  Upload,
  Select,
  Empty,
} from 'antd'
import { Content } from 'antd/lib/layout/layout'
import zhCN from 'antd/lib/locale/zh_CN'
import OAMSider from '../oam-sider'
import React, { useEffect, useRef, useState } from 'react'
import { sendHttpPostRequest } from '../../../api/http-request'
import Cookies from 'js-cookie'
import css from '@/pages/case/CaseDetail/case-detail.module.less'
import { useHistory, useLocation } from 'react-router'
import GlobalCss from '@/global.module.less'
import * as constants from '@/constants/constants'
import defaultCaseCover from '@/static/image/case/default_case_cover.png'
import TextArea from 'antd/lib/input/TextArea'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import rehypeRaw from 'rehype-raw'
import 'katex/dist/katex.min.css'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter' // 代码高亮
//高亮的主题，还有很多别的主题，可以自行选择
import { oneLight } from 'react-syntax-highlighter/dist/esm/styles/prism'
import { FileList } from '@/pages/case/CaseDetail/FileList'

import CopyToClipboard from 'react-copy-to-clipboard'

const { Search } = Input
const { TabPane } = Tabs
const { Option } = Select

export interface Props {}

/** OAM 实验预览页 */
export const OAMCasePreview: React.FC<Props> = (props) => {
  const location = useLocation()
  const caseId = location?.search?.split('case_id=')[1]
  const fromOAM = location.pathname?.includes('oam') ? 1 : 0
  const history = useHistory()
  const [userLogin, setUserLogin] = useState(false) // 用户登录状态
  const [caseInfo, setCaseInfo] = useState<any>()
  const [fileContent, setFileContent] = useState<any>()
  const [forkModalOpen, setForkModalOpen] = useState(false)
  const [editState, setEditState] = useState(false)
  const [coverFileList, setCoverfileList] = useState([])
  const [fields, setFields] = useState([]) //无全部的行业选择
  const [tabActiveKey, setTabActiveKey] = useState('exp_file')
  const [expDatasetInfo, setExpDatasetInfo] = useState<any>()
  const [activeMontedDatasetInfo, setActiveMontedDatasetInfo] = useState<any>()
  const [infoLoading, setInfoLoading] = useState(true)
  const [ipynbFileLoading, setIpynbFileInfoLoading] = useState(true)

  // 校验图片类型和大小
  function beforeCoverUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('请上传png或jpg格式的图片!')
    }
    const isLt5M = file.size / 1024 / 1024 < 5
    if (!isLt5M) {
      message.error('请上传小于5M的图片')
    }
    return isJpgOrPng && isLt5M
  }
  // 上传封面
  const onCoverChange = (info: any) => {
    setCoverfileList(info.fileList)
    const { status } = info.file
    if (status !== 'uploading') {
      //上传中
      console.log(info.file, info.fileList)
    }
    if (status === 'done') {
      //上传成功
      let new_cover = info.file.response.image_url
      let code = info.file.response.code
      if (code == -100) {
        document.getElementById('show-login-modal')!.click()
      } else {
        caseInfo.image = new_cover
        setCaseInfo({ ...caseInfo })
      }
    } else if (status === 'error') {
      //上传失败
      message.error(`${info.file.name} file upload failed.`)
    }
  }

  // 上传cover传参
  const uploadCoverData = {
    sessionid: Cookies.get('sessionid'),
    sid: Cookies.get('sessionid'),
    case_id: caseId,
    // token: window.location.search.split('&')[1].split('token=')[1],
  }

  const onCaseNameChange = (e) => {
    caseInfo.name = e.target.value
    setCaseInfo({ ...caseInfo })
  }

  /**
   * 查询实验信息
   */
  const queryCase = () => {
    setInfoLoading(true)
    let params = {
      sessionid: Cookies.get('sessionid'),
      case_id: caseId,
      is_my: '2',
      from_oam: fromOAM,
    }
    // if (caseId) {
    //   params['course_id'] = caseId
    // }
    sendHttpPostRequest(
      'case/api/oam_query_case_by_id',
      params,
      queryCaseCallback
    )
  }

  /**
   * 查询实验回调
   * @param response
   */
  const queryCaseCallback = (response: any) => {
    message.destroy()
    if (response.code == -100) {
      // 未登录
      setUserLogin(false)
      document.getElementById('show-login-modal')!.click()
    } else if (response.code == 0) {
      setUserLogin(true)
      setCaseInfo(response?.case_info)
      setInfoLoading(false)
    } else {
      message.error(response.message)
    }
  }

  // 保存实验信息回调
  const saveCaseInfoCallback = (response: any) => {
    if (response.code == -100) {
      // 未登录
      document.getElementById('show-login-modal')!.click()
    } else if (response.is_ok) {
      queryCase()
    } else {
      message.error(response.message)
    }
  }

  // 即时保存实验领域
  const onCaseDomainChange = (domain: string) => {
    sendHttpPostRequest(
      'case/api/update_case',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        case_id: caseId,
        domain: domain,
      },
      saveCaseInfoCallback
    )
  }

  const onSave = () => {
    setEditState(false)
    sendHttpPostRequest(
      'case/api/update_case',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        case_id: caseId,
        description: caseInfo.desc,
        name: caseInfo.name,
      },
      saveCaseInfoCallback
    )
  }

  const getDatasetDetail = () => {
    let data = {
      dataset_id: caseInfo?.dataset_id,
    }
    sendHttpPostRequest('dataset/api/oam_get_dataset_by_id/', data, (res) => {
      if (res.code == 0) {
        setExpDatasetInfo(res.query_info)
      }
      if (res.code == -100) {
        message.error(res.message)
      }
    })
  }

  const onTabkeyChange = (key) => {
    setTabActiveKey(key)
    if (key == 'dataset') {
      getDatasetDetail()
    }
  }

  const onCaseDescChange = (e) => {
    caseInfo.desc = e.target.value
    setCaseInfo({ ...caseInfo })
  }

  const queryMountedDatasetInfo = (id) => {
    let data = {
      dataset_id: id,
    }
    sendHttpPostRequest('dataset/api/oam_get_dataset_by_id/', data, (res) => {
      if (res.code == 0) {
        setActiveMontedDatasetInfo({
          ...res.query_info,
          dataset_id: res.query_info.id,
        })
      } else {
        message.error(res.message)
      }
    })
  }

  const onMenuClick = (dataset) => {
    queryMountedDatasetInfo(dataset?.dataset_id)
  }

  const queryIpynbFile = () => {
    setIpynbFileInfoLoading(true)
    let params = {
      case_id: caseId,
    }
    sendHttpPostRequest(
      'case/api/oam_preview_case_ipynb_content',
      params,
      (res) => {
        if (res.code == 0) {
          setTimeout(() => {
            setIpynbFileInfoLoading(false)
          }, 1000)
          setFileContent(res.text_content)
        } else {
          message.error(res.message)
        }
      }
    )
  }

  const queryDoMain = () => {
    message.destroy()
    const requestParams = {
      sessionid: Cookies.get('sessionid'),
    }
    sendHttpPostRequest(
      'case/api/query_domain_list',
      requestParams,
      queryDoMainCallback
    )
  }

  const queryDoMainCallback = (response: any) => {
    if (response.code == -100) {
      // 未登录
      document.getElementById('show-login-modal')!.click()
    } else if (response.code == 0) {
      // 成功
      const arrnew = response.domain_list
      setFields(arrnew)
    } else if (response.code == -501) {
      // 权限不足
      message.error('当前用户权限不足')
    } else {
      message.error(response.message)
    }
  }

  useEffect(() => {
    queryCase()
    queryIpynbFile()
    queryDoMain()
  }, [])

  const MenuItem = (props: any) => {
    const { dataset, onClick, index } = props

    return (
      <Row
        onClick={onClick}
        className={
          css.menuItem +
          ' ' +
          (dataset.dataset_id == activeMontedDatasetInfo?.dataset_id ||
          dataset.dataset_id == activeMontedDatasetInfo?.id
            ? css.activeMenuItem
            : '')
        }
        align={'top'}
        justify={'space-between'}
        wrap={false}
      >
        <Col>
          <Row
            align={'top'}
            justify={'start'}
            style={{ gap: '10px' }}
            wrap={false}
          >
            <Col>
              <span
                style={{
                  color: '#07C160',
                }}
                className="iconfont icon_a-zu3673"
              ></span>
            </Col>
            <Col>
              <div
                className={css.dtstTitle + ' ' + GlobalCss.oneLineTextOverflow}
                style={{ maxWidth: '200px' }}
              >
                {dataset.title}
              </div>
              <CopyToClipboard
                text={'../' + dataset?.base_path}
                onCopy={() => {
                  message.success('路径已复制')
                }}
              >
                <span
                  className={
                    css.datasetBasePath + ' ' + GlobalCss.oneLineTextOverflow
                  }
                >
                  {dataset?.base_path}
                </span>
              </CopyToClipboard>
            </Col>
          </Row>
        </Col>
        {/* <Col>
          <div
            className={GlobalCss.btnBox}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              unMountDataset(dataset, index)
            }}
          >
            <CloseOutlined className={css.deleteIcon} />
          </div>
        </Col> */}
      </Row>
    )
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <OAMSider keys={['4']} openKeys={[]} />
      <div style={{ width: '100%' }}>
        <Breadcrumb style={{ margin: '20px 30px' }}>
          <Breadcrumb.Item>实验</Breadcrumb.Item>
          <Breadcrumb.Item>预览</Breadcrumb.Item>
        </Breadcrumb>
        <div className={css.OAMinfoContain}>
          {infoLoading ? (
            <Skeleton active avatar paragraph={{ rows: 4 }} />
          ) : (
            <Row
              className={css.headerInfo}
              wrap={false}
              justify={'start'}
              align={'top'}
            >
              <Col
                flex={'250px'}
                className={GlobalCss.ColFlex + ' ' + css.uploadContain}
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  minHeight: '150px',
                }}
              >
                {editState ? (
                  <Upload
                    beforeUpload={beforeCoverUpload}
                    accept={'image/*'}
                    action={
                      constants.BACKEND_API_URL + 'case/api/upload_case_image'
                    }
                    listType="picture-card"
                    name="case_cover"
                    fileList={coverFileList}
                    onChange={onCoverChange}
                    data={uploadCoverData}
                    showUploadList={false}
                  >
                    <img
                      width={250}
                      height={150}
                      style={{
                        minHeight: '150px',
                        minWidth: '250px',
                      }}
                      src={
                        caseInfo?.image
                          ? caseInfo?.image?.startsWith('http')
                            ? caseInfo?.image
                            : constants.BACKEND_URL + caseInfo?.image
                          : defaultCaseCover
                      }
                    />
                    {/* {caseInfo?.image ? (
                    
                  ) : (
                    uploadCoverButton
                  )} */}
                  </Upload>
                ) : (
                  <img
                    width={'250px'}
                    height={150}
                    src={
                      caseInfo?.image
                        ? constants.BACKEND_URL + caseInfo?.image
                        : defaultCaseCover
                    }
                    alt=""
                  />
                )}
              </Col>
              <Col
                className={css.headerMid + ' ' + GlobalCss.ColFlex}
                flex={'auto'}
              >
                {editState ? (
                  <Row
                    align={'middle'}
                    justify={'start'}
                    style={{ gap: '20px', width: '100%' }}
                    wrap={false}
                  >
                    <Col flex={'auto'}>
                      <Input
                        maxLength={40}
                        onChange={onCaseNameChange}
                        value={caseInfo?.name}
                        placeholder="请输入实验名称"
                      />
                    </Col>
                    <Col flex={'200px'}>
                      <Select
                        defaultValue={caseInfo?.domain}
                        onChange={onCaseDomainChange}
                        style={{
                          textAlign: 'left',
                          display: 'block',
                          width: '200px',
                        }}
                      >
                        {fields?.map((item, index) => {
                          // 2、利用map属性
                          return (
                            <Option value={item} key={index}>
                              {item}
                            </Option>
                          )
                        })}
                      </Select>
                    </Col>
                  </Row>
                ) : (
                  <div className={css.headerTitle}>{caseInfo?.name}</div>
                )}
                {editState ? (
                  ''
                ) : (
                  <div style={{ marginBottom: 10 }}>
                    <span
                      style={{
                        padding: '3px 8px 3px 8px',
                        fontSize: 12,
                        height: 18,
                        color: '#00CC99',
                        backgroundColor: '#F2F2F2',
                        borderRadius: 10,
                        marginBottom: 5,
                      }}
                    >
                      {caseInfo?.domain}
                    </span>
                  </div>
                )}
                {editState ? (
                  <TextArea
                    style={{ borderRadius: 3 }}
                    value={caseInfo?.desc}
                    defaultValue={caseInfo?.desc}
                    onChange={onCaseDescChange}
                    placeholder="请输入实验简介"
                    rows={4}
                  />
                ) : (
                  <div
                    className={
                      GlobalCss.threeLinesTextOverflow + ' ' + css.desc
                    }
                  >
                    {caseInfo?.desc}
                  </div>
                )}
              </Col>
              <Col flex={'100px'} style={{ alignSelf: 'center' }}>
                {!editState ? (
                  <div className={css.btnGroup}>
                    <Button
                      className={GlobalCss.radiusBtn + ' ' + GlobalCss.defBtn}
                      onClick={() => setEditState(true)}
                    >
                      编辑
                    </Button>
                    <Button
                      className={GlobalCss.radiusBtn}
                      type="primary"
                      onClick={() => {
                        history.push('/oam-case-run?case_id=' + caseId)
                      }}
                    >
                      运行
                    </Button>
                  </div>
                ) : editState ? (
                  <Button
                    className={GlobalCss.radiusBtn}
                    type="primary"
                    onClick={onSave}
                  >
                    保存
                  </Button>
                ) : (
                  ''
                )}
              </Col>
            </Row>
          )}
          <div className={css.TabContain}>
            <Tabs activeKey={tabActiveKey} onChange={onTabkeyChange}>
              <Tabs.TabPane tab="实验文件" key="exp_file">
                {ipynbFileLoading ? (
                  <div className={css.fileInfoContain}>
                    <Skeleton active paragraph={{ rows: 10 }} />
                  </div>
                ) : (
                  <div className={css.fileInfoContain}>
                    {fileContent?.cells?.map((cell) => {
                      return cell.cell_type == 'markdown' ? (
                        <div
                          key={cell.id}
                          id={cell.id}
                          className={css.markdownContain}
                        >
                          <ReactMarkdown
                            remarkPlugins={[
                              [remarkGfm, { singleTilde: false }],
                              [remarkMath],
                            ]}
                            rehypePlugins={[rehypeKatex, rehypeRaw]}
                          >
                            {Array.isArray(cell.source) &&
                              cell.source?.join('\n')}
                          </ReactMarkdown>
                        </div>
                      ) : cell?.cell_type == 'code' ? (
                        <div className={css.codeContain}>
                          <SyntaxHighlighter
                            language={'python'}
                            key={cell.id}
                            style={oneLight}
                            id={cell.id}
                          >
                            {Array.isArray(cell.source)
                              ? cell.source?.join('')
                              : cell.source}
                          </SyntaxHighlighter>
                          {cell?.outputs?.length > 0
                            ? cell?.outputs?.map((output: any, i) => {
                                if (output.output_type == 'stream') {
                                  return (
                                    <div
                                      key={
                                        output.output_type +
                                        output.execution_count
                                      }
                                      className={css.outputMarkdownContain}
                                    >
                                      <ReactMarkdown
                                        remarkPlugins={[
                                          [remarkGfm, { singleTilde: false }],
                                          [remarkMath],
                                        ]}
                                        rehypePlugins={[rehypeKatex, rehypeRaw]}
                                      >
                                        {output.text?.join('')}
                                      </ReactMarkdown>
                                    </div>
                                  )
                                } else if (
                                  output.output_type == 'display_data'
                                ) {
                                  return (
                                    <div
                                      key={
                                        output.output_type +
                                        output.execution_count
                                      }
                                      className={css.outputMarkdownContain}
                                    >
                                      {output.data['image/png'] ? (
                                        <img
                                          style={{ objectFit: 'contain' }}
                                          width={'100%'}
                                          src={
                                            'data:image/png;base64,' +
                                            output.data['image/png']
                                          }
                                          alt="图片"
                                        />
                                      ) : (
                                        ''
                                      )}
                                      <ReactMarkdown
                                        remarkPlugins={[
                                          [remarkGfm, { singleTilde: false }],
                                          [remarkMath],
                                        ]}
                                        rehypePlugins={[rehypeKatex, rehypeRaw]}
                                      >
                                        {output.data['text/plain']
                                          ?.join('')
                                          ?.replaceAll('\n', '<br/>')}
                                      </ReactMarkdown>
                                    </div>
                                  )
                                } else if (
                                  output.output_type == 'execute_result'
                                ) {
                                  return (
                                    <div
                                      key={
                                        output.output_type +
                                        output.execution_count
                                      }
                                      className={css.outputMarkdownContain}
                                    >
                                      {output.data['text/html']?.length > 0 ? (
                                        <ReactMarkdown
                                          remarkPlugins={[
                                            [remarkGfm, { singleTilde: false }],
                                            [remarkMath],
                                          ]}
                                          rehypePlugins={[
                                            rehypeKatex,
                                            rehypeRaw,
                                          ]}
                                        >
                                          {output.data['text/html']
                                            ?.join('')
                                            ?.replaceAll('<br/>', '')}
                                        </ReactMarkdown>
                                      ) : (
                                        <ReactMarkdown
                                          remarkPlugins={[
                                            [remarkGfm, { singleTilde: false }],
                                            [remarkMath],
                                          ]}
                                          rehypePlugins={[
                                            rehypeKatex,
                                            rehypeRaw,
                                          ]}
                                        >
                                          {output.data['text/plain']
                                            ?.join('')
                                            ?.replaceAll('\n', '<br/>')}
                                        </ReactMarkdown>
                                      )}
                                    </div>
                                  )
                                }
                              })
                            : ''}
                        </div>
                      ) : null
                    })}
                  </div>
                )}
              </Tabs.TabPane>
              <Tabs.TabPane tab="实验数据" key="dataset">
                <div
                  style={{
                    padding: '0px 30px',
                    minHeight: '500px',
                  }}
                >
                  {expDatasetInfo && (
                    <FileList
                      actionPath={location.pathname + location.search}
                      datasetInfo={expDatasetInfo}
                      mode={caseInfo?.is_system ? 'monted' : 'case'}
                      requestType={'oam'}
                    />
                  )}
                </div>
              </Tabs.TabPane>
              {caseInfo?.mounted_datasets.length > 0 ? (
                <Tabs.TabPane tab="挂载数据" key="mnt_dataset">
                  <div
                    style={{
                      padding: '0px 30px',
                      minHeight: '500px',
                    }}
                  >
                    {caseInfo?.mounted_datasets?.length > 0 ? (
                      <Row className={css.montedContain} wrap={false}>
                        <Col className={css.montedMenu} flex={'280px'}>
                          {caseInfo?.mounted_datasets?.map((dtst, i) => {
                            return (
                              <MenuItem
                                key={dtst.dataset_id}
                                dataset={dtst}
                                onClick={onMenuClick.bind(this, dtst)}
                                index={i}
                              />
                            )
                          })}
                        </Col>
                        <Col className={css.montedTable} flex={'auto'}>
                          {activeMontedDatasetInfo && (
                            <div style={{ padding: '20px 30px' }}>
                              {' '}
                              <FileList
                                actionPath={location.pathname + location.search}
                                datasetInfo={activeMontedDatasetInfo}
                                mode={'monted'}
                              />
                            </div>
                          )}
                        </Col>
                      </Row>
                    ) : (
                      <div
                        style={{
                          width: '100%',
                          height: '500px',
                          lineHeight: '500px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={
                            <span style={{ color: '#999999' }}>
                              暂无数据
                              {/* {caseInfo?.is_system ? '' : '点击'}
                          {caseInfo?.is_system ? (
                            ''
                          ) : (
                            <span
                              // onClick={() => setAddDatasetModalVis(true)}
                              className={
                                GlobalCss.link + ' ' + GlobalCss.hlText
                              }
                            >
                              添加数据集
                            </span>
                          )} */}
                            </span>
                          }
                        />
                      </div>
                    )}
                  </div>
                </Tabs.TabPane>
              ) : (
                ''
              )}
            </Tabs>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default OAMCasePreview
