import { Button, Col, ConfigProvider, Layout, message, Pagination, Popconfirm, Popover, Row, Statistic, Table } from 'antd';
import { Content } from 'antd/lib/layout/layout';

import React, { useEffect, useRef, useState } from "react";
import OAMSider from '../oam-sider';
import Cookies from 'js-cookie';
import { sendHttpPostRequest } from '../../../api/http-request';
import Search from 'antd/lib/input/Search';
import zhCN from 'antd/lib/locale/zh_CN';

import idataCourseTableStyles from "../../common/global-styles/idatacourse-table.module.less";
import selfCss from './oam-lecture.module.less'

import courseBasicInfoStyle from "../../course/course-edit-basicinfo.module.less"

import {PlayCircleOutlined, DownOutlined,SearchOutlined, DeleteOutlined, FileSearchOutlined, PlusSquareOutlined, UploadOutlined} from "@ant-design/icons";
import * as constants from '../../../constants/constants';



export interface Props {}

export const OAMLectureBannerList: React.FC<Props> = (props) => {
    const [bannerListResult, setBannerResult] = useState([] as any[]);  // 当前显示的结果页
    const [resultSum, setResultSum] = useState(0);  // 搜索返回的结果数
    const [totalCount, setTotalCount] = useState(0);  // 总数
    const [onlineCount, setOnlineCount] = useState(0);  // 上架的数量

    const queryContent = useRef('');  // 记录搜索串
    const order = useRef('');  // 排序升序降序
    const order_by = useRef('');  // 排序列
    const page = useRef(1);  // 记录当前的页数
    const pageSize = useRef(10);  // 记录当前每页展示数量
    const onlineStatusFilters = useRef([]);  // 在线状态过滤选项

    useEffect(() => {
        queryLectureBannerList();
    }, [])


    /**
     * 查询讲座Banner列表
     */
     const queryLectureBannerList = () => {
        sendHttpPostRequest(
            'video/api/oam_query_lecture_banner_list/',
            {
                sessionid: Cookies.get("sessionid"),    
                query: queryContent.current,
                page: page.current,
                pagesize: pageSize.current,
                order: order.current,
                order_by: order_by.current,
                online_status: JSON.stringify(onlineStatusFilters.current)
            },
            (response: any) => {
                // console.log(response)
                message.destroy();
                if (response.code == -100) {
                    // 未登录
                    window.location.href = '/oam_login?next=oam_lecture_banner_list';
                } else if (response.is_ok == true) {
                    // 成功
                    setBannerResult(response.query_list);
                    setResultSum(response.total_sum);
                    setTotalCount(response.total_count);
                    setOnlineCount(response.online_count);
                } else if (response.code == -501) {
                    // 权限不足
                    window.location.href = '/oam_login?next=oam_lecture_banner_list';
                } else {
                    message.error(response.message);
                }
            }
        
        )
    }


    //页码和页数发生了变化
    const onPageChange = (newPage: number, newPageSize?: number | undefined) => {
        page.current = newPage ? newPage : 1;
        if (newPageSize) {
            pageSize.current = newPageSize;
        }
        queryLectureBannerList();    
    }

   
     //点击了查看按钮
  const onLookOverBtnClick = (event:any) => {
    event.preventDefault()
    //window.open('/lecture-vod?id=' + event.currentTarget.value)
  }

  //点击了编辑按钮
  const onEditBtnClick = (event:any) => {
    //message.success('编辑数据' + event.currentTarget.value)
    event.preventDefault()
    window.open('/oam_lecture_banner_edit?banner_id=' + event.currentTarget.value)
  }

    //点击创建按钮
    const onUploadBtnClick = (event:any) => {
        sendHttpPostRequest(
            'video/api/oam_create_lecture_banner/',
            {
                sessionid: Cookies.get("sessionid"),    
            },
            (response: any) => {
                // console.log(response)
                message.destroy();
                if (response.code == -100) {
                    // 未登录
                    window.location.href = '/oam_login?next=oam_lecture_banner_list';
                } else if (response.is_ok == true) {
                    // 成功
                    window.open(`/oam_lecture_banner_edit?banner_id=${response.banner_id}`);
                   
                } else if (response.code == -501) {
                    // 权限不足
                    window.location.href = '/oam_login?next=oam_lecture_banner_list';
                } else {
                    message.error(response.message);
                }
            }
        
        )
      }


  // 点击 删除
  const onDeleteBtnClick = (banner_id:any) => {
    sendHttpPostRequest(
        'video/api/oam_delete_lecture_banner/',
        {
            sessionid: Cookies.get("sessionid"), 
            banner_id: banner_id,   
        },
        (response: any) => {
            // console.log(response)
            message.destroy();
            if (response.code == -100) {
                // 未登录
                window.location.href = '/oam_login?next=oam_lecture_banner_list';
            } else if (response.is_ok == true) {
                // 成功
                message.success('讲座banner删除成功~');
                queryLectureBannerList();
               
            } else if (response.code == -501) {
                // 权限不足
                window.location.href = '/oam_login?next=oam_lecture_banner_list';
            } else {
                message.error(response.message);
            }
        }
    
    )
  }


  // 点了上架按钮
  const onPutOnOffBtnClick = (banner_id:any, status:any) => {
   if(status == 1) //上架
   {
    sendHttpPostRequest(
        'video/api/oam_publish_lecture_banner/',
        {
            sessionid: Cookies.get("sessionid"),    
            banner_id: banner_id,
        },
        (response: any) => {
            // console.log(response)
            message.destroy();
            if (response.code == -100) {
                // 未登录
                window.location.href = '/oam_login?next=oam_lecture_banner_list';
            } else if (response.is_ok == true) {
                // 成功
               message.success('讲座banner上架成功~');
               queryLectureBannerList();
               
            } else if (response.code == -501) {
                // 权限不足
                window.location.href = '/oam_login?next=oam_lecture_banner_list';
            } else {
                message.error(response.message);
            }
        }
    
    )
   }else if(status == 2){//下架
    sendHttpPostRequest(
        'video/api/oam_unpublish_lecture_banner/',
        {
            sessionid: Cookies.get("sessionid"), 
            banner_id: banner_id,   
        },
        (response: any) => {
            // console.log(response)
            message.destroy();
            if (response.code == -100) {
                // 未登录
                window.location.href = '/oam_login?next=oam_lecture_banner_list';
            } else if (response.is_ok == true) {
                // 成功
                message.success('讲座banner下架成功~');
                queryLectureBannerList();
               
            } else if (response.code == -501) {
                // 权限不足
                window.location.href = '/oam_login?next=oam_lecture_banner_list';
            } else {
                message.error(response.message);
            }
        }
    
    )
   }
    
  }



    // 渲染操作
    const renderActions = (text:any, record:any) => {
        return <>
        <Row justify="start" align="middle" gutter={[8, 5]} >
        {/*  <Col style={{width:50}}><Button style={{borderRadius:20,fontSize:12}} size='small' id="lookover" value={record.id} type="default" onClick={onLookOverBtnClick} > 查 看 </Button></Col> */}
        <Col style={{width:50}}><Button style={{borderRadius:20,fontSize:12}} size='small' id="edit" value={record.id} type="dashed" onClick={onEditBtnClick}> 编 辑 </Button> </Col>
            {<Col style={{width:50}}> { parseInt(record.status) == 1 ? 
            <Button style={{borderRadius:20,fontSize:12}}  size='small' id="puton" value={record.id} type="primary"  onClick={onPutOnOffBtnClick.bind(this, record.id, record.status)}> 上 架 </Button>
            :  <Popconfirm placement="top" title={'确认下架banner"' + record.title + '"吗？'} onConfirm={onPutOnOffBtnClick.bind(this, record.id, record.status)} okText="确认" cancelText="取消">
                <Button style={{borderRadius:20,fontSize:12}}  size='small' id="putoff" value={record.id} type="primary"  danger> 下 架 </Button>
                </Popconfirm>}
            </Col> }
            { <Col style={{width:50}}>
            <Popconfirm placement="top" title={'确认删banner"' + record.title + '"吗？'} onConfirm={onDeleteBtnClick.bind(this, record.id)} okText="确认" cancelText="取消">
            <Button style={{borderRadius:20,fontSize:12}} size='small' id="delete" danger value={record.id} > 删 除 </Button> 
            </Popconfirm></Col> }
        </Row>

        </>

        }

 
  
    //点击了搜索按钮
    const onSearchBtn = (value: string) => {
        page.current = 1;
        queryContent.current = value;
        queryLectureBannerList();
    }

    // 表格发生变化
    const onTableChange:any = (pagination:any, filters:any, sorter:any, extra: { currentDataSource: [], action: any })=> {
        if (sorter.order) { //如果选择了排序列
            order.current = sorter.order;
            order_by.current = sorter.field;
        }
        if (filters.status) { //如果选择了过滤列
            onlineStatusFilters.current = filters.status;
        } else {
            onlineStatusFilters.current = [];
        }
        page.current = 1;
        queryLectureBannerList();
    }



    // 表格列
    const cols: any = [
        {
            title: "序号",
            dataIndex: "id",
            key: "id",
            width: 60,
            align: 'left',
            ellipsis: true,
            render:(text, record, index)=>((page.current - 1)* pageSize.current + index + 1)
        },
    
        {
            dataIndex: "cover",
            key: "cover",
            width: 338,
            align: 'left',
            render:(text,record)=>{
                return <div >
                                
                { record.cover?
                    <img
                    style={{cursor:"pointer"}}
                    width={338}
                    height={62}
                    src={ record.cover?.startsWith("http") ? record.cover : constants.BACKEND_URL + record.cover}
                    /> :<div style={{height:62, width:338}}></div>
                }  
            </div>
            }
        },
        {
            title: "题目",
            dataIndex: "title",
            key: "title",
            width: 120,
            align: 'left',
        },
        {
            title: "主讲人",
            dataIndex: "teacher",
            key: "teacher",
            width: 120,
            align: 'left',
        },
        /*{
            title: "时间信息",
            dataIndex: "time_info",
            key: "time_info",
            width: 120,
            align: 'left',
        },
        {
            title: "创建时间",
            dataIndex: "create_time",
            key: "create_time",
            width: 120,
            align: 'left',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },*/
        {
            title: "状态",
            dataIndex: "status",
            key: "status",
            width: 80,
            align: 'center',
            render:(text)=>(text == 1 ? '下线':'在线'),
            filters: [
                {
                    text: '下线',
                    value: 1,
                },
                {
                    text: '在线',
                    value: 2,
                }
            ],
            onFilter: (value:any, record:any) => true,
        },
        {
            title: "操作",
            dataIndex: "actions",
            key: "actions",
            width: 160,
            ellipsis: false,
            align: 'center',
            fixed: "right",
            render: renderActions ,
        },
    ]

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <OAMSider keys={"9"} openKeys={["sub-lecture"]} ></OAMSider>
            <Layout style={{backgroundColor:'#f9f9f9'}}>
                <Content style={{ backgroundColor:'#fff',borderRadius:5,minWidth:400,margin:30 ,paddingTop:20}}>
                
                    <div style={{paddingLeft:30,paddingRight:30}}>
                    <Row align="middle"  gutter={16}>
                        <Col flex='120px'>
                            <Statistic title="Banner总数" value={totalCount} />
                        </Col>
                        <Col flex='80px'>
                            <Statistic title="已上线" value={onlineCount} />
                        </Col>
                        <Col flex='350px'>
                            <Search
                                placeholder="搜索banner"
                                style={{ width: 350 }}
                                enterButton={true}
                                onSearch={onSearchBtn}
                            />

                        </Col>
                       
                        <Col flex='auto' style={{textAlign:'right'}}> <Button style={{borderRadius:20}} type={'primary'} onClick={onUploadBtnClick}>+创建</Button></Col>
                        </Row>
                      

                        <div className={idataCourseTableStyles.tableWrapper}>
                        <ConfigProvider locale={zhCN}>
                            <Table
                                dataSource={bannerListResult}
                                columns={cols}
                                rowKey={record => record.id}
                                pagination={false}
                                scroll={{ x: 800 }}
                               // bordered={true}
                                onChange={onTableChange}
                            />
                        </ConfigProvider>
                        </div>

                        <ConfigProvider locale={zhCN}>
                            <div style={{marginBottom:30,paddingTop:30}} className={"css.pagination"} >
                                <Pagination
                                    defaultCurrent={1}
                                    total={resultSum}
                                    current={page.current}
                                    defaultPageSize={10}
                                    hideOnSinglePage={true}
                                    onChange={onPageChange}
                                    showQuickJumper={true}
                                    showTotal={total => `共 ${total} 讲座`}
                                    pageSizeOptions={["3","20","30","50","100"]}
                                />
                            </div>
                        </ConfigProvider>
                    </div>
                </Content>
            </Layout>
        </Layout>

    )

}

export default OAMLectureBannerList


