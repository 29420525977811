import { Button, Col, Layout, Popconfirm, Row, Statistic,Input, ConfigProvider, Pagination, Table} from 'antd';
import { Content } from 'antd/lib/layout/layout';
const {Search} = Input;
import React, { useEffect, useRef, useState } from "react";
import zhCN from 'antd/lib/locale/zh_CN';
import OAMSider from '../oam-sider';

import idataCourseTableStyles from "../../common/global-styles/idatacourse-table.module.less";

const channelMap = new Map([['爱数科', 1], ['爱数课', 2]]);


//课程的课件
const test_slides_list = [
    {
        id: 1,
        name:"【第一章】数据科学介绍.pptx",//名称
        file_path:"",//文件路径
        download_cnt: 20,//课件下载次数
        size: 1000,
        upload_time: "2021-10-09 08:00",
        status:"关闭",
    },
    {
        id: 2,
        name:"【第二章】缺失值处理.pptx",
        file_path:"",
        download_cnt: 10,//课件下载次数
        size: 1000,
        upload_time: "2021-10-09 08:00",
        status:"开放",
    },
    {
        id: 3,
        name:"【第三章】决策树.pptx",
        file_path:"",
        download_cnt: 30,//课件下载次数
        size: 1000,
        upload_time: "2021-10-09 08:00",
        status:"关闭",
    },
    {
        id: 4,
        name:"【第四章】卷积神经网络.pptx",
        file_path:"",
        download_cnt: 180,//课件下载次数
        size: 1000,
        upload_time: "2021-10-09 08:00",
        status:"开放",
    },
    {
        id: 5,
        name:"【第五章】数据治理.pptx",
        file_path:"",
        download_cnt: 500,//课件下载次数
        size: 1000,
        upload_time: "2021-10-09 08:00",
        status:"开放",
    },
    {
        id: 6,
        name:"【第六章】数据伦理.pptx",
        file_path:"",
        download_cnt: 10,//课件下载次数
        size: 1000,
        upload_time: "2021-10-09 08:00",
        status:"开放",
    },
]


export interface Props {}
export const OAMSlidesList: React.FC<Props> = (props) => {

    const [userResult, setUserResult] = useState([] as any[]);  // 当前显示的结果页
    const [resultSum, setResultSum] = useState(0);  // 搜索返回的结果数
    const [totalCount, setTotalCount] = useState(0);  // 搜索返回的结果数
    const [isEditUserModalVisible, setIsEditUserModalVisible] = useState(false);  // 模态框是否显示
    const [editUserId, setEditUserId] = useState(-1);  // 待编辑备注的用户ID
    const [editUserRemark, setEditUserRemark] = useState("");  // 备注信息
    const queryChannel = useRef(0);  // 来源渠道
    const queryContent = useRef('');  // 记录搜索串
    const order = useRef('');  // 排序升序降序
    const order_by = useRef('');  // 排序列
    const page = useRef(1);  // 记录当前的页数
    const pageSize = useRef(20);  // 记录当前每页展示数量
    const sexFilters = useRef([]);  // 性别过滤选项
    const channelFilters = useRef([]);  // 渠道过滤项
    const userIsLogin = useRef(false);  // 用户是否登录

    useEffect(() => {
        
    }, [])


    //页码和页数发生了变化
    const onPageChange = (newPage: number, newPageSize?: number | undefined) => {
        page.current = newPage ? newPage : 1;
        if (newPageSize) {
            pageSize.current = newPageSize;
        }
       
    }

   
     //点击了查看按钮
  const onLookOverBtnClick = (event:any) => {
    //message.success('查看数据' + event.currentTarget.value)
    event.preventDefault()
    window.open('/lecture-vod?id=' + event.currentTarget.value)
  }

  //点击了编辑按钮
  const onEditBtnClick = (event:any) => {
    //message.success('编辑数据' + event.currentTarget.value)
    event.preventDefault()
    window.open('/oam_video_edit?video_id=' + event.currentTarget.value)
  }

    //点击上传按钮
    const onUploadBtnClick = (event:any) => {
        //message.success('编辑数据' + event.currentTarget.value)
        event.preventDefault()
        window.open('/oam_video_upload')
      }


  // 点击 删除
  const onDeleteBtnClick = (name:string, id:number) => {
  
  }


  // 点了上架按钮
  const onPutOnOffBtnClick = (table_id:string, statusStr:string) => {
   
    
  }



    // 渲染操作
    const renderActions = (text:any, record:any) => {
        return <>
        <Row justify="start" align="middle" gutter={[8, 5]} >
        <Col style={{width:50}}><Button style={{borderRadius:20,fontSize:12}} size='small' id="lookover" value={record.id} type="default" onClick={onLookOverBtnClick} > 查 看 </Button></Col>
        <Col style={{width:50}}><Button style={{borderRadius:20,fontSize:12}} size='small' id="edit" value={record.id} type="dashed" onClick={onEditBtnClick}> 编 辑 </Button> </Col>
            {<Col style={{width:50}}> { parseInt(record.status) == 1 ? 
            <Button style={{borderRadius:20,fontSize:12}}  size='small' id="puton" value={record.id} type="primary"  onClick={onPutOnOffBtnClick.bind(this, record.id, record.title)}> 上 架 </Button>
            :  <Popconfirm placement="top" title={'确认下架视频"' + record.title + '"吗？'} onConfirm={onPutOnOffBtnClick.bind(this, record.id, record.title)} okText="确认" cancelText="取消">
                <Button style={{borderRadius:20,fontSize:12}}  size='small' id="putoff" value={record.id} type="primary"  danger> 下 架 </Button>
                </Popconfirm>}
            </Col> }
            { <Col style={{width:50}}>
            <Popconfirm placement="top" title={'确认删除视频"' + record.title + '"吗？'} onConfirm={onDeleteBtnClick.bind(this, record.title,record.id)} okText="确认" cancelText="取消">
            <Button style={{borderRadius:20,fontSize:12}} size='small' id="delete" danger value={record.id} > 删 除 </Button> 
            </Popconfirm></Col> }
        </Row>
        </>

        }

 
  
    //点击了搜索按钮
    const onSearchBtn = (value: string) => {
        page.current = 1;
        queryContent.current = value;
        //queryUserInfo();
    }

    // 表格发生变化
    const onTableChange:any = (pagination:any, filters:any, sorter:any, extra: { currentDataSource: [], action: any })=> {
        if (sorter.order) { //如果选择了排序列
            //order.current = sorter.order;
            //order_by.current = sorter.field;
        }
        if (filters.sex) { //如果选择了过滤列
            //sexFilters.current = filters.sex;
        } else {
            //sexFilters.current = [];
        }
        if (filters.channel_name) {
            channelFilters.current = filters.channel_name.map(element=> {
                return channelMap.get(element);
            });
        } else {
            channelFilters.current = [];
        }
        page.current = 1;
        //queryUserInfo();
    }

    // 表格列
    const cols: any = [
        {
            title: "序号",
            dataIndex: "id",
            key: "id",
            width: 50,
            align: 'left',
            //sortDirections: ['descend', 'ascend'],
        },
        {
            title: "名称",
            dataIndex: "name",
            key: "name",
            width: 150,
            align: 'left',
            //sortDirections: ['descend', 'ascend'],
        },
        {
            title: "大小",
            dataIndex: "size",
            key: "size",
            width: 50,
            align: 'center',
        },
        {
            title: "下载次数",
            dataIndex: "download_cnt",
            key: "download_cnt",
            width: 60,
            align: 'center',
        },
        {
            title: "上传时间",
            dataIndex: "upload_time",
            key: "upload_time",
            width: 120,
            align: 'center',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: "状态",
            dataIndex: "status",
            key: "status",
            width: 50,
            align: 'center',
        },
        {
            title: "操作",
            dataIndex: "actions",
            key: "actions",
            width: 120,
            ellipsis: false,
            align: 'center',
            render: renderActions ,
        },
    ]

    return (  <Layout style={{ minHeight: '100vh' }}>
        <OAMSider keys={"35"} openKeys={["sub-course"]}></OAMSider>
        <Layout style={{backgroundColor:'#f9f9f9'}}>
                <Content style={{ backgroundColor:'#fff',borderRadius:5,minWidth:400,margin:30 ,paddingTop:20}}>
                
                    <div style={{paddingLeft:30,paddingRight:30}}>
                    <Row align="middle"  gutter={16}>
                        <Col flex='80px'>
                            <Statistic title="课件总数" value={100} />
                        </Col>
                        <Col flex='80px'>
                            <Statistic title="已开放" value={80} />
                        </Col>
                        <Col flex='250px'>

                            <Search
                                placeholder="搜索课件"
                                style={{ width: 250 }}
                                enterButton={true}
                                onSearch={onSearchBtn}
                            />

                        </Col>
                       
                        <Col flex='auto' style={{textAlign:'right'}}> <Button style={{borderRadius:20}} type={'primary'} onClick={onUploadBtnClick}>+上传课件</Button></Col>
                        </Row>
                      

                        <div className={idataCourseTableStyles.tableWrapper}>
                        <ConfigProvider locale={zhCN}>
                            <Table
                                dataSource={test_slides_list}
                                columns={cols}
                                rowKey={record => record.id}
                                pagination={false}
                                //bordered={true}
                                onChange={onTableChange}
                            />
                        </ConfigProvider>
                        </div>

                        <ConfigProvider locale={zhCN}>
                            <div style={{marginBottom:30,paddingTop:30}} className={"css.pagination"} >
                                <Pagination
                                    defaultCurrent={1}
                                    total={resultSum}
                                    defaultPageSize={20}
                                    hideOnSinglePage={true}
                                    onChange={onPageChange}
                                    showQuickJumper={true}
                                    showTotal={total => `共 ${total} 课件`}
                                    pageSizeOptions={["3","20","30","50","100"]}
                                />
                            </div>
                        </ConfigProvider>
                    </div>
                </Content>
        </Layout>
    </Layout>
    )

}

export default OAMSlidesList

