import React, {useEffect, useRef, useState} from "react";
import {
    Statistic,
    Steps,
    Image,
    Table,
    Layout,
    message,
    Col,
    Row,
    ConfigProvider,
    Pagination,
    Tabs,
    Button,
    Tag,
    Tooltip,
    Modal,
    InputNumber,
    Input
} from 'antd';
import { PageHeader } from "../../common/page-header/header";
import { sendHttpPostRequest } from "../../../api/http-request";
import zhCN from 'antd/lib/locale/zh_CN'
import LoginRegisterModal from "../../../component/modal/login-register/login-register";
import {
    RightOutlined,
    LeftOutlined,
    ExportOutlined,
    EyeOutlined,
    DiffOutlined,
    DownloadOutlined,
    FileWordOutlined,
    TableOutlined,
    UpOutlined,
    DownOutlined,
    SearchOutlined
} from '@ant-design/icons';
import testPreviewStyles from "./course-teacher-test-preview.module.less";
import moment from 'moment';
import Cookies from 'js-cookie';
import ExerciseCard from "../../exercise/exercise-card";
import 'echarts-wordcloud';
import * as echarts from 'echarts/core';
import { TitleComponent,TooltipComponent,LegendComponent} from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import Draggable from "react-draggable";
import PageFooter from "../../common/page-footer/footer";
import { BACKEND_API_URL, BACKEND_URL } from '../../../constants/constants';
import examPreviewStyles from "../exam/course-teacher-exam-preview.module.less";
import img1 from "../../../static/image/course/计算机视觉.jpg";
import img2 from "../../../static/image/course/深度学习.jpg";
import img3 from "../../../static/image/course/数据科学导论.jpg";
import img4 from "../../../static/image/course/数据可视化.jpg";

echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer,
  LabelLayout
]);

const {TabPane} = Tabs
const { CheckableTag } = Tag;  

export interface Props {
  test:any;
  exerciseList:any
}



const typeMap = new Map([[1, '实操题'], [2, '单选题'], [3, '多选题'],[4,'排序题'],[5,'主观题']]);
const difficultyMap = new Map([[1, '容易'], [2, '中等'], [3, '困难']]);
   
export interface Props {}
/** 教师查看作业详情页面 */
export const CourseTeacherTestPreview: React.FC<Props> = (props) => {

    const homeworkId = parseInt(window.location.search.split("&")[0].split("=")[1]);//作业ID
    const courseId = parseInt(window.location.search.split("&")[1].split("=")[1]);//课程
    const currentCourseMenuId = parseInt(window.location.search.split("&")[2].split("=")[1]);//课程大纲ID

    const [homework, setHomework] = useState(null as any);  // 作业
    const [homeworkName, setHomeworkName] = useState('');  // 作业名称
    const [homeworkDescription, setHomeworkDescription] = useState('');  // 作业说明
    const [homeworkExercises,setHomeworkExercises] = useState(null as any);

    const [submitTime, setSubmitTime] = useState(null as any);  // 作业提交时间
    const [deadline, setDeadline] = useState(null as any);  // 作业最晚时间
    const [allowDelay, setAllowDelay] = useState(null as any);  // 接受作业延迟提交

    const nowTime = moment().format('YYY-MM-DD HH:mm:ss').slice(2);

    const [studentCompleteList, setStudentCompleteList] = useState(null as any);//学生作业提交情况
    const page = useRef(1);  // 记录当前的页数
    const pageSize = useRef(20);  // 记录当前每页展示数量
    const queryContent = useRef('') // 查询关键字
    const [resultSum, setResultSum] = useState(0);  // 搜索返回的结果数
    const [totalStudentCount, setTotalStudentCount] = useState(0);  // 总学生数
    const [selecetedExerciseIndex, setSelecetedExerciseIndex] = useState(0);//默认选中第一题
    const [selectComplete, setSelectComplete] = useState(0);

    const [submittedStudentIdList, setSubmittedStudentIdList] = useState(null as any);//已提交的学生ID列表
    const [unSubmittedStudentIdList, setUnSubmittedStudentIdList] = useState(null as any);//未提交的学生ID列表

    const order = useRef('');  // 排序升序降序
    const order_by = useRef('');  // 排序列
    const statusFilters = useRef([]);  // 在线状态过滤选项

    const [activeKey, setActiveKey] = useState("1");
  
    const [showTestTableMode,setShowTestTableMode] = useState(true)
    const [showTestExpandMode,setShowTestExpandMode] = useState(false)
    
    
    const [isPublishAnswerModalVisible, setPublishAnswerModalVisible] = useState(false);
    const [isTestModalVisible, setIsTestModalVisible] = useState(false);

  
    const [completeStatus, setCompleteStatus] = useState([] as any []); // 单个学生完成情况

    const [systemScore, setSystemScore] = useState(0);    // 系统评分
    const [manualScore, setManualScore] = useState(0 as any );   // 手动评分
    const [tempManualScore, setTempManualScore] = useState(0 as any );   // 临时分
    const exerciseId = useRef(0);  // 题目id
    const [exerciseResult, setExerciseResult] = useState([] as any[]);  // 当前习题
    const [exerciseIdList, setExerciseIdList] = useState([] as any []) // 题目id列表
    const studentId = useRef(0);   // 学生id
    const [studentName, setStudentName] = useState("");   // 学生昵称
    const [studentRealName, setStudentRealName] = useState("");   // 学生真实姓名
    const [studentAvatar, setStudentAvatar] = useState('static/image/avatar/default_avatar.png');   // 学生头像

    const [disabled,setDisabled] = useState(true)
    const [bounds,setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const draggleRef = useRef<HTMLDivElement | null>(null)
    const onStart = (event:any, uiData:any) => {
      const { clientWidth, clientHeight } = window?.document?.documentElement;
      const targetRect= draggleRef?.current?.getBoundingClientRect();
      if(targetRect){
        setBounds( {
          left: -targetRect?.left + uiData?.x,
          right: clientWidth - (targetRect?.right - uiData?.x),
          top: -targetRect?.top + uiData?.y,
          bottom: clientHeight - (targetRect?.bottom - uiData?.y),
        }
      )
      }
    }

    const [imgs, setImgs] = useState([] as any []);
    const [ppt, setPpt] = useState([] as any []);
    // const imgs = [
    //   {
    //     id:1,
    //     src: img1,
    //   },{
    //     id:2,
    //     src:img2,
    //   },{
    //     id:3,
    //     src:img3,
    //   },{
    //     id:4,
    //     src:img4
    //   }
    // ]

    // const ppt =[{name:'大数据与人工智能.pdf',size:'30M'},{name:'数据可视化.pdf',size:'20M'}]

    useEffect(()=>{
      queryHomework();
      queryHomeworkSubmission();
    },[])

      // 基本习题框架
    const baseExerciseInfo:any = {
      answer_parse: "",
      create_time: "",
      creator: "",
      creator_id: 1,
      dataset_path: "",
      datasets: [],
      default_code: "",
      difficulty: 1,
      difficulty_str: "",
      exam_id: 0,
      hint_submit_btn: 1,
      id: 1,
      images: [],
      is_delete: 1,
      judgement_code: "",
      language: 2,
      language_str: "python3",
      options: [{id: "1", options: "1"}, {id: "2", options: "2"}, {id: "3", options: "3"}, {id: "4", options: "4"}],
      options_answer: [],
      preset_code: "",
      reference_answer: "",
      remarks: "",
      score: 0,
      source: 1,
      source_key: 4,
      source_page: 4,
      status: 1,
      steps: "",
      submission_info: {
        is_html: false,
        submission_answer: [],
        submission_code: "",
        submission_id: 0,
        submission_image: [],
        submission_output: "",
        submission_status: 3,
        submission_time: "",
        submission_variables: []
      },
      title: "",
      type: 8,
      type_name: "",
      update_time: ""
    }

        // 查询作业回调
        const queryHomeworkCallback = (response:any) => {
          message.destroy();
          if (response.code == -100) {
              // 未登录
              document.getElementById("show-login-modal")!.click();
          } else if (response.is_ok == true) {
              // 成功
              setHomework(response.query_info);
              setHomeworkName(response.query_info.name);
              setHomeworkDescription(response.query_info.description);
              setHomeworkExercises(response.query_info.exercise);
  
              response.query_info.submit_time.length > 0 ? setSubmitTime(response.query_info.submit_time) : '';
              response.query_info.deadline.length > 0 ? setDeadline(response.query_info.deadline) : '';
              response.query_info.allow_delay ? setAllowDelay(response.query_info.allow_delay) : '';

              let _exerciseIdList = [] as any [];
              response.query_info?.exercise?.map(item => {
                _exerciseIdList.push(item.id);
              })
              setExerciseIdList(_exerciseIdList);
              // 成功
              if (response.query_info.exercise.length > 0) {
                exerciseId.current = response.query_info.exercise[0]["id"];
              }

          } else if (response.code == -501) {
              // 权限不足
              message.error("权限不足")
          } else {
              message.error(response.message);
          }
      }
  
      // 查询作业信息列表
      const queryHomework = () => {
          sendHttpPostRequest(
              'homework/api/user_query_homework_by_id/',
              {
                  sessionid: Cookies.get("sessionid"),
                  homework_id: homeworkId,
                  course_id: courseId
              },
              queryHomeworkCallback
          )
      }
    /** 计算作业总分 */
    const getTotalScore = ()=>{
      let sumScore = 0;
      homeworkExercises?.map(item=>{
          if (parseInt(item.score) == 0)
              sumScore += 5;
          else
              sumScore += parseInt(item.score);
      })
  
      return sumScore
    }

    const onSearchValueChange = (event:any) => {
        // console.log(event.target.value)
        queryContent.current = event.target.value;
    }

    const onSearchBtn = () => {
        page.current = 1
        queryHomeworkSubmission()
    }

    const handleTestOk = () => {
      setIsTestModalVisible(false);
    };
  
    const handleTestCancel = () => {
      setIsTestModalVisible(false);
    };
   

     /** 答题状态和颜色的映射  背景色*/
     const getColorByStatus = (status:number, type:number)=>{
      
      if (status == 1) {//未做
        return "#fff"
      }else {
        if(type == 5 ){//做了，是主观题
          return "#47B1DF"
        }else {
          if (status == 2){
            return "#38CD7F"
          }else {
            return "#FF6F73"
          }
      }
    
    }
  }

    /** 答题状态和题号颜色的映射 */
    const getFontColorByStatus = (status:number, type:number)=>{
      if (status == 1) {
        return "#999"
      }else{
        return "#fff"
      }
    }

    /** 答题状态和题号颜色的映射 */
    const getBorderByStatus = (status:number, type: number)=>{
      if (status == 1) {
        return "1px solid #999"
      }else if(type == 5){//做了，是主观题
        return "1px solid #47B1DF"
      }else if (status == 2){
        return "1px solid #38CD7F"
      }else{
        return "1px solid #FF6F73"
      }
    }


  // 完成情况渲染操作
  const renderCompleteActions = (score:number,record:any) => {
    return <Row align='middle' justify='center' gutter={[16,8]}> 
              <Col><Button style={{ color:'#07C160',cursor:'pointer',border:0,backgroundColor:'transparent',boxShadow:'0 0 0'}}  onClick={()=>{lookatTest(record)}}> 查看 </Button>  </Col>
    </Row>
  }

        // 根据id查询习题得分,主观题
        const queryExerciseScoreById = (studentId: any, exerciseId: any) => {
          sendHttpPostRequest(
              'homework/api/user_query_exercise_score_by_id',
              {
                  sessionid: Cookies.get("sessionid"),
                  sid: Cookies.get("sessionid"),
                  homework_id: homeworkId,
                  course_id: courseId,
                  exercise_id: exerciseId,
                  student_id: studentId
              },
              (response: any) => {
                  if (response.code == -100) {
                      // 未登录
                      document.getElementById("show-login-modal")!.click();
                  } else if (response.is_ok) {
                      setSystemScore(response.system_score);
                      setManualScore(response.manual_score);
                      setTempManualScore(response.manual_score);
                  } else {
                      console.error(response.message);
                  }
              }
          )
      }

      // 主观题手动打分
      const setExerciseScoreById = (studentId: any, exerciseId: any, score: any) => {
          sendHttpPostRequest(
              'homework/api/user_set_exercise_score_by_id',
              {
                  sessionid: Cookies.get("sessionid"),
                  sid: Cookies.get("sessionid"),
                  homework_id: homeworkId,
                  course_id: courseId,
                  exercise_id: exerciseId,
                  student_id: studentId,
                  score: score
              },
              (response: any) => {
                if(response.is_ok == true){
                  message.success("评分保存成功~");
                  setManualScore(tempManualScore);
                }else{
                  message.error("评分保存失败~");
                }
              }
          )
      }


  const queryExerciseById = () => {
    setExerciseResult(baseExerciseInfo);
    let _params = {
      sessionid: Cookies.get("sessionid"),
      sid: Cookies.get("sessionid"),
      exercise_id: exerciseId.current,
      source_page: 3,  // 作业3
      source_key: 3,   // 学生3
      homework_id: homeworkId,    // 作业id
      hint_submit_btn: 2,   // 隐藏提交按钮，老师只能查看学生提交，不能代其提交
      show_answer:1,//返回答案信息
    }
    if (0 != studentId.current) {
      _params["user_id"] = studentId.current   // 查询的用户id，不传默认查自己
    }
    sendHttpPostRequest('exercise/api/query_exercise_by_id/',
        _params,
        (response: any) => {
          message.destroy();
          if (response.code == -100) {
            // 未登录
            document.getElementById("show-login-modal")!.click();
          } else if (response.is_ok) {
            setExerciseResult(response.exercise_info);
            if (response.exercise_info.type == 5) {
                // 主观题查得分
                // 作业不打分 2022-03又改为打分
                queryExerciseScoreById(response.exercise_info.submission_info.submitter_id, response.exercise_info.id);
                queryStudentAttachmentList(response.exercise_info.id);
            }
          } else {
            message.error(response.message);
          }
        }
    )
  }

    // 查询 主观题 学生 上传的 附件（图片） 列表
    const queryStudentAttachmentList = (exerciseId: number) => {
        sendHttpPostRequest(
            'homework/api/user_query_student_homework_attachment',
            {
                sessionid: Cookies.get("sessionid"),
                sid: Cookies.get("sessionid"),
                homework_id: homeworkId,
                exercise_id: exerciseId,
                student_id: studentId.current,
            },
            (response: any) => {
                if (response.is_ok) {
                    setImgs(response.image_list);
                    setPpt(response.attachment_list);
                }
            }
        )
    }


  const lookatTest  = (record: any) => {
    //setIsModalVisible(false);
    //setIsTestModalVisible(true);
    console.log(record);
    const _studentId = record.id;
    sendHttpPostRequest(
        'homework/api/user_query_student_homework_submission',
        {
          sessionid: Cookies.get("sessionid"),
          sid: Cookies.get("sessionid"),
          homework_id: homeworkId,
          course_id: courseId,
          student_id: _studentId
        },
        (response: any)  => {
          message.destroy();
          if (response.code == -100) {
            // 没登录
            document.getElementById("show-login-modal")!.click();
          } else if (response.code == -501) {
            message.error("权限不足");
          } else if (response.is_ok == true) {
            setCompleteStatus(response.query_list);
            setStudentName(response.user_name);
            setStudentRealName(response.user_real_name);
            setStudentName(response.user_name);
            (response.user_avatar && response.user_avatar !== '' )? setStudentAvatar(response.user_avatar) : '';
            // setStudentId(parseInt(response.user_id));
            studentId.current = parseInt(response.user_id);
            if (response.query_list.length > 0) {
              queryExerciseById();   // 查询第一个题的提交情况
            }
            setIsTestModalVisible(true);
          } else {
            message.error(response.message);
          }
        }
    )
  }

    // 表格发生变化
    const onStudenTableChange:any = (pagination:any, filters:any, sorter:any, extra: { currentDataSource: [], action: any })=> {
        if (sorter.order) { //如果选择了排序列
            order.current = sorter.order;
            order_by.current = sorter.field;
        } else {
            order.current = '';
            order_by.current = '';
        }
        if (filters.status) { //如果选择了过滤列
            statusFilters.current = filters.status;
        } else {
            statusFilters.current = [];
        }

        page.current = 1;
        queryHomeworkSubmission();
    }

  /** 完成情况列 */
  const complete_cols:any = [
    {
      title: '序号',
      dataIndex: 'id',
      key: 'id',
      align: 'left',
      width: 80,
      render:(text, record, index)=>(pageSize.current * (page.current -1) + index + 1)
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: 80,
      render:(text,record)=>(record.real_name !== ''? record.real_name : text)
    },
    {
      title: '学号',
      dataIndex: 'number',
      key: 'number',
      align: 'left',
      width: 80,      
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 80,
        filters: [
            {
                text: '未参加',
                value: 1,
            },
            {
                text: '进行中',
                value: 2,
            },
            {
                text: '已提交',
                value: 3,
            },
            {
                text: '延迟提交',
                value: 4,
            }
        ],
        onFilter: (value:any, record:any) => true,
    },
    {
      title: '作业提交时间',
      dataIndex: 'time',
      key: 'time',
      align: 'center',
      width: 130,
      render:(text)=>((text && text !== '')? text?.slice(0,16):text)    
    },    
    {
      title: '成绩',
      dataIndex: 'score',
      key: 'score',
      align: 'center',
      width: 80,
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    },
    {
      title: "操作",
      dataIndex: "actions",
      key: "actions",
      width: 130,
      ellipsis: false,
      align: 'center',
      //fixed: 'right',
      render: renderCompleteActions,
    }
  ];

  const handleTestTableModeChange = (checked) => {
      setShowTestTableMode(true)
      setShowTestExpandMode(false)
      setActiveKey("2");
  }

  const handleTestExpandModeChange = (checked) => {
      setShowTestTableMode(false)
      setShowTestExpandMode(true)
      setActiveKey("2");
  }


    // 查作业提交情况
    const queryHomeworkSubmission = () => {
      sendHttpPostRequest(
          'homework/api/user_query_homework_submission',
          {
              sessionid: Cookies.get("sessionid"),
              sid: Cookies.get("sessionid"),
              homework_id: homeworkId,
              course_id: courseId,
              page: page.current,
              pagesize: pageSize.current,
              query: queryContent.current,
              order: order.current,
              order_by: order_by.current,
              status: JSON.stringify(statusFilters.current)
          },
          (response: any) => {
            message.destroy();
            if (response.code == -100) {
                // 未登录
                document.getElementById("show-login-modal")!.click();
            } else if (response.is_ok == true) {
                // 成功
 
                setStudentCompleteList(response.query_list)
                setTotalStudentCount(response.student_count);
                setResultSum(response.total_sum);
                setSubmittedStudentIdList(response.committed_user_list)
                setUnSubmittedStudentIdList(response.uncommitted_user_list)
          
            } else if (response.code == -501) {
                // 权限不足
                message.error("权限不足")
            } else {
                message.error(response.message);
            }
          }
      )
    }

    //页码和页数发生了变化
    const onPageChange = (newPage: number, newPageSize?: number | undefined) => {
        page.current = newPage ? newPage : 1;
        if (newPageSize) {
            pageSize.current = newPageSize;
        }
        queryHomeworkSubmission();
    }


  // 渲染类型
  const renderType = (text: any, record: any) => (
      <div>{typeMap.get(parseInt(text))}</div>
  )

  // 渲染难易程度
  const renderDifficulty = (text: any, record: any) => (
      <div>{difficultyMap.get(parseInt(text))}</div>
  )

  const [selectedRowKeys,setSelectedRowKeys] = useState([])
  
  const hasSelected = selectedRowKeys.length > 0
  
  const onSelectChange = (selectedRows:any) => {
    setSelectedRowKeys(selectedRows)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    preserveSelectedRowKeys:false,
  }

  // 渲染分值列
  const renderScore = (text:any, record:any) => {  
     return <div style={{width:50,height:30}} >{text}</div>    
  }
 
  const exercise_columns:any = [
    {
      title: '题号',
      dataIndex: 'id',
      key: 'id',
      align: 'left',
      width: 60,
      render:(text,record,index)=>(index + 1)
    },
    {
      title: '题目',
      dataIndex: 'title',
      key: 'title',
      align: 'left',
      width: 200,
      ellipsis:"true"
    },
    {
      title: '题型',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      width: 100,
      render: renderType,
    },
    {
      title: '难度',
      dataIndex: 'difficulty',
      key: 'difficulty',
      align: 'center',
      width: 80,
      render: renderDifficulty, 
    },
    {
      title: '分值',
      dataIndex: 'score',
      key: 'score',
      align: 'center',
      width: 50,
      render:renderScore,  
    },  
  ];


    const saveHomeworkParams = (params: any) => {
        sendHttpPostRequest(
            'homework/api/user_update_homework/',
            params,
            (response:any) => {
              message.destroy();
              if (response.code == -100) {
                  // 没登录
                  window.location.href = '/login?next=course-main-teacher?course_id=' + courseId + "&tabKey=4";
              } else if (response.code == -501) {
                  // 没权限
                  window.location.href = '/login?next=course-main-teacher?course_id=' + courseId + "&tabKey=4";
              } else if (response.is_ok == true) {
                  message.success("作业信息保存成功~")
              }else {
                  message.error(response.message);
              }
          }
        )
    }

    /** 确认公布考试答案 */
    const handlePublishAnswerOk = () => {
      setPublishAnswerModalVisible(false);
      const params = {
        sessionid: Cookies.get("sessionid"),
        homework_id: homeworkId,
        publish_answer: 2//公布答案
      }
      saveHomeworkParams(params);
      queryHomework();
    };
  
    /** 取消公布考试答案 */
    const handlePublishAnswerCancel = () => {
      setPublishAnswerModalVisible(false);
    };



  /** 渲染预览的习题 */
  const renderExercisePreview = (record:any) => {
    return <ExerciseCard exercise={record} renderType="white" teacherOnly={true} editorMode={true} showAnser={false} showClean={true} />
  }

  /** 点击了习题方块按钮 */
  const onExerciseBtnClick = (index) => {
    setSelecetedExerciseIndex(index);
      exerciseId.current = exerciseIdList[index];
      queryExerciseById();
  }

  // 公布成绩
  const announceResult = () => {
    setPublishAnswerModalVisible(true);
    setIsTestModalVisible(false);
  }

    /** 成绩导出 */
    const scoreExport = () => {
        const sessionid = Cookies.get("sessionid");
        const queryData = {
            course_id: courseId,
            sessionid: sessionid,
            homework_id: homeworkId,
            sid: sessionid,
        };
        sendHttpPostRequest(
            'homework/api/export_homework_score',
            queryData,
            (response: any) => {
                if (response.code == -100) {
                    // 未登录
                    document.getElementById("show-login-modal")!.click();
                } else if (response.is_ok) {
                    window.open(BACKEND_URL + response.url)
                }
            }
        )
    }

  const typeCountMap = new Map([['实操题', 0], ['单选题', 0], ['多选题', 0],['排序题',0],['主观题', 0]]);
  const difficultyCountMap = new Map([['容易', 0], ['中等', 0], ['困难', 0]]);

  homeworkExercises?.map(item => {
    const typeCount = Number(typeCountMap.get(typeMap.get(item.type) as string));
    typeCountMap.set(typeMap.get(item.type) as string, typeCount + 1);

    const diffCount = Number(difficultyCountMap.get(difficultyMap.get(item.difficulty) as string));
    difficultyCountMap.set(difficultyMap.get(item.difficulty) as string, diffCount + 1);

  })

    /**
     * 编辑作业信息
     */
    const onEditHomeworkClick = () => {
      window.location.href = `course-new-test?test_id=${homeworkId}&course_id=${courseId}&menu_id=${currentCourseMenuId}`;
    }

  /** 完成情况面板 */
  const TestComplete  = ()=>{
      return <div style={{ marginTop: 20, marginBottom: 20,position:'relative'}} className={testPreviewStyles.tabsDiv}>

        <Tabs defaultActiveKey={activeKey} type="line" size="middle" tabPosition="top" onChange={(key:any)=>{setActiveKey(key)}}>
          <TabPane tab={<div>完成情况</div>} key="1" >
              <div style={{display:'inline-block',position:'absolute',top:10,right:0,color:'#333'}}>
                共<span style={{color:'#07C160',margin:2}}>{totalStudentCount}</span>人 已提交<span style={{color:'#07C160',margin:2}}>{submittedStudentIdList?.length}</span>人 未提交<span style={{color:'#07C160',margin:2}}>{unSubmittedStudentIdList?.length}</span>人
              </div>
              <div className={testPreviewStyles.tableWrapper}>
                  <Row style={{margin:"5px 0 20px 0"}}>
                      <Col style={{textAlign:'left'}} span={24}>
                          <Input
                              onPressEnter={onSearchBtn}
                              placeholder="姓名/学号/手机号"
                              style={{float:'left',borderRadius:20,width:200 }}
                              suffix={<SearchOutlined  style={{color:"#666666"}} />}
                              onChange={onSearchValueChange}
                          />
                      </Col>
                  </Row>
                <ConfigProvider locale={zhCN}>
                  <Table
                    dataSource={studentCompleteList}
                    columns={complete_cols}
                    size='small'
                    rowKey={record => record.id}
                    onChange={onStudenTableChange}
                    pagination={false} />

                  <Pagination
                    className={testPreviewStyles.pagination}
                    style={{ marginTop: 20 }}
                    defaultCurrent={1}
                    current={page.current}
                    total={resultSum}
                    hideOnSinglePage={true}
                    onChange={onPageChange}
                    pageSize={pageSize.current} />
                </ConfigProvider>
              </div>

          </TabPane>

          <TabPane tab={<div>题目列表</div>} key="2">
            <div style={{display:'inline-block',position:'absolute',top:10,right:0,color:'#00000073'}}>
                共<span style={{color:'#07C160',marginLeft:2,marginRight:2}}>{homeworkExercises?.length}</span>题 
                实操<span style={{color:'#07C160',marginLeft:2,marginRight:4}}> {typeCountMap.get("实操题")}</span>
                单选<span style={{color:'#07C160',marginLeft:2,marginRight:4}}> {typeCountMap.get("单选题")}</span>
                多选<span style={{color:'#07C160',marginLeft:2,marginRight:4}}> {typeCountMap.get("多选题")}</span>
                排序<span style={{color:'#07C160',marginLeft:2,marginRight:10}}>{typeCountMap.get("排序题")}</span>
                主观<span style={{color:'#07C160',marginLeft:2,marginRight:10}}>{typeCountMap.get("主观题")}</span>         
              <span>
                <CheckableTag key={'table'} checked={showTestTableMode} onChange={handleTestTableModeChange}>  <Tooltip placement="top" title=""> <TableOutlined /> 列表 </Tooltip></CheckableTag>
                <CheckableTag key={'expand'} checked={showTestExpandMode} onChange={handleTestExpandModeChange}> <Tooltip placement="top" title=""> <FileWordOutlined /> 展开 </Tooltip> </CheckableTag>
              </span>
            </div>

            {showTestTableMode &&
              <div className={testPreviewStyles.tableWrapper}>
                <ConfigProvider locale={zhCN}>
                  <Table
                    dataSource={homeworkExercises}
                    columns={exercise_columns}
                    size='small'
                    rowKey={record => record.id}
                    pagination={false}
                    //onChange={onTableChange}
                    //rowSelection={rowSelection}
                    expandable={{
                      columnWidth: 90,
                      childrenColumnName: " ",
                      expandIconColumnIndex:6,
                      expandIcon:({ expanded, onExpand, record }) =>
                      expanded ? (
                       <div onClick={e => onExpand(record, e)} style={{cursor:'pointer'}}>预览<UpOutlined  className={testPreviewStyles.actionBtn} /></div>
                      ) : (
                       <div onClick={e => onExpand(record, e)} style={{cursor:'pointer'}}>预览<DownOutlined className={testPreviewStyles.actionBtn} /></div>
                      ),
                      expandedRowRender: renderExercisePreview,
                      rowExpandable: record => record.name !== 'Not Expandable',
                      }}
                    />

             
                </ConfigProvider>
              </div>}

            {showTestExpandMode && <div>
              {homeworkExercises.map((exercise,index) => (
                <Row wrap={false} key = {exercise.id} style={{marginBottom:10,padding:10,borderColor:"#F5FAF7",borderWidth:1,borderStyle:"solid"}}>     
                <Col flex="50px" style={{marginTop:3,textAlign:'right',fontWeight:700}}>{index+1}.</Col>
                <Col flex="auto"> <ExerciseCard  exercise={exercise} renderType="white" teacherOnly={true} editorMode={true} showAnser={false} showClean={true} /></Col>
                <Col flex="50px" style={{marginTop:3,textAlign:'right'}}>({exercise?.score}分)</Col>
              </Row>
              ))}
            </div>}

          </TabPane>
        </Tabs>
      </div>;
  }

  return (
    <Layout style={{minHeight: 750,backgroundColor: '#f9f9f9'}}>
        
        <LoginRegisterModal></LoginRegisterModal>
        <div className={testPreviewStyles.courseMain} style={{minHeight:"calc(100vh - 226px)",width:800}}>
          <div id="testinfo">
            <Row align="middle" style={{borderBottom: '1px solid #eee',height:60}}>
              <Col span={12} style={{textAlign:"left"}}> <div style={{fontWeight:700,fontSize:16}}>查看作业信息</div> </Col>
              <Col span={12} style={{color:"#333333",textAlign:"right"}}>  <a style={{color:"#333333"}} href={`/course-main-teacher?course_id=${courseId}&tabKey=4`}>返回&gt;</a> </Col>
            </Row>
            <Row style={{marginTop:20}}>
              <Col style={{textAlign:'left'}} span={12}> 
                  <Row align='middle' justify='start' gutter={[6,6]}>
                    <Col style={{fontWeight:700,fontSize:16,textAlign:'left'}} span={24}> {homeworkName} </Col>
                    <Col style={{textAlign:'left'}} span={24}>截止时间： {submitTime?.slice(0,16)} </Col>
                    <Col style={{textAlign:'left'}} span={24}> 共 <span style={{color:'#07C160'}}>{homeworkExercises?.length}</span> 道题    总分<span style={{marginLeft:5,marginRight:5,color:'#07C160'}}>{getTotalScore()}</span> 分 </Col>
                  </Row>              
              </Col>
              <Col style={{textAlign:'right'}} span={12}> 
                {deadline > nowTime ? <Button  type="primary" style={{ borderRadius:20,marginTop:40}} onClick={onEditHomeworkClick}> 编辑作业信息</Button>
                : <div style={{width:100,float:'right'}}>
                    <Button onClick={()=>{setPublishAnswerModalVisible(true)}} hidden={homework?.publish_answer == 2} style={{ display:'block',color:'#07c140',borderColor:'#07c140',borderRadius:20}}><DiffOutlined /> 公布答案</Button>
                    <Button hidden={homework?.publish_answer == 1} style={{ display:'block',color:'#07c140',borderColor:'#07c140',borderRadius:20}}><DiffOutlined /> 答案已公布</Button>
                    <Button hidden={homework?.publish_answer == 1} style={{ color:'#07c140',borderColor:'#07c140',borderRadius:20,marginTop:15}} onClick={()=>{scoreExport()}}><ExportOutlined /> 导出成绩</Button>
                  </div>
                }
              </Col>
            </Row>
            <Row style={{marginTop:20}}>
              <Col style={{fontWeight:500,textAlign:'left'}} span={24}>作业说明:</Col>
              <Col style={{textAlign:'left'}} span={24}> {homeworkDescription} </Col>
            </Row>
            { TestComplete()}
          </div>
          
          <Modal closable={false} width={400} title="提示" visible={isPublishAnswerModalVisible} 
          bodyStyle={{borderBottom:0}}
          className={testPreviewStyles.announceModal}
          footer={
            <div style={{textAlign:'center',height:50}}>
            <Button onClick={handlePublishAnswerCancel} style={{width:80,borderRadius:5,borderColor:'#07c160',color:'#07c160',marginRight:20}}>取消</Button>
            <Button type="primary" onClick={handlePublishAnswerOk } style={{width:80,borderRadius:5,backgroundColor:'#07c160',color:'#fff'}}>确认</Button>
            </div>
          }>
              <div style={{margin: '20px auto',width:200,textAlign:'center'}}>您确认要公布答案吗?</div>
          </Modal>

          <Modal  
                title={<div
                        style={{width: '100%',cursor: 'move',}}
                        onMouseOver={() => {if (disabled) {setDisabled(false)}}}
                        onMouseOut={() => {setDisabled(true)}}
                        onFocus={() => {}}
                        onBlur={() => {}}>
                          <Row gutter={[4,4]} align="middle" wrap={false}>
                            <Col span={12}>
                              <img src={BACKEND_API_URL +  studentAvatar}  alt="" style={{verticalAlign:'middle',margin:2.4,width:30,height:30,borderRadius:15}}/>
                              <span> {studentRealName !== "" ? studentRealName : studentName} </span>
                            </Col>
                            <Col span={12} style={{textAlign:"right"}}>
                              <Row gutter={[4,4]} align="middle" justify="end" wrap={false} style={{marginRight: 40}}>
                                <Col>
                                  <div style={{borderRadius:2,backgroundColor:getColorByStatus(1,1),color:getFontColorByStatus(1,1),border:getBorderByStatus(1,1),height:16,width:16}}>{''}</div>
                                </Col>
                                <Col>
                                  <span style={{fontSize:12, fontWeight:400}}>未答</span>
                                </Col>
                                <Col>
                                  <div style={{borderRadius:2,backgroundColor:getColorByStatus(2,1),color:getFontColorByStatus(2,1),border:getBorderByStatus(2,1),height:16,width:16}}>{''}</div>
                                </Col>
                                <Col><span style={{fontSize:12, fontWeight:400}}>正确</span>
                                </Col>
                                <Col>
                                  <div style={{borderRadius:2,backgroundColor:getColorByStatus(3,1),color:getFontColorByStatus(3,1),border:getBorderByStatus(3,1),height:16,width:16}}>{''}</div>
                                </Col>
                                <Col> <span style={{fontSize:12, fontWeight:400}}>错误</span>
                                </Col>
                                <Col>
                                  <div style={{borderRadius:2,backgroundColor:getColorByStatus(3,5),color:getFontColorByStatus(3,5),border:getBorderByStatus(3,5),height:16,width:16}}>{''}</div>
                                </Col>
                                <Col><span style={{fontSize:12, fontWeight:400}}>主观题</span>
                                </Col>
                                </Row>
                            </Col>
                          </Row>
                      </div>}
                width={800} 
                bodyStyle={{margin:10,padding:10,height:600,overflowY:'auto'}} 
                footer={[]}
                destroyOnClose={true}
                modalRender={modal => (<div  className={examPreviewStyles.createDiv}>
                  <Draggable
                  disabled={disabled}
                  bounds={bounds}
                  onStart={(event, uiData) => onStart(event, uiData)}
                  >
                  <div ref={draggleRef}>{modal}</div>
                  </Draggable> </div>
                )}
                 visible={isTestModalVisible} onOk={handleTestOk} onCancel={handleTestCancel}
                >
                <Row align='middle' justify= {'start'} gutter={[2,2]} className={examPreviewStyles.completeIndex} style={{textAlign:"center",width:"100%",marginBottom:10,padding:'0 5px 0 12px'}}> 

                  <Col span={24}>
                    <Row style={{paddingLeft:3}}>
                      {homeworkExercises && completeStatus?.map((status:any,index:number)=>(
                        <Col key={index}>
                          <div onClick={()=>{onExerciseBtnClick(index)}}
                          style={{cursor:'pointer',backgroundColor:getColorByStatus(status,homeworkExercises[index].type),color:getFontColorByStatus(status,homeworkExercises[index].type),border:getBorderByStatus(status,homeworkExercises[index].type),margin:6,borderRadius:2,textAlign:"center",height:20,lineHeight:'18px',minWidth:20,fontSize:12}}>
                            {index+1}
                          </div> 
                        </Col>
                      ))}
                    </Row>
                  </Col>

                </Row>  

                <Row style={{padding:'0 20px 0 10px'}} wrap={false} >
                  <Col flex="50px" style={{marginTop:1,fontSize:16,textAlign:'right',fontWeight:600}}>{selecetedExerciseIndex+1}.</Col>   
                  <Col flex="auto"><ExerciseCard key={selecetedExerciseIndex} exercise={exerciseResult} renderType="white" teacherOnly={true} editorMode={false} showAnser={true} showClean={false} /></Col>
                  { homeworkExercises && <Col flex="80px" >（{completeStatus[selecetedExerciseIndex] == 2? homeworkExercises[selecetedExerciseIndex].score : (exerciseResult["type"] == 5 ? manualScore : 0)}/{homeworkExercises[selecetedExerciseIndex].score}分）</Col> }
                </Row>

                {exerciseResult["type"] == 5   &&
                  <Row className={testPreviewStyles.imgList} style={{width:600,marginLeft:60,marginBottom:10}}>
                    {imgs.map(img=>{
                      return <Col key={img.id} flex="140px" className={testPreviewStyles.previewImageWrap}>
                        {/* <div style={{position:'absolute',top:5,right:5,width:20,height:20,textAlign:'center',borderRadius:10,backgroundColor:'#fff',display:'none'}}>
                          <EyeOutlined  style={{color:'#07c160'}} onClick={outputShowFull.bind(this, img.id)}/>
                        </div> */}
                        {/* <div id={"img-preview-wrapper" + img.id} style={{backgroundColor:'#fff',textAlign:'center',lineHeight:outputIsFullScreen?'100vh':'initial'}}> */}
                          <Image src={img.url?.startsWith("http") ? img.url : BACKEND_URL + img.url} style={{width:140,height:90,borderRadius:4}} preview={{mask:<EyeOutlined/>}}/>
                        {/* </div> */}
                        {/* <img src={img.url.startsWith("http") ? img.url : BACKEND_URL + img.url} alt="" style={{width:140,height:90,borderRadius:4}}/> */}
                      </Col>
                    })
                    }
                  </Row>
                }
                
                {exerciseResult["type"] == 5   &&
                  <div style={{width:600,marginLeft:60,marginBottom:30}}>
                    {ppt.map(PPT=> {
                      return <Row key={PPT.name} style={{backgroundColor:'#FBFBFB',paddingLeft:10,borderRadius:3,marginBottom:10}}>
                        <Col flex="auto">
                          {PPT.name}
                          <span style={{textAlign:'center',color:'#999',fontSize:12,marginLeft:10}}>{PPT.size}</span>
                        </Col>
                        <Col flex="50px" style={{textAlign:'center'}}><a href={PPT.url.startsWith("http") ? PPT.url : BACKEND_URL + PPT.url} target={"_blank"}><DownloadOutlined style={{color:'#999'}}/></a></Col>
                      </Row>
                    })
                    }
                  </div>
                }
                

              { exerciseResult["type"] == 5   &&

                <Row style={{padding:'0 20px 0 10px'}} wrap={false} >
                <Col flex="50px" style={{marginTop:1,fontSize:16,textAlign:'right',fontWeight:700}}></Col>   

                <Col flex="auto" >
                  <Row gutter={[10,10]} align="middle" justify="start" style={{marginBottom:10}}>
                      <Col flex={"80px"}> 系统评分：</Col>
                      <Col flex={"100px"} style={{color:"#07C160"}}>{systemScore}</Col>
                      <Col  flex={"80px"}>重新评分：</Col>
                      <Col  flex={"100px"}>
                        {  homework?.status == '已完成' ? 
                        <div  style={{color:"#07C160",width: 80,textAlign:'left'}} >{manualScore}</div> 
                          :<>
                          <InputNumber min={0} max={homework?.exercise?.filter(item=>item.id==exerciseResult["id"])[0].score} maxLength={5} style={{width: 80,textAlign:'left'}} key={"score-" + manualScore} name={"manual"} value={tempManualScore} onChange={(value)=>{setTempManualScore(value)}} /> 
                        </>
                          }
                      </Col>
                     {  homework?.status != '已完成'  && <Col flex={"80px"} style={{textAlign:"left"}}>
                      <Button style={{width: 60,textAlign:'left'}}  onClick={()=>{setExerciseScoreById( exerciseResult["submission_info"]["submitter_id"], exerciseResult["id"], tempManualScore);}} type="primary" > 确定 </Button>
                          
                      </Col>
                      }
                        <Col span={24} style={{fontSize:12, color:"#999"}}> 提示：系统评分是爱数课AI评分引擎根据学生输入内容和参考答案的匹配程度自动给出的评分。老师如果需要对系统评分进行修正，可人工进行评分。 </Col>
                      
                  </Row>
                </Col>
                <Col flex="80px"></Col>
                </Row>
              }
                                  
           </Modal>
        </div>
        
    </Layout>
  )

}

export default CourseTeacherTestPreview

