import {
  Tabs,
  Pagination,
  Layout,
  message,
  ConfigProvider,
  Input,
  Table,
  Modal,
  Button,
  Row,
  Statistic,
  Col,
  Popconfirm,
} from 'antd'
import { Content } from 'antd/lib/layout/layout'
import React, { useState, useRef, useEffect } from 'react'
import Cookies from 'js-cookie'
import zhCN from 'antd/lib/locale/zh_CN'
import { sendHttpPostRequest } from '../../../api/http-request'
const { Search } = Input
import TextArea from 'antd/lib/input/TextArea'
import OAMSider from '../oam-sider'

import idataCourseTableStyles from '../../common/global-styles/idatacourse-table.module.less'
const { TabPane } = Tabs
export interface Props {}
const statusMap = new Map([
  [1, '下线'],
  [2, '在线'],
])

export const OAMCourseList: React.FC<Props> = (props) => {
  const [userResult, setUserResult] = useState([] as any[]) // 当前显示的结果页
  const [resultSum, setResultSum] = useState(0) // 搜索返回的结果数
  const [onlineCount, setOnlineCount] = useState(0) // 上线的机构数
  const [totalCount, setTotalCount] = useState(0) // 搜索返回的结果数

  const [sysCount, setSysCount] = useState(0) //搜索返回的结果数
  const [userCount, setUserCount] = useState(0) //搜索返回的结果数

  const [isEditUserModalVisible, setIsEditUserModalVisible] = useState(false) // 模态框是否显示
  const [editUserId, setEditUserId] = useState(-1) // 待编辑备注的用户ID
  const [editUserRemark, setEditUserRemark] = useState('') // 备注信息

  const queryContent = useRef('') // 记录搜索串
  const order = useRef('') // 排序升序降序
  const order_by = useRef('') // 排序列
  const page = useRef(1) // 记录当前的页数
  const pageSize = useRef(20) // 记录当前每页展示数量
  const querySource = useRef('2') //2-系统，1-用户

  const sexFilters = useRef([]) // 性别过滤选项
  const roleFilters = useRef([]) // 身份过滤选项
  const channelFilters = useRef([]) // 渠道过滤项
  const userIsLogin = useRef(false) // 用户是否登录

  useEffect(() => {
    queryCourseList()
  }, [])

  // 查询课程列表
  const queryCourseList = () => {
    const sessionid = Cookies.get('sessionid')
    const queryData = {
      query: queryContent.current,
      page: page.current,
      pagesize: pageSize.current,
      order: order.current,
      order_by: order_by.current,
      source: querySource.current,
      sessionid: sessionid,
      sid: sessionid,
    }
    sendHttpPostRequest(
      'course/api/oam_query_course_list/',
      queryData,
      courseListCallback
    )
  }

  // 查询课程列表接口回调
  const courseListCallback = (response: any) => {
    // console.log(response);
    message.destroy()
    if (response.code == -100) {
      // 未登录
      window.location.href = '/oam_login?next=/oam_course_list'
    } else if (response.is_ok == true) {
      // 成功
      setUserResult(response.query_list)
      setResultSum(response.total_sum)
      setTotalCount(response.total_count)
      setOnlineCount(response.online_count)
      setSysCount(response.system_count)
      setUserCount(response.personal_count)
    } else if (response.code == -501) {
      // 权限不足
      window.location.href = '/oam_login?next=oam_course_list'
    } else {
      message.error(response.message)
    }
  }

  // 切换Tab页
  const onTabKeyChange = (key: string) => {
    console.log(key)
    querySource.current = key
    page.current = 1
    queryCourseList()
  }

  /**
   * 创建课程回调
   * @param response
   */
  const setCourseInfoCallback = (response: any) => {
    message.destroy()
    if (response.code == -100) {
      // 没登录
      window.location.href = '/oam_login?next=oam_course_list'
    } else if (response.code == -501) {
      // 没权限
      window.location.href = '/oam_login?next=oam_course_list'
    } else if (response.is_ok == true) {
      if (response.course_id) {
        window.open(`/oam_course_edit?course_id=${response.course_id}`)
      } else {
        message.error(response.message)
      }
    }
  }

  /**
   * 点击创建课程
   */
  const createCourse = () => {
    sendHttpPostRequest(
      'course/api/oam_create_course/',
      {
        sessionid: Cookies.get('sessionid'),
      },
      setCourseInfoCallback
    )
  }

  //页码和页数发生了变化
  const onPageChange = (newPage: number, newPageSize?: number | undefined) => {
    page.current = newPage ? newPage : 1
    if (newPageSize) {
      pageSize.current = newPageSize
    }
    queryCourseList()
  }

  // 渲染状态
  const renderStatus = (text: any, record: any) => (
    <div>{statusMap.get(parseInt(text))}</div>
  )

  //点击了编辑按钮
  const onEditBtnClick = (id: any, event: any) => {
    window.open('/oam_course_edit?course_id=' + id)
  }

  // 点了预览按钮
  const onLookOverBtnClick = (courseId: string, event: any) => {
    window.open(`/oam_preview_course?course_id=${courseId}`)
  }

  // 删除回调
  const onDeleteBtnClickCallback = (response: any) => {
    message.destroy()
    if (response.code == -100) {
      // 没登录
      window.location.href = '/oam_login?next=oam_course_list'
    } else if (response.code == -501) {
      // 没权限
      window.location.href = '/oam_login?next=oam_course_list'
    } else if (response.code == 0) {
      message.success(response.message)
      queryCourseList()
    } else {
      message.error(response.message)
    }
  }

  // 点击 删除
  const onDeleteBtnClick = (courseId: string) => {
    sendHttpPostRequest(
      'course/api/oam_delete_course/',
      {
        sessionid: Cookies.get('sessionid'),
        course_id: courseId,
      },
      onDeleteBtnClickCallback
    )
  }

  // 上下架回调
  const onPutOnOffBtnClickCallback = (response: any) => {
    message.destroy()
    if (response.code == -100) {
      // 没登录
      window.location.href = '/oam_login?next=oam_course_list'
    } else if (response.code == -501) {
      // 没权限
      window.location.href = '/oam_login?next=oam_course_list'
    } else if (response.is_ok == true) {
      message.success(response.message)
      queryCourseList()
    } else {
      message.error(response.message)
      queryCourseList()
    }
  }

  // 点了上下架按钮
  const onPutOnOffBtnClick = (courseId: number, status: number) => {
    sendHttpPostRequest(
      'course/api/oam_update_course/',
      {
        course_id: courseId,
        status: status == 1 ? 2 : 1,
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
      },
      onPutOnOffBtnClickCallback
    )
  }

  // 渲染操作
  const renderSystemActions = (text: any, record: any) => {
    return (
      <Row justify="start" align="middle" gutter={[8, 4]}>
        {/* <Col style={{width:50}}><Button style={{borderRadius:20,fontSize:14}} size='small' id="lookover" value={record.id} type="default" onClick={onLookOverBtnClick.bind(this, record.id)} > 预 览 </Button></Col> */}
        <Col style={{ width: 50 }}>
          <Button
            style={{ borderRadius: 20, fontSize: 14 }}
            size="small"
            id="editit"
            type="dashed"
            onClick={onEditBtnClick.bind(this, record.id)}
          >
            {' '}
            编辑
          </Button>
        </Col>

        {
          <Col style={{ width: 50 }}>
            {' '}
            {parseInt(record.status) == 1 ? (
              <Button
                style={{ borderRadius: 20, fontSize: 12 }}
                size="small"
                id="puton"
                value={record.id}
                type="primary"
                onClick={onPutOnOffBtnClick.bind(
                  this,
                  record.id,
                  record.status
                )}
              >
                {' '}
                上 架{' '}
              </Button>
            ) : (
              <Popconfirm
                placement="top"
                title={'确认下架课程"' + record.name + '"吗？'}
                onConfirm={onPutOnOffBtnClick.bind(
                  this,
                  record.id,
                  record.status
                )}
                okText="确认"
                cancelText="取消"
              >
                <Button
                  style={{ borderRadius: 20, fontSize: 12 }}
                  size="small"
                  id="putoff"
                  value={record.id}
                  type="primary"
                  danger
                >
                  {' '}
                  下 架{' '}
                </Button>
              </Popconfirm>
            )}
          </Col>
        }

        {parseInt(record.status) == 1 && (
          <Col style={{ width: 50 }}>
            <Popconfirm
              placement="top"
              title={'确认删除课程"' + record.name + '"吗？'}
              onConfirm={onDeleteBtnClick.bind(this, record.id)}
              okText="确认"
              cancelText="取消"
            >
              <Button
                style={{ borderRadius: 20, fontSize: 14 }}
                size="small"
                id="deleteexe"
                type="dashed"
                danger
              >
                {' '}
                删除
              </Button>
            </Popconfirm>
          </Col>
        )}
      </Row>
    )
  }

  // 渲染操作
  const renderUserActions = (text: any, record: any) => {
    return (
      <Row justify="start" align="middle" gutter={[8, 4]}>
        {/*  <Col style={{width:50}}><Button style={{borderRadius:20,fontSize:14}} size='small' id="lookover" value={record.id} type="default" onClick={onLookOverBtnClick.bind(this, record.id)} > 预 览 </Button></Col> */}
        <Col style={{ width: 50 }}>
          <Button
            style={{ borderRadius: 20, fontSize: 14 }}
            size="small"
            id="editit"
            type="dashed"
            onClick={onEditBtnClick.bind(this, record.id)}
          >
            {' '}
            编辑
          </Button>
        </Col>

        <Col style={{ width: 50 }}>
          <Popconfirm
            placement="top"
            title={'确认删除课程"' + record.name + '"吗？'}
            onConfirm={onDeleteBtnClick.bind(this, record.id)}
            okText="确认"
            cancelText="取消"
          >
            <Button
              style={{ borderRadius: 20, fontSize: 14 }}
              size="small"
              id="deleteexe"
              type="dashed"
              danger
            >
              {' '}
              删除
            </Button>
          </Popconfirm>
        </Col>
      </Row>
    )
  }

  //点击了编辑备注按钮
  const onEditRemarkBtnClick = (user_id: any, remark: string, event: any) => {
    setEditUserId(user_id[0])
    setEditUserRemark(remark)
    setIsEditUserModalVisible(true)
  }

  // 用户备注改变
  const onUserRemarkChange = async (e: any) => {
    setEditUserRemark(e.target.value)
  }

  // 用户备注确认
  const handleRemarkOk = () => {}

  // 用户备注取消
  const handleRemarkCancel = () => {}

  //点击了搜索按钮
  const onSearchBtn = (value: string) => {
    page.current = 1
    queryContent.current = value
    queryCourseList()
  }

  // 表格发生变化
  const onTableChange: any = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: { currentDataSource: []; action: any }
  ) => {
    if (sorter.order) {
      //如果选择了排序列
      order.current = sorter.order
      order_by.current = sorter.field
    }
    if (filters.sex) {
      //如果选择了过滤列
      sexFilters.current = filters.sex
    } else {
      sexFilters.current = []
    }
    if (filters.role) {
      roleFilters.current = filters.role
    } else {
      roleFilters.current = []
    }

    page.current = 1
    queryCourseList()
  }

  // 表格列
  const systemCols: any = [
    {
      title: '序号',
      dataIndex: 'id',
      key: 'id',
      align: 'left',
      width: 50,
      render: (text, record, index) =>
        (page.current - 1) * pageSize.current + index + 1,
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      align: 'left',
      width: 50,
    },
    {
      title: '课程名',
      dataIndex: 'name',
      key: 'name',
      width: 80,
      align: 'left',
    },
    {
      title: '创建人',
      dataIndex: 'user_name',
      key: 'user_name',
      width: 80,
      align: 'left',
      render: (text, record) =>
        record.teacher_real_name == '' ? text : record.teacher_real_name,
    },

    {
      title: '创建时间',
      dataIndex: 'create_time',
      key: 'create_time',
      width: 100,
      align: 'center',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '开始日期',
      dataIndex: 'start_time',
      key: 'start_time',
      width: 100,
      align: 'center',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text: any, record: any) => {
        return text.slice(0, 10)
      },
    },
    {
      title: '结束日期',
      dataIndex: 'end_time',
      key: 'end_time',
      width: 100,
      align: 'center',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text: any, record: any) => {
        return text.slice(0, 10)
      },
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      width: 50,
      align: 'center',
      render: renderStatus,
      filters: [
        {
          text: '下线',
          value: 1,
        },
        {
          text: '在线',
          value: 2,
        },
      ],
      onFilter: (value: any, record: any) => true,
    },

    {
      title: '操作',
      dataIndex: 'id',
      key: 'actions',
      width: 80,
      ellipsis: false,
      align: 'center',
      render: renderSystemActions,
    },
  ]

  // 表格列
  const userCols: any = [
    {
      title: '序号',
      dataIndex: 'id',
      key: 'id',
      align: 'left',
      width: 50,
      render: (text, record, index) =>
        (page.current - 1) * pageSize.current + index + 1,
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      align: 'left',
      width: 50,
    },
    {
      title: '课程名',
      dataIndex: 'name',
      key: 'name',
      width: 80,
      align: 'left',
    },
    {
      title: '创建人',
      dataIndex: 'user_name',
      key: 'user_name',
      width: 80,
      align: 'center',
      render: (text, record) =>
        record.teacher_real_name == '' ? text : record.teacher_real_name,
    },
    {
      title: '所属机构',
      dataIndex: 'college_name',
      key: 'college_name',
      width: 80,
      align: 'left',
    },
    {
      title: '创建时间',
      dataIndex: 'create_time',
      key: 'create_time',
      width: 100,
      align: 'center',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '开始日期',
      dataIndex: 'start_time',
      key: 'start_time',
      width: 100,
      align: 'center',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text: any, record: any) => {
        return text.slice(0, 10)
      },
    },
    {
      title: '结束日期',
      dataIndex: 'end_time',
      key: 'end_time',
      width: 100,
      align: 'center',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text: any, record: any) => {
        return text.slice(0, 10)
      },
    },
    {
      title: '操作',
      dataIndex: 'id',
      key: 'actions',
      width: 80,
      ellipsis: false,
      align: 'center',
      render: renderUserActions,
    },
  ]

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <OAMSider keys={'31'} openKeys={['sub-course']}></OAMSider>
      <Layout style={{ backgroundColor: '#f9f9f9' }}>
        <Content
          style={{
            backgroundColor: '#fff',
            borderRadius: 5,
            minWidth: 400,
            margin: 30,
            paddingTop: 20,
          }}
        >
          <div style={{ paddingLeft: 30, paddingRight: 30 }}>
            <div
              className={'css.search1'}
              style={{ textAlign: 'left', paddingBottom: 20 }}
            >
              <Row align="middle" gutter={16}>
                <Col flex="80px">
                  <Statistic title="课程总数" value={totalCount} />
                </Col>
                <Col flex="80px">
                  <Statistic title="系统" value={sysCount} />
                </Col>
                <Col flex="80px">
                  <Statistic title="用户" value={userCount} />
                </Col>
                <Col flex="80px">
                  <Statistic title="已上架" value={onlineCount} />
                </Col>
                <Col flex="350px">
                  <Search
                    placeholder="搜索课程"
                    style={{ width: 350 }}
                    enterButton={true}
                    onSearch={onSearchBtn}
                  />
                </Col>

                <Col flex="auto" style={{ textAlign: 'right' }}>
                  <Button
                    type="primary"
                    style={{
                      float: 'right',
                      marginRight: 20,
                      borderRadius: 20,
                    }}
                    onClick={createCourse}
                  >
                    创建课程
                  </Button>
                </Col>
              </Row>
            </div>

            <Tabs defaultActiveKey="2" onChange={onTabKeyChange}>
              <TabPane tab="系统" key="2" className="">
                <div className={idataCourseTableStyles.tableWrapper}>
                  <ConfigProvider locale={zhCN}>
                    <Table
                      dataSource={userResult}
                      columns={systemCols}
                      rowKey={(record) => record.id}
                      pagination={false}
                      scroll={{ x: 800 }}
                      //bordered={true}
                      onChange={onTableChange}
                    />
                  </ConfigProvider>
                </div>

                <ConfigProvider locale={zhCN}>
                  <div
                    style={{ marginBottom: 30, paddingTop: 30 }}
                    className={'css.pagination'}
                  >
                    <Pagination
                      defaultCurrent={1}
                      total={resultSum}
                      defaultPageSize={20}
                      hideOnSinglePage={true}
                      onChange={onPageChange}
                      current={page.current}
                      showQuickJumper={true}
                      showTotal={(total) => `共 ${total} 课程`}
                      pageSizeOptions={['3', '20', '30', '50', '100']}
                    />
                  </div>
                </ConfigProvider>
              </TabPane>

              <TabPane tab="用户" key="1" className="">
                <div className={idataCourseTableStyles.tableWrapper}>
                  <ConfigProvider locale={zhCN}>
                    <Table
                      dataSource={userResult}
                      columns={userCols}
                      rowKey={(record) => record.id}
                      pagination={false}
                      scroll={{ x: 800 }}
                      //bordered={true}
                      onChange={onTableChange}
                    />
                  </ConfigProvider>
                </div>

                <ConfigProvider locale={zhCN}>
                  <div
                    style={{ marginBottom: 30, paddingTop: 30 }}
                    className={'css.pagination'}
                  >
                    <Pagination
                      defaultCurrent={1}
                      total={resultSum}
                      defaultPageSize={20}
                      hideOnSinglePage={true}
                      onChange={onPageChange}
                      current={page.current}
                      showQuickJumper={true}
                      showTotal={(total) => `共 ${total} 课程`}
                      pageSizeOptions={['3', '20', '30', '50', '100']}
                    />
                  </div>
                </ConfigProvider>
              </TabPane>
            </Tabs>
          </div>
        </Content>
      </Layout>
    </Layout>
  )
}

export default OAMCourseList
