import {message,Col, Input, Layout, Row, Tooltip, Upload, Button } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Content } from 'antd/lib/layout/layout';
import { RouteComponentProps } from 'react-router';
import * as constants from '../../../constants/constants';

import React, {useEffect, useState} from "react";

interface  Props extends  RouteComponentProps<{ video_id: string }> {
    video_id: string
}

import myStyles from "./oam-video.module.less"
import {PlusOutlined} from '@ant-design/icons';  

import { test_video_list } from './oam-video-list';
import OAMSider from '../oam-sider';
import Cookies from 'js-cookie';
import {sendHttpPostRequest} from "../../../api/http-request";

/** 视频编辑页 */
export const OAMVideoEdit: React.FC<Props> = (props) => {
    //const video_id = props.location.search.split("=")[1]
    const lectureId = props.location.search.split("=")[1];

    const [title, setTitle] = useState("")//视频标题
    const [description, setDescription] = useState("")//视频简介
    const [cover, setCover] = useState(null as any) //视频封面

    const [teacher, setTeacher] = useState("")  // 老师
    const [teacherTitle, setTeacherTitle] = useState("") //老师
    const [teacherDescription, setTeacherDescription] = useState("") //老师


    const [videoPath, setVideoPath] = useState("") // 视频 存储地址
    const [videoUrl, setVideoUrl] = useState("") // 视频 url地址
    const [duration, setDuration] = useState("") // 视频 时长

    const [coverFileList, setCoverfileList] = useState([])

    useEffect(() => {
        queryLectureInfo();
    }, [])

    /**
     * 查询讲座信息
     */
    const queryLectureInfo = () => {
        sendHttpPostRequest(
            'video/api/user_get_lecture_by_id/',
            {
                sessionid: Cookies.get("sessionid"),
                lecture_id: lectureId,
            },
            (response: any) => {
                message.destroy();
                if (response.code == -100) {
                    // 未登录
                    window.location.href = '/login?next=lecture';
                } else if (response.is_ok == true) {
                    // 成功
                    setTitle(response?.query_info.title);
                    setDescription(response?.query_info.description);
                    setVideoPath(response?.query_info.video_path);
                    setVideoUrl(response?.query_info.video_url);
                    setCover(response?.query_info.cover);
                    setDuration(response?.query_info.duration);

                    setTeacher(response?.query_info.teacher);
                    setTeacherTitle(response?.query_info.teacher_title);
                    setTeacherDescription(response?.query_info.teacher_description);

                } else if (response.code == -501) {
                    // 权限不足
                    window.location.href = '/login?next=lecture';
                } else {
                    message.error(response.message);
                }
            }

        )
    }


    // 上传案例封面
    const onCoverChange = (info: any) => {
    setCoverfileList(info.fileList)
    const { status } = info.file
    if (status !== 'uploading') {
      //上传中
      console.log(info.file, info.fileList)
    }
    if (status === 'done') {
      //上传成功
      let new_cover = info.file.response.img_url
      let code = info.file.response.code
      code == -100 ? ()=>{} : document.getElementById("video-cover")?.setAttribute("src", new_cover)
      setCover(constants.BACKEND_URL + info.file.response.image_url )
    } else if (status === 'error') {
      //上传失败
      message.error(`${info.file.name} file upload failed.`)
    }
    }

    // 校验图片类型和大小
    function beforeCoverUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('请上传png或jpg格式的图片!');
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error('请上传小于5M的图片');
    }
    return isJpgOrPng && isLt5M;
    }

    // 上传cover传参
    const uploadCoverData = {
        sessionid: Cookies.get("sessionid"),
        sid: Cookies.get("sessionid"),
        //video_id: currentVideo.id
    }

    const uploadCoverButton = (
        <div>
          {<PlusOutlined />}
          <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const onTitleChange = (event: any) => {
        setTitle(event.target.value);
    }


    const onDescriptionChange = (event: any) => {
        setDescription(event.target.value);
    }

    const onTeacherChange = (event: any) => {
        setTeacher(event.target.value);
    }

    const onTeacherTitleChange = (event: any) => {
        setTeacherTitle(event.target.value);
    }

    const onTeacherDescriptionChange = (event: any) => {
        setTeacherDescription(event.target.value);
    }

    const onSaveBtnClick= (event:any) => {
        //message.success('编辑数据' + event.currentTarget.value)
        event.preventDefault();
        sendHttpPostRequest(
            'video/api/oam_update_lecture/',
            {
                sessionid: Cookies.get("sessionid"),
                lecture_id:lectureId,
                title: title,
                description: description,
                cover: cover,
                video_path: videoPath,
                video_url: videoUrl,
                duration: duration,
                teacher: teacher,
                teacher_title: teacherTitle,
                teacher_description : teacherDescription,
                //exercise_id: exerciseId
            },
            queryLectureInfo
        )
    }

    return (
        <Layout style={{ minHeight: '100vh' }}>
        <OAMSider keys={"34"} openKeys={["sub-course"]}></OAMSider>
        <div>
            <Content style={{ minHeight: '90vh',backgroundColor:'#fff',borderRadius:5,width:800,margin:30 ,paddingTop:20}}>
            <div style={{paddingLeft:80,paddingRight:80}}>

                <Row gutter={[8,8]} justify='end'  align='middle' style={{marginBottom:20}} >
                    <Col  span={24} style={{color:"#00000073",textAlign:'right'}}>
                        <Button style={{borderRadius:20,width:80}}
                                onClick={onSaveBtnClick}
                                type="primary">保 存</Button>
                    </Col>
                    
                </Row>

                <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:20}} >
                    <Col flex="80px" style={{color:"#00000073",textAlign:'left'}}>视频题目</Col>
                    <Col flex="auto" style={{textAlign:'left'}}>
                        <Input placeholder="请输入视频题目" 
                            required={true}
                            defaultValue={title}
                            value={title}
                            onChange={onTitleChange}
                            style={{borderRadius:6,width:"100%"}}
                        />
                    </Col>
                </Row>


                <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:20}} >
                    <Col flex="80px" style={{color:"#00000073",textAlign:'left'}}>视频简介</Col>
                    <Col flex="auto" style={{textAlign:'left'}}>
                        <TextArea placeholder="请输入视频简介" 
                            style = {{borderRadius:6,width:"100%"}}
                            autoSize = {{minRows: 5, maxRows: 5}}
                            defaultValue = {description}
                            value = {description}
                            onChange={onDescriptionChange}
                        />
                    </Col>
                </Row>

                <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:20}} >
                    <Col flex="80px" style={{color:"#00000073",textAlign:'left'}}>老师</Col>
                    <Col flex="auto" style={{textAlign:'left'}}>
                        <TextArea placeholder="请输入老师"
                            style = {{borderRadius:6,width:"100%"}}
                            autoSize = {{minRows: 5, maxRows: 5}}
                            defaultValue = {teacher}
                            value = {teacher}
                            onChange={onTeacherChange}
                        />
                    </Col>
                </Row>

                <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:20}} >
                    <Col flex="80px" style={{color:"#00000073",textAlign:'left'}}>老师title</Col>
                    <Col flex="auto" style={{textAlign:'left'}}>
                        <TextArea placeholder="请输入老师title"
                            style = {{borderRadius:6,width:"100%"}}
                            autoSize = {{minRows: 5, maxRows: 5}}
                            defaultValue = {teacherTitle}
                            value = {teacherTitle}
                            onChange={onTeacherTitleChange}
                        />
                    </Col>
                </Row>

                <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:20}} >
                    <Col flex="80px" style={{color:"#00000073",textAlign:'left'}}>老师简介</Col>
                    <Col flex="auto" style={{textAlign:'left'}}>
                        <TextArea placeholder="请输入老师简介"
                            style = {{borderRadius:6,width:"100%"}}
                            autoSize = {{minRows: 5, maxRows: 5}}
                            defaultValue = {teacherDescription}
                            value = {teacherDescription}
                            onChange={onTeacherDescriptionChange}
                        />
                    </Col>
                </Row>

                <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:20}} >
                    <Col flex="80px" style={{color:"#00000073",textAlign:'left'}}>视 频</Col>
                    <Col flex="250px" style={{textAlign:'left'}}>
                         <div >
                            { videoUrl !== "" &&
                            <video  style={{marginTop:25,marginBottom:25,height:'130px',width:"250px"}}  preload="preload"  controls={true}>
                                <source 
                                src={videoUrl?.startsWith("http") ? videoUrl : (constants.BACKEND_URL + videoUrl)}
                                type={"video/mp4"} />
                                您的浏览器不支持 HTML5 video 标签。
                            </video>
                            }
                        </div>
                        
                    </Col>

                   
                </Row>


                <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:20}} >
                    <Col flex="80px" style={{color:"#00000073",textAlign:'left'}}>视频封面</Col>
                    <Col flex="auto" style={{textAlign:'left'}}>
                        <div className={myStyles.uploadCover}>
                          <Upload
                            beforeUpload={beforeCoverUpload}
                            accept={'image/*'}
                            action={constants.BACKEND_API_URL + 'video/api/upload_cover/'}
                            listType="picture-card"
                            name="video_cover"
                            fileList={coverFileList}
                            onChange={onCoverChange}
                            data={uploadCoverData}
                            showUploadList={false}
                            
                        >
                        { cover ?
                            <img
                            id="video-cover"
                            style={{width:"250px",height:"130px"}}
                            src={cover}
                            /> : uploadCoverButton

                        }
                            
                        </Upload>
                        </div>
                    </Col>
                </Row>


            </div>
  
            </Content>
        </div>
    </Layout>
    )

}

export default OAMVideoEdit

