import {
  Button,
  Col,
  Input,
  Layout,
  message,
  Popconfirm,
  Radio,
  Row,
  Select,
  Upload,
} from 'antd'
import React, { useEffect, useState } from 'react'
import userStyles from './user.module.less'
import { PageHeader } from '../page-header/header'
import PageFooter from '../page-footer/footer'
import { FileImageOutlined, UploadOutlined } from '@ant-design/icons'
import exerciseStyles from '../../exercise/exercise.module.less'
import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined'
import UserInfo from './user-info'
import { sendHttpPostRequest } from '../../../api/http-request'
import Cookies from 'js-cookie'
import localStorage from 'localStorage'
import Feedback from '../feedback-help/feedback'
const { Option } = Select
import { BACKEND_URL, BACKEND_API_URL } from '../../../constants/constants'
import UpdatePasswordModal from './components/UpdatePasswordModal/UpdatePasswordModal'
export interface Props {}
export interface Props2 {}
interface UserInfo {
  user_id: number
  introduction: string
  nick_name: string
  phone: string
  real_name: string
  avatar: string
  sex: string
  sex_number: number
  role: number
  number: number
}

export const UserCenter: React.FC<Props> = (props) => {
  const [userInfo, setUserInfo] = useState(null as unknown as UserInfo) // 用户信息
  const [fieldsHidden, setFieldsHidden] = useState(false) // 字段隐藏

  //const [school, setSchool] = useState("");//
  const [schoolCode, setSchoolCode] = useState('') //
  //const [userRole, setUserRole] = useState(null as any);//
  const [userHeaderImg, setUserHeaderImg] = useState(null as any) // 用户头像

  //const [isCollegeManager, setIsCollegeManager] = useState(null as any);  // 是否是机构管理员

  const [currentCollege, setCurrentCollege] = useState(null as any) //当前的机构
  const [myCollegeList, setMyCollegeList] = useState(null as any) //我的机构列表
  const [passwordUpdateModalVis, setPasswordUpdateModalVis] = useState(false)

  useEffect(() => {
    queryUserInfo()
    queryCollegeInfo()
  }, [])

  const queryCollegeInfo = () => {
    sendHttpPostRequest(
      'account/api/query_teacher_authenticate',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
      },
      (response: any) => {
        if (response?.is_ok == true) {
          setMyCollegeList(response.college_list)
          setCurrentCollege(response.college_info)
        }
      }
    )
  }

  // 查询用户信息回调
  const queryUserInfoCallback = (response: any) => {
    message.destroy()
    if (response.code == -100) {
      // 未登录
      setFieldsHidden(true)
      document.getElementById('show-login-modal')!.click()
    } else if (response.code == 0) {
      setFieldsHidden(false)
      setUserInfo(response.data)

      setUserHeaderImg(response.data.avatar)
    } else {
      message.error(response.message)
      setFieldsHidden(true)
    }
  }

  // 查询用户信息
  const queryUserInfo = () => {
    sendHttpPostRequest(
      'account/api/get_user_info/',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
      },
      queryUserInfoCallback
    )
  }

  // 上传个人头像logo传参
  const uploadData = {
    sessionid: Cookies.get('sessionid'),
    sid: Cookies.get('sessionid'),
    //exercise_id: """,
  }

  // 上传图片
  const uploadImageProps = {
    accept: '.png,.jpg,.jpeg,.gif',
    name: 'avatar',
    multiple: false,
    showUploadList: false,
    data: uploadData,
    action: BACKEND_API_URL + 'account/api/upload_header_image',
    onChange(info) {
      const { status } = info.file
      if (status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (status === 'done') {
        if (info.file.response.is_ok) {
          message.success(`图片${info.file.name}文件上传成功！`)
          setUserHeaderImg(info.file.response.img_url)
          //saveInstitution();
        } else {
          if (info.file.response.code == -100) {
            // 未登录
            const localPath = window.location.href.split(
              window.location.host
            )[1]
            window.location.href = `/oam_login?next=${localPath}`
          } else if (info.file.response.code == -501) {
            // 权限不足
            const localPath = window.location.href.split(
              window.location.host
            )[1]
            window.location.href = `/oam_login?next=${localPath}`
          } else {
            message.error(info.file.response.message)
          }
        }
      } else if (status === 'error') {
        message.error(`图片${info.file.name}文件上传失败了:(`)
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files)
    },
  }

  // 用户加入院校
  const userJoinCollegeCallback = (response: any) => {
    message.destroy()
    if (response.code == -100) {
      // 未登录
      setFieldsHidden(true)
      document.getElementById('show-login-modal')!.click()
    } else if (response.is_ok == true) {
      const college_role = response.college_role == 2 ? '教师' : '学生'
      message.success(
        '加入院校' + response.college_name + ',身份:' + college_role
      )
      //setSchool(response.college_name);
      //setUserRole(college_role);
      // setUserRole(response.college_role == 2 ? "教师" : "学生");
      window.location.href = '/user-center'
    } else {
      message.error(response.message)
    }
  }

  //用户加入院校
  const userJoinCollege = () => {
    sendHttpPostRequest(
      'college/api/user_join_college_by_code',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        college_code: schoolCode,
      },
      userJoinCollegeCallback
    )
  }

  //用户切换机构
  const handleChangeCollege = (value) => {
    sendHttpPostRequest(
      'college/api/user_swift_college/',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        college_id: value,
      },
      (response: any) => {
        message.destroy()
        if (response.code == -100) {
          // 未登录
          document.getElementById('show-login-modal')!.click()
        } else if (response.is_ok == true) {
          message.success('院校切换成功~')
          setCurrentCollege(
            myCollegeList?.filter((item) => item.college_id == value)[0]
          )
          localStorage.removeItem('college_info')
          window.location.href = '/user-center'
        } else {
          message.error(response.message)
        }
      }
    )
  }
  /**
   * 文本类型改变
   * @param key
   * @param event
   */
  const onTextChange = (key: string, event: any) => {
    const sex = event.target.value
    userInfo[key] = sex
    if (key == 'sex_number') {
      if (sex == 2) {
        userInfo['sex'] = '男'
      } else if (sex == 3) {
        userInfo['sex'] = '女'
      }
    }
    setUserInfo(JSON.parse(JSON.stringify(userInfo)))
  }

  // 保存用户信息回调
  const saveUserInfoCallback = (response: any) => {
    message.destroy()
    if (response.code == -100) {
      // 未登录
      setFieldsHidden(true)
      document.getElementById('show-login-modal')!.click()
    } else if (response.code == 0) {
      setFieldsHidden(false)
      message.success('更新用户信息成功')
      // 重设localStorage
      let cachedUserInfo = JSON.parse(localStorage.getItem('user_info'))
      cachedUserInfo.login_user_name = userInfo.nick_name
      localStorage.setItem('user_info', JSON.stringify(cachedUserInfo))
      //window.location.reload();
      //queryUserInfo();
    } else {
      message.error(response.message)
      setFieldsHidden(true)
    }
  }

  // 保存用户信息
  const saveUserInfo = () => {
    console.log(userInfo)
    sendHttpPostRequest(
      'account/api/update_user_info/',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        user_id: userInfo.user_id,
        nick_name: userInfo.nick_name,
        real_name: userInfo.real_name,
        introduction: userInfo.introduction,
        number: userInfo.number,
        sex: userInfo.sex_number,
      },
      saveUserInfoCallback
    )
  }

  // 用户基本信息
  const UserBaseForm = () => {
    return (
      <div
        className={'css.search1'}
        style={{
          paddingTop: 20,
          paddingLeft: 50,
          paddingRight: 50,
          position: 'relative',
        }}
      >
        <div style={{ padding: 20 }}>
          <Row
            gutter={[8, 8]}
            justify="start"
            align="middle"
            style={{ marginBottom: 20 }}
          >
            <Col flex="100px" style={{ color: '#00000073', textAlign: 'left' }}>
              昵称
            </Col>
            <Col flex="auto" style={{ textAlign: 'left' }}>
              <Input
                hidden={fieldsHidden}
                placeholder="请输入昵称"
                required={true}
                value={userInfo?.nick_name}
                onChange={onTextChange.bind(this, 'nick_name')}
                style={{ borderRadius: 3, width: '100%' }}
              />
            </Col>
          </Row>

          <Row
            gutter={[8, 8]}
            justify="start"
            align="middle"
            style={{ marginBottom: 20 }}
          >
            <Col flex="100px" style={{ color: '#00000073', textAlign: 'left' }}>
              头像
            </Col>
            <Col flex="auto" style={{ textAlign: 'left' }}>
              <Upload {...uploadImageProps}>
                <div hidden={fieldsHidden} className={userStyles.headerImg}>
                  {userHeaderImg ? (
                    <img
                      id="avatar"
                      width={50}
                      height={50}
                      src={
                        userHeaderImg?.startsWith('http')
                          ? userHeaderImg
                          : BACKEND_API_URL + userHeaderImg
                      }
                    />
                  ) : (
                    <div
                      style={{
                        paddingTop: 50,
                        textAlign: 'center',
                        width: 50,
                        height: 50,
                        border: '1px solid #00000073',
                      }}
                    >
                      {' '}
                      <div>+ 上传(点击或拖拽) </div>
                    </div>
                  )}
                </div>
              </Upload>
            </Col>
          </Row>

          <Row
            gutter={[8, 8]}
            justify="start"
            align="middle"
            style={{ marginBottom: 20 }}
          >
            <Col flex="100px" style={{ color: '#00000073', textAlign: 'left' }}>
              真实姓名
            </Col>
            <Col flex="auto" style={{ textAlign: 'left' }}>
              <Input
                hidden={fieldsHidden}
                placeholder="请输入真实姓名"
                required={true}
                value={userInfo?.real_name}
                onChange={onTextChange.bind(this, 'real_name')}
                style={{ borderRadius: 3, width: '100%' }}
              />
            </Col>
          </Row>

          <Row
            gutter={[8, 8]}
            justify="start"
            align="middle"
            style={{ marginBottom: 20 }}
          >
            <Col flex="100px" style={{ color: '#00000073', textAlign: 'left' }}>
              {'学号/工号'}
            </Col>
            <Col flex="auto" style={{ textAlign: 'left' }}>
              <Input
                hidden={fieldsHidden}
                placeholder={'请输入学号/工号'}
                value={userInfo?.number}
                onChange={onTextChange.bind(this, 'number')}
                style={{ borderRadius: 3, width: '100%' }}
              />
            </Col>
          </Row>

          <Row
            gutter={[8, 8]}
            justify="start"
            align="middle"
            style={{ marginBottom: 20 }}
          >
            <Col flex="100px" style={{ color: '#00000073', textAlign: 'left' }}>
              性别
            </Col>
            <Col
              flex="auto"
              hidden={fieldsHidden}
              style={{ textAlign: 'left' }}
            >
              <Radio.Group
                onChange={onTextChange.bind(this, 'sex_number')}
                value={userInfo?.sex_number}
              >
                <Radio
                  value={2}
                  checked={userInfo?.sex_number == 2 ? true : false}
                >
                  男
                </Radio>
                <Radio
                  value={3}
                  checked={userInfo?.sex_number == 3 ? true : false}
                >
                  女
                </Radio>
              </Radio.Group>
            </Col>
          </Row>
          <Row
            gutter={[8, 8]}
            justify="start"
            align="middle"
            style={{ marginBottom: 20 }}
          >
            <Col flex="100px" style={{ color: '#00000073', textAlign: 'left' }}>
              个人简介
            </Col>
            <Col flex="auto" style={{ textAlign: 'left' }}>
              <Input.TextArea
                hidden={fieldsHidden}
                rows={4}
                placeholder="请输入个人简介"
                required={true}
                value={userInfo?.introduction}
                onChange={onTextChange.bind(this, 'introduction')}
                style={{ borderRadius: 3, width: '100%' }}
              />
            </Col>
          </Row>

          <Row
            gutter={[8, 8]}
            justify="start"
            align="middle"
            style={{ marginBottom: 20 }}
          >
            <Col flex="100px" style={{ color: '#00000073', textAlign: 'left' }}>
              我的院校
            </Col>
            <Col flex="auto" style={{ textAlign: 'left' }}>
              {myCollegeList && (
                <Select
                  value={currentCollege?.college_id}
                  onSelect={handleChangeCollege}
                  style={{ width: '100%' }}
                >
                  {myCollegeList?.map((college) => {
                    return (
                      <Option
                        key={college.college_id}
                        value={college.college_id}
                      >
                        {college.college_name}-
                        {college.is_college_teacher == 1 ? '教师' : '学生'}
                        {college?.is_manager == 1 ? '-管理员' : ''}
                      </Option>
                    )
                  })}
                </Select>
              )}
            </Col>
            {currentCollege?.is_manager == 1 && (
              <Col flex="100px" style={{ textAlign: 'right' }}>
                <Button
                  type="primary"
                  onClick={() => {
                    window.open('/college-oam')
                  }}
                  style={{ width: 80, borderRadius: 5 }}
                >
                  院校管理
                </Button>
              </Col>
            )}
          </Row>

          <Row
            gutter={[8, 8]}
            justify="start"
            align="middle"
            style={{ marginBottom: 20 }}
          >
            <Col flex="100px" style={{ color: '#00000073', textAlign: 'left' }}>
              院校码
            </Col>
            <Col flex="auto" style={{ textAlign: 'left' }}>
              <Input
                hidden={fieldsHidden}
                placeholder="请输入院校码"
                value={schoolCode}
                style={{ borderRadius: 3, width: '100%' }}
                onChange={(e: any) => {
                  setSchoolCode(e.target.value)
                }}
              />
            </Col>
            <Col flex="100px" style={{ textAlign: 'right' }}>
              <Button
                onClick={userJoinCollege}
                hidden={fieldsHidden}
                style={{
                  width: 80,
                  borderRadius: 5,
                  color: '#07c160',
                  borderColor: '#07c160',
                }}
              >
                提交
              </Button>
            </Col>
          </Row>

          <Row
            justify="center"
            align="middle"
            style={{ marginTop: -10, marginBottom: 10, gap: '20px' }}
            wrap={false}
          >
            <Col style={{ marginTop: 20, textAlign: 'center' }}>
              <Button
                hidden={fieldsHidden}
                type="default"
                style={{ width: 80, borderRadius: 5, padding: 0 }}
                onClick={() => setPasswordUpdateModalVis(true)}
              >
                修改密码
              </Button>
            </Col>
            <Col style={{ marginTop: 20, textAlign: 'center' }}>
              <Button
                hidden={fieldsHidden}
                type="primary"
                style={{ width: 80, borderRadius: 5 }}
                onClick={saveUserInfo}
              >
                保存
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    )
  }

  return (
    <Layout style={{ backgroundColor: '#f9f9f9' }}>
      <div className={userStyles.maincontent}>
        <div className={userStyles.userInfoList} style={{ maxWidth: 800 }}>
          {UserBaseForm()}
        </div>
      </div>
      <UpdatePasswordModal open={passwordUpdateModalVis} setOpen={setPasswordUpdateModalVis}  />
    </Layout>
  )
}

export default UserCenter
