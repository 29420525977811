import React, {useEffect, useState} from "react";
import { Input,Table,Switch,Layout, message, Card, Col, Row, ConfigProvider, Pagination, Tabs, Button, Tag } from 'antd';
import courseTeacherMenuStyles from "./menu.module.less";

import zhCN from 'antd/lib/locale/zh_CN'
import * as constants from '../../../constants/constants';

import LoginRegisterModal from "../../../component/modal/login-register/login-register";
import {checkUserLogin} from "../../common/user-center/user-info";
import { BarsOutlined,VideoCameraOutlined,CodeOutlined,FilePdfOutlined,UserOutlined } from '@ant-design/icons';

export interface Props {
    init_menu:any;
}

       
/** 教师课程大纲标签页 */
export const MenuPreview: React.FC<Props> = (props) => {
    const {init_menu} = props
    

    useEffect(()=> {
        // 示例代码请勿删除
  /*      sendHttpPostRequest(
            'case/api/get_case_list',
            {page: 1, pagesize: 16, type: 1, query: ""},
            renderCourse
        );*/
  },[])

    return (
        <div className={courseTeacherMenuStyles.tabpaneWrapper}>
        {
            init_menu?.map((chapter, chapterInd)=>(
            <div key={"chapter" + chapter.id}>
                <div className={courseTeacherMenuStyles.chapterName} style={{textAlign:'left',fontWeight: 700}} > { "第" +  (chapterInd + 1)  + "章 " + chapter.name}</div>
                
                {chapter.tasks.map((module, moduleIndex) => (
                    module.is_delete == 1 ?
                <div key={module.id} className={courseTeacherMenuStyles.modules} >
                    <Row  gutter={[4, 4]} justify='start' align='middle'>
                        <Col flex='30px'> <div className={courseTeacherMenuStyles.timeperiod} style={{paddingLeft:5,paddingRight:5,marginLeft:5,fontSize:12,borderRadius:5}}>15:00</div></Col>
                        <Col style={{textAlign:'left'}} flex="auto"> {(chapterInd + 1) + "-" + (moduleIndex+1) + " " + module.name}</Col>
                                    
                        <Col flex="100px" style={{textAlign:"right"}}>  
                            {module.practice.length > 0 && <BarsOutlined  className={courseTeacherMenuStyles.iconbuttons}/> }                  
                            {module.case?.case_id  &&  <CodeOutlined className={courseTeacherMenuStyles.iconbuttons}/> }
                            { module?.video_url == ""? <VideoCameraOutlined className={courseTeacherMenuStyles.iconbuttons}/> :  <FilePdfOutlined className={courseTeacherMenuStyles.iconbuttons}/> }
                        </Col>

                    </Row>  
                </div> : ""
                ))}

            </div>))                 
         }
        </div>
    )

}

export default MenuPreview

