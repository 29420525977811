import { Modal, ConfigProvider } from 'antd'
import zhCN from 'antd/es/locale/zh_CN'
import React, { useRef, useState } from 'react'
import type { DraggableData, DraggableEvent } from 'react-draggable'
import Draggable from 'react-draggable'
import css from '../index.module.less'

interface Props {
  open: boolean
  setOpen: any
  modalTitle: string
  handleOk: any
  handleCancel: any
  modalContent: any
  bodyStyle?: any
  size?: string
  theme?: string
  width?: any
}
const tipBodyStyle = {
  padding: '20px 70px',
  minHeight: '110px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}
const darkTheme = {
  backgroundColor: '#314a4b',
}

export const PublicTipModal: React.FC<Props> = (props) => {
  const {
    open,
    setOpen,
    modalTitle,
    handleOk,
    handleCancel,
    modalContent,
    bodyStyle,
    size,
    theme,
    width,
  } = props
  const [disabled, setDisabled] = useState(false)
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
  const draggleRef = useRef<HTMLDivElement>(null)

  const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
    const { clientWidth, clientHeight } = window.document.documentElement
    const targetRect = draggleRef.current?.getBoundingClientRect()
    if (!targetRect) {
      return
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    })
  }

  return (
    <ConfigProvider locale={zhCN}>
      <Modal
        width={width}
        className={
          size == 'large'
            ? css.LargeModal
            : theme == 'dark'
            ? css.darkThemeModal
            : theme == 'blue'
            ? css.blueThemeModal
            : css.ModalTip
        }
        bodyStyle={bodyStyle ? bodyStyle : tipBodyStyle}
        title={
          <div
            style={{
              width: '100%',
              cursor: 'move',
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false)
              }
            }}
            onMouseOut={() => {
              setDisabled(true)
            }}
            onFocus={() => {}}
            onBlur={() => {}}
          >
            {modalTitle}
          </div>
        }
        visible={open}
        onOk={handleOk}
        onCancel={handleCancel}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        {modalContent}
      </Modal>
    </ConfigProvider>
  )
}

export default PublicTipModal
