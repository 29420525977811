import React, { useEffect, useRef, useState } from 'react'
import DiscusItemStyle from './DiscussItem.module.less'
import { Button, Col, Image, message, Row, ConfigProvider, Modal } from 'antd'
import MarkDownEditor, { mdParser } from '../MarkDownEditor/MarkDownEditor'
import { UpOutlined, DownOutlined, CaretRightOutlined } from '@ant-design/icons'
export interface ReplyProps {
  type: any
  reply: any
  replies: any
  setReplies: any
  index: any
  editorStateInit: any
  theme?: string
  submitCallBack?: any
  replayToName?: any
}
import { BACKEND_API_URL, BACKEND_URL } from '../../constants/constants'
import { sendHttpPostRequest } from '../../api/http-request'
import Cookies from 'js-cookie'
import katex from 'katex'
import MarkdownItKatex from '@iktakahiro/markdown-it-katex'
import Draggable from 'react-draggable'
import zhCN from 'antd/lib/locale/zh_CN'

const emoji = require('markdown-it-emoji')
export const ReplyItem: React.FC<ReplyProps> = (props) => {
  const {
    type,
    reply,
    replies,
    setReplies,
    index,
    editorStateInit,
    theme,
    submitCallBack,
    replayToName,
  } = props
  mdParser.use(MarkdownItKatex, { katex })
  mdParser.use(emoji)
  const [isreplyEditorVis, setisreplyEditorVis] = useState(false)
  const [isExtend, setIsExtend] = useState(false)
  const [myArticleReplyComment, setMyArticleReplyComment] = useState('') // 我回复文章的内容
  const [editorVis, setEditorVis] = useState(false)
  const [uploadImageList, setUploadImageList] = useState([] as any[])
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const [deleteCommentModalVisible, setDeleteCommentModalVisible] =
    useState(false)
  const [deleteItemId, setDeleteItemId] = useState(-1)
  const [deleteCommentItemId, setDeleteCommentItemId] = useState(-1)
  const draggleRef = useRef<HTMLDivElement | null>(null)

  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
  const [disabled, setDisabled] = useState(true)

  const onStart = (event: any, uiData: any) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement
    const targetRect = draggleRef?.current?.getBoundingClientRect()
    if (targetRect) {
      setBounds({
        left: -targetRect?.left + uiData?.x,
        right: clientWidth - (targetRect?.right - uiData?.x),
        top: -targetRect?.top + uiData?.y,
        bottom: clientHeight - (targetRect?.bottom - uiData?.y),
      })
    }
  }
  // const imagelist = [
  //   {
  //     src: 'http://idkcbd.idatascience.cn/media/course_cover/591/399f0c41056f91b8db59980bb57a7cd.jpg',
  //   },
  //   { src: 'http://idkcbd.idatascience.cn/media/course_cover/162/导论.jpg' },
  // ]

  const from = type['from']
  let courseId: any = from == 'course' ? type['course_id'] : null

  let knowledgeCellId: any = null
  const refreshKnowId = () => {
    const _location = window.location.href.includes('?')
      ? window.location.href.split('?')[1].split('/')
      : null
    if (_location && _location.length >= 6) {
      knowledgeCellId = parseInt(_location[5])
    }
  }

  const onLikeChange = () => {
    let newReply = reply
    if (reply?.praised) {
      newReply.praised_count -= 1
    } else {
      newReply.praised_count += 1
    }
    newReply.praised = !newReply.praised
    replies[index] = newReply

    setReplies(JSON.parse(JSON.stringify(replies)))
  }

  const uploadOption = {
    name: from == 'course' ? 'discussion_picture' : 'community_picture',
    actionUrl:
      from == 'course'
        ? 'course/api/discussion/upload_picture/'
        : 'community/api/upload_picture/',
    uploadData: { sessionid: Cookies.get('sessionid') },
    uploadImageList: uploadImageList,
    setUploadImageList: setUploadImageList,
  }

  const onReplyCommentChange = (value: any) => {
    setMyArticleReplyComment(value.text)
  }

  /**
   * 发布文章回复の回复
   * @param reply_id  回复的id
   * @param father_id  父id
   */
  const publishArticleReplyComment = (reply_id: any, father_id: any) => {
    let _params: any = {
      sessionid: Cookies.get('sessionid'),
      reply_id: reply_id,
      content: myArticleReplyComment,
      upload_image_list: JSON.stringify(uploadImageList),
    }
    if (from == 'course') {
      refreshKnowId()
      // 从课程来的
      _params['course_id'] = courseId
      _params['know_id'] = knowledgeCellId
        ? knowledgeCellId
        : reply.knowledge_cell_id
    }
    if (from == 'community') {
      // 从社区来的
    }
    if (father_id) _params['father_id'] = father_id
    sendHttpPostRequest(
      from == 'course'
        ? 'course/api/discussion/publish_kc_discussion_reply_comment/'
        : 'community/api/publish_reply_comment/',
      _params,
      (response: any) => {
        message.destroy()
        if (response.code == -100) {
          // 未登录
          document.getElementById('show-login-modal')!.click()
          // window.location.href = '/login?next=community'
        } else if (response.code == 0) {
          // 成功
          message.success('发表回复成功')
          // setTimeout(function () {
          setMyArticleReplyComment('')
          setUploadImageList([])
          from == 'course' ? submitCallBack(knowledgeCellId) : submitCallBack()
          // },1000)
          setEditorVis(false)
        } else if (response.code == -501) {
          // 权限不足
          from == 'course'
            ? (window.location.href = '/')
            : (window.location.href = '/community')
        } else if (response.code == -509) {
          // 未阅读社区协议
          window.location.href = '/community?tab=3'
        } else {
          message.error(response.message)
        }
      }
    )
  }

  /**
   * 删除文章回复
   * @param replyId
   */
  const deleteArticleReply = (replyId: any) => {
    setDeleteItemId(replyId)
    setDeleteModalVisible(true)
  }

  /**
   * 删除文章回复确认
   */
  const deleteArticleReplyConfirm = () => {
    setDeleteModalVisible(false)
    let _params: any = {
      sessionid: Cookies.get('sessionid'),
      reply_id: deleteItemId,
    }
    if (from == 'course') {
      // 从课程来的
      refreshKnowId()
      _params['course_id'] = courseId
      _params['know_id'] = knowledgeCellId
        ? knowledgeCellId
        : reply.knowledge_cell_id
    }
    sendHttpPostRequest(
      from == 'course'
        ? 'course/api/discussion/delete_discussion_reply/'
        : 'community/api/delete_post_reply/',
      _params,
      (response: any) => {
        message.destroy()
        if (response.code == -100) {
          // 未登录
          document.getElementById('show-login-modal')!.click()
        } else if (response.code == 0) {
          // 成功
          message.success('删除回复成功')
          // setTimeout(function () {
          // message.success('TODO: 这里需要刷新回复的列表')
          setDeleteItemId(-1)
          submitCallBack(
            knowledgeCellId ? knowledgeCellId : reply.knowledge_cell_id
          )
          // }, 1000)
        } else if (response.code == -501) {
          // 权限不足
          from == 'course'
            ? (window.location.href = '/')
            : (window.location.href = '/community')
        } else if (response.code == -509) {
          // 未阅读社区协议
          window.location.href = '/community?tab=3'
        } else {
          message.error(response.message)
        }
      }
    )
  }

  /**
   * 删除文章回复的回复
   * @param commentId
   */
  const deleteArticleReplyComment = (commentId: any) => {
    setDeleteCommentItemId(commentId)
    setDeleteCommentModalVisible(true)
  }

  /**
   * 删除文章回复的回复确认
   */
  const deleteArticleReplyCommentConfirm = () => {
    setDeleteCommentModalVisible(false)
    let _params: any = {
      sessionid: Cookies.get('sessionid'),
      comment_id: deleteCommentItemId,
    }
    if (from == 'course') {
      // 从课程来的
      refreshKnowId()
      _params['course_id'] = courseId
      _params['know_id'] = knowledgeCellId
    }
    sendHttpPostRequest(
      from == 'course'
        ? 'course/api/discussion/delete_kc_discussion_reply_comment/'
        : 'community/api/delete_reply_comment/',
      _params,
      (response: any) => {
        message.destroy()
        if (response.code == -100) {
          // 未登录
          document.getElementById('show-login-modal')!.click()
        } else if (response.code == 0) {
          // 成功
          message.success('删除回复成功')
          // setTimeout(function () {
          // message.success('TODO: 这里需要刷新回复的列表')
          setDeleteCommentItemId(-1)
          submitCallBack(
            knowledgeCellId ? knowledgeCellId : reply.knowledge_cell_id
          )
          // }, 1000)
        } else if (response.code == -501) {
          // 权限不足
          from == 'course'
            ? (window.location.href = '/')
            : (window.location.href = '/community')
        } else if (response.code == -509) {
          // 未阅读社区协议
          window.location.href = '/community?tab=3'
        } else {
          message.error(response.message)
        }
      }
    )
  }

  /**
   * 点赞文章回复
   * @param replyId
   */
  const praiseArticleReply = (replyId: any) => {
    let _params: any = {
      sessionid: Cookies.get('sessionid'),
      reply_id: replyId,
    }
    if (from == 'course') {
      // 从课程来的
      refreshKnowId()
      _params['course_id'] = courseId
      _params['know_id'] = knowledgeCellId
        ? knowledgeCellId
        : reply.knowledge_cell_id
    }
    sendHttpPostRequest(
      from == 'course'
        ? 'course/api/discussion/praise_discussion_reply/'
        : 'community/api/praise_post_reply/',
      _params,
      (response: any) => {
        message.destroy()
        if (response.code == -100) {
          // 未登录
          document.getElementById('show-login-modal')!.click()
        } else if (response.code == 0) {
          // 成功
          message.success(response.message)
          if (reply?.praised) {
            reply.praised_count -= 1
          } else {
            reply.praised_count += 1
          }
          reply.praised = !reply.praised
          setReplies(JSON.parse(JSON.stringify(replies)))
          submitCallBack(
            knowledgeCellId ? knowledgeCellId : reply.knowledge_cell_id
          )
        } else if (response.code == -501) {
          // 权限不足
          from == 'course'
            ? (window.location.href = '/')
            : (window.location.href = '/community')
        } else if (response.code == -509) {
          // 未阅读社区协议
          window.location.href = '/community?tab=3'
        } else {
          message.error(response.message)
        }
      }
    )
  }

  /**
   * 点赞文章回复的回复
   * @param commentId
   */
  const praiseArticleReplyComment = (commentId: any) => {
    let _params: any = {
      sessionid: Cookies.get('sessionid'),
      comment_id: commentId,
    }
    if (from == 'course') {
      // 从课程来的
      refreshKnowId()
      _params['course_id'] = courseId
      _params['know_id'] = knowledgeCellId
    }
    setReplies(JSON.parse(JSON.stringify(replies)))
    sendHttpPostRequest(
      from == 'course'
        ? 'course/api/discussion/praise_kc_discussion_reply_comment/'
        : 'community/api/praise_reply_comment/',
      _params,
      (response: any) => {
        message.destroy()
        if (response.code == -100) {
          // 未登录
          document.getElementById('show-login-modal')!.click()
        } else if (response.code == 0) {
          // 成功
          message.success(response.message)
          // setTimeout(function () {
          // message.success('TODO: 这里需要刷新回复列表')
          // submitCallBack()
          // }, 1000)

          if (reply?.praised) {
            reply.praised_count -= 1
          } else {
            reply.praised_count += 1
          }
          reply.praised = !reply.praised
          setReplies(JSON.parse(JSON.stringify(replies)))
          submitCallBack(
            knowledgeCellId ? knowledgeCellId : reply.knowledge_cell_id
          )
        } else if (response.code == -501) {
          // 权限不足
          from == 'course'
            ? (window.location.href = '/')
            : (window.location.href = '/community')
        } else if (response.code == -509) {
          // 未阅读社区协议
          window.location.href = '/community?tab=3'
        } else {
          message.error(response.message)
        }
      }
    )
  }

  return (
    <div className={DiscusItemStyle.replyContain}>
      <Row align="middle">
        <Col>
          <img
            className={DiscusItemStyle.avatar}
            src={BACKEND_API_URL + reply?.creator_avatar}
            alt=""
          />
        </Col>
        <Col
          className={
            DiscusItemStyle.name + ' ' + (theme ? DiscusItemStyle.darkname : '')
          }
        >
          {reply?.creator_name}
        </Col>
        {replayToName && (
          <Col
            className={
              DiscusItemStyle.replyName +
              ' ' +
              (theme ? DiscusItemStyle.darkname : '')
            }
          >
            <CaretRightOutlined />
            {reply.reply_to_name}
          </Col>
        )}
      </Row>

      <div
        className={
          DiscusItemStyle.replyContent +
          ' ' +
          (theme ? DiscusItemStyle.darkcontent : '')
        }
      >
        {/* {reply.content?.length > 200 && !isExtend
          ? reply.content.substring(0, 190) + '...'
          : reply.content} */}
        <div
          style={{
            whiteSpace: 'pre-wrap',
            maxWidth:"100%",
            wordBreak:"break-all"
          }}
          // dangerouslySetInnerHTML={{
          //   __html: mdParser.render(String(reply?.content)),
          // }}
        >
          {reply?.content}
        </div>
        {/* {reply.content?.length > 200 && (
          <Button
            type="link"
            style={{
              display: 'inline',
              height: '20px',
              padding: '0',
              lineHeight: '20px',
            }}
            onClick={() => setIsExtend(!isExtend)}
          >
            {isExtend ? '收起' : '展开'}
            {isExtend ? <UpOutlined /> : <DownOutlined />}
          </Button>
        )} */}
        <div className={DiscusItemStyle.commentImg}>
          <Image.PreviewGroup>
            {reply?.image_list?.map((img) => (
              <Image
                style={{ borderRadius: '6px' }}
                width={157}
                height={100}
                key={img}
                src={BACKEND_URL + img}
              ></Image>
            ))}
          </Image.PreviewGroup>
        </div>
        <Row align="middle" justify="space-between">
          <Col>
            <span
              className={
                DiscusItemStyle.commentTime +
                ' ' +
                (theme ? DiscusItemStyle.darkcommentTime : '')
              }
            >
              {reply.create_time}
            </span>
          </Col>
          <Col flex={'400px'}>
            <div className={DiscusItemStyle.actionContain}>
              <div
                className={DiscusItemStyle.iconBtn}
                onClick={
                  reply?.is_reply
                    ? praiseArticleReply.bind(this, reply?.id)
                    : praiseArticleReplyComment.bind(this, reply?.id)
                }

                // onClick={onLikeChange}
              >
                {reply?.praised ? (
                  <span
                    className={
                      'iconfont icon_dianzan' +
                      ' ' +
                      DiscusItemStyle.heighLightBtnTextColor
                    }
                  ></span>
                ) : (
                  <span
                    className={
                      'iconfont icon_dianzan' +
                      ' ' +
                      DiscusItemStyle.grayBtnColor
                    }
                  ></span>
                )}{' '}
                {reply?.praised_count <= 0 ? (
                  <span className={DiscusItemStyle.grayBtnTextColor}>赞</span>
                ) : (
                  <span
                    className={
                      DiscusItemStyle.likesText +
                      ' ' +
                      (theme ? DiscusItemStyle.darkcommentTime : '')
                    }
                    // onClick={reply?.is_reply ? praiseArticleReply.bind(this, reply?.id) : praiseArticleReplyComment.bind(this, reply?.id)}
                  >
                    {reply?.praised_count}
                  </span>
                )}
              </div>
              <div
                className={DiscusItemStyle.iconBtn}
                style={{ marginRight: '20px' }}
                onClick={() => {
                  editorStateInit()
                  setEditorVis(!editorVis)
                  // let newRep = reply
                  // replies.map(r=>r.replyVist = false)
                  // newRep.replyVist = true
                  // replies[index] = newRep
                  // setReplies(JSON.parse(JSON.stringify(replies)))
                }}
              >
                <span
                  className={
                    'iconfont icon_huifu' +
                    ' ' +
                    DiscusItemStyle.grayBtnTextColor
                  }
                ></span>{' '}
                <span className={DiscusItemStyle.grayBtnTextColor}>回复</span>
                {reply?.comment_count > 0 && (
                  <span className={DiscusItemStyle.grayBtnTextColor}>
                    ({reply?.comment_count})
                  </span>
                )}
              </div>
              {reply?.is_my && (
                <div className={DiscusItemStyle.iconBtn}>
                  <span
                    className={
                      'iconfont icon_shanchu' +
                      ' ' +
                      DiscusItemStyle.grayBtnTextColor
                    }
                  ></span>
                  <span
                    className={DiscusItemStyle.grayBtnTextColor}
                    onClick={
                      reply?.is_reply
                        ? deleteArticleReply.bind(this, reply?.id)
                        : deleteArticleReplyComment.bind(this, reply?.id)
                    }
                  >
                    删除
                  </span>
                </div>
              )}
            </div>
          </Col>
        </Row>
        {editorVis && (
          <div
            className={
              DiscusItemStyle.editorContainer +
              ' ' +
              (theme ? DiscusItemStyle.darkEditorContainer : '')
            }
          >
            <MarkDownEditor
              placeholder={`回复${reply?.creator_name}:`}
              onChange={onReplyCommentChange}
              uploadOption={uploadOption}
              toolbar={[
                // 'header',
                // 'font-bold',
                // 'emoji',
                // 'font-italic',
                // 'font-underline',
                // 'font-strikethrough',
                // 'list-unordered',
                // 'list-ordered',
                // 'block-quote',
                // 'block-wrap',
                // 'block-code-inline',
                // 'block-code-block',
                // 'table',
                'img-upload',
              ]}
            />
            <Button
              className={DiscusItemStyle.submitBtn}
              type="primary"
              onClick={publishArticleReplyComment.bind(
                this,
                reply.is_reply ? reply.id : reply.reply_post_id,
                reply.is_reply ? null : reply.id
              )}
            >
              发送
            </Button>
          </div>
        )}
      </div>
      <ConfigProvider locale={zhCN}>
        <Modal
          title={
            <div
              style={{
                width: '100%',
                cursor: 'move',
              }}
              onMouseOver={() => {
                if (disabled) {
                  setDisabled(false)
                }
              }}
              onMouseOut={() => {
                setDisabled(true)
              }}
              onFocus={() => {}}
              onBlur={() => {}}
              // end
            >
              提示
            </div>
          }
          visible={deleteModalVisible}
          onOk={deleteArticleReplyConfirm}
          onCancel={() => {
            setDeleteModalVisible(false)
          }}
          bodyStyle={{ height: '120px' }}
          destroyOnClose={true}
          width={350}
          modalRender={(modal) => (
            <div className={DiscusItemStyle.miniModal}>
              <Draggable
                disabled={disabled}
                bounds={bounds}
                onStart={(event, uiData) => onStart(event, uiData)}
              >
                <div ref={draggleRef}>{modal}</div>
              </Draggable>{' '}
            </div>
          )}
        >
          <div>确认删除回复吗？</div>
        </Modal>
      </ConfigProvider>

      <ConfigProvider locale={zhCN}>
        <Modal
          title={
            <div
              style={{
                width: '100%',
                cursor: 'move',
              }}
              onMouseOver={() => {
                if (disabled) {
                  setDisabled(false)
                }
              }}
              onMouseOut={() => {
                setDisabled(true)
              }}
              onFocus={() => {}}
              onBlur={() => {}}
              // end
            >
              提示
            </div>
          }
          visible={deleteCommentModalVisible}
          onOk={deleteArticleReplyCommentConfirm}
          onCancel={() => {
            setDeleteCommentModalVisible(false)
          }}
          bodyStyle={{ height: '120px' }}
          destroyOnClose={true}
          width={350}
          modalRender={(modal) => (
            <div className={DiscusItemStyle.miniModal}>
              <Draggable
                disabled={disabled}
                bounds={bounds}
                onStart={(event, uiData) => onStart(event, uiData)}
              >
                <div ref={draggleRef}>{modal}</div>
              </Draggable>{' '}
            </div>
          )}
        >
          <div>确认删除回复吗？</div>
        </Modal>
      </ConfigProvider>
    </div>
  )
}

export default ReplyItem
