import { Divider,Layout,Input,Select, message,Button,Radio,Checkbox,Row,Col,Popconfirm,Upload, Breadcrumb, ConfigProvider, Table, Pagination } from 'antd';

import React, { useEffect, useRef, useState } from "react";
import { sendHttpPostRequest } from '../../../../api/http-request';
import Cookies from 'js-cookie';
import rehypeRaw from 'rehype-raw';
import {CloseCircleOutlined} from '@ant-design/icons';
import confirm from 'antd/lib/modal/confirm';
import * as constants from '../../../../constants/constants';
import PageHeader from '../../../common/page-header/header';
import PageFooter from '../../../common/page-footer/footer';
import Feedback from '../../../common/feedback-help/feedback';
import stuDetail from "./index.module.less"
import zhCN from 'antd/lib/locale/zh_CN'
import { Link } from 'react-router-dom';

// /course-main-teacher-studentdetail

function CourseMainTeacherStuDetail() {
  const courseId = parseInt(window.location.href.split("&")[0].split("=")[1]);
  const caseId = parseInt(window.location.href.split("&")[1].split("=")[1]);
  const [caseName, setCaseName] = useState("");
  const [studentSubmissionList, setStudentSubmissionList] = useState([] as any [])
  const [totalStudent, setTotalStudent] = useState(0)   //班级学生总数
  const [totalSubmitStudent, setTotalSubmitStudent] = useState(0)   //提交总数
  const [finishRate, setFinishRate] = useState(0)   // 完成率
  const queryContent = useRef('');  // 记录搜索串
  const order = useRef('');  // 排序升序降序
  const order_by = useRef('');  // 排序列
  const page = useRef(1);  // 记录当前的页数
  const pageSize = useRef(10);  // 记录当前每页展示数量

  useEffect(() => {
    querySubmissionList();
  }, [])
  
  /**
   * 查询提交列表
   */
  const querySubmissionList = () => {
    sendHttpPostRequest(
        'course/api/user_query_student_case_submission/',
        {
          sessionid: Cookies.get("sessionid"),
          query: queryContent.current,
          page: page.current,
          pagesize: pageSize.current,
          order: order.current,
          order_by: order_by.current,
          course_id: courseId,
          case_id: caseId
        },
        (response: any) => {
          message.destroy();
          if (response.code == -100) {
            // 未登录
            document.getElementById("show-login-modal")!.click();
          } else if (response.is_ok == true) {
            // 成功
            setFinishRate(response?.finish_rate)
            setCaseName(response?.case_name)
            setTotalStudent(response?.total_sum)
            setTotalSubmitStudent(response?.finish_sum)
            setStudentSubmissionList(response?.query_list)
          } else if (response.code == -501) {
            // 权限不足
            window.location.href = '/';
          } else {
            message.error(response.message);
          }
        }
    )
  }

  //页码和页数发生了变化
  const onPageChange = (newPage: number, newPageSize?: number | undefined) => {
    page.current = newPage ? newPage : 1;
    if (newPageSize) {
      pageSize.current = newPageSize;
    }
    querySubmissionList();
  }

  const renderCase = (text:any, record:any) => {
    if (record?.submission && record?.submission.case_id) {
      return <Link to={`/case-static?case_id=${record?.submission.case_id}`}><span style={{color:'#07c160'}}>{record?.submission.case_name}</span></Link>
    }
  }

  const columns_case: any = [
    {
      title: '序号',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width:70,
      render: (text:any, record:any, index:number)=>((page.current - 1) * pageSize.current + index + 1)
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      width:100,
      render: (text:any, record:any, index:number)=>(record?.real_name && record?.real != "" ? record.real_name : record.name)
    },
    {
      title: '学号',
      dataIndex: 'number',
      key: 'number',
      align: 'center',
      width:100
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width:100,
      // filterDropdownVisible:true
      render: (text:any, record:any, index:number)=>(record?.submission && record?.submission.case_id ? "已提交" : "未提交")
    },
    {
      title: '保存时间',
      dataIndex: 'update_datetime',
      key: 'update_datetime',
      align: 'center',
      width:200,
      render: (text:any, record:any, index:number)=>(record?.submission && record?.submission.update_datetime ? record?.submission.update_datetime : "")
    },
    {
      title: '实验',
      dataIndex: 'case',
      key: 'case',
      align: 'center',
      ellipsis:true,
      width:200,
      render: renderCase
    },
  ]

  return (
    <Layout style={{minHeight: "100vh",backgroundColor: '#f9f9f9'}}>
            
            <div className={stuDetail.stuDetail} style={{paddingTop:20,width:900,marginTop:30,backgroundColor: '#fff'}}>
                <Row align="middle">
                    <Col span={12} style={{textAlign:"left", marginBottom:10,width:'100%'}}>
                        <div style={{marginLeft:40,fontWeight:600, fontSize:18, color:"#333333"}}> 实验详情 </div>
                    </Col>
                    <Col span={12}  style={{textAlign:"right", marginBottom:10,width:'100%'}}>
                        <Button style={{marginRight:40,color:"#333333"}}
                        //  onClick={()=>{history.back()}} 
                          onClick={()=>{window.location.href = `/course-main-teacher?course_id=${courseId}&tabKey=3`}}
                         type="link"> {"返回 >"} </Button>
                    </Col>
                </Row>
                <Divider style={{marginTop:0}}/>
                <Row align='middle'>
                    <Col span={12} style={{textAlign:"left", marginBottom:10,width:'100%'}}>
                      <span style={{marginLeft:30}}>
                          实验{caseId}-<span style={{fontWeight:800}}>{caseName}</span>
                      </span>
                    </Col>
                    <Col span={12} style={{textAlign:"right", marginBottom:10,width:'100%'}}>
                      <span
                        style={{marginRight:30}}
                      >
                        已完成（<span style={{color:'#07c160'}}>{totalSubmitStudent}</span>）/ 全部（<span style={{color:'#07c160'}}>{totalStudent}</span>） 完成率 <span style={{color:'#07c160'}}>{finishRate}%</span>
                      </span>
                    </Col>
                </Row>
                
                <ConfigProvider locale={zhCN}>
                        <Table
                            className={stuDetail.tableStyle}
                            dataSource={studentSubmissionList}
                            columns={columns_case}
                            size="small"
                            bordered
                            rowKey={record => record.id}
                            pagination={false}
                        />
                </ConfigProvider>
                <ConfigProvider locale={zhCN}>
                        <div className={stuDetail.pagination} style={{marginTop:20}}>
                            <Pagination
                                defaultCurrent={1}
                                total={totalStudent}
                                defaultPageSize={pageSize.current}
                                current={page.current}
                                hideOnSinglePage={true}
                                onChange={onPageChange}
                                showSizeChanger={false}
                            />
                        </div>
                    </ConfigProvider>
                
            </div>
        </Layout>
  )
}

export default CourseMainTeacherStuDetail