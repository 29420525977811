import React, { useEffect, useRef, useState } from 'react'
import {
  Layout,
  message,
  Checkbox,
  Card,
  Select,
  Modal,
  Col,
  Row,
  ConfigProvider,
  Pagination,
  Tabs,
  Button,
  Input,
  Tooltip,
  Popconfirm,
  Radio,
} from 'antd'
import { PageHeader } from '../common/page-header/header'
import { sendHttpPostRequest } from '../../api/http-request'
import logoSvg from '../../static/image/idclogo.png'
import pekinglogoSvg from '../../static/image/peking.png'
import courseStyles from './course.module.less'
import './course.module.css'
import Search from 'antd/lib/input/Search'
import zhCN from 'antd/lib/locale/zh_CN'
import * as constants from '../../constants/constants'
import PageFooter from '../common/page-footer/footer'
import { checkUserLogin } from '../common/user-center/user-info'
import {
  UserOutlined,
  SearchOutlined,
  EyeOutlined,
  EditOutlined,
  StarOutlined,
  DeleteOutlined,
  FileAddOutlined,
  CopyOutlined,
} from '@ant-design/icons'

import emptyCourse from '../../static/image/empty/queshengye-kecheng.png'
import loadingGif from '../../static/image/loding.gif'
import { getCollegeId } from '../../component/modal/teacher-authenticate/teacher-authenticate'

import Feedback from '../common/feedback-help/feedback'
import Cookies from 'js-cookie'
import Draggable from 'react-draggable'
import courseLearnStyles from './course-learn.module.less'

const { TabPane } = Tabs
const { Option } = Select
const CheckboxGroup = Checkbox.Group

interface Course {
  course_id: number
  name: string
  cover: string
}

export interface Props {}
export const Course: React.FC<Props> = (props) => {
  const [courseResult, setCourseResult] = useState(null as any)
  const [resultSum, setResultSum] = useState(0) // 搜索返回的结果数

  const page = useRef(1) // 记录当前的页数
  const pageSize = useRef(16) // 记录当前每页展示数量
  const queryContent = useRef('') // 记录搜索串

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isLogin, setIsLogin] = useState(false) //是否登录
  const [collegeId, setCollegeId] = useState(null as any) // 院校ID
  const [collegeName, setCollegeName] = useState(null as any) // 院校名称
  const [collegeInfo, setCollegeInfo] = useState(null as any) //查询当前用户的机构信息,is_college_teacher weitrue表示是教师，
  const [myCollegeList, setMyCollegeList] = useState(null as any) //我的机构列表

  const [systemPublisedCoourseList, setSystemPublisedCoourseList] = useState(
    null as any
  ) //平台已上线课程列表，供未登录和未加机构用户查看

  const [role, setRole] = useState('教师')

  const [wrongHidden, setWrongHidden] = useState(true)
  const [inviteNumber, setInviteNumber] = useState(null as any)
  const [joinSuccessVisible, setJoinSuccessVisible] = useState(false)

  const [value, setValue] = React.useState(1)

  const [courseList, setCourseList] = useState(null as any)

  const courseSource = React.useRef(1)

  const [copyCourseId, setCopyCourseId] = React.useState(null as any) //要复制的课程ID
  const [copyMenu, setCopyMenu] = React.useState(1)
  const [copyLecture, setCopyLecture] = React.useState(1)
  const [copyCase, setCopyCase] = React.useState(1)

  const draggleRef = useRef<HTMLDivElement | null>(null)
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
  const [disabled, setDisabled] = useState(true)

  const [courseLoaded, setCourseLoaded] = useState(false) // 加载中

  const [collegeManagerPhone, setCollegeManagerPhone] = useState("")

  const onStart = (event: any, uiData: any) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement
    const targetRect = draggleRef?.current?.getBoundingClientRect()
    if (targetRect) {
      setBounds({
        left: -targetRect?.left + uiData?.x,
        right: clientWidth - (targetRect?.right - uiData?.x),
        top: -targetRect?.top + uiData?.y,
        bottom: clientHeight - (targetRect?.bottom - uiData?.y),
      })
    }
  }

  useEffect(() => {
    sendHttpPostRequest(
      'account/api/query_teacher_authenticate',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
      },
      (response: any) => {
        if (response.is_ok == true) {
          setIsLogin(true)
          setCourseLoaded(false)
          if (response?.college_id !== 0) {
            //已加入机构
            let _collegeId = response.college_info.college_id
            setCollegeId(_collegeId)
            setCollegeInfo(response.college_info)
            setMyCollegeList(response.college_list)
            /** 设置机构、当前人员教师和学生身份 */
            sendHttpPostRequest(
              'course/api/user_query_college_course_list',
              {
                sessionid: Cookies.get('sessionid'),
                sid: Cookies.get('sessionid'),
                college_id: _collegeId,
                source: 1,
                page: page.current,
                pagesize: pageSize.current,
                query: queryContent.current,
              },
              (response: any) => {
                message.destroy()
                setCourseLoaded(true)
                if (response.is_ok == true) {
                  if (response.query_list) {
                    setCourseResult(response.query_list)
                    setResultSum(response.total_sum)
                  }
                }
              }
            )
          } else {
            //未加入机构
            setCollegeId(0)
            let url = 'account/api/user_get_college_manager_idens'
            sendHttpPostRequest(url, {}, (res) => {
             setCollegeManagerPhone(res.data.manager_idens)
            })
            querySystemPublishedCourseList()
          }
        } else {
          //用户未登录
          querySystemPublishedCourseList()
        }
      }
    )
  }, [])

  const querySystemPublishedCourseList = () => {
    sendHttpPostRequest(
      'course/api/user_query_system_published_course_list',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        page: page.current,
        pagesize: pageSize.current,
        query: queryContent.current,
      },
      (response: any) => {
        message.destroy()
        setCourseLoaded(true)
        if (response.is_ok == true) {
          if (response.query_list) {
            setSystemPublisedCoourseList(response.query_list)
          }
        }
      }
    )
  }

  const handleOk = () => {
    if (copyCourseId) {
      //勾选了复制的课程
      setIsModalVisible(false)

      let _collegeId = collegeId
      if (_collegeId == 0) {
        _collegeId = getCollegeId()
      }

      sendHttpPostRequest(
        'course/api/user_create_course',
        {
          course_id: copyCourseId,

          copy_menu: copyMenu,
          copy_lecture: copyLecture,
          copy_case: copyCase,

          college_id: _collegeId,

          sessionid: Cookies.get('sessionid'),
        },
        setCreateCourseCallback
      )
    } else {
      message.error('请选择一门复用课程，或者直接点击新建课程~')
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const onChange = (e) => {
    setValue(e.target.value)
  }

  const onChangeMenu = (e) => {
    setCopyMenu(e.target.checked ? 1 : 0)
  }

  const onChangeLecture = (e) => {
    setCopyLecture(e.target.checked ? 1 : 0)
  }

  const onChangeCase = (e) => {
    setCopyCase(e.target.checked ? 1 : 0)
  }

  const handleSelectChange = (e) => {
    console.log('选择的课程是：')
    console.log(e)
    setCopyCourseId(e)
  }

  const onRadioChange = (e) => {
    let _collegeId = collegeId
    if (_collegeId == 0) {
      _collegeId = getCollegeId()
    }
    setValue(e.target.value)
    setCourseLoaded(true)
    sendHttpPostRequest(
      'course/api/user_query_college_course_list',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        college_id: _collegeId,
        source: e.target.value,
        page: page.current,
        pagesize: 1000,
        query: '',
      },
      (response: any) => {
        message.destroy()
        setCourseLoaded(true)
        if (response.is_ok == true) {
          if (response.query_list) {
            if (e.target.value == 1) {
              setCourseList(
                response.query_list.filter(
                  (item) => item.ispay == '已购买' && item.isoutdate == 0
                )
              )
            } else {
              setCourseList(response.query_list)
            }
            setResultSum(response.total_sum)
          }
        }
      }
    )
  }

  const onCourseCardClick = (course_id, ispay) => {
    message.destroy()
    window.open(`/course-main?course_id=${course_id}`)
  }

  const onCollegeCourseCardClick = (course_id) => {
    message.destroy()
    if (!checkUserLogin()) {
      document.getElementById('show-login-modal')!.click()
    } else {
      let role = 'student'
      if (collegeInfo?.is_college_teacher == 1) {
        role = 'teacher'
        window.open(`/course-main-${role}?course_id=${course_id}&tabKey=1`)
      } else {
        window.open(`/course-main-${role}?course_id=${course_id}`)
      }
    }
  }

  const onCourseTabChange = (key: string) => {
    if ('3' == key) {
      console.log(key)
      // 我的
      if (!checkUserLogin()) {
        document.getElementById('show-login-modal')!.click()
        setCourseResult([])
      }
      courseSource.current = 3
      page.current = 1
      queryCollegeCourse()
    } else if ('2' == key) {
      courseSource.current = 2
      // 院校
      if (!checkUserLogin()) {
        document.getElementById('show-login-modal')!.click()
        setCourseResult([])
      }
      if (collegeId == null || collegeId == 0) {
        setCourseResult([])
      } else {
        setCourseResult([])
        page.current = 1
        queryCollegeCourse()
      }
    } else {
      courseSource.current = 1
      page.current = 1
      queryCollegeCourse()
    }
  }

  // 查询院校课程
  const queryCollegeCourse = () => {
    let _collegeId = collegeId
    if (_collegeId == 0) {
      _collegeId = getCollegeId()
    }
    setCourseLoaded(false)
    sendHttpPostRequest(
      'course/api/user_query_college_course_list',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        college_id: _collegeId,
        source: courseSource.current == 0 ? null : courseSource.current,
        page: page.current,
        pagesize: pageSize.current,
        query: queryContent.current,
      },
      (response: any) => {
        message.destroy()
        setCourseLoaded(true)
        if (response.code == -100) {
          // 没登录
          document.getElementById('show-login-modal')!.click()
          setCourseResult([])
        } else if (response.code == -501) {
          // 没权限
        } else if (response.is_ok == true) {
          if (response.query_list) {
            setCourseResult(response.query_list)
            setResultSum(response.total_sum)
          }
        }
      }
    )
  }

  //页码和页数发生了变化
  const onPageChange = (newPage: number, newPageSize?: number | undefined) => {
    page.current = newPage ? newPage : 1
    if (newPageSize) {
      pageSize.current = newPageSize
    }
    queryCollegeCourse()
  }

  /**
   * 点击了创建课程按钮
   */
  const onCreateCourseClick = () => {
    message.destroy()
    if (!checkUserLogin()) {
      document.getElementById('show-login-modal')!.click()
    } else {
      //if (!checkIsTeacher()) {
      //document.getElementById("show-teacher-authenticate")!.click();
      //} else {
      let _collegeId = collegeId
      if (_collegeId == 0) {
        _collegeId = getCollegeId()
      }
      //以下代码是查询复制课程时的课程列表
      setCourseLoaded(true)
      sendHttpPostRequest(
        'course/api/user_query_college_course_list',
        {
          sessionid: Cookies.get('sessionid'),
          sid: Cookies.get('sessionid'),
          college_id: _collegeId,
          source: 1,
          page: page.current,
          pagesize: pageSize.current,
          query: queryContent.current,
        },
        (response: any) => {
          message.destroy()
          setCourseLoaded(true)
          if (response.is_ok == true) {
            if (response.query_list) {
              setCourseList(
                response.query_list.filter(
                  (item) => item.ispay == '已购买' && item.isoutdate == 0
                )
              )
            }
          }
        }
      )

      setIsModalVisible(true)
      // }
    }
  }

  //点击了搜索按钮
  const onSearchBtn = (event: any) => {
    page.current = 1
    queryContent.current = event.target.value
    if (collegeId > 0) {
      queryCollegeCourse()
    } else {
      querySystemPublishedCourseList()
    }
  }

  /**
   *点击了新建课程按钮
   */
  const createNewCourse = () => {
    message.destroy()
    if (!checkUserLogin()) {
      document.getElementById('show-login-modal')!.click()
    } else {
      //if (!checkIsTeacher()) {
      //document.getElementById("show-teacher-authenticate")!.click();
      // } else {
      //if (!isOAMUser()) {
      //message.success("课程创建功能正在研发中，敬请期待...")
      //} else {
      createCourse()
      //}
      //}
    }
  }

  /**
   * 创建课程回调
   * @param response
   */
  const setCreateCourseCallback = (response: any) => {
    message.destroy()
    if (response.code == -100) {
      // 没登录
      window.location.href = '/login?next=course'
    } else if (response.code == -501) {
      // 没权限
      window.location.href = '/login?next=course'
    } else if (response.is_ok == true) {
      if (response.course_id) {
        window.open(`/course-create?course_id=${response.course_id}`)
      } else {
        message.error(response.message)
      }
    }
  }

  /**
   * 点击创建课程
   */
  const createCourse = () => {
    let _collegeId = collegeId
    if (_collegeId == 0) {
      _collegeId = getCollegeId()
    }
    sendHttpPostRequest(
      'course/api/user_create_course',
      {
        sessionid: Cookies.get('sessionid'),
        college_id: _collegeId,
      },
      setCreateCourseCallback
    )
  }

  /**
   * 删除课程回调
   */
  const setDeleteCourseCallback = (response: any) => {
    message.destroy()
    if (response.code == -100) {
      // 没登录
      window.location.href = '/login?next=course'
    } else if (response.code == -501) {
      // 没权限
      window.location.href = '/login?next=course'
    } else if (response.is_ok == true) {
      message.success('课程删除成功~')
    } else {
      message.error('课程删除失败~')
    }
  }

  /**
   * 点击删除课程
   */
  const deleteCourse = (courseId: string | number) => {
    sendHttpPostRequest(
      'course/api/user_delete_course',
      {
        sessionid: Cookies.get('sessionid'),
        course_id: courseId,
      },
      setDeleteCourseCallback
    )
    queryCollegeCourse()
  }

  const onInviteChange = (e: any) => {
    setInviteNumber(e.target.value)
  }

  // 用户加入院校
  const userJoinCollegeCallback = (response: any) => {
    message.destroy()
    if (response.code == -100) {
      // 未登录
      document.getElementById('show-login-modal')!.click()
    } else if (response.is_ok == true) {
      setCollegeId(response.college_id)
      setCollegeName(response.college_name)
      if (response.college_role == 2) {
        setWrongHidden(true)
        setRole('教师')
        setJoinSuccessVisible(true)
        setTimeout(function () {
          setJoinSuccessVisible(false)
          queryCollegeCourse()
        }, 2000)
      } else if (response.college_role == 1) {
        setWrongHidden(true)
        setRole('学生')
        setJoinSuccessVisible(true)
        setTimeout(function () {
          setJoinSuccessVisible(false)
          queryCollegeCourse()
        }, 2000)
      } else {
        setWrongHidden(false)
      }
      // const college_role = response.college_role == 2 ? "教师" : "学生";
      // message.success("加入院校"+ response.college_name +",身份:"+ response.college_role);
    } else if (response.code == -9) {
      console.log(response.college_id)
      setCollegeId(response.college_id)
      queryCollegeCourse()
    } else {
      message.error(response.message)
    }
  }

  // 用户加入院校
  const userJoinCollege = () => {
    sendHttpPostRequest(
      'college/api/user_join_college_by_code',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        college_code: inviteNumber,
      },
      userJoinCollegeCallback
    )
  }

  const statusBuyColor = (courseStatusBuy: any) => {
    if (courseStatusBuy == '已购买') {
      return '#07c160'
    } else {
      return '#199ed8'
    }
  }

  const statusMyColor = (courseStatusMy: any) => {
    if (courseStatusMy == '进行中') {
      return '#07c160'
    } else if (courseStatusMy == '未发布') {
      return '#199ed8'
    } else if (courseStatusMy == '未开课') {
      return '#ff780e'
    } else if (courseStatusMy == '已结课') {
      return '#0ac6ae'
    } else {
      return '#199ed8'
    }
  }

  return (
    <Layout style={{ minHeight: 750, backgroundColor: '#f9f9f9' }}>
      <div className={courseStyles.maincontent}>
        <div className={courseStyles.courseList}>
          <Row gutter={[16, 16]} style={{ paddingRight: 24, marginBottom: 20 }}>
            <Col span={24}>
              <div>
                <Input
                  onPressEnter={onSearchBtn}
                  placeholder="搜索课程"
                  style={{ float: 'left', borderRadius: 20, width: 300 }}
                  suffix={<SearchOutlined style={{ color: '#666666' }} />}
                />
                {collegeInfo?.is_college_teacher == 1 && ( //针对机构的老师才显示创建课程按钮
                  <Button
                    style={{ float: 'right', borderRadius: 20 }}
                    type="primary"
                    onClick={onCreateCourseClick}
                  >
                    创建课程
                  </Button>
                )}
              </div>
            </Col>
          </Row>

          <div
            hidden={courseLoaded}
            style={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%,-50%)',
              textAlign: 'center',
            }}
          >
            <img height={100} width={100} src={loadingGif}></img>
          </div>
          <Tabs
            defaultActiveKey="1"
            size="small"
            onChange={onCourseTabChange}
            style={{ width: '100%', maxWidth: 1086 }}
          >
            <TabPane tab="平台" key="1">
              <div
                hidden={!courseLoaded}
                className={courseStyles.cardListWarpper}
              >
                {collegeId > 0 ? (
                  <Row gutter={[24, 24]} wrap={true} justify="start">
                    {courseResult?.map((course) => {
                      return (
                        <Col key={course.course_id}>
                          <Card
                            className={courseStyles.courseCard}
                            bodyStyle={{
                              textAlign: 'left',
                              paddingLeft: 15,
                              paddingRight: 15,
                              paddingBottom: 0,
                              paddingTop: 0,
                              width: 250,
                            }}
                            hoverable={true}
                            onClick={onCourseCardClick.bind(
                              this,
                              course.course_id,
                              course.ispay
                            )}
                            cover={
                              <img
                                alt=""
                                src={
                                  course.cover?.startsWith('http')
                                    ? course.cover
                                    : constants.BACKEND_URL + course.cover
                                }
                                height={140}
                                style={{
                                  borderTopRightRadius: 5,
                                  borderTopLeftRadius: 5,
                                }}
                              />
                            }
                          >
                            {course?.ispay == '未购买' && (
                              <div
                                style={{
                                  position: 'absolute',
                                  top: 5,
                                  right: 0,
                                  transform:
                                    'matrix(0.707,0.5,-0.5,0.707,20,0)',
                                  fontSize: 12,
                                  backgroundColor: 'rgba(0,0,0,.5)',
                                  width: 74,
                                  paddingLeft: 18,
                                  color: '#E8E8E8',
                                }}
                              >
                                未购买
                              </div>
                            )}
                            {course?.ispay == '已购买' &&
                              course?.isoutdate == 1 && (
                                <div
                                  style={{
                                    position: 'absolute',
                                    top: 5,
                                    right: 0,
                                    transform:
                                      'matrix(0.707,0.5,-0.5,0.707,20,0)',
                                    fontSize: 12,
                                    backgroundColor: 'rgba(0,0,0,.5)',
                                    width: 74,
                                    paddingLeft: 18,
                                    color: '#E8E8E8',
                                  }}
                                >
                                  需延期
                                </div>
                              )}

                            <Row
                              align="middle"
                              justify="start"
                              gutter={[12, 12]}
                            >
                              <Col
                                span={24}
                                className={courseStyles.courseTitle}
                              >
                                {course.name}
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      )
                    })}
                  </Row>
                ) : (
                  <Row gutter={[24, 24]} wrap={true} justify="start">
                    {systemPublisedCoourseList?.map((course) => {
                      return (
                        <Col key={course.course_id}>
                          <Card
                            className={courseStyles.courseCard}
                            bodyStyle={{
                              textAlign: 'left',
                              paddingLeft: 15,
                              paddingRight: 15,
                              paddingBottom: 0,
                              paddingTop: 0,
                              width: 250,
                            }}
                            hoverable={true}
                            onClick={onCourseCardClick.bind(
                              this,
                              course.course_id,
                              course.ispay
                            )}
                            cover={
                              <img
                                alt=""
                                src={
                                  course.cover?.startsWith('http')
                                    ? course.cover
                                    : constants.BACKEND_URL + course.cover
                                }
                                height={140}
                                style={{
                                  borderTopRightRadius: 5,
                                  borderTopLeftRadius: 5,
                                }}
                              />
                            }
                          >
                            <Row
                              align="middle"
                              justify="start"
                              gutter={[12, 12]}
                            >
                              <Col
                                span={24}
                                className={courseStyles.courseTitle}
                              >
                                {course.name}
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      )
                    })}
                  </Row>
                )}
              </div>
              <ConfigProvider locale={zhCN}>
                <div
                  style={{ marginBottom: 30, paddingTop: 30 }}
                  className={'css.pagination'}
                >
                  <Pagination
                    defaultCurrent={1}
                    total={resultSum}
                    defaultPageSize={16}
                    hideOnSinglePage={true}
                    onChange={onPageChange}
                    current={page.current}
                    showQuickJumper={true}
                    pageSizeOptions={['3', '20', '30', '50', '100']}
                  />
                </div>
              </ConfigProvider>
            </TabPane>
            {isLogin == true && (
              <TabPane tab="院校" key="2">
                {collegeId == 0 && (
                  <div className={courseStyles.cardListWarpper}>
                    <div hidden={!courseLoaded} style={{ marginTop: 60 }}>
                      <div style={{ color: '#666' }}>请输入所在院校院校码</div>
                      <div
                        style={{ color: '#666', fontSize: 12, marginTop: 10 }}
                      >
                        获取院校码请联系{collegeManagerPhone}
                      </div>
                      <Input
                        style={{
                          width: 256,
                          marginTop: 30,
                          borderRadius: 20,
                          borderColor: '#707070',
                        }}
                        onChange={onInviteChange}
                      ></Input>
                      <div
                        style={{
                          textAlign: 'center',
                          marginTop: 5,
                          color: '#ff4b50',
                        }}
                        hidden={wrongHidden}
                      >
                        院校码无效，请输入正确的院校码
                      </div>
                      <div style={{ marginTop: 50 }}>
                        <Button
                          type="primary"
                          style={{ borderRadius: 3, width: 74 }}
                          onClick={userJoinCollege}
                        >
                          确认
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
                {courseResult?.length != 0 && (
                  <div
                    hidden={!courseLoaded}
                    className={courseStyles.cardListWarpper}
                  >
                    <Row gutter={[24, 24]} wrap={true} justify="start">
                      {courseResult?.map((course) => {
                        return (
                          <Col key={course.course_id}>
                            <Card
                              className={courseStyles.courseCardCollege}
                              style={{ height: 'auto' }}
                              bodyStyle={{
                                paddingLeft: 15,
                                paddingRight: 15,
                                paddingBottom: 0,
                                paddingTop: 0,
                                height: 150,
                                width: 250,
                              }}
                              hoverable={true}
                              onClick={onCollegeCourseCardClick.bind(
                                this,
                                course.id
                              )}
                              cover={
                                <img
                                  src={
                                    course.cover?.startsWith('http')
                                      ? course.cover
                                      : constants.BACKEND_URL + course.cover
                                  }
                                  height={140}
                                  width={250}
                                  style={{
                                    borderTopRightRadius: 5,
                                    borderTopLeftRadius: 5,
                                  }}
                                />
                              }
                            >
                              {collegeInfo?.is_college_teacher == 1 && (
                                <Row
                                  align="middle"
                                  justify="start"
                                  gutter={[12, 12]}
                                >
                                  <Col
                                    span={24}
                                    className={courseStyles.courseTitle}
                                  >
                                    {course.name}
                                  </Col>
                                </Row>
                              )}

                              {collegeInfo?.is_college_teacher == 0 && ( //学生才显示课程状态
                                <Row
                                  align="middle"
                                  justify="start"
                                  gutter={[12, 12]}
                                >
                                  <Col
                                    span={18}
                                    className={courseStyles.courseTitle}
                                  >
                                    {course.name}
                                  </Col>
                                  <Col span={6}>
                                    <div
                                      style={{
                                        width: 50,
                                        marginTop: -22,
                                        color: '#fff',
                                        fontSize: 12,
                                        marginLeft: -4,
                                        borderRadius: 3,
                                        textAlign: 'center',
                                        backgroundColor: statusMyColor(
                                          course?.course_status
                                        ),
                                      }}
                                    >
                                      {course?.course_status}
                                    </div>
                                  </Col>
                                </Row>
                              )}

                              <Row style={{ fontSize: 12, color: '#666' }}>
                                时间：{course.start_time} 至 {course.end_time}
                              </Row>
                              <Row
                                style={{
                                  height: 20,
                                  fontSize: 12,
                                  color: '#666',
                                  marginTop: 5,
                                }}
                              >
                                {course?.code !== '' && (
                                  <Col
                                    span={11}
                                    title={course?.code}
                                    style={{
                                      textAlign: 'left',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                    }}
                                  >
                                    课号：{course?.code}
                                  </Col>
                                )}
                                {course?.code !== '' &&
                                  course?.class !== '' && (
                                    <Col span={2}>
                                      <span
                                        style={{
                                          display: 'block',
                                          float: 'left',
                                          width: 1,
                                          height: 10,
                                          backgroundColor: '#ccc',
                                          margin: '4px 6px 0',
                                        }}
                                      ></span>
                                    </Col>
                                  )}
                                {course?.class !== '' && (
                                  <Col
                                    span={11}
                                    title={course?.class}
                                    style={{
                                      textAlign: 'left',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                    }}
                                  >
                                    班级：{course?.class}
                                  </Col>
                                )}
                              </Row>

                              <Row justify="start" style={{ marginTop: 5 }}>
                                <Col
                                  span={18}
                                  style={{ textAlign: 'left', color: '#666' }}
                                >
                                  {collegeInfo?.is_college_teacher == 1 && ( //机构教师才显示原创与否的标签
                                    <>
                                      <span
                                        style={{
                                          color: '#07c160',
                                          float: 'left',
                                        }}
                                      >
                                        {course.label}
                                      </span>
                                      {course.label !== '' && (
                                        <span
                                          style={{
                                            display: 'block',
                                            float: 'left',
                                            width: 1,
                                            height: 10,
                                            backgroundColor: '#ccc',
                                            margin: '6px 5px 0',
                                          }}
                                        ></span>
                                      )}
                                    </>
                                  )}

                                  {course.teacher_name}
                                </Col>

                                <Col
                                  span={6}
                                  style={{
                                    color: '#999999',
                                    textAlign: 'right',
                                  }}
                                >
                                  {' '}
                                  <UserOutlined /> {course.user_count}{' '}
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                        )
                      })}
                    </Row>
                  </div>
                )}

                {collegeId > 0 && courseResult?.length == 0 && (
                  <div hidden={!courseLoaded} style={{ paddingTop: 60 }}>
                    <img src={emptyCourse} height={150} />
                    <div style={{ color: '#999999' }}>
                      学院老师还未创建课程哦！
                    </div>
                  </div>
                )}

                <ConfigProvider locale={zhCN}>
                  <div
                    style={{ marginBottom: 30, paddingTop: 30 }}
                    className={'css.pagination'}
                  >
                    <Pagination
                      defaultCurrent={1}
                      total={resultSum}
                      defaultPageSize={16}
                      hideOnSinglePage={true}
                      onChange={onPageChange}
                      current={page.current}
                      showQuickJumper={true}
                      pageSizeOptions={['3', '20', '30', '50', '100']}
                    />
                  </div>
                </ConfigProvider>
              </TabPane>
            )}

            {isLogin == true && collegeId > 0 && (
              <TabPane tab="我的" key="3">
                <div
                  hidden={!courseLoaded}
                  className={courseStyles.cardListWarpper}
                >
                  <Row gutter={[24, 24]} wrap={true} justify="start">
                    {courseResult?.map((course) => {
                      return (
                        <Col key={course.course_id}>
                          <Card
                            className={courseStyles.courseCardMy}
                            bodyStyle={{
                              paddingLeft: 15,
                              paddingRight: 15,
                              paddingBottom: 0,
                              paddingTop: 0,
                              height: 150,
                              width: 250,
                            }}
                            hoverable={true}
                            onClick={onCollegeCourseCardClick.bind(
                              this,
                              course.id
                            )}
                            cover={
                              <div className={courseStyles.myCardCoverWrapper}>
                                {collegeInfo?.is_college_teacher == 1 &&
                                  course.label == '原创' && (
                                    <div
                                      className={courseStyles.myCardAcitionDiv}
                                    >
                                      <Popconfirm
                                        placement="bottom"
                                        title={'您确定删除此课程吗？'}
                                        onCancel={(e: any) => {
                                          e.stopPropagation()
                                        }}
                                        onConfirm={(e: any) => {
                                          e.stopPropagation()
                                          deleteCourse(course.id)
                                        }}
                                        okText="确认"
                                        cancelText="取消"
                                      >
                                        <Tooltip title="删除">
                                          <Button
                                            onClick={(e: any) => {
                                              e.stopPropagation()
                                            }}
                                            shape="circle"
                                            icon={<DeleteOutlined />}
                                            style={{ paddingLeft: 8 }}
                                          >
                                            {' '}
                                          </Button>
                                        </Tooltip>
                                      </Popconfirm>
                                    </div>
                                  )}

                                {collegeInfo?.is_college_teacher == 1 &&
                                  course?.course_status !== '未发布' && (
                                    <>
                                      {course.visible == 1 ? (
                                        <div
                                          style={{
                                            position: 'absolute',
                                            top: 15,
                                            left: 0,
                                            fontSize: 12,
                                            borderRight: '1px solid #07c160',
                                            borderTop: '1px solid #07c160',
                                            borderBottom: '1px solid #07c160',
                                            backgroundColor: '#07c160',
                                            width: 50,
                                            borderRadius: '0 25px 25px 0',
                                            color: '#fff',
                                          }}
                                        >
                                          公开
                                        </div>
                                      ) : (
                                        <div
                                          style={{
                                            position: 'absolute',
                                            top: 15,
                                            left: 0,
                                            fontSize: 12,
                                            borderRight: '1px solid #07c160',
                                            borderTop: '1px solid #07c160',
                                            borderBottom: '1px solid #07c160',
                                            backgroundColor: '#fff',
                                            width: 50,
                                            borderRadius: '0 25px 25px 0',
                                            color: '#07c160',
                                          }}
                                        >
                                          未公开
                                        </div>
                                      )}
                                    </>
                                  )}

                                <img
                                  alt=""
                                  src={
                                    course.cover?.startsWith('http')
                                      ? course.cover
                                      : constants.BACKEND_URL + course.cover
                                  }
                                  height={140}
                                  width={250}
                                  style={{
                                    borderTopRightRadius: 5,
                                    borderTopLeftRadius: 5,
                                  }}
                                />
                              </div>
                            }
                          >
                            <Row
                              align="middle"
                              justify="start"
                              gutter={[12, 12]}
                            >
                              <Col
                                span={18}
                                className={courseStyles.courseTitle}
                              >
                                {course.name}
                              </Col>
                              <Col span={6}>
                                <div
                                  style={{
                                    width: 50,
                                    marginTop: -22,
                                    color: '#fff',
                                    fontSize: 12,
                                    marginLeft: -4,
                                    borderRadius: 3,
                                    textAlign: 'center',
                                    backgroundColor: statusMyColor(
                                      course?.course_status
                                    ),
                                  }}
                                >
                                  {course?.course_status}
                                </div>
                              </Col>
                            </Row>

                            <Row style={{ fontSize: 12, color: '#666' }}>
                              时间：{course.start_time} 至 {course.end_time}
                            </Row>
                            <Row
                              style={{
                                height: 20,
                                fontSize: 12,
                                color: '#666',
                                marginTop: 5,
                              }}
                            >
                              {course?.code !== '' && (
                                <Col
                                  span={11}
                                  title={course?.code}
                                  style={{
                                    textAlign: 'left',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                  }}
                                >
                                  课号：{course?.code}
                                </Col>
                              )}
                              {course?.code !== '' && course?.class !== '' && (
                                <Col span={2}>
                                  <span
                                    style={{
                                      display: 'block',
                                      float: 'left',
                                      width: 1,
                                      height: 10,
                                      backgroundColor: '#ccc',
                                      margin: '4px 6px 0',
                                    }}
                                  ></span>
                                </Col>
                              )}
                              {course?.class !== '' && (
                                <Col
                                  span={11}
                                  title={course?.class}
                                  style={{
                                    textAlign: 'left',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                  }}
                                >
                                  班级：{course?.class}
                                </Col>
                              )}
                            </Row>

                            <Row justify="start" style={{ marginTop: 5 }}>
                              <Col
                                span={18}
                                style={{ textAlign: 'left', color: '#666' }}
                              >
                                {collegeInfo?.is_college_teacher == 1 && ( //机构教师才显示原创与否的标签
                                  <>
                                    <span
                                      style={{
                                        color: '#07c160',
                                        float: 'left',
                                      }}
                                    >
                                      {course.label}
                                    </span>
                                    {course.label !== '' && (
                                      <span
                                        style={{
                                          display: 'block',
                                          float: 'left',
                                          width: 1,
                                          height: 10,
                                          backgroundColor: '#ccc',
                                          margin: '6px 5px 0',
                                        }}
                                      ></span>
                                    )}
                                  </>
                                )}

                                {course.teacher_name}
                              </Col>

                              <Col
                                span={6}
                                style={{ color: '#999999', textAlign: 'right' }}
                              >
                                {' '}
                                <UserOutlined /> {course.user_count}{' '}
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      )
                    })}
                  </Row>

                  <ConfigProvider locale={zhCN}>
                    <div
                      style={{ marginBottom: 30, paddingTop: 30 }}
                      className={'css.pagination'}
                    >
                      <Pagination
                        defaultCurrent={1}
                        total={resultSum}
                        defaultPageSize={16}
                        hideOnSinglePage={true}
                        onChange={onPageChange}
                        current={page.current}
                        showQuickJumper={true}
                        pageSizeOptions={['3', '20', '30', '50', '100']}
                      />
                    </div>
                  </ConfigProvider>

                  {courseResult?.length == 0 &&
                    collegeInfo?.is_college_teacher == 1 && (
                      <div>
                        <img src={emptyCourse} height={150} />
                        <div style={{ color: '#999999' }}>
                          您还没有创建课程哟~
                        </div>
                        <Button
                          size="small"
                          style={{
                            border: '1px solid #07c176',
                            color: '#07c176',
                            marginTop: 10,
                            borderRadius: 3,
                          }}
                          type="default"
                          onClick={onCreateCourseClick}
                        >
                          {' '}
                          立即创建
                        </Button>
                      </div>
                    )}

                  {courseResult?.length == 0 &&
                    collegeInfo?.is_college_teacher == 0 && (
                      <div>
                        <img src={emptyCourse} height={150} />
                        <div style={{ color: '#999999' }}>
                          你还未加入课程哦~
                        </div>
                      </div>
                    )}
                </div>
              </TabPane>
            )}
          </Tabs>

          <Modal
            title={
              <div
                style={{ width: '100%', cursor: 'move' }}
                onMouseOver={() => {
                  if (disabled) {
                    setDisabled(false)
                  }
                }}
                onMouseOut={() => {
                  setDisabled(true)
                }}
                onFocus={() => {}}
                onBlur={() => {}}
              >
                提示
              </div>
            }
            width={600}
            closable={false}
            visible={isModalVisible}
            className={courseStyles.modal}
            footer={
              <div>
                <Button
                  className={courseStyles.stepsBtn}
                  style={{ marginRight: 20 }}
                  type="default"
                  onClick={handleCancel}
                >
                  取消
                </Button>
                <Button
                  className={courseStyles.stepsBtn}
                  style={{ marginRight: 20, color: '#fff' }}
                  type="primary"
                  onClick={handleOk}
                >
                  确认
                </Button>
              </div>
            }
            modalRender={(modal) => (
              <div>
                <Draggable
                  disabled={disabled}
                  bounds={bounds}
                  onStart={(event, uiData) => onStart(event, uiData)}
                >
                  <div ref={draggleRef}>{modal}</div>
                </Draggable>{' '}
              </div>
            )}
          >
            <div
              style={{
                float: 'left',
                width: 260,
                height: 260,
                border: '1px dotted #07c160',
                marginRight: 10,
                padding: 20,
              }}
            >
              <div
                style={{ color: '#333', cursor: 'pointer', fontWeight: 600 }}
              >
                <StarOutlined style={{ color: '#07c160', marginRight: 5 }} />
                复用课程
              </div>
              <div
                style={{
                  color: '#666',
                  fontSize: 12,
                  margin: '10px 0 10px 20px',
                }}
              >
                复制平台或已创建的课程
              </div>
              <Radio.Group
                name="radiogroup"
                onChange={onRadioChange}
                value={value}
              >
                <Radio value={1}>平台</Radio>
                <Radio value={3}>我的</Radio>
              </Radio.Group>
              <div style={{ marginTop: 10 }}>
                <span
                  style={{ color: '#ff4b50', marginLeft: 7, marginRight: 6 }}
                >
                  *
                </span>
                <Select
                  style={{ width: 180 }}
                  placeholder="请选择使用的课程"
                  onChange={handleSelectChange}
                >
                  {courseList?.map((item, index) => {
                    return (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    )
                  })}
                </Select>
              </div>
              <div style={{ color: '#666', marginTop: 6, marginLeft: 20 }}>
                <Checkbox
                  style={{ marginTop: 10 }}
                  defaultChecked={true}
                  value={1}
                  onClick={onChangeMenu}
                >
                  复制大纲
                </Checkbox>
                <br />
                <Checkbox
                  style={{ marginTop: 10 }}
                  defaultChecked={true}
                  value={1}
                  onClick={onChangeLecture}
                >
                  复制课件
                </Checkbox>
                <br />
                <Checkbox
                  style={{ marginTop: 10 }}
                  defaultChecked={true}
                  value={1}
                  onClick={onChangeCase}
                >
                  复制实验
                </Checkbox>
                <br />
              </div>
            </div>
            <div
              style={{
                float: 'left',
                width: 260,
                height: 260,
                border: '1px dotted #07c160',
                lineHeight: '260px',
              }}
            >
              <div
                style={{
                  color: '#333',
                  textAlign: 'center',
                  cursor: 'pointer',
                  fontWeight: 600,
                }}
                onClick={createNewCourse}
              >
                <FileAddOutlined style={{ color: '#07c160', marginRight: 5 }} />
                新建课程
              </div>
            </div>
          </Modal>

          <Modal
            title={
              <div
                style={{
                  width: '100%',
                  cursor: 'move',
                }}
                onMouseOver={() => {
                  if (disabled) {
                    setDisabled(false)
                  }
                }}
                onMouseOut={() => {
                  setDisabled(true)
                }}
                onFocus={() => {}}
                onBlur={() => {}}
                // end
              >
                提示
              </div>
            }
            visible={joinSuccessVisible}
            bodyStyle={{ height: '120px' }}
            destroyOnClose={true}
            closable={false}
            width={350}
            footer={[]}
            modalRender={(modal) => (
              <div className={courseStyles.miniModal}>
                <Draggable
                  disabled={disabled}
                  bounds={bounds}
                  onStart={(event, uiData) => onStart(event, uiData)}
                >
                  <div ref={draggleRef}>{modal}</div>
                </Draggable>{' '}
              </div>
            )}
          >
            <div style={{ textAlign: 'center', marginTop: 30 }}>
              您已加入院校{' '}
              <span style={{ color: '#07c160' }}>{collegeName}</span> ，身份为{' '}
              {role}。
            </div>
          </Modal>
        </div>
      </div>
      <Feedback />
    </Layout>
  )
}

export default Course
