import React, {useEffect, useRef, useState} from "react";
import {Divider,Steps,Input,Layout, Col, Row, ConfigProvider, Tabs, Button, DatePicker, Modal, Space, message, Table } from 'antd';
import { PageHeader } from "../../common/page-header/header";
import { sendHttpPostRequest } from "../../../api/http-request";
import courseCreateExamStyles from "./course-create-exam.module.less";
import "../course.module.css";
import zhCN from 'antd/lib/locale/zh_CN'
import LoginRegisterModal from "../../../component/modal/login-register/login-register";
import {checkUserLogin} from "../../common/user-center/user-info";

import {ClockCircleOutlined,FileWordOutlined,TableOutlined,DownOutlined,UpOutlined} from  '@ant-design/icons';

import selectedExeStyles from "./course-teacher-selected-exercises.module.less";
import selectExeStyles from "./course-teacher-select-exercises.module.less";

import { getRangeDate } from '../../common/utils/util';
const {TextArea} = Input;
const { RangePicker } = DatePicker;
import Draggable from 'react-draggable'

import moment from 'moment';
import CourseTeacherExamComposition from "./course-teacher-exam-composition";

import PageFooter from "../../common/page-footer/footer";
import Cookies from "js-cookie";
import ExerciseCard from "../../exercise/exercise-card";


const {Step} = Steps

const dateFormat = 'YYYY-MM-DD HH:mm:ss';


const typeMap = new Map([[1, '实操题'], [2, '单选题'], [3, '多选题'],[4,"排序题"],[5, "主观题"]]);
const difficultyMap = new Map([[1, '容易'], [2, '中等'], [3, '困难']]);
  


export interface Props {}
/** 教师课程创建作业页面 */
export const CourseCreateExam: React.FC<Props> = (props) => {
    //const examId = parseInt(window.location.search.split("=")[1]);  // 考试的ID
    const examId = parseInt(window.location.search.split("&")[0].split("=")[1]);//考试ID
    const courseId = parseInt(window.location.search.split("&")[1].split("=")[1]);//课程
    const currentCourseMenuId = parseInt(window.location.search.split("&")[2].split("=")[1]);//课程大纲ID
    const [examName, setExamName] = useState('');//考试名称
    const [examDescription, setExamDescription] = useState('');//考试说明

    /** 考试试题列表 */
    const [examExercises,setExamExercises] = useState([] as any[])

    const [startTime, setStartTime] = useState(moment().utc().zone(-8).format(dateFormat).toString() as any);//考试开始时间
    const [endTime, setEndTime] = useState(moment().utc().zone(-8).add(2, 'hours').format(dateFormat).toString() as any);//考试结束时间

    const [current, setCurrent] = React.useState(0);

    const disabledDate = (current:any) => {
      return current && current < moment().startOf('day');
    }

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
    const [isSaveVisible, setIsSaveVisible] = useState(false);

    const draggleRef = useRef<HTMLDivElement | null>(null)
  
    const [bounds,setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const [disabled,setDisabled] = useState(true);
  
    const onStart = (event:any, uiData:any) => {
      const { clientWidth, clientHeight } = window?.document?.documentElement;
      const targetRect= draggleRef?.current?.getBoundingClientRect();
      if(targetRect){
        setBounds( {
          left: -targetRect?.left + uiData?.x,
          right: clientWidth - (targetRect?.right - uiData?.x),
          top: -targetRect?.top + uiData?.y,
          bottom: clientHeight - (targetRect?.bottom - uiData?.y),
        }
      )
      }
  

    }
  

      /** 计算考试总分 */
  const getTotalScore = ()=>{
    let sumScore = 0;
    examExercises.map(item=>{
        if (parseInt(item.score) == 0)
            sumScore += 5;
        else
            sumScore += parseInt(item.score);
    })

    return sumScore
  }

  //计算考试时长
  const getExamDuration = ()=>{
    const t1 = moment(startTime).utc().zone(-8);
    const t2 = moment(endTime).utc().zone(-8);
    const duration = t2.diff(t1, 'minute');
    console.log(duration)

    return duration

  }


    const onDateRangeChange = (dates,dateStrings) => {
      setStartTime(dateStrings[0]);
      setEndTime(dateStrings[1]);
     
  }
  
      // 查询考试回调
      const queryExamCallback = (response:any) => {
        message.destroy();
        if (response.code == -100) {
            // 未登录
            window.location.href = '/login?next=course-main-teacher?course_id=' + courseId + "&tabKey=5";
        } else if (response.is_ok == true) {
            // 成功
            setExamName(response.query_info.name);
            setExamDescription(response.query_info.description);
            setExamExercises(response.query_info.exercise);
            
            response.query_info.start_time.length > 0 ? setStartTime(response.query_info.start_time) : '';
            response.query_info.end_time.length > 0 ? setEndTime(response.query_info.end_time):'';

        } else if (response.code == -501) {
         //权限不足
         window.location.href = '/login?next=course-main-teacher?course_id=' + courseId + "&tabKey=5";
        } else {
        }
    }

    // 查询考试信息列表
    const queryExam = () => {
        sendHttpPostRequest(
            'exam/api/user_query_exam_by_id/',
            {
                sessionid: Cookies.get("sessionid"),
                exam_id: examId,
                course_id: courseId
 
            },
            queryExamCallback
        )
    }

  

    const saveExamParams = (params: any, showMessage: true | false) => {
        sendHttpPostRequest(
            'exam/api/user_update_exam/',
            params,
            (response:any) => {
              message.destroy();
              if (response.code == -100) {
                  // 没登录
                  window.location.href = '/login?next=course-main-teacher?course_id=' + courseId + "&tabKey=5";
              } else if (response.code == -501) {
                  // 没权限
                  window.location.href = '/login?next=course-main-teacher?course_id=' + courseId + "&tabKey=5";
              } else if (response.is_ok == true) {
                showMessage == true? message.success("考试信息保存成功~") : ""
              }else {
                  message.error(response.message);
              }
          }
        )
    }

    const handleOk = () => { //确认发布考试
      setIsModalVisible(false);
      const params = {
        sessionid: Cookies.get("sessionid"),
        exam_id: examId,
        status: 2//发布状态
      };
      sendHttpPostRequest(
        'exam/api/user_update_exam/',
        params,
        (response:any) => {
          message.destroy();
          if (response.code == -100) {
              // 没登录
              window.location.href = '/login?next=course-main-teacher?course_id=' + courseId + "&tabKey=5";
          } else if (response.code == -501) {
              // 没权限
              window.location.href = '/login?next=course-main-teacher?course_id=' + courseId + "&tabKey=5";
          } else if (response.is_ok == true) {
              setIsSuccessModalVisible(true);
              setTimeout(function() {
                setIsSuccessModalVisible(false);
                window.location.href = '/course-main-teacher?course_id=' + courseId + "&tabKey=5";
              },2000)
          }else {
              message.error(response.message);
          }
        }
    )

    }
  
    const handleCancel = () => {
      setIsModalVisible(false)
    }

  
    
    const onExamExerciseChange = (exercisesList)=>{
      setExamExercises(exercisesList);
    }

    const next = () => {
      message.destroy()
        if(current == 0){
          const params = {
            sessionid: Cookies.get("sessionid"),
            exam_id: examId,
            name: examName,
            description: examDescription,
            start_time: startTime ,
            end_time: endTime
          }
          if(examName == '' || examName.trim() == ''){
            message.error("考试标题不能为空！");
            return '';
          }
          if(examDescription == '' || examDescription.trim() == ''){
            message.error("考试说明不能为空！");
            return '';
          }
          saveExamParams(params, false);
          setCurrent(current + 1);
        }else if(current == 1){
          const params = {
            sessionid: Cookies.get("sessionid"),
            exam_id: examId,
            exercise: JSON.stringify(examExercises)
          }
  
          if(examExercises?.length == 0){
            message.error("试卷中还没有题目呢~");
            return '';
          }
          saveExamParams(params, false);
          setCurrent(current + 1);
        }else if(current == 2)
        {
          setCurrent(current + 1);
        }else {
          setCurrent(current + 1);
        }
    }

    const prev = () => {
        //onSaveBtnClick(null as any);
        setCurrent(current - 1)
    }

 
    useEffect(()=> {
      queryExam();
  },[])




  /** 考试基本信息预览 */
const ExamBasicInfoPreview : React.FC<Props> = (props)=>{




    /** 渲染预览的习题 */
    const renderExercisePreview = (record:any) => {
      return <ExerciseCard exercise={record} renderType="white" teacherOnly={true} editorMode={true} showAnser={false} showClean={true}/>
    }
  

       // 渲染分值列
   const renderScore = (text:any, record:any) => {

     return <div>{text == 0 ? 5 : text}</div>
   
 }

     // 渲染类型
     const renderType = (text: any, record: any) => (
      <div>{typeMap.get(parseInt(text))}</div>
  )

  // 渲染难易程度
  const renderDifficulty = (text: any, record: any) => (
      <div>{difficultyMap.get(parseInt(text))}</div>
  )
  const exercise_columns:any = [
    {
      title: '题号',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 80,
      render:(text,record,index)=>(index + 1)
    },
    {
      title: '题目',
      dataIndex: 'title',
      key: 'title',
      align: 'left',
      width: 200,
      ellipsis:"true"
    },
    {
      title: '题型',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      width: 80,
      render: renderType,
    },
    {
      title: '难度',
      dataIndex: 'difficulty',
      key: 'difficulty',
      align: 'center',
      width: 80,
      render: renderDifficulty,
    },
   
    {
      title: '分值',
      dataIndex: 'score',
      key: 'score',
      align: 'center',
      width: 80,
      render:renderScore,
     
    },
  ]

  return <ConfigProvider locale={zhCN}>
    <div style={{marginTop:20,marginBottom:20}}>
    <Row align='middle' justify='start' gutter={[6,6]}>
        <Col style={{fontWeight:500,fontSize:16,textAlign:'left'}} span={24}> {examName} </Col>
        <Col><span style={{color:'#07C160',fontSize:16}}><ClockCircleOutlined /></span></Col>
        <Col style={{textAlign:'left'}} span={22}> {startTime} 至 {endTime} </Col>
        <Col style={{textAlign:'left'}} span={24}> 共 <span style={{color:"#07C160"}}>{examExercises?.length}</span> 道题   总分<span style={{color:"#07C160"}}> {getTotalScore()} </span>分   考试时长：<span style={{color:"#07C160"}}>{getExamDuration()}</span> 分钟</Col>
        <Col style={{fontWeight:500,textAlign:'left',marginTop:10}} span={24}> 考试说明：</Col>
        <Col style={{textAlign:'left'}} span={24}> {examDescription} </Col>
    </Row>
    <div className={selectedExeStyles.tableWrapper} style={{marginTop:20}}>
    <ConfigProvider locale={zhCN}>
       <Table
         dataSource={examExercises}
         columns={exercise_columns}
         size='small'
         rowKey={record => record.id}
         pagination={false}
         expandable={{
          columnWidth: 90,
          childrenColumnName:"预览",
          expandIconColumnIndex:6,
          expandIcon:({ expanded, onExpand, record }) =>
          expanded ? (
           <><UpOutlined  className={selectExeStyles.actionBtn} onClick={e => onExpand(record, e)} /></>
          ) : (
           <><DownOutlined className={selectExeStyles.actionBtn} onClick={e => onExpand(record, e)} /></>
          ),
          expandedRowRender: renderExercisePreview,
          rowExpandable: record => record.name !== 'Not Expandable',
          }}
          
          />
     </ConfigProvider>
    </div>

  </div>
  </ConfigProvider>
}



/** 填写考试基本信息 */
const ExamBasicInfo =  ()=>{
 
  return <ConfigProvider locale={zhCN}>
    <div style={{marginTop:30,marginBottom:20}}>
      <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:30}} >
          <Col flex="80px" style={{color:"#00000073", textAlign:'left'}}>考试标题</Col>                 
          <Col style={{textAlign:'left'}} flex="400px" > <Input maxLength={20} value={examName} onChange={(e:any)=>{setExamName(e.target.value)}}/> </Col>
      </Row>

      <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:30}}>
          <Col flex="80px" style={{color:"#00000073",textAlign:'left'}}>考试时间</Col>                        
          <Col style={{textAlign:'left'}}  flex="400px">
          { startTime && endTime &&
          <RangePicker
            style={{width:"100%"}}
            disabledDate={disabledDate}
            placeholder={["开始时间","结束时间"]}
            showTime={{ format: 'HH:mm:ss' }}
            format="YYYY-MM-DD HH:mm:ss"
            onChange={onDateRangeChange}
            onOk={()=>{}}
            defaultValue={[moment(startTime,dateFormat), moment(endTime,dateFormat)]}
            separator={<div style={{marginRight:20}}>到</div>}
          />
        }
            </Col>
          
      </Row>

      <Row gutter={[8,8]} justify='start'  align='top' style={{marginBottom:10}} >
          <Col flex="80px" style={{color:"#00000073",textAlign:'left'}}>考试说明</Col>
          <Col flex="400px">
            <TextArea 
              value={examDescription}
              style={{}} 
              rows={6}
              showCount 
              maxLength={500}
              onChange={(e: any)=>{setExamDescription(e.target.value)}}
            >
            </TextArea>
            </Col>
          
      </Row>

  </div>
  </ConfigProvider>
}

    const steps = [
      //布置考试步骤
      {
        title: '填写基本信息',
        content: <div style={{width:480,display:"block",marginLeft:"auto",marginRight:"auto"}}>{ExamBasicInfo()}</div>,
      },
      {
        title: '组卷',
        content: <CourseTeacherExamComposition  course_id = {courseId} menu_id={currentCourseMenuId} initExercises={examExercises}  onExamExerciseChange={onExamExerciseChange}/>  
      },
      {
        title: '发布考试',
        content: <ExamBasicInfoPreview />,
      },
    ]


    /** 点击保存按钮 */
    const onSaveBtnClick = (e:any)=>{
      if(current == 0)//第一步
      {
          const params = {
            sessionid: Cookies.get("sessionid"),
            exam_id: examId,
            name: examName,
            description: examDescription,
            start_time: startTime ,
            end_time: endTime
          }
          if(examName == '' || examName.trim() == ''){
            message.error("考试标题不能为空！");
            return '';
          }
          if(examDescription == '' || examDescription.trim() == ''){
            message.error("考试说明不能为空！");
            return '';
          }
          saveExamParams(params, true);
      }else if(current == 1)//第二步
      {
        const params = {
          sessionid: Cookies.get("sessionid"),
          exam_id: examId,
          exercise: JSON.stringify(examExercises)
        }

        if(examExercises?.length == 0){
          message.error("试卷中还没有题目呢~");
          return '';
        }
        saveExamParams(params, true);
      }else//第三步
      {
        const params = {
          sessionid: Cookies.get("sessionid"),
          exam_id: examId,
          name: examName,
          description: examDescription,
          start_time: startTime ,
          end_time: endTime,
          exercise: JSON.stringify(examExercises)
        }
        saveExamParams(params, true);
      }
    }
    

    return (
        <Layout style={{minHeight: 750,backgroundColor: '#f9f9f9'}}>
            
            <LoginRegisterModal></LoginRegisterModal>

        <div className={courseCreateExamStyles.maindiv} style={{minHeight:"calc(100vh - 184px)"}} >
          <div >
          <Row align="middle" justify="center" style={{borderBottom: '1px solid #eee',height:60}}>
            <Col flex={"100px"} style={{textAlign:"left"}}> <div style={{fontWeight:700,fontSize:16}}>新建考试</div> </Col>
            <Col flex={"auto"} >
              <Steps size='small'  labelPlacement="horizontal" current={current} style={{marginTop:0}}>
                {steps.map((item) => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
            </Col>
            <Col flex={"150px"} style={{color:"#333333",textAlign:"right",cursor:'pointer'}} >
              <a style={{color:"#333333"}} href={"/course-main-teacher?course_id="+ courseId + "&tabKey=5"}>返回{">"}</a> 
             
            </Col>
          </Row>

          
    
            <div style={{minHeight:250}} >{steps[current].content}</div>

            </div>

       </div>

      
        <ConfigProvider locale={zhCN}>
            <Modal
              title={<div style={{width: '100%',cursor: 'move',}}
                onMouseOver={() => {if (disabled) {setDisabled(false)}}}
                onMouseOut={() => {setDisabled(true)}}
                onFocus={() => {}}
                onBlur={() => {}}>提示</div>
              }
              closable={false}
              visible={isSaveVisible}
              bodyStyle={{ height: '100px',borderTop:'1px solid #eee'}}
              destroyOnClose={true}
              width={350}
              footer={
                <div>
                  <Button className={courseCreateExamStyles.stepsBtn} onClick={()=>{setIsSaveVisible(false)}}>取消</Button>                      
                  <Button className={courseCreateExamStyles.stepsBtn} 
                  onClick={()=>{setIsSaveVisible(false);window.open('/course-main-teacher?course_id=1','_self')}}>不保存</Button>
                  <Button className={courseCreateExamStyles.stepsBtn} type="primary" style={{color:'#fff'}}
                  onClick={()=>{setIsSaveVisible(false);window.open('/course-main-teacher?course_id=1','_self')}}>保存</Button>
                        
                </div>
              }
              modalRender={modal => (<div className={courseCreateExamStyles.miniModal}>
                <Draggable
                  disabled={disabled}
                  bounds={bounds}
                  onStart={(event, uiData) => onStart(event, uiData)}
                >
                  <div ref={draggleRef}>{modal}</div>
                </Draggable> </div>
                )}  
            >
              <div style={{textAlign:'center',color:'#333'}}>
                <div>您所填写的内容尚未保存，是否保存？</div>
                <div style={{fontSize:12,marginTop:10}}>若不保存，修改将会丢失。</div>
              </div>
          </Modal>

            <Modal
            title={<div
                style={{
                  width: '100%',
                  cursor: 'move',
                }}
                onMouseOver={() => {
                  if (disabled) {
                    setDisabled(false)
                  }
                }}
                onMouseOut={() => {
                  setDisabled(true)
                }}
                onFocus={() => {}}
                onBlur={() => {}}
              >
                提示
              </div>
            }

              visible={isModalVisible}
              bodyStyle={{ height: '180px'}}
              destroyOnClose={true}
              width={520}
              closable={false}
              footer={
                <div>
                  <Button className={courseCreateExamStyles.stepsBtn}
                  style={{marginRight:20}}
                  type="default"
                  onClick={handleCancel}>取消</Button>
                  <Button className={courseCreateExamStyles.stepsBtn}
                  style={{marginRight:20,color:'#fff'}}
                  type="primary"
                  onClick={handleOk}>确认</Button>
                </div>
              }
              modalRender={modal => (<div className={courseCreateExamStyles.miniModal}>
                <Draggable
                  disabled={disabled}
                  bounds={bounds}
                  onStart={(event, uiData) => onStart(event, uiData)}
                >
                  <div ref={draggleRef}>{modal}</div>
                </Draggable> </div>
              )}
            >

            <Space
                size="small"
                direction="vertical"
                style={{ width: '100%' }}
              >
                <Row justify="center" align="middle" style={{marginBottom:20}}>
                  您确认要发布考试吗？
                </Row>

                <Row justify="center" align="top" style={{marginBottom:10,color:'#999'}}>
                考试时长： <span style={{color:"#07C160"}}>{" " + getExamDuration() + " "} 分</span>
                </Row>
                <Row justify="center" align="top" style={{marginBottom:10,color:'#999'}}>
                总分: <span style={{color:"#07C160",marginRight:10}}> {" " + getTotalScore()+ " "} </span> {"    "} 共 <span style={{color:"#07C160"}}>{" " + examExercises?.length + " "}</span> 题
                </Row>
              </Space>

            </Modal>

            <Modal
            title={<div
                style={{
                  width: '100%',
                  cursor: 'move',
                }}
                onMouseOver={() => {
                  if (disabled) {
                    setDisabled(false)
                  }
                }}
                onMouseOut={() => {
                  setDisabled(true)
                }}
                onFocus={() => {}}
                onBlur={() => {}}
                // end
              >
                提示
              </div>
            }
              closable={false}
              visible={isSuccessModalVisible}
              bodyStyle={{ height: '100px'}}
              destroyOnClose={true}
              width={350}
              modalRender={modal => (<div className={courseCreateExamStyles.miniModal}>
                <Draggable
                  disabled={disabled}
                  bounds={bounds}
                  onStart={(event, uiData) => onStart(event, uiData)}
                >
                  <div ref={draggleRef}>{modal}</div>
                </Draggable> </div>
              )}
              footer={
                []
              }
            >

              <div style={{textAlign:'center'}}>
                已发布成功！
              </div>
               

            </Modal>
        </ConfigProvider>

         <div style={{textAlign:'center',bottom:0,paddingTop:0,paddingBottom:0,width:'100%',backgroundColor:'#fff',boxShadow:'0 -2px 5px #eee'}}>
          {/* <div style={{margin:'14px auto'}}> */}
            
              {current > 0 && (
                <Button 
                  type="default" 
                  className={courseCreateExamStyles.stepsBtn} 
                  style={{marginRight:20,marginTop:14,marginBottom:14}}  
                  onClick={() => prev()}>
                  上一步
                </Button>
              )}

            {current >= 0 && (
                <Button
                  className={courseCreateExamStyles.stepsBtn}
                  style={{marginRight:20,marginTop:14,marginBottom:14}}
                  type="default"
                  onClick={onSaveBtnClick}
                >
                  保存
                </Button>
              )}

              {current < steps.length - 1 && (
                <Button
                  className={courseCreateExamStyles.stepsBtn}
                  style={{marginRight:20,color:'#fff',marginTop:14,marginBottom:14}}
                  type="primary"
                  onClick={() => { next();}}
                  //disabled={!uploadSuccess}
                >
                  下一步
                </Button>
              )}

              {current == steps.length - 1 && (
                <Button
                  className={courseCreateExamStyles.stepsBtn}
                  style={{marginRight:20,color:'#fff',marginTop:14,marginBottom:14}}
                  type="primary"
                  onClick={() => {setIsModalVisible(true)}}
                >
                  发布
                </Button>
              )}
          {/* </div> */}
        </div> 
          {/*   */}
       </Layout>
    )

}

export default CourseCreateExam

