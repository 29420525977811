import {Tabs,Pagination, DatePicker,Layout, message, ConfigProvider, Input, Table, Modal, Button, Row, Statistic, Col, Upload, InputNumber, Popconfirm, Checkbox} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useState, useRef, useEffect } from "react";
import Cookies from 'js-cookie';
import courseBasicInfoStyle from "../../course/course-edit-basicinfo.module.less"

import * as constants from '../../../constants/constants';

import {CopyToClipboard} from 'react-copy-to-clipboard';

import courseCreateStyles from "../../course/course-create.module.less";
import courseEditSlidesCasesStyle from "../../course/course-edit-slides-cases.module.less"
import moduleStyles from "../module/module.module.less";
import zhCN from 'antd/lib/locale/zh_CN';
import {sendHttpPostRequest} from "../../../api/http-request";
const { Search } = Input;
import TextArea from 'antd/lib/input/TextArea';
import OAMSider from "../oam-sider";
import {SearchOutlined,PlusCircleOutlined,FileImageOutlined, EditOutlined} from '@ant-design/icons';
import idataCourseTableStyles from "../../common/global-styles/idatacourse-table.module.less";

import ReactDOM from 'react-dom';
const { RangePicker } = DatePicker;
const {TabPane} = Tabs

import idataCodingTableStyles from "../../common/global-styles/idatacourse-table.module.less";

import menuStyles from "../menu/menu-edit.module.less";

export interface Props {}

const statusMap = new Map([[1, '下线'], [2, '在线']]);
const paidMap = new Map([[1, '未付费'], [2, '已付费']]);


import { getRangeDate } from '../../common/utils/util';
import moment from 'moment';
import Draggable from 'react-draggable';

const sexMap = new Map([[1, '未设置'], [2, '男'], [3, '女']]);
const roleMap = new Map([[1, '学生'], [2, '教师']]);
const channelMap = new Map([['爱数科', 1], ['爱数课', 2]]);

export const OAMInstitutionEdit: React.FC<Props> = (props) => {
    const collegeId = parseInt(window.location.search.split("=")[1]);  // 机构ID
    const [collegeName, setCollegeName] = useState(""); //机构名称
    const [collegeIntroduction, setCollegeIntroduction] = useState(""); //机构介绍
    const [collegeLogo, setCollegeLogo] = useState(""); //机构logo图片

    const [systemOnlineCourseList, setSystemOnlineCourseList] = useState(null as any);//平台已上线课程列表
    const [currentCollegeCourseList, setCurrentCollegeCourseList] = useState(null as any);//当前机构已绑定课程
    const [selectedCourses, setSelectedCourses] = useState([] as any[]);//记录已打钩课程列表

    const [startTime, setStartTime] = useState(null as any);//机构开始时间
    const [endTime, setEndTime] = useState(null as any);//机构结束时间
    const [managerName, setManagerName] = useState("");//机构管理员名称
    const [onlineStatus, setOnlineStatus] = useState(null as any);//机构在线状态
    const [paid, setPaid] = useState(null as any);//机构付费状态
    const [salesman, setSalesman] = useState("");//机构负责业务员

    const [studentCount, setStudentCount] = useState("");//机构学生数量
    const [studentCode, setStudentCode] = useState("");//机构学生码
    const [studentCodeTime, setStudentCodeTime] = useState("");//机构学生码有效期

    const [teacherCount, setTeacherCount] = useState("");//机构教师数量
    const [teacherCode, setTeacherCode] = useState("");//机构教师码
    const [teacherCodeTime, setTeacherCodeTime] = useState("");//机构教师码有效期

    const [courseStartEndTimeMap, setCourseStartEndTimeMap] = useState(new Map([]));//保存本机构课程的时间的map

    const [remarks, setRemarks] = useState("");//机构备注信息


    const dateFormat = 'YYYY-MM-DD';


    const [isSelectUserModalVisible, setIsSelectUserModalVisible] = useState(false);
    const selectedUser = useRef(null as any);//选择的用户
    const draggleRef = useRef<HTMLDivElement | null>(null)
  
    const [bounds,setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const [disabled,setDisabled] = useState(true)
  
    const [userResult, setUserResult] = useState([] as any[]);  // 当前显示的结果页
    const [resultSum, setResultSum] = useState(0);  // 搜索返回的结果数
    const [totalCount, setTotalCount] = useState(0);  // 搜索返回的结果数

    const queryUserContent = useRef('');  // 记录搜索串
    const orderUser = useRef('');  // 排序升序降序
    const orderByUser = useRef('');  // 排序列
    const pageUser = useRef(1);  // 记录当前的页数
    const pageSizeUser = useRef(20);  // 记录当前每页展示数量
    const sexUserFilters = useRef([]);  // 性别过滤选项
    const roleUserFilters = useRef([]);  // 身份过滤选项
    const channelUserFilters = useRef([]);  // 渠道过滤项

    const onStart = (event:any, uiData:any) => {
      const { clientWidth, clientHeight } = window?.document?.documentElement;
      const targetRect= draggleRef?.current?.getBoundingClientRect();
      if(targetRect){
        setBounds( {
          left: -targetRect?.left + uiData?.x,
          right: clientWidth - (targetRect?.right - uiData?.x),
          top: -targetRect?.top + uiData?.y,
          bottom: clientHeight - (targetRect?.bottom - uiData?.y),
        }
      )
    }

    }
    
    useEffect(() => {
        queryInstitution();
        queryUserInfo();
        querySystemOnlineCourseList();
    }, [])


    // 查询平台已上线课程列表
    const querySystemOnlineCourseList = () => {
        const sessionid = Cookies.get("sessionid");
        const queryData = {
            query: '',
            page: 1,
            pagesize: 10000,
            source: '2',
            sessionid: sessionid,
            sid: sessionid,
        };
        sendHttpPostRequest(
            'course/api/oam_query_course_list/',
            queryData,
            courseListCallback
        )
    }

    // 查询平台已上线课程列表接口回调
    const courseListCallback = (response: any) => {
        // console.log(response);
        message.destroy();
        if (response.code == -100) {
            // 未登录
            window.location.href = '/oam_login?next=/oam_course_list';
        } else if (response.is_ok == true) {
            // 成功
            setSystemOnlineCourseList(response.query_list.filter(item=> item.status == 2));//筛选项目中已经上线的部分
            response.query_list.filter(item=> item.status == 2)?.map(onlineCourse=>{
                courseStartEndTimeMap.set(onlineCourse.id,{id:onlineCourse.id, start_time:getRangeDate(0),end_time:getRangeDate(180)})
            }     
            )
           
        } else if (response.code == -501) {
            // 权限不足
            window.location.href = '/oam_login?next=oam_course_list';
        } else {
            message.error(response.message);
        }
    }


    // 查询课程信息
    const queryInstitution = () => {
        const sessionid = Cookies.get("sessionid");
        const queryData = {
          
            college_id: collegeId,
            sessionid: sessionid,
            sid: sessionid,
        };
        sendHttpPostRequest(
            'college/api/oam_query_college_by_id/',
            queryData,
            institutionCallback
        )
    }


    // 查询机构接口回调
    const institutionCallback = (response: any) => {
        // console.log(response);
        message.destroy();
        if (response.code == -100) {
            // 未登录
            window.location.href = '/oam_login?next=/oam_institution_list';
        } else if (response.is_ok == true) {
            // 成功
            setCollegeName(response.college_info.name);
            setCollegeIntroduction(response.college_info.introduction);

            setStartTime(response.college_info.start_time == "" ? getRangeDate(0) : response.college_info.start_time);
            setEndTime(response.college_info.end_time == "" ? getRangeDate(180) : response.college_info.end_time);
            
            setCollegeLogo(response.college_info.logo_url);
            setManagerName(response.college_info.manager_real_name == '' ? response.college_info.manager_name :response.college_info.manager_real_name );
            setOnlineStatus(response.college_info.online_status);
            setPaid(response.college_info.paid);
            setSalesman(response.college_info.salesman);

            setStudentCount(response.college_info.student_count);
            setStudentCode(response.college_info.student_code);
            setStudentCodeTime(response.college_info.student_code_time);

            setTeacherCount(response.college_info.teacher_count);
            setTeacherCode(response.college_info.teacher_code);
            setTeacherCodeTime(response.college_info.teacher_code_time);

            setCurrentCollegeCourseList(response.college_info.course_list);

            response.college_info.course_list?.map(myCourse=>{
                courseStartEndTimeMap.set(myCourse.id, {id:myCourse.id,start_time:myCourse.start_time,end_time:myCourse.end_time})
            })            

            if(response.college_info.manager_name) {
                selectedUser.current = {user_id:response.college_info.manager_id, nick_name:response.college_info.manager_name}
            }

            setRemarks(response.college_info.remarks);
        } else if (response.code == -501) {
            // 权限不足
            window.location.href = '/oam_login?next=oam_institution_list';
        } else {
            message.error(response.message);
        }
    }


      //选择课程发生了改变
      const handleSelectCoursesChange = (checkedValue:any) => {
      
        setSelectedCourses(checkedValue);
        
         //1.获取需要绑定的课程id列表
        const toBindCourseIds = checkedValue//?.filter(item => currentCollegeCourseList.filter(course=>course.id == item).length == 0);

        //2.获取需要解绑的课程id列表
        const toUnBindCourseIds = systemOnlineCourseList?.filter(item => checkedValue.filter(course=>course == item.id).length == 0).map(item=>item.id)//currentCollegeCourseList?.filter(item => checkedValue.filter(course=>course == item.id).length == 0).map(item=>item.id);
        
        //1.发送绑定课程请求

        const toBindCourse = []  as any[]
        //console.log(toBindCourseIds)
        //console.log(courseStartEndTimeMap)
        toBindCourseIds?.map((couseId:any)=>{
            //console.log(couseId)
            if(courseStartEndTimeMap.get(couseId)){
                toBindCourse.push(courseStartEndTimeMap.get(couseId));
            }
            
        })
        console.log(toBindCourse)
        

        bindCourseList(toBindCourse);

        //1.发送解绑课程请求
        toUnBindCourseIds?.length > 0 ? unBindCourseList(toUnBindCourseIds) :"";
        //queryInstitution();
         
      }


    // 机构绑定课程
    const bindCourseList = (bindCourseIds:any) => {
        const sessionid = Cookies.get("sessionid");
        const queryData = {
            college_id: collegeId,
            course_id_list: JSON.stringify(bindCourseIds),
            sessionid: sessionid,
            sid: sessionid,
        };
        sendHttpPostRequest(
            'course/api/oam_bind_college_course/',
            queryData,
            (response: any) => {
                // console.log(response);
                message.destroy();
                if (response.code == -100) {
                    // 未登录
                    window.location.href = '/oam_login?next=/oam_course_list';
                } else if (response.is_ok == true) {
                    // 成功
                    message.success("绑定课程成功~");
                   
                } else if (response.code == -501) {
                    // 权限不足
                    window.location.href = '/oam_login?next=oam_course_list';
                } else {
                    message.error(response.message);
                }
            }
        )
    }

    // 机构绑定课程
    const unBindCourseList = (unBindCourseIds:any) => {
        const sessionid = Cookies.get("sessionid");
        const queryData = {
            college_id: collegeId,
            course_id_list: JSON.stringify(unBindCourseIds),
            sessionid: sessionid,
            sid: sessionid,
        };
        sendHttpPostRequest(
            'course/api/oam_unbind_college_course/',
            queryData,
            (response: any) => {
                // console.log(response);
                message.destroy();
                if (response.code == -100) {
                    // 未登录
                    window.location.href = '/oam_login?next=/oam_course_list';
                } else if (response.is_ok == true) {
                    // 成功
                    message.success("解绑课程成功~");
                   
                } else if (response.code == -501) {
                    // 权限不足
                    window.location.href = '/oam_login?next=oam_course_list';
                } else {
                    message.error(response.message);
                }
            }
        )
    }



    // 查询用户列表
    const queryUserInfo = () => {
        const sessionid = Cookies.get("sessionid");
        const queryData = {
            query: queryUserContent.current,
            page: pageUser.current,
            pagesize: pageSizeUser.current,
            order: orderUser.current,
            order_by: orderByUser.current,
            sex: JSON.stringify(sexUserFilters.current),
            role: JSON.stringify(roleUserFilters.current),
            channel: JSON.stringify(channelUserFilters.current),
            sessionid: sessionid,
            sid: sessionid,
            college_id: collegeId
        };
        sendHttpPostRequest(
            'account/api/query_user_list/',
            queryData,
            userListCallback
        )
    }

    // 查询用户列表接口回调
    const userListCallback = (response: any) => {
        // console.log(response);
        message.destroy();
        if (response.code == -100) {
            // 未登录
            window.location.href = '/oam_login?next=user_list';
        } else if (response.is_ok == true) {
            // 成功
            setUserResult(response.user_list);
            setResultSum(response.total_sum);
            setTotalCount(response.total_count);
        } else if (response.code == -501) {
            // 权限不足
            window.location.href = '/oam_login?next=user_list';
        } else {
            message.error(response.message);
        }
    }

    //页码和页数发生了变化
    const onUserPageChange = (newPage: number, newPageSize?: number | undefined) => {
        pageUser.current = newPage ? newPage : 1;
        if (newPageSize) {
            pageSizeUser.current = newPageSize;
        }
        queryUserInfo();
    }

    // 渲染性别
    const renderSex = (text: any, record: any) => (
        <div>{sexMap.get(parseInt(text))}</div>
    )

    // 渲染身份
    const renderRole = (text: any, record: any) => (
        <div>{roleMap.get(parseInt(text))}</div>
    )

  

    /** 选择了用户 */
    const rowUserSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
            selectedUser.current = selectedRows[0];
            //console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        getCheckboxProps: (record: any) => ({
            disabled: record.user_id == selectedUser.current?.user_id
            //name: record.name,
        }),
        };

    //点击了搜索按钮
    const onSearchUserBtn = (e: any) => {
        pageUser.current = 1;
        queryUserContent.current = e.target.value;
        queryUserInfo();
    }

    // 表格发生变化
    const onUserTableChange:any = (pagination:any, filters:any, sorter:any, extra: { currentDataSource: [], action: any })=> {
        if (sorter.order) { //如果选择了排序列
            orderUser.current = sorter.order;
            orderByUser.current = sorter.field;
        }
        if (filters.sex) { //如果选择了过滤列
            sexUserFilters.current = filters.sex;
        } else {
            sexUserFilters.current = [];
        }
        if (filters.role) {
            roleUserFilters.current = filters.role;
        } else {
            roleUserFilters.current = [];
        }
        if (filters.channel_name) {
            channelUserFilters.current = filters.channel_name.map(element=> {
                return channelMap.get(element);
            });
        } else {
            channelUserFilters.current = [];
        }
        pageUser.current = 1;
        queryUserInfo();
    }

    // 表格列
    const userCols: any = [
        {
            title: "序号",
            dataIndex: "user_id",
            key: "user_id",
            width: 80,
            align: 'center',
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: "手机号",
            dataIndex: "phone",
            key: "phone",
            width: 80,
            align: 'center',
        },
        {
            title: "注册时间",
            dataIndex: "register_date",
            key: "register_date",
            width: 120,
            align: 'center',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
            render: (text: any, record:any)=>{return text.slice(0,10)}
        },
        {
            title: "昵称",
            dataIndex: "nick_name",
            key: "nick_name",
            width: 80,
            align: 'left'
        },
        {
            title: "真实姓名",
            dataIndex: "real_name",
            key: "real_name",
            width: 60,
            align: 'left',
        },
        {
            title: "性别",
            dataIndex: "sex",
            key: "sex",
            width: 50,
            align: 'center',
            render: renderSex,
            filters: [
                {
                    text: '未设置',
                    value: 1,
                },
                {
                    text: '男',
                    value: 2,
                },
                {
                    text: '女',
                    value: 3,
                }
            ],
            onFilter: (value:any, record:any) => true,
        },
        /*{
            title: "邀请者",
            dataIndex: "inviter",
            key: "inviter",
            width: 80,
            align: 'left',
        },*/
        {
            title: "来源",
            dataIndex: "channel_name",
            key: "channel_name",
            width: 80,
            align: 'left',
            filters: [
                {
                    text: '爱数科',
                    value: '爱数科',
                },
                {
                    text: '爱数课',
                    value: '爱数课',
                }
            ],
            onFilter: (value:any, record:any) => true,
        },
        {
            title: "平台身份",
            dataIndex: "role",
            key: "role",
            width: 50,
            align: 'center',
            render: renderRole,
            filters: [
                {
                    text: '学生',
                    value: 1,
                },
                {
                    text: '教师',
                    value: 2,
                }
            ],
            onFilter: (value:any, record:any) => true,
        },
    ]


    const handleSelectUserOk = () => { //选择了用户
        setIsSelectUserModalVisible(false);

        console.log(selectedUser.current);

        saveInstitution();
        setManagerName(selectedUser.current?.real_name == '' ? selectedUser.current?.nick_name : selectedUser.current?.real_name );
    }



    const showSelectUserModal = () => {
       setIsSelectUserModalVisible(true);
    };

    const  handleSelectUserCancel = () => {
        setIsSelectUserModalVisible(false)
    }


    /**
     * 创建课程回调
     * @param response
     */
     const saveCourseInfoCallback = (response:any) => {
        message.destroy();
        if (response.code == -100) {
            // 没登录
            window.location.href = '/oam_login?next=oam_institution_list';
        } else if (response.code == -501) {
            // 没权限
            window.location.href = '/oam_login?next=oam_institution_list';
        } else if (response.is_ok == true) {
             message.success("课程机构保存成功~");
        }else {
            message.error(response.message);
        }
    }

    /**
     * 点击保存机构按钮
     */
    const saveInstitution = () => {
        sendHttpPostRequest(
            'college/api/oam_update_college/',
            {
                college_id: collegeId,
                college_name: collegeName,
                introduction: collegeIntroduction,
                logo_url: collegeLogo,
                start_time: startTime,
                end_time: endTime,
                paid: paid,
                salesman: salesman,
                manager_id: selectedUser.current?.user_id,
                manager_name: selectedUser.current?.nick_name,
                remarks: remarks,
                sessionid: Cookies.get("sessionid"),
                sid: Cookies.get("sessionid"),
            },
            saveCourseInfoCallback
        )
    }

    const disabledDate = (current:any) => {
        return false;//return current && current > moment().endOf('day');
    }


   // 上传机构logo传参
   const uploadData = {
    sessionid: Cookies.get("sessionid"),
    sid: Cookies.get("sessionid"),
    //exercise_id: """,
    }


    // 上传图片
    const uploadImageProps = {
      accept: '.png,.jpg,jpeg,.gif,.svg',
      name: 'college_logo',
      multiple: false,
      showUploadList: false,
      data: uploadData,
      action: constants.BACKEND_API_URL + 'college/api/oam_upload_college_logo',
      onChange(info) {
          const { status } = info.file;
          if (status !== 'uploading') {
              console.log(info.file, info.fileList);
          }
          if (status === 'done') {
              if (info.file.response.is_ok) {
                  message.success(`图片${info.file.name}文件上传成功！`);
                  setCollegeLogo(info.file.response.logo_url);
                  //saveInstitution();
              } else {
                  if (info.file.response.code == -100) {
                      // 未登录
                      const localPath = window.location.href.split(window.location.host)[1];
                      window.location.href = `/oam_login?next=${localPath}`;
                  } else if (info.file.response.code == -501) {
                      // 权限不足
                      const localPath = window.location.href.split(window.location.host)[1];
                      window.location.href = `/oam_login?next=${localPath}`;
                  } else {
                      message.error(info.file.response.message);
                  }
              }

          } else if (status === 'error') {
              message.error(`图片${info.file.name}文件上传失败了:(`);
          }
      },
      onDrop(e) {
          console.log('Dropped files', e.dataTransfer.files);
      },
  };

    // 上下架回调
    const onPutOnOffBtnClickCallback = (response: any) => {
        message.destroy();
        if (response.code == -100) {
            // 没登录
            window.location.href = '/oam_login?next=oam_institution_list';
        } else if (response.code == -501) {
            // 没权限
            window.location.href = '/oam_login?next=oam_institution_list';
        } else if (response.is_ok == true) {
            message.success(response.message);
            queryInstitution();
        } else {
            message.error(response.message);
        }
    }

    const onDateRangeChange = (dates,dateStrings) => {
        setStartTime(dateStrings[0]);
        setEndTime(dateStrings[1]);
        saveInstitution();   
    }

    // 点了上下架按钮
    const onPutOnOffBtnClick = (collegeId:number, status:number) => {

        if(status == 1) { //上线
            sendHttpPostRequest(
                'college/api/oam_set_college_online/',
                {
                    sessionid: Cookies.get("sessionid"),
                    college_id: collegeId
                },
                onPutOnOffBtnClickCallback
            )
        }else {//下线
            sendHttpPostRequest(
                'college/api/oam_set_college_offline/',
                {
                    sessionid: Cookies.get("sessionid"),
                    college_id: collegeId
                },
                onPutOnOffBtnClickCallback
            )
        }
        
    }

        // 点了生成教师码
        const generateTeacherCodeCallback = (response: any) => {
            message.destroy();
            if (response.code == -100) {
                // 没登录
                console.log("没登录")
                //window.location.href = '/oam_login?next=oam_institution_list';
            } else if (response.code == -501) {
                // 没权限
                console.log("没权限")
                //window.location.href = '/oam_login?next=oam_institution_list';
            } else if (response.is_ok == true) {
                setTeacherCode(response?.teacher_code);
                setTeacherCodeTime(response?.teacher_code_time);
            } else {
                message.error(response.message);
            }
        }

        // 点了生成学生码
        const generateStudentCodeCallback = (response: any) => {
        message.destroy();
        if (response.code == -100) {
            // 没登录
            console.log("没登录")
            //window.location.href = '/oam_login?next=oam_institution_list';
        } else if (response.code == -501) {
            // 没权限
            console.log("没权限")
            //window.location.href = '/oam_login?next=oam_institution_list';
        } else if (response.is_ok == true) {
            setStudentCode(response?.student_code);
            setStudentCodeTime(response?.student_code_time);
        } else {
            message.error(response.message);
        }
    }
    
        // 点了生成教师码和学生码按钮
        const generateCode = (codeType: number) => {
                sendHttpPostRequest(
                    'college/api/manager_update_college_code/',
                    {
                        sessionid: Cookies.get("sessionid"),
                        college_id: collegeId,
                        code_type: codeType
                    },
                    codeType == 2? generateTeacherCodeCallback :generateStudentCodeCallback
                )     
        }


    const onCourseDateRangeChange = (course_id:any,dates,dateStrings) => {
        courseStartEndTimeMap.set(course_id,{id:course_id, start_time: dateStrings[0], end_time:dateStrings[1]})
        bindCourseList([{id:course_id, start_time: dateStrings[0], end_time:dateStrings[1]}])
    
    }

      // 查案例列表

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <OAMSider keys={"2"} openKeys={["sub-user"]}></OAMSider>
            <Layout style={{backgroundColor:'#f9f9f9'}}>
                <Content style={{ backgroundColor:'#fff',borderRadius:5,minWidth:600,margin:30 ,paddingTop:20}}>
                <ConfigProvider locale={zhCN}>
                    <div style={{paddingLeft:30,paddingRight:30}}>
                        <div className={"css.search1"} style={{textAlign:"left",paddingBottom:20}}>
                        <Row align="middle"  gutter={16} >

                            <Col flex='auto' style={{textAlign: 'right'}}>
                                <Button type="primary" style={{float: 'right', marginRight: 20, borderRadius: 20}} onClick={()=>{saveInstitution();  message.success("机构信息保存成功~");}}>保 存</Button>
                            </Col>
                        </Row>
                            
                        </div>

                        <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:20}} >
                            <Col flex="80px" style={{color:"#00000073",textAlign:'left'}}>机构名称</Col>
                            <Col flex="auto" style={{textAlign:'left'}}>
                                <Input maxLength={30} placeholder="请输入机构名称" required={true} value={collegeName} onChange={(e)=>{setCollegeName(e.target.value)}} style={{borderRadius:3,width:"100%"}}/>
                            </Col>
                        </Row>


                        <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:20}} >
                            <Col flex="80px" style={{color:"#00000073",textAlign:'left'}}>机构介绍</Col>
                            <Col flex="auto" style={{textAlign:'left'}}>
                                <TextArea  showCount maxLength={500} placeholder="请输入机构介绍" 
                                style={{borderRadius:3,width:"100%"}} 
                                autoSize={{minRows: 3, maxRows: 5}} 
                                value={collegeIntroduction} 
                                onChange={(e)=>{setCollegeIntroduction(e.target.value)}}
                                />

                            </Col>
                        </Row>


                        <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:20}} >
                            <Col flex="80px" style={{color:"#00000073",textAlign:'left'}}>机构logo</Col>
                            <Col flex="auto" style={{textAlign:'left'}}>
                                <Upload className={courseBasicInfoStyle.uploadImage} {...uploadImageProps}>
                                

                                    { collegeLogo?
                                        <img
                                        id="course-cover"
                                        width={200}
                                        height={50}
                                        src={ collegeLogo?.startsWith("http") ? collegeLogo : constants.BACKEND_API_URL + collegeLogo}
                                        /> : <div  style={{paddingTop:15,textAlign:"center",width:200,height:50,border:"1px solid #00000073"}}> + 上传(点击或拖拽)</div>
                                    }
          
                                </Upload>
                            </Col>
                        </Row>
         
                        <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:20}} >
                            <Col flex="80px" style={{color:"#00000073",textAlign:'left'}}>起始日期</Col>
                            <Col flex="auto" style={{textAlign:'left'}}>
                                <ConfigProvider locale={zhCN}>
                                    {startTime && endTime &&  <RangePicker onChange={onDateRangeChange} style={{marginLeft:10}} format={dateFormat} disabledDate={disabledDate} defaultValue={[moment(startTime,dateFormat),moment(endTime,dateFormat)]}/> }
                                </ConfigProvider>
                            </Col>
                        </Row>


                        <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:20}} >
                            <Col flex="80px" style={{color:"#00000073",textAlign:'left'}}>管理员</Col>
                            <Col flex="auto" style={{textAlign:'left'}}>
                                <div> {managerName} </div>
                            </Col>
                            <Col flex="100px" style={{color:"#00000073",textAlign:'right'}}>
                                <Button onClick={showSelectUserModal} size="small" style={{borderColor:'#07c160',width:80,height:32,color:'#07c160'}} > {managerName == "" ? '设 置' : '更 改'} </Button>
                            </Col>
                        </Row>

                        <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:20}} >
                            <Col flex="80px" style={{color:"#00000073",textAlign:'left'}}>在线状态</Col>
                            <Col flex="auto" style={{textAlign:'left'}}>
                                { onlineStatus == 1 ? "下线" : "在线" }
                            </Col>
                            <Col flex="100px" style={{color:"#00000073",textAlign:'right'}}>
                                <Popconfirm placement="top" title={onlineStatus == 1 ? '上线' : '下线' } onConfirm={()=>{onPutOnOffBtnClick(collegeId, onlineStatus)}} okText="确认" cancelText="取消">
                                    <Button size="small" style={{borderColor:'#07c160',width:80,height:32,color:'#07c160'}} > {onlineStatus == 1 ? '上 线' : '下 线'} </Button>
                                </Popconfirm>
                            </Col>
                        </Row>

                        <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:20}} >
                            <Col flex="80px" style={{color:"#00000073",textAlign:'left'}}>付费状态</Col>
                            <Col flex="auto" style={{textAlign:'left'}}>
                                { paid == 1 ? "未付费" : "已付费" }
                            </Col>
                            <Col flex="100px" style={{color:"#00000073",textAlign:'right'}}>
                                <Button onClick={()=>{setPaid(paid == 1 ? 2: 1);}} size="small" style={{borderColor:'#07c160',width:80,height:32,color:'#07c160'}} > { paid == 1  ? '改为付费' : '改为未付费'} </Button>
                            </Col>
                        </Row>

                        <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:20}} >
                            <Col flex="80px" style={{color:"#00000073",textAlign:'left'}}>成员数</Col>
                            <Col flex="auto" style={{textAlign:'left'}}>
                                <div> 学生数: {studentCount},  教师数: {teacherCount} </div>
                            </Col>
                            <Col flex="100px" style={{color:"#00000073",textAlign:'right'}}>
                                <Button onClick={()=>{window.open('/oam_institution_user_manager?college_id=' + collegeId)}} size="small" style={{borderColor:'#07c160',width:80,height:32,color:'#07c160'}} > 成员管理 </Button>
                            </Col>
                        </Row>

                        <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:20}} >
                            <Col flex="80px" style={{color:"#00000073",textAlign:'left'}}>业务员</Col>
                            <Col flex="100px" style={{textAlign:'left'}}>
                                <Input maxLength={30} placeholder="请输入业务员姓名" required={true} value={salesman} onChange={(e)=>{setSalesman(e.target.value)}} style={{borderRadius:3,width:"150px"}}/>
                            </Col>
                            
                        </Row>

                        <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:20}} >
                            <Col flex="80px" style={{color:"#00000073",textAlign:'left'}}>教师码</Col>
                            <Col flex="250px" style={{textAlign:'left'}}>
                                <div> {teacherCode} {"  "} <span style={{color:"#00000073"}}>{teacherCodeTime}</span></div>
                                
                            </Col>
                            <Col flex="auto" style={{textAlign:'left'}}>
                                    <CopyToClipboard text={teacherCode} 
                                        onCopy={() => {message.success("教师码已复制~")}}>
                                        <Button size="small" style={{marginLeft:10,width:60,height:32}}>复制</Button>
                                    </CopyToClipboard>
                            </Col>
                            <Col flex="100px" style={{color:"#00000073",textAlign:'right'}}>
                                <Popconfirm placement="top" title={'教师码更新后，原有教师码将失效，确认吗？'} onConfirm={()=>{generateCode(2)}} okText="确认" cancelText="取消">
                                    <Button size="small" style={{borderColor:'#07c160',width:80,height:32,color:'#07c160'}} > {teacherCode == "" ? '生 成' : '更 改'} </Button>
                                </Popconfirm>
                            </Col>
                        </Row>

                        <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:20}} >
                            <Col flex="80px" style={{color:"#00000073",textAlign:'left'}}>学生码</Col>
                            <Col flex="250px" style={{textAlign:'left'}}>
                                <div> {studentCode} {"  "} <span style={{color:"#00000073"}}>{studentCodeTime}</span></div>
                            </Col>
                            <Col flex="auto" style={{textAlign:'left'}}>
                                    <CopyToClipboard text={studentCode} 
                                        onCopy={() => {message.success("学生码已复制~")}}>
                                        <Button size="small" style={{marginLeft:10,width:60,height:32}}>复制</Button>
                                    </CopyToClipboard>
                            </Col>
                            <Col flex="100px" style={{color:"#00000073",textAlign:'right'}}>
                                <Popconfirm placement="top" title={'学生码更新后，原有学生码将失效，确认吗？'} onConfirm={()=>{generateCode(1)}} okText="确认" cancelText="取消">
                                    <Button size="small" style={{borderColor:'#07c160',width:80,height:32,color:'#07c160'}} > {studentCode == "" ? '生 成' : '更 改'} </Button>
                                </Popconfirm>
                            </Col>
                           
                        </Row>



                        <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:20}} >
                            <Col flex="80px" style={{color:"#00000073",textAlign:'left'}}>备注</Col>
                            <Col flex="auto" style={{textAlign:'left'}}>
                                <TextArea  showCount maxLength={500} placeholder="请输入机构备注" 
                                style={{borderRadius:3,width:"100%"}} 
                                autoSize={{minRows: 3, maxRows: 5}} 
                                value={remarks} 
                                onChange={(e)=>{setRemarks(e.target.value);}}
                                />

                            </Col>
                        </Row>

                        <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:10}} >
                            <Col span={24} style={{color:"#333",textAlign:'left',fontWeight:500}}>配置课程({currentCollegeCourseList?.length})</Col>
                          
                        </Row>
                        { systemOnlineCourseList && currentCollegeCourseList &&
                        <div style={{marginLeft:20,marginBottom:10,textAlign:"left"}} >
                          <Checkbox.Group onChange={handleSelectCoursesChange} defaultValue={currentCollegeCourseList.map(item=>item.id)} >
                              { systemOnlineCourseList?.map(
                                  (course)=> (<div style={{padding:5,textAlign:"left"}}  key = {course.id}>
                                    <Checkbox  value={course.id} >
                                        <Row wrap={false}>
                                            <Col flex="300px">
                                                {course.name} 
                                            </Col>
                                            
                                            <Col flex="300px">
                                            {currentCollegeCourseList?.filter(item=>(item.id == course.id)).length > 0 ?
                                            <RangePicker onChange={onCourseDateRangeChange.bind(this,course.id)} 
                                                style={{marginLeft:10}} 
                                                format={dateFormat} 
                                                disabledDate={disabledDate} 
                                                defaultValue={[moment(currentCollegeCourseList?.filter(item=>(item.id == course.id))[0].start_time,dateFormat),moment(currentCollegeCourseList?.filter(item=>(item.id == course.id))[0].end_time,dateFormat)]}
                                                /> :
                                                <RangePicker onChange={onCourseDateRangeChange.bind(this,course.id)} 
                                                style={{marginLeft:10}} 
                                                format={dateFormat} 
                                                disabledDate={disabledDate} 
                                                defaultValue={[moment(startTime,dateFormat),moment(endTime,dateFormat)]}
                                                />
                                            }
                                            </Col>
             
                                        </Row>
                                    </Checkbox>
                                    </div>
                                  )
                              )}
                          </Checkbox.Group>
                        </div>
                        }


                        <div style={{height:50,marginBottom:20,textAlign:"left"}} >
                        </div>


                </div>
                </ConfigProvider>
                </Content>
                            
                <ConfigProvider locale={zhCN}>
                    <Modal
                            title={<div
                            style={{
                            width: '100%',
                            cursor: 'move',
                            }}
                            onMouseOver={() => {
                            if (disabled) {
                                setDisabled(false)
                            }
                            }}
                            onMouseOut={() => {
                            setDisabled(true)
                            }}
                            onFocus={() => {}}
                            onBlur={() => {}}
                            // end
                        >
                            选择用户
                        </div>
                        }

                        closable={false}
                        visible={isSelectUserModalVisible}
                        onOk={handleSelectUserOk}
                        onCancel={handleSelectUserCancel}
                        bodyStyle={{ height:550,overflow:"auto"}}
                        destroyOnClose={true}
                        width={800}
                        modalRender={modal => (<div className={menuStyles.createDiv}>
                            <Draggable
                            disabled={disabled}
                            bounds={bounds}
                            onStart={(event, uiData) => onStart(event, uiData)}
                            >
                            <div ref={draggleRef}>{modal}</div>
                            </Draggable> </div>
                        )}
                        
                          
                    >

                    <div >
                        <Input
                        onPressEnter={onSearchUserBtn} 
                        placeholder="名称/ID" 
                        style={{borderRadius:20,width:250,marginBottom:10}} 
                        suffix={<SearchOutlined  style={{color:"#666666"}}/>}
                        />

                    </div>


           
                    <div className={idataCodingTableStyles.tableWrapper}>
                        <ConfigProvider locale={zhCN}>
                            <Table
                                dataSource={userResult}
                                columns={userCols}
                                rowKey={record => record.user_id}
                                pagination={false}
                                size="small"
                                //scroll={{ x: 600 }}
                                //bordered={true}
                                onChange={onUserTableChange}
                                rowSelection={{
                                    type: "radio",
                                    ...rowUserSelection,
                                    }}
                            />
                        </ConfigProvider>
                    </div>
                    <ConfigProvider locale={zhCN}>
                        <div style={{marginBottom:10,paddingTop:10,textAlign:"center"}} className={"css.pagination"} >
                            <Pagination
                                defaultCurrent={1}
                                total={resultSum}
                                defaultPageSize={10}
                                hideOnSinglePage={true}
                                current={pageUser.current}
                                onChange={onUserPageChange}
                                //showQuickJumper={true}
                                showTotal={total => `找到 ${total} 用户`}
                                showSizeChanger={false}
                            />
                        </div>
                    </ConfigProvider>                         
                    
            </Modal>
        </ConfigProvider>

            </Layout>
        </Layout>

    )

}


export default OAMInstitutionEdit
