import { ConfigProvider, Form, Input, Modal, message } from 'antd'
import React, { useRef, useState } from 'react'
import zhCN from 'antd/es/locale/zh_CN'
import Draggable from 'react-draggable'
import type { DraggableData, DraggableEvent } from 'react-draggable'
import GlobalCss from '@/global.module.less'
import css from '../index.module.less'
import { arch } from 'os'
import { sendHttpPostRequest } from 'api/http-request'

interface UpdatePasswordModalProps {
  open: boolean
  setOpen: any
}
export const UpdatePasswordModal = (props: UpdatePasswordModalProps) => {
  const { open, setOpen } = props
  const [disabled, setDisabled] = useState(false)
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
  const draggleRef = useRef<HTMLDivElement>(null)
  const updatePassFormRef = useRef<any>()

  const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
    const { clientWidth, clientHeight } = window.document.documentElement
    const targetRect = draggleRef.current?.getBoundingClientRect()
    if (!targetRect) {
      return
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    })
  }

  const handleOk = () => {
    updatePassFormRef.current.submit()
  }

  const handleCancel = () => {
    setOpen(false)
  }

  const onFinish = (values: any) => {
    let data = {
      current_password: values.currentPassword,
      new_password: values.newPassword,
      new_password_confirm: values.confirmPassword,
    }
    sendHttpPostRequest('account/api/change_password', data, (res) => {
      if (res.code == 0) {
        message.success(res.message)
        setOpen(false)
      } else {
        message.error(res.message)
      }
    })
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }
  return (
    <ConfigProvider locale={zhCN}>
      <Modal
        width={420}
        className={GlobalCss.ModalTip}
        bodyStyle={{ padding: '30px 58px 0px' }}
        title={
          <div
            style={{
              width: '100%',
              cursor: 'move',
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false)
              }
            }}
            onMouseOut={() => {
              setDisabled(true)
            }}
            onFocus={() => {}}
            onBlur={() => {}}
          >
            {'修改密码'}
          </div>
        }
        visible={open}
        onOk={handleOk}
        onCancel={handleCancel}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <Form
          name="updatePassword"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          labelAlign="left"
          validateTrigger={'onBlur'}
          colon={false}
          ref={updatePassFormRef}
        >
          <Form.Item
            label="当前密码"
            name="currentPassword"
            rules={[{ required: true, message: '当前密码不能为空!' }]}
          >
            <Input.Password placeholder="请输入当前密码" />
          </Form.Item>

          <Form.Item
            label="新密码"
            name="newPassword"
            rules={[
              { required: true, message: '新密码不能为空!' },
              {
                max: 20,
                min: 6,
                message: '密码最少6个字符，最多20个字符！',
              },
            ]}
          >
            <Input.Password maxLength={20} placeholder="请输入新密码,6-20位" />
          </Form.Item>
          <Form.Item
            label="确认密码"
            name="confirmPassword"
            rules={[
              { required: true, message: '请再次输入新密码!' },

              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error('两次新密码输入不一致!'))
                },
              }),
            ]}
          >
            <Input.Password placeholder="请确认新密码" />
          </Form.Item>
        </Form>
      </Modal>
    </ConfigProvider>
  )
}

export default UpdatePasswordModal
