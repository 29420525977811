import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { sendHttpPostRequest } from '../../../api/http-request'
import Cookies from 'js-cookie'
import { Button, Col, Layout, Menu, message, Row, Tabs } from 'antd'
import css from './case-run.module.less'
import * as constants from '@/constants/constants'
import '@/static/font/iconfont.css'
import GlobalCss from '@/global.module.less'
import type { MenuProps } from 'antd'
import CommonModal from 'src/component/modal/CommonModal/CommonModal'
import {
  AppstoreOutlined,
  ContainerOutlined,
  DesktopOutlined,
  MailOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PieChartOutlined,
} from '@ant-design/icons'

type MenuItem = Required<MenuProps>['items'][number]
interface Props {}

const Header = (props: any) => {
  const { caseInfo } = props

  return (
    <Row className={css.header} justify={'start'} align={'middle'} wrap={false}>
      <Col className={css.headerTitle}>{caseInfo?.name}</Col>
      <Col className={css.domainTag}>{caseInfo?.domain}</Col>
      <Col
        onClick={() => window.close()}
        style={{ position: 'absolute', right: '20px', cursor: 'pointer' }}
      >
        {'返回 >'}
      </Col>
    </Row>
  )
}
function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group'
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem
}

const items: MenuItem[] = [
  getItem('实验', '1', <span className="iconfont icon_a-zu3672"></span>),
  // getItem('数据集', '2', <span className="iconfont icon_a-zu3673"></span>),
]
export const CaseRun: React.FC<Props> = (props: Props) => {
  const location = useLocation()
  const fromCourse = location?.pathname?.includes('course')
  const oamMode = location.pathname.includes('oam')
  const caseId = fromCourse
    ? location?.search?.split('case_id=')[1].split('?')[0]
    : location?.search?.split('case_id=')[1]
  const courseId = location?.search?.split('course_id=')[1]
  const [userLogin, setUserLogin] = useState(false) // 用户登录状态
  const [caseInfo, setCaseInfo] = useState<any>()

  const [collapsed, setCollapsed] = useState(true)

  useEffect(() => {
    queryCase()
    return () => {}
  }, [])

  /**
   * 查询实验信息
   */
  const queryCase = () => {
    let params = {
      sessionid: Cookies.get('sessionid'),
      case_id: caseId,
      // is_my: '2',
    }
    // if (caseId) {
    //   params['course_id'] = caseId
    // }
    if (oamMode) {
      sendHttpPostRequest(
        'case/api/oam_query_case_by_id',
        params,
        queryCaseCallback
      )
    } else if (fromCourse) {
      params['course_id'] = courseId
      sendHttpPostRequest(
        'course/api/user_query_course_case_by_id',
        params,
        queryCaseCallback
      )
    } else {
      sendHttpPostRequest(
        'case/api/query_case_by_id',
        params,
        queryCaseCallback
      )
    }
  }

  /**
   * 查询实验回调
   * @param response
   */
  const queryCaseCallback = (response: any) => {
    console.log(response)
    message.destroy()
    if (response.code == -100) {
      // 未登录
      setUserLogin(false)
      document.getElementById('show-login-modal')!.click()
    } else if (response.code == 0) {
      setUserLogin(true)
      setCaseInfo(response?.case_info)
    }
  }

  return (
    <Layout style={{ minHeight: '100vh', backgroundColor: '#f9f9f9' }}>
      <Header caseInfo={caseInfo} />
      <div className={css.sideBar}>
        <Menu
          defaultSelectedKeys={['1']}
          // defaultOpenKeys={['sub1']}
          mode="inline"
          theme="light"
          inlineCollapsed={collapsed}
          items={items}
        />
      </div>
      <div className={css.content}>
        <iframe
          id="notebook-iframe"
          sandbox="allow-scripts allow-forms allow-same-origin"
          src={caseInfo?.run_url}
          style={{
            minHeight: 'calc(100vh - 52px)',
            width: '-webkit-fill-available',
            borderRadius: 10,
            marginLeft: '50px',
            marginTop: '60px',
          }}
          frameBorder="0"
        />
      </div>
    </Layout>
  )
}

export default CaseRun
