import { Input } from 'antd'
import {SearchOutlined} from "@ant-design/icons"
import SearchInputStyle from "./searchInput.module.less"
import "../../static/font/iconfont.css"
import React from 'react'


export interface SearchProps {
  onSearch:any
  value?:any
  onChange:any
  placeholder?:string
  theme?:string
}

export const SearchInputCom:React.FC<SearchProps> = (props)=> {

  const {onSearch,onChange,value,placeholder,theme} = props
  return (
    <Input placeholder={placeholder} className={SearchInputStyle.input + " " + (theme == "dark" ? SearchInputStyle.inputdark : "")} value={value} onChange={onChange} onPressEnter={onSearch} suffix={<span className={"iconfont icon_sousuo"} style={{cursor:"pointer",color:theme=="dark" ? "#fff" :"#07c160"}} onClick={onSearch} ></span>}/>
  )
}

export default SearchInputCom