import React, { useState, useEffect, useRef } from 'react';
import { Layout, Menu, Avatar, Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import headerStyle from './header.module.less';
const { Header } = Layout;
import * as constants from '../../../constants/constants';
import { Divider } from 'antd';
import localStorage from 'localStorage';
import logoSvg from '../../../static/image/logo.svg';
import axios from 'axios';
import Cookies from 'js-cookie';
import Qs from 'qs';
import LoginRegisterModal from '../../../component/modal/login-register/login-register';
import UserInfo from '../user-center/user-info';
import TeacherAuthenticate from "../../../component/modal/teacher-authenticate/teacher-authenticate";
import { sendHttpPostRequest } from '../../../api/http-request';
export interface Props {
    keys: any
}

export const PageHeader: React.FC<Props> = (props: Props) => {
    const { keys } = props
    const [selectedKey, setSelectedKey] = useState('0')
    const [collapsed,setCollapsed]= useState(false)
    const [collegeId, setCollegeId] = useState(null as any);  // 院校ID
    const [collegeName, setCollegeName] = useState(null as any);  // 院校名称
    const [collegeInfo, setCollegeInfo] = useState(null as any);//查询当前用户的机构信息,is_college_teacher weitrue表示是教师，


    const handleClientW = (width:number,num:number)=> {
        if (width < num) {
            setCollapsed(true)
        } else {
            setCollapsed(false)
        }
    }

    const onMenuClick = (event: any) => {
        // console.log(event.key)
        // const key = event.key
        // console.log(document.getElementById(`menu-${key}`))
        //
        // if (event.key == "1") {
        //
        // }
    }

    const onLogoClick = (event: any) => {
        // document.getElementsByClassName('ant-menu-item-selected')[0]?.classList.remove("ant-menu-item-selected")
    }

    useEffect(()=> {
        const clientW = document.documentElement.clientWidth;
        // console.log(clientW)
        handleClientW(clientW,600);
        keys != -1 && document.getElementById('menu-' + keys) != null ? document.getElementById('menu-' + keys)!.click() : "";

        const cachedCollegeInfo = JSON.parse(localStorage.getItem("college_info"));
        if (cachedCollegeInfo) {
            setCollegeId(cachedCollegeInfo.college_id);
            setCollegeInfo(cachedCollegeInfo);
            setCollegeName(cachedCollegeInfo.college_name);
        } else {
            sendHttpPostRequest(//获取院校身份信息
                'account/api/query_teacher_authenticate',{
                    sessionid: Cookies.get("sessionid"),
                    sid: Cookies.get("sessionid"),
                },
                (response:any)=>{
                    if(response.is_ok == true)
                    {
                        let _collegeId = response.college_info.college_id;
                        if(_collegeId && _collegeId !== 0){
                            setCollegeId(_collegeId);
                            setCollegeInfo(response.college_info);
                            setCollegeName(response.college_info.college_name);
                            localStorage.setItem("college_info", JSON.stringify(response.college_info));
                        }
                    }
                }
            );
        }
    },[])

    return (
        <Header className={headerStyle.Header} >
            <Row justify="center" align="middle" wrap={true}>
            {(collegeInfo && collegeInfo?.logo_url !== '') ?
                <Col>
                    <Link to={"/"}>
                        <img
                            key={"0"}
                            id={"menu-0"}
                            title="爱数课"
                            alt="logo"
                            src={ collegeInfo?.logo_url ? constants.BACKEND_API_URL + collegeInfo?.logo_url : logoSvg}
                            height="40px"
                            className={headerStyle.logo}
                        ></img>
                    </Link>
                </Col> :
                <Col>
                <Link to={"/"}>
                    <img
                        key={"0"}
                        id={"menu-0"}
                        title="爱数课"
                        alt="logo"
                        src={logoSvg}
                        height="40px"
                        className={headerStyle.logo}
                    ></img>
                </Link>
            </Col> 
                
                }
                
                <Col flex="auto">
                    <Menu
                        theme="light"
                        mode="horizontal"
                        defaultSelectedKeys={keys}
                        style={{fontSize:15,borderBottom:'0px',height:64,marginTop:0}}
                        onClick={onMenuClick}
                    >
                        <Menu.Item key="1" id={"menu-1"}><Link to={'/course'}>课程</Link></Menu.Item>
                        <Menu.Item key="2" id={"menu-2"}><Link to={'/case'}>实验</Link></Menu.Item>
                        {collegeInfo?.is_college_teacher == 1 && <Menu.Item key="3" id={"menu-3"}><Link to={'/module'}>知识点</Link></Menu.Item>}
                        <Menu.Item key="4" id={"menu-4"}><Link to={'/exercise'}>题库</Link></Menu.Item>
                        <Menu.Item key="5" id={"menu-5"}><Link to={'/lecture'}>数智讲堂</Link></Menu.Item>
                        <Menu.Item key="6" id={"menu-6"}><Link to={'/community'}>社区</Link></Menu.Item>
                    </Menu>
                </Col>

                <Col >
                    <LoginRegisterModal></LoginRegisterModal>
                    <UserInfo>
                    </UserInfo>
                </Col>

            </Row>
        </Header>
    )
}

export default PageHeader



