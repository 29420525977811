import React, { Component } from 'react';
import { List, Input, Button, message } from 'antd';
import { Link } from 'react-router-dom';
import { UserOutlined, LockOutlined, MobileOutlined } from '@ant-design/icons';
import loginRegisterStyles from './college-login-register.module.less';
import localStorage from 'localStorage';
import logoSvg from '../../static/image/college/logo.png';
import bg from '../../static/image/college/bg.png';
import {sendHttpPostRequest} from "../../api/http-request";

class CollegeLoginRegister extends Component {
    state = {
        idens: '', //帐号
        password: '', // 密码
        passwordConfirm: '', // 密码确认
        vcode: '', // 验证码
        registerMode: false, // 注册模式
        errorMessage: '', // 错误码
        sendVcodeTip: '发送验证码', // 验证码发送提示
        sendBtnDisabled: false, // 发送按钮不可用
        inviter: '', // 邀请者
    }

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="college-login-register-wrapper college-login" style={{background:`URL(${bg}) no-repeat`,backgroundSize:'100% 100%'}}>
                
                <div className={loginRegisterStyles.pageLogin} hidden={this.state.registerMode}>
                    <div className={loginRegisterStyles.login_register_logo}>
                        <Link to={"/college-project"} title={"返回黄河交通学院首页"}>
                            <img src={logoSvg}
                                alt=""
                                width={250}
                                style={{display: "inline-block"}}
                            />
                        </Link>
                    </div>
                    <List className="college-login-form">
                        <div id="error-message" className="error-message">{this.state.errorMessage}</div>
                        <Input id="phone" name="phone" className="login-form phone" value={this.state.idens}
                               placeholder={"请输入手机号"} required={true} prefix={<UserOutlined />}
                               onChange={this.handleChange.bind(this, "idens")} />
                        <Input.Password id="password" name="password" type="password" prefix={<LockOutlined />}
                               className="login-form password" placeholder="请输入密码，6-20位"
                               maxLength={20} onChange={this.handleChange.bind(this,"password")} />
                        <div onClick={this.handleLogin.bind(this)} className="button btn-register">登 &nbsp;&nbsp;录</div>
                        <div className="cue1" onClick={this.handleGoRegister.bind(this)}>还没有帐号，去注册 &gt;</div>
                    </List>
                </div>
                <div className={loginRegisterStyles.pageRegister} hidden={!this.state.registerMode}>
                    <div className={loginRegisterStyles.login_register_logo}>
                        <Link to={"/college-project"} title={"返回黄河交通学院首页"}>
                            <img src={logoSvg}
                                alt=""
                                width={250}
                                style={{display: "inline-block"}}
                            />
                        </Link>
                    </div>
                    <List className="college-register-form">
                        <div className="error-message">{this.state.errorMessage}</div>
                        <Input className="register-form phone" placeholder={"请输入手机号"} required={true}
                               prefix={<UserOutlined />}
                               onChange={this.handleChange.bind(this,"idens")} value={this.state.idens} />
                        <Input onClick={this.checkPhone.bind(this)} type="button"
                               className="btn btn-primary send-phone-code" disabled={this.state.sendBtnDisabled}
                               value={this.state.sendVcodeTip} />
                        <Input className="register-form phone-vcode" placeholder={"请输入短信验证码"} maxLength={6}
                               onChange={this.handleChange.bind(this,"vcode")} prefix={<MobileOutlined />} />
                        <Input className="register-form password" placeholder={"请输入密码，6-20位"} maxLength={20}
                               prefix={<LockOutlined />}
                               onChange={this.handleChange.bind(this,"password")} type={"password"} />
                        <Input className="register-form password-confirm" placeholder={"请再输入一遍密码"}
                               prefix={<LockOutlined />}
                               onChange={this.handleChange.bind(this,"passwordConfirm")} type={"password"} />
                        <Input className="register-form inviter" placeholder={"请输入邀请人手机号(可选)"}
                               onChange={this.handleChange.bind(this,"inviter")} prefix={<UserOutlined />} />
                        <div onClick={this.handleRegister.bind(this)} className="button btn-register">注 &nbsp;&nbsp;册</div>
                        <div className="cue1" onClick={this.handleGoLogin.bind(this)}>已有帐号，返回登录 &gt;</div>
                    </List>
                </div>
            </div>
        )
    }

    // 去注册
    handleGoRegister() {
        this.setState({registerMode: true, errorMessage: ""});
    }

    // 返回登录
    handleGoLogin() {
        this.setState({registerMode: false, errorMessage: ""});
    }

    // 绑定表单值
    handleChange(key, event) {
        this.setState({
            [key]: event.target.value
        })
    }

    // 登录
    handleLogin() {
        doLogin(this);
    }

    // 注册
    handleRegister() {
        doRegister(this);
    }

    /**
     * 发送手机验证码回调
     * @param response
     */
    sendPhoneVcodeCallback(response) {
        if (response.code == 0) {
            message.destroy();
            message.success(response.message);
            let times = 60;
            this.setState({sendVcodeTip: times + "秒后重发", sendBtnDisabled: true});
            let _this: any = this;
            let time_handle = setInterval(function() {
                times -= 1;
                if (times <= 0) {
                    _this.setState({sendVcodeTip: "发送验证码", sendBtnDisabled: false});
                    clearInterval(time_handle)
                } else
                    _this.setState({sendVcodeTip: times + "秒后重发"});
            }, 1000)
        } else {
            this.setState({errorMessage: response.message});
        }
    }

    /**
     * 登录回调
     * @param response
     */
    loginCallback(response) {
        const location = window.location.href.split("next=")[1];
        if (response.is_ok) {
            setCookie("sessionid", response.token);
            localStorage.setItem("user_info", JSON.stringify(response.user_info.user_info));
            // location ? window.location.href = location : window.location.href = "/";
            window.location.href = '/college-project';
        } else {
            this.setState({errorMessage: response.message});
        }
    }

    /**
     * 检查手机号
     */
    checkPhone() {
        this.setState({errorMessage: ""});
        const phone = this.state.idens;
        const is_mobile = /^(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[0-9]{1})|(18[0-9]{1})|(19[0-9]{1}))+\d{8})$/;

        if (phone == "") {
            this.setState({errorMessage: "请输入手机号"});
        } else if (!phone.match(is_mobile)) {
            this.setState({errorMessage: "请输入正确的手机号"});
        } else {
            try {
                sendHttpPostRequest(
                    'account/api/send_sms/',
                    {phone: phone, type: 1},
                    this.sendPhoneVcodeCallback.bind(this)
                );
            } catch (e) {
                console.error(e);
            }
        }
    }

}

export default CollegeLoginRegister


/**
 * 调后端登录接口
 */
export function doLogin(_this: any) {
    const phone = _this.state.idens;
    const password = _this.state.password;
    const is_mobile = /^(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[0-9]{1})|(18[0-9]{1})|(19[0-9]{1}))+\d{8})$/;
    if (!phone) {
        _this.setState({errorMessage: "请输入手机号"});
        return false;
    } else if (!phone.match(is_mobile)) {
        _this.setState({errorMessage: "请输入正确的手机号"});
        return false;
    } else if (!password) {
        _this.setState({errorMessage: "请输入密码"});
        return false;
    } else if (password.length < 6 || password.length > 20) {
        _this.setState({errorMessage: "密码长度应为6-20位"});
        return false;
    }

    _this.setState({errorMessage: ""});
    try {
        sendHttpPostRequest(
            'account/api/login/',
            {idens: phone, password: password},
            _this.loginCallback.bind(_this)
        );
    } catch (e) {
        console.error(e);
    }
}

/**
 * 设置cookie
 * @param key
 * @param value
 */
export function setCookie(key, value) {
    const date: any = new Date();
    date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toGMTString();
    document.cookie = key + "=" + value + "; " + expires;
}

/**
 * 删除cookie
 * @param key
 */
export function delCookie(key) {
    const date: any= new Date();
    date.setTime(date.getTime() - 10000);
    const expires = "=; expires=; " + date.toGMTString();
    document.cookie = key + expires;
}

/**
 * 获取cookie
 * @param key
 */
export function getCookie(key) {
    let arr, reg = new RegExp("(^| )" + key + "=([^;]*)(;|$)");
    if (arr=document.cookie.match(reg))
        return unescape(arr[2]);
    else
        return null;
}


/**
 * 注册
 * @param _this
 */
export function doRegister(_this: any) {
    const user_name = _this.state.idens;
    const phone = _this.state.idens;
    const password = _this.state.password;
    const phone_vcode = _this.state.vcode;
    const password_confirm = _this.state.passwordConfirm;
    const inviter = _this.state.inviter;
    const is_mobile = /^(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[0-9]{1})|(18[0-9]{1})|(19[0-9]{1}))+\d{8})$/;
    if (!phone) {
        _this.setState({errorMessage: "请输入手机号"});
        // document.getElementById('error-message').innerHTML = "请输入手机号";
        return false;
    } else if (!phone.match(is_mobile)) {
        _this.setState({errorMessage: "请输入正确的手机号"});
        // document.getElementById('error-message').innerHTML = "请输入正确的手机号";
        return false;
    } else if (!phone_vcode) {
        _this.setState({errorMessage: "请输入手机验证码"});
        // document.getElementById('error-message').innerHTML = "请输入手机验证码";
        return false;
    } else if (!user_name) {
        _this.setState({errorMessage: "请输入姓名"});
        // document.getElementById('error-message').innerHTML = "请输入姓名";
        return false;
        // $(".error-message").html(error_message);
    } else if (!password) {
        _this.setState({errorMessage: "请输入密码"});
        // document.getElementById('error-message').innerHTML = "请输入密码";
        return false;
    } else if (password.length < 6 || password.length > 20) {
        _this.setState({errorMessage: "密码长度应为6-20位"});
        // document.getElementById('error-message').innerHTML = "密码长度应为6-20位";
        return false;
    } else if (!password_confirm) {
        _this.setState({errorMessage: "请输入密码确认"});
        // document.getElementById('error-message').innerHTML = "请输入密码确认";
        return false;
    } else if (password != password_confirm) {
        _this.setState({errorMessage: "两次输入的密码不一致"});
        // document.getElementById('error-message').innerHTML = "两次输入的密码不一致";
        return false;
    }
    if (inviter) {
        if (!inviter.match(is_mobile)) {
            _this.setState({errorMessage: "请输入正确的邀请人手机号"});
            // document.getElementById('error-message').innerHTML = "请输入正确的邀请人手机号"
            return false;
        } else if (inviter.trim() == phone.trim()) {
            _this.setState({errorMessage: "亲，您不能邀请自己~"});
            // document.getElementById('error-message').innerHTML = "亲，您不能邀请自己"
            return false;
        }
    }
    _this.setState({errorMessage: " "});

    try {
        sendHttpPostRequest(
            'account/api/register/',
            {
                user_name: user_name,
                idens: phone,
                password: password,
                phone_vcode: phone_vcode,
                password_confirm: password_confirm,
                inviter: inviter,
                channel: 2
            },
            _this.loginCallback.bind(_this)
        );

    } catch (e) {
        console.error(e);
    }
}


