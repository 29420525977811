import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Switch,
  Statistic,
  Steps,
  Input,
  InputNumber,
  Table,
  Layout,
  message,
  Col,
  Row,
  Image,
  ConfigProvider,
  Pagination,
  Tabs,
  Button,
  Tag,
  DatePicker,
  Tooltip,
  Modal,
  Popconfirm,
} from 'antd'
import { PageHeader } from '../../common/page-header/header'
import { sendHttpPostRequest } from '../../../api/http-request'
import examPreviewStyles from './course-teacher-exam-preview.module.less'
import zhCN from 'antd/lib/locale/zh_CN'
import LoginRegisterModal from '../../../component/modal/login-register/login-register'
import { checkUserLogin } from '../../common/user-center/user-info'
import {
  LeftOutlined,
  RightOutlined,
  PlayCircleOutlined,
  FundProjectionScreenOutlined,
  UnorderedListOutlined,
  PieChartOutlined,
  FileWordOutlined,
  DownloadOutlined,
  TableOutlined,
  ClockCircleOutlined,
  CheckCircleOutlined,
  EyeOutlined,
  FileTextOutlined,
  SearchOutlined,
} from '@ant-design/icons'

import courseCreateExamStyles from './course-create-exam.module.less'
import selectedExeStyles from './course-teacher-selected-exercises.module.less'

import FullscreenOutlined from '@ant-design/icons/lib/icons/FullscreenOutlined'
import FullscreenExitOutlined from '@ant-design/icons/lib/icons/FullscreenExitOutlined'

import CourseTeacherExamComposition from './course-teacher-exam-composition'
import ScoreSVG from '../../../static/image/exam/score.svg'
import PaperSVG from '../../../static/image/exam/paper.svg'
import AllPaperSVG from '../../../static/image/exam/allpaper.svg'
import AnswerSVG from '../../../static/image/exam/answer.svg'

import moment from 'moment'

const { RangePicker } = DatePicker

import Cookies from 'js-cookie'
import ExerciseCard from '../../exercise/exercise-card'

import { BACKEND_API_URL, BACKEND_URL } from '../../../constants/constants'

const { TextArea } = Input

import 'echarts-wordcloud'

import * as echarts from 'echarts/core'
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from 'echarts/components'
import { PieChart } from 'echarts/charts'
import { LabelLayout } from 'echarts/features'
import { CanvasRenderer } from 'echarts/renderers'

import selectExeStyles from './course-teacher-select-exercises.module.less'
import courseExamListStyles from './course-main-teacher-exam-list.module.less'
import UpOutlined from '@ant-design/icons/lib/icons/UpOutlined'
import DownOutlined from '@ant-design/icons/lib/icons/DownOutlined'
import Draggable from 'react-draggable'

echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer,
  LabelLayout,
])

const { Countdown } = Statistic //倒计时控件
const { Step } = Steps

const { TabPane } = Tabs

const { CheckableTag } = Tag

export interface Props {
  exam: any
  exerciseList: any
}

const typeMap = new Map([
  [1, '实操题'],
  [2, '单选题'],
  [3, '多选题'],
  [4, '排序题'],
  [5, '主观题'],
])
const difficultyMap = new Map([
  [1, '容易'],
  [2, '中等'],
  [3, '困难'],
])

const dateFormat = 'YYYY-MM-DD HH:mm:ss'

export interface Props {}
/** 教师查看考试详情页面 */
export const CourseTeacherExamPreview: React.FC<Props> = (props) => {
  //const examId = parseInt(window.location.search.split("=")[1]);  // 考试的ID
  const examId = parseInt(window.location.search.split('&')[0].split('=')[1]) //考试ID
  const courseId = parseInt(window.location.search.split('&')[1].split('=')[1]) //课程
  const currentCourseMenuId = parseInt(
    window.location.search.split('&')[2].split('=')[1]
  ) //课程大纲ID
  const [exam, setExam] = useState(null as any) //考试
  const [examName, setExamName] = useState('') //考试名称
  const examNameCurrent = useRef('')
  const [examDescription, setExamDescription] = useState('') //考试说明
  const examDescriptionCurrent = useRef('')
  /** 考试试题列表 */
  const [examExercises, setExamExercises] = useState([] as any[])

  const [startTime, setStartTime] = useState(
    null
    // moment().utc().zone(-8).format(dateFormat).toString() as any
  ) //考试开始时间
  const [endTime, setEndTime] = useState(
    null
    // moment().utc().zone(-8).add(2, 'hours').format(dateFormat).toString() as any
  ) //考试结束时间

  const startTimeCurrent = useRef<any>(
    null
    // ""
    // null
    // moment().utc().zone(-8).format(dateFormat).toString() as any
  )
  const endTimeCurrent = useRef<any>(
    null
    // ""
    // null
    // moment().utc().zone(-8).add(2, 'hours').format(dateFormat).toString() as any
  )

  const page = useRef(1) // 记录当前的页数
  const pageSize = useRef(10) // 记录当前每页展示数量
  const [resultSum, setResultSum] = useState(0) // 搜索返回的结果数

  const disabledDate = (current: any) => {
    return current && current < moment().startOf('day')
  }

  //const [examPublishStatus, setExamPublishStatus] = useState(null as any);//1未发布，2已发布
  //const [examPublishScore, setExamPublishScore] = useState(1);//1未公布，已公布

  const [examStatus, setExamStatus] = useState(null as any) //["未发布","未开考","考试中","阅卷中","已完成"]
  const [isLoad, setIsLoad] = useState(false)
  const examStatusList = ['未发布', '未开考', '考试中', '阅卷中', '已完成']

  // const [showExportAnswerBtn, setShowExportAnswerBtn] = useState("1"); // 是否显示导出成绩按钮 "1"-显示（默认） "2"-不显示

  const [isPublishScoreModalVisible, setPublishScoreModalVisible] =
    useState(false)
  const [isPublishAnswerModalVisible, setPublishAnswerModalVisible] =
    useState(false)

  const [answerPublished, setAnswerPublished] = useState(null as any) //答案是否已公布
  const [scorePublished, setScorePublished] = useState(null as any) //成绩是否已经公布

  useEffect(() => {
    queryExam()
  }, [])

  const onExamExerciseChange = (exercisesList) => {
    setExamExercises(exercisesList)
  }

  /** 计算考试总分 */
  const getTotalScore = () => {
    let sumScore = 0
    examExercises.map((item) => {
      if (parseInt(item.score) == 0) sumScore += 5
      else sumScore += parseInt(item.score)
    })

    return sumScore
  }

  const onDateRangeChange = (dates, dateStrings) => {
    setStartTime(dateStrings[0])
    setEndTime(dateStrings[1])
    startTimeCurrent.current = dateStrings[0]
    endTimeCurrent.current = dateStrings[1]
  }

  // 查询考试回调
  const queryExamCallback = (response: any) => {
    message.destroy()
    if (response.code == -100) {
      // 未登录
      window.location.href =
        '/login?next=course-main-teacher?course_id=' + courseId + '&tabKey=5'
    } else if (response.is_ok == true) {
      // 成功

      setExamName(response.query_info.name)
      examNameCurrent.current = response.query_info.name
      setExamDescription(response.query_info.description)
      examDescriptionCurrent.current = response.query_info.description
      setExamExercises(response.query_info.exercise)
      setAnswerPublished(response.query_info.publish_answer == 2 ? true : false)
      setScorePublished(response.query_info.publish_score == 2 ? true : false)

      //setExamPublishStatus(response.query_info.status);
      //setExamPublishScore(response.query_info.publish_score);

      const currentTime = moment().utc().zone(-8)
      const start = moment(response.query_info.start_time).utc().zone(-8)
      const end = moment(response.query_info.end_time).utc().zone(-8)

      if (response.query_info.status == 1) {
        //未发布
        setExamStatus('未发布')
      } else if (response.query_info.status == 2 && currentTime < start) {
        setExamStatus('未开考')
      } else if (
        response.query_info.status == 2 &&
        currentTime > start &&
        currentTime < end
      ) {
        setExamStatus('考试中')
      } else if (
        response.query_info.status == 2 &&
        currentTime > end &&
        response.query_info.publish_score == 1
      ) {
        setExamStatus('阅卷中')
        //queryStudentSubmission();  // 查询学生提交情况
      } else if (
        response.query_info.status == 2 &&
        currentTime > end &&
        response.query_info.publish_score == 2
      ) {
        setExamStatus('已完成')
        //queryStudentSubmission();  // 查询学生提交情况
      }

      setExam(response.query_info)
      response.query_info.start_time.length > 0
        ? setStartTime(response.query_info.start_time)
        : ''
      response.query_info.end_time.length > 0
        ? setEndTime(response.query_info.end_time)
        : ''
      response.query_info.start_time.length > 0
        ? (startTimeCurrent.current = response.query_info.start_time)
        : ''
      response.query_info.end_time.length > 0
        ? (endTimeCurrent.current = response.query_info.end_time)
        : ''
    } else if (response.code == -501) {
      //权限不足
      window.location.href =
        '/login?next=course-main-teacher?course_id=' + courseId + '&tabKey=5'
    } else {
      //message.error(response.message);
    }
  }

  // 查询考试信息列表
  const queryExam = () => {
    sendHttpPostRequest(
      'exam/api/user_query_exam_by_id/',
      {
        sessionid: Cookies.get('sessionid'),
        exam_id: examId,
        course_id: courseId,
      },
      queryExamCallback
    )
  }

  /**
   * 保存考试回调
   * @param response
   */
  const saveExamParamsInfoCallback = (response: any) => {
    message.destroy()
    if (response.code == -100) {
      // 没登录
      window.location.href =
        '/login?next=course-main-teacher?course_id=' + courseId + '&tabKey=5'
    } else if (response.code == -501) {
      // 没权限
      window.location.href =
        '/login?next=course-main-teacher?course_id=' + courseId + '&tabKey=5'
    } else if (response.is_ok == true) {
      message.success('考试信息保存成功~')
      queryExam()
    } else {
      message.error(response.message)
    }
  }

  const saveExamParams = (params: any) => {
    sendHttpPostRequest(
      'exam/api/user_update_exam/',
      params,
      saveExamParamsInfoCallback
    )
  }

  /** 确认公布考试成绩 */
  const handlePublishScoreOk = () => {
    setPublishScoreModalVisible(false)

    const params = {
      sessionid: Cookies.get('sessionid'),
      exam_id: examId,
      publish_score: 2, //公布成绩
    }
    saveExamParams(params)
    queryExam()
  }

  /** 取消公布考试成绩 */
  const handlePublishScoreCancel = () => {
    setPublishScoreModalVisible(false)
  }

  /** 确认公布考试答案 */
  const handlePublishAnswerOk = () => {
    setPublishAnswerModalVisible(false)
    const params = {
      sessionid: Cookies.get('sessionid'),
      exam_id: examId,
      publish_answer: 2, //公布答案
    }
    saveExamParams(params)
    queryExam()
  }

  /** 取消公布考试答案 */
  const handlePublishAnswerCancel = () => {
    setPublishAnswerModalVisible(false)
  }

  /** 成绩导出 */
  const scoreExport = () => {
    const sessionid = Cookies.get('sessionid')
    const queryData = {
      course_id: courseId,
      sessionid: sessionid,
      exam_id: examId,
      sid: sessionid,
    }
    sendHttpPostRequest(
      'exam/api/export_exam_score',
      queryData,
      (response: any) => {
        if (response.code == -100) {
          // 未登录
          document.getElementById('show-login-modal')!.click()
        } else if (response.is_ok) {
          window.open(BACKEND_URL + response.url)
        }
      }
    )
  }
  /**
   * 导出答卷
   */

  const examAnswerExport = () => {
    const sessionid = Cookies.get('sessionid')
    const queryData = {
      course_id: courseId,
      sessionid: sessionid,
      exam_id: examId,
      sid: sessionid,
    }
    sendHttpPostRequest(
      'exam/api/download_student_paper',
      queryData,
      (response: any) => {
        if (response.code == -100) {
          // 未登录
          document.getElementById('show-login-modal')!.click()
        } else if (response.is_ok) {
          window.open(BACKEND_URL + response.url)
        }
      }
    )
  }
  /**
   * 导出试卷
   */
  const examExport = () => {
    const sessionid = Cookies.get('sessionid')
    const queryData = {
      course_id: courseId,
      sessionid: sessionid,
      exam_id: examId,
      sid: sessionid,
    }
    sendHttpPostRequest(
      'exam/api/download_paper_file',
      queryData,
      (response: any) => {
        if (response.code == -100) {
          // 未登录
          document.getElementById('show-login-modal')!.click()
        } else if (response.is_ok) {
          window.open(BACKEND_URL + response.url)
        }
      }
    )
  }

  /** 点击保存考试按钮 */
  const onSaveBtnClick = (e: any) => {
    const params = {
      sessionid: Cookies.get('sessionid'),
      exam_id: examId,
      // name: examName,
      name: examNameCurrent.current,
      // description: examDescription,
      description: examDescriptionCurrent.current,
      // start_time: startTime,
      // end_time: endTime,
      start_time: startTimeCurrent.current,
      end_time: endTimeCurrent.current,
      exercise: JSON.stringify(examExercises),
    }
    saveExamParams(params)
  }

  /** 点击发布考试按钮 */
  const onPublishBtnClick = (e: any) => {
    const params = {
      sessionid: Cookies.get('sessionid'),
      exam_id: examId,
      // name: examName,
      name: examNameCurrent.current,
      // description: examDescription,
      description: examDescriptionCurrent.current,
      // start_time: startTime,
      // end_time: endTime,
      start_time: startTimeCurrent.current,
      end_time: endTimeCurrent.current,
      exercise: JSON.stringify(examExercises),
      status: 2, //发布考试
    }
    saveExamParams(params)
    queryExam()
    //window.location.href = '/course-teacher-exam-preview?exam_id=' + examId + "&course_id=" + courseId + "&menu_id=" + currentCourseMenuId;
  }

  /** 答题状态和颜色的映射  背景色*/
  const getColorByStatus = (status: number, type: number) => {
    if (status == 1) {
      //未做
      return '#fff'
    } else {
      if (type == 5) {
        //做了，是主观题
        return '#47B1DF'
      } else {
        if (status == 2) {
          return '#38CD7F'
        } else {
          return '#FF6F73'
        }
      }
    }
  }

  /** 答题状态和题号颜色的映射 */
  const getFontColorByStatus = (status: number, type: number) => {
    if (status == 1) {
      return '#999'
    } else {
      return '#fff'
    }
  }

  /** 答题状态和题号颜色的映射 */
  const getBorderByStatus = (status: number, type: number) => {
    if (status == 1) {
      return '1px solid #999'
    } else if (type == 5) {
      //做了，是主观题
      return '1px solid #47B1DF'
    } else if (status == 2) {
      return '1px solid #38CD7F'
    } else {
      return '1px solid #FF6F73'
    }
  }

  // 基本习题框架
  const baseExerciseInfo: any = {
    answer_parse: '',
    create_time: '',
    creator: '',
    creator_id: 1,
    dataset_path: '',
    datasets: [],
    default_code: '',
    difficulty: 1,
    difficulty_str: '',
    exam_id: 0,
    hint_submit_btn: 1,
    id: -1,
    images: [],
    is_delete: 1,
    judgement_code: '',
    language: 2,
    language_str: 'python3',
    options: [
      { id: '1', options: '1' },
      { id: '2', options: '2' },
      { id: '3', options: '3' },
      { id: '4', options: '4' },
    ],
    options_answer: [],
    preset_code: '',
    reference_answer: '',
    remarks: '',
    score: 0,
    source: 1,
    source_key: 4,
    source_page: 4,
    status: 1,
    steps: '',
    submission_info: {
      is_html: false,
      submission_answer: [],
      submission_code: '',
      submission_id: 0,
      submission_image: [],
      submission_output: '',
      submission_status: 3,
      submission_time: '',
      submission_variables: [],
    },
    title: '',
    type: 8,
    type_name: '',
    update_time: '',
  }

  // useEffect(() => {
  //   //queryExerciseList();
  // }, [])

  const onEditExamClick = (event: any) => {
    if (!checkUserLogin()) {
      document.getElementById('show-login-modal')!.click()
    }
    window.open(
      '/course-teacher-exam-edit?exam_id=' +
        examId +
        '&course_id=' +
        courseId +
        '&menu_id=' +
        currentCourseMenuId
    )
  }

  // 显示公布成绩弹出框
  const announceResult = () => {
    setPublishScoreModalVisible(true)
  }

  interface Props2 {}
  /** 教师已选题查看面板 */
  const CourseTeacherExamExercise: React.FC<Props2> = (props) => {
    const [showExamTableMode, setShowExamTableMode] = useState(true)
    const [showExamExpandMode, setShowExamExpandMode] = useState(false)
    const [showExamStatMode, setShowExamStatMode] = useState(false)

    const handleExamTableModeChange = (checked) => {
      setShowExamTableMode(true)
      setShowExamExpandMode(false)
      setShowExamStatMode(false)
    }

    const handleExamExpandModeChange = (checked) => {
      setShowExamTableMode(false)
      setShowExamExpandMode(true)
      setShowExamStatMode(false)
    }

    const handleExamStatModeChange = (checked) => {
      setShowExamTableMode(false)
      setShowExamExpandMode(false)
      setShowExamStatMode(true)
    }

    /** 渲染预览的习题 */
    const renderExercisePreview = (record: any) => {
      return (
        <ExerciseCard
          exercise={record}
          renderType="white"
          teacherOnly={true}
          editorMode={true}
          showAnser={false}
          showClean={true}
        />
      )
    }

    const typeCountMap = new Map([
      ['实操题', 0],
      ['单选题', 0],
      ['多选题', 0],
      ['排序题', 0],
      ['主观题', 0],
    ])
    const difficultyCountMap = new Map([
      ['容易', 0],
      ['中等', 0],
      ['困难', 0],
    ])

    //console.log(examExerciseList)
    examExercises.map((item) => {
      const typeCount = Number(
        typeCountMap.get(typeMap.get(item.type) as string)
      )
      typeCountMap.set(typeMap.get(item.type) as string, typeCount + 1)

      const diffCount = Number(
        difficultyCountMap.get(difficultyMap.get(item.difficulty) as string)
      )
      difficultyCountMap.set(
        difficultyMap.get(item.difficulty) as string,
        diffCount + 1
      )
    })

    // 渲染类型
    const renderType = (text: any, record: any) => (
      <div>{typeMap.get(parseInt(text))}</div>
    )

    // 渲染难易程度
    const renderDifficulty = (text: any, record: any) => (
      <div>{difficultyMap.get(parseInt(text))}</div>
    )

    const exercise_columns: any = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        align: 'center',
        width: 60,
        render: (text, record, index) => `${index + 1}`,
      },
      {
        title: '题目',
        dataIndex: 'title',
        key: 'title',
        align: 'left',
        width: 200,
        ellipsis: 'true',
      },
      {
        title: '题型',
        dataIndex: 'type',
        key: 'type',
        align: 'center',
        width: 100,
        render: renderType,
      },
      {
        title: '难度',
        dataIndex: 'difficulty',
        key: 'difficulty',
        align: 'center',
        width: 80,
        render: renderDifficulty,
      },

      {
        title: '分值',
        dataIndex: 'score',
        key: 'score',
        align: 'center',
        width: 50,
      },
    ]

    interface Props2 {}
    /** 试题难度分布面板 */
    const ExamExerciseStatDifficultPanel: React.FC<Props2> = (props) => {
      const difficult_option = {
        title: {
          text: '试题难度分布',
          textStyle: { fontSize: 14 },
          left: 'center',
          top: 'bottom',
        },
        tooltip: {
          trigger: 'item',
        },
        legend: {
          orient: 'vertical',
          left: 'right',
          top: 'center',
        },
        series: [
          {
            name: '难度',
            type: 'pie',
            radius: '50%',

            label: {
              show: true,
              formatter: '{b} ( {c} / {d}% ) ',
              position: 'outside',
            },
            data: [
              { value: difficultyCountMap.get('容易'), name: '容易' },
              { value: difficultyCountMap.get('中等'), name: '中等' },
              { value: difficultyCountMap.get('困难'), name: '困难' },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      }

      useEffect(() => {
        const chartDifficultDom = document.getElementById('difficult')!
        const myDifficultChart = echarts.init(chartDifficultDom)
        difficult_option && myDifficultChart.setOption(difficult_option)
      }, [])

      return (
        <div id="difficult" style={{ height: 400 }}>
          {' '}
        </div>
      )
    }

    /** 试题题型分布面板 */
    const ExamExerciseStatTypePanel: React.FC<Props2> = (props) => {
      const type_option = {
        title: {
          text: '试题题型分布',
          textStyle: { fontSize: 14 },
          left: 'center',
          top: 'bottom',
        },
        tooltip: {
          trigger: 'item',
        },
        legend: {
          orient: 'vertical',
          left: 'right',
          top: 'center',
        },
        series: [
          {
            name: '题型',
            type: 'pie',
            radius: '50%',

            label: {
              show: true,
              formatter: '{b} ( {c} / {d}% ) ',
              position: 'outside',
            },
            data: [
              { value: typeCountMap.get('实操题'), name: '实操题' },
              { value: typeCountMap.get('单选题'), name: '单选题' },
              { value: typeCountMap.get('多选题'), name: '多选题' },
              { value: typeCountMap.get('排序题'), name: '排序题' },
              { value: typeCountMap.get('主观题'), name: '主观题' },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      }

      useEffect(() => {
        const chartTypeDom = document.getElementById('type')!
        const myTypeChart = echarts.init(chartTypeDom)
        type_option && myTypeChart.setOption(type_option)
      }, [])

      return (
        <div id="type" style={{ height: 400 }}>
          {' '}
        </div>
      )
    }

    return (
      <>
        <div style={{ marginBottom: 10, marginTop: 0, textAlign: 'right' }}>
          <Row justify="end" align="bottom" gutter={[4, 4]}>
            <Col>
              <div className={selectedExeStyles.checkableTab}>
                <CheckableTag
                  key={'table'}
                  checked={showExamTableMode}
                  onChange={handleExamTableModeChange}
                >
                  {' '}
                  <UnorderedListOutlined /> 列表{' '}
                </CheckableTag>{' '}
              </div>
            </Col>
            <Col>
              <div className={selectedExeStyles.checkableTab}>
                <CheckableTag
                  key={'expand'}
                  checked={showExamExpandMode}
                  onChange={handleExamExpandModeChange}
                >
                  {' '}
                  <PlayCircleOutlined rotate={90} /> 展开{' '}
                </CheckableTag>
              </div>
            </Col>
            <Col style={{ textAlign: 'right', marginRight: 0 }}>
              <div className={selectedExeStyles.checkableTab}>
                <CheckableTag
                  key={'stat'}
                  checked={showExamStatMode}
                  onChange={handleExamStatModeChange}
                >
                  {' '}
                  <PieChartOutlined /> 统计{' '}
                </CheckableTag>
              </div>
            </Col>
          </Row>
        </div>

        {showExamTableMode && (
          <div className={selectedExeStyles.tableWrapper}>
            <ConfigProvider locale={zhCN}>
              <Table
                dataSource={examExercises}
                columns={exercise_columns}
                size="small"
                rowKey={(record) => record.id}
                pagination={false}
                // onChange={onTableChange}
                //rowSelection={rowSelection}
                expandable={{
                  columnWidth: 90,
                  childrenColumnName: '预览',
                  expandIconColumnIndex: 7,
                  expandIcon: ({ expanded, onExpand, record }) =>
                    expanded ? (
                      <div
                        onClick={(e) => onExpand(record, e)}
                        style={{ cursor: 'pointer' }}
                      >
                        预览
                        <UpOutlined className={selectExeStyles.actionBtn} />
                      </div>
                    ) : (
                      <div
                        onClick={(e) => onExpand(record, e)}
                        style={{ cursor: 'pointer' }}
                      >
                        预览
                        <DownOutlined className={selectExeStyles.actionBtn} />
                      </div>
                    ),
                  expandedRowRender: renderExercisePreview,
                  rowExpandable: (record) => record.name !== 'Not Expandable',
                }}
              />
            </ConfigProvider>
          </div>
        )}

        {showExamExpandMode && (
          <div>
            {examExercises.map((exercise, index) => (
              <Row
                key={exercise.id}
                style={{
                  marginBottom: 10,
                  padding: 10,
                  borderColor: '#F5FAF7',
                  borderWidth: 1,
                  borderStyle: 'solid',
                }}
              >
                <Col
                  span={2}
                  style={{ marginTop: 3, textAlign: 'right', fontWeight: 700 }}
                >
                  {index + 1}.
                </Col>
                <Col span={22}>
                  <ExerciseCard
                    exercise={exercise}
                    renderType="white"
                    teacherOnly={true}
                    editorMode={true}
                    showAnser={false}
                    showClean={true}
                  />
                </Col>
              </Row>
            ))}
          </div>
        )}

        {showExamStatMode && (
          <div style={{ textAlign: 'center' }}>
            <div>
              <ExamExerciseStatDifficultPanel />
            </div>

            <div>
              <ExamExerciseStatTypePanel />
            </div>
          </div>
        )}
      </>
    )
  }

  /** 填写考试操作面板 */
  const ExamActionsInfo = useCallback(() => {
    if (examStatus == '未发布') {
      return <ConfigProvider locale={zhCN}></ConfigProvider>
    } else if (examStatus == '未开考') {
      return (
        <ConfigProvider locale={zhCN}>
          <Row align="middle" justify="start" gutter={[6, 6]}>
            <Col style={{ textAlign: 'center' }} span={12}>
              <div
                style={{ border: '1px solid #eee', marginTop: 20, padding: 5 }}
              >
                <Countdown
                  valueStyle={{ color: 'red', fontSize: 24 }}
                  title="等待考试,倒计时:"
                  value={exam?.start_time?.slice(0, -3)}
                  onFinish={() => {
                    queryExam()
                  }}
                />
              </div>
            </Col>
            <Col style={{ textAlign: 'right' }} span={12}>
              {' '}
              <Button
                onClick={onEditExamClick}
                type="primary"
                style={{ borderRadius: 20, marginTop: 20 }}
              >
                {' '}
                编辑考试信息{' '}
              </Button>
            </Col>
          </Row>
        </ConfigProvider>
      )
    } else if (examStatus == '考试中') {
      return (
        <ConfigProvider locale={zhCN}>
          <div
            style={{
              border: '1px solid #eee',
              width: 180,
              marginLeft: 120,
              marginTop: 20,
              padding: 5,
            }}
          >
            <Countdown
              valueStyle={{ color: 'red', fontSize: 24 }}
              title={`正在考试,距离结束：`}
              value={endTime?.slice(0, -3)}
              onFinish={() => {
                queryExam()
              }}
            />
          </div>
        </ConfigProvider>
      )
    } else if (examStatus == '阅卷中') {
      return (
        <ConfigProvider locale={zhCN}>
          <Row
            align="middle"
            justify="start"
            gutter={[16, 16]}
            style={{ marginTop: 20 }}
          >
            {/* <Col style={{textAlign:'left'}} span={12}> <Button onClick={announceResult} size="small" style={{color: '#07C160',borderColor:'#07C160',width:100,height:30, borderRadius:20}}> 公布成绩 </Button> </Col>
              <Col style={{textAlign:'left'}} span={12}> <Button size="small" style={{color: '#07C160',borderColor:'#07C160',width:100,height:30, borderRadius:20}}> 公布答案 </Button></Col>
              <Col style={{textAlign:'left'}} span={12}> <Button size="small" style={{color: '#07C160',borderColor:'#07C160',width:100,height:30, borderRadius:20}}> 导出答卷 </Button> </Col> */}
            <Col style={{ textAlign: 'right' }} span={24}>
              {' '}
              <Button
                onClick={announceResult}
                size="small"
                style={{
                  color: '#07C160',
                  borderColor: '#07C160',
                  width: 120,
                  height: 30,
                  borderRadius: 20,
                }}
              >
                {' '}
                <CheckCircleOutlined style={{ marginLeft: 0 }} />
                完成阅卷{' '}
              </Button>
            </Col>
          </Row>
        </ConfigProvider>
      )
    } else {
      // 已完成
      return (
        <ConfigProvider locale={zhCN}>
          <Row
            align="middle"
            justify="start"
            gutter={[16, 16]}
            style={{ marginTop: 20 }}
          >
            <Col
              style={{
                textAlign: 'right',
                paddingRight: 8,
                paddingTop: 10,
                paddingBottom: 5,
              }}
              span={24}
            >
              <Button
                onClick={() => {
                  setPublishAnswerModalVisible(true)
                }}
                disabled={answerPublished == true ? true : false}
                size="small"
                style={{
                  color: '#07C160',
                  borderColor: '#07C160',
                  width: 'answerPublished == true ? 120 : 100',
                  height: 30,
                  borderRadius: 20,
                }}
              >
                {' '}
                <img
                  style={{ position: 'relative', bottom: 1 }}
                  src={AnswerSVG}
                />
                {answerPublished == true ? '答案已公布' : '公布答案'}{' '}
              </Button>
              <Button
                onClick={() => {
                  examExport()
                }}
                size="small"
                style={{
                  color: '#07C160',
                  borderColor: '#07C160',
                  width: 100,
                  height: 30,
                  borderRadius: 20,
                  marginLeft: 8,
                }}
              >
                <img
                  style={{ position: 'relative', bottom: 1 }}
                  src={PaperSVG}
                />
                导出试卷
              </Button>
              <Button
                onClick={() => {
                  examAnswerExport()
                }}
                size="small"
                style={{
                  color: '#07C160',
                  borderColor: '#07C160',
                  width: 100,
                  height: 30,
                  borderRadius: 20,
                  marginLeft: 8,
                }}
              >
                {' '}
                导出答卷{' '}
              </Button>
              {/* <Button hidden={true} onClick={()=>{examExport()}} size="small" style={{color: '#07C160',borderColor:'#07C160',width:100,height:30, borderRadius:20,marginLeft:10}}> 导出全部答卷 </Button> */}
              <Button
                onClick={() => {
                  scoreExport()
                }}
                size="small"
                style={{
                  color: '#07C160',
                  borderColor: '#07C160',
                  width: 100,
                  height: 30,
                  borderRadius: 20,
                  marginLeft: 8,
                }}
              >
                {' '}
                <img
                  style={{ position: 'relative', bottom: 1 }}
                  src={ScoreSVG}
                />
                导出成绩{' '}
              </Button>
            </Col>
          </Row>
        </ConfigProvider>
      )
    }
  }, [exam])

  /** 填写考试基本信息 */
  const ExamBasicInfo = () => (
    <ConfigProvider locale={zhCN}>
      <div style={{ marginTop: 10, marginBottom: 20 }}>
        <Row
          gutter={[8, 8]}
          justify="start"
          align="middle"
          style={{ marginBottom: 20 }}
        >
          <Col flex="80px" style={{ color: '#00000073', textAlign: 'left' }}>
            考试标题
          </Col>
          <Col style={{ textAlign: 'left' }} flex="auto">
            {' '}
            <Input
              maxLength={20}
              // value={examName}
              defaultValue={examNameCurrent.current}
              onChange={(e: any) => {
                console.log(e.target.value)

                setExamName(e.target.value)
                examNameCurrent.current = e.target.value
              }}
            />{' '}
          </Col>
        </Row>

        <Row
          gutter={[8, 8]}
          justify="start"
          align="middle"
          style={{ marginBottom: 20 }}
        >
          <Col flex="80px" style={{ color: '#00000073', textAlign: 'left' }}>
            考试时间
          </Col>
          <Col style={{ textAlign: 'left' }} flex="400px">
            {/* {startTime && endTime && ( */}
            <RangePicker
              style={{ width: '100%' }}
              disabledDate={disabledDate}
              placeholder={['开始时间', '结束时间']}
              showTime={{ format: 'HH:mm:ss' }}
              format="YYYY-MM-DD HH:mm:ss"
              onChange={onDateRangeChange}
              // onOk={() => {}}
              defaultValue={
                exam?.start_time
                  ? [
                      moment(exam?.start_time, dateFormat),
                      moment(exam?.end_time, dateFormat),
                    ]
                  : []
              }
              separator={<div style={{ marginRight: 20 }}>到</div>}
            />
            {/* )} */}
          </Col>
        </Row>

        <Row
          gutter={[8, 8]}
          justify="start"
          align="top"
          style={{ marginBottom: 10 }}
        >
          <Col flex="80px" style={{ color: '#00000073', textAlign: 'left' }}>
            考试说明
          </Col>
          <Col flex="auto">
            <TextArea
              // value={examDescription}
              defaultValue={examDescriptionCurrent.current}
              rows={3}
              //showCount
              maxLength={500}
              onChange={(e: any) => {
                // setExamDescription(e.target.value)
                examDescriptionCurrent.current = e.target.value
              }}
            ></TextArea>
          </Col>
        </Row>
      </div>
    </ConfigProvider>
  )
  //计算考试时长
  const getExamDuration = (startTime, endTime) => {
    const t1 = moment(startTime).utc().zone(-8)
    const t2 = moment(endTime).utc().zone(-8)
    const duration = t2.diff(t1, 'minute')
    return duration
  }

  /** 填写考试内容面板 */
  const ExamContentInfo: React.FC<Props> = useCallback(
    (props) => {
      if (examStatus == '未发布') {
        return (
          <ConfigProvider locale={zhCN}>
            <div>
              <ExamBasicInfo />
            </div>
            <CourseTeacherExamComposition
              course_id={courseId}
              menu_id={currentCourseMenuId}
              initExercises={examExercises}
              onExamExerciseChange={onExamExerciseChange}
            />
            <div>
              <Button
                className={courseCreateExamStyles.stepsBtn}
                style={{ marginRight: 20 }}
                type="default"
                onClick={onSaveBtnClick}
              >
                保存
              </Button>

              <Button
                className={courseCreateExamStyles.stepsBtn}
                style={{ marginRight: 20, color: '#fff' }}
                type="primary"
                onClick={onPublishBtnClick}
              >
                发布
              </Button>
            </div>
          </ConfigProvider>
        )
      } else if (examStatus == '未开考') {
        return (
          <ConfigProvider locale={zhCN}>
            <Row>
              <Col style={{ textAlign: 'left' }} span={12}>
                <Row align="middle" justify="start" gutter={[6, 6]}>
                  <Col
                    style={{ fontWeight: 500, fontSize: 16, textAlign: 'left' }}
                    span={24}
                  >
                    {' '}
                    {examName}{' '}
                  </Col>
                  <Col>
                    <span style={{ color: '#07C160', fontSize: 16 }}>
                      <ClockCircleOutlined />
                    </span>
                  </Col>
                  <Col style={{ textAlign: 'left' }} span={22}>
                    {' '}
                    {startTime?.slice(0, -3)} 至 {endTime?.slice(0, -3)}{' '}
                  </Col>
                  <Col style={{ textAlign: 'left' }} span={24}>
                    {' '}
                    共{' '}
                    <span style={{ color: '#07C160' }}>
                      {examExercises?.length}
                    </span>{' '}
                    道题 总分{' '}
                    <span style={{ color: '#07C160' }}>{getTotalScore()}</span>{' '}
                    考试时长：
                    <span style={{ color: '#07C160' }}>
                      {getExamDuration(exam?.start_time, exam.end_time)}{' '}
                    </span>{' '}
                    分钟{' '}
                  </Col>
                </Row>
              </Col>
              <Col span={12}> {ExamActionsInfo()} </Col>
            </Row>

            <Row style={{ marginTop: 20 }}>
              <Col style={{ fontWeight: 500, textAlign: 'left' }} span={24}>
                {' '}
                考试说明:
              </Col>
              <Col style={{ textAlign: 'left' }} span={24}>
                {' '}
                {examDescription}{' '}
              </Col>
            </Row>
            <CourseTeacherExamExercise />
          </ConfigProvider>
        )
      } else if (examStatus == '考试中') {
        return (
          <ConfigProvider locale={zhCN}>
            <Row>
              <Col style={{ textAlign: 'left' }} span={12}>
                <Row align="middle" justify="start" gutter={[6, 6]}>
                  <Col
                    style={{ fontWeight: 500, fontSize: 16, textAlign: 'left' }}
                    span={24}
                  >
                    {' '}
                    {examName}{' '}
                  </Col>
                  <Col>
                    <span style={{ color: '#07C160', fontSize: 16 }}>
                      <ClockCircleOutlined />
                    </span>
                  </Col>
                  <Col style={{ textAlign: 'left' }} span={22}>
                    {' '}
                    {startTime?.slice(0, -3)} 至 {endTime?.slice(0, -3)}{' '}
                  </Col>
                  <Col style={{ textAlign: 'left' }} span={24}>
                    {' '}
                    共{' '}
                    <span style={{ color: '#07C160' }}>
                      {examExercises?.length}
                    </span>{' '}
                    道题 总分{' '}
                    <span style={{ color: '#07C160' }}>{getTotalScore()}</span>{' '}
                    考试时长：
                    <span style={{ color: '#07C160' }}>
                      {getExamDuration(exam?.start_time, exam.end_time)}
                    </span>{' '}
                    分钟{' '}
                  </Col>
                </Row>
              </Col>
              <Col span={12}>{ExamActionsInfo()}</Col>
            </Row>

            <Row style={{ marginTop: 20 }}>
              <Col style={{ fontWeight: 500, textAlign: 'left' }} span={24}>
                {' '}
                考试说明:
              </Col>
              <Col style={{ textAlign: 'left' }} span={24}>
                {' '}
                {examDescription}{' '}
              </Col>
            </Row>

            <ExamComplete exam={[]} exerciseList={[]} />
          </ConfigProvider>
        )
      } else if (examStatus == '阅卷中') {
        return (
          <ConfigProvider locale={zhCN}>
            <Row>
              <Col style={{ textAlign: 'left' }} span={16}>
                <Row align="middle" justify="start" gutter={[6, 6]}>
                  <Col
                    style={{ fontWeight: 500, fontSize: 16, textAlign: 'left' }}
                    span={24}
                  >
                    {' '}
                    {examName}{' '}
                  </Col>
                  <Col>
                    <span style={{ color: '#07C160', fontSize: 16 }}>
                      <ClockCircleOutlined />
                    </span>
                  </Col>
                  <Col style={{ textAlign: 'left' }} span={22}>
                    {' '}
                    {startTime?.slice(0, -3)} 至 {endTime?.slice(0, -3)}{' '}
                  </Col>
                  <Col style={{ textAlign: 'left' }} span={24}>
                    {' '}
                    共{' '}
                    <span style={{ color: '#07C160' }}>
                      {examExercises?.length}
                    </span>{' '}
                    道题 总分{' '}
                    <span style={{ color: '#07C160' }}>{getTotalScore()}</span>{' '}
                    考试时长：
                    <span style={{ color: '#07C160' }}>
                      {getExamDuration(exam?.start_time, exam.end_time)}
                    </span>{' '}
                    分钟{' '}
                  </Col>
                </Row>
              </Col>
              <Col span={8}> {ExamActionsInfo()} </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Col style={{ fontWeight: 500, textAlign: 'left' }} span={24}>
                {' '}
                考试说明:
              </Col>
              <Col style={{ textAlign: 'left' }} span={24}>
                {' '}
                {examDescription}{' '}
              </Col>
            </Row>
            <ExamComplete exam={[]} exerciseList={[]} />
          </ConfigProvider>
        )
      } else if (examStatus == '已完成') {
        // 已完成
        return (
          <ConfigProvider locale={zhCN}>
            <Row>
              <Col style={{ textAlign: 'left' }} span={10}>
                <Row align="middle" justify="start" gutter={[6, 6]}>
                  <Col
                    style={{ fontWeight: 500, fontSize: 16, textAlign: 'left' }}
                    span={24}
                  >
                    {' '}
                    {examName}{' '}
                  </Col>
                  <Col>
                    <span style={{ color: '#07C160', fontSize: 16 }}>
                      <ClockCircleOutlined />
                    </span>
                  </Col>
                  <Col style={{ textAlign: 'left' }} span={22}>
                    {' '}
                    {startTime?.slice(0, -3)} 至 {endTime?.slice(0, -3)}{' '}
                  </Col>
                  <Col style={{ textAlign: 'left' }} span={24}>
                    {' '}
                    共{' '}
                    <span style={{ color: '#07C160' }}>
                      {examExercises?.length}
                    </span>{' '}
                    道题 总分{' '}
                    <span style={{ color: '#07C160' }}>{getTotalScore()}</span>{' '}
                    考试时长：
                    <span style={{ color: '#07C160' }}>
                      {getExamDuration(exam?.start_time, exam.end_time)}
                    </span>{' '}
                    分钟{' '}
                  </Col>
                </Row>
              </Col>
              <Col span={14}> {ExamActionsInfo()} </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Col style={{ fontWeight: 500, textAlign: 'left' }} span={24}>
                {' '}
                考试说明:
              </Col>
              <Col style={{ textAlign: 'left' }} span={24}>
                {' '}
                {examDescription}{' '}
              </Col>
            </Row>
            <ExamComplete exam={[]} exerciseList={[]} />
          </ConfigProvider>
        )
      } else {
        return <div>加载中...</div>
      }
    },
    [exam]
  )

  /** 完成情况和考卷 */
  const ExamComplete: React.FC<Props> = (props) => {
    const [showExamCompleteInviMode, setShowExamCompleteInviMode] = useState(
      examStatus == '考试中' ? true : false
    )
    const [showExamCompleteListMode, setShowExamCompleteListMode] = useState(
      examStatus == '考试中' ? false : true
    )

    const [isTestModalVisible, setIsTestModalVisible] = useState(false)
    const [exerciseResult, setExerciseResult] = useState([] as any[]) // 当
    const [exerciseIdList, setExerciseIdList] = useState([] as any[]) // 题目id列表

    const [completeStatus, setCompleteStatus] = useState([] as any[]) // 完成情况

    const [activeKey, setActiveKey] = useState('1')
    const [studentCompleteList, setStudentCompleteList] = useState([] as any[]) //学生完成情况
    const [studentCompleteLoading, setStudentCompleteLoading] = useState(false)
    const studentPage = useRef(1) // 记录当前的页数
    const studentPageSize = useRef(20) // 记录当前每页展示数量
    const studentQueryContent = useRef('') // 查询关键字
    const [studentResultSum, setStudentResultSum] = useState(0) // 搜索返回的结果数

    const order = useRef('') // 排序升序降序
    const order_by = useRef('') // 排序列
    const statusFilters = useRef([]) // 在线状态过滤选项

    const [selecetedExerciseIndex, setSelecetedExerciseIndex] = useState(0) //默认选中第一题

    const refreshRef = useRef(null as any)

    const [autoRefresh, setAutoRefresh] = useState(false) //是否自动刷新开关，默认关系

    const [outputIsFullScreen, setOutputIsFullScreen] = useState(false) //记录监控大屏是否处于全屏状态

    const [imgs, setImgs] = useState([] as any[])
    const [ppt, setPpt] = useState([] as any[])

    useEffect(() => {
      queryBigScreen()

      sendHttpPostRequest(
        'exam/api/user_query_exam_by_id/',
        {
          sessionid: Cookies.get('sessionid'),
          exam_id: examId,
          course_id: courseId,
        },
        (response: any) => {
          message.destroy()
          if (response.code == -100) {
            // 未登录
            window.location.href =
              '/login?next=course-main-teacher?course_id=' +
              courseId +
              '&tabKey=5'
          } else if (response.is_ok == true) {
            let _exerciseIdList = [] as any[]
            response.query_info?.exercise?.map((item) => {
              _exerciseIdList.push(item.id)
            })
            setExerciseIdList(_exerciseIdList)
            // 成功
            if (response.query_info.exercise.length > 0) {
              exerciseId.current = response.query_info.exercise[0]['id']
            }
          } else if (response.code == -501) {
            //权限不足
            window.location.href =
              '/login?next=course-main-teacher?course_id=' +
              courseId +
              '&tabKey=5'
          } else {
            //message.error(response.message);
          }
        }
      )

      queryStudentSubmission()
      setIsLoad(true)

      function fullScreenChange() {
        let isFull = document.fullscreenElement
        if (!isFull) {
          setOutputIsFullScreen(false)
          console.log('退出全屏')
        } else {
          setOutputIsFullScreen(true)
          console.log('进入全屏')
        }
      }
      document.addEventListener('fullscreenchange', fullScreenChange)
    }, [])

    const outputShowFull = () => {
      let full = document.getElementById('big-screen-wrapper')
      launchIntoFullscreen(full)
      setOutputIsFullScreen(true)
    }

    const outputDelFull = () => {
      let full = document.getElementById('big-screen-wrapper')
      exitFullscreen()
      setOutputIsFullScreen(false)
    }

    const exitFullscreen = () => {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } //else if(document.mozCancelFullScreen) {
      //document.mozCancelFullScreen();
      //} else if(document.webkitExitFullscreen) {
      //document.webkitExitFullscreen();
      //}
    }

    const launchIntoFullscreen = (element: any) => {
      if (element.requestFullscreen) {
        element.requestFullscreen()
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen()
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen()
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen()
      }
    }

    /** 教师更改了自动刷新开关 */
    const onRefrechSwitchChage = (checked) => {
      setAutoRefresh(checked)
      if (checked) {
        const timer = setInterval(() => {
          //每30秒自动刷新
          queryBigScreen() //查询监考大屏数据
        }, 30000)
        refreshRef.current = timer //保存定时器对象
      } else {
        clearInterval(refreshRef.current) //关闭定时器
      }
    }

    const [studentBigScreenData, setStudentBigScreenData] = useState(
      [] as any[]
    ) //学生监控大屏数据
    const [studentBigScreenDataLoading, setStudentBigScreenDataLoading] =
      useState(false) //学生监控大屏数据loading
    const studentBigScreenPage = useRef(1)
    const studentBigScreenPageSize = useRef(10)
    const [studentBigScreenTotalSum, setStudentBigScreenTotalSum] = useState()
    const queryBigScreen = () => {
      setStudentBigScreenDataLoading(true)
      let _params = {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        page: studentBigScreenPage.current,
        pagesize: studentBigScreenPageSize.current,
        exam_id: examId, // 考试id
        course_id: courseId,
        query: studentQueryContent.current,
      }

      sendHttpPostRequest(
        'exam/api/user_query_exam_bigscreen/',
        _params,
        (response: any) => {
          message.destroy()
          if (response.code == -100) {
            // 未登录
            document.getElementById('show-login-modal')!.click()
          } else if (response.is_ok) {
            setStudentBigScreenDataLoading(false)
            setStudentBigScreenData(response.query_list)
            setStudentBigScreenTotalSum(response.total_sum)
          } else {
            message.error(response.message)
          }
        }
      )
    }

    //监考大屏分页变化
    const studentBigScreenPageChange = (
      newPage: number,
      newPageSize: number | undefined
    ) => {
      studentBigScreenPage.current = newPage ? newPage : 1
      if (newPageSize) {
        studentBigScreenPageSize.current = newPageSize
      }
      queryBigScreen()
    }
    // 监控状态渲染
    const renderComplteStatus = (completeStatus: any, record: any) => {
      // console.log(completeStatus)
      return (
        <Row
          align="middle"
          justify={'start'}
          gutter={[2, 2]}
          style={{ textAlign: 'left', width: '100%' }}
        >
          {completeStatus?.map((status: any, index: number) => (
            <Col key={index}>
              {' '}
              <div
                style={{
                  backgroundColor: getColorByStatus(
                    status.status,
                    exam?.exercise[index].type
                  ),
                  color: getFontColorByStatus(
                    status.status,
                    exam?.exercise[index].type
                  ),
                  border: getBorderByStatus(
                    status.status,
                    exam?.exercise[index].type
                  ),
                  margin: 4,
                  textAlign: 'center',
                  height: 20,
                  lineHeight: '18px',
                  minWidth: 20,
                  fontSize: 12,
                  borderRadius: 10,
                }}
              >
                {index + 1}
              </div>{' '}
            </Col>
          ))}
        </Row>
      )
    }

    /** 监控大屏列 */
    const complete_invi_cols: any = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        align: 'center',
        width: 80,
        render: (text, record, index) =>
          studentBigScreenPageSize.current *
            (studentBigScreenPage.current - 1) +
          index +
          1,
      },
      {
        title: '姓名',
        dataIndex: 'name',
        key: 'name',
        align: 'left',
        width: 120,
        render: (text, record) => (
          <>{record.real_name != '' ? record.real_name : record.name}</>
        ),
      },
      {
        title: '题目',
        dataIndex: 'exam_list',
        key: 'exam_list',
        align: 'left',
        render: renderComplteStatus,
      },
    ]

    const [disabled, setDisabled] = useState(true)
    const [bounds, setBounds] = useState({
      left: 0,
      top: 0,
      bottom: 0,
      right: 0,
    })
    const draggleRef = useRef<HTMLDivElement | null>(null)
    const onStart = (event: any, uiData: any) => {
      const { clientWidth, clientHeight } = window?.document?.documentElement
      const targetRect = draggleRef?.current?.getBoundingClientRect()
      if (targetRect) {
        setBounds({
          left: -targetRect?.left + uiData?.x,
          right: clientWidth - (targetRect?.right - uiData?.x),
          top: -targetRect?.top + uiData?.y,
          bottom: clientHeight - (targetRect?.bottom - uiData?.y),
        })
      }
    }

    const [systemScore, setSystemScore] = useState(0) // 系统评分
    const [manualScore, setManualScore] = useState(0 as any) // 手动评分
    const [tempManualScore, setTempManualScore] = useState(0 as any) // 临时分
    const exerciseId = useRef(0) // 题目id
    // const [studentId, setStudentId] = useState(0);   // 学生id
    const studentId = useRef(0) // 学生id
    const [studentName, setStudentName] = useState('') // 学生昵称
    const [studentRealName, setStudentRealName] = useState('') // 学生真实姓名
    const [studentAvatar, setStudentAvatar] = useState(
      'static/image/avatar/default_avatar.png'
    ) // 学生头像
    const [showExportAnswerBtn, setShowExportAnswerBtn] = useState('1') // 是否显示导出成绩按钮 "1"-显示（默认） "2"-不显示

    /** 点击了习题方块按钮 */
    const onExerciseBtnClick = (index) => {
      setSelecetedExerciseIndex(index)
      exerciseId.current = exerciseIdList[index]
      queryExerciseById()
    }
    const handleTestOk = () => {
      setIsTestModalVisible(false)
    }

    const handleTestCancel = () => {
      setIsTestModalVisible(false)
    }

    // 根据id查询习题得分
    const queryExerciseScoreById = (studentId: any, exerciseId: any) => {
      sendHttpPostRequest(
        'exam/api/user_query_exercise_score_by_id',
        {
          sessionid: Cookies.get('sessionid'),
          sid: Cookies.get('sessionid'),
          exam_id: examId,
          course_id: courseId,
          exercise_id: exerciseId,
          student_id: studentId,
        },
        (response: any) => {
          if (response.code == -100) {
            // 未登录
            document.getElementById('show-login-modal')!.click()
          } else if (response.is_ok) {
            setSystemScore(response.system_score)
            setManualScore(response.manual_score)
            setTempManualScore(response.manual_score)
          } else {
            console.error(response.message)
          }
        }
      )
    }

    // 主观题手动打分
    const setExerciseScoreById = (
      studentId: any,
      exerciseId: any,
      score: any
    ) => {
      sendHttpPostRequest(
        'exam/api/user_set_exercise_score_by_id',
        {
          sessionid: Cookies.get('sessionid'),
          sid: Cookies.get('sessionid'),
          exam_id: examId,
          course_id: courseId,
          exercise_id: exerciseId,
          student_id: studentId,
          score: score,
        },
        (response: any) => {
          if (response.is_ok == true) {
            message.success('评分保存成功~')
            setManualScore(tempManualScore)
          } else {
            message.error('评分保存失败~')
          }
        }
      )
    }

    const queryExerciseById = () => {
      setExerciseResult(baseExerciseInfo)
      let _params = {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        exercise_id: exerciseId.current,
        source_page: 4, // 考试4
        source_key: 4, // 学生考试4
        exam_id: examId, // 考试id
        hint_submit_btn: 2, // 隐藏提交按钮，老师只能查看学生提交，不能代其提交
        show_answer: 1, //返回答案信息
      }
      if (0 != studentId.current) {
        _params['user_id'] = studentId.current // 查询的用户id，不传默认查自己
      }
      sendHttpPostRequest(
        'exercise/api/query_exercise_by_id/',
        _params,
        (response: any) => {
          message.destroy()
          if (response.code == -100) {
            // 未登录
            document.getElementById('show-login-modal')!.click()
          } else if (response.is_ok) {
            setExerciseResult(response.exercise_info)
            if (response.exercise_info.type == 5) {
              // 主观题查得分
              queryExerciseScoreById(
                response.exercise_info.submission_info.submitter_id,
                response.exercise_info.id
              )
              queryStudentAttachmentList(response.exercise_info.id)
            }
          } else {
            message.error(response.message)
          }
        }
      )
    }

    // 查询 主观题 学生 上传的 附件（图片） 列表
    const queryStudentAttachmentList = (exerciseId: number) => {
      sendHttpPostRequest(
        'exam/api/user_query_student_exam_attachment',
        {
          sessionid: Cookies.get('sessionid'),
          sid: Cookies.get('sessionid'),
          exam_id: examId,
          exercise_id: exerciseId,
          student_id: studentId.current,
        },
        (response: any) => {
          if (response.is_ok) {
            setImgs(response.image_list)
            setPpt(response.attachment_list)
          }
        }
      )
    }

    const lookatTest = (record: any) => {
      //setIsModalVisible(false);
      //setIsTestModalVisible(true);
      console.log(record)
      const _studentId = record.id
      sendHttpPostRequest(
        'exam/api/user_query_student_exam_submission',
        {
          sessionid: Cookies.get('sessionid'),
          sid: Cookies.get('sessionid'),
          exam_id: examId,
          course_id: courseId,
          student_id: _studentId,
        },
        (response: any) => {
          message.destroy()
          if (response.code == -100) {
            // 没登录
            document.getElementById('show-login-modal')!.click()
          } else if (response.code == -501) {
            message.error('权限不足')
          } else if (response.is_ok) {
            setCompleteStatus(response.query_list)
            setStudentName(response.user_name)
            setStudentRealName(response.user_real_name)
            setStudentName(response.user_name)
            response.user_avatar && response.user_avatar !== ''
              ? setStudentAvatar(response.user_avatar)
              : ''
            // setStudentId(parseInt(response.user_id));
            studentId.current = parseInt(response.user_id)
            if (response.query_list.length > 0) {
              queryExerciseById() // 查询第一个题的提交情况
            }
            setIsTestModalVisible(true)
          } else {
            message.error(response.message)
          }
        }
      )
    }

    // 完成情况渲染操作
    const renderCompleteActions = (text: any, record: any) => {
      return (
        <Row align="middle" justify="center" gutter={[16, 8]}>
          {examStatus == '考试中' && (
            <Col
              style={{ color: '#07C160', cursor: 'pointer' }}
              onClick={lookatTest.bind(this, record)}
            >
              {' '}
              查看{' '}
            </Col>
          )}
          {examStatus == '阅卷中' && (
            <Col
              style={{ color: '#07C160', cursor: 'pointer' }}
              onClick={lookatTest.bind(this, record)}
            >
              {' '}
              打分{' '}
            </Col>
          )}
          {examStatus == '已完成' && (
            <Col
              style={{ color: '#07C160', cursor: 'pointer' }}
              onClick={lookatTest.bind(this, record)}
            >
              {' '}
              查看{' '}
            </Col>
          )}
          {/* <Col style={{color:'#07C160'}}> 导出答卷 </Col>             
                    <Col> 查看 <EyeOutlined className={examPreviewStyles.iconbuttons} /> </Col>
                    <Col> 导出试卷 <Tooltip title="导出试卷"> <FileTextOutlined className={examPreviewStyles.iconbuttons} /> </Tooltip></Col>     */}
        </Row>
      )
    }
    // 渲染姓名
    const renderName = (text: any, record: any) => {
      return <>{record.real_name != '' ? record.real_name : record.name}</>
    }

    // 查询学生提交情况
    const queryStudentSubmission = () => {
      setStudentCompleteLoading(true)
      sendHttpPostRequest(
        'exam/api/user_query_exam_submission',
        {
          sessionid: Cookies.get('sessionid'),
          sid: Cookies.get('sessionid'),
          exam_id: examId,
          course_id: courseId,
          page: studentPage.current,
          pagesize: studentPageSize.current,
          query: studentQueryContent.current,
          order: order.current,
          order_by: order_by.current,
          status: JSON.stringify(statusFilters.current),
        },
        (response: any) => {
          message.destroy()
          if (response.code == -100) {
            // 没登录
            document.getElementById('show-login-modal')!.click()
          } else if (response.code == -501) {
            message.error('权限不足')
          } else if (response.is_ok) {
            setStudentCompleteList(response.query_list)
            setStudentCompleteLoading(false)
            setStudentResultSum(response.total_sum)
            // setStudentRealName(response.user_real_name);
            // setStudentName(response.user_name);
            // (response.user_avatar && response.user_avatar !== '' )? setStudentAvatar(response.user_avatar) : '';
          } else {
            message.error(response.message)
          }
        }
      )
    }

    //页码和页数发生了变化
    const onPageChange = (
      newPage: number,
      newPageSize?: number | undefined
    ) => {
      page.current = newPage ? newPage : 1
      if (newPageSize) {
        pageSize.current = newPageSize
      }
      // queryCourseStudent();
    }

    //页码和页数发生了变化
    const onStudentPageChange = (
      newPage: number,
      newPageSize?: number | undefined
    ) => {
      studentPage.current = newPage ? newPage : 1
      if (newPageSize) {
        studentPageSize.current = newPageSize
      }
      queryStudentSubmission()
    }

    // 表格发生变化
    const onStudenTableChange: any = (
      pagination: any,
      filters: any,
      sorter: any,
      extra: { currentDataSource: []; action: any }
    ) => {
      if (sorter.order) {
        //如果选择了排序列
        order.current = sorter.order
        order_by.current = sorter.field
      } else {
        order.current = ''
        order_by.current = ''
      }
      if (filters.status) {
        //如果选择了过滤列
        statusFilters.current = filters.status
      } else {
        statusFilters.current = []
      }

      studentPage.current = 1
      queryStudentSubmission()
    }

    /** 完成情况列 */
    const complete_cols: any = [
      {
        title: '序号',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: 80,
        render: (text, record, index) =>
          studentPageSize.current * (studentPage.current - 1) + index + 1,
      },
      {
        title: '姓名',
        dataIndex: 'name',
        key: 'name',
        align: 'left',
        width: 80,
        render: renderName,
      },
      {
        title: '学号',
        dataIndex: 'number',
        key: 'number',
        align: 'center',
        width: 80,
      },
      {
        title: '状态',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        width: 80,
        filters: [
          {
            text: '未参加',
            value: 1,
          },
          {
            text: '考试中',
            value: 2,
          },
          {
            text: '已交卷',
            value: 3,
          },
        ],
        onFilter: (value: any, record: any) => true,
      },
      {
        title: '交卷时间',
        dataIndex: 'time',
        key: 'time',
        align: 'center',
        width: 130,
      },
      {
        title: '成绩',
        dataIndex: 'score',
        key: 'score',
        align: 'center',
        width: 80,
        sorter: true,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: '百分制成绩',
        dataIndex: 'percent',
        key: 'percent',
        align: 'center',
        width: 90,
      },
      {
        title: '操作',
        dataIndex: 'actions',
        key: 'actions',
        width: 130,
        ellipsis: false,
        align: 'center',
        //fixed: 'right',
        render: renderCompleteActions,
      },
    ]

    /** 监考模式 */
    const handleExamCompleteInviModeChange = (checked) => {
      setShowExamCompleteInviMode(true)
      setShowExamCompleteListMode(false)
      studentBigScreenPage.current = 1
      queryBigScreen()
      //setActiveKey("1");
    }

    /** 学生列表模式 */
    const handleExamCompleteListModeChange = (checked) => {
      setShowExamCompleteInviMode(false)
      setShowExamCompleteListMode(true)
      studentPage.current = 1
      queryStudentSubmission()
      //setActiveKey("1");
    }

    const onSearchValueChange = (event: any) => {
      // console.log(event.target.value)
      studentQueryContent.current = event.target.value
    }

    const onSearchBtn = () => {
      if (showExamCompleteListMode) {
        studentPage.current = 1
        queryStudentSubmission()
      } else {
        studentBigScreenPage.current = 1
        queryBigScreen()
      }
    }

    const onTabChange = (key: string) => {
      setShowExportAnswerBtn(key)
    }

    return (
      <div
        style={{ marginTop: 20, marginBottom: 20 }}
        className={examPreviewStyles.tabsDiv}
      >
        <Tabs
          defaultActiveKey={activeKey}
          type="line"
          onChange={onTabChange}
          size="middle"
          tabPosition="top"
        >
          <TabPane tab={<div>完成情况</div>} key="1">
            <Row style={{ margin: '5px 0' }}>
              <Col style={{ textAlign: 'left' }} span={24}>
                <Input
                  onPressEnter={onSearchBtn}
                  placeholder="姓名/学号/手机号"
                  style={{ float: 'left', borderRadius: 20, width: 200 }}
                  suffix={<SearchOutlined style={{ color: '#666666' }} />}
                  onChange={onSearchValueChange}
                />
              </Col>
            </Row>

            <div style={{ textAlign: 'right', marginBottom: 10 }}>
              <CheckableTag
                key={'invigilator'}
                checked={showExamCompleteInviMode}
                onChange={handleExamCompleteInviModeChange}
              >
                {' '}
                <FundProjectionScreenOutlined /> 监考大屏{' '}
              </CheckableTag>
              <CheckableTag
                key={'studentList'}
                checked={showExamCompleteListMode}
                onChange={handleExamCompleteListModeChange}
              >
                {' '}
                <TableOutlined /> 列表{' '}
              </CheckableTag>
            </div>
            {showExamCompleteInviMode && (
              <div
                className={examPreviewStyles.tableWrapper}
                id="big-screen-wrapper"
                style={{
                  backgroundColor: '#fff',
                  padding: outputIsFullScreen ? 20 : 0,
                }}
              >
                {examStatus == '考试中' && (
                  <div style={{ textAlign: 'left', marginBottom: 10 }}>
                    <Row gutter={[10, 10]}>
                      <Col span={8}>
                        <span style={{ marginLeft: 10, color: '#666' }}>
                          自动刷新：
                        </span>
                        <Switch
                          checkedChildren="开启"
                          unCheckedChildren="关闭"
                          checked={autoRefresh}
                          onChange={onRefrechSwitchChage}
                        />
                      </Col>

                      <Col span={12}>
                        {outputIsFullScreen && (
                          <Row gutter={[10, 10]}>
                            <Col span={20} style={{ fontSize: 20 }}>
                              {examName}
                            </Col>

                            <Col span={4} style={{ textAlign: 'right' }}>
                              <Countdown
                                valueStyle={{ color: 'red', fontSize: 20 }}
                                title={''}
                                value={endTime}
                                onFinish={() => {
                                  queryExam()
                                }}
                              />
                            </Col>
                          </Row>
                        )}
                      </Col>
                      <Col span={4} style={{ textAlign: 'right' }}>
                        {!outputIsFullScreen ? (
                          <FullscreenOutlined
                            style={{ marginRight: 10 }}
                            onClick={outputShowFull}
                          />
                        ) : (
                          <FullscreenExitOutlined
                            style={{ marginRight: 10 }}
                            onClick={outputDelFull}
                          />
                        )}
                      </Col>
                    </Row>
                  </div>
                )}

                <Table
                  className={courseExamListStyles.studentBigScreenTableWapper}
                  dataSource={studentBigScreenData}
                  columns={complete_invi_cols}
                  size="small"
                  rowKey={(record) => record.id}
                  loading={studentBigScreenDataLoading}
                  pagination={{
                    current: studentBigScreenPage.current,
                    pageSize: studentBigScreenPageSize.current,
                    total: studentBigScreenTotalSum,
                    defaultCurrent: 1,
                    defaultPageSize: 10,
                    onChange: studentBigScreenPageChange,
                    size: 'default',
                    position: ['bottomCenter'],
                  }}
                />
              </div>
            )}

            {showExamCompleteListMode && (
              <div className={courseExamListStyles.tableWrapper}>
                <ConfigProvider locale={zhCN}>
                  <Table
                    dataSource={studentCompleteList}
                    columns={complete_cols}
                    size="small"
                    rowKey={(record) => record.id}
                    pagination={false}
                    onChange={onStudenTableChange}
                    loading={studentCompleteLoading}
                  />

                  <Pagination
                    className={selectExeStyles.pagination}
                    style={{ marginTop: 20 }}
                    defaultCurrent={1}
                    current={studentPage.current}
                    total={studentResultSum}
                    hideOnSinglePage={true}
                    onChange={onStudentPageChange}
                    //onChange={}
                    pageSize={studentPageSize.current}
                  />
                </ConfigProvider>
              </div>
            )}
            <Modal
              title={
                <div
                  style={{ width: '100%', cursor: 'move' }}
                  onMouseOver={() => {
                    if (disabled) {
                      setDisabled(false)
                    }
                  }}
                  onMouseOut={() => {
                    setDisabled(true)
                  }}
                  onFocus={() => {}}
                  onBlur={() => {}}
                >
                  <Row gutter={[4, 4]} align="middle" wrap={false}>
                    <Col span={12}>
                      <img
                        src={BACKEND_API_URL + studentAvatar}
                        alt=""
                        style={{
                          verticalAlign: 'middle',
                          margin: 2.4,
                          width: 30,
                          height: 30,
                          borderRadius: 15,
                        }}
                      />
                      <span>
                        {' '}
                        {studentRealName !== ''
                          ? studentRealName
                          : studentName}{' '}
                      </span>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                      <Row
                        gutter={[4, 4]}
                        align="middle"
                        justify="end"
                        wrap={false}
                        style={{ marginRight: 40 }}
                      >
                        <Col>
                          <div
                            style={{
                              borderRadius: 2,
                              backgroundColor: getColorByStatus(1, 1),
                              color: getFontColorByStatus(1, 1),
                              border: getBorderByStatus(1, 1),
                              height: 16,
                              width: 16,
                            }}
                          >
                            {''}
                          </div>
                        </Col>
                        <Col>
                          <span style={{ fontSize: 12, fontWeight: 400 }}>
                            未答
                          </span>
                        </Col>
                        <Col>
                          <div
                            style={{
                              borderRadius: 2,
                              backgroundColor: getColorByStatus(2, 1),
                              color: getFontColorByStatus(2, 1),
                              border: getBorderByStatus(2, 1),
                              height: 16,
                              width: 16,
                            }}
                          >
                            {''}
                          </div>
                        </Col>
                        <Col>
                          <span style={{ fontSize: 12, fontWeight: 400 }}>
                            正确
                          </span>
                        </Col>
                        <Col>
                          <div
                            style={{
                              borderRadius: 2,
                              backgroundColor: getColorByStatus(3, 1),
                              color: getFontColorByStatus(3, 1),
                              border: getBorderByStatus(3, 1),
                              height: 16,
                              width: 16,
                            }}
                          >
                            {''}
                          </div>
                        </Col>
                        <Col>
                          {' '}
                          <span style={{ fontSize: 12, fontWeight: 400 }}>
                            错误
                          </span>
                        </Col>
                        <Col>
                          <div
                            style={{
                              borderRadius: 2,
                              backgroundColor: getColorByStatus(3, 5),
                              color: getFontColorByStatus(3, 5),
                              border: getBorderByStatus(3, 5),
                              height: 16,
                              width: 16,
                            }}
                          >
                            {''}
                          </div>
                        </Col>
                        <Col>
                          <span style={{ fontSize: 12, fontWeight: 400 }}>
                            主观题
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              }
              width={800}
              bodyStyle={{
                margin: 10,
                padding: 10,
                height: 600,
                overflowY: 'auto',
              }}
              footer={[]}
              destroyOnClose={true}
              modalRender={(modal) => (
                <div className={examPreviewStyles.createDiv}>
                  <Draggable
                    disabled={disabled}
                    bounds={bounds}
                    onStart={(event, uiData) => onStart(event, uiData)}
                  >
                    <div ref={draggleRef}>{modal}</div>
                  </Draggable>{' '}
                </div>
              )}
              visible={isTestModalVisible}
              onOk={handleTestOk}
              onCancel={handleTestCancel}
            >
              <Row
                align="middle"
                justify={'start'}
                gutter={[2, 2]}
                className={examPreviewStyles.completeIndex}
                style={{
                  textAlign: 'center',
                  width: '100%',
                  marginBottom: 10,
                  padding: '0 5px 0 12px',
                }}
              >
                <Col span={24}>
                  <Row style={{ paddingLeft: 3 }}>
                    {completeStatus?.map((status: any, index: number) => (
                      <Col key={index}>
                        <div
                          onClick={() => {
                            onExerciseBtnClick(index)
                          }}
                          style={{
                            cursor: 'pointer',
                            backgroundColor: getColorByStatus(
                              status,
                              exam?.exercise[index].type
                            ),
                            color: getFontColorByStatus(
                              status,
                              exam?.exercise[index].type
                            ),
                            border: getBorderByStatus(
                              status,
                              exam?.exercise[index].type
                            ),
                            margin: 6,
                            borderRadius: 2,
                            textAlign: 'center',
                            height: 20,
                            lineHeight: '18px',
                            minWidth: 20,
                            fontSize: 12,
                          }}
                        >
                          {index + 1}
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
              {exerciseResult && exerciseResult['id'] !== -1 && (
                <Row style={{ padding: '0 20px 0 10px' }} wrap={false}>
                  <Col
                    flex="50px"
                    style={{
                      marginTop: 1,
                      fontSize: 16,
                      textAlign: 'right',
                      fontWeight: 600,
                    }}
                  >
                    {selecetedExerciseIndex + 1}.
                  </Col>

                  <Col flex="auto">
                    <ExerciseCard
                      key={selecetedExerciseIndex}
                      exercise={exerciseResult}
                      renderType="white"
                      teacherOnly={true}
                      editorMode={false}
                      showAnser={true}
                      showClean={false}
                    />
                  </Col>

                  <Col flex="80px">
                    （
                    {completeStatus[selecetedExerciseIndex] == 2
                      ? exam?.exercise[selecetedExerciseIndex]?.score
                      : exerciseResult['type'] == 5
                      ? manualScore
                      : 0}
                    /{exam?.exercise[selecetedExerciseIndex]?.score}分）
                  </Col>
                </Row>
              )}

              {exerciseResult['type'] == 5 && (
                <Row
                  className={examPreviewStyles.imgList}
                  style={{
                    width: 600,
                    marginLeft: 60,
                    marginBottom: 10,
                    justifyContent: 'center',
                  }}
                >
                  {imgs.map((img) => {
                    return (
                      <Col
                        key={img.id}
                        flex="140px"
                        className={examPreviewStyles.previewImageWrap}
                      >
                        {/* <div style={{position:'absolute',top:5,right:5,width:20,height:20,textAlign:'center',borderRadius:10,backgroundColor:'#fff',display:'none'}}>
                          <EyeOutlined  style={{color:'#07c160'}} onClick={outputShowFull.bind(this, img.id)}/>
                        </div> */}
                        {/* <div id={"img-preview-wrapper" + img.id} style={{backgroundColor:'#fff',textAlign:'center',lineHeight:outputIsFullScreen?'100vh':'initial'}}> */}
                        <Image
                          src={
                            img.url?.startsWith('http')
                              ? img.url
                              : BACKEND_URL + img.url
                          }
                          style={{ width: 140, height: 90, borderRadius: 4 }}
                          preview={{ mask: <EyeOutlined /> }}
                        />
                        {/* </div> */}
                        {/* <img src={img.url.startsWith("http") ? img.url : BACKEND_URL + img.url} alt="" style={{width:140,height:90,borderRadius:4}}/> */}
                      </Col>
                    )
                  })}
                </Row>
              )}

              {exerciseResult['type'] == 5 && (
                <div style={{ width: 600, marginLeft: 60, marginBottom: 30 }}>
                  {ppt.map((PPT) => {
                    return (
                      <Row
                        key={PPT.name}
                        style={{
                          backgroundColor: '#FBFBFB',
                          paddingLeft: 10,
                          borderRadius: 3,
                          marginBottom: 10,
                        }}
                      >
                        <Col flex="auto">
                          {PPT.name}
                          <span
                            style={{
                              textAlign: 'center',
                              color: '#999',
                              fontSize: 12,
                              marginLeft: 10,
                            }}
                          >
                            {PPT.size}
                          </span>
                        </Col>
                        <Col flex="50px" style={{ textAlign: 'center' }}>
                          <a
                            href={
                              PPT.url.startsWith('http')
                                ? PPT.url
                                : BACKEND_URL + PPT.url
                            }
                            target={'_blank'}
                          >
                            <DownloadOutlined style={{ color: '#999' }} />
                          </a>
                        </Col>
                      </Row>
                    )
                  })}
                </div>
              )}

              {exerciseResult && exerciseResult['type'] == 5 && (
                <Row style={{ padding: '0 20px 0 10px' }} wrap={false}>
                  <Col
                    flex="50px"
                    style={{
                      marginTop: 1,
                      fontSize: 16,
                      textAlign: 'right',
                      fontWeight: 700,
                    }}
                  ></Col>

                  <Col flex="auto">
                    <Row
                      gutter={[10, 10]}
                      align="middle"
                      justify="start"
                      style={{ marginBottom: 10 }}
                    >
                      <Col flex={'80px'}> 系统评分：</Col>
                      <Col flex={'100px'} style={{ color: '#07C160' }}>
                        {systemScore}
                      </Col>
                      <Col flex={'80px'}>重新评分：</Col>
                      <Col flex={'100px'}>
                        {scorePublished == true ? (
                          <div
                            style={{
                              color: '#07C160',
                              width: 80,
                              textAlign: 'left',
                            }}
                          >
                            {manualScore}
                          </div>
                        ) : (
                          <>
                            <InputNumber
                              min={0}
                              max={
                                exam?.exercise?.filter(
                                  (item) => item.id == exerciseResult['id']
                                )[0].score
                              }
                              maxLength={5}
                              style={{ width: 80, textAlign: 'left' }}
                              key={'score-' + manualScore}
                              name={'manual'}
                              value={tempManualScore}
                              onChange={(value) => {
                                setTempManualScore(value)
                              }}
                            />
                          </>
                        )}
                      </Col>
                      {scorePublished == false && (
                        <Col flex={'80px'} style={{ textAlign: 'left' }}>
                          <Button
                            style={{ width: 60, textAlign: 'left' }}
                            onClick={() => {
                              setExerciseScoreById(
                                exerciseResult['submission_info'][
                                  'submitter_id'
                                ],
                                exerciseResult['id'],
                                tempManualScore
                              )
                            }}
                            type="primary"
                          >
                            {' '}
                            确定{' '}
                          </Button>
                        </Col>
                      )}
                      <Col span={24} style={{ fontSize: 12, color: '#999' }}>
                        {' '}
                        提示：系统评分是爱数课AI评分引擎根据学生输入内容和参考答案的匹配程度自动给出的评分。老师如果需要对系统评分进行修正，可人工进行评分。{' '}
                      </Col>
                    </Row>
                  </Col>
                  <Col flex="80px"></Col>
                </Row>
              )}
            </Modal>
          </TabPane>

          <TabPane tab={<div>试 卷</div>} key="2">
            <CourseTeacherExamExercise />
          </TabPane>
        </Tabs>
      </div>
    )
  }

  const [disabled, setDisabled] = useState(true)
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
  const draggleRef = useRef<HTMLDivElement | null>(null)
  const onStart = (event: any, uiData: any) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement
    const targetRect = draggleRef?.current?.getBoundingClientRect()
    if (targetRect) {
      setBounds({
        left: -targetRect?.left + uiData?.x,
        right: clientWidth - (targetRect?.right - uiData?.x),
        top: -targetRect?.top + uiData?.y,
        bottom: clientHeight - (targetRect?.bottom - uiData?.y),
      })
    }
  }

  return (
    <Layout style={{ minHeight: 750, backgroundColor: '#f9f9f9' }}>
      <LoginRegisterModal></LoginRegisterModal>
      <div
        className={examPreviewStyles.courseMain}
        style={{ width: 800, minHeight: 'calc(100vh - 134px)' }}
      >
        <div id="examinfo" style={{ maxHeight: 250, marginBottom: 20 }}>
          <Row
            align="middle"
            gutter={[10, 10]}
            style={{ borderBottom: '1px solid #eee', height: 60 }}
          >
            <Col flex="120px" style={{ textAlign: 'left' }}>
              {' '}
              <div style={{ fontWeight: 700, fontSize: 16 }}>
                查看考试信息
              </div>{' '}
            </Col>
            <Col flex="auto">
              <Steps size="small" current={examStatusList.indexOf(examStatus)}>
                <Step title="未发布" />
                <Step title="未开考" />
                <Step title="考试中" />
                <Step title="阅卷中" />
                <Step title="已完成" />
              </Steps>
            </Col>
            <Col
              span={'100px'}
              style={{ color: '#333333', textAlign: 'right' }}
            >
              {' '}
              <a
                style={{ color: '#333333' }}
                href={
                  '/course-main-teacher?course_id=' + courseId + '&tabKey=5'
                }
              >
                返回{'>'}
              </a>{' '}
            </Col>
          </Row>
        </div>

        <ExamContentInfo exam={[]} exerciseList={[]} />

        <Modal
          title={
            <div
              style={{
                width: '100%',
                cursor: 'move',
              }}
              onMouseOver={() => {
                if (disabled) {
                  setDisabled(false)
                }
              }}
              onMouseOut={() => {
                setDisabled(true)
              }}
              onFocus={() => {}}
              onBlur={() => {}}
            >
              考试成绩公布
            </div>
          }
          closable={false}
          width={400}
          visible={isPublishScoreModalVisible}
          bodyStyle={{ borderBottom: 0 }}
          className={examPreviewStyles.announceModal}
          footer={
            <div style={{ textAlign: 'center', height: 50 }}>
              <Button
                onClick={handlePublishScoreCancel}
                style={{
                  width: 80,
                  borderRadius: 5,
                  borderColor: '#07c160',
                  color: '#07c160',
                  marginRight: 20,
                }}
              >
                取消
              </Button>
              <Button
                type="primary"
                onClick={handlePublishScoreOk}
                style={{
                  width: 80,
                  borderRadius: 5,
                  backgroundColor: '#07c160',
                  color: '#fff',
                }}
              >
                确认
              </Button>
            </div>
          }
        >
          <div style={{ margin: '20px auto', width: 250, textAlign: 'center' }}>
            完成阅卷，代表公布成绩给学生，
          </div>
          <div style={{ margin: '20px auto', width: 250, textAlign: 'center' }}>
            成绩不可再更改。
          </div>
        </Modal>

        <Modal
          title={
            <div
              style={{
                width: '100%',
                cursor: 'move',
              }}
              onMouseOver={() => {
                if (disabled) {
                  setDisabled(false)
                }
              }}
              onMouseOut={() => {
                setDisabled(true)
              }}
              onFocus={() => {}}
              onBlur={() => {}}
            >
              考试答案公布
            </div>
          }
          closable={false}
          width={400}
          visible={isPublishAnswerModalVisible}
          bodyStyle={{ borderBottom: 0 }}
          className={examPreviewStyles.announceModal}
          footer={
            <div style={{ textAlign: 'center', height: 50 }}>
              <Button
                onClick={handlePublishAnswerCancel}
                style={{
                  width: 80,
                  borderRadius: 5,
                  borderColor: '#07c160',
                  color: '#07c160',
                  marginRight: 20,
                }}
              >
                取消
              </Button>
              <Button
                type="primary"
                onClick={handlePublishAnswerOk}
                style={{
                  width: 80,
                  borderRadius: 5,
                  backgroundColor: '#07c160',
                  color: '#fff',
                }}
              >
                确认
              </Button>
            </div>
          }
        >
          <div style={{ margin: '20px auto', width: 250, textAlign: 'center' }}>
            公布答案后，学生可查题目答案。确定公布吗？
          </div>
        </Modal>
      </div>
    </Layout>
  )
}

export default CourseTeacherExamPreview
