import React, { useEffect, useRef, useState } from 'react'
import { Layout, message, Col, Row, Tabs, Button, Modal } from 'antd'
import { PageHeader } from '../common/page-header/header'
import { sendHttpPostRequest } from '../../api/http-request'
import courseMainStyles from './course-main.module.less'
import './course.module.css'
import zhCN from 'antd/lib/locale/zh_CN'
import * as constants from '../../constants/constants'
import PageFooter from '../common/page-footer/footer'
import LoginRegisterModal from '../../component/modal/login-register/login-register'
import { checkUserLogin } from '../common/user-center/user-info'
import { PlayCircleOutlined } from '@ant-design/icons'

const { TabPane } = Tabs

import Feedback from '../common/feedback-help/feedback'
import Cookies from 'js-cookie'
import PlatformMenuPreview from './menu/platform-course-menu-preview'
import LockedMenuPreview from './menu/locked-course-menu-preview'

export interface Props {}

/**平台课程主页面*/
export const CourseMain: React.FC<Props> = (props) => {
  const courseId = parseInt(window.location.search.split('=')[1]) // 课程ID
  const [course, setCourse] = useState(null as any) //课程信息
  const [courseName, setCourseName] = useState('') //课程名称
  const [courseDescription, setCourseDescription] = useState('') //课程介绍
  const [courseCover, setCourseCover] = useState(null as any) //课程封面路径

  const [isLogin, setIsLogin] = useState(false) //是否登录
  const [collegeId, setCollegeId] = useState(null as any) // 院校ID
  const [collegeInfo, setCollegeInfo] = useState(null as any) //查询当前用户的机构信息,is_college_teacher weitrue表示是教师，

  const [menuId, setMenuId] = useState(null as any) //课程大纲
  const [isBuyModalVisible, setIsBuyModalVisible] = useState(true)

  const [isSystemCourse, setIsSystemCourse] = useState(null as any) //是否为平台课
  const [isPay, setIsPay] = useState(null as any) //当前机构是否已经购买该课程
  const [isCollegeTeacher, setIsCollegeTeacher] = useState(null as any) //当前机构是否是机构的教师

  useEffect(() => {
    sendHttpPostRequest(
      'account/api/query_teacher_authenticate',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
      },
      (response: any) => {
        if (response.is_ok == true) {
          setIsLogin(true)
          if (response?.college_info?.college_id !== '') {
            //已加入机构
            let _collegeId = response.college_info.college_id
            setCollegeId(_collegeId)
            setCollegeInfo(response.college_info)
            queryCourseInCollege(_collegeId)
            setIsCollegeTeacher(response.college_info.is_college_teacher)
          } else {
            //未加入机构
            setCollegeId(0)
            queryCourse()
            setIsPay(false)
          }
        } else {
          //用户未登录
          setIsLogin(false)
          queryCourse()
        }
      }
    )
  }, [])

  // 查询课程信息
  const queryCourse = () => {
    const sessionid = Cookies.get('sessionid')
    const queryData = {
      source: 1,
      course_id: courseId,
      sessionid: sessionid,
      sid: sessionid,
    }
    sendHttpPostRequest(
      'course/api/user_query_course_by_id',
      queryData,
      courseCallback
    )
  }

  // 查询课程信息,已加入机构
  const queryCourseInCollege = (college_id) => {
    const sessionid = Cookies.get('sessionid')
    const queryData = {
      source: 1,
      course_id: courseId,
      college_id: college_id,
      sessionid: sessionid,
      sid: sessionid,
    }
    sendHttpPostRequest(
      'course/api/user_query_course_by_id',
      queryData,
      courseInCollegeCallback
    )
  }

  // 查询课程接口回调
  const courseCallback = (response: any) => {
    console.log(response)
    message.destroy()
    //if (response.code == -100) {
    // 未登录
    //document.getElementById("show-login-modal")!.click();
    //} else
    if (response.is_ok == true) {
      // 成功
      setCourse(response.query_info)
      setCourseName(response.query_info.name)
      setCourseDescription(response.query_info.description)
      setCourseCover(response.query_info.cover)
      setMenuId(response.query_info.syllabus_id)
      setIsSystemCourse(response.query_info.is_platform_course)
    } else if (response.code == -501) {
      // 权限不足
      message.error('权限不足')
    } else {
      message.error(response.message)
    }
  }

  // 查询课程接口回调,机构用户
  const courseInCollegeCallback = (response: any) => {
    console.log(response)
    message.destroy()
    //if (response.code == -100) {
    // 未登录
    //document.getElementById("show-login-modal")!.click();
    //} else
    if (response.is_ok == true) {
      // 成功
      setCourse(response.query_info)
      setCourseName(response.query_info.name)
      setCourseCover(response.query_info.cover)
      setCourseDescription(response.query_info.description)
      setMenuId(response.query_info.syllabus_id)
      setIsSystemCourse(response.query_info.is_platform_course)
      setIsPay(response.query_info.ispay)
      console.log(response.query_info.ispay)
    } else if (response.code == -501) {
      // 权限不足
      message.error('权限不足')
    } else {
      message.error(response.message)
    }
  }

  const onCourseLearnClick = () => {
    if (!checkUserLogin()) {
      document.getElementById('show-login-modal')!.click()
    } else {
      if (course.recent_knowledge_url != '') {
        window.open(course.recent_knowledge_url)
      } else if (course.first_knowledge_url != '') {
        window.open(course.first_knowledge_url)
      } else {
        message.destroy()
        message.error('课程暂时无法学习，请稍候...')
      }
    }
  }

  const statusBackGroundColor = (courseStatus: string) => {
    return courseStatus == '已发布' ? '#07c160' : '#FF780E'
  }

  return (
    <Layout style={{ minHeight: 750, backgroundColor: '#f8f8f8' }}>
      <LoginRegisterModal></LoginRegisterModal>

      <div className={courseMainStyles.courseHeaderWrapper}>
        <Row
          className={courseMainStyles.courseHeader}
          style={{ marginRight: '0 auto' }}
          align="middle"
          wrap={false}
          // gutter={[12, 0]}
        >
          <Col flex="300px" style={{ margin: 0 }}>
            <div className={courseMainStyles.courseImage}>
              <img
                src={
                  courseCover && courseCover?.startsWith('http')
                    ? courseCover
                    : constants.BACKEND_URL + courseCover
                }
                width={300}
                height={190}
                style={{ borderRadius: 6 }}
              />
            </div>
          </Col>

          <Col
            style={{
              position: 'relative',
              textAlign: 'left',
              overflow: 'hidden',
              margin: 0,
              marginRight: 20,
              paddingLeft: 40,
              // height: "290px",
            }}
            flex="auto"
          >
            <div className={courseMainStyles.courseName}>{courseName}</div>
            <div className={courseMainStyles.courseIntroduction}>
              {' '}
              {courseDescription}{' '}
            </div>
          </Col>

          <Col style={{ margin: 0 }} flex="100px">
            {isPay == true && course?.isoutdate == 0 && (
              <Button
                type="primary"
                style={{
                  borderRadius: 20,
                  width: 100,
                  float: 'right',
                  padding: 0,
                }}
                onClick={onCourseLearnClick}
              >
                {' '}
                开始学习{' '}
              </Button>
            )}
            {isLogin == false && (
              <Button
                type="primary"
                style={{
                  borderRadius: 20,
                  width: 100,
                  float: 'right',
                  padding: 0,
                }}
                onClick={onCourseLearnClick}
              >
                {' '}
                开始学习{' '}
              </Button>
            )}
          </Col>
        </Row>
      </div>

      <Row align="top">
        <Col flex="auto">
          {' '}
          <div
            style={{
              borderBottomStyle: 'solid',
              borderBottomColor: '#07c160',
              borderBottomWidth: 1,
              height: 36,
              backgroundColor: '#fff',
            }}
          ></div>{' '}
        </Col>
        <Col
          flex="1220px"
          style={{ whiteSpace: 'nowrap', overflow: 'visible', maxWidth: 1220 }}
        >
          <div
            className={courseMainStyles.courseMain}
            // style={{ minHeight: 'calc(100vh - 398px)' }}
          >
            <Tabs
              defaultActiveKey={'1'}
              type="card"
              size="middle"
              tabPosition="top"
            >
              {isLogin == true ? (
                <TabPane tab={<div>大纲</div>} key="1">
                  {course?.syllabus_id > 0 && (
                    <PlatformMenuPreview
                      menu_id={course?.syllabus_id}
                      course_id={course?.id}
                      ispay={isPay}
                      is_teacher={isCollegeTeacher}
                      is_in_college={collegeId > 0}
                      is_outdate={course?.isoutdate == 1}
                    />
                  )}
                </TabPane>
              ) : (
                <TabPane tab={<div>大纲</div>} key="1">
                  {course?.syllabus_id > 0 && (
                    <LockedMenuPreview menu_id={course?.syllabus_id} />
                  )}
                </TabPane>
              )}

              <TabPane tab={<div></div>} disabled={true} key="2"></TabPane>
              <TabPane tab={<div></div>} disabled={true} key="3"></TabPane>
              <TabPane tab={<div></div>} disabled={true} key="4"></TabPane>
              <TabPane tab={<div></div>} disabled={true} key="5"></TabPane>
              <TabPane tab={<div></div>} disabled={true} key="6"></TabPane>
              <TabPane tab={<div></div>} disabled={true} key="7"></TabPane>
              <TabPane tab={<div></div>} disabled={true} key="8"></TabPane>
              <TabPane tab={<div></div>} disabled={true} key="9"></TabPane>
              <TabPane tab={<div></div>} disabled={true} key="10"></TabPane>
            </Tabs>
          </div>
        </Col>
        <Col flex="auto">
          <div
            style={{
              borderBottomStyle: 'solid',
              borderBottomColor: '#07c160',
              borderBottomWidth: 1,
              height: 36,
              backgroundColor: '#fff',
            }}
          ></div>
        </Col>
      </Row>

      <Feedback />
    </Layout>
  )
}

export default CourseMain
