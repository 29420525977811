import {Button, Col, Layout, Row} from 'antd';
import { RouteComponentProps } from 'react-router';
import { Content } from 'antd/lib/layout/layout';
import OAMSider from "../oam-sider";
import React from "react";

import MenuEdit from './menu-edit';



// 通过路由组件额外添加参数信息到表中
interface Props extends RouteComponentProps<{ menu_id: string }> {
    menu_id: string
}

export const OAMMenuEdit: React.FC<Props> = (props: Props) => {
    const menuId = parseInt(props.location.search.split("=")[1])


         //点击了查看按钮
  const onLookOverBtnClick = (event:any) => {
    //message.success('查看数据' + event.currentTarget.value)
    event.preventDefault()
    window.open('/oam_menu_detail?menu_id=' + menuId)
  }


    return (
        <Layout style={{ minHeight: '100vh' }}>
        <OAMSider keys={"32"} openKeys={["sub-course"]}  ></OAMSider>
        
            <Content style={{ backgroundColor:'#fff',margin:30,borderRadius:5,minWidth:600,padding:20}}>
                <div style={{paddingLeft:30,paddingRight:30}}>
                    <MenuEdit menu_id={menuId} />    
                </div>
            </Content>
        
    </Layout>
    )
}

export default OAMMenuEdit

