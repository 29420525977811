import React, { useRef, useEffect, useState } from 'react'
import {
  Modal,
  Input,
  Table,
  Switch,
  Layout,
  message,
  Card,
  Col,
  Row,
  ConfigProvider,
  Pagination,
  Tabs,
  Button,
  Tag,
  Tooltip,
} from 'antd'
import { PageHeader } from '../../common/page-header/header'
import { sendHttpPostRequest } from '../../../api/http-request'
import logoSvg from '../../static/image/idclogo.png'
import pekinglogoSvg from '../../static/image/peking.png'
import courseMainStyles from '../course-main.module.less'
import '../course.module.css'
import zhCN from 'antd/lib/locale/zh_CN'
import * as constants from '../../../constants/constants'
import PageFooter from '../../common/page-footer/footer'
import LoginRegisterModal from '../../../component/modal/login-register/login-register'
import { checkUserLogin } from '../../common/user-center/user-info'
import {
  SyncOutlined,
  InfoCircleOutlined,
  ClockCircleOutlined,
  VideoCameraOutlined,
  CodeOutlined,
  FormOutlined,
  UserOutlined,
} from '@ant-design/icons'
import courseStudentCasesStyles from './course-main-student-cases.module.less'
import Cookies from 'js-cookie'
import shiyanEmpty from '../../../static/image/empty/queshengye-shiyan.png'
import Draggable from 'react-draggable'
import { useHistory } from 'react-router'
import formatIcon from '@/static/image/formatIcon.png';

export interface Props {
  course_id: any
}
/** 教师课程实验列表页面 */
export const CourseMainStudentCases: React.FC<Props> = (props) => {
  const { course_id } = props
  const [disabled, setDisabled] = useState(true)
  const [isInitCaseModal, setIsInitCaseModal] = useState(false) // 初始化实验
  const [courseResult, setCourseResult] = useState([] as any[])
  const [casesList, setCasesList] = useState([] as any[]) //课程的实验
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 }) // Modal框移动
  const [selectedCaseName, setSelectedCaseName] = useState('')
  const [selectedCaseId, setSelectedCaseId] = useState(0)
  const history = useHistory()

  const draggleRef = useRef<HTMLDivElement | null>(null)

  const routeToCaseRun = (record: any, case_id: number, course_id?: any) => {
    window.open(`/case-run?case_id=${case_id}`)

    // if (!record?.is_system) {
    //   window.open(`/course-case-run?case_id=${case_id}?course_id=${course_id}`)
    // } else {
    //   history.push(`/case-run?case_id=${case_id}`)
    // }
  }

  const onRunCaseBtnClick = (record: any) => {
    if (!checkUserLogin()) {
      document.getElementById('show-login-modal')!.click()
    }
    let params = {
      case_id: record.id,
      course_id: course_id,
    }
    sendHttpPostRequest(
      'course/api/user_get_or_fork_course_case_by_id',
      params,
      (res) => {
        if (res.code == 0) {
          routeToCaseRun(record, res.case_info.case_id, course_id)

          // if (record.is_system) {

          //   window.open(
          //     `/course-case-run?case_id=${res.case_info.case_id}?course_id=${course_id}`
          //   )
          // } else {
          //   window.open(`/case-run?case_id=${res.case_info.case_id}`)
          // }

          queryCases()
        } else {
          message.error(res.message)
        }
      }
    )

    // else {
    //   window.open(
    //     `/course-case-run?case_id=${record.id}?course_id=${course_id}`
    //   )
    // }
  }
  const initialbtn = (name, id) => {
    setSelectedCaseId(id)
    setSelectedCaseName(name)
    setIsInitCaseModal(true)
  }

  const onStart = (event: any, uiData: any) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement
    const targetRect = draggleRef.current!.getBoundingClientRect()

    setBounds({
      left: -targetRect?.left + uiData?.x,
      right: clientWidth - (targetRect?.right - uiData?.x),
      top: -targetRect?.top + uiData?.y,
      bottom: clientHeight - (targetRect?.bottom - uiData?.y),
    })
  }

  const onPreviewCaseBtnClick = (caseId: any) => {
    if (!checkUserLogin()) {
      document.getElementById('show-login-modal')!.click()
    } else {
      window.open(
        `/course-case-detail?case_id=${caseId}?course_id=${course_id}`
      )
    }
  }

  // 渲染操作
  const renderActions = (text: any, record: any) => {
    return (
      <div style={{ textAlign: 'left', width: '60px' }}>
        {/* <Button
          onClick={() => initialbtn(record.name, record.id)}
          className={courseStudentCasesStyles.actionBtn}
          style={{
            borderRadius: 5,
            marginRight: 5,
            backgroundColor: '#F5FAF7',
          }}
          size="small"
          type="default"
        >
          {' '}
          初始化{' '}
        </Button> */}
        <Button
          onClick={onPreviewCaseBtnClick.bind(this, record.id)}
          className={courseStudentCasesStyles.actionBtn}
          style={{ borderRadius: 20, marginRight: 5 }}
          size="small"
          type="link"
        >
          {' '}
          查看{' '}
        </Button>
        <Button
          onClick={onRunCaseBtnClick.bind(this, record)}
          className={courseStudentCasesStyles.actionBtn}
          style={{ borderRadius: 20, marginRight: 5 }}
          size="small"
          type="link"
        >
          {/* {record.need_fork ? 'Fork并运行' : '运行'} */}
          {'运行'}
        </Button>
      </div>
    )
  }
  const casesCols: any = [
    {
      title: '序号',
      dataIndex: 'id',
      key: 'id',
      align: 'left',
      width: 80,
      render: (text, record, index) => casesList.indexOf(record) + 1,
    },
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: 350,
      render: (text: any, record: any) => {
        return (
          <div
            style={{ cursor: 'pointer' }}
            onClick={onPreviewCaseBtnClick.bind(this, record.id)}
            className={courseStudentCasesStyles.slideName}
          >
            {' '}
            {text}{' '}
          </div>
        )
      },
    },
    // {
    //   title: '初始化时间',
    //   dataIndex: 'reset_time',
    //   key: 'reset_time',
    //   align: 'left',
    //   width: 350,
    //   // render:(text:any, record:any)=>{return <div style={{cursor:"pointer"}} onClick={onRunCaseBtnClick.bind(this, record.id, record.token)} className={courseStudentCasesStyles.slideName}> {text} </div>}
    // },
    {
      title: (
        <Row
          align={'middle'}
          justify={'center'}
          wrap={false}
          style={{ gap: '10px' }}
        >
          <Col>
            <Tooltip
              placement="bottomLeft"
              title={
                '所有实验，需要先Fork才能运行；Fork过的实验，想要再次Fork,请点击查看按钮进预览页操作'
              }
            >
              {/* <InfoCircleOutlined
                style={{ color: '#4285F4', cursor: 'pointer' }}
              /> */}
              <img src={formatIcon} style={{width:16,height:16,marginTop:-2}} alt="" />
            </Tooltip>
          </Col>
          <Col>操作</Col>
        </Row>
      ),
      dataIndex: 'actions',
      key: 'actions',
      width: '60px',
      ellipsis: false,
      align: 'center',
      render: renderActions,
    },
  ]

  useEffect(() => {
    // 示例代码请勿删除
    /*      sendHttpPostRequest(
            'case/api/get_case_list',
            {page: 1, pagesize: 16, type: 1, query: ""},
            renderCourse
        );*/
    queryCases()
  }, [])
  const url = 'course/api/user_reset_course_case'
  const onInitCaseOk = () => {
    //  这里写  初始化请求代码
    sendHttpPostRequest(
      url,
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        course_id: course_id,
        case_id: selectedCaseId,
      },
      (res) => {
        message.destroy()
        if (res.is_ok) {
          // 请求成功

          message.success('初始化成功')
          setTimeout(function () {
            queryCases()
          }, 1000)
        } else {
          message.error('初始化失败')
        }
      }
    )

    //请求结束  关闭模态框
    setIsInitCaseModal(false)
  }
  const onInitCaseCancel = () => {
    //取消  隐藏模态框
    setIsInitCaseModal(false)
  }

  const queryCases = () => {
    sendHttpPostRequest(
      'course/api/user_query_course_info',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        course_id: course_id,
      },
      (response: any) => {
        if (response.is_ok) {
          setCasesList(response.query_info.case_list)
        }
      }
    )
  }

  return (
    <div className={courseMainStyles.tabpaneWrapper}>
      <div className={courseStudentCasesStyles.tableWrapper}>
        {casesList.length != 0 && (
          <ConfigProvider locale={zhCN}>
            <Table
              style={{ minHeight: '500px' }}
              dataSource={casesList}
              columns={casesCols}
              size="small"
              rowKey={(record) => record.id}
              pagination={{
                position: ['bottomCenter'],
                pageSize: 10,
                size: 'default',
                hideOnSinglePage: true,
              }}
            />
          </ConfigProvider>
        )}
        {casesList.length == 0 && (
          <div style={{ textAlign: 'center', paddingTop: 40 }}>
            <img src={shiyanEmpty} width={120} height={120} />
            <div style={{ color: '#999', marginTop: 10 }}>
              老师还未开放实验哟~
            </div>
          </div>
        )}
      </div>

      {/* 初始化实验 */}
      <ConfigProvider locale={zhCN}>
        <Modal
          title={
            <div
              onMouseOver={() => {
                if (disabled) {
                  setDisabled(false)
                }
              }}
              onMouseOut={() => {
                setDisabled(true)
              }}
              onFocus={() => {}}
              onBlur={() => {}}
            >
              <span className={courseStudentCasesStyles.classtitle}>提示</span>
            </div>
          }
          centered
          className={courseStudentCasesStyles.initmodal}
          visible={isInitCaseModal}
          onOk={onInitCaseOk}
          okText={'确认'}
          onCancel={onInitCaseCancel}
          bodyStyle={{ height: '112px' }}
          destroyOnClose={true}
          width={285}
          footer={[
            <Button key="return" type="default" onClick={onInitCaseCancel}>
              取消
            </Button>,
            <Button key="submit" type="primary" onClick={onInitCaseOk}>
              确认
            </Button>,
          ]}
          modalRender={(modal) => (
            <Draggable
              disabled={disabled}
              bounds={bounds}
              onStart={(event, uiData) => onStart(event, uiData)}
            >
              <div ref={draggleRef}>{modal}</div>
            </Draggable>
          )}
        >
          <Row
            justify="center"
            align="middle"
            gutter={[32, 32]}
            className={courseStudentCasesStyles.textarea}
          >
            <Col span={24}>
              <div style={{ textAlign: 'center' }}>
                您确认初始化实验<span> {selectedCaseName} </span>吗?{' '}
                <span style={{ color: '#ff0000' }}>
                  初始化后已编辑内容将清空
                </span>
                。
              </div>
            </Col>
          </Row>
        </Modal>
      </ConfigProvider>
    </div>
  )
}

export default CourseMainStudentCases
