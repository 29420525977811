import React, { useEffect, useRef, useState } from 'react'
import {
  Input,
  Table,
  Switch,
  Layout,
  message,
  Card,
  Col,
  Row,
  ConfigProvider,
  Pagination,
  Tabs,
  Button,
  Tag,
  Popconfirm,
  Upload,
  Modal,
  Popover,
} from 'antd'
import { PageHeader } from '../../common/page-header/header'
import { sendHttpPostRequest } from '../../../api/http-request'
import logoSvg from '../../static/image/idclogo.png'
import pekinglogoSvg from '../../static/image/peking.png'
import courseMainStyles from '../course-main.module.less'
import '../course.module.css'
import zhCN from 'antd/lib/locale/zh_CN'
import * as constants from '../../../constants/constants'
import PageFooter from '../../common/page-footer/footer'
import LoginRegisterModal from '../../../component/modal/login-register/login-register'
import { checkUserLogin } from '../../common/user-center/user-info'
import {
  ClockCircleOutlined,
  VideoCameraOutlined,
  CodeOutlined,
  FormOutlined,
  UserOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons'
import CourseMainTeacherMenu from '../menu/course-main-teacher-menu'
import kejianEmpty from '../../../static/image/empty/queshengye-kejian.png'
import formatIcon from '../../../static/image/formatIcon.png'

import slidesStyles from './course-main-teacher-slides.module.less'
import Cookies from 'js-cookie'
import courseCreateStyles from '../course-create.module.less'
import Draggable from 'react-draggable'

export interface Props {
  course_id: any
  is_course_teacher: any
  course?: any
}
/** 教师课程主页面 */
export const CourseMainTeacherSlides: React.FC<Props> = (props) => {
  const { course_id, is_course_teacher, course } = props
  const [courseResult, setCourseResult] = useState([] as any[])
  const [slides, setSlides] = useState([] as any[]) //课程的课件

  const selectedSlideId = React.useRef(null as any) //当前操作的课件
  const changedSlideName = React.useRef(null as any) //当前操作的课件名
  const [isChangeSlideNameModalVisible, setIsChangeSlideNameModalVisible] =
    useState(false) //修改课件名弹出框
  const draggleRef = useRef<HTMLDivElement | null>(null)

  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
  const [disabled, setDisabled] = useState(true)

  const onStart = (event: any, uiData: any) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement
    const targetRect = draggleRef?.current?.getBoundingClientRect()
    if (targetRect) {
      setBounds({
        left: -targetRect?.left + uiData?.x,
        right: clientWidth - (targetRect?.right - uiData?.x),
        top: -targetRect?.top + uiData?.y,
        bottom: clientHeight - (targetRect?.bottom - uiData?.y),
      })
    }
  }

  const querySlides = () => {
    sendHttpPostRequest(
      'course/api/user_query_course_info',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        course_id: course_id,
      },
      (response: any) => {
        message.destroy()
        if (response.code == -100) {
          // 没登录
          document.getElementById('show-login-modal')!.click()
        } else if (response.code == -501) {
          // 没权限
          message.error('权限不足')
        } else if (response.is_ok) {
          setSlides(response.query_info.lecture_list)
        } else {
          message.error(response.message)
        }
      }
    )
  }

  /**
   * 保存课程回调
   * @param response
   */
  const saveCourseInfoCallback = (response: any) => {
    message.destroy()
    if (response.code == -100) {
      // 没登录
      document.getElementById('show-login-modal')!.click()
      // window.location.href = '/login?next=course';
    } else if (response.code == -501) {
      // 没权限
      message.error('权限不足')
      // window.location.href = '/login?next=course';
    } else if (response.is_ok == true) {
      //message.success("课程信息保存成功~")
    } else {
      message.error(response.message)
    }
  }

  const saveCourse = (newSlides: any) => {
    sendHttpPostRequest(
      'course/api/user_update_course',
      {
        name: course?.name,
        course_id: course_id,
        lecture_list: JSON.stringify(newSlides),
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
      },
      saveCourseInfoCallback
    )
  }

  // 上传课件传参
  const uploadLectureData = {
    sessionid: Cookies.get('sessionid'),
    sid: Cookies.get('sessionid'),
    course_id: course_id,
  }

  // 上传课件
  const uploadLectureProps = {
    accept: '.ppt,.pptx,.pdf,.doc,.docx,.zip',
    name: 'data_file',
    multiple: false,
    showUploadList: false,
    data: uploadLectureData,
    action: constants.BACKEND_API_URL + 'course/api/course_upload_lecture/',
    onChange(info) {
      const { status } = info.file
      if (status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (status === 'done') {
        message.destroy()
        if (info.file.response.is_ok) {
          message.success(`文件${info.file.name}上传成功！`)
          const newLectureList = [...slides]
          newLectureList.push({
            id: info.file.response.id,
            name: info.file.response.url.split('/')[
              info.file.response.url.split('/').length - 1
            ],
            url: info.file.response.url,
            open: 0,
          })
          console.log(newLectureList)
          setSlides(newLectureList)
          saveCourse(newLectureList)
        } else {
          if (info.file.response.code == -100) {
            // 未登录
            // const localPath = window.location.href.split(window.location.host)[1];
            document.getElementById('show-login-modal')!.click()
            // window.location.href = `/login?next=course`;
          } else if (info.file.response.code == -501) {
            // 权限不足
            message.error('权限不足')
            // const localPath = window.location.href.split(window.location.host)[1];
            // window.location.href = `/login?next=course`;
          } else {
            message.error(info.file.response.message)
          }
        }
      } else if (status === 'error') {
        message.error(`文件${info.file.name}上传失败了:(`)
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files)
    },
  }

  // 点击下载
  const onDownloadSlidesClick = (url: any, event: any) => {
    event.preventDefault()
    window.open(url?.startsWith('http') ? url : constants.BACKEND_URL + url)
  }

  /** 修改了课件名 */
  const onSlideNameChange = (e: any) => {
    changedSlideName.current = e.target.value
  }
  const handleChangeSlideNameOk = () => {
    //确认修改了课件名
    setIsChangeSlideNameModalVisible(false)
    const newLectureList = [...slides]
    newLectureList.map((item) => {
      if (item.id == selectedSlideId.current) {
        item.name = changedSlideName.current
      }
    })
    console.log(newLectureList)
    setSlides(newLectureList)
    saveCourse(newLectureList)
  }

  // 点击开关
  const onSwitchClick = (slidesId: number, openStatus: number) => {
    const newLectureList = [...slides]
    newLectureList.map((item) => {
      if (item.id == slidesId) {
        item.open = openStatus == 0 ? 1 : 0
      }
    })
    setSlides(newLectureList)
    saveCourse(newLectureList)
  }

  const onDeleteClick = (slidesId: number) => {
    // console.log(slidesId)
    const newLectureList = slides.filter((item) => item.id !== slidesId)
    setSlides(newLectureList)
    deleteSlides(slidesId)
  }

  const deleteSlides = (slidesId: any) => {
    sendHttpPostRequest(
      'course/api/user_delete_course_lecture',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        lecture_id: slidesId,
        course_id: course_id,
      },
      (response: any) => {
        if (response.code == -100) {
          // 没登录
          document.getElementById('show-login-modal')!.click()
          //window.location.href = '/login?next=course-main-teacher?course_id=' + course_id;
        } else if (response.code == -501) {
          // 没权限
          message.error('权限不足')
          // window.location.href = '/login?next=course';
        } else if (response.is_ok == true) {
          message.success('课件删除成功~')
        } else {
          message.error(response.message)
        }
      }
    )
  }

  // 渲染操作
  const renderActions = (text: any, record: any) => {
    return (
      <div>
        {is_course_teacher && (
          <Switch
            style={{ marginRight: 10, width: 40, height: 18 }}
            size="small"
            checked={record.open == 1 ? true : false}
            onClick={onSwitchClick.bind(this, record.id, record.open)}
          />
        )}
        <Button
          onClick={onDownloadSlidesClick.bind(this, record.url)}
          className={slidesStyles.actionBtn}
          style={{ borderRadius: 20, marginRight: 5 }}
          size="small"
          type="link"
        >
          {' '}
          下载{' '}
        </Button>
        {is_course_teacher && (
          <Popconfirm
            placement="top"
            title={'确认删除课件"' + record.name + '"吗？'}
            onConfirm={onDeleteClick.bind(this, record.id)}
            okText="确认"
            cancelText="取消"
          >
            <Button
              className={slidesStyles.actionBtn}
              style={{ borderRadius: 20, marginRight: 5 }}
              size="small"
              type="link"
            >
              {' '}
              删除{' '}
            </Button>
          </Popconfirm>
        )}
        {/*is_course_teacher && <Button onClick={()=>{setIsChangeSlideNameModalVisible(true); selectedSlideId.current = record.id;changedSlideName.current=record.name}} className={slidesStyles.actionBtn} style={{borderRadius:20,marginRight:5}} size='small' type='link'> 改名 </Button>*/}
      </div>
    )
  }
  const slidesCols: any = [
    {
      title: '序号',
      dataIndex: 'id',
      key: 'id',
      align: 'left',
      width: 80,
      render: (text, record, index) => slides.indexOf(record) + 1,
    },
    {
      title: '课件',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: 350,
      render: (text: any, record: any) => {
        return <div className={slidesStyles.slideName}> {text} </div>
      },
    },
    {
      title: '操作',
      dataIndex: 'actions',
      key: 'actions',
      width: 120,
      ellipsis: false,
      align: 'left',
      //fixed: 'right',
      render: renderActions,
    },
  ]

  useEffect(() => {
    // 示例代码请勿删除
    /*      sendHttpPostRequest(
            'case/api/get_case_list',
            {page: 1, pagesize: 16, type: 1, query: ""},
            renderCourse
        );*/
    querySlides()
  }, [])

  return (
    <div className={courseMainStyles.tabpaneWrapper}>
      <Row align="middle" style={{ marginTop: -10, marginBottom: 4 }}>
        <Col span={12} style={{ textAlign: 'left' }}></Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Popover
            overlayStyle={{ marginTop: -5 }}
            content={
              <div>
                请选择pptx， pdf，docx格式文件。
                <br />
                文件大小不得超过50M。
              </div>
            }
            placement="bottom"
            title={''}
            trigger="hover"
          >
            <span style={{ cursor: 'pointer', marginRight: 8, fontSize: 12 }}>
              <img
                src={formatIcon}
                style={{ width: 16, height: 16, marginTop: -2 }}
                alt=""
              />
              文件格式
            </span>
          </Popover>
          {is_course_teacher && (
            <Upload {...uploadLectureProps}>
              <Button
                style={{ marginBottom: 10, width: 80, borderRadius: 20 }}
                type="primary"
              >
                {' '}
                + 添加
              </Button>
            </Upload>
          )}
        </Col>
      </Row>

      <div className={slidesStyles.tableWrapper}>
        {slides.length != 0 && (
          <ConfigProvider locale={zhCN}>
            <Table
              dataSource={slides}
              columns={slidesCols}
              size="small"
              rowKey={(record) => record.id}
              pagination={{
                position: ['bottomCenter'],
                pageSize: 10,
                size: 'default',
                hideOnSinglePage: true,
              }}
              // onChange={onTableChange}
            />
          </ConfigProvider>
        )}

        {slides.length == 0 && (
          <div style={{ textAlign: 'center', paddingTop: 20 }}>
            <img src={kejianEmpty} width={120} height={120} />
            <div style={{ color: '#999', marginTop: 10 }}>
              您还没有添加课件哟~
            </div>
          </div>
        )}
      </div>
      <ConfigProvider locale={zhCN}>
        <Modal
          title={
            <div
              style={{
                width: '100%',
                cursor: 'move',
              }}
              onMouseOver={() => {
                if (disabled) {
                  setDisabled(false)
                }
              }}
              onMouseOut={() => {
                setDisabled(true)
              }}
              onFocus={() => {}}
              onBlur={() => {}}
              // end
            >
              修改课件名称
            </div>
          }
          visible={isChangeSlideNameModalVisible}
          onOk={handleChangeSlideNameOk}
          onCancel={() => {
            setIsChangeSlideNameModalVisible(false)
          }}
          bodyStyle={{ height: '120px' }}
          destroyOnClose={true}
          width={350}
          modalRender={(modal) => (
            <div className={courseCreateStyles.miniModal}>
              <Draggable
                disabled={disabled}
                bounds={bounds}
                onStart={(event, uiData) => onStart(event, uiData)}
              >
                <div ref={draggleRef}>{modal}</div>
              </Draggable>{' '}
            </div>
          )}
        >
          <Input
            defaultValue={changedSlideName.current}
            onChange={onSlideNameChange}
          ></Input>
        </Modal>
      </ConfigProvider>
    </div>
  )
}

export default CourseMainTeacherSlides
