import React, { useEffect, useRef, useState } from 'react'

import { Button, Col, Input, Menu, message, Row, Select, Tabs } from 'antd'

import chatStyle from './chatroom.module.less'

import localStorage from 'localStorage'

import MarkDownEditor, {
  mdParser,
} from '../../../../component/MarkDownEditor/MarkDownEditor'

import Cookies from 'js-cookie'
import * as constants from '../../../../constants/constants'

import { checkUserLogin } from '../../../common/user-center/user-info'
import { sendHttpPostRequest } from '../../../../api/http-request'

import katex from 'katex'
import MarkdownItKatex from '@iktakahiro/markdown-it-katex'
import {
  BACKEND_API_URL,
  WS_BACKEND_URL,
} from '../../../../constants/constants'
import { useHistory } from 'react-router'

interface ChatProps {
  course_id: any
  teacherInfo: any
}
const emoji = require('markdown-it-emoji')

export const ChatRoom: React.FC<ChatProps> = (props) => {
  mdParser.use(MarkdownItKatex, { katex })
  mdParser.use(emoji)
  const { course_id, teacherInfo } = props
  const history = useHistory()

  const user_info = JSON.parse(localStorage.getItem('user_info'))

  const [userId, setUserId] = useState(0)

  const [studentList, setStudentList] = useState<any>([
    /*     {
      avatar:
        'http://127.0.0.1:9099/media/course_cover/4/%E5%8D%8E%E5%BC%BA.jpeg',
      userId: 1,
      username: '刘瑞宇',
      is_online: true,
    },
    {
      avatar:
        'http://127.0.0.1:9099/media/course_cover/4/%E5%8D%8E%E5%BC%BA.jpeg',
      userId: 2,
      username: 'wangzining',
      is_online: false,
    },
    {
      avatar:
        'http://127.0.0.1:9099/media/course_cover/4/%E5%8D%8E%E5%BC%BA.jpeg',
      userId: 3,
      username: 'zhangchengchen',
      is_online: false,
    }, */
  ])
  const memberList = useRef([] as any)
  // const [onlineCount, setOnlineCount] = useState<number>(0)
  const onlineCount = useRef(0)
  const [msgList, setMsgList] = useState<any>([
    // {
    //   id: 1,
    //   user: '刘瑞宇',
    //   is_my: false,
    //   send_time: '2023-05-19 15:21',
    //   avatar:
    //     'http://127.0.0.1:9099/media/course_cover/4/%E5%8D%8E%E5%BC%BA.jpeg',
    //   msg: '上节课函数参数那个知识点我没太听懂，有没有大佬可以指导一下？',
    // },
    // {
    //   id: 2,
    //   user: 'wangzining',
    //   is_my: false,
    //   send_time: '2023-05-19 15:21',
    //   avatar:
    //     'http://127.0.0.1:9099/media/course_cover/4/%E5%8D%8E%E5%BC%BA.jpeg',
    //   msg: '其实那块不难，只要理解了定义，然后做一做习题巩固一下就好了，有什么问题你可以问我。',
    // },
    // {
    //   id: 3,
    //   user: 'zhangchengchen',
    //   is_my: true,
    //   send_time: '2023-05-19 15:21',
    //   avatar:
    //     'http://127.0.0.1:9099/media/course_cover/4/%E5%8D%8E%E5%BC%BA.jpeg',
    //   msg: '我也不太懂，特别熟函数参数的定义那块，理解起来有点费劲。函数的参数分为形参(定义函数时的参数变量) 和实参(调用函数时使用的参数变量)。形参就是形式上的参数，可以理解为未知变量X，没有实际的值，通过赋值后才有意义，相当于变量;实参就是实际意义上的参数，是一个实际存在的参数，可以是字符串或是数字等。哪位大佬通俗易懂的解释一下？',
    // },
  ])
  const chatHistoryMsgList = useRef([] as any)
  const [chatContent, setChatContent] = useState('')
  // let userInfo = localStorage.getItem("user_info")
  const msgBox = useRef<any>()
  const userInfo = useRef(JSON.parse(localStorage.getItem('user_info')!))

  const [ws, setWs] = useState<WebSocket>()

  /*
    websocket 相关
  **/

  useEffect(() => {
    // console.log(JSON.parse(JSON.stringify(userInfo)));
    queryChatHistory()

    initWs()
    // scrollBottm()
  }, [])

  const queryChatHistory = () => {
    if (!checkUserLogin()) {
      document.getElementById('show-login-modal')!.click()
    } else {
      sendHttpPostRequest(
        'chatroom/api/query_chat_history/',
        {
          sessionid: Cookies.get('sessionid'),
          course_id: course_id,
        },
        queryChatHistoryCallBack
      )
    }
  }

  /**
   * 查询历史聊天记录回调
   * @param response
   */
  const queryChatHistoryCallBack = (response: any) => {
    // message.destroy();
    if (response.code == -100) {
      // 没登录
      document.getElementById('show-login-modal')!.click()
    } else if (response.code == -501) {
      // 没权限
      message.error('权限不足')
    } else if (response.is_ok == true) {
      // message.success("删除作业成功~");
      console.log(response)
      if (response.query_list) {
        chatHistoryMsgList.current = response.query_list
        //msgList.push(response.query_list)
        setMsgList([...response.query_list])
      }
    } else {
      message.error('查询聊天历史记录失败~')
    }
  }

  const initWs = () => {
    let user_id
    if(localStorage.getItem('user_info')){
      user_id = JSON.parse(localStorage.getItem('user_info')).login_user_id
    }
    if (!user_id) {
      document.getElementById('show-login-modal')!.click()
    }

    if (window.WebSocket) {
      // let ws = new WebSocket("ws://172.23.12.53:8181");
      // let ws = new WebSocket('ws://127.0.0.1:9099/ws/chat/c01/')
      let ws = new WebSocket(
        WS_BACKEND_URL + '/ws/chat/c' + course_id + '/s' + user_id + '/'
      )

      setWs(ws)

      ws.onopen = function (e) {
        console.log('服务器连接成功')
      }
      ws.onclose = function (e) {
        console.log('服务器连接关闭')
      }
      ws.onerror = function () {
        console.log('服务器连接出错')
      }
      ws.onmessage = function (e) {
        //接收服务器返回的数据
        
        let resData: any = JSON.parse(e.data)
        console.log("resData",resData);
        if (resData.message) {
          msgList.push(resData)
          setMsgList([...chatHistoryMsgList.current, ...msgList])
        }
        if (resData.room_list) {
          //studentList.push(resData.room_list)
          //setStudentList([...studentList])
          setStudentList(resData.room_list)
          memberList.current = resData.room_list
          onlineCount.current = resData.online
        }
        // setOnlineCount(resData.online)
        // if (resData.funName == 'userCount') {
        //   // console.log(resData.users);
        //   // setUserCount(resData.users)
        //   // setMsgList([...resData.chat])
        //   // msgLIstRef.current = resData.cha          // _this.count = resData.users;
        //   // _this.list = resData.chat;
        //   // console.log("resData.chat",resData.chat);
        // } else {
        //   // _this.list = [
        //   //   ..._this.list,
        //   //   { userId: resData.userId, content: resData.msg }
        //   // ];
        //   // console.log("resData",resData);
        //   // msgList.push({ userId: resData.userId, content: resData.content })
        //   // console.log("msgList",msgList);
        //   // setMsgList(JSON.parse(JSON.stringify(msgLIstRef.current.concat(msgList))))
        // }
      }
    }
  }
  //滚动条到底部
  const scrollBottm = () => {
    let el = msgBox.current
    console.log(el)
    el.scrollTop = el.scrollHeight
  }
  //发送聊天信息
  // const sendText = () => {
  //   if (!chatContent) {
  //    return;
  //   }
  // let params = {
  //   userId: 1 , //userId,
  //   msg: "test", // contentText
  // };

  //         // _this.count = resData.users;
  //         // _this.list = resData.chat;
  //         console.log("resData.chat",resData.chat);
  //       } else {
  //         // _this.list = [
  //         //   ..._this.list,
  //         //   { userId: resData.userId, content: resData.msg }
  //         // ];
  //         console.log("resData",resData);
  //         msgList.push({ userId: resData.userId, content: resData.content })
  //         console.log("msgList",msgList);
  //         setMsgList(JSON.parse(JSON.stringify(msgLIstRef.current.concat(msgList))))
  //       }
  //     }
  //   }
  // }
  //  //滚动条到底部
  //  const scrollBottm = ()=> {
  //   let el = msgBox.current
  //   console.log(el);
  //   el.scrollTop = el.scrollHeight;
  // }
  //发送聊天信息
  const sendText = () => {
    if (!chatContent) {
      return
    }
    let params = JSON.stringify({
      message: {
        course_id: course_id,
        user_id: userInfo.current?.login_user_id,
        msg: chatContent,
        avatar: userInfo.current?.login_user_avatar,
        user: userInfo.current?.login_user_real_name,
      },
    })
    ws!.send(params) //调用WebSocket send()发送信息的方法
    setChatContent('')
    console.log('发送成功！')
    setTimeout(() => {
      scrollBottm()
    }, 100)
  }
  const chatEditorChange = ({ text }) => {
    setChatContent(text)
  }

  return (
    <div className={chatStyle.chatroomContain}>
      <div className={chatStyle.leftUserPanel}>
        <Row align="middle" justify="start" className={chatStyle.leftHead}>
          <Col>
            <div className={chatStyle.avatarContain}>
              <img
                className={chatStyle.avatar}
                src={constants.BACKEND_API_URL + teacherInfo.teacher_avatar}
                alt=""
              />
            </div>
          </Col>
          <Col style={{ marginLeft: '10px' }}>{teacherInfo.teacher_real_name ? teacherInfo.teacher_real_name : teacherInfo.teacher}</Col>
        </Row>
        <div className={chatStyle.stuTitle}>
          学员(<span className={chatStyle.heighlightText}>{onlineCount.current}</span>/
          {memberList.current.length})
        </div>
        <div className={chatStyle.stuList}>
          {studentList?.map((stu: any, sIndx) => (
            <Row
              key={stu.user_id}
              align="middle"
              justify="start"
              className={chatStyle.stuItem}
            >
              <Col flex={'20px'}>{sIndx + 1} </Col>
              <Col flex={'40px'} style={{ position: 'relative' }}>
                <img
                  className={chatStyle.avatar}
                  src={constants.BACKEND_API_URL + (stu.avatar ? stu.avatar : 'static/image/avatar/default_avatar.png')}
                  alt=""
                />
                {stu.is_online && <div className={chatStyle.onlineBadge}></div>}
              </Col>
              <Col flex={'auto'}>{stu.user} </Col>
            </Row>
          ))}
        </div>
      </div>
      <div className={chatStyle.rightChatPanel}>
        <div className={chatStyle.chatHeader}>讨论</div>
        <div className={chatStyle.chatArea} ref={msgBox}>
          {msgList?.map((msg: any, mIdx) => {
            if (msg.message?.user_id !== userInfo.current?.login_user_id) {
              return (
                <div key={mIdx} className={chatStyle.chatItem}>
                  <Row
                    align="middle"
                    justify="start"
                    className={chatStyle.chatUser}
                  >
                    <Col flex={'40px'}>
                      <img
                        className={chatStyle.avatar}
                        src={constants.BACKEND_API_URL + msg.message?.avatar}
                        alt=""
                      />
                    </Col>
                    <Col>{msg.message?.user}</Col>
                    <Col style={{ marginLeft: '10px' }} flex={'140px'}>
                      {msg.message?.time}
                    </Col>
                  </Row>
                  <div
                    className={chatStyle.contentContain}
                    dangerouslySetInnerHTML={{
                      __html: mdParser.render(String(msg.message?.msg)),
                    }}
                  ></div>
                </div>
              )
            } else {
              return (
                <div key={mIdx} className={chatStyle.chatItem}>
                  <Row
                    align="middle"
                    justify={'end'}
                    className={chatStyle.chatUser}
                  >
                    <Col flex={'40px'} order={3} style={{ marginLeft: '5px' }}>
                      <img
                        className={chatStyle.avatar}
                        src={constants.BACKEND_API_URL + msg.message?.avatar}
                        alt=""
                      />
                    </Col>
                    <Col order={2}>{msg.message.user}</Col>
                    <Col
                      order={1}
                      // style={{ marginRight: '10px' }}
                      flex={'135px'}
                    >
                      {msg.message?.time}
                    </Col>
                  </Row>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: mdParser.render(String(msg.message?.msg)),
                    }}
                    className={chatStyle.myContentContain}
                  ></div>
                </div>
              )
            }
          })}
        </div>
        <div className={chatStyle.chatEditorContain}>
          <MarkDownEditor
            value={chatContent}
            onChange={chatEditorChange}
            height={150}
            toolbar={[
              // "emoji",
              'control-enter']}
            autoFocus={true}
          />
          <Row align="middle" justify="end" className={chatStyle.submitRow}>
            <Col className={chatStyle.tipText}>Enter换行，Ctrl+Enter发送</Col>
            <Col>
              <Button id="submit_chat" onClick={sendText} type="primary">
                发送
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default ChatRoom
