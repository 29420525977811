import { Layout, Menu, Breadcrumb } from 'antd'
import { PageFooter } from '../common/page-footer/footer';
import {
    AppstoreOutlined,
    CodeOutlined,
    DatabaseOutlined,
    DeploymentUnitOutlined,
    DesktopOutlined,
    ExperimentOutlined,
    FileDoneOutlined,
    FilePptOutlined,
    MenuOutlined,
    BankOutlined,
    VideoCameraOutlined,
    SnippetsOutlined,
    CalculatorOutlined,
    SolutionOutlined,
    UserOutlined,
    EditOutlined,
    PhoneOutlined,
    AudioOutlined,
    UserSwitchOutlined,
} from '@ant-design/icons'
import { useState } from 'react';
import React from 'react';
import oamStyles from './oam-sider.module.less';
import SubMenu from 'antd/lib/menu/SubMenu';

const { Header, Content, Sider } = Layout;
import logoSvg from '../../static/image/oamlogo.svg';
import TeacherAuthenticate from '../../component/modal/teacher-authenticate/teacher-authenticate';

export interface Props {
  keys: any,
  openKeys:any //默认打开的子目录
}

export const OAMSider: React.FC<Props> = (props:Props) => {
    const { keys,openKeys } = props;
    const [collapsed,setCollapsed] = useState(false);
    const [selectedKey, setSelectedKey] = useState('2');

    const handleClick = (e: any) => {
      setSelectedKey(e.key)
      if (e.key == '1') {
        window.location.href = '/oam_user_list';
      } else if (e.key == '11') {
        window.location.href = '/oam_user_stat';
      } else if (e.key == '12') {
        window.location.href = '/oam_user_list';
      } else if (e.key == '13') {
        window.location.href = '/oam_user_apply';
      } else if (e.key == '14') {
        window.location.href = '/oam_teacher_apply';
      } else if (e.key == '2') {//机构
        window.location.href = '/oam_institution_list';
      } else if (e.key == '31') {//课程管理
        window.location.href = '/oam_course_list';
      } else if (e.key == '32') {//大纲
        window.location.href = '/oam_menu_list';
      } else if (e.key == '33') {//知识点
        window.location.href = '/oam_module_list';
      } else if (e.key == '34') {//视频
        window.location.href = '/oam_video_list';
      } else if (e.key == '35') {//课件
        window.location.href = '/oam_slides_list';
      } else if (e.key == '4') {//实验
        window.location.href = '/oam_case_list';
      }else if (e.key == '5') {//题目
        window.location.href = '/oam_exercise_list';
      } else if (e.key == '6') {//作业
        window.location.href = '/oam_test_list';
      } else if (e.key == '7') {//考试
        window.location.href = '/oam_exam_list';
      } else if (e.key == '8') {//数智讲堂
        window.location.href = '/oam_lecture_list';
      } else if (e.key == '9') {//banner
        window.location.href = '/oam_lecture_banner_list';
      }  
      else  {
        window.location.href = '/oam_user_list';
      }
    }

    const onCollapse = (collapsed:boolean) => {
      setCollapsed(collapsed);
    }

    const handleMainPage = () => {
      window.location.href = '/';
    }

    return (
        <Sider width={200} collapsible collapsed={collapsed} onCollapse={onCollapse}>
            <TeacherAuthenticate></TeacherAuthenticate>
            <img
            onClick={handleMainPage}
            title="爱数课"
            alt="logo"
            src={logoSvg}
            className={oamStyles.oam_logo}
          ></img>

          <Menu style={{marginTop:20}} theme="dark" defaultOpenKeys={openKeys} defaultSelectedKeys={keys} mode="inline" onClick={handleClick}>

          <SubMenu key="sub-user" title="用户管理">
            <Menu.Item key="11" icon={<SolutionOutlined />}>用户统计</Menu.Item>
            <Menu.Item key="12" icon={<UserOutlined />}>用户管理</Menu.Item>
            <Menu.Item key="13" icon={<PhoneOutlined />}>申请信息</Menu.Item>
            <Menu.Item key="14" icon={<UserSwitchOutlined />}>教师申请</Menu.Item>
          </SubMenu>

            <Menu.Item key="2" icon={<BankOutlined />}>
              机构
            </Menu.Item>

          <SubMenu key="sub-course" title="课程管理">
            <Menu.Item key="31" icon={<FileDoneOutlined />}>
              课程
            </Menu.Item>

            <Menu.Item key="32" icon={<MenuOutlined />}>
              大纲
            </Menu.Item>

            <Menu.Item key="33" icon={<DeploymentUnitOutlined />}>
              知识点
            </Menu.Item>

            <Menu.Item key="34" icon={<VideoCameraOutlined />}>
              视频
            </Menu.Item>

            <Menu.Item key="35" icon={<FilePptOutlined />}>
              课件
            </Menu.Item>

          </SubMenu>

          <Menu.Item key="4" icon={<ExperimentOutlined />}>
              实验
          </Menu.Item>

          <Menu.Item key="5" icon={<CodeOutlined />}>
              题目
          </Menu.Item>

            <Menu.Item key="6" icon={<EditOutlined />}>
              作业
            </Menu.Item>

            <Menu.Item key="7" icon={<CalculatorOutlined />}>
              考试
            </Menu.Item>

            <SubMenu key="sub-lecture" title="数智讲堂">
              <Menu.Item key="8" icon={<AudioOutlined />}>
                讲座
              </Menu.Item>
              <Menu.Item key="9" icon={<DesktopOutlined />}>
                Banner
              </Menu.Item>
            </SubMenu>
          </Menu>
        </Sider>

    )

}

export default OAMSider
