import React, {useEffect, useRef, useState} from "react";
import {Tooltip,Progress,Tree,Drawer,Layout, message, Card, Col, Row, ConfigProvider, Pagination, Tabs, Input, Upload, Button, Popconfirm, Select } from 'antd';
import { sendHttpPostRequest } from "../../api/http-request";
import logo from '../../static/image/college/logo.png';
import Cookies from 'js-cookie';
import zhCN from 'antd/lib/locale/zh_CN'
import * as constants from '../../constants/constants';
import LoginRegisterModal from "../../component/modal/login-register/login-register";
import UserInfo, {checkUserLogin} from "../common/user-center/user-info";
import { Content, Footer } from "antd/lib/layout/layout";
import collegeStyles from './college.module.less';
import courseIcon from '../../static/image/college/course.png';
import caseIcon from '../../static/image/college/case.png';
import projectIcon from '../../static/image/college/project.png';
import projectBg from '../../static/image/college/finance/projectBg.png';
import course1 from '../../static/image/college/finance/9.png';
import course2 from '../../static/image/college/finance/4.png';
import course3 from '../../static/image/college/finance/6.png';
import course4 from '../../static/image/college/finance/8.png';
import course5 from '../../static/image/college/finance/7.png';
import case1 from '../../static/image/college/finance/2-1.png';
import case2 from '../../static/image/college/finance/2-2.png';
import case3 from '../../static/image/college/finance/2-3.png';
import case4 from '../../static/image/college/finance/2-4.png';
import case5 from '../../static/image/college/finance/2-5.png';

// 通过路由组件额外添加参数信息到表中
import { Link } from "react-router-dom";
import PageFooter from "./college-footer";
const { Header } = Layout;

interface Props  {
}

export const CollegeFinance: React.FC<Props> = (props) => {
    const courseImgs = [
        {
            src: course1,
            title: '',
            id: 167,
        },
        {
            src: course2,
            title: '',
            id: 167,
        },
        {
            src: course3,
            title: '',
            id: 167,
        },
        {
            src: course4,
            title: '',
            id: 167,
        },
        {
            src: course5,
            title: '',
            id: 167,
        },
    ];
    const caseImgs = [
        {
            src: case1,
            title: '利用Python爬取股票交易数据',
            url: "case-run?id=51&token=8f65f212661420c26878cb57a1665376"
        },{
            src: case2,
            title: '金融贷款数据可视化',
            url: "case-run?id=599&token=184bd465de483199567e96dc20aaff23"
        },{
            src: case3,
            title: '非洲国家金融危机数据分析 ',
            url: "case-run?id=597&token=d9848194058cc4b30fbda8f7b93588cc"
        },{
            src: case4,
            title: '金融交易反欺诈案例研究',
            url: "case-run?id=600&token=08f3ddbe0d4e8146b74ed1161c39c957"
        },{
            src: case5,
            title: '台湾地区信用卡客户贷款违约预测',
            url: "case-run?id=52&token=df80d63cdf9bbe4f73ee88f5908bcfee"
        },
    ];

    const project ={
        name: '个人信用风险评估项目',
        description: '2007-2008年的全球金融危机凸显了透明度和严密性在银行业务中的重要性。由于信贷供应受到了限制，所以银行正日益紧缩其贷款体系，转向机器学习来更准确地识别高风险贷款。通过构建自动化的信用评分模型，以在线方式进行即时的信贷审批能够为银行节约很多人工成本。',
        ability: '逻辑回归;XGBoost;二分类模型评估;特征工程',
        industry_name: '金融',
        practice_duration: '8小时',
        teaching_duration: '16课时',
    }

    const onStartProjectClick = () => {
        window.open('http://idatacoding.cn/project_main?project_id=2');
    }

    return (
        <Layout style={{minHeight: 750,backgroundColor: '#fff'}}>
            <Header style={{backgroundColor:'#fff',boxShadow:'0px 0px 10px rgba(0, 0, 0, 0.16)',height:80,paddingLeft:80,paddingTop:6}}>
                <Row justify="center" align="middle" wrap={true}>
                    <Col>
                        <Link to={"/college-project"}>
                            <img
                                key={"0"}
                                id={"menu-0"}
                                title="黄河交通学院"
                                alt="logo"
                                src={logo}
                                width={300}
                            ></img>
                        </Link>
                    </Col>
                    <Col flex="150px" style={{color:'#155ba5',fontSize:22,fontWeight:700}}>数字金融</Col>
                    <Col flex="auto"></Col>
                    <Col><UserInfo/></Col>
                </Row>
            </Header>

            <div className={collegeStyles.mainDiv}>
                <div className={collegeStyles.course}>
                    <div style={{height:60,paddingTop:25,fontSize:16,color:'#333'}}>
                        <img src={courseIcon} alt=""  className={collegeStyles.icon}/>
                        课程</div>
                    <div>
                        {courseImgs.map((courseImg)=>(
                            <Link to={"/course-main?course_id=" + courseImg.id} target={"_blank"} key={courseImg.src}>
                                <img src={courseImg.src} alt="" key={courseImg.src} className={collegeStyles.courseImg}/>
                            </Link>
                        ))                           
                        }
                    </div>
                </div>

                <div className={collegeStyles.case}>
                    <div style={{height:60,paddingTop:25,fontSize:16,color:'#333'}}>
                        <img src={caseIcon} alt=""  className={collegeStyles.icon}/>
                        实战案例
                    </div>
                    <div>
                        {caseImgs.map((caseImg)=>(
                            <Link to={caseImg.url} key={caseImg.src} target={"_blank"}>
                                <div key={caseImg.src} style={{backgroundImage:`url(${caseImg.src})`}} className={collegeStyles.caseDiv}>
                                    {caseImg.title}
                                </div>
                            </Link>
                        ))                           
                        }
                    </div>
                </div>

                <div className={collegeStyles.project}>
                    <div style={{height:60,paddingTop:25,fontSize:16,color:'#333'}}>
                        <img src={projectIcon} alt=""  className={collegeStyles.icon}/>
                        实战项目
                    </div>
                    <div
                                className={collegeStyles.projectCard}
                                style={{background:`url(${projectBg}) center center no-repeat`,backgroundSize:'cover'}}
                                
                            >
                                <div className={collegeStyles.projectContent}>
                                    <div className={collegeStyles.projectTitle}>
                                        {project.name}
                                    </div>
                                    <div className={collegeStyles.projectDescription} title={project.description}>{project.description}</div>
                                    <Row wrap={false} align="middle" justify="start" gutter={[12,12]} style={{overflow:"hidden"}}>
                                        {project.ability?.split(";")?.map((skill,skillInd)=> {
                                            return <Col key={skillInd} className={collegeStyles.list}>{skill}</Col>
                                        })
                                        }
                                    </Row>
                                    <Row wrap={false} align="middle" justify="start" gutter={[12,12]} style={{marginTop:40,position:'relative'}} className={collegeStyles.infoDiv}>
                                        <Col  style={{width:100,height:50,color:'#fff!important',marginRight:20,borderRight: '1px solid rgba(255, 255, 255, 0.2)'}}>
                                                <div>行业</div>
                                                <div style={{fontSize:18,textOverflow: "ellipsis"}}>{project.industry_name}</div>
                                        </Col>

                                        <Col  style={{width:100,height:50,color:'#fff!important',marginRight:20,borderRight: '1px solid rgba(255, 255, 255, 0.2)'}}>
                                                <div>实践课时</div>
                                                <div style={{fontSize:18}}>{project.practice_duration}</div>
                                        </Col>

                                        <Col style={{width:100,height:50,color:'#fff!important',marginRight:20,borderRight: '1px solid rgba(255, 255, 255, 0.2)'}}>
                                                <div>教学课时</div>
                                                <div style={{fontSize:18}}>{project.teaching_duration}</div>
                                        </Col>
                                    </Row>
                                </div>
                                
                                <div className={collegeStyles.startBtn} style={{transform: "translate(-50%,0)", position:'absolute',left:'50%',bottom:40,display:'none',zIndex:10}}>
                                    <Button onClick={onStartProjectClick} style={{borderRadius:27,backgroundColor:'#fff',width:120,height:40,color:'#3b6d74'}}>
                                        点击开始
                                    </Button>
                                </div>
                            </div>
                    
                </div>
            </div>

            <PageFooter/>
        </Layout>

    )

}

export default CollegeFinance

