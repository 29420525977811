import React, {useEffect, useRef, useState} from "react";
import {Steps,Input,Layout, message,Row, ConfigProvider, Tabs, Button, Space, Modal, Col, Pagination, Select, Table, Popconfirm, Upload, Radio, Popover } from 'antd';
import { sendHttpPostRequest } from "../../api/http-request";
import courseCreateStyles from "./course-create.module.less";

import courseEditKnowlegeStyles from "./course-edit-knowledge.module.less"
import moduleStyles from "../oam/module/module.module.less";
import "./course.module.css";
import zhCN from 'antd/lib/locale/zh_CN'
import * as constants from '../../constants/constants';
import LoginRegisterModal from "../../component/modal/login-register/login-register";
import {checkUserLogin} from "../common/user-center/user-info";
import {PlayCircleOutlined,SearchOutlined,UpOutlined,DownOutlined,FileSearchOutlined,DeleteOutlined,ClockCircleOutlined,ProfileOutlined,VideoCameraOutlined,CodeOutlined,FormOutlined,UserOutlined,UploadOutlined,PlusSquareOutlined } from '@ant-design/icons';


import Draggable from 'react-draggable';
import Cookies from "js-cookie";
import ExerciseCard from "../exercise/exercise-card";

const {Step} = Steps
const {TextArea} = Input 
const {TabPane} = Tabs
const { Search } = Input
const { Option } = Select;


interface moduleInfo {
  id: number,
  name: string,
  description: string,
  lecture_url: string,
  video_url: string,
  practice: any,
  exam: any,
  case: any
}

const typeMap = new Map([[1, '实操题'], [2, '单选题'], [3, '多选题'],[4, '排序题'], [5, '主观题']]);
const difficultyMap = new Map([[1, '容易'], [2, '中等'], [3, '困难']]);


export interface Props {}
/** 教师创建知识点 */
export const CourseCreateKnowledge: React.FC<Props> = (props) => {
  const moduleId = parseInt(window.location.search.split("module_id=")[1]);  // moduleId
  //const courseId = parseInt(window.location.search.split("course_id=")[1]);  // courseId
  const [module, setModule] = useState((null as unknown) as moduleInfo);  // 知识点对象
  const [moduleType, setModuleType] = useState(1);//1视频 2 课件
  const [moduleResult, setModuleResult] = useState([] as any[]);  // 当前显示的结果页
  const [deleteKnowledgeVisible, setDeleteKnowledgeVisible] = useState(false);
  const [addExerciseVisible, setAddExerciseVisible] = useState(false);
  
  const selectedCaseRowKeys = React.useRef([] as any); //选择的实验
  const selectedExercises = React.useRef([] as any); //选择的练习题
  const [moduleExercises, setModuleExercises] = useState([] as any[]);//当前知识点的练习题列表

  const selectedTestExamExercises  = React.useRef([] as any[]); //选择的作业考试题
  const [moduleTestExamExercises, setModuleTestExamExercises] = useState([] as any[]);//当前知识点的作业考试题列表

  const [addCaseVisible, setAddCaseVisible] = useState(false);
  const [exerciseResult, setExerciseResult] = useState([] as any[]);
  const [exercise, setExercise] = useState([] as any[]);
  const [testexam, setTestexam] = useState([] as any[]);
  const [addTestexamVisible, setTestexamVisible] = useState(false);
  const [testexamResult, setTestexamResult] = useState([] as any[]);
  const [disabled, setDisabled] = useState(true);
  
  const [moduleName, setModuleName] = useState(null as any);//知识点名称
  const [moduleCase, setModuleCase] = useState(null as any);//知识点的实验
  const [moduleLectureUrl, setModuleLectureUrl] = useState(null as any);//pdf地址
  const [moduleLectureSize, setModuleLectureSize] = useState(0);//pdf大小
  const [moduleVideoUrl, setModuleVideoUrl] = useState(null as any);//视频url地址

  const [moduleVideoName, setModuleVideoName] = useState(null as any);//视频名称

  const [moduleVideoDuration, setModuleVideoDuration] = useState('0秒');//视频时长
  const [moduleVideoSize, setModuleVideoSize] = useState('0M');//视频大小

  const [moduleLectureName, setModuleLectureName] = useState('');//pdf名称

  const [exerciseData, setExerciseData] = useState([] as any[]);
  const [caseData, setCaseData] = useState([] as any[]);


  const [isUploading, setIsUploading] = useState(false);//是否正在上传文件

  const exeQueryContent = useRef('');  // 记录搜索串
  const exePage = useRef(1);  // 记录当前的页数
  const exePageSize = useRef(10);  // 记录当前每页展示数量
  const queryExeIsMY = useRef('2');  // 来源 1-我的  2-平台上架的
  const exeOrder = useRef('');  // 排序升序降序
  const exeOrderBy = useRef('');  // 排序列
  const exeTypeFilters = useRef([]);  // 类型过滤选项
  const exeDifficultyFilters = useRef([]);  // 难易程度过滤选项

  const [exeTotalSum, setExeTotalSum] = useState(0);  // 搜索返回的结果数
  const [exeTotalCount, setExeTotalCount] = useState(0);  // 搜索返回的结果数

  const caseQueryContent = useRef('');  // 记录搜索串
  const casePage = useRef(1);  // 记录当前的页数
  const casePageSize = useRef(10);  // 记录当前每页展示数量
  const [caseTotalSum, setCaseTotalSum] = useState(0);  // 搜索返回的结果数
  const queryCaseIsMY = useRef('2');  // 搜索实验的来源， 来源 1 我的  2 平台上架的
  const caseOrder = useRef('');  // 排序升序降序
  const caseOrderBy = useRef('');  // 排序列

  const [caseTotalCount, setCaseTotalCount] = useState(0);  // 搜索返回的结果数

  const draggleRef = useRef<HTMLDivElement | null>(null);
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
  const onStart = (event:any, uiData:any) => {
      const { clientWidth, clientHeight } = window?.document?.documentElement;
      const targetRect= draggleRef?.current?.getBoundingClientRect();
      if (targetRect) {
          setBounds( {
              left: -targetRect?.left + uiData?.x,
              right: clientWidth - (targetRect?.right - uiData?.x),
              top: -targetRect?.top + uiData?.y,
              bottom: clientHeight - (targetRect?.bottom - uiData?.y),
          } )
      }
  }

  // 查询知识点列表回调
  const queryModuleCallback = (response:any) => {
      message.destroy();
      if (response.code == -100) {
          // 未登录
          window.location.href = `/login?next=course-create-knowledge?module_id=${moduleId}`;
      } else if (response.is_ok == true) {
          // 成功
          setModule(response?.query_info); 
          setModuleType(response?.query_info.type);
          setModuleName(response?.query_info.name);
          setModuleExercises(response?.query_info.practice);
          setModuleTestExamExercises(response?.query_info.exam);
          setModuleCase(response?.query_info.case);
          setModuleLectureUrl(response?.query_info.lecture_url);
          setModuleLectureSize(response?.query_info.lecture_size_str);
          // setModuleLectureName(response?.query_info.lecture_url.split("\\")[1]); // Windows写法
          setModuleVideoUrl(response?.query_info.video_url);
          setModuleVideoSize(response?.query_info.video_size_str);
          setModuleVideoDuration(response?.query_info.video_duration_str);
          const _moduleVideoName = response?.query_info.video_url.length > 0 ? response?.query_info.video_url.split("/")[3] : "";
          setModuleVideoName(_moduleVideoName);
          const _moduleLectureName = response?.query_info.lecture_url.length > 0 ? response?.query_info.lecture_url.split("/")[3] : "";
          setModuleLectureName(_moduleLectureName);
          // setModuleVideoName(response?.query_info.video_url.split("\\")[1]); // Windows写法
      } else if (response.code == -501) {
          // 权限不足
          window.location.href = `/login?next=course-create-knowledge?module_id=${moduleId}`;
      } else {
          message.error(response.message);
      }
  }

  // 查询知识点列表
  const queryModule = () => {
      sendHttpPostRequest(
          'course/api/user_query_knowcell_by_id',
          {
              sessionid: Cookies.get("sessionid"),
              sid: Cookies.get("sessionid"),
              know_id: moduleId
          },
          queryModuleCallback
      )
  }

  useEffect(() => {
      queryModule();
      // console.log(moduleId);
  }, []);

  // 查作业考试题列表
  const queryExerciseList = () => {
      sendHttpPostRequest(
          'exercise/api/query_exercise_list/',
          {
              sessionid: Cookies.get("sessionid"),
              sid: Cookies.get("sessionid"),
              query: exeQueryContent.current,
              page: exePage.current,
              pagesize: exePageSize.current,
              order: exeOrder.current,
              order_by: exeOrderBy.current,
              type: JSON.stringify(exeTypeFilters.current),
              difficulty: JSON.stringify(exeDifficultyFilters.current),
              is_my: queryExeIsMY.current,
              source: queryExeIsMY.current,
              source_page: 2,  // 知识点2
              source_key: 1,  // OAM1
              published: 1, // 只查询发布的
          },
          (response: any) => {
              if (response.is_ok && response.code == 0) {
                  setExerciseData(response.exercise_list);
                  setExeTotalSum(response.total_sum);
                  setExeTotalCount(response.total_count);
              } else {
                  console.error("查询习题出错了");
              }
          }
      )
  }

  // 选题点击了搜索
  const onExeSearchBtn = (event: any) => {
      exePage.current = 1;
      exeQueryContent.current = event.target.value;
      queryExerciseList();
  }

  // 查案例列表
  const queryCaseList = () => {
      sendHttpPostRequest(
          'case/api/query_case_list',
          {
              sessionid: Cookies.get("sessionid"),
              sid: Cookies.get("sessionid"),
              query: caseQueryContent.current,
              page: casePage.current,
              pagesize: casePageSize.current,
              is_my: queryCaseIsMY.current,
              order: caseOrder.current,
              order_by: caseOrderBy.current,
          },
          (response: any) => {
              if (response.is_ok && response.code == 0) {
                  setCaseData(response.case_list);
                  setCaseTotalSum(response.total_sum);
                  setCaseTotalCount(response.total_count);
              } else {
                  console.error("查询案例出错了");
              }
          }
      )
  }

  // 案例点击了搜索
  const onCaseSearchBtn = (e: any) => {
      casePage.current = 1;
      caseQueryContent.current = e.target.value;
      queryCaseList();
  }


  // 实验结果表格发生变化
  const onCaseTableChange:any = (pagination:any, filters:any, sorter:any, extra: { currentDataSource: [], action: any })=> {
      if (sorter.order) { //如果选择了排序列
          caseOrder.current = sorter.order;
          caseOrderBy.current = sorter.field;
      }
   
      casePage.current = 1;
      queryCaseList();
  }


  // 切换习题结果Tab页
  const onExeTabKeyChange = (key:string) => {
     
      queryExeIsMY.current = key;
      exePage.current = 1;
      queryExerciseList();
  }

  // 习题结果表格发生变化
  const onExeTableChange:any = (pagination:any, filters:any, sorter:any, extra: { currentDataSource: [], action: any })=> {
      if (sorter.order) { //如果选择了排序列
          exeOrder.current = sorter.order;
          exeOrderBy.current = sorter.field;
      }
      if (filters.type) { //如果选择了过滤列
          exeTypeFilters.current = filters.type;
      } else {
          exeTypeFilters.current = [];
      }
      if (filters.difficulty) {
          exeDifficultyFilters.current = filters.difficulty;
      } else {
          exeDifficultyFilters.current = [];
      }
      exePage.current = 1;
      queryExerciseList();
  }

  // 选择实验
  const caseRowSelection = {
      onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
          selectedCaseRowKeys.current = selectedRowKeys;
          setModuleCase({case_id: selectedCaseRowKeys.current[0], name: selectedRows[0].name, token:selectedRows[0].token});
        },
        getCheckboxProps: (record: any) => ({
          disabled: module.case?.case_id == record.case_id,
          //name: record.name,
        }),
  };

  // 每次初始化习题列表
  const initExercise = () => {
      exePage.current = 1;
      exeQueryContent.current = '';
      queryExerciseList();
  }

  // 每次初始化实验列表
  const initCase = () => {
      casePage.current = 1;
      caseQueryContent.current = '';
      queryCaseList();
  }

  //选择作业考试题
  const onExerciseTestExamSelection = {
      onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      selectedTestExamExercises.current = selectedRows;
      },
      getCheckboxProps: (record: any) => ({
      disabled: module?.exam?.filter(item=> item.id == record.id).length > 0,
      //name: record.name,
      })
  }

  const handleAddTestexamOk = () => {
      setTestexamVisible(false);
  
      const newModuleExercises = [...moduleTestExamExercises];

      selectedTestExamExercises.current.map(row=>{
          newModuleExercises.push(row);
      })
      
      setModuleTestExamExercises(newModuleExercises);
      module.exam = newModuleExercises;
      onSaveModuleBtnClick(null as any);
  }
  /** 渲染预览的习题 */
  const renderExercisePreview = (record:any) => {
      return <ExerciseCard exercise={record} renderType="white" teacherOnly={true} editorMode={true} showAnser={true} showClean={true}/>
  }
  
  const handleAddCaseOk = () => {
      setAddCaseVisible(false);
      module.case = moduleCase;
      onSaveModuleBtnClick(null as any);   
  }

  const getVideoPreview =   ()=>{
    return <div style={{background:"#000"}}>
        {moduleVideoUrl !== "" && 
        <video  style={{marginTop:25,marginBottom:25,height:320,width:500}}  preload="preload" 
        controls={true}
        controlsList="nodownload"
        
        >
            <source 
           src= { moduleVideoUrl?.startsWith("http") ? moduleVideoUrl : constants.BACKEND_URL + "/course/api/get_stream_video?path="+ moduleVideoUrl}
            type={"video/mp4"} />
            您的浏览器不支持 HTML5 video 标签。
        </video>
        }
     </div>

} 

  //选择练习题
  const onExerciseSelection = {
      onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      selectedExercises.current = selectedRows;
      },
      getCheckboxProps: (record: any) => ({
      disabled: module?.practice?.filter(item=> item.id == record.id).length > 0,
      //name: record.name,
      })
  }


  const handleAddExerciseOk = () => {
      setAddExerciseVisible(false);
      
      const newModuleExercises = [...moduleExercises];

      selectedExercises.current.map(row=>{
          newModuleExercises.push(row);
      })
      
      setModuleExercises(newModuleExercises);
      module.practice = newModuleExercises;
      onSaveModuleBtnClick(null as any);
      
  }



  const onModuleNameChange = (event: any) => {
      setModuleName(event.target.value);
      module['name'] = event.target.value;
      //onSaveModuleBtnClick(null as any);
  }

  // fix bug 306
  const onModuleTypeChange = (e: any) => {
      message.destroy();
      if (moduleType == 1) {
          // 1讲义
          if (moduleLectureUrl != "") {
              message.info("不同时支持上传视频和PDF，上传视频将覆盖PDF")
          }
      } else if (moduleType == 2) {
          // 视频
          if (moduleVideoUrl != "") {
              message.info("不同时支持上传视频和PDF，上传PDF将覆盖视频")
          }
      }
      setModuleType(e.target.value)
  }

  // 点击保存
  const onSaveModuleBtnClick = (event: any) => {
     
      sendHttpPostRequest(
          'course/api/user_update_knowcell',
          {
              sessionid: Cookies.get("sessionid"),
              sid: Cookies.get("sessionid"),
              know_id: moduleId,
              type: moduleType,
              name: module.name,
              lecture_url: module.lecture_url,
              video_url: module.video_url,
              case: JSON.stringify(module.case),
              exam: JSON.stringify(module.exam),
              practice: JSON.stringify(module.practice)
          },
          (response: any) => {
              //message.destroy();
              console.log(response)
              if (response.code == -100) {
                  // 未登录
                  window.location.href = `/login?next=course_edit_knowledge?module_id=${moduleId}`;
              } else if (response.is_ok == true) {
                  // 成功
                  message.success("更新知识点成功");
              } else if (response.code == -501) {
                  // 权限不足
                  window.location.href = `/login?next=course_edit_knowledge?module_id=${moduleId}`;
              } else {
                  message.error(response.message);
              }
          }
      )
  }



  // 点击 删除练习题
  const onDeleteBtnClick = (exerciseId: string) => {
      module.practice = moduleExercises.filter(item => item.id !== exerciseId);
      setModuleExercises(moduleExercises.filter(item => item.id !== exerciseId));
      onSaveModuleBtnClick(null as any);
      
  }


  // 点击 删除作业考试题
  const onDeleteTestExamBtnClick = (exerciseId: string) => {
      module.exam= moduleTestExamExercises.filter(item => item.id !== exerciseId);
      setModuleTestExamExercises(moduleTestExamExercises.filter(item => item.id !== exerciseId));
      onSaveModuleBtnClick(null as any);
      
  }

  const renderExerciseActions = (text:any, record:any) => {
      return <div style={{color:'#999',paddingLeft:36}}>
          {/* <FileSearchOutlined style={{marginRight:10,fontSize:16,cursor:'pointer'}}/> */}

          <Popconfirm placement="top" title={'确认删除习题"' + record.title + '"吗？'} onConfirm={onDeleteBtnClick.bind(this, record.id)} okText="确认" cancelText="取消">
              <DeleteOutlined style={{fontSize:16,cursor:'pointer'}} />
          </Popconfirm>

          
      </div>
  }

  const renderExerciseTestExamActions = (text:any, record:any) => {
      return <div style={{color:'#999',paddingLeft:36}}>
          {/* <FileSearchOutlined style={{marginRight:10,fontSize:16,cursor:'pointer'}}/> */}

          <Popconfirm placement="top" title={'确认删除习题"' + record.title + '"吗？'} onConfirm={onDeleteTestExamBtnClick.bind(this, record.id)} okText="确认" cancelText="取消">
              <DeleteOutlined style={{fontSize:16,cursor:'pointer'}} />
          </Popconfirm>

          
      </div>
  }

  // 渲染类型
  const renderType = (text: any, record: any) => (
      <div>{typeMap.get(parseInt(text))}</div>
  )

  // 渲染难易程度
  const renderDifficulty = (text: any, record: any) => (
      <div>{difficultyMap.get(parseInt(text))}</div>
  )

  // 习题页码和页数发生了变化
  const onExePageChange = (newPage: number, newPageSize?: number | undefined) => {
      exePage.current = newPage ? newPage : 1;
      if (newPageSize) {
          exePageSize.current = newPageSize;
      }
      queryExerciseList();
  }

  // 案例页码和页数发生了变化
  const onCasePageChange = (newPage: number, newPageSize?: number | undefined) => {
      casePage.current = newPage ? newPage : 1;
      if (newPageSize) {
          casePageSize.current = newPageSize;
      }
      queryCaseList();
  }

  const exerciseResultCols:any = [
      {
          title: '序号',
          dataIndex: 'id',
          key: 'id',
          ellipsis: true,
          align: 'center',
          width: '10%',
          render:(text,record,index)=>(index+1)
      },
      {
          title: '名称',
          dataIndex: 'title',
          key: 'title',
          ellipsis: true,
          align: 'left',
          width: '30%',
      },
      {
          title: '题型',
          dataIndex: 'type',
          key: 'type',
          align: 'center',
          width: '15%',
          render: renderType,
          
      },
      {
          title: '难度',
          dataIndex: 'difficulty',
          key: 'difficulty',
          align: 'center',
          width: '15%',
          render: renderDifficulty,
         
      },
  
      {
          title: "操作",
          dataIndex: "id",
          key: "actions",
          width: '20%',
          ellipsis: false,
          align: 'center',
          fixed: 'right',
          render: renderExerciseActions,
      },
  ]

  const exerciseTestExamResultCols:any = [
      {
          title: '序号',
          dataIndex: 'id',
          key: 'id',
          ellipsis: true,
          align: 'center',
          width: '10%',
          render:(text,record,index)=>(index+1)
      },
      {
          title: '名称',
          dataIndex: 'title',
          key: 'title',
          ellipsis: true,
          align: 'left',
          width: '30%',
      },
      {
          title: '题型',
          dataIndex: 'type',
          key: 'type',
          align: 'center',
          width: '15%',
          render: renderType,   
      },
      {
          title: '难度',
          dataIndex: 'difficulty',
          key: 'difficulty',
          align: 'center',
          width: '15%',
          render: renderDifficulty,
          
         
      },
  
      {
          title: "操作",
          dataIndex: "id",
          key: "actions",
          width: '20%',
          ellipsis: false,
          align: 'center',
          fixed: 'right',
          render: renderExerciseTestExamActions,
      },
  ]


  const onPreviewCase = (id:string, token:string, event:any)=>{
      event.preventDefault();
      window.open('/case-run?id=' + id + "&token=" + token);
  }
  const renderActions = (text:any, record:any) => {
      return <Button className={courseCreateStyles.modalBtn} type="primary" size="small" style={{height:28,width:60}}>预览</Button>
  }

  const renderCaseActions = (text:any, record:any) => {
      return <Button className={courseCreateStyles.modalBtn} type="primary" size="small" style={{height:28,width:60}} onClick={onPreviewCase.bind(this,record.case_id, record.token )}>预览</Button>
  }

  // 搜索切换Tab页
  const onCaseTabKeyChange = (key:string) => { 
      queryCaseIsMY.current = key;
      casePage.current = 1;
      queryCaseList();
  }

  // 删除视频
    const onDeleteVideoClick = () => {
        sendHttpPostRequest(
            'course/api/knowcell_delete_video',
            {
                sessionid: Cookies.get("sessionid"),
                sid: Cookies.get("sessionid"),
                knowcell_id: moduleId,
            },
            (response:any) => {
                if (response.is_ok) {
                    message.success("PDF删除成功");
                    queryModule();
                } else {
                    message.error("PDF删除失败: " + response.message);
                }
            }
        )
    }

  // 删除PDF
    const onDeletePDFClick = () => {
        sendHttpPostRequest(
            'course/api/knowcell_delete_lecture',
            {
                sessionid: Cookies.get("sessionid"),
                sid: Cookies.get("sessionid"),
                knowcell_id: moduleId,
            },
            (response:any) => {
                if (response.is_ok) {
                    message.success("视频删除成功");
                    queryModule();
                } else {
                    message.error("视频删除失败: " + response.message);
                }
            }
        )
    }

  const caseCols:any = [
    
      {
          title: '序号',
          dataIndex: 'case_id',
          key: 'case_id',
          align: 'center',
          width: 80,
          render:(text,record,index)=>((casePage.current - 1)*casePageSize.current + index + 1)
      },
      {
          title: '实验名称',
          dataIndex: 'name',
          key: 'name',
          align: 'left',
          width: 200,
      },
      {
          title: '领域',
          dataIndex: 'domain',
          key: 'domain',
          align: 'center',
          width: 50,
        },
        {
          title: '创建时间',
          dataIndex: 'create_time',
          key: 'create_time',
          align: 'center',
          width: 150,
          sorter: true,
          ellipsis: true,
          sortDirections: ['descend', 'ascend'],
          defaultSortOrder: 'descend',
        },
      {
          title: "操作",
          dataIndex: "actions",
          key: "actions",
          width: 100,
          ellipsis: false,
          align: 'center',
          fixed: 'right',
          render: renderCaseActions ,
      },
  ];
  
  const exerciseCols:any = [
      {
          title: '序号',
          dataIndex: 'id',
          key: 'id',
          ellipsis: true,
          align: 'left',
          width: '10%',
          render:(text,record,index)=>((exePage.current - 1)*exePageSize.current + index + 1)
      },
      {
          title: '题目名称',
          dataIndex: 'title',
          key: 'title',
          ellipsis: true,
          align: 'left',
          width: '30%',
      },
      {
          title: '题型',
          dataIndex: 'type',
          key: 'type',
          align: 'center',
          width: '15%',
          render:renderType,
          onFilter: (value:any, record:any) => true,
          filters: [
              {
                  text: '实操题',
                  value: 1,
              },
              {
                  text: '单选题',
                  value: 2,
              },
              {
                  text: '多选题',
                  value: 3,
              },
              {
                  text: '排序题',
                  value: 4,
              },
              {
                  text: '主观题',
                  value: 5,
              }
          ],
      },
      {
          title: '难度',
          dataIndex: 'difficulty',
          key: 'difficulty',
          align: 'center',
          width: '15%',
          render: renderDifficulty,
          filters: [
              {
                  text: '容易',
                  value: 1,
              },
              {
                  text: '中等',
                  value: 2,
              },
              {
                  text: '困难',
                  value: 3,
              }
          ],
          onFilter: (value:any, record:any) => true,
      },
      {
          title: "操作",
          dataIndex: "id",
          key: "actions",
          width: '20%',
          ellipsis: false,
          align: 'center',
          fixed: 'right',
          render: renderActions ,
      }
  ]

  // 上传文件传参
  const uploadData = {
      sessionid: Cookies.get("sessionid"),
      sid: Cookies.get("sessionid"),
      knowcell_id: moduleId,
  }

  // 上传文件
  const uploadProps = {
      accept: moduleType == 1 ? ".pdf" : '.mp4,.mov',
      name: 'data_file',
      multiple: false,
      maxCount:1,
      showUploadList: isUploading == false ? false: 
                     { showPreviewIcon: false, showRemoveIcon: false, showDownloadIcon: false,previewIcon: false},
      data: uploadData,
      progress: {strokeColor:'#07c160',strokeWidth:2,showInfo:false},
      action: constants.BACKEND_API_URL + 'course/api/knowcell_upload_video/',
      beforeUpload: file => {
        
          if (moduleType == 2) {
              if (file.size > 1024 * 1024 * 1024) {
                  message.error("请上传小于1G的文件");
                  return false;
              }
          }
          if (moduleType == 1) {
              if (file.size > (1024 * 1024 * 1024) / 10) {
                  message.error("请上传小于100M的文件");
                  return false;
              }
          }
      },
      onChange(info) {
          const { status } = info.file;
          if (status == 'uploading') {
             setIsUploading(true);
              console.log(info.file, info.fileList);
          }
          if (status === 'done') {
              setIsUploading(false);
              message.destroy();
              if (info.file.response.is_ok) {
                  message.success(`文件${info.file.name}上传成功！`);
                  if (info.file.response.type == 1) {
                    module.lecture_url = info.file.response.url;
                    setModuleLectureUrl(module.lecture_url);
                    setModuleLectureSize(info.file.response.size_str);
                    setModuleVideoUrl("");
                    module.video_url = "";
                    const _moduleLectureName = module.lecture_url.length > 0 ? module.lecture_url.split("/")[3] : "";
                    setModuleLectureName(_moduleLectureName);
                } else if (info.file.response.type == 2) {
                    module.video_url = info.file.response.url;
                    setModuleVideoUrl(module.video_url);


                    setModuleVideoDuration(info.file.response.duration_str);
                    setModuleVideoSize(info.file.response.size_str);


                    setModuleLectureUrl("");
                    module.lecture_url = "";
                    const _moduleVideoName = module.video_url.length > 0 ? module.video_url.split("/")[3] : "";
                    setModuleVideoName(_moduleVideoName);
                }
                 // onSaveModuleBtnClick(null as any);
              } else {
                  if (info.file.response.code == -100) {
                      // 未登录
                      // const localPath = window.location.href.split(window.location.host)[1];
                      window.location.href = `/login?next=course`;
                  } else if (info.file.response.code == -501) {
                      // 权限不足
                      // const localPath = window.location.href.split(window.location.host)[1];
                      window.location.href = `/login?next=course`;
                  } else {
                      message.error(info.file.response.message);
                  }
              }
          } else if (status === 'error') {
              message.error(`文件${info.file.name}上传失败了:(`);
          }
      },
      onDrop(e) {
          console.log('Dropped files', e.dataTransfer.files);
      },
  };

    return (
        <Layout style={{backgroundColor: '#f9f9f9',minHeight: 'calc(110vh - 60px)'}}>
          <LoginRegisterModal></LoginRegisterModal>
          <div className={courseCreateStyles.maindiv}  style={{height:"calc(100vh - 110px)", minHeight:650,overflowY:'scroll'}}>
            
              
              <Row align="middle" style={{borderBottom: '1px solid #eee',height:60}}>
                <Col span={12} style={{textAlign:"left"}}> <div style={{fontWeight:700,fontSize:16}}>编辑知识点</div> </Col>
                <Col span={12} style={{color:"#333333",textAlign:"right"}}> 
                 {/* <a style={{color:"#333333"}} href="/course">返回{">"}</a>  */}
                 <span style={{color:"#333333",cursor:'pointer'}} onClick={()=>{window.close()}}>返回{">"}</span>
                </Col>
              </Row>

              <div style={{paddingTop:20}}>
                  <Row style={{marginBottom:10}}>
                      <Col flex="360px" style={{textAlign:'left'}}><span style={{fontSize:18, marginRight:10}}>名称</span><Input placeholder="名称/ID" style={{width:300,margin:'0 6px'}} value={moduleName} onChange={onModuleNameChange}/></Col>
                      <Col flex="auto" style={{color:'#ccc',fontSize:12,lineHeight:'30px'}}>知识点包含视频、练习题、实验、作业考试题等，讲义为必要元素。</Col>
                  </Row>

                  <Row style={{height:40,lineHeight:'40px'}}>
                      <Col flex="30px" style={{color:'#07c160',fontSize:20,marginRight:10}}>①</Col>
                      <Col  flex="120px" style={{textAlign:"left", fontSize:18}}><span style={{color:'rgba(255, 75, 80, 1)',marginLeft:5}}>*</span>上传讲义</Col>
                      <Col flex="auto" style={{textAlign:"left"}}>
                            <Radio.Group onChange={onModuleTypeChange} value={moduleType} size="large">
                                <Radio value={2}>视频</Radio>
                                <Radio value={1}>PDF</Radio>
                            </Radio.Group>
                      </Col>                           
                  </Row>

                  <div style={{marginLeft:50,marginTop:10,marginBottom:10,textAlign:"left"}}>
                    <Upload {...uploadProps}>
                        <Button size="small" style={{borderColor:'#07c160',width:80,height:32,color:'#07c160'}}>
                            <UploadOutlined style={{margin:0}}/>上传
                        </Button>
                        {moduleType != 1 &&
                            <span style={{color:'#999',textAlign:'left',marginLeft:20}}>请上传1G以内的视频，支持mp4、mov格式。</span>
                        }
                        {moduleType == 1 &&
                            <span style={{color:'#999',textAlign:'left',marginLeft:20}}>请上传100M以内的PDF文件</span>
                        }
                    </Upload>
                      <Col flex="auto" style={{textAlign:'left',marginTop:10}}>

                      </Col>
                  </div>
    
                 {  moduleType != 1 && moduleVideoUrl !== '' &&  <Row gutter={[8,8]} justify='start'  align='middle' style={{marginTop:0,marginBottom:10}} >
                    <Col flex="50px" style={{color:"#00000073",textAlign:'left'}}></Col>
                    <Col flex="auto" style={{textAlign:'left'}}>
                        <div className={courseEditKnowlegeStyles.cardImageDiv}>
                        
                            <Popover overlayStyle={{padding:0}} destroyTooltipOnHide={true} placement = "top" trigger= "click" content={getVideoPreview()}>
                                <PlayCircleOutlined className={courseEditKnowlegeStyles.imagePlayIcon}/>
                            </Popover>
                        
                            <div style={{background:"#666",height:80, width:125}}></div>
                        </div>
                        <div style={{marginTop:10,marginBottom:10}}>
                            <span style={{marginRight:20,color:'#333'}}>{moduleVideoName}</span>
                            <span style={{marginRight:20,color:'#999'}}>视频时长：{moduleVideoDuration}</span>
                            <span style={{marginRight:20,color:'#999'}}>视频大小：{moduleVideoSize}</span>
                            <span>
                                {moduleVideoName && <Popconfirm placement="top" title={'确认删除视频"' + moduleVideoName + '"吗？'} onConfirm={onDeleteVideoClick} okText="确认" cancelText="取消">
                                    <DeleteOutlined style={{fontSize:16,cursor:'pointer'}} />
                                </Popconfirm>}
                            </span>
                        </div>
                    </Col>
                </Row>
                } 

                {  moduleType == 1 && moduleLectureUrl !== '' &&  <Row gutter={[8,8]} justify='start'  align='middle' style={{marginLeft:50,marginTop:-10,marginBottom:0}} >
                    <Col flex="50px" style={{color:"#00000073",textAlign:'left'}}></Col>
                    <Col flex="auto" style={{textAlign:'left'}}>
                        <div style={{marginTop:10,marginBottom:10}}>
                            <span style={{marginRight:20,color:'#333'}}>{moduleLectureName}</span>
                            <span style={{marginRight:20,color:'#999'}}>文件大小：{moduleLectureSize}</span>
                            {moduleType == 1 && moduleLectureName &&
                                <Popconfirm placement="top" title={'确认删除PDF"' + moduleLectureName + '"吗？'} onConfirm={onDeletePDFClick} okText="确认" cancelText="取消">
                                    <DeleteOutlined style={{fontSize:16,cursor:'pointer'}} />
                                </Popconfirm>
                            }
                        </div>
                    </Col>
                </Row>
                } 

                  <Row style={{height:50,lineHeight:'50px',marginTop:10}}>
                      <Col flex="30px" style={{color:'#07c160',fontSize:20,marginRight:10}}>②</Col>
                      <Col  flex="100px" style={{textAlign:"left",backgroundColor:'#E8FAEF',paddingLeft:10,fontSize:18}}>添加实验</Col>
                      <Col flex="auto" style={{textAlign:"right",backgroundColor:'#E8FAEF',paddingRight:10}}>
                          <Button size="small" type="primary" style={{borderColor:'#07c160',width:80,height:32,color:'#fff'}}
                                  onClick={()=>{initCase(); setAddCaseVisible(true)}}>
                              <PlusSquareOutlined style={{margin:0}}/>实验
                          </Button>
                      </Col>     
                  </Row>
                  
                  { !moduleCase?.case_id &&
                    <div style={{color:'#999',textAlign:'center',height:50,lineHeight:'50px',margin:'0 0 20px 40px',border:'1px solid #E1F2E8'}}>请添加实验</div>
                  }
                  { moduleCase?.case_id && <Row style={{color:'#333',textAlign:'left',margin:'0 0 20px 40px',height:36,lineHeight:'36px',border:'1px solid #E1F2E8'}}>
                    <Col flex="auto" style={{paddingLeft:10,cursor:"pointer"}}
                    onClick={(event:any)=>{event.preventDefault();
                        window.open('/case-run?id=' + module?.case?.case_id + "&token=" + module?.case?.token);}}
                         className={courseCreateStyles.caseName}>{moduleCase?.case_id} {moduleCase?.name}</Col> 
                    <Col flex="154px" style={{textAlign:'center',borderLeft:'1px solid #E1F2E8',color:'#999'}}><FileSearchOutlined style={{marginLeft:10,marginRight:10,fontSize:16,cursor:'pointer'}} onClick={(event:any)=>{event.preventDefault();
                        window.open('/case-run?id=' + module?.case?.case_id + "&token=" + module?.case?.token);}}/>
                    <DeleteOutlined style={{fontSize:16,cursor:'pointer'}} onClick={()=>{module.case={};setModuleCase({});onSaveModuleBtnClick(null as any);queryModule();}}/>
                    </Col>
                  </Row> }

                  <Row style={{height:50,lineHeight:'50px'}}>
                      <Col flex="30px" style={{color:'#07c160',fontSize:20,marginRight:10}}>③</Col>
                      <Col flex="120px" style={{textAlign:"left",backgroundColor:'#E8FAEF',paddingLeft:10,fontSize:18}}><span style={{color:'rgba(255, 75, 80, 1)',marginLeft:5}}>*</span>添加练习题</Col>
                      <Col flex="auto" style={{textAlign:"right",backgroundColor:'#E8FAEF',paddingRight:10}}>
                          <Button size="small" type="primary" style={{borderColor:'#07c160',width:80,height:32,color:'#fff'}}
                              onClick={()=>{initExercise(); setAddExerciseVisible(true);}}>
                              <PlusSquareOutlined style={{margin:0}}/>习题
                          </Button>
                      </Col>  
                  </Row>

                  {moduleExercises.length==0 &&
                    <div style={{color:'#999',textAlign:'center',height:50,lineHeight:'50px',margin:'0 0 20px 40px',border:'1px solid #E1F2E8'}}>请添加练习题</div>
                  }
                  {moduleExercises.length!=0 &&
                    <div className={courseCreateStyles.knowledgeTableWrapper}>
                      <ConfigProvider locale={zhCN}>

                          <Table size="small"
                              dataSource={moduleExercises}
                              columns={exerciseResultCols}
                              pagination={false}
                              style={{margin:'0 0 20px 40px'}}
                              rowKey={record => record.id}
                              expandable={{
                                  columnWidth: 0,
                                  expandIconColumnIndex:5,
                                  expandIcon:({ expanded, onExpand, record }) => <div onClick={e => onExpand(record, e)} style={{color:'#999',position:'absolute',right:67,zIndex:99,top:8}}>
                                    <FileSearchOutlined style={{marginRight:10,fontSize:16,cursor:'pointer'}}/>                                 
                                  </div>
                                //   expanded ? (
                                //   <div onClick={e => onExpand(record, e)} style={{cursor:'pointer'}}>预览<UpOutlined  className={moduleStyles.actionBtn} /></div>
                                //   ) : (
                                //   <div onClick={e => onExpand(record, e)} style={{cursor:'pointer'}}>预览<DownOutlined className={moduleStyles.actionBtn} /></div>
                                //   )
                                  ,
                                  expandedRowRender: renderExercisePreview,
                                  rowExpandable: record => record.name !== 'Not Expandable',
                                  }}
                          />
                      </ConfigProvider>
                  </div>
                  }

                  <Row style={{height:50,lineHeight:'50px'}}>
                      <Col flex="30px" style={{color:'#07c160',fontSize:20,marginRight:10}}>④</Col>
                      <Col flex="150px" style={{backgroundColor:'#E8FAEF',paddingLeft:10,fontSize:18}}>添加作业考试题</Col>
                      <Col flex="auto" style={{backgroundColor:'#E8FAEF',paddingRight:10,textAlign:"right"}}>
                          <Button size="small" type="primary" style={{borderColor:'#07c160',width:80,height:32,color:'#fff'}}
                              onClick={()=>{initExercise(); setTestexamVisible(true);console.log(testexam);console.log(testexamResult)}}>
                              <PlusSquareOutlined style={{margin:0}}/>习题
                          </Button>
                      </Col>
                      
                  </Row>

                  {moduleTestExamExercises.length==0 &&
                    <div style={{color:'#999',textAlign:'center',height:50,lineHeight:'50px',margin:'0 0 20px 40px',border:'1px solid #E1F2E8'}}>请添加作业考试题</div>
                  }
                  {moduleTestExamExercises.length!=0 &&
                    <div className={courseCreateStyles.knowledgeTableWrapper}>
                        <ConfigProvider locale={zhCN}>
                            <Table size="small"
                                dataSource={moduleTestExamExercises}
                                columns={exerciseTestExamResultCols}
                                pagination={false}
                                style={{margin:'0 0 20px 40px'}}
                                rowKey={record => record.id}
                                expandable={{
                                    columnWidth: 0,
                                    expandIconColumnIndex:5,
                                    expandIcon:({ expanded, onExpand, record }) => <div onClick={e => onExpand(record, e)} style={{color:'#999',position:'absolute',right:67,zIndex:99,top:8}}>
                                    <FileSearchOutlined style={{marginRight:10,fontSize:16,cursor:'pointer'}}/>
                                </div>
                                    //   expanded ? (
                                    //   <div onClick={e => onExpand(record, e)} style={{cursor:'pointer'}}>预览<UpOutlined  className={moduleStyles.actionBtn} /></div>
                                    //   ) : (
                                    //   <div onClick={e => onExpand(record, e)} style={{cursor:'pointer'}}>预览<DownOutlined className={moduleStyles.actionBtn} /></div>
                                    //   )
                                    ,
                                    expandedRowRender: renderExercisePreview,
                                    rowExpandable: record => record.name !== 'Not Expandable',
                                    }}
                            />
                        </ConfigProvider>
                    </div>
                  }

                  {/* <Row style={{height:50,lineHeight:'50px'}}>
                      <Col flex="30px" style={{color:'#07c160',fontSize:20,marginRight:10}}>⑤</Col>
                      <Col flex="100px" style={{lineHeight:'50px'}}><Button type="primary" style={{borderRadius:20}}>+添加标签</Button></Col>               
                      <Col><span style={{color:'#999',fontSize:12,marginLeft:10}}>提示：请添加标签，最多支持三个。</span></Col>  
                  </Row> */}
              </div>
            
            
          </div>

          <div style={{bottom:0,height:60,paddingTop:12,width:'100%',backgroundColor:'#fff'}}>
              <div style={{margin:'0 auto'}}> 
              <Button className={courseCreateStyles.stepsBtn} style={{marginRight:30,color:'#666'}} type="default" onClick={()=>{window.open(`/module-learn-preview?module_id=${moduleId}`)}}>预览</Button>        
                 <Button className={courseCreateStyles.stepsBtn} style={{marginRight:20,color:'#fff'}} type="primary" onClick={onSaveModuleBtnClick}>保存</Button>
              </div>
           </div>


          <Modal
              title={<div style={{width: '100%',cursor: 'move',}}
                  onMouseOver={() => {if (disabled) {setDisabled(false)}}}
                  onMouseOut={() => {setDisabled(true)}}
                  onFocus={() => {}}
                  onBlur={() => {}}>添加实验</div>
              }
              visible={addCaseVisible}
              width={800}
              bodyStyle={{ minHeight:400,overflow:"auto"}}
            //   onOk={handleAddCaseOk}
            //   onCancel={()=>{setAddCaseVisible(false)}}
              destroyOnClose={true}
              closable={false}
              footer={
                <div>
                  <Button className={courseCreateStyles.stepsBtn}
                  style={{marginRight:50}}
                  type="default"
                  onClick={()=>{setAddCaseVisible(false)}}>取消</Button>
                  <Button className={courseCreateStyles.stepsBtn}
                  style={{marginRight:20,color:'#fff'}}
                  type="primary"
                  onClick={handleAddCaseOk}>确认</Button>
                </div>
              }
              modalRender={modal => (<div className={moduleStyles.addModal}>
                  <Draggable
                      disabled={disabled}
                      bounds={bounds}
                      onStart={(event, uiData) => onStart(event, uiData)}
                  >
                      <div ref={draggleRef}>{modal}</div>
                  </Draggable> </div>
              )}    
          >
              <div>
              <Input
                      onPressEnter={onCaseSearchBtn} 
                      placeholder="搜索实验（名称或ID）" 
                      style={{borderRadius:20,width:300,marginBottom:10}} 
                      suffix={<SearchOutlined  style={{color:"#666666"}}/>}
                  />
              </div>

              <Tabs defaultActiveKey="2" onChange={onCaseTabKeyChange}>
                  <TabPane tab="平台" key="2" className="">
                      <div className={courseCreateStyles.tableWrapper}>
                          <ConfigProvider locale={zhCN}>
                              <Table
                              dataSource={caseData}
                              columns={caseCols}
                              rowKey={record => record.case_id}
                              pagination={false}
                              size="small"
                              //scroll={{ x: 600 }}
                              //bordered={true}
                              onChange={onCaseTableChange}
                              rowSelection={{
                                  type: "radio",
                                  ...caseRowSelection,
                                  }}
                          />
                          </ConfigProvider>
                      </div>
                  </TabPane>
                  <TabPane tab="我的" key="1" className="">
                  <div className={courseCreateStyles.tableWrapper}>
                      <ConfigProvider locale={zhCN}>
                          <Table
                              dataSource={caseData}
                              columns={caseCols}
                              rowKey={record => record.case_id}
                              pagination={false}
                              size="small"
                              //scroll={{ x: 600 }}
                              //bordered={true}
                              onChange={onCaseTableChange}
                              rowSelection={{
                                  type: "radio",
                                  ...caseRowSelection,
                                  }}
                          />
                      </ConfigProvider>
                      </div>
                  </TabPane>
              </Tabs>

              <ConfigProvider locale={zhCN}>
                  <div style={{marginBottom:10,paddingTop:10,textAlign:"center"}} >
                      <Pagination className={moduleStyles.pagination}
                          total={caseTotalSum}
                          defaultCurrent={1}
                          defaultPageSize={10}
                          current={casePage.current}
                          onChange={onCasePageChange}
                          hideOnSinglePage={true}
                          style={{textAlign:"center"}}
                      />
                  </div>
              </ConfigProvider>



          </Modal>

          <Modal
              title={<div style={{width: '100%',cursor: 'move',}}
                  onMouseOver={() => {if (disabled) {setDisabled(false)}}}
                  onMouseOut={() => {setDisabled(true)}}
                  onFocus={() => {}}
                  onBlur={() => {}}>添加练习题</div>
              }
              visible={addExerciseVisible}
              width={800}
            //   onOk={handleAddExerciseOk}
            //   onCancel={()=>{setAddExerciseVisible(false)}}
              bodyStyle={{ minHeight:400,overflow:"auto"}}
              //className={courseCreateStyles.addModal}
              destroyOnClose={true}
              closable={false}
              footer={
                <div>
                  <Button className={courseCreateStyles.stepsBtn}
                  style={{marginRight:50}}
                  type="default"
                  onClick={()=>{setAddExerciseVisible(false)}}>取消</Button>
                  <Button className={courseCreateStyles.stepsBtn}
                  style={{marginRight:20,color:'#fff'}}
                  type="primary"
                  onClick={handleAddExerciseOk}>确认</Button>
                </div>
              }
              modalRender={modal => (<div className={moduleStyles.addModal}>
                  <Draggable
                      disabled={disabled}
                      bounds={bounds}
                      onStart={(event, uiData) => onStart(event, uiData)}
                  >
                      <div ref={draggleRef}>{modal}</div>
                  </Draggable> </div>
              )}

          >
              <div>
              <Input
                  onPressEnter={onExeSearchBtn} 
                  placeholder="搜索习题（名称或ID）" 
                  style={{borderRadius:20,width:300,marginBottom:10}} 
                  suffix={<SearchOutlined  style={{color:"#666666"}}/>}
              />
              </div>
              <Tabs defaultActiveKey="2" onChange={onExeTabKeyChange}>
                  <TabPane tab="平台" key="2" className="">
                      <div className={courseCreateStyles.tableWrapper}>
                          <ConfigProvider locale={zhCN}>
                          <Table size="small"
                          dataSource={exerciseData}
                          columns={exerciseCols}
                          pagination={false}
                          onChange={onExeTableChange}
                          rowKey={record => record.id}
                          rowSelection={{
                              type: "checkbox",
                              ...onExerciseSelection,
                              }
                              }
                          expandable={{
                              columnWidth: 90,
                              childrenColumnName:"预览",
                              expandIconColumnIndex:6,
                              expandIcon:({ expanded, onExpand, record }) =>
                              expanded ? (
                              <div onClick={e => onExpand(record, e)} style={{cursor:'pointer'}}>预览<UpOutlined  className={moduleStyles.actionBtn} /></div>
                              ) : (
                              <div onClick={e => onExpand(record, e)} style={{cursor:'pointer'}}>预览<DownOutlined className={moduleStyles.actionBtn} /></div>
                              ),
                              expandedRowRender: renderExercisePreview,
                              rowExpandable: record => record.name !== 'Not Expandable',
                              }}
                      />
                          </ConfigProvider>
                      </div>
                  </TabPane>
                  <TabPane tab="我的" key="1" className="">
                  <div className={courseCreateStyles.tableWrapper}>
                      <ConfigProvider locale={zhCN}>
                          <Table size="small"
                              dataSource={exerciseData}
                              columns={exerciseCols}
                              pagination={false}
                              onChange={onExeTableChange}
                              rowKey={record => record.id}
                              rowSelection={{
                                  type: "checkbox",
                                  ...onExerciseSelection,
                                  }
                                  }
                              expandable={{
                                  columnWidth: 90,
                                  childrenColumnName:"预览",
                                  expandIconColumnIndex:6,
                                  expandIcon:({ expanded, onExpand, record }) =>
                                  expanded ? (
                                  <div onClick={e => onExpand(record, e)} style={{cursor:'pointer'}}>预览<UpOutlined  className={moduleStyles.actionBtn} /></div>
                                  ) : (
                                  <div onClick={e => onExpand(record, e)} style={{cursor:'pointer'}}>预览<DownOutlined className={moduleStyles.actionBtn} /></div>
                                  ),
                                  expandedRowRender: renderExercisePreview,
                                  rowExpandable: record => record.name !== 'Not Expandable',
                                  }}
                          />
                      </ConfigProvider>
                      </div>
                  </TabPane>
              </Tabs>

              


              <ConfigProvider locale={zhCN}>
                  <div style={{marginBottom:10,paddingTop:10,textAlign:"center"}}  >
                      <Pagination className={moduleStyles.pagination}
                          total={exeTotalSum}
                          defaultCurrent={1}
                          defaultPageSize={10}
                          current={exePage.current}
                          onChange={onExePageChange}
                          hideOnSinglePage={true}
                          style={{textAlign:"center"}}
                      />
                  </div>
              </ConfigProvider>

              
          </Modal>

          <Modal
              title={<div style={{width: '100%',cursor: 'move',}}
                  onMouseOver={() => {if (disabled) {setDisabled(false)}}}
                  onMouseOut={() => {setDisabled(true)}}
                  onFocus={() => {}}
                  onBlur={() => {}}>添加作业考试题</div>
              }
              visible={addTestexamVisible}
              width={800}
            //   onOk={handleAddTestexamOk}
            //   onCancel={()=>{setTestexamVisible(false)}}
              bodyStyle={{ minHeight:400,overflow:"auto"}}
              //className={courseCreateStyles.addModal}
              destroyOnClose={true}
              closable={false}
              footer={
                <div>
                  <Button className={courseCreateStyles.stepsBtn}
                  style={{marginRight:50}}
                  type="default"
                  onClick={()=>{setTestexamVisible(false)}}>取消</Button>
                  <Button className={courseCreateStyles.stepsBtn}
                  style={{marginRight:20,color:'#fff'}}
                  type="primary"
                  onClick={handleAddTestexamOk}>确认</Button>
                </div>
              }
              modalRender={modal => (<div className={moduleStyles.addModal}>
                  <Draggable
                      disabled={disabled}
                      bounds={bounds}
                      onStart={(event, uiData) => onStart(event, uiData)}
                  >
                      <div ref={draggleRef}>{modal}</div>
                  </Draggable> </div>
              )}

          >

          <div>
              <Input
                  onPressEnter={onExeSearchBtn} 
                  placeholder="搜索习题（名称或ID）" 
                  style={{borderRadius:20,width:300,marginBottom:10}} 
                  suffix={<SearchOutlined  style={{color:"#666666"}}/>}
              />
              </div>
              <Tabs defaultActiveKey="2" onChange={onExeTabKeyChange}>
                  <TabPane tab="平台" key="2" className="">
                      <div className={courseCreateStyles.tableWrapper}>
                          <ConfigProvider locale={zhCN}>
                          <Table size="small"
                          dataSource={exerciseData}
                          columns={exerciseCols}
                          pagination={false}
                          onChange={onExeTableChange}
                          rowKey={record => record.id}
                          rowSelection={{
                              type: "checkbox",
                              ...onExerciseTestExamSelection,
                              }
                              }
                          expandable={{
                              columnWidth: 90,
                              childrenColumnName:"预览",
                              expandIconColumnIndex:6,
                              expandIcon:({ expanded, onExpand, record }) =>
                              expanded ? (
                              <div onClick={e => onExpand(record, e)} style={{cursor:'pointer'}}>预览<UpOutlined  className={moduleStyles.actionBtn} /></div>
                              ) : (
                              <div onClick={e => onExpand(record, e)} style={{cursor:'pointer'}}>预览<DownOutlined className={moduleStyles.actionBtn} /></div>
                              ),
                              expandedRowRender: renderExercisePreview,
                              rowExpandable: record => record.name !== 'Not Expandable',
                              }}
                      />
                          </ConfigProvider>
                      </div>
                  </TabPane>
                  <TabPane tab="我的" key="1" className="">
                  <div className={courseCreateStyles.tableWrapper}>
                      <ConfigProvider locale={zhCN}>
                          <Table size="small"
                              dataSource={exerciseData}
                              columns={exerciseCols}
                              pagination={false}
                              onChange={onExeTableChange}
                              rowKey={record => record.id}
                              rowSelection={{
                                  type: "checkbox",
                                  ...onExerciseTestExamSelection,
                                  }
                                  }
                              expandable={{
                                  columnWidth: 90,
                                  childrenColumnName:"预览",
                                  expandIconColumnIndex:6,
                                  expandIcon:({ expanded, onExpand, record }) =>
                                  expanded ? (
                                  <div onClick={e => onExpand(record, e)} style={{cursor:'pointer'}}>预览<UpOutlined  className={moduleStyles.actionBtn} /></div>
                                  ) : (
                                  <div onClick={e => onExpand(record, e)} style={{cursor:'pointer'}}>预览<DownOutlined className={moduleStyles.actionBtn} /></div>
                                  ),
                                  expandedRowRender: renderExercisePreview,
                                  rowExpandable: record => record.name !== 'Not Expandable',
                                  }}
                          />
                      </ConfigProvider>
                      </div>
                  </TabPane>
              </Tabs>

          


              <ConfigProvider locale={zhCN}>
                  <div style={{marginBottom:10,paddingTop:10,textAlign:"center"}}  >
                      <Pagination className={moduleStyles.pagination}
                          total={exeTotalSum}
                          defaultCurrent={1}
                          defaultPageSize={10}
                          current={exePage.current}
                          onChange={onExePageChange}
                          hideOnSinglePage={true}
                          style={{textAlign:"center"}}
                      />
                  </div>
              </ConfigProvider>

              
          </Modal>

          
       </Layout>
    )

}

export default CourseCreateKnowledge

