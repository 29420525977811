import * as React from 'react'
import { useState, useRef, useEffect } from 'react'
import cmtyStyle from './community.module.less'
import {
  Button,
  Col,
  Divider,
  Row,
  Tabs,
  message,
  Checkbox,
  ConfigProvider,
  Pagination,
} from 'antd'
import SearchInputCom from '../../component/SearchInputCom/SearchInputCom'
import { useHistory } from 'react-router-dom'
import { sendHttpPostRequest } from '../../api/http-request'
import Cookies from 'js-cookie'
import { BACKEND_API_URL } from '../../constants/constants'
import loadingGif from '../../static/image/loding.gif'
import CommunityAgreement from './CommunityAgreement'
import zhCN from 'antd/lib/locale/zh_CN'

interface Props {}

export const Community = (props: Props) => {
  const [agreementRead, setAgreementRead] = useState(true)
  const queryContent = useRef('') // 记录搜索串
  const order = useRef('descend') // 排序升序降序
  const orderBy = useRef('publish_time') // 排序列
  const page = useRef(1) // 记录当前的页数
  const pageSize = useRef(10) // 记录当前每页展示数量
  const [resultSum, setResultSum] = useState(0) // 搜索返回的结果数
  const [checkedAgreementRead, setCheckedAgreementRead] = useState(false) // 勾选我已阅读并同意
  const [todayMyPublishCount, setTodayMyPublishCount] = useState(0) // 我今天发文数
  const [myPublishCount, setMyPublishCount] = useState(0) // 我累计发文数
  const [myAvatar, setMyAvatar] = useState(
    'static/image/avatar/default_avatar.png'
  ) // 我的头像
  const [myName, setMyName] = useState('') // 我的名字
  const [articleLoaded, setArticleLoaded] = useState(false) // 加载中
  // const currentTab = useRef("new")  // 当前tab
  const recommendPage = useRef(1) // 记录当前的页数
  const recommendPageSize = useRef(10) // 记录当前每页展示数量
  const [recommendResultSum, setRecommendResultSum] = useState(0) // 搜索返回的结果数

  const history = useHistory()

  const [articleList, setArticleList] = useState([] as any[])

  const changeTab = window.location.search
  const currentTab = useRef(
    changeTab && changeTab == '?tab=3'
      ? 'my'
      : changeTab && changeTab == '?tab=2'
      ? 'hot'
      : 'new'
  ) // 当前tab

  const [recommendList, setRecommendList] = useState([] as any[])

  // 搜索框输入变化
  const searchInputChange = (e) => {
    queryContent.current = e.target.value
  }

  /**
   * 切换tab
   * @param key
   */
  const onTabkeyChange = (key: string) => {
    currentTab.current = key
    onSearch()
  }

  /**
   * 搜索
   */
  const onSearch = () => {
    page.current = 1
    let url: string =
      currentTab.current == 'my'
        ? 'community/api/query_my_post_list/'
        : 'community/api/query_public_post_list/'
    orderBy.current = currentTab.current == 'hot' ? 'hot' : 'publish_time'
    queryArticleList(url)
  }

  useEffect(() => {
    onSearch()
    queryMyArticleCount()
    queryRecommendArticleList()
  }, [])

  /**
   * 查文章列表
   * @param url
   */
  const queryArticleList = (url = 'community/api/query_public_post_list/') => {
    setArticleLoaded(false)
    sendHttpPostRequest(
      url,
      {
        sessionid: Cookies.get('sessionid'),
        query: queryContent.current,
        page: page.current,
        pagesize: pageSize.current,
        order: order.current,
        order_by: orderBy.current,
      },
      (response: any) => {
        setArticleLoaded(true)
        message.destroy()

        if (response.code == -100) {
          // 未登录
          currentTab.current == 'my'
            ? (window.location.href = '/login?next=community')
            : ''
          setArticleList(response.query_list)
          setResultSum(response.total_sum)
        } else if (response.code == 0) {
          // 成功
          setArticleList(response.query_list)
          setResultSum(response.total_sum)
          setAgreementRead(true)
        } else if (response.code == -501) {
          // 权限不足
          window.location.href = '/login?next=community'
        } else if (response.code == -509) {
          // 未阅读社区协议
          setAgreementRead(false)
          setArticleList([])
          setResultSum(0)
          setTodayMyPublishCount(0)
          setMyPublishCount(0)
        } else {
          message.error(response.message)
        }

        if (changeTab) {
          const newSearch = changeTab.substring(0, changeTab.indexOf('?'))
          window.history.replaceState(null, '', `community`)
        }
      }
    )
  }

  /**
   * 查我的文章总数
   * @param url
   */
  const queryMyArticleCount = (url = 'community/api/query_my_post_count/') => {
    sendHttpPostRequest(
      url,
      {
        sessionid: Cookies.get('sessionid'),
      },
      (response: any) => {
        message.destroy()
        if (response.code == -100) {
          // 未登录
          // window.location.href = '/login?next=community';
        } else if (response.code == 0) {
          // 成功
          setMyName(response.myself.name)
          setMyAvatar(response.myself.avatar)
          setTodayMyPublishCount(response.today_my_publish_count)
          setMyPublishCount(response.my_publish_count)
        } else if (response.code == -501) {
          // 权限不足
          window.location.href = '/login?next=community'
        } else {
          message.error(response.message)
        }
      }
    )
  }

  /**
   * 查推荐文章列表
   */
  const queryRecommendArticleList = () => {
    sendHttpPostRequest(
      'community/api/query_top_post_list/',
      {
        sessionid: Cookies.get('sessionid'),
        page: recommendPage.current,
        pagesize: recommendPageSize.current,
      },
      (response: any) => {
        message.destroy()
        if (response.code == -100) {
          // 未登录
          // window.location.href = '/login?next=community';
        } else if (response.code == 0) {
          // 成功
          setRecommendList(response.query_list)
          setRecommendResultSum(response.total_sum)
        } else if (response.code == -501) {
          // 权限不足
          window.location.href = '/login?next=community'
        } else {
          message.error(response.message)
        }
      }
    )
  }

  // 点了复选框
  const onOptionsChange = (event: any) => {
    setCheckedAgreementRead(!checkedAgreementRead)
  }

  // 点击继续
  const onContinue = (event: any) => {
    message.destroy()
    if (!checkedAgreementRead) {
      message.error('请认真阅读社区协议并勾选“我已阅读并同意”')
    } else {
      sendHttpPostRequest(
        'community/api/read_community_agreement/',
        {
          sessionid: Cookies.get('sessionid'),
        },
        (response: any) => {
          message.destroy()
          if (response.code == -100) {
            // 未登录
            window.location.href = '/login?next=community'
          } else if (response.code == 0) {
            // 成功
            onSearch()
          } else if (response.code == -501) {
            // 权限不足
            window.location.href = '/login?next=community'
          } else {
            message.error(response.message)
          }
        }
      )
    }
  }

  const newArticleBtnClick = () => {
    history.push('/community-article-create')
  }

  const onPageSizeChange = (newPage, newPageSize) => {
    page.current = newPage
    pageSize.current = newPageSize
    queryArticleList()
  }

  const articleListRender = (
    <div className={cmtyStyle.listContain}>
      {articleList?.map((article) => (
        <div
          key={article?.id}
          className={cmtyStyle.articleInfo}
          onClick={() =>
            history.push(`/community-article-detail/${article?.id}`)
          }
        >
          <h1 className={cmtyStyle.articleTitle}>{article?.title}</h1>
          <div className={cmtyStyle.articleMain}>{article?.content}</div>
          <div className={cmtyStyle.timeBox}>{article?.publish_time}</div>
        </div>
      ))}
      {/* {articleList.length > 0 && ( */}
        <div style={{margin:"20px 0px"}}>
          <ConfigProvider locale={zhCN}>
            <Pagination
              total={resultSum}
              current={page.current}
              pageSize={pageSize.current}
              onChange={onPageSizeChange}
              showQuickJumper
              hideOnSinglePage
              pageSizeOptions={['10', '20', '50', '100']}
            />
          </ConfigProvider>
        </div>
      {/* )} */}
    </div>
  )

  const recommendListRender = (
    <div className={cmtyStyle.recommendList}>
      {recommendList?.map((recommend) => (
        <div
          className={cmtyStyle.recommendItem}
          key={recommend?.id}
          onClick={() =>
            history.push(`/community-article-detail/${recommend?.id}`)
          }
        >
          <h1 className={cmtyStyle.articleTitle}>{recommend?.title}</h1>
          <div style={{ margin: '0px' }} className={cmtyStyle.articleMain}>
            {recommend?.content}
          </div>
        </div>
      ))}
    </div>
  )
  return (
    <div className={cmtyStyle.mainContent}>
      <div className={cmtyStyle.contentBox}>
        <div
          hidden={articleLoaded}
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%,-50%)',
            textAlign: 'center',
          }}
        >
          <img height={100} width={100} src={loadingGif}></img>
        </div>
        {agreementRead && (
          <div className={cmtyStyle.leftMain}>
            <Row
              className={cmtyStyle.header}
              justify={'space-between'}
              align={'middle'}
            >
              <Col>
                <SearchInputCom
                  placeholder={'根据标题/内容关键字搜索'}
                  onChange={searchInputChange}
                  onSearch={onSearch}
                />
              </Col>
              <Col>
                <Button
                  className={cmtyStyle.radiusBtn}
                  type={'primary'}
                  onClick={newArticleBtnClick}
                >
                  +发文
                </Button>
              </Col>
            </Row>
            <Tabs
              onChange={onTabkeyChange}
              defaultActiveKey={currentTab.current}
            >
              <Tabs.TabPane tab={'最新'} key={'new'}>
                {articleListRender}
              </Tabs.TabPane>
              <Tabs.TabPane tab={'最热'} key={'hot'}>
                {articleListRender}
              </Tabs.TabPane>
              <Tabs.TabPane tab={'我的'} key={'my'}>
                {articleListRender}
              </Tabs.TabPane>
            </Tabs>
          </div>
        )}
        {!agreementRead && articleLoaded && (
          <div
            className={cmtyStyle.leftMain}
            hidden={!articleLoaded}
            style={agreementRead ? { display: 'none' } : { display: 'block' }}
          >
            <CommunityAgreement />
            <div>
              <Checkbox
                style={{
                  padding: 5,
                  minHeight: 30,
                  alignItems: 'center',
                  alignContent: 'center',
                }}
                key={1}
                value={1}
                onChange={onOptionsChange}
              >
                <span style={{ top: 3, position: 'relative' }}>
                  我已阅读并同意
                </span>
              </Checkbox>
              <Button onClick={onContinue}>继续</Button>
            </div>
          </div>
        )}
        <div className={cmtyStyle.rightMain}>
          <div className={cmtyStyle.userMain}>
            <Row align={'middle'} justify={'start'}>
              <Col>
                <img
                  className={cmtyStyle.userAvatar}
                  src={BACKEND_API_URL + myAvatar}
                  alt=""
                />
              </Col>
              <Col className={cmtyStyle.userName}>{myName}</Col>
            </Row>
            <div className={cmtyStyle.statistic}>
              <div className={cmtyStyle.statisticInfo}>
                <div className={cmtyStyle.statisticTitle}>今日发文数</div>
                <div className={cmtyStyle.statisticNum}>
                  {todayMyPublishCount}
                </div>
              </div>
              <Divider style={{ height: '100%' }} type={'vertical'} />
              <div className={cmtyStyle.statisticInfo}>
                <div className={cmtyStyle.statisticTitle}>总发文数</div>
                <div className={cmtyStyle.statisticNum}>{myPublishCount}</div>
              </div>
            </div>
          </div>
          <div className={cmtyStyle.recommendMain}>
            <div className={cmtyStyle.recommendTitle}>
              推荐({recommendList.length})
            </div>
            {recommendListRender}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Community
