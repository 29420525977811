import React from 'react'
import { Modal, Button, Input, List, ConfigProvider, message } from 'antd'
import { UserOutlined, LockOutlined, MobileOutlined } from '@ant-design/icons'
import loginRegisterStyles from './login-register.less'
import './login-register.css'
import zhCN from 'antd/lib/locale/zh_CN'
import { setCookie } from '../../../pages/common/login-register/login-register'
import Cookies from 'js-cookie'
import localStorage from 'localStorage'
import { sendHttpPostRequest } from '../../../api/http-request'
import CommonModal from '../CommonModal/CommonModal'
import GlobalCss from '@/global.module.less'

/**
 * LoginRegisterModal
 * 登录注册模态框
 */
class LoginRegisterModal extends React.Component {
  // state 模态框显示状态
  state = {
    // register_modal_visible: false,
    // login_modal_visible: false,
    // password_retrieval_mode: false,  // 密码找回模式
    modalVisible: false, // 是否可见
    modalType: 1, // 1-注册  2-登录  3-找回密码

    idens: '', //帐号
    password: '', // 密码
    passwordConfirm: '', // 密码确认
    vcode: '', // 验证码（注册）
    retrievalVcode: '', // 验证码（密码找回）
    registerMode: false, // 注册模式
    errorMessage: '', // 错误码
    sendVcodeTip: '发送验证码', // 验证码发送提示（注册）
    sendBtnDisabled: false, // 发送按钮不可用（注册）
    sendRetrievalVcodeTip: '发送验证码', // 验证码发送提示（密码找回）
    sendRetrievalBtnDisabled: false, // 发送按钮不可用（密码找回）
    inviter: '', // 邀请者
    forgetPasswordModalVis: false, // 密码找回模态框
    manager_idens: '',
  }

  constructor(props) {
    super(props)
  }

  // 绑定表单值
  handleChange(key, event) {
    this.setState({
      [key]: event.target.value,
    })
  }

  forgetPasswordClick() {
    let url = 'account/api/user_get_college_manager_idens'
    sendHttpPostRequest(url, {}, (res) => {
      this.setState({
        forgetPasswordModalVis: true,
        manager_idens: res.data.manager_idens,
      })
    })
  }

  // 改变模态框类型
  handleModalTypeChange(key, value) {
    if (process.env.REACT_APP_SMS_HIDE == 'true' && value == '3') {
      this.forgetPasswordClick()
    } else {
      this.setState({ errorMessage: '' })
      this.setState({
        [key]: value,
      })
    }
  }

  retrievalCallback(response) {
    message.destroy()
    if (response.is_ok) {
      message.success('密码重置成功，请使用新密码登录')
    } else {
      message.error('密码重置失败，请稍候再试')
    }
  }

  /**
   * 登录回调
   * @param response
   */
  loginCallback(response) {
    // const location = window.location.href.split("next=")[1];
    if (response.is_ok) {
      setCookie('sessionid', response.token)
      localStorage.setItem(
        'user_info',
        JSON.stringify(response.user_info.user_info)
      )
      window.location.reload()
      // location ? window.location.href = location : window.location.href = "/";
    } else {
      this.setState({ errorMessage: response.message })
    }
  }

  /**
   * 发送手机验证码回调
   * @param response
   */
  sendPhoneVcodeCallback(response) {
    if (response.code == 0) {
      message.destroy()
      message.success(response.message)
      let times = 60
      this.setState({ sendVcodeTip: times + '秒后重发', sendBtnDisabled: true })
      let _this: any = this
      let time_handle = setInterval(function () {
        times -= 1
        if (times <= 0) {
          _this.setState({ sendVcodeTip: '发送验证码', sendBtnDisabled: false })
          clearInterval(time_handle)
        } else _this.setState({ sendVcodeTip: times + '秒后重发' })
      }, 1000)
    } else {
      this.setState({ errorMessage: response.message })
    }
  }

  /**
   * 检查手机号
   */
  checkPhone() {
    this.setState({ errorMessage: '' })
    const phone = this.state.idens
    const is_mobile =
      /^(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[0-9]{1})|(18[0-9]{1})|(19[0-9]{1}))+\d{8})$/

    if (phone == '') {
      this.setState({ errorMessage: '请输入手机号' })
    } else if (!phone.match(is_mobile)) {
      this.setState({ errorMessage: '请输入正确的手机号' })
    } else {
      try {
        sendHttpPostRequest(
          'account/api/send_sms/',
          { phone: phone, type: 1 },
          this.sendPhoneVcodeCallback.bind(this)
        )
      } catch (e) {
        console.error(e)
      }
    }
  }

  /**
   * 找回密码发送手机验证码回调
   * @param response
   */
  sendRetrievalPhoneVcodeCallback(response) {
    if (response.code == 0) {
      message.destroy()
      message.success(response.message)
      let times = 60
      this.setState({
        sendRetrievalVcodeTip: times + '秒后重发',
        sendRetrievalBtnDisabled: true,
      })
      let _this: any = this
      let time_handle = setInterval(function () {
        times -= 1
        if (times <= 0) {
          _this.setState({
            sendRetrievalVcodeTip: '发送验证码',
            sendRetrievalBtnDisabled: false,
          })
          clearInterval(time_handle)
        } else _this.setState({ sendRetrievalVcodeTip: times + '秒后重发' })
      }, 1000)
    } else {
      this.setState({ errorMessage: response.message })
    }
  }

  /**
   * 找回密码检查手机号
   */
  retrievalCheckPhone() {
    this.setState({ errorMessage: '' })
    const phone = this.state.idens
    const is_mobile =
      /^(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[0-9]{1})|(18[0-9]{1})|(19[0-9]{1}))+\d{8})$/

    if (phone == '') {
      this.setState({ errorMessage: '请输入手机号' })
    } else if (!phone.match(is_mobile)) {
      this.setState({ errorMessage: '请输入正确的手机号' })
    } else {
      try {
        sendHttpPostRequest(
          'account/api/send_sms/',
          { phone: phone, type: 2 },
          this.sendRetrievalPhoneVcodeCallback.bind(this)
        )
      } catch (e) {
        console.error(e)
      }
    }
  }

  handleGoLogin() {
    doLogin(this)
  }

  handleGoRegister() {
    doRegister(this)
  }

  handleGoRetrieval() {
    doRetrieval(this)
  }

  // 渲染
  render() {
    return (
      <>
        <ConfigProvider locale={zhCN}>
          <Button
            className={loginRegisterStyles.buttons}
            hidden={true}
            id="show-register-modal"
            type="primary"
            onClick={() => this.setState({ modalVisible: true, modalType: 1 })}
          >
            注册
          </Button>
          <Button
            className={loginRegisterStyles.buttons}
            hidden={true}
            id="show-login-modal"
            type="primary"
            onClick={() => this.setState({ modalVisible: true, modalType: 2 })}
          >
            登录
          </Button>
          <Modal
            className="login-register-modal"
            title={
              this.state.modalType == 1
                ? '注册'
                : this.state.modalType == 2
                ? '登录'
                : '密码找回'
            }
            centered
            width={330}
            visible={this.state.modalVisible}
            footer={null}
            onCancel={() => this.setState({ modalVisible: false })}
          >
            <div
              className={loginRegisterStyles.pageRegister}
              hidden={this.state.modalType != 1}
            >
              <List className="">
                <div id="error-message-register" className="error-message">
                  {this.state.errorMessage}
                </div>
                <Input
                  id="register-phone"
                  name="register-phone"
                  className="register-form phone"
                  value={this.state.idens}
                  placeholder="请输入手机号"
                  required={true}
                  prefix={<UserOutlined />}
                  onChange={this.handleChange.bind(this, 'idens')}
                />
                {process.env.REACT_APP_SMS_HIDE == 'true' ? (
                  ''
                ) : (
                  <Input
                    onClick={this.checkPhone.bind(this)}
                    type="button"
                    className="btn btn-primary send-phone-code register"
                    value={this.state.sendVcodeTip}
                    disabled={this.state.sendBtnDisabled}
                  />
                )}
                {process.env.REACT_APP_SMS_HIDE == 'true' ? (
                  ''
                ) : (
                  <Input
                    id="register-phone-vcode"
                    name="register-phone-vcode"
                    className="register-form phone-vcode"
                    placeholder="请输入短信验证码"
                    maxLength={6}
                    onChange={this.handleChange.bind(this, 'vcode')}
                    prefix={<MobileOutlined />}
                  />
                )}
                <Input
                  id="register-password"
                  name="register-password"
                  className="register-form password"
                  placeholder="请输入密码，6-20位"
                  maxLength={20}
                  prefix={<LockOutlined />}
                  type={'password'}
                  onChange={this.handleChange.bind(this, 'password')}
                />
                <Input
                  id="register-password-confirm"
                  name="register-password-confirm"
                  className="register-form password-confirm"
                  placeholder="请再输入一遍密码"
                  prefix={<LockOutlined />}
                  onChange={this.handleChange.bind(this, 'passwordConfirm')}
                  type="password"
                />
                <Input
                  id="register-inviter"
                  name="register-inviter"
                  className="register-form inviter"
                  placeholder="请输入邀请人手机号(可选)"
                  onChange={this.handleChange.bind(this, 'inviter')}
                  prefix={<UserOutlined />}
                />
                <div
                  onClick={this.handleGoRegister.bind(this)}
                  className="button btn-register"
                >
                  注 &nbsp;&nbsp;册
                </div>
                <div
                  className="cue1"
                  onClick={this.handleModalTypeChange.bind(
                    this,
                    'modalType',
                    2
                  )}
                >
                  已有帐号，返回登录 &gt;
                </div>
              </List>
            </div>
            <div
              className={loginRegisterStyles.pageLogin}
              hidden={this.state.modalType != 2}
            >
              <List className="">
                <div id="error-message-login" className="error-message">
                  {this.state.errorMessage}
                </div>
                <Input
                  className="login-form phone"
                  placeholder="请输入手机号"
                  required={true}
                  prefix={<UserOutlined />}
                  onChange={this.handleChange.bind(this, 'idens')}
                  value={this.state.idens}
                />
                <Input.Password
                  type="password"
                  className="login-form password"
                  placeholder="请输入密码，6-20位"
                  maxLength={20}
                  onChange={this.handleChange.bind(this, 'password')}
                  prefix={<LockOutlined />}
                />
                <div
                  onClick={this.handleGoLogin.bind(this)}
                  className="button btn-register"
                >
                  登 &nbsp;&nbsp;录
                </div>
                <div
                  className="cue1"
                  onClick={this.handleModalTypeChange.bind(
                    this,
                    'modalType',
                    1
                  )}
                >
                  还没有帐号，去注册 &gt;
                </div>
                <div
                  style={{ marginTop: 10 }}
                  className="cue1"
                  onClick={this.handleModalTypeChange.bind(
                    this,
                    'modalType',
                    3
                  )}
                >
                  忘记密码？去找回 &gt;
                </div>
              </List>
            </div>
            <div
              className={loginRegisterStyles.modalRetrieval}
              hidden={this.state.modalType != 3}
            >
              <List className="">
                <div id="error-message-login" className="error-message">
                  {this.state.errorMessage}
                </div>
                <Input
                  className="login-form phone"
                  placeholder="请输入手机号"
                  required={true}
                  value={this.state.idens}
                  onChange={this.handleChange.bind(this, 'idens')}
                  prefix={<UserOutlined />}
                />
                {process.env.REACT_APP_SMS_HIDE == 'true' ? (
                  ''
                ) : (
                  <Input
                    onClick={this.retrievalCheckPhone.bind(this)}
                    type="button"
                    className="btn btn-primary send-phone-code retrieval"
                    value={this.state.sendRetrievalVcodeTip}
                    disabled={this.state.sendRetrievalBtnDisabled}
                  />
                )}
                {process.env.REACT_APP_SMS_HIDE == 'true' ? (
                  ''
                ) : (
                  <Input
                    className="register-form phone-vcode"
                    placeholder="请输入短信验证码"
                    maxLength={6}
                    onChange={this.handleChange.bind(this, 'retrievalVcode')}
                    prefix={<MobileOutlined />}
                  />
                )}
                <Input
                  className="register-form password"
                  placeholder="请输入新密码，6-20位"
                  maxLength={20}
                  prefix={<LockOutlined />}
                  onChange={this.handleChange.bind(this, 'password')}
                  type={'password'}
                />
                <Input
                  className="register-form password-confirm"
                  placeholder="请再输入一遍新密码"
                  prefix={<LockOutlined />}
                  onChange={this.handleChange.bind(this, 'passwordConfirm')}
                  type={'password'}
                />
                <div
                  onClick={this.handleGoRetrieval.bind(this)}
                  className="button btn-retrieval"
                >
                  重 &nbsp;&nbsp;置
                </div>
                <div
                  style={{ marginTop: 10 }}
                  className="cue1"
                  onClick={this.handleModalTypeChange.bind(
                    this,
                    'modalType',
                    2
                  )}
                >
                  返回登录 &gt;
                </div>
              </List>
            </div>
          </Modal>
          <CommonModal
            width={460}
            modalContent={
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '20px',
                }}
              >
                <div>请联系所在班级教师或院校管理员重置密码</div>
                <div>
                  院校管理员：
                  <span className={GlobalCss.hlText}>
                    {this.state.manager_idens}
                  </span>
                </div>
              </div>
            }
            modalTitle={'提示'}
            open={this.state.forgetPasswordModalVis}
            onOk={() => this.setState({ forgetPasswordModalVis: false })}
            onCancel={() => this.setState({ forgetPasswordModalVis: false })}
          />
        </ConfigProvider>
      </>
    )
  }
}

export default LoginRegisterModal

/**
 * 调后端登录接口
 */
export function doLogin(_this: any) {
  const phone = _this.state.idens
  const password = _this.state.password
  const is_mobile =
    /^(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[0-9]{1})|(18[0-9]{1})|(19[0-9]{1}))+\d{8})$/
  if (!phone) {
    _this.setState({ errorMessage: '请输入手机号' })
    return false
  } else if (!phone.match(is_mobile)) {
    _this.setState({ errorMessage: '请输入正确的手机号' })
    return false
  } else if (!password) {
    _this.setState({ errorMessage: '请输入密码' })
    return false
  } else if (password.length < 6 || password.length > 20) {
    _this.setState({ errorMessage: '密码长度应为6-20位' })
    return false
  }

  _this.setState({ errorMessage: '' })
  try {
    sendHttpPostRequest(
      'account/api/login/',
      { idens: phone, password: password },
      _this.loginCallback.bind(_this)
    )
  } catch (e) {
    console.error(e)
  }
}

/**
 * 注册
 * @param _this
 */
export function doRegister(_this: any) {
  const user_name = _this.state.idens
  const phone = _this.state.idens
  const password = _this.state.password
  const phone_vcode = _this.state.vcode
  const password_confirm = _this.state.passwordConfirm
  const inviter = _this.state.inviter
  const is_mobile =
    /^(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[0-9]{1})|(18[0-9]{1})|(19[0-9]{1}))+\d{8})$/
  if (!phone) {
    _this.setState({ errorMessage: '请输入手机号' })
    return false
  } else if (!phone.match(is_mobile)) {
    _this.setState({ errorMessage: '请输入正确的手机号' })
    return false
  } else if (!phone_vcode && process.env.REACT_APP_SMS_HIDE !== 'true') {
    _this.setState({ errorMessage: '请输入手机验证码' })
    return false
  } else if (!user_name) {
    _this.setState({ errorMessage: '请输入姓名' })
    return false
  } else if (!password) {
    _this.setState({ errorMessage: '请输入密码' })
    return false
  } else if (password.length < 6 || password.length > 20) {
    _this.setState({ errorMessage: '密码长度应为6-20位' })
    return false
  } else if (!password_confirm) {
    _this.setState({ errorMessage: '请输入密码确认' })
    return false
  } else if (password != password_confirm) {
    _this.setState({ errorMessage: '两次输入的密码不一致' })
    return false
  }
  if (inviter) {
    if (!inviter.match(is_mobile)) {
      _this.setState({ errorMessage: '请输入正确的邀请人手机号' })
      return false
    } else if (inviter.trim() == phone.trim()) {
      _this.setState({ errorMessage: '亲，您不能邀请自己~' })
      return false
    }
  }
  _this.setState({ errorMessage: ' ' })
  let data = {
    user_name: user_name,
    idens: phone,
    password: password,
    phone_vcode:
      process.env.REACT_APP_SMS_HIDE == 'true' ? '123456' : phone_vcode,
    password_confirm: password_confirm,
    inviter: inviter,
    channel: 2,
  }
  if (process.env.REACT_APP_SMS_HIDE == 'true') {
    Object.assign(data, { mode: 2 })
  }

  try {
    sendHttpPostRequest(
      'account/api/register/',
      data,
      _this.loginCallback.bind(_this)
    )
  } catch (e) {
    console.error(e)
  }
}

/**
 * 找回密码
 * @param _this
 */
function doRetrieval(_this: any) {
  const phone = _this.state.idens
  const password = _this.state.password
  const password_confirm = _this.state.passwordConfirm
  const phone_vcode = _this.state.retrievalVcode
  const is_mobile =
    /^(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[0-9]{1})|(18[0-9]{1})|(19[0-9]{1}))+\d{8})$/

  if (!phone) {
    _this.setState({ errorMessage: '请输入手机号' })
    return false
  } else if (!phone.match(is_mobile)) {
    _this.setState({ errorMessage: '请输入正确的手机号' })
    return false
  } else if (!phone_vcode && process.env.REACT_APP_SMS_HIDE !== 'true') {
    _this.setState({ errorMessage: '请输入手机验证码' })
    return false
  } else if (!password) {
    _this.setState({ errorMessage: '请输入新密码' })
    return false
  } else if (password.length < 6 || password.length > 20) {
    _this.setState({ errorMessage: '密码长度应为6-20位' })
    return false
  } else if (!password_confirm) {
    _this.setState({ errorMessage: '请输入新密码确认' })
    return false
  } else if (password != password_confirm) {
    _this.setState({ errorMessage: '两次输入的密码不一致' })
    return false
  }

  _this.setState({ errorMessage: ' ' })
  let data = {
    idens: phone,
    password: password,
    phone_vcode:
      process.env.REACT_APP_SMS_HIDE == 'true' ? '123456' : phone_vcode,
    password_confirm: password_confirm,
  }

  if (process.env.REACT_APP_SMS_HIDE == 'true') {
    Object.assign(data, { mode: 2 })
  }

  try {
    sendHttpPostRequest(
      'account/api/password_retrieval/',
      data,
      _this.retrievalCallback.bind(_this)
    )
  } catch (e) {
    console.error(e)
  }
}
