import React, { useEffect, useMemo, useRef, useState } from 'react'
import discussStyle from './course-main-discuss.module.less'
import { Button, Col, Input, Menu, message, Row, Select, Tabs } from 'antd'
import { sendHttpPostRequest } from '../../../api/http-request'
import Cookies from 'js-cookie'
import { number2text } from '../../../utils/numToZhUpper.js'
import SearchInputCom from '../../../component/SearchInputCom/SearchInputCom'
import DiscussItem from '../../../component/DiscussItem/DiscussItem'
import ReplyItem from '../../../component/DiscussItem/ReplyItem'
import MarkDownEditor from '../../../component/MarkDownEditor/MarkDownEditor'
import ChatRoom from './components/ChatRoom'

export interface DiscussProps {
  menu_id: any
  course_id: any
  teacherInfo: any
}

export interface TaskType {
  id: number
  name: string
}

export interface MenuType {
  id: number
  name: string
  tasks: TaskType[]
}

export const CourseMainDiscuss: React.FC<DiscussProps> = (props) => {
  const { menu_id, course_id, teacherInfo } = props

  const discussionsPage = useRef(1)
  const discussionsPageSize = useRef(10)
  const querySource = useRef(1)
  const [menu, setMenu] = useState([] as any)

  const [discussions, setDiscussions] = useState([] as any)
  const [replies, setReplies] = useState([] as any)

  const [isAllRepliesVis, setIsAllRepliesVis] = useState(false)
  const [isAddEditorVis, setIsAddEditorVis] = useState(false)
  const [knowledgeSelected, setKnowledgeSelected] = useState<any>()
  const [knowledgeName, setKnowledgeName] = useState('')
  const [taskList, setTaskList] = useState([] as any)
  const [newKnowledge, setNewKnowledge] = useState({} as any)
  const [chapterId, setChapterId] = useState('')

  const queryContent = useRef<any>()
  useEffect(() => {
    queryMenu()

    return () => {}
  }, [])

  const onDiscussTabChange = () => (activeKey: any) => {
    if (activeKey == '2') {
      queryDiscussions(taskList[0]?.id)
      console.log(111, taskList[0]?.id)
    }
  }

  const queryMenu = () => {
    sendHttpPostRequest(
      'course/api/user_query_menu_by_id',
      {
        sessionid: Cookies.get('sessionid'),
        menu_id: menu_id,
        // description: description,
        format: 1,
      },
      queryMenuCallback
    )
  }

  /**
   * 查询大纲回调
   * @param response
   */
  const queryMenuCallback = (response: any) => {
    if (response.code == -100) {
      // 未登录
      document.getElementById('show-login-modal')!.click()
    } else if (response.is_ok == true) {
      // 成功
      setMenu(response.query_info.json)
      let tasks = response.query_info.json
        .map((m) => m.tasks.map((t) => t))
        .flat()
      setTaskList([...tasks])
      setKnowledgeName(response.query_info.json[0].tasks[0].name)
      setKnowledgeSelected(response.query_info.json[0].tasks[0].id.toString())
      queryDiscussions(response.query_info.json[0].tasks[0].id)
      setChapterId(response.query_info.json[0].id)
      getNewKnowledge(response.query_info.json[0].tasks[0].id)

      // setDescription(response.query_info.description);
    } else {
      message.error(response.message)
    }
  }

  const onKnowledgeChange = (activeKey) => {
    activeKey == 'my' ? (querySource.current = 2) : (querySource.current = 1)
    queryDiscussions(knowledgeSelected)
  }

  const onSearchDiscuss = () => {
    queryDiscussions(knowledgeSelected)
  }

  const queryDiscussions = (knowledgeCellId) => {
    sendHttpPostRequest(
      'course/api/discussion/query_kc_discussion_list/',
      {
        sessionid: Cookies.get('sessionid'),
        course_id: course_id,
        know_id: knowledgeCellId,
        page: discussionsPage.current,
        pagesize: discussionsPageSize.current,
        query: queryContent.current,
        source: querySource.current,
      },
      (response: any) => {
        message.destroy()
        if (response.code == -100) {
          // 未登录
          document.getElementById('show-login-modal')!.click()
        } else if (response.code == 0) {
          // 成功
          setDiscussions(response.query_list)
        } else if (response.code == -501) {
          // 权限不足
          window.location.href = '/'
        } else if (response.code == -509) {
          // 未阅读社区协议
        } else {
          message.error(response.message)
        }
      }
    )
  }

  const onDiscussChange = (e) => {
    queryContent.current = e.target.value
  }

  const editorStateInit = () => {
    replies.map((r) => (r.replyVist = false))
    discussions.map((d) => (d.replyVist = false))
    setReplies([...replies])
    setDiscussions([...discussions])
  }

  const cancelNewArticle = () => {
    setIsAddEditorVis(false)
  }
  const getNewKnowledge = (key) => {
    let newKnowledge = taskList.filter((t) => t.id == key)[0]
    setNewKnowledge(taskList?.filter((t) => t.id == key)[0])
    const [i, j] = menu
      .map((m) => m.tasks.map((t) => t))
      .reduce(
        (acc, cur, i) => {
          const j = cur.indexOf(newKnowledge)
          return j !== -1 ? [i, j] : acc
        },
        [-1, -1]
      )
    if (i !== -1) {
      let newName = newKnowledge?.name
      setKnowledgeName(newName)
    }
  }
  const knowledgeSelectChange = (info) => {
    setKnowledgeSelected(info.key)
    queryDiscussions(info.key)
    getNewKnowledge(info.key)
    setChapterId(
      menu.filter(
        (item) => item.tasks.filter((task) => task.id == info.key)[0]
      )[0].id
    )
  }
  const [discussionTitle, setDiscussionTitle] = useState('')
  const [discussionContent, setDiscussionContent] = useState('')
  const [uploadImageList, setUploadImageList] = useState([] as any[])
  const [createKnowledge, setCreateKnowledge] = useState("")
  const uploadOption = {
    name: 'discussion_picture',
    actionUrl: 'course/api/discussion/upload_picture/',
    uploadData: { sessionid: Cookies.get('sessionid') },
    uploadImageList: uploadImageList,
    setUploadImageList: setUploadImageList,
  }
   /**
   * 发布知识点讨论
   */
  const publishKnowledgeCellDiscussion = () => {
    sendHttpPostRequest(
      'course/api/discussion/publish_kc_discussion/',
      {
        sessionid: Cookies.get('sessionid'),
        course_id: course_id,
        know_id: createKnowledge,
        title: discussionTitle,
        content: discussionContent,
        upload_image_list: JSON.stringify(uploadImageList),
      },
      (response: any) => {
        message.destroy()
        if (response.code == -100) {
          // 未登录
          document.getElementById('show-login-modal')!.click()
        } else if (response.code == 0) {
          // 成功
          message.success('发表讨论成功')
          setDiscussionContent('')
          setDiscussionTitle('')
          setIsAddEditorVis(false)
          setUploadImageList([])
          getNewKnowledge(createKnowledge)
          queryDiscussions(createKnowledge)
          setKnowledgeSelected(createKnowledge.toString())
        } else if (response.code == -501) {
          // 权限不足
          window.location.href = '/'
        } else if (response.code == -509) {
          // 未阅读社区协议
        } else {
          message.error(response.message)
        }
      }
    )
  }

  const DiscusTabPane = (
    <div>
      {isAddEditorVis && (
        <div className={discussStyle.editorContain}>
          <div className={discussStyle.titleContain}>
            <span className={discussStyle.newTitle}>标题</span>
            <Input
              style={{ fontSize: '14px', fontWeight: 700 }}
              bordered={false}
              placeholder={'请输入文章标题'}
              onChange={(e)=>{setDiscussionTitle(e.target.value)}}
            />
          </div>
          <div className={discussStyle.contentContain}>
            <div className={discussStyle.contentTitle}>内容</div>
            <div className={discussStyle.editorBox}>
              <MarkDownEditor      onChange={({ html, text }) => {
                  setDiscussionContent(text)
                }}
                uploadOption={uploadOption} height={154} placeholder={'请输入文章内容'} />
            </div>
          </div>
          <div className={discussStyle.knowledgeSelectContain}>
            <span className={discussStyle.newTitle}>知识点</span>
            <Select
              options={menu?.map((chapt: MenuType) => {
                return {
                  label: chapt.name,
                  options: chapt.tasks.map((task: TaskType) => {
                    return { label: task.name, value: task.id }
                  }),
                }
              })}
              placeholder={'请选择知识点'}
              className={discussStyle.selectInput}
              bordered={false}
              onChange={(value)=>{setCreateKnowledge(value)}}
            />
          </div>
          <div className={discussStyle.actionBtnContain}>
            <Button
              onClick={cancelNewArticle}
              style={{ marginRight: '20px' }}
              className={discussStyle.defBtn}
            >
              取消
            </Button>
            <Button onClick={publishKnowledgeCellDiscussion} type={'primary'} className={discussStyle.publishBtn}>
              发布
            </Button>
          </div>
        </div>
      )}
      {discussions.map((discus, disIdx) => (
        <div
          key={'dis' + disIdx}
          style={{
            marginBottom: '20px',
            paddingBottom: '20px',
            borderBottom: '1px solid #eee',
          }}
        >
          <DiscussItem
            discussions={discussions}
            discuss={discus}
            setDiscussions={setDiscussions}
            index={disIdx}
            editorStateInit={editorStateInit}
            tagShow={true}
            knowledgeCellId={knowledgeSelected}
            courseId={course_id}
            submitCallBack={queryDiscussions}
            knowledgeName={knowledgeName}
          />
          {discus?.reply_count > 2 && !isAllRepliesVis
            ? JSON.parse(JSON.stringify(discus?.reply_list))
                .splice(0, 2)
                .map((reply, rpIdx) => (
                  <div key={rpIdx + 'reply'}>
                    <ReplyItem
                      type={{ from: 'course', course_id: course_id }}
                      reply={reply}
                      replies={replies}
                      setReplies={setReplies}
                      index={rpIdx}
                      editorStateInit={editorStateInit}
                      submitCallBack={queryDiscussions}
                    />
                  </div>
                ))
            : discus?.reply_list.map((reply, rpIdx) => (
                <div key={rpIdx + 'reply'}>
                  <ReplyItem
                    type={{ from: 'course', course_id: course_id }}
                    reply={reply}
                    replies={replies}
                    setReplies={setReplies}
                    index={rpIdx}
                    editorStateInit={editorStateInit}
                    submitCallBack={queryDiscussions}
                  />
                  {reply.comment_list?.map((comment, cmIdx) => (
                    <div key={comment.id} style={{ marginLeft: '37px' }}>
                      <ReplyItem
                        type={{ from: 'community' }}
                        reply={comment}
                        replies={replies}
                        setReplies={setReplies}
                        index={cmIdx}
                        editorStateInit={editorStateInit}
                        submitCallBack={queryDiscussions}
                      />
                      {comment.comment_reply_list?.map((cr, crIdx) => (
                        <ReplyItem
                          type={{ from: 'community' }}
                          key={cr.id}
                          reply={cr}
                          replies={replies}
                          setReplies={setReplies}
                          index={crIdx}
                          editorStateInit={editorStateInit}
                          submitCallBack={queryDiscussions}
                          replayToName={cr.reply_to_name}
                        />
                      ))}
                    </div>
                  ))}
                </div>
              ))}
          {discus?.reply_count > 2 && (
            <div
              onClick={() => {
                setIsAllRepliesVis(!isAllRepliesVis)
                editorStateInit()
              }}
            >
              <div className={discussStyle.showAllBtn}>
                {isAllRepliesVis ? '收起' : '展开'}
                {`全部${discus?.reply_count}条回复>`}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  )

  return (
    <div className={discussStyle.tabpaneWrapper}>
      <Tabs onChange={onDiscussTabChange} defaultActiveKey="1">
        <Tabs.TabPane tab="讨论" key="1">
          <ChatRoom course_id={course_id} teacherInfo={teacherInfo} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="知识点讨论" key="2">
          <div className={discussStyle.discussContain}>
            <div className={discussStyle.discussLeftMenu}>
              <Menu
                selectedKeys={knowledgeSelected}
                onClick={knowledgeSelectChange}
              >
                {menu?.map((sub: any, subIdx) => (
                  <Menu.ItemGroup
                    key={sub.id}
                    title={number2text(subIdx + 1, 'lower') + '、' + sub.name}
                  >
                    {sub.tasks?.map((task, taskIdx) => (
                      <Menu.Item key={task.id}>
                        {subIdx + 1}.{taskIdx + 1} {task.name}
                      </Menu.Item>
                    ))}
                  </Menu.ItemGroup>
                ))}
              </Menu>
            </div>
            <div className={discussStyle.discussRightContent}>
              <div className={discussStyle.discussRightTab}>
                <Tabs onChange={onKnowledgeChange} defaultActiveKey="all">
                  <Tabs.TabPane tab="全部" key="all">
                    {DiscusTabPane}
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="我的" key="my">
                    {DiscusTabPane}
                  </Tabs.TabPane>
                </Tabs>
                <Row className={discussStyle.rightActionBox} align="middle">
                  <Col>
                    <SearchInputCom
                      placeholder={'根据标题/内容关键字搜索'}
                      onSearch={onSearchDiscuss}
                      onChange={onDiscussChange}
                    />
                  </Col>
                  <Col>
                    <Button
                      style={{ marginLeft: '20px' }}
                      className={discussStyle.pribtn}
                      onClick={() => setIsAddEditorVis(true)}
                      type="primary"
                    >
                      +发文
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}

export default CourseMainDiscuss
