import {
  Button,
  Col,
  ConfigProvider,
  Input,
  Layout,
  message,
  Modal,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Statistic,
  Table,
  Upload,
  Tabs,
  Popover,
  Radio,
  InputNumber,
} from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { RouteComponentProps } from 'react-router'
import React, { useEffect, useRef, useState } from 'react'
import OAMSider from '../oam-sider'
import idataCourseStyles from '../../common/global-styles/idatacourse-table.module.less'
import zhCN from 'antd/lib/locale/zh_CN'
import { sendHttpPostRequest } from '../../../api/http-request'
import Cookies from 'js-cookie'
import courseCreateStyles from '../../course/course-create.module.less'
import moduleStyles from './module.module.less'
import {
  PlayCircleOutlined,
  UpOutlined,
  DownOutlined,
  SearchOutlined,
  DeleteOutlined,
  FileSearchOutlined,
  PlusSquareOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import Draggable from 'react-draggable'
import * as constants from '../../../constants/constants'
const { Search } = Input
const { TabPane } = Tabs
const { Option } = Select
import ExerciseCard from '../../exercise/exercise-card'
import { on } from 'stream'
export interface Props {}
export interface Props2 {}
export interface Props3 {}

import selfCss from './module-edit.module.less'

interface moduleInfo {
  id: number
  name: string
  description: string
  lecture_url: string
  video_url: string
  practice: any
  exam: any
  case: any
}

const typeMap = new Map([
  [1, '实操题'],
  [2, '单选题'],
  [3, '多选题'],
  [4, '排序题'],
  [5, '主观题'],
])
const difficultyMap = new Map([
  [1, '容易'],
  [2, '中等'],
  [3, '困难'],
])

export const OAMModuleEdit: React.FC<Props> = (props) => {
  const moduleId = parseInt(window.location.search.split('=')[1]) // moduleId
  const [module, setModule] = useState(null as unknown as moduleInfo) // 知识点对象
  const [moduleResult, setModuleResult] = useState([] as any[]) // 当前显示的结果页
  const [deleteKnowledgeVisible, setDeleteKnowledgeVisible] = useState(false)
  const [addExerciseVisible, setAddExerciseVisible] = useState(false)

  const selectedCaseRowKeys = React.useRef([] as any) //选择的实验
  const selectedCaseRowItem = React.useRef([] as any) //选择的实验

  const selectedExercises = React.useRef([] as any) //选择的练习题
  const [moduleExercises, setModuleExercises] = useState([] as any[]) //当前知识点的练习题列表

  const selectedTestExamExercises = React.useRef([] as any[]) //选择的作业考试题
  const [moduleTestExamExercises, setModuleTestExamExercises] = useState(
    [] as any[]
  ) //当前知识点的作业考试题列表

  const [addCaseVisible, setAddCaseVisible] = useState(false)
  const [exerciseResult, setExerciseResult] = useState([] as any[])
  const [exercise, setExercise] = useState([] as any[])
  const [testexam, setTestexam] = useState([] as any[])
  const [addTestexamVisible, setTestexamVisible] = useState(false)
  const [testexamResult, setTestexamResult] = useState([] as any[])
  const [disabled, setDisabled] = useState(true)

  const [isUploading, setIsUploading] = useState(false) //是否正在上传文件

  const [moduleName, setModuleName] = useState(null as any) //知识点名称
  const [moduleType, setModuleType] = useState(1) //1课件 2 视频
  const [selectedModuleCase, setSelectedModuleCase] = useState(null as any) //选择的实验
  const [moduleCase, setModuleCase] = useState(null as any) //知识点的实验
  const [moduleLectureUrl, setModuleLectureUrl] = useState(null as any) //pdf地址
  const [moduleLectureName, setModuleLectureName] = useState(null as any) //pdf名称
  const [moduleLectureSize, setModuleLectureSize] = useState('342M') //pdf大小

  const [moduleLectureSizeBytes, setModuleLectureSizeBytes] = useState(0) //pdf大小
  const [moduleLecturePages, setModuleLecturePages] = useState(0) //pdf页码数

  const [moduleVideoUrl, setModuleVideoUrl] = useState(null as any) //视频url地址
  const [moduleVideoName, setModuleVideoName] = useState(null as any) //视频名称

  const [moduleVideoDuration, setModuleVideoDuration] = useState('0秒') //视频时长
  const [moduleVideoSize, setModuleVideoSize] = useState('0M') //视频大小

  const [moduleVideoDurationSeconds, setModuleVideoDurationSeconds] =
    useState(0) //视频时长
  const [moduleVideoSizeBytes, setModuleVideoSizeBytes] = useState(0) //视频大小

  const [exerciseData, setExerciseData] = useState([] as any[])
  const [caseData, setCaseData] = useState([] as any[])

  const exeQueryContent = useRef('') // 记录搜索串
  const exePage = useRef(1) // 记录当前的页数
  const exePageSize = useRef(10) // 记录当前每页展示数量
  const queryExeSource = useRef('2') // 来源 1-个人  2-OAM创建（系统）
  const exeOrder = useRef('') // 排序升序降序
  const exeOrderBy = useRef('') // 排序列
  const exeTypeFilters = useRef([]) // 类型过滤选项
  const exeDifficultyFilters = useRef([]) // 难易程度过滤选项

  const [exeTotalSum, setExeTotalSum] = useState(0) // 搜索返回的结果数
  const [exeTotalCount, setExeTotalCount] = useState(0) // 搜索返回的结果数

  const caseQueryContent = useRef('') // 记录搜索串
  const casePage = useRef(1) // 记录当前的页数
  const casePageSize = useRef(10) // 记录当前每页展示数量
  const [caseTotalSum, setCaseTotalSum] = useState(0) // 搜索返回的结果数
  const queryCaseSource = useRef('2') // 搜索实验的来源， 来源 1 个人  2 系统
  const caseOrder = useRef('') // 排序升序降序
  const caseOrderBy = useRef('') // 排序列

  const [caseTotalCount, setCaseTotalCount] = useState(0) // 搜索返回的结果数

  const draggleRef = useRef<HTMLDivElement | null>(null)
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
  const onStart = (event: any, uiData: any) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement
    const targetRect = draggleRef?.current?.getBoundingClientRect()
    if (targetRect) {
      setBounds({
        left: -targetRect?.left + uiData?.x,
        right: clientWidth - (targetRect?.right - uiData?.x),
        top: -targetRect?.top + uiData?.y,
        bottom: clientHeight - (targetRect?.bottom - uiData?.y),
      })
    }
  }

  // 查询知识点列表回调
  const queryModuleCallback = (response: any) => {
    message.destroy()
    if (response.code == -100) {
      // 未登录
      window.location.href = `/oam_login?next=oam_module_edit?module_id=${moduleId}`
    } else if (response.code == 0) {
      // 成功
      setModule(response?.query_info)
      setModuleType(response?.query_info.type)
      setModuleName(response?.query_info.name)
      setModuleExercises(response?.query_info.practice)
      setModuleTestExamExercises(response?.query_info.exam)
      setModuleCase(response?.query_info.case)
      setModuleLectureUrl(response?.query_info.lecture_url)
      setModuleLectureSize(response?.query_info.lecture_size_str)
      // setModuleLectureName(response?.query_info.lecture_url.split("\\")[1]); // Windows写法
      setModuleVideoUrl(response?.query_info.video_url)
      setModuleVideoSize(response?.query_info.video_size_str)
      setModuleVideoDuration(response?.query_info.video_duration_str)

      setModuleVideoDurationSeconds(response?.query_info.video_duration)
      setModuleVideoSizeBytes(response?.query_info.video_size)
      setModuleLectureSizeBytes(response?.query_info.lecture_size)
      setModuleLecturePages(response?.query_info.lecture_pages)

      setModuleLectureName(response?.query_info.lecture_url.split('\\')[1])
      console.log(moduleLectureUrl, setModuleLectureName)
      setModuleVideoUrl(response?.query_info.video_url)
      setModuleVideoName(response?.query_info.video_url.split('\\')[1])
    } else if (response.code == -501) {
      // 权限不足
      window.location.href = `/oam_login?next=oam_module_edit?module_id=${moduleId}`
    } else {
      message.error(response.message)
    }
  }

  // 查询知识点列表
  const queryModule = () => {
    sendHttpPostRequest(
      'course/api/oam_query_knowcell_by_id',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        know_id: moduleId,
      },
      queryModuleCallback
    )
  }

  useEffect(() => {
    queryModule()
  }, [])

  // 查作业考试题列表
  const queryExerciseList = () => {
    sendHttpPostRequest(
      'exercise/api/oam_query_exercise_list',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        query: exeQueryContent.current,
        page: exePage.current,
        pagesize: exePageSize.current,
        order: exeOrder.current,
        order_by: exeOrderBy.current,
        type: JSON.stringify(exeTypeFilters.current),
        difficulty: JSON.stringify(exeDifficultyFilters.current),
        source: queryExeSource.current,
        source_page: 2, // 知识点2
        source_key: 1, // OAM1
        published: 1, // 只查询发布的
      },
      (response: any) => {
        if (response.is_ok && response.code == 0) {
          setExerciseData(response.exercise_list)
          setExeTotalSum(response.total_sum)
          setExeTotalCount(response.total_count)
        } else {
          console.error('查询习题出错了')
        }
      }
    )
  }

  // 选题点击了搜索
  const onExeSearchBtn = (event: any) => {
    exePage.current = 1
    exeQueryContent.current = event.target.value
    queryExerciseList()
  }

  // 查案例列表
  const queryCaseList = () => {
    sendHttpPostRequest(
      'case/api/oam_query_case_list',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        query: caseQueryContent.current,
        page: casePage.current,
        pagesize: casePageSize.current,
        source: queryCaseSource.current,
        order: caseOrder.current,
        order_by: caseOrderBy.current,
      },
      (response: any) => {
        if (response.is_ok && response.code == 0) {
          setCaseData(response.case_list)
          setCaseTotalSum(response.total_sum)
          setCaseTotalCount(response.total_count)
        } else {
          console.error('查询案例出错了')
        }
      }
    )
  }

  // 案例点击了搜索
  const onCaseSearchBtn = (e: any) => {
    casePage.current = 1
    caseQueryContent.current = e.target.value
    queryCaseList()
  }

  // 实验结果表格发生变化
  const onCaseTableChange: any = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: { currentDataSource: []; action: any }
  ) => {
    if (sorter.order) {
      //如果选择了排序列
      caseOrder.current = sorter.order
      caseOrderBy.current = sorter.field
    }

    casePage.current = 1
    queryCaseList()
  }

  // 切换习题结果Tab页
  const onExeTabKeyChange = (key: string) => {
    queryExeSource.current = key
    exePage.current = 1
    queryExerciseList()
  }

  // 习题结果表格发生变化
  const onExeTableChange: any = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: { currentDataSource: []; action: any }
  ) => {
    if (sorter.order) {
      //如果选择了排序列
      exeOrder.current = sorter.order
      exeOrderBy.current = sorter.field
    }
    if (filters.type) {
      //如果选择了过滤列
      exeTypeFilters.current = filters.type
    } else {
      exeTypeFilters.current = []
    }
    if (filters.difficulty) {
      exeDifficultyFilters.current = filters.difficulty
    } else {
      exeDifficultyFilters.current = []
    }
    exePage.current = 1
    queryExerciseList()
  }

  // 选择实验
  const caseRowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      selectedCaseRowKeys.current = selectedRowKeys
      selectedCaseRowItem.current = selectedRows
      setSelectedModuleCase({
        case_id: selectedCaseRowKeys.current[0],
        name: selectedRows[0].name,
      })
      // setModuleCase({
      //   case_id: selectedCaseRowKeys.current[0],
      //   name: selectedRows[0].name,
      // })
    },
    getCheckboxProps: (record: any) => ({
      disabled: module.case?.case_id == record.case_id,
      //name: record.name,
    }),
  }

  // 每次初始化习题列表
  const initExercise = () => {
    exePage.current = 1
    exeQueryContent.current = ''
    queryExerciseList()
  }

  // 每次初始化实验列表
  const initCase = () => {
    casePage.current = 1
    caseQueryContent.current = ''
    queryCaseList()
  }

  //选择作业考试题
  const onExerciseTestExamSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      selectedTestExamExercises.current = selectedRows
    },
    getCheckboxProps: (record: any) => ({
      disabled: module?.exam?.filter((item) => item.id == record.id).length > 0,
      //name: record.name,
    }),
  }

  const handleAddTestexamOk = () => {
    setTestexamVisible(false)

    const newModuleExercises = [...moduleTestExamExercises]

    selectedTestExamExercises.current.map((row) => {
      newModuleExercises.push(row)
    })

    setModuleTestExamExercises(newModuleExercises)
    module.exam = newModuleExercises
    onSaveModuleBtnClick(null as any)
  }
  /** 渲染预览的习题 */
  const renderExercisePreview = (record: any) => {
    return (
      <ExerciseCard
        exercise={record}
        renderType="white"
        teacherOnly={true}
        editorMode={true}
        showAnser={false}
        showClean={true}
      />
    )
  }

  const handleAddCaseOk = () => {
    setModuleCase({
      case_id: selectedCaseRowKeys.current[0],
      name: selectedCaseRowItem.current[0]?.name,
    })
    module.case = selectedModuleCase
    onSaveModuleBtnClick(null as any)
  }

  const handleDeleteKnowledge = () => {
    setDeleteKnowledgeVisible(false)
  }

  //选择练习题
  const onExerciseSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      selectedExercises.current = selectedRows
    },
    getCheckboxProps: (record: any) => ({
      disabled:
        module?.practice?.filter((item) => item.id == record.id).length > 0,
      //name: record.name,
    }),
  }

  const handleAddExerciseOk = () => {
    setAddExerciseVisible(false)

    const newModuleExercises = [...moduleExercises]

    selectedExercises.current.map((row) => {
      newModuleExercises.push(row)
    })

    setModuleExercises(newModuleExercises)
    module.practice = newModuleExercises
    onSaveModuleBtnClick(null as any)
  }

  const onModuleNameChange = (event: any) => {
    setModuleName(event.target.value)
    module['name'] = event.target.value
    //onSaveModuleBtnClick(null as any);
  }

  // 点击保存
  const onSaveModuleBtnClick = (event: any) => {
    sendHttpPostRequest(
      'course/api/oam_update_knowcell',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        know_id: moduleId,
        name: module.name,
        type: moduleType,
        lecture_url: moduleLectureUrl,
        video_url: moduleVideoUrl,
        case: JSON.stringify(module.case),
        exam: JSON.stringify(module.exam),
        practice: JSON.stringify(module.practice),
        lecture_pages: moduleLecturePages,
        lecture_size: moduleLectureSizeBytes,
        video_size: moduleVideoSizeBytes,
        video_duration: moduleVideoDurationSeconds,
      },
      (response: any) => {
        //message.destroy();
        if (response.code == -100) {
          // 未登录
          window.location.href = `/oam_login?next=oam_module_edit?module_id=${moduleId}`
        } else if (response.is_ok == true) {
          // 成功
          message.success('更新知识点成功')
          queryModule()
          setAddCaseVisible(false)

          //queryModule();
          try {
            window.opener.location.reload()
          } catch (e) {
            console.error(e)
          }
        } else if (response.code == -501) {
          // 权限不足
          window.location.href = `/oam_login?next=oam_module_edit?module_id=${moduleId}`
        } else {
          message.error(response.message)
        }
      }
    )
  }

  // 点击了查看按钮
  const onLookOverBtnClick = (event: any) => {
    //message.success('查看数据' + event.currentTarget.value)
    event.preventDefault()
    // window.open('/oam_menu_detail?menu_id=' + event.currentTarget.value)
  }

  // 点击 删除练习题
  const onDeleteBtnClick = (exerciseId: string) => {
    module.practice = moduleExercises.filter((item) => item.id !== exerciseId)
    setModuleExercises(moduleExercises.filter((item) => item.id !== exerciseId))
    onSaveModuleBtnClick(null as any)
  }

  // 点击 删除作业考试题
  const onDeleteTestExamBtnClick = (exerciseId: string) => {
    module.exam = moduleTestExamExercises.filter(
      (item) => item.id !== exerciseId
    )
    setModuleTestExamExercises(
      moduleTestExamExercises.filter((item) => item.id !== exerciseId)
    )
    onSaveModuleBtnClick(null as any)
  }

  const renderExerciseActions = (text: any, record: any) => {
    return (
      <div style={{ color: '#999', paddingLeft: 36 }}>
        {/* <FileSearchOutlined style={{marginRight:10,fontSize:16,cursor:'pointer'}}/> */}

        <Popconfirm
          placement="top"
          title={'确认删除习题"' + record.title + '"吗？'}
          onConfirm={onDeleteBtnClick.bind(this, record.id)}
          okText="确认"
          cancelText="取消"
        >
          <DeleteOutlined style={{ fontSize: 16, cursor: 'pointer' }} />
        </Popconfirm>
      </div>
    )
  }

  const renderExerciseTestExamActions = (text: any, record: any) => {
    return (
      <div style={{ color: '#999', paddingLeft: 36 }}>
        {/* <FileSearchOutlined style={{marginRight:10,fontSize:16,cursor:'pointer'}}/> */}

        <Popconfirm
          placement="top"
          title={'确认删除习题"' + record.title + '"吗？'}
          onConfirm={onDeleteTestExamBtnClick.bind(this, record.id)}
          okText="确认"
          cancelText="取消"
        >
          <DeleteOutlined style={{ fontSize: 16, cursor: 'pointer' }} />
        </Popconfirm>
      </div>
    )
  }

  const getVideoPreview = () => {
    return (
      <div style={{ background: '#000' }}>
        {moduleVideoUrl !== '' && (
          <video
            style={{ marginTop: 25, marginBottom: 25, height: 320, width: 500 }}
            preload="preload"
            controls={true}
            controlsList="nodownload"
          >
            <source
              src={
                moduleVideoUrl?.startsWith('http')
                  ? moduleVideoUrl
                  : constants.BACKEND_URL +
                    '/course/api/get_stream_video?path=' +
                    moduleVideoUrl
              }
              type={'video/mp4'}
            />
            您的浏览器不支持 HTML5 video 标签。
          </video>
        )}
      </div>
    )
  }

  // 渲染类型
  const renderType = (text: any, record: any) => (
    <div>{typeMap.get(parseInt(text))}</div>
  )

  // 渲染难易程度
  const renderDifficulty = (text: any, record: any) => (
    <div>{difficultyMap.get(parseInt(text))}</div>
  )

  // 习题页码和页数发生了变化
  const onExePageChange = (
    newPage: number,
    newPageSize?: number | undefined
  ) => {
    exePage.current = newPage ? newPage : 1
    if (newPageSize) {
      exePageSize.current = newPageSize
    }
    queryExerciseList()
  }

  // 案例页码和页数发生了变化
  const onCasePageChange = (
    newPage: number,
    newPageSize?: number | undefined
  ) => {
    casePage.current = newPage ? newPage : 1
    if (newPageSize) {
      casePageSize.current = newPageSize
    }
    queryCaseList()
  }

  const exerciseResultCols: any = [
    {
      title: '序号',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      align: 'center',
      width: '10%',
      render: (text, record, index) => index + 1,
    },
    {
      title: '名称',
      dataIndex: 'title',
      key: 'title',
      ellipsis: true,
      align: 'left',
      width: '30%',
    },
    {
      title: '题型',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      width: '15%',
      render: renderType,
    },
    {
      title: '难度',
      dataIndex: 'difficulty',
      key: 'difficulty',
      align: 'center',
      width: '15%',
      render: renderDifficulty,
    },

    {
      title: '操作',
      dataIndex: 'id',
      key: 'actions',
      width: '20%',
      ellipsis: false,
      align: 'center',
      fixed: 'right',
      render: renderExerciseActions,
    },
  ]

  const exerciseTestExamResultCols: any = [
    {
      title: '序号',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      align: 'center',
      width: '10%',
      render: (text, record, index) => index + 1,
    },
    {
      title: '名称',
      dataIndex: 'title',
      key: 'title',
      ellipsis: true,
      align: 'left',
      width: '30%',
    },
    {
      title: '题型',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      width: '15%',
      render: renderType,
    },
    {
      title: '难度',
      dataIndex: 'difficulty',
      key: 'difficulty',
      align: 'center',
      width: '15%',
      render: renderDifficulty,
    },

    {
      title: '操作',
      dataIndex: 'id',
      key: 'actions',
      width: '20%',
      ellipsis: false,
      align: 'center',
      fixed: 'right',
      render: renderExerciseTestExamActions,
    },
  ]

  const onPreviewCase = (id: string, event: any) => {
    event.preventDefault()
    window.open('/oam-case-preview?case_id=' + id)
  }
  const renderActions = (text: any, record: any) => {
    return (
      <Button
        className={courseCreateStyles.modalBtn}
        type="primary"
        size="small"
        style={{ height: 28, width: 60 }}
      >
        预览
      </Button>
    )
  }

  const renderCaseActions = (text: any, record: any) => {
    return (
      <Button
        className={courseCreateStyles.modalBtn}
        type="primary"
        size="small"
        style={{ height: 28, width: 60 }}
        onClick={onPreviewCase.bind(this, record.case_id)}
      >
        预览
      </Button>
    )
  }

  // 搜索切换Tab页
  const onCaseTabKeyChange = (key: string) => {
    queryCaseSource.current = key
    casePage.current = 1
    queryCaseList()
  }

  const caseCols: any = [
    {
      title: '序号',
      dataIndex: 'case_id',
      key: 'case_id',
      align: 'center',
      width: 80,
      render: (text, record, index) =>
        (casePage.current - 1) * casePageSize.current + index + 1,
    },
    {
      title: '实验名称',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: 200,
      ellipsis: true,
    },
    {
      title: '领域',
      dataIndex: 'domain',
      key: 'domain',
      align: 'center',
      width: 100,
    },
    {
      title: '创建时间',
      dataIndex: 'create_time',
      key: 'create_time',
      align: 'center',
      width: 150,
      sorter: true,
      ellipsis: true,
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'descend',
    },
    {
      title: '操作',
      dataIndex: 'actions',
      key: 'actions',
      width: 100,
      ellipsis: false,
      align: 'center',
      fixed: 'right',
      render: renderCaseActions,
    },
  ]

  const exerciseCols: any = [
    {
      title: '序号',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      align: 'left',
      width: '10%',
      render: (text, record, index) =>
        (exePage.current - 1) * exePageSize.current + index + 1,
    },
    {
      title: '题目名称',
      dataIndex: 'title',
      key: 'title',
      ellipsis: true,
      align: 'left',
      width: '30%',
    },
    {
      title: '题型',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      width: '15%',
      render: renderType,
      onFilter: (value: any, record: any) => true,
      filters: [
        {
          text: '实操题',
          value: 1,
        },
        {
          text: '单选题',
          value: 2,
        },
        {
          text: '多选题',
          value: 3,
        },
        {
          text: '排序题',
          value: 4,
        },
        {
          text: '主观题',
          value: 5,
        },
      ],
    },
    {
      title: '难度',
      dataIndex: 'difficulty',
      key: 'difficulty',
      align: 'center',
      width: '15%',
      render: renderDifficulty,
      filters: [
        {
          text: '容易',
          value: 1,
        },
        {
          text: '中等',
          value: 2,
        },
        {
          text: '困难',
          value: 3,
        },
      ],
      onFilter: (value: any, record: any) => true,
    },
    {
      title: '操作',
      dataIndex: 'id',
      key: 'actions',
      width: '20%',
      ellipsis: false,
      align: 'center',
      fixed: 'right',
      render: renderActions,
    },
  ]

  // 上传文件传参
  const uploadData = {
    sessionid: Cookies.get('sessionid'),
    sid: Cookies.get('sessionid'),
    knowcell_id: moduleId,
  }

  // 上传文件
  const uploadProps = {
    accept: moduleType == 2 ? '.mp4,.mov' : '.pdf',
    name: 'data_file',
    multiple: false,
    showUploadList:
      isUploading == false
        ? false
        : {
            showPreviewIcon: false,
            showRemoveIcon: false,
            showDownloadIcon: false,
            previewIcon: false,
          },
    data: uploadData,
    progress: { strokeColor: '#07c160', strokeWidth: 2, showInfo: false },
    action: constants.BACKEND_API_URL + 'course/api/knowcell_upload_video/',
    onChange(info) {
      const { status } = info.file
      if (status == 'uploading') {
        setIsUploading(true)
        console.log(info.file, info.fileList)
      }
      if (status === 'done') {
        setIsUploading(false)
        message.destroy()
        if (info.file.response.is_ok) {
          message.success(`文件${info.file.name}上传成功！`)
          if (info.file.response.type == 1) {
            module.lecture_url = info.file.response.url
            setModuleLectureUrl(module.lecture_url)
            setModuleLectureSize(info.file.response.size_str)
            setModuleLectureSizeBytes(info.file.response.size)
            setModuleLecturePages(info.file.response.pages)

            module.video_url = ''
          } else if (info.file.response.type == 2) {
            module.video_url = info.file.response.url
            setModuleVideoUrl(module.video_url)

            setModuleVideoDuration(info.file.response.duration_str)
            setModuleVideoSize(info.file.response.size_str)

            setModuleVideoDurationSeconds(info.file.response.duration)
            setModuleVideoSizeBytes(info.file.response.size)
            console.log(info.file, info.fileList)
            module.lecture_url = ''
          }
          //onSaveModuleBtnClick(null as any);
        } else {
          if (info.file.response.code == -100) {
            // 未登录
            window.location.href = `/oam_login?next=oam_module_edit?${moduleId}`
          } else if (info.file.response.code == -501) {
            // 权限不足
            window.location.href = `/oam_login?next=oam_module_edit?${moduleId}`
          } else {
            message.error(info.file.response.message)
          }
        }
      } else if (status === 'error') {
        message.error(`文件${info.file.name}上传失败了:(`)
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files)
    },
  }

  return (
    <Layout style={{ minHeight: '100vh', minWidth: 1200 }}>
      <OAMSider keys={'33'} openKeys={['sub-course']}></OAMSider>
      <Layout style={{ backgroundColor: '#f9f9f9' }}>
        <Content
          style={{
            backgroundColor: '#fff',
            borderRadius: 5,
            width: 900,
            margin: 30,
            paddingTop: 20,
          }}
        >
          <div style={{ paddingLeft: 30, paddingRight: 30 }}>
            <div>
              <Row
                align="middle"
                style={{ borderBottom: '1px solid #eee', height: 60 }}
              >
                <Col span={12} style={{ textAlign: 'left' }}>
                  {' '}
                  <div style={{ fontWeight: 500, fontSize: 16 }}>
                    编辑知识点
                  </div>{' '}
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                  <Button
                    className={courseCreateStyles.stepsBtn}
                    style={{ marginRight: 20, color: '#666' }}
                    type="default"
                    onClick={() => {
                      window.open(`/module-learn-preview?module_id=${moduleId}`)
                    }}
                  >
                    预览
                  </Button>
                  <Button
                    className={courseCreateStyles.stepsBtn}
                    style={{ marginRight: 0, color: '#fff' }}
                    type="primary"
                    onClick={onSaveModuleBtnClick}
                  >
                    保存
                  </Button>{' '}
                </Col>
              </Row>

              <div style={{ paddingTop: 20, width: 800, margin: '0 auto' }}>
                <Row style={{ marginBottom: 10 }}>
                  <Col flex="auto" style={{ textAlign: 'left' }}>
                    <span style={{ fontSize: 18 }}>名称</span>
                    <Input
                      placeholder="名称/ID"
                      style={{ width: 250, margin: '0 6px' }}
                      value={moduleName}
                      onChange={onModuleNameChange}
                    />
                  </Col>
                  <Col
                    flex="450px"
                    style={{
                      textAlign: 'left',
                      color: '#ccc',
                      fontSize: 12,
                      lineHeight: '30px',
                    }}
                  >
                    提示：知识点包含视频、练习题、实验、作业考试题等元素，其中讲义为必要元素。
                  </Col>
                </Row>

                <Row style={{ height: 40, lineHeight: '40px' }}>
                  <Col
                    flex="30px"
                    style={{ color: '#07c160', fontSize: 20, marginRight: 10 }}
                  >
                    ①
                  </Col>
                  <Col flex="120px" style={{ textAlign: 'left', fontSize: 18 }}>
                    <span
                      style={{ color: 'rgba(255, 75, 80, 1)', marginLeft: 5 }}
                    >
                      *
                    </span>
                    上传讲义
                  </Col>

                  <Col flex="auto" style={{ textAlign: 'left' }}>
                    <Radio.Group
                      onChange={(e) => {
                        setModuleType(e.target.value)
                      }}
                      value={moduleType}
                      size="large"
                    >
                      <Radio value={2}>视频</Radio>
                      <Radio value={1}>PDF</Radio>
                    </Radio.Group>
                  </Col>
                </Row>
                <div
                  style={{
                    marginLeft: 50,
                    marginTop: 10,
                    marginBottom: 10,
                    textAlign: 'left',
                  }}
                >
                  <Upload {...uploadProps}>
                    <Button
                      size="small"
                      style={{
                        borderColor: '#07c160',
                        width: 80,
                        height: 32,
                        color: '#07c160',
                      }}
                    >
                      <UploadOutlined style={{ margin: 0 }} />
                      上传
                    </Button>
                    {moduleType != 1 && (
                      <span
                        style={{
                          color: '#999',
                          textAlign: 'left',
                          marginLeft: 20,
                        }}
                      >
                        请上传1G以内的视频，支持mp4、mov格式。
                      </span>
                    )}
                    {moduleType == 1 && (
                      <span
                        style={{
                          color: '#999',
                          textAlign: 'left',
                          marginLeft: 20,
                        }}
                      >
                        请上传100M以内的PDF文件
                      </span>
                    )}
                  </Upload>
                </div>

                {/* {moduleType == 1 && (
                  <div>
                    <Row
                      gutter={[8, 8]}
                      justify="start"
                      align="middle"
                      style={{ marginTop: 0, marginBottom: 10 }}
                    >
                      <Col flex="30px"></Col>
                      <Col flex="auto" style={{ textAlign: 'left' }}>
                        <Input
                          placeholder="请输入pdf课件地址"
                          value={moduleLectureUrl}
                          onChange={(e) => {
                            setModuleLectureUrl(e.target.value)
                          }}
                          style={{ borderRadius: 3, width: '100%' }}
                        />
                      </Col>
                    </Row>
                  </div>
                )} */}

                {/* {moduleType !== 1 && (
                  <Row
                    gutter={[8, 8]}
                    justify="start"
                    align="middle"
                    style={{ marginTop: 0, marginBottom: 10 }}
                  >
                    <Col flex="30px"></Col>
                    <Col flex="auto" style={{ textAlign: 'left' }}>
                      <Input
                        placeholder="请输入视频地址"
                        value={moduleVideoUrl}
                        onChange={(e) => {
                          setModuleVideoUrl(e.target.value)
                        }}
                        style={{ borderRadius: 3, width: '100%' }}
                      />
                    </Col>
                  </Row>
                )} */}

                {moduleType !== 1 && moduleVideoUrl !== '' && (
                  <Row
                    gutter={[8, 8]}
                    justify="start"
                    align="middle"
                    style={{ marginBottom: 10 }}
                  >
                    <Col
                      flex="30px"
                      style={{ color: '#00000073', textAlign: 'left' }}
                    ></Col>
                    <Col flex="auto" style={{ textAlign: 'left' }}>
                      <div className={selfCss.cardImageDiv}>
                        <Popover
                          overlayStyle={{ padding: 0 }}
                          destroyTooltipOnHide={true}
                          placement="top"
                          trigger="click"
                          content={getVideoPreview()}
                        >
                          <PlayCircleOutlined
                            className={selfCss.imagePlayIcon}
                          />
                        </Popover>

                        <div
                          style={{
                            background: '#666',
                            height: 160,
                            width: 250,
                          }}
                        ></div>
                      </div>
                      <div style={{ marginTop: 10, marginBottom: 10 }}>
                        <span style={{ marginRight: 20, color: '#333' }}>
                          {moduleVideoName}
                        </span>
                        <span style={{ marginRight: 20, color: '#999' }}>
                          视频时长：{moduleVideoDuration}
                        </span>
                        <span style={{ marginRight: 20, color: '#999' }}>
                          视频大小：{moduleVideoSize}
                        </span>
                      </div>

                      {/* <Row style={{marginTop:10,marginBottom:10}}>
    
                                            <Col style={{marginRight:20,color:'#999'}}>视频时长：<InputNumber value={moduleVideoDurationSeconds} onChange={(value)=>{setModuleVideoDurationSeconds(value)}} /> 秒</Col>
                                            <Col style={{marginRight:20,color:'#999'}}>视频大小：<InputNumber  value={moduleVideoSizeBytes} onChange={(value)=>{setModuleVideoSizeBytes(value)}}/> Byte</Col>
                                        </Row> */}
                    </Col>
                  </Row>
                )}

                {moduleType == 1 && moduleLectureUrl !== '' && (
                  <Row
                    gutter={[8, 8]}
                    justify="start"
                    align="middle"
                    style={{ marginTop: -10, marginBottom: 0 }}
                  >
                    <Col
                      flex="50px"
                      style={{ color: '#00000073', textAlign: 'left' }}
                    ></Col>
                    <Col flex="auto" style={{ textAlign: 'left' }}>
                      <div style={{ marginTop: 10, marginBottom: 10 }}>
                        <span style={{ marginRight: 20, color: '#333' }}>
                          {moduleLectureName}
                        </span>
                        <span style={{ marginRight: 20, color: '#999' }}>
                          文件大小：{moduleLectureSize}
                        </span>
                      </div>

                      {/* <Row style={{ marginTop: 10, marginBottom: 10 }}>
                        <Col style={{ marginRight: 20, color: '#999' }}>
                          PDF页数：
                          <InputNumber
                            value={moduleLecturePages}
                            onChange={(value) => {
                              setModuleLecturePages(value)
                            }}
                          />{' '}
                          页
                        </Col>
                        <Col style={{ marginRight: 20, color: '#999' }}>
                          文件大小：
                          <InputNumber
                            value={moduleLectureSizeBytes}
                            onChange={(value) => {
                              setModuleLectureSizeBytes(value)
                            }}
                          />{' '}
                          Byte
                        </Col>
                      </Row> */}
                    </Col>
                  </Row>
                )}

                <Row style={{ height: 50, lineHeight: '50px', marginTop: 10 }}>
                  <Col
                    flex="30px"
                    style={{ color: '#07c160', fontSize: 20, marginRight: 10 }}
                  >
                    ②
                  </Col>
                  <Col
                    flex="100px"
                    style={{
                      textAlign: 'left',
                      backgroundColor: '#E8FAEF',
                      paddingLeft: 10,
                      fontSize: 18,
                    }}
                  >
                    添加实验
                  </Col>
                  <Col
                    flex="auto"
                    style={{
                      textAlign: 'right',
                      backgroundColor: '#E8FAEF',
                      paddingRight: 10,
                    }}
                  >
                    <Button
                      size="small"
                      type="primary"
                      style={{
                        borderColor: '#07c160',
                        width: 80,
                        height: 32,
                        color: '#fff',
                      }}
                      onClick={() => {
                        initCase()
                        setAddCaseVisible(true)
                      }}
                    >
                      <PlusSquareOutlined style={{ margin: 0 }} />
                      实验
                    </Button>
                  </Col>
                </Row>

                {!moduleCase?.case_id && (
                  <div
                    style={{
                      color: '#999',
                      textAlign: 'center',
                      height: 50,
                      lineHeight: '50px',
                      margin: '0 0 20px 40px',
                      border: '1px solid #E1F2E8',
                    }}
                  >
                    请添加实验
                  </div>
                )}
                {moduleCase?.case_id && (
                  <Row
                    style={{
                      color: '#333',
                      textAlign: 'left',
                      margin: '0 0 20px 40px',
                      height: 36,
                      lineHeight: '36px',
                      border: '1px solid #E1F2E8',
                    }}
                  >
                    <Col
                      onClick={(event: any) => {
                        event.preventDefault()
                        window.open(
                          '/oam-case-preview?case_id=' + module?.case?.case_id
                        )
                      }}
                      flex="auto"
                      style={{ paddingLeft: 10, cursor: 'pointer' }}
                      className={courseCreateStyles.caseName}
                    >
                      {' '}
                      {moduleCase?.case_id} {moduleCase?.name}{' '}
                    </Col>
                    <Col
                      flex="169px"
                      style={{
                        textAlign: 'center',
                        borderLeft: '1px solid #E1F2E8',
                        color: '#999',
                      }}
                    >
                      <FileSearchOutlined
                        style={{
                          marginLeft: 10,
                          marginRight: 10,
                          fontSize: 16,
                          cursor: 'pointer',
                        }}
                        onClick={(event: any) => {
                          event.preventDefault()
                          window.open(
                            '/oam-case-preview?case_id=' + module?.case?.case_id
                          )
                        }}
                      />
                      <DeleteOutlined
                        style={{ fontSize: 16, cursor: 'pointer' }}
                        onClick={() => {
                          module.case = {}
                          setModuleCase({})
                          onSaveModuleBtnClick(null as any)
                         
                        }}
                      />
                    </Col>
                  </Row>
                )}

                <Row style={{ height: 50, lineHeight: '50px' }}>
                  <Col
                    flex="30px"
                    style={{ color: '#07c160', fontSize: 20, marginRight: 10 }}
                  >
                    ③
                  </Col>
                  <Col
                    flex="120px"
                    style={{
                      textAlign: 'left',
                      backgroundColor: '#E8FAEF',
                      paddingLeft: 10,
                      fontSize: 18,
                    }}
                  >
                    <span
                      style={{ color: 'rgba(255, 75, 80, 1)', marginLeft: 5 }}
                    >
                      *
                    </span>
                    添加练习题
                  </Col>
                  <Col
                    flex="auto"
                    style={{
                      textAlign: 'right',
                      backgroundColor: '#E8FAEF',
                      paddingRight: 10,
                    }}
                  >
                    <Button
                      size="small"
                      type="primary"
                      style={{
                        borderColor: '#07c160',
                        width: 80,
                        height: 32,
                        color: '#fff',
                      }}
                      onClick={() => {
                        initExercise()
                        setAddExerciseVisible(true)
                      }}
                    >
                      <PlusSquareOutlined style={{ margin: 0 }} />
                      习题
                    </Button>
                  </Col>
                </Row>
                {moduleExercises.length == 0 && (
                  <div
                    style={{
                      color: '#999',
                      textAlign: 'center',
                      height: 50,
                      lineHeight: '50px',
                      margin: '0 0 20px 40px',
                      border: '1px solid #E1F2E8',
                    }}
                  >
                    请添加练习题
                  </div>
                )}
                {moduleExercises.length != 0 && (
                  <div className={courseCreateStyles.knowledgeTableWrapper}>
                    <ConfigProvider locale={zhCN}>
                      <Table
                        size="small"
                        dataSource={moduleExercises}
                        columns={exerciseResultCols}
                        pagination={false}
                        style={{ margin: '0 0 20px 40px' }}
                        rowKey={(record) => record.id}
                        expandable={{
                          columnWidth: 0,
                          expandIconColumnIndex: 5,
                          expandIcon: ({ expanded, onExpand, record }) => (
                            <div
                              onClick={(e) => onExpand(record, e)}
                              style={{
                                color: '#999',
                                position: 'absolute',
                                right: 75,
                                zIndex: 99,
                                top: 8,
                              }}
                            >
                              <FileSearchOutlined
                                style={{
                                  marginRight: 10,
                                  fontSize: 16,
                                  cursor: 'pointer',
                                }}
                              />
                            </div>
                          ),
                          // expanded ? (
                          // <div onClick={e => onExpand(record, e)} style={{cursor:'pointer'}}>预览<UpOutlined  className={moduleStyles.actionBtn} /></div>
                          // ) : (
                          // <div onClick={e => onExpand(record, e)} style={{cursor:'pointer'}}>预览<DownOutlined className={moduleStyles.actionBtn} /></div>
                          // )
                          expandedRowRender: renderExercisePreview,
                          rowExpandable: (record) =>
                            record.name !== 'Not Expandable',
                        }}
                      />
                    </ConfigProvider>
                  </div>
                )}

                <Row style={{ height: 50, lineHeight: '50px' }}>
                  <Col
                    flex="30px"
                    style={{ color: '#07c160', fontSize: 20, marginRight: 10 }}
                  >
                    ④
                  </Col>
                  <Col
                    flex="150px"
                    style={{
                      backgroundColor: '#E8FAEF',
                      paddingLeft: 10,
                      fontSize: 18,
                    }}
                  >
                    添加作业考试题
                  </Col>
                  <Col
                    flex="auto"
                    style={{
                      backgroundColor: '#E8FAEF',
                      paddingRight: 10,
                      textAlign: 'right',
                    }}
                  >
                    <Button
                      size="small"
                      type="primary"
                      style={{
                        borderColor: '#07c160',
                        width: 80,
                        height: 32,
                        color: '#fff',
                      }}
                      onClick={() => {
                        initExercise()
                        setTestexamVisible(true)
                        console.log(testexam)
                        console.log(testexamResult)
                      }}
                    >
                      <PlusSquareOutlined style={{ margin: 0 }} />
                      习题
                    </Button>
                  </Col>
                </Row>

                {moduleTestExamExercises.length == 0 && (
                  <div
                    style={{
                      color: '#999',
                      textAlign: 'center',
                      height: 50,
                      lineHeight: '50px',
                      margin: '0 0 20px 40px',
                      border: '1px solid #E1F2E8',
                    }}
                  >
                    请添加作业考试题
                  </div>
                )}
                {moduleTestExamExercises.length != 0 && (
                  <div className={courseCreateStyles.knowledgeTableWrapper}>
                    <ConfigProvider locale={zhCN}>
                      <Table
                        size="small"
                        dataSource={moduleTestExamExercises}
                        columns={exerciseTestExamResultCols}
                        pagination={false}
                        style={{ margin: '0 0 20px 40px' }}
                        rowKey={(record) => record.id}
                        expandable={{
                          columnWidth: 0,
                          expandIconColumnIndex: 5,
                          expandIcon: ({ expanded, onExpand, record }) => (
                            <div
                              onClick={(e) => onExpand(record, e)}
                              style={{
                                color: '#999',
                                position: 'absolute',
                                right: 75,
                                zIndex: 99,
                                top: 8,
                              }}
                            >
                              <FileSearchOutlined
                                style={{
                                  marginRight: 10,
                                  fontSize: 16,
                                  cursor: 'pointer',
                                }}
                              />
                            </div>
                          ),
                          // expanded ? (
                          // <div onClick={e => onExpand(record, e)} style={{cursor:'pointer'}}>预览<UpOutlined  className={moduleStyles.actionBtn} /></div>
                          // ) : (
                          // <div onClick={e => onExpand(record, e)} style={{cursor:'pointer'}}>预览<DownOutlined className={moduleStyles.actionBtn} /></div>
                          // )
                          expandedRowRender: renderExercisePreview,
                          rowExpandable: (record) =>
                            record.name !== 'Not Expandable',
                        }}
                      />
                    </ConfigProvider>
                  </div>
                )}
              </div>
            </div>

            <Modal
              title={
                <div
                  style={{ width: '100%', cursor: 'move' }}
                  onMouseOver={() => {
                    if (disabled) {
                      setDisabled(false)
                    }
                  }}
                  onMouseOut={() => {
                    setDisabled(true)
                  }}
                  onFocus={() => {}}
                  onBlur={() => {}}
                >
                  添加实验
                </div>
              }
              visible={addCaseVisible}
              width={800}
              bodyStyle={{ minHeight: 400, overflow: 'auto' }}
              //className={moduleStyles.addModal}
              // onOk={handleAddCaseOk}
              // onCancel={()=>{setAddCaseVisible(false)}}
              destroyOnClose={true}
              closable={false}
              footer={
                <div>
                  <Button
                    className={courseCreateStyles.stepsBtn}
                    style={{ marginRight: 50 }}
                    type="default"
                    onClick={() => {
                      setAddCaseVisible(false)
                    }}
                  >
                    取消
                  </Button>
                  <Button
                    className={courseCreateStyles.stepsBtn}
                    style={{ marginRight: 20, color: '#fff' }}
                    type="primary"
                    onClick={handleAddCaseOk}
                  >
                    确认
                  </Button>
                </div>
              }
              modalRender={(modal) => (
                <div className={moduleStyles.addModal}>
                  <Draggable
                    disabled={disabled}
                    bounds={bounds}
                    onStart={(event, uiData) => onStart(event, uiData)}
                  >
                    <div ref={draggleRef}>{modal}</div>
                  </Draggable>{' '}
                </div>
              )}
            >
              <div>
                <Input
                  onPressEnter={onCaseSearchBtn}
                  placeholder="搜索实验（名称或ID）"
                  style={{ borderRadius: 20, width: 300, marginBottom: 10 }}
                  suffix={<SearchOutlined style={{ color: '#666666' }} />}
                />
              </div>

              <Tabs defaultActiveKey="2" onChange={onCaseTabKeyChange}>
                <TabPane tab="系统" key="2" className="">
                  <div className={courseCreateStyles.tableWrapper}>
                    <ConfigProvider locale={zhCN}>
                      <Table
                        dataSource={caseData}
                        columns={caseCols}
                        rowKey={(record) => record.case_id}
                        pagination={false}
                        size="small"
                        //scroll={{ x: 600 }}
                        //bordered={true}
                        onChange={onCaseTableChange}
                        rowSelection={{
                          type: 'radio',
                          ...caseRowSelection,
                        }}
                      />
                    </ConfigProvider>
                  </div>
                </TabPane>
                {/* <TabPane tab="个人" key="1" className="">
                  <div className={courseCreateStyles.tableWrapper}>
                    <ConfigProvider locale={zhCN}>
                      <Table
                        dataSource={caseData}
                        columns={caseCols}
                        rowKey={(record) => record.case_id}
                        pagination={false}
                        size="small"
                        //scroll={{ x: 600 }}
                        //bordered={true}
                        onChange={onCaseTableChange}
                        rowSelection={{
                          type: 'radio',
                          ...caseRowSelection,
                        }}
                      />
                    </ConfigProvider>
                  </div>
                </TabPane> */}
              </Tabs>

              <ConfigProvider locale={zhCN}>
                <div
                  style={{
                    marginBottom: 10,
                    paddingTop: 10,
                    textAlign: 'center',
                  }}
                >
                  <Pagination
                    className={moduleStyles.pagination}
                    total={caseTotalSum}
                    defaultCurrent={1}
                    defaultPageSize={10}
                    current={casePage.current}
                    onChange={onCasePageChange}
                    hideOnSinglePage={true}
                    style={{ textAlign: 'center' }}
                  />
                </div>
              </ConfigProvider>
            </Modal>

            <Modal
              title={
                <div
                  style={{ width: '100%', cursor: 'move' }}
                  onMouseOver={() => {
                    if (disabled) {
                      setDisabled(false)
                    }
                  }}
                  onMouseOut={() => {
                    setDisabled(true)
                  }}
                  onFocus={() => {}}
                  onBlur={() => {}}
                >
                  添加练习题
                </div>
              }
              visible={addExerciseVisible}
              width={800}
              // onOk={handleAddExerciseOk}
              // onCancel={()=>{setAddExerciseVisible(false)}}
              bodyStyle={{ minHeight: 400, overflow: 'auto' }}
              //className={courseCreateStyles.addModal}
              destroyOnClose={true}
              closable={false}
              footer={
                <div>
                  <Button
                    className={courseCreateStyles.stepsBtn}
                    style={{ marginRight: 50 }}
                    type="default"
                    onClick={() => {
                      setAddExerciseVisible(false)
                    }}
                  >
                    取消
                  </Button>
                  <Button
                    className={courseCreateStyles.stepsBtn}
                    style={{ marginRight: 20, color: '#fff' }}
                    type="primary"
                    onClick={handleAddExerciseOk}
                  >
                    确认
                  </Button>
                </div>
              }
              modalRender={(modal) => (
                <div className={moduleStyles.addModal}>
                  <Draggable
                    disabled={disabled}
                    bounds={bounds}
                    onStart={(event, uiData) => onStart(event, uiData)}
                  >
                    <div ref={draggleRef}>{modal}</div>
                  </Draggable>{' '}
                </div>
              )}
            >
              <div>
                <Input
                  onPressEnter={onExeSearchBtn}
                  placeholder="搜索习题（名称或ID）"
                  style={{ borderRadius: 20, width: 300, marginBottom: 10 }}
                  suffix={<SearchOutlined style={{ color: '#666666' }} />}
                />
              </div>
              <Tabs defaultActiveKey="2" onChange={onExeTabKeyChange}>
                <TabPane tab="系统" key="2" className="">
                  <div className={courseCreateStyles.tableWrapper}>
                    <ConfigProvider locale={zhCN}>
                      <Table
                        size="small"
                        dataSource={exerciseData}
                        columns={exerciseCols}
                        pagination={false}
                        onChange={onExeTableChange}
                        rowKey={(record) => record.id}
                        rowSelection={{
                          type: 'checkbox',
                          ...onExerciseSelection,
                        }}
                        expandable={{
                          columnWidth: 90,
                          childrenColumnName: '预览',
                          expandIconColumnIndex: 6,
                          expandIcon: ({ expanded, onExpand, record }) =>
                            expanded ? (
                              <div
                                onClick={(e) => onExpand(record, e)}
                                style={{ cursor: 'pointer' }}
                              >
                                预览
                                <UpOutlined
                                  className={moduleStyles.actionBtn}
                                />
                              </div>
                            ) : (
                              <div
                                onClick={(e) => onExpand(record, e)}
                                style={{ cursor: 'pointer' }}
                              >
                                预览
                                <DownOutlined
                                  className={moduleStyles.actionBtn}
                                />
                              </div>
                            ),
                          expandedRowRender: renderExercisePreview,
                          rowExpandable: (record) =>
                            record.name !== 'Not Expandable',
                        }}
                      />
                    </ConfigProvider>
                  </div>
                </TabPane>
                <TabPane tab="个人" key="1" className="">
                  <div className={courseCreateStyles.tableWrapper}>
                    <ConfigProvider locale={zhCN}>
                      <Table
                        size="small"
                        dataSource={exerciseData}
                        columns={exerciseCols}
                        pagination={false}
                        onChange={onExeTableChange}
                        rowKey={(record) => record.id}
                        rowSelection={{
                          type: 'checkbox',
                          ...onExerciseSelection,
                        }}
                        expandable={{
                          columnWidth: 90,
                          childrenColumnName: '预览',
                          expandIconColumnIndex: 6,
                          expandIcon: ({ expanded, onExpand, record }) =>
                            expanded ? (
                              <div
                                onClick={(e) => onExpand(record, e)}
                                style={{ cursor: 'pointer' }}
                              >
                                预览
                                <UpOutlined
                                  className={moduleStyles.actionBtn}
                                />
                              </div>
                            ) : (
                              <div
                                onClick={(e) => onExpand(record, e)}
                                style={{ cursor: 'pointer' }}
                              >
                                预览
                                <DownOutlined
                                  className={moduleStyles.actionBtn}
                                />
                              </div>
                            ),
                          expandedRowRender: renderExercisePreview,
                          rowExpandable: (record) =>
                            record.name !== 'Not Expandable',
                        }}
                      />
                    </ConfigProvider>
                  </div>
                </TabPane>
              </Tabs>

              <ConfigProvider locale={zhCN}>
                <div
                  style={{
                    marginBottom: 10,
                    paddingTop: 10,
                    textAlign: 'center',
                  }}
                >
                  <Pagination
                    className={moduleStyles.pagination}
                    total={exeTotalSum}
                    defaultCurrent={1}
                    defaultPageSize={10}
                    current={exePage.current}
                    onChange={onExePageChange}
                    hideOnSinglePage={true}
                    style={{ textAlign: 'center' }}
                  />
                </div>
              </ConfigProvider>
            </Modal>

            <Modal
              title={
                <div
                  style={{ width: '100%', cursor: 'move' }}
                  onMouseOver={() => {
                    if (disabled) {
                      setDisabled(false)
                    }
                  }}
                  onMouseOut={() => {
                    setDisabled(true)
                  }}
                  onFocus={() => {}}
                  onBlur={() => {}}
                >
                  添加作业考试题
                </div>
              }
              visible={addTestexamVisible}
              width={800}
              // onOk={handleAddTestexamOk}
              // onCancel={()=>{setTestexamVisible(false)}}
              bodyStyle={{ minHeight: 400, overflow: 'auto' }}
              //className={courseCreateStyles.addModal}
              destroyOnClose={true}
              closable={false}
              footer={
                <div>
                  <Button
                    className={courseCreateStyles.stepsBtn}
                    style={{ marginRight: 50 }}
                    type="default"
                    onClick={() => {
                      setTestexamVisible(false)
                    }}
                  >
                    取消
                  </Button>
                  <Button
                    className={courseCreateStyles.stepsBtn}
                    style={{ marginRight: 20, color: '#fff' }}
                    type="primary"
                    onClick={handleAddTestexamOk}
                  >
                    确认
                  </Button>
                </div>
              }
              modalRender={(modal) => (
                <div className={moduleStyles.addModal}>
                  <Draggable
                    disabled={disabled}
                    bounds={bounds}
                    onStart={(event, uiData) => onStart(event, uiData)}
                  >
                    <div ref={draggleRef}>{modal}</div>
                  </Draggable>{' '}
                </div>
              )}
            >
              <div>
                <Input
                  onPressEnter={onExeSearchBtn}
                  placeholder="搜索习题（名称或ID）"
                  style={{ borderRadius: 20, width: 300, marginBottom: 10 }}
                  suffix={<SearchOutlined style={{ color: '#666666' }} />}
                />
              </div>
              <Tabs defaultActiveKey="2" onChange={onExeTabKeyChange}>
                <TabPane tab="系统" key="2" className="">
                  <div className={courseCreateStyles.tableWrapper}>
                    <ConfigProvider locale={zhCN}>
                      <Table
                        size="small"
                        dataSource={exerciseData}
                        columns={exerciseCols}
                        pagination={false}
                        onChange={onExeTableChange}
                        rowKey={(record) => record.id}
                        rowSelection={{
                          type: 'checkbox',
                          ...onExerciseTestExamSelection,
                        }}
                        expandable={{
                          columnWidth: 90,
                          childrenColumnName: '预览',
                          expandIconColumnIndex: 6,
                          expandIcon: ({ expanded, onExpand, record }) =>
                            expanded ? (
                              <div
                                onClick={(e) => onExpand(record, e)}
                                style={{ cursor: 'pointer' }}
                              >
                                预览
                                <UpOutlined
                                  className={moduleStyles.actionBtn}
                                />
                              </div>
                            ) : (
                              <div
                                onClick={(e) => onExpand(record, e)}
                                style={{ cursor: 'pointer' }}
                              >
                                预览
                                <DownOutlined
                                  className={moduleStyles.actionBtn}
                                />
                              </div>
                            ),
                          expandedRowRender: renderExercisePreview,
                          rowExpandable: (record) =>
                            record.name !== 'Not Expandable',
                        }}
                      />
                    </ConfigProvider>
                  </div>
                </TabPane>
                <TabPane tab="个人" key="1" className="">
                  <div className={courseCreateStyles.tableWrapper}>
                    <ConfigProvider locale={zhCN}>
                      <Table
                        size="small"
                        dataSource={exerciseData}
                        columns={exerciseCols}
                        pagination={false}
                        onChange={onExeTableChange}
                        rowKey={(record) => record.id}
                        rowSelection={{
                          type: 'checkbox',
                          ...onExerciseTestExamSelection,
                        }}
                        expandable={{
                          columnWidth: 90,
                          childrenColumnName: '预览',
                          expandIconColumnIndex: 6,
                          expandIcon: ({ expanded, onExpand, record }) =>
                            expanded ? (
                              <div
                                onClick={(e) => onExpand(record, e)}
                                style={{ cursor: 'pointer' }}
                              >
                                预览
                                <UpOutlined
                                  className={moduleStyles.actionBtn}
                                />
                              </div>
                            ) : (
                              <div
                                onClick={(e) => onExpand(record, e)}
                                style={{ cursor: 'pointer' }}
                              >
                                预览
                                <DownOutlined
                                  className={moduleStyles.actionBtn}
                                />
                              </div>
                            ),
                          expandedRowRender: renderExercisePreview,
                          rowExpandable: (record) =>
                            record.name !== 'Not Expandable',
                        }}
                      />
                    </ConfigProvider>
                  </div>
                </TabPane>
              </Tabs>

              <ConfigProvider locale={zhCN}>
                <div
                  style={{
                    marginBottom: 10,
                    paddingTop: 10,
                    textAlign: 'center',
                  }}
                >
                  <Pagination
                    className={moduleStyles.pagination}
                    total={exeTotalSum}
                    defaultCurrent={1}
                    defaultPageSize={10}
                    current={exePage.current}
                    onChange={onExePageChange}
                    hideOnSinglePage={true}
                    style={{ textAlign: 'center' }}
                  />
                </div>
              </ConfigProvider>
            </Modal>
          </div>
        </Content>
      </Layout>
    </Layout>
  )
}

export default OAMModuleEdit
