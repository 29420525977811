import React, {useEffect, useRef, useState} from "react";
import {Tooltip,Progress,Tree,Drawer,Layout, message, Card, Col, Row, ConfigProvider, Pagination, Tabs, Input, Upload, Button, Popconfirm, Select } from 'antd';
import { PageHeader } from "../common/page-header/header";
import { sendHttpPostRequest } from "../../api/http-request";
import logoSvg from '../../static/image/idclogo.png';
import logo from '../../static/image/college/logo.png';
import pekinglogoSvg from '../../static/image/peking.png';
import {DownOutlined,ArrowLeftOutlined,FormOutlined,CodeOutlined,ProfileOutlined,LeftCircleOutlined,RightCircleOutlined} from '@ant-design/icons';
import Cookies from 'js-cookie';
import zhCN from 'antd/lib/locale/zh_CN'
import * as constants from '../../constants/constants';
import PageFooter from "./college-footer";
import LoginRegisterModal from "../../component/modal/login-register/login-register";
import UserInfo, {checkUserLogin} from "../common/user-center/user-info";
import { UserOutlined } from '@ant-design/icons';
import { Content, Footer } from "antd/lib/layout/layout";
import Sider from "antd/lib/layout/Sider";
import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';
import {UploadOutlined,FileImageOutlined} from '@ant-design/icons';
import collegeStyles from './college.module.less';
import project1 from '../../static/image/college/project1.png';
import project2 from '../../static/image/college/project2.png';
import project3 from '../../static/image/college/project3.png';


// 通过路由组件额外添加参数信息到表中
import {RouteComponentProps} from "react-router";
import SplitPane from "react-split-pane";
import caseStyles from "./case.module.less";
import ReactDOM from "react-dom";
import { identifier } from "@babel/types";
import { Link } from "react-router-dom";
const { TextArea } = Input;
const { Option } = Select;
const { Header } = Layout;

interface Props  {
}




export const CollegeProject: React.FC<Props> = (props) => {
   
    const projects = [
        {
            name: '数字交通',
            description: '数字交通是数字经济发展的重要领域，是以数据为关键要素和核心驱动，促进物理和虚拟空间的交通运输活动不断融合、交互作用的现代交通运输体系。数字交通微专业旨在培养掌握一定数据分析技术，赋能交通运输及关联产业，推动模式、产品、服务等联动创新的高端人才。',
            src: project1,
            href: '/college-traffic',
        },{
            name: '数字金融',
            description: '数字金融通是指以互联网、大数据、人工智能、区块链、移动物联、云计算等为代表的数字技术应用于金融领域，产生新的金融产品、服务和形态。数字金融微专业通过多学科交叉、理论与实践相结合的培养模式，旨在培养善于运用大数据、人工智能等技术打破金融行业服务边界，解决金融领域实际问题的高素质复合型创新人才。',
            src: project2,
            href: '/college-finance',
        },{
            name: '数字营销',
            description: '数字营销是指借助于互联网、电脑通信技术和数字交互式媒体来实现营销目标的一种营销方式，是数据科学和市场营销的高度融合，其本质是由互联网技术、数据分析技术驱动市场营销创新，是数字经济时代企业的主要营销方式和发展趋势。数字营销微专业旨在培养既懂营销知识，又能掌握一定数据分析技术的管理人才，使其能够在大数据环境里进行管理和创新。',
            src: project3,
            href: '/college-marketing',
        },
    ]

    return (
        <Layout style={{minHeight: 750,backgroundColor: '#fff'}}>
            <Header style={{backgroundColor:'#fff',boxShadow:'0px 0px 10px rgba(0, 0, 0, 0.16)',height:80,paddingLeft:80,paddingTop:6}}>
                <Row justify="center" align="middle" wrap={true}>
                    <Col>
                        <Link to={"/college-project"}>
                            <img
                                key={"0"}
                                id={"menu-0"}
                                title="黄河交通学院"
                                alt="logo"
                                src={logo}
                                width={300}
                            ></img>
                        </Link>
                    </Col>
                    {/* <Col flex="150px" style={{color:'#155ba5',fontSize:22,fontWeight:700}}>数字交通</Col> */}
                    <Col flex="auto"></Col>
                    <Col><UserInfo/></Col>
                </Row>
            </Header>

            <div className={collegeStyles.mainDiv} style={{paddingTop:40}}>
                {projects.map((project)=>(
                    <div key={project.name} className={collegeStyles.projectDiv}>
                        <div style={{width:400,float:'left',padding:20,color:'#fff',position:'relative',height:320}}>
                            <div style={{fontSize:20,marginBottom:10}}>{project.name}</div>
                            <div style={{maxHeight:180}}>{project.description}</div>
                            <div className={collegeStyles.startBtn} style={{transform: "translate(-50%,0)", position:'absolute',left:'50%',bottom:40,zIndex:10}}>
                                <Button style={{borderRadius:27,backgroundColor:'#fff',width:120,height:40,color:'#3b6d74'}}
                                onClick={()=>{window.open(project.href)}}>
                                    进入
                                </Button>
                            </div>
                        </div>
                        <div style={{width:600,float:'left'}}>
                            <img src={project.src} alt=""  className={collegeStyles.projectImg}/>
                        </div>
                    </div>
                ))

                }
            </div>

            <PageFooter />
        </Layout>

    )

}

export default CollegeProject

