import React, {useEffect, useState} from "react";
import {Tooltip,Popconfirm,Table, Col, Row, ConfigProvider, Button, Checkbox, message} from 'antd';
import { sendHttpPostRequest } from "../../../api/http-request";
import zhCN from 'antd/lib/locale/zh_CN'

import {checkUserLogin} from "../../common/user-center/user-info";
import { EyeOutlined,EditOutlined,FileTextOutlined,DeleteOutlined } from '@ant-design/icons';

import courseMainStyles from "../course-main.module.less";
import courseExamListStyles from "./course-main-teacher-exam-list.module.less";
import { renderIntoDocument } from "react-dom/test-utils";
import kaoshiEmpty from "../../../static/image/empty/queshengye-kaoshi.png";
import Cookies from "js-cookie";
import moment from 'moment';

export interface Props {
  course_id : any,
}

const dateFormat = 'YYYY-MM-DD HH:mm:ss';

const statusMap = new Map([[1, '未发布'], [2, '已发布']]);
/** 教师课程考试列表页面 */
export const CourseMainStudentExamList: React.FC<Props> = (props) => {
    const {course_id} = props;

    const [examList, setExamList] = useState([] as any[]);

    useEffect(()=> {
      queryExamList();
  },[])


  const queryExamList = () => {
    if (!checkUserLogin()) {
        document.getElementById("show-login-modal")!.click();
    }
    
    sendHttpPostRequest(
      'exam/api/user_query_exam_list/',
      {
          sessionid: Cookies.get("sessionid"),
          course_id: course_id,
      },
      queryExamListCallback
  )
  
}

    /**
     * 创建考试回调
     * @param response
     */
     const queryExamListCallback = (response:any) => {
      message.destroy();
      if (response.code == -100) {
          // 没登录
          window.location.href = '/login?next=course-main-student?course_id=' + course_id + "&tabKey=5";
      } else if (response.code == -501) {
          // 没权限
          window.location.href = '/login?next=course-main-student?course_id=' + course_id + "&tabKey=5";
      } else if (response.is_ok == true) {
          setExamList(response.query_list);
      }
  }




    const onPreviewExamClick = (exam_id:string, event:any) => {
          if (!checkUserLogin()) {
              document.getElementById("show-login-modal")!.click();
          }
          window.open('/course-student-exam-preview?exam_id=' + exam_id + "&course_id=" + course_id)
      }


    const renderStatus = (text: any, record: any) => {
      // const currentTime = moment().utc().zone(-8);
      // const status = record.status;//1未发布；2已发布
      // const publish_score = record.publish_score;//1未公布；2已公布
      // const start = moment(record.start_time).utc().zone(-8);
      // const end = moment(record.end_time).utc().zone(-8);
      // if(status == 1){//未发布
      //   return <div>{"未发布"}</div>
      // }else if(status == 2 && currentTime < start){
      //   return <div>{"未开考"}</div>
      // }else if(status == 2 && currentTime > start && currentTime < end){
      //   return <div>{"考试中"}</div>
      // }else if(status == 2 && currentTime > end && publish_score == 1){
      //   return <div>{"阅卷中"}</div>
      // }else if(status == 2 && currentTime > end && publish_score == 2){
      //   return <div>{"已完成"}</div>
      // }
     return <div> {record.exam_status} </div>
    }
      
    
    // 渲染操作
    const renderActions = (text:any, record:any) => {
        return <Row align='middle' justify='center' gutter={[16,8]}> 
                  <Col>  <EyeOutlined  value={record.id} onClick={onPreviewExamClick.bind(this,record.id)}  className={courseExamListStyles.actionBtn} /> </Col>                
              </Row>
    }

    const renderName = (text:any, record:any) => {
        if (text.length > 50) {
          return <>
            <a className={courseExamListStyles.slideName} href={'/course-student-exam-preview?exam_id=' + record.id + "&course_id=" + course_id } target="_blank">{record.name.slice(0,50) + '...'}</a>
          </>
        } else {
          return <>
            <a  className={courseExamListStyles.slideName}  href={'/course-student-exam-preview?exam_id=' + record.id + "&course_id=" + course_id} target="_blank">{record.name}</a>
          </>
        }
      }
    const examCols :any= [
      {
        title:'序号',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: '9%',
        render:(text,record,index)=>(examList.indexOf(record) + 1)
      },
      {
        title: '考试',
        dataIndex: 'name',
        key: 'name',
        align: 'left',
        width: '20%',
        render:renderName,
      },
      {
        title: '状态',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        width: '10%',
        render: renderStatus,

      },
      {
        title: '开始时间',
        dataIndex: 'start_time',
        key: 'start_time',
        align: 'center',
        width: '20%',
        render: (text: any, record: any) => (
          <div>{text?.slice(0,16)}</div>
        )
      },
      {
        title: '结束时间',
        dataIndex: 'end_time',
        key: 'end_time',
        align: 'center',
        width: '20%',
        render: (text: any, record: any) => (
          <div>{text?.slice(0,16)}</div>
        )
      },
      {
        title: "操作",
        dataIndex: "actions",
        key: "actions",
        width: '15%',
        ellipsis: false,
        align: 'center',
        //fixed: 'right',
        render: renderActions,
    },
    ]




    return (
        <div className={courseMainStyles.tabpaneWrapper}>
          
            <div className={courseExamListStyles.tableWrapper}>
            {examList.length!=0 && 
                <ConfigProvider locale={zhCN}>
                    <Table
                        dataSource={examList}
                        columns={examCols}
                        size='small'
                        rowKey={record => record.id}
                        pagination={{position:["bottomCenter"], pageSize:10, size:"default",hideOnSinglePage:true}}
                    />
                </ConfigProvider>
            }
            
              {examList.length==0 &&
                <div style={{textAlign:'center',paddingTop:20}}>
                    <img src={kaoshiEmpty} width={120} height={120}/>
                    <div style={{color:'#999',marginTop:10}}>授课老师还没有创建考试哟~</div>
                </div>
              }
            </div>
        </div>
    )

}

export default CourseMainStudentExamList

