import React, { useEffect, useRef, useState } from 'react'
import {
  Pagination,
  DatePicker,
  Table,
  Upload,
  Steps,
  Input,
  Layout,
  message,
  Row,
  ConfigProvider,
  Tabs,
  Button,
  Space,
  Modal,
  Col,
  Popconfirm,
  Switch,
  Popover,
} from 'antd'
import { PageHeader } from '../common/page-header/header'
import { sendHttpPostRequest } from '../../api/http-request'
import courseCreateStyles from './course-create.module.less'

import courseBasicInfoStyle from './course-edit-basicinfo.module.less'

import courseEditSlidesCasesStyle from './course-edit-slides-cases.module.less'
import moduleStyles from '../oam/module/module.module.less'
import { getRangeDate } from '../common/utils/util'
import './course.module.css'
import zhCN from 'antd/lib/locale/zh_CN'
import * as constants from '../../constants/constants'
import PageFooter from '../common/page-footer/footer'
import LoginRegisterModal from '../../component/modal/login-register/login-register'
import { checkUserLogin } from '../common/user-center/user-info'
import {
  SearchOutlined,
  PlayCircleOutlined,
  PlusCircleOutlined,
  ClockCircleOutlined,
  VideoCameraOutlined,
  CodeOutlined,
  FormOutlined,
  UserOutlined,
  PlusSquareOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import formatIcon from '../../static/image/formatIcon.png'
import CourseEditSlidesCases from './course-edit-slides-cases'
import MenuEdit from './menu/course-main-teacher-menu-edit'
import Draggable from 'react-draggable'
import Feedback from '../common/feedback-help/feedback'
import Cookies from 'js-cookie'
import moment from 'moment'
const { RangePicker } = DatePicker
const { Step } = Steps
const { TextArea } = Input
const { TabPane } = Tabs
const { Search } = Input

const dateFormat = 'YYYY-MM-DD'

export interface Props {}
/** 教师创建课程 */
export const CourseCreate: React.FC<Props> = (props) => {
  const courseId = parseInt(window.location.search.split('=')[1]) // 课程ID

  const [courseName, setCourseName] = useState('') //课程名称
  const [courseDescription, setCourseDescription] = useState('') //课程介绍
  const [courseCover, setCourseCover] = useState('') //课程封面路径
  const [startTime, setStartTime] = useState(null as any) //课程开始时间
  const [endTime, setEndTime] = useState(null as any) //课程结束时间
  const [lectureList, setLectureList] = useState(null as any) //课程课件列表
  const [caseList, setCaseList] = useState(null as any) //课程实验列表

  const [videoDescription, setVideoDescription] = useState('') //视频介绍

  const [menuId, setMenuId] = useState(null as any) //课程大纲
  const [menu, setMenu] = useState(null as any) //课程大纲对象

  const [images, setImages] = useState([]) // 已上传课程封面照片

  const [courseResult, setCourseResult] = useState([] as any[])

  const [current, setCurrent] = React.useState(0)

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [addCaseVisible, setAddCaseVisible] = useState(false)
  const [addVideoVisible, setAddVideoVisible] = useState(false)
  const [cancelVideoVisible, setCancelVideoVisible] = useState(false)
  const caseQueryContent = useRef('') // 记录搜索串
  const casePage = useRef(1) // 记录当前的页数
  const casePageSize = useRef(10) // 记录当前每页展示数量
  const [caseTotalSum, setCaseTotalSum] = useState(0) // 搜索返回的结果数
  const queryCaseSource = useRef('2') // 搜索实验的来源， 来源 1 我的  2 系统
  const caseOrder = useRef('') // 排序升序降序
  const caseOrderBy = useRef('') // 排序列
  const [caseTotalCount, setCaseTotalCount] = useState(0) // 搜索返回的结果数
  const [caseData, setCaseData] = useState([] as any[])

  const selectedCaseRows = React.useRef([] as any) //选择的实验

  const [courseVisible, setCourseVisible] = useState(null as any) //课程是否开放
  const [courseNeedApply, setCourseNeedApply] = useState(2) //课程是否需要申请才能加入；2需要申请；1不需要申请

  const selectedSlideId = React.useRef(null as any) //当前操作的课件
  const changedSlideName = React.useRef(null as any) //当前操作的课件名
  const [isChangeSlideNameModalVisible, setIsChangeSlideNameModalVisible] =
    useState(false) //修改课件名弹出框
  const draggleRef = useRef<HTMLDivElement | null>(null)

  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
  const [disabled, setDisabled] = useState(true)

  const onStart = (event: any, uiData: any) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement
    const targetRect = draggleRef?.current?.getBoundingClientRect()
    if (targetRect) {
      setBounds({
        left: -targetRect?.left + uiData?.x,
        right: clientWidth - (targetRect?.right - uiData?.x),
        top: -targetRect?.top + uiData?.y,
        bottom: clientHeight - (targetRect?.bottom - uiData?.y),
      })
    }
  }

  useEffect(() => {
    queryCourse()
    queryCaseList()
  }, [])

  /** 计算课程大纲的章节数 */
  const computeMenuChapters = () => {
    const numChapters = menu?.json?.length
    let numModules = 0
    menu?.json?.map((chapter) => {
      numModules = numModules + chapter?.tasks?.length
    })

    return { chapters: numChapters, modules: numModules }
  }

  const saveCourseParams = (params: any, showMessage: true | false) => {
    sendHttpPostRequest(
      'course/api/user_update_course',
      params,
      (response: any) => {
        message.destroy()
        if (response.code == -100) {
          // 没登录
          window.location.href = '/login?next=course'
        } else if (response.code == -501) {
          // 没权限
          window.location.href = '/login?next=course'
        } else if (response.is_ok == true) {
          showMessage ? message.success('课程信息保存成功~') : ''
        } else {
          message.error(response.message)
        }
      }
    )
  }

  const onCoursePublishedChange = (checked) => {
    const visible = checked ? 1 : 2
    setCourseVisible(visible)
    const params = {
      sessionid: Cookies.get('sessionid'),
      sid: Cookies.get('sessionid'),
      course_id: courseId,
      visible: visible,
    }
    saveCourseParams(params, false)
  }

  const onCourseNeedApplyChange = (checked) => {
    const applySwitch = checked ? 2 : 1
    setCourseNeedApply(applySwitch)
    const params = {
      sessionid: Cookies.get('sessionid'),
      sid: Cookies.get('sessionid'),
      course_id: courseId,
      apply: applySwitch,
    }
    saveCourseParams(params, false)
  }

  const disabledDate = (current: any) => {
    return current && current < moment().startOf('day')
  }

  const onDateRangeChange = (dates, dateStrings) => {
    setStartTime(dateStrings[0])
    setEndTime(dateStrings[1])
    // saveCourse()
  }

  // 查询课程信息
  const queryCourse = () => {
    const sessionid = Cookies.get('sessionid')
    const queryData = {
      course_id: courseId,
      sessionid: sessionid,
      sid: sessionid,
    }
    sendHttpPostRequest(
      'course/api/user_query_course_by_id',
      queryData,
      courseCallback
    )
  }

  // 查询课程接口回调
  const courseCallback = (response: any) => {
    // console.log(response);
    message.destroy()
    if (response.code == -100) {
      // 未登录
      window.location.href = '/oam_login?next=/course'
    } else if (response.is_ok == true) {
      // 成功
      setCourseName(response.query_info.name)
      setCourseDescription(response.query_info.description)
      setMenuId(response.query_info.syllabus_id)
      response.query_info.start_time.length > 0
        ? setStartTime(response.query_info.start_time)
        : setStartTime(getRangeDate(0))
      response.query_info.end_time.length > 0
        ? setEndTime(response.query_info.end_time)
        : setEndTime(getRangeDate(180))
      setLectureList(response.query_info.lecture_list)
      setCaseList(response.query_info.case)
      setCourseCover(response.query_info.cover)

      setCourseVisible(response.query_info.visible)
      //setCourseNeedApply(response.query_info.apply);

      if (response.query_info.syllabus_id == 0) {
        createMenu() //为课程创建一个新大纲
      }

      //setStartTime(response.query_info.start_time);
      //setEndTime(response.query_info.end_time);
      //setOnlineCount(response.online_count);
    } else if (response.code == -501) {
      // 权限不足
      window.location.href = '/login?next=course'
    } else {
      message.error(response.message)
    }
  }

  /**
   * 创建课程回调
   * @param response
   */
  const saveCourseInfoCallback = (response: any) => {
    message.destroy()
    if (response.code == -100) {
      // 没登录
      window.location.href = '/login?next=course'
    } else if (response.code == -501) {
      // 没权限
      window.location.href = '/login?next=course'
    } else if (response.is_ok == true) {
      //message.success("课程信息保存成功~")
      queryCourse()
    } else {
      message.error(response.message)
    }
  }

  /**
   * 点击保存课程按钮
   */
  const saveCourse = () => {
    sendHttpPostRequest(
      'course/api/user_update_course',
      {
        course_id: courseId,
        name: courseName,
        description: courseDescription,
        cover: courseCover,
        menu_id: menuId,
        start_time: startTime,
        end_time: endTime,
        case: JSON.stringify(caseList),
        lecture_list: JSON.stringify(lectureList),
        apply: courseNeedApply,
        visible: courseVisible,
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
      },
      saveCourseInfoCallback
    )
  }

  // 渲染操作
  const renderCourseSlidesActions = (text: any, record: any) => {
    return (
      <div>
        <Button
          style={{ borderRadius: 20, marginRight: 5, color: '#333' }}
          size="small"
          type="link"
          onClick={() => {
            window.open(
              record.url?.startsWith('http')
                ? record.url
                : constants.BACKEND_URL + record.url
            )
          }}
        >
          {' '}
          下载{' '}
        </Button>

        <Button
          style={{ borderRadius: 20, marginRight: 5, color: '#333' }}
          size="small"
          type="link"
          onClick={() => {
            setIsChangeSlideNameModalVisible(true)
            selectedSlideId.current = record.id
            changedSlideName.current = record.name
          }}
        >
          {' '}
          改名{' '}
        </Button>

        <Popconfirm
          placement="top"
          title={'确认删除课件"' + record.name + '"吗？'}
          onConfirm={() => {
            setLectureList(lectureList.filter((item) => item.id !== record.id))
            saveCourse()
          }}
          okText="确认"
          cancelText="取消"
        >
          <Button
            style={{ borderRadius: 20, marginRight: 5, color: '#333' }}
            size="small"
            type="link"
          >
            {' '}
            删除{' '}
          </Button>
        </Popconfirm>
      </div>
    )
  }

  const slidesCols: any = [
    {
      title: '文件名称',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: '75%',
    },
    {
      title: '操作',
      dataIndex: 'actions',
      key: 'actions',
      width: '25%',
      ellipsis: false,
      align: 'center',
      //fixed: 'right',
      render: renderCourseSlidesActions,
    },
  ]

  // 渲染操作
  const renderCourseCaseActions = (text: any, record: any) => {
    return (
      <div>
        <Button
          onClick={onPreviewCase.bind(this, record.id)}
          style={{ borderRadius: 20, marginRight: 5, color: '#333' }}
          size="small"
          type="link"
        >
          {' '}
          查看{' '}
        </Button>

        <Popconfirm
          placement="top"
          title={'确认删除实验"' + record.name + '"吗？'}
          onConfirm={() => {
            setCaseList(caseList.filter((item) => item.id !== record.id))
            saveCourse()
          }}
          okText="确认"
          cancelText="取消"
        >
          <Button
            style={{ borderRadius: 20, marginRight: 5, color: '#333' }}
            size="small"
            type="link"
          >
            {' '}
            删除{' '}
          </Button>
        </Popconfirm>
      </div>
    )
  }
  const casesCols: any = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: '10%',
    },
    {
      title: '案例',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: '70%',
    },
    {
      title: '操作',
      dataIndex: 'actions',
      key: 'actions',
      width: '20%',
      ellipsis: false,
      align: 'center',
      //fixed: 'right',
      render: renderCourseCaseActions,
    },
  ]

  const onPreviewCase = (id: string, event: any) => {
    event.preventDefault()
    window.open('course-case-detail?case_id=' + id + '?course_id=' + courseId)
  }
  const renderActions = (text: any, record: any) => {
    return (
      <Button
        className={courseCreateStyles.modalBtn}
        type="primary"
        size="small"
        style={{ height: 28, width: 60 }}
      >
        预览
      </Button>
    )
  }

  const renderCaseActions = (text: any, record: any) => {
    return (
      <Button
        className={courseCreateStyles.modalBtn}
        type="primary"
        size="small"
        style={{ height: 28, width: 60 }}
        onClick={onPreviewCase.bind(this, record.case_id)}
      >
        预览
      </Button>
    )
  }

  // 查案例列表
  const queryCaseList = () => {
    sendHttpPostRequest(
      'case/api/query_case_list',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        query: caseQueryContent.current,
        page: casePage.current,
        pagesize: casePageSize.current,
        is_my: queryCaseSource.current,
        order: caseOrder.current,
        order_by: caseOrderBy.current,
      },
      (response: any) => {
        if (response.is_ok && response.code == 0) {
          setCaseData(response.case_list)
          setCaseTotalSum(response.total_sum)
          setCaseTotalCount(response.total_count)
        } else {
          console.error('查询案例出错了')
        }
      }
    )
  }

  // 上传课件传参
  const uploadLectureData = {
    sessionid: Cookies.get('sessionid'),
    sid: Cookies.get('sessionid'),
    course_id: courseId,
  }

  // 上传课件
  const uploadLectureProps = {
    accept: '.mp4,.pdf',
    name: 'data_file',
    multiple: false,
    showUploadList: false,
    data: uploadLectureData,
    action: constants.BACKEND_API_URL + 'course/api/course_upload_lecture/',
    onChange(info) {
      const { status } = info.file
      if (status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (status === 'done') {
        message.destroy()
        if (info.file.response.is_ok) {
          message.success(`文件${info.file.name}上传成功！`)
          const newLectureList = [...lectureList]
          newLectureList.push({
            id: info.file.response.id,
            name: info.file.response.url.split('/')[
              info.file.response.url.split('/').length - 1
            ],
            url: info.file.response.url,
            open: 0,
          })
          console.log(newLectureList)
          setLectureList(newLectureList)
          saveCourse()
        } else {
          if (info.file.response.code == -100) {
            // 未登录
            // const localPath = window.location.href.split(window.location.host)[1];
            window.location.href = `/login?next=course`
          } else if (info.file.response.code == -501) {
            // 权限不足
            // const localPath = window.location.href.split(window.location.host)[1];
            window.location.href = `/login?next=course`
          } else {
            message.error(info.file.response.message)
          }
        }
      } else if (status === 'error') {
        message.error(`文件${info.file.name}上传失败了:(`)
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files)
    },
  }

  // 案例点击了搜索
  const onCaseSearchBtn = (e: any) => {
    casePage.current = 1
    caseQueryContent.current = e.target.value
    queryCaseList()
  }

  // 实验结果表格发生变化
  const onCaseTableChange: any = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: { currentDataSource: []; action: any }
  ) => {
    if (sorter.order) {
      //如果选择了排序列
      caseOrder.current = sorter.order
      caseOrderBy.current = sorter.field
    }

    casePage.current = 1
    queryCaseList()
  }

  // 选择实验
  const caseRowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      selectedCaseRows.current = selectedRows
    },
    getCheckboxProps: (record: any) => ({
      disabled: caseList.filter((item) => item.id == record.case_id).length > 0,
      //name: record.name,
    }),
  }

  const handleAddVideoOk = () => {
    setAddVideoVisible(false)
  }

  /**
   * 保存课程实验列表回调
   * @param response
   */
  const saveCourseCaseListInfoCallback = (response: any) => {
    message.destroy()
    if (response.code == -100) {
      // 没登录
      window.location.href = '/login?next=course'
    } else if (response.code == -501) {
      // 没权限
      window.location.href = '/login?next=course'
    } else if (response.is_ok == true) {
      //message.success("课程信息保存成功~")
    } else {
      message.error(response.message)
    }
  }
  /**
   * 保存课程实验按钮
   */
  const saveCourseCaseList = (caseList: any) => {
    sendHttpPostRequest(
      'course/api/user_update_course',
      {
        course_id: courseId,
        case: JSON.stringify(caseList),
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
      },
      saveCourseCaseListInfoCallback
    )
  }

  /** 确认添加实验 */
  const handleAddCaseOk = () => {
    setAddCaseVisible(false)
    queryCaseSource.current = '2'
    const newCaseList = [...caseList]
    selectedCaseRows.current.map((row) => {
      newCaseList.push({
        id: row.case_id,
        name: row.name,
        open: 0,
        token: row.token,
      })
    })

    setCaseList(newCaseList)
    queryCaseList()
    saveCourseCaseList(newCaseList)
  }

  // 搜索切换Tab页
  const onCaseTabKeyChange = (key: string) => {
    queryCaseSource.current = key
    casePage.current = 1
    queryCaseList()
  }

  // 案例页码和页数发生了变化
  const onCasePageChange = (
    newPage: number,
    newPageSize?: number | undefined
  ) => {
    casePage.current = newPage ? newPage : 1
    if (newPageSize) {
      casePageSize.current = newPageSize
    }
    queryCaseList()
  }

  const caseModalCols: any = [
    {
      title: '序号',
      dataIndex: 'case_id',
      key: 'case_id',
      align: 'center',
      width: 80,
    },
    {
      title: '实验名称',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: 200,
      ellipsis: true,
    },
    {
      title: '领域',
      dataIndex: 'domain',
      key: 'domain',
      align: 'center',
      width: 100,
    },
    {
      title: '创建时间',
      dataIndex: 'create_time',
      key: 'create_time',
      align: 'center',
      width: 150,
      sorter: true,
      ellipsis: true,
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'descend',
    },
    {
      title: '操作',
      dataIndex: 'actions',
      key: 'actions',
      width: 100,
      ellipsis: false,
      align: 'center',
      fixed: 'right',
      render: renderCaseActions,
    },
  ]

  // 上传封面传参
  const uploadData = {
    sessionid: Cookies.get('sessionid'),
    sid: Cookies.get('sessionid'),
    course_id: courseId,
  }

  // 上传图片
  const uploadImageProps = {
    accept: '.png,.jpg,jpeg,.gif',
    name: 'data_file',
    multiple: false,
    showUploadList: false,
    data: uploadData,
    action: constants.BACKEND_API_URL + 'course/api/course_upload_cover',
    onChange(info) {
      const { status } = info.file
      if (status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (status === 'done') {
        if (info.file.response.is_ok) {
          message.success(`图片${info.file.name}文件上传成功！`)
          setCourseCover(info.file.response.url)
          const params = {
            sessionid: Cookies.get('sessionid'),
            course_id: courseId,
            sid: Cookies.get('sessionid'),
            cover: info.file.response.url,
          }
          // saveCourseParams(params, false)
        } else {
          if (info.file.response.code == -100) {
            // 未登录
            const localPath = window.location.href.split(
              window.location.host
            )[1]
            window.location.href = `/login?next=${localPath}`
          } else if (info.file.response.code == -501) {
            // 权限不足
            const localPath = window.location.href.split(
              window.location.host
            )[1]
            window.location.href = `/login?next=${localPath}`
          } else {
            message.error(info.file.response.message)
          }
        }
      } else if (status === 'error') {
        message.error(`图片${info.file.name}文件上传失败了:(`)
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files)
    },
  }

  const handleOk = () => {
    //确认发布课程
    //creatExperiment()
    sendHttpPostRequest(
      'course/api/user_publish_course',
      {
        course_id: courseId,
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
      },
      (response: any) => {}
    )
    setIsModalVisible(false)
    window.location.href = '/course-main-teacher?course_id=' + courseId
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  // 创建一个新大纲
  const createMenu = () => {
    sendHttpPostRequest(
      'course/api/user_create_menu',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
      },
      (response: any) => {
        if (response.is_ok && response.menu_id) {
          setMenuId(response.menu_id)
          saveCourse()
        } else {
          message.destroy()
          message.error(`创建大纲失败：${response.message}`)
        }
      }
    )
  }

  /** 查询大纲 */
  const queryMenu = (menu_id: any) => {
    sendHttpPostRequest(
      'course/api/user_query_menu_by_id',
      {
        sessionid: Cookies.get('sessionid'),
        menu_id: menu_id,
      },
      (response: any) => {
        // console.log(response)
        //message.destroy();
        if (response.code == -100) {
          // 未登录
          document.getElementById('show-login-modal')!.click()
        } else if (response.is_ok == true) {
          // 成功
          setMenu(response.query_info)
        } else {
          message.error(response.message)
        }
      }
    )
  }

  const basicContent = (
    <div style={{ paddingLeft: 80, paddingRight: 80, paddingTop: 40 }}>
      <Row
        gutter={[8, 8]}
        justify="start"
        align="middle"
        style={{ marginBottom: 20 }}
      >
        <Col flex="80px" style={{ color: '#333', textAlign: 'left' }}>
          课程名称
        </Col>
        <Col flex="auto" style={{ textAlign: 'left' }}>
          <Input
            maxLength={30}
            placeholder="请输入课程名称"
            required={true}
            value={courseName}
            onBlur={() => saveCourse()}
            onChange={(e) => {
              setCourseName(e.target.value)
              // saveCourse()
            }}
            style={{ borderRadius: 3, width: '100%' }}
          />
        </Col>
      </Row>

      <Row
        gutter={[8, 8]}
        justify="start"
        align="middle"
        style={{ marginBottom: 20 }}
      >
        <Col flex="80px" style={{ color: '#333', textAlign: 'left' }}>
          课程介绍
        </Col>
        <Col flex="auto" style={{ textAlign: 'left' }}>
          <TextArea
            showCount
            maxLength={500}
            placeholder="请输入课程介绍"
            style={{ borderRadius: 3, width: '100%' }}
            autoSize={{ minRows: 5, maxRows: 5 }}
            value={courseDescription}
            onChange={(e) => {
              setCourseDescription(e.target.value)
              saveCourse()
            }}
          />
        </Col>
      </Row>

      <Row
        gutter={[8, 8]}
        justify="start"
        align="middle"
        style={{ marginBottom: 20 }}
      >
        <Col flex="80px" style={{ color: '#333', textAlign: 'left' }}>
          课程封面
        </Col>
        <Col flex="auto" style={{ textAlign: 'left' }}>
          <Upload
            className={courseBasicInfoStyle.uploadImage}
            {...uploadImageProps}
          >
            {courseCover ? (
              <img
                id="course-cover"
                width={250}
                height={130}
                src={
                  courseCover?.startsWith('http')
                    ? courseCover
                    : constants.BACKEND_URL + courseCover
                }
              />
            ) : (
              <div
                style={{
                  paddingTop: 50,
                  textAlign: 'center',
                  width: 250,
                  height: 130,
                  border: '1px solid #ccc',
                }}
              >
                {' '}
                <div className={courseBasicInfoStyle.uploadImageText}>
                  <UploadOutlined
                    style={{ color: '#07c160', marginRight: 5 }}
                  />
                  上传课程封面{' '}
                </div>
              </div>
            )}
          </Upload>
          <div style={{ marginTop: 6, color: '#666', fontSize: 12 }}>
            请上传格式为png、jpg、jpeg、gif、svg的图片，大小不得超过10M。
          </div>
        </Col>
      </Row>

      {/*<Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:20}} >
                <Col flex="80px" style={{color:"#00000073",textAlign:'left'}}>导学视频<br/><span style={{marginLeft:10}}>(选填)</span></Col>
                <Col flex="auto" style={{textAlign:'left'}}>
                    <Upload className={courseBasicInfoStyle.uploadImage} {...uploadImageProps}>
                    
                        { courseCover?
                            <img
                            id="course-cover"
                            width={250}
                            height={130}
                            src={ courseCover.startsWith("http") ? courseCover : constants.BACKEND_URL + courseCover}
                            /> : <div  style={{paddingTop:50,textAlign:"center",width:250,height:130,border:"1px solid #00000073"}}> <div className={courseBasicInfoStyle.uploadImageText} ><PlusSquareOutlined  style={{color:'#07c160',marginTop:2,marginRight:5}}/> 添加视频 </div></div>
                        }
                    </Upload> 
                    <div className={courseBasicInfoStyle.video} onClick={()=>{setAddVideoVisible(true)}}>
                       <div><PlusSquareOutlined  style={{color:'#07c160',marginTop:2,marginRight:5}}/> 添加视频 </div>
                    </div>
                </Col>
            </Row> */}
    </div>
  )

  const courseEditSlidesCases = (
    <div style={{ padding: 20 }}>
      <Row
        className={courseEditSlidesCasesStyle.courseHeader}
        style={{ marginRight: 'auto', marginLeft: 'auto' }}
        align="middle"
        gutter={[12, 0]}
      >
        <Col flex="200px" style={{ margin: 0, padding: 0 }}>
          <div className={courseEditSlidesCasesStyle.courseImage}>
            <PlayCircleOutlined
              className={courseEditSlidesCasesStyle.imagePlayIcon}
            />
            <img
              //{/*src={constants.BACKEND_API_URL + course.cover}*/}
              src={
                courseCover?.startsWith('http')
                  ? courseCover
                  : constants.BACKEND_URL + courseCover
              }
              width={180}
              height={120}
              style={{ borderRadius: 6 }}
            />
          </div>
        </Col>

        <Col
          style={{
            textAlign: 'left',
            overflow: 'hidden',
            margin: 0,
            marginRight: 20,
          }}
          flex="auto"
        >
          <Row align="middle" gutter={[4, 0]}>
            <Col>
              <div className={courseEditSlidesCasesStyle.courseName}>
                {courseName}
              </div>
            </Col>
          </Row>
          <div className={courseEditSlidesCasesStyle.courseIntroduction}>
            {' '}
            {courseDescription}{' '}
          </div>
          <div style={{ color: '#333' }}>
            章节数：{' '}
            <span style={{ color: '#07c160' }}>
              {computeMenuChapters()?.chapters}
            </span>{' '}
            章{' '}
            <span style={{ color: '#07c160' }}>
              {computeMenuChapters()?.modules}
            </span>{' '}
            节
          </div>
        </Col>
      </Row>
      <ConfigProvider locale={zhCN}>
        <div style={{ textAlign: 'left' }}>
          <span style={{ color: '#ff4b50', marginRight: 3 }}>*</span>开课时间
          <RangePicker
            onChange={onDateRangeChange}
            style={{ marginLeft: 10 }}
            format={dateFormat}
            disabledDate={disabledDate}
            defaultValue={[
              moment(startTime, dateFormat),
              moment(endTime, dateFormat),
            ]}
          />
        </div>
      </ConfigProvider>
      <div
        style={{ marginTop: 20 }}
        className={courseCreateStyles.slidesHeader}
      >
        <div
          style={{ backgroundColor: '#e8faef', height: 48, lineHeight: '48px' }}
        >
          <span
            style={{
              float: 'left',
              marginLeft: 10,
              fontSize: 18,
              fontWeight: 500,
            }}
          >
            课件
          </span>
          <Upload {...uploadLectureProps}>
            <Button
              type="primary"
              style={{ margin: 8, marginRight: 10, width: 80, borderRadius: 5 }}
            >
              <PlusCircleOutlined />
              添加
            </Button>
          </Upload>
          <Popover
            content={
              <div>
                请选择pptx， pdf，docx格式文件。
                <br />
                文件大小不得超过50M。
              </div>
            }
            placement="bottom"
            title={''}
            trigger="hover"
          >
            <span
              style={{
                float: 'right',
                cursor: 'pointer',
                display: 'block',
                height: 30,
                fontSize: 12,
              }}
            >
              <img
                src={formatIcon}
                style={{ width: 16, height: 16, marginTop: -2 }}
                alt=""
              />
              文件格式
            </span>
          </Popover>
        </div>
        <ConfigProvider locale={zhCN}>
          <Table
            className={courseEditSlidesCasesStyle.slidesTable}
            dataSource={lectureList}
            columns={slidesCols}
            size="small"
            pagination={false}
          />
        </ConfigProvider>
      </div>
      <div style={{ marginTop: 20 }}>
        <div
          style={{ backgroundColor: '#e8faef', height: 48, lineHeight: '48px' }}
        >
          <span
            style={{
              float: 'left',
              marginLeft: 10,
              fontSize: 18,
              fontWeight: 500,
            }}
          >
            实验
          </span>
          <Button
            onClick={() => {
              setAddCaseVisible(true)
            }}
            type="primary"
            style={{
              float: 'right',
              margin: 8,
              marginRight: 10,
              width: 80,
              borderRadius: 5,
            }}
          >
            <PlusCircleOutlined />
            添加
          </Button>
        </div>
        <ConfigProvider locale={zhCN}>
          <Table
            className={courseEditSlidesCasesStyle.casesTable}
            dataSource={caseList}
            columns={casesCols}
            size="small"
            pagination={false}
          />
        </ConfigProvider>
      </div>
    </div>
  )

  const steps = [
    //创建课程步骤
    {
      title: '填写基本信息',
      content: basicContent,
    },
    {
      title: '编辑课程大纲',
      content: (
        <MenuEdit menu_id={menuId} menu_obj="" is_course_teacher={true} />
      ),
    },
    {
      title: '发布课程',
      content: courseEditSlidesCases,
    },
  ]

  const next = () => {
    if (current == 0) {
      const params = {
        course_id: courseId,
        name: courseName,
        description: courseDescription,
        cover: courseCover,
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
      }

      if (courseName == '' || courseName.trim() == '') {
        message.error('课程名称不能为空！')
        return ''
      }
      if (courseDescription == '' || courseDescription.trim() == '') {
        message.error('课程介绍不能为空！')
        return ''
      }
      if (courseCover == '' || courseCover.trim() == '') {
        message.error('请上传课程封面')
        return ''
      }
      saveCourseParams(params, false)
      setCurrent(current + 1)
    } else {
      queryMenu(menuId)
      setCurrent(current + 1)
    }
  }

  const prev = () => {
    setCurrent(current - 1)
  }
  /** 点击了保存课程按钮 */
  const onSaveCourseBtnClick = (e: any) => {
    console.log(courseName);
    
    if (current == 0) {
      const params = {
        course_id: courseId,
        name: courseName,
        description: courseDescription,
        cover: courseCover,
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
      }

      if (courseName == '' || courseName.trim() == '') {
        message.error('课程名称不能为空！')
        return ''
      }
      if (courseDescription == '' || courseDescription.trim() == '') {
        message.error('课程介绍不能为空！')
        return ''
      }
      if (courseCover == '' || courseCover.trim() == '') {
        message.error('请上传课程封面')
        return ''
      }
      saveCourseParams(params, true)
    } else if (current == 1) {
      const params = {
        course_id: courseId,
        menu_id: menuId,
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        name: courseName,
      }
      saveCourseParams(params, true)
    } else if (current == 2) {
      const params = {
        name: courseName,
        course_id: courseId,
        start_time: startTime,
        end_time: endTime,
        case: JSON.stringify(caseList),
        lecture_list: JSON.stringify(lectureList),
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
      }
      saveCourseParams(params, true)
    }
  }

  /** 修改了课件名 */
  const onSlideNameChange = (e: any) => {
    changedSlideName.current = e.target.value
  }
  const handleChangeSlideNameOk = () => {
    //确认修改了课件名
    setIsChangeSlideNameModalVisible(false)
    const newLectureList = [...lectureList]
    newLectureList.map((item) => {
      if (item.id == selectedSlideId.current) {
        item.name = changedSlideName.current
      }
    })
    setLectureList(newLectureList)

    saveCourse()
  }

  return (
    <Layout style={{ backgroundColor: '#f9f9f9' }}>
      <LoginRegisterModal></LoginRegisterModal>
      <div
        className={courseCreateStyles.maindiv}
        style={{ minHeight: 'calc(100vh - 216px)', marginBottom: 30 }}
      >
        <Row
          align="middle"
          style={{ borderBottom: '1px solid #eee', height: 60 }}
        >
          <Col flex="100px" style={{ textAlign: 'left' }}>
            {' '}
            <div style={{ fontWeight: 700, fontSize: 16 }}>创建课程</div>{' '}
          </Col>
          <Col flex="auto">
            <Steps
              size="small"
              labelPlacement="horizontal"
              current={current}
              style={{ marginTop: 0 }}
            >
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
          </Col>
          <Col flex="100px" style={{ color: '#333333', textAlign: 'right' }}>
            {' '}
            <a style={{ color: '#333333' }} href="/course">
              返回{'>'}
            </a>{' '}
          </Col>
        </Row>

        <div style={{ position: 'relative' }}>{steps[current].content}</div>
        <div
          style={{
            height: 60,
            lineHeight: '60px',
            width: '100%',
            backgroundColor: '#fff',
          }}
        >
          <div>
            {current > 0 && (
              <Button
                type="default"
                className={courseCreateStyles.stepsBtn}
                style={{ marginRight: 20 }}
                onClick={() => prev()}
              >
                上一步
              </Button>
            )}

            {current >= 0 && (
              <Button
                className={courseCreateStyles.stepsBtn}
                style={{ marginRight: 20 }}
                type="default"
                onClick={onSaveCourseBtnClick}
              >
                保存
              </Button>
            )}
            {current < steps.length - 1 && (
              <Button
                className={courseCreateStyles.stepsBtn}
                style={{ marginRight: 20, color: '#fff' }}
                type="primary"
                onClick={() => next()}
                //disabled={!uploadSuccess}
              >
                下一步
              </Button>
            )}
            {current == steps.length - 1 && (
              <Button
                className={courseCreateStyles.stepsBtn}
                style={{ marginRight: 20, color: '#fff', paddingRight: 10 }}
                type="primary"
                onClick={() => {
                  setIsModalVisible(true)
                }}
                //disabled={!uploadSuccess}
              >
                确认发布
              </Button>
            )}
          </div>
        </div>
      </div>

      <ConfigProvider locale={zhCN}>
        <Modal
          title={
            <div
              style={{ width: '100%', cursor: 'move' }}
              onMouseOver={() => {
                if (disabled) {
                  setDisabled(false)
                }
              }}
              onMouseOut={() => {
                setDisabled(true)
              }}
              onFocus={() => {}}
              onBlur={() => {}}
            >
              添加视频
            </div>
          }
          visible={addVideoVisible}
          width={500}
          //className={moduleStyles.addModal}
          destroyOnClose={true}
          closable={false}
          modalRender={(modal) => (
            <div className={moduleStyles.addModal}>
              <Draggable
                disabled={disabled}
                bounds={bounds}
                onStart={(event, uiData) => onStart(event, uiData)}
              >
                <div ref={draggleRef}>{modal}</div>
              </Draggable>{' '}
            </div>
          )}
          footer={
            <div>
              <Button
                onClick={handleAddVideoOk}
                style={{
                  borderRadius: 5,
                  backgroundColor: '#fafefc',
                  border: '1px solid #07c160',
                  color: '#07c160',
                }}
              >
                保存
              </Button>
            </div>
          }
        >
          <Row
            gutter={[8, 8]}
            justify="start"
            align="middle"
            style={{ marginBottom: 20 }}
          >
            <Col flex="80px" style={{ color: '#333', textAlign: 'left' }}>
              上传视频
            </Col>
            <Col
              flex="auto"
              style={{ textAlign: 'left', color: '#ccc', fontSize: 12 }}
            >
              请上传时长小于3小时的视频，支持主流的视频格式。
            </Col>
          </Row>

          <Row
            gutter={[8, 8]}
            justify="start"
            align="middle"
            style={{ marginBottom: 20 }}
          >
            <Col flex="80px"></Col>
            <Col
              flex="auto"
              style={{ textAlign: 'left', color: '#ccc', fontSize: 12 }}
            >
              <Button
                style={{
                  width: 100,
                  padding: 0,
                  marginLeft: 0,
                  borderRadius: 5,
                  backgroundColor: '#fff',
                  border: '1px solid #07c160',
                  color: '#07c160',
                }}
              >
                <UploadOutlined style={{ marginRight: 5 }} />
                <span style={{ marginLeft: 0 }}>上传视频</span>
              </Button>
            </Col>
          </Row>

          <Row
            gutter={[8, 8]}
            justify="start"
            align="middle"
            style={{ marginBottom: 20 }}
          >
            <Col flex="80px" style={{ color: '#333', textAlign: 'left' }}>
              标题
            </Col>
            <Col flex="auto" style={{ textAlign: 'left' }}>
              <Input
                placeholder="自动获取文件名称"
                style={{ borderRadius: 3 }}
              />
            </Col>
          </Row>

          <Row
            gutter={[8, 8]}
            justify="start"
            align="middle"
            style={{ marginBottom: 20 }}
          >
            <Col flex="80px" style={{ color: '#333', textAlign: 'left' }}>
              视频介绍
              <br />
              <span style={{ marginLeft: 10 }}>(选填)</span>
            </Col>
            <Col flex="auto" style={{ textAlign: 'left' }}>
              <TextArea
                maxLength={500}
                placeholder=""
                style={{ borderRadius: 3, width: '100%' }}
                autoSize={{ minRows: 5, maxRows: 5 }}
                value={courseDescription}
                onChange={(e) => {
                  setVideoDescription(e.target.value)
                  saveCourse()
                }}
              />
            </Col>
          </Row>
        </Modal>
      </ConfigProvider>

      <ConfigProvider locale={zhCN}>
        <Modal
          title={
            <div
              style={{ width: '100%', cursor: 'move' }}
              onMouseOver={() => {
                if (disabled) {
                  setDisabled(false)
                }
              }}
              onMouseOut={() => {
                setDisabled(true)
              }}
              onFocus={() => {}}
              onBlur={() => {}}
            >
              提示
            </div>
          }
          visible={cancelVideoVisible}
          width={350}
          //className={moduleStyles.addModal}
          destroyOnClose={true}
          closable={false}
          modalRender={(modal) => (
            <div className={moduleStyles.addModal}>
              <Draggable
                disabled={disabled}
                bounds={bounds}
                onStart={(event, uiData) => onStart(event, uiData)}
              >
                <div ref={draggleRef}>{modal}</div>
              </Draggable>{' '}
            </div>
          )}
          footer={
            <div>
              <Button
                onClick={handleAddVideoOk}
                style={{
                  borderRadius: 5,
                  backgroundColor: '#fafefc',
                  border: '1px solid #07c160',
                  color: '#07c160',
                  marginRight: 20,
                }}
              >
                确认
              </Button>
              <Button
                onClick={handleAddVideoOk}
                style={{
                  borderRadius: 5,
                  backgroundColor: '#07c160',
                  border: '1px solid #07c160',
                  color: '#fff',
                }}
              >
                继续上传
              </Button>
            </div>
          }
        >
          <div style={{ textAlign: 'center', marginTop: 10, marginBottom: 10 }}>
            是否确认取消视频上传？
          </div>
        </Modal>
      </ConfigProvider>

      <ConfigProvider locale={zhCN}>
        <Modal
          title={
            <div
              style={{ width: '100%', cursor: 'move' }}
              onMouseOver={() => {
                if (disabled) {
                  setDisabled(false)
                }
              }}
              onMouseOut={() => {
                setDisabled(true)
              }}
              onFocus={() => {}}
              onBlur={() => {}}
            >
              添加实验
            </div>
          }
          visible={addCaseVisible}
          width={800}
          bodyStyle={{ minHeight: 400, overflow: 'auto' }}
          //className={moduleStyles.addModal}
          onOk={handleAddCaseOk}
          onCancel={() => {
            queryCaseSource.current = '2'
            setAddCaseVisible(false)
            queryCaseList()
          }}
          destroyOnClose={true}
          closable={false}
          modalRender={(modal) => (
            <div className={moduleStyles.addModal}>
              <Draggable
                disabled={disabled}
                bounds={bounds}
                onStart={(event, uiData) => onStart(event, uiData)}
              >
                <div ref={draggleRef}>{modal}</div>
              </Draggable>{' '}
            </div>
          )}
        >
          <div>
            <Input
              onPressEnter={onCaseSearchBtn}
              placeholder="搜索实验（名称或ID）"
              style={{ borderRadius: 20, width: 300, marginBottom: 10 }}
              suffix={<SearchOutlined style={{ color: '#666666' }} />}
            />
          </div>

          <Tabs defaultActiveKey="2" onChange={onCaseTabKeyChange}>
            <TabPane tab="平台" key="2" className="">
              <div className={courseCreateStyles.tableWrapper}>
                <ConfigProvider locale={zhCN}>
                  <Table
                    dataSource={caseData}
                    columns={caseModalCols}
                    rowKey={(record) => record.case_id}
                    pagination={false}
                    size="small"
                    //scroll={{ x: 600 }}
                    //bordered={true}
                    onChange={onCaseTableChange}
                    rowSelection={{
                      type: 'checkbox',
                      ...caseRowSelection,
                    }}
                  />
                </ConfigProvider>
              </div>
            </TabPane>
            <TabPane tab="我的" key="1" className="">
              <div className={courseCreateStyles.tableWrapper}>
                <ConfigProvider locale={zhCN}>
                  <Table
                    dataSource={caseData}
                    columns={caseModalCols}
                    rowKey={(record) => record.case_id}
                    pagination={false}
                    size="small"
                    //scroll={{ x: 600 }}
                    //bordered={true}
                    onChange={onCaseTableChange}
                    rowSelection={{
                      type: 'checkbox',
                      ...caseRowSelection,
                    }}
                  />
                </ConfigProvider>
              </div>
            </TabPane>
          </Tabs>

          <ConfigProvider locale={zhCN}>
            <div
              style={{ marginBottom: 10, paddingTop: 10, textAlign: 'center' }}
            >
              <Pagination
                className={moduleStyles.pagination}
                total={caseTotalSum}
                defaultCurrent={1}
                defaultPageSize={10}
                current={casePage.current}
                onChange={onCasePageChange}
                hideOnSinglePage={true}
                style={{ textAlign: 'center' }}
              />
            </div>
          </ConfigProvider>
        </Modal>
      </ConfigProvider>

      <ConfigProvider locale={zhCN}>
        <Modal
          title={
            <div
              style={{
                width: '100%',
                cursor: 'move',
              }}
              onMouseOver={() => {
                if (disabled) {
                  setDisabled(false)
                }
              }}
              onMouseOut={() => {
                setDisabled(true)
              }}
              onFocus={() => {}}
              onBlur={() => {}}
              // end
            >
              课程发布
            </div>
          }
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          bodyStyle={{ height: '120px' }}
          destroyOnClose={true}
          width={400}
          closable={false}
          modalRender={(modal) => (
            <div className={courseCreateStyles.miniModal}>
              <Draggable
                disabled={disabled}
                bounds={bounds}
                onStart={(event, uiData) => onStart(event, uiData)}
              >
                <div ref={draggleRef}>{modal}</div>
              </Draggable>{' '}
            </div>
          )}
        >
          <Space size="small" direction="vertical" style={{ width: '100%' }}>
            <Row
              justify="center"
              align="middle"
              style={{ marginBottom: 30, color: '#333' }}
            >
              您确认要发布课程吗？
            </Row>

            {/* <Row justify="center" align="top">
                  课程名：{courseName}
                </Row> */}
            <Row justify="center" align="top">
              <Col span={6} style={{ textAlign: 'center' }}>
                公开设置
              </Col>
              <Col span={4}>
                <Switch
                  size="small"
                  checked={courseVisible == 1 ? true : false}
                  onClick={onCoursePublishedChange.bind(this)}
                />
              </Col>
              <Col span={14} style={{ color: '#999', fontSize: 12 }}>
                {' '}
                {courseVisible == 1
                  ? '已开放，允许学生看到课程'
                  : '未开放，学生无法看到课程'}
              </Col>
            </Row>
            <Row justify="center" align="top" style={{ marginTop: 10 }}>
              <Col span={6} style={{ textAlign: 'center' }}>
                申请开关
              </Col>
              <Col span={4}>
                <Switch
                  size="small"
                  checked={courseNeedApply == 2 ? true : false}
                  onClick={onCourseNeedApplyChange.bind(this)}
                />
              </Col>
              <Col span={14} style={{ color: '#999', fontSize: 12 }}>
                {' '}
                {courseNeedApply == 2
                  ? '已打开，学生加入课程需要审核'
                  : '未打开，学生加入不需要审核'}
              </Col>
            </Row>
          </Space>
        </Modal>
      </ConfigProvider>

      <ConfigProvider locale={zhCN}>
        <Modal
          title={
            <div
              style={{
                width: '100%',
                cursor: 'move',
              }}
              onMouseOver={() => {
                if (disabled) {
                  setDisabled(false)
                }
              }}
              onMouseOut={() => {
                setDisabled(true)
              }}
              onFocus={() => {}}
              onBlur={() => {}}
              // end
            >
              修改课件名称
            </div>
          }
          visible={isChangeSlideNameModalVisible}
          onOk={handleChangeSlideNameOk}
          onCancel={() => {
            setIsChangeSlideNameModalVisible(false)
          }}
          bodyStyle={{ height: '120px' }}
          destroyOnClose={true}
          width={350}
          modalRender={(modal) => (
            <div className={courseCreateStyles.miniModal}>
              <Draggable
                disabled={disabled}
                bounds={bounds}
                onStart={(event, uiData) => onStart(event, uiData)}
              >
                <div ref={draggleRef}>{modal}</div>
              </Draggable>{' '}
            </div>
          )}
        >
          <Input
            defaultValue={changedSlideName.current}
            onChange={onSlideNameChange}
          ></Input>
        </Modal>
      </ConfigProvider>

      <Feedback />
    </Layout>
  )
}

export default CourseCreate
