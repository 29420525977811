import React, {useEffect, useRef, useState} from "react";
import { Button, Col, Divider, Drawer, Layout, Row, Spin,message , Tabs, Tooltip, Tree } from "antd";
import { PageHeader } from "../common/page-header/header";
import PageFooter from "../common/page-footer/footer";
import coursePPTStyles from "./course-ppt.module.less";
import SplitPane from "react-split-pane";
import {LogoutOutlined,LockOutlined,FundProjectionScreenOutlined,BackwardOutlined,ForwardOutlined,CodeOutlined,RightCircleOutlined,ProfileOutlined,FileTextOutlined ,RedoOutlined,DownOutlined,UndoOutlined,FormOutlined,ClearOutlined,LeftCircleOutlined,KeyOutlined,UpOutlined,RightOutlined} from '@ant-design/icons';
const {TabPane} = Tabs
import "./course-split-pane.css";
import ExerciseCard from "../exercise/exercise-card";
import { sendHttpPostRequest } from "../../api/http-request";
import Cookies from "js-cookie";
import {checkIsTeacher} from "../../component/modal/teacher-authenticate/teacher-authenticate";

export interface Props {
    
}

const PPT = [
    {
        name: '第一章',
        title: '计算与计算思维.pdf',
        src: 'https://static.idatascience.cn/pdf/%E7%AC%AC1%E7%AB%A0%20%E8%AE%A1%E7%AE%97%E4%B8%8E%E8%AE%A1%E7%AE%97%E6%80%9D%E7%BB%B4.pdf',
    },{
        name: '第二章',
        title: 'Python语言基础.pdf',
        src: 'https://static.idatascience.cn/pdf/%E7%AC%AC2%E7%AB%A0%20%20Python%E8%AF%AD%E8%A8%80%E5%9F%BA%E7%A1%80.pdf',
    },{
        name: '第三章',
        title: '流程自动化.pdf',
        src: 'https://static.idatascience.cn/pdf/%E7%AC%AC3%E7%AB%A0%20%E6%B5%81%E7%A8%8B%E8%87%AA%E5%8A%A8%E5%8C%96.pdf',
    },{
        name: '第四章',
        title: 'Python存储自动化.pdf',
        src: 'https://static.idatascience.cn/pdf/%E7%AC%AC4%E7%AB%A0%20python%E5%AD%98%E5%82%A8%E8%87%AA%E5%8A%A8%E5%8C%96.pdf',
    },{
        name: '第五章',
        title: ' 计算思维与Python程序设计.pdf',
        src: 'https://static.idatascience.cn/pdf/%E7%AC%AC5%E7%AB%A0%20%E8%AE%A1%E7%AE%97%E6%80%9D%E7%BB%B4%E4%B8%8EPython%E7%A8%8B%E5%BA%8F%E8%AE%BE%E8%AE%A1.pdf',
    },{
        name: '第六章',
        title: '计算思维与Python程序设计.pdf',
        src: 'https://static.idatascience.cn/pdf/%E7%AC%AC6%E7%AB%A0%20%E8%AE%A1%E7%AE%97%E6%80%9D%E7%BB%B4%E4%B8%8EPython%E7%A8%8B%E5%BA%8F%E8%AE%BE%E8%AE%A1.pdf',
    },{
        name: '第七章',
        title: 'Python科学计算三维可视化.pdf',
        src: 'https://static.idatascience.cn/pdf/%E7%AC%AC7%E7%AB%A0%20Python%E7%A7%91%E5%AD%A6%E8%AE%A1%E7%AE%97%E4%B8%89%E7%BB%B4%E5%8F%AF%E8%A7%86%E5%8C%96.pdf',
    },{
        name: '第八章',
        title: 'Python 机器学习应用.pdf',
        src: 'https://static.idatascience.cn/pdf/%E7%AC%AC8%E7%AB%A0%20Python%20%E6%9C%BA%E5%99%A8%E5%AD%A6%E4%B9%A0%E5%BA%94%E7%94%A8.pdf',
    },
]

export const CoursePPTPreview: React.FC<Props> = (props) => {
    

    const [pptId,setPptId] = useState(parseInt(window.location.search.split("=")[1]));  // ppt的ID
    const ppt = PPT[pptId-1];
    const [currentActiveTabKey,setCurrentActiveTabKey] = useState("1");
    const [exerciseList, setExerciseList] = useState([] as any[]) //当前知识点对应的练习题列表
    const [menuVisible,setMenuVisible] = useState(false) //课程大纲弹出框是否可见
    const isTeacher = useRef(false);

    const exerciseNum = exerciseList.length //该知识点对应的测验习题数量
    const caseNum = 1//该知识点对应的实验数量 

    useEffect(()=> {
        if (checkIsTeacher()) {
            isTeacher.current = true;
        }
        queryExerciseList()
    }, []);

    const queryExerciseList = () => {
        sendHttpPostRequest(
            'exercise/api/query_exercise_list/',
            {
                sessionid: Cookies.get("sessionid"),
                query: "",
                page: 1,
                pagesize: 10,
                order: "",
                order_by: "",
                type: [],
                difficulty: JSON.stringify([]),
                create_time: "",
                source: 1,
                is_my:1,
                source_page: 2,  // 学习页面 知识点2
                source_key: isTeacher.current ? 3 : 4,  // 教师学习3，学生学习4
            },
            queryExerciseListCallback,
        )
    } 

    /**
     * 查询习题列表回调
     * @param response
     */
    const queryExerciseListCallback = (response: any) => {
        // console.log(response)
        message.destroy();
        if (response.code == -100) {
            // 未登录
            window.location.href = '/oam_login?next=oam_exercise_list';
        } else if (response.code == 0) {
            // 成功
            setExerciseList(response.exercise_list);
        } else if (response.code == -501) {
            // 权限不足
            window.location.href = '/oam_login?next=oam_exercise_list';
        } else {
            message.error(response.message);
        }
    }

    const prePPTBtn = () => {
        setPptId(pptId-1);
    }

    const nextPPTBtn = () => {
        setPptId(pptId+1);
    }

    const showMenuDrawer = (e:any) => {
        e.preventDefault();
        setMenuVisible(true);
    };

    const onMenuDrawerClose = (e:any) => {
        e.preventDefault();
        setMenuVisible(false)
    };

    /** 点击了退出学习按钮 */
    const logoutLearnBtnClick = () => {
        
    };

    interface Props2 {
        exerciseNum: number,
        caseNum:number
    }
    
    const CourseLearnExerciseCase: React.FC<Props2> = (props:Props2) => {
        const [selecetedExerciseIndex, setSelecetedExerciseIndex] = useState(0);//默认选中第一题
    
        const getExerciseBackColorByIndex = (index)=>{
            if(index == 0 || index == 2 || index == 3){
                return "#07c160"
            }else if(index == 1){
                return "#ff4b50"
            }else{
                return "#343434"
            }
        }
          
        return  <div className={coursePPTStyles.exerciseCaseTabpanelWrapper}>
            <Tabs  activeKey = {currentActiveTabKey} onChange={(activeKey:any)=>{setCurrentActiveTabKey(activeKey)}} type="card" size="middle" tabPosition="top"> 
                <TabPane  style={{paddingBottom:0}} tab={<div style={{width:'50%',fontSize:16,fontWeight:400}}><FormOutlined /> 练习</div>} key="1">
                    <div style={{paddingLeft:15,paddingRight:15,height:"calc(100vh - 116px)"}} >
                        <Row className={coursePPTStyles.exerciseSelectWrapper}>                          
                            { exerciseList?.map((exercise,index) =>{
                                return (
                                    <Col key={exercise?.id}>
                                        <div className={coursePPTStyles.exerciseIndexDiv} 
                                        style={{backgroundColor:getExerciseBackColorByIndex(index),border: index == selecetedExerciseIndex ? "1px solid #fff":0}}
                                        onClick={()=>{setSelecetedExerciseIndex(index)}} 
                                        >  
                                            {index+1} 
                                        </div>
                                    </Col>)
                                })
                            }
                        </Row>
        
                        <div style={{marginTop:0}}><ExerciseCard exercise={exerciseList[selecetedExerciseIndex]} renderType="dark" teacherOnly={true} editorMode={true} showAnser={false} showClean={true} /></div>
        
                    </div>
                </TabPane>
            
                <TabPane  tab={<div style={{width:'50%',fontSize:16,fontWeight:400}}><CodeOutlined />实验</div>}   key="2">                        
                        
                </TabPane>
                
            </Tabs>

            { currentActiveTabKey == "1"  &&
                <div style={{width:'100%',height:50,background: "#343434",position:'absolute',bottom:0}}>
                    
                    <Row align="middle"  justify="center" gutter={[16,16]} style={{height:50}}>
                        <Col>
                            <Button type="primary" style={{borderRadius:3,color:"#fff",height:30,width:80, backgroundColor:"#07c160"}}> 提 交 </Button>
                            <Button disabled={selecetedExerciseIndex == exerciseList.length - 1} onClick={()=>{setSelecetedExerciseIndex(selecetedExerciseIndex < exerciseList?.length -1 ? selecetedExerciseIndex + 1 : selecetedExerciseIndex)}}className={coursePPTStyles.btnDefault}  style={{marginLeft:50,borderRadius:3,height:30,width:80}}> 下一题 </Button>
                        </Col>
                    </Row>
                
                </div>            
            }
    
            { currentActiveTabKey == "2"  &&
                <div  style={{width:'100%',height:50,background: "#343434",position:'absolute',bottom:0}}>
                    <Row align="middle"  justify="start" gutter={[16,16]} style={{height:50}}>
                       
                    </Row>
                </div>              
            }
        </div>           
        
    }

    return (
        <Layout  className="course-ppt-preview" style={{backgroundColor: '#1f1f1f'}}>
            <SplitPane  allowResize={true} split="vertical" defaultSize={"50%"} primary="second" maxSize={-300}>
                <div style={{height: '100vh'}}> 
                    <div style={{height:50,background: "#343434" }}>
                        <Row style={{paddingTop:15}} justify='start' align='middle' gutter={[4,4]}>
                            <Col flex='15%'> <Tooltip placement="right" title="课程大纲">
                                <ProfileOutlined onClick={showMenuDrawer} className={coursePPTStyles.iconbuttons} /> </Tooltip></Col>
                            <Col flex='70%'> 
                                <Row justify='center' align='middle' gutter={[4,4]}>
                                    <Col flex='10%'><Tooltip placement="left"  title="上一章课件"><LeftCircleOutlined  onClick={()=>{prePPTBtn()}} className={coursePPTStyles.iconbuttons} /></Tooltip></Col>
                                    <Col flex='70%'> <div style={{fontWeight:600,color:'#f9f9f9'}}>{ppt.title.slice(0,ppt.title.length-4)}</div></Col>
                                    <Col flex='10%'> <Tooltip placement="right"  title="下一章课件"><RightCircleOutlined  onClick={()=>{nextPPTBtn()}} className={coursePPTStyles.iconbuttons} /></Tooltip></Col>
                                </Row>
                            </Col>
                            <Col flex='15%'> <Tooltip placement="right" title="退出学习"><LogoutOutlined onClick={logoutLearnBtnClick} className={coursePPTStyles.iconbuttons} /> </Tooltip>  </Col>
                        </Row>
                    </div>

                    <div style={{height:'calc(100vh - 100px)',background:"#000000"}} >
                        <div>
                            {/* <iframe src={ppt.src} style={{width:'100%',height:668,border:0}}/>  */}
                        </div>
                    </div>

                    <div style={{height:50,background: "#343434" }}></div>

                    <Drawer
                        title={<div style={{color:'#fff'}}>课件</div>}
                        placement="left"
                        closable={true}
                        onClose={onMenuDrawerClose}
                        visible={menuVisible}
                        key="left"
                        width={230}
                        headerStyle={{background:'#101010',height:50,border:0}}
                        drawerStyle={{overflow:'hidden',background:'#101010'}}
                        bodyStyle={{padding:10,color:'#fff',overflowX:'hidden',marginRight:-20,marginBottom:-20}}
                        contentWrapperStyle={{overflow:'hidden',opacity:0.7}}
                        closeIcon={<LeftCircleOutlined className={coursePPTStyles.iconbuttons} />}                       
                    >
                        <div className={coursePPTStyles.menuTrue}>
                            {/* <Tree
                                //showLine
                                switcherIcon={<DownOutlined />}
                                showIcon={false}
                                blockNode={true}
                                //fieldNames={{title:'name',key:'id',children:'modules'}}
                                treeData={treeData}
                                defaultExpandAll={true}
                                onSelect={handleModuleSelected}
                                titleRender={nodeData=>(
                                    
                                    <Row justify='start' align='middle' gutter={[4,4]} style={{height:20}}>
                                        <Col flex='auto'>{nodeData.title}</Col>
                                        <Col flex='30px'  >
                                            <div className={courseLearnStyles.progsetting}>
                                            Math.round(100*Math.random()+0.1) + '%' 
                                    {nodeData.selectable !== false && <Progress showInfo={false} strokeWidth={8} strokeColor='#07C160'  type="circle" percent={Math.round(100*Math.random()+0.1)} width={15}/> }
                                        </div></Col>      
                                    </Row>
                                        
                                    
                                )}
                                
                                style={{background:'#101010',color:'#fff'}}
                            ></Tree> */}
                        </div>
                    </Drawer>
                </div>

                <div className={coursePPTStyles.setting} style={{height: '100vh'}} > 
                    <div style={{width:'100%',height:'100%'}}>
                        <CourseLearnExerciseCase exerciseNum={exerciseNum} caseNum={caseNum} />
                    </div>               
                </div>
            </SplitPane> 
        </Layout>
    )
}

export default CoursePPTPreview