import {
  message,
  ConfigProvider,
  Button,
  Col,
  Input,
  Popconfirm,
  Row,
  Tooltip,
  Modal,
  Table,
  Statistic,
  Pagination,
  Tabs,
  Select,
  Radio,
} from 'antd'

import { sendHttpPostRequest } from '../../../api/http-request'
import React, { useState, useEffect, useRef } from 'react'
import Cookies from 'js-cookie'

const { TabPane } = Tabs
const { Option } = Select
const { Search } = Input

import Draggable from 'react-draggable'

import Qs from 'qs'

import axios from 'axios'
import * as constants from '../../../constants/constants'
import { logoutCallback } from '../../../pages/common/user-center/user-info'

import zhCN from 'antd/lib/locale/zh_CN'

import {
  PlusSquareOutlined,
  CloseCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import {
  DragDropContext,
  Droppable,
  Draggable as DndDraggable,
} from 'react-beautiful-dnd'
import MenuOutlined from '@ant-design/icons/lib/icons/MenuOutlined'

import myStyles from './menu-edit.module.less'
import TextArea from 'antd/lib/input/TextArea'
import idataCodingTableStyles from '../../common/global-styles/idatacourse-table.module.less'
import { AnyAaaaRecord, AnyRecord } from 'dns'
import { Link } from 'react-router-dom'
interface Module {
  id: number
  name: string
}

// 通过路由组件额外添加参数信息到表中
interface Props {
  menu_id: any //默认的大纲对象
}
const taskTypeMap = new Map([
  [1, '实训'],
  [6, '纯讲义'],
])
/** 模块化的大纲编辑组件，输入一个大纲对象，进行编辑可通过回调函数获取编辑后的大纲信息 */

export const MenuEdit: React.FC<Props> = (props: Props) => {
  const { menu_id } = props

  //console.log(menu_id)

  const [menuName, setMenuName] = useState('') //大纲名称
  const [menuDesp, setMenuDesp] = useState('') //大纲描述
  const [menuDirectory, setMenuDirectory] = useState([] as any) //大纲目录

  const currentTaskId = useRef(null as any) //记录输入的任务ID
  const currentTask = useRef(null as any) //记录当前任务信息

  const selectedTasks = useRef(null as any) //待添加的任务记录

  const currentChapterInd = useRef(null as any) //当前处理的章索引

  const [moduleResult, setModuleResult] = useState([] as any[]) // 当前显示的结果页

  const [resultSum, setResultSum] = useState(0) // 搜索返回的结果数
  const [totalCount, setTotalCount] = useState(0) // 搜索返回的结果数
  const queryContent = useRef('') // 记录搜索串
  const queryModuleIsMy = useRef('2') //2平台已经上架的
  const page = useRef(1) // 记录当前的页数
  const pageSize = useRef(10) // 记录当前每页展示数量

  // 查询知识点列表回调
  const queryModuleListCallback = (response: any) => {
    message.destroy()
    if (response.code == -100) {
      // 未登录
      window.location.href = '/oam_login?next=oam_module_list'
    } else if (response.is_ok == true) {
      // 成功
      setModuleResult(response.query_list)

      setResultSum(response.total_sum)
      setTotalCount(response.total_count)
      //setSysCount(response.sys_count);
      //setUserCount(response.user_count);
      //setOnlineCount(response.online_count);
    } // else if (response.code == -501) {
    // 权限不足
    //window.location.href = '/oam_login?next=oam_chapter';
    //} else {
    //message.error(response.message);
    //}
  }

  // 查询知识点列表
  const queryModuleList = () => {
    sendHttpPostRequest(
      'course/api/user_query_knowcell_list',
      {
        sessionid: Cookies.get('sessionid'),
        page: page.current,
        pageSize: pageSize.current,
        query: queryContent.current,
        is_my: queryModuleIsMy.current,
      },
      queryModuleListCallback
    )
  }
  /** 以下是添加章弹出框显示用到的变量和函数 */

  const [isAddTaskModalVisible, setIsAddTaskModalVisible] = useState(false)

  const draggleRef = useRef<HTMLDivElement | null>(null)

  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
  const [disabled, setDisabled] = useState(true)

  const onStart = (event: any, uiData: any) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement
    const targetRect = draggleRef?.current?.getBoundingClientRect()
    if (targetRect) {
      setBounds({
        left: -targetRect?.left + uiData?.x,
        right: clientWidth - (targetRect?.right - uiData?.x),
        top: -targetRect?.top + uiData?.y,
        bottom: clientHeight - (targetRect?.bottom - uiData?.y),
      })
    }
  }

  /** 选择了任务,keyi */
  const rowTaskSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      selectedTasks.current = selectedRows
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record: any) => ({
      disabled: checkContainsTaskInMenu(record.id) > 0,
      //name: record.name,
    }),
  }

  const handleAddChapterOk = () => {
    //添加章
    //window.open(`/exercise-edit?exercise_type=${selectedType}`);
    // const newID =  Date.parse(new Date().toString())
    sendHttpPostRequest(
      'course/api/oam_create_chapter',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
      },
      (response: any) => {
        const newChapters = [...menuDirectory]
        //console.log(newID)
        const newID = response.chapter_id
          ? response.chapter_id
          : Date.parse(new Date().toString())
        newChapters.push({ id: newID, name: '', tasks: [] })
        setMenuDirectory(newChapters)
        saveMenu()
      }
    )
  }

  const handleAddTaskOk = () => {
    //添加任务
    //window.open(`/exercise-edit?exercise_type=${selectedType}`);
    
    //onAddedTaskConfirm();

    const newChapters = [...menuDirectory]

    selectedTasks.current.map((row) => {
      newChapters[currentChapterInd.current].tasks.push({
        id: row.id,
        name: row.name,
        type: row.type,
        video_url: row.video_url,
        case: row.case,
        practice: row.practice,
      })
    })
    setMenuDirectory(newChapters.filter((group) => group.tasks.length >= 0))

    saveMenu()
    setTimeout(() => {
      setIsAddTaskModalVisible(false)
    }, 100);
  }

  const showAddTaskModal = () => {
    setIsAddTaskModalVisible(true)
  }

  const handleAddTaskCancel = () => {
    setIsAddTaskModalVisible(false)
  }

  useEffect(() => {
    queryMenu()
    queryModuleList()
  }, [isAddTaskModalVisible])

  //点击了搜索按钮
  const onSearchTaskBtn = (e: any) => {
    page.current = 1
    queryContent.current = e.target.value
    queryModuleList()
  }

  //页码和页数发生了变化
  const onTaskPageChange = (
    newPage: number,
    newPageSize?: number | undefined
  ) => {
    page.current = newPage ? newPage : 1
    if (newPageSize) {
      pageSize.current = newPageSize
    }
    queryModuleList()
  }

  // 渲染操作
  const renderActions = (text: any, record: any) => {
    return (
      <div>
        <Button
          style={{ width: 50, borderRadius: 20, fontSize: 14, marginRight: 10 }}
          size="small"
          type="default"
          onClick={() => {
            window.open('/module-learn-preview?module_id=' + record.id)
          }}
        >
          {' '}
          预 览{' '}
        </Button>
        <Button
          style={{ width: 50, borderRadius: 20, fontSize: 14, margin: 0 }}
          size="small"
          type="default"
          onClick={() => {
            window.open('/oam_module_edit?module_id=' + record.id)
          }}
        >
          {' '}
          编 辑{' '}
        </Button>
      </div>
    )
  }

  /**
   * 查询大纲
   */
  const queryMenu = () => {
    sendHttpPostRequest(
      'course/api/oam_query_menu_by_id',
      {
        sessionid: Cookies.get('sessionid'),
        menu_id: menu_id,
      },
      queryMenuCallback
    )
  }

  /**
   * 查询大纲回调
   * @param response
   */
  const queryMenuCallback = (response: any) => {
    // console.log(response)
    message.destroy()
    if (response.code == -100) {
      // 未登录
      window.location.href = '/oam_login?next=oam_menu'
    } else if (response.is_ok == true) {
      // 成功
      setMenuName(response.query_info.name)
      setMenuDesp(response.query_info.description)
      setMenuDirectory(response.query_info.json)
      //message.success(response.query_info.name)
    } /*else if (response.code == -501) {
            权限不足
            window.location.href = '/oam_login?next=oam_menu';
        } */ else {
      message.error(response.message)
    }
  }

  /**
   * 保存大纲
   */
  const saveMenu = () => {
    //console.log(menuDirectory)
    sendHttpPostRequest(
      'course/api/oam_update_menu',
      {
        sessionid: Cookies.get('sessionid'),
        menu_id: menu_id,
        name: menuName,
        description: menuDesp,
        json: JSON.stringify(menuDirectory),
      },
      saveMenuCallback
    )
  }

  /**
   * 保存大纲回调
   * @param response
   */
  const saveMenuCallback = (response: any) => {
    // console.log(response)
    message.destroy()
    if (response.code == -100) {
      // 未登录
      window.location.href = '/oam_login?next=oam_menu'
    } else if (response.is_ok == true) {
      // 成功
    } /*else if (response.code == -501) {
            权限不足
            window.location.href = '/oam_login?next=oam_menu';
        } */ else {
      message.error(response.message)
    }
  }

  const reorder = (list: any, startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  /**
   * Moves an item from one list to another list.
   */
  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source)
    const destClone = Array.from(destination)
    const [removed] = sourceClone.splice(droppableSource.index, 1)

    destClone.splice(droppableDestination.index, 0, removed)

    const result = {}
    result[droppableSource.droppableId] = sourceClone
    result[droppableDestination.droppableId] = destClone

    return result
  }
  const grid = 4

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    borderRadius: 0,
    background: isDragging ? '#eefff6' : 'white',
    ...draggableStyle,
  })
  const getListStyle = (isDraggingOver) => ({
    borderRadius: 5,
    margin: 'auto',
    marginBottom: 10,
  })

  function onDragEnd(result) {
    const { source, destination } = result

    // dropped outside the list
    if (!destination) {
      return
    }
    const sInd = +source.droppableId
    const dInd = +destination.droppableId

    if (sInd === dInd) {
      //知识点在同一个章节内排序
      const items = reorder(
        menuDirectory[sInd].tasks,
        source.index,
        destination.index
      ) as Module[]
      const newState = [...menuDirectory]
      newState[sInd].tasks = items
      setMenuDirectory(newState)
    } else {
      //知识点在不同章节内移动排序
      const result = move(
        menuDirectory[sInd].tasks,
        menuDirectory[dInd].tasks,
        source,
        destination
      )
      const newState = [...menuDirectory]
      newState[sInd].tasks = result[sInd]
      newState[dInd].tasks = result[dInd]
      setMenuDirectory(newState)
    }
  }

  //点击了查看按钮
  const onLookOverBtnClick = (event: any) => {
    //message.success('查看数据' + event.currentTarget.value)
    event.preventDefault()
    window.open('/oam_menu_detail?menu_id=' + menu_id)
  }

  //点击了保存按钮
  const onSaveBtnClick = (event: any) => {
    saveMenu()
    message.success('大纲保存成功~')
  }

  const [selectedRowKeys, setSelectedRowKeys] = React.useState([])

  const rowSelection = {
    selectedRowKeys,
    onChange: (keys) => {
      setSelectedRowKeys(keys)
    },
  }

  /** 检查当前大纲中是否包含某一个任务 */
  const checkContainsTaskInMenu = (taskId: number | string) => {
    let result = 0
    menuDirectory?.map((chapter) => {
      result =
        result + chapter?.tasks?.filter((item) => item.id == taskId).length
    })
    //console.log(result)
    return result
  }

  // 更新拉取任务
  const updateTaskInfo = (taskId, taskInd, chapterInd) => {
    const _headers = {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    }
    const params = { know_id: taskId, sessionid: Cookies.get('sessionid') }
    const url = 'course/api/oam_query_knowcell_by_id/'
    const _data = Qs.stringify(params)
    axios
      .post(constants.BACKEND_API_URL + url, _data, _headers)
      .then((response) => response.data)
      .then((data) => {
        if (data.is_ok) {
          menuDirectory[chapterInd].tasks[taskInd].name = data.query_info?.name
          menuDirectory[chapterInd].tasks[taskInd].type = data.query_info?.type
          menuDirectory[chapterInd].tasks[taskInd].video_url =
            data.query_info?.video_url
          menuDirectory[chapterInd].tasks[taskInd].case = data.query_info?.case
          menuDirectory[chapterInd].tasks[taskInd].practice =
            data.query_info?.practice

          setMenuDirectory([...menuDirectory])
          message.success(taskId + '信息更新成功~')
          saveMenu()
        } else {
          message.error(taskId + '信息更新失败！')
        }
      })
      .catch((e) => {
        console.log('make http post request error', e)
        message.destroy()
        message.error('服务器请求失败了:(')
      })
  }

  const myCols: any = [
    {
      title: '序号',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      align: 'left',
      width: 60,
    },
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: 120,
      ellipsis: true,
    },
    {
      title: '类型',
      dataIndex: 'type',
      key: 'type',
      width: 50,
      align: 'center',
      ellipsis: false,
      render: (text: any, record: any) => {
        return text == 1 ? '课件' : '视频'
      },
    },
    {
      title: '实验',
      dataIndex: 'case',
      key: 'case',
      width: 50,
      align: 'center',
      ellipsis: false,
      render: (text: any, record: any) => {
        return text?.case_id ? '1' : '0'
      },
    },
    {
      title: '练习',
      dataIndex: 'practice',
      key: 'practice',
      width: 50,
      align: 'center',
      ellipsis: false,
      render: (text: any, record: any) => {
        return text?.length
      },
    },
    {
      title: '作业考试',
      dataIndex: 'exam',
      key: 'exam',
      width: 50,
      align: 'center',
      ellipsis: false,
      render: (text: any, record: any) => {
        return text?.length
      },
    },
    {
      title: '操作',
      dataIndex: 'actions',
      key: 'actions',
      width: 180,

      align: 'center',

      render: renderActions,
    },
  ]

  // 点击创建知识点
  const onCreateModuleBtnClick = (event: any) => {
    event.preventDefault()
    sendHttpPostRequest(
      'course/api/oam_create_knowcell',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
      },
      (response: any) => {
        if (response.is_ok && response.know_id) {
          window.open(`/oam_module_edit?module_id=${response.know_id}`)
        } else {
          message.destroy()
          message.error(`创建知识点失败：${response.message}`)
        }
      }
    )
  }

  /** 修改了某一章的名称 */
  const onChapterNameChange = (chapterId, chapterInd, event: any) => {
    menuDirectory[chapterInd].name = event.target.value
    saveMenu()
    const newChapters = [...menuDirectory]
    setMenuDirectory(JSON.parse(JSON.stringify(newChapters)))
  }

  return (
    <div style={{ marginTop: 0 }}>
      <div style={{ textAlign: 'right', marginBottom: 20 }}>
        <Button
          style={{ borderRadius: 20, width: 80, marginRight: 20 }}
          type={'primary'}
          onClick={onSaveBtnClick}
        >
          保 存
        </Button>
        <Button
          style={{ borderRadius: 20, width: 80, marginRight: 20 }}
          type={'primary'}
          onClick={onLookOverBtnClick}
        >
          预 览
        </Button>
      </div>
      <Row
        gutter={[8, 8]}
        justify="start"
        align="middle"
        style={{ marginBottom: 20 }}
      >
        <Col flex="80px" style={{ color: '#00000073', textAlign: 'left' }}>
          名称
        </Col>
        <Col flex="auto" style={{ textAlign: 'left' }}>
          <Input
            placeholder="请输入大纲名称"
            required={true}
            value={menuName}
            onChange={(e) => {
              setMenuName(e.target.value)
            }}
            style={{ borderRadius: 3, width: '100%' }}
          />
        </Col>
      </Row>

      <Row
        gutter={[8, 8]}
        justify="start"
        align="top"
        style={{ marginBottom: 20 }}
      >
        <Col flex="80px" style={{ color: '#00000073', textAlign: 'left' }}>
          介绍
        </Col>
        <Col flex="auto" style={{ textAlign: 'left' }}>
          <TextArea
            placeholder="请输入大纲介绍"
            style={{ borderRadius: 3, width: '100%' }}
            autoSize={{ minRows: 3 }}
            value={menuDesp}
            onChange={(e) => {
              setMenuDesp(e.target.value)
            }}
          />
        </Col>
      </Row>

      <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
        <DragDropContext onDragEnd={onDragEnd}>
          {' '}
          {/*拖拽上下文 */}
          {menuDirectory?.map((chapter, chapterInd) => (
            <Droppable key={chapterInd} droppableId={`${chapterInd}`}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                  {...provided.droppableProps}
                >
                  <Row
                    gutter={[0, 4]}
                    justify="start"
                    align="middle"
                    className={myStyles.chapterHeader}
                  >
                    <Col
                      flex="80px"
                      style={{ fontWeight: 500, paddingLeft: 10 }}
                    >
                      {' '}
                      {'第' + (chapterInd + 1) + '章'}
                    </Col>
                    <Col
                      flex="auto"
                      style={{ textAlign: 'left', overflow: 'hidden' }}
                    >
                      {' '}
                      <Input
                        onChange={onChapterNameChange.bind(
                          this,
                          chapter.id,
                          chapterInd
                        )}
                        style={{ width: 200 }}
                        value={chapter.name}
                      ></Input>{' '}
                    </Col>

                    <Col
                      flex="250px"
                      style={{ textAlign: 'right', marginRight: 10 }}
                    >
                      <Button
                        onClick={() => {
                          currentChapterInd.current = chapterInd
                          showAddTaskModal()
                        }}
                        size="small"
                        style={{ marginRight: 10, borderRadius: 3 }}
                        type={'primary'}
                      >
                        {' '}
                        + 知识点
                      </Button>

                      <Popconfirm
                        placement="top"
                        title={'确认删除章"' + chapter.name + '"吗？'}
                        onConfirm={() => {
                          const newChapters = [...menuDirectory]
                          newChapters.splice(chapterInd, 1)
                          setMenuDirectory(
                            JSON.parse(JSON.stringify(newChapters))
                          )
                        }}
                        okText="确认"
                        cancelText="取消"
                      >
                        <CloseCircleOutlined
                          className={myStyles.moduleBtn}
                          style={{ marginRight: 5 }}
                        />
                      </Popconfirm>
                    </Col>
                  </Row>

                  {chapter.tasks.map((task, taskIndex) =>
                    task.is_delete == 1 ? (
                      <DndDraggable
                        key={task.id}
                        draggableId={String(task.id)}
                        index={taskIndex}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <div
                              className={myStyles.moduleWrapper}
                              style={{ textAlign: 'left' }}
                            >
                              {' '}
                              {/* 知识点div */}
                              <Row
                                wrap={false}
                                gutter={[0, 4]}
                                justify="start"
                                align="middle"
                                className={myStyles.module}
                              >
                                <Col
                                  flex="60px"
                                  style={{
                                    marginLeft: 20,
                                    textAlign: 'center',
                                  }}
                                >
                                  <Tooltip title="拖拽排序">
                                    <MenuOutlined
                                      className={myStyles.moduleBtn}
                                    />
                                  </Tooltip>{' '}
                                  {chapterInd + 1 + '.' + (taskIndex + 1)}{' '}
                                </Col>
                                <Col flex="auto">
                                  {' '}
                                  <div
                                    style={{
                                      overflow: 'hidden',
                                      minWidth: 500,
                                    }}
                                  >
                                    {' '}
                                    {
                                      menuDirectory[chapterInd].tasks[taskIndex]
                                        .name
                                    }
                                  </div>
                                </Col>

                                <Col
                                  flex="200px"
                                  style={{
                                    textAlign: 'right',
                                    marginRight: 10,
                                  }}
                                >
                                  {/* <Button size="small" type="default" style={{marginRight:10,borderRadius:3}} onClick={()=>{updateTaskInfo(task.id,taskIndex,chapterInd)}} >刷新</Button>    */}
                                  <Button
                                    size="small"
                                    type="default"
                                    style={{ marginRight: 10, borderRadius: 3 }}
                                    onClick={() => {
                                      window.open(
                                        '/module-learn-preview?module_id=' +
                                          task.id
                                      )
                                    }}
                                  >
                                    预览
                                  </Button>
                                  <Button
                                    size="small"
                                    type="default"
                                    style={{
                                      marginRight: 10,
                                      borderRadius: 3,
                                      border: '1px solid #3B6D74',
                                      color: '#3B6D74',
                                    }}
                                    onClick={() => {
                                      window.open(
                                        '/oam_module_edit?module_id=' + task.id
                                      )
                                    }}
                                  >
                                    编辑
                                  </Button>
                                  <Popconfirm
                                    placement="top"
                                    title={
                                      '确认删除知识点"' +
                                      (chapterInd + 1) +
                                      '.' +
                                      (taskIndex + 1) +
                                      ' ' +
                                      task.name +
                                      '"吗？'
                                    }
                                    onConfirm={() => {
                                      const newMenuDirectory = [
                                        ...menuDirectory,
                                      ]
                                      newMenuDirectory[chapterInd].tasks.splice(
                                        taskIndex,
                                        1
                                      )
                                      setMenuDirectory(
                                        newMenuDirectory.filter(
                                          (group) => group.tasks.length >= 0
                                        )
                                      )
                                      saveMenu()
                                    }}
                                    okText="确认"
                                    cancelText="取消"
                                  >
                                    <CloseCircleOutlined
                                      className={myStyles.moduleBtn}
                                      style={{ marginRight: 5 }}
                                    />
                                  </Popconfirm>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        )}
                      </DndDraggable>
                    ) : (
                      ''
                    )
                  )}

                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ))}
        </DragDropContext>
      </div>

      <div style={{ textAlign: 'right', marginBottom: 20 }}>
        <Button
          onClick={handleAddChapterOk}
          className={myStyles.addChapterBtn}
          style={{
            width: '100%',
            height: 42,
            borderRadius: 0,
            marginRight: 30,
          }}
          type="default"
        >
          {' '}
          + 添加章
        </Button>
      </div>

      <ConfigProvider locale={zhCN}>
        <Modal
          title={
            <div
              style={{
                width: '100%',
                cursor: 'move',
              }}
              onMouseOver={() => {
                if (disabled) {
                  setDisabled(false)
                }
              }}
              onMouseOut={() => {
                setDisabled(true)
              }}
              onFocus={() => {}}
              onBlur={() => {}}
            >
              添加知识点
            </div>
          }
          closable={false}
          visible={isAddTaskModalVisible}
          onOk={handleAddTaskOk}
          onCancel={handleAddTaskCancel}
          bodyStyle={{ height: 500, overflow: 'auto' }}
          destroyOnClose={true}
          width={800}
          modalRender={(modal) => (
            <div className={myStyles.createDiv}>
              <Draggable
                disabled={disabled}
                bounds={bounds}
                onStart={(event, uiData) => onStart(event, uiData)}
              >
                <div ref={draggleRef}>{modal}</div>
              </Draggable>{' '}
            </div>
          )}
        >
          <div>
            <Input
              onPressEnter={onSearchTaskBtn}
              placeholder="名称/ID"
              style={{ borderRadius: 20, width: 250, marginBottom: 10 }}
              suffix={<SearchOutlined style={{ color: '#666666' }} />}
            />
            <Button
              className={myStyles.modalBtn}
              type="primary"
              size="small"
              style={{ margin: 0, float: 'right', height: 32 }}
              onClick={onCreateModuleBtnClick}
            >
              <PlusSquareOutlined />
              创建
            </Button>
          </div>

          <div className={idataCodingTableStyles.tableWrapper}>
            <ConfigProvider locale={zhCN}>
              <Table
                dataSource={moduleResult}
                columns={myCols}
                rowKey={(record) => record.id}
                pagination={false}
                size="small"
                rowSelection={{
                  type: 'checkbox',
                  ...rowTaskSelection,
                  preserveSelectedRowKeys: true,
                }}
              />
            </ConfigProvider>
          </div>
          <ConfigProvider locale={zhCN}>
            <div
              style={{ marginBottom: 10, paddingTop: 10, textAlign: 'center' }}
              className={'css.pagination'}
            >
              <Pagination
                defaultCurrent={1}
                total={resultSum}
                defaultPageSize={10}
                hideOnSinglePage={true}
                current={page.current}
                onChange={onTaskPageChange}
                showTotal={(total) => `找到 ${total} 知识点`}
                showSizeChanger={false}
              />
            </div>
          </ConfigProvider>
        </Modal>
      </ConfigProvider>
    </div>
  )
}

export default MenuEdit
