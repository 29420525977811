import React, { useState, useRef, useEffect } from 'react';
;import zhCN from 'antd/lib/locale/zh_CN';
import { Pagination,ConfigProvider,Layout, Table, Input, Statistic, Row, Col, Button, Modal, Radio, message } from 'antd';
import 'antd/lib/style/index.css';
import Cookies from 'js-cookie';
import { sendHttpPostRequest } from "../../../api/http-request";
import OAMSider from "../oam-sider";
import { Content } from 'antd/lib/layout/layout';

import idataCourseTableStyles from "../../common/global-styles/idatacourse-table.module.less";

const { Search, TextArea } = Input;
export interface Props {}

const usageMap = new Map([['科研',3],['教学',2],['其他',1]])
const statusMap = new Map([['待解决',1],['已解决',2],['延迟',3]])

export const OAMUserApply: React.FC<Props> = (props: Props) => {
    const [userResult, setUserResult] = useState([] as any[]);  // 当前显示的结果页
    const [resultSum, setResultSum] = useState(0);  // 搜索返回的结果数
    const [isStatusModalVisible, setIsStatusModalVisible] = useState(false);
    const [statusApplyId, setStatusApplyId] = useState(-1);  // 待处理的申请ID
    const [applyStatus, setApplyStatus] = useState(1);  // 待处理的申请宁的状态
    const [applyMessage,setApplyMessage] = useState('');  // 待处理的申请的备注信息
    const [totalCount, setTotalCount] = useState(0);  // 搜索返回的结果数

    const queryContent = useRef('');  // 记录搜索串
    const order = useRef('');  // 排序升序降序
    const order_by = useRef('');  // 排序列
    const page = useRef(1);  // 记录当前的页数
    const pageSize = useRef(20);  // 记录当前每页展示数量
    const userIsLogin = useRef(false);  // 用户是否登录

    const usageFilters = useRef([]);
    const statusFilters = useRef([]);

    useEffect(() => {
        queryApplyInfo();
    }, [])

    //
    const onApplyMessageChange = async (e: any) => {
        setApplyMessage(e.target.value);
    }

    const onApplyStatusRadioChange = (e:any) => {
        setApplyStatus(e.target.value);
    }

    const handleStatusCancel = () => {
        setIsStatusModalVisible(false);
    }

    const updateUserApplyCallback = (response: any) => {
        message.destroy();
        response.code == -100 ? userIsLogin.current = false : userIsLogin.current = true;
        if (response.code == -100) {
            // 未登录
            window.location.href = '/oam_login?next=user_list';
        } else if (response.code == -501) {
            // 权限不足
            window.location.href = '/oam_login?next=user_list';
        } else {
            if (response.is_ok) {
                message.success("更新用户申请信息成功！");
                queryApplyInfo();
                window.location.reload();
            } else {
                message.error("更新用户申请信息失败了:(")
            }
        }
    }
    const handleStatusOk = () => {
        setIsStatusModalVisible(false);
        sendHttpPostRequest(
            'account/api/oam_update_user_apply_by_id/',
            {
                au_id: statusApplyId,
                status: applyStatus,
                message: applyMessage,
                sessionid: Cookies.get("sessionid"),
                sid: Cookies.get("sessionid"),
            },
            updateUserApplyCallback
        )
    }

    const queryApplyInfoCallback = (response:any) => {
        message.destroy();
        response.code == -100 ? userIsLogin.current = false : userIsLogin.current = true;
        if (response.code == -100) {
            // 未登录
            window.location.href = '/oam_login?next=user_list';
        } else if (response.code == -501) {
            // 权限不足
            window.location.href = '/oam_login?next=user_list';
        } else {
            if (response.is_ok) {
                setUserResult(response.qs_list);
                setResultSum(response.total_sum);
                setTotalCount(response.total_count);
            } else {
                message.error("查询用户申请列表失败了:(");
            }
        }
    }

    // 向后端请求数据列表
    const queryApplyInfo = () => {
        sendHttpPostRequest(
            'account/api/oam_query_user_apply_list/',
            {
                query: queryContent.current,
                page: page.current,
                pagesize: pageSize.current,
                order: order.current,
                order_by: order_by.current,
                usage:JSON.stringify(usageFilters.current),
                status:JSON.stringify(statusFilters.current),
                sessionid: Cookies.get("sessionid"),
                sid: Cookies.get("sessionid"),
            },
            queryApplyInfoCallback
        )
    }

    //点击了搜索按钮
    const onSearchBtn = (value: string) => {
        page.current = 1;
        queryContent.current = value;
        queryApplyInfo();
    }

    const onTableChange:any = (pagination:any, filters:any, sorter:any, extra: { currentDataSource: [], action: any })=>{
        if (sorter.order) {
            //如果选择了排序列
            order.current = sorter.order;
            order_by.current= sorter.field;
        }
        if (filters.usage) {
            //如果选择了过滤列
            usageFilters.current = filters.usage.map(element=> {
                return usageMap.get(element);
            });
        }
        if (filters.status) { //如果选择了过滤列
            statusFilters.current = filters.status.map(element=> {
                return statusMap.get(element);
            });
        }
        page.current = 1;
        queryApplyInfo();
    }

    //页码和页数发生了变化
    const onPageChange = (newPage: number, newPageSize?: number | undefined) => {
        page.current = newPage?newPage : 1;
        if (newPageSize) {
            pageSize.current = newPageSize;
        }
        queryApplyInfo()
    }

    //点击了待解决按钮
    const onHandleStatusBtn = (event:any) => {
        setStatusApplyId(event.currentTarget.value);
        setIsStatusModalVisible(true);
    }

    const renderStatus = (text:any, record:any) => {
        if (text == 1)
            return ( <Button size='small' style={{borderRadius:20}} type='primary' value={record.id} onClick={onHandleStatusBtn}>{'待解决'}</Button>);
        else if (text == 2 ) {
            return (<div>{'已解决'}</div>);
        }
        else
            return (<div>{'延迟'}</div>);
    }

    const cols: any = [
        {
            title: "序号",
            dataIndex: "id",
            key: "id",
            width: 80,
            align: 'center',
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: "姓名",
            dataIndex: "username",
            key: "username",
            width: 80,
            align: 'left',
        },
        {
            title: "手机号",
            dataIndex: "phone",
            key: "phone",
            width: 80,
            align: 'left',
        },
        {
            title: "院校",
            dataIndex: "institute",
            key: "institute",
            width: 80,
            align: 'left',
        },
        {
            title: "专业/方向",
            dataIndex: "direction",
            key: "direction",
            width: 80,
            align: 'left',
        },
        {
            title: "职务",
            dataIndex: "position",
            key: "position",
            width: 80,
            align: 'left',
        },
        {
            title: "使用人数",
            dataIndex: "usercount",
            key: "usercount",
            width: 50,
            align: 'left',
        },
        {
            title: "备注信息",
            dataIndex: "comment",
            key: "comment",
            width: 80,
            align: 'left',
        },
        {
            title: "用途",
            dataIndex: "usage",
            key: "usage",
            width: 50,
            align: 'left',
            render: (value:any)=> {
                if (value == 2) {
                    return '教学'
                } else if (value == 3) {
                    return '科研'
                }
            },
            filters: [
                {
                    text: '教学',
                    value: '教学',
                },
                {
                    text: '科研',
                    value: '科研',
                },
                {
                    text: '其他',
                    value: '其他',
                }
            ],
            onFilter: (value:any, record:any) => true,
        },
        {
            title: "申请时间",
            dataIndex: "create_time",
            key: "create_time",
            width: 80,
            align: 'left',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: "反馈意见",
            dataIndex: "message",
            key: "message",
            width: 80,
            align: 'left',
        },
        {
            title: "处理状态",
            fixed: "right",
            dataIndex: "status",
            key: "status",
            width: 80,
            align: 'left',
            filters: [
                {
                    text: '待解决',
                    value: '待解决',
                },
                {
                    text: '已解决',
                    value: '已解决',
                },
                {
                    text: '延迟',
                    value: '延迟',
                }
            ],
            onFilter: (value:any, record:any) => true,
            render:renderStatus,
        }
    ]

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <OAMSider keys={"13"} openKeys={["sub-user"]}></OAMSider>
            <Layout style={{backgroundColor:'#f9f9f9'}}>
                <Content style={{ backgroundColor:'#fff',borderRadius:5,minWidth:400,margin:30 ,paddingTop:20}}>
                    <div style={{paddingLeft:30,paddingRight:30}}>
                        <Row align="middle"  gutter={16} >
                            <Col flex='80px' >
                                <Statistic title="申请数量" value={totalCount} />
                            </Col>
                            <Col flex='auto'  style={{textAlign: "left"}}>
                                <div className={"css.search1"} style={{paddingBottom:20}}>
                                    <Search
                                        placeholder="搜索申请信息"
                                        style={{ width: 350 }}
                                        enterButton={true}
                                        onSearch={onSearchBtn}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <div className={idataCourseTableStyles.tableWrapper}>
                            <ConfigProvider locale={zhCN}>
                                <Table
                                    dataSource={userResult}
                                    columns={cols}
                                    rowKey={record => record.id}
                                    pagination={false}
                                    scroll={{ x: 800 }}
                                    //bordered={true}
                                    onChange={onTableChange}
                                />
                            </ConfigProvider>
                        </div>

                        <ConfigProvider locale={zhCN}>
                            <div style={{marginBottom:30,paddingTop:30}} className={"css.pagination"} >
                                <Pagination
                                    defaultCurrent={1}
                                    total={resultSum}
                                    defaultPageSize={20}
                                    current={page.current}
                                    hideOnSinglePage={true}
                                    onChange={onPageChange}
                                    showQuickJumper={true}
                                    showTotal={total => `共 ${total} 申请`}
                                    pageSizeOptions={["10","20","30","50","100"]}
                                />
                            </div>

                            <Modal title="处理意见" visible={isStatusModalVisible} onOk={handleStatusOk} onCancel={handleStatusCancel}>
                                <Radio.Group onChange={onApplyStatusRadioChange} value={applyStatus}>
                                    <Radio value={2}>已解决</Radio>
                                    <Radio value={3}>延迟处理</Radio>
                                </Radio.Group>
                                <TextArea
                                    style={{marginTop:20}}
                                    placeholder="备注信息"
                                    rows={8}
                                    maxLength={300}
                                    onChange={onApplyMessageChange} >
                                </TextArea>
                            </Modal>
                        </ConfigProvider>
                    </div>
                </Content>
            </Layout>
        </Layout>
    )
}

export default OAMUserApply
