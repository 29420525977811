import React, {useEffect, useRef, useState} from "react";
import {Statistic,Steps,Breadcrumb,Input,Table,Layout, message,Image, Col, Row, ConfigProvider, Pagination, Tabs, Button, Tag, DatePicker, Tooltip, Modal, Popconfirm, Divider, Popover, Upload } from 'antd';
import { PageHeader } from "../../common/page-header/header";
import { sendHttpPostRequest } from "../../../api/http-request";
import examPreviewStyles from "./course-teacher-exam-preview.module.less";
import zhCN from 'antd/lib/locale/zh_CN'
import LoginRegisterModal from "../../../component/modal/login-register/login-register";
import {checkUserLogin} from "../../common/user-center/user-info";
import { FundProjectionScreenOutlined,DeleteOutlined,FilePdfOutlined,ExportOutlined,PieChartOutlined,FileWordOutlined,TableOutlined,ClockCircleOutlined,EyeOutlined,FileTextOutlined,DownloadOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';
import ExerciseCard from "../../exercise/exercise-card";
import scoreBg from "../../../static/image/scoreBg.png";
import scoreIcon from "../../../static/image/scoreIcon.png";
import * as constants from "../../../constants/constants";
import { BACKEND_API_URL, BACKEND_URL } from '../../../constants/constants';

import formatIcon from '../../../static/image/formatIcon.png';
import moment from 'moment';

import 'echarts-wordcloud';

import * as echarts from 'echarts/core';
import {
  TitleComponent,
  TitleComponentOption,
  TooltipComponent,
  TooltipComponentOption,
  LegendComponent,
  LegendComponentOption
} from 'echarts/components';
import { PieChart, PieSeriesOption } from 'echarts/charts';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

import Draggable from "react-draggable";
import PageFooter from "../../common/page-footer/footer";


echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer,
  LabelLayout
]);

const { Countdown } = Statistic;//倒计时控件

export interface Props {
  exam:any;
  exerciseList:any
}



const typeMap = new Map([[1, '实操题'], [2, '单选题'], [3, '多选题'],[4,"排序题"],[5, "主观题"]]);
export interface Props {}
/** 学生查看考试详情页面 */
export const CourseStudentExamPreview: React.FC<Props> = (props) => {

    const examId = parseInt(window.location.search.split("&")[0].split("=")[1]);//考试ID
    const courseId = parseInt(window.location.search.split("&")[1].split("=")[1]);//课程

    const [exam, setExam] = useState( null as any);// 保存当前考试对象

    const [examStatus, setExamStatus] = useState(null as any);//["未发布","未开考","考试中","阅卷中","已完成"]

    /** 考试试题列表 */
    const [examExercises,setExamExercises] = useState([] as any[]);


    const typeCountMap = new Map([['实操题', 0], ['单选题', 0], ['多选题', 0],['排序题',0],['主观题', 0]]);
    exam?.exercise.map(item => {
      const typeCount = Number(typeCountMap.get(typeMap.get(item.type) as string));
      typeCountMap.set(typeMap.get(item.type) as string, typeCount + 1);

    })





    const exerciseId = useRef(0);  // 题目id
    const [exerciseIdList, setExerciseIdList] = useState([] as any []) // 题目id列表

    const [exerciseResult, setExerciseResult] = useState([] as any[]);  // 当前显示的结果页
    const [exerciseResultType, setExerciseResultType] = useState(0);  // 当前显示的考试类型
    const [exerciseResultID, setExerciseResultID] = useState(0);  // 当前显示的考试ID
    const [selecetedExerciseIndex, setSelecetedExerciseIndex] = useState(0);//默认选中第一题
    const [completeStatus, setCompleteStatus] = useState([] as any []);
    const [rightStatus, setRightStatus] = useState([] as any []);  // 做对的列表
    const [wrongStatus, setWrongStatus] = useState([] as any []);  // 做对的列表
    const [myScore, setMyScore] = useState(null as any);//我的考试分数
    const [totalScore, setTotalScore] = useState(null as any);//考试总分


    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isFinishedVisible, setIsFinishedVisible] = useState(false);
    const [isSuccessVisible, setIsSuccessVisible] = useState(false);


    const [startExamBtnDisabled, setStartExamBtnDisabled] = useState(true);   


    const [exerciseAnswerPublished, setExerciseAnswerPublished] = useState(false);//老师是否公布了考试答案

    const [exerciseScores, setExerciseScores] = useState(null as any);//学生考题得分
  

    // 基本习题框架
    const baseExerciseInfo:any = {
        answer_parse: "",
        create_time: "",
        creator: "",
        creator_id: 1,
        dataset_path: "",
        datasets: [],
        default_code: "",
        difficulty: 1,
        difficulty_str: "",
        exam_id: 0,
        hint_submit_btn: 1,
        id: 1,
        images: [],
        is_delete: 1,
        judgement_code: "",
        language: 2,
        language_str: "python3",
        options: [{id: "1", options: "1"}, {id: "2", options: "2"}, {id: "3", options: "3"}, {id: "4", options: "4"}],
        options_answer: [],
        preset_code: "",
        reference_answer: "",
        remarks: "",
        score: 0,
        source: 1,
        source_key: 4,
        source_page: 4,
        status: 1,
        steps: "",
        submission_info: {
            is_html: false,
            submission_answer: [],
            submission_code: "",
            submission_id: 0,
            submission_image: [],
            submission_output: "",
            submission_status: 3,
            submission_time: "",
            submission_variables: []
        },
        title: "",
        type: 8,
        type_name: "",
        update_time: ""
    }

    const [disabled,setDisabled] = useState(true)
    const [bounds,setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const draggleRef = useRef<HTMLDivElement | null>(null)
    const onStart = (event:any, uiData:any) => {
      const { clientWidth, clientHeight } = window?.document?.documentElement;
      const targetRect= draggleRef?.current?.getBoundingClientRect();
      if(targetRect){
        setBounds( {
          left: -targetRect?.left + uiData?.x,
          right: clientWidth - (targetRect?.right - uiData?.x),
          top: -targetRect?.top + uiData?.y,
          bottom: clientHeight - (targetRect?.bottom - uiData?.y),
        }
      )
      }


    }


    useEffect(()=> {
      queryExam();
    },[])



          // 查询考试回调
          const queryExamCallback = (response:any) => {
            message.destroy();
            if (response.code == -100) {
                // 未登录
                window.location.href = '/login?next=course-main-student?course_id=' + courseId + "&tabKey=5";
            } else if (response.is_ok == true) {
                // 成功

                setExam(response.query_info);
                setExerciseAnswerPublished(response.query_info.publish_answer == 2 ? true : false);

                if(response.query_info.publish_score == 2) {//公布了分数
                    queryExamExerciseScore();
                }
                let _exerciseIdList = [] as any [];
                response.query_info?.exercise?.map(item => {
                    const typeCount = Number(typeCountMap.get(typeMap.get(item.type) as string));
                    typeCountMap.set(typeMap.get(item.type) as string, typeCount + 1);
                    _exerciseIdList.push(item.id);
                })
                setExerciseIdList(_exerciseIdList);

                

                setExamExercises(response.query_info?.exercise);
                setExamStatus(response.query_info?.exam_status);
                let exam_status = response.query_info?.exam_status;
                if (response.query_info.status == 2 && exam_status == "考试中" && !response.query_info.submit_exam) {
                  userStartExam();
                } else if (response.query_info.publish_score == 2 || response.query_info.publish_answer == 2) {
                    setExamStatus("已完成");
                } else {
                    setExamStatus("阅卷中");
                }
                //let exam_status = refreshExamStatus(response.query_info.status, response.query_info.publish_score,response.query_info.start_time,response.query_info.end_time,response.query_info.submit_exam);

                if (response.query_info?.exercise.length > 0) {
                  exerciseId.current = response.query_info?.exercise[0]["id"];
                  queryExerciseById(exam_status, response.query_info.publish_answer == 2 ? true : false);
                  queryExamSubmission();
              }
            } else if (response.code == -501) {
             //权限不足
             window.location.href = '/login?next=course-main-student?course_id=' + courseId + "&tabKey=5";
            } else {
            //message.error(response.message);
            }
        }

        // 查询考试信息列表
        const queryExam = () => {
            sendHttpPostRequest(
                'exam/api/user_query_exam_by_id/',
                {
                    sessionid: Cookies.get("sessionid"),
                    exam_id: examId,
                    course_id: courseId

                },
                queryExamCallback
            )
        }

        // 查询考试提交情况
        const queryExamSubmission = () => {
            sendHttpPostRequest(
                'exam/api/user_query_self_exam_submission/',
                {
                    sessionid: Cookies.get("sessionid"),
                    exam_id: examId,
                    course_id: courseId
                },
                (response: any) => {
                    message.destroy();
                    if (response.code == -100) {
                        // 未登录
                        document.getElementById("show-login-modal")!.click();
                    } else if (response.is_ok) {
                        setCompleteStatus(response.query_list);
                        let _rightStatus = [] as any [];
                        let _wrongStatus = [] as any [];
                        response.query_list?.map(item => {
                            [1, 3].includes(item) ? _wrongStatus.push(item) : _rightStatus.push(item);
                        })
                        setRightStatus(_rightStatus);
                        setWrongStatus(_wrongStatus);

                        setMyScore(response.person_score);
                        setTotalScore(response.total_score);
                    } else {
                        message.error(response.message);
                    }
                }
            )
        }

    /**
     * 用户开始考试，由后端判断是否记录过，没记录则记录一次，有记录则不记录，前端只管发送请求
     */
    const userStartExam = () => {
        try {
            sendHttpPostRequest(
                'exam/api/user_start_exam/',
                {
                    sessionid: Cookies.get("sessionid"),
                    exam_id: examId,
                },
                (response: any) => {

                }
            )
        } catch (e) {
            console.error(e);
        }
    }

    const refreshExamStatus = (pushishStatus:number, publish_score: number, startTime: any, endTime: any, submitExam: any) => { //更新考试状态
      const currentTime = moment().utc().zone(-8);
      const start = moment(startTime).utc().zone(-8);
      const end = moment(endTime).utc().zone(-8);
      if(pushishStatus == 1){//未发布
        setExamStatus("未发布")
        return "未发布"
      }else if(pushishStatus == 2 && currentTime < start){
        setExamStatus("未开考")
        return "未开考"
      }else if(pushishStatus == 2 && currentTime > start && currentTime < end){
          if (submitExam) {
              setExamStatus("阅卷中");
              return "阅卷中"
          } else {
              setExamStatus("考试中");
              userStartExam();
              return "考试中"
          }
      }else if(pushishStatus == 2 && currentTime > end && publish_score == 1){
        setExamStatus("阅卷中")
        return "阅卷中"
      }else if(pushishStatus == 2 && currentTime > end && publish_score == 2){
        setExamStatus("已完成")
        return "已完成"
      }

    }


         /** 计算考试总分 */
  const getTotalScore = ()=>{
    let sumScore = 0;
    examExercises.map(item=>{
        if (parseInt(item.score) == 0)
            sumScore += 5;
        else
            sumScore += parseInt(item.score);
    })

    return sumScore
  }

  //计算考试时长
  const getExamDuration = ()=>{
    const t1 = moment(exam?.start_time).utc().zone(-8);
    const t2 = moment(exam?.end_time).utc().zone(-8);
    const duration = t2.diff(t1, 'minute');
    console.log(duration)

    return duration

  }


    const isFinishedOk = () => {
      setIsFinishedVisible(false);
      setIsModalVisible(true);
      setIsSuccessVisible(false);
    };

    const isFinishedCancel = () => {
      setIsFinishedVisible(false);
    };

    const handleOk = () => {
        try {
            sendHttpPostRequest(
                'exam/api/user_submit_exam',
                {
                    sessionid: Cookies.get("sessionid"),
                    sid: Cookies.get("sessionid"),
                    exam_id: examId,
                    type: 1
                },
                (response: any) => {
                    console.error(response.message);
                }
            )
        } catch (e) {
            console.error(e);
        }
      setIsFinishedVisible(false);
      setIsModalVisible(false);
      setIsSuccessVisible(true);
      setTimeout(function() {
        setIsSuccessVisible(false);
        window.location.href=`/course-student-exam-preview?exam_id=${examId}&course_id=${courseId}`;
        
      },2000)
    };

    const handleCancel = () => {
      setIsFinishedVisible(false);
      setIsModalVisible(false);
    };


    //未完成习题
    const notFinished= completeStatus.filter((value)=> {
      return value==1;
    });

    
    /** 答题状态和颜色的映射 */
    const getColorByStatus = (status:number)=>{
      if (status == 1) {
        return "#eee"
      }else {
        return "#38CD7F"
      }
    }

      /** 答题正误状态和颜色的映射 */
      const getColorBySuccess = (status:number, type: number)=>{
        if (status == 1) {//未做
          return "#fff"
        }else {
          if(type == 5 ){//做了，是主观题
            return "#47B1DF"
          }else {
              if (status == 2){ //做了，做对了
                return "#38CD7F"
              }else {
                return "#FF6F73" //做了，做错了
              }
          }
        }
      }

    /** 答题状态和题号颜色的映射 */
    const getFontColorByStatus = (status:number)=>{
      if (status == 1) {
        return "#666"
      }else{
        return "#fff"
      }
    }

    /** 选中题号和答题状态与题号颜色的映射 */
    const  getBorderByStatus = (index: number,selecetedExerciseIndex: number,status: number)=> {
      if(index == selecetedExerciseIndex && status==1) {
          return "1px solid #38CD7F"
      }
      else
          return 0;
    }

    const  getLineHeight = (index: number,selecetedExerciseIndex: number,status: number)=> {
      if(index == selecetedExerciseIndex && status==1) {
          return "26px"
      }
      else
          return "30px";
    }

    const  getOpacity = (index: number,selecetedExerciseIndex: number,status: number)=> {
      if(index != selecetedExerciseIndex && status!=1) {
          return 0.75
      }
      else
          return 1;
    }

    const [imgs, setImgs] = useState([] as any []);
    const [ppt, setPpt] = useState([] as any []);
    const [isUploading, setIsUploading] = useState(false);  // 是否正在上传文件

    /**
     * 查询习题
     */
    const queryExerciseById = (exam_status, showAnswer) => {
        setExerciseResult(baseExerciseInfo);
        sendHttpPostRequest('exercise/api/query_exercise_by_id/',
            {
                sessionid: Cookies.get("sessionid"),
                sid: Cookies.get("sessionid"),
                exercise_id: exerciseId.current,
                source_page: 4,  // 考试4
                source_key: 4,   // 学生考试4
                exam_id: examId,    // 考试id
                hint_submit_btn: exam_status == "考试中" ? 1 : 2,   // 隐藏提交按钮
                show_answer: showAnswer == true ? 1 : 0,//返回答案信息,
            },
            (response: any) => {
                message.destroy();
                if (response.code == -100) {
                    // 未登录
                    document.getElementById("show-login-modal")!.click();
                } else if (response.is_ok) {
                    setExerciseResult(response.exercise_info);
                    setExerciseResultType(response.exercise_info.type);
                    setExerciseResultID(response.exercise_info.id);
                    queryExamSubmission();
                    if (response.exercise_info.type == 5) {
                        // 主观题 查询 学生上传的附件（图片）列表
                        queryStudentAttachmentList(response.exercise_info.id);
                    }
                } else {
                    message.error(response.message);
                }
            }
        )
    }

    // 查询 主观题 学生 上传的 附件（图片） 列表
    const queryStudentAttachmentList = (exerciseId: number) => {
      sendHttpPostRequest(
          'exam/api/user_query_student_exam_attachment',
          {
              sessionid: Cookies.get("sessionid"),
              sid: Cookies.get("sessionid"),
              exam_id: examId,
              exercise_id: exerciseId,
          },
          (response: any) => {
              if (response.is_ok) {
                  setImgs(response.image_list);
                  setPpt(response.attachment_list);
                  setCurrentExerciseId(response.exercise_id);
              }
          }
      )
    }

    const [currentExerciseId, setCurrentExerciseId] = useState(0);  // 上传附件时的题目ID
    const onUploadBtnClick = (exerciseId:number) => {
      setCurrentExerciseId(exerciseId);
    }

    // 上传文件传参
    const uploadData = {
      sessionid: Cookies.get("sessionid"),
      sid: Cookies.get("sessionid"),
      exam_id: examId,
      exercise_id: currentExerciseId,
  }

  // 上传图片文件
  const uploadImageProps = {
      accept: ".png,.jpeg,.jpg,.gif",
      name: 'data_file',
      multiple: false,
      maxCount:1,
      showUploadList: isUploading == false ? false:
          { showPreviewIcon: false, showRemoveIcon: false, showDownloadIcon: false,previewIcon: false},
      data: uploadData,
      progress: {strokeColor:'#07c160',strokeWidth:2,showInfo:false},
      action: constants.BACKEND_API_URL + 'exam/api/user_upload_exercise_image/',
      beforeUpload: file => {
          if (file.size > ((1024 * 1024 * 1024) / 100) * 3) {
              message.error("请上传小于30M的文件");
              return false;
          }
      },
      onChange(info) {
          const { status } = info.file;
          if (status == 'uploading') {
              setIsUploading(true);
              console.log(info.file, info.fileList);
          }
          if (status === 'done') {
              setIsUploading(false);
              message.destroy();
              if (info.file.response.is_ok) {
                  message.success(`文件${info.file.name}上传成功！`);
                  if (info.file.response.file_repeat) {
                      // 传过，修改原来的

                  } else {
                      // 未传过，append
                      const newImageList = [...imgs];
                      newImageList.push({id:info.file.response.id, name: info.file.name, url: info.file.response.url, size: info.file.response.file_size});
                      setImgs(newImageList);
                  }
              } else {
                  if (info.file.response.code == -100) {
                      // 未登录
                      document.getElementById("show-login-modal")!.click();
                  } else if (info.file.response.code == -501) {
                      // 权限不足
                  } else {
                      message.error(info.file.response.message);
                  }
              }
          } else if (status === 'error') {
              message.error(`文件${info.file.name}上传失败了:(`);
          }
      },
      onDrop(e) {
          console.log('Dropped files', e.dataTransfer.files);
      },
  };

  // 上传附件
  const uploadAttachmentProps = {
      accept: ".pptx,.pdf,.docx",
      name: 'data_file',
      multiple: false,
      maxCount:1,
      showUploadList: isUploading == false ? false:
          { showPreviewIcon: false, showRemoveIcon: false, showDownloadIcon: false,previewIcon: false},
      data: uploadData,
      progress: {strokeColor:'#07c160',strokeWidth:2,showInfo:false},
      action: constants.BACKEND_API_URL + 'exam/api/user_upload_exercise_attachment/',
      beforeUpload: file => {
          if (file.size > ((1024 * 1024 * 1024) / 100) * 5) {
              message.error("请上传小于50M的文件");
              return false;
          }
      },
      onChange(info) {
          const { status } = info.file;
          if (status == 'uploading') {
              setIsUploading(true);
              console.log(info.file, info.fileList);
          }
          if (status === 'done') {
              setIsUploading(false);
              message.destroy();
              if (info.file.response.is_ok) {
                  message.success(`文件${info.file.name}上传成功！`);
                  if (info.file.response.file_repeat) {
                      // 传过，修改原来的

                  } else {
                      // 未传过，append
                      const newPptList = [...ppt];
                      newPptList.push({id:info.file.response.id, name: info.file.name, url: info.file.response.url, size: info.file.response.file_size});
                      setPpt(newPptList);
                  }
              } else {
                  if (info.file.response.code == -100) {
                      // 未登录
                      document.getElementById("show-login-modal")!.click();
                  } else if (info.file.response.code == -501) {
                      // 权限不足
                  } else {
                      message.error(info.file.response.message);
                  }
              }
          } else if (status === 'error') {
              message.error(`文件${info.file.name}上传失败了:(`);
          }
      },
      onDrop(e) {
          console.log('Dropped files', e.dataTransfer.files);
      },
  };

      // 删除附件
      const deleteAttachment = (attachmentId: any, attachmentName: any) => {
          // console.log(attachmentId, attachmentName);
          sendHttpPostRequest(
              'exam/api/user_delete_exercise_attachment',
              {
                  sessionid: Cookies.get("sessionid"),
                  sid: Cookies.get("sessionid"),
                  exam_id: examId,
                  exercise_id: currentExerciseId,
                  file_id: attachmentId,
                  file_name: attachmentName,
              },
              (response: any) => {
                  if (response.is_ok) {
                      message.destroy();
                      message.success(`文件${attachmentName}删除成功`);
                      setPpt(response.attachment_list);
                  }
              }
          )
      }

  // 删除图片
  const deleteImage = (imageId: any, imageName: any) => {
      // console.log(imageId, imageName);
      sendHttpPostRequest(
          'exam/api/user_delete_exercise_image',
          {
              sessionid: Cookies.get("sessionid"),
              sid: Cookies.get("sessionid"),
              exam_id: examId,
              exercise_id: currentExerciseId,
              file_id: imageId,
              file_name: imageName,
          },
          (response: any) => {
              if (response.is_ok) {
                  message.destroy();
                  message.success(`图片${imageName}删除成功`);
                  setImgs(response.image_list);
              }
          }
      )
  }


    /** 点击了下一题按钮 */
    const onNextExerciseBtnClick = () => {
        if (examStatus == "考试中") {
            try {
                // 先提交再切题
                document.getElementById(`exercise-run-${exerciseId.current}`)!.click();
            } catch (e) {
                console.error(e);
            }
        }
      selecetedExerciseIndex < exerciseIdList?.length - 1 ?setSelecetedExerciseIndex(selecetedExerciseIndex + 1):setSelecetedExerciseIndex(0);
      selecetedExerciseIndex < exerciseIdList?.length - 1 ? exerciseId.current = exerciseIdList[selecetedExerciseIndex + 1] : exerciseId.current = exerciseIdList[0];
      queryExerciseById(examStatus, exerciseAnswerPublished);
    }
    /** 点击了上一题按钮 */
    const onPrevExerciseBtnClick = () => {
        if (examStatus == "考试中") {
            try {
                // 先提交再切题
                document.getElementById(`exercise-run-${exerciseId.current}`)!.click();
            } catch (e) {
                console.error(e);
            }
        }
        selecetedExerciseIndex < exerciseIdList?.length ?setSelecetedExerciseIndex(selecetedExerciseIndex - 1):setSelecetedExerciseIndex(exerciseIdList?.length - 1);
      selecetedExerciseIndex < exerciseIdList?.length ? exerciseId.current = exerciseIdList[selecetedExerciseIndex - 1] : exerciseId.current = exerciseIdList[exerciseIdList?.length - 1];
      queryExerciseById(examStatus, exerciseAnswerPublished);
    }
    /** 点击了习题方块按钮 */
    const onExerciseBtnClick = (index) => {
        if (examStatus == "考试中") {
            try {
                // 先提交再切题
                document.getElementById(`exercise-run-${exerciseId.current}`)!.click();
            } catch (e) {
                console.error(e);
            }
        }
      setSelecetedExerciseIndex(index);
        exerciseId.current = exerciseIdList[index];
        queryExerciseById(exam?.exam_status, exerciseAnswerPublished);
    }
    /** 点击了交卷按钮 */
    const submitPractice =() => {
        try {
            // 先提交再算
            document.getElementById(`exercise-run-${exerciseId.current}`)!.click();
        } catch (e) {
            console.error(e);
        }
        

        setTimeout(function() {
          queryExamSubmission();
          if (notFinished.length !==  0) {
              setIsFinishedVisible(true);
              setIsModalVisible(false);
          } else {
              setIsModalVisible(true);
              setIsFinishedVisible(false);
          }
      }, 2000);
        
       
    }
    const [isAutoSubmitModalVisible, setIsAutoSubmitModalVisible] = useState(false);//自动交卷体制弹窗
    const [autoSubmitDeadLine, setAutoSubmitDeadLine] =  useState(null as any);
    
    const submitExamBySystemPre = ()=>{
      setAutoSubmitDeadLine(Date.now() + 1000 * 5);
      setIsAutoSubmitModalVisible(true);

    }
    // 系统自动交卷
    const submitExamBySystem = () => {
        
      setIsAutoSubmitModalVisible(false);
          try {
            // 先提交再算
            document.getElementById(`exercise-run-${exerciseId.current}`)!.click();
        } catch (e) {
            console.error(e);
        }
        try {
            sendHttpPostRequest(
                'exam/api/user_submit_exam',
                {
                    sessionid: Cookies.get("sessionid"),
                    sid: Cookies.get("sessionid"),
                    exam_id: examId,
                    type: 2   // 1-手动交卷 2-自动交卷
                },
                (response: any) => {
                    console.error(response.message);
                }
            )
        } catch (e) {
            console.error(e);
        }
        queryExam();
     
    }


      // 根据id查询习题得分,只有主观题才需要额外查询
      const queryExamExerciseScore = () => {
        sendHttpPostRequest(
            'exam/api/user_query_exam_score',
            {
                sessionid: Cookies.get("sessionid"),
                sid: Cookies.get("sessionid"),
                exam_id: examId,
            },
            (response: any) => {
                if (response.code == -100) {
                    // 未登录
                    document.getElementById("show-login-modal")!.click();
                } else if (response.is_ok) {
                    
                  setExerciseScores(response.query_info.exercise_list);
                  console.log(response.query_info.exercise_list);
                } else {
                    console.error(response.message);
                }
            }
        )
    }


    return (
        <Layout style={{minHeight: 750,backgroundColor: '#f9f9f9',position:'relative'}}>
          
          <LoginRegisterModal></LoginRegisterModal>

            {examStatus=='未开考' &&
              <div className={examPreviewStyles.courseMain} style={{position:'relative',width:800,minHeight:"calc(100vh - 204px)"}}>
                <div style={{height:60,lineHeight:'60px',fontSize:20,fontWeight:700,color:'#333'}}>{exam?.name}</div>
                <div style={{position:'absolute',right:0,top:0,width:100,height:60,lineHeight:'60px'}}><a style={{color:"#333333"}} href={"/course-main-student?course_id="+ courseId + "&tabKey=5"}>返回{">"}</a></div>
                <Row>
                  <Col style={{textAlign:'left'}} span={16}>
                    <div><span style={{color:'#07C160',fontSize:16,marginRight:6}}><ClockCircleOutlined /></span>{exam?.start_time} 至 {exam?.end_time}</div>
                    <div style={{marginTop:10}}>共 <span style={{color:'#07C160'}}>{exam?.exercise?.length}</span> 道题 总分 <span style={{color:'#07C160'}}>{getTotalScore()}</span> 分   考试时长：<span style={{color:'#07C160'}}>{getExamDuration()}</span> 分钟</div>
                      <div style={{ fontWeight:500,textAlign:'left',marginTop:10,color:'#666'}}>考生姓名：{exam?.student_info?.real_name && exam?.student_info?.real_name != "" ? exam?.student_info?.real_name : exam?.student_info?.name}&nbsp;&nbsp;&nbsp;&nbsp;学 / 工号：{exam?.student_info?.number}</div>
                    <div style={{fontWeight:500,textAlign:'left',marginTop:10,color:'#666'}}>考试说明:</div>
                    <div style={{marginTop:5}}>{exam?.description}</div>
                  </Col>
                  <Col span={8}>
                        <div style={{border:"1px solid #eee",margin:'20px auto 0',padding:5,width:200,height:75,textAlign:'center'}}>
                          <Countdown valueStyle={{color:'red',fontSize:24}} title={<span style={{color:'#333'}}>等待考试,倒计时:</span>} value={exam?.start_time} onFinish={()=>{setStartExamBtnDisabled(false)}} />
                        </div>
                  </Col>
                </Row>
                <div style={{marginTop:50}}>
                  <Button disabled={startExamBtnDisabled} type="primary" onClick={()=>{queryExam()}} style={{ borderRadius:20,width:120,height:36}}>开始考试</Button>
                </div>
              </div>
            }

            {examStatus=='考试中' &&
            <Row style={{margin:'0 auto',width:1100,flexWrap:'nowrap',overflow:'visible',maxWidth:'100%'}}>
              <Col style={{width:250,marginRight:20,float:'left',backgroundColor:'#fff',minHeight:580,marginTop:40}}>
                <div style={{height:60,lineHeight:'60px',fontSize:16,color:'#333'}}>答题卡</div>
                  <div style={{height: 40, textAlign: 'left', fontSize:12, color:'#333', margin: "auto 24px 10px 24px"}}>
                      <div>考生姓名：{exam?.student_info?.real_name && exam?.student_info?.real_name != "" ? exam?.student_info?.real_name : exam?.student_info?.name}</div>
                      <div>学 / 工号：{exam?.student_info?.number}</div>
                  </div>
                <Row style={{padding:'0 20px',fontSize:12}}>
                  <Col span={4}>共<span style={{color:'#07c160',margin:4}}>{exam?.exercise?.length}</span>题</Col>
                  <Col span={4}>实操<span style={{color:'#07c160',margin:4}}>{typeCountMap.get('实操题')}{" "}</span></Col>
                  <Col span={4}>单选<span style={{color:'#07c160',margin:4}}>{typeCountMap.get('单选题')}{" "}</span></Col>
                  <Col span={4}>多选<span style={{color:'#07c160',margin:4}}>{typeCountMap.get('多选题')}{" "}</span></Col>
                  <Col span={4}>排序<span style={{color:'#07c160',margin:4}}>{typeCountMap.get('排序题')}{" "}</span></Col>
                  <Col span={4}>主观<span style={{color:'#07c160',margin:4}}>{typeCountMap.get('主观题')}{" "}</span></Col>

                </Row>
                <Divider style={{marginTop:10}}/>
                <Row align='middle' justify= {'start'} gutter={[2,2]} style={{textAlign:"left",width:"100%",padding:'0 25px'}}>
                { completeStatus.map((status:any,index:number)=>(
                    <Col key={index}>
                      <div onClick={()=>{onExerciseBtnClick(index)}}
                        className={examPreviewStyles.coursePractice}
                        style={{backgroundColor:getColorByStatus(status),color:getFontColorByStatus(status),border: getBorderByStatus(index,selecetedExerciseIndex,status),lineHeight:getLineHeight(index,selecetedExerciseIndex,status),opacity:getOpacity(index,selecetedExerciseIndex,status)}}>
                        {index+1}
                      </div>
                    </Col>
                  ))
                  }
                </Row>
              </Col>
              <Col className={examPreviewStyles.courseMain} style={{position:'relative',width:800,float:'left',padding:0,overflowY:'auto',height:"calc(100vh - 185px)"}}>
                <div style={{height:60,lineHeight:'60px',fontSize:20,fontWeight:700,color:'#333'}}>{exam?.name}</div>
                <div style={{position:'absolute',right:0,top:0,width:100,height:60,lineHeight:'60px'}}><a style={{color:"#333333"}} href={"/course-main-student?course_id="+ courseId + "&tabKey=5"}>返回{">"}</a></div>
                <Row style={{paddingLeft:30}} >
                  <Col style={{textAlign:'left'}} span={16}>
                    <div><span style={{color:'#07C160',fontSize:16,marginRight:6}}><ClockCircleOutlined /></span>{exam?.start_time} 至 {exam?.end_time}</div>
                    <div style={{marginTop:10}}>共 <span style={{color:'#07C160'}}>{exam?.exercise?.length}</span> 道题 总分 <span style={{color:'#07C160'}}>{getTotalScore()}</span> 分   考试时长：<span style={{color:'#07C160'}}>{getExamDuration()}</span> 分钟</div>
                    <div style={{ fontWeight:500,textAlign:'left',marginTop:10,color:'#666'}}>考生姓名：{exam?.student_info?.real_name && exam?.student_info?.real_name != "" ? exam?.student_info?.real_name : exam?.student_info?.name}&nbsp;&nbsp;&nbsp;&nbsp;学 / 工号：{exam?.student_info?.number}</div>
                    <div style={{fontWeight:500,textAlign:'left',marginTop:10,color:'#666'}}>考试说明:</div>
                    <div style={{marginTop:5}}>{exam?.description}</div>
                  </Col>
                  <Col span={8}>
                        <div style={{border:"1px solid #eee",margin:'20px auto 0',padding:5,width:200,height:75}}>
                          <Countdown valueStyle={{color:'red',fontSize:24}} title={<span style={{color:'#333'}}>正在考试,距离结束:</span>} value={exam?.end_time} onFinish={submitExamBySystemPre} />
                        </div>
                  </Col>
                </Row>
                <Divider/>
                <Row style={{padding:'0 20px 0 10px'}}>
                 <Col span={1} style={{marginTop:3,textAlign:'right',fontWeight:700}}>{selecetedExerciseIndex+1}.</Col>
                 <Col span={21}><ExerciseCard key={selecetedExerciseIndex} exercise={exerciseResult} renderType="white" teacherOnly={false} editorMode={true} showAnser={false} showClean={true}/></Col>
                 <Col span={2} style={{marginTop:3,textAlign:'right',fontWeight:600}}>（{exam?.exercise[selecetedExerciseIndex].score} 分）</Col>
                </Row>

                {exerciseResultType == 5   && <div>
                    <div style={{textAlign:'right',marginRight:100,marginBottom:20}}>
                        <Popover overlayStyle={{marginTop:-5,width:300}} content={<div>请上传png,jpeg,jpg,gif等格式的图片，图片大小不得超过30M。<br/>请上传pptx,pdf,docx等格式的文件，文件大小不得超过50M。</div>} placement="bottom" title={""} trigger="hover">
                            <span style={{cursor:'pointer',marginRight:10,fontSize:12}}><img src={formatIcon} style={{width:16,height:16,marginTop:-2}} alt="" />文件格式</span>
                        </Popover>
                        <Upload {...uploadImageProps}>
                            <Button onClick={onUploadBtnClick.bind(this, exerciseResultID)} style={{color:'#07c160',borderColor:'#07c160',marginRight:10}}>+上传图片</Button>
                        </Upload>
                        <Upload {...uploadAttachmentProps}>
                            <Button onClick={onUploadBtnClick.bind(this, exerciseResultID)} style={{color:'#07c160',borderColor:'#07c160',marginRight:0}}>+上传附件</Button>
                        </Upload>
                      </div>
                    <Row className={examPreviewStyles.imgList} style={{width:600,marginLeft:60,marginBottom:20,justifyContent:'center'}}>
                      {imgs.map(img=>{
                        return <Col key={img.id} flex="140px">
                          <div style={{position:'absolute',top:5,right:5,width:20,height:20,borderRadius:10,backgroundColor:'#fff',display:'none'}}>
                            <Popconfirm placement="top" title={'确认删除图片"' + img.name + '"吗？'} onConfirm={deleteImage.bind(this, img.id, img.name)}  okText="确认" cancelText="取消">
                              <DeleteOutlined style={{color:'#07c160'}}/>
                            </Popconfirm>
                          </div>
                          <img src={img.url?.startsWith("http") ? img.url : constants.BACKEND_URL + img.url} alt="" style={{width:140,height:90,borderRadius:4}}/>
                        </Col>
                      })
                      }
                    </Row>

                    <div style={{width:600,marginLeft:60,marginBottom:30,textAlign:'left'}}>
                      {ppt.map(PPT=> {
                        return <Row key={PPT.name} style={{backgroundColor:'#FBFBFB',paddingLeft:10,borderRadius:3,marginBottom:10}}>
                          <Col flex="auto">
                            <FilePdfOutlined style={{color:'#999',marginRight:6}}/>{PPT.name}
                            <span style={{textAlign:'center',color:'#999',fontSize:12,marginLeft:10}}>{PPT.size}</span>
                          </Col>
                          <Col flex="50px" style={{textAlign:'center'}}>
                            <Popconfirm placement="top" title={'确认删除"' + PPT.name + '"吗？'} onConfirm={deleteAttachment.bind(this, PPT.id, PPT.name)} okText="确认" cancelText="取消">
                              <DeleteOutlined style={{color:'#999'}}/>
                            </Popconfirm> 
                          </Col>
                        </Row>
                      })
                      }
                    </div>
                  </div>
                }
              </Col>
            </Row>
            }

            {examStatus=='阅卷中' &&
              <div className={examPreviewStyles.courseMain} style={{position:'relative',width:800,minHeight:"calc(100vh - 204px)"}}>
                <div style={{height:60,lineHeight:'60px',fontSize:20,fontWeight:700,color:'#333'}}>{exam?.name}</div>
                <div style={{position:'absolute',right:0,top:0,width:100,height:60,lineHeight:'60px'}}><a style={{color:"#333333"}} href={"/course-main-student?course_id="+ courseId + "&tabKey=5"}>返回{">"}</a></div>
                <Row style={{paddingLeft:30}}>
                  <Col style={{textAlign:'left'}} span={16}>
                    <div><span style={{color:'#07C160',fontSize:16,marginRight:6}}><ClockCircleOutlined /></span>{exam?.start_time} 至 {exam?.end_time}</div>
                    <div style={{marginTop:10}}>共 <span style={{color:'#07C160'}}>{exam?.exercise?.length}</span> 道题 总分 <span style={{color:'#07C160'}}>{getTotalScore()}</span> 分   考试时长：<span style={{color:'#07C160'}}>{getExamDuration()}</span> 分钟</div>
                    <div style={{ fontWeight:500,textAlign:'left',marginTop:10,color:'#666'}}>考生姓名：{exam?.student_info?.real_name && exam?.student_info?.real_name != "" ? exam?.student_info?.real_name : exam?.student_info?.name}&nbsp;&nbsp;&nbsp;&nbsp;学 / 工号：{exam?.student_info?.number}</div>
                    <div style={{fontWeight:500,textAlign:'left',marginTop:10,color:'#666'}}>考试说明:</div>
                    <div style={{marginTop:5}}>{exam?.description}</div>
                  </Col>
                  <Col span={8}>
                        <div style={{border:"1px solid #eee",margin:'20px auto 0',width:150,height:75,lineHeight:'75px',color:'#07C160',boxShadow:'0px 0px 8px rgba(0, 0, 0, 0.08)'}}>
                          老师正在阅卷
                        </div>
                  </Col>
                </Row>
              </div>
            }

            {examStatus=='已完成' &&
              <Row style={{margin:'0 auto',flexWrap:'nowrap',overflow:'visible',maxWidth:'100%'}}>
                <Col style={{width:250,marginRight:20,float:'left',backgroundColor:'#fff',minHeight:580,marginTop:40}}>
                  <div style={{height:60,lineHeight:'60px',fontSize:16,color:'#333'}}>答题卡</div>
                    <div style={{height: 40, textAlign: 'left', fontSize:12, color:'#333', margin: "auto 24px 10px 24px"}}>
                        <div>考生姓名：{exam?.student_info?.real_name && exam?.student_info?.real_name != "" ? exam?.student_info?.real_name : exam?.student_info?.name}</div>
                        <div>学 / 工号：{exam?.student_info?.number}</div>
                    </div>
                  <Row style={{padding:'0 20px',fontSize:12}}>
                    <Col span={4}>总共<span style={{color:'#07c160',margin:4}}>{exam?.exercise?.length}</span></Col>
                    <Col span={4}>实操<span style={{color:'#07c160',margin:4}}>{typeCountMap.get('实操题')}{" "}</span></Col>
                    <Col span={4}>单选<span style={{color:'#07c160',margin:4}}>{typeCountMap.get('单选题')}{" "}</span></Col>
                    <Col span={4}>多选<span style={{color:'#07c160',margin:4}}>{typeCountMap.get('多选题')}{" "}</span></Col>
                    <Col span={4}>排序<span style={{color:'#07c160',margin:4}}>{typeCountMap.get('排序题')}{" "}</span></Col>
                    <Col span={4}>主观<span style={{color:'#07c160',margin:4}}>{typeCountMap.get('主观题')}{" "}</span></Col>
                  </Row>
                  <Divider style={{marginTop:10}}/>
                  <Row align='middle' justify= {'start'} gutter={[2,2]} style={{textAlign:"left",width:"100%",padding:'0 25px',marginBottom:20}}>
                    { completeStatus.map((status:any,index:number)=>(
                      <Col key={index}>
                        <div onClick={()=>{onExerciseBtnClick(index)}}
                          style={{backgroundColor:exam?.publish_answer == 2 ? getColorBySuccess(status,exam?.exercise[index].type) : getColorByStatus(status) ,color:getFontColorByStatus(status),border: getBorderByStatus(index,selecetedExerciseIndex,status),opacity:getOpacity(index,selecetedExerciseIndex,status),cursor:'pointer',
                          margin:4,textAlign:"center",height:30,lineHeight:'30px',minWidth:30,fontSize:16,borderRadius:2}}>
                          {index+1}
                        </div>
                      </Col>
                    ))
                    }
                  </Row>
                    {exam?.publish_answer == 2 &&
                    <div style={{width: '100%', float: 'left', textAlign: 'center'}}>

                        <div style={{
                            display: 'inline-block',
                            width: 12,
                            height: 12,
                            borderRadius: 6,
                            backgroundColor: '#07c160',
                            marginRight: 5
                        }}/>
                        正确 <span style={{color: '#07c160', marginRight: 5}}></span>
                        <div style={{
                            display: 'inline-block',
                            width: 12,
                            height: 12,
                            borderRadius: 6,
                            backgroundColor: '#ff4b50',
                            marginRight: 5
                        }}/>
                        错误 <span style={{color: '#07c160', marginRight: 5}}></span>
                      
                        <div style={{
                            display: 'inline-block',
                            width: 12,
                            height: 12,
                            borderRadius: 6,
                            backgroundColor: '#47B1DF',
                            marginRight: 5
                        }}/>
                       
                        主观 <span style={{color: '#07c160', marginRight: 5}}></span>
                        
                    </div>
                    }
                </Col>
                <Col className={examPreviewStyles.courseMain} style={{position:'relative',width:800,float:'left',padding:0,overflowY:'auto',height:"calc(100vh - 140px)"}}>
                  <div style={{height:60,lineHeight:'60px',fontSize:20,fontWeight:700,color:'#333'}}>{exam?.name}</div>
                  <div style={{position:'absolute',right:0,top:0,width:100,height:60,lineHeight:'60px'}}><a style={{color:"#333333"}} href={"/course-main-student?course_id="+ courseId + "&tabKey=5"}>返回{">"}</a></div>
                  <Row style={{paddingLeft:30}} wrap={false} >
                    <Col style={{textAlign:'left'}} span={16}>
                      <div><span style={{color:'#07C160',fontSize:16,marginRight:6}}><ClockCircleOutlined /></span>{exam?.start_time} 至 {exam?.end_time}</div>
                      <div style={{marginTop:10}}>共 <span style={{color:'#07C160'}}>{exam?.exercise?.length}</span> 道题 总分 <span style={{color:'#07C160'}}>{getTotalScore()}</span> 分   考试时长：<span style={{color:'#07C160'}}>{getExamDuration()}</span> 分钟</div>
                        <div style={{ fontWeight:500,textAlign:'left',marginTop:10,color:'#666'}}>考生姓名：{exam?.student_info?.real_name && exam?.student_info?.real_name != "" ? exam?.student_info?.real_name : exam?.student_info?.name}&nbsp;&nbsp;&nbsp;&nbsp;学 / 工号：{exam?.student_info?.number}</div>
                      <div style={{fontWeight:500,textAlign:'left',marginTop:10,color:'#666'}}>考试说明:</div>
                      <div style={{marginTop:5}}>{exam?.description}</div>
                    </Col>
                    <Col span={8}>
                        <div style={{width:200,height:100,textAlign:'left',padding:'20px 0 0 30px',backgroundImage:`url(${scoreBg})`,backgroundSize:'100% 100%'}}>
                          <div style={{fontSize:12}}><img src={scoreIcon} style={{width:16,height:16,marginTop:-3,marginRight:2}} alt="" />考试成绩：</div>
                          <div style={{fontSize:24,color:'#07c160',marginLeft:3}}> {myScore} <span style={{color:'#07c160',fontSize:12}}>分</span></div>
                        </div>
                        {exam?.publish_answer == 2 && <span>答案已公布</span> }
                    </Col>
                  </Row>
                  <Divider/>
                  <Row style={{padding:'0 20px 0 10px'}} wrap={false}>
                        <Col flex="50px" style={{marginTop:3,textAlign:'right',fontWeight:600}}>{selecetedExerciseIndex+1}.</Col>
                        <Col flex="auto"><ExerciseCard key={selecetedExerciseIndex} exercise={exerciseResult} renderType="white" teacherOnly={false} editorMode={false} showAnser={exam?.publish_answer == 2 ? true : false} showClean={false}/></Col>
                        {/*exam?.publish_answer == 1 &&  <Col flex="80px" >（{exam?.exercise[selecetedExerciseIndex].score} 分）</Col> */}
                        { exam?.publish_score == 2 && exerciseScores && <Col flex="80px" > （{exerciseScores[selecetedExerciseIndex].score}/{exerciseScores[selecetedExerciseIndex].full_score}分） </Col>}
                  </Row>
                  {exerciseResult["type"] == 5   &&
                  <Row className={examPreviewStyles.imgList} style={{width:600,marginLeft:80,marginBottom:10,justifyContent:'center'}}>
                    {imgs.map(img=>{
                      return <Col key={img.id} flex="140px" className={examPreviewStyles.previewImageWrap}>
                         <Image src={img.url?.startsWith("http") ? img.url : BACKEND_URL + img.url} style={{width:140,height:90,borderRadius:4}} preview={{mask:<EyeOutlined/>}}/>
                       </Col>
                    })
                    }
                  </Row>
                }
                
                {exerciseResult["type"] == 5   &&
                  <div style={{width:600,marginLeft:80,marginBottom:30}}>
                    {ppt.map(PPT=> {
                      return <Row key={PPT.name} style={{backgroundColor:'#FBFBFB',paddingLeft:10,borderRadius:3,marginBottom:10,textAlign:'left'}}>
                        <Col flex="auto">
                          {PPT.name}
                          <span style={{textAlign:'center',color:'#999',fontSize:12,marginLeft:10}}>{PPT.size}</span>
                        </Col>
                        <Col flex="50px" style={{textAlign:'center'}}><a href={PPT.url?.startsWith("http") ? PPT.url : BACKEND_URL + PPT.url} target={"_blank"}><DownloadOutlined style={{color:'#999'}}/></a></Col>
                      </Row>
                    })
                    }
                  </div>
                }
                </Col>
              </Row>
            }

            {examStatus == '考试中' &&
            <div style={{
                position: 'fixed',
                bottom: 0,
                textAlign: "center",
                width: '100%',
                height: 80,
                lineHeight: '80px',
                backgroundColor: "#fff",
                borderRadius: '0 0 10px 10px',
                boxShadow: '0px -2px 15px rgba(0, 0, 0, 0.12)'
            }}>
                <Button style={{
                    marginTop: 10,
                    marginRight: 40,
                    width: 100,
                    borderRadius: 5,
                    color: '#07c160',
                    borderColor: '#07c160'
                }}
                        onClick={onPrevExerciseBtnClick} hidden={selecetedExerciseIndex == 0}
                >上一题</Button>
                <Button style={{
                    marginTop: 10,
                    marginRight: 40,
                    width: 100,
                    borderRadius: 5,
                    color: '#07c160',
                    borderColor: '#07c160'
                }}
                        onClick={onNextExerciseBtnClick}
                        hidden={selecetedExerciseIndex == (exerciseIdList?.length - 1)}
                >下一题</Button>
                <Button type="primary" style={{marginTop: 10, width: 100, borderRadius: 5, backgroundColor: '#07c160'}}
                        onClick={submitPractice}

                >交卷</Button>
            </div>
            }

           <Modal closable={false} width={400} title="提示" visible={isFinishedVisible}
            bodyStyle={{borderBottom:0,textAlign:'center',height:150,paddingTop:40}}
            className={examPreviewStyles.announceModal}
            footer={
              <div style={{textAlign:'center',height:50}}>
                <Button onClick={isFinishedCancel} style={{width:80,borderRadius:5,borderColor:'#07c160',color:'#07c160',marginRight:20}}>取消</Button>
                <Button type="primary" onClick={isFinishedOk} style={{width:80,borderRadius:5,backgroundColor:'#07c160',color:'#fff'}}>确认</Button>
              </div>
            }>
              <p style={{marginTop:20}}>还有 <span style={{color:'#07c160',margin:5}}>{notFinished.length}</span> 题未答，你确定要提交吗 ？</p>
            </Modal>

            <Modal closable={false} width={400} title="提示" visible={isModalVisible}
              bodyStyle={{borderBottom:0,textAlign:'center',height:150,paddingTop:40}}
              className={examPreviewStyles.announceModal}
              footer={
                <div style={{textAlign:'center',height:50}}>
                  <Button onClick={handleCancel} style={{width:80,borderRadius:5,borderColor:'#07c160',color:'#07c160',marginRight:20}}>取消</Button>
                  <Button type="primary" onClick={handleOk} style={{width:80,borderRadius:5,backgroundColor:'#07c160',color:'#fff'}}>确认</Button>
                </div>
              }>
                <p>确认要交卷吗？</p>
                <p>交卷后不可再答。</p>
            </Modal>

            <Modal closable={false} width={400} title="提示" visible={isSuccessVisible}
              bodyStyle={{borderBottom:0,textAlign:'center',height:150,paddingTop:70}}
              className={examPreviewStyles.announceModal}
              footer={[]}>
                <p>已成功交卷！</p>
            </Modal>


            <Modal closable={false} width={400} title="提示" visible={isAutoSubmitModalVisible}
              bodyStyle={{borderBottom:0,textAlign:'center',height:150,paddingTop:0}}
              className={examPreviewStyles.announceModal} 
              footer={[]}>
              <p style={{marginTop:20}}> 考试时间已到，5秒后将自动交卷！</p>
              <Countdown valueStyle={{color:'red',fontSize:24}} title={''} value={autoSubmitDeadLine} onFinish={submitExamBySystem}/>
            </Modal>

           
            {examStatus=='未开考' &&
              <PageFooter />
            }
            {examStatus=='阅卷中' &&
              <PageFooter />
            }
       </Layout>
    )

}

export default CourseStudentExamPreview

