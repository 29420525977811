import React, {useEffect, useRef, useState} from "react";
import {Tooltip,Progress,Tree,Drawer,Layout, message, Card, Col, Row, ConfigProvider, Pagination, Tabs, Input, Upload, Button, Popconfirm, Select } from 'antd';
import { sendHttpPostRequest } from "../../api/http-request";
import logo from '../../static/image/college/logo.png';
import Cookies from 'js-cookie';
import zhCN from 'antd/lib/locale/zh_CN'
import * as constants from '../../constants/constants';
import LoginRegisterModal from "../../component/modal/login-register/login-register";
import UserInfo, {checkUserLogin} from "../common/user-center/user-info";
import { Content, Footer } from "antd/lib/layout/layout";
import collegeStyles from './college.module.less';
import courseIcon from '../../static/image/college/course.png';
import caseIcon from '../../static/image/college/case.png';
import projectIcon from '../../static/image/college/project.png';
import projectBg from '../../static/image/college/traffic/projectBg.png';
import course1 from '../../static/image/college/traffic/3.png';
import course2 from '../../static/image/college/traffic/1.png';
import course3 from '../../static/image/college/traffic/4.png';
import course4 from '../../static/image/college/traffic/5.png';
import course5 from '../../static/image/college/traffic/2.png';
import case1 from '../../static/image/college/traffic/1-1.png';
import case2 from '../../static/image/college/traffic/1-2.png';
import case3 from '../../static/image/college/traffic/1-3.png';
import case4 from '../../static/image/college/traffic/1-4.png';
import case5 from '../../static/image/college/traffic/1-5.png';

// 通过路由组件额外添加参数信息到表中
import { Link } from "react-router-dom";
import PageFooter from "./college-footer";
const { Header } = Layout;

interface Props  {
}

export const CollegeTraffic: React.FC<Props> = (props) => {
    const courseImgs = [
        {
            src: course1,
            title: '',
            id: 167,
        },
        {
            src: course2,
            title: '',
            id: 167,
        },
        {
            src: course3,
            title: '',
            id: 167,
        },
        {
            src: course4,
            title: '',
            id: 167,
        },
        {
            src: course5,
            title: '',
            id: 167,
        },
    ];
    const caseImgs = [
        {
            src: case1,
            title: '首尔共享自行车需求数据可视化分析',
            url: "case-run?id=54&token=9173f1abdfb93e4288daca6ed621f10c"
        },{
            src: case2,
            title: '飞机失事事故探索',
            url: "case-run?id=593&token=f1bb343920953f7a3cc7f6bcc4dac0a6"
        },{
            src: case3,
            title: '客户汽车满意度预测',
            url: "case-run?id=596&token=4f506f467c5bd501711fd3dd20635489"
        },{
            src: case4,
            title: '使用PageRank对全球机场进行排序',
            url: "case-run?id=598&token=46e006beb9a62e7de41872fa9019e072"
        },{
            src: case5,
            title: '汽车款式聚类',
            url: "case-run?id=601&token=44ad068dc0ff09f776624037c0d60f0a"
        },
    ];

    const project ={
        name: 'Spark大数据分析',
        description: '交通作为城市发展的主要动力，对生产要素的流动，城镇体系的发展有着决定性的影响，一个良好的交通运输状态能够为一座城市带来源源不断的发展动力。对城市的交通大数据进行分析，能够帮助我们了解城市中不同区域居民的生活空间和工作空间，能够帮助我们了解不同区域居民在城市交通网络上的活动规律。通过了解这些内容，我们可以做到以人为本，实现交通和生活的和谐，提高城市的宜居性，为政府的精准管理提供决策支持。城市中交通大数据的种类多种多样，有道路监测数据、车辆GPS数据、电子收费数据、移动互联数据等等。其中，作为城市交通道路中重要的一环，出租车的运营数据也是城市交通大数据中非常重要的组成部分。通过分析出租车的运营数据，我们可以获得城市中居民的出行高峰信息，不同时间不同区域之间居民的出行规律等。',
        ability: '地理数据可视化;Spark SQL;Spark ML',
        industry_name: '交通',
        practice_duration: '20小时',
        teaching_duration: '30课时',
    }

    const onStartProjectClick = () => {
        window.open('http://idatacoding.cn/project_main?project_id=3');
    }

    return (
        <Layout style={{minHeight: 750,backgroundColor: '#fff'}}>
            <Header style={{backgroundColor:'#fff',boxShadow:'0px 0px 10px rgba(0, 0, 0, 0.16)',height:80,paddingLeft:80,paddingTop:6}}>
                <Row justify="center" align="middle" wrap={true}>
                    <Col>
                        <Link to={"/college-project"}>
                            <img
                                key={"0"}
                                id={"menu-0"}
                                title="黄河交通学院"
                                alt="logo"
                                src={logo}
                                width={300}
                            ></img>
                        </Link>
                    </Col>
                    <Col flex="150px" style={{color:'#155ba5',fontSize:22,fontWeight:700}}>数字交通</Col>
                    <Col flex="auto"></Col>
                    <Col><UserInfo/></Col>
                </Row>
            </Header>

            <div className={collegeStyles.mainDiv}>
                <div className={collegeStyles.course}>
                    <div style={{height:60,paddingTop:25,fontSize:16,color:'#333'}}>
                        <img src={courseIcon} alt=""  className={collegeStyles.icon}/>
                        课程</div>
                    <div>
                        {courseImgs.map((courseImg)=>(
                            <Link to={"/course-main?course_id=" + courseImg.id} target={"_blank"} key={courseImg.src}>
                                <img src={courseImg.src} alt="" key={courseImg.src} className={collegeStyles.courseImg}/>
                            </Link>
                        ))                           
                        }
                    </div>
                </div>

                <div className={collegeStyles.case}>
                    <div style={{height:60,paddingTop:25,fontSize:16,color:'#333'}}>
                        <img src={caseIcon} alt=""  className={collegeStyles.icon}/>
                        实战案例
                    </div>
                    <div>
                        {caseImgs.map((caseImg)=>(
                            <Link to={caseImg.url} key={caseImg.src} target={"_blank"}>
                                <div key={caseImg.src} style={{backgroundImage:`url(${caseImg.src})`}} className={collegeStyles.caseDiv}>
                                    {caseImg.title}
                                </div>
                            </Link>
                        ))                           
                        }
                    </div>
                </div>

                <div className={collegeStyles.project}>
                    <div style={{height:60,paddingTop:25,fontSize:16,color:'#333'}}>
                        <img src={projectIcon} alt=""  className={collegeStyles.icon}/>
                        实战项目
                    </div>
                    <div
                                className={collegeStyles.projectCard}
                                style={{background:`url(${projectBg}) center center no-repeat`,backgroundSize:'cover'}}
                                
                            >
                                <div className={collegeStyles.projectContent}>
                                    <div className={collegeStyles.projectTitle}>
                                        {project.name}
                                    </div>
                                    <div className={collegeStyles.projectDescription} title={project.description}>{project.description}</div>
                                    <Row wrap={false} align="middle" justify="start" gutter={[12,12]} style={{overflow:"hidden"}}>
                                        {project.ability?.split(";")?.map((skill,skillInd)=> {
                                            return <Col key={skillInd} className={collegeStyles.list}>{skill}</Col>
                                        })
                                        }
                                    </Row>
                                    <Row wrap={false} align="middle" justify="start" gutter={[12,12]} style={{marginTop:40,position:'relative'}} className={collegeStyles.infoDiv}>
                                        <Col  style={{width:100,height:50,color:'#fff!important',marginRight:20,borderRight: '1px solid rgba(255, 255, 255, 0.2)'}}>
                                                <div>行业</div>
                                                <div style={{fontSize:18,textOverflow: "ellipsis"}}>{project.industry_name}</div>
                                        </Col>

                                        <Col  style={{width:100,height:50,color:'#fff!important',marginRight:20,borderRight: '1px solid rgba(255, 255, 255, 0.2)'}}>
                                                <div>实践课时</div>
                                                <div style={{fontSize:18}}>{project.practice_duration}</div>
                                        </Col>

                                        <Col style={{width:100,height:50,color:'#fff!important',marginRight:20,borderRight: '1px solid rgba(255, 255, 255, 0.2)'}}>
                                                <div>教学课时</div>
                                                <div style={{fontSize:18}}>{project.teaching_duration}</div>
                                        </Col>
                                    </Row>
                                </div>
                                
                                <div className={collegeStyles.startBtn} style={{transform: "translate(-50%,0)", position:'absolute',left:'50%',bottom:40,display:'none',zIndex:10}}>
                                    <Button onClick={onStartProjectClick} style={{borderRadius:27,backgroundColor:'#fff',width:120,height:40,color:'#3b6d74'}}>
                                        点击开始
                                    </Button>
                                </div>
                            </div>
                    
                </div>
            </div>

            
            <PageFooter/>
        </Layout>

    )

}

export default CollegeTraffic

