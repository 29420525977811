import React from 'react'
import cmtyStyle from "./community.module.less"
export const CommunityAgreement = () => {
  return (
    <div>
      <div style={{ marginTop: 25 }}>
        <h1>请先阅读社区协议</h1>
      </div>
      <div className={cmtyStyle.agreement}>
        <p>
          爱数课社区是一个大数据开发者学习交流社区门户，致力于壮大大数据的应用与学习交流的中国开发者群体，通过互帮互助解决大家在学习过程中遇到的各种问题。
        </p>
        <p>
          爱数课社区致力于尽全力维护社区的热情友好文明互助的和谐氛围，并努力提高社区整体文章的含金量和阅读亲和力，方便广大会员从文章中提取有用信息，本站坚持原创性文章为主，但不排除转载其他网站的相关文章，因为很多问题都有成熟的解决方案，希望大家可以在爱数课社区上互帮互助，通过彼此的沟通交流，获得更多的相关知识，共同提高技术。
        </p>
        <p>请自觉遵守以下社区规则请仔细阅读。</p>
        <p>爱数课社区管理总则（2023061）</p>
        <br />
        <h3>一、 免责条款</h3>
        <p>
          1.在爱数课社区里发表的文章仅代表作者本人的观点，与本网站立场无关。出于遵守国家相关法规或促进社区发展的前提，我们有权在不经作者准许的情况下删除其在本社区所发表的文章。
        </p>
        <p>
          2.爱数课社区的所有文章、内容、信息、资料，都不保证其准确性、完整性、有效性、时效性。请依据情况自身做出判断。阅读本站内容因误导等其他因素而造成的损失责任自负。本站没有任何责任。
        </p>
        <p>
          3.会员对自己的言论和行为负责，完全承担发表内容的责任，所持立场与本社区无关。社区使用者因为任何行为而触犯中华人民共和国法律或相关法规的，一切后果自己负责，爱数课社区不承担任何责任。
        </p>
        <p>
          4.会员所发布的信息中涉及到具体的第三方个人（单位/公司）隐私、商业秘密、诋毁商誉等，侵犯其权益，对其构成不良影响的，由第三方向爱数课提交正式书面申请删除该信息后，爱数课有权将该信息予以直接删除处理。
        </p>
        <p>
          5.爱数课社区如因系统维护或升级而需暂停服务时，将事先公告。若因线路及非本公司控制范围外的硬件故障或其它不可抗力而导致暂停服务，于暂停服务期间造成的一切不便与损失，爱数课社区不负任何责任。
        </p>
        <p>
          6.凡以任何方式登录本站或直接、间接使用爱数课社区资料者，视为自愿接受爱数课社区声明的约束。本声明未涉及的问题参见国家有关法律法规，当本声明与国家法律法规冲突时，以国家法律法规为准。
        </p>
        <h3>二、 用户昵称管理规则</h3>
        <p>
          <strong>1.本社区帐号的注册均采用手机验证机制。</strong>
        </p>
        <p>
          <strong>
            2.请勿使用以下注册名，包含以下内容的注册名将被无条件删除：
          </strong>
          <br />
        </p>
        <ul>
          <li>
            <p>
              ⑴ 以党和国家领导人的真实姓名、字、号、艺名、笔名或音近名称注册；
              <br />
            </p>
          </li>
          <li>
            <p>
              ⑵ 以国家机构或其他机构的名称注册；
              <br />
            </p>
          </li>
          <li>
            <p>
              ⑶ 和政治、宗教有关之敏感名称；
              <br />
            </p>
          </li>
          <li>
            <p>
              ⑷ 以未经（本社区）许可的公司/单位名称之类含有广告性质的名称注册；
              <br />
            </p>
          </li>
          <li>
            <p>
              ⑸ 以本社区管理员、工作人员管理账号等相关或相近名称注册；
              <br />
            </p>
          </li>
          <li>
            <p>
              ⑹
              以含有恶意人身攻击或淫秽字眼的名称注册(包括所有语言：如方言、英语、拼音等)；
              <br />
            </p>
          </li>
          <li>
            <p>
              ⑺ 以与他人相似用户名注册，并进行攻击、谩骂、诋毁、挑衅等恶意行为；
              <br />
            </p>
          </li>
          <li>
            <p>
              ⑻ 以机器手段批量注册，或批量注册相似字符用户名；
              <br />
            </p>
          </li>
          <li>
            <p>
              ⑼ 图形、符号名字。
              <br />
            </p>
          </li>
        </ul>
        <h3>三、 帐号管理规则</h3>
        <p>
          <strong>
            1.用户有义务保证其帐号和密码的安全，因用户保管不当引起的任何损失或损害，本社区不承担任何责任。
          </strong>
        </p>
        <p>
          <strong>
            2.帐号的个性签名、文章签名、头像如有以下情况的，本社区有权对其进行修改、删除或禁止使用，对该用户予以警告，或对该用户禁言：
          </strong>
        </p>
        <ul>
          <li>
            <p>⑴ 包含党和国家领导人、国家机构等信息；</p>
          </li>
          <li>
            <p>⑵ 包含色情、反动、外网网址链接等内容；</p>
          </li>
          <li>
            <p>⑶ 包含未经本社区许可的QQ群号、广告信息等内容；</p>
          </li>
          <li>
            <p>
              ⑷
              包含恶意人身攻击的字眼及含义；包含有严重影响网友浏览的内容或格式；
            </p>
          </li>
          <li>
            <p>
              ⑸
              个性签名、用户名、头像、文章中的任何一项与另一项相互结合后组成广告、敏感信息等违规行为的。
            </p>
          </li>
        </ul>
        <h3>四、社区发文规则</h3>
        <p>
          本社区用户拥有发布信息的权利，但发布信息必须严格遵守中华人民共和国的法律，任何违法性质的文章，本社区管理人员有权第一时间进行删除文章操作，并有权对该用户名进行永久禁言，情节严重者不排除禁IP操作。
        </p>
        <p>
          <strong>
            1.任何人均不得传播、复制、发布含有下列内容的信息或网址链接：
          </strong>
        </p>
        <ul>
          <li>
            <p>
              ⑴ 反对宪法确定的基本原则，危害国家统一、主权和领土完整的；
              <br />
            </p>
          </li>
          <li>
            <p>
              ⑵ 泄露国家秘密、危害国家安全或者损害国家荣誉和利益的；
              <br />
            </p>
          </li>
          <li>
            <p>
              ⑶ 煽动民族仇恨、民族歧视，破坏民族团结，或者侵害民族风俗、习惯的；
              <br />
            </p>
          </li>
          <li>
            <p>
              ⑷ 宣扬邪教、迷信的；
              <br />
            </p>
          </li>
          <li>
            <p>
              ⑸ 散布谣言，扰乱社会秩序，破坏社会稳定的；
              <br />
            </p>
          </li>
          <li>
            <p>
              ⑹ 宣扬淫秽、赌博、暴力或者教唆犯罪的；
              <br />
            </p>
          </li>
          <li>
            <p>
              ⑺ 煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的；
              <br />
            </p>
          </li>
          <li>
            <p>
              ⑻ 有法律、行政法规和国家规定禁止的其他内容的。
              <br />
            </p>
          </li>
        </ul>
        <p>
          <strong>
            2.在文章中公布他人个人隐私或真实信息，或利用不当手段诋毁他人工作、生活的，根据情节严重程度，本社区管理员有权进行删除、屏蔽等操作，并有权对该用户进行警告、禁言。
          </strong>
          <br />
        </p>
        <ul>
          <li>
            <p>
              ⑴ 涉及他人隐私、有意模仿他人注册名或冒充本社区管理人员的；
              <br />
            </p>
          </li>
          <li>
            <p>
              ⑵ 发布侮辱或诽谤他人，公布及侵害他人隐私及侵害他人合法权益的内容；
              <br />
            </p>
          </li>
          <li>
            <p>
              ⑶ 恶意发布他人真实姓名、联系方式、照片等涉及他人隐私的内容。
              <br />
            </p>
          </li>
        </ul>
        <p>
          <strong>
            3.本社区不允许发布非社区官方的QQ群等群号信息；禁止用户以“本社区官方”“本社区准官方”或相似称呼命名群名称，或使用于群内公告、群内外信息传播。任何未经本社区许可的广告性质文章，根据情节严重程度，本社区管理员有权进行删除、屏蔽等操作，并有权对该用户名进行警告、禁言，以下情况将有可能被视为广告性质文章：
          </strong>
          <br />
        </p>
        <ul>
          <li>
            <p>
              ⑴ 发布非本社区指定团体组织的活动信息或商业行为；
              <br />
            </p>
          </li>
          <li>
            <p>
              ⑵ 发布其他网站地址链接的；
              <br />
            </p>
          </li>
          <li>
            <p>
              ⑶
              带有QQ号码、QQ群号、微信号、微信二维码等其他社交产品的联系方式的；
              <br />
            </p>
          </li>
          <li>
            <p>
              ⑷ 带有联系人、联系电话或联系地址等信息的；
              <br />
            </p>
          </li>
          <li>
            <p>
              ⑸ 带有其他任何形式的宣传公司、机构、产品等内容的；
              <br />
            </p>
          </li>
          <li>
            <p>
              ⑹ 带有本社区内部链接但链接指向的内容中含有以上信息的等。
              <br />
            </p>
          </li>
        </ul>
        <p>
          4.以下行为将被视为扰乱、破坏社区公共秩序：
          <br />
        </p>
        <ul>
          <li>
            <p>
              ⑴ 同一ID重复发表同一或相似主题、同一或相似内容的文章；
              <br />
            </p>
          </li>
          <li>
            <p>
              ⑵ 同一ID进行刷屏活动或蓄意批量顶起旧文热度；
              <br />
            </p>
          </li>
          <li>
            <p>
              ⑶ 同一ID或系统证实为同一用户在版内恶意顶文，扰乱社区正常秩序；
              <br />
            </p>
          </li>
          <li>
            <p>
              ⑷
              相类似ID对同一网友连续评分，影响网友浏览体验、扰乱社区正常秩序的行为；
              <br />
            </p>
          </li>
          <li>
            <p>
              ⑸ 反复发标题和内容空洞的文章、文章内容大量使用重复文字或空白的；
              <br />
            </p>
          </li>
          <li>
            <p>
              ⑹
              在前一文章尚未被删除、关闭（不影响正常回复）的情况下，就该同一问题、事件不停地发表新文章的；
              <br />
            </p>
          </li>
          <li>
            <p>
              ⑺ 发生纠纷后，发文聚众或怂恿他人发文攻击和寻衅闹事、打击报复的；
              <br />
            </p>
          </li>
          <li>
            <p>
              ⑻ 在本社区内搞小团体，拉帮结派进行谩骂、攻击行为的；
              <br />
            </p>
          </li>
          <li>
            <p>
              ⑼ 屡次在版内发表与主题无关、蓄意灌水的文章；
              <br />
            </p>
          </li>
          <li>
            <p>
              ⑽ 攻击、谩骂、造谣本社区、社区其他成员、管理人员的；
              <br />
            </p>
          </li>
          <li>
            <p>
              ⑾ 使用马甲讽刺、攻击本社区或社区管理人员的；
              <br />
            </p>
          </li>
          <li>
            <p>
              ⑿ 其他影响网友浏览体验、扰乱社区正常秩序的行为；
              <br />
            </p>
          </li>
          <li>
            <p>
              ⒀
              破坏社区公共秩序、违反社区其他管理规定的文章，根据情节严重程度，本社区管理员有权进行删除。
              <br />
            </p>
          </li>
        </ul>
        <p>
          <strong>
            5.经他人举报，文章中含有虚假信息及招摇撞骗、蛊惑性信息的，根据情节严重程度，本社区管理员有权进行删除、屏蔽等操作，并有权对该用户进行警告、禁言。
          </strong>
        </p>
        <p>
          <strong>
            6.争议、争吵性及不宜公开继续讨论的文章，以及带有诋毁、谩骂、辱骂、恶意挑衅骚扰及变相人身攻击性质的文章，根据情节严重程度，本社区管理员有权锁文、压文、删除、移动处理，并有权对该用户进行警告、禁言。
          </strong>
        </p>
        <p>
          <strong>
            7.发布与本社区定位不相符的文章，本社区管理员有权进行删除、移除，并对该用户进行警告，对于不听警告继续发布的，有权进行禁言处理。
          </strong>
        </p>
        <p>
          <strong>
            8.若出现投诉、举报、建议相关的文章，本社区管理员有权将文章转移由相关管理人员主导受理。
          </strong>
        </p>
        <h3>六、文章处理及投诉标准</h3>
        <p>以下规则解释权属于爱数课社区所有，并保留随时更新此规则的权利。</p>
        <p>
          <strong>
            1.文章被转移、删除等会玉用户联系，若涉及违规行为不予通知爱数课进行处理。
          </strong>
          <br />
        </p>
        <p>
          <strong>
            2.任何建议、意见、讨论或质疑、投诉社区规则、都可在本文下留言。
          </strong>
          <br />
        </p>
        <p>
          用户投诉请一并提交以下内容：
          <br />
        </p>
        <ul>
          <li>
            <p>
              ⑴ 投诉人昵称
              <br />
            </p>
          </li>
          <li>
            <p>
              ⑵ 投诉事件
              <br />
            </p>
          </li>
          <li>
            <p>
              ⑶ 事件发生时间
              <br />
            </p>
          </li>
          <li>
            <p>
              ⑷ 投诉理由投诉证据
              <br />
            </p>
          </li>
        </ul>
        <p>
          以上内容表述不清的，将不予受理和解释。
          <br />
        </p>
        <h3>七、免责声明：</h3>
        <p>出现以下情况时本社区将不承担任何责任：</p>
        <p>
          <strong>
            1.用户使用出现在本社区的文章，参加其他用户个人组织的活动或与其他用户进行个人交易并由此发生纠纷和其他伤害的；
          </strong>
        </p>
        <p>
          <strong>
            2.用户通过与本社区相关的渠道参加一切活动，由此发生人身伤亡、财产损失等重大后果的；
          </strong>
        </p>
        <p>
          <strong>
            3.用户在本社区发布的除前述范围以外的其它信息，并由此产生纠纷和伤害的；
          </strong>
        </p>
        <p>
          <strong>4.用户因社区行为引发的法律纠纷，与本社区无关。</strong>
        </p>
        <h3>八、附则</h3>
        <p>
          本社区可依照互联网发展的不同阶段，随着管理经验的不断丰富，出于维护社区秩序的目的，不断完善本规范。
          本社区拥有对本规范的最终解释权。
        </p>
      </div>
    </div>
  )
}

export default CommunityAgreement
