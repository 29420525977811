import { Button, Col, ConfigProvider, Layout, message, Pagination, Popconfirm, Popover, Row, Statistic, Table } from 'antd';
import { Content } from 'antd/lib/layout/layout';

import React, { useEffect, useRef, useState } from "react";
import OAMSider from '../oam-sider';
import Cookies from 'js-cookie';
import { sendHttpPostRequest } from '../../../api/http-request';
import Search from 'antd/lib/input/Search';
import zhCN from 'antd/lib/locale/zh_CN';

import idataCourseTableStyles from "../../common/global-styles/idatacourse-table.module.less";
import selfCss from './oam-lecture.module.less'

import courseBasicInfoStyle from "../../course/course-edit-basicinfo.module.less"

import {PlayCircleOutlined, DownOutlined,SearchOutlined, DeleteOutlined, FileSearchOutlined, PlusSquareOutlined, UploadOutlined} from "@ant-design/icons";
import * as constants from '../../../constants/constants';

//测试用视频数据
export const test_video_list = [
    {
        id: "a0b13f8d288c4b57a34c0b992551ad77",
        vod_user_id: "HSuh23Hl34h",
        cover: "http://video.idatascience.cn/image/cover/48642073A1CA488981ABB4E5E1B37D9A-6-2.png", //封面
        video_path:"http://video.idatascience.cn/sv/3c4ee67f-17cc9cbf61e/3c4ee67f-17cc9cbf61e.mp4",
        title: "全国大数据和数字经济产业发展概览", //标题
        teacher: "程超", //主讲人
        description:"基于近期发布的2021中国大数据产业发展指数和2021中国数字经济产业发展指数结果，解读各地大数据和数字经济产业发展情况，并交流基于微观企业数据和大数据建模分析方法的产业咨询和决策。",//简介
        duration:20, //视频时长
        status:"审核中",
        upload_time:"2021-09-23 18:00",//上传时间
        publish_time:"2021-09-23 18:00",//审核通过实践
        view_cnt:100,//观看次数
    },
    {
        id: "7ea290078959468dbe716d70e98c81f1",
        vod_user_id: "HSuh23Hl34h",
        cover: "http://video.idatascience.cn/image/cover/649058C1C2DD4BF48A6A90DAB8C09DC0-6-2.png", //封面
        video_path:"http://video.idatascience.cn/sv/16924267-17c1c96fd89/16924267-17c1c96fd89.mp4",
        title: "如何开好数据科学通识导论课程", //标题
        teacher: "欧高炎", //主讲人
        description:"如何开好数据科学通识导论课程",//简介
        duration:20, //视频时长
        status:"审核中",
        upload_time:"2021-09-23 18:00",//上传时间
        publish_time:"2021-09-23 18:00",//审核通过实践
        view_cnt:100,//观看次数
    },
    {
        id: "24ebbed6b2c64ddab86e509fa4dac2da",
        vod_user_id: "HSuh23Hl34h",
        cover: "http://video.idatascience.cn/image/cover/CC41FC8716024F1BAE9B1336DF34A90C-6-2.png",
        video_path:"http://video.idatascience.cn/sv/48f31ced-17c1c96fd81/48f31ced-17c1c96fd81.mp4",
        title: "大数据基础与应用课程思考与探索",
        teacher: "郭熹崴",
        description:"大数据基础与应用课程思考与探索",
        duration:20,
        status:"已发布",
        upload_time:"2021-09-23 18:00",
        publish_time:"2021-09-23 18:00",//审核通过实践
        view_cnt:100,//观看次数
    },
    {
        id: "670eb7145efc4977a67649beccdb0d89",
        vod_user_id: "HSuh23Hl34h",
        cover: "http://video.idatascience.cn/image/cover/F19E8F6DED504E2FBEDF25E157651A46-6-2.png",
        video_path:"http://video.idatascience.cn/sv/33179000-17c1c96fd80/33179000-17c1c96fd80.mp4",
        title: "大数据技术与应用课程建设",
        teacher: "王建民",
        description:"大数据技术与应用课程建设",
        duration:20,
        status:"上传中",
        upload_time:"2021-09-23 18:00",
        publish_time:"2021-09-23 18:00",//审核通过实践
        view_cnt:100,//观看次数
    },
    {
        id: "fd245bccc809485e9e622cd4a841029b",
        vod_user_id: "HSuh23Hl34h",
        cover: "http://video.idatascience.cn/image/cover/E9F3D8C727104FFC9930616ED9306A0B-6-2.png",
        video_path:"http://video.idatascience.cn/sv/100965b5-17c1c96fd92/100965b5-17c1c96fd92.mp4",
        title: "商业大数据分析教与学",
        teacher: "叶龙",
        description:"商业大数据分析教与学",
        duration:20,
        status:"已发布",
        upload_time:"2021-09-23 18:00",
        publish_time:"2021-09-23 18:00",//审核通过实践
        view_cnt:100,//观看次数
    },
    {
        id: "13a9f1aa719347a2b11b9aaf5b688425",
        vod_user_id: "HSuh23Hl34h",
        cover: "http://video.idatascience.cn/image/cover/0B69D9C6A9A44A2CA976C05E97FB2F40-6-2.png",
        video_path:"http://video.idatascience.cn/sv/58dc68f7-17c1c96fdac/58dc68f7-17c1c96fdac.mp4",
        title: "大数据通识课教学研讨组介绍",
        teacher: "研讨组",
        description:"商业大数据分析教与学",
        duration:20,
        status:"已发布",
        upload_time:"2021-09-23 18:00",
        publish_time:"2021-09-23 18:00",//审核通过实践
        view_cnt:100,//观看次数
    },
    {
        id: "8b18c3c04f6e41319049de9f40da72c5",
        vod_user_id: "HSuh23Hl34h",
        cover: "http://video.idatascience.cn/image/cover/1EFF0B5264074BA7BF4E20BFDFFF25A3-6-2.png",
        video_path:"http://video.idatascience.cn/sv/5c3cb61a-17b2e99c0ac/5c3cb61a-17b2e99c0ac.mp4",
        title: "图表示学习",
        teacher: "宋国杰",
        description:"图表示学习",
        duration:20,
        status:"已发布",
        upload_time:"2021-09-23 18:00",
        publish_time:"2021-09-23 18:00",//审核通过实践
        view_cnt:100,//观看次数
    },
    {
        id: "1a3f2766a3ea47edb4456ee26137bd60",
        vod_user_id: "Ph9hsHuh8oa",
        cover: "http://video.idatascience.cn/image/cover/F4DC55CA5CEA44148BB9863FBBB86C72-6-2.png",
        video_path:"http://video.idatascience.cn/sv/1778dc6c-17b2e99c094/1778dc6c-17b2e99c094.mp4",
        title: "深度强化学习与智能体",
        teacher: "乔媛媛",
        description:"深度强化学习与智能体",
        duration:20,
        status:"已发布",
        upload_time:"2021-09-23 18:00",
        publish_time:"2021-09-23 18:00",//审核通过实践
        view_cnt:100,//观看次数
    },
    {
        id: "c8d091646e1a421dbc91d098a75d172c",
        vod_user_id: "MnafhlH1fls",
        cover: "http://video.idatascience.cn/image/cover/D255B28B74DA41678E1F05F20F4A852A-6-2.png",
        video_path:"http://video.idatascience.cn/sv/493a6fb5-17b2e99c044/493a6fb5-17b2e99c044.mp4",
        title: "机器学习在神策数据的应用和挑战",
        teacher: "胡士文",
        description:"机器学习在神策数据的应用和挑战",
        duration:20,
        status:"已发布",
        upload_time:"2021-09-23 18:00",
        publish_time:"2021-09-23 18:00",//审核通过实践
        view_cnt:100,//观看次数
    },
    {
        id: "70dd850561974c26a920b584070d18e1",
        vod_user_id: "MnafhlH1fls",
        cover: "http://video.idatascience.cn/image/cover/0C814557E4074912BD7D470F60BF08DB-6-2.png",
        video_path:"http://video.idatascience.cn/sv/5a546a2-17ccb3094ec/5a546a2-17ccb3094ec.mov",
        title: "爱数科平台介绍",
        teacher: "爱数科",
        description:"爱数科平台介绍",
        duration:20,
        status:"已发布",
        upload_time:"2021-09-23 18:00",
        publish_time:"2021-09-23 18:00",//审核通过实践
        view_cnt:100,//观看次数
    },
    {
        id: "27cdeb74d35f49c6a8f74e0d52e2ec27",
        vod_user_id: "MnafhlH1fls",
        cover: "http://video.idatascience.cn/image/cover/30AB308F0703474EA399619F50E84C5F-6-2.png",
        video_path:"http://video.idatascience.cn/sv/4c79bbe9-17ccb1cd0ce/4c79bbe9-17ccb1cd0ce.mp4",
        title: "爱数科案例：数据值替换",
        teacher: "爱数科",
        description:"爱数科案例：数据值替换",
        duration:20,
        status:"已发布",
        upload_time:"2021-09-23 18:00",
        publish_time:"2021-09-23 18:00",//审核通过实践
        view_cnt:100,//观看次数
    },
    {
        id: "f4d5769bffb9494ab915cd71869e23bc",
        vod_user_id: "MnafhlH1fls",
        cover: "http://video.idatascience.cn/image/cover/771E5F8E70E5473EB3304AF67594C0E7-6-2.png",
        video_path:"http://video.idatascience.cn/sv/5e90e29b-17ccb1c9191/5e90e29b-17ccb1c9191.mp4",
        title: "爱数科案例：数据筛选",
        teacher: "爱数科",
        description:"爱数科案例：数据筛选",
        duration:20,
        status:"已发布",
        upload_time:"2021-09-23 18:00",
        publish_time:"2021-09-23 18:00",//审核通过实践
        view_cnt:100,//观看次数
    },
]
const channelMap = new Map([['爱数科', 1], ['爱数课', 2]]);

export interface Props {}

export const OAMLectureList: React.FC<Props> = (props) => {
    const [lectureListResult, setLectureResult] = useState([] as any[]);  // 当前显示的结果页
    const [resultSum, setResultSum] = useState(0);  // 搜索返回的结果数
    const [totalCount, setTotalCount] = useState(0);  // 总数
    const [onlineCount, setOnlineCount] = useState(0);  // 上架的数量


    const queryContent = useRef('');  // 记录搜索串
    const order = useRef('');  // 排序升序降序
    const order_by = useRef('');  // 排序列
    const page = useRef(1);  // 记录当前的页数
    const pageSize = useRef(10);  // 记录当前每页展示数量
    const onlineStatusFilters = useRef([]);  // 在线状态过滤选项


    useEffect(() => {
        queryLectureList();
    }, [])


    /**
     * 查询讲座列表
     */
     const queryLectureList = () => {
        sendHttpPostRequest(
            'video/api/oam_query_lecture_list/',
            {
                sessionid: Cookies.get("sessionid"),    
                query: queryContent.current,
                page: page.current,
                pagesize: pageSize.current,
                order: order.current,
                order_by: order_by.current,
                online_status: JSON.stringify(onlineStatusFilters.current)
            },
            (response: any) => {
                // console.log(response)
                message.destroy();
                if (response.code == -100) {
                    // 未登录
                    window.location.href = '/oam_login?next=oam_lecture_list';
                } else if (response.is_ok == true) {
                    // 成功
                    setLectureResult(response.query_list);
                    setResultSum(response.total_sum);
                    setTotalCount(response.total_count);
                    setOnlineCount(response.online_count);
                } else if (response.code == -501) {
                    // 权限不足
                    window.location.href = '/oam_login?next=oam_lecture_list';
                } else {
                    message.error(response.message);
                }
            }
        
        )
    }


    //页码和页数发生了变化
    const onPageChange = (newPage: number, newPageSize?: number | undefined) => {
        page.current = newPage ? newPage : 1;
        if (newPageSize) {
            pageSize.current = newPageSize;
        }
        queryLectureList();
       
    }

   
     //点击了查看按钮
  const onLookOverBtnClick = (event:any) => {
    //message.success('查看数据' + event.currentTarget.value)
    event.preventDefault()
    window.open('/lecture-vod?id=' + event.currentTarget.value)
  }

  //点击了编辑按钮
  const onEditBtnClick = (event:any) => {
    //message.success('编辑数据' + event.currentTarget.value)
    event.preventDefault()
    window.open('/oam_lecture_edit?lecture_id=' + event.currentTarget.value)
  }

    //点击上传按钮
    const onUploadBtnClick = (event:any) => {
        sendHttpPostRequest(
            'video/api/oam_create_lecture/',
            {
                sessionid: Cookies.get("sessionid"),    
            },
            (response: any) => {
                // console.log(response)
                message.destroy();
                if (response.code == -100) {
                    // 未登录
                    window.location.href = '/oam_login?next=oam_lecture_list';
                } else if (response.is_ok == true) {
                    // 成功
                    window.open(`/oam_lecture_edit?lecture_id=${response.lecture_id}`);
                   
                } else if (response.code == -501) {
                    // 权限不足
                    window.location.href = '/oam_login?next=oam_lecture_list';
                } else {
                    message.error(response.message);
                }
            }
        
        )
      }


  // 点击 删除
  const onDeleteBtnClick = (lecture_id:any) => {
    sendHttpPostRequest(
        'video/api/oam_delete_lecture/',
        {
            sessionid: Cookies.get("sessionid"), 
            lecture_id: lecture_id,   
        },
        (response: any) => {
            // console.log(response)
            message.destroy();
            if (response.code == -100) {
                // 未登录
                window.location.href = '/oam_login?next=oam_lecture_list';
            } else if (response.is_ok == true) {
                // 成功
                message.success('讲座删除成功~');
                queryLectureList();
               
            } else if (response.code == -501) {
                // 权限不足
                window.location.href = '/oam_login?next=oam_lecture_list';
            } else {
                message.error(response.message);
            }
        }
    
    )
  }


  // 点了上架按钮
  const onPutOnOffBtnClick = (lecture_id:any, status:any) => {
   if(status == 1) //上架
   {
    sendHttpPostRequest(
        'video/api/oam_publish_lecture/',
        {
            sessionid: Cookies.get("sessionid"),    
            lecture_id: lecture_id,
        },
        (response: any) => {
            // console.log(response)
            message.destroy();
            if (response.code == -100) {
                // 未登录
                window.location.href = '/oam_login?next=oam_lecture_list';
            } else if (response.is_ok == true) {
                // 成功
               message.success('讲座上架成功~');
               queryLectureList();
               
            } else if (response.code == -501) {
                // 权限不足
                window.location.href = '/oam_login?next=oam_lecture_list';
            } else {
                message.error(response.message);
            }
        }
    
    )
   }else if(status == 2){//下架
    sendHttpPostRequest(
        'video/api/oam_unpublish_lecture/',
        {
            sessionid: Cookies.get("sessionid"), 
            lecture_id: lecture_id,   
        },
        (response: any) => {
            // console.log(response)
            message.destroy();
            if (response.code == -100) {
                // 未登录
                window.location.href = '/oam_login?next=oam_lecture_list';
            } else if (response.is_ok == true) {
                // 成功
                message.success('讲座下架成功~');
                queryLectureList();
               
            } else if (response.code == -501) {
                // 权限不足
                window.location.href = '/oam_login?next=oam_lecture_list';
            } else {
                message.error(response.message);
            }
        }
    
    )
   }
    
  }



    // 渲染操作
    const renderActions = (text:any, record:any) => {
        return <>
        <Row justify="start" align="middle" gutter={[8, 5]} >
        <Col style={{width:50}}><Button style={{borderRadius:20,fontSize:12}} size='small' id="lookover" value={record.id} type="default" onClick={onLookOverBtnClick} > 查 看 </Button></Col>
        <Col style={{width:50}}><Button style={{borderRadius:20,fontSize:12}} size='small' id="edit" value={record.id} type="dashed" onClick={onEditBtnClick}> 编 辑 </Button> </Col>
            {<Col style={{width:50}}> { parseInt(record.status) == 1 ? 
            <Button style={{borderRadius:20,fontSize:12}}  size='small' id="puton" value={record.id} type="primary"  onClick={onPutOnOffBtnClick.bind(this, record.id, record.status)}> 上 架 </Button>
            :  <Popconfirm placement="top" title={'确认下架讲座"' + record.title + '"吗？'} onConfirm={onPutOnOffBtnClick.bind(this, record.id, record.status)} okText="确认" cancelText="取消">
                <Button style={{borderRadius:20,fontSize:12}}  size='small' id="putoff" value={record.id} type="primary"  danger> 下 架 </Button>
                </Popconfirm>}
            </Col> }
            { <Col style={{width:50}}>
            <Popconfirm placement="top" title={'确认删除讲座"' + record.title + '"吗？'} onConfirm={onDeleteBtnClick.bind(this, record.id)} okText="确认" cancelText="取消">
            <Button style={{borderRadius:20,fontSize:12}} size='small' id="delete" danger value={record.id} > 删 除 </Button> 
            </Popconfirm></Col> }
        </Row>

        </>

        }

 
  
    //点击了搜索按钮
    const onSearchBtn = (value: string) => {
        page.current = 1;
        queryContent.current = value;
        queryLectureList();
    }

    // 表格发生变化
    const onTableChange:any = (pagination:any, filters:any, sorter:any, extra: { currentDataSource: [], action: any })=> {
        if (sorter.order) { //如果选择了排序列
            order.current = sorter.order;
            order_by.current = sorter.field;
        }
        if (filters.status) { //如果选择了过滤列
            onlineStatusFilters.current = filters.status;
        } else {
            onlineStatusFilters.current = [];
        }
        page.current = 1;
        queryLectureList();
    }

    const getVideoPreview =   (lecture: any)=>{
        return <div style={{background:"#000"}}>
            { lecture?.video_path !== "" && 
            <video  style={{marginTop:25,marginBottom:25,height:320,width:500}}  preload="preload" 
            controls={true}
            controlsList="nodownload">
                <source 
                src= { lecture?.video_path?.startsWith("http") ? lecture?.video_path : constants.BACKEND_URL + lecture?.video_path}
                type={"video/mp4"} />
                您的浏览器不支持 HTML5 video 标签。
            </video>
            }
         </div>

    } 

    // 表格列
    const cols: any = [
        {
            title: "序号",
            dataIndex: "id",
            key: "id",
            width: 60,
            align: 'left',
            ellipsis: true,
            render:(text, record, index)=>((page.current - 1)* pageSize.current + index + 1)
            //fixed: "left",
            //sortDirections: ['descend', 'ascend'],
        },

        {
            dataIndex: "video",
            key: "video",
            width: 125,
            align: 'left',
            render:(text,record)=>{
                return <div className={selfCss.cardImageDivMini}>
                                
                <Popover overlayStyle={{padding:0}} overlayInnerStyle={{padding:0}}
                        destroyTooltipOnHide={true} placement = "top" trigger= "click" content={getVideoPreview(record)}>
                    <PlayCircleOutlined className={selfCss.imagePlayIconMini}/>
                </Popover>
            
                { record.cover?
                    <img
                    style={{cursor:"pointer"}}
                    width={125}
                    height={80}
                    src={ record.cover?.startsWith("http") ? record.cover : constants.BACKEND_URL + record.cover}
                    /> :<div style={{height:80, width:125}}></div>
                }  
            </div>
            }
        },
        {
            title: "题目",
            dataIndex: "title",
            key: "title",
            width: 120,
            align: 'left',
            render:(text, record)=>{
               return <div style={{cursor:"pointer"}} onClick={()=>{window.open(`/lecture-vod?lecture_id=${record.id}`)}}> {text} </div>
            }
            //fixed: "left",
            //sortDirections: ['descend', 'ascend'],
        },
        {
            title: "主讲人",
            dataIndex: "teacher",
            key: "teacher",
            width: 80,
            align: 'left',
        },
        {
            title: "观看次数",
            dataIndex: "view_cnt",
            key: "view_cnt",
            width: 80,
            align: 'center',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: "创建时间",
            dataIndex: "create_time",
            key: "create_time",
            width: 120,
            align: 'left',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: "状态",
            dataIndex: "status",
            key: "status",
            width: 80,
            align: 'center',
            render:(text)=>(text == 1 ? '下线':'在线'),
            filters: [
                {
                    text: '下线',
                    value: 1,
                },
                {
                    text: '在线',
                    value: 2,
                }
            ],
            onFilter: (value:any, record:any) => true,
        },
        {
            title: "操作",
            dataIndex: "actions",
            key: "actions",
            width: 160,
            ellipsis: false,
            align: 'center',
            fixed: "right",
            render: renderActions ,
        },
    ]

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <OAMSider keys={"8"} openKeys={["sub-lecture"]} ></OAMSider>
            <Layout style={{backgroundColor:'#f9f9f9'}}>
                <Content style={{ backgroundColor:'#fff',borderRadius:5,minWidth:400,margin:30 ,paddingTop:20}}>
                
                    <div style={{paddingLeft:30,paddingRight:30}}>
                    <Row align="middle"  gutter={16}>
                        <Col flex='80px'>
                            <Statistic title="讲座总数" value={totalCount} />
                        </Col>
                        <Col flex='80px'>
                            <Statistic title="已上线" value={onlineCount} />
                        </Col>
                        <Col flex='350px'>
                            <Search
                                placeholder="搜索讲座"
                                style={{ width: 350 }}
                                enterButton={true}
                                onSearch={onSearchBtn}
                            />

                        </Col>
                       
                        <Col flex='auto' style={{textAlign:'right'}}> <Button style={{borderRadius:20}} type={'primary'} onClick={onUploadBtnClick}>+创建讲座</Button></Col>
                        </Row>
                      

                        <div className={idataCourseTableStyles.tableWrapper}>
                        <ConfigProvider locale={zhCN}>
                            <Table
                                dataSource={lectureListResult}
                                columns={cols}
                                rowKey={record => record.id}
                                pagination={false}
                                scroll={{ x: 800 }}
                               // bordered={true}
                                onChange={onTableChange}
                            />
                        </ConfigProvider>
                        </div>

                        <ConfigProvider locale={zhCN}>
                            <div style={{marginBottom:30,paddingTop:30}} className={"css.pagination"} >
                                <Pagination
                                    defaultCurrent={1}
                                    total={resultSum}
                                    current={page.current}
                                    defaultPageSize={10}
                                    hideOnSinglePage={true}
                                    onChange={onPageChange}
                                    showQuickJumper={true}
                                    showTotal={total => `共 ${total} 讲座`}
                                    pageSizeOptions={["3","20","30","50","100"]}
                                />
                            </div>
                        </ConfigProvider>
                    </div>
                </Content>
            </Layout>
        </Layout>

    )

}

export default OAMLectureList


