import React, {useEffect, useRef, useState} from "react";
import {TimePicker,Steps,Input,Switch,Layout, message, Col, Row, ConfigProvider, Button,DatePicker, Space, Modal, Checkbox, Table, Alert } from 'antd';
import { PageHeader } from "../../common/page-header/header";
import { sendHttpPostRequest } from "../../../api/http-request";
import courseCreateTestStyles from "./course-create-test.module.less";
import zhCN from 'antd/lib/locale/zh_CN';
import LoginRegisterModal from "../../../component/modal/login-register/login-register";
import {checkUserLogin} from "../../common/user-center/user-info";
import CourseTeacherTestComposition from "./course-teacher-test-composition";

import {ClockCircleOutlined,FileWordOutlined,TableOutlined,DownOutlined,UpOutlined} from  '@ant-design/icons';

import testPreviewStyles from "./course-teacher-test-preview.module.less";

import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from 'moment';
import Cookies from 'js-cookie';
import { getRangeDate } from "../../common/utils/util";
import Draggable from "react-draggable";
import ExerciseCard from "../../exercise/exercise-card";

const format = 'HH:mm';

const {Step} = Steps
const {TextArea} = Input 



const typeMap = new Map([[1, '实操题'], [2, '单选题'], [3, '多选题'],[4,"排序题"],[5, "主观题"]]);
const difficultyMap = new Map([[1, '容易'], [2, '中等'], [3, '困难']]);
  
const dateFormat = 'YYYY-MM-DD HH:mm';


export interface Props {}
/** 教师课程创建作业页面 */
export const CourseCreateTest: React.FC<Props> = (props) => {
    const [current, setCurrent] = React.useState(0)//当前所在步骤
    const homeworkId = parseInt(window.location.search.split("&")[0].split("=")[1]);//作业ID
    const courseId = parseInt(window.location.search.split("&")[1].split("=")[1]);//课程
    const currentCourseMenuId = parseInt(window.location.search.split("&")[2].split("=")[1]);//课程大纲ID
    const [homeworkName, setHomeworkName] = useState('');  // 作业名称
    const [homeworkDescription, setHomeworkDescription] = useState('');  // 作业说明

    const [homeworkExercises,setHomeworkExercises] = useState([] as any[]);
    const [submitTime, setSubmitTime] = useState(moment().utc().zone(-8).add(2, 'hours').format(dateFormat).toString() as any);  // 作业提交时间
    const [deadline, setDeadline] = useState(moment().utc().zone(-8).add(4, 'hours').format(dateFormat).toString() as any);  // 作业最晚时间
    const [allowDelay, setAllowDelay] = useState(false);  // 接受作业延迟提交

 
    
    const [isModalVisible, setIsModalVisible] = useState(false); 
    const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
    const [isSaveVisible, setIsSaveVisible] = useState(false);

    const [bounds,setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const [disabled,setDisabled] = useState(true);
    const draggleRef = useRef<HTMLDivElement | null>(null);
    const onStart = (event:any, uiData:any) => {
      const { clientWidth, clientHeight } = window?.document?.documentElement;
      const targetRect= draggleRef?.current?.getBoundingClientRect();
      if(targetRect){
        setBounds({
          left: -targetRect?.left + uiData?.x,
          right: clientWidth - (targetRect?.right - uiData?.x),
          top: -targetRect?.top + uiData?.y,
          bottom: clientHeight - (targetRect?.bottom - uiData?.y),
        })
      }
    }



    /** 计算作业总分 */
  const getTotalScore = ()=>{
    let sumScore = 0;
    homeworkExercises.map(item=>{
        if (parseInt(item.score) == 0)
            sumScore += 5;
        else
            sumScore += parseInt(item.score);
    })

    return sumScore
  }

    const disabledSubmitDate = (current:any) => {
      return current && current < moment().startOf('day');
    }

    const disabledEndDate = (current:any) => {
      return current && current < moment(submitTime).startOf('day');
    }

    // 提交时间改变
    const onSubmitTimeChange = (value: any, dateString: any) => {
        setSubmitTime(dateString);
        setDeadline(moment(dateString).utc().zone(-8).add(2, 'hours').format(dateFormat).toString() as any);
    }

    // 延迟时间改变
    const onDeadlineChange = (value: any, dateString: any) => {
        setDeadline(dateString);
    }

    // 接受延迟提交
    const onDelayChecked = () => {
        setAllowDelay(!allowDelay);
    }

    // 获取当前时间
    const currentTime = moment().valueOf();
    const range = (start, end) => {
      const result:any = [];
      for (let i = start; i < end; i++) {
        result.push(i);
      }
      return result;
    };
    
    // 截止时间与当前时间牵制
    const disabledSubmitTime:any = (dateTime) => { 
      // 判断所选日期与当前日期是否相等  
      if (moment(dateTime).isSame(moment(currentTime), "day")) {
          return {
            disabledHours: () => range(0, moment(currentTime).hour()),
            disabledMinutes: () =>
              moment(dateTime).isSame(moment(currentTime), "hour")
                ? range(0, moment(currentTime).minute())
                : []
          };
        }else return [];
    };

    // 最晚时间与截止时间牵制
    const disabledEndTime:any = (dateTime) => {
      if (moment(dateTime).isSame(moment(submitTime), "day")) {
        return {
          disabledHours: () => range(0, moment(submitTime).hour()),
          disabledMinutes: () =>
            moment(dateTime).isSame(moment(submitTime), "hour")
              ? range(0, moment(submitTime).minute())
              : []
        };
      }else return [];
    }

    // 查询作业回调
    const queryHomeworkCallback = (response:any) => {
        message.destroy();
        if (response.code == -100) {
            // 未登录
            document.getElementById("show-login-modal")!.click();
        } else if (response.is_ok == true) {
            // 成功
            setHomeworkName(response.query_info.name);
            setHomeworkDescription(response.query_info.description);
            setHomeworkExercises(response.query_info.exercise);

            response.query_info.submit_time.length > 0 ? setSubmitTime(response.query_info.submit_time) : '';
            response.query_info.deadline.length > 0 ? setDeadline(response.query_info.deadline) : '';
            response.query_info.allow_delay ? setAllowDelay(true) : '';
        } else if (response.code == -501) {
            // 权限不足
            message.error("权限不足")
        } else {
            message.error(response.message);
        }
    }

    // 查询作业信息列表
    const queryHomework = () => {
        sendHttpPostRequest(
            'homework/api/user_query_homework_by_id/',
            {
                sessionid: Cookies.get("sessionid"),
                homework_id: homeworkId,
                course_id: courseId
            },
            queryHomeworkCallback
        )
    }


    useEffect(()=>{
        queryHomework();
  },[])



    const onTestExerciseChange = (exercisesList)=>{
        setHomeworkExercises(exercisesList)
    }

    const handleOk = () => { //确认发布作业
        setIsModalVisible(false);
        const params = {
            sessionid: Cookies.get("sessionid"),
            homework_id: homeworkId,
            status: 2,//发布状态
            name: homeworkName,
            description: homeworkDescription,
            submit_time: submitTime,
            deadline: deadline,
            exercise: JSON.stringify(homeworkExercises),
            allow_delay: allowDelay,
        };
        sendHttpPostRequest(
            'homework/api/user_update_homework/',
            params,
            (response:any) => {
                message.destroy();
                if (response.code == -100) {
                    // 没登录
                    document.getElementById("show-login-modal")!.click();
                } else if (response.code == -501) {
                    // 没权限
                    message.error("权限不足");
                } else if (response.is_ok == true) {
                    setIsSuccessModalVisible(true);
                    setTimeout(function() {
                        setIsSuccessModalVisible(false);
                        window.location.href=`/course-main-teacher?course_id=${courseId}&tabKey=4`;
                    },2000)
                }else {
                    message.error(response.message);
                }
            }
        )
    }
  
    const handleCancel = () => {
      setIsModalVisible(false)
    }


      /** 作业基本信息预览 */
    const TestBasicInfoPreview : React.FC<Props> = (props)=>{



      /** 渲染预览的习题 */
      const renderExercisePreview = (record:any) => {
        return <ExerciseCard exercise={record} renderType="white" teacherOnly={true} editorMode={true} showAnser={false} showClean={true}/>
      }


        // 渲染分值列
    const renderScore = (text:any, record:any) => {

      return <div>{text == 0 ? 5 : text}</div>
    
    }

      // 渲染类型
      const renderType = (text: any, record: any) => (
        <div>{typeMap.get(parseInt(text))}</div>
    )

    // 渲染难易程度
    const renderDifficulty = (text: any, record: any) => (
        <div>{difficultyMap.get(parseInt(text))}</div>
    )
    const exercise_columns:any = [
      {
        title: '题号',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: 80,
        render:(text,record,index)=>(index + 1)
      },
      {
        title: '题目',
        dataIndex: 'title',
        key: 'title',
        align: 'left',
        width: 200,
        ellipsis:"true",
      },
      {
        title: '题型',
        dataIndex: 'type',
        key: 'type',
        align: 'center',
        width: 80,
        render: renderType,
      },
      {
        title: '难度',
        dataIndex: 'difficulty',
        key: 'difficulty',
        align: 'center',
        width: 80,
        render: renderDifficulty,
      },
    
      {
        title: '分值',
        dataIndex: 'score',
        key: 'score',
        align: 'center',
        width: 80,
        render:renderScore,
      
      },
    ]

    return  <ConfigProvider locale={zhCN}>
    <div style={{marginTop:20,marginBottom:20}}>
      <Row align='middle' justify='start' gutter={[6,6]}>
          <Col style={{fontWeight:700,fontSize:16,textAlign:'left'}} span={24}> {homeworkName} </Col>
          <Col><span style={{color:'#07C160',fontSize:16}}><ClockCircleOutlined /></span></Col>
          <Col style={{textAlign:'left'}} span={22}>{submitTime} </Col>
          <Col style={{textAlign:'left'}} span={24}> 共 <span style={{color:"#07C160"}}>{homeworkExercises?.length}</span> 道题   总分<span style={{color:"#07C160"}}> {getTotalScore()} </span>分</Col>
          <Col style={{fontWeight:500,textAlign:'left',marginTop:10}} span={24}> 作业说明：</Col>
          <Col style={{textAlign:'left'}} span={24}> {homeworkDescription} </Col>
      </Row>
      <div className={testPreviewStyles.tableWrapper} style={{marginTop:20}}>
        <ConfigProvider locale={zhCN}>
          <Table
            dataSource={homeworkExercises}
            columns={exercise_columns}
            size='small'
            rowKey={record => record.id}
            pagination={false}
            expandable={{
              columnWidth: 90,
              childrenColumnName:"预览",
              expandIconColumnIndex:6,
              expandIcon:({ expanded, onExpand, record }) =>
              expanded ? (
              <><UpOutlined  className={testPreviewStyles.actionBtn} onClick={e => onExpand(record, e)} /></>
              ) : (
              <><DownOutlined className={testPreviewStyles.actionBtn} onClick={e => onExpand(record, e)} /></>
              ),
              expandedRowRender: renderExercisePreview,
              rowExpandable: record => record.name !== 'Not Expandable',
              }}
              
              />
        </ConfigProvider>
      </div>

    </div>
  </ConfigProvider>
    }



    /** 填写作业基本信息 */
    const TestBasicInfo = ()=>{
        return <ConfigProvider locale={zhCN}>
          <div style={{marginTop:30,marginBottom:20}}>       
            <div>
              <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:30}}>
                <Col span={4} style={{color:"#00000073", textAlign:'left'}}>作业标题</Col>

                <Col style={{textAlign:'left'}} span={20}> <Input style={{borderRadius:3}} value={homeworkName} onChange={(e:any)=>{setHomeworkName(e.target.value)}} /> </Col>
              </Row>
              <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:30}}>
                <Col span={4} style={{color:"#00000073", textAlign:'left'}} >截止时间</Col>
                               
                <Col style={{textAlign:'left'}}  >
                  <DatePicker  
                  value={submitTime ? moment(submitTime, dateFormat) : moment(getRangeDate(1), dateFormat)}
                  allowClear={false}
                  disabledDate = {disabledSubmitDate}
                  format={dateFormat}
                  showTime
                  onChange={onSubmitTimeChange}
                  disabledTime={disabledSubmitTime}
                  />
                  {/*<TimePicker style={{marginLeft:10,width:100}} defaultValue={moment('23:59', format)} format={format} />*/}
                  </Col>
                  <Col style={{textAlign:'left',fontSize:12,paddingRight:0,color:'#666'}} >
                     {/* <Switch  defaultChecked  />   */}
                     <Checkbox checked={allowDelay ? true : false} onChange={onDelayChecked} style={{margin:'0 8px 0 10px'}}/>接受作业延迟提交
                  </Col>
              </Row>
                <Row gutter={[8,8]} justify='start' hidden={!allowDelay} align='middle' style={{marginBottom:30}}>
                  <Col span={4} style={{color:"#00000073", textAlign:'left'}} >最晚时间</Col>
                  <Col style={{textAlign:'left'}} >
                      <DatePicker
                          value={deadline ? moment(deadline, dateFormat) : moment(getRangeDate(1), dateFormat)}
                          allowClear={false}
                          disabledDate = {disabledEndDate}
                          format={dateFormat}
                          showTime
                          onChange={onDeadlineChange}
                          disabledTime={disabledEndTime}
                      />
                  </Col>
                </Row>
                <Row gutter={[8,8]} justify='start'  align='top' style={{marginBottom:30}}>
                <Col span={4} style={{color:"#00000073", textAlign:'left'}}>作业说明</Col>
                <Col span={20}>
                    <TextArea
                        style={{borderRadius:6}}
                        rows={6}
                        showCount={true}
                        maxLength={1000}
                        value={homeworkDescription}
                        onChange={(e: any)=>{setHomeworkDescription(e.target.value)}}
                    ></TextArea>
                </Col>
              </Row>
            </div>
          </div>
        </ConfigProvider>
    }

   
    const steps = [
        //布置作业步骤
        {
          title: '填写基本信息',
          content: <div style={{width:480,display:"block",marginLeft:"auto",marginRight:"auto"}}>{TestBasicInfo()}</div>,
        },
        {
          title: '选择题目',
          // content: <TestSelectPractice />
          content: <CourseTeacherTestComposition  course_id = {courseId} menu_id={currentCourseMenuId} initExercises={homeworkExercises}   onTestExerciseChange={onTestExerciseChange}/>
          
        },
        {
          title: '发布作业',
          content: <TestBasicInfoPreview />,
        },
      ]

    const next = () => {
      message.destroy()
      if(current == 0){
        const params = {
          sessionid: Cookies.get("sessionid"),
          homework_id: homeworkId,
          name: homeworkName,
          description: homeworkDescription,
          submit_time: submitTime,
          deadline: deadline,
          exercise: JSON.stringify(homeworkExercises),
          allow_delay: allowDelay,
        }
        if(homeworkName == null || homeworkName.trim() == ''){
          message.error("作业标题不能为空！");
          return '';
        }
        if(homeworkDescription == '' || homeworkDescription.trim() == ''){
          message.error("作业说明不能为空！");
          return '';
        }
        saveHomeworkParams(params, false);
        setCurrent(current + 1);
      }else if(current == 1){
        const params = {
          sessionid: Cookies.get("sessionid"),
          homework_id: homeworkId,
          exercise: JSON.stringify(homeworkExercises)
        }

        if(homeworkExercises?.length == 0){
          message.error("作业中还没有题目呢~");
          return '';
        }
        saveHomeworkParams(params, false);
        setCurrent(current + 1);
      }else if(current == 2)
      {
        setCurrent(current + 1);
      }else {
        setCurrent(current + 1);
      }
    }

    const prev = () => {
        setCurrent(current - 1)
    }

    const saveHomeworkParams = (params: any, showMessage: true | false) => {
        sendHttpPostRequest(
            'homework/api/user_update_homework/',
            params,
            (response:any) => {
              message.destroy();
              if (response.code == -100) {
                // 没登录
                document.getElementById("show-login-modal")!.click();
              } else if (response.code == -501) {
                // 没权限
                message.error("权限不足")
              } else if (response.is_ok == true) {
                showMessage == true ? message.success("作业信息保存成功~") : ""
              } else {
                message.error(response.message);
              }
            }
        )
    }

    /** 点击保存按钮 */
    const onSaveBtnClick = (e:any)=>{
        if (current == 0) {
            // 第一步
            const params = {
                sessionid: Cookies.get("sessionid"),
                homework_id: homeworkId,
                name: homeworkName,
                description: homeworkDescription,
                submit_time: submitTime,
                deadline: deadline,
                allow_delay: allowDelay,
            }
            saveHomeworkParams(params, true);
        } else if(current == 1) {
            // 第二步
            const params = {
                sessionid: Cookies.get("sessionid"),
                homework_id: homeworkId,
                name: homeworkName,
                description: homeworkDescription,
                submit_time: submitTime,
                deadline: deadline,
                exercise: JSON.stringify(homeworkExercises),
                allow_delay: allowDelay,
            }
            saveHomeworkParams(params, true);
        } else {
            // 第三步
            const params = {
                sessionid: Cookies.get("sessionid"),
                homework_id: homeworkId,
                name: homeworkName,
                description: homeworkDescription,
                submit_time: submitTime,
                deadline: deadline,
                exercise: JSON.stringify(homeworkExercises),
                allow_delay: allowDelay,
            }
            saveHomeworkParams(params, true);
        }
    }


    return (
        <Layout style={{minHeight: 750,backgroundColor: '#f9f9f9'}}>
          
          <LoginRegisterModal></LoginRegisterModal>

          <div className={courseCreateTestStyles.courseMain} style={{minHeight:"calc(100vh - 184px)",marginBottom:30}} >
          <div >
          <Row align="middle" justify="center" style={{borderBottom: '1px solid #eee',height:60}}>
            <Col flex={"100px"} style={{textAlign:"left"}}> <div style={{fontWeight:700,fontSize:16}}>布置作业</div> </Col>
            <Col flex={"auto"} >
              <Steps size='small'  labelPlacement="horizontal" current={current} style={{marginTop:0}}>
                {steps.map((item) => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
            </Col>
            <Col flex={"150px"} style={{color:"#333333",textAlign:"right",cursor:'pointer'}} >
              <a style={{color:"#333333"}} href={"/course-main-teacher?course_id="+ courseId + "&tabKey=4"}>返回{">"}</a> 
             
            </Col>
          </Row>

          
    
            <div style={{minHeight:250}} >{steps[current].content}</div>

            </div>

       </div>


          <ConfigProvider locale={zhCN}>
              <Modal
                    title={<div style={{width: '100%',cursor: 'move',}}
                    onMouseOver={() => {if (disabled) {setDisabled(false)}}}
                    onMouseOut={() => {setDisabled(true)}}
                    onFocus={() => {}}
                    onBlur={() => {}}>提示</div>
                    }
                    closable={false}
                    visible={isSaveVisible}
                    bodyStyle={{ height: '100px',borderTop:'1px solid #eee'}}
                    destroyOnClose={true}
                    width={350}
                    footer={
                      <div>
                        <Button className={courseCreateTestStyles.stepsBtn} onClick={()=>{setIsSaveVisible(false)}}>取消</Button>                      
                        <Button className={courseCreateTestStyles.stepsBtn} 
                        onClick={()=>{setIsSaveVisible(false);window.open('/course-main-teacher?course_id=1','_self')}}>不保存</Button>
                        <Button className={courseCreateTestStyles.stepsBtn} type="primary" style={{color:'#fff'}}
                        onClick={()=>{setIsSaveVisible(false);window.open('/course-main-teacher?course_id=1','_self')}}>保存</Button>
                        
                      </div>
                    }
                    modalRender={modal => (<div className={courseCreateTestStyles.miniModal}>
                      <Draggable
                        disabled={disabled}
                        bounds={bounds}
                        onStart={(event, uiData) => onStart(event, uiData)}
                      >
                        <div ref={draggleRef}>{modal}</div>
                      </Draggable> </div>
                    )}  
                  >
                    <div style={{textAlign:'center',color:'#333'}}>
                      <div>您所填写的内容尚未保存，是否保存？</div>
                      <div style={{fontSize:12,marginTop:10}}>若不保存，修改将会丢失。</div>
                    </div>
                </Modal>


                <Modal
                title={<div
                    style={{
                      width: '100%',
                      cursor: 'move',
                    }}
                    onMouseOver={() => {
                      if (disabled) {
                        setDisabled(false)
                      }
                    }}
                    onMouseOut={() => {
                      setDisabled(true)
                    }}
                    onFocus={() => {}}
                    onBlur={() => {}}
                    // end
                  >
                    提示
                  </div>
                }

                  visible={isModalVisible}             
                  bodyStyle={{ height: '120px',borderTop:'1px solid #eee'}}
                  destroyOnClose={true}
                  closable={false}
                  width={520}
                  footer={
                    <div>
                      <Button className={courseCreateTestStyles.stepsBtn}
                      style={{marginRight:20}}
                      type="default"
                      onClick={handleCancel}>取消</Button>
                      <Button className={courseCreateTestStyles.stepsBtn}
                      style={{marginRight:20,color:'#fff'}}
                      type="primary"
                      onClick={handleOk}>确认</Button>
                    </div>
                  }
                  modalRender={modal => (<div className={courseCreateTestStyles.miniModal}>
                    <Draggable
                      disabled={disabled}
                      bounds={bounds}
                      onStart={(event, uiData) => onStart(event, uiData)}
                    >
                      <div ref={draggleRef}>{modal}</div>
                    </Draggable> </div>
                  )}
                >

                <Space
                    size="small"
                    direction="vertical"
                    style={{ width: '100%' }}
                  >
                    <Row justify="center" align="middle" style={{marginBottom:20}}>
                      您确认要发布作业吗？
                    </Row>

                    
                    <Row justify="center" align="top" style={{marginBottom:10}}>
                    总分:<span style={{color:"#07C160",marginRight:10}}> {getTotalScore()}</span> {"    "} 共<span style={{marginLeft:5,marginRight:5,color:"#07C160"}}>{" "} {homeworkExercises?.length} {" "} </span>题
                    </Row>
                  </Space>

                </Modal>

                <Modal
                  title={<div style={{width: '100%',cursor: 'move',}}
                  onMouseOver={() => {if (disabled) {setDisabled(false)}}}
                  onMouseOut={() => {setDisabled(true)}}
                  onFocus={() => {}}
                  onBlur={() => {}}>提示</div>
                  }
                  closable={false}
                  visible={isSuccessModalVisible}
                  bodyStyle={{ height: '80px',borderTop:'1px solid #eee'}}
                  destroyOnClose={true}
                  width={350}
                  footer={[]}
                  modalRender={modal => (<div className={courseCreateTestStyles.miniModal}>
                    <Draggable
                      disabled={disabled}
                      bounds={bounds}
                      onStart={(event, uiData) => onStart(event, uiData)}
                    >
                      <div ref={draggleRef}>{modal}</div>
                    </Draggable> </div>
                  )}  
                >
                  <div style={{textAlign:'center',marginTop:10}}>已发布成功!</div>
                </Modal>
          </ConfigProvider>

          <div style={{bottom:0,paddingTop:14,paddingBottom:14,width:'100%',textAlign: 'center',backgroundColor:'#fff',boxShadow:'0 -2px 5px #eee'}}>
            {/* <div style={{margin:'14px auto'}}>              */}
              {current > 0 && (
                <Button 
                  type="default" 
                  className={courseCreateTestStyles.stepsBtn} 
                  style={{marginRight:20}}  
                  onClick={() => prev()}>
                    上一步
                  </Button>
              )}
              {current == 0 && (
                  <Button
                    className={courseCreateTestStyles.stepsBtn}
                    style={{marginRight:20}}
                    type="default"
                    onClick={()=>window.close()}
                  //
                  >
                    取消
                  </Button>
              )}
              {current >= 0 && (
                  <Button
                    className={courseCreateTestStyles.stepsBtn}
                    style={{marginRight:20}}
                    type="default"
                    onClick={onSaveBtnClick}
                  //
                  >
                    保存
                  </Button>
              )}
              {current < steps.length - 1 && (
                  <Button
                    className={courseCreateTestStyles.stepsBtn}
                    style={{marginRight:20,color:'#fff'}}
                    type="primary"
                    onClick={() => next()}
                    //disabled={!uploadSuccess}
                  >
                    下一步
                  </Button>
              )}
              {current == steps.length - 1 && (
                  <Button
                    className={courseCreateTestStyles.stepsBtn}
                    style={{marginRight:20,color:'#fff'}}
                    type="primary"
                    onClick={() => {homeworkExercises?.length == 0 ? message.warning("请先将题库中的题加入到作业中") :setIsModalVisible(true)}}
                    //disabled={!uploadSuccess}
                  >
                    发布
                  </Button>
              )}
            {/* </div> */}
          </div>
       </Layout>
    )

}

export default CourseCreateTest

