import React, { Component } from 'react'
import { List, Input, Button, message } from 'antd'
import { Link } from 'react-router-dom'
import { UserOutlined, LockOutlined, MobileOutlined } from '@ant-design/icons'
import axios from 'axios'
import loginRegisterStyles from './login-register.module.less'
import './login-register.css'
import localStorage from 'localStorage'
import logoSvg from '../../../static/image/logo.svg'
import { sendHttpPostRequest } from '../../../api/http-request'
import CommonModal from 'component/modal/CommonModal/CommonModal'
import GlobalCss from '@/global.module.less'

class LoginRegister extends Component {
  state = {
    idens: '', //帐号
    password: '', // 密码
    passwordConfirm: '', // 密码确认
    vcode: '', // 验证码
    displayMode: 1, // 1-登录 2-注册模式 3-密码找回
    errorMessage: '', // 错误码
    sendVcodeTip: '发送验证码', // 验证码发送提示
    sendBtnDisabled: false, // 发送按钮不可用
    sendRetrievalVcodeTip: '发送验证码', // 验证码发送提示（密码找回）
    sendRetrievalBtnDisabled: false, // 发送按钮不可用（密码找回）
    inviter: '', // 邀请者
    forgetPasswordModalVis: false, // 密码找回模态框
    manager_idens: '',
  }

  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="login-register-wrapper login">
        <div className={loginRegisterStyles.login_register_logo}>
          <Link to={'/'} title={'返回爱数课首页'}>
            <img
              src={logoSvg}
              alt=""
              // height={36}
              width={180}
              style={{ display: 'inline-block' }}
              // style={{paddingTop:10, paddingLeft:10}}
            />
          </Link>
          {/*<div><span>爱数课</span></div>*/}
        </div>
        <div
          className={loginRegisterStyles.pageLogin}
          hidden={this.state.displayMode != 1}
        >
          {/*<div className="semi-globe">*/}
          {/*    <img src={ imgUrl } alt="" />*/}
          {/*</div>*/}
          <List className="">
            <div id="error-message" className="error-message">
              {this.state.errorMessage}
            </div>
            <Input
              id="phone"
              name="phone"
              className="login-form phone"
              value={this.state.idens}
              placeholder={'请输入手机号'}
              required={true}
              prefix={<UserOutlined />}
              onChange={this.handleChange.bind(this, 'idens')}
            />
            <Input.Password
              id="password"
              name="password"
              type="password"
              prefix={<LockOutlined />}
              className="login-form password"
              placeholder="请输入密码，6-20位"
              maxLength={20}
              onChange={this.handleChange.bind(this, 'password')}
            />
            <div
              onClick={this.handleLogin.bind(this)}
              className="button btn-register"
            >
              登 &nbsp;&nbsp;录
            </div>
            <div className="cue1" onClick={this.handleGoRegister.bind(this)}>
              还没有帐号，去注册 &gt;
            </div>
            <div
              style={{ marginTop: 10 }}
              className="cue1"
              onClick={this.handleGoPasswordRetrieve.bind(this)}
            >
              忘记密码？去找回 &gt;
            </div>
          </List>
        </div>
        <div
          className={loginRegisterStyles.pageRegister}
          hidden={this.state.displayMode != 2}
        >
          <List className="">
            <div className="error-message">{this.state.errorMessage}</div>
            <Input
              className="register-form phone"
              placeholder={'请输入手机号'}
              required={true}
              prefix={<UserOutlined />}
              onChange={this.handleChange.bind(this, 'idens')}
              value={this.state.idens}
            />
            {process.env.REACT_APP_SMS_HIDE == 'true' ? (
              ''
            ) : (
              <Input
                onClick={this.checkPhone.bind(this)}
                type="button"
                className="btn btn-primary send-phone-code"
                disabled={this.state.sendBtnDisabled}
                value={this.state.sendVcodeTip}
              />
            )}
            {process.env.REACT_APP_SMS_HIDE == 'true' ? (
              ''
            ) : (
              <Input
                className="register-form phone-vcode"
                placeholder={'请输入短信验证码'}
                maxLength={6}
                onChange={this.handleChange.bind(this, 'vcode')}
                prefix={<MobileOutlined />}
              />
            )}
            <Input
              className="register-form password"
              placeholder={'请输入密码，6-20位'}
              maxLength={20}
              prefix={<LockOutlined />}
              onChange={this.handleChange.bind(this, 'password')}
              type={'password'}
            />
            <Input
              className="register-form password-confirm"
              placeholder={'请再输入一遍密码'}
              prefix={<LockOutlined />}
              onChange={this.handleChange.bind(this, 'passwordConfirm')}
              type={'password'}
            />
            <Input
              className="register-form inviter"
              placeholder={'请输入邀请人手机号(可选)'}
              onChange={this.handleChange.bind(this, 'inviter')}
              prefix={<UserOutlined />}
            />
            <div
              onClick={this.handleRegister.bind(this)}
              className="button btn-register"
            >
              注 &nbsp;&nbsp;册
            </div>
            <div className="cue1" onClick={this.handleGoLogin.bind(this)}>
              已有帐号，返回登录 &gt;
            </div>
          </List>
        </div>
        <div
          className={loginRegisterStyles.pageRetrieval}
          hidden={this.state.displayMode != 3}
        >
          <List className="">
            <div id="error-message-login" className="error-message">
              {this.state.errorMessage}
            </div>
            <Input
              className="login-form phone"
              placeholder="请输入手机号"
              required={true}
              value={this.state.idens}
              onChange={this.handleChange.bind(this, 'idens')}
              prefix={<UserOutlined />}
            />
            {process.env.REACT_APP_SMS_HIDE == 'true' ? (
              ''
            ) : (
              <Input
                onClick={this.retrievalCheckPhone.bind(this)}
                type="button"
                className="btn btn-primary send-phone-code retrieval"
                value={this.state.sendRetrievalVcodeTip}
                disabled={this.state.sendRetrievalBtnDisabled}
              />
            )}
            {process.env.REACT_APP_SMS_HIDE == 'true' ? (
              ''
            ) : (
              <Input
                className="register-form phone-vcode"
                placeholder="请输入短信验证码"
                maxLength={6}
                onChange={this.handleChange.bind(this, 'retrievalVcode')}
                prefix={<MobileOutlined />}
              />
            )}
            <Input
              className="register-form password"
              placeholder="请输入新密码，6-20位"
              maxLength={20}
              prefix={<LockOutlined />}
              onChange={this.handleChange.bind(this, 'password')}
              type={'password'}
            />
            <Input
              className="register-form password-confirm"
              placeholder="请再输入一遍新密码"
              prefix={<LockOutlined />}
              onChange={this.handleChange.bind(this, 'passwordConfirm')}
              type={'password'}
            />
            <div
              onClick={this.handleGoRetrieval.bind(this)}
              className="button btn-retrieval"
            >
              重 &nbsp;&nbsp;置
            </div>
            <div
              style={{ marginTop: 10 }}
              className="cue1"
              onClick={this.handleGoLogin.bind(this)}
            >
              返回登录 &gt;
            </div>
          </List>
        </div>
        <CommonModal
          width={460}
          modalContent={
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '20px',
              }}
            >
              <div>请联系所在班级教师或院校管理员重置密码</div>
              <div>
                院校管理员：
                <span className={GlobalCss.hlText}>
                  {this.state.manager_idens}
                </span>
              </div>
            </div>
          }
          modalTitle={'提示'}
          open={this.state.forgetPasswordModalVis}
          onOk={() => this.setState({ forgetPasswordModalVis: false })}
          onCancel={() => this.setState({ forgetPasswordModalVis: false })}
        />
      </div>
    )
  }

  // 去注册
  handleGoRegister() {
    this.setState({ displayMode: 2, errorMessage: '' })
  }

  // 返回登录
  handleGoLogin() {
    this.setState({ displayMode: 1, errorMessage: '' })
  }

  forgetPasswordClick() {
    let url = 'account/api/user_get_college_manager_idens'
    sendHttpPostRequest(url, {}, (res) => {
      this.setState({
        forgetPasswordModalVis: true,
        manager_idens: res.data.manager_idens,
      })
    })
  }

  // 密码找回
  handleGoPasswordRetrieve() {
    if (process.env.REACT_APP_SMS_HIDE == 'true') {
      this.forgetPasswordClick()
    } else {
      this.setState({ displayMode: 3, errorMessage: '' })
    }
  }

  // 绑定表单值
  handleChange(key, event) {
    this.setState({
      [key]: event.target.value,
    })
  }

  // 登录
  handleLogin() {
    doLogin(this)
  }

  // 注册
  handleRegister() {
    doRegister(this)
  }

  handleGoRetrieval() {
    doRetrieval(this)
  }

  /**
   * 发送手机验证码回调
   * @param response
   */
  sendPhoneVcodeCallback(response) {
    if (response.code == 0) {
      message.destroy()
      message.success(response.message)
      let times = 60
      this.setState({ sendVcodeTip: times + '秒后重发', sendBtnDisabled: true })
      let _this: any = this
      let time_handle = setInterval(function () {
        times -= 1
        if (times <= 0) {
          _this.setState({ sendVcodeTip: '发送验证码', sendBtnDisabled: false })
          clearInterval(time_handle)
        } else _this.setState({ sendVcodeTip: times + '秒后重发' })
      }, 1000)
    } else {
      this.setState({ errorMessage: response.message })
    }
  }

  /**
   * 登录回调
   * @param response
   */
  loginCallback(response) {
    const location = window.location.href.split('next=')[1]
    if (response.is_ok) {
      setCookie('sessionid', response.token)
      localStorage.setItem(
        'user_info',
        JSON.stringify(response.user_info.user_info)
      )
      location
        ? (window.location.href = location)
        : (window.location.href = '/')
    } else {
      this.setState({ errorMessage: response.message })
    }
  }

  retrievalCallback(response) {
    message.destroy()
    if (response.is_ok) {
      message.success('密码重置成功，请使用新密码登录')
    } else {
      message.error('密码重置失败，请稍候再试')
    }
  }

  /**
   * 检查手机号
   */
  checkPhone() {
    this.setState({ errorMessage: '' })
    const phone = this.state.idens
    const is_mobile =
      /^(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[0-9]{1})|(18[0-9]{1})|(19[0-9]{1}))+\d{8})$/

    if (phone == '') {
      this.setState({ errorMessage: '请输入手机号' })
    } else if (!phone.match(is_mobile)) {
      this.setState({ errorMessage: '请输入正确的手机号' })
    } else {
      try {
        sendHttpPostRequest(
          'account/api/send_sms/',
          { phone: phone, type: 1 },
          this.sendPhoneVcodeCallback.bind(this)
        )
      } catch (e) {
        console.error(e)
      }
    }
  }

  /**
   * 找回密码发送手机验证码回调
   * @param response
   */
  sendRetrievalPhoneVcodeCallback(response) {
    if (response.code == 0) {
      message.destroy()
      message.success(response.message)
      let times = 60
      this.setState({
        sendRetrievalVcodeTip: times + '秒后重发',
        sendRetrievalBtnDisabled: true,
      })
      let _this: any = this
      let time_handle = setInterval(function () {
        times -= 1
        if (times <= 0) {
          _this.setState({
            sendRetrievalVcodeTip: '发送验证码',
            sendRetrievalBtnDisabled: false,
          })
          clearInterval(time_handle)
        } else _this.setState({ sendRetrievalVcodeTip: times + '秒后重发' })
      }, 1000)
    } else {
      this.setState({ errorMessage: response.message })
    }
  }

  /**
   * 找回密码检查手机号
   */
  retrievalCheckPhone() {
    this.setState({ errorMessage: '' })
    const phone = this.state.idens
    const is_mobile =
      /^(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[0-9]{1})|(18[0-9]{1})|(19[0-9]{1}))+\d{8})$/

    if (phone == '') {
      this.setState({ errorMessage: '请输入手机号' })
    } else if (!phone.match(is_mobile)) {
      this.setState({ errorMessage: '请输入正确的手机号' })
    } else {
      try {
        sendHttpPostRequest(
          'account/api/send_sms/',
          { phone: phone, type: 2 },
          this.sendRetrievalPhoneVcodeCallback.bind(this)
        )
      } catch (e) {
        console.error(e)
      }
    }
  }
}

export default LoginRegister

/**
 * 调后端登录接口
 */
export function doLogin(_this: any) {
  const phone = _this.state.idens
  const password = _this.state.password
  const is_mobile =
    /^(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[0-9]{1})|(18[0-9]{1})|(19[0-9]{1}))+\d{8})$/
  if (!phone) {
    _this.setState({ errorMessage: '请输入手机号' })
    return false
  } else if (!phone.match(is_mobile)) {
    _this.setState({ errorMessage: '请输入正确的手机号' })
    return false
  } else if (!password) {
    _this.setState({ errorMessage: '请输入密码' })
    return false
  } else if (password.length < 6 || password.length > 20) {
    _this.setState({ errorMessage: '密码长度应为6-20位' })
    return false
  }

  _this.setState({ errorMessage: '' })
  try {
    sendHttpPostRequest(
      'account/api/login/',
      { idens: phone, password: password },
      _this.loginCallback.bind(_this)
    )
  } catch (e) {
    console.error(e)
  }
}

/**
 * 设置cookie
 * @param key
 * @param value
 */
export function setCookie(key, value) {
  const date: any = new Date()
  date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000)
  const expires = 'expires=' + date.toGMTString()
  document.cookie = key + '=' + value + '; ' + expires
}

/**
 * 删除cookie
 * @param key
 */
export function delCookie(key) {
  const date: any = new Date()
  date.setTime(date.getTime() - 10000)
  const expires = '=; expires=; ' + date.toGMTString()
  document.cookie = key + expires
}

/**
 * 获取cookie
 * @param key
 */
export function getCookie(key) {
  let arr,
    reg = new RegExp('(^| )' + key + '=([^;]*)(;|$)')
  if ((arr = document.cookie.match(reg))) return unescape(arr[2])
  else return null
}

/**
 * 注册
 * @param _this
 */
export function doRegister(_this: any) {
  const user_name = _this.state.idens
  const phone = _this.state.idens
  const password = _this.state.password
  const phone_vcode = _this.state.vcode
  const password_confirm = _this.state.passwordConfirm
  const inviter = _this.state.inviter
  const is_mobile =
    /^(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[0-9]{1})|(18[0-9]{1})|(19[0-9]{1}))+\d{8})$/
  if (!phone) {
    _this.setState({ errorMessage: '请输入手机号' })
    // document.getElementById('error-message').innerHTML = "请输入手机号";
    return false
  } else if (!phone.match(is_mobile)) {
    _this.setState({ errorMessage: '请输入正确的手机号' })
    // document.getElementById('error-message').innerHTML = "请输入正确的手机号";
    return false
  } else if (!phone_vcode && process.env.REACT_APP_SMS_HIDE == 'false') {
    _this.setState({ errorMessage: '请输入手机验证码' })
    // document.getElementById('error-message').innerHTML = "请输入手机验证码";
    return false
  } else if (!user_name) {
    _this.setState({ errorMessage: '请输入姓名' })
    // document.getElementById('error-message').innerHTML = "请输入姓名";
    return false
    // $(".error-message").html(error_message);
  } else if (!password) {
    _this.setState({ errorMessage: '请输入密码' })
    // document.getElementById('error-message').innerHTML = "请输入密码";
    return false
  } else if (password.length < 6 || password.length > 20) {
    _this.setState({ errorMessage: '密码长度应为6-20位' })
    // document.getElementById('error-message').innerHTML = "密码长度应为6-20位";
    return false
  } else if (!password_confirm) {
    _this.setState({ errorMessage: '请输入密码确认' })
    // document.getElementById('error-message').innerHTML = "请输入密码确认";
    return false
  } else if (password != password_confirm) {
    _this.setState({ errorMessage: '两次输入的密码不一致' })
    // document.getElementById('error-message').innerHTML = "两次输入的密码不一致";
    return false
  }
  if (inviter) {
    if (!inviter.match(is_mobile)) {
      _this.setState({ errorMessage: '请输入正确的邀请人手机号' })
      // document.getElementById('error-message').innerHTML = "请输入正确的邀请人手机号"
      return false
    } else if (inviter.trim() == phone.trim()) {
      _this.setState({ errorMessage: '亲，您不能邀请自己~' })
      // document.getElementById('error-message').innerHTML = "亲，您不能邀请自己"
      return false
    }
  }
  _this.setState({ errorMessage: ' ' })
  let data = {
    user_name: user_name,
    idens: phone,
    password: password,
    phone_vcode:
      process.env.REACT_APP_SMS_HIDE == 'true' ? '123456' : phone_vcode,
    password_confirm: password_confirm,
    inviter: inviter,
    channel: 2,
  }
  if (process.env.REACT_APP_SMS_HIDE == 'true') {
    Object.assign(data, { mode: 2 })
  }

  try {
    sendHttpPostRequest(
      'account/api/register/',
      data,
      _this.loginCallback.bind(_this)
    )
  } catch (e) {
    console.error(e)
  }
}

/**
 * 找回密码
 * @param _this
 */
function doRetrieval(_this: any) {
  const phone = _this.state.idens
  const password = _this.state.password
  const password_confirm = _this.state.passwordConfirm
  const phone_vcode = _this.state.retrievalVcode
  const is_mobile =
    /^(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[0-9]{1})|(18[0-9]{1})|(19[0-9]{1}))+\d{8})$/

  if (!phone) {
    _this.setState({ errorMessage: '请输入手机号' })
    return false
  } else if (!phone.match(is_mobile)) {
    _this.setState({ errorMessage: '请输入正确的手机号' })
    return false
  } else if (!phone_vcode && process.env.REACT_APP_SMS_HIDE == 'false') {
    _this.setState({ errorMessage: '请输入手机验证码' })
    return false
  } else if (!password) {
    _this.setState({ errorMessage: '请输入新密码' })
    return false
  } else if (password.length < 6 || password.length > 20) {
    _this.setState({ errorMessage: '密码长度应为6-20位' })
    return false
  } else if (!password_confirm) {
    _this.setState({ errorMessage: '请输入新密码确认' })
    return false
  } else if (password != password_confirm) {
    _this.setState({ errorMessage: '两次输入的密码不一致' })
    return false
  }

  _this.setState({ errorMessage: ' ' })
  let data = {
    idens: phone,
    password: password,
    phone_vcode:
      process.env.REACT_APP_SMS_HIDE == 'true' ? '123456' : phone_vcode,
    password_confirm: password_confirm,
  }

  if (process.env.REACT_APP_SMS_HIDE == 'true') {
    Object.assign(data, { mode: 2 })
  }

  try {
    sendHttpPostRequest(
      'account/api/password_retrieval/',
      data,
      _this.retrievalCallback.bind(_this)
    )
  } catch (e) {
    console.error(e)
  }
}
