import React, {useEffect, useState} from "react";
import {Badge,Input,Table,Switch,Layout, message, Card, Col, Row, ConfigProvider, Pagination, Tabs, Button, Tag, DatePicker, Upload } from 'antd';
import { PageHeader } from "../common/page-header/header";
import { sendHttpPostRequest } from "../../api/http-request";
import logoSvg from '../../static/image/idclogo.png';
import pekinglogoSvg from '../../static/image/peking.png';
import courseMainStyles from "./course-main.module.less";
import courseBasicInfoStyle from "./course-edit-basicinfo.module.less";
import MenuEdit from "./menu/course-main-teacher-menu-edit";
import "./course.module.css";
import zhCN from 'antd/lib/locale/zh_CN'
import * as constants from '../../constants/constants';
import PageFooter from "../common/page-footer/footer";
import LoginRegisterModal from "../../component/modal/login-register/login-register";
import {checkUserLogin} from "../common/user-center/user-info";
import {UploadOutlined,PlayCircleOutlined,ClockCircleOutlined,EditOutlined,VideoCameraOutlined,CodeOutlined,FormOutlined,UserOutlined } from '@ant-design/icons';
import CourseMainTeacherMenu, { MenuPreview } from "./menu/course-main-teacher-menu";
import CourseMainTeacherSlides from "./slides/course-main-teacher-slides";
import CourseMainTeacherSetting from "./setting/course-main-teacher-setting";
import CourseMainTeacherStudent from "./student-list/course-main-teacher-student";
import  { CourseMainTeacherTestList } from "./test/course-main-teacher-test-list";
import CourseMainTeacherExamList from "./exam/course-main-teacher-exam-list";
import CourseMainTeacherCases from "./case/course-main-teacher-cases";
import CourseMainTeacherStats from "./statistics/course-main-teacher-stats";import moment from 'moment';
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

const {TextArea} = Input 
const {TabPane} = Tabs
const { Search } = Input

import Feedback from "../common/feedback-help/feedback";
import Cookies from "js-cookie";

export interface Props {}

/** 教师课程编辑主页面 */
export const CourseMainTeacherEdit: React.FC<Props> = (props) => {
    
    const courseId = parseInt(window.location.search.split("=")[1]);  // 课程ID
    const [course, setCourse] = useState(null as any);//课程信息
    const [courseName, setCourseName] = useState(""); //课程名称
    const [courseDescription, setCourseDescription] = useState(""); //课程介绍
    const [courseCover, setCourseCover] = useState(""); //课程封面路径

    const [startTime, setStartTime] = useState(null as any);//课程开始时间
    const [endTime, setEndTime] = useState(null as any);//课程结束时间
 

    useEffect(()=> {
        queryCourse();
      },[])
  
  
  
      // 查询课程信息
      const queryCourse = () => {
          const sessionid = Cookies.get("sessionid");
          const queryData = {
          
              course_id: courseId,
              sessionid: sessionid,
              sid: sessionid,
          };
          sendHttpPostRequest(
              'course/api/user_query_course_by_id',
              queryData,
              courseCallback
          )
      }
  
      // 查询课程接口回调
      const courseCallback = (response: any) => {
          // console.log(response);
          message.destroy();
          if (response.code == -100) {
              // 未登录
              window.location.href = '/login?next=/course';
          } else if (response.is_ok == true) {
              // 成功
            setCourse(response.query_info);
            setCourseName(response.query_info.name);
            setCourseDescription(response.query_info.description);
            //setMenuId(response.query_info.syllabus_id);
            setStartTime(response.query_info.start_time);
            setEndTime(response.query_info.end_time);
            //setLectureList(response.query_info.lecture_list);
            //setCaseList(response.query_info.case);
            setCourseCover(response.query_info.cover);

  
              //setStartTime(response.query_info.start_time);
              //setEndTime(response.query_info.end_time);
              //setOnlineCount(response.online_count);
          } else if (response.code == -501) {
              // 权限不足
              window.location.href = '/login?next=course';
          } else {
              message.error(response.message);
          }
      }
  
  
      /**
       * 保存课程回调
       * @param response
       */
      const saveCourseInfoCallback = (response:any) => {
          message.destroy();
          if (response.code == -100) {
              // 没登录
              window.location.href = '/login?next=course';
          } else if (response.code == -501) {
              // 没权限
              window.location.href = '/login?next=course';
          } else if (response.is_ok == true) {
              message.success("课程信息保存成功~")
          }else {
              message.error(response.message);
          }
      }
  
      /**
       * 点击保存课程按钮
       */
      const saveCourse = () => {
          sendHttpPostRequest(
              'course/api/user_update_course',
              {
                  course_id:courseId,
                  name: courseName,
                  description: courseDescription,
                  cover: courseCover,
                  //menu_id: menuId,
                  start_time: startTime,
                  end_time: endTime,
                  sessionid: Cookies.get("sessionid"),
                  sid: Cookies.get("sessionid"),
              },
              saveCourseInfoCallback
          )
      }


    const onCourseLearnClick = () => {
        if (!checkUserLogin()) {
            document.getElementById("show-login-modal")!.click();
        }
        if (course.recent_knowledge_url != "") {
            window.open(course.recent_knowledge_url);
        } else if (course.first_knowledge_url != "") {
            window.open(course.first_knowledge_url);
        } else {
            message.destroy();
            message.error("课程暂时无法学习，请稍候...");
        }
    }

    const onCourseEditClick = () => {
        saveCourse();
    }

    const disabledDate = (current:any) => {
        return current && current < moment().startOf('day');
    }
  
    const onDateRangeChange = (dates,dateStrings) => {
        setStartTime(dateStrings[0]);
        setEndTime(dateStrings[1]);  
        
        console.log(endTime);
    }

    const onCourseNameChange = (e:any) => {
        setCourseName(e.target.value);
        //saveCourse();
    }

    const onCourseIntroductionChange = (e:any) => {
        setCourseDescription(e.target.value);
    }


      /**
     * 保存课程回调
     * @param response
     */
       const saveCourseParamsInfoCallback = (response:any) => {
        message.destroy();
        if (response.code == -100) {
            // 没登录
            window.location.href = '/login?next=course';
        } else if (response.code == -501) {
            // 没权限
            window.location.href = '/login?next=course';
        } else if (response.is_ok == true) {
            message.success("课程信息保存成功~")
        }else {
            message.error(response.message);
        }
    }

    const saveCourseParams = (params: any) => {
        sendHttpPostRequest(
            'course/api/user_update_course',
            params,
            saveCourseParamsInfoCallback
        )
    }



    // 上传封面传参
    const uploadData = {
        sessionid: Cookies.get("sessionid"),
        sid: Cookies.get("sessionid"),
        course_id: courseId,
      }
  
  
      // 上传图片
      const uploadImageProps = {
        accept: '.png,.jpg,jpeg,.gif',
        name: 'data_file',
        multiple: false,
        showUploadList: false,
        data: uploadData,
        action: constants.BACKEND_API_URL + 'course/api/course_upload_cover',
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                if (info.file.response.is_ok) {
                    message.success(`图片${info.file.name}文件上传成功！`);
                    setCourseCover(info.file.response.url);
                    const params = {
                      sessionid: Cookies.get("sessionid"),
                      course_id: courseId,
                      sid: Cookies.get("sessionid"),
                      cover: info.file.response.url,
                     
                  }
                    saveCourseParams(params);
                } else {
                    if (info.file.response.code == -100) {
                        // 未登录
                        const localPath = window.location.href.split(window.location.host)[1];
                        window.location.href = `/oam_login?next=${localPath}`;
                    } else if (info.file.response.code == -501) {
                        // 权限不足
                        const localPath = window.location.href.split(window.location.host)[1];
                        window.location.href = `/oam_login?next=${localPath}`;
                    } else {
                        message.error(info.file.response.message);
                    }
                }
  
            } else if (status === 'error') {
                message.error(`图片${info.file.name}文件上传失败了:(`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };


    return (
        <Layout style={{minHeight: 750,backgroundColor: '#f8f8f8'}} className={courseMainStyles.courseEdit}>
            
            <LoginRegisterModal></LoginRegisterModal>
            
            <div className={courseMainStyles.courseHeaderWrapper}>
                    <Row className={courseMainStyles.courseHeader} style={{marginRight:"auto",marginLeft:"auto"}} align='middle' gutter={[12,0]}>
                        <Col flex='300px' style={{margin:0,padding:0}}>
                            {course?.is_course_teacher &&
                            <Upload className={courseBasicInfoStyle.uploadImage} {...uploadImageProps}>
                                {courseCover ?
                                    <div>
                                        <img
                                            id="course-cover"
                                            className={courseBasicInfoStyle.courseImg}
                                            width={300}
                                            height={180}
                                            style={{borderRadius: 10}}
                                            src={courseCover?.startsWith("http") ? courseCover : constants.BACKEND_URL + courseCover}
                                        />
                                        <div className={courseBasicInfoStyle.editImageText}>
                                            <EditOutlined style={{ marginRight: 5}}/>修改课程封面
                                            <div style={{backgroundColor:'transparent',marginTop:6,color:'#ccc',fontSize:12,padding:'0 10px'}}>（ 提示：请上传格式为png、jpg、jpeg、gif、svg的图片，大小不得超过10M。）</div>   
                                        </div>
                                    </div> :
                                    <div style={{
                                        paddingTop: 75,
                                        borderRadius: 10,
                                        textAlign: "center",
                                        width: 300,
                                        height: 180,
                                        border: "1px solid #00000073"
                                    }}>
                                        <div className={courseBasicInfoStyle.uploadImageText}>
                                            <UploadOutlined style={{color: '#07c160', marginRight: 5}}/>上传课程封面
                                            <div style={{marginTop:6,color:'#666',fontSize:12,padding:'0 10px'}}>（ 提示：请上传格式为png、jpg、jpeg、gif、svg的图片，大小不得超过10M。）</div>   
                                        </div>
                                    </div>
                                }
                            </Upload>
                            }
                        </Col>

                        <Col style={{height:180,textAlign:'left',overflow:'hidden',margin:0,marginRight:20,paddingLeft:40}} flex='450px'>
                            <Input onChange={onCourseNameChange} placeholder="请输入课程名称"   value={courseName} style={{marginBottom:10}}/>
                            <TextArea  showCount maxLength={500} placeholder="请输入课程介绍" 
                                style={{borderRadius:3,width:"100%",borderColor:'#d9d9d9'}} 
                                autoSize={{minRows: 3, maxRows: 3}} 
                                value={courseDescription} 
                                onChange={(e)=>{setCourseDescription(e.target.value);}}
                                />
                            
                           {/*  <Row align='middle' style={{marginBottom:10}} gutter={[4,0]}>
                                <Col><span style={{color:'#52c8b9',fontSize:16}}><VideoCameraOutlined /></span></Col>
                                <Col><div style={{color:'#333333',fontSize:12}}>  视频时长： 20时30分</div></Col>
                           
                            </Row>
                            */}
                           
                            <Row align='middle' style={{marginTop:30,width:'100%'}} gutter={[4,0]}>
                                <Col flex="30px"><span style={{color:'#52c8b9',fontSize:16}}><ClockCircleOutlined /></span></Col>
                                <Col flex="auto">
                                    <ConfigProvider locale={zhCN}>
                                    <div style={{color:'#333333',fontSize:12}}>  
                                        开课时间：{ startTime && endTime && <RangePicker onChange={onDateRangeChange} size="small" style={{marginLeft:10}} format={dateFormat} disabledDate={disabledDate} defaultValue={[moment(startTime,dateFormat),moment(endTime,dateFormat)]}/> }
                                    </div>
                                    </ConfigProvider>
                                </Col>
                        
                            </Row>
                            
                        </Col>

                        <Col style={{margin:0}} flex='auto'>
                        <div><Button type='default' style={{borderRadius:20,width:100,color:'#07c160',borderColor:'#07c160'}} onClick={()=>{window.location.href = `/course-main-teacher?course_id=${courseId}`}}> 返回 </Button></div>
                            <div>{course?.is_course_teacher && <Button type='primary' style={{borderRadius:20,width:100,marginTop:20}} onClick={(e)=>{saveCourse();}}> 保存 </Button>}</div>
                        </Col>
                    </Row>
                    
            </div>
            
            
            <Row align="top">
            <Col flex="auto"> <div style={{borderBottomStyle:"solid",borderBottomColor:"#07c160",borderBottomWidth:1,height:36,backgroundColor:"#fff"}}></div> </Col>
            <Col flex="900px">

            <div className={courseMainStyles.courseMain} style={{minHeight:"calc(100vh - 398px)"}}>


                <Tabs  defaultActiveKey= {"1"} type="card" size="middle" tabPosition="top"> 
                    <TabPane   tab={<div>大纲</div>}   key="1">
                       <div style={{background:"#fff"}}>{ course?.syllabus_id > 0 &&  <MenuEdit menu_id={course?.syllabus_id} menu_obj="课程编辑" is_course_teacher={course?.is_course_teacher}/>}
                       </div>
                    </TabPane>
                    <TabPane tab={<div></div>} disabled={true} key="2">
                    </TabPane>
                    <TabPane tab={<div></div>} disabled={true} key="3">
                    </TabPane>
                    <TabPane tab={<div></div>} disabled={true} key="4">
                    </TabPane>
                    <TabPane tab={<div></div>} disabled={true} key="5">
                    </TabPane>
                    <TabPane tab={<div></div>} disabled={true} key="6">
                    </TabPane>
                    <TabPane tab={<div></div>} disabled={true} key="7">
                    </TabPane>
                    <TabPane tab={<div></div>} disabled={true} key="8">
                    </TabPane>  
                </Tabs>  
                 
            </div>
            </Col>
            <Col flex="auto"><div style={{borderBottomStyle:"solid",borderBottomColor:"#07c160",borderBottomWidth:1,height:36,backgroundColor:"#fff"}}></div></Col>
            </Row>
        </Layout>

    )

}

export default CourseMainTeacherEdit

