import { Col, Layout, Row } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import courseLogo from '../../static/image/courseLogo.png';
import codingLogo from '../../static/image/codingLogo.png';
import collegeStyles from './college.module.less';

const { Footer } = Layout
interface Props {}

export const PageFooter: React.FC<Props> = (props: Props) => {
    return <Footer style={{ backgroundColor:'#f2f2f2', width:"100%",color:'#acacac',textAlign: 'center' ,bottom:0}}>
        {/* <Footer style={{backgroundColor:'#f2f2f2',height:80,marginTop:20}}> */}
                <Row justify="center" align="middle" wrap={true}>
                    <Col>
                        <Link to={"/"}>
                            <img
                                title="爱数课"
                                alt="logo"
                                src={courseLogo}
                                width={120}
                                height={30}
                                className={collegeStyles.logo}
                            />
                            <span style={{color:'#666',marginLeft:20,cursor:'default'}}>http://idatacourse.cn/</span>
                        </Link>
                    </Col>
                    <Col flex="100px"></Col>
                    <Col>
                        <div onClick={()=>{window.open('http://idatacoding.cn/')}}>
                            <img
                                title="数据实验楼"
                                alt="logo"
                                src={codingLogo}
                                width={120}
                                height={30}
                                className={collegeStyles.logo}
                            />
                            <span style={{color:'#666',marginLeft:20,cursor:'default'}}>http://idatacoding.cn/</span>
                        </div>
                    </Col>
                </Row>
            {/* </Footer> */}
    </Footer>
}

export default PageFooter
