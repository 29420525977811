import React, {useEffect, useRef, useState} from "react";
import {Tooltip,Progress,Tree,Drawer,Layout, message, Card, Col, Row, ConfigProvider, Pagination, Tabs, Input, Upload, Button, Popconfirm, Select, Menu, Table, Divider } from 'antd';
import { PageHeader } from "../common/page-header/header";
import { sendHttpPostRequest } from "../../api/http-request";
import logoSvg from '../../static/image/idclogo.png';
import collegeLogo from '../../static/image/college-oam/collegeLogo.png';
import pekinglogoSvg from '../../static/image/peking.png';
import {SafetyOutlined,DownOutlined,ArrowLeftOutlined,FormOutlined,CodeOutlined,ProfileOutlined,LeftCircleOutlined,RightCircleOutlined} from '@ant-design/icons';
import Cookies from 'js-cookie';
import zhCN from 'antd/lib/locale/zh_CN'
import * as constants from '../../constants/constants';
import PageFooter from "../college/college-footer";
import LoginRegisterModal from "../../component/modal/login-register/login-register";
import UserInfo, {checkUserLogin} from "../common/user-center/user-info";
import { UserOutlined } from '@ant-design/icons';
import { Content, Footer } from "antd/lib/layout/layout";
import Sider from "antd/lib/layout/Sider";
import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';
import {UploadOutlined,FileImageOutlined} from '@ant-design/icons';
import collegeStyles from './college.module.less';


import memberIcon from '../../static/image/college-oam/memberIcon.png';
import courseIcon from '../../static/image/college-oam/courseIcon.png';
import collegeNumberIcon from '../../static/image/college-oam/collegeNumberIcon.png';

import jiaoshimaBg from '../../static/image/college-oam/jiaoshima-bg.png';
import xueshengmaBg from '../../static/image/college-oam/xueshengma-bg.png';

import {CopyToClipboard} from 'react-copy-to-clipboard';

// 通过路由组件额外添加参数信息到表中
import {RouteComponentProps} from "react-router";
import SplitPane from "react-split-pane";
import caseStyles from "./case.module.less";
import ReactDOM from "react-dom";
import { identifier } from "@babel/types";
import { Link } from "react-router-dom";
const { TextArea } = Input;
const { Option } = Select;
const { Header } = Layout;
const { Search } = Input;

import collegeManageStyles from "./college-oam.module.less";
import CollegeCourseList from "./college-course-list";
import CollegeMemberList from "./college-member-list";

interface Props  {
}




export const CollegeOAM: React.FC<Props> = (props) => {
   
    
    const [selectedTabKey, setSelectedTabKey] = useState(["1"] as any);//选中的项目菜单项

    const [isLogin, setIsLogin] = useState(false);//是否登录
    const [isCollegeManager, setIsCollegeManager] = useState(null as any);  // 当前用户是否为当前院校管理员
    const [collegeId, setCollegeId] =  useState(null as any);  // 当前用户是否为当前院校管理员

    const [collegeInfo, setCollegeInfo] =  useState(null as any);  // 当前院校信息

    const [studentCode, setStudentCode] = useState("");//机构学生码
    const [studentCodeTime, setStudentCodeTime] = useState("");//机构学生码有效期

    const [teacherCode, setTeacherCode] = useState("");//机构教师码
    const [teacherCodeTime, setTeacherCodeTime] = useState("");//机构教师码有效期




    useEffect(()=> {
        //首先做权限验证，验证当前用户是否为当前院校管理员
        sendHttpPostRequest(
            'account/api/query_teacher_authenticate',{
                sessionid: Cookies.get("sessionid"),
                sid: Cookies.get("sessionid"),
            },
            (response:any)=>{
                if(response.is_ok == true)
                {
                    setIsLogin(true);
                    if (response?.college_info?.college_id !== '') { //已加入机构
                        let _collegeId = response.college_info.college_id; 
                        setCollegeId(_collegeId);

                        setIsCollegeManager(response.college_info.is_manager == 1 ? true:false);
                        if(response.college_info.is_manager == 1){
                            queryInstitution(_collegeId);
                        }else{
                            window.location.href = "/user-center"
                        }
                        
                    }else{//未加入机构
                        setCollegeId(0);
                        window.location.href = "/user-center"
                      
                    }
                }else {//用户未登录
                    setIsLogin(false);
                    
                }
            }
            )
      

    },[])


    // 查询课程信息
    const queryInstitution = (collegeId: any) => {
        const sessionid = Cookies.get("sessionid");
        const queryData = {
            
            college_id: collegeId,
            sessionid: sessionid,
            sid: sessionid,
        };
        sendHttpPostRequest(
            'college/api/manager_query_college_by_id/',
            queryData,
            institutionCallback
        )
    }


    // 查询机构接口回调
    const institutionCallback = (response: any) => {
        // console.log(response);
        message.destroy();
        if (response.code == -100) {
            // 未登录
            window.location.href = '/login?next=/college-oam';
        } else if (response.is_ok == true) {
            // 成功

            setCollegeInfo(response.college_info);
            setStudentCode(response.college_info.student_code);
            setStudentCodeTime(response.college_info.student_code_time);

    
            setTeacherCode(response.college_info.teacher_code);
            setTeacherCodeTime(response.college_info.teacher_code_time);
           
        } else if (response.code == -501) {
            // 权限不足
            window.location.href = '/login?next=/college-oam';
        } else {
            message.error(response.message);
        }
    }


        // 点了生成教师码
        const generateTeacherCodeCallback = (response: any) => {
            message.destroy();
            if (response.code == -100) {
                // 没登录
                console.log("没登录")
                //window.location.href = '/oam_login?next=oam_institution_list';
            } else if (response.code == -501) {
                // 没权限
                console.log("没权限")
                //window.location.href = '/oam_login?next=oam_institution_list';
            } else if (response.is_ok == true) {
                setTeacherCode(response?.teacher_code);
                setTeacherCodeTime(response?.teacher_code_time);
            } else {
                message.error(response.message);
            }
        }

        // 点了生成学生码
        const generateStudentCodeCallback = (response: any) => {
        message.destroy();
        if (response.code == -100) {
            // 没登录
            console.log("没登录")
            //window.location.href = '/oam_login?next=oam_institution_list';
        } else if (response.code == -501) {
            // 没权限
            console.log("没权限")
            //window.location.href = '/oam_login?next=oam_institution_list';
        } else if (response.is_ok == true) {
            setStudentCode(response?.student_code);
            setStudentCodeTime(response?.student_code_time);
        } else {
            message.error(response.message);
        }
    }
    
        // 点了生成教师码和学生码按钮
        const generateCode = (codeType: number) => {
                sendHttpPostRequest(
                    'college/api/manager_update_college_code/',
                    {
                        sessionid: Cookies.get("sessionid"),
                        college_id: collegeId,
                        code_type: codeType
                    },
                    codeType == 2? generateTeacherCodeCallback :generateStudentCodeCallback
                )     
        }


    const handleClick = (e: any) => {
        setSelectedTabKey(e.key);
    }

    const renderActions = (text:any, record:any) => {
        return <Button type="primary" style={{borderRadius:20,height:28,padding:'4px 10px'}}>移出</Button>
    }


    interface Props2 {}


    const ProjectCollegeNumber: React.FC<Props2> = (props) => {
        return <div style={{margin:20}}> 
                 <div className={collegeManageStyles.collegeNumber}>
                    <div id="teacher_code"  style={{backgroundImage:`url(${jiaoshimaBg})`,backgroundSize:'100% 100%',marginBottom:20,padding:20}}  className={collegeManageStyles.teacherCodeDiv}>
                            <Divider orientation="left" style={{color:"#666",fontSize:16,fontWeight:600}}>教师码</Divider>
                         
                            <Row gutter={[8,8]} align='middle' justify="center" style={{marginTop:40}}>
                                <Col span={14} style={{textAlign:'right'}}>
                                    <Input value={teacherCode} style={{width:250}}></Input> 
                                    <CopyToClipboard text={"院校:" + collegeInfo.name+ "，教师码：" + teacherCode + "，请前往个人中心认证身份：http://idatacourse.cn/user-center"} 
                                        onCopy={() => {message.success("教师码已复制~")}}>
                                        <Button size="small" style={{marginLeft:10,width:60,height:32,color:"#fff"}}>复制</Button>
                                    </CopyToClipboard>
                                </Col>

                                <Col span={10} style={{textAlign:'left'}}>
                                    <Popconfirm placement="top" title={'教师码更新后，原有教师码将失效，确认吗？'} onConfirm={()=>{generateCode(2)}} okText="确认" cancelText="取消">
                                        <Button size="small" style={{width:80,height:32,color:"#fff"}} > {teacherCode == "" ? '生 成' : '重新生成'} </Button>
                                    </Popconfirm>
                                </Col>
                                </Row>

                                
                                <div  style={{color:"#00000073",textAlign:'center',marginBottom:10,marginTop:40}}>
                                    教师码使用超过一个月后，建议重新生成
                                </div>
                                <div  style={{color:"#00000073",textAlign:'center',marginBottom:20}}>
                                    上次生成时间:{teacherCodeTime}
                                </div>
                               
                        </div>

                        <div id="student_code"  style={{backgroundImage:`url(${xueshengmaBg})`,backgroundSize:'100% 100%',marginTop:30,marginBottom:20,padding:20}}  className={collegeManageStyles.teacherCodeDiv}>
                            <Divider orientation="left" style={{color:"#666",fontSize:16,fontWeight:600}}>学生码</Divider>
                         
                            <Row gutter={[10,10]} align='middle' justify="center" style={{marginTop:30}}>
                                <Col span={14} style={{textAlign:'right'}}>
                                    <Input value={studentCode} style={{width:250}}></Input> 
                                    <CopyToClipboard text={"院校:" + collegeInfo.name+ "，学生码：" + studentCode + "，请前往个人中心认证身份：http://idatacourse.cn/user-center"} 
                                        onCopy={() => {message.success("学生码已复制~")}}>
                                        <Button size="small" style={{marginLeft:10,width:60,height:32,color:"#fff"}}>复制</Button>
                                    </CopyToClipboard>
                                </Col>


                                <Col span={10} style={{textAlign:'left'}}>
                                    <Popconfirm placement="top" title={'学生码更新后，原有学生码将失效，确认吗？'} onConfirm={()=>{generateCode(1)}} okText="确认" cancelText="取消">
                                        <Button size="small" style={{marginLeft:0,width:80,height:32,color:"#fff"}} > {studentCode == "" ? '生 成' : '重新生成'} </Button>
                                    </Popconfirm>
                                </Col>
                                </Row>

                                
                                <div  style={{color:"#00000073",textAlign:'center',marginBottom:10,marginTop:40}}>
                                    学生码使用超过一个月后，建议重新生成
                                </div>
                                <div  style={{color:"#00000073",textAlign:'center',marginBottom:20}}>
                                    上次生成时间:{studentCodeTime}
                                </div>


                               
                        </div>


                        <div style={{color:"#00000073",textAlign:'left',marginBottom:10,marginTop:40}}>
                            <div style={{fontSize:16,marginBottom:10,fontWeight:600}}> 院校码使用说明 </div>
                            <ul>
                                <li>院校码用于认证平台用户的院校身份，可前往个人中心输入院校码认证身份。</li>
                                <li>院校码分为教师码和学生码两种。教师通过教师码获得本院校教师权限。学生通过学生码获得本院校学生权限。</li>
                                <li>请注意不要将教师码发送给学生，以免学生获得教师权限。</li>
                            </ul>
                        </div>

                 </div>
            </div>
    }

    const getProjectContent = () => {
        if (selectedTabKey == "2") {
            return <CollegeCourseList college_id={collegeId}/>
        }else if (selectedTabKey == "3") {
            return <ProjectCollegeNumber />
        }
        else {
            return  <CollegeMemberList college_id={collegeId}/> 
        }
    }
    

    return (
        <Layout style={{backgroundColor: '#f2f2f2'}}>
             <Row wrap={false} style={{backgroundColor:"#f2f2f2"}} className={collegeManageStyles.projectMain}>
                <Col flex="250px" style={{margin:0,minHeight: "calc(110vh - 60px)"}}>
                    <div className={collegeManageStyles.projectMenuWrapper}>
                        <Menu style={{marginTop:20}} theme="dark" selectedKeys={selectedTabKey} mode="inline" onClick={handleClick}>
                            
                           <div onClick={()=>{window.open("/")}} style={{cursor:"pointer", backgroundColor:"#fff", padding:"10px 10px",borderBottom:"1px solid #2572ae"}}>
                               <img height="40px" width="200px" src={constants.BACKEND_API_URL + collegeInfo?.logo_url} />
                           </div> 
                            
                            <Menu.Item key="1">
                            <img src={memberIcon} alt=""  className={collegeManageStyles.icon}/>师生管理
                            </Menu.Item>

                            <Menu.Item key="2">
                            <img src={courseIcon} alt=""  className={collegeManageStyles.icon}/>课程管理
                            </Menu.Item>

                            <Menu.Item key="3">
                            <img src={collegeNumberIcon} alt="" style={{width:14,height:16,marginLeft:2}} className={collegeManageStyles.icon}/>院校码
                            </Menu.Item>
                        </Menu>
                    </div>
                </Col>

                <Col flex="auto" style={{minWidth:500,width:"calc(100vw - 250px)",color:"#f2f2f2",backgroundColor:"#f2f2f2",minHeight:"calc(100vh - 60px)",position:'relative'}} >
                    <div style={{height:60,lineHeight:'60px',backgroundColor:'#fff',color:'#333',textAlign:'left',paddingLeft:16}}>
                        <Row wrap={false}>
                        <Col span={16} style={{fontWeight:700,fontSize:18}}>数据科学和人工智能专业教学平台</Col>
                        <Col span={8} style={{textAlign:"right",paddingRight:20}}> <UserInfo /></Col>
                        </Row>
                        
                        
                    </div>
                    { collegeId && <div>
                    {getProjectContent()}

                    </div> }
              
                </Col>
              
            </Row>
        </Layout>

    )

}

export default CollegeOAM

