import {message,Col, Input, Layout, Row, Tooltip, Upload, Button } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Content } from 'antd/lib/layout/layout';
import { RouteComponentProps } from 'react-router';

import React, { useState } from "react";

const { Dragger } = Upload;

interface  Props extends  RouteComponentProps {
  
}

import myStyles from "./oam-video.module.less"
import {PlusOutlined,CheckCircleOutlined,InboxOutlined} from '@ant-design/icons';  

import { test_video_list } from './oam-video-list';

import OAMSider from '../oam-sider';
import Cookies from 'js-cookie';

/** 视频发布成功页 */
export const OAMVideoUploadSuccess: React.FC<Props> = (props) => {
    //const video_id = props.location.search.split("=")[1]
    //const currentVideo = test_video_list.filter((item)=> item.id == video_id)[0]
    const id = 1 //视频ID

    //点击上传按钮
    const onUploadBtnClick = (event:any) => {
      //message.success('编辑数据' + event.currentTarget.value)
      event.preventDefault()
      window.open('/oam_video_upload')
    }

    //点击去我视频
    const onVideoListBtnClick = (event:any) => {
      //message.success('编辑数据' + event.currentTarget.value)
      event.preventDefault()
      window.open('/oam_video_list')
    }

    return (
        <Layout style={{ minHeight: '100vh' }}>
        <OAMSider keys={"34"} openKeys={["sub-course"]}></OAMSider>
        <div>
            <Content style={{ minHeight: '90vh',backgroundColor:'#fff',borderRadius:5,width:1000,margin:30 ,paddingTop:40}}>
            <div style={{textAlign:"center",paddingLeft:80,paddingRight:80,paddingTop:200}}>
              <p >
                <CheckCircleOutlined  style={{fontSize:48,color:"#07c160",marginBottom:10}}/>
              </p>
              <div style={{fontSize:18,marginBottom:10}} >发布成功</div>
              <div style={{fontSize:12,color:"#00000073",marginBottom:20}}>
               审核通过后将会在个人中心我的视频显示
              </div>
              <div><Button  onClick={onUploadBtnClick} style={{borderRadius:20,width:100,marginRight:20}} >继续上传</Button>
                  <Button onClick={onVideoListBtnClick} style={{borderRadius:20,width:100}} >去我的视频</Button></div>

            </div>
  
            </Content>
        </div>
    </Layout>
    )

}

export default OAMVideoUploadSuccess

