import {
  Button,
  Card,
  Col,
  ConfigProvider,
  Layout,
  Pagination,
  Row,
  Tabs,
  message,
  Input,
  Modal,
  Select,
  Upload,
  Popconfirm,
} from 'antd'
import { EyeOutlined, ForkOutlined, SearchOutlined } from '@ant-design/icons'
import { withRouter } from 'react-router-dom'
import { PageHeader } from '../common/page-header/header'
import React, { useEffect, useRef, useState } from 'react'
import PageFooter from '../common/page-footer/footer'
import caseStyles from './case.module.less'
import zhCN from 'antd/lib/locale/zh_CN'
import { Tag } from 'antd'
import Meta from 'antd/lib/card/Meta'
import { checkUserLogin } from '../common/user-center/user-info'
import { Link } from 'react-router-dom'
import { sendHttpPostRequest } from '../../api/http-request'
import Cookies from 'js-cookie'
import * as constants from '../../constants/constants'
import { PlusOutlined } from '@ant-design/icons'

import Draggable from 'react-draggable'
import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined'

import emptyCase from '../../static/image/empty/queshengye-shiyan.png'

import defaultCaseCover from '../../static/image/case/default_case_cover.png'

import Feedback from '../common/feedback-help/feedback'

const { CheckableTag } = Tag

const { TabPane } = Tabs
const { TextArea } = Input
const { Option } = Select

const domainName2CodeMap = new Map([
  ['金融', 1],
  ['交通', 2],
  ['医疗', 3],
  ['电信', 4],
  ['教育', 5],
  ['社会科学', 6],
  ['植物', 7],
  ['动物', 8],
  ['消费', 9],
  ['天文', 10],
  ['其他', 11],
])

export interface Props {}
export const Case: React.FC<Props> = (props) => {
  const [caseResult, setCaseResult] = useState([] as any[])

  const [totalSum, setTotalSum] = useState(0)
  const tagData = [
    '全部',
    '可视化',
    '预处理',
    '分类',
    '降维',
    '回归',
    'K-Means',
    '支持向量机',
    '热力图',
    'GBDT',
    '随机森林',
  ]

  const [selectedDomainTags, setselectedDomainTags] = useState([
    '全部',
  ] as string[])
  const [selectedCaseTags, setselectedCaseTags] = useState(['全部'] as string[])
  const [domains, setDomains] = useState([] as string[]) //有全部的行业选择
  const [domains1, setDomains1] = useState([] as string[]) //无全部的行业选择
  const queryContent = useRef('') // 记录搜索串
  const queryDomainTags = useRef('' as any)
  const page = useRef(1)
  const pageSize = useRef(12)
  const ismy = useRef('2') // 2 公开  1 我的

  const [caseName, setCaseName] = useState('')
  const [caseDescription, setCaseDescription] = useState('')
  const [caseDomain, setCaseDomain] = useState('')
  const [isOk, setIsOk] = useState('false')
  const [caseUrl, setCaseUrl] = useState('')
  const [cover, setCover] = useState(null as any) //封面
  const [coverFileList, setCoverfileList] = useState([])

  const [activeKey, setActiveKey] = useState('1')

  /** 以下是创建实验弹出框显示用到的变量和函数 */
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [ipynb, setIpynb] = useState(null as any) //封面
  const [ipynbFileList, setIpynbfileList] = useState([])
  const draggleRef = useRef<HTMLDivElement | null>(null)

  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
  const [disabled, setDisabled] = useState(true)

  const onStart = (event: any, uiData: any) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement
    const targetRect = draggleRef?.current?.getBoundingClientRect()
    if (targetRect) {
      setBounds({
        left: -targetRect?.left + uiData?.x,
        right: clientWidth - (targetRect?.right - uiData?.x),
        top: -targetRect?.top + uiData?.y,
        bottom: clientHeight - (targetRect?.bottom - uiData?.y),
      })
    }
  }

  const handleOk = () => {
    if(!caseName){
      message.error('请输入实验名称')
      return
    }
    //确认创建实验
    createCase()
    setIsModalVisible(false)
  }

  const showModal = () => {
    if (!checkUserLogin()) {
      document.getElementById('show-login-modal')!.click()
    } else {
      setIsModalVisible(true)
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  useEffect(() => {
    queryCaseList()
    queryDoMain()
  }, [])

  /**
   * 查询实验列表
   */
  const queryCaseList = () => {
    sendHttpPostRequest(
      'case/api/query_case_list',
      {
        sessionid: Cookies.get('sessionid'),
        query: queryContent.current,
        page: page.current,
        domain: queryDomainTags.current,
        pagesize: pageSize.current,
        is_my: ismy.current,
      },
      queryCaseListCallback
    )
  }

  /**
   * 查询实验列表回调
   * @param response
   */
  const queryCaseListCallback = (response: any) => {
    // console.log(response);
    message.destroy()
    if (response.code == -100) {
      // 未登录
      document.getElementById('show-login-modal')!.click()
      setCaseResult([])
      setTotalSum(0)
      // window.location.href = '/oam_login?next=case';
    } else if (response.code == 0) {
      // 成功
      setCaseResult(response.case_list)
      setTotalSum(response.total_sum)
    } else if (response.code == -501) {
      // 权限不足
      message.error('当前用户权限不足')
      // window.location.href = '/oam_login?next=case';
    } else {
      message.error(response.message)
    }
  }
  const queryDoMainCallback = (response: any) => {
    console.log(response.domain_list)
    if (response.code == -100) {
      // 未登录
      document.getElementById('show-login-modal')!.click()
      // window.location.href = '/oam_login?next=case';
    } else if (response.code == 0) {
      // 成功
      const arrnew = ['全部'].concat(response.domain_list)
      const arrnew1 = response.domain_list.slice(
        0,
        response.domain_list.length - 1
      )
      setDomains(arrnew)
      setDomains1(arrnew1)
    } else if (response.code == -501) {
      // 权限不足
      message.error('当前用户权限不足')
      // window.location.href = '/oam_login?next=case';
    } else {
      message.error(response.message)
    }
  }

  const queryDoMain = () => {
    message.destroy()
    const requestParams = {
      sessionid: Cookies.get('sessionid'),
    }
    sendHttpPostRequest(
      'case/api/query_domain_list',
      requestParams,
      queryDoMainCallback
    )
  }

  const handleCaseDomainsChange = (tag: string, checked: boolean) => {
    setselectedDomainTags([tag])
    queryDomainTags.current = domainName2CodeMap.get(tag)
    queryCaseList()
  }

  const handleCaseTagsChange = (tag: string, checked: boolean) => {
    const selectedTags = selectedCaseTags
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag)
    setselectedCaseTags(nextSelectedTags)
  }

  const onCaseCardClick = (case_id: any, event: any) => {
    event.preventDefault()
    if (!checkUserLogin()) {
      document.getElementById('show-login-modal')!.click()
    } else {
      // window.open('case-run?id=' + case_id + '&token=' + token)
      window.open('case-detail?case_id=' + case_id)
    }
  }

  // 创建案例回调
  const createCaseCallback = (response: any) => {
    console.log(response)
    message.destroy()
    if (response.is_ok) {
      // window.open(response.url);
      message.success(response.message)
      setIsOk(response.is_ok)
      setCaseUrl(response.url)
      console.log(response.url)
    } else {
      message.error(`创建案例失败${response.message}`)
    }
    if (response.is_ok) {
      // console.log(response.is_ok);
      // window.open(response.url)
      window.open('/case-run?case_id=' + response.case_info.case_id)
      queryCaseList()
    }
  }

  // 点击创建案例
  const createCase = () => {
    message.destroy()
    const requestParams = {
      sessionid: Cookies.get('sessionid'),
      name: caseName,
      description: caseDescription,
      domain: caseDomain,
      cover: cover,
    }
    sendHttpPostRequest(
      'case/api/create_case',
      requestParams,
      createCaseCallback
    )
  }

  //点击了搜索按钮
  const onSearchBtn = (e: any) => {
    queryContent.current = e.target.value
    queryCaseList()
  }

  const onTabKeyChange = (activeKey: string) => {
    setActiveKey(activeKey)
    if (activeKey == '1') {
      //公开实验
      ismy.current = '2'
    } else if (activeKey == '2') {
      //我的实验
      ismy.current = '1'
    }
    page.current = 1
    queryCaseList()
  }

  // 案例名称改变
  const onNameChange = (key: string, event: any) => {
    setCaseName(event.target.value)
  }

  // 案例描述改变
  const onDescriptionChange = (key: string, event: any) => {
    setCaseDescription(event.target.value)
  }

  // 行业改变
  const onDomainChange = (key: string, event: any) => {
    setCaseDomain(event)
    console.log(event)
  }

  // 上传封面
  const onCoverChange = (info: any) => {
    setCoverfileList(info.fileList)
    const { status } = info.file
    if (status !== 'uploading') {
      //上传中
      console.log(info.file, info.fileList)
    }
    if (status === 'done') {
      //上传成功
      let new_cover = info.file.response.cover
      let code = info.file.response.code
      code == -100
        ? document.getElementById('show-login-modal')!.click()
        : document.getElementById('case-cover')?.setAttribute('src', new_cover)
      // image.current = info.file.response.img_url
      setCover(info.file.response.image_url)
    } else if (status === 'error') {
      //上传失败
      message.error(`${info.file.name} file upload failed.`)
    }
  }

  // 校验图片类型和大小
  function beforeCoverUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('请上传png或jpg格式的图片!')
    }
    const isLt5M = file.size / 1024 / 1024 < 5
    if (!isLt5M) {
      message.error('请上传小于5M的图片')
    }
    return isJpgOrPng && isLt5M
  }

  // 上传cover传参
  const uploadCoverData = {
    sessionid: Cookies.get('sessionid'),
    sid: Cookies.get('sessionid'),
  }

  const uploadCoverButton = (
    <div>
      {<PlusOutlined />}
      <div style={{ marginTop: 8 }}>上传(小于5M的jpg或png)</div>
    </div>
  )

  //页码和页数发生了变化
  const onPageChange = (newPage: number, newPageSize?: number | undefined) => {
    page.current = newPage ? newPage : 1
    if (newPageSize) {
      pageSize.current = newPageSize
    }
    queryCaseList()
  }

  // 点击 删除
  const onDeleteBtnClick = (caseid: any, e: any) => {
    e.stopPropagation()
    e.preventDefault()
    console.log('删除')
    //接口尚未实现
    sendHttpPostRequest(
      'case/api/delete_case/',
      {
        sessionid: Cookies.get('sessionid'),
        case_id: caseid,
      },
      onDeleteBtnClickCallback
    )
  }

  // 删除回调
  const onDeleteBtnClickCallback = (response: any) => {
    message.destroy()
    if (response.code == -100) {
      // 没登录
      document.getElementById('show-login-modal')!.click()
      // window.location.href = '/oam_login?next=case';
    } else if (response.code == -501) {
      // 没权限
      message.error('当前用户权限不足')
      // window.location.href = '/oam_login?next=case';
    } else if (response.code == 0) {
      //message.success(response.message);
      queryCaseList()
    } else {
      message.success('删除实验成功')
      queryCaseList()
    }
  }

  return (
    <Layout style={{ minHeight: 750, backgroundColor: '#f9f9f9' }}>
      <div className={caseStyles.maincontent}>
        <div className={caseStyles.caseList}>
          <Row gutter={[16, 16]} style={{ paddingRight: 24, marginBottom: 20 }}>
            <Col span={24}>
              <div>
                <Input
                  onPressEnter={onSearchBtn}
                  placeholder="搜索实验"
                  style={{ float: 'left', borderRadius: 20, width: 300 }}
                  suffix={<SearchOutlined style={{ color: '#666666' }} />}
                />
                <Button
                  style={{ float: 'right', borderRadius: 20 }}
                  type="primary"
                  onClick={showModal}
                >
                  创建实验
                </Button>

                <Modal
                  title={
                    <div
                      style={{
                        width: '100%',
                        cursor: 'move',
                      }}
                      onMouseOver={() => {
                        if (disabled) {
                          setDisabled(false)
                        }
                      }}
                      onMouseOut={() => {
                        setDisabled(true)
                      }}
                      onFocus={() => {}}
                      onBlur={() => {}}
                      // end
                    >
                      创建实验
                    </div>
                  }
                  closable={false}
                  visible={isModalVisible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  bodyStyle={{ minHeight: '400px' }}
                  destroyOnClose={true}
                  width={600}
                  modalRender={(modal) => (
                    <div className={caseStyles.createDiv}>
                      <Draggable
                        disabled={disabled}
                        bounds={bounds}
                        onStart={(event, uiData) => onStart(event, uiData)}
                      >
                        <div ref={draggleRef}>{modal}</div>
                      </Draggable>{' '}
                    </div>
                  )}
                >
                  <Row
                    gutter={[8, 8]}
                    justify="start"
                    align="middle"
                    style={{ marginBottom: 20 }}
                  >
                    <Col
                      flex="100px"
                      style={{ textAlign: 'left', marginLeft: 20 }}
                    >
                      实验名称
                    </Col>
                    <Col
                      flex="auto"
                      style={{ textAlign: 'left', marginRight: 20 }}
                    >
                      <Input
                        maxLength={40}
                        onChange={onNameChange.bind(this, 'name')}
                        className={caseStyles.caseName}
                        placeholder="请输入实验名称"
                        required={true}
                        style={{ width: '100%' }}
                      />
                    </Col>
                  </Row>

                  <Row
                    gutter={[8, 8]}
                    justify="start"
                    align="top"
                    style={{ marginBottom: 20, maxHeight: 120 }}
                  >
                    <Col
                      flex="100px"
                      style={{ textAlign: 'left', marginLeft: 20 }}
                    >
                      实验描述
                    </Col>
                    <Col
                      flex="auto"
                      style={{ textAlign: 'left', marginRight: 20 }}
                    >
                      <TextArea
                        className={caseStyles.caseText}
                        showCount
                        maxLength={300}
                        rows={4}
                        onChange={onDescriptionChange.bind(this, 'description')}
                        placeholder="请输入实验描述"
                        required={true}
                        style={{ width: '100%', maxHeight: 100 }}
                      />
                    </Col>
                  </Row>

                  <Row
                    gutter={[8, 8]}
                    justify="start"
                    align="middle"
                    style={{ marginBottom: 20 }}
                  >
                    <Col
                      flex="100px"
                      style={{ textAlign: 'left', marginLeft: 20 }}
                    >
                      领域
                    </Col>
                    <Col
                      flex="auto"
                      style={{ textAlign: 'left', marginRight: 20 }}
                    >
                      <Select
                        onChange={onDomainChange.bind(this, 'domain')}
                        className={caseStyles.caseSelect}
                        style={{ width: '100%', height: 32, outline: 0 }}
                      >
                        {domains1.map((item, index) => {
                          //利用map属性
                          return (
                            <Option value={item} key={index}>
                              {item}
                            </Option>
                          )
                        })}
                      </Select>
                    </Col>
                  </Row>
                  <Row
                    gutter={[8, 8]}
                    justify="start"
                    align="middle"
                    style={{ marginBottom: 20 }}
                  >
                    <Col
                      flex="100px"
                      style={{ textAlign: 'left', marginLeft: 20 }}
                    >
                      封 面
                    </Col>
                    <Col
                      flex="auto"
                      style={{ textAlign: 'left', marginRight: 20 }}
                    >
                      <div className={caseStyles.uploadCover}>
                        <Upload
                          beforeUpload={beforeCoverUpload}
                          accept={'image/*'}
                          action={
                            constants.BACKEND_API_URL +
                            'case/api/upload_case_image'
                          }
                          listType="picture-card"
                          name="case_cover"
                          fileList={coverFileList}
                          onChange={onCoverChange}
                          data={uploadCoverData}
                          showUploadList={false}
                        >
                          {cover ? (
                            <img
                              id="case-cover"
                              width={250}
                              height={100}
                              src={constants.BACKEND_URL + cover}
                            />
                          ) : (
                            uploadCoverButton
                          )}
                        </Upload>
                      </div>
                    </Col>
                  </Row>
                </Modal>
              </div>
            </Col>
          </Row>
          <div style={{ textAlign: 'left', marginBottom: 10 }}>
            <span style={{ marginRight: 8 }}>领域：</span>
            {domains.map((tag) => (
              <CheckableTag
                style={{ fontSize: 14, borderRadius: 20 }}
                key={tag}
                checked={selectedDomainTags.indexOf(tag) > -1}
                onChange={(checked) => handleCaseDomainsChange(tag, checked)}
              >
                {tag}
              </CheckableTag>
            ))}
          </div>

          <div style={{ textAlign: 'left', marginBottom: 10, display: 'none' }}>
            <span style={{ marginRight: 8 }}>知识点:</span>
            {tagData.map((tag) => (
              <CheckableTag
                style={{ fontSize: 12, borderRadius: 20 }}
                key={tag}
                checked={selectedCaseTags.indexOf(tag) > -1}
                onChange={(checked) => handleCaseTagsChange(tag, checked)}
              >
                {tag}
              </CheckableTag>
            ))}
          </div>

          <Tabs
            activeKey={activeKey}
            defaultActiveKey="1"
            size="small"
            style={{ paddingRight: 24 }}
            onChange={onTabKeyChange}
          >
            <TabPane tab="公开" key="1">
              <div className={caseStyles.cardListWarpper}>
                <Row gutter={[24, 24]} justify="start" wrap={true}>
                  {caseResult?.map((caseInterface) => {
                    return (
                      <Col key={caseInterface.case_id}>
                        <Link
                          to={"'/case-static?id' + caseInterface.case_id "}
                          onClick={onCaseCardClick.bind(
                            this,
                            caseInterface.case_id
                            // caseInterface.token
                          )}
                          target="_blank"
                        >
                          <Card
                            className={caseStyles.caseCard}
                            bodyStyle={{
                              padding: 20,
                              paddingTop: 10,
                              height: 140,
                              width: 250,
                              borderRadius: 6,
                            }}
                            hoverable={true}
                            cover={
                              caseInterface.image == '' ? (
                                <img
                                  alt=""
                                  src={defaultCaseCover}
                                  height={100}
                                  width={250}
                                  style={{
                                    borderTopRightRadius: 6,
                                    borderTopLeftRadius: 6,
                                  }}
                                />
                              ) : (
                                <img
                                  alt=""
                                  //{/*src={constants.BACKEND_API_URL + course.cover}*/}
                                  src={
                                    caseInterface.image?.startsWith('http')
                                      ? caseInterface.image
                                      : constants.BACKEND_URL +
                                        caseInterface.image
                                  }
                                  height={100}
                                  style={{
                                    borderTopRightRadius: 6,
                                    borderTopLeftRadius: 6,
                                  }}
                                />
                              )
                            }
                            actions={[
                              <div key="folk">
                                {caseInterface?.create_time?.slice(0, 10)}
                              </div>,
                              <div title="浏览量" key="view">
                                {' '}
                                <EyeOutlined /> {caseInterface.view_cnt}
                              </div>,
                              /*<div  title="运行量" key="folk"> <ForkOutlined  />   {caseInterface.view_cnt}</div> ,*/
                            ]}
                          >
                            <Meta
                              title={
                                <div
                                  style={{
                                    textAlign: 'left',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                  }}
                                >
                                  {caseInterface.name}
                                </div>
                              }
                              description={
                                <div style={{ textAlign: 'left' }}>
                                  <span
                                    style={{
                                      padding: '3px 8px 3px 8px',
                                      fontSize: 12,
                                      height: 18,
                                      color: '#00CC99',
                                      backgroundColor: '#F2F2F2',
                                      borderRadius: 10,
                                      marginBottom: 5,
                                    }}
                                  >
                                    {caseInterface.domain}
                                  </span>

                                  <div
                                    style={{
                                      marginTop: 5,
                                      textAlign: 'justify',
                                      maxHeight: 70,
                                      textOverflow: 'ellipsis',
                                    }}
                                  >
                                    {caseInterface.desc}
                                  </div>
                                </div>
                              }
                            />
                          </Card>
                        </Link>
                      </Col>
                    )
                  })}
                </Row>
              </div>
            </TabPane>
            <TabPane tab="我的" key="2">
              <div className={caseStyles.cardListWarpper}>
                <Row gutter={[24, 24]} justify="start" wrap={true}>
                  {caseResult?.map((caseInterface) => {
                    return (
                      <Col key={caseInterface.case_id}>
                        <Link
                          to={"'/case-static?id' + caseInterface.case_id "}
                          onClick={onCaseCardClick.bind(
                            this,
                            caseInterface.case_id
                            // caseInterface.token
                          )}
                          target="_blank"
                        >
                          <Card
                            className={caseStyles.caseCard}
                            // style={{ height: 260 }}
                            actions={[
                              <div key="folk">
                                {caseInterface?.create_time?.slice(0, 10)}
                              </div>,
                              <div title="浏览量" key="view">
                                {' '}
                                {/* <EyeOutlined /> {caseInterface.view_cnt} */}
                              </div>,
                              /*<div  title="运行量" key="folk"> <ForkOutlined  />   {caseInterface.view_cnt}</div> ,*/
                            ]}
                            bodyStyle={{
                              padding: 20,
                              paddingTop: 10,
                              height: 140,
                              width: 250,
                              borderRadius: 6,
                            }}
                            hoverable={true}
                            cover={
                              <div>
                                <div className={caseStyles.myCardAcitionDiv}>
                                  <Popconfirm
                                    placement="bottom"
                                    title={'您确定删除此实验吗？'}
                                    onCancel={(e: any) => {
                                      e.stopPropagation()
                                    }}
                                    onConfirm={onDeleteBtnClick.bind(
                                      this,
                                      caseInterface.case_id
                                    )}
                                    okText="确认"
                                    cancelText="取消"
                                  >
                                    <Button
                                      onClick={(e: any) => {
                                        e.stopPropagation()
                                      }}
                                      shape="circle"
                                      icon={<DeleteOutlined />}
                                      style={{ paddingLeft: 8, zIndex: 10000 }}
                                    >
                                      {' '}
                                    </Button>
                                  </Popconfirm>
                                </div>
                                {caseInterface.image == '' ? (
                                  <img
                                    alt=""
                                    src={defaultCaseCover}
                                    height={100}
                                    width={250}
                                    style={{
                                      borderTopRightRadius: 6,
                                      borderTopLeftRadius: 6,
                                    }}
                                  />
                                ) : (
                                  <img
                                    alt=""
                                    src={
                                      caseInterface.image?.startsWith('http')
                                        ? caseInterface.image
                                        : constants.BACKEND_URL +
                                          caseInterface.image
                                    }
                                    height={100}
                                    width={250}
                                    style={{
                                      borderTopRightRadius: 6,
                                      borderTopLeftRadius: 6,
                                    }}
                                  />
                                )}
                              </div>
                            }
                          >
                            <Meta
                              title={
                                <div
                                  style={{
                                    textAlign: 'left',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                  }}
                                >
                                  {caseInterface.name}
                                </div>
                              }
                              description={
                                <div style={{ textAlign: 'left' }}>
                                  <span
                                    style={{
                                      padding: '3px 8px 3px 8px',
                                      fontSize: 12,
                                      height: 18,
                                      color: '#00CC99',
                                      backgroundColor: '#F2F2F2',
                                      borderRadius: 10,
                                      marginBottom: 5,
                                    }}
                                  >
                                    {caseInterface.domain}
                                  </span>

                                  <div
                                    style={{
                                      marginTop: 5,
                                      textAlign: 'justify',
                                      maxHeight: 70,
                                      textOverflow: 'ellipsis',
                                    }}
                                  >
                                    {caseInterface.desc}
                                  </div>
                                </div>
                              }
                            />
                          </Card>
                        </Link>
                      </Col>
                    )
                  })}
                </Row>

                {caseResult?.length == 0 && (
                  <div>
                    <img src={emptyCase} height={100} />
                    <div style={{ color: '#999999' }}>您还没有创建实验哟~</div>
                    <Button
                      size="small"
                      style={{
                        border: '1px solid #07c176',
                        color: '#07c176',
                        marginTop: 10,
                        borderRadius: 3,
                      }}
                      type="default"
                      onClick={showModal}
                    >
                      {' '}
                      立即创建
                    </Button>
                  </div>
                )}
              </div>
            </TabPane>
          </Tabs>

          <ConfigProvider locale={zhCN}>
            <div className={caseStyles.pagination}>
              <Pagination
                defaultCurrent={1}
                total={totalSum}
                current={page.current}
                defaultPageSize={12}
                hideOnSinglePage={true}
                onChange={onPageChange}
                pageSizeOptions={['12', '18', '24', '32', '40', '48', '56']}
              />
            </div>
          </ConfigProvider>
        </div>
      </div>
    </Layout>
  )
}

export default withRouter(Case)
