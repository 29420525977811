import {
  Tabs,
  Divider,
  Image,
  Layout,
  Input,
  Select,
  message,
  Button,
  Radio,
  Checkbox,
  Row,
  Col,
  Popconfirm,
  Upload,
  ConfigProvider,
  Steps,
} from 'antd'
import { UploadOutlined, UpOutlined, DownOutlined } from '@ant-design/icons'

import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { recordExpression } from '@babel/types'
import { sendHttpPostRequest } from '../../api/http-request'
import Cookies from 'js-cookie'
import exerciseStyles from './exercise.module.less'
import './exercise.css'
import MonacoEditor from 'react-monaco-editor'

const { Step } = Steps
const { TabPane } = Tabs
import gfm from 'remark-gfm'
import remarkMath from 'remark-math'

import ReactMarkdown from 'react-markdown'
import rehypeKatex from 'rehype-katex'
import 'katex/dist/katex.min.css' // `rehype-katex` does not import the CSS for you

import zhCN from 'antd/lib/locale/zh_CN'

import rehypeRaw from 'rehype-raw'

import { CloseCircleOutlined, PaperClipOutlined } from '@ant-design/icons'
import confirm from 'antd/lib/modal/confirm'
import * as constants from '../../constants/constants'
import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined'
import PageHeader from '../common/page-header/header'
import PageFooter from '../common/page-footer/footer'
import Feedback from '../common/feedback-help/feedback'
import { FileList } from 'pages/case/CaseDetail/FileList'
import { useHistory, useLocation } from 'react-router'
import formatIcon from '@/static/image/formatIcon.png'
import ModalDrag from 'component/ModaDrag/ModalDrag'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter' // 代码高亮
import { oneLight } from 'react-syntax-highlighter/dist/esm/styles/prism'

import remarkGfm from 'remark-gfm'

const { Option } = Select
const { TextArea } = Input

export interface Props {}
export interface Props2 {
  dataset?: any
}

interface exerciseInfo {
  id: number
  title: string
  remarks: string
  type: number
  type_name: string
  difficulty: number
  difficulty_str: string
  score: number
  default_code: string
  steps: string
  preset_code: string
  language: number
  language_str: string
  judgement_code: string
  reference_answer: string
  answer_parse: string
  options: any
  options_answer: any
  datasets: any
  images: any
  full_code: string
}

export const ExerciseEdit: React.FC<Props> = (props) => {
  const history = useHistory()
  const location = useLocation()
  const exerciseId = parseInt(window.location.search.split('=')[1]) // 习题的ID
  const [exercise, setExercise] = useState(null as unknown as exerciseInfo) // 习题对象
  const [exerciseType, setExerciseType] = useState(1) // 习题类型
  const [options, setOptions] = useState([] as any[]) // 选项
  const [optionsLength, setOptionsLength] = useState(1) //选项长度
  const [optionsAnswer, setOptionsAnswer] = useState([] as any[]) // 单选、多选正确选项
  const [editorLanguage, setEditorLanguage] = useState('python') // 编辑框默认语言
  const [mdSteps, setMdSteps] = useState('') // MD渲染区域默认文本
  const [fileList, setFileList] = useState([]) // 新上传数据集
  const [datasets, setDatasets] = useState([]) // 已绑定数据集
  const [images, setImages] = useState([] as any[]) // 已绑定图片
  const [datasetInfo, setDatasetInfo] = useState<any>()

  const [currentCodingStep, setCurrentCodingStep] = useState(0) //数据实操题编辑的步骤

  const [completeCodeVisiable, setCompleteCodeVisiable] = useState(false) //实操题，代码切分步骤，是否显示完整代码
  const [answerCodeVisiable, setAnswerCodeVisiable] = useState(false) //实操题，代码挖空步骤，是否显示参考代码

  const [isLogin, setIsLogin] = useState(false) //是否登录
  const [collegeId, setCollegeId] = useState(null as any) // 院校ID

  const [userId, setUserId] = useState(null as any)

  const [isCollegeTeacher, setIsCollegeTeacher] = useState(null as any) //是否机构教师权限 1是  0 不是

  const [userInfo, setUserInfo] = useState(null as any) //当前登录用户信息

  const monocoEditorWidth = '100%'

  const [visible, setVisible] = useState(false) // 图片的显隐

  // 存储旧的参考答案用来和新输入的参考答案进行比较
  const [beforeReference, setBeforeReference] = useState('')
  const [beforePreset, setBeforePreset] = useState('')
  const [beforeDefault, setBeforeDefault] = useState('')
  const [beforeJudgement, setBeforeJudgement] = useState('')

  // 选择题默认选项
  const defaultOptions = [
    { id: 1, options: '' },
    { id: 2, options: '' },
    { id: 3, options: '' },
    { id: 4, options: '' },
  ]

  // 编辑框选项
  const editorOptions = {
    selectOnLineNumbers: true,
    fontSize: 14,
    minimap: { enabled: false },

    lineDecorationsWidth: 5,
    lineNumbersMinChars: 2, //默认至少显示多少行号
    quickSuggestions: false,
    overviewRulerBorder: false,
    //automaticLayout: true,
    formatOnPaste: true,
  }

  const editorReadOnlyoptions = {
    readOnly: true,
    selectOnLineNumbers: true,
    fontSize: 14,
    minimap: { enabled: false },
    lineDecorationsWidth: 5,
    lineNumbersMinChars: 2,
    quickSuggestions: false,
    //automaticLayout: true,  // 拖拽分屏线时自适应宽度
  }

  const queryExerciseCallback = (response) => {
    message.destroy()
    if (response.code == -100) {
      // 未登录
      document.getElementById('show-login-modal')!.click()
      // window.location.href = '/oam_login?next=oam_exercise_list';
    } else if (response.code == 0) {
      // 成功

      if (userId && userId !== response.exercise_info.creator_id) {
        window.location.href = '/exercise'
      }

      setExercise(response.exercise_info)
      setExerciseType(response.exercise_info.type)
      // response.exercise_info.options.length > 0 ? setOptions(response.exercise_info.options) : setOptions(defaultOptions);
      response.exercise_info.options
        ? setOptions(response.exercise_info.options)
        : setOptions(defaultOptions)
      setOptionsLength(response.exercise_info.options.length)
      setOptionsAnswer(response.exercise_info.options_answer)

      setBeforeReference(response.exercise_info.reference_answer)
      setBeforePreset(response.exercise_info.preset_code)
      setBeforeDefault(response.exercise_info.default_code)
      setBeforeJudgement(response.exercise_info.judgement_code)
      if (response.exercise_info.type == 1) {
        queryDatasetInfo(response.exercise_info.dataset_id)
      }

      if (
        response.exercise_info.language == 3 ||
        response.exercise_info.language == 4
      ) {
        setEditorLanguage('r')
      } else if (response.exercise_info.language == 5) {
        setEditorLanguage('mysql')
      } else if (response.exercise_info.language == 6) {
        setEditorLanguage('java')
      } else if (response.exercise_info.language == 7) {
        setEditorLanguage('scala')
      }

      setMdSteps(response.exercise_info.steps)
      setDatasets(response.exercise_info.datasets)
      setImages(response.exercise_info.images)
    } else if (response.code == -501) {
      // 权限不足
      message.error('您尚未获得教师权限')
    } else {
      message.error(response.message)
    }
  }
  const queryDatasetInfo = (id) => {
    const params = {
      dataset_id: id,
    }
    sendHttpPostRequest(
      'dataset/api/user_get_dataset_by_id/',
      params,
      (res) => {
        if (res.code == 0) {
          setDatasetInfo(res.query_info)
        }
      }
    )
  }
  useEffect(() => {
    sendHttpPostRequest(
      'account/api/query_teacher_authenticate',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
      },
      (response: any) => {
        if (response.is_ok == true) {
          setIsLogin(true)
          if (response?.college_info?.college_id !== '') {
            //已加入机构
            let _collegeId = response.college_info.college_id
            setCollegeId(_collegeId)
            setIsCollegeTeacher(response.college_info.is_college_teacher)
            setUserId(response.teacher_info.user_id)
            if (response.college_info.is_college_teacher == 1) {
              //是当前机构教师才允许编辑，需要进一步验证是老师自己的习题
              queryExerciseInfo() //
            } else {
              window.location.href = '/exercise'
            }
          } else {
            //未加入机构
            setCollegeId(0)
          }
        } else {
          //用户未登录
          setIsLogin(false)
        }
      }
    )
  }, [])

  // 查询用户信息
  const queryUserInfo = () => {
    sendHttpPostRequest(
      'account/api/get_user_info/',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
      },
      (response: any) => {
        message.destroy()
        if (response.code == -100) {
          // 未登录
          document.getElementById('show-login-modal')!.click()
        } else if (response.code == 0) {
          setUserInfo(response.data)
        } else {
          message.error(response.message)
        }
      }
    )
  }

  // 查询习题详情
  const queryExerciseInfo = () => {
    sendHttpPostRequest(
      'exercise/api/query_exercise_by_id/',
      {
        exercise_id: exerciseId,
        sessionid: Cookies.get('sessionid'),
        source_page: 1, // 习题模块1
        source_key: 2, // 教师出题2,
        query_type: 1, // 查询答案和答案解析
      },
      queryExerciseCallback
    )
  }

  // 题型改变
  const typeChange = (key: any, object: any) => {
    const currentType = exercise.type
    exercise.type_name = object['children']
    exercise.type = parseInt(key)
    setExerciseType(parseInt(key))
    currentType == 1 && !exercise.options ? setOptions(defaultOptions) : ''
    if (exercise.type == 2 && exercise.options_answer.length > 0) {
      // 单选题 如果从多选切过来，只保留第一个答案作为正确答案
      // exercise.options_answer = [exercise.options_answer[0]];
      // setOptionsAnswer(exercise.options_answer[0]);
      // TODO: 逻辑复杂，交后台处理，只取第一个为正确答案
    }
    // console.log(exercise)
  }

  // 题型改变
  const onTypeChange = (e: any) => {
    const currentType = exercise.type
    const newType = e.target.value
    exercise.type = newType
    if (newType == 1) {
      exercise.type_name = '实操题'
    } else if (newType == 2) {
      exercise.type_name = '单选题'
    } else if (newType == 3) {
      exercise.type_name = '多选题'
    } else if (newType == 4) {
      exercise.type_name = '排序题'
    } else {
      exercise.type_name = '主观题'
    }
    setExerciseType(parseInt(newType))
    if ([1, 4, 5].includes(currentType)) {
      if (exercise.options.length == 0) {
        setOptions(defaultOptions) // 从其他题型且到选择题如果没有默认选项时自动添加4个空的
        exercise.options = defaultOptions
      } else if (exercise.options.length > 4) {
        let _currentOptions = exercise.options
        let _optionsAnswer = optionsAnswer
        for (let _index = exercise.options.length - 1; _index >= 4; _index--) {
          _currentOptions.splice(_index, 1)
        }
        if (_optionsAnswer.length > 0) {
          if (exercise.type == 2) {
            // 单选题只保留一个正确选项
            _optionsAnswer = _optionsAnswer[0]
          } else {
            for (
              let _index = 0;
              _index <= _optionsAnswer.length + 1;
              _index++
            ) {
              console.log(_index)
              if (_optionsAnswer[_index] > 4) {
                let _answerPosition = _optionsAnswer.indexOf(
                  _optionsAnswer[_index]
                )
                _optionsAnswer.splice(_answerPosition, 1)
              }
            }
          }
        }
        // console.log(_optionsAnswer);
        setOptions(_currentOptions)
        setOptionsAnswer(_optionsAnswer)
        setOptionsLength(_optionsAnswer.length)
        exercise.options = _currentOptions
        exercise.options_answer = _optionsAnswer
      }
    }
    if (exercise.type == 2 && exercise.options_answer.length > 0) {
      // 单选题 如果从多选切过来，只保留第一个答案作为正确答案
      // exercise.options_answer = [exercise.options_answer[0]];
      // setOptionsAnswer(exercise.options_answer[0]);
      // TODO: 逻辑复杂，交后台处理，只取第一个为正确答案
    }
    console.log(exercise)
  }

  // 难度改变
  const difficultyChange = (key: any, object: any) => {
    exercise.difficulty = parseInt(key)
    exercise.difficulty_str = object['children']
  }

  const onDifficultyChange = (e: any) => {
    exercise.difficulty = e.target.value
    if (exercise.difficulty == 1) {
      exercise.difficulty_str = '易'
    } else if (exercise.difficulty == 2) {
      exercise.difficulty_str = '中'
    } else {
      exercise.difficulty_str = '难'
    }
    console.log(exercise)
  }

  // 语言改变
  const languageChange = (key: any, object: any) => {
    const languageKey = parseInt(key)
    console.log(languageKey)
    exercise.language = languageKey
    exercise.language_str = object['children']
    let languageStr = 'python'
    if (languageKey == 3 || languageKey == 4) {
      languageStr = 'r'
    } else if (languageKey == 5) {
      languageStr = 'mysql'
    } else if (languageKey == 6) {
      languageStr = 'java'
    } else if (languageKey == 7) {
      languageStr = 'scala'
    }
    // } else if (languageKey == 8) {
    //     languageStr = "spark";
    // }

    setEditorLanguage(languageStr)
  }

  // 选择题选项改变
  const onOptionsChange = (key: string, event: any) => {
    console.log(key)
    exercise.options.map((item, index) => {
      if (item.id == key) {
        exercise.options[index]['options'] = event.target.value
      }
    })
  }

  // 排序题选项删除
  const onSortOptionsDel = (key: string, event: any) => {
    //1.重置正确顺序
    setOptionsAnswer([])
    exercise.options_answer = []

    setOptions(options.filter((item) => item.id !== key))
    exercise.options = exercise.options.filter((item) => item.id !== key) //删除当前选项

    const newOptions = exercise.options.map((item, index) => {
      return { id: String(index + 1), options: item.options }
    })
    exercise.options = newOptions
    setOptions(newOptions)
  }

  // 多选题选项勾选改变
  const onMultipleChoiceChange = (optionId: string, event: any) => {
    const index = exercise.options_answer.indexOf(parseInt(optionId))
    let _optionsAnswer = exercise.options_answer
    if (index >= 0) {
      _optionsAnswer.splice(index, 1)
    } else {
      _optionsAnswer.push(parseInt(optionId))
    }
    setOptionsAnswer(_optionsAnswer)
  }

  // 排序题勾选改变
  const onSortChange = (optionId: string, event: any) => {
    const index = exercise.options_answer.indexOf(parseInt(optionId))
    let _optionsAnswer = exercise.options_answer
    if (index >= 0) {
      _optionsAnswer.splice(index, 1)
    } else {
      _optionsAnswer.push(parseInt(optionId))
    }
    setOptionsAnswer(_optionsAnswer)
    exercise.options_answer = _optionsAnswer
    document.getElementById('answer-sort')!.focus()
  }

  // 选择题添加选项
  const appendOptions = () => {
    const currentOptionsIndex = exercise.options.length + 1
    const optionsHtml = (
      <div
        key={`div-${currentOptionsIndex}`}
        style={{ marginBottom: 20 }}
        className={'options-item'}
      >
        <span
          className={'options-span'}
          style={{ marginRight: 20, marginLeft: -33, verticalAlign: 'top' }}
        >
          选项{currentOptionsIndex}
          {exerciseType == 2 ? (
            <Radio
              name={'opitons'}
              value={`${currentOptionsIndex}`}
              onChange={onSingleChoiceChange}
            ></Radio>
          ) : [3, 4].includes(exerciseType) ? (
            <Checkbox
              onChange={onMultipleChoiceChange.bind(
                this,
                `${currentOptionsIndex}`
              )}
            ></Checkbox>
          ) : (
            ''
          )}
        </span>
        <TextArea
          style={{ width: 300 }}
          onChange={onOptionsChange.bind(this, `${currentOptionsIndex}`)}
        />
        <Popconfirm
          placement="top"
          title={'确认删除选项 ' + currentOptionsIndex + ' 吗？'}
          okText="确认"
          cancelText="取消"
          onConfirm={(e) => {
            confirm(currentOptionsIndex, this)
          }}
          onCancel={() => {
            return
          }}
        >
          <CloseCircleOutlined
            style={{
              verticalAlign: 'middle',
              color: 'lightgray',
              marginTop: -40,
              marginLeft: 20,
            }}
          />
        </Popconfirm>
      </div>
    )
    ReactDOM.render(
      optionsHtml,
      document.getElementsByClassName('new-options')[0]
    ) // 渲染到指定位置
    const newOptions = { id: `${currentOptionsIndex}`, options: '' } // 新增一个选项
    const currentOptions = exercise.options // 获取当前选项
    currentOptions.push(newOptions) // 为习题选项列表增加一个选项
    setOptions(currentOptions) // 设置选项
    document
      .getElementsByClassName('new-options')[0]
      .classList.remove('new-options') // 移除插入节点的class
    const newClass = document.createElement('div')
    newClass.classList.add('new-options')
    document.getElementById('add-options-btn')!.before(newClass) // 重设插入节点的class
    console.log(currentOptions)
    setOptionsLength(currentOptions.length)
  }

  // 排序题添加选项
  const appendSortOptions = () => {
    const currentOptionsIndex = exercise.options.length + 1
    const newOptions = { id: `${currentOptionsIndex}`, options: '' } // 新增一个选项
    const currentOptions = exercise.options // 获取当前选项
    currentOptions.push(newOptions) // 为习题选项列表增加一个选项
    setOptions([...currentOptions]) // 设置选项
  }

  // 保存习题回调
  const saveExerciseCallback = (response: any) => {
    console.log(response)
    if (response.code == -100) {
      // 未登录
      document.getElementById('show-login-modal')!.click()
      // window.location.href = '/oam_login?next=oam_exercise_list';
    } else if (response.code == 0) {
      // 成功
      message.success(response.message)
      setMdSteps(exercise.steps)
      //window.opener.location.reload();
    } else if (response.code == -501) {
      // 权限不足
      // document.getElementById("show-login-modal")!.click();
      // window.location.href = '/oam_login?next=oam_exercise_list';
      message.error('您尚未获得教师权限')
    } else {
      message.error(response.message)
    }
  }
  // 点了预览按钮
  const onLookOverBtnClick = (event: any) => {
    const requestParams = {
      sessionid: Cookies.get('sessionid'),
      exercise_id: exercise.id,
      title: exercise.title,
      remarks: exercise.remarks,
      type: exercise.type,
      difficulty: exercise.difficulty,
      score: exercise.score,
      default_code: exercise.default_code,
      steps: exercise.steps,
      preset_code: exercise.preset_code,
      language: exercise.language,
      judgement_code: exercise.judgement_code,
      full_code: exercise.full_code,
      reference_answer: exercise.reference_answer,
      answer_parse: exercise.answer_parse,
      options: JSON.stringify(exercise.options),
      options_answer: JSON.stringify(exercise.options_answer),
    }

    sendHttpPostRequest(
      'exercise/api/update_exercise/',
      requestParams,
      saveExerciseCallback
    )
    window.open(`/exercise-preview?exercise_id=${exerciseId}`)
  }
  // 保存习题
  const saveExercise = () => {
    message.destroy()
    if ([2, 3].indexOf(exercise.type) >= 0) {
      if (exercise.options_answer.length == 0) {
        message.error('选择题请至少勾选一个正确选项')
        return
      }
      let nullOptionCount = 0 //检查是否有选项内容为空
      exercise.options?.map((option) => {
        if (option.options?.trim() == '') {
          nullOptionCount = nullOptionCount + 1
        }
      })

      if (nullOptionCount > 0) {
        message.error('请确保选项内容不为空')
        return
      }
    } else if (4 == exercise.type) {
      //排序题要勾选所有选项
      if (exercise.options_answer.length < options.length) {
        message.error('排序题请按正确顺序勾选全部选项！')
        return
      }
      let nullOptionCount = 0 //检查是否有选项内容为空
      exercise.options?.map((option) => {
        if (option.options?.trim() == '') {
          nullOptionCount = nullOptionCount + 1
        }
      })

      if (nullOptionCount > 0) {
        message.error('请确保选项内容不为空')
        return
      }
    } else if (1 == exercise.type) {
      setBeforeReference(exercise.reference_answer)
      setBeforePreset(exercise.preset_code)
      setBeforeDefault(exercise.default_code)
      setBeforeJudgement(exercise.judgement_code)
      // setIsPrompt(false)
    }

    const requestParams = {
      sessionid: Cookies.get('sessionid'),
      exercise_id: exercise.id,
      title: exercise.title,
      remarks: exercise.remarks,
      type: exercise.type,
      difficulty: exercise.difficulty,
      score: exercise.score,
      default_code: exercise.default_code,
      steps: exercise.steps,
      preset_code: exercise.preset_code,
      language: exercise.language,
      judgement_code: exercise.judgement_code,
      full_code: exercise.full_code,
      reference_answer: exercise.reference_answer,
      answer_parse: exercise.answer_parse,
      options: JSON.stringify(exercise.options),
      options_answer: JSON.stringify(exercise.options_answer),
    }

    sendHttpPostRequest(
      'exercise/api/update_exercise/',
      requestParams,
      saveExerciseCallback
    )
  }

  // 文本类型字段改变
  const onTextChange = (key: string, event: any) => {
    exercise[key] = event.target.value
  }

  //题目说明改变
  const onStepsChange = (event: any) => {
    exercise.steps = event.target.value
    //setMdSteps(event.target.value)
  }
  //用户编辑代码发生改变
  const onCodeChange = (key: string, newValue: string, event: any) => {
    // console.log('onChange', key, newValue, event, exercise);
    exercise[key] = newValue
    // if (key == "steps") {
    //     setMdSteps(newValue);
    // }
  }

  const listenEditor = () => {
    //监听窗口事件
    useEffect(() => {
      const listener = (ev) => {
        if (
          beforeReference != exercise.reference_answer ||
          beforePreset != exercise.preset_code ||
          beforeDefault != exercise.default_code ||
          beforeJudgement != exercise.judgement_code
        ) {
          ev.returnValue = false
        }
      }
      window.addEventListener('beforeunload', listener)
      return () => {
        window.removeEventListener('beforeunload', listener)
      }
    }, [])
  }

  const editorDidMount = (editor, monaco) => {
    // console.log('editorDidMount', editor);
    editor.focus()
  }

  // 单选题点击选项
  const onSingleChoiceChange = (event: any) => {
    setOptionsAnswer([parseInt(event.target.value)])
    exercise.options_answer = [parseInt(event.target.value)]
  }

  //删除选项点击确定
  const confirm = (optionId: string, event: any) => {
    const index = parseInt(optionId) //获取要删除选项的ID
    let _options = exercise.options //获取所有选项
    _options.splice(index - 1, 1) //删除指定选项
    setOptions(_options) //设置选项
    console.log(_options)
    let deleteOption = document.getElementsByClassName('options-item') //获取所有选项节点
    let spans = document.getElementsByClassName('options-span')
    console.log(spans)
    deleteOption[index - 1].remove() //删除被删除选项的节点
    for (let i = 0; i < _options.length; i++) {
      _options[i].id = JSON.stringify(i + 1)
      // spans[i].textContent = '选项' + (i+1);
    }
    setOptions(_options) //设置选项
    // console.log(setOptions);
    setOptionsLength(_options.length)
  }

  // 上传数据集传参
  const uploadData = {
    sessionid: Cookies.get('sessionid'),
    sid: Cookies.get('sessionid'),
    exercise_id: exerciseId,
    type: 1,
    source: 2,
  }

  // 上传数据集
  const uploadProps = {
    accept: '.csv,.txt,.pickle,.xls,.xlsx',
    name: 'datasets',
    multiple: false,
    showUploadList: false,
    data: uploadData,
    action: constants.BACKEND_API_URL + 'exercise/api/upload_datasets/',
    onChange(info) {
      const { status } = info.file
      if (status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (status === 'done') {
        if (info.file.response.is_ok) {
          //message.success(`数据集${info.file.name}文件上传成功！`);
          addNewDataset(info.file.response)
        } else {
          if (info.file.response.code == -100) {
            // 未登录
            // const localPath = window.location.href.split(window.location.host)[1];
            // window.location.href = `/oam_login?next=${localPath}`;
            document.getElementById('show-login-modal')!.click()
          } else if (info.file.response.code == -501) {
            // 权限不足
            // const localPath = window.location.href.split(window.location.host)[1];
            // window.location.href = `/oam_login?next=${localPath}`;
            //document.getElementById("show-login-modal")!.click();
            message.error('您尚未获得教师权限')
          } else {
            message.error(info.file.response.message)
          }
        }
      } else if (status === 'error') {
        message.error(`数据集${info.file.name}文件上传失败了:(`)
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files)
    },
  }

  // 删除数据集回调
  const deleteDatasetCallback = (response: any) => {
    message.destroy()
    if (response.code == -100) {
      // 未登录
      // const localPath = window.location.href.split(window.location.host)[1];
      // window.location.href = `/oam_login?next=${localPath}`;
      document.getElementById('show-login-modal')!.click()
    } else if (response.code == -501) {
      // 权限不足
      // const localPath = window.location.href.split(window.location.host)[1];
      // window.location.href = `/oam_login?next=${localPath}`;
      // document.getElementById("show-login-modal")!.click();
      message.error('您尚未获得教师权限')
    } else if (response.code == 0) {
      message.success(`数据集${response.datasets_name}删除成功！`)
      document.getElementById('dataset-' + response.datasets_id)!.remove()
    } else {
      message.error(response.message)
    }
  }

  // 删除数据集
  const deleteDataset = (datasetId: number, event: any) => {
    // const currentDatasets = datasets;
    // console.log(currentDatasets);
    // for (let index in currentDatasets) {
    //     if (currentDatasets[index]["id"] == datasetId) {
    //         currentDatasets.splice(parseInt(index), 1);
    //     }
    // }
    // console.log(currentDatasets);
    // setDatasets(currentDatasets);
    sendHttpPostRequest(
      'exercise/api/delete_datasets/',
      {
        sessionid: Cookies.get('sessionid'),
        exercise_id: exerciseId,
        datasets_id: datasetId,
      },
      deleteDatasetCallback
    )
  }

  // 添加数据集列表
  const addNewDataset = (response: any) => {
    const datasetHtml = (
      <Row
        key={response.datasets_id}
        id={'dataset-' + response.datasets_id}
        style={{ textAlign: 'left', margin: '0px 0' }}
      >
        <Col
          span={24}
          className={exerciseStyles.fileLink}
          style={{ display: 'inline-block', width: '100%' }}
        >
          <div style={{ padding: 5 }}>
            <PaperClipOutlined /> {response.datasets_name}
            <Popconfirm
              placement="top"
              title={'确认删除数据集吗？'}
              okText="确认"
              cancelText="取消"
              onConfirm={(e) => {
                deleteDataset(response.datasets_id, this)
              }}
              onCancel={() => {
                return
              }}
            >
              <DeleteOutlined className={exerciseStyles.iconfilebuttonsdel} />
            </Popconfirm>
          </div>
        </Col>
      </Row>
    )
    ReactDOM.render(
      datasetHtml,
      document.getElementsByClassName('new-dataset')[0]
    ) // 渲染到指定位置
    document
      .getElementsByClassName('new-dataset')[0]
      .classList.remove('new-dataset') // 移除插入节点的class
    const newClass = document.createElement('div')
    newClass.classList.add('new-dataset')
    document.getElementById('add-dataset-div')!.before(newClass) // 重设插入节点的class
  }

  // 上传图片
  const uploadImageProps = {
    accept: '.png,.jpg,.gif,.jpeg',
    name: 'picture',
    multiple: false,
    showUploadList: false,
    data: uploadData,
    action: constants.BACKEND_API_URL + 'exercise/api/upload_image/',
    onChange(info) {
      const { status } = info.file
      if (status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (status === 'done') {
        if (info.file.response.is_ok) {
          //message.success(`图片${info.file.name}文件上传成功！`);
          if (!info.file.response.update_only) {
            images.push({
              id: info.file.response.image_id,
              name: info.file.response.image_name,
              path: info.file.response.image_path,
            })
            setImages(JSON.parse(JSON.stringify(images)))
            //addNewImage(info.file.response);
          }
        } else {
          if (info.file.response.code == -100) {
            // 未登录
            // const localPath = window.location.href.split(window.location.host)[1];
            // window.location.href = `/oam_login?next=${localPath}`;
            document.getElementById('show-login-modal')!.click()
          } else if (info.file.response.code == -501) {
            // 权限不足
            // const localPath = window.location.href.split(window.location.host)[1];
            // window.location.href = `/oam_login?next=${localPath}`;
            // document.getElementById("show-login-modal")!.click();
            message.error('您尚未获得教师权限')
          } else {
            message.error(info.file.response.message)
          }
        }
      } else if (status === 'error') {
        message.error(`图片${info.file.name}文件上传失败了:(`)
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files)
    },
  }

  // 删除图片回调
  const deleteImageCallback = (response: any) => {
    message.destroy()
    if (response.code == -100) {
      // 未登录
      // const localPath = window.location.href.split(window.location.host)[1];
      // window.location.href = `/oam_login?next=${localPath}`;
      document.getElementById('show-login-modal')!.click()
    } else if (response.code == -501) {
      // 权限不足
      message.error('您尚未获得教师权限')
    } else if (response.code == 0) {
      //message.success(`图片${response.image_name}删除成功！`)
      const newImages = images.filter((item) => item.id !== response.image_id)
      setImages(newImages)
    } else {
      message.error(response.message)
    }
  }

  // 删除图片
  const deleteImage = (imageName: string, event: any) => {
    sendHttpPostRequest(
      'exercise/api/delete_image/',
      {
        sessionid: Cookies.get('sessionid'),
        exercise_id: exerciseId,
        image_name: imageName,
      },
      deleteImageCallback
    )
  }

  // 根据题型显示类型，不支持来回切换
  const renderExerciseType = (exerciseType1: number) => {
    if (exerciseType1 == 1) {
      return '实操题'
    } else if (exerciseType1 == 2) {
      return '单选题'
    } else if (exerciseType1 == 3) {
      return '多选题'
    } else if (exerciseType1 == 4) {
      return '排序题'
    } else if (exerciseType1 == 5) {
      return '主观题'
    }
  }

  /** 各种题型的公共部分 */
  const ExerciseBasicForm: React.FC<Props2> = (props: Props2) => {
    return (
      <div className={exerciseStyles.editExerciseFormWrapperNew}>
        <Row
          gutter={[8, 8]}
          justify="start"
          align="middle"
          // style={{ marginBottom: 20 }}
        >
          <Col flex="100px" style={{ color: '#999999', textAlign: 'left' }}>
            习题名称
          </Col>
          <Col flex="auto" style={{ textAlign: 'left' }}>
            <Input
              placeholder="请输入题目名称"
              required={true}
              defaultValue={exercise?.title}
              onChange={onTextChange.bind(this, 'title')}
              style={{ borderRadius: 3, width: '100%' }}
            />
          </Col>
        </Row>

        <Row
          gutter={[8, 8]}
          justify="start"
          align="middle"
          style={{ marginBottom: 10 }}
        >
          <Col flex="100px" style={{ color: '#999999', textAlign: 'left' }}>
            难度
          </Col>
          <Col flex="auto" style={{ textAlign: 'left' }}>
            <Radio.Group
              style={{ width: 500, textAlign: 'left' }}
              onChange={onDifficultyChange}
              defaultValue={exercise?.difficulty}
            >
              <Radio value={1}>容易</Radio>
              <Radio value={2}>中等</Radio>
              <Radio value={3}>困难</Radio>
            </Radio.Group>
          </Col>
          <Col flex="200px"></Col>
        </Row>

        <Row
          gutter={[8, 8]}
          justify="start"
          align="top"
          style={{ marginBottom: 10 }}
        >
          <Col flex="100px" style={{ color: '#999999', textAlign: 'left' }}>
            题目说明
          </Col>
          <Col flex="auto" style={{ textAlign: 'left' }}>
            <TextArea
              placeholder="请输入题目说明"
              style={{ borderRadius: 3, width: '100%' }}
              autoSize={{ minRows: 5, maxRows: 10 }}
              defaultValue={exercise?.steps}
              onChange={onStepsChange}
            />
            <Row
              gutter={[8, 8]}
              justify="end"
              align="middle"
              style={{ marginTop: 10 }}
            >
              <Col flex={'auto'} style={{ textAlign: 'right' }}>
                <span style={{ fontSize: 12, color: '#ccc' }}>
                  请上传以字母、数字命名的png,jepg,jpg,gif等格式的图片,图片小于10M，最多上传8张{' '}
                </span>
              </Col>
              <Col flex="80px">
                <Upload {...uploadImageProps}>
                  <div>
                    <Button
                      size="small"
                      type="default"
                      style={{
                        border: '1px solid #07c176',
                        color: '#07c176',
                        width: 80,
                        borderRadius: 12,
                      }}
                    >
                      <UploadOutlined /> 传图{' '}
                    </Button>
                  </div>
                </Upload>
              </Col>
            </Row>

            {/*  <ReactMarkdown
                                className={exerciseStyles.reactmarkdown}
                                remarkPlugins={[gfm,remarkMath]}
                                rehypePlugins={[rehypeRaw,rehypeKatex]}
                            >
                                {mdSteps}
                           </ReactMarkdown> */}
          </Col>
        </Row>

        <Row gutter={[8, 8]} justify="start" align="middle">
          <Col span={4}>
            <div style={{ textAlign: 'left', width: 100 }}></div>
          </Col>
          <Col span={20} style={{ textAlign: 'left' }}>
            <div style={{ marginTop: 10, marginBottom: 10 }}>
              <ConfigProvider locale={zhCN}>
                <Row
                  justify="start"
                  align="middle"
                  gutter={[8, 8]}
                  style={{ marginLeft: -20 }}
                >
                  {images?.map((image: any) => {
                    return (
                      <Col key={image.name} span={6}>
                        <div className={exerciseStyles.imageWrapper}>
                          <Popconfirm
                            placement="top"
                            title={'确认删除图片吗？'}
                            okText="确认"
                            cancelText="取消"
                            onConfirm={(e) => {
                              deleteImage(image.name, this)
                            }}
                            onCancel={() => {
                              return
                            }}
                          >
                            <DeleteOutlined
                              className={exerciseStyles.iconbuttonsdel}
                            />
                          </Popconfirm>

                          <div className={exerciseStyles.imageDiv}>
                            <Image
                              width={'100%'}
                              height={100}
                              preview={false}
                              src={
                                constants.BACKEND_URL + image.path + image.name
                              }
                            />
                          </div>
                          {/*<div style={{textAlign:"center",fontSize:12,color:"#666666"}}>{image.name}</div> */}
                        </div>
                      </Col>
                    )
                  })}
                </Row>
              </ConfigProvider>
            </div>

            <div className={'new-image'}></div>
            <div id={'add-image-div'}></div>
          </Col>
        </Row>
      </div>
    )
  }

  // const data_coding_steps = [
  //     //创建实操题分步骤
  //     {
  //       title: '输入完整代码',
  //       content: <div>
  //                     <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:10}} >
  //                         <Col flex="100px" style={{color:"#999999",textAlign:'left'}}>数据集</Col>
  //                             <Col flex={"auto"} style={{textAlign:'left'}}>
  //                             <span style={{fontSize:12,color:"#ccc" }}> 请上传50M以内的csv或Excel文件，最多上传20个数据集 </span>
  //                             </Col>
  //                             <Col flex="100px" >
  //                                 <Upload {...uploadProps}>
  //                                     <div ><Button size="small" type="default" style={{border:"1px solid #07c176", color:"#07c176", width:80,borderRadius:12}} > <UploadOutlined /> 数据</Button></div>
  //                                 </Upload>
  //                             </Col>

  //                     </Row>

  //                     <Row gutter={[8,8]} justify='start'  align='middle'  >
  //                         <Col flex='100px'><span style={{marginRight:-14,verticalAlign:'top'}}></span></Col>
  //                         <Col flex="auto" style={{textAlign:'left'}}>
  //                             {datasets?.map((dataset:any, index) => {
  //                                 return (
  //                                     <Row key={dataset.id} id={"dataset-" + dataset.id} style={{textAlign: 'left', margin: '0px 0'}}>
  //                                         <Col span={24} className={exerciseStyles.fileLink} style={{display: 'inline-block', width: '100%'}} >

  //                                             <div style={{padding:5}}><PaperClipOutlined /> {dataset?.name}
  //                                             <Popconfirm placement="top"
  //                                                 title={'确认删除数据集吗？'}
  //                                                 okText="确认"
  //                                                 cancelText="取消"
  //                                                 onConfirm={(e)=>{deleteDataset(dataset.id, this)}}
  //                                                 onCancel={()=>{return ;}}
  //                                             >
  //                                                 <DeleteOutlined  className={exerciseStyles.iconfilebuttonsdel}/>
  //                                             </Popconfirm>
  //                                             </div>
  //                                         </Col>

  //                                     </Row>
  //                                 )
  //                             })}
  //                             <div  className={"new-dataset"}></div>
  //                             <div id={"add-dataset-div"}></div>
  //                         </Col>
  //                     </Row>

  //                 <div className={"oam-exercise-code-editor"} style={{marginTop:20,marginBottom:20}}>
  //                     <Row gutter={[8,8]} justify='start'  align='top'  >
  //                     <Col flex='100px'  style={{color:"#999999",textAlign:'left'}}>完整代码</Col>
  //                     <Col flex='auto'   style={{textAlign:'left'}}>
  //                         <MonacoEditor
  //                             className={exerciseStyles.codeEditor}
  //                             //width={monocoEditorWidth}
  //                             height="300px"
  //                             language={editorLanguage}
  //                             theme="vs" //"vs" | "vs-dark" | "hc-black"
  //                             value={exercise?.full_code}
  //                             options={editorOptions}
  //                             onChange={onCodeChange.bind(this, "full_code")}
  //                             //editorDidMount={editorDidMount}
  //                         />
  //                         </Col>
  //                     </Row>
  //                     <Row hidden={true} gutter={[8,8]} justify='start'  align='top' style={{marginTop:10}}>
  //                         <Col flex='100px'  style={{color:"#999999",textAlign:'left'}}></Col>
  //                         <Col flex='auto'   style={{textAlign:'left'}}>
  //                             <Button type="primary" size="small" style={{borderRadius:3}}>测试</Button>
  //                         </Col>
  //                     </Row>

  //                     <Row hidden={true} gutter={[8,8]} justify='start'  align='top' style={{marginTop:0}}>
  //                         <Col flex='100px'  style={{color:"#999999",textAlign:'left'}}></Col>
  //                         <Col flex='auto'   style={{textAlign:'left'}}>
  //                             <div className= {exerciseStyles.resultCardContainer}>
  //                                 <Tabs type="card">
  //                                 <TabPane tab="运行结果" key="outputs">
  //                                     <div style={{marginLeft:20, marginRight:20, marginBottom:10,paddingTop:0,borderRadius:5}}>
  //                                         <ReactMarkdown
  //                                             className={exerciseStyles.reactmarkdown}
  //                                             remarkPlugins={[gfm,remarkMath]}
  //                                             rehypePlugins={[rehypeKatex,rehypeRaw]}
  //                                         >
  //                                         { "haha"/*outputs.replaceAll("\n","<br/>") */}
  //                                         </ReactMarkdown>
  //                                     </div>
  //                                 </TabPane>
  //                                 <TabPane tab="变量信息" key="variables">

  //                                 </TabPane>

  //                                 </Tabs>
  //                             </div>

  //                         </Col>
  //                     </Row>

  //                 </div>

  //                 <div className={"oam-exercise-code-editor"} style={{marginBottom:20}}>
  //                     <Row gutter={[8,8]} justify='start'  align='top'  >
  //                     <Col flex='100px'  style={{color:"#999999",textAlign:'left'}}>判定代码</Col>
  //                     <Col flex='auto'   style={{textAlign:'left'}}>
  //                         <MonacoEditor
  //                             className={exerciseStyles.codeEditor}
  //                             //width={monocoEditorWidth}
  //                             height="100px"
  //                             language={editorLanguage}
  //                             theme="vs" //"vs" | "vs-dark" | "hc-black"
  //                             value={exercise?.judgement_code}
  //                             options={editorOptions}
  //                             onChange={onCodeChange.bind(this, "judgement_code")}
  //                             //editorDidMount={editorDidMount}
  //                         />
  //                         </Col>
  //                     </Row>

  //                     <Row hidden={true} gutter={[8,8]} justify='start'  align='top' style={{marginTop:10}}>
  //                         <Col flex='100px'  style={{color:"#999999",textAlign:'left'}}></Col>
  //                         <Col flex='auto'   style={{textAlign:'left'}}>
  //                             <Button type="primary" size="small" style={{borderRadius:3}}>测试</Button>
  //                         </Col>
  //                     </Row>

  //                     <Row hidden={true} gutter={[8,8]} justify='start'  align='top' style={{marginTop:10}}>
  //                         <Col flex='100px'  style={{color:"#999999",textAlign:'left'}}></Col>
  //                         <Col flex='auto'   style={{textAlign:'left'}}>
  //                             <div className= {exerciseStyles.resultCardContainer}>
  //                             <Tabs type="card">
  //                             <TabPane tab="运行结果" key="outputs">
  //                                 <div style={{marginLeft:20, marginRight:20, marginBottom:10,paddingTop:0,borderRadius:5}}>
  //                                     <ReactMarkdown
  //                                         className={exerciseStyles.reactmarkdown}
  //                                         remarkPlugins={[gfm,remarkMath]}
  //                                         rehypePlugins={[rehypeKatex,rehypeRaw]}
  //                                     >
  //                                     { "haha"/*outputs.replaceAll("\n","<br/>")*/}
  //                                     </ReactMarkdown>
  //                                 </div>
  //                             </TabPane>
  //                             <TabPane tab="变量信息" key="variables">

  //                             </TabPane>

  //                             </Tabs>
  //                         </div>

  //                         </Col>
  //                     </Row>

  //                 </div>

  //                </div>,
  //     },

  //     {
  //       title: '代码切分',
  //       content: <div>
  //                 <div style={{textAlign:"right",marginBottom:0}}> <Button  onClick={()=>{setCompleteCodeVisiable(!completeCodeVisiable)}}  type='link' style={{width:80,marginRight:10,borderRadius:20}}> 完整代码 {completeCodeVisiable ? <UpOutlined />: <DownOutlined />  } </Button></div>

  //                 <Row align="top" gutter={[8,8]}>
  //                     <Col span={completeCodeVisiable?12:24}>
  //                         <div className={"oam-exercise-code-editor"} style={{marginBottom:20}}>
  //                             <Row gutter={[8,8]} justify='start'  align='top'  >
  //                                 <Col flex='50px' style={{color:"#999999",textAlign:'left'}}>{"预设\n代码"}</Col>
  //                                 <Col flex='auto' style={{textAlign:'left'}}>
  //                                     <MonacoEditor
  //                                         className={exerciseStyles.codeEditor}
  //                                         //width={monocoEditorWidth}
  //                                         height={100}
  //                                         language={editorLanguage}
  //                                         theme="vs" //"vs" | "vs-dark" | "hc-black"
  //                                         value={exercise?.preset_code}
  //                                         options={editorOptions}
  //                                         onChange={onCodeChange.bind(this, "preset_code")}
  //                                         //editorDidMount={editorDidMount}
  //                                     />
  //                                 </Col>
  //                             </Row>
  //                             </div>

  //                             <div className={"oam-exercise-code-editor"} style={{marginBottom:20}}>
  //                             <Row gutter={[8,8]} justify='start'  align='top' >
  //                             <Col flex='50px'  style={{color:"#999999",textAlign:'left'}}> {"参考\n代码"} </Col>
  //                             <Col flex='auto'  style={{textAlign:'left'}}>
  //                                 <MonacoEditor
  //                                     className={exerciseStyles.codeEditor}
  //                                     //width={monocoEditorWidth}
  //                                     height={300}
  //                                     language={editorLanguage}
  //                                     theme="vs" //"vs" | "vs-dark" | "hc-black"
  //                                     value={exercise?.reference_answer}
  //                                     options={editorOptions}
  //                                     onChange={onCodeChange.bind(this, "reference_answer")}
  //                                     //editorDidMount={editorDidMount}
  //                                 />
  //                                 </Col>
  //                             </Row>
  //                         </div>
  //                     </Col>

  //                     { completeCodeVisiable &&
  //                     <Col span={12}>
  //                         <div className={"oam-exercise-code-editor"} style={{textAlign:"left",marginBottom:20}}>
  //                         <MonacoEditor
  //                             className={exerciseStyles.codeEditor}
  //                             //width={monocoEditorWidth}
  //                             height={426}
  //                             language={editorLanguage}
  //                             theme="vs" //"vs" | "vs-dark" | "hc-black"
  //                             value={exercise?.full_code + '\n' +  exercise?.preset_code}
  //                             options={editorReadOnlyoptions}
  //                             onChange={onCodeChange.bind(this, "preset_code")}
  //                             //editorDidMount={editorDidMount}
  //                         />
  //                         </div>
  //                     </Col>
  //                     }
  //                 </Row>

  //               </div>

  //     },
  //     {
  //       title: '代码挖空',
  //       content: <div>
  //                 <div style={{textAlign:"right",marginBottom:0}}> <Button  onClick={()=>{setAnswerCodeVisiable(!answerCodeVisiable)}}  type='link' style={{width:80,marginRight:10,borderRadius:20}}> 参考代码 {answerCodeVisiable ? <UpOutlined />: <DownOutlined />  } </Button></div>
  //                     <Row align="top" gutter={[8,8]}>
  //                         <Col span={answerCodeVisiable?12:24}>
  //                             <div className={"oam-exercise-code-editor"} style={{marginBottom:20,height:300}}>
  //                                 <Row gutter={[8,8]} justify='start'  align='top'  >
  //                                     <Col flex='50px' style={{color:"#999999",textAlign:'left'}}>{"默认\n代码"}</Col>
  //                                     <Col flex='auto'  style={{textAlign:'left'}}>
  //                                             <MonacoEditor
  //                                                 className={exerciseStyles.codeEditor}
  //                                                 //width={monocoEditorWidth}
  //                                                 height="400px"
  //                                                 language={editorLanguage}
  //                                                 theme="vs" //"vs" | "vs-dark" | "hc-black"
  //                                                 value={exercise?.default_code}
  //                                                 options={editorOptions}
  //                                                 onChange={onCodeChange.bind(this, "default_code")}
  //                                                 //editorDidMount={editorDidMount}
  //                                             />
  //                                         </Col>
  //                                 </Row>
  //                             </div>
  //                         </Col>

  //                         { answerCodeVisiable &&
  //                         <Col span={12}>

  //                             <div className={"oam-exercise-code-editor"} style={{textAlign:"left",marginBottom:20}}>

  //                                 <MonacoEditor
  //                                     className={exerciseStyles.codeEditor}
  //                                     //width={monocoEditorWidth}
  //                                     height="400"
  //                                     language={editorLanguage}
  //                                     theme="vs" //"vs" | "vs-dark" | "hc-black"
  //                                     value={exercise?.reference_answer}
  //                                     options={editorReadOnlyoptions}
  //                                     onChange={()=>{}}
  //                                 />

  //                             </div>

  //                         </Col> }
  //                     </Row>
  //                </div>,
  //     },
  //   ]

  // const next = () => {
  //     setCurrentCodingStep(currentCodingStep + 1)
  // }

  // const prev = () => {
  //     setCurrentCodingStep(currentCodingStep - 1)
  // }

  // /** 实操题 */
  // const ExerciseDataCodingForm: React.FC<Props2> = (props: Props2) => {
  //     return (

  //         <div className={exerciseStyles.editExerciseFormWrapper} >

  //             <Steps size='small' current={currentCodingStep} style={{marginBottom:20,marginTop:0}}>
  //                 {data_coding_steps.map((item) => (
  //                     <Step key={item.title} title={item.title} />
  //                 ))}
  //                 </Steps>

  //                 <div style={{minHeight:450}} >{data_coding_steps[currentCodingStep].content}</div>

  //                 <div >
  //                 {currentCodingStep > 0 && (
  //                     <Button
  //                     type="default"
  //                     size="small"
  //                     style={{marginRight:20}}
  //                     onClick={() => prev()}>
  //                     上一步
  //                     </Button>
  //                 )}
  //                 {currentCodingStep < data_coding_steps.length - 1 && (
  //                     <Button
  //                     size="small"
  //                     type="primary"
  //                     onClick={() => next()}
  //                     //disabled={!uploadSuccess}
  //                     >
  //                     下一步
  //                     </Button>
  //                 )}
  //                 </div>
  //        </div>
  //     )
  // }

  /** 实操题 */
  const ExerciseDataCodingForm: React.FC<Props2> = (props: Props2) => {
    const [activeTabkey, setActiveTabkey] = useState('1')
    const onTabkeyChange = () => {}
    return (
      <div
        className={exerciseStyles.codeEditTabContain}
        style={{
          width: '100%',
          // paddingTop: 20,
          // paddingLeft: 50,
          // paddingRight: 50,
          // paddingBottom: 20,
        }}
      >
        {/* <div
          style={{
            width: '100%',
            padding: 20,
            borderRadius: 6,
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: '#e9e9e9',
          }}
        > */}
        {/* <Row
            gutter={[8, 8]}
            justify="start"
            align="middle"
            style={{ marginBottom: 20, display: 'none' }}
          >
            <Col flex="100px" style={{ color: '#00000073', textAlign: 'left' }}>
              语言
            </Col>
            <Col flex="auto" style={{ textAlign: 'left' }}>
              <Select
                defaultValue={exercise?.language_str}
                style={{ borderRadius: 6, width: 150, textAlign: 'left' }}
                onChange={languageChange}
              >
                <Option value="1">python2</Option>
                <Option value="2">python3</Option>
                <Option value="3">r32</Option>
                <Option value="4">r33</Option>
                <Option value="5">mysql</Option>
                <Option value="6">java</Option>
                <Option value="7">scala</Option>
                <Option value="8">spark</Option>
                <Option value="9">pyspark</Option>
              </Select>
            </Col>
          </Row> */}

        {/* <Row
            gutter={[8, 8]}
            justify="start"
            align="middle"
            style={{ marginBottom: 10 }}
          >
            <Col flex="100px" style={{ color: '#00000073', textAlign: 'left' }}>
              数据集
            </Col>

            <Col
              flex="auto"
              style={{ fontSize: 12, color: '#00000073', textAlign: 'right' }}
            >
              请上传50M以内的csv或Excel文件
            </Col>
            <Col
              flex="100px"
              style={{ color: '#00000073', textAlign: 'right' }}
            >
              <Upload {...uploadProps}>
                <div>
                  <Button
                    size="small"
                    type="default"
                    style={{ width: 100, borderRadius: 20 }}
                  >
                    {' '}
                    <UploadOutlined /> 传数据
                  </Button>
                </div>
              </Upload>
            </Col>
          </Row> */}

        {/* <Row
            gutter={[8, 8]}
            justify="start"
            align="middle"
            style={{ marginBottom: datasets?.length > 0 ? 20 : 0 }}
          >
            <Col flex="100px">
              <span style={{ marginRight: -14, verticalAlign: 'top' }}></span>
            </Col>
            <Col
              flex="auto"
              style={{
                borderRadius: 6,
                borderStyle: 'dashed',
                borderWidth: datasets?.length > 0 ? 1 : 0,
                borderColor: '#e9e9e9',
                textAlign: 'left',
              }}
            >
              {datasets?.map((dataset: any, index) => {
                return (
                  <Row
                    key={dataset.id}
                    id={'dataset-' + dataset.id}
                    style={{ textAlign: 'left', margin: '10px 0' }}
                  >
                    <Col
                      span={24}
                      style={{ display: 'inline-block', width: '100%' }}
                    >
                      <div className={exerciseStyles.fileLink}>
                        <a>
                          <PaperClipOutlined /> {dataset?.name}{' '}
                        </a>
                        <Popconfirm
                          placement="top"
                          title={'确认删除数据集' + dataset.name + ' 吗？'}
                          okText="确认"
                          cancelText="取消"
                          onConfirm={(e) => {
                            deleteDataset(dataset.id, this)
                          }}
                          onCancel={() => {
                            return
                          }}
                        >
                          <CloseCircleOutlined
                            className={exerciseStyles.iconbuttonsdel}
                          />
                        </Popconfirm>
                      </div>
                    </Col>
                  </Row>
                )
              })}
              <div className={'new-dataset'}></div>
              <div id={'add-dataset-div'}></div>
            </Col>
          </Row> */}

        <Tabs>
          <Tabs.TabPane tab={'参考代码'} key="1">
            <div
              style={{ textAlign: 'left' }}
              className={'oam-exercise-code-editor'}
            >
              <MonacoEditor
                className={exerciseStyles.codeEditor}
                height="500px"
                width="100%"
                language={editorLanguage}
                theme="vs" //"vs" | "vs-dark" | "hc-black"
                value={
                  //                   exercise?.reference_answer == ''
                  //                     ? `# 这里是标准答案
                  // `
                  //                     :
                  exercise?.reference_answer
                }
                options={editorOptions}
                onChange={onCodeChange.bind(this, 'reference_answer')}
                //editorDidMount={editorDidMount}
              />
              {listenEditor()}
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab={'预设代码'} key="2">
            <div
              style={{ textAlign: 'left' }}
              className={'oam-exercise-code-editor'}
            >
              <MonacoEditor
                className={exerciseStyles.codeEditor}
                height="500px"
                language={editorLanguage}
                theme="vs" //"vs" | "vs-dark" | "hc-black"
                value={
                  //                   exercise?.preset_code == ''
                  //                     ? `# 在这里导入包
                  // `
                  //                     :
                  exercise?.preset_code
                }
                options={editorOptions}
                onChange={onCodeChange.bind(this, 'preset_code')}
                //editorDidMount={editorDidMount}
              />
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab={'默认代码'} key="3">
            <div
              style={{ textAlign: 'left' }}
              className={'oam-exercise-code-editor'}
            >
              <MonacoEditor
                //className={exerciseStyles.codeEditor}
                width="100%"
                height="500px"
                language={editorLanguage}
                theme="vs" //"vs" | "vs-dark" | "hc-black"
                value={
                  //                   exercise?.default_code == ''
                  //                     ? `# 开始答题
                  // `
                  //                     :
                  exercise?.default_code
                }
                options={editorOptions}
                onChange={onCodeChange.bind(this, 'default_code')}
                //editorDidMount={editorDidMount}
              />
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab={'判定代码'} key="4">
            <div
              style={{ textAlign: 'left' }}
              className={'oam-exercise-code-editor'}
            >
              <MonacoEditor
                className={exerciseStyles.codeEditor}
                width="100%"
                height="500px"
                language={editorLanguage}
                theme="vs" //"vs" | "vs-dark" | "hc-black"
                value={
                  //                   exercise?.judgement_code == ''
                  //                     ? `# 这里是判定函数
                  // def __bysz_judge__():
                  //     print("{success_mark}")
                  // `
                  //                     :
                  exercise?.judgement_code
                }
                options={editorOptions}
                onChange={onCodeChange.bind(this, 'judgement_code')}
                //editorDidMount={editorDidMount}
              />
            </div>
          </Tabs.TabPane>
        </Tabs>

        {/* <div
            className={'oam-exercise-code-editor'}
            style={{ marginBottom: 20 }}
          >
            <Row
              gutter={[8, 8]}
              justify="start"
              align="middle"
              style={{ marginBottom: 20 }}
            >
              <Col span={14} style={{ color: '#00000073', textAlign: 'left' }}>
                完整答题流程 - 参考答案
              </Col>
              <Col span={10}>
                <Button
                  type="primary"
                  onClick={() => setVisible(true)}
                  style={{
                    display: 'none',
                    float: 'right',
                    backgroundColor: '#fff',
                    color: '#00000073',
                    border: '1px solid #00000073',
                    borderRadius: '10px',
                    textAlign: 'right',
                  }}
                >
                  help
                </Button>
                <Image
                  width={200}
                  style={{ display: 'none' }}
                  src=""
                  preview={{
                    visible,
                    src: '',
                    onVisibleChange: (value) => {
                      setVisible(value)
                    },
                  }}
                />
              </Col>
              <Col span={24} style={{ textAlign: 'left' }}>
                <MonacoEditor
                  className={exerciseStyles.codeEditor}
                  height="300px"
                  width="100%"
                  language={editorLanguage}
                  theme="vs" //"vs" | "vs-dark" | "hc-black"
                  value={
                    exercise?.reference_answer == ''
                      ? `# 这里是标准答案
`
                      : exercise?.reference_answer
                  }
                  options={editorOptions}
                  onChange={onCodeChange.bind(this, 'reference_answer')}
                  //editorDidMount={editorDidMount}
                />
              </Col>
              {listenEditor()}
            </Row>
          </div>

          <div
            className={'oam-exercise-code-editor'}
            style={{ marginBottom: 20 }}
          >
            <Row
              gutter={[8, 8]}
              justify="start"
              align="middle"
              style={{ marginBottom: 20 }}
            >
              <Col span={14} style={{ color: '#00000073', textAlign: 'left' }}>
                题目环境预设
              </Col>
              <Col span={10}>
                <Button
                  type="primary"
                  onClick={() => setVisible(true)}
                  style={{
                    display: 'none',
                    float: 'right',
                    backgroundColor: '#fff',
                    color: '#00000073',
                    border: '1px solid #00000073',
                    borderRadius: '10px',
                    textAlign: 'right',
                  }}
                >
                  help
                </Button>
                <Image
                  width={200}
                  style={{ display: 'none' }}
                  src=""
                  preview={{
                    visible,
                    src: '',
                    onVisibleChange: (value) => {
                      setVisible(value)
                    },
                  }}
                />
              </Col>
              <Col span={24} style={{ textAlign: 'left' }}>
                <MonacoEditor
                  className={exerciseStyles.codeEditor}
                  height="100px"
                  language={editorLanguage}
                  theme="vs" //"vs" | "vs-dark" | "hc-black"
                  value={
                    exercise?.preset_code == ''
                      ? `# 在这里导入包
`
                      : exercise?.preset_code
                  }
                  options={editorOptions}
                  onChange={onCodeChange.bind(this, 'preset_code')}
                  //editorDidMount={editorDidMount}
                />
              </Col>
            </Row>
          </div>

          <div
            className={'oam-exercise-code-editor'}
            style={{ marginBottom: 20 }}
          >
            <Row
              gutter={[8, 8]}
              justify="start"
              align="middle"
              style={{ marginBottom: 20 }}
            >
              <Col span={14} style={{ color: '#00000073', textAlign: 'left' }}>
                学生答题框挖空
              </Col>
              <Col span={10}>
                <Button
                  type="primary"
                  onClick={() => setVisible(true)}
                  style={{
                    display: 'none',
                    float: 'right',
                    backgroundColor: '#fff',
                    color: '#00000073',
                    border: '1px solid #00000073',
                    borderRadius: '10px',
                    textAlign: 'right',
                  }}
                >
                  help
                </Button>
                <Image
                  width={200}
                  style={{ display: 'none' }}
                  src=""
                  preview={{
                    visible,
                    src: '',
                    onVisibleChange: (value) => {
                      setVisible(value)
                    },
                  }}
                />
              </Col>
              <Col span={24} style={{ textAlign: 'left' }}>
                <MonacoEditor
                  //className={exerciseStyles.codeEditor}
                  width="100%"
                  height="300px"
                  language={editorLanguage}
                  theme="vs" //"vs" | "vs-dark" | "hc-black"
                  value={
                    exercise?.default_code == ''
                      ? `# 开始答题
`
                      : exercise?.default_code
                  }
                  options={editorOptions}
                  onChange={onCodeChange.bind(this, 'default_code')}
                  //editorDidMount={editorDidMount}
                />
              </Col>
            </Row>
          </div>

          <div
            className={'oam-exercise-code-editor'}
            style={{ marginBottom: 20 }}
          >
            <Row
              gutter={[8, 8]}
              justify="start"
              align="middle"
              style={{ marginBottom: 20 }}
            >
              <Col span={14} style={{ color: '#00000073', textAlign: 'left' }}>
                答案判定函数
              </Col>
              <Col span={10}>
                <Button
                  type="primary"
                  onClick={() => setVisible(true)}
                  style={{
                    display: 'none',
                    float: 'right',
                    backgroundColor: '#fff',
                    color: '#00000073',
                    border: '1px solid #00000073',
                    borderRadius: '10px',
                    textAlign: 'right',
                  }}
                >
                  help
                </Button>
                <Image
                  width={200}
                  style={{ display: 'none' }}
                  src=""
                  preview={{
                    visible,
                    src: '',
                    onVisibleChange: (value) => {
                      setVisible(value)
                    },
                  }}
                />
              </Col>
              <Col span={24} style={{ textAlign: 'left' }}>
                <MonacoEditor
                  className={exerciseStyles.codeEditor}
                  width="100%"
                  height="200px"
                  language={editorLanguage}
                  theme="vs" //"vs" | "vs-dark" | "hc-black"
                  value={
                    exercise?.judgement_code == ''
                      ? `# 这里是判定函数
def __bysz_judge__():
    return True
`
                      : exercise?.judgement_code
                  }
                  options={editorOptions}
                  onChange={onCodeChange.bind(this, 'judgement_code')}
                  //editorDidMount={editorDidMount}
                />
              </Col>
            </Row>
          </div> */}
        {/* </div> */}
      </div>
    )
  }

  /** 单选题和多选题 */
  const ExerciseSelectForm: React.FC<Props2> = (props: Props2) => {
    return (
      <div className={exerciseStyles.editExerciseFormWrapper}>
        <Row gutter={[8, 8]} justify="start" align="middle">
          <Col flex="100px" style={{ color: '#999999', textAlign: 'left' }}>
            编辑选项
          </Col>
          <Col flex="auto" style={{ textAlign: 'left' }}>
            {options.map((option) => {
              return (
                <Row
                  key={'div-' + option.id}
                  gutter={[8, 8]}
                  justify="start"
                  align="middle"
                  style={{ marginBottom: 20 }}
                >
                  <Col flex="30px">
                    {' '}
                    {exerciseType == 2 ? (
                      <Radio
                        name={'opitons'}
                        checked={optionsAnswer[0] == option.id ? true : false}
                        value={option.id}
                        onChange={onSingleChoiceChange}
                      ></Radio>
                    ) : exerciseType == 3 ? (
                      <Checkbox
                        defaultChecked={
                          optionsAnswer.indexOf(parseInt(option.id)) >= 0
                            ? true
                            : false
                        }
                        onChange={onMultipleChoiceChange.bind(this, option.id)}
                      ></Checkbox>
                    ) : (
                      ''
                    )}
                  </Col>
                  <Col flex="50px">选项{option.id}</Col>
                  <Col flex="auto" style={{ textAlign: 'left' }}>
                    <TextArea
                      style={{ width: '100%', borderRadius: 3 }}
                      defaultValue={option?.options}
                      autoSize={{ minRows: 1, maxRows: 3 }}
                      onChange={onOptionsChange.bind(this, option.id)}
                    />
                  </Col>
                </Row>
              )
            })}
          </Col>
        </Row>
        <div style={{ marginBottom: 20 }} className={'new-options'}></div>
        <div
          style={{ marginBottom: 20 }}
          className={'add-options'}
          id={'add-options-btn'}
          hidden={!(options.length < 4)}
        >
          <span
            style={{ marginRight: 20, marginLeft: -28, verticalAlign: 'top' }}
          ></span>
          <span style={{ width: 300 }}>
            <Button
              type="primary"
              style={{ borderRadius: 6 }}
              onClick={appendOptions}
            >
              添加选项
            </Button>
          </span>
        </div>

        <Row
          gutter={[8, 8]}
          justify="start"
          align="top"
          style={{ marginBottom: 20 }}
        >
          <Col flex="100px" style={{ color: '#999999', textAlign: 'left' }}>
            答案解析
          </Col>
          <Col flex="auto" style={{ textAlign: 'left' }}>
            <TextArea
              placeholder="请输入答案解析"
              autoSize={{ minRows: 2, maxRows: 5 }}
              style={{ width: '100%', borderRadius: 3 }}
              defaultValue={exercise?.answer_parse}
              onChange={onTextChange.bind(this, 'answer_parse')}
            />
          </Col>
        </Row>
      </div>
    )
  }

  /** 排序题 */
  const ExerciseSortForm: React.FC<Props2> = (props: Props2) => {
    return (
      <div className={exerciseStyles.editExerciseFormWrapper}>
        <Row gutter={[8, 8]} justify="start" align="top">
          <Col flex="100px" style={{ color: '#00000073', textAlign: 'left' }}>
            编辑选项
          </Col>
          <Col flex="auto" style={{ textAlign: 'left' }}>
            {options.map((option) => {
              return (
                <Row
                  key={'div-' + option.id}
                  gutter={[8, 8]}
                  justify="start"
                  align="middle"
                  style={{ marginBottom: 20 }}
                >
                  <Col flex="30px">
                    <Checkbox
                      defaultChecked={
                        optionsAnswer.indexOf(parseInt(option.id)) >= 0
                          ? true
                          : false
                      }
                      onChange={onSortChange.bind(this, option.id)}
                    ></Checkbox>
                  </Col>
                  <Col flex="50px">选项{option.id}</Col>
                  <Col flex="auto" style={{ textAlign: 'left' }}>
                    <TextArea
                      style={{ width: '100%', borderRadius: 3 }}
                      defaultValue={option?.options}
                      autoSize={{ minRows: 1, maxRows: 3 }}
                      onChange={onOptionsChange.bind(this, option.id)}
                    />
                  </Col>

                  <Col flex="80px" style={{ textAlign: 'right' }}>
                    <Button
                      danger
                      style={{ width: '100%', borderRadius: 3 }}
                      onClick={onSortOptionsDel.bind(this, option.id)}
                    >
                      删除
                    </Button>
                  </Col>
                </Row>
              )
            })}
          </Col>
        </Row>
        <div style={{ marginBottom: 0 }} className={'new-sort-options'}></div>
        <div
          style={{ marginBottom: 20 }}
          className={'add-sort-options'}
          id={'add-sort-options-btn'}
        >
          <span
            style={{ marginRight: 0, marginLeft: -28, verticalAlign: 'top' }}
          ></span>
          <span style={{ width: 300 }}>
            <Button
              type="primary"
              style={{ borderRadius: 20 }}
              onClick={appendSortOptions}
            >
              添加选项
            </Button>
          </span>
        </div>

        <Row
          gutter={[8, 8]}
          justify="start"
          align="top"
          style={{ marginBottom: 20 }}
        >
          <Col flex="100px" style={{ color: '#00000073', textAlign: 'left' }}>
            正确顺序
          </Col>
          <Col flex="auto" style={{ textAlign: 'left' }}>
            <Input
              id={'answer-sort'}
              readOnly={true}
              defaultValue={optionsAnswer}
              style={{ width: '100%', borderRadius: 3 }}
              placeholder="勾选选项前面的复选框改变答案排序"
              onChange={onTextChange.bind(this, 'options_answer')}
            />
          </Col>
        </Row>
        <Row
          gutter={[8, 8]}
          justify="start"
          align="top"
          style={{ marginBottom: 20 }}
        >
          <Col flex="100px" style={{ color: '#00000073', textAlign: 'left' }}>
            答案解析
          </Col>
          <Col flex="auto" style={{ textAlign: 'left' }}>
            <TextArea
              placeholder="请输入答案解析"
              autoSize={{ minRows: 3, maxRows: 5 }}
              style={{ width: '100%', borderRadius: 3 }}
              defaultValue={exercise?.answer_parse}
              onChange={onTextChange.bind(this, 'answer_parse')}
            />
          </Col>
        </Row>
      </div>
    )
  }

  /** 主观题 */
  const ExerciseSubjectiveForm: React.FC<Props2> = (props: Props2) => {
    return (
      <div className={exerciseStyles.editExerciseFormWrapper}>
        <Row
          gutter={[8, 8]}
          justify="start"
          align="top"
          style={{ marginBottom: 20 }}
        >
          <Col flex="100px" style={{ color: '#00000073', textAlign: 'left' }}>
            参考答案
          </Col>
          <Col flex="auto" style={{ textAlign: 'left' }}>
            <TextArea
              placeholder="请输入参考答案"
              autoSize={{ minRows: 3, maxRows: 5 }}
              style={{ width: '100%', borderRadius: 3 }}
              defaultValue={exercise?.answer_parse}
              onChange={onTextChange.bind(this, 'answer_parse')}
            />
          </Col>
        </Row>
      </div>
    )
  }

  /** 题目输入框 */
  const ExerciseForm: React.FC<Props2> = (props: Props2) => {
    const { dataset } = props
    const [activeTabKey, setActiveTabKey] = useState('info')
    // const [datasetInfo, setDatasetInfo] = useState<any>()
    const [tipModalVis, setTipModalVis] = useState(false)
    const [tip, setTip] = useState('')

    const tabKeyChange = (key) => {
      setActiveTabKey(key)
    }

    if (exerciseType == 1) {
      return (
        <>
          <Tabs activeKey={activeTabKey} onChange={tabKeyChange}>
            <Tabs.TabPane tab="题目信息" key={'info'}>
              <div style={{ minHeight: '500px' }}>
                <ExerciseBasicForm />
              </div>
            </Tabs.TabPane>
            {dataset && (
              <Tabs.TabPane tab="题目数据集" key={'dataset'}>
                <div style={{ minHeight: '500px' }}>
                  <FileList
                    datasetInfo={dataset}
                    actionPath={location.pathname + location.search}
                  />
                </div>
              </Tabs.TabPane>
            )}

            <Tabs.TabPane
              tab={
                <Row align={'middle'} wrap={false} style={{ gap: '5px' }}>
                  <Col>{'代码'}</Col>{' '}
                  <Col
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      sendHttpPostRequest(
                        'exercise/api/get_exercise_fill_helper_doc/',
                        {},
                        (res) => {
                          if (res.code == 0) {
                            setTip(res.markdown_doc)
                            setTipModalVis(true)
                          } else {
                            message.error(res.message)
                          }
                        }
                      )
                    }}
                  >
                    <img
                      src={formatIcon}
                      style={{ width: 16, height: 16, marginTop: -2 }}
                      alt=""
                    />
                  </Col>
                </Row>
              }
              key={'code'}
            >
              <div style={{ minHeight: '500px' }}>
                <ExerciseDataCodingForm />
              </div>
            </Tabs.TabPane>
          </Tabs>
          <ModalDrag
            width={720}
            modalTitle={'提示'}
            open={tipModalVis}
            onCancel={() => {
              setTipModalVis(false)
            }}
            modalContent={
              <div style={{ maxHeight: '500px', overflow: 'auto' }}>
                <ReactMarkdown
                  remarkPlugins={[
                    [remarkGfm, { singleTilde: false }],
                    [remarkMath],
                  ]}
                  rehypePlugins={[rehypeKatex, rehypeRaw]}
                  components={{
                    code({ node, inline, className, children, ...props }) {
                      return !inline ? (
                        <SyntaxHighlighter
                          // children={}
                          style={oneLight}
                          language={'javascript'}
                          PreTag="div"
                          {...props}
                        >
                          {String(children).replace(/\n$/, '')}
                        </SyntaxHighlighter>
                      ) : (
                        <code className={className} {...props}>
                          {children}
                        </code>
                      )
                    },
                  }}
                >
                  {tip}
                </ReactMarkdown>
              </div>
            }
            footer={null}
          />
        </>
      )
    } else if (exerciseType == 2 || exerciseType == 3) {
      return (
        <>
          <ExerciseBasicForm />
          <ExerciseSelectForm />
        </>
      )
    } else if (exerciseType == 4) {
      return (
        <>
          <ExerciseBasicForm />
          <ExerciseSortForm />
        </>
      )
    } else if (exerciseType == 5) {
      return (
        <>
          <ExerciseBasicForm />
          <ExerciseSubjectiveForm />
        </>
      )
    } else {
      return <></>
    }
  }

  return (
    <Layout style={{ minHeight: 750, backgroundColor: '#f9f9f9' }}>
      <div
        className={exerciseStyles.exercisePreview}
        style={{
          paddingTop: 20,
          width: 900,
          marginTop: 30,
          backgroundColor: '#fff',
        }}
      >
        <Row align="middle">
          <Col
            span={12}
            style={{ textAlign: 'left', marginBottom: 10, width: '100%' }}
          >
            <div
              style={{
                marginLeft: 40,
                fontWeight: 600,
                fontSize: 18,
                color: '#333333',
              }}
            >
              {' '}
              创建题目
              <span style={{ color: '#07c176' }}>
                {' -【' + renderExerciseType(exercise?.type) + '】'}
              </span>
            </div>
          </Col>
          <Col
            span={12}
            style={{ textAlign: 'right', marginBottom: 10, width: '100%' }}
          >
            <Button
              style={{ marginRight: 40, color: '#333333' }}
              onClick={() => {
                window.location.href = '/exercise'
              }}
              type="link"
            >
              {' '}
              {'返回 >'}{' '}
            </Button>
          </Col>
        </Row>
        <Divider style={{ marginTop: 0 }} />

        <div
          style={{
            paddingLeft: 50,
            paddingRight: 50,
            marginTop: 30,
            backgroundColor: '#fff',
          }}
        >
          <ExerciseForm dataset={datasetInfo} />
        </div>

        <Row
          gutter={[8, 8]}
          justify="start"
          align="middle"
          style={{ marginBottom: 10 }}
        >
          <Col style={{ textAlign: 'center' }} span={24}>
            <Button
              className={exerciseStyles.savePreBtn}
              type="default"
              style={{
                border: '1px solid #07c176',
                color: '#07c176',
                marginRight: 30,
              }}
              onClick={onLookOverBtnClick}
            >
              预览
            </Button>
            <Button
              className={exerciseStyles.savePreBtn}
              type="primary"
              onClick={saveExercise}
            >
              保存
            </Button>
          </Col>
        </Row>
      </div>
    </Layout>
  )
}

export default ExerciseEdit
