import {
  Button,
  Col,
  Pagination,
  Row,
  Table,
  Tabs,
  Tooltip,
  message,
} from 'antd'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import styles from './add-college-stu-modal.module.less'
import idataCodingStylesDark from '@/pages/common/global-styles/idatacoding-dark.module.less'
// import SearchInput from '@/pages/components/SearchInput/SearchInput'
import Progress from 'antd/es/progress'
import Dragger from 'antd/lib/upload/Dragger'
import Cookies from 'js-cookie'
import type { UploadProps } from 'antd'
import * as contants from '@/constants/constants'
import axios from 'axios'
import { download } from '@/pages/common/utils/util'
import { DownloadOutlined } from '@ant-design/icons'
import Qs from 'qs'
import ModalDrag from 'component/ModaDrag/ModalDrag'
import { sendHttpPostRequest } from 'api/http-request'

interface AddCollegeStuModalProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  theme: string
  onCancel: () => void
  college_id?: any
  classroom_id?: any
  actionCallback: () => void
}

export const AddCollegeStudentModal = (props: AddCollegeStuModalProps) => {
  const {
    open,
    setOpen,
    theme,
    onCancel,
    college_id,
    classroom_id,
    actionCallback,
  } = props
  const page = useRef(1)
  const pageSize = useRef(10)
  const query = useRef('')
  const [total, setTotal] = useState(0)
  const [collegeStuList, setCollegeStuList] = useState()
  const [tabActivekey, setTabActivekey] = useState('1')
  const [isUploading, setIsUploading] = useState(false)
  const [uploadName, setUploadName] = useState('')
  const [uploadStatus, setUploadStatus] = useState('') // ? uploading done error
  const [uploadPercent, setUploadPercent] = useState<any>(0)

  const [readFileStatus, setReadFileStatus] = useState('') // ? reading done error
  const [readFilePercent, setReadFilePercent] = useState(0)

  const [createUsersStatus, setCreateUsersStatus] = useState('') // ? creating done error
  const [createUsersPercent, setCreateUsersPercent] = useState(0)
  const [fileList, setFileList] = useState([] as any)
  const [filePath, setFilePath] = useState<any>('')
  const [errorMessage, setErrorMessage] = useState('')
  const [selectRowKeys, setSelectRowKeys] = useState([] as any)

  // ! 创建用户
  const createUsers = (file_path: any) => {
    setCreateUsersStatus('creating')
    let timeout = setTimeout(() => {
      setCreateUsersPercent(createUsersPercent + 10)
      if (readFilePercent < 100) {
        // 如果还需要继续执行，重新设置定时器
        timeout = setTimeout(() => {
          setCreateUsersPercent(createUsersPercent + 10)
        }, 500)
      }
    }, 500)
    // 取消定时器
    clearTimeout(timeout)
    let data = {
      file_path: file_path,
      college_id: college_id,
    }
    sendHttpPostRequest(
      'college/api/manager_batch_import_users_by_file_path',
      data,
      (res) => {
        if (res.code == 0) {
          setCreateUsersStatus('done')
          setCreateUsersPercent(100)
        } else if (res.code == -2) {
          setCreateUsersStatus('error')
          setFilePath(res.data.feedback_file_path)
          setErrorMessage(res.msg)
        } else {
          setCreateUsersStatus('error')
          setErrorMessage(res.msg)
        }
      }
    )
  }
  // ! 读取文件
  const readingFile = (file_path: any) => {
    let data = {
      file_path: file_path,
    }

    setReadFileStatus('reading')
    let timeout = setTimeout(() => {
      setReadFilePercent(readFilePercent + 20)
      if (readFilePercent < 100) {
        // 如果还需要继续执行，重新设置定时器
        timeout = setTimeout(() => {
          setReadFilePercent(readFilePercent + 20)
        }, 500)
      }
    }, 500)
    // 取消定时器
    clearTimeout(timeout)

    try {
      sendHttpPostRequest(
        'college/api/manager_check_batch_import_users_file',
        data,
        (res) => {
          if (res.code == 0) {
            setReadFileStatus('done')
            setReadFilePercent(100)
            createUsers(file_path)
            // TODO: 下一步创建用户
          } else if (res.code == -2) {
            setReadFileStatus('error')
            setFilePath(res.data.feedback_file_path)
            setErrorMessage(res.msg)
          } else {
            setReadFileStatus('error')
            setErrorMessage(res.msg)
          }
        }
      )
    } catch (error) {
      setReadFileStatus('error')
    }
  }

  const uploadData = {
    sessionid: Cookies.get('sessionid'),
    sid: Cookies.get('sessionid'),
  }
  const uploadProps: UploadProps = {
    name: 'users_file',
    multiple: false,
    showUploadList: false,
    data: uploadData,
    accept: '.xlsx,.xls',
    fileList: fileList,
    action:
      contants.BACKEND_URL +
      '/college/api/manager_batch_import_users_upload_file',
    onChange(info) {
      const { status, percent, name } = info.file

      setFileList(info.fileList)
      if (status !== 'uploading') {
      }
      if (status == 'uploading') {
        setIsUploading(true)
        setUploadPercent(percent?.toFixed())
        setUploadName(name)
        setUploadStatus(status)
      }
      if (status === 'done') {
        if (info.file.response.code == 0) {
          message.success(`${info.file.name} 文件上传成功`)
          setUploadStatus(status)
          console.log(info)

          readingFile(info.file.response.data.file_path)
        } else {
          setUploadStatus('error')
          setErrorMessage(info.file.response.msg)
        }
      } else if (status === 'error') {
        setUploadStatus(status)
        message.error(`${info.file.name} 文件上传失败`)
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files)
    },
  }

  const downloadTemplate = () => {
    sendHttpPostRequest(
      'college/api/manager_download_batch_import_users_template_xlsx_file',
      {},
      (res) => {
        download(res, 'application/excel;chartset=UTF-8', '用户模板.xlsx')
      },
      { responseType: 'blob' }
    )
  }

  const retryToUpload = () => {
    // TODO: 重新上传
    setFileList([])
    setUploadStatus('')
    setReadFileStatus('')
    setCreateUsersStatus('')
    setReadFilePercent(0)
    setUploadPercent(0)
    setCreateUsersPercent(0)
    setIsUploading(false)
    setFilePath('')
  }

  const addStuModalContent = (
    <div className={styles.modalContain}>
      <div className={styles.uploadContain}>
        <div className={styles.progressContain} hidden={!isUploading}>
          <Row
            align={'middle'}
            justify={'space-between'}
            style={{
              height: '40px',
              borderBottom: '1px solid #eee',
              marginBottom: '10px',
            }}
          >
            <Col>文件名</Col>
            <Col>状态</Col>
          </Row>
          <div className={styles.progress}>
            {/* TODO: 上传文件 */}
            <div>
              {' '}
              <Row style={{ width: '100%', gap: '10px' }} align={'middle'}>
                <Col>
                  <span
                    style={{ color: '#4285F4', fontSize: '22px' }}
                    className={'iconfont icon_xuhao1'}
                  ></span>
                </Col>
                <Col>上传文件</Col>
              </Row>
              <Row
                align={'middle'}
                justify={'space-between'}
                style={{ height: '40px', width: '592px' }}
              >
                <Col>{uploadName}</Col>
                <Col className={styles.ColFlex}>
                  <div
                    className={
                      uploadStatus == 'done'
                        ? styles.uploadComplete
                        : uploadStatus == 'error'
                        ? styles.uploadError
                        : ''
                    }
                  ></div>
                  {uploadStatus == 'uploading' && (
                    <span
                      style={{ color: '#4285F4' }}
                      className="iconfont icon_zhengzaitingzhi"
                    ></span>
                  )}
                  <span>
                    {uploadStatus == 'uploading'
                      ? '上传中'
                      : uploadStatus == 'done'
                      ? '上传成功'
                      : uploadStatus == 'error'
                      ? '上传失败'
                      : ''}
                  </span>
                </Col>
              </Row>
              <Progress
                showInfo={false}
                percent={uploadPercent}
                status={'active'}
                strokeColor={'#025393'}
              />
            </div>
            {/* TODO: 读取文件 */}
            <div>
              {' '}
              <Row style={{ width: '100%', gap: '10px' }} align={'middle'}>
                <Col>
                  <span
                    style={{ color: '#4285F4', fontSize: '22px' }}
                    className={'iconfont icon_xuhao2'}
                  ></span>
                </Col>
                <Col>读取文件</Col>
              </Row>
              <Row
                align={'middle'}
                justify={'space-between'}
                style={{ height: '40px', width: '592px' }}
              >
                <Col>{uploadName}</Col>
                <Col className={styles.ColFlex}>
                  <div
                    className={
                      readFileStatus == 'done'
                        ? styles.uploadComplete
                        : readFileStatus == 'error'
                        ? styles.uploadError
                        : ''
                    }
                  ></div>
                  {readFileStatus == 'reading' && (
                    <span
                      style={{ color: '#4285F4' }}
                      className="iconfont icon_zhengzaitingzhi"
                    ></span>
                  )}
                  <span>
                    {readFileStatus == 'reading'
                      ? '读取中'
                      : readFileStatus == 'done'
                      ? '读取成功'
                      : readFileStatus == 'error'
                      ? '读取失败'
                      : ''}
                  </span>
                </Col>
              </Row>
              <Progress
                showInfo={false}
                percent={readFilePercent}
                status={'active'}
                strokeColor={'#025393'}
              />
            </div>
            {/* TODO: 创建用户 */}
            <div>
              {' '}
              <Row style={{ width: '100%', gap: '10px' }} align={'middle'}>
                <Col>
                  <span
                    style={{ color: '#4285F4', fontSize: '22px' }}
                    className={'iconfont icon_xuhao3'}
                  ></span>
                </Col>
                <Col>创建用户</Col>
              </Row>
              <Row
                align={'middle'}
                justify={'space-between'}
                style={{ height: '40px', width: '592px' }}
              >
                <Col>{uploadName}</Col>
                <Col className={styles.ColFlex}>
                  <div
                    className={
                      createUsersStatus == 'done'
                        ? styles.uploadComplete
                        : createUsersStatus == 'error'
                        ? styles.uploadError
                        : ''
                    }
                  ></div>
                  {createUsersStatus == 'creating' && (
                    <span
                      style={{ color: '#4285F4' }}
                      className="iconfont icon_zhengzaitingzhi"
                    ></span>
                  )}
                  <span>
                    {createUsersStatus == 'creating'
                      ? '创建中'
                      : createUsersStatus == 'done'
                      ? '创建成功'
                      : createUsersStatus == 'error'
                      ? '创建失败'
                      : ''}
                  </span>
                </Col>
              </Row>
              <Progress
                showInfo={false}
                percent={createUsersPercent}
                status={'active'}
                strokeColor={'#025393'}
              />
            </div>
          </div>
        </div>

        <div hidden={isUploading} style={{ height: '400px' }}>
          <Dragger {...uploadProps}>
            <h2 className={styles.importTitle}>
              导入之前，请先
              <a
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  downloadTemplate()
                }}
                type="link"
                style={{ fontSize: '18px', padding: 0, color: '#025393' }}
              >
                下载模版
              </a>
              ～
            </h2>

            <Button
              style={{ marginTop: '80px', backgroundColor: '#025393' }}
              type="primary"
            >
              上传
            </Button>
            <h2>将文件拖到此处，或点击上传</h2>
            <div className={styles.tip}>
              已注册用户请从平台添加，本地导入将会自动为未注册的用户创建账号，默认密码
              <span className={styles.highlight}>123456</span>
            </div>
            <span className={styles.tip}>
              只能上传xls/xlsx 文件，工作项数量限制3000条，数据文件大小限制为10M
            </span>
          </Dragger>
        </div>
      </div>
      {(uploadStatus == 'error' ||
        readFileStatus == 'error' ||
        createUsersStatus == 'error') && (
        <Row
          align={'middle'}
          justify={'space-between'}
          style={{ width: '100%', padding: '20px 34px' }}
        >
          <Col className={styles.errorTip}>
            <span className="iconfont icon-shibai1"></span>
            {errorMessage}
          </Col>
          {filePath && (
            <Col>
              <div
                className={styles.btnBox}
                onClick={() => {
                  let data = {
                    sessionid: Cookies.get('sessionid'),
                    file_path: filePath,
                  }

                  sendHttpPostRequest(
                    'college/api/manager_download_batch_import_users_feedback_files',
                    data,
                    (res) => {
                      download(
                        res,
                        'application/excel;chartset=UTF-8',
                        (readFileStatus == 'error' ? '读取' : '创建') +
                          '错误反馈数据.xlsx'
                      )
                    },
                    { responseType: 'blob' }
                  )
                }}
              >
                <DownloadOutlined style={{ color: '#999' }} />
                {/* <span
                        style={{ color: '#999999' }}
                        className="iconfont icon_xiazai_moren"
                      ></span> */}
              </div>
            </Col>
          )}
        </Row>
      )}
    </div>
  )
  return (
    <ModalDrag
      width={720}
      modalTitle={'导入用户'}
      modalContent={addStuModalContent}
      open={open}
      theme={theme}
      onCancel={() => {
        retryToUpload()
        setOpen(false)
      }}
      footer={
        uploadStatus ? (
          <Row align="middle" justify="center" style={{ gap: '50px' }}>
            <Col>
              <Button
                onClick={() => {
                  retryToUpload()
                  setOpen(false)
                }}
                style={
                  theme == 'blue'
                    ? {
                        width: '64px',
                        padding: '0px',
                        borderColor: '#025393',
                        color: '#025393',
                      }
                    : { width: '64px', padding: '0px' }
                }
              >
                取消
              </Button>
            </Col>
            {uploadStatus == 'done' &&
            createUsersStatus == 'done' &&
            readFileStatus == 'done' ? (
              <Col>
                <Button
                  style={
                    theme == 'blue'
                      ? {
                          width: '64px',
                          padding: '0px',
                          backgroundColor: '#025393',
                          color: '#fff',
                        }
                      : { width: '64px', padding: '0px' }
                  }
                  type="primary"
                  onClick={() => {
                    try {
                      setSelectRowKeys([])
                      setOpen(false)
                      actionCallback && actionCallback()
                      setFileList([])
                      setUploadStatus('')
                      setReadFileStatus('')
                      setCreateUsersStatus('')
                      setReadFilePercent(0)
                      setUploadPercent(0)
                      setCreateUsersPercent(0)
                      setIsUploading(false)
                      setFilePath('')
                    } catch (e) {}
                  }}
                >
                  确定
                </Button>
              </Col>
            ) : (
              <Button
                disabled={
                  !(
                    uploadStatus == 'error' ||
                    readFileStatus == 'error' ||
                    createUsersStatus == 'error'
                  )
                }
                type="primary"
                onClick={retryToUpload}
                style={
                  theme == 'blue'
                    ? {
                        width: '64px',
                        padding: '0px',
                        backgroundColor: '#025393',
                        color: '#fff',
                      }
                    : { width: '64px', padding: '0px' }
                }
              >
                重新上传
              </Button>
            )}
          </Row>
        ) : (
          false
        )
      }
    />
  )
}

export default AddCollegeStudentModal
