// constants.tsx 定义常量

// export const BACKEND_API_URL = 'http://127.0.0.1:9099/';   // 后端API地址
export const BACKEND_API_URL = window && window?.myUrl.URL + "/";   // 后端API地址


// export const BACKEND_URL = 'http://127.0.0.1:9099';
export const BACKEND_URL =window && window?.myUrl.URL;

// export const MEDIA_CORS_URL = 'http://127.0.0.1:9099';
export const MEDIA_CORS_URL = window && window?.myUrl.CORS;
// export const MEDIA_CORS_URL_WWW = 'http://127.0.0.1:9099';
export const MEDIA_CORS_URL_WWW =window && window?.myUrl.CORSWWW;

// export const WS_BACKEND_URL = 'ws://127.0.0.1:9099';
export const WS_BACKEND_URL =window && window?.myUrl.WSURL;

export const NOTEBOOK_BACKEND_URL = "http://case.idatascience.cn/"; //实验notebook服务地址
//export const NOTEBOOK_BACKEND_URL = "http://127.0.0.1:8888/"; //实验notebook服务地址


