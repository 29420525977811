import {Pagination, Layout, message, ConfigProvider, Input, Table, Modal, Button, Row, Statistic, Col, Popconfirm} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useState, useRef, useEffect } from "react";
import Cookies from 'js-cookie';
import zhCN from 'antd/lib/locale/zh_CN';
import {sendHttpPostRequest} from "../../../api/http-request";
const { Search } = Input;
import TextArea from 'antd/lib/input/TextArea';
import OAMSider from "../oam-sider";
import {BACKEND_API_URL, BACKEND_URL } from '../../../constants/constants';

import idataCourseTableStyles from "../../common/global-styles/idatacourse-table.module.less";

export interface Props {}
const statusMap = new Map([[1, '下线'], [2, '在线']]);
const paidMap = new Map([[1, '未付费'], [2, '已付费']]);


export const OAMInstitutionList: React.FC<Props> = (props) => {
    const [userResult, setUserResult] = useState([] as any[]);  // 当前显示的结果页
    const [resultSum, setResultSum] = useState(0);  // 搜索返回的结果数
    const [onlineCount, setOnlineCount] = useState(0);  // 上线的机构数
    const [totalCount, setTotalCount] = useState(0);  // 搜索返回的结果数
    const [paidCount, setPaidCount] = useState(0);//已付费机构数
    const [isEditUserModalVisible, setIsEditUserModalVisible] = useState(false);  // 模态框是否显示
    const [editUserId, setEditUserId] = useState(-1);  // 待编辑备注的用户ID
    const [editUserRemark, setEditUserRemark] = useState("");  // 备注信息
    
    const queryContent = useRef('');  // 记录搜索串
    const order = useRef('');  // 排序升序降序
    const order_by = useRef('');  // 排序列
    const page = useRef(1);  // 记录当前的页数
    const pageSize = useRef(20);  // 记录当前每页展示数量
    const onlineStatusFilters = useRef([]);  // 在线状态过滤选项
    const paidFilters = useRef([]);  // 是否付费过滤选项


    useEffect(() => {
        queryInstitutionList();
    }, [])

    // 查询机构列表
    const queryInstitutionList = () => {
        const sessionid = Cookies.get("sessionid");
        const queryData = {
            query: queryContent.current,
            page: page.current,
            pagesize: pageSize.current,
            order: order.current,
            order_by: order_by.current,
            online_status: JSON.stringify(onlineStatusFilters.current),
            paid: JSON.stringify(paidFilters.current),
            sessionid: sessionid,
            sid: sessionid,
        };
        sendHttpPostRequest(
            'college/api/oam_query_college_list/',
            queryData,
            institutionListCallback
        )
    }

    // 查询机构列表接口回调
    const institutionListCallback = (response: any) => {
        // console.log(response);
        message.destroy();
        if (response.code == -100) {
            // 未登录
            window.location.href = '/oam_login?next=/oam_institution_list';
        } else if (response.code == 0) {
            // 成功
            setUserResult(response.college_list);
            setResultSum(response.total_sum);
            setTotalCount(response.total_count);
            setOnlineCount(response.online_count);
        } else if (response.code == -501) {
            // 权限不足
            window.location.href = '/oam_login?next=/oam_institution_list';
        } else {
            message.error(response.message);
        }
    }

    //页码和页数发生了变化
    const onPageChange = (newPage: number, newPageSize?: number | undefined) => {
        page.current = newPage ? newPage : 1;
        if (newPageSize) {
            pageSize.current = newPageSize;
        }
        queryInstitutionList();
    }

    // 渲染状态
    const renderStatus = (text: any, record: any) => (
        <div>{statusMap.get(parseInt(text))}</div>
    )

    // 渲染付费状态
    const renderPaidStatus = (text: any, record: any) => (
        <div>{paidMap.get(parseInt(text))}</div>
    )

    // 上下架回调
    const onPutOnOffBtnClickCallback = (response: any) => {
        message.destroy();
        if (response.code == -100) {
            // 没登录
            window.location.href = '/oam_login?next=oam_institution_list';
        } else if (response.code == -501) {
            // 没权限
            window.location.href = '/oam_login?next=oam_institution_list';
        } else if (response.is_ok == true) {
            message.success(response.message);
            queryInstitutionList();
        } else {
            message.error(response.message);
        }
    }

    // 点了上下架按钮
    const onPutOnOffBtnClick = (collegeId:string, status:number) => {

        if(status == 1) { //上线
            sendHttpPostRequest(
                'college/api/oam_set_college_online/',
                {
                    sessionid: Cookies.get("sessionid"),
                    college_id: collegeId
                },
                onPutOnOffBtnClickCallback
            )
        }else {//下线
            sendHttpPostRequest(
                'college/api/oam_set_college_offline/',
                {
                    sessionid: Cookies.get("sessionid"),
                    college_id: collegeId
                },
                onPutOnOffBtnClickCallback
            )
        }
        
    }

    // 点了预览按钮
    const onLookOverBtnClick = (collegeId: string, event: any) => {
        window.open(`/oam_preview_institution?college_id=${collegeId}`);
    }

    //点击了编辑按钮
    const onEditBtnClick = (id:any,  event:any) => {
        window.open('/oam_institution_edit?college_id='+id);
    }
    
       // 删除回调
       const onDeleteBtnClickCallback = (response: any) => {
        message.destroy();
        if (response.code == -100) {
            // 没登录
            window.location.href = '/oam_login?next=oam_institution_list';
        } else if (response.code == -501) {
            // 没权限
            window.location.href = '/oam_login?next=oam_institution_list';
        } else if (response.code == 0) {
            message.success(response.message);
            queryInstitutionList();
        } else {
            message.error(response.message);
        }
    }

    // 点击 删除
    const onDeleteBtnClick = (collegeId: string) => {
        sendHttpPostRequest(
            'college/api/oam_delete_college/',
            {
                sessionid: Cookies.get("sessionid"),
                college_id: collegeId
            },
            onDeleteBtnClickCallback
        )
    }

    // 渲染操作
    const renderActions = (text:any, record:any) => {
        return (
            <Row justify="start" align="middle" gutter={[8, 4]} >
                {/*<Col style={{width:50}}><Button style={{borderRadius:20,fontSize:14}} size='small' id="lookover" value={record.id} type="default" onClick={onLookOverBtnClick.bind(this, record.id)} > 预 览 </Button></Col> */}
                <Col style={{width:50}}>
                    <Button
                        style={{borderRadius:20,fontSize:14}}
                        size='small'
                        id="editit"
                        type="dashed"
                        onClick={onEditBtnClick.bind(this,record.id)} > 编辑
                    </Button>
                </Col>
                <Col style={{width:50}}>
                    <Button
                        style={{borderRadius:20,fontSize:14}}
                        size='small'
                        id="manageuser"
                        type="primary"
                        onClick={()=>{window.open('/oam_institution_user_manager?college_id=' + record.id)}} > 成员
                    </Button>
                </Col>
                {<Col style={{width:50}}> { parseInt(record.online_status) == 1 ?
                    <Button style={{borderRadius:20,fontSize:12}} size='small' id="puton" value={record.id} type="primary"  onClick={onPutOnOffBtnClick.bind(this, record.id, record.online_status)}> 上 线 </Button>
                    :  <Popconfirm placement="top" title={'确认下线机构"' + record.name + '"吗？'} onConfirm={onPutOnOffBtnClick.bind(this, record.id, record.online_status)} okText="确认" cancelText="取消">
                        <Button style={{borderRadius:20,fontSize:12}} size='small' id="putoff" value={record.id} type="primary" danger> 下 线 </Button>
                    </Popconfirm>}
                </Col> }

                { parseInt(record.online_status) == 1  && <Col style={{width:50}}> 
                   
                   <Popconfirm placement="top" title={'确认删除机构"' + record.name + '"吗？'} onConfirm={onDeleteBtnClick.bind(this, record.id)} okText="确认" cancelText="取消">
                       <Button
                           style={{borderRadius:20,fontSize:14}}
                           size='small'
                           id="deleteexe"
                           type="dashed"
                           danger
                       > 删除
                       </Button>
                   </Popconfirm> 
                 
           </Col> }
                
            </Row>
        )
    }



    //点击了搜索按钮
    const onSearchBtn = (value: string) => {
        page.current = 1;
        queryContent.current = value;
        queryInstitutionList();
    }

    // 表格发生变化
    const onTableChange:any = (pagination:any, filters:any, sorter:any, extra: { currentDataSource: [], action: any })=> {
        if (sorter.order) { //如果选择了排序列
            order.current = sorter.order;
            order_by.current = sorter.field;
        }
        if (filters.online_status) { //如果选择了过滤列
            onlineStatusFilters.current = filters.online_status;
        } else {
            onlineStatusFilters.current = [];
        }
        if (filters.paid) {
            paidFilters.current = filters.paid;
        } else {
            paidFilters.current = [];
        }
        
        page.current = 1;
        queryInstitutionList();
    }


    /**
     * 创建机构回调
     * @param response
     */
         const createInstitutionCallback = (response:any) => {
            message.destroy();
            if (response.code == -100) {
                // 没登录
                window.location.href = '/oam_login?next=oam_institution_list';
            } else if (response.code == -501) {
                // 没权限
                window.location.href = '/oam_login?next=oam_institution_list';
            } else if (response.is_ok == true) {
                if (response.college_id) {
                    window.open(`/oam_institution_edit?college_id=${response.college_id}`);
                } else {
                    message.error(response.message);
                }
            }
        }
    
        /**
         * 点击创建机构
         */
        const createInstitution = () => {
            sendHttpPostRequest(
                'college/api/oam_create_college/',
                {
                    sessionid: Cookies.get("sessionid"),
                    college_name:" ",
                },
                createInstitutionCallback
            )
        }

        
    // 表格列
    const cols: any = [
       
        {
            title: "序号",
            dataIndex: "id",
            key: "id",
            width: 60,
            align: 'left',
            render:(text, record, index)=>((page.current - 1)*pageSize.current + index + 1)
        },
        /*{
            title: "ID",
            dataIndex: "id",
            key: "id",
            width: 60,
            align: 'left',
        },*/
        {
            title: "",
            dataIndex: "logo_url",
            key: "logo_url",
            width: 100,
            ellipsis: false,
            align: 'left',
            render :(text,record) => (<img  onMouseOver={()=>{}} style={{width:100,height:25}} src={ record?.logo_url?.startsWith("http") ? record?.logo_url : BACKEND_API_URL + record?.logo_url} />)
        },
        {
            title: "机构名",
            dataIndex: "name",
            key: "name",
            width: 100,
            ellipsis: false,
            align: 'left',
        },
        {
            title: "教师",
            dataIndex: "teacher_count",
            key: "teacher_count",
            width: 60,
            align: 'center',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: "学生",
            dataIndex: "student_count",
            key: "student_count",
            width: 60,
            align: 'center',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },

        {
            title: "管理员",
            dataIndex: "manager_name",
            key: "manager_name",
            width: 70,
            align: 'left',
            render:(text,record)=>(record.manager_real_name == '' ? text : record.manager_real_name )
        },
        {
            title: "销售员",
            dataIndex: "salesman",
            key: "salesman",
            width: 70,
            align: 'left',
        },
        {
            title: "状态",
            dataIndex: "online_status",
            key: "online_status",
            width: 60,
            align: 'center',
            render: renderStatus,
            filters: [
                {
                    text: '下线',
                    value: 1,
                },
                {
                    text: '在线',
                    value: 2,
                }
            ],
            onFilter: (value:any, record:any) => true,
        },
        {
            title: "付费",
            dataIndex: "paid",
            key: "paid",
            width: 60,
            align: 'center',
            render: renderPaidStatus,
            filters: [
                {
                    text: '未付费',
                    value: 1,
                },
                {
                    text: '已付费',
                    value: 2,
                }
            ],
            onFilter: (value:any, record:any) => true,
        },
        {
            title: "创建时间",
            dataIndex: "create_time",
            key: "create_time",
            width: 100,
            align: 'center',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
            render:(text)=>(text?.slice(0,10))
        },
        /*{
            title: "开始时间",
            dataIndex: "start_time",
            key: "start_time",
            width: 80,
            align: 'center',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },*/
        {
            title: "到期时间",
            dataIndex: "end_time",
            key: "end_time",
            width: 100,
            align: 'center',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
            render:(text)=>(text?.slice(0,10))
        },
        {
            title: "操作",
            dataIndex: "actions",
            key: "actions",
            width: 150,
            ellipsis: false,
            align: 'center',
            fixed: 'right',
            render: renderActions,
        },
    ]

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <OAMSider keys={"2"} openKeys={["sub-user"]}></OAMSider>
            <Layout style={{backgroundColor:'#f9f9f9'}}>
                <Content style={{ backgroundColor:'#fff',borderRadius:5,minWidth:400,margin:30 ,paddingTop:20}}>
                    <div style={{paddingLeft:30,paddingRight:30}}>
                        <div className={"css.search1"} style={{textAlign:"left",paddingBottom:20}}>
                        <Row align="middle"  gutter={16} >
                            <Col flex='80px'>
                                <Statistic title="总机构" value={totalCount} />
                            </Col>
                           
                            <Col flex='80px'>
                                <Statistic title="已上线" value={onlineCount} />
                            </Col>

                            <Col flex='80px'>
                                <Statistic title="已付费" value={paidCount} />
                            </Col>

                            <Col flex='350px'>
                                <Search
                                    placeholder="搜索机构"
                                    style={{ width: 350 }}
                                    enterButton={true}
                                    onSearch={onSearchBtn}
                                />
                            </Col>

                            <Col flex='auto' style={{textAlign: 'right'}}>
                                <Button type="primary" style={{float: 'right', marginRight: 20, borderRadius: 20}} onClick={createInstitution}>创建机构</Button>
                            </Col>
                            </Row>
                            
                        </div>


                        <div className={idataCourseTableStyles.tableWrapper}>
                        <ConfigProvider locale={zhCN}>
                            <Table
                                dataSource={userResult}
                                columns={cols}
                                rowKey={record => record.user_id}
                                pagination={false}
                                scroll={{ x: 800 }}
                                //bordered={true}
                                onChange={onTableChange}
                            />
                        </ConfigProvider>
                        </div>

                        <ConfigProvider locale={zhCN}>
                            <div style={{marginBottom:30,paddingTop:30}} className={"css.pagination"} >
                                <Pagination
                                    defaultCurrent={1}
                                    total={resultSum}
                                    defaultPageSize={20}
                                    hideOnSinglePage={true}
                                    onChange={onPageChange}
                                    current={page.current}
                                    showQuickJumper={true}
                                    showTotal={total => `共 ${total} 机构`}
                                    pageSizeOptions={["3","20","30","50","100"]}
                                />
                            </div>
                        </ConfigProvider>
                        

                    </div>
                </Content>
            </Layout>
        </Layout>

    )

}

export default OAMInstitutionList

