import exerciseStyles from "./exercise.module.less";
import { Rate,message,Button, Radio, Col, ConfigProvider, Layout, Pagination, Row, Tabs, Table, Input, Modal, Card, Popconfirm, Tooltip } from 'antd';
import { HeartOutlined, DeleteOutlined,EditOutlined,CheckCircleOutlined,CheckSquareOutlined,SearchOutlined,EyeOutlined, ForkOutlined,CodeOutlined,SortDescendingOutlined} from '@ant-design/icons'
import { PageHeader } from "../common/page-header/header";
import React, { useEffect, useRef, useState } from "react";
import PageFooter from "../common/page-footer/footer";
import Search from 'antd/lib/input/Search';
import zhCN from 'antd/lib/locale/zh_CN'
import { Tag } from 'antd';
import Cookies from 'js-cookie';
import { Checkbox } from 'antd';
import courseMainStyles from "../course/course-main.module.less";
const CheckboxGroup = Checkbox.Group;

import remarkMath from 'remark-math'
import gfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import rehypeKatex from 'rehype-katex'
import 'katex/dist/katex.min.css' // `rehype-katex` does not import the CSS for you


import emptyExercise from "../../static/image/empty/queshengye-timu.png";

import Meta from 'antd/lib/card/Meta';
import { sendHttpPostRequest } from "../../api/http-request";
import ExerciseCard from "./exercise-card";
import { checkUserLogin } from "../common/user-center/user-info";
import Draggable from "react-draggable";
import { url } from "inspector";
import {checkIsTeacher} from "../../component/modal/teacher-authenticate/teacher-authenticate";
import Feedback from "../common/feedback-help/feedback";
const { CheckableTag } = Tag;

const { Content } = Layout
const {TabPane} = Tabs

interface Exercise {
    id: number,
    name: string,
    author: string,
    difficulty: number, //难度
    channel: number, //
    type: number, //题型
    status: number, //状态
    create_time: string,//创建时间
    modify_time: string,//修改时间
    is_my: number, // 查我的
    source_page: number,
    source_key: number
}


export interface Props {}
export const ExerciseList: React.FC<Props> = (props) => {

    const [totalSum, setTotalSum] = useState(0)

    const [exerciseResult, setExerciseResult] = useState(null as any);  // 当前显示的结果页
    const [resultSum, setResultSum] = useState(0);  // 搜索返回的结果数
    const [totalCount, setTotalCount] = useState(0);  // 搜索返回的结果数
    const queryContent = useRef('');  // 记录搜索串
    const querySource = useRef('2');  // 来源 1-个人  2-OAM创建（系统）
    const order = useRef('');  // 排序升序降序
    const order_by = useRef('');  // 排序列
    const page = useRef(1);  // 记录当前的页数
    const pageSize = useRef(20);  // 记录当前每页展示数量
    const userIsLogin = useRef(false);  // 用户是否登录
    const typeFilters = useRef([] as any[]);  // 类型过滤选项
    const difficultyFilters = useRef([] as any[]);  // 难易程度过滤选项
    const createTime = useRef('');  // 创建时间
    const isMy = useRef(2); // 2-查公开上架的 1-查我的
    const isTeacher = useRef(false);  // 是否为教师

    const [selectedType, setSelectedType] = useState(1) //创建习题时选择的题型
    
    const [isLogin, setIsLogin] = useState(false);//是否登录
    const [collegeId, setCollegeId] = useState(null as any);  // 院校ID



    const typesData = ["全部", '实操题', '单选题', '多选题', '排序题', '主观题']
    const difficultyData = ["全部", '容易', '中等', '困难']

    const tagData = ['全部', '可视化', '预处理', '分类', '降维',"回归","K-Means","支持向量机","热力图","GBDT","随机森林"];

    const [selectedExerciseTags,setselectedCaseTags] = useState(['全部'] as string[] ) 

    const [selectedDifficulty, setSelectedDifficulty] = useState([0] as number[]) // 筛选的难度
 
    const [selectedTypes,setSelectedTypes] = useState([0] as number[]) //筛选的题型
    const [isCollegeTeacher, setIsCollegeTeacher] =  useState(null as any);//当前机构是否是机构的教师

    /** 以下是创建习题弹出框显示用到的变量和函数 */
    const [isModalVisible, setIsModalVisible] = useState(false)

    const draggleRef = useRef<HTMLDivElement | null>(null)
  
    const [bounds,setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const [disabled,setDisabled] = useState(true)
  
    const onStart = (event:any, uiData:any) => {
      const { clientWidth, clientHeight } = window?.document?.documentElement;
      const targetRect= draggleRef?.current?.getBoundingClientRect();
      if(targetRect){
        setBounds( {
          left: -targetRect?.left + uiData?.x,
          right: clientWidth - (targetRect?.right - uiData?.x),
          top: -targetRect?.top + uiData?.y,
          bottom: clientHeight - (targetRect?.bottom - uiData?.y),
        }
      )
      }
    }
  
    const [isExeCardModalVisible, setIsExeCardModalVisible] = useState(false);
    const draggleExeCardRef = useRef<HTMLDivElement | null>(null);
    const [exeCardModalbounds,setExeCardModalbounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const [exeCardModalDisabled,setExeCardModalDisabled] = useState(true)
  
    const onExeCardModalStart = (event:any, uiData:any) => {
      const { clientWidth, clientHeight } = window?.document?.documentElement;
      const targetRect= draggleRef?.current?.getBoundingClientRect();
      if(targetRect){
        setExeCardModalbounds( {
          left: -targetRect?.left + uiData?.x,
          right: clientWidth - (targetRect?.right - uiData?.x),
          top: -targetRect?.top + uiData?.y,
          bottom: clientHeight - (targetRect?.bottom - uiData?.y),
        }
      )
      }
    }


    useEffect(()=>{
        sendHttpPostRequest(
            'account/api/query_teacher_authenticate',{
                sessionid: Cookies.get("sessionid"),
                sid: Cookies.get("sessionid"),
            },
            (response:any)=>{
                if(response.is_ok == true)
                {
                    setIsLogin(true);
                    if (response?.college_info?.college_id !== '') { //已加入机构
                        let _collegeId = response.college_info.college_id; 
                        setCollegeId(_collegeId);
                        setIsCollegeTeacher(response.college_info.is_college_teacher);
                    }else{//未加入机构
                        setCollegeId(0);
                    }
                }else {//用户未登录
                    setIsLogin(false);
                }
            }
            )
        queryExerciseList();
    },[])

    const handleOk = () => { //确认创建习题
        //window.open(`/exercise-edit?exercise_type=${selectedType}`);
        setIsModalVisible(false);
        createExercise();
    }

    const showModal = () => {
        if (!checkUserLogin()) {
            document.getElementById("show-login-modal")!.click();
        } else {
            //if (!checkIsTeacher()) {
                //document.getElementById("show-teacher-authenticate")!.click();
            //} else {
                isTeacher.current = true;
                setIsModalVisible(true);
            //}
        }
    };

    const handleCancel = () => {
      setIsModalVisible(false)
    }

    /**
     * 查询习题列表
     */
     const queryExerciseList = () => {
        sendHttpPostRequest(
            'exercise/api/query_exercise_list/',
            {
                sessionid: Cookies.get("sessionid"),
                query: queryContent.current,
                page: page.current,
                pagesize: pageSize.current,
                order: order.current,
                order_by: order_by.current,
                type: JSON.stringify(typeFilters.current),
                difficulty: JSON.stringify(difficultyFilters.current),
                create_time: createTime.current,
                source: querySource.current,
                is_my: isMy.current,
                source_page: 1,  // 习题模块1
                source_key: isTeacher.current ? 3 : 4,  // 教师做题3  学生做题4
            },
            queryExerciseListCallback,
        )
    }

    /**
     * 查询习题列表回调
     * @param response
     */
    const queryExerciseListCallback = (response: any) => {
        // console.log(response)
        message.destroy();
        //if (response.code == -100) {
            // 未登录
            //document.getElementById("show-login-modal")!.click();
            //setExerciseResult([]);
            //setResultSum(0);
            //setTotalCount(0);
            // window.location.href = '/login?next=exercise';
        //} else
        if (response.code == 0) {
            // 成功
            setExerciseResult(response.exercise_list);
            setResultSum(response.total_sum);
            setTotalCount(response.total_count);
 
        } else if (response.code == -501) {
            // 权限不足
            //document.getElementById("show-login-modal")!.click();
            // window.location.href = '/login?next=exercise';
        } else {
            message.error(response.message);
        }
    }

  

 
    
    // 切换Tab页
        const onTabKeyChange = (key:string) => {
            console.log(key);
            querySource.current = key;
            page.current = 1;
            isMy.current = parseInt(key);
            queryExerciseList();
        }
    //点击了搜索按钮
    const onSearchBtn = (event: any) => {
        page.current = 1;
        queryContent.current = event.target.value;
        queryExerciseList();
    }
    // 表格发生变化
    const onTableChange:any = (pagination:any, filters:any, sorter:any, extra: { currentDataSource: [], action: any })=> {
        if (sorter.order) { //如果选择了排序列
            order.current = sorter.order;
            order_by.current = sorter.field;
        }
        if (filters.type) { //如果选择了过滤列
            typeFilters.current = filters.type;
        } else {
            typeFilters.current = [];
        }
        if (filters.difficulty) {
            difficultyFilters.current = filters.difficulty;
        } else {
            difficultyFilters.current = [];
        }
        page.current = 1;
        queryExerciseList();
    }

 

    //页码和页数发生了变化
    const onPageChange = (newPage: number, newPageSize?: number | undefined) => {
        page.current = newPage ? newPage : 1;
        if (newPageSize) {
            pageSize.current = newPageSize;
        }
        queryExerciseList();
    }

    // 删除习题回调
    const deleteExerciseCallback = (response: any) => {
        message.destroy();
        if (response.code == -100) {
            // 没登录
            document.getElementById("show-login-modal")!.click();
        } else if (response.code == -501) {
            // 没权限
            message.error("您还没有教师权限");
            // window.location.href = '/login?next=exercise';
        } else if (response.code == 0) {
             //message.success("习题删除成功");
             queryExerciseList();
        } else {
            message.error(response.message);
        }
    }

    // 删除习题
    const deleteExercise = (exerciseId: string) => {
        sendHttpPostRequest(
            'exercise/api/delete_exercise/',
            {
                exercise_id: parseInt(exerciseId),
                sessionid: Cookies.get("sessionid"),
                sid: Cookies.get("sessionid")
            },
            deleteExerciseCallback
        )
    }

    /**
     * 创建习题回调
     * @param response
     */
    const setUserExerciseInfoCallback = (response:any) => {
        message.destroy();
        if (response.code == -100) {
            // 没登录
            document.getElementById("show-login-modal")!.click();
            // window.location.href = '/exercise';
        } else if (response.code == -501) {
            // 没权限
            message.error("您还没有教师权限");
            // window.location.href = '/exercise';
        } else if (response.code == 0) {
            if (response.exercise_id) {
                window.open(`/exercise-edit?exercise_id=${response.exercise_id}`);
                queryExerciseList();
            } else {
                message.error(response.message);
            }
        }
    }

    /**
     * 点击创建习题
     */
    const createExercise = () => {
        sendHttpPostRequest(
            'exercise/api/create_exercise/',
            {
                sessionid: Cookies.get("sessionid"),
                type: selectedType
            },
            setUserExerciseInfoCallback
        )
    }
    const getCardClassName = (type:number)=> {
        if(type == 1) {
            return exerciseStyles.exerciseCard1
        }else if(type == 2) {
            return exerciseStyles.exerciseCard2
        }else if(type == 3) {
            return exerciseStyles.exerciseCard3
        }else if(type == 4) {
            return exerciseStyles.exerciseCard4
        
        }else {
            return exerciseStyles.exerciseCard5
        }
    }

    const getTypeName = (type:number)=> {
        if(type == 1) {
            return "实操题"
        }else if(type == 2) {
            return "单选题"
        }else if(type == 3) {
            return "多选题"
        }else if(type == 4) {
            return "排序题"
        
        }else {
            return "主观题"
        }
    }

    const onExerciseCardClick = (exercise_id: any,event: any) => {
        event.preventDefault();
        event.stopPropagation()
        if (!checkUserLogin()) {
            document.getElementById("show-login-modal")!.click();
        } else if(isCollegeTeacher == 1){
                window.open('/exercise-preview?exercise_id=' + exercise_id);
        }else {
            setIsExeCardModalVisible(true);
        }
    }

    const onExerciseEditClick = (exercise_id: any,event: any) => {
        event.preventDefault();
        event.stopPropagation()
        if (!checkUserLogin()) {
            document.getElementById("show-login-modal")!.click();
        } else {
            window.open('/exercise-edit?exercise_id=' + exercise_id);
        }
    }


    const handleExerciseTypesChange = (tag:number, checked:boolean) => {
        if(tag == 0){
            setSelectedTypes([0]);
            typeFilters.current = [1,2,3,4,5];
        }else{
            setSelectedTypes([tag]);
            typeFilters.current = [tag]     
        }
        queryExerciseList();
      }


    const handleExerciseDifficultyChange = (tag:number, checked:boolean) => {
        if(tag == 0){
            setSelectedDifficulty([0]);
            difficultyFilters.current = [1,2,3];
            queryExerciseList();
        }else{
            setSelectedDifficulty([tag]);
            difficultyFilters.current = [tag]
        }
        queryExerciseList();
 
    }


    return (
        <Layout style={{minHeight: 750,backgroundColor: '#f9f9f9'}}>
     <div className={exerciseStyles.maincontent}>
        <div className={exerciseStyles.exerciseList} style={{ width:'100%',maxWidth:1086}}  >
            <Row gutter={[16, 16]} style={{marginBottom:20}}>
                <Col span={24} >
                    <div>
                    <Input
                        onPressEnter={onSearchBtn} 
                        placeholder="搜索题目" 
                        style={{float:'left',borderRadius:20,width:300 }} 
                        suffix={<SearchOutlined  style={{color:"#666666"}}/>}
                    />

                 { isLogin == true && isCollegeTeacher == 1 && <Button onClick={showModal} style={{float:'right',borderRadius:20}} type="primary">
                    创建题目
                  </Button>
                 }

                  <Modal
                            title={<div
                            style={{
                            width: '100%',
                            cursor: 'move',
                            }}
                            onMouseOver={() => {
                            if (disabled) {
                                setDisabled(false)
                            }
                            }}
                            onMouseOut={() => {
                            setDisabled(true)
                            }}
                            onFocus={() => {}}
                            onBlur={() => {}}
                            // end
                        >
                            选择题型
                        </div>
                        }
                        closable={false}
                        visible={isModalVisible}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        bodyStyle={{ height: '200px' }}
                        destroyOnClose={true}
                        width={900}
                        modalRender={modal => (<div className={exerciseStyles.createDiv}>
                            <Draggable
                            disabled={disabled}
                            bounds={bounds}
                            onStart={(event, uiData) => onStart(event, uiData)}
                            >
                            <div ref={draggleRef}>{modal}</div>
                            </Draggable> </div>
                        )}
                        >
                            

                            <Radio.Group 
                            defaultValue={selectedType} 
                            buttonStyle="solid"
                            onChange={(e:any) => {setSelectedType(e.target.value)}}>
                                <Radio.Button value="1">  
                                <div >
                                        <CodeOutlined />
                                        <div >实操题 </div>
                                    </div></Radio.Button>
                                <Radio.Button value="2">
                                    <div>
                                    <CheckCircleOutlined />
                                    <div  > 单选题 </div>
                                    </div></Radio.Button>
                                <Radio.Button value="3"> <div>
                                    <CheckSquareOutlined />
                                    <div  >多选题 </div>
                                    </div></Radio.Button>
                                <Radio.Button value="4">
                                    <div >
                                        <SortDescendingOutlined />
                                        <div > 排序题</div>
                                    </div></Radio.Button>
                                <Radio.Button value="5"><div >
                                    <EditOutlined />
                                    <div > 主观题 </div>
                                    </div></Radio.Button>
                            </Radio.Group>
                     
                        </Modal>

                </div>

                </Col>
            </Row>
            

            <div style={{textAlign:'left',marginBottom:10}}>
                    <span style={{ marginRight: 8 }}>题型：</span>
                    {typesData?.map((value,index) => {
                        return <CheckableTag
                        style={{fontSize:14,borderRadius:20}}
                            key={index}
                            checked={selectedTypes.indexOf(index) > -1}
                            onChange={checked => handleExerciseTypesChange(index, checked)}
                        >
                            {value}
                        </CheckableTag>
                    })}
                       
                </div>
           
            
                    <div style={{textAlign:'left',marginBottom:10}}>
                        <span style={{ marginRight: 8 }}>难度：</span>
                        {difficultyData?.map((value,index) => {
                        return <CheckableTag
                        style={{fontSize:14,borderRadius:20}}
                            key={index}
                            checked={selectedDifficulty.indexOf(index) > -1}
                            onChange={checked => handleExerciseDifficultyChange(index, checked)}
                        >
                            {value}
                        </CheckableTag>
                        })}
                    </div>  

                
           
       
                <Tabs  onChange={onTabKeyChange} defaultActiveKey="2" size="small" style={{ width:'100%',maxWidth:1086}} >
                
                <TabPane tab="公开" key="2">

                
                <div className={exerciseStyles.cardListWarpper}>
                    <Row gutter={[24, 30]} wrap={true} justify="start">
                        {exerciseResult?.map((exercise) => {
                            return (
                                <Col key={exercise.id}>
                                    <Card
                                        className={getCardClassName(exercise.type)}
                                        bodyStyle={{paddingLeft:20,paddingRight:20,paddingBottom:0,paddingTop:0,height:60,width:250}}
                                        hoverable={true}
                                        onClick={onExerciseCardClick.bind(this, exercise.id)}         
                                    >
                                        <Row align="middle" justify="start" gutter={[12,12]}>
                                            <Col span={24} style={{textAlign:"left"}}>
                                            <div className={exerciseStyles.exerciseTitle} >{exercise.id + " " + exercise.title}</div>  
                                            </Col>
                                            <Col span={24} style={{textAlign:"left"}} >
                                            <ReactMarkdown  
                                                    className={exerciseStyles.reactmarkdown}
                                                    remarkPlugins={[gfm,remarkMath]}
                                                    rehypePlugins={[rehypeKatex]}
                                                >
                                                    {exercise.steps}
                                                </ReactMarkdown>  
                                            </Col>
                                            <Col span={10} style={{textAlign:"left"}}>
                                                <div  style={{color:"#666666"}} > 题型:  <span style={{color:"#07C160"}}>{getTypeName(exercise.type)}</span> </div>
                                            </Col>
                                            <Col span={14} style={{textAlign:"right"}}>
                                                <div style={{color:"#666666"}} > 难度:   <span style={{color:"#07C160"}}> <Rate disabled defaultValue={exercise.difficulty} count={3} /> </span> </div> 
                                            </Col>
                                        </Row>
                                        
                                    </Card>
                                </Col>
                            )
                        })}

                        
                    </Row>
                    </div>


                </TabPane>
                { isLogin == true && isCollegeTeacher == 1 &&
                <TabPane tab="我的" key="1">
                <div className={exerciseStyles.cardListWarpper}>
                    <Row gutter={[24, 30]} wrap={true} justify="start">
                        {exerciseResult?.map((exercise) => {
                            return (
                                <Col key={exercise.id}>
                                    <div className={exerciseStyles.myCardCoverWrapper}>
                                    <Card
                                        className={getCardClassName(exercise.type)}
                                        bodyStyle={{paddingLeft:20,paddingRight:20,paddingBottom:0,paddingTop:0,height:60,width:250}}
                                        hoverable={true}
                                        onClick={onExerciseCardClick.bind(this, exercise.id)}
                                    >
                                        <div className={exerciseStyles.myCardAcitionDiv}>
                                        <Tooltip title="预览"><Button onClick={onExerciseCardClick.bind(this, exercise.id)}  shape="circle" icon={<EyeOutlined />} style={{paddingLeft:8,marginRight:5,textAlign:"center"}}> </Button></Tooltip>
                                        <Tooltip title="编辑"> <Button onClick={onExerciseEditClick.bind(this, exercise.id)}  shape="circle" icon={<EditOutlined />} style={{paddingLeft:8,marginRight:5,textAlign:"center"}}>  </Button></Tooltip>
                                            <Popconfirm placement="bottom" title={'您确定删除此习题吗？'} onCancel={(e:any)=>{e.stopPropagation();}} onConfirm={(e:any)=>{e.stopPropagation();deleteExercise(exercise.id)}} okText="确认" cancelText="取消">
                                                <Button onClick={(e:any)=>{e.stopPropagation();}} shape="circle" icon={<DeleteOutlined />} style={{paddingLeft:8,zIndex:10000}} > </Button>
                                            </Popconfirm>  
                                        </div>
                                        <Row align="middle" justify="start" gutter={[12,12]}>
                                            <Col span={24} style={{textAlign:"left"}}>
                                            <div className={exerciseStyles.exerciseTitle} >{exercise.id + " " + exercise.title}</div>  
                                            </Col>
                                            <Col span={24} style={{textAlign:"left"}} >
                                                {/*<div className={exerciseStyles.exerciseIntroduction}  >{exercise?.steps}</div>  */}
                                                <ReactMarkdown  
                                                    className={exerciseStyles.reactmarkdown}
                                                    remarkPlugins={[gfm,remarkMath]}
                                                    rehypePlugins={[rehypeKatex]}
                                                >
                                                    {exercise.steps}
                                                </ReactMarkdown>
                
                                            </Col>
                                            <Col span={10} style={{textAlign:"left"}}>
                                                <div  style={{color:"#666666"}} > 题型:  <span style={{color:"#07C160"}}>{getTypeName(exercise.type)}</span> </div>
                                            </Col>
                                            <Col span={14} style={{textAlign:"right"}}>
                                                <div style={{color:"#666666"}} > 难度:   <span style={{color:"#07C160"}}> <Rate disabled defaultValue={exercise.difficulty} count={3} /> </span> </div> 
                                            </Col>
                                        </Row>
                                        
                                    </Card>
                                    </div>
                                </Col>
                            )
                        })}
                    </Row>

                    {exerciseResult?.length == 0 &&
                    <div> 
                       <img src={emptyExercise} height={150}/> 
                       <div style={{color:"#999999"}}>您还没有创建题目哟~ 
                            
                       </div>
                       <Button size="small" style={{border:"1px solid #07c176",color:"#07c176",marginTop:10,borderRadius:3}} type="default" onClick={showModal}> 立即创建
                             </Button>
                    </div>
                    }

                    </div>

                        
                 </TabPane>
                    }
                </Tabs>

            <ConfigProvider locale={zhCN}>

              <div className={exerciseStyles.pagination} style={{marginTop:20}}>
                <Pagination
                  defaultCurrent={1}
                  current={page.current}
                  total={resultSum}
                  defaultPageSize={20}
                  hideOnSinglePage={true}
                  onChange={onPageChange}
                  showSizeChanger={false}
                />
              </div>
            </ConfigProvider>
                 
        </div>

        </div>

        <Modal 
            title={<div style={{ width: '100%',cursor: 'move',}}
            onMouseOver={() => {if (disabled) {setExeCardModalDisabled(false)}}}
            onMouseOut={() => {setExeCardModalDisabled(true)}}
            onFocus={() => {}}
            onBlur={() => {}}
            >
                提示</div>
            }
            width={350}
            closable={false}
            visible={isExeCardModalVisible}
            className={courseMainStyles.miniModal}
            footer={
                <div>
                {isLogin == true && collegeId > 0 && isCollegeTeacher == 0 &&
                    <Button className={courseMainStyles.stepsBtn}
                    style={{marginRight:20,color:'#fff'}}
                    type="primary"
                    onClick={()=>{setIsExeCardModalVisible(false)}}>确认</Button>
                }
                {isLogin == true && (collegeId == 0 ||  collegeId == null || collegeId == '')  &&
                    <div>
                        <Button className={courseMainStyles.stepsBtn}
                        style={{marginRight:20,color:'#07c160'}}
                        onClick={()=>{setIsExeCardModalVisible(false)}}>取消</Button>
                        <Button className={courseMainStyles.stepsBtn}
                        style={{marginRight:20,color:'#fff'}}
                        type="primary"
                        onClick={()=>{window.open("/user-center")}}>填写</Button></div>
                    }
                </div>
            }
            modalRender={modal => (<div>
                <Draggable
                disabled={exeCardModalDisabled}
                bounds={exeCardModalbounds}
                onStart={(event, uiData) => onExeCardModalStart(event, uiData)}
                >
                <div ref={draggleRef}>{modal}</div>
                </Draggable> </div>
            )}
            >
                {isLogin == true && collegeId > 0 && isCollegeTeacher == 0 &&
                    <div style={{width:300,textAlign:'center',paddingTop:20}}>
                        <p>只有教师可以查看题目哦~</p>  
                </div>}

                {isLogin == true && (collegeId == 0 ||  collegeId == null || collegeId == '')  &&
                    <div style={{width:320,textAlign:'center',paddingTop:20}}>
                    您还没加入院校，如需查看题目，请填写院校码。
                    {/* <span><Button type="link" onClick={()=>{window.open("/user-center")}}>填写</Button> </span> 院校码。 */}
                    <p>获取院校码，请咨询：136 9329 0406</p>
                </div>
               }
            </Modal>

     

        </Layout>

    )

}

export default ExerciseList