import {
    Layout,
    Button,
    Input,
    message,
    Table,
    ConfigProvider,
    Tabs,
    Row,
    Col,
    Popconfirm,
    Statistic,
    Pagination, Upload,
} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import zhCN from 'antd/lib/locale/zh_CN';
import OAMSider from '../oam-sider';
import React, {useEffect, useRef, useState} from "react";
import {sendHttpPostRequest} from "../../../api/http-request";
import Cookies from 'js-cookie';
import {RouteComponentProps} from "react-router";
import {PlusOutlined} from "@ant-design/icons";
import caseStyles from "../../case/case.module.less";
import * as constants from "../../../constants/constants";

const { Search } = Input;
const { TabPane } = Tabs;


interface Props extends RouteComponentProps<{ course_id: string, token: string }> {
    course_id: string,
    token : string,
}

/** OAM 实验编辑页面 */
export const OAMCaseEdit: React.FC<Props> = (props) => {

    const caseId = window.location.search.split('&')[0].split('id=')[1]

    const caseToken = window.location.search.split('&')[1].split('token=')[1]


    //const caseId = parseInt(props.location.search.split("=")[1]);   //实验ID
    //const caseToken = parseInt(props.location.search.split("=")[2]);   //实验ID

    //console.log(caseId)
    //console.log(caseToken)


    const [ipynb, setIpynb] = useState(null as any);  //封面
    const [ipynbFileList, setIpynbfileList] = useState([]);


    useEffect(() => {

    }, [])

    // 上传 ipynb 参数
    const uploadIpynbData = {
        sessionid: Cookies.get("sessionid"),
        sid: Cookies.get("sessionid"),
        id: caseId,
        token: caseToken,
    }


    // 上传 ipynb
    const onIpynbChange = (info: any) => {

        setIpynbfileList(info.fileList)

        const { status } = info.file
        if (status !== 'uploading') {
            //上传中
            console.log(info.file, info.fileList)
        }
        if (status === 'done') {
            //上传成功

            setIpynb(info.file.response.ipynb)
        } else if (status === 'error') {
            //上传失败
            message.error(`${info.file.name} file upload failed.`)
        }
    }


    const uploadIpynbButton = (
        <div>
            {<PlusOutlined />}
            <div style={{ marginTop: 8 }}>上传ipynb</div>
        </div>
    );



    /**
     * 创建习题回调
     * @param response
     */
    const setCaseCallback = (response:any) => {
        message.destroy();
        if (response.code == -100) {
            // 没登录
            window.location.href = '/oam_login?next=oam_case_list';
        } else if (response.code == -501) {
            // 没权限
            window.location.href = '/oam_login?next=oam_case_list';
        } else if (response.code == 0) {
            if (response.exercise_id) {
                window.open(`/oam_case_edit?case_id=${response.case_id}`);
            } else {
                message.error(response.message);
            }
        }
    }

    //  验证ipynb文件 - 提示太大风险 - 不阻止
    function beforeIpynbUpload(file) {
        const isLt5M = file.size / 1024 / 1024 < 15;
        if (!isLt5M) {
            message.error('文件大小超过15M, 使用时加载慢, 建议优化');
        }
        return true;
    }



    return (
             
        <Layout style={{ minHeight: '100vh' }}>
            <OAMSider keys={['4']}  openKeys={[]} />
            <div>
                {/*<h1>oam题目列表页</h1>*/}

                <Content style={{ backgroundColor: '#fff', borderRadius: 5, minWidth: 800, margin: 30, paddingTop: 20}}>
                    <div style={{paddingLeft: 30, paddingRight: 30, paddingBottom: 30}}>

                        <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:20}} >
                                        <Col flex="100px"  style={{textAlign:'left',marginLeft:50}}> ipynb文件 </Col>
                                        <Col flex="auto"  style={{textAlign:'left'}}>
                                            <div className={caseStyles.uploadCover}>
                                                <Upload
                                                    beforeUpload={beforeIpynbUpload}
                                                    accept={'*'}
                                                    action={constants.BACKEND_API_URL + 'case/api/oam_update_case_ipynb'}
                                                    listType="picture-card"
                                                    name="case_ipynb"
                                                    fileList={ipynbFileList}
                                                    onChange={onIpynbChange}
                                                    data={uploadIpynbData}
                                                    showUploadList={false}
                                                >
                                                    { ipynb ? <span>ipynb</span> : uploadIpynbButton }
                                                </Upload>
                                            </div>
                                        </Col>
                                    </Row>





                    </div>
                </Content>
            </div>
            
        </Layout>

    )

}

export default OAMCaseEdit
