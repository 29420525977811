import {
  message,
  Layout,
  Carousel,
  Card,
  Button,
  Modal,
  Space,
  ConfigProvider,
  Row,
  Input,
  InputNumber,
  Col,
} from 'antd'
import { PageHeader } from '../common/page-header/header'
import { CheckOutlined } from '@ant-design/icons'
import React, { useRef, useState } from 'react'
import indexStyles from './index.module.less'
import PageFooter from '../common/page-footer/footer'
import Meta from 'antd/lib/card/Meta'
import banner from '../../static/image/banner.jpg'
import introduction1 from '../../static/image/3.png'
import introduction2 from '../../static/image/4.png'
import introduction3 from '../../static/image/5.png'
import introduction4 from '../../static/image/6.png'
import introduction5 from '../../static/image/7.png'
import imgFunction from '../../static/image/8.png'
import imgCase from '../../static/image/9.png'
import imgIndividual from '../../static/image/10.png'
import decoration from '../../static/image/decoration.png'
import { url } from 'inspector'
import TextArea from 'antd/lib/input/TextArea'
import zhCN from 'antd/lib/locale/zh_CN'
import Cookies from 'js-cookie'
import Qs from 'qs'
import axios from 'axios'
import { sendHttpPostRequest } from '../../api/http-request'

import Draggable from 'react-draggable'
import { checkUserLogin } from '../common/user-center/user-info'
import Feedback from '../common/feedback-help/feedback'

export interface Props {}
export const Index: React.FC<Props> = (props) => {
  const [name, setName] = useState('') //姓名
  const [phone, setPhone] = useState('') //手机号
  const [institute, setInstitute] = useState('') //学院
  const [position, setPosition] = useState('') //职位
  const [userCount, setUserCount] = useState(0) //使用人数/团队大小
  const [comment, setComment] = useState('') //备注信息
  const [direction, setDirection] = useState('') // 专业或研究方向
  const [isModalVisible, setIsModalVisible] = useState(false)

  const draggleRef = useRef<HTMLDivElement | null>(null)

  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
  const [disabled, setDisabled] = useState(true)

  const onStart = (event: any, uiData: any) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement
    const targetRect = draggleRef?.current?.getBoundingClientRect()
    if (targetRect) {
      setBounds({
        left: -targetRect?.left + uiData?.x,
        right: clientWidth - (targetRect?.right - uiData?.x),
        top: -targetRect?.top + uiData?.y,
        bottom: clientHeight - (targetRect?.bottom - uiData?.y),
      })
    }
  }

  const handleOk = () => {
    //确认创建实验
    setIsModalVisible(false)
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const onDirectionChange = async (e: any) => {
    setDirection(e.target.value)
  }

  const onNameChange = async (e: any) => {
    setName(e.target.value)
  }
  const onPhoneChange = async (e: any) => {
    setPhone(e.target.value)
  }

  const onInstituteChange = async (e: any) => {
    setInstitute(e.target.value)
  }

  const onPositionChange = async (e: any) => {
    setPosition(e.target.value)
  }

  const onUserCountChange = async (value: number) => {
    setUserCount(value)
  }

  const onCommentChange = async (e: any) => {
    setComment(e.target.value)
  }

  const onCourseBtnClick = (e: any) => {
    setIsModalVisible(true)
  }

  const onExperienceBtnClick = (e: any) => {
    if (!checkUserLogin()) {
      document.getElementById('show-login-modal')!.click()
    } else {
      window.location.href = '/case'
    }
  }

  // 提交教学申请回调
  const userApplyCreateCallback = (response: any) => {
    message.destroy()
    if (response.is_ok) {
      message.success('申请信息提交成功')
    } else {
      message.error('申请信息提交失败，请稍候再试')
    }
  }

  //提交教学申请
  const onCourseSubmitBtnClick = (e: any) => {
    let validArr = [
      name,
      phone,
      institute,
      position,
      userCount,
      direction,
      comment,
    ]

    if (validArr.some((item) => !item)) {
      message.error('请填写完整信息')
      return
    }

    setIsModalVisible(false)
    sendHttpPostRequest(
      'account/api/user_apply_create/',
      {
        username: name,
        phone: phone,
        institute: institute,
        position: position,
        usercount: userCount,
        direction: direction,
        comment: comment,
        usage: 2,
        platform_id: 2, // 1-爱数科  2-爱数课
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
      },
      userApplyCreateCallback
    )
  }

  return (
    <Layout>
      <div className={indexStyles.indexMainPage}>
        <div className={indexStyles.indexBanner}>
          <div className={indexStyles.indexTitle}>
            <h1>大数据教学管理平台</h1>
            <ul
              style={{
                color: '#c6e0dd',
                marginTop: 20,
                marginLeft: -36,
                fontSize: 14,
              }}
            >
              <li>
                <CheckOutlined style={{ marginRight: 10 }} />
                理论与实践结合，以数据为基础，实际问题为导向的创新教学理念
              </li>
              <li>
                <CheckOutlined style={{ marginRight: 10 }} />
                提供院校大数据和人工智能专业建设亟需的资源：课程、案例、数据、题库、运行环境
              </li>
              <li>
                <CheckOutlined style={{ marginRight: 10 }} />
                AI for AI
                EDU：采用先进的AI技术帮助教师进行教学效果评估、教学方法改进，全面提升教学效果
              </li>
            </ul>
            <Button onClick={onExperienceBtnClick}>马上体验</Button>
            {process.env.REACT_APP_SMS_HIDE == 'true' ? (
              ''
            ) : (
              <Button onClick={onCourseBtnClick}>教学申请</Button>
            )}
          </div>
          {/* <Carousel autoplay>
                        <div>
                            <h3 className={indexStyles.contentStyle}>Banner1</h3>
                        </div>
                        <div>
                            <h3 className={indexStyles.contentStyle}>Banner2</h3>
                        </div>
                        <div>
                            <h3 className={indexStyles.contentStyle}>Banner3</h3>
                        </div>
                        <div>
                            <h3 className={indexStyles.contentStyle}>Banner4</h3>
                        </div>
                    </Carousel> */}
        </div>
        {/* <div className={indexStyles.indexVideoWrapper}>
                    <video className={indexStyles.indexVideo} loop={true} autoPlay={true} controls={true} preload="preload" >
                        <source src="http://backend.idatascience.cn/static/vedio/des.mp4" type="video/mp4" />
                        您的浏览器不支持 HTML5 video 标签。
                    </video>
                </div> */}
        <div className={indexStyles.indexIntroduction}>
          <h1 style={{ fontSize: 24 }}>
            {' '}
            <span style={{ height: 30 }}>
              <img
                src={decoration}
                alt=""
                style={{ marginRight: 5, width: 23, height: 23 }}
              />
            </span>{' '}
            精品课+自建课
          </h1>
          <div style={{ marginTop: -10, fontSize: 10, color: '#ccc' }}>
            EXCELLENT COURSE + SELF-CREATED COURSE
          </div>
          <div
            className={indexStyles.indexCard}
            style={{ marginTop: 20, verticalAlign: 'top' }}
          >
            <Card
              className={indexStyles.indexCardDetail}
              style={{
                display: 'inline-block',
                margin: 10,
                height: 300,
                width: 200,
                borderRadius: 6,
              }}
              hoverable={true}
              cover={<img alt="" src={introduction1} />}
            >
              <Meta
                style={{ fontSize: 12 }}
                description="经过全国院校广泛应用检验的权威课程体系"
              />
            </Card>
            <Card
              className={indexStyles.indexCardDetail}
              style={{
                display: 'inline-block',
                margin: 10,
                height: 300,
                width: 200,
                borderRadius: 6,
              }}
              hoverable={true}
              cover={<img alt="" src={introduction2} />}
            >
              <Meta
                style={{ fontSize: 12 }}
                description="支持教师使用平台已有资源灵活创建课程"
              />
            </Card>
            <Card
              className={indexStyles.indexCardDetail}
              style={{
                display: 'inline-block',
                margin: 10,
                height: 300,
                width: 200,
                borderRadius: 6,
              }}
              hoverable={true}
              cover={<img alt="" src={introduction3} />}
            >
              <Meta
                style={{ fontSize: 12 }}
                description="多种教学场景：自学、授课、作业、考试、实验"
              />
            </Card>
            <Card
              className={indexStyles.indexCardDetail}
              style={{
                display: 'inline-block',
                margin: 10,
                height: 300,
                width: 200,
                borderRadius: 6,
              }}
              hoverable={true}
              cover={<img alt="" src={introduction4} />}
            >
              <Meta
                style={{ fontSize: 12 }}
                description="课程包含视频、课件、练习题、实验案例"
              />
            </Card>
            <Card
              className={indexStyles.indexCardDetail}
              style={{
                display: 'inline-block',
                margin: 10,
                height: 300,
                width: 200,
                borderRadius: 6,
              }}
              hoverable={true}
              cover={<img alt="" src={introduction5} />}
            >
              <Meta
                style={{ fontSize: 12 }}
                description="贴合了理论与实践结合，实际问题为导向的教学思想"
              />
            </Card>
          </div>
        </div>

        <div className={indexStyles.indexCase}>
          <div style={{ float: 'left', width: '50%', height: '100%' }}>
            <img src={imgCase} alt="" />
          </div>
          <div style={{ float: 'left', width: '50%', height: '100%' }}>
            <div style={{ float: 'left', marginTop: 150, textAlign: 'left' }}>
              <div style={{ height: 30 }}>
                <img
                  src={decoration}
                  alt=""
                  style={{ margin: 0, width: 20, height: 20 }}
                />
              </div>
              <h1 style={{ fontSize: 24 }}>丰富的行业实战案例</h1>
              <div style={{ marginTop: -10, fontSize: 10, color: '#ccc' }}>
                RICH INDUSTRY ACTUAL COMBAT CASES
              </div>
              <ul style={{ marginTop: 20, marginLeft: -24, fontSize: 12 }}>
                <li>数千个行业实战案例，为教学中动手实践环节提供丰富的素材</li>
                <li>
                  提供多元数据和全新升级工具为学生开展业务探索与创新提供机会和环境
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className={indexStyles.indexIndividual}>
          <div style={{ float: 'left', width: '50%', height: '100%' }}>
            <div
              style={{
                float: 'left',
                marginTop: 150,
                marginLeft: 180,
                textAlign: 'left',
              }}
            >
              <div style={{ height: 30 }}>
                <img
                  src={decoration}
                  alt=""
                  style={{ margin: 0, width: 20, height: 20 }}
                />
              </div>
              <h1 style={{ fontSize: 24 }}>个性化定制</h1>
              <div style={{ marginTop: -10, fontSize: 10, color: '#ccc' }}>
                PERSONALIZED CUSTOMIZATION PROPOSITION
              </div>
              <ul style={{ marginTop: 20, marginLeft: -24, fontSize: 12 }}>
                <li>
                  自由创建课程、上传视频、创建题目，注重用户生产内容，并为内容安全提供保障
                </li>
              </ul>
            </div>
          </div>
          <div
            className={indexStyles.individualImg}
            style={{
              position: 'relative',
              float: 'left',
              width: '50%',
              height: '100%',
            }}
          >
            {/* <img src={imgIndividual} alt="" style={{marginLeft:20}}/> */}
            <div style={{ position: 'absolute', top: 180, left: 115 }}>
              创建课程
            </div>
            <div style={{ position: 'absolute', top: 285, left: 212 }}>
              上传视频
            </div>
            <div style={{ position: 'absolute', top: 378, left: 130 }}>
              创建题目
            </div>
          </div>
        </div>

        <div className={indexStyles.indexFunction}>
          <div style={{ float: 'left', width: '50%', height: '100%' }}>
            <img src={imgFunction} alt="" />
          </div>
          <div style={{ float: 'left', width: '50%', height: '100%' }}>
            <div style={{ float: 'left', marginTop: 150, textAlign: 'left' }}>
              <div style={{ height: 30 }}>
                <img
                  src={decoration}
                  alt=""
                  style={{ margin: 0, width: 20, height: 20 }}
                />
              </div>
              <h1 style={{ fontSize: 24 }}>教、学、练、测、评</h1>
              <div style={{ marginTop: -10, fontSize: 10, color: '#ccc' }}>
                TEACH, LEARN, PRACTICE, TEST, EVALUATE
              </div>
              <ul style={{ marginTop: 20, marginLeft: -24, fontSize: 12 }}>
                <li>
                  提供作业系统、考试系统、学习效果评价等更多的服务功能，满足教、学、练、测、评等场景
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* <div className={indexStyles.indexBottom}>
                    {/*底部区域，链接，合作伙伴*/}

        {/* <div className={indexStyles.footerColumn1}>
                        <h2 className={indexStyles.footerLabel}>相关资源</h2>
                        <div className={indexStyles.footerItem}>
                            <a href={"http://www.idatascience.cn"} title={"爱数科"} target={"_blank"} rel="noreferrer">爱数科</a>
                        </div>

                    </div>
                    <div className={indexStyles.footerColumn1}>
                        <h2 className={indexStyles.footerLabel}>友情链接</h2>
                        <div className={indexStyles.footerItem}>
                            <a href={"http://www.idatascience.cn"} title={"爱数科"} target={"_blank"} rel="noreferrer">爱数科</a>
                        </div>
                        <div className={indexStyles.footerItem}>
                            <a href={"http://www.idatascience.cn"} title={"爱数科"} target={"_blank"} rel="noreferrer" >爱数科</a>
                        </div>
                    </div>
                    <div className={indexStyles.footerColumn2}>
                        <h2 className={indexStyles.footerLabel}>加入我们</h2>
                        <div className={indexStyles.footerItem}>
                            <div className={indexStyles.footerWeChat1}></div>
                        </div>
                    </div>
                </div> */}
      </div>

      <ConfigProvider locale={zhCN}>
        <Modal
          title={
            <div
              style={{
                width: '100%',
                cursor: 'move',
              }}
              onMouseOver={() => {
                if (disabled) {
                  setDisabled(false)
                }
              }}
              onMouseOut={() => {
                setDisabled(true)
              }}
              onFocus={() => {}}
              onBlur={() => {}}
              // end
            >
              教学申请
            </div>
          }
          closable={true}
          visible={isModalVisible}
          footer={null}
          onOk={handleOk}
          onCancel={handleCancel}
          bodyStyle={{ height: '500px' }}
          destroyOnClose={true}
          width={600}
          modalRender={(modal) => (
            <div className={indexStyles.applyDiv}>
              <Draggable
                disabled={disabled}
                bounds={bounds}
                onStart={(event, uiData) => onStart(event, uiData)}
              >
                <div ref={draggleRef}>{modal}</div>
              </Draggable>{' '}
            </div>
          )}
        >
          <div style={{ paddingLeft: 30, paddingRight: 30 }}>
            自由组课，海量实操题库，作业、考试，解锁更多丰富的教师教学功能和资源，请填写以下信息，会有专员与您联系。
          </div>
          <div style={{ padding: 30 }}>
            <Row
              gutter={[8, 8]}
              justify="start"
              align="middle"
              style={{ marginBottom: 10 }}
            >
              <Col flex="100px" style={{ textAlign: 'left' }}>
                真实姓名
              </Col>
              <Col flex="auto" style={{ textAlign: 'left' }}>
                <Input
                  onChange={onNameChange}
                  maxLength={20}
                  placeholder={'请输入您的姓名'}
                  required={true}
                />
              </Col>
            </Row>

            <Row
              gutter={[8, 8]}
              justify="start"
              align="middle"
              style={{ marginBottom: 10 }}
            >
              <Col flex="100px" style={{ textAlign: 'left' }}>
                手机号
              </Col>
              <Col flex="auto" style={{ textAlign: 'left' }}>
                <Input
                  onChange={onPhoneChange}
                  maxLength={11}
                  placeholder={'请输入手机号'}
                  required={true}
                />
              </Col>
            </Row>

            <Row
              gutter={[8, 8]}
              justify="start"
              align="middle"
              style={{ marginBottom: 10 }}
            >
              <Col flex="100px" style={{ textAlign: 'left' }}>
                学校院系
              </Col>
              <Col flex="auto" style={{ textAlign: 'left' }}>
                <Input
                  onChange={onInstituteChange}
                  maxLength={40}
                  placeholder={'所在学校和院系'}
                  required={true}
                />
              </Col>
            </Row>

            <Row
              gutter={[8, 8]}
              justify="start"
              align="middle"
              style={{ marginBottom: 10 }}
            >
              <Col flex="100px" style={{ textAlign: 'left' }}>
                专 业
              </Col>
              <Col flex="auto" style={{ textAlign: 'left' }}>
                <Input
                  onChange={onDirectionChange}
                  maxLength={40}
                  placeholder={'授课专业'}
                  required={true}
                />
              </Col>
            </Row>

            <Row
              gutter={[8, 8]}
              justify="start"
              align="middle"
              style={{ marginBottom: 10 }}
            >
              <Col flex="100px" style={{ textAlign: 'left' }}>
                职 务
              </Col>
              <Col flex="auto" style={{ textAlign: 'left' }}>
                <Input
                  onChange={onPositionChange}
                  maxLength={40}
                  placeholder={
                    '输入您的职务（院长、系主任、专业负责人、授课教师）'
                  }
                  required={true}
                />
              </Col>
            </Row>

            <Row
              gutter={[8, 8]}
              justify="start"
              align="middle"
              style={{ marginBottom: 10 }}
            >
              <Col flex="100px" style={{ textAlign: 'left' }}>
                使用人数
              </Col>
              <Col flex="auto" style={{ textAlign: 'left' }}>
                <InputNumber
                  style={{ width: 180 }}
                  onChange={onUserCountChange}
                  maxLength={5}
                  placeholder={''}
                  required={true}
                />
              </Col>
            </Row>

            <Row
              gutter={[8, 8]}
              justify="start"
              align="middle"
              style={{ marginBottom: 10 }}
            >
              <Col flex="100px" style={{ textAlign: 'left' }}>
                备 注
              </Col>
              <Col flex="auto" style={{ textAlign: 'left' }}>
                <TextArea
                  onChange={onCommentChange}
                  maxLength={400}
                  placeholder={'请输入备注信息'}
                  required={false}
                />
              </Col>
            </Row>
          </div>
          <Row justify="center" align="middle">
            <Button
              type="primary"
              style={{
                marginTop: 20,
                borderRadius: 20,
                margin: 'auto',
                width: 200,
              }}
              onClick={onCourseSubmitBtnClick}
            >
              {' '}
              提交{' '}
            </Button>
          </Row>
        </Modal>
      </ConfigProvider>
    </Layout>
  )
}

export default Index
