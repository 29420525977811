import React, {useEffect, useRef, useState} from "react";
import { Col, Divider, Layout, Row, Spin } from "antd";
import { PageHeader } from "../common/page-header/header";
import PageFooter from "../common/page-footer/footer";
import coursePPTStyles from "./course-ppt.module.less";

export interface Props {
    
}

const PPT = [
    {
        name: '第一章',
        title: '计算与计算思维.pdf',
        src: 'https://static.idatascience.cn/pdf/%E7%AC%AC1%E7%AB%A0%20%E8%AE%A1%E7%AE%97%E4%B8%8E%E8%AE%A1%E7%AE%97%E6%80%9D%E7%BB%B4.pdf',
    },{
        name: '第二章',
        title: 'Python语言基础.pdf',
        src: 'https://static.idatascience.cn/pdf/%E7%AC%AC2%E7%AB%A0%20%20Python%E8%AF%AD%E8%A8%80%E5%9F%BA%E7%A1%80.pdf',
    },{
        name: '第三章',
        title: '流程自动化.pdf',
        src: 'https://static.idatascience.cn/pdf/%E7%AC%AC3%E7%AB%A0%20%E6%B5%81%E7%A8%8B%E8%87%AA%E5%8A%A8%E5%8C%96.pdf',
    },{
        name: '第四章',
        title: 'Python存储自动化.pdf',
        src: 'https://static.idatascience.cn/pdf/%E7%AC%AC4%E7%AB%A0%20python%E5%AD%98%E5%82%A8%E8%87%AA%E5%8A%A8%E5%8C%96.pdf',
    },{
        name: '第五章',
        title: ' 计算思维与Python程序设计.pdf',
        src: 'https://static.idatascience.cn/pdf/%E7%AC%AC5%E7%AB%A0%20%E8%AE%A1%E7%AE%97%E6%80%9D%E7%BB%B4%E4%B8%8EPython%E7%A8%8B%E5%BA%8F%E8%AE%BE%E8%AE%A1.pdf',
    },{
        name: '第六章',
        title: '计算思维与Python程序设计.pdf',
        src: 'https://static.idatascience.cn/pdf/%E7%AC%AC6%E7%AB%A0%20%E8%AE%A1%E7%AE%97%E6%80%9D%E7%BB%B4%E4%B8%8EPython%E7%A8%8B%E5%BA%8F%E8%AE%BE%E8%AE%A1.pdf',
    },{
        name: '第七章',
        title: 'Python科学计算三维可视化.pdf',
        src: 'https://static.idatascience.cn/pdf/%E7%AC%AC7%E7%AB%A0%20Python%E7%A7%91%E5%AD%A6%E8%AE%A1%E7%AE%97%E4%B8%89%E7%BB%B4%E5%8F%AF%E8%A7%86%E5%8C%96.pdf',
    },{
        name: '第八章',
        title: 'Python 机器学习应用.pdf',
        src: 'https://static.idatascience.cn/pdf/%E7%AC%AC8%E7%AB%A0%20Python%20%E6%9C%BA%E5%99%A8%E5%AD%A6%E4%B9%A0%E5%BA%94%E7%94%A8.pdf',
    },
]

export const CoursePPT: React.FC<Props> = (props) => {

    const pptClick = (index) => {
        window.open('./course-ppt-preview?id='+(index+1));
    }

    return (
        <Layout style={{backgroundColor: '#f9f9f9',position:'relative'}}>
            
            <div style={{width:800,minHeight:560,backgroundColor:'#fff',margin:'40px auto 30px'}}>
                <Row style={{height:60,lineHeight:'60px',paddingLeft:40,fontSize:16}}>课件</Row>
                <Divider style={{margin:0,marginBottom:10}}/>
                {PPT.map((ppt,index)=> {
                    return <Row key={index} className={coursePPTStyles.PPT}>
                        <Col span={6}>{ppt.name}</Col>
                        <Col span={18} onClick={()=>{pptClick(index)}} className={coursePPTStyles.PPTtitle}>{ppt.title}</Col>
                    </Row>
                })
                }
            </div>
            

        </Layout>
    )
}

export default CoursePPT