import { log } from 'console'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { sendHttpPostRequest } from '../../../api/http-request'
import Cookies from 'js-cookie'
import {
  Button,
  Col,
  Empty,
  Input,
  Layout,
  message,
  Row,
  Select,
  Skeleton,
  Tabs,
  Dropdown,
  Upload,
} from 'antd'
import css from './case-detail.module.less'
import * as constants from '@/constants/constants'
import GlobalCss from '@/global.module.less'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import rehypeRaw from 'rehype-raw'
import 'katex/dist/katex.min.css'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter' // 代码高亮
//高亮的主题，还有很多别的主题，可以自行选择
import { oneLight } from 'react-syntax-highlighter/dist/esm/styles/prism'
import CommonModal from '@/component/modal/CommonModal/CommonModal'
import { PlusOutlined, DownOutlined } from '@ant-design/icons'
import { FileList } from './FileList'
import CopyToClipboard from 'react-copy-to-clipboard'
import defaultCaseCover from '@/static/image/case/default_case_cover.png'

import type { MenuProps } from 'antd'
interface Props {}

const { Option } = Select
const { TextArea } = Input

export const CaseDetail: React.FC<Props> = (props: Props) => {
  const location = useLocation()
  const fromOAM = location.pathname?.includes('oam') ? 1 : 0
  const fromCourse = location?.pathname?.includes('course')
  const caseId = fromCourse
    ? location?.search?.split('case_id=')[1].split('?')[0]
    : location?.search?.split('case_id=')[1]
  const courseId = location?.search?.split('course_id=')[1]

  const history = useHistory()
  const [userLogin, setUserLogin] = useState(false) // 用户登录状态
  const [caseInfo, setCaseInfo] = useState<any>()
  const [fileContent, setFileContent] = useState<any>()
  const [forkModalOpen, setForkModalOpen] = useState(false)
  const [editState, setEditState] = useState(false)
  const [coverFileList, setCoverfileList] = useState([])
  const [fields, setFields] = useState([]) //无全部的行业选择
  const [tabActiveKey, setTabActiveKey] = useState('exp_file')
  const [expDatasetInfo, setExpDatasetInfo] = useState<any>()
  const [activeMontedDatasetInfo, setActiveMontedDatasetInfo] = useState<any>()
  const [infoLoading, setInfoLoading] = useState(true)
  const [ipynbFileLoading, setIpynbFileInfoLoading] = useState(true)
  const [reForkModalOpen, setReForkModalOpen] = useState(false)

  useEffect(() => {
    // let requestArr = [queryCase(), queryIpynbFile()]
    // Promise.all(requestArr)
    //   .then((res) => {
    //     setInfoLoading(false)
    //   })
    //   .catch((e) => {
    //     message.error(e)
    //     setInfoLoading(false)
    //   })
    queryCase()
    queryIpynbFile()
    queryDoMain()

    return () => {}
  }, [])

  const items: MenuProps['items'] = [
    {
      label: '重新Fork',
      key: '1',
      onClick: () => {
        setReForkModalOpen(true)
      },
    },
  ]

  const MenuItem = (props: any) => {
    const { dataset, onClick, index } = props

    return (
      <Row
        onClick={onClick}
        className={
          css.menuItem +
          ' ' +
          (dataset.dataset_id == activeMontedDatasetInfo?.dataset_id ||
          dataset.dataset_id == activeMontedDatasetInfo?.id
            ? css.activeMenuItem
            : '')
        }
        align={'top'}
        justify={'space-between'}
        wrap={false}
      >
        <Col>
          <Row
            align={'top'}
            justify={'start'}
            style={{ gap: '10px' }}
            wrap={false}
          >
            <Col>
              <span
                style={{
                  color: '#07C160',
                }}
                className="iconfont icon_a-zu3673"
              ></span>
            </Col>
            <Col>
              <div
                className={css.dtstTitle + ' ' + GlobalCss.oneLineTextOverflow}
                style={{ maxWidth: '200px' }}
              >
                {dataset.title}
              </div>
              <CopyToClipboard
                text={'../' + dataset?.base_path}
                onCopy={() => {
                  message.success('路径已复制')
                }}
              >
                <span
                  className={
                    css.datasetBasePath + ' ' + GlobalCss.oneLineTextOverflow
                  }
                >
                  {dataset?.base_path}
                </span>
              </CopyToClipboard>
            </Col>
          </Row>
        </Col>
        {/* <Col>
          <div
            className={GlobalCss.btnBox}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              unMountDataset(dataset, index)
            }}
          >
            <CloseOutlined className={css.deleteIcon} />
          </div>
        </Col> */}
      </Row>
    )
  }

  const getDatasetDetail = () => {
    let data = {
      dataset_id: caseInfo?.dataset_id,
    }
    sendHttpPostRequest('dataset/api/user_get_dataset_by_id/', data, (res) => {
      if (res.code == 0) {
        setExpDatasetInfo(res.query_info)
      }
      if (res.code == -100) {
        message.error(res.message)
      }
    })
  }

  const onTabkeyChange = (key) => {
    setTabActiveKey(key)
    if (key == 'dataset') {
      getDatasetDetail()
    }
  }
  const queryDoMain = () => {
    message.destroy()
    const requestParams = {
      sessionid: Cookies.get('sessionid'),
    }
    sendHttpPostRequest(
      'case/api/query_domain_list',
      requestParams,
      queryDoMainCallback
    )
  }

  const queryDoMainCallback = (response: any) => {
    if (response.code == -100) {
      // 未登录
      document.getElementById('show-login-modal')!.click()
    } else if (response.code == 0) {
      // 成功
      const arrnew = response.domain_list
      setFields(arrnew)
    } else if (response.code == -501) {
      // 权限不足
      message.error('当前用户权限不足')
    } else {
      message.error(response.message)
    }
  }
  const caseReFork = () => {
    let data = {
      course_id: courseId,
      case_id: caseId,
    }
    // if (fromCourse) {
    //   data['course_id'] = courseId
    //   sendHttpPostRequest(
    //     'course/api/user_get_or_fork_course_case_by_id',
    //     data,
    //     (res) => {
    //       console.log(res)
    //       if (res.code == 0) {
    //         message.success('Fork成功')
    //         history.push(
    //           `/course-case-run?case_id=${res.case_id}?course_id=${courseId}`
    //         )
    //       }
    //     }
    //   )
    // } else {
    sendHttpPostRequest(
      'course/api/user_refork_course_case_by_id',
      data,
      (res) => {
        console.log(res)
        if (res.code == 0) {
          message.success('Fork成功')
          if (caseInfo?.is_system) {
            history.push(`/case-run?case_id=${res.case_info.case_id}`)
          } else {
            history.push(
              `/course-case-run?case_id=${res.case_info.case_id}?course_id=${courseId}`
            )
          }
        }
      }
    )
    // }
  }

  const routeToCaseRun = (case_id: number, course_id?: any) => {
    if (fromCourse && !caseInfo?.is_system) {
      window.open(`/course-case-run?case_id=${case_id}?course_id=${course_id}`)
    } else {
      history.push(`/case-run?case_id=${case_id}`)
    }
  }

  const caseFork = () => {
    let data = {
      case_id: caseId,
    }
    if (fromCourse) {
      data['course_id'] = courseId
      sendHttpPostRequest(
        'course/api/user_get_or_fork_course_case_by_id',
        data,
        (res) => {
          console.log(res)
          if (res.code == 0) {
            message.success('Fork成功')
            routeToCaseRun(res.case_info.case_id, courseId)
            // history.push(
            //   `/course-case-run?case_id=${res.case_id}?course_id=${courseId}`
            // )
          }
        }
      )
    } else {
      sendHttpPostRequest('case/api/user_fork_case', data, (res) => {
        console.log(res)
        if (res.code == 0) {
          message.success('Fork成功')
          routeToCaseRun(res.case_id)
          // history.push(`/case-run?case_id=${res.case_id}`)
        }
      })
    }
  }

  const queryIpynbFile = () => {
    setIpynbFileInfoLoading(true)
    let params = {
      case_id: caseId,
    }
    sendHttpPostRequest(
      'case/api/user_preview_case_ipynb_content',
      params,
      (res) => {
        if (res.code == 0) {
          setTimeout(() => {
            setIpynbFileInfoLoading(false)
          }, 1000)
          setFileContent(res.text_content)
        } else {
          message.error(res.message)
        }
      }
    )
  }

  /**
   * 查询实验信息
   */
  const queryCase = () => {
    setInfoLoading(true)
    let params = {
      sessionid: Cookies.get('sessionid'),
      case_id: caseId,
      is_my: '2',
      from_oam: fromOAM,
    }
    // if (caseId) {
    //   params['course_id'] = caseId
    // }

    if (fromCourse) {
      params['course_id'] = courseId
      sendHttpPostRequest(
        'course/api/user_query_course_case_by_id',
        params,
        queryCaseCallback
      )
    } else {
      sendHttpPostRequest(
        'case/api/query_case_by_id',
        params,
        queryCaseCallback
      )
    }
  }

  /**
   * 查询实验回调
   * @param response
   */
  const queryCaseCallback = (response: any) => {
    message.destroy()
    if (response.code == -100) {
      // 未登录
      setUserLogin(false)
      document.getElementById('show-login-modal')!.click()
    } else if (response.code == 0) {
      setUserLogin(true)
      setCaseInfo(response?.case_info)
      setInfoLoading(false)
    } else {
      message.error(response.message)
    }
  }
  // 校验图片类型和大小
  function beforeCoverUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('请上传png或jpg格式的图片!')
    }
    const isLt5M = file.size / 1024 / 1024 < 5
    if (!isLt5M) {
      message.error('请上传小于5M的图片')
    }
    return isJpgOrPng && isLt5M
  }
  // 上传封面
  const onCoverChange = (info: any) => {
    setCoverfileList(info.fileList)
    const { status } = info.file
    if (status !== 'uploading') {
      //上传中
      console.log(info.file, info.fileList)
    }
    if (status === 'done') {
      //上传成功
      let new_cover = info.file.response.image_url
      let code = info.file.response.code
      if (code == -100) {
        document.getElementById('show-login-modal')!.click()
      } else {
        caseInfo.image = new_cover
        setCaseInfo({ ...caseInfo })
      }
    } else if (status === 'error') {
      //上传失败
      message.error(`${info.file.name} file upload failed.`)
    }
  }
  const uploadCoverButton = (
    <div style={{ width: '200px', height: '150px' }}>
      {<PlusOutlined />} 上传封面
      <div
        style={{ marginTop: 6, color: '#666', fontSize: 12, padding: '0 10px' }}
      >
        （ 提示：请上传格式为jpg、png的图片，大小不得超过5M。）
      </div>
    </div>
  )

  // 上传cover传参
  const uploadCoverData = {
    sessionid: Cookies.get('sessionid'),
    sid: Cookies.get('sessionid'),
    case_id: caseId,
    // token: window.location.search.split('&')[1].split('token=')[1],
  }

  const onCaseNameChange = (e) => {
    caseInfo.name = e.target.value
    setCaseInfo({ ...caseInfo })
  }

  // 即时保存实验领域
  const onCaseDomainChange = (domain: string) => {
    sendHttpPostRequest(
      'case/api/update_case',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        case_id: caseId,
        domain: domain,
      },
      saveCaseInfoCallback
    )
  }

  // 保存实验信息回调
  const saveCaseInfoCallback = (response: any) => {
    if (response.code == -100) {
      // 未登录
      document.getElementById('show-login-modal')!.click()
    } else if (response.is_ok) {
      queryCase()
    } else {
      message.error(response.message)
    }
  }

  const onSave = () => {
    if (!caseInfo?.name) {
      message.error('实验名称不能为空！')
      return
    }
    setEditState(false)
    sendHttpPostRequest(
      'case/api/update_case',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        case_id: caseId,
        description: caseInfo.desc,
        name: caseInfo.name,
      },
      saveCaseInfoCallback
    )
  }

  const onCaseDescChange = (e) => {
    caseInfo.desc = e.target.value
    setCaseInfo({ ...caseInfo })
  }

  const queryMountedDatasetInfo = (id) => {
    let data = {
      dataset_id: id,
    }
    sendHttpPostRequest('dataset/api/oam_get_dataset_by_id/', data, (res) => {
      if (res.code == 0) {
        setActiveMontedDatasetInfo({
          ...res.query_info,
          dataset_id: res.query_info.id,
        })
      } else {
        message.error(res.message)
      }
    })
  }

  const onMenuClick = (dataset) => {
    queryMountedDatasetInfo(dataset?.dataset_id)
  }

  const CourseForkBtnGroup = (
    <div>
      {caseInfo?.need_fork ? (
        <Button
          onClick={() => setForkModalOpen(true)}
          className={GlobalCss.radiusBtn + ' ' + GlobalCss.defBtn}
        >
          Fork
        </Button>
      ) : (
        <Dropdown.Button
          overlayStyle={{
            width: '95px',
          }}
          type="primary"
          icon={<DownOutlined />}
          menu={{ items }}
          onClick={() => {
            let params = {
              case_id: caseId,
              course_id: courseId,
            }
            sendHttpPostRequest(
              'course/api/user_get_or_fork_course_case_by_id',
              params,
              (res) => {
                if (res.code == 0) {
                  // window.open(
                  //   `/course-case-run?case_id=${res.case_info.case_id}?course_id=${courseId}`
                  // )
                  window.open('/case-run?case_id=' + res.case_info.case_id)
                } else {
                  message.error(res.message)
                }
              }
            )
          }}
        >
          运行
        </Dropdown.Button>
      )}
    </div>
  )

  const DefaultForkBtnGroup = (
    <div className={css.btnGroup}>
      <Button
        className={GlobalCss.radiusBtn + ' ' + GlobalCss.defBtn}
        onClick={() => setEditState(true)}
      >
        编辑
      </Button>
      <Button
        className={GlobalCss.radiusBtn}
        type="primary"
        onClick={() => {
          if (fromCourse) {
            history.push(
              '/course-case-run?case_id=' + caseId + '?course_id=' + courseId
            )
          } else {
            window.open('/case-run?case_id=' + caseId)
          }
        }}
      >
        运行
      </Button>
    </div>
  )

  const renderBtnGroup = (
    <Col flex={'100px'} style={{ alignSelf: 'center' }}>
      {/* 是否是编辑状态 */}
      {editState ? (
        <Button className={GlobalCss.radiusBtn} type="primary" onClick={onSave}>
          保存
        </Button>
      ) : caseInfo?.is_system ? (
        fromCourse && caseInfo?.is_course_teacher ? (
          CourseForkBtnGroup
        ) : fromCourse && caseInfo?.is_course_student ? (
          CourseForkBtnGroup
        ) : (
          <Button
            onClick={() => setForkModalOpen(true)}
            className={GlobalCss.radiusBtn + ' ' + GlobalCss.defBtn}
          >
            Fork
          </Button>
        )
      ) : fromCourse && caseInfo?.is_course_teacher ? (
        CourseForkBtnGroup
      ) : fromCourse && caseInfo?.is_course_student ? (
        CourseForkBtnGroup
      ) : (
        DefaultForkBtnGroup
      )}
    </Col>
  )
  return (
    <Layout style={{ minHeight: 750, backgroundColor: '#f9f9f9' }}>
      <div className={css.infoContain}>
        {infoLoading ? (
          <Skeleton active avatar paragraph={{ rows: 4 }} />
        ) : (
          <Row
            className={css.headerInfo}
            wrap={false}
            justify={'start'}
            align={'top'}
          >
            <Col
              flex={'250px'}
              className={GlobalCss.ColFlex + ' ' + css.uploadContain}
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '150px',
              }}
            >
              {editState ? (
                <Upload
                  beforeUpload={beforeCoverUpload}
                  accept={'image/*'}
                  action={
                    constants.BACKEND_API_URL + 'case/api/upload_case_image'
                  }
                  listType="picture-card"
                  name="case_cover"
                  fileList={coverFileList}
                  onChange={onCoverChange}
                  data={uploadCoverData}
                  showUploadList={false}
                >
                  <img
                    width={250}
                    height={150}
                    style={{
                      minHeight: '150px',
                      minWidth: '250px',
                    }}
                    src={
                      caseInfo?.image
                        ? caseInfo?.image?.startsWith('http')
                          ? caseInfo?.image
                          : constants.BACKEND_URL + caseInfo?.image
                        : defaultCaseCover
                    }
                  />
                  {/* {caseInfo?.image ? (
                    
                  ) : (
                    uploadCoverButton
                  )} */}
                </Upload>
              ) : (
                <img
                  width={'250px'}
                  height={150}
                  src={
                    caseInfo?.image
                      ? constants.BACKEND_URL + caseInfo?.image
                      : defaultCaseCover
                  }
                  alt=""
                />
              )}
            </Col>
            <Col
              className={css.headerMid + ' ' + GlobalCss.ColFlex}
              flex={'auto'}
            >
              {editState ? (
                <Row
                  align={'middle'}
                  justify={'start'}
                  style={{ gap: '20px', width: '100%' }}
                  wrap={false}
                >
                  <Col flex={'auto'}>
                    <Input
                      maxLength={40}
                      onChange={onCaseNameChange}
                      value={caseInfo?.name}
                      placeholder="请输入实验名称"
                    />
                  </Col>
                  <Col flex={'200px'}>
                    <Select
                      defaultValue={caseInfo?.domain}
                      onChange={onCaseDomainChange}
                      style={{
                        textAlign: 'left',
                        display: 'block',
                        width: '200px',
                      }}
                    >
                      {fields?.map((item, index) => {
                        // 2、利用map属性
                        return (
                          <Option value={item} key={index}>
                            {item}
                          </Option>
                        )
                      })}
                    </Select>
                  </Col>
                </Row>
              ) : (
                <div className={css.headerTitle}>{caseInfo?.name}</div>
              )}
              {editState ? (
                ''
              ) : (
                <div style={{ marginBottom: 10 }}>
                  <span
                    style={{
                      padding: '3px 8px 3px 8px',
                      fontSize: 12,
                      height: 18,
                      color: '#00CC99',
                      backgroundColor: '#F2F2F2',
                      borderRadius: 10,
                      marginBottom: 5,
                    }}
                  >
                    {caseInfo?.domain}
                  </span>
                  <span
                    style={{
                      color: '#666',
                      marginLeft: '10px',
                    }}
                  >
                    创建时间：{caseInfo?.create_time?.slice(0, 10)}
                  </span>
                </div>
              )}
              {editState ? (
                <TextArea
                  style={{ borderRadius: 3 }}
                  value={caseInfo?.desc}
                  defaultValue={caseInfo?.desc}
                  onChange={onCaseDescChange}
                  placeholder="请输入实验简介"
                  rows={4}
                />
              ) : (
                <div
                  className={GlobalCss.threeLinesTextOverflow + ' ' + css.desc}
                >
                  {caseInfo?.desc}
                </div>
              )}
            </Col>
            {renderBtnGroup}
          </Row>
        )}
        <div className={css.TabContain}>
          <Tabs activeKey={tabActiveKey} onChange={onTabkeyChange}>
            <Tabs.TabPane tab="实验文件" key="exp_file">
              {ipynbFileLoading ? (
                <div className={css.fileInfoContain}>
                  <Skeleton active paragraph={{ rows: 10 }} />
                </div>
              ) : (
                <div className={css.fileInfoContain}>
                  {fileContent?.cells?.map((cell) => {
                    return cell.cell_type == 'markdown' ? (
                      <div
                        key={cell.id}
                        id={cell.id}
                        className={css.markdownContain}
                      >
                        <ReactMarkdown
                          remarkPlugins={[
                            [remarkGfm, { singleTilde: false }],
                            [remarkMath],
                          ]}
                          rehypePlugins={[rehypeKatex, rehypeRaw]}
                        >
                          {Array.isArray(cell.source) &&
                            cell.source?.join('\n')}
                        </ReactMarkdown>
                      </div>
                    ) : cell?.cell_type == 'code' ? (
                      <div className={css.codeContain}>
                        <SyntaxHighlighter
                          language={'python'}
                          key={cell.id}
                          style={oneLight}
                          id={cell.id}
                        >
                          {Array.isArray(cell.source)
                            ? cell.source?.join('')
                            : cell.source}
                        </SyntaxHighlighter>
                        {cell?.outputs?.length > 0
                          ? cell?.outputs?.map((output: any, i) => {
                              if (output.output_type == 'stream') {
                                return (
                                  <div
                                    key={
                                      output.output_type +
                                      output.execution_count
                                    }
                                    className={css.outputMarkdownContain}
                                  >
                                    <ReactMarkdown
                                      remarkPlugins={[
                                        [remarkGfm, { singleTilde: false }],
                                        [remarkMath],
                                      ]}
                                      rehypePlugins={[rehypeKatex, rehypeRaw]}
                                    >
                                      {output.text?.join('')}
                                    </ReactMarkdown>
                                  </div>
                                )
                              } else if (output.output_type == 'display_data') {
                                return (
                                  <div
                                    key={
                                      output.output_type +
                                      output.execution_count
                                    }
                                    className={css.outputMarkdownContain}
                                  >
                                    {output.data['image/png'] ? (
                                      <img
                                        style={{ objectFit: 'contain' }}
                                        width={'100%'}
                                        src={
                                          'data:image/png;base64,' +
                                          output.data['image/png']
                                        }
                                        alt="图片"
                                      />
                                    ) : (
                                      ''
                                    )}
                                    <ReactMarkdown
                                      remarkPlugins={[
                                        [remarkGfm, { singleTilde: false }],
                                        [remarkMath],
                                      ]}
                                      rehypePlugins={[rehypeKatex, rehypeRaw]}
                                    >
                                      {output.data['text/plain']
                                        ?.join('')
                                        ?.replaceAll('\n', '<br/>')}
                                    </ReactMarkdown>
                                  </div>
                                )
                              } else if (
                                output.output_type == 'execute_result'
                              ) {
                                return (
                                  <div
                                    key={
                                      output.output_type +
                                      output.execution_count
                                    }
                                    className={css.outputMarkdownContain}
                                  >
                                    {output.data['text/html']?.length > 0 ? (
                                      <ReactMarkdown
                                        remarkPlugins={[
                                          [remarkGfm, { singleTilde: false }],
                                          [remarkMath],
                                        ]}
                                        rehypePlugins={[rehypeKatex, rehypeRaw]}
                                      >
                                        {output.data['text/html']
                                          ?.join('')
                                          ?.replaceAll('<br/>', '')}
                                      </ReactMarkdown>
                                    ) : (
                                      <ReactMarkdown
                                        remarkPlugins={[
                                          [remarkGfm, { singleTilde: false }],
                                          [remarkMath],
                                        ]}
                                        rehypePlugins={[rehypeKatex, rehypeRaw]}
                                      >
                                        {output.data['text/plain']
                                          ?.join('')
                                          ?.replaceAll('\n', '<br/>')}
                                      </ReactMarkdown>
                                    )}
                                  </div>
                                )
                              }
                            })
                          : ''}
                      </div>
                    ) : null
                  })}
                </div>
              )}
            </Tabs.TabPane>
            <Tabs.TabPane tab="实验数据" key="dataset">
              <div
                style={{
                  padding: '0px 30px',
                  minHeight: '500px',
                }}
              >
                {expDatasetInfo && (
                  <FileList
                    actionPath={location.pathname + location.search}
                    datasetInfo={expDatasetInfo}
                    mode={caseInfo?.is_system || fromCourse ? 'monted' : 'case'}
                  />
                )}
              </div>
            </Tabs.TabPane>
            {caseInfo?.mounted_datasets.length > 0 ? (
              <Tabs.TabPane tab="挂载数据" key="mnt_dataset">
                <div
                  style={{
                    padding: '0px 30px',
                    minHeight: '500px',
                  }}
                >
                  {caseInfo?.mounted_datasets?.length > 0 ? (
                    <Row className={css.montedContain} wrap={false}>
                      <Col className={css.montedMenu} flex={'280px'}>
                        {caseInfo?.mounted_datasets?.map((dtst, i) => {
                          return (
                            <MenuItem
                              key={dtst.dataset_id}
                              dataset={dtst}
                              onClick={onMenuClick.bind(this, dtst)}
                              index={i}
                            />
                          )
                        })}
                      </Col>
                      <Col className={css.montedTable} flex={'auto'}>
                        {activeMontedDatasetInfo && (
                          <div style={{ padding: '20px 30px' }}>
                            {' '}
                            <FileList
                              actionPath={location.pathname + location.search}
                              datasetInfo={activeMontedDatasetInfo}
                              mode={'monted'}
                            />
                          </div>
                        )}
                      </Col>
                    </Row>
                  ) : (
                    <div
                      style={{
                        width: '100%',
                        height: '500px',
                        lineHeight: '500px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={
                          <span style={{ color: '#999999' }}>
                            暂无数据
                            {/* {caseInfo?.is_system ? '' : '点击'}
                          {caseInfo?.is_system ? (
                            ''
                          ) : (
                            <span
                              // onClick={() => setAddDatasetModalVis(true)}
                              className={
                                GlobalCss.link + ' ' + GlobalCss.hlText
                              }
                            >
                              添加数据集
                            </span>
                          )} */}
                          </span>
                        }
                      />
                    </div>
                  )}
                </div>
              </Tabs.TabPane>
            ) : (
              ''
            )}
          </Tabs>
        </div>
      </div>
      <CommonModal
        modalTitle={'fork'}
        open={forkModalOpen}
        onOk={caseFork}
        onCancel={() => setForkModalOpen(false)}
        modalContent={
          <Row style={{ height: '100px' }} align={'middle'} justify={'center'}>
            <Col>
              确认要fork实验
              <span className={GlobalCss.hlText}>{caseInfo?.name}</span>吗？
            </Col>
          </Row>
        }
      />
      <CommonModal
        modalTitle={'提示'}
        open={reForkModalOpen}
        onOk={caseReFork}
        onCancel={() => setReForkModalOpen(false)}
        modalContent={
          <Row style={{ height: '100px' }} align={'middle'} justify={'center'}>
            <Col>您已Fork过该实验，再次Fork会覆盖原有的实验，确认再次Fork?</Col>
          </Row>
        }
      />
    </Layout>
  )
}

export default CaseDetail
