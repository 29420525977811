import {
    Layout,
    Button,
    Input,
    message,
    Table,
    ConfigProvider,
    Tabs,
    Row,
    Col,
    Popconfirm,
    Statistic,
    Pagination,
    Breadcrumb,
    Upload,
    Popover,
} from 'antd';

import selfCss from './oam-lecture.module.less'

import courseBasicInfoStyle from "../../course/course-edit-basicinfo.module.less"

import {PlayCircleOutlined, DownOutlined,SearchOutlined, DeleteOutlined, FileSearchOutlined, PlusSquareOutlined, UploadOutlined} from "@ant-design/icons";

import { Content } from 'antd/lib/layout/layout';
import zhCN from 'antd/lib/locale/zh_CN';
import OAMSider from '../oam-sider';
import React, {useEffect, useRef, useState} from "react";
import {sendHttpPostRequest} from "../../../api/http-request";
import Cookies from 'js-cookie';


import * as constants from '../../../constants/constants';
const { Search } = Input;
const { TabPane } = Tabs;

export interface Props {}

/** OAM 讲堂Banner编辑页面 */
export const OAMLectureBannerEdit: React.FC<Props> = (props) => {
    const bannerId = parseInt(window.location.search.split("=")[1]);  // 节点的ID
    const [banner, setBanner] = useState(null as any);  // 讲座对象
    const [title, setTitle] = useState(null as any);  // 
    const [teacher, setTeacher] = useState(null as any);  //
    const [teacherDescription, setTeacherDescription] = useState(null as any);  // 
    const [description, setDescription] = useState(null as any);  //
    const [cover, setCover] = useState(null as any);  //
    const [timeInfo, setTimeinfo] = useState(null as any);  // 



    const draggleRef = useRef<HTMLDivElement | null>(null)
  
    const [bounds,setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const [disabled,setDisabled] = useState(true)
  
    const onStart = (event:any, uiData:any) => {
      const { clientWidth, clientHeight } = window?.document?.documentElement;
      const targetRect= draggleRef?.current?.getBoundingClientRect();
      if(targetRect){
        setBounds( {
          left: -targetRect?.left + uiData?.x,
          right: clientWidth - (targetRect?.right - uiData?.x),
          top: -targetRect?.top + uiData?.y,
          bottom: clientHeight - (targetRect?.bottom - uiData?.y),
        }
      )
      }
    }
  

    useEffect(()=> {
        queryBannerInfo(bannerId);
    },[])
        
    // 查询Banner详情
    const queryBannerInfo = (bannerId) => {
        sendHttpPostRequest(
            'video/api/oam_get_lecture_banner_by_id/',
            {banner_id: bannerId, sessionid: Cookies.get("sessionid")},
            (response) => {
                message.destroy();
                if (response.code == -100) {
                    // 未登录
                    window.location.href = '/oam_login?next=oam_lecture_banner_list';
                } else if (response.is_ok == true) {
                    // 成功
                    setBanner(response.query_info);
                    setTitle(response.query_info.title);
                    setTeacher(response.query_info.teacher);
                    setTimeinfo(response.query_info.time_info);
                    setTeacherDescription(response.query_info.teacher_description);
                    setDescription(response.query_info.description);
                    setCover(response.query_info.cover);
                } else if (response.code == -501) {
                    // 权限不足
                    window.location.href = '/oam_login?next=oam_lecture_banner_list';
                } else {
                    message.error(response.message);
                }
            }
        )
    }



        
   // 上传讲座封面传参
   const uploadData = {
    sessionid: Cookies.get("sessionid"),
    sid: Cookies.get("sessionid"),
    banner_id: bannerId,
}


    // 上传图片
    const uploadImageProps = {
      accept: '.png,.jpg,jpeg,.gif,.svg',
      name: 'banner_cover',
      multiple: false,
      showUploadList: false,
      data: uploadData,
      action: constants.BACKEND_API_URL + 'video/api/upload_lecture_banner_cover/',
      onChange(info) {
          const { status } = info.file;
          if (status !== 'uploading') {
              console.log(info.file, info.fileList);
          }
          if (status === 'done') {
              if (info.file.response.is_ok == true) {
                  message.success(`图片${info.file.name}文件上传成功！`);
                  setCover(info.file.response.image_url);
                  
              } else {
                  if (info.file.response.code == -100) {
                      // 未登录
                      const localPath = window.location.href.split(window.location.host)[1];
                      window.location.href = `/oam_login?next=${localPath}`;
                  } else if (info.file.response.code == -501) {
                      // 权限不足
                      const localPath = window.location.href.split(window.location.host)[1];
                      window.location.href = `/oam_login?next=${localPath}`;
                  } else {
                      message.error(info.file.response.message);
                  }
              }

          } else if (status === 'error') {
              message.error(`图片${info.file.name}文件上传失败了:(`);
          }
      },
      onDrop(e) {
          console.log('Dropped files', e.dataTransfer.files);
      },
  };




    // 点了预览按钮
    const onLookOverBtnClick = (event: any) => {
        //window.open(`/lecture-vod?lecture_id=${lectureId}`)
    }


    // 保存讲座
    const saveLectureBanner = (key: any) => {

        if(title?.trim() == ''){
            message.error("请输入报告题目");
            return ''
        }


        message.destroy();

        const requestParams = {
            sessionid: Cookies.get("sessionid"),
            sid: Cookies.get("sessionid"),
            banner_id: bannerId,
            title: title,
            teacher: teacher,
            teacher_description: teacherDescription,
            description: description,
            cover: cover,
            time_info: timeInfo,
            
         
        }
        sendHttpPostRequest(
            'video/api/oam_update_lecture_banner/',
            requestParams,
            (response: any) => {
                if (response.code == -100) {
                    // 未登录
                    window.location.href = '/oam_login?next=oam_lecture_banner_list';
                } else if (response.is_ok == true) {
                    // 成功
                    message.success("Banner信息保存成功~");
                    //setMdSteps(exercise.steps);
                    //window.opener.location.reload();
                } else if (response.code == -501) {
                    // 权限不足
                    window.location.href = '/oam_login?next=oam_lecture_banner_list';
                } else {
                    message.error(response.message);
                }
            }
        )
    }


    return (
        <Layout style={{ minHeight: '100vh' }}>
            <OAMSider keys={['9']} openKeys={["sub-lecture"]}  />
                

            <Content style={{ backgroundColor:'#fff',borderRadius:5,minWidth:500,margin:50 ,paddingTop:20}}>
                <Breadcrumb style={{marginLeft:0,float:'left',marginTop: -50}}>
                    <Breadcrumb.Item>
                    <a href="/oam_lecture_banner_list">Banner列表</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                    <span style={{fontWeight: 500}}> Banner编辑 </span> 
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className={"css.search1"}  style={{paddingTop:20,paddingLeft:50,paddingRight:50,position:'relative'}}>
  
                <Row gutter={[8,8]} justify='start'  align='middle' style={{marginTop:-10,marginBottom:10}} >

                        <Col style={{marginTop:-10,textAlign:'right'}} span={24}>
                            <Button type="default" style={{width:80,borderRadius:20,marginRight:10}} onClick={onLookOverBtnClick}>预览</Button>
                            <Button type="primary" style={{width:80,borderRadius:20}} onClick={saveLectureBanner}>保存</Button>
                        </Col>
                    </Row>  

            <div style={{padding:20,borderRadius:6,borderStyle:'solid',borderWidth:1,borderColor:"#e9e9e9"}}>
                    <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:20}} >
                        <Col flex="100px" style={{color:"#00000073",textAlign:'left'}}>报告题目</Col>
                        <Col flex="auto" style={{textAlign:'left'}}>
                            <Input placeholder="请输入报告题目" value={title} onChange={(e)=>{setTitle(e.target.value)}} style={{borderRadius:3,width:"100%"}}/>
                        </Col>
                        
                    </Row>

                    <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:20}} >
                        <Col flex="100px" style={{color:"#00000073",textAlign:'left'}}>背景图</Col>
                        <Col flex="auto" style={{textAlign:'left'}}>
                            <Input placeholder="请输入背景图URL" value={cover} onChange={(e)=>{setCover(e.target.value)}} style={{borderRadius:3,width:"100%"}}/>
                        </Col>
                        
                    </Row>

                    <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:20}} >
                            <Col flex="100px" style={{color:"#00000073",textAlign:'left'}}></Col>
                            <Col flex="auto" style={{textAlign:'left'}}>
                                <Upload className={courseBasicInfoStyle.uploadImage} {...uploadImageProps}>
                                
                                    { cover?
                                        <img
                                        id="banner-cover"
                                        width={677}
                                        height={124}
                                        src={ cover?.startsWith("http") ? cover : constants.BACKEND_URL + cover}
                                        /> : <div  style={{paddingTop:50,textAlign:"center",width:677,height:124,border:"1px solid #00000073"}}> <div className={courseBasicInfoStyle.uploadImageText} >+ 上传(点击或拖拽) </div></div>
                                    }  
                                </Upload>
                            </Col>
                        </Row>


                    <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:20}} >
                        <Col flex="100px" style={{color:"#00000073",textAlign:'left'}}>时间信息</Col>
                        <Col flex="auto" style={{textAlign:'left'}}>
                            <Input placeholder="请输入报告时间信息" value={timeInfo} onChange={(e)=>{setTimeinfo(e.target.value)}} style={{borderRadius:3,width:"100%"}}/>
                        </Col>
                       
                    </Row>

                    <Row gutter={[8,8]} justify='start'  align='top' style={{marginBottom:10}} >
                        <Col flex="100px" style={{color:"#00000073",textAlign:'left'}}>报告简介</Col>
                        <Col flex="auto" style={{textAlign:'left'}}>
                            <Input.TextArea placeholder="请输入报告简介" 
                            style={{borderRadius:3,width:"100%"}} 
                            autoSize={{minRows: 4, maxRows:8}} 
                            value={description} 
                            onChange={(e)=>{setDescription(e.target.value)}}/>
                        </Col>
                    </Row>


               

                    <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:20}} >
                        <Col flex="100px" style={{color:"#00000073",textAlign:'left'}}>报告人</Col>
                        <Col flex="auto" style={{textAlign:'left'}}>
                            <Input placeholder="请输入主讲人" value={teacher} onChange={(e)=>{setTeacher(e.target.value)}} style={{borderRadius:3,width:"100%"}}/>
                        </Col>
                        
                    </Row>



                    <Row gutter={[8,8]} justify='start'  align='top' style={{marginBottom:10}} >
                        <Col flex="100px" style={{color:"#00000073",textAlign:'left'}}>报告人简介</Col>
                        <Col flex="auto" style={{textAlign:'left'}}>
                            <Input.TextArea placeholder="请输入报告人简介" 
                            style={{borderRadius:3,width:"100%"}} 
                            autoSize={{minRows: 4, maxRows:8}} 
                            value={teacherDescription} 
                            onChange={(e)=>{setTeacherDescription(e.target.value)}}/>
                        </Col>
                    </Row>

                </div>

        
            </div>
            </Content>
                
            
        </Layout>

    )

}

export default OAMLectureBannerEdit

