import React, { useEffect, useState } from 'react'
import {
  Input,
  Table,
  Switch,
  Layout,
  message,
  Card,
  Col,
  Row,
  ConfigProvider,
  Pagination,
  Tabs,
  Button,
  Tag,
  Collapse,
  Tooltip,
} from 'antd'
import courseTeacherMenuStyles from './course-main-teacher-menu.module.less'
import '../course.module.css'
import zhCN from 'antd/lib/locale/zh_CN'
import * as constants from '../../../constants/constants'

import LoginRegisterModal from '../../../component/modal/login-register/login-register'
import { checkUserLogin } from '../../common/user-center/user-info'
import {
  FilePdfOutlined,
  BarsOutlined,
  VideoCameraOutlined,
  CodeOutlined,
  FormOutlined,
  UserOutlined,
} from '@ant-design/icons'
import Cookies from 'js-cookie'
import { sendHttpPostRequest } from '../../../api/http-request'
const { Panel } = Collapse

export interface Props {
  menu_id: any
  course_user: string
  course_status: string
  course_id: number
}

/** 课程大纲标签页 */
export const MenuPreview: React.FC<Props> = (props) => {
  const { menu_id } = props
  const { course_user } = props
  const { course_status } = props
  const { course_id } = props
  const [menu, setMenu] = useState(null as any)
  const [user, setUser] = useState(course_user)
  const [status, setStatus] = useState(course_status)
  const [description, setDescription] = useState('')

  /**
   * 查询大纲
   */

  useEffect(() => {
    queryMenu()
  }, [])

  const queryMenu = () => {
    sendHttpPostRequest(
      'course/api/user_query_menu_by_id',
      {
        sessionid: Cookies.get('sessionid'),
        menu_id: menu_id,
        description: description,
        format: 1,
      },
      queryMenuCallback
    )
  }

  /**
   * 查询大纲回调
   * @param response
   */
  const queryMenuCallback = (response: any) => {
    // console.log(response)
    //message.destroy();
    if (response.code == -100) {
      // 未登录
      document.getElementById('show-login-modal')!.click()
    } else if (response.is_ok == true) {
      // 成功
      setMenu(response.query_info)
      setDescription(response.query_info.description)
    } else {
      message.error(response.message)
    }
  }

  const tabpaneWrapperWidth = (user: string, status: string) => {
    // console.log(status)
    return user == 'student' && status == '未加入' ? 1220 : 1280
  }

  const onKnowledgeClick = (chapter_id: number, knowledge_id: number) => {
    if (!checkUserLogin()) {
      document.getElementById('show-login-modal')!.click()
    } else {
      if (!['未开始', '未加入', '已结束'].includes(course_status)) {
        window.open(
          `/course-learn?course/${course_id}/chapter/${chapter_id}/knowledge/${knowledge_id}/`
        )
      }
    }
  }

  return (
    <div
      className={courseTeacherMenuStyles.tabpaneWrapper}
      style={{ width: tabpaneWrapperWidth(user, status) }}
    >
      {menu?.json?.map((chapter, chapterInd) => (
        <>
          <div
            className={courseTeacherMenuStyles.chapterName}
            style={{ textAlign: 'left', fontWeight: 700 }}
          >
            {' '}
            {'第' + (chapterInd + 1) + '章 ' + chapter.name}
          </div>

          {chapter.tasks.map((module, moduleIndex) => (
            <div
              key={module.id}
              style={{ cursor: 'pointer' }}
              className={courseTeacherMenuStyles.modules}
              onClick={onKnowledgeClick.bind(this, chapter.id, module.id)}
            >
              <Row gutter={[4, 4]} justify="start" align="middle">
                <Col style={{ marginLeft: 30, textAlign: 'left' }} flex="auto">
                  {' '}
                  {chapterInd + 1 + '.' + (moduleIndex + 1) + ' ' + module.name}
                </Col>
                <Col flex="100px" style={{ textAlign: 'right' }}>
                  {module.if_practice && (
                    <Tooltip placement="top" title={'练习'}>
                      {' '}
                      <BarsOutlined
                        className={courseTeacherMenuStyles.iconbuttons}
                      />
                    </Tooltip>
                  )}
                  {module.if_case && (
                    <Tooltip placement="top" title={'实验'}>
                      {' '}
                      <CodeOutlined
                        className={courseTeacherMenuStyles.iconbuttons}
                      />{' '}
                    </Tooltip>
                  )}
                  {module?.type == 1 ? (
                    <Tooltip placement="top" title={'PDF'}>
                      {' '}
                      <FilePdfOutlined
                        className={courseTeacherMenuStyles.iconbuttons}
                      />{' '}
                    </Tooltip>
                  ) : (
                    <Tooltip placement="top" title={'视频'}>
                      {' '}
                      <VideoCameraOutlined
                        className={courseTeacherMenuStyles.iconbuttons}
                      />{' '}
                    </Tooltip>
                  )}
                </Col>
              </Row>
            </div>
          ))}
        </>
      ))}
    </div>
  )
}

export default MenuPreview
