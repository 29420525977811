import { Modal, Button, Row, Col, Input, message } from 'antd';
import React, { useEffect, useRef, useState } from "react";
import {sendHttpPostRequest} from "../../../api/http-request";
import Cookies from "js-cookie";
import Draggable from 'react-draggable'

import myStyles from './teacher-authenticate.module.less';

let globalIsTeacher = false;
let globalInCollege = false;
let globalCollegeId = -1;
let globalIsCollegeTeacher = false;

interface TeacherInfo {
    user_id: number,  // 用户id
    real_name: string,  // 真实姓名
    college: string,  // 院校或单位
    title: string,  // 院系或职务
    submitted: number,  // 已经提交过了
    handling_opinions: string  // 处理意见
    deal_method: number  // 处理结果
}

const TeacherAuthenticate = () => {
    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);  // 已经提交过申请了
    const [applicationFailed, setApplicationFailed] = useState(false);  // 申请未通过
    const [teacherInfo, setTeacherInfo] = useState(null as unknown as TeacherInfo);

    const draggleRef = useRef<HTMLDivElement | null>(null)
  
    const [bounds,setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const [disabled,setDisabled] = useState(true)
  
    const onStart = (event:any, uiData:any) => {
      const { clientWidth, clientHeight } = window?.document?.documentElement;
      const targetRect= draggleRef?.current?.getBoundingClientRect();
      if(targetRect){
        setBounds( {
          left: -targetRect?.left + uiData?.x,
          right: clientWidth - (targetRect?.right - uiData?.x),
          top: -targetRect?.top + uiData?.y,
          bottom: clientHeight - (targetRect?.bottom - uiData?.y),
        }
      )
      }
  

    }
    useEffect(()=> {
        queryAuthenticateRecord();
    },[])

    // 查询申请记录结果回调
    const queryCallback = (response: any) => {
        message.destroy();
        if (response.code == -100) {
            // 未登录
            // document.getElementById("show-login-modal")!.click();
        } else if (response.code == 0 || response.code == -9) {
            // 成功且申请过
            setSubmitted(true);
            setTeacherInfo(response.teacher_info);
            if (response.deal_method == 3) {
                // 处理通过，已经是教师身份
                if (response.teacher_info.role == 2) {
                    globalIsTeacher = true;
                }
            } else if (response.deal_method == 4) {
                // 被驳回
                setApplicationFailed(true);
                setSubmitted(false);
            }
            if (response.teacher_info.role == 4) {
                // 管理员
                globalIsTeacher = true;
            }
            if (response.college_info.in_college == 1) {
                // 在院校
                globalInCollege = true;
            }
            if (response.college_info.college_id) {
                globalCollegeId = response.college_info.college_id;
            }
            if (response.college_info.is_college_teacher == 1) {
                globalIsCollegeTeacher = true;
            }
        } else if (response.code == -2) {
            // 未申请过
            setTeacherInfo(response.teacher_info);
            if (response.college_info.in_college == 1) {
                // 在院校
                globalInCollege = true;
            }
            if (response.college_info.college_id) {
                globalCollegeId = response.college_info.college_id;
            }
            if (response.college_info.is_college_teacher == 1) {
                globalIsCollegeTeacher = true;
            }
        } else {
            message.error(response.message);
        }
    }

    // 查询申请记录
    const queryAuthenticateRecord = () => {
        sendHttpPostRequest(
            'account/api/query_teacher_authenticate',{
                sessionid: Cookies.get("sessionid"),
                sid: Cookies.get("sessionid"),
            },
            queryCallback
        )
        // setTeacherInfo({
        //     user_id: 3, real_name: "张三", college: "", title: "", submitted: 0
        // })
    }

    // 提交申请回调
    const commitCallback = (response: any) => {
        message.destroy();
        if (response.code == -100) {
            // 未登录
            setVisible(false);
            document.getElementById("show-login-modal")!.click();
        } else if (response.code == 0) {
            // 申请成功
            setVisible(false);
            message.success("申请已提交，请等待审核结果");
            setSubmitted(true);
        } else {
            message.error(response.message);
        }
    }

    // 提交申请
    const teacherAuthenticateCommit = () => {
        sendHttpPostRequest(
            'account/api/teacher_authenticate/',
            {
                sessionid: Cookies.get("sessionid"),
                sid: Cookies.get("sessionid"),
                user_id: teacherInfo.user_id,
                real_name: teacherInfo.real_name,
                college: teacherInfo.college,
                title: teacherInfo.title,
            },
            commitCallback
        )
    }

    // 显示模态框
    const showModal = () => {
        queryAuthenticateRecord();
        setVisible(true);
    };

    // 点击确认
    const handleOk = () => {
        console.log(teacherInfo);
        if (!submitted) {
            message.destroy();
            let errorMessage = "";
            if (!(teacherInfo.real_name.trim())) {
                errorMessage = "请输入真实姓名";
            } else if (!(teacherInfo.college.trim())) {
                errorMessage = "请输入院校或单位";
            } else if (!(teacherInfo.title.trim())) {
                errorMessage = "请输入院系或职务";
            }
            if (errorMessage.length > 0) {
                message.error(errorMessage);
            } else {
                teacherAuthenticateCommit();
                // setConfirmLoading(true);
                // setTimeout(() => {
                //     // setVisible(false);
                //     setConfirmLoading(false);
                //
                // }, 2000);
            }
        } else {
            setVisible(false);
        }
    };

    // 点击取消
    const handleCancel = () => {
        setVisible(false);
    };

    /**
     * 文本类型改变
     * @param key 键
     * @param event 值
     */
    const onTextChange = (key: string, event: any) => {
        teacherInfo[key] = event.target.value;
    }

    return (
        <>
            <Button hidden={true} type="primary" onClick={showModal} id={"show-teacher-authenticate"}></Button>

            <Modal
                title={<div
                style={{
                width: '100%',
                cursor: 'move',
                }}
                onMouseOver={() => {
                if (disabled) {
                    setDisabled(false)
                }
                }}
                onMouseOut={() => {
                setDisabled(true)
                }}
                onFocus={() => {}}
                onBlur={() => {}}
                // end
            >
                教师身份认证
            </div>
            }
            visible={visible}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            closable={false}
            onCancel={handleCancel}
            bodyStyle={{}}
            destroyOnClose={true}
            width={500}
            modalRender={modal => (<div className={myStyles.modalDiv}>
                <Draggable
                disabled={disabled}
                bounds={bounds}
                onStart={(event, uiData) => onStart(event, uiData)}
                >
                <div ref={draggleRef}>{modal}</div>
                </Draggable> </div>
            )}
            >
        
                <div hidden={submitted} style={{paddingLeft:20,paddingRight:20}}>
                    <Row gutter={[8,8]} justify='start'  align='top' style={{marginBottom:20}}>
                        <div hidden={!applicationFailed}>申请未通过，请修改信息后重新提交。【{teacherInfo?.handling_opinions}】</div>
                        <div>当前功能仅对教师开放，请补全以下信息申请教师身份认证。</div>
                    </Row>
                    <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:20}}>
                        <Col flex="100px" style={{color:"#00000073",textAlign:'left'}}>真实姓名</Col>
                        <Col flex="auto" style={{textAlign:'left'}}>
                            <Input
                                required={true}
                                placeholder="请输入真实姓名"
                                style={{width:"100%",borderRadius:3}}
                                defaultValue={teacherInfo?.real_name}
                                onChange={onTextChange.bind(this, "real_name")}
                            />
                        </Col>
                    </Row>
                    <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:20}}>
                        <Col flex="100px" style={{color:"#00000073",textAlign:'left'}}>学校院系</Col>
                        <Col flex="auto" style={{textAlign:'left'}}>
                            <Input
                                required={true}
                                placeholder="请输入学校和院系"
                                defaultValue={teacherInfo?.college}
                                style={{width:"100%",borderRadius:3}}
                                onChange={onTextChange.bind(this, "college")}
                            />
                        </Col>
                    </Row>
                    <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:20}}>
                        <Col flex="100px" style={{color:"#00000073",textAlign:'left'}}>职务</Col>
                        <Col flex="auto" style={{textAlign:'left'}}>
                            <Input
                                required={true}
                                placeholder="请输入您的职务"
                                defaultValue={teacherInfo?.title}
                                style={{width:"100%",borderRadius:3}}
                                onChange={onTextChange.bind(this, "title")}
                            />
                        </Col>
                    </Row>
                </div>
                <div hidden={!submitted}>
                    <Row gutter={[8,8]} justify='start'  align='top' style={{marginBottom:20}}>
                        <p>尊敬的用户您好！您的教师身份认证申请已经提交，请耐心等待审核结果^_^</p>
                    </Row>
                </div>
            </Modal>
        </>
    );
};

export default TeacherAuthenticate;

/**
 * 外部调用判断是否是老师
 */
export function checkIsTeacher() {
    return globalIsTeacher;
}

export function isOAMUser() {
    return false;
}

export function checkInCollege() {
    return globalInCollege;
}

export function getCollegeId() {
    return globalCollegeId;
}

export function isCollegeTeacher() {
    return globalIsCollegeTeacher;
}
