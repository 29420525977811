import Qs from 'qs';
import Cookies from 'js-cookie';
import axios from 'axios';
import * as constants from '../constants/constants';
import {logoutCallback} from "../pages/common/user-center/user-info";
import {message} from "antd";

/**
 * 发送http POST 请求
 * @param url 请求地址
 * @param params 参数
 * @param callback 回调
 */
export function sendHttpPostRequest(url: string, params: any, callback: any,  config?: any) {
    // const _headers = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}};
    const sessionid = Cookies.get('sessionid')
    params['sessionid'] = sessionid
    params['sid'] = sessionid
    const _data = Qs.stringify(params)

    const _headers = Object.assign(
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
      config
    )
    axios
        .post(constants.BACKEND_API_URL + url,
            _data,
            _headers,
        )
        .then((response) => response.data)
        .then((data) => {
            // if (data.is_ok) {
                if (callback && typeof(callback) == "function") {
                    callback(data);
                }
            // } else {
                console.log(data);
            // }
        })
        .catch((e) => {
            console.log('make http post request error', e);
            message.destroy();
            message.error("服务器请求失败了:(");
            if (url == "account/api/logout/")
                logoutCallback();   // 退出失败，清除本地缓存
        })
}




