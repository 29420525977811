import {Tabs,Pagination, Layout, message, ConfigProvider, Input, Table, Modal, Button, Row, Col, Popconfirm, Statistic} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useState, useRef, useEffect } from "react";
import Cookies from 'js-cookie';
import zhCN from 'antd/lib/locale/zh_CN';
import {sendHttpPostRequest} from "../../../api/http-request";
const { Search } = Input;
import TextArea from 'antd/lib/input/TextArea';
import OAMSider from "../oam-sider";

import idataCourseStyles from "../../common/global-styles/idatacourse-table.module.less"

import moment from 'moment'


import { DatePicker } from 'antd'

const { RangePicker } = DatePicker
const { TabPane } = Tabs;

export interface Props {}

const channelMap = new Map([['爱数科', 1], ['爱数课', 2]]);

const statusMap = new Map([[1, '下线'], [2, '在线']]);

export const OAMMenuList: React.FC<Props> = (props) => {
    const [menuResult, setMenuResult] = useState([] as any[]);  // 当前显示的结果页
    const [resultSum, setResultSum] = useState(0);  // 搜索返回的结果数
    const [totalCount, setTotalCount] = useState(0);  // 搜索返回的结果数
    const [sysCount, setSysCount] = useState(0) //搜索返回的结果数
    const [userCount, setUserCount] = useState(0) //搜索返回的结果数
    const [onlineCount, setOnlineCount] = useState(0);  // 搜索返回的结果数

    const [isEditUserModalVisible, setIsEditUserModalVisible] = useState(false);  // 模态框是否显示
    const [editUserId, setEditUserId] = useState(-1);  // 待编辑备注的用户ID
    const [editUserRemark, setEditUserRemark] = useState("");  // 备注信息
    const queryChannel = useRef(0);  // 来源渠道
    const queryContent = useRef('');  // 记录搜索串
    const querySource = useRef('2');//2-系统，1-用户
    const order = useRef('');  // 排序升序降序
    const order_by = useRef('');  // 排序列
    const page = useRef(1);  // 记录当前的页数
    const pageSize = useRef(20);  // 记录当前每页展示数量
    const sexFilters = useRef([]);  // 性别过滤选项
    const channelFilters = useRef([]);  // 渠道过滤项
    const userIsLogin = useRef(false);  // 用户是否登录

    
    useEffect(() => {
        queryMenuList();
    }, [])

    /**
     * 查询大纲列表
     */
    const queryMenuList = () => {
        sendHttpPostRequest(
            'course/api/oam_query_menu_list',
            {
                sessionid: Cookies.get("sessionid"),
                page: page.current,
                pagesize: pageSize.current,
                query: queryContent.current,
                source: querySource.current,
                order: order.current,
                order_by: order_by.current,
            },
            queryMenuListCallback
        )
    }

    /**
     * 查询大纲列表回调
     * @param response
     */
    const queryMenuListCallback = (response: any) => {
        // console.log(response)
        message.destroy();
        if (response.code == -100) {
            // 未登录
            window.location.href = '/oam_login?next=oam_chapter';
        } else if (response.is_ok == true) {
            // 成功
            setMenuResult(response.query_list);
            setResultSum(response.total_sum);
            setTotalCount(response.total_count);
            setSysCount(response.system_count);
            setUserCount(response.personal_count);
            setOnlineCount(response.online_count);

        }// else if (response.code == -501) {
            // 权限不足
            //window.location.href = '/oam_login?next=oam_chapter';
        //} else {
            //message.error(response.message);
        //}
    }

    // 切换Tab页
    const onTabKeyChange = (key:string) => {
        console.log(key);
        querySource.current = key;
        page.current = 1;
        queryMenuList();
    }

   /**
     * 删除大纲
     */
    const deleteMenu = (id: string | number) => {
        sendHttpPostRequest(
            'course/api/oam_delete_menu',
            {
                sessionid: Cookies.get("sessionid"),
                menu_id: id,
            },
            deleteMenuCallback
        )
    }
  
    /**
     * 删除大纲回调
     * @param response
     */
    const deleteMenuCallback = (response: any) => {
        // console.log(response)
        message.destroy();
        if (response.code == -100) {
            // 未登录
            window.location.href = '/oam_login?next=oam_menu';
        } else if (response.is_ok == true) {
            // 成功
            //setName(response.query_info?.name)
            //setDescription(response.query_info?.description)
            message.success("删除成功~")
           
        } /*else if (response.code == -501) {
            // 权限不足
            window.location.href = '/oam_login?next=oam_chapter';
        } */else {
            message.error(response.message);
        }
    }
    //页码和页数发生了变化
    const onPageChange = (newPage: number, newPageSize?: number | undefined) => {
        page.current = newPage ? newPage : 1;
        if (newPageSize) {
            pageSize.current = newPageSize;
        }
        queryMenuList();
    }

   
     //点击了查看按钮
  const onLookOverBtnClick = (event:any) => {
    //message.success('查看数据' + event.currentTarget.value)
    event.preventDefault()
    window.open('/oam_menu_detail?menu_id=' + event.currentTarget.value)
  }

  //点击了编辑按钮
  const onEditBtnClick = (event:any) => {
    //message.success('编辑数据' + event.currentTarget.value)
    event.preventDefault()
    window.open('/oam_menu_edit?menu_id=' + event.currentTarget.value)
  }



  // 点击 删除
  const onDeleteBtnClick = (name:string, id:number) => {
    deleteMenu(id)
  }
    // 上下架回调
    const onPutOnOffBtnClickCallback = (response: any) => {
        message.destroy();
        if (response.code == -100) {
            // 没登录
            window.location.href = '/oam_login?next=oam_menu_list';
        } else if (response.code == -501) {
            // 没权限
            window.location.href = '/oam_login?next=oam_menu_list';
        } else if (response.is_ok == true) {
            message.success(response.message);
            queryMenuList();
        } else {
            message.error(response.message);
        }
    }


    // 点了上下架按钮
    const onPutOnOffBtnClick = (menuId:number, status:number) => {
        sendHttpPostRequest(
            'course/api/oam_update_menu/',
            {
                menu_id:menuId,
                status: status == 1?2:1,
                sessionid: Cookies.get("sessionid"),
                sid: Cookies.get("sessionid"),
            },
            onPutOnOffBtnClickCallback
        )

       
    }



    // 渲染操作
    const renderSystemActions = (text:any, record:any) => {
        return <>
        <Row justify="start" align="middle" gutter={[8, 5]} >
        <Col style={{width:50}}><Button style={{borderRadius:20,fontSize:12}} size='small' id="lookover" value={record.id} type="default" onClick={onLookOverBtnClick} > 查 看 </Button></Col>
        <Col style={{width:50}}><Button style={{borderRadius:20,fontSize:12}} size='small' id="edit" value={record.id} type="dashed" onClick={onEditBtnClick}> 编 辑 </Button> </Col>
          {<Col style={{width:50}}> { parseInt(record.status) == 1 ? 
            <Button style={{borderRadius:20,fontSize:12}}  size='small' id="puton" value={record.id} type="primary"  onClick={onPutOnOffBtnClick.bind(this, record.id, record.status)}> 上 架 </Button>
          :  <Popconfirm placement="top" title={'确认下架大纲"' + record.name + '"吗？'} onConfirm={onPutOnOffBtnClick.bind(this, record.id, record.status)} okText="确认" cancelText="取消">
                <Button style={{borderRadius:20,fontSize:12}}  size='small' id="putoff" value={record.id} type="primary"  danger> 下 架 </Button>
              </Popconfirm>}
          </Col> }
          { parseInt(record.status) == 1  && <Col style={{width:50}}>
            <Popconfirm placement="top" title={'确认删除大纲"' + record.name + '"吗？'} onConfirm={onDeleteBtnClick.bind(this, record.name,record.id)} okText="确认" cancelText="取消">
          <Button style={{borderRadius:20,fontSize:12}} size='small' id="delete" danger value={record.id} > 删 除 </Button> 
          </Popconfirm></Col> }
        </Row>
    
       </>
    
      }

          // 渲染操作
    const renderUserActions = (text:any, record:any) => {
        return <>
        <Row justify="start" align="middle" gutter={[8, 5]} >
        <Col style={{width:50}}><Button style={{borderRadius:20,fontSize:12}} size='small' id="lookover" value={record.id} type="default" onClick={onLookOverBtnClick} > 查 看 </Button></Col>
        <Col style={{width:50}}><Button style={{borderRadius:20,fontSize:12}} size='small' id="edit" value={record.id} type="dashed" onClick={onEditBtnClick}> 编 辑 </Button> </Col>
          { <Col style={{width:50}}>
            <Popconfirm placement="top" title={'确认删除大纲"' + record.name + '"吗？'} onConfirm={onDeleteBtnClick.bind(this, record.name,record.id)} okText="确认" cancelText="取消">
          <Button style={{borderRadius:20,fontSize:12}} size='small' id="delete" danger value={record.id} > 删 除 </Button> 
          </Popconfirm></Col> }
        </Row>
    
       </>
    
      }

  // 点击了创建按钮
  const onCreateMenuBtnClick = (event:any) => {
    event.preventDefault();
    sendHttpPostRequest(
        'course/api/oam_create_menu',
        {
            sessionid: Cookies.get("sessionid"),
            sid: Cookies.get("sessionid")
        },
        (response: any) => {
            if (response.is_ok && response.menu_id) {
                window.open(`/oam_menu_edit?menu_id=${response.menu_id}`);
            } else {
                message.destroy();
                message.error(`创建大纲失败：${response.message}`)
            }
        }
    )
  }

  
    //点击了搜索按钮
    const onSearchBtn = (value: string) => {
        page.current = 1;
        queryContent.current = value;
        queryMenuList();
    }

    // 表格发生变化
    const onTableChange:any = (pagination:any, filters:any, sorter:any, extra: { currentDataSource: [], action: any })=> {
        if (sorter.order) { //如果选择了排序列
            order.current = sorter.order;
            order_by.current = sorter.field;
        }
        if (filters.sex) { //如果选择了过滤列
            sexFilters.current = filters.sex;
        } else {
            sexFilters.current = [];
        }
        if (filters.channel_name) {
            channelFilters.current = filters.channel_name.map(element=> {
                return channelMap.get(element);
            });
        } else {
            channelFilters.current = [];
        }
        page.current = 1;
        queryMenuList();
    }

 // 表格列
 const systemCols: any = [
    {
        title: "序号",
        dataIndex: "id",
        key: "id",
        width: 50,
        align: 'left',
        render:(text, record, index)=>((page.current - 1)*pageSize.current + index + 1)
    },
    {
        title: "ID",
        dataIndex: "id",
        key: "id",
        width: 60,
        align: 'left',
    },
    {
        title: "名称",
        dataIndex: "name",
        key: "name",
        width: 100,
        align: 'left',
    },
    
    {
        title: "创建人",
        dataIndex: "user_name",
        key: "user_name",
        width: 100,
        align: 'left',
    },
    {
        title: "创建时间",
        dataIndex: "create_time",
        key: "create_time",
        width: 150,
        align: 'center',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
    },
    {
        title: "状态",
        dataIndex: "status",
        key: "status",
        width: 80,
        align: 'center',
        render:(text:any, record:any)=>{
            return text == 1 ? '下线':'在线'
        }
    },
    {
        title: "描述",
        dataIndex: "description",
        key: "description",
        width: 100,
        align: 'center',
        ellipsis: false,
    },
    {
        title: "操作",
        dataIndex: "actions",
        key: "actions",
        width: 160,
        ellipsis: false,
        align: 'center',
        render: renderSystemActions ,
    },
]

// 表格列
const userCols: any = [
    {
        title: "序号",
        dataIndex: "id",
        key: "id",
        width: 50,
        align: 'left',
        render:(text, record, index)=>((page.current - 1)*pageSize.current + index + 1)
    },
    {
        title: "ID",
        dataIndex: "id",
        key: "id",
        width: 60,
        align: 'center',
    },
    {
        title: "名称",
        dataIndex: "name",
        key: "name",
        width: 100,
        align: 'left',
    },
    
    {
        title: "创建人",
        dataIndex: "user_name",
        key: "user_name",
        width: 100,
        align: 'left',
    },
    {
        title: "创建时间",
        dataIndex: "create_time",
        key: "create_time",
        width: 150,
        align: 'center',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
    },
    {
        title: "描述",
        dataIndex: "description",
        key: "description",
        width: 100,
        align: 'center',
        ellipsis: false,
    },
    {
        title: "操作",
        dataIndex: "actions",
        key: "actions",
        width: 160,
        ellipsis: false,
        align: 'center',
        render: renderUserActions ,
    },
]

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <OAMSider keys={"32"} openKeys={["sub-course"]}  ></OAMSider>
            <Layout style={{backgroundColor:'#f9f9f9'}}>
                <Content style={{ backgroundColor:'#fff',borderRadius:5,minWidth:400,margin:30 ,paddingTop:20}}>
              
                    <div style={{paddingLeft:30,paddingRight:30}}>
                    <Row align="middle"  gutter={16}>
                             <Col flex='80px'>
                                <Statistic title="大纲总数" value={totalCount} />
                            </Col>
                            <Col flex='80px'>
                                <Statistic title="系统" value={sysCount} />
                            </Col>
                            <Col flex='80px'>
                                <Statistic title="用户" value={userCount} />
                            </Col>
                            <Col flex='80px'>
                                <Statistic title="已上架" value={onlineCount} />
                            </Col>
                        <Col flex='250px'>

                            <Search
                                placeholder="搜索大纲"
                                style={{ width: 350 }}
                                enterButton={true}
                                onSearch={onSearchBtn}
                            />

                        </Col>
                       
                        <Col flex='auto' style={{textAlign:'right'}}> <Button style={{borderRadius:20}} type={'primary'} onClick={onCreateMenuBtnClick}>创 建</Button></Col>
                        </Row>
                      
                        <Tabs defaultActiveKey="2" onChange={onTabKeyChange}>
                            <TabPane tab="系统" key="2" className="">
                                    <div className={idataCourseStyles.tableWrapper}>
                                        <ConfigProvider locale={zhCN}>
                                            <Table
                                                dataSource={menuResult}
                                                columns={systemCols}
                                                rowKey={record => record.id}
                                                pagination={false}
                                                //bordered={true}
                                                onChange={onTableChange}
                                            />
                                    </ConfigProvider>
                                    </div>

                                    <ConfigProvider locale={zhCN}>
                                        <div style={{marginBottom:30,paddingTop:30}} className={"css.pagination"} >
                                            <Pagination
                                                defaultCurrent={1}
                                                current={page.current}
                                                total={resultSum}
                                                defaultPageSize={20}
                                                hideOnSinglePage={true}
                                                onChange={onPageChange}
                                                showQuickJumper={true}
                                                showTotal={total => `共 ${total} 大纲`}
                                                pageSizeOptions={["3","20","30","50","100"]}
                                            />
                                        </div>
                                    </ConfigProvider>
                                </TabPane>
                                <TabPane tab="用户" key="1" className="">
                                    <div className={idataCourseStyles.tableWrapper}>
                                            <ConfigProvider locale={zhCN}>
                                                <Table
                                                    dataSource={menuResult}
                                                    columns={userCols}
                                                    rowKey={record => record.id}
                                                    pagination={false}
                                                    //bordered={true}
                                                    onChange={onTableChange}
                                                />
                                        </ConfigProvider>
                                        </div>

                                        <ConfigProvider locale={zhCN}>
                                            <div style={{marginBottom:30,paddingTop:30}} className={"css.pagination"} >
                                                <Pagination
                                                    defaultCurrent={1}
                                                    current={page.current}
                                                    total={resultSum}
                                                    defaultPageSize={20}
                                                    hideOnSinglePage={true}
                                                    onChange={onPageChange}
                                                    showQuickJumper={true}
                                                    showTotal={total => `共 ${total} 大纲`}
                                                    pageSizeOptions={["3","20","30","50","100"]}
                                                />
                                            </div>
                                        </ConfigProvider>
                                </TabPane>
                        </Tabs>



                    </div>
                </Content>
            </Layout>
        </Layout>

    )

}

export default OAMMenuList

