import {
  Divider,
  Layout,
  Input,
  Select,
  message,
  Button,
  Radio,
  Checkbox,
  Row,
  Col,
  Popconfirm,
  Upload,
  Breadcrumb,
} from 'antd'
import { Content } from 'antd/lib/layout/layout'

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { recordExpression } from '@babel/types'
import { sendHttpPostRequest } from '../../api/http-request'
import Cookies from 'js-cookie'
import exerciseStyles from './exercise.module.less'
import './exercise.css'
import MonacoEditor from 'react-monaco-editor'
import gfm from 'remark-gfm'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { CloseCircleOutlined } from '@ant-design/icons'
import confirm from 'antd/lib/modal/confirm'
import * as constants from '../../constants/constants'
import ExerciseCard from '../exercise/exercise-card'
import PageHeader from '../common/page-header/header'
import PageFooter from '../common/page-footer/footer'
import Feedback from '../common/feedback-help/feedback'
import { checkIsTeacher } from '../../component/modal/teacher-authenticate/teacher-authenticate'
const { Option } = Select
const { TextArea } = Input

export interface Props {}
export interface Props2 {}

interface exerciseInfo {
  id: number
  title: string
  remarks: string
  type: number
  type_name: string
  difficulty: number
  difficulty_str: string
  score: number
  default_code: string
  steps: string
  preset_code: string
  language: number
  language_str: string
  judgement_code: string
  reference_answer: string
  answer_parse: string
  options: any
  options_answer: any
  datasets: any
  images: any
  source_page: number
  source_key: number
}

export const ExercisePreview: React.FC<Props> = (props) => {
  const exerciseId = parseInt(window.location.search.split('=')[1]) // 习题的ID
  const [exercise, setExercise] = useState(null as unknown as exerciseInfo) // 习题对象
  const [exerciseType, setExerciseType] = useState(1) // 习题类型
  const isTeacher = useRef(false) // 是否为教师

  const queryExerciseCallback = (response) => {
    message.destroy()
    if (response.code == -100) {
      // 未登录
      document.getElementById('show-login-modal')!.click()
      // window.location.href = '/oam_login?next=oam_exercise_list';
    } else if (response.code == 0) {
      // 成功
      console.log(response.exercise_info)
      setExercise(response.exercise_info)
    } else if (response.code == -501) {
      // 权限不足
      // document.getElementById("show-login-modal")!.click();
      // window.location.href = '/oam_login?next=oam_exercise_list';
    } else {
      message.error(response.message)
    }
  }

  //点击了编辑按钮
  const onEditBtnClick = (id: any, event: any) => {
    window.open('/exercise_edit?exercise_id=' + id)
  }

  useEffect(() => {
    if (checkIsTeacher()) {
      isTeacher.current = true
    }
    queryExerciseInfo()
  }, [])

  // 查询习题详情
  const queryExerciseInfo = () => {
    sendHttpPostRequest(
      'exercise/api/query_exercise_by_id/',
      {
        exercise_id: exerciseId,
        sessionid: Cookies.get('sessionid'),
        source_page: 1, // 习题模块1
        source_key: 3, // 教师做题3，学生做题4
      },
      queryExerciseCallback
    )
  }

  return (
    <Layout style={{ minHeight: '100vh', backgroundColor: '#f9f9f9' }}>
      <div
        className={exerciseStyles.exercisePreview}
        style={{
          paddingTop: 20,
          width: 900,
          marginTop: 30,
          backgroundColor: '#fff',
        }}
      >
        <Row align="middle">
          <Col
            span={12}
            style={{ textAlign: 'left', marginBottom: 10, width: '100%' }}
          >
            <div
              style={{
                marginLeft: 40,
                fontWeight: 600,
                fontSize: 18,
                color: '#333333',
              }}
            >
              {' '}
              题目详情{' '}
            </div>
          </Col>
          <Col
            span={12}
            style={{ textAlign: 'right', marginBottom: 10, width: '100%' }}
          >
            <Button
              style={{ marginRight: 40, color: '#333333' }}
              onClick={() => {
                window.location.href = '/exercise'
              }}
              type="link"
            >
              {' '}
              {'返回 >'}{' '}
            </Button>
          </Col>
        </Row>
        <Divider style={{ marginTop: 0 }} />

        <div style={{ marginLeft: 40, marginRight: 40, padding: 10 }}>
          <ExerciseCard
            exercise={exercise}
            renderType="white"
            teacherOnly={true}
            editorMode={true}
            showAnser={false}
            showClean={true}
          />
        </div>
      </div>
    </Layout>
  )
}

export default ExercisePreview
