import React, { useRef, useState } from 'react'
import mbCss from './course-main-memberlist.module.less'
import { Col, ConfigProvider, Pagination, Row, Table, message } from 'antd'
import SearchInputCom from '../../../component/SearchInputCom/SearchInputCom'
import { useEffect } from 'react'
import { sendHttpPostRequest } from '../../../api/http-request'
import Cookies from 'js-cookie'
import * as contants from '../../../constants/constants'
import zhCN from 'antd/lib/locale/zh_CN'

interface memberProps {
  actionCallback: any
  studentList: any
  teacherList: any
}

export const CourseMainMemberList: React.FC<memberProps> = (props) => {
  const { actionCallback, studentList, teacherList } = props
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  // const pageSize = useRef(2)
  const cols: any = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: '100px',
      render: (content, record, index) => <span>{index + 1}</span>,
    },
    {
      title: '姓名',
      dataIndex: 'name',
      align: 'left',
      key: 'name',
      render: (content, record, index) => (
        <Row align="middle" justify="start">
          <Col>
            <img
              className={mbCss.avatar}
              width={24}
              height={24}
              src={contants.BACKEND_API_URL + record.avatar}
              alt=""
            />
          </Col>
          <Col>{record.real_name ? record.real_name : record.name}</Col>
        </Row>
      ),
    },
    {
      title: '手机号',
      dataIndex: 'phone',
      align: 'center',
      key: 'phone',
      width: '200px',
    },
  ]

  const onSearchNote = () => {}
  const onNoteChange = () => {}
  const onPageSizeChange = (newPage,newPageSize) => {
    // page.current = newPage
    setPage(newPage)
    setPageSize(newPageSize)
    // pageSize.current = newPageSize
    console.log(newPage,newPageSize);
    
  }

  // const queryCourseTeacherCallback = (response: any) => {
  //   if (response.code == -100) {
  //     // 没登录
  //     document.getElementById('show-login-modal')!.click()
  //     // window.location.href = '/login?next=course';
  //   } else if (response.code == -501) {
  //     // 没权限
  //     message.error('权限不足')
  //     // window.location.href = '/login?next=course';
  //   } else if (response.is_ok == true) {
  //     setMemberList(response.query_list)
  //   } else {
  //     message.error(response.message)
  //   }
  // }
  // const queryCourseMemberList = () => {
  //   sendHttpPostRequest(
  //     'course/api/user_query_course_user',
  //     {
  //       sessionid: Cookies.get('sessionid'),
  //       sid: Cookies.get('sessionid'),
  //       course_id: course_id,
  //       type: 0, //0查学生，1,查教师
  //     },
  //     queryCourseTeacherCallback
  //   )
  // }

  useEffect(() => {}, [])

  return (
    <div className={mbCss.contentWapper}>
      <Row
        className={mbCss.rightActionBox}
        align="middle"
        justify={'space-between'}
      >
        <Col>
          <SearchInputCom
            placeholder={'输入姓名/手机号进行搜索'}
            onSearch={onSearchNote}
            onChange={onNoteChange}
          />
        </Col>
      </Row>
      <div className={mbCss.tableTitle}>教师</div>
      <div className={mbCss.tableWapper}>
        <Table
          columns={cols}
          dataSource={teacherList}
          pagination={false}
          bordered={false}
        />
      </div>
      <div className={mbCss.tableTitle}>学员</div>
      <div className={mbCss.tableWapper}>
        <Table
          columns={cols}
          dataSource={studentList}
          pagination={{
            onChange: onPageSizeChange,
            total: studentList.length,
            pageSize: pageSize,
            current: page,
            hideOnSinglePage:true
          }}
          bordered={false}
        />
      </div>
      {/* <div>
        <ConfigProvider locale={zhCN}>
          <Pagination
            onChange={onPageSizeChange}
            total={studentList.length}
            pageSize={pageSize.current}
            current={page.current}
          />
        </ConfigProvider>
      </div> */}
    </div>
  )
}

export default CourseMainMemberList
