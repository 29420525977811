import React, { useEffect, useRef, useState } from 'react'
import {
  Button,
  Col,
  Drawer,
  Image as AntdImage,
  Input,
  Layout,
  message,
  Pagination,
  Popover,
  Progress,
  Row,
  Select,
  Tabs,
  Tooltip,
  Tree,
  Empty,
  Steps,
  Image,
} from 'antd'
import { sendHttpPostRequest } from '../../api/http-request'
import {
  CodeOutlined,
  CopyOutlined,
  DownOutlined,
  FormOutlined,
  LeftCircleOutlined,
  LogoutOutlined,
  ProfileOutlined,
  RightCircleOutlined,
  TableOutlined,
} from '@ant-design/icons'
import Cookies from 'js-cookie'
import './course.module.css'
import * as constants from '../../constants/constants'
import { RouteComponentProps } from 'react-router'
import courseLearnStyles from './course-learn.module.less'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import './course-split-pane.css'

import TeacherAuthenticate from '../../component/modal/teacher-authenticate/teacher-authenticate'

import SplitPane from 'react-split-pane'
import ExerciseCard from '../exercise/exercise-card'

import { Document, Page, pdfjs } from 'react-pdf'
import { getChapterLabels, increaseModuleViewCount } from '../common/utils/util'
import loadingGif from '../../static/image/loding.gif'
import discussStyle from './discussion/course-main-discuss.module.less'
import SearchInputCom from '../../component/SearchInputCom/SearchInputCom'
import MarkDownEditor from '../../component/MarkDownEditor/MarkDownEditor'
import DiscussItem from '../../component/DiscussItem/DiscussItem'
import ReplyItem from '../../component/DiscussItem/ReplyItem'
import { MenuType, TaskType } from './discussion/course-main-discuss'
import ScreenShort from 'js-web-screen-shot'

import { secTotime } from '../../utils/timeUtils'
import NoteItem from '../../component/NoteItem/NoteItem'
import CommonModal from '../../component/modal/CommonModal/CommonModal'
import navFirstStepOne from '../../static/image/navstep1-2.pic.jpg'
import navFirstStepTwo from '../../static/image/navstep1-1.pic.jpg'
import lastStep from '../../static/image/laststep.pic.jpg'
import navSecondStep from '../../static/image/navstep2.pic.jpg'
const { TabPane } = Tabs
//pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js';
//pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

pdfjs.GlobalWorkerOptions.workerSrc =
  'http://idatacourse.cn/static/js/pdf.worker.min-2.10.377.js'

//pdfjs.GlobalWorkerOptions.workerSrc =  "http://static.idatascience.cn/static/pdfjs2-10-377/pdf.worker.min-2.10.377.js"

let url_prefix = constants.NOTEBOOK_BACKEND_URL

const { TreeNode } = Tree

interface moduleInfo {
  id: number
  name: string
  type: number
  description: string
  lecture_url: string
  video_url: string
  practice: any
  exam: any
  case: any
  chapter_id: any
}

// 通过路由组件额外添加参数信息到表中
interface Props extends RouteComponentProps<{ module_id: string }> {
  module_id: string
}

let globalExerciseId = 0
const { Step } = Steps
export const CourseLearn: React.FC<Props> = (props) => {
  const _location = props.location.search.includes('?')
    ? props.location.search.split('?')[1].split('/')
    : null
  const course_id = _location![1]
  // const moduleId = parseInt(props.location.search.split("&")[0].split("=")[1]);
  const [module, setModule] = useState(null as unknown as moduleInfo) // 知识点对象
  const [moduleName, setModuleName] = useState(null as any) //知识点名称
  const [moduleCase, setModuleCase] = useState(null as any) //知识点的实验
  const [moduleLectureUrl, setModuleLectureUrl] = useState(null as any) //pdf课件地址
  const [moduleVideoUrl, setModuleVideoUrl] = useState(null as any) //视频url地址
  const [moduleExercises, setModuleExercises] = useState([] as any[]) //当前知识点的练习题列表
  const moduleExerciseIds = useRef([]) //当前知识点的练习题id列表
  //const isTeacher = useRef(false);
  const isCourseStudent = useRef(false) // 是否课程学生
  const isCourseTeacher = useRef(false) // 是否课程教师
  //const percent = Math.round(100*Math.random()+0.1)
  const [practiceExercises, setPracticeExercises] = useState([] as any[]) // 知识点的习题需要单独查询，才会有提交记录之类的信息

  const [menu, setMenu] = useState(null as any) // 项目大纲
  const [menuId, setMenuId] = useState(0) // 项目大纲ID

  const [courseName, setCourseName] = useState(null as any) //知识点名称
  const jumpChapterId = useRef(1)
  const jumpKnowledgeId = useRef(1)

  const [isBuyModalVisible, setIsBuyModalVisible] = useState(true)

  const draggleRef = useRef<HTMLDivElement | null>(null)
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
  const [disabled, setDisabled] = useState(true)
  const isFinished = useRef(false)
  const learningProgress = useRef(0)
  const totalProgress = useRef(0)
  const notDoTimer = useRef(false)
  let timer: any
  const progress = useRef(0)

  //  讨论  Hooks
  const [isAllRepliesVis, setIsAllRepliesVis] = useState(false)
  const [isAddEditorVis, setIsAddEditorVis] = useState(false)
  const discussionsPage = useRef(1)
  const discussionsPageSize = useRef(10)
  const queryContent = useRef('')
  const querySource = useRef(1)
  const [discussions, setDiscussions] = useState([] as any[])
  const [replies, setReplies] = useState([] as any)
  const [isShoting, setIsShoting] = useState(false)
  const shotScreenTime = useRef<number>(0)
  const pdfPosition = useRef(0)
  const [navigateTourVis, setNavigateTourVis] = useState(false)
  const [navigateStep, setNavigateStep] = useState(0)

  let courseId: any = 1 // 课程ID
  let chapterId: any = 1 // 章ID
  let knowledgeId: any = 1 // 知识点ID

  if (_location && _location.length >= 6) {
    courseId = parseInt(_location[1])
    chapterId = parseInt(_location[3])
    knowledgeId = parseInt(_location[5])
  } else {
    history.replaceState(
      null,
      '',
      `course-learn?course/${courseId}/chapter/${chapterId}/knowledge/${knowledgeId}/`
    )
  }

  const navigateStepChange = (current) => {
    setNavigateStep(current)
  }

  const navigateTourContent = (
    <div>
      <Steps
        current={navigateStep}
        onChange={navigateStepChange}
        size="small"
        style={{ marginBottom: '20px' }}
      >
        <Step title="第一步" />
        <Step title="第二步" />
        <Step title="第三步" />
      </Steps>
      {navigateStep == 0 ? (
        <div className={courseLearnStyles.stepTitle}>
          打开Chrome或Edge浏览器,地址栏输入
          <span style={{ color: '#07c160' }}>
            chrome://flags/#unsafely-treat-insecure-origin-as-secure
          </span>
          <CopyToClipboard
            onCopy={() => {
              message.success('复制成功！')
            }}
            text={'chrome://flags/#unsafely-treat-insecure-origin-as-secure'}
          >
            <Tooltip title={'点击复制'}>
              <CopyOutlined className={courseLearnStyles.copyBtn} />
            </Tooltip>
          </CopyToClipboard>
          <div className={courseLearnStyles.navTitle}>Chrome浏览器</div>
          <Image width={'100%'} src={navFirstStepOne} alt="" />
          <div className={courseLearnStyles.navTitle}>Edge浏览器</div>
          <Image width={'100%'} src={navFirstStepTwo} alt="" />
        </div>
      ) : navigateStep == 1 ? (
        <div className={courseLearnStyles.stepTitle}>
          在打开的界面中：下拉框选择enabled，地址填写你的项目访问路径。
          <Image width={'100%'} src={navSecondStep} alt="" />
        </div>
      ) : navigateStep == 2 ? (
        <div className={courseLearnStyles.lastStep}>
          <div style={{ marginBottom: '100px' }}>重启浏览器开始体验吧！</div>
          <Image width={'100%'} height={'80px'} src={lastStep} alt="" />
        </div>
      ) : (
        ''
      )}
    </div>
  )

  // 查课程以获取大纲id
  const queryCourse = () => {
    sendHttpPostRequest(
      'course/api/user_query_course_by_id',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        course_id: courseId,
      },
      (response: any) => {
        if (response.is_ok) {
          queryMenu(response.query_info.syllabus_id)
          setCourseName(response.query_info.name)
          isCourseStudent.current = response.query_info.is_course_student
          isCourseTeacher.current = response.query_info.is_course_teacher

          queryDiscussionList()
          // 根据id查询习题
          if (moduleExerciseIds.current.length > 0) {
            selecetedExerciseIndex.current = 0
            // queryExerciseSubmission();  // 查询所有题目的提交时间
            queryPracticesExercise(moduleExerciseIds.current[0]) // 查询单个题目详情
          }
        }
      }
    )
  }

  const queryMenu = (menuId: number) => {
    sendHttpPostRequest(
      'course/api/user_query_menu_by_id',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        course_id: courseId,
        menu_id: menuId,
        format: 1,
      },
      queryMenuCallback
    )
  }

  /**
   * 查询大纲回调
   * @param response
   */
  const queryMenuCallback = (response: any) => {
    // console.log(response)
    message.destroy()
    if (response.code == -100) {
      // 未登录
      document.getElementById('show-login-modal')!.click()
    } else if (response.is_ok == true) {
      // 成功
      //setMenuName(response.query_info.name);
      //setMenuDesp(response.query_info.description);
      //setMenuDirectory(response.query_info.json);
      //message.success(response.query_info)
      setMenu(response.query_info.json)
      //window.location.href = `course-learn?course/${courseId}/chapter/${jumpChapterId.current}/knowledge/${jumpKnowledgeId.current}/`
      history.replaceState(
        null,
        '',
        `course-learn?course/${courseId}/chapter/${jumpChapterId.current}/knowledge/${jumpKnowledgeId.current}/`
      )
    } /*else if (response.code == -501) {
            权限不足
            window.location.href = '/oam_login?next=oam_menu';
        } */ else {
      message.error(response.message)
    }
  }

  const [courseResult, setCourseResult] = useState([] as any[])
  const [menuVisible, setMenuVisible] = useState(false) //课程大纲弹出框是否可见
  const [userLogin, setUserLogin] = useState(false) // 用户登录状态
  const exerciseNum = moduleExercises.length //该知识点对应的测验习题数量
  const caseNum = 1 //该知识点对应的实验数量

  const [completeStatus, setCompleteStatus] = useState([] as any[])

  const [caseId, setCaseId] = useState(null as any)
  const [caseToken, setCaseToken] = useState(null as any)
  const [urlPrefix, setUrlPrefix] = useState(url_prefix)

  const [caseDataList, setCaseDataList] = useState([] as any[]) //实验包含的数据集列表
  const [caseDataListLength, setCaseDataListLength] = useState(0)
  const [currentCase, setCurrentCase] = useState(null as any)
  const [currentActiveTabKey, setCurrentActiveTabKey] = useState('1') //1 练习 2实验 ，右侧面板

  useEffect(() => {
    //if (checkIsTeacher()) {
    queryModule(chapterId, knowledgeId)
    increaseModuleViewCount(knowledgeId)
    queryNoteList(knowledgeId)
  }, [])

  // 查询笔记列表
  const queryNoteList = (knowledgeId) => {
    sendHttpPostRequest(
      'course/api/study_note/query_note_by_kc/',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        course_id: courseId,
        // position: shotScreenTime.current,
        know_id: knowledgeId,
        // image_content: base64data,
      },
      (res) => {
        if (res.is_ok) {
          const notelist = res.query_list.map((note) => {
            return {
              is_editor: false,
              ...note,
            }
          })
          setNoteList([...notelist])
        }
      }
    )
  }
  // 更新学习进度
  const updateModule = (
    chapterId: number,
    knowledgeId: number,
    progress: number
  ) => {
    let requestParams = {
      sessionid: Cookies.get('sessionid'),
      course_id: course_id,
      knowledge_cell_id: knowledgeId,
      chapter_id: chapterId,
      progress: progress,
      api_id: 11006, // 更新作业进度
    }
    sendHttpPostRequest(
      'statistics/api/info/',
      requestParams,
      (response: any) => {
        message.destroy()
        if (response.code == -100) {
          // 没登录
          document.getElementById('show-login-modal')!.click()
        } else if (response.code == -501) {
          // 没权限
          message.error('权限不足')
          // window.location.href = '/login?next=course';
        } else if (response.is_ok == true) {
          console.log('更新成功')
          learningProgress.current = response.data.learning_progress
          console.log('更新了LearningProgress')
        } else {
          message.error(response.message)
        }
      }
    )
  }

  // 查询知识点列表回调
  const queryModuleCallback = (response: any) => {
    message.destroy()
    if (response.code == -100) {
      // 未登录
      document.getElementById('show-login-modal')!.click()
    } else if (response.is_ok == true) {
      isFinished.current = response.query_info.progress_dict.finished
      learningProgress.current =
        response.query_info.progress_dict.learning_progress
      console.log('请求回来的', learningProgress.current)
      totalProgress.current = response.query_info.progress_dict.total_progress
      // 成功
      setModule(response?.query_info)
      setModuleName(response?.query_info.name)
      setModuleExercises(response?.query_info.practice)
      moduleExerciseIds.current = response?.query_info.practice_ids
      setModuleCase(response?.query_info.case)

      setModuleLectureUrl(response?.query_info.lecture_url)
      //setModuleLectureUrl("http://idkcbd.idatascience.cn/media/knowcell_lecture/博雅数智.pdf");
      setModuleVideoUrl('')
      setModuleVideoUrl(response?.query_info.video_url)
      //console.log(isTeacher, moduleExerciseIds.current, response?.query_info.practice_ids);
      queryCase(
        response?.query_info.case.case_id,
        response?.query_info.case.token
      )
      queryCourse()

      chapterId = parseInt(response.query_info.chapter_id)
      knowledgeId = response.query_info.id
      jumpChapterId.current = parseInt(response.query_info.chapter_id)
      jumpKnowledgeId.current = response.query_info.id

      setTimeout(() => {
        if (
          isCourseStudent.current &&
          response.query_info.type == 2 &&
          !isFinished.current
        ) {
          let video: any = document.getElementById('_volum')
          video.addEventListener('seeked', () => {
            if (video.currentTime > learningProgress.current) {
              video.currentTime = learningProgress.current
            }
          })

          video.addEventListener('play', () => {
            timer = setInterval(() => {
              progress.current = parseInt(video.currentTime)
              learningProgress.current = parseInt(video.currentTime)
              updateModule(chapterId, knowledgeId, progress.current)
            }, 5000)
          })

          video.addEventListener('pause', () => {
            clearInterval(timer)
            progress.current = parseInt(video.currentTime)
            updateModule(chapterId, knowledgeId, progress.current)
          })
        }
      }, 100)

      // history.replaceState(null, "", `course-learn?course/${courseId}/chapter/${new_chapter_id}/knowledge/${new_knowledge_id}/`);
    } else if (response.code == -501) {
      // 权限不足
    } else {
      message.error(response.message)
    }
  }

  /**
   * 查询实验信息
   */
  const queryCase = (caseid, token) => {
    if (caseid > 0) {
      sendHttpPostRequest(
        'course/api/user_get_or_fork_knowcell_case_by_id',
        {
          sessionid: Cookies.get('sessionid'),
          case_id: caseid,
          token: token,
          is_my: '2',
          knowcell_id: knowledgeId,
          //from_oam: fromOAM,
        },
        queryCaseCallback
      )
    }
  }

  /**
   * 查询实验回调
   * @param response
   */
  const queryCaseCallback = (response: any) => {
    console.log(response)
    message.destroy()
    if (response.code == -100) {
      // 未登录
      //setUserLogin(false);
      document.getElementById('show-login-modal')!.click()
    } else if (response.is_ok == true) {
      // 成功
      setUserLogin(true)
      setCurrentCase(response.case_info)
      setCaseDataList(response.case_info.mounted_datasets)
      setCaseDataListLength(response.case_info.mounted_datasets?.length)
      setCaseId(response.case_info.case_id)
      setUrlPrefix(response.case_info.run_url)

      // setCaseDataList(response.case_info.dataset)
      // setCaseDataListLength(response.case_info.dataset.length)
      // console.log(caseDataListLength)
      // if (response.case_info.need_jump) {
      //   setCaseId(response.case_info.case_id)
      //   setCaseToken(response.case_info.token)
      // } else {
      //   // setCaseId(moduleCase.case_id);
      //   // setCaseToken(moduleCase.token);
      //   setCaseId(response.case_info.module_case_id)
      //   setCaseToken(response.case_info.module_case_token)
      // }
      // if (response.case_info.url_prefix) {
      //   setUrlPrefix(response.case_info.url_prefix)
      // }
    } else if (response.code == -501) {
      // 权限不足
      //setUserLogin(false);
      message.error('当前用户权限不足')
    } else {
      //setUserLogin(false);
      message.error(response.message)
    }
  }

  // 查询知识点列表
  const queryModule = (chapterId: number, knowledgeId: number) => {
    sendHttpPostRequest(
      'course/api/user_query_knowcell_by_id',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        know_id: knowledgeId,
        chapter_id: chapterId,
        course_id: courseId,
      },
      queryModuleCallback
    )
  }

  // 查询题目提交情况列表
  const queryExerciseSubmission = () => {
    console.log(knowledgeId)

    sendHttpPostRequest(
      'course/api/user_query_exercise_submission_list/',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        source_page: 2,
        source_key: isCourseStudent.current
          ? 4
          : isCourseTeacher.current
          ? 3
          : 0, // 知识点学习,
        knowledge_id: knowledgeId,
        course_id: courseId,
        chapter_id: chapterId,
      },
      (response: any) => {
        if (response.code == -100) {
          // 未登录
          document.getElementById('show-login-modal')!.click()
        } else if (response.code == 0 && response.is_ok == true) {
          console.log(response.submission_list)
          setCompleteStatus(response.submission_list)
        }
      }
    )
  }

  /**
   * @author yanhan
   * @date 2022-01-14 15:07
   * @param exerciseId
   */
  const queryPracticesExercise = (exerciseId: any) => {
    // console.log(knowledgeId)
    knowledgeId = location.search?.split('knowledge/')[1]?.split('/')[0]
    // console.log(
    //   'knowledge/',
    //   knowledgeId,
    //   location.search?.split('knowledge/')[1]?.split('/')[0]
    // )

    // 基本习题框架
    const baseExerciseInfo: any = {
      answer_parse: '',
      create_time: '',
      creator: '',
      creator_id: 1,
      dataset_path: '',
      datasets: [],
      default_code: '',
      difficulty: 1,
      difficulty_str: '',
      exam_id: 0,
      hint_submit_btn: 1,
      id: 1,
      images: [],
      is_delete: 1,
      judgement_code: '',
      language: 2,
      language_str: 'python3',
      options: [
        { id: '1', options: '1' },
        { id: '2', options: '2' },
        { id: '3', options: '3' },
        {
          id: '4',
          options: '4',
        },
      ],
      options_answer: [],
      preset_code: '',
      reference_answer: '',
      remarks: '',
      score: 0,
      source: 1,
      source_key: 4,
      source_page: 4,
      status: 1,
      steps: '',
      submission_info: {
        is_html: false,
        submission_answer: [],
        submission_code: '',
        submission_id: 0,
        submission_image: [],
        submission_output: '',
        submission_status: 3,
        submission_time: '',
        submission_variables: [],
      },
      title: '',
      type: 8,
      type_name: '',
      update_time: '',
    }
    setPracticeExercises(baseExerciseInfo)
    sendHttpPostRequest(
      'exercise/api/query_exercise_by_id/',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        exercise_id: exerciseId,
        source_page: 2, // 知识点学习
        source_key: isCourseStudent.current
          ? 4
          : isCourseTeacher.current
          ? 3
          : 0, // 知识点学习
        // hint_submit_btn: moduleExerciseIds.current.length > 1 ? 2 : 1,  // 隐藏 习题卡片里的 测试 按钮
        hint_submit_btn: 1, // 1-显示 按钮
        // hint_submit_btn: 2,  // 1-隐藏 按钮
        course_id: courseId,
        chapter_id: chapterId,
        knowcell_id: knowledgeId,
      },
      (response: any) => {
        setPracticeExercises(response.exercise_info)
        globalExerciseId = exerciseId
        queryExerciseSubmission()
      }
    )
  }

  /** 将大纲转成菜单控件的输入格式 */
  const getTreeData = () => {
    const treeData = [] as any[] //
    menu?.map((chapter, chapterInd) => {
      const treeChapter = {
        key: 'chapter_' + chapter.id,
        title: getChapterLabels(chapterInd) + ' ' + chapter.name,
        selectable: false,
        // status: chapter.status,
        // is_pass: chapter.is_pass,
        children: [] as any[],
      }

      chapter.tasks?.map((task, taskInd) => {
        if (task.is_delete == 1) {
          treeChapter.children.push({
            key: 'task_' + task.id,
            title:
              String(chapterInd + 1) +
              '.' +
              String(taskInd + 1) +
              ' ' +
              task.name,
            chapterId: chapter.id,
            selectable: true,
            // status: task.status,
            // selectable: chapter.is_pass == 0 ? true : true
          })
        }
      })

      treeData.push(treeChapter)
    })
    //console.log(treeData)
    return treeData
  }

  // 点击下一知识点
  const onNextKnowledgeClick = () => {
    sendHttpPostRequest(
      'course/api/studyroom_get_next',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        course_id: courseId,
        chapter_id: module?.chapter_id,
        know_id: module?.id,
      },
      (response: any) => {
        chapterId = parseInt(response.chapter_id)
        knowledgeId = parseInt(response.know_id)
        history.replaceState(
          null,
          '',
          `course-learn?course/${courseId}/chapter/${response.chapter_id}/knowledge/${response.know_id}/`
        )
        queryModule(chapterId, knowledgeId)
        queryNoteList(knowledgeId)
      }
    )
  }

  // 点击上一知识点
  const onPrewKnowledgeClick = () => {
    sendHttpPostRequest(
      'course/api/studyroom_get_prew',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        course_id: courseId,
        chapter_id: module.chapter_id,
        know_id: module.id,
      },
      (response: any) => {
        chapterId = parseInt(response.chapter_id)
        knowledgeId = parseInt(response.know_id)
        history.replaceState(
          null,
          '',
          `course-learn?course/${courseId}/chapter/${response.chapter_id}/knowledge/${response.know_id}/`
        )
        queryModule(chapterId, knowledgeId)
        queryNoteList(knowledgeId)
      }
    )
  }

  const showMenuDrawer = (e: any) => {
    e.preventDefault()
    setMenuVisible(true)
  }

  /** 点击了退出学习按钮 */
  const logoutLearnBtnClick = () => {
    if (isCourseStudent.current) {
      window.location.href = `/course-main-student?course_id=${courseId}`
    } else if (isCourseTeacher.current) {
      window.location.href = `/course-main-teacher?course_id=${courseId}`
    } else {
      window.location.href = '/course'
    }
  }

  const onMenuDrawerClose = (e: any) => {
    e.preventDefault()
    setMenuVisible(false)
  }

  const handleModuleSelected = (selectedKeys: any, object: any) => {
    //处理通过大纲菜单选择了某一个知识点事件
    //message.success("选择了知识点"+ selectedKeys)
    if (selectedKeys[0]?.split('_')?.length > 1) {
      console.log(selectedKeys[0].split('_')[1])
      const newKnowledgeId = selectedKeys[0].split('_')[1]
      const newChapterId = object.node.chapterId
      console.log(chapterId)
      if (newChapterId) chapterId = newChapterId
      if (newKnowledgeId) knowledgeId = newKnowledgeId
      queryModule(newChapterId, newKnowledgeId)
      setMenuVisible(false) //关闭大纲选择框
      // setMenuSelected(false);
    }
  }

  // const controlMouseDown = e => {
  //     const pane = document.getElementsByClassName("SplitPane");
  //     const pane1 = document.getElementsByClassName("Pane1");
  //     const pane2 = document.getElementsByClassName("Pane2");
  //     const controlPoint = document.getElementsByClassName("controlPoint");
  //     controlMouseMove;
  //     controlMouseUp;
  // }

  // const controlMouseMove = e => {

  // }

  // const controlMouseUp = e => {

  // }

  const selecetedExerciseIndex = useRef(0) //默认选中第一题

  const getExerciseBackColorByIndex = (status: any) => {
    if (status == 2) {
      return '#07c160'
    } else if (status == 1 || status == 3) {
      return '#ff4b50'
    } else {
      return '#343434'
    }
  }

  // 点击提交按钮
  const onExerciseSubmitClick = () => {
    const exerciseIndex = selecetedExerciseIndex.current
    globalExerciseId = moduleExerciseIds.current[exerciseIndex]
    if (0 < globalExerciseId) {
      document.getElementById(`exercise-run-${globalExerciseId}`)!.click()
      setTimeout(function () {
        queryExerciseSubmission()
      }, 2000)
    }
  }

  const pageLoad = () => {
    document.getElementById('waitPage')!.style.display = 'none'
    document.getElementById('notebook-iframe')!.style.display = 'block'
  }

  // 点击上一题
  const onPrevExerciseClick = () => {
    selecetedExerciseIndex.current =
      selecetedExerciseIndex.current < moduleExercises?.length
        ? selecetedExerciseIndex.current - 1
        : selecetedExerciseIndex.current
    queryPracticesExercise(
      moduleExerciseIds.current[selecetedExerciseIndex.current]
    )
  }

  // 点击下一题
  const onNextExerciseClick = () => {
    selecetedExerciseIndex.current =
      selecetedExerciseIndex.current < moduleExercises?.length - 1
        ? selecetedExerciseIndex.current + 1
        : selecetedExerciseIndex.current
    queryPracticesExercise(
      moduleExerciseIds.current[selecetedExerciseIndex.current]
    )
  }

  const caseDataContent = (
    <div>
      <Row
        style={{ padding: 0, marginBottom: 0 }}
        gutter={[0, 2]}
        justify="start"
        align="top"
      >
        {caseDataList?.map((data, index) => {
          return (
            <Col
              span={24}
              key={data.dataset_id}
              style={{ padding: 0, textAlign: 'left' }}
            >
              <Row
                className={courseLearnStyles.dataList}
                gutter={[0, 0]}
                align="middle"
                key={data.dataset_id}
                style={{ fontSize: 12 }}
              >
                <Col span={20} style={{ textAlign: 'left' }}>
                  <span className={courseLearnStyles.dataName}>
                    {index + 1} {data?.title}
                  </span>
                </Col>
                <Col span={4} style={{ textAlign: 'right' }}>
                  <CopyToClipboard
                    onCopy={() => {
                      message.success('复制成功！')
                    }}
                    text={data?.title}
                  >
                    <Tooltip title="复制">
                      <CopyOutlined className={courseLearnStyles.copyIcon} />
                    </Tooltip>
                  </CopyToClipboard>
                </Col>
              </Row>
            </Col>
          )
        })}
      </Row>
    </div>
  )

  const editorStateInit = () => {
    replies.map((r) => (r.replyVist = false))
    discussions.map((d) => (d.replyVist = false))
    setReplies([...replies])
    setDiscussions([...discussions])
  }
  const onSearchDiscuss = () => {
    discussionsPage.current = 1
    queryDiscussionList()
  }

  const onDiscussChange = (e) => {
    queryContent.current = e.target.value
  }

  const onKnowledgeChange = (key) => {
    key == 'my' ? (querySource.current = 2) : (querySource.current = 1)
    queryDiscussionList()
  }
  const cancelNewArticle = () => {
    setIsAddEditorVis(false)
  }

  const [discussionTitle, setDiscussionTitle] = useState('')
  const [discussionContent, setDiscussionContent] = useState('')
  const [uploadImageList, setUploadImageList] = useState([] as any[])
  const uploadOption = {
    name: 'discussion_picture',
    actionUrl: 'course/api/discussion/upload_picture/',
    uploadData: { sessionid: Cookies.get('sessionid') },
    uploadImageList: uploadImageList,
    setUploadImageList: setUploadImageList,
  }

  /**
   * 查讨论列表
   */
  const queryDiscussionList = (knowledgeCellId = knowledgeId) => {
    sendHttpPostRequest(
      'course/api/discussion/query_kc_discussion_list/',
      {
        sessionid: Cookies.get('sessionid'),
        course_id: courseId,
        know_id: knowledgeCellId,
        page: discussionsPage.current,
        pagesize: discussionsPageSize.current,
        query: queryContent.current,
        source: querySource.current,
      },
      (response: any) => {
        message.destroy()
        if (response.code == -100) {
          // 未登录
          document.getElementById('show-login-modal')!.click()
        } else if (response.code == 0) {
          // 成功
          setDiscussions(response.query_list)
        } else if (response.code == -501) {
          // 权限不足
          window.location.href = '/'
        } else if (response.code == -509) {
          // 未阅读社区协议
        } else {
          message.error(response.message)
        }
      }
    )
  }

  /**
   * 发布知识点讨论
   */
  const publishKnowledgeCellDiscussion = () => {
    const _location = window.location.href.includes('?')
      ? window.location.href.split('?')[1].split('/')
      : null
    if (_location && _location.length >= 6) {
      courseId = parseInt(_location[1])
      chapterId = parseInt(_location[3])
      knowledgeId = parseInt(_location[5])
    }
    sendHttpPostRequest(
      'course/api/discussion/publish_kc_discussion/',
      {
        sessionid: Cookies.get('sessionid'),
        course_id: courseId,
        know_id: knowledgeId,
        title: discussionTitle,
        content: discussionContent,
        upload_image_list: JSON.stringify(uploadImageList),
      },
      (response: any) => {
        message.destroy()
        if (response.code == -100) {
          // 未登录
          document.getElementById('show-login-modal')!.click()
        } else if (response.code == 0) {
          // 成功
          message.success('发表讨论成功')
          setDiscussionContent('')
          setDiscussionTitle('')
          setIsAddEditorVis(false)
          setUploadImageList([])
          queryDiscussionList()
        } else if (response.code == -501) {
          // 权限不足
          window.location.href = '/'
        } else if (response.code == -509) {
          // 未阅读社区协议
        } else {
          message.error(response.message)
        }
      }
    )
  }

  const DiscusTabPane = (
    <div>
      {isAddEditorVis && (
        <div className={courseLearnStyles.editorContain}>
          <div className={courseLearnStyles.titleContain}>
            <span className={courseLearnStyles.newTitle}>标题</span>
            <Input
              style={{ fontSize: '14px', fontWeight: 700 }}
              bordered={false}
              placeholder={'请输入文章标题'}
              onChange={(e: any) => {
                setDiscussionTitle(e.target.value)
              }}
            />
          </div>
          <div className={courseLearnStyles.contentContain}>
            <div className={courseLearnStyles.contentTitle}>内容</div>
            <div className={discussStyle.editorBox}>
              <MarkDownEditor
                height={154}
                placeholder={'请输入文章内容'}
                onChange={({ html, text }) => {
                  setDiscussionContent(text)
                }}
                toolbar={[
                  // 'header',
                  // 'font-bold',
                  // 'emoji',
                  // 'font-italic',
                  // 'font-underline',
                  // 'font-strikethrough',
                  // 'list-unordered',
                  // 'list-ordered',
                  // 'block-quote',
                  // 'block-wrap',
                  // 'block-code-inline',
                  // 'block-code-block',
                  // 'table',
                  // 'image',
                  'img-upload',
                  // 'link',
                  // 'clear',
                  // 'mode-toggle',
                  'full-screen',
                  'tab-insert',
                ]}
                uploadOption={uploadOption}
              />
            </div>
          </div>
          {/*<div className={courseLearnStyles.knowledgeSelectContain}>*/}
          {/*  <span className={courseLearnStyles.newTitle}>知识点</span>*/}
          {/*  <Select*/}
          {/*    options={menu?.map((chapt: MenuType) => {*/}
          {/*      return {*/}
          {/*        label: chapt.name,*/}
          {/*        options: chapt.tasks.map((task: TaskType) => {*/}
          {/*          return { label: task.name, value: task.id }*/}
          {/*        }),*/}
          {/*      }*/}
          {/*    })}*/}
          {/*    placeholder={'请选择知识点'}*/}
          {/*    className={courseLearnStyles.selectInput}*/}
          {/*    bordered={false}*/}
          {/*  />*/}
          {/*</div>*/}
          <div className={discussStyle.actionBtnContain}>
            <Button
              onClick={cancelNewArticle}
              style={{ marginRight: '20px' }}
              className={courseLearnStyles.defBtn}
            >
              取消
            </Button>
            <Button
              type={'primary'}
              className={discussStyle.publishBtn}
              onClick={publishKnowledgeCellDiscussion}
            >
              发布
            </Button>
          </div>
        </div>
      )}
      <div>
        {discussions.length > 0 ? (
          <div>
            {discussions.map((discus, disIdx) => (
              <div
                key={'dis' + disIdx}
                style={{
                  marginBottom: '20px',
                  borderBottom: '1px solid #333',
                }}
              >
                <DiscussItem
                  discussions={discussions}
                  discuss={discus}
                  setDiscussions={setDiscussions}
                  index={disIdx}
                  editorStateInit={editorStateInit}
                  theme={'dark'}
                  tagShow={false}
                  knowledgeCellId={knowledgeId}
                  courseId={courseId}
                  submitCallBack={queryDiscussionList}
                />
                {discus?.reply_count > 2 && !isAllRepliesVis
                  ? JSON.parse(JSON.stringify(discus?.reply_list))
                      .splice(0, 2)
                      .map((reply, rpIdx) => (
                        <div key={rpIdx + 'reply'}>
                          <ReplyItem
                            type={{ from: 'course', course_id: courseId }}
                            reply={reply}
                            replies={replies}
                            setReplies={setReplies}
                            index={rpIdx}
                            editorStateInit={editorStateInit}
                            submitCallBack={queryDiscussionList}
                            theme={'dark'}
                          />
                        </div>
                      ))
                  : discus?.reply_list.map((reply, rpIdx) => (
                      <div key={rpIdx + 'reply'}>
                        <ReplyItem
                          type={{ from: 'course', course_id: courseId }}
                          reply={reply}
                          replies={replies}
                          setReplies={setReplies}
                          index={rpIdx}
                          editorStateInit={editorStateInit}
                          submitCallBack={queryDiscussionList}
                          theme={'dark'}
                        />
                        {reply.comment_list?.map((comment, cmIdx) => (
                          <div key={comment.id} style={{ marginLeft: '37px' }}>
                            <ReplyItem
                              type={{ from: 'course', course_id: courseId }}
                              reply={comment}
                              replies={replies}
                              setReplies={setReplies}
                              index={cmIdx}
                              editorStateInit={editorStateInit}
                              submitCallBack={queryDiscussionList}
                              theme={'dark'}
                            />
                            {comment.comment_reply_list?.map((cr, crIdx) => (
                              <ReplyItem
                                type={{ from: 'course', course_id: courseId }}
                                key={cr.id}
                                reply={cr}
                                replies={replies}
                                setReplies={setReplies}
                                index={crIdx}
                                editorStateInit={editorStateInit}
                                submitCallBack={queryDiscussionList}
                                replayToName={cr.reply_to_name}
                                theme={'dark'}
                              />
                            ))}
                          </div>
                        ))}
                      </div>
                    ))}
                {discus?.reply_count > 2 && (
                  <div
                    onClick={() => {
                      setIsAllRepliesVis(!isAllRepliesVis)
                      editorStateInit()
                    }}
                  >
                    <div className={courseLearnStyles.showAllBtn}>
                      {isAllRepliesVis ? '收起' : '展开'}
                      {`全部${discus?.reply_count}条回复>`}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : (
          <div
            style={{
              paddingRight: '15px',
              height: 'calc(100vh - 200px)',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Empty
              style={{ color: '#fff' }}
              description={'暂时还没有讨论，快去发表一个文章吧！'}
              image={Empty.PRESENTED_IMAGE_DEFAULT}
            />
          </div>
        )}
      </div>
    </div>
  )

  const [noteList, setNoteList] = useState([] as any)

  const jumpCurrentTime = (currentTime) => {
    if (module?.type == 2) {
      const video_: any = document!.getElementById('_volum')
      video_.currentTime = currentTime
    } else {
      setPageNumber(currentTime)
    }
  }
  const noteTabPane = (
    <TabPane
      className="course-learn-exercise-list"
      style={{ paddingBottom: 0 }}
      tab={
        <div style={{ width: '50%', fontSize: 16, fontWeight: 400 }}>
          <span className={'iconfont icon_biji1'}></span> 笔记
        </div>
      }
      key="3"
    >
      {noteList.length > 0 ? (
        <div
          id="note-scroll-contain"
          style={{
            overflowY: 'auto',
            paddingRight: '15px',
            maxHeight: 'calc(100vh - 130px)',
          }}
        >
          {noteList?.map((note, nIdx) => (
            <NoteItem
              key={note.id}
              note={note}
              nIdx={nIdx}
              noteList={noteList}
              setNoteList={setNoteList}
              courseId={courseId}
              knowId={module?.id}
              jumpCurrentTime={jumpCurrentTime}
              actionCallback={queryNoteList}
              type={module?.type}
              noHide={true}
            />
          ))}
        </div>
      ) : (
        <div
          style={{
            paddingRight: '15px',
            height: 'calc(100vh - 130px)',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Empty
            style={{ color: '#fff' }}
            description={'暂时还没有笔记，快去标注知识点创建笔记吧！'}
            image={Empty.PRESENTED_IMAGE_DEFAULT}
          />
        </div>
      )}
    </TabPane>
  )

  const discussTabPane = (
    <TabPane
      className="course-learn-exercise-list"
      style={{ paddingBottom: 0 }}
      tab={
        <div style={{ width: '50%', fontSize: 16, fontWeight: 400 }}>
          <span className={'iconfont icon_taolun'}></span> 讨论
        </div>
      }
      key="4"
    >
      <div className={courseLearnStyles.discussRightTab}>
        <Tabs onChange={onKnowledgeChange} defaultActiveKey="all">
          <Tabs.TabPane tab="全部" key="all">
            {DiscusTabPane}
          </Tabs.TabPane>
          <Tabs.TabPane tab="我的" key="my">
            {DiscusTabPane}
          </Tabs.TabPane>
        </Tabs>
        <Row className={courseLearnStyles.rightActionBox} align="middle">
          <Col>
            <SearchInputCom
              placeholder={'根据标题/内容关键字搜索'}
              onSearch={onSearchDiscuss}
              onChange={onDiscussChange}
              theme={'dark'}
            />
          </Col>
          <Col>
            <Button
              style={{ marginLeft: '20px' }}
              className={discussStyle.pribtn}
              onClick={() => setIsAddEditorVis(true)}
              type="primary"
            >
              +发文
            </Button>
          </Col>
        </Row>
      </div>
    </TabPane>
  )

  const CourseLearnExerciseCase = (
    <div>
      {(moduleExercises?.length > 0 && moduleExercises?.length,
      (JSON.stringify(moduleCase) == '{}'
        ? 0
        : moduleCase?.length == 0
        ? 0
        : 1) > 0) ? (
        <div className={courseLearnStyles.exerciseCaseTabpanelWrapper}>
          <Tabs
            activeKey={currentActiveTabKey}
            onChange={(activeKey: any) => {
              setCurrentActiveTabKey(activeKey)
            }}
            type="card"
            size="middle"
            tabPosition="top"
          >
            <TabPane
              style={{ paddingBottom: 0 }}
              tab={
                <div style={{ width: '50%', fontSize: 16, fontWeight: 400 }}>
                  <FormOutlined /> 练习
                </div>
              }
              key="1"
            >
              <div
                className="course-learn-exercise-list"
                style={{
                  paddingLeft: 15,
                  paddingRight: 15,
                  height: 'calc(100vh - 116px)',
                }}
              >
                <Row className={courseLearnStyles.exerciseSelectWrapper}>
                  {moduleExercises?.map((exercise, index) => {
                    return (
                      <Col key={exercise?.id}>
                        <div
                          className={courseLearnStyles.exerciseIndexDiv}
                          style={{
                            backgroundColor: getExerciseBackColorByIndex(
                              completeStatus[index]
                            ),
                            border:
                              index == selecetedExerciseIndex.current
                                ? '1px solid #fff'
                                : 0,
                          }}
                          onClick={() => {
                            selecetedExerciseIndex.current = index
                            queryPracticesExercise(
                              moduleExerciseIds.current[
                                selecetedExerciseIndex.current
                              ]
                            )
                          }}
                        >
                          {index + 1}
                        </div>
                      </Col>
                    )
                  })}
                </Row>

                <div style={{ marginTop: 0 }}>
                  <ExerciseCard
                    exercise={practiceExercises}
                    renderType="dark"
                    teacherOnly={false}
                    editorMode={true}
                    showAnser={false}
                    showClean={true}
                  />
                </div>
              </div>
            </TabPane>

            <TabPane
              tab={
                <div style={{ width: '50%', fontSize: 16, fontWeight: 400 }}>
                  <CodeOutlined />
                  实验
                </div>
              }
              key="2"
            >
              <iframe
                id="notebook-iframe"
                sandbox="allow-scripts allow-forms allow-same-origin"
                // security="restricted"
                src={urlPrefix + '?style=dark'}
                width="100%"
                style={{
                  overflowY: 'hidden',
                  backgroundColor: '#222222',
                  height: 'calc(100vh - 122px)',
                  borderRadius: 0,
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
                frameBorder="0"
              />
            </TabPane>
            {noteTabPane}
            {discussTabPane}
          </Tabs>
          {currentActiveTabKey == '1' && (
            <div style={{ height: 50, background: '#343434', bottom: 0 }}>
              <Row
                align="middle"
                justify="center"
                gutter={[16, 16]}
                style={{ height: 50 }}
              >
                <Col>
                  {/*<Button type="primary" style={{borderRadius:3,color:"#fff",height:30,width:80, backgroundColor:"#07c160"}} onClick={onExerciseSubmitClick}> 提 交 </Button>*/}
                  {moduleExercises?.length > 1 && (
                    <Button
                      disabled={selecetedExerciseIndex.current == 0}
                      onClick={onPrevExerciseClick}
                      className={courseLearnStyles.btnDefault}
                      style={{
                        marginLeft: 50,
                        borderRadius: 3,
                        height: 30,
                        width: 80,
                      }}
                    >
                      {' '}
                      上一题{' '}
                    </Button>
                  )}
                  {moduleExercises?.length > 1 && (
                    <Button
                      disabled={
                        selecetedExerciseIndex.current ==
                        moduleExercises.length - 1
                      }
                      onClick={onNextExerciseClick}
                      className={courseLearnStyles.btnDefault}
                      style={{
                        marginLeft: 10,
                        borderRadius: 3,
                        height: 30,
                        width: 80,
                      }}
                    >
                      {' '}
                      下一题{' '}
                    </Button>
                  )}
                </Col>
              </Row>
            </div>
          )}

          {currentActiveTabKey == '2' && (
            <div style={{ height: 50, background: '#343434', bottom: 0 }}>
              <Row
                align="middle"
                justify="start"
                gutter={[16, 16]}
                style={{ height: 50 }}
              >
                <Col
                  flex={'auto'}
                  className={courseLearnStyles.caseName}
                  style={{
                    paddingLeft: 20,
                    textAlign: 'left',
                    color: '#ffffff80',
                  }}
                >
                  {moduleCase?.name}
                </Col>
                <Col
                  flex={'100px'}
                  style={{ padding: 0, textAlign: 'left', color: '#ffffff80' }}
                >
                  <Popover
                    overlayClassName={courseLearnStyles.caseBottomWrapper}
                    placement="topRight"
                    content={caseDataContent}
                    title="实验数据"
                  >
                    <div className={courseLearnStyles.bottomDataDiv}>
                      <TableOutlined style={{ marginRight: 10 }} />{' '}
                      {caseDataListLength}
                    </div>
                  </Popover>
                </Col>
              </Row>
            </div>
          )}
        </div>
      ) : moduleExercises?.length == 0 &&
        (JSON.stringify(moduleCase) == '{}'
          ? 0
          : moduleCase?.length == 0
          ? 0
          : 1) == 0 ? (
        <Tabs
          activeKey={currentActiveTabKey}
          onChange={(activeKey: any) => {
            setCurrentActiveTabKey(activeKey)
          }}
          type="card"
          size="middle"
          tabPosition="top"
        >
          {noteTabPane}
          {discussTabPane}
        </Tabs>
      ) : moduleExercises?.length > 0 ? (
        <div>
          <Tabs
            activeKey={currentActiveTabKey}
            onChange={(activeKey: any) => {
              setCurrentActiveTabKey(activeKey)
            }}
            defaultActiveKey={'1'}
            type="card"
            size="middle"
            tabPosition="top"
          >
            <TabPane
              className="course-learn-exercise-list"
              style={{ paddingBottom: 0 }}
              tab={
                <div style={{ width: '50%', fontSize: 16, fontWeight: 400 }}>
                  <FormOutlined /> 练习
                </div>
              }
              key="1"
            >
              <div
                style={{
                  paddingLeft: 15,
                  paddingRight: 15,
                  height: 'calc(100vh - 116px)',
                }}
              >
                <Row className={courseLearnStyles.exerciseSelectWrapper}>
                  {moduleExercises?.map((exercise, index) => {
                    return (
                      <Col key={exercise?.id}>
                        <div
                          className={courseLearnStyles.exerciseIndexDiv}
                          style={{
                            backgroundColor: getExerciseBackColorByIndex(
                              completeStatus[index]
                            ),
                            border:
                              index == selecetedExerciseIndex.current
                                ? '1px solid #fff'
                                : 0,
                          }}
                          onClick={() => {
                            selecetedExerciseIndex.current = index
                            queryPracticesExercise(
                              moduleExerciseIds.current[
                                selecetedExerciseIndex.current
                              ]
                            )
                          }}
                        >
                          {index + 1}
                        </div>
                      </Col>
                    )
                  })}
                </Row>

                <div style={{ marginTop: 0 }}>
                  <ExerciseCard
                    exercise={practiceExercises}
                    renderType="dark"
                    teacherOnly={false}
                    editorMode={true}
                    showAnser={false}
                    showClean={true}
                  />
                </div>
              </div>

              <div
                style={{
                  height: 50,
                  background: '#343434',
                  bottom: 0,
                  position: 'fixed',
                  width: '50%',
                  right: 0,
                }}
              >
                <Row
                  align="middle"
                  justify="center"
                  gutter={[16, 16]}
                  style={{ height: 50 }}
                >
                  <Col>
                    {/*<Button type="primary" style={{borderRadius:3,color:"#fff",height:30,width:80, backgroundColor:"#07c160"}}> 提 交 </Button>*/}
                    {moduleExercises?.length > 1 && (
                      <Button
                        disabled={selecetedExerciseIndex.current == 0}
                        onClick={() => {
                          onPrevExerciseClick()
                        }}
                        className={courseLearnStyles.btnDefault}
                        style={{
                          marginLeft: 50,
                          borderRadius: 3,
                          height: 30,
                          width: 80,
                        }}
                      >
                        {' '}
                        上一题{' '}
                      </Button>
                    )}
                    {moduleExercises?.length > 1 && (
                      <Button
                        disabled={
                          selecetedExerciseIndex.current ==
                          moduleExercises.length - 1
                        }
                        onClick={() => {
                          onNextExerciseClick()
                        }}
                        className={courseLearnStyles.btnDefault}
                        style={{
                          marginLeft: 10,
                          borderRadius: 3,
                          height: 30,
                          width: 80,
                        }}
                      >
                        {' '}
                        下一题{' '}
                      </Button>
                    )}
                  </Col>
                </Row>
              </div>
            </TabPane>
            {noteTabPane}
            {discussTabPane}
          </Tabs>
        </div>
      ) : (
        <Tabs
          activeKey={currentActiveTabKey}
          onChange={(activeKey: any) => {
            setCurrentActiveTabKey(activeKey)
          }}
          className={courseLearnStyles.exerciseCaseWrapper}
          defaultActiveKey={'1'}
          type="card"
          size="middle"
          tabPosition="top"
        >
          <TabPane
            tab={
              <div style={{ width: '50%', fontSize: 16, fontWeight: 400 }}>
                <CodeOutlined />
                实验
              </div>
            }
            key="2"
          >
            <div id="waitPage">
              <div className={courseLearnStyles.iframeImg}>
                <img src={loadingGif} style={{ width: 90 }} />
              </div>
            </div>
            <iframe
              id="notebook-iframe"
              sandbox="allow-scripts allow-forms allow-same-origin"
              // security="restricted"
              // className={caseToken==null?courseLearnStyles.iframeImg:courseLearnStyles.iframe}
              // src={caseToken==null? loadingGif : urlPrefix + 'notebooks/note/' +  caseToken + '/' +  caseToken + '.ipynb?style=dark'}
              className={courseLearnStyles.iframe}
              src={urlPrefix + '?style=dark'}
              onLoad={pageLoad}
              width="100%"
              // style={{overflowY:"hidden",backgroundColor:"#222222",height:"calc(100vh - 122px)", borderRadius:0,marginLeft:"auto",marginRight:"auto"}}
              frameBorder="0"
            />
          </TabPane>
          {noteTabPane}
          {discussTabPane}
        </Tabs>
      )}
    </div>
  )

  const [numPages, setNumPages] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
    console.log(numPages)
  }
  const handelOnChange = (page: any) => {
    // 如果是学生的话
    if (isCourseStudent.current) {
      // 第一次访问  learningProgress = 0    page > 2 不能访问
      if (
        learningProgress.current == 0 &&
        page > learningProgress.current + 2
      ) {
        console.log('page', page, 'pageNumber', pageNumber)
        page = pageNumber
        message.error('当前进度不可以跳越！')
        // 不是第一次访问  假设 learnProgress = 3  page > 4 不能访问
      } else if (
        learningProgress.current != 0 &&
        page > learningProgress.current + 1 &&
        !isFinished.current
      ) {
        page = pageNumber
        message.error('当前进度不可以跳越！')
        //  learnProgress = 3  page <= 3  的时候不需要更新 learningProgress
      } else if (page <= learningProgress.current) {
        setPageNumber(page)
      } else {
        setPageNumber(page)
        updateModule(jumpChapterId.current, jumpKnowledgeId.current, page)
      }
    } else {
      setPageNumber(page)
    }
  }
  const cancelCallback = (e) => {
    if (e.code == -1 && !localStorage.getItem('already_setting')) {
      setNavigateTourVis(true)
    }
    setIsShoting(false)
  }
  const callback = (base64data) => {
    setIsShoting(false)
    setCurrentActiveTabKey('3')
    if (!base64data) {
      return
    }
    sendHttpPostRequest(
      'course/api/study_note/save_capture_image/',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        course_id: courseId,
        position:
          module.type == 2 ? shotScreenTime.current : pdfPosition.current,
        know_id: module.id,
        image_content: base64data,
      },
      (res) => {
        if (res.is_ok) {
          noteList.push({
            image_id: res.image_id,
            hash_id: res.hash_id,
            position:
              module?.type == 2 ? shotScreenTime.current : pdfPosition.current,
            image_src: res.image_url,
            content: '',
            create_time: '',
            tag_id: module.id,
            is_editor: true,
          })
          setNoteList([...noteList])
          let noteArea = document.getElementById('note-scroll-contain')
          noteArea!.scrollTop = noteArea!.scrollHeight
        }
      }
    )
  }

  const convertImageToCanvas = (image) => {
    let canvas = document.createElement('canvas')
    canvas.width = image.width
    canvas.height = image.height
    canvas.getContext('2d')!.drawImage(image, 0, 0)
    return canvas
  }

  const closeFn = () => {
    console.log('截图窗口关闭')
    setIsShoting(false)
    shotScreenTime.current = 0
    pdfPosition.current = 0
  }

  const screenShotClick = () => {
    setIsShoting(true)
    if (noteList.some((note) => note.is_editor)) {
      setNoteList([...noteList.splice(-1)])
    }
    if (module.type == 2) {
      const video_: any = document!.getElementById('_volum')
      shotScreenTime.current = video_.currentTime
      video_.pause()
    } else {
      pdfPosition.current = pageNumber
    }
    sendHttpPostRequest(
      'course/api/study_note/capture_kc_image/',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        course_id: courseId,
        position:
          module.type == 2 ? shotScreenTime.current : pdfPosition.current,
        know_id: module.id,
      },
      (res) => {
        if (res.is_ok) {
          noteList.push({
            image_id: res.image_id,
            hash_id: res.hash_id,
            position:
              module?.type == 2 ? shotScreenTime.current : pdfPosition.current,
            image_src: res.image_url,
            content: '',
            create_time: '',
            tag_id: module.id,
            is_editor: true,
          })
          setIsShoting(false)
          setCurrentActiveTabKey('3')
          setNoteList([...noteList])
          let noteArea = document.getElementById('note-scroll-contain')
          noteArea!.scrollTop = noteArea!.scrollHeight
        }
      }
    )
    // new ScreenShort({
    //   enableWebRtc: true, //是否显示选项框
    //   level: 99, //层级级别
    //   // cropBoxInfo:{x:240,y:100,w:500,h:500},
    //   // wrcImgPosition:{x:0,y:0,w:500,h:500},
    //   // screenShotDom:_v__contain,
    //   toolPosition: 'right',
    //   wrcWindowMode: false,
    //   loadCrossImg: true,
    //   maskColor: { r: 255, g: 255, b: 255, a: 0.5 },
    //   proxyUrl: '0.0.0.0:9099',
    //   // wrcReplyTime:100,
    //   // screenShotDom:document.getElementById("_volum"),
    //   // color:"#000",
    //   // noScroll:false,
    //   // hiddenScrollBar:true,
    //   // showScreenData:true,
    //   completeCallback: callback,
    //   closeCallback: closeFn,
    //   cancelCallback: cancelCallback,
    //   hiddenToolIco: {
    //     save: true,
    //   },
    // })
  }

  return (
    <Layout className="course-learn-page">
      <SplitPane
        allowResize={true}
        split="vertical"
        defaultSize={exerciseNum + caseNum > 0 ? '50%' : 0}
        primary="second"
        maxSize={-300}
      >
        <div style={{ height: '100vh' }}>
          <div
            style={{ height: 50, background: '#343434', textAlign: 'center' }}
          >
            <Row
              style={{ paddingTop: 15 }}
              justify="start"
              align="middle"
              gutter={[4, 4]}
            >
              <Col flex="15%">
                {' '}
                <Tooltip placement="right" title="课程大纲">
                  <ProfileOutlined
                    onClick={showMenuDrawer}
                    className={courseLearnStyles.iconbuttons}
                  />
                </Tooltip>
              </Col>
              <Col flex="70%">
                <Row justify="center" align="middle" gutter={[4, 4]}>
                  <Col flex="10%">
                    <Tooltip placement="left" title="上一知识点">
                      <LeftCircleOutlined
                        onClick={onPrewKnowledgeClick.bind(this)}
                        className={courseLearnStyles.iconbuttons}
                      />
                    </Tooltip>
                  </Col>
                  <Col flex="70%">
                    <div style={{ fontWeight: 600, color: '#f9f9f9' }}>
                      {moduleName}{' '}
                    </div>
                  </Col>
                  <Col flex="10%">
                    {' '}
                    <Tooltip placement="right" title="下一知识点">
                      <RightCircleOutlined
                        onClick={onNextKnowledgeClick.bind(this)}
                        className={courseLearnStyles.iconbuttons}
                      />
                    </Tooltip>
                  </Col>
                </Row>
              </Col>
              <Col flex="15%">
                {' '}
                <Tooltip placement="right" title="退出学习">
                  <LogoutOutlined
                    onClick={logoutLearnBtnClick}
                    className={courseLearnStyles.iconbuttons}
                  />{' '}
                </Tooltip>
              </Col>
            </Row>
          </div>

          <div
            style={{
              height: 'calc(100vh - 100px)',
              background: '#000',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 10,
            }}
          >
            <div
              style={{
                maxHeight: 'calc(100vh - 100px)',
                position: 'relative',
                overflow: 'hidden',
                zIndex: 99,
              }}
              id="video_contain"
            >
              <div
                onClick={screenShotClick}
                className={courseLearnStyles.screenShotBtn}
                hidden={isShoting}
              >
                <span className={'iconfont icon_biaozhu'}></span>标注
              </div>
              {module?.type == 2 && moduleVideoUrl && (
                <video
                  id={'_volum'}
                  style={{
                    marginTop: 25,
                    marginBottom: 25,
                    height: 'calc(100vh - 150px)',
                    width: '90%',
                    zIndex: 98,
                    position: 'relative',
                  }}
                  preload="preload"
                  controls={true}
                  controlsList="nodownload"
                >
                  <source
                    src={
                      moduleVideoUrl && moduleVideoUrl?.startsWith('http')
                        ? moduleVideoUrl
                        : constants.BACKEND_URL +
                          '/course/api/get_stream_video?path=' +
                          moduleVideoUrl
                    }
                    type={'video/mp4'}
                  />
                  您的浏览器不支持 HTML5 video 标签。
                </video>
              )}

              {module?.type == 1 && moduleLectureUrl && (
                <div className={courseLearnStyles.PdfViewerWrapper}>
                  <Document
                    style={{ maxHeight: '100%', maxWidth: '100%' }}
                    file={
                      // moduleLectureUrl.startsWith('http')
                      //   ? moduleLectureUrl
                      //   : window.location.host.startsWith('www')
                      //   ? constants.MEDIA_CORS_URL_WWW + moduleLectureUrl
                      //   : constants.MEDIA_CORS_URL + moduleLectureUrl
                      constants.BACKEND_URL + moduleLectureUrl + '/'
                    }
                    onLoadSuccess={onDocumentLoadSuccess}
                  >
                    <Page pageNumber={pageNumber} />
                  </Document>

                  {/* <Pagination pageSize= {1} showSizeChanger={false} onChange={handelOnChange} total={numPages} current={pageNumber}
                        
                      }
                    }
                    onLoadSuccess={onDocumentLoadSuccess}
                  >
                    <Page pageNumber={pageNumber} />
                  </Document>

                  {/* <Pagination pageSize= {1} showSizeChanger={false} onChange={handelOnChange} total={numPages} current={pageNumber}
                                    style={{maxWidth:"100%",height:50,position:'absolute',bottom:0}}/> */}
                </div>
              )}
            </div>
          </div>

          <div style={{ height: 50, background: '#343434' }}></div>

          <Drawer
            title={<div style={{ color: '#fff' }}>{courseName}</div>}
            placement="left"
            closable={true}
            onClose={onMenuDrawerClose}
            visible={menuVisible}
            key="left"
            width={300}
            headerStyle={{ background: '#101010', height: 50, border: 0 }}
            drawerStyle={{ overflow: 'hidden', background: '#101010' }}
            bodyStyle={{
              padding: 10,
              color: '#fff',
              overflowX: 'hidden',
              marginRight: -20,
              marginBottom: -20,
            }}
            contentWrapperStyle={{ overflow: 'hidden', opacity: 0.7 }}
            closeIcon={
              <LeftCircleOutlined className={courseLearnStyles.iconbuttons} />
            }
          >
            <div className={courseLearnStyles.menuTrue}>
              <Tree
                //showLine
                switcherIcon={<DownOutlined />}
                showIcon={false}
                blockNode={true}
                //fieldNames={{title:'name',key:'id',children:'modules'}}
                treeData={getTreeData()}
                defaultExpandAll={true}
                onSelect={handleModuleSelected.bind(this)}
                titleRender={(nodeData) => (
                  <Row
                    justify="start"
                    align="middle"
                    gutter={[4, 4]}
                    style={{ height: 20 }}
                  >
                    <Col flex="auto">{nodeData.title}</Col>
                    <Col flex="30px">
                      <div className={courseLearnStyles.progsetting}>
                        {/*Math.round(100*Math.random()+0.1) + '%' */}
                        {nodeData.selectable !== false && (
                          <Progress
                            showInfo={false}
                            strokeWidth={8}
                            strokeColor="#07C160"
                            type="circle"
                            percent={Math.round(100 * Math.random() + 0.1)}
                            width={15}
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                )}
                style={{ background: '#101010', color: '#fff' }}
              ></Tree>
            </div>
          </Drawer>
          {module?.type == 1 && moduleLectureUrl && (
            <div
              className={courseLearnStyles.pagination}
              style={{
                width: '100%',
                height: 50,
                lineHeight: '50px',
                position: 'absolute',
                bottom: 0,
              }}
            >
              <Pagination
                pageSize={1}
                showSizeChanger={false}
                onChange={handelOnChange}
                total={numPages}
                current={pageNumber}
                style={{ maxWidth: '100%', height: 32, margin: '0 auto' }}
              />
            </div>
          )}
          {/* <div className={courseLearnStyles.controlPoint} style={{float:'right',width:2,height:'100vh',marginTop:'-100vh'}} onMouseMove={controlMouseMove} onMouseDown={controlMouseDown} onMouseUp={controlMouseUp}></div> */}
        </div>
        <div className={courseLearnStyles.setting} style={{ height: '100vh' }}>
          <div style={{ width: '100%', height: '100%' }}>
            {CourseLearnExerciseCase}
          </div>
        </div>
      </SplitPane>
      <CommonModal
        modalTitle={'导航'}
        modalContent={navigateTourContent}
        open={navigateTourVis}
        onCancel={() => setNavigateTourVis(false)}
        onOk={() => {
          setNavigateTourVis(false)
          localStorage.setItem('already_setting', '1')
        }}
        okText="不再提醒"
      />
      <TeacherAuthenticate />
    </Layout>
  )
}

export default CourseLearn
