import React, { useEffect, useState } from "react";
import {
  Collapse,
  Divider,
  Layout,
  Input,
  Select,
  message,
  Button,
  Radio,
  Checkbox,
  Row,
  Col,
  Popconfirm,
  Upload,
  Breadcrumb,
  ConfigProvider,
  Table,
  Pagination,
  Tooltip
} from 'antd';
import { PageHeader } from "../common/page-header/header";
import { sendHttpPostRequest } from "../../api/http-request";
import logoSvg from '../../static/image/idclogo.png';
import pekinglogoSvg from '../../static/image/peking.png';
import {
  DownOutlined,
  ArrowLeftOutlined,
  FormOutlined,
  CodeOutlined,
  ProfileOutlined,
  LeftCircleOutlined,
  RightCircleOutlined,
  FullscreenExitOutlined, FullscreenOutlined
} from '@ant-design/icons';
import Cookies from 'js-cookie';

import zhCN from 'antd/lib/locale/zh_CN'
import * as constants from '../../constants/constants';
import PageFooter from "../common/page-footer/footer";
import LoginRegisterModal from "../../component/modal/login-register/login-register";
import { checkUserLogin } from "../common/user-center/user-info";
import { UserOutlined } from '@ant-design/icons';
import { Content } from "antd/lib/layout/layout";
import Sider from "antd/lib/layout/Sider";
import staticStyle from "./case-static.module.less"
// 通过路由组件额外添加参数信息到表中
import { RouteComponentProps } from "react-router";
import SplitPane from "react-split-pane";
import caseStyles from "./case.module.less";
import './case-notebook.module.css'

const { Panel } = Collapse;


interface Props extends RouteComponentProps<{ course_id: string }> {
  course_id: string
}

export const CaseStatic: React.FC<Props> = (props) => {
  const caseId = parseInt(props.location.search.split("=")[1])
  const [caseSrc, setCaseSrc] =  useState( "");
  const [currentCase, setCurrentCase] = useState(null as any)
  const [caseName, setCaseName] = useState( null as any);
  const [descriptions, setDescriptions] = useState( null as any);
  const [field,setField] = useState( null as any);
  const [fields,setFields] = useState([]); //无全部的行业选择
  const [cover, setCover] = useState(null as any) //封面
  const [coverFileList, setCoverfileList] = useState([])
  const [caseDataList, setCaseDataList] = useState([] as any[]) //实验包含的数据集列表
  const [userLogin, setUserLogin] = useState(false);  // 用户登录状态
  const [isFullScreen, setIsFullScreen] = useState(false);//是否处于全品模式
  const [width, setWidth] = useState("auto")
  const [userInfo, setUserInfo] = useState(null as any)

  useEffect(() => {
    // 示例代码请勿删除
    queryCase()
  }, []);

  /**
   * 查询实验信息
   */
  const queryCase = () => {
    sendHttpPostRequest(
        'case/api/query_student_case_by_id',
        {
          sessionid: Cookies.get("sessionid"),
          case_id: caseId,
          is_my: "2",
          from_oam: 0,
        },
        queryCaseCallback
    )
  }

  /**
   * 查询实验回调
   * @param response
   */
  const queryCaseCallback = (response: any) => {
    console.log(response);
    message.destroy();
    if (response.code == -100) {
      // 未登录
      setUserLogin(false);
      document.getElementById("show-login-modal")!.click();
    } else if (response.code == 0) {
      // 成功
      setUserLogin(true);
      setUserInfo(response.case_info.case_creator)
      setCurrentCase(response.case_info);
      setCaseName(response.case_info.name);
      setCaseDataList(response.case_info.dataset)
      setDescriptions(response.case_info.desc)
      setField(response.case_info.domain)
      if (response.case_info.need_jump) {
        window.location.href = ('case-static?id=' + response.case_info.case_id);
      } else {

        let url_prefix = constants.NOTEBOOK_BACKEND_URL;

        let token = response?.case_info.token;
        if (response.case_info.url_prefix) {
          setCaseSrc(response.case_info.url_prefix + 'notebooks/note/' + token + '/' + token + '.html');
        } else {
          setCaseSrc(url_prefix + 'notebooks/note/' + token + '/' + token + '.html');
        }
      }
    } else if (response.code == -501) {
      // 权限不足
      setUserLogin(false);
      message.error("当前用户权限不足");
    } else {
      setUserLogin(false);
      message.error(response.message);
    }
  }

  // 渲染Iframe
  const renderCaseIframe = () => {
    if (userLogin) {
      return (
          <iframe
              id="notebook-iframe"
              sandbox="allow-scripts allow-forms allow-same-origin"
              src={caseSrc}
              width="100%"
              style={{minHeight:'calc(100vh - 52px)', borderRadius:10,marginLeft:"auto",marginRight:"auto",marginBottom:20}}
              frameBorder="0"
          />
      )
    } else {
      return (
          <div
              style={{minHeight: 1000}}
          ></div>
      )
    }
  }

  return (
    <Layout style={{minHeight: 750, minWidth: 900, backgroundColor: '#f9f9f9' }}>
      
      <div className={staticStyle.caseStaticHeader} style={{ paddingTop: 20, width: 900, marginTop: 30, backgroundColor: '#fff' }}>
        <Row align="middle">
          <Col span={18} style={{ textAlign: "left", marginBottom: 10, width: '100%' }}>
            <div className={staticStyle.userInfo}>
              <img src={userInfo?.avatar?.startsWith("http") ? userInfo?.avatar : constants.BACKEND_API_URL + userInfo?.avatar} alt="" />
              <div className={staticStyle.userDetail}>
                <span>{userInfo?.real_name ? userInfo?.real_name : userInfo?.name}</span> <span style={{color:'#ccc'}}>|</span>
                <span>{userInfo?.number}</span> <span style={{color:'#ccc'}}>|</span>
                <span>保存时间：{currentCase?.update_datetime}</span>
              </div>
            </div>
          </Col>
          <Col span={6} style={{ textAlign: "right", marginBottom: 10, width: '100%' }}>
            <Button style={{ marginRight: 40, color: "#333333" }} onClick={() => { history.back() }} type="link"> {"返回 >"} </Button>
          </Col>
        </Row>
        <Divider style={{ marginTop: 0 }} />
        <div className={staticStyle.caseInfo}>
          <img src={currentCase?.image?.startsWith("http") ? currentCase?.image : constants.BACKEND_URL + currentCase?.image} alt="" />
          <div className={staticStyle.casedetail}>
            <div style={{ width: '100%', marginBottom: 10, textAlign: 'left', fontSize: 16 }}>{caseName}</div>
            <div style={{ marginBottom: 10 }}><span style={{ padding: "3px 8px 3px 8px", fontSize: 12, height: 18, color: "#00CC99", backgroundColor: '#F2F2F2', borderRadius: 10, marginBottom: 5 }}>{currentCase?.domain}</span></div>
            <div className={staticStyle.casedesc} style={{ textAlign: 'left', color: '#00000073' }}>{currentCase?.desc}</div>
            <div className={staticStyle.collapseWrapper}>
              <Collapse bordered={false} defaultActiveKey={["1"]}>
                <Panel header={"实验数据 (" + currentCase?.dataset?.length + ")"} key="1">
                  <Row style={{ marginBottom: 0 }} gutter={[0, 2]} justify="start" align="top">
                    {currentCase?.dataset?.map((data) => {
                      return <Col span={24} key={data} className={staticStyle.uploadFile} style={{ textAlign: "left" }}>
                        <div key={data} style={{ padding: "5px 5px 5px 20px", fontSize: 12, width: "100%" }}>
                          {data}
                        </div>
                      </Col>
                    })}
                  </Row>
                </Panel>
              </Collapse>
            </div>
          </div>
        </div>

      </div>
      <div className={staticStyle.staticStyleContent}>
        <div id="notebook-iframe-wrapper"

             style={{paddingLeft:isFullScreen?50:30, paddingRight:isFullScreen?50:30,
               marginRight:"auto",marginLeft:"auto",width:width,backgroundColor:"#fff",
               borderRadius:10,marginBottom:20}}>

          {/*<div className={caseStyles.fullscreenHeader}  style={{marginBottom:isFullScreen?0:0,*/}
          {/*  padding:isFullScreen?"10px 30px 10px 30px":0}}>*/}
          {/*  <Row align="middle" justify="start">*/}
          {/*    <Col  flex="200px" style={{textAlign:"left"}} >*/}
          {/*      <img src={logoSvg} style = {{display:isFullScreen?"block":"none", width:115}}></img></Col>*/}
          {/*    <Col flex="auto" style={{textAlign:"center"}} >*/}
          {/*      <div style={{display:isFullScreen?"block":"none", fontWeight:500,fontSize:16, color:"#333"}}>{currentCase?.name} </div></Col>*/}
          {/*    <Col flex="170px"  > </Col>*/}
          {/*    <Col flex="30px" style={{textAlign:"right"}} >*/}
          {/*      <div className={caseStyles.fullscreenIcon} style={{marginRight:-30,marginBottom:-30,fontSize:18,paddingRight:5,textAlign:"right"}}>{isFullScreen? <Tooltip placement="right" title="退出全屏"><FullscreenExitOutlined onClick={notebookDelFull}/> </Tooltip>*/}
          {/*          :*/}
          {/*          <Tooltip title="全屏" ><FullscreenOutlined  onClick={notebookShowFull}/></Tooltip>} </div>*/}

          {/*    </Col>*/}
          {/*  </Row>*/}
          {/*</div>*/}



          {renderCaseIframe()}
        </div>

      </div>
      
    </Layout>

  )

}

export default CaseStatic

