import { Carousel, Modal, ConfigProvider, Layout, message, Card, Col, Row, Pagination, Input } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import zhCN from 'antd/lib/locale/zh_CN'
import axios from 'axios'
import Qs from 'qs'
import selfCss from './vod-list.module.less'
import Cookies from 'js-cookie'
import { PageHeader } from "../common/page-header/header";
import './vod-list.module.css'
import * as constants from '../../constants/constants';

import { Link } from 'react-router-dom';
import PageFooter from "../common/page-footer/footer";
import {checkUserLogin} from "../common/user-center/user-info";

import { LeftOutlined, SearchOutlined,RightOutlined,EyeOutlined,PlayCircleOutlined } from '@ant-design/icons';

import { CarouselRef } from 'antd/lib/carousel'
import Feedback from '../common/feedback-help/feedback'
import { sendHttpPostRequest } from '../../api/http-request'

const { Content } = Layout
const { Meta } = Card

interface Props {
  lecture:any,
    className: string
}
/**
 * VodList
 * 视频列表
 */
export const VodList: React.FC<Props> = (props) => {
  
  const [bannerList, setBannerList] = useState([] as any[]);  // 当前显示的结果页

  const [lectureListResult, setLectureResult] = useState([] as any[]);  // 当前显示的结果页
  const [resultSum, setResultSum] = useState(0);  // 搜索返回的结果数
  const [totalCount, setTotalCount] = useState(0);  // 总数
  const [onlineCount, setOnlineCount] = useState(0);  // 上架的数量


  const queryContent = useRef('');  // 记录搜索串
  const order = useRef('');  // 排序升序降序
  const order_by = useRef('');  // 排序列
  const page = useRef(1);  // 记录当前的页数
  const pageSize = useRef(12);  // 记录当前每页展示数量


  const myCarousel = useRef<CarouselRef | null>(null)

  useEffect(() => {
    queryLectureList();
    querybannerList();
  }, [])


      /**
     * 查询讲座列表
     */
       const queryLectureList = () => {
        sendHttpPostRequest(
            'video/api/user_query_lecture_list/',
            {
                sessionid: Cookies.get("sessionid"),    
                query: queryContent.current,
                page: page.current,
                pagesize: pageSize.current,
                order: order.current,
                order_by: order_by.current,
            },
            (response: any) => {
                message.destroy();
                if (response.code == -100) {
                    // 未登录
                    window.location.href = '/login?next=lecture';
                } else if (response.is_ok == true) {
                    // 成功
                    setLectureResult(response.query_list);
                    setResultSum(response.total_sum);
                    setTotalCount(response.total_count);
                    setOnlineCount(response.online_count);
                } else if (response.code == -501) {
                    // 权限不足
                    window.location.href = '/login?next=lecture';
                } else {
                    message.error(response.message);
                }
            }
        
        )
    }
    


      /**
     * 查询Banner列表
     */
       const querybannerList = () => {
        sendHttpPostRequest(
            'video/api/user_query_lecture_banner_list/',
            {
                sessionid: Cookies.get("sessionid"),    
                query: '',
                page: 1,
                pagesize: 1000,
            },
            (response: any) => {
                // console.log(response)
                message.destroy();
                if (response.code == -100) {
                    // 未登录
                    window.location.href = '/login?next=lecture';
                } else if (response.is_ok == true) {
                    // 成功
                    setBannerList(response.query_list);
   
                } else if (response.code == -501) {
                    // 权限不足
                    window.location.href = '/login?next=lecture';
                } else {
                    message.error(response.message);
                }
            }
        
        )
    }
    

 const onVodCardClick = (lecture_id: string, event: any) => {
    event.preventDefault();
    if (!checkUserLogin()) {
      document.getElementById("show-login-modal")!.click();
    } else {
      window.open('lecture-vod?lecture_id=' + lecture_id);
    }
 }
  

     //点击了搜索按钮
     const onSearchBtn = (event: any) => {
      page.current = 1;
      queryContent.current = event.target.value;
      queryLectureList();
  }
  //页码和页数发生了变化
  const onPageChange = (newPage: number, newPageSize?: number | undefined) => {
        page.current = newPage ? newPage : 1;
        if (newPageSize) {
            pageSize.current = newPageSize;
        }
       queryLectureList();
  }

  /**
   * 视频点播卡片
   * @param props
   * @constructor
   */
  const VodCard: React.FC<Props> = (props: Props) => {
    const { lecture } = props

    return (
      <div className={selfCss.vodCardWrapper}>
        <Card
          className={selfCss.vodCard}
          bodyStyle={{padding:"10px 15px 10px 15px"}}
          hoverable={true}
          cover={
            <div className={selfCss.cardImageDiv}>
              <PlayCircleOutlined className={selfCss.imagePlayIcon}/>
              <img
                alt=""
                src={ lecture?.cover?.startsWith("http") ? lecture?.cover : constants.BACKEND_URL + lecture?.cover}
                height={160}
                width={250}
                style={{borderTopRightRadius: 6, borderTopLeftRadius: 6}}
              />
            </div>
          }
         
        >
          <Row justify='start'>
              <Col span={24} style={{textAlign: 'left'}}><div className={selfCss.vodTitle}>{lecture.title}</div></Col>
          </Row>
          <Row justify='end' style={{color:"#999"}} > 
            <Col ><EyeOutlined style={{marginRight:5}}/>   </Col> <Col>{lecture.view_cnt}</Col></Row>
          
        </Card>

      </div>
    )
  }

  return (

      <Layout style={{minHeight: 750,backgroundColor: '#f9f9f9'}}>
        
  
        <div className={selfCss.corouseWrapper}>

            
     

        <ConfigProvider locale={zhCN}>
            <Carousel  ref={(elem) => {
        myCarousel.current = elem

      }}  autoplay autoplaySpeed={5000}>

         
          {bannerList?.map(banner=>{

            return <div key={banner.id} className={selfCss.corousel} 
            > <div style={{height:350,background:`url(${ banner?.cover?.startsWith("http") ? banner?.cover : constants.BACKEND_URL + banner?.cover})`, backgroundRepeat:"repeat-x"}}>
               <Row justify="center" align="bottom" gutter={[50,0]} style={{marginLeft:0,marginBottom:0}}>

                  <Col flex="800px">
                    <div style={{textAlign:"left",marginTop:50,color:"#fff",fontSize:42}}>{banner?.title}</div>
                    <div style={{ borderRadius:10,marginTop:30,textAlign:"left",color:"#fff",fontSize:14}} > {banner?.teacher}<br/>{banner?.time_info}</div>
                  </Col>
                  </Row >
                </div>

            </div>
          })}

          </Carousel>

          </ConfigProvider>

          <div className={selfCss.corousePre}  ><LeftOutlined onClick={()=>{myCarousel.current?.prev()}}/></div>
          <div className={selfCss.corouseNext}><RightOutlined  onClick={()=>{myCarousel.current?.next()}}/></div>
        </div>

       

              <div className={selfCss.vodList}>

              
              <Row style={{marginBottom:20}} gutter={[24, 24]} wrap={true} justify="center">
                <Input
                  onPressEnter={onSearchBtn} 
                  placeholder="搜索讲座" 
                  style={{marginLeft:15,borderRadius:20,width:300 }} 
                  suffix={<SearchOutlined  style={{color:"#666666"}}/>}
                />
                </Row>
                <Row gutter={[24, 24]} wrap={true} justify="start">
                  {lectureListResult?.map((vod) => {
                    return (
                      <Col key={vod.id}>
                        <Link to={'/lecture-vod?lecture_id=' + vod.id} onClick={onVodCardClick.bind(this, vod.id)} target="_blank">
                          <VodCard className={selfCss.vodCard}
                            lecture={vod} />
                        </Link>
                      </Col>
                    )
                  })}
                </Row>
              </div>

            <ConfigProvider locale={zhCN}>

            <div className={selfCss.pagination} style={{marginTop:20,marginBottom:30}}>
              <Pagination
                defaultCurrent={1}
                current={page.current}
                total={resultSum}
                defaultPageSize={pageSize.current}
                hideOnSinglePage={true}
                onChange={onPageChange}
                showSizeChanger={false}       
              />
            </div>
            </ConfigProvider>
          <Feedback />
          
        </Layout>

  );

}

export default VodList


