import React, { useRef, useState } from 'react'
import DiscusItemStyle from './DiscussItem.module.less'
import {
  Button,
  Col,
  Image,
  Row,
  Space,
  Tag,
  message,
  ConfigProvider,
  Modal,
} from 'antd'
import { UpOutlined, DownOutlined } from '@ant-design/icons'
import '../../static/font/iconfont.css'
import MarkDownEditor from '../MarkDownEditor/MarkDownEditor'
import { BACKEND_API_URL, BACKEND_URL } from '../../constants/constants'
import { sendHttpPostRequest } from '../../api/http-request'
import zhCN from 'antd/lib/locale/zh_CN'
import Draggable from 'react-draggable'
import Cookies from 'js-cookie'

export interface DiscussItemProps {
  discuss: any
  discussions: any
  setDiscussions: any
  index: any
  editorStateInit: any
  theme?: string
  tagShow?: boolean
  knowledgeCellId: any
  courseId: any
  submitCallBack: any
  knowledgeName?:any
}

export const DiscussItem: React.FC<DiscussItemProps> = (props) => {
  const {
    discuss,
    discussions,
    setDiscussions,
    index,
    editorStateInit,
    theme,
    tagShow,
    knowledgeCellId,
    courseId,
    submitCallBack,
    knowledgeName
  } = props

  // console.log(discussions)
  const imagelist = [] as any[]
  //   {
  //     src: 'http://idkcbd.idatascience.cn/media/course_cover/591/399f0c41056f91b8db59980bb57a7cd.jpg',
  //   },
  //   { src: 'http://idkcbd.idatascience.cn/media/course_cover/162/导论.jpg' },
  // ]
  const [isExtend, setIsExtend] = useState(false)
  const [isreplyEditorVis, setisreplyEditorVis] = useState(false)
  const [myDiscussionReply, setMyDiscussionReply] = useState('') // 我回复讨论的内容
  const [uploadImageList, setUploadImageList] = useState([] as any[])
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const draggleRef = useRef<HTMLDivElement | null>(null)

  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
  const [disabled, setDisabled] = useState(true)

  const onStart = (event: any, uiData: any) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement
    const targetRect = draggleRef?.current?.getBoundingClientRect()
    if (targetRect) {
      setBounds({
        left: -targetRect?.left + uiData?.x,
        right: clientWidth - (targetRect?.right - uiData?.x),
        top: -targetRect?.top + uiData?.y,
        bottom: clientHeight - (targetRect?.bottom - uiData?.y),
      })
    }
  }

  let _knowledgeCellId = knowledgeCellId
  const refreshKnowId = () => {
    const _location = window.location.href.includes('?')
      ? window.location.href.split('?')[1].split('/')
      : null
    if (_location && _location.length >= 6) {
      _knowledgeCellId = parseInt(_location[5])
    }
  }

  const onLikeChange = () => {
    let newDiscuss = discuss
    if (discuss.is_my_like) {
      newDiscuss.likes -= 1
    } else {
      newDiscuss.likes += 1
    }
    newDiscuss.is_my_like = !newDiscuss.is_my_like
    discussions[index] = newDiscuss
    setDiscussions(JSON.parse(JSON.stringify(discussions)))
  }

  const uploadOption = {
    name: 'discussion_picture',
    actionUrl: 'course/api/discussion/upload_picture/',
    uploadData: { sessionid: Cookies.get('sessionid') },
    uploadImageList: uploadImageList,
    setUploadImageList: setUploadImageList,
  }

  /**
   * 点赞讨论
   */
  const praiseDiscussion = () => {
    refreshKnowId()
    if (discuss.praised) {
      discuss.praised_count -= 1
    } else {
      discuss.praised_count += 1
    }
    discuss.praised = !discuss.praised
    setDiscussions(JSON.parse(JSON.stringify(discussions)))
    sendHttpPostRequest(
      'course/api/discussion/praise_kc_discussion/',
      {
        sessionid: Cookies.get('sessionid'),
        discussion_id: discuss.id,
        know_id: _knowledgeCellId,
        course_id: courseId,
      },
      (response: any) => {
        if (response.code == -100) {
          // 未登录
          document.getElementById('show-login-modal')!.click()
        } else if (response.code == 0) {
          // 成功
          message.success(response.message)
        } else if (response.code == -501) {
          // 权限不足
        } else if (response.code == -509) {
          // 未阅读社区协议
        } else {
          message.error(response.message)
        }
      }
    )
  }

  /**
   * 删除讨论
   */
  const deleteDiscussion = () => {
    setDeleteModalVisible(true)
  }

  /**
   * 删除讨论确认
   */
  const deleteDiscussionConfirm = () => {
    setDeleteModalVisible(false)
    refreshKnowId()
    sendHttpPostRequest(
      'course/api/discussion/delete_kc_discussion/',
      {
        sessionid: Cookies.get('sessionid'),
        discussion_id: discuss.id,
        know_id: _knowledgeCellId,
        course_id: courseId,
      },
      (response: any) => {
        if (response.code == -100) {
          // 未登录
          document.getElementById('show-login-modal')!.click()
        } else if (response.code == 0) {
          // 成功
          message.success('删除讨论成功')
          submitCallBack(_knowledgeCellId)
        } else if (response.code == -501) {
          // 权限不足
        } else if (response.code == -509) {
          // 未阅读社区协议
        } else {
          message.error(response.message)
        }
      }
    )
  }

  /**
   * 发布讨论回复
   */
  const publishDiscussionReply = () => {
    refreshKnowId()
    sendHttpPostRequest(
      'course/api/discussion/publish_kc_discussion_reply/',
      {
        sessionid: Cookies.get('sessionid'),
        discussion_id: discuss.id,
        know_id: _knowledgeCellId,
        course_id: courseId,
        content: myDiscussionReply,
        upload_image_list: JSON.stringify(uploadImageList),
      },
      (response: any) => {
        if (response.code == -100) {
          // 未登录
          document.getElementById('show-login-modal')!.click()
        } else if (response.code == 0) {
          // 成功
          message.success('发表讨论回复成功')
          // setTimeout(function () {
          setMyDiscussionReply('')
          setUploadImageList([])
          submitCallBack(_knowledgeCellId)
          // },1000)
        } else if (response.code == -501) {
          // 权限不足
        } else if (response.code == -509) {
          // 未阅读社区协议
        } else {
          message.error(response.message)
        }
      }
    )
  }

  return (
    <div>
      <div className={DiscusItemStyle.disItemContain}>
        <div>
          <Row align="middle">
            <Col>
              <img
                className={DiscusItemStyle.avatar}
                src={BACKEND_API_URL + discuss.creator_avatar}
                alt=""
              />
            </Col>
            <Col
              className={
                DiscusItemStyle.name +
                ' ' +
                (theme ? DiscusItemStyle.darkname : '')
              }
            >
              {discuss.creator_name}
            </Col>
          </Row>
          <h1
            className={
              DiscusItemStyle.title +
              ' ' +
              (theme ? DiscusItemStyle.darktitle : '')
            }
          >
            {discuss.title}
          </h1>
          <div
            className={
              DiscusItemStyle.content +
              ' ' +
              (theme ? DiscusItemStyle.darkcontent : '')
            }
          >
            {discuss.content.length > 200 && !isExtend
              ? discuss.content.substring(0, 190) + '...'
              : discuss.content}
            {discuss.content.length > 200 && (
              <Button
                type="link"
                style={{
                  display: 'inline',
                  height: '20px',
                  padding: '0',
                  lineHeight: '20px',
                }}
                onClick={() => setIsExtend(!isExtend)}
              >
                {isExtend ? '收起' : '展开'}
                {isExtend ? <UpOutlined /> : <DownOutlined />}
              </Button>
            )}
          </div>
          <div className={DiscusItemStyle.commentImg}>
            <Image.PreviewGroup>
              {discuss?.image_list?.map((img) => (
                <Image
                  style={{ borderRadius: '6px' }}
                  width={157}
                  height={100}
                  key={img}
                  src={BACKEND_URL + img}
                ></Image>
              ))}
            </Image.PreviewGroup>
          </div>
          <Row align="middle" justify="space-between">
            <Col>
              {tagShow ? (
                <Tag color={'#eefff6'} className={DiscusItemStyle.tag}>
                  {knowledgeName}
                </Tag>
              ) : (
                ''
              )}
              <span
                className={
                  DiscusItemStyle.commentTime +
                  ' ' +
                  (theme ? DiscusItemStyle.darkcommentTime : '')
                }
              >
                {discuss.create_time}
              </span>
            </Col>
            <Col flex={'220px'}>
              <div className={DiscusItemStyle.actionContain}>
                <div
                  className={DiscusItemStyle.iconBtn}
                  // onClick={onLikeChange}
                  onClick={praiseDiscussion}
                >
                  {discuss?.praised ? (
                    <span
                      className={
                        'iconfont icon_dianzan' +
                        ' ' +
                        DiscusItemStyle.heighLightBtnTextColor
                      }
                    ></span>
                  ) : (
                    <span
                      className={
                        'iconfont icon_dianzan' +
                        ' ' +
                        DiscusItemStyle.grayBtnColor
                      }
                    ></span>
                  )}{' '}
                  {discuss?.praised_count <= 0 ? (
                    <span
                      className={
                        DiscusItemStyle.likesText +
                        ' ' +
                        (theme ? DiscusItemStyle.darkcommentTime : '')
                      }
                    >
                      赞
                    </span>
                  ) : (
                    <span
                      className={
                        DiscusItemStyle.likesText +
                        ' ' +
                        (theme ? DiscusItemStyle.darkcommentTime : '')
                      }
                    >
                      {discuss?.praised_count}
                    </span>
                  )}
                </div>
                <div
                  className={DiscusItemStyle.iconBtn}
                  onClick={() => {
                    editorStateInit()
                    let newDis = discuss
                    newDis.replyVist = true
                    discussions[index] = newDis
                    setDiscussions(JSON.parse(JSON.stringify(discussions)))
                  }}
                >
                  <span
                    className={
                      'iconfont icon_huifu' +
                      ' ' +
                      DiscusItemStyle.grayBtnTextColor
                    }
                  ></span>{' '}
                  <span className={DiscusItemStyle.grayBtnTextColor}>回复</span>
                  {discuss?.reply_count > 0 && (
                    <span className={DiscusItemStyle.grayBtnTextColor}>
                      ({discuss?.reply_count})
                    </span>
                  )}
                </div>
                {discuss?.is_my && (
                  <div
                    className={DiscusItemStyle.iconBtn}
                    onClick={deleteDiscussion}
                  >
                    <span
                      className={
                        'iconfont icon_shanchu' +
                        ' ' +
                        DiscusItemStyle.grayBtnTextColor
                      }
                    ></span>
                    <span className={DiscusItemStyle.grayBtnTextColor}>
                      删除
                    </span>
                  </div>
                )}
              </div>
            </Col>
          </Row>
          {discuss.replyVist && (
            <div
              className={
                DiscusItemStyle.editorContainer +
                ' ' +
                (theme ? DiscusItemStyle.darkEditorContainer : '')
              }
            >
              <MarkDownEditor
                placeholder={`回复${discuss?.creator_name}:`}
                onChange={({ html, text }) => {
                  setMyDiscussionReply(text)
                }}
                toolbar={[
                  // 'header',
                  // 'font-bold',
                  // 'emoji',
                  // 'font-italic',
                  // 'font-underline',
                  // 'font-strikethrough',
                  // 'list-unordered',
                  // 'list-ordered',
                  // 'block-quote',
                  // 'block-wrap',
                  // 'block-code-inline',
                  // 'block-code-block',
                  // 'table',
                  // 'image',
                  'img-upload',
                  // 'link',
                  // 'clear',
                  // 'mode-toggle',
                  // 'full-screen',
                  // 'tab-insert',
                ]}
                uploadOption={uploadOption}
              />
              <Button
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  publishDiscussionReply()
                }}
                className={DiscusItemStyle.submitBtn}
                type="primary"
              >
                发送
              </Button>
            </div>
          )}
        </div>
      </div>

      <ConfigProvider locale={zhCN}>
        <Modal
          title={
            <div
              style={{
                width: '100%',
                cursor: 'move',
              }}
              onMouseOver={() => {
                if (disabled) {
                  setDisabled(false)
                }
              }}
              onMouseOut={() => {
                setDisabled(true)
              }}
              onFocus={() => {}}
              onBlur={() => {}}
              // end
            >
              提示
            </div>
          }
          visible={deleteModalVisible}
          onOk={deleteDiscussionConfirm}
          onCancel={() => {
            setDeleteModalVisible(false)
          }}
          bodyStyle={{ height: '120px' }}
          destroyOnClose={true}
          width={350}
          modalRender={(modal) => (
            <div className={DiscusItemStyle.miniModal}>
              <Draggable
                disabled={disabled}
                bounds={bounds}
                onStart={(event, uiData) => onStart(event, uiData)}
              >
                <div ref={draggleRef}>{modal}</div>
              </Draggable>{' '}
            </div>
          )}
        >
          <div>确认删除讨论吗？</div>
        </Modal>
      </ConfigProvider>
    </div>
  )
}

export default DiscussItem
