import React, { useEffect, useRef, useState } from 'react'
import { Upload, Modal, Button, message, Row, Col } from 'antd'
import ModalDrag from '@/component/ModaDrag/ModalDrag'
import GlobalCss from '@/global.module.less'
import styles from './dataset-upload-zip-modal.module.less'
import Cookies from 'js-cookie'
import QS from 'qs'
import axios from 'axios'
import * as constants from '@/constants/constants'
import { CheckCircleFilled } from '@ant-design/icons'
import SparkMD5 from 'spark-md5'
import { sendHttpPostRequest } from 'api/http-request'

const { Dragger } = Upload

const DatasetUploadZipModal = (props: any) => {
  const {
    actionCallback,
    open,
    setOpen,
    uploadType,
    uploadDataProps,
    apiUrl,
    moduleType,
  } = props
  const SIZE = 1024 * 1024 * 5

  const uploadData = Object.assign(uploadDataProps, {
    sessionid: Cookies.get('sessionid'),
    sid: Cookies.get('sessionid'),
  })

  const [fileList, setFileList] = useState<any>([])
  const [uploadStatus, setUploadStatus] = useState('')
  const [isUploading, setIsUploading] = useState(false)
  const [uploadPercent, setUploadPercent] = useState(0)
  const [uploadName, setUploadName] = useState('')
  const [makeSureModalVis, setMakeSureModalVis] = useState(false)
  const [chunksState, setChunksState] = useState<any[]>([])
  const [mergeChunkPathList, setMergeChunkPathList] = useState<any>([])
  const [uploadedPercent, setUploadedPercent] = useState(0)
  // const [resumeStatus, setResumeStatus] = useState(0)
  // const [
  //   alreadyUploadChunkIndexList,
  //   setAlreadyUploadChunkIndexList,
  // ] = useState<any>([0, 2, 3])
  const resumeStatus = useRef(0)

  const alreadyUploadChunkIndexList = useRef<any>([])

  const [md5Code, setMd5Code] = useState<any>('')
  const handleCancel = () => {
    if (fileList?.some((f) => f.status == 'uploading')) {
      setMakeSureModalVis(true)
    } else {
      setFileList([])
      setOpen(false)
      reIndex.current = 0
      result.current = []
      setIsUploading(false)
      actionCallback()
    }
  }
  useEffect(() => {
    return () => {
      setFileList([])
    }
  }, [])

  const reIndex = useRef(0)
  let result = useRef<any>([])

  // 并发请求函数
  const concurrencyRequest = (urls, maxNum) => {
    return new Promise((resolve) => {
      if (urls.length === 0) {
        resolve([])
        return
      }
      const results = []
      let index = 0 // 下一个请求的下标
      let count = 0 // 当前请求完成的数量

      // 发送请求
      async function request() {
        if (index === urls.length) return
        const i = index // 保存序号，使result和urls相对应
        const url = urls[index]
        index++
        console.log(url)
        try {
          const resp = await fetch(
            constants.BACKEND_API_URL + 'course/api/oam_upload_video_chunk',
            {
              method: 'POST',
              body: url,
            }
          )
            .then((res: any) => res.json())
            .then((data) => {
              setUploadedPercent(
                Math.floor(
                  (data.data.uploaded_count / chunksState.length) * 100
                )
              )
              return data
            })
          // resp 加入到results
          results[i] = resp
        } catch (err) {
          // err 加入到results
          results[i] = err
        } finally {
          count++
          // 判断是否所有的请求都已完成
          if (count === urls.length) {
            console.log('完成了')
            resolve(results)
          }
          request()
        }
      }

      // maxNum和urls.length取最小进行调用
      const times = Math.min(maxNum, urls.length)
      for (let i = 0; i < times; i++) {
        request()
      }
    })
  }

  const handleUploadRequest = (info?: any) => {
    console.log('开始上传')
    setIsUploading(true)
    setUploadStatus('uploading')
    const list: any[] = []
    for (let i = 0; i < chunksState.length; i++) {
      if (
        resumeStatus.current == 2 &&
        alreadyUploadChunkIndexList.current.length > 0 &&
        alreadyUploadChunkIndexList?.current?.includes(i)
      ) {
        continue
      }
      const formData: any = new FormData()
      formData.append('sessionid', Cookies.get('sessionid'))
      formData.append('chunk_num', i)
      formData.append('video_chunk', chunksState[i])
      formData.append('chunk_count', chunksState.length)
      formData.append('knowledgecell_id', uploadData.knowledgecell_id)
      formData.append('video_md5', md5Code)
      list.push(formData)

      //   list.push(
      //     fetch(constants.BACKEND_API_URL + 'dataset/api/oam_upload_zip_chunk/', {
      //       method: 'POST',
      //       body: formData,
      //     })
      //       .then((res: any) => res.json())
      //       .then((data) => {
      //         setUploadedCount(uploadedCount + 1)
      //         return data
      //       })
      //   )
      // }

      // Promise.all(list)
      //   .then((res) => {
      //     // let chunksMergeList = res.map((item) => item.data.chunk_path)
      //     const formData: any = new FormData()
      //     formData.append('sessionid', Cookies.get('sessionid'))
      //     formData.append('filename', uploadName)
      //     formData.append('dataset_id', uploadData.dataset_id)
      //     formData.append('zip_md5', md5Code)

      //     // formData.append('chunks', JSON.stringify(chunksMergeList))

      //     try {
      //       fetch(
      //         constants.BACKEND_API_URL + 'dataset/api/oam_merge_zip_chunk/',
      //         {
      //           method: 'POST',
      //           body: formData,
      //         }
      //       )
      //         .then((res) => res.json())
      //         .then((data) => {
      //           if (data.code == 0) {
      //             message.success(data.msg)
      //             setUploadStatus('done')
      //           } else {
      //             setUploadStatus('error')
      //           }
      //         })
      //     } catch (error) {
      //       setUploadStatus('error')
      //       message.error(error + '合并文件出错')
      //     }
      //   })
      //   .catch((err) => {
      //     console.error('上传失败', err)
      //     setUploadStatus('error')
      //   })
    }
    concurrencyRequest(list, 5).then((results: any[]) => {
      if (
        results.length == list.length &&
        results.every((item) => item.code == 0)
      ) {
        const formData: any = new FormData()
        formData.append('sessionid', Cookies.get('sessionid'))
        formData.append('filename', uploadName)
        formData.append('knowledgecell_id', uploadData.knowledgecell_id)
        formData.append('video_md5', md5Code)

        // formData.append('chunks', JSON.stringify(chunksMergeList))

        try {
          fetch(
            constants.BACKEND_API_URL + 'course/api/oam_merge_video_chunk',
            {
              method: 'POST',
              body: formData,
            }
          )
            .then((res) => res.json())
            .then((data) => {
              if (data.code == 0) {
                message.success(data.msg)
                setUploadStatus('done')
              } else {
                setUploadStatus('error')
              }
            })
        } catch (error) {
          setUploadStatus('error')
          message.error(error + '合并文件出错')
        }
      }
    })
  }
  const makeChunk = (file, size = SIZE) => {
    const chunks = []
    for (let i = 0; i < file.size; i += size) {
      const chunk = file.slice(i, i + size)
      chunks.push(chunk)
    }
    return chunks
  }

  const uploadProps: any = {
    name: uploadType,
    multiple: true,
    accept: moduleType == 1 ? '.pdf' : '.mp4,.mov',
    showUploadList: false,
    customRequest: handleUploadRequest,

    beforeUpload: (file, FileList) => {
      const spark = new SparkMD5.ArrayBuffer()
      spark.append(file)
      const md5code = spark.end()

      setMd5Code(md5code)

      let data = {
        video_md5: md5code,
        knowledgecell_id: uploadData.knowledgecell_id,
      }
      setUploadName(file.name)

      setChunksState(makeChunk(file))

      setFileList([
        ...FileList.map((f) =>
          Object.assign(f, { status: 'waiting', percent: 0 })
        ),
      ])
      return new Promise((resolve, reject) => {
        sendHttpPostRequest(
          'course/api/oam_resume_upload',
          data,
          (res: any) => {
            if (res.code == 0) {
              setUploadedPercent(
                Math.floor(
                  (res.data.uploaded_count / makeChunk(file).length) * 100
                )
              )
              if (res.data.uploaded_status == 1) {
                message.error(res.msg)
                reject()
              } else if (res.data.uploaded_status == 2) {
                resolve(res.data.uploaded_status)
                resumeStatus.current = res.data.uploaded_status
                // setAlreadyUploadChunkIndexList(res.uploaded_chunk)
                alreadyUploadChunkIndexList.current = res.data.uploaded_chunk
              } else {
                // setResumeStatus(res.data.uploaded_status)
                resumeStatus.current = res.data.uploaded_status
                resolve(res.data.uploaded_status)
              }
            } else {
              reject()
            }
          }
        )
      })
    },
    action: constants.BACKEND_API_URL + apiUrl,
    data: uploadData,
  }

  const handleDelete = (f, i) => {
    let data = {
      path_id: f.path_id,
    }
    sendHttpPostRequest(
      'dataset/api/oam_remove_dataset_file/',
      Object.assign(data, {
        knowledgecell_id: uploadDataProps?.knowledgecell_id,
      }),
      (res) => {
        if (res.code == 0) {
          message.success('删除成功')
          Object.assign(f, { status: 'deleted' })
          setFileList([...fileList])
        } else {
          message.error(res.message)
        }
      }
    )
  }

  const uploadModalContent = (
    <div className={styles.modalContain}>
      <div
        // style={uploadStatus == 'done' ? { height: '285px' } : {}}
        className={styles.uploadContain}
      >
        <div
          // style={uploadStatus == 'done' ? { height: '285px' } : {}}
          className={styles.progressContain}
          hidden={!isUploading}
        >
          <Row
            align={'middle'}
            justify={'space-between'}
            style={{ height: '40px', borderBottom: '1px solid #eee' }}
          >
            <Col flex={'300px'}>文件名</Col>
            <Col flex={'100px'} style={{ textAlign: 'center' }}>
              状态
            </Col>
            {/* <Col flex={'50px'}>操作</Col> */}
          </Row>
          <div className={styles.progress}>
            {fileList
              ?.filter((item) => item.status !== 'deleted')
              .map((f, i) => (
                <Row
                  key={f.uid}
                  align={'middle'}
                  justify={'space-between'}
                  style={{ height: '40px', width: '592px' }}
                >
                  <Col flex={'350px'} className={GlobalCss.oneLineTextOverflow}>
                    {f.name}
                    {/* {'  '} {uploadPercent != 100 && uploadPercent + '%'} */}
                  </Col>
                  <Col flex={'100px'} className={GlobalCss.ColFlex}>
                    <div
                      className={
                        uploadStatus == 'done'
                          ? styles.uploadComplete
                          : uploadStatus == 'waiting'
                          ? styles.uploadWaiting
                          : uploadStatus == 'uploading'
                          ? styles.uploading
                          : uploadStatus == 'error'
                          ? styles.uploadError
                          : ''
                      }
                    ></div>
                    <span>
                      {uploadStatus == 'uploading' ? (
                        <span>
                          <span
                            style={{ fontSize: '16px', color: '#4285F4' }}
                            className="iconfont icon-zhengzaitingzhi"
                          ></span>{' '}
                          {'上传' + uploadedPercent + '%'}
                        </span>
                      ) : uploadStatus == 'waiting' ? (
                        '等待中'
                      ) : uploadStatus == 'done' ? (
                        '上传成功'
                      ) : (
                        '上传失败'
                      )}
                    </span>
                  </Col>
                  {/* <Col flex={'50px'} className={GlobalCss.ColFlex}>
                    {uploadStatus !== 'uploading' && (
                      <div
                        id="deleteModalBtn"
                        className={GlobalCss.btnBox + ' ' + styles.deleIcon}
                        onClick={() => handleDelete(f, i)}
                      >
                        <span className={'iconfont icon-shanchu'}></span>
                      </div>
                    )}
                  </Col> */}
                </Row>
              ))}
          </div>
        </div>

        <div hidden={isUploading} style={{ height: '400px' }}>
          <Dragger {...uploadProps}>
            <Button className={GlobalCss.pribtn} type="primary">
              上传
            </Button>
            <h2>将文件拖到此处，或点击上传</h2>
            <span className={styles.tip}>
              {/* 压缩包仅支持zip文件，上传后将为您自动解压 */}
              {moduleType != 1 ? '请上传视频格式文件' : '请上传Pdf文件'}
            </span>
          </Dragger>
        </div>
      </div>
    </div>
  )
  return (
    <div>
      {/* <Button onClick={showModal}>上传文件</Button> */}
      <ModalDrag
        width={720}
        style={{ minHeight: '500px' }}
        bodyStyle={{ minHeight: '400px' }}
        open={open}
        footer={null}
        modalTitle={
          <Row align={'middle'} style={{ gap: '5px' }}>
            <Col className={GlobalCss.modalTitle}>
              <span>上传</span>
            </Col>
            <Col>
              {fileList.length > 0 ? (
                fileList?.every((f) => f.status == 'done') ? (
                  <CheckCircleFilled
                    style={{ fontSize: '15px', color: '#4BB04F' }}
                  />
                ) : (
                  <span
                    style={
                      fileList?.some((f) => f.status == 'uploading')
                        ? {
                            fontSize: '15px',
                            color: '#4285F4',
                          }
                        : {
                            fontSize: '15px',
                            color: '#F24957',
                          }
                    }
                    className={
                      'iconfont ' +
                      (fileList?.some((f) => f.status == 'uploading')
                        ? 'icon-zhengzaitingzhi'
                        : fileList?.some((f) => f.status == 'error')
                        ? 'icon-tingzhi'
                        : '')
                    }
                  ></span>
                )
              ) : (
                ''
              )}
            </Col>
          </Row>
        }
        maskClosable={false}
        destroyOnClose={true}
        // onOk={handleOk}
        onCancel={handleCancel}
        modalContent={uploadModalContent}
      />
      <ModalDrag
        width={460}
        modalContent={
          <div
            style={{
              margin: '0 auto',
              textAlign: 'center',
              lineHeight: '100px',
            }}
          >
            关闭弹窗后正在运行的任务将会终止，确认关闭？
          </div>
        }
        open={makeSureModalVis}
        centered
        bodyStyle={{ minHeight: '100px' }}
        onCancel={() => setMakeSureModalVis(false)}
        onOk={() => {
          setMakeSureModalVis(false)
          setFileList([])
          setOpen(false)
          reIndex.current = 0
          result.current = []
          setIsUploading(false)
          actionCallback()
          message.success('已停止上传')
          // document.getElementById('deleteModalBtn')?.click()
        }}
        modalTitle={<span className={GlobalCss.modalTitle}>提示</span>}
      />
    </div>
  )
}

export default DatasetUploadZipModal
