import {
  Modal,
  ConfigProvider,
  Layout,
  message,
  Card,
  Col,
  Row,
  Tooltip,
  Pagination,
} from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import zhCN from 'antd/lib/locale/zh_CN'
import axios from 'axios'
import Qs from 'qs'
import selfCss from './vod-list.module.less'
import Cookies from 'js-cookie'
//import $ from 'jquery'
import lectureLogoSvg from '../../static/image/boya_logo_white.svg'
import './vod-list.module.css'
import { LogoutOutlined } from '@ant-design/icons'
import { RouteComponentProps } from 'react-router'

const { Content } = Layout

import * as constants from '../../constants/constants'

import { sendHttpPostRequest } from '../../api/http-request'

// 通过路由组件额外添加参数信息到表中
interface Props extends RouteComponentProps<{ lecture_id: string }> {
  lecture_id: string
}
/**
 * VodPlay
 * 视频点播播放
 */
export const VodPlay: React.FC<Props> = (props: Props) => {
  // const vodId = "8b18c3c04f6e41319049de9f40da72c5"
  const lectureId = props.location.search.split('=')[1]

  const [lecture, setLecture] = useState(null as any)

  useEffect(() => {
    queryLectureInfo()
    increaseLectureViewCount()
  }, [])

  /**
   * 查询讲座信息
   */
  const queryLectureInfo = () => {
    sendHttpPostRequest(
      'video/api/user_get_lecture_by_id/',
      {
        sessionid: Cookies.get('sessionid'),
        lecture_id: lectureId,
      },
      (response: any) => {
        message.destroy()
        if (response.code == -100) {
          // 未登录
          window.location.href = '/login?next=lecture'
        } else if (response.is_ok == true) {
          // 成功
          setLecture(response.query_info)
        } else if (response.code == -501) {
          // 权限不足
          window.location.href = '/login?next=lecture'
        } else {
          message.error(response.message)
        }
      }
    )
  }

  /**
   * 增加讲座预览数量
   */
  const increaseLectureViewCount = () => {
    sendHttpPostRequest(
      'video/api/increase_lecture_view_count/',
      {
        sessionid: Cookies.get('sessionid'),
        lecture_id: lectureId,
      },
      (response: any) => {
        // console.log(response)
        message.destroy()
        if (response.code == -100) {
          // 未登录
          //window.location.href = '/login?next=lecture';
        } else if (response.is_ok == true) {
        } else if (response.code == -501) {
          // 权限不足
          //window.location.href = '/login?next=lecture';
        } else {
          //message.error(response.message);
        }
      }
    )
  }

  return (
    <div style={{ overflowX: 'hidden', height: '100vh' }}>
      {/* <div style={{ height: 60, background: '#343434' }}> */}
      <Row
        justify="start"
        align="middle"
        gutter={[4, 4]}
        style={{ height: 60, background: '#343434' }}
      >
        <Col flex="100px">
          <img src={lectureLogoSvg} className={selfCss.logo}></img>
        </Col>
        <Col flex="auto">
          <div style={{ fontWeight: 600, color: '#f9f9f9' }}>
            {lecture?.title}{' '}
          </div>
        </Col>
        <Col flex="100px"> </Col>
      </Row>
      {/* </div> */}

      <div style={{ height: 'calc(100vh - 100px)', background: '#000000' }}>
        <div>
          {lecture?.video_path !== '' && (
            <video
              style={{
                marginTop: 25,
                marginBottom: 25,
                height: 'calc(100vh - 150px)',
                width: '90%',
              }}
              preload="preload"
              controls={true}
              controlsList="nodownload"
              src={
                // lecture?.video_url?.startsWith('http')
                //   ? lecture?.video_url
                //   : constants.BACKEND_URL + lecture?.video_url
                constants.BACKEND_URL +
                '/course/api/get_stream_video?path=' +
                lecture?.video_url
              }
            >
              <source
                // src= { lecture?.video_path?.startsWith("http") ? lecture?.video_path : (constants.BACKEND_URL + lecture?.video_path)}
                type={'video/mp4'}
              />
              您的浏览器不支持 HTML5 video 标签。
            </video>
          )}
        </div>
      </div>

      <div style={{ height: 50, background: '#343434' }}></div>
    </div>
  )
}

export default VodPlay
