import React, { useRef, useState } from 'react'
import courseLearnStyles from '../../pages/course/course-learn.module.less'
import { Col, Image as AntdImage, message, Row, Tooltip } from 'antd'
import * as constants from '../../constants/constants'
import { secTotime } from '../../utils/timeUtils'
import MarkDownEditor, { mdParser } from '../MarkDownEditor/MarkDownEditor'
import { sendHttpPostRequest } from '../../api/http-request'
import Cookies from 'js-cookie'
import katex from 'katex'
import MarkdownItKatex from '@iktakahiro/markdown-it-katex'
import CommonModal from '../modal/CommonModal/CommonModal'

interface NoteItemProps {
  note: any
  nIdx: number
  noteList: any
  setNoteList: any
  courseId: any
  knowId: any
  jumpCurrentTime: any
  actionCallback: any
  type: any
  noHide?: boolean
}
const emoji = require('markdown-it-emoji')
export const NoteItem: React.FC<NoteItemProps> = (props) => {
  const {
    note,
    nIdx,
    noteList,
    setNoteList,
    courseId,
    knowId,
    jumpCurrentTime,
    actionCallback,
    type,
    noHide,
  } = props
  mdParser.use(MarkdownItKatex, { katex })
  mdParser.use(emoji)
  const [editorVis, setEditorVis] = useState(note.is_editor)
  const [isEdit, setIsEdit] = useState(false)
  const [deleteNoteModalVis, setDeleteNoteModalVis] = useState(false)

  const onNoteSave = () => {
    if (!noteList[nIdx].content) {
      message.error('笔记内容不能为空')
      return
    }
    if (!isEdit) {
      sendHttpPostRequest(
        'course/api/study_note/create_note/',
        {
          sessionid: Cookies.get('sessionid'),
          sid: Cookies.get('sessionid'),
          course_id: courseId,
          know_id: knowId,
          hash_id: note.hash_id,
          content: note.content,
          // image_id:note.image_id
        },
        (res) => {
          if (res.is_ok) {
            actionCallback(knowId)
            message.success('笔记保存成功！')
          } else {
            message.error(res.message)
          }
        }
      )
    } else {
      sendHttpPostRequest(
        'course/api/study_note/update_note/',
        {
          sessionid: Cookies.get('sessionid'),
          sid: Cookies.get('sessionid'),
          course_id: courseId,
          know_id: knowId,
          hash_id: note.hash_id,
          content: note.content,
          note_id: note.id,
          // image_id:note.image_id
        },
        (res) => {
          if (res.is_ok) {
            message.success('笔记更新成功！')
          } else {
            message.error(res.message)
          }
        }
      )
    }
    setEditorVis(false)
  }

  const onNoteContentChange = (indx, { text }) => {
    noteList[indx].content = text
    setNoteList([...noteList])
  }

  const deleteNoteOk = () => {
    sendHttpPostRequest(
      'course/api/study_note/delete_note/',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        course_id: courseId,
        know_id: knowId,
        hash_id: note.hash_id,
        content: note.content,
        note_id: note.id,
        // image_id:note.image_id
      },
      (res) => {
        if (res.is_ok) {
          message.success('笔记删除成功！')
          actionCallback(knowId)
        } else {
          message.error(res.message)
        }
      }
    )
    setDeleteNoteModalVis(false)
  }

  return (
    <div key={note.id} className={courseLearnStyles.noteContain}>
      <Row align={'middle'} justify={'start'} style={{ marginBottom: '13px' }}>
        <Col className={courseLearnStyles.darkText} flex={'40px'}>
          {nIdx + 1}
        </Col>
        <Col
          className={
            courseLearnStyles.heighLightText + ' ' + courseLearnStyles.height
          }
          onClick={jumpCurrentTime.bind(this, note.position)}
          style={{ cursor: 'pointer' }}
        >
          <span className={'iconfont icon_shipindingwei'}></span>{' '}
          <span style={{ margin: '0px 5px' }}>
            {type == 2 ? '视频片段' : 'PDF页码'}
          </span>
          <span>
            {type == 2 ? secTotime(note.position) : note.position + 1}
          </span>
        </Col>
      </Row>
      <div className={courseLearnStyles.noteImgContain}>
        <AntdImage
          src={constants.BACKEND_URL + note.image_src}
          width={156}
          height={100}
        />
      </div>
      {!editorVis && (
        <div>
          <div className={courseLearnStyles.noteItemContain}>
            <div
              style={
                noHide
                  ? {}
                  : {
                      WebkitLineClamp: 3,
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }
              }
              className={courseLearnStyles.noteContent}
              dangerouslySetInnerHTML={{
                __html: mdParser.render(note.content),
              }}
            ></div>
          </div>

          <Row
            className={courseLearnStyles.timeActionRow}
            align="middle"
            justify={'space-between'}
          >
            <Col className={courseLearnStyles.time}>{note.create_time}</Col>
            <Col className={courseLearnStyles.actionContain}>
              <div
                className={courseLearnStyles.actionBtn}
                onClick={() => {
                  setEditorVis(!editorVis)
                  setIsEdit(true)
                }}
              >
                <span className={'iconfont icon_bianji'}></span>编辑
              </div>
              <div
                className={courseLearnStyles.actionBtn}
                style={{ marginLeft: '30px' }}
                onClick={() => setDeleteNoteModalVis(true)}
              >
                <span className={'iconfont icon_shanchu'}></span>删除
              </div>
            </Col>
          </Row>
        </div>
      )}
      {editorVis && (
        <div className={courseLearnStyles.editorContainer}>
          <Tooltip title={'保存'}>
            <div className={courseLearnStyles.saveBtn} onClick={onNoteSave}>
              <span className="iconfont icon_baocun"></span>
            </div>
          </Tooltip>
          <MarkDownEditor
            placeholder={`请输入笔记内容`}
            onChange={onNoteContentChange.bind(this, nIdx)}
            toolbar={[
              'font-bold',
              'font-italic',
              'header',
              'block-quote',
              'table',
              'list-unordered',
              'list-ordered',
            ]}
            value={note.content}
          />
        </div>
      )}
      <CommonModal
        onOk={deleteNoteOk}
        onCancel={() => setDeleteNoteModalVis(false)}
        width={350}
        modalContent={<div>确认删除这条笔记吗？</div>}
        open={deleteNoteModalVis}
        modalTitle={'提示'}
      />
    </div>
  )
}

export default NoteItem
