import { Col, Layout, Row } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

import scienceLogo from '../../../static/image/scienceLogo.png'
import codingLogo from '../../../static/image/codingLogo.png'
import modelCubeLogo from '../../../static/image/modelCubeLogo.png'
const { Footer } = Layout
interface Props {}

export const PageFooter: React.FC<Props> = (props: Props) => {
  const ENV = process.env
  const scienceBool = ENV.REACT_APP_SCIENCE === 'true'
  const codingBool = ENV.REACT_APP_CODING === 'true'
  const modelCubeBool = ENV.REACT_APP_MODELCUBE === 'true'
  const numBool = ENV.REACT_APP_FOOTER_NUM === 'true'
  return (
    <Footer
      style={{
        paddingTop: 16,
        paddingBottom: 16,
        backgroundColor: '#f2f2f2',
        width: '100%',
        color: '#acacac',
        textAlign: 'center',
        bottom: 0,
      }}
    >
      <Row style={{ width: 1000, margin: '0 auto' }} wrap={false}>
        {(scienceBool || codingBool|| modelCubeBool) && (
          <Col
            span={12}
            style={{ borderRight: '1px solid rgb(240, 235, 235)' }}
          >
            <Row
              justify="center"
              align="middle"
              wrap={false}
              style={{ height: 60,gap:"30px" }}
            >
              {scienceBool && (
                <Col  style={{ paddingLeft: 40 }}>
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      window.open(ENV.REACT_APP_SCIENCE_URL)
                    }}
                  >
                    <img
                      title="爱数科"
                      alt="logo"
                      src={scienceLogo}
                      width={120}
                      height={30}
                    />
                    {/* <span style={{color:'#666',marginLeft:20,cursor:'default'}}>http://idatascience.cn/</span> */}
                  </div>
                </Col>
              )}
              {codingBool && (
                <Col >
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      window.open(ENV.REACT_APP_CODING_URL)
                    }}
                  >
                    <img
                      title="数据实验楼"
                      alt="logo"
                      src={codingLogo}
                      width={120}
                      height={30}
                    />
                    {/* <span style={{color:'#666',marginLeft:20,cursor:'default'}}>http://idatacoding.cn/</span> */}
                  </div>
                </Col>
              )}
              {modelCubeBool && (
                <Col >
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      window.open(ENV.REACT_APP_MODELCUBE_URL)
                    }}
                  >
                    <img
                      title="modelcube"
                      alt="logo"
                      src={modelCubeLogo}
                      width={120}
                      height={30}
                    />
                    {/* <span style={{color:'#666',marginLeft:20,cursor:'default'}}>http://idatacoding.cn/</span> */}
                  </div>
                </Col>
              )}
            </Row>
          </Col>
        )}
        {numBool && (
          <Col span={12}>
            <div
              style={{
                height: 60,
                lineHeight: '60px',
                margin: '0 auto',
                marginLeft: -10,
                cursor: 'pointer',
              }}
            >
              <span style={{ fontFamily: 'Arial' }}>©</span> 2021
              爱数课（iDataCourse）{' '}
              <a style={{ color: '#acacac' }} href="https://beian.miit.gov.cn/">
                {' '}
                京ICP备2021001687号-3{' '}
              </a>
            </div>
          </Col>
        )}
      </Row>
    </Footer>
  )
}

export default PageFooter
