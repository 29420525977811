import React, {useEffect, useRef, useState} from "react";
import {
    Input,
    Divider,
    Table,
    Switch,
    Layout,
    message,
    Card,
    Col,
    Row,
    ConfigProvider,
    Pagination,
    Tabs,
    Button,
    Tag,
    Popconfirm,
    Modal
} from 'antd';
import { RightCircleFilled, LeftCircleFilled } from '@ant-design/icons';
import { sendHttpPostRequest } from "../../../../api/http-request";
import zhCN from 'antd/lib/locale/zh_CN'
import * as constants from "../../../../constants/constants"
import LoginRegisterModal from "../../../../component/modal/login-register/login-register";
import { checkUserLogin } from "../../../common/user-center/user-info";
import 'echarts-wordcloud';

import * as echarts from 'echarts/core';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import Cookies from "js-cookie";
import exerciseDetail from "./index.module.less"
import { useHistory } from "react-router-dom";
echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer,
  LabelLayout
]);

interface Props {

}
interface Props2{

}

/** 教师统计 作业概况作业详情页面 */
export const CourseCommonDetail: React.FC<Props> = (props) => {
  // 假数据   
    const commonDetailDatas = [
      {
        id: 1,
        name:"胡小小",
        stuNum: "2018742052",
        right_percent: 90,
        score:80,
        percent_score:80,
      },
      {
        id: 2,
        name:"胡小小",
        stuNum: "2018742052",
        right_percent: 90,
        score:80,
        percent_score:80,
      },
      {
        id: 3,
        name:"胡小小",
        stuNum: "2018742052",
        right_percent: 90,
        score:80,
        percent_score:80,
      },
      {
        id: 4,
        name:"胡小小",
        stuNum: "2018742052",
        right_percent: 90,
        score:80,
        percent_score:80,
      }
    ]
    const pathName = useRef(window.location.pathname)
    const exercisePath = '/course-main-teacher-exercise-detail'
    const examPath = '/course-main-teacher-exam-detail'
    const courseId = parseInt(window.location.href.split("&")[0].split("=")[1]);

    const [countBox, setCountBox] = useState([{id:1,label:"人数（已提交/全部）"}, {id:2,label:"完成率"}, {id:3, label:"正确率"}, {id:4, label:"平均分"}])
    const [headerInfo, setHeaderInfo] = useState({title:"第一章课后作业", last_time:"2022-08-11 16:09", completed:90, total:100, completed_percent:75, right_percent:65, avg_score:75 })

    const [commonDetailData, setCommonDetailData] = useState(commonDetailDatas)
    const typeCountMap = new Map([['实操题', 0], ['单选题', 0], ['多选题', 0],['排序题',0],['主观题', 0]]);
    const difficultyCountMap = new Map([['容易', 0], ['中等', 0], ['困难', 0]]);
    let history = useHistory();
    const { Search } = Input;
   
    const commonDetailCols :any= [
      {
        title: "序号",
        dataIndex: "id",
        key: "id",
        width: '10%',
        ellipsis: false,
        align: 'center',
        render:(text,record,index)=>(commonDetailData.indexOf(record) + 1)
      },
      {
        title: "姓名",
        dataIndex: "name",
        key: "name",
        width: '10%',
        ellipsis: false,
        align: 'center',
      },
      {
        title: "学号",
        dataIndex: "stuNum",
        key: "stuNum",
        width: '10%',
        ellipsis: false,
        align: 'center',
      },
      {
        title: "正确率",
        dataIndex: "right_percent",
        key: "right_percent",
        width: '10%',
        ellipsis: false,
        align: 'center',
        sorter:(a,b)=>a.right_percent - b.right_percent,
      },
      {
        title: "成绩",
        dataIndex: "score",
        key: "score",
        width: '10%',
        ellipsis: false,
        align: 'center',
        sorter:(a,b)=>a.score - b.score,
      },
      {
        title: "百分制成绩",
        dataIndex: "percent_score",
        key: "percent_score",
        width: '10%',
        ellipsis: false,
        align: 'center',
        sorter:(a,b)=>a.percent_score - b.percent_score,
      },
    ]
    
    const ExamExerciseStatDifficultPanel : React.FC<Props2> = (props)=>{
      const difficult_option = {
        title: {
          text: '试题难度分布',
          textStyle:{fontSize:14},
          left: 'center',
          top:'bottom'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 20,
          bottom:40
        },
        series: [
          {
            name: '难度',
            type: 'pie',
            radius: '50%',
            label:{
              show:true,
              formatter: '{b} ( {c} / {d}% ) ',
              position:'outside',
            },
            data: [
              { value: difficultyCountMap.get('容易'), name: '容易' },
              { value: difficultyCountMap.get('中等'), name: '中等' },
              { value: difficultyCountMap.get('困难'), name: '困难' },
            
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      
      useEffect(()=>{
        const chartDifficultDom = document.getElementById('difficult')!;
        const myDifficultChart = echarts.init(chartDifficultDom);
        difficult_option && myDifficultChart.setOption(difficult_option);
      },[])
      
      return <div id="difficult" style={{height:400,width:567,marginLeft:25,border:"1px solid #ccc",marginTop:10}}> </div>
        
    }

    const ExamExerciseStatTypePanel : React.FC<Props2> = (props)=>{
      const type_option = {
        title: {
          text: '试题题型分布',
          textStyle:{fontSize:14},
          left: 'center',
          top:'bottom'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 20,
          bottom:40,
        },
        series: [
          {
            name: '题型',
            type: 'pie',
            radius: '50%',
            left:100,
            width:450,
            height:400,
            
            label:{
              show:true,
              formatter: '{b} ( {c} / {d}% ) ',
              position:'outside',
            },
            data: [
              { value: typeCountMap.get('实操题'), name: '实操题' },
              { value: typeCountMap.get('单选题'), name: '单选题' },
              { value: typeCountMap.get('多选题'), name: '多选题' },
              { value: typeCountMap.get('排序题'), name: '排序题' },
              { value: typeCountMap.get('主观题'), name: '主观题' },
            
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
          }
        ]
      };
      
      useEffect(()=>{
        const chartTypeDom = document.getElementById('type')!;
        const myTypeChart = echarts.init(chartTypeDom);
        type_option && myTypeChart.setOption(type_option);
      },[])
      
      return <div id="type" style={{height:400,width:567,marginLeft:5,border:"1px solid #ccc",marginTop:10}}> </div>
        
    }

    useEffect(()=> {

    },[])

     //页码和页数发生了变化
    const onPageChange = (newPage: number, newPageSize?: number | undefined) => {

    }
    const searchByKnoName = (value :string)=>{
        console.log(value);
    }

    return (
    <Layout style={{minHeight: "100vh",backgroundColor: '#f9f9f9'}}>
        <div className={exerciseDetail.Headercontainer} style={{paddingTop:20,width:900,marginTop:30,backgroundColor: '#fff'}}>
            <Row align="middle">
                    <Col span={12} style={{textAlign:"left", marginBottom:10,width:'100%'}}>
                        <div style={{marginLeft:40,fontWeight:600, fontSize:15, color:"#333333"}}> 
                          { pathName.current === exercisePath ?  '作业概况详情' : "考试概况详情" } 
                        </div>
                    </Col>
                    <Col span={12}  style={{textAlign:"right", marginBottom:10,width:'100%'}}>
                        <Button 
                          style={{marginRight:40,color:"#333333"}}
                          type="link"
                          onClick={()=>{window.location.href = `/course-main-teacher?course_id=${courseId}&tabKey=6`}}
                        > 
                          {"返回 >"} 
                        </Button>
                    </Col>
            </Row>
            <Divider style={{ marginTop:0 }}/>
            <div className={ exerciseDetail.Header }>
              <span>
                <LeftCircleFilled style={{ color:"#07C160", fontSize:18 }} />
              </span>
              <div className={exerciseDetail.headerInfo}>
                <div style={{ fontSize:15, fontWeight:600, marginBottom:10 }}>{ headerInfo.title }</div>
                <div style={{ fontSize:10 }}>截止时间：{ headerInfo.last_time }</div>
              </div>
              <span>
                <RightCircleFilled  style={{ color:"#07C160", fontSize:18 }} />
              </span>
            </div>
            <Row style={{marginRight:20, marginLeft:20}}>
                {
                  countBox?.map((box,index)=>(
                    <Col span={6} key={box.id}>
                      <div className={exerciseDetail.countBox}>
                        <div style={{color:"#07C160",fontSize:16, marginBottom:5}}>
                          {
                            box.id === 1 ? headerInfo.completed +  '/' + headerInfo.total :
                            box.id === 2 ? headerInfo.completed_percent + "%" :
                            box.id === 3 ? headerInfo.right_percent + "%" :
                            box.id === 4 ? headerInfo.avg_score + "%" : 
                            ""
                          }
                        </div>
                        <div style={{fontSize:12}}>
                          { box.label }
                        </div>
                      </div>
                  </Col>
                  ))
                }
            </Row>
            <Row>
              <Col span={12} >
                <ExamExerciseStatDifficultPanel/>
              </Col>
              <Col span={12} >
                <ExamExerciseStatTypePanel/>
              </Col>
            </Row>
        </div>
        <div className={exerciseDetail.Footercontainer} style={{ paddingTop:20,width:900,marginTop:30,backgroundColor: '#fff' }}>
            <Row>
              <Col span={12} style={{textAlign:"left"}}>
                <Search placeholder="输入学生姓名/学号"  onSearch={searchByKnoName} style={{ width: 200, marginLeft:30,borderRadius:5 }} />
              </Col>
              <Col span={12} style={{textAlign:"right"}}>
                <Button style={{marginRight:30,width:100,borderRadius:5,backgroundColor:"#F6FAF7",border:"none", color:"#07C160"}} type='default'>导出Execl</Button>
              </Col>
            </Row>
            <ConfigProvider locale={zhCN}>
              <Table
                  style={{marginLeft:30, marginTop:20, marginRight:30}}
                  className={exerciseDetail.tableWrapper}
                  dataSource={commonDetailData}
                  columns={commonDetailCols}
                  size='small'
                  rowKey={record => record.id}
                  pagination={false}
                  // onChange={onTableChange}
              />
          </ConfigProvider>
          <ConfigProvider locale={zhCN}>
            <div className={exerciseDetail.pagination} style={{marginTop:20}}>
                <Pagination
                    defaultCurrent={1}
                    total={100}
                    defaultPageSize={1}
                    current={1}
                    hideOnSinglePage={true}
                    onChange={onPageChange}
                    showSizeChanger={false}
                />
            </div>
          </ConfigProvider>
        </div>
    </Layout>
    )

}

export default CourseCommonDetail

