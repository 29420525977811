import exerciseStyles from "./exercise.module.less";
import { message,Button, Card, Col, ConfigProvider, Layout, Pagination, Row, Tabs, Table } from 'antd';
import { EyeOutlined, ForkOutlined,LoadingOutlined } from '@ant-design/icons'
import { PageHeader } from "../common/page-header/header";
import React, { useEffect, useRef, useState } from "react";
import PageFooter from "../common/page-footer/footer";
import Search from 'antd/lib/input/Search';
import zhCN from 'antd/lib/locale/zh_CN'
import { Tag } from 'antd';
import Cookies from 'js-cookie';
import { Checkbox } from 'antd';

const CheckboxGroup = Checkbox.Group;


import Meta from 'antd/lib/card/Meta';
import { sendHttpPostRequest } from "../../api/http-request";
import ExerciseCardDataCoding from "./exercise-card-data-coding";
import ExerciseCardSingleSelect from "./exercise-card-single-select";
import ExerciseCardMultiSelect from "./exercise-card-multi-select";
import ExerciseCardSort from "./exercise-card-sort";
import ExerciseCardSubjective from "./exercise-card-subjecitive";
const { CheckableTag } = Tag;

const { Content } = Layout
const {TabPane} = Tabs

interface Exercise {
    id: number,
    title: string,
    author: string,
    difficulty: number, //难度
    channel: number, //
    type: number, //题型
    status: number, //状态
    create_time: string,//创建时间
    modify_time: string,//修改时间
    source_page: number,
    source_key: number
}



export interface Props {exercise:any,
    renderType:string, //渲染的主题类型，"dark","white"
    teacherOnly:boolean,  // 仅老师
    editorMode:boolean,//是否可编辑模式，
    showAnser:boolean,//是否默认显示答案
    showClean:boolean,//是否显示清除按钮
  }

export const ExerciseCard: React.FC<Props> = (props) => {

    const {exercise,renderType,teacherOnly,editorMode,showAnser,showClean} = props


    useEffect(()=>{
        
    },[])
   

    switch(exercise?.type){
        case 1 : //数据实操题
           return  <ExerciseCardDataCoding exercise={exercise} renderType = {renderType} teacherOnly={teacherOnly} editorMode={editorMode} showAnser={showAnser} showClean={showClean}/>
           
        case 2: //单选题
           
           return <ExerciseCardSingleSelect exercise={exercise} renderType = {renderType} teacherOnly={teacherOnly} editorMode={editorMode} showAnser={showAnser} />
      
        case 3 ://多选题
            return   <ExerciseCardMultiSelect exercise={exercise} renderType = {renderType} teacherOnly={teacherOnly} editorMode={editorMode} showAnser={showAnser}/>
            
        case 4: //排序题
            return  <ExerciseCardSort exercise={exercise} renderType = {renderType} teacherOnly={teacherOnly} editorMode={editorMode} showAnser={showAnser}/>

        case 5: //主观题
            return <ExerciseCardSubjective exercise={exercise} renderType = {renderType} teacherOnly={teacherOnly} editorMode={editorMode} showAnser={showAnser}/>
        default :
            return (
            <div style={{textAlign:"center"}}>
                <LoadingOutlined />
            </div>
            )
    }


}

export default ExerciseCard