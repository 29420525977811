import {Tabs,Button, Col, ConfigProvider, Input, Layout, message, Pagination, Popconfirm, Row, Statistic, Table} from 'antd';
import { Content } from 'antd/lib/layout/layout';

import React, {useEffect, useRef, useState} from "react";
import OAMSider from '../oam-sider';
import idataCourseStyles from "../../common/global-styles/idatacourse-table.module.less";
import zhCN from "antd/lib/locale/zh_CN";
import {sendHttpPostRequest} from "../../../api/http-request";
import Cookies from "js-cookie";
const { Search } = Input;
const { TabPane } = Tabs;
export interface Props {}
const typeMap = new Map([['课件', 1], ['视频', 2]]);
const statusMap = new Map([['下线', 1], ['在线', 2]]);

export const OAMModuleList: React.FC<Props> = (props) => {
    const [moduleResult, setModuleResult] = useState([] as any[]);  // 当前显示的结果页
    const [resultSum, setResultSum] = useState(0);  // 搜索返回的结果数
    const [totalCount, setTotalCount] = useState(0);  // 搜索返回的结果数
    const [sysCount, setSysCount] = useState(0) //搜索返回的结果数
    const [userCount, setUserCount] = useState(0) //搜索返回的结果数
  
    const [onlineCount, setOnlineCount] = useState(0);  // 搜索返回的结果数
    const queryContent = useRef('');  // 记录搜索串
    const querySource = useRef('2');//2-系统，1-用户
    const order = useRef('');  // 排序升序降序
    const order_by = useRef('');  // 排序列
    const page = useRef(1);  // 记录当前的页数
    const pageSize = useRef(20);  // 记录当前每页展示数量
    const typeFilters = useRef([]);  // 类型过滤选项
    const statusFilters = useRef([]);  // 状态过滤选项

    // 查询知识点列表回调
    const queryModuleListCallback = (response:any) => {
        message.destroy();
        if (response.code == -100) {
            // 未登录
            window.location.href = '/oam_login?next=oam_module_list';
        } else if (response.is_ok == true) {
            // 成功
            setModuleResult(response.query_list);
            setResultSum(response.total_sum);
            setTotalCount(response.total_count);
            setSysCount(response.system_count);
            setUserCount(response.personal_count);
            setOnlineCount(response.online_count);
        }// else if (response.code == -501) {
        // 权限不足
        //window.location.href = '/oam_login?next=oam_chapter';
        //} else {
        //message.error(response.message);
        //}
    }

    // 查询知识点列表
    const queryModuleList = () => {
        sendHttpPostRequest(
            'course/api/oam_query_knowcell_list',
            {
                sessionid: Cookies.get("sessionid"),
                page: page.current,
                pagesize: pageSize.current,
                query: queryContent.current,
                source: querySource.current,
                order: order.current,
                order_by: order_by.current,
                type: JSON.stringify(typeFilters.current),
                status: JSON.stringify(statusFilters.current),
            },
            queryModuleListCallback
        )
    }

    useEffect(() => {
        queryModuleList();
    }, [])

    // 点击创建知识点
    const onCreateModuleBtnClick = (event:any) => {
        event.preventDefault();
        sendHttpPostRequest(
            'course/api/oam_create_knowcell',
            {
                sessionid: Cookies.get("sessionid"),
                sid: Cookies.get("sessionid")
            },
            (response: any) => {
                if (response.is_ok && response.know_id) {
                    window.open(`/oam_module_edit?module_id=${response.know_id}`);
                } else {
                    message.destroy();
                    message.error(`创建知识点失败：${response.message}`)
                }
            }
        )
    }

        // 点击删除知识点
        const onDeleteModuleBtnClick = (name:string, id:number, event:any) => {
            event.preventDefault();
            sendHttpPostRequest(
                'course/api/oam_delete_knowcell',
                {
                    sessionid: Cookies.get("sessionid"),
                    sid: Cookies.get("sessionid"),
                    know_id: id,
                },
                (response: any) => {
                    if (response.is_ok == true) {
                        message.success("知识点删除成功~")
                        queryModuleList();
                    } else {
                        message.destroy();
                        message.error(`创建知识点失败：${response.message}`)
                    }
                }
            )
        }


    // 点击了搜索按钮
    const onSearchBtn = (value: string) => {
        page.current = 1;
        queryContent.current = value;
        queryModuleList();
    }

    // 点击了查看按钮
    const onLookOverBtnClick = (event:any) => {
        //message.success('查看数据' + event.currentTarget.value)
        event.preventDefault()
        // window.open('/oam_menu_detail?menu_id=' + event.currentTarget.value)
    }

    // 点击了编辑按钮
    const onEditBtnClick = (event:any) => {
        //message.success('编辑数据' + event.currentTarget.value)
        event.preventDefault()
        window.open('/oam_module_edit?module_id=' + event.currentTarget.value)
    }

    // 表格发生变化
    const onTableChange:any = (pagination:any, filters:any, sorter:any, extra: { currentDataSource: [], action: any })=> {
        if (sorter.order) { //如果选择了排序列
            order.current = sorter.order;
            order_by.current = sorter.field;
        }
        if (filters.type) {
            typeFilters.current = filters.type.map(element=> {
                return typeMap.get(element);
            });
        } else {
            typeFilters.current = [];
        }
        if (filters.status) {
            statusFilters.current = filters.status.map(element=> {
                return statusMap.get(element);
            });
        } else {
            statusFilters.current = [];
        }
        page.current = 1;
        queryModuleList();
    }

    // 页码和页数发生了变化
    const onPageChange = (newPage: number, newPageSize?: number | undefined) => {
        page.current = newPage ? newPage : 1;
        if (newPageSize) {
            pageSize.current = newPageSize;
        }
        queryModuleList();
    }

 
       // 上下架回调
       const onPutOnOffBtnClickCallback = (response: any) => {
        message.destroy();
        if (response.code == -100) {
            // 没登录
            window.location.href = '/oam_login?next=oam_module_list';
        } else if (response.code == -501) {
            // 没权限
            window.location.href = '/oam_login?next=oam_module_list';
        } else if (response.is_ok == true) {
            message.success(response.message);
            queryModuleList();
        } else {
            message.error(response.message);
        }
    }

    // 点了上下架按钮
    const onPutOnOffBtnClick = (knowcellId:number, status:number) => {
        sendHttpPostRequest(
            'course/api/oam_update_knowcell/',
            {
                know_id:knowcellId,
                status: status == 1?2:1,
                sessionid: Cookies.get("sessionid"),
                sid: Cookies.get("sessionid"),
            },
            onPutOnOffBtnClickCallback
        )

       
    }

    // 点击 删除
    const onDeleteBtnClick = (name:string, id:number) => {
        // deleteMenu(id)
    }

    // 渲染操作
    const renderSystemActions = (text:any, record:any) => {
       
        return <>
            <Row justify="start" align="middle" gutter={[8, 5]} >
                <Col style={{width:50}}><Button style={{borderRadius:20,fontSize:12}} size='small' id="lookover" value={record.id} type="default" onClick={()=>{window.open(`/module-learn-preview?module_id=${record.id}`)}} > 查 看 </Button></Col>
                <Col style={{width:50}}><Button style={{borderRadius:20,fontSize:12}} size='small' id="edit" value={record.id} type="dashed" onClick={onEditBtnClick}> 编 辑 </Button> </Col>
                {<Col style={{width:50}}> { parseInt(record.status) == 1 ?
                    <Button style={{borderRadius:20,fontSize:12}}  size='small' id="puton" value={record.id} type="primary"  onClick={onPutOnOffBtnClick.bind(this, record.id, record.status)}> 上 架 </Button>
                    :  <Popconfirm placement="top" title={'确认下架知识点"' + record.name + '"吗？'} onConfirm={onPutOnOffBtnClick.bind(this, record.id, record.status)} okText="确认" cancelText="取消">
                        <Button style={{borderRadius:20,fontSize:12}}  size='small' id="putoff" value={record.id} type="primary"  danger> 下 架 </Button>
                    </Popconfirm>}
                </Col> }
                { parseInt(record.status) == 1  && <Col style={{width:50}}>
                    <Popconfirm placement="top" title={'确认删除知识点"' + record.name + '"吗？'} onConfirm={onDeleteModuleBtnClick.bind(this, record.name,record.id)} okText="确认" cancelText="取消">
                        <Button style={{borderRadius:20,fontSize:12}} size='small' id="delete" danger value={record.id} > 删 除 </Button>
                    </Popconfirm></Col> }
            </Row>

        </>

    }

        // 渲染操作
        const renderUserActions = (text:any, record:any) => {
       
            return <>
                <Row justify="start" align="middle" gutter={[8, 5]} >
                    <Col style={{width:50}}><Button style={{borderRadius:20,fontSize:12}} size='small' id="lookover" value={record.id} type="default" onClick={()=>{window.open(`/module-learn-preview?module_id=${record.id}`)}} > 查 看 </Button></Col>
                    <Col style={{width:50}}><Button style={{borderRadius:20,fontSize:12}} size='small' id="edit" value={record.id} type="dashed" onClick={onEditBtnClick}> 编 辑 </Button> </Col>
               
                     <Col style={{width:50}}>
                        <Popconfirm placement="top" title={'确认删除知识点"' + record.name + '"吗？'} onConfirm={onDeleteModuleBtnClick.bind(this, record.name,record.id)} okText="确认" cancelText="取消">
                            <Button style={{borderRadius:20,fontSize:12}} size='small' id="delete" danger value={record.id} > 删 除 </Button>
                        </Popconfirm></Col> 
                </Row>
    
            </>
    
        }


    // 表格列
    const systemCols: any = [
        {
            title: "序号",
            dataIndex: "id",
            key: "id",
            width: 50,
            align: 'left',
            render:(text, record, index)=>((page.current - 1)*pageSize.current + index + 1)
        },
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            width: 60,
            align: 'left',
        },
        {
            title: "名称",
            dataIndex: "name",
            key: "name",
            width: 100,
            align: 'left',
        },
        {
            title: "创建人",
            dataIndex: "user_name",
            key: "user_name",
            width: 80,
            align: 'left',
            ellipsis: false,
        },
        {
            title: "创建时间",
            dataIndex: "create_time",
            key: "create_time",
            width: 150,
            align: 'center',
            ellipsis: false,
            sorter: true,
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'descend',
            render:(text:any, record:any)=>{ return text?.slice(0,19)}
        },
        {
            title: "类型",
            dataIndex: "type",
            key: "type",
            width: 50,
            align: 'center',
            ellipsis: false,
            render:(text:any, record:any)=>{ return record.type == 1 ? '课件': '视频'},
            filters: [
                {
                    text: '课件',
                    value: '课件',
                },
                {
                    text: '视频',
                    value: '视频',
                }
            ],
            onFilter: (value:any, record:any) => true,
        },
        {
            title: "实验数",
            dataIndex: "case_count",
            key: "case_count",
            width: 50,
            align: 'center',
            ellipsis: false,
        },
        {
            title: "练习题",
            dataIndex: "practice_count",
            key: "practice_count",
            width: 50,
            align: 'center',
            ellipsis: false,
        },
        {
            title: "作业考试题",
            dataIndex: "exam_count",
            key: "exam_count",
            width: 50,
            align: 'center',
            ellipsis: false,

        },
       {
            title: "状态",
            dataIndex: "status",
            key: "status",
            width: 50,
            align: 'center',
            ellipsis: false,
            render:(text:any, record:any)=>{
                return text == 1 ? '下线':'在线'
            },
            filters: [
               {
                   text: '下线',
                   value: '下线',
               },
               {
                   text: '在线',
                   value: '在线',
               }
           ],
           onFilter: (value:any, record:any) => true,
        },
        {
            title: "学习次数",
            dataIndex: "view_count",
            key: "view_count",
            width: 60,
            align: 'left',
            ellipsis: false,
            sorter: true,
            sortDirections: ['descend', 'ascend'],
            
        },
        {
            title: "操作",
            dataIndex: "actions",
            key: "actions",
            width: 150,
            ellipsis: false,
            align: 'center',
            render: renderSystemActions ,
        },
    ]

        // 表格列
        const userCols: any = [
            {
                title: "序号",
                dataIndex: "id",
                key: "id",
                width: 50,
                align: 'left',
                render:(text, record, index)=>((page.current - 1)*pageSize.current + index + 1)
            },
            {
                title: "ID",
                dataIndex: "id",
                key: "id",
                width: 60,
                align: 'left',
            },
            {
                title: "名称",
                dataIndex: "name",
                key: "name",
                width: 100,
                align: 'left',
            },
            {
                title: "创建人",
                dataIndex: "user_name",
                key: "user_name",
                width: 80,
                align: 'left',
                ellipsis: false,
            },
            {
                title: "创建时间",
                dataIndex: "create_time",
                key: "create_time",
                width: 150,
                align: 'center',
                ellipsis: false,
                sorter: true,
                sortDirections: ['descend', 'ascend'],
                defaultSortOrder: 'descend',
                render:(text:any, record:any)=>{ return text?.slice(0,19)}
            },
            {
                title: "类型",
                dataIndex: "type",
                key: "type",
                width: 50,
                align: 'center',
                ellipsis: false,
                render:(text:any, record:any)=>{ return record.type == 1? '课件': '视频'},
                filters: [
                    {
                        text: '课件',
                        value: '课件',
                    },
                    {
                        text: '视频',
                        value: '视频',
                    }
                ],
                onFilter: (value:any, record:any) => true,
            },
            {
                title: "实验数",
                dataIndex: "case_count",
                key: "case_count",
                width: 50,
                align: 'center',
                ellipsis: false
            },
            {
                title: "练习题",
                dataIndex: "practice_count",
                key: "practice_count",
                width: 50,
                align: 'center',
                ellipsis: false,
            },
            {
                title: "作业考试题",
                dataIndex: "exam_count",
                key: "exam_count",
                width: 50,
                align: 'center',
                ellipsis: false,

            },
            {
                title: "学习次数",
                dataIndex: "view_count",
                key: "view_count",
                width: 60,
                align: 'left',
                ellipsis: false,
                sorter: true,
                sortDirections: ['descend', 'ascend'],
                
            },
            {
                title: "操作",
                dataIndex: "actions",
                key: "actions",
                width: 150,
                ellipsis: false,
                align: 'center',
                render: renderUserActions ,
            },
        ]


        // 切换Tab页
        const onTabKeyChange = (key:string) => {
            console.log(key);
            querySource.current = key;
            page.current = 1;
            queryModuleList();
        }

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <OAMSider keys={"33"} openKeys={["sub-course"]}></OAMSider>
            <Layout style={{backgroundColor:'#f9f9f9'}}>
                <Content style={{ backgroundColor:'#fff',borderRadius:5,minWidth:400,margin:30 ,paddingTop:20}}>
                    <div style={{paddingLeft:30,paddingRight:30}}>
                        <Row align="middle"  gutter={16}>
                            

                            <Col flex='80px'>
                                <Statistic title="知识点" value={totalCount} />
                            </Col>
                            <Col flex='80px'>
                                <Statistic title="系统" value={sysCount} />
                            </Col>
                            <Col flex='80px'>
                                <Statistic title="用户" value={userCount} />
                            </Col>
                            <Col flex='80px'>
                                <Statistic title="已上架" value={onlineCount} />
                            </Col>

                            <Col flex='250px'>

                                <Search
                                    placeholder="搜索知识点"
                                    style={{ width: 350 }}
                                    enterButton={true}
                                    onSearch={onSearchBtn}
                                />

                            </Col>

                            <Col flex='auto' style={{textAlign:'right'}}> <Button style={{borderRadius:20}} type={'primary'} onClick={onCreateModuleBtnClick}>创 建</Button></Col>
                        </Row>

                        <Tabs defaultActiveKey="2" onChange={onTabKeyChange}>
                            <TabPane tab="系统" key="2" className="">

                                <div className={idataCourseStyles.tableWrapper}>
                                    <ConfigProvider locale={zhCN}>
                                        <Table
                                            dataSource={moduleResult}
                                            columns={systemCols}
                                            rowKey={record => record.id}
                                            pagination={false}
                                            //bordered={true}
                                            onChange={onTableChange}
                                        />
                                    </ConfigProvider>
                                </div>

                                <ConfigProvider locale={zhCN}>
                                    <div style={{marginBottom:30,paddingTop:30}} className={"css.pagination"} >
                                        <Pagination
                                            defaultCurrent={1}
                                            current={page.current}
                                            total={resultSum}
                                            defaultPageSize={20}
                                            hideOnSinglePage={true}
                                            onChange={onPageChange}
                                            showQuickJumper={true}
                                            showTotal={total => `共 ${total} 知识点`}
                                            pageSizeOptions={["3","20","30","50","100"]}
                                        />
                                    </div>
                                </ConfigProvider>
                            </TabPane>
                            <TabPane tab="用户" key="1" className="">
                            <div className={idataCourseStyles.tableWrapper}>
                                    <ConfigProvider locale={zhCN}>
                                        <Table
                                            dataSource={moduleResult}
                                            columns={userCols}
                                            rowKey={record => record.id}
                                            pagination={false}
                                            //bordered={true}
                                            onChange={onTableChange}
                                        />
                                    </ConfigProvider>
                                </div>

                                <ConfigProvider locale={zhCN}>
                                    <div style={{marginBottom:30,paddingTop:30}} className={"css.pagination"} >
                                        <Pagination
                                            defaultCurrent={1}
                                            current={page.current}
                                            total={resultSum}
                                            defaultPageSize={20}
                                            hideOnSinglePage={true}
                                            onChange={onPageChange}
                                            showQuickJumper={true}
                                            showTotal={total => `共 ${total} 知识点`}
                                            pageSizeOptions={["3","20","30","50","100"]}
                                        />
                                    </div>
                                </ConfigProvider>

                            </TabPane>
                        </Tabs>
                    </div>
                </Content>
            </Layout>
        </Layout>

    )

}

export default OAMModuleList

