import {
    Layout,
    Button,
    Input,
    message,
    Table,
    Space,
    ConfigProvider,
    Tabs,
    Row,
    Col,
    Popconfirm,
    Statistic,
    Pagination,
    Tooltip
} from 'antd';

import moment from 'moment';
import {checkUserLogin} from "../../common/user-center/user-info";

import { Content } from 'antd/lib/layout/layout';
import zhCN from 'antd/lib/locale/zh_CN';
import OAMSider from '../oam-sider';
import React, {useEffect, useRef, useState} from "react";
import {sendHttpPostRequest} from "../../../api/http-request";
import Cookies from 'js-cookie';

import { EyeOutlined,EditOutlined,FileTextOutlined,DeleteOutlined } from '@ant-design/icons';

import idataCourseTableStyles from "../../common/global-styles/idatacourse-table.module.less";

const { Search } = Input;
const { TabPane } = Tabs;



export interface Props {}
const typeMap = new Map([[1, '实操题'], [2, '单选题'], [3, '多选题'],[4, '排序题'], [5, '主观题']]);
const difficultyMap = new Map([[1, '容易'], [2, '中等'], [3, '困难']]);

export const OAMTestList: React.FC<Props> = (props) => {
    const [testResult, setTestResult] = useState([] as any[]);  // 当前显示的结果页
    const [resultSum, setResultSum] = useState(0);  // 搜索返回的结果数
    const [totalCount, setTotalCount] = useState(0);  // 搜索返回的结果数
    const queryContent = useRef('');  // 记录搜索串
    const querySource = useRef('1');  // 来源 1-用户  2-OAM创建（系统）
    const order = useRef('');  // 排序升序降序
    const order_by = useRef('');  // 排序列
    const page = useRef(1);  // 记录当前的页数
    const pageSize = useRef(20);  // 记录当前每页展示数量
    const userIsLogin = useRef(false);  // 用户是否登录
    const typeFilters = useRef([]);  // 类型过滤选项
    const difficultyFilters = useRef([]);  // 难易程度过滤选项
    const createTime = useRef('');  // 创建时间

    const [sysCount, setSysCount] = useState(0) //搜索返回的结果数
    const [userCount, setUserCount] = useState(0) //搜索返回的结果数
    const [onlineCount, setOnlineCount] = useState(0) //搜索返回的结果数


    useEffect(()=> {
        queryTestList();
      },[])
  
  
    const queryTestList = () => {      
      sendHttpPostRequest(
        'homework/api/oam_query_homework_list/',
        {
            sessionid: Cookies.get("sessionid"),
            query: queryContent.current,
            page: page.current,
            pagesize: pageSize.current,
            order: order.current,
            order_by: order_by.current,
            //source: querySource.current,
        },
        (response:any) => {
          message.destroy();
          if (response.code == -100) {
              // 没登录
              window.location.href = '/oam_login?next=oam_test_list';
          } else if (response.code == -501) {
              // 没权限
              window.location.href = '/oam_login?next=oam_test_list';
          } else if (response.is_ok == true) {
              setTestResult(response.query_list);
              setResultSum(response.total_sum);
              setTotalCount(response.total_count);
          }
      }
    )
    
  }
  

    /** 删除作业 */
    const deleteTest = (homeworkId:any) => {
      sendHttpPostRequest(
        'homework/api/oam_delete_homework/',
        {
            sessionid: Cookies.get("sessionid"),
            homework_id: homeworkId,
        },
        (response:any) => {
          message.destroy();
          if (response.code == -100) {
              // 没登录
              window.location.href = '/oam_login?next=oam_test_list';
          } else if (response.code == -501) {
              // 没权限
              window.location.href = '/oam_login?next=oam_test_list';
          } else if (response.is_ok == true) {
              queryTestList();
          }else{
            message.error("删除作业失败~");
          }
      }
    )
    
  }
    
      const onPreviewTestClick = (test_id:string, event:any) => {
            if (!checkUserLogin()) {
                document.getElementById("show-login-modal")!.click();
            }
            window.open('/oam-exam-preview?test_id=' + test_id )
        }
  
      const onEditTestClick = (test_id:string, event:any) => {
          if (!checkUserLogin()) {
              document.getElementById("show-login-modal")!.click();
          }
          
          window.open('/oam-test-edit?test_id=' + test_id)
      }
        
  
        
      
      // 渲染操作
      const renderActions = (text:any, record:any) => {
          return <Row align='middle' justify='center' gutter={[16,8]}> 
  
                { /*<Col>  <EyeOutlined  value={record.id} onClick={onPreviewExamClick.bind(this,record.id)}   /> </Col> */}
  
                {/*<Col>  <EditOutlined onClick={onEditBtnClick.bind(this,record.id)}   /> </Col>  */}
  
                  {/*<Col>  <Tooltip title="导出试卷"> <FileTextOutlined className={courseExamListStyles.actionBtn}/> </Tooltip></Col> */}
                  <Col> 
                      <Popconfirm placement="top" title={'确认删除作业"' + record.name + '"吗？'} onConfirm={()=>{deleteTest(record.id)}} okText="确认" cancelText="取消">
                      <DeleteOutlined  />
                      </Popconfirm>
                  </Col>
                 
                       
          </Row>
      }
  
      const renderName = (text:any, record:any) => {
          if (text.length > 50) {
            return <>
              {record.name.slice(0,50) + '...'}
            </>
          } else {
            return <>
              {record.name}
            </>
          }
        }
      const testCols :any= [
       {
          title:'序号',
          dataIndex: 'eee',
          key: 'eee',
          align: 'left',
          width: 50,
          render:(text,record,index)=>((page.current - 1)*pageSize.current + index + 1)
        },
        {
          title:'ID',
          dataIndex: 'id',
          key: 'id',
          align: 'left',
          width: 50,
        },
        {
          title: '作业',
          dataIndex: 'name',
          key: 'name',
          align: 'left',
          width: 150,
          render:renderName,
        },
        
        {
          title: '状态',
          dataIndex: 'status',
          key: 'status',
          align: 'center',
          width: 80,  
        },
        {
          title: '截止时间',
          dataIndex: 'submit_time',
          key: 'submit_time',
          align: 'center',
          width: 120,
          render: (text: any, record: any) => (
            <div>{text?.slice(0,16)}</div>
          )
        },
        {
            title: "教师",
            dataIndex: "teacher",
            key: "teacher",
            width: 80,
            align: 'center',
            render:(text,record)=>(record.teacher_real_name == '' ? record.teacher : record.teacher_real_name)
        },
        {
            title: "所属课程",
            dataIndex: "course_name",
            key: "course_name",
            width: 120,
            align: 'center',
        },
        {
            title: "所属机构",
            dataIndex: "college_name",
            key: "college_name",
            width: 120,
            align: 'center',
        },
        {
          title: "操作",
          dataIndex: "actions",
          key: "actions",
          width: 80,
          ellipsis: false,
          align: 'center',
          //fixed: 'right',
          render: renderActions,
      },
      ]

          //页码和页数发生了变化
    const onPageChange = (newPage: number, newPageSize?: number | undefined) => {
        page.current = newPage ? newPage : 1;
        if (newPageSize) {
            pageSize.current = newPageSize;
        }
        queryTestList();
    }

    //点击了编辑按钮
    const onEditBtnClick = (id:any,  event:any) => {
        window.open('/oam_exam_edit?exam_id='+id);
    }

    //点击了搜索按钮
    const onSearchBtn = (value: string) => {
        page.current = 1;
        queryContent.current = value;
        queryTestList();
    }

    // 切换Tab页
    const onTabKeyChange = (key:string) => {
        console.log(key);
        querySource.current = key;
        page.current = 1;
        queryTestList();
    }

    // 表格发生变化
    const onTableChange:any = (pagination:any, filters:any, sorter:any, extra: { currentDataSource: [], action: any })=> {
        if (sorter.order) { //如果选择了排序列
            order.current = sorter.order;
            order_by.current = sorter.field;
        }
        if (filters.type) { //如果选择了过滤列
            typeFilters.current = filters.type;
        } else {
            typeFilters.current = [];
        }
        if (filters.difficulty) {
            difficultyFilters.current = filters.difficulty;
        } else {
            difficultyFilters.current = [];
        }
        page.current = 1;
        queryTestList();
    }


    return (
             
        <Layout style={{ minHeight: '100vh' }}>
            <OAMSider keys={['6']}  openKeys={[]} />
            <Layout style={{backgroundColor:'#f9f9f9'}}>
                <Content style={{ backgroundColor:'#fff',borderRadius:5,minWidth:400,margin:30 ,paddingTop:20}}>
                  <div style={{paddingLeft: 30, paddingRight: 30, paddingBottom: 30}}>
                        <Row align="middle"  gutter={16} >
                            <Col flex='120px'>
                                <Statistic title="总作业" value={totalCount} />
                            </Col>

                            <Col flex='250px'>
                                <Search
                                    placeholder="搜索作业"
                                    style={{ width: 350 }}
                                    enterButton={true}
                                    onSearch={onSearchBtn}
                                />
                            </Col>
                        </Row>

                        <div className={idataCourseTableStyles.tableWrapper}>
                            <ConfigProvider locale={zhCN}>
                                <Table
                                    dataSource={testResult}
                                    columns={testCols}
                                    rowKey={record => record.id}
                                    pagination={false}
                                    scroll={{ x: 800 }}
                                    //bordered={true}
                                    //onChange={onTableChange}
                                />
                            </ConfigProvider>
                        </div>
                            

                        <ConfigProvider locale={zhCN}>
                            <div style={{marginBottom:30,paddingTop:30}} className={"css.pagination"} >
                                <Pagination
                                   
                                    total={resultSum}
                                    pageSize={pageSize.current}
                                    hideOnSinglePage={true}
                                    current={page.current}
                                    onChange={onPageChange}
                                    showQuickJumper={true}
                                    showTotal={total => `共 ${total} 作业`}
                                    pageSizeOptions={["3","20","30","50","100"]}
                                />
                            </div>
                        </ConfigProvider>
                    </div>
                </Content>
            </Layout>
            
        </Layout>

    )

}

export default OAMTestList

