import {
    Layout,
    Button,
    Input,
    message,
    Table,
    ConfigProvider,
    Tabs,
    Row,
    Col,
    Popconfirm,
    Statistic,
    Pagination,
    Breadcrumb,
    Upload,
    Popover,
} from 'antd';

import selfCss from './oam-lecture.module.less'

import courseBasicInfoStyle from "../../course/course-edit-basicinfo.module.less"

import {PlayCircleOutlined, DownOutlined,SearchOutlined, DeleteOutlined, FileSearchOutlined, PlusSquareOutlined, UploadOutlined} from "@ant-design/icons";

import { Content } from 'antd/lib/layout/layout';
import zhCN from 'antd/lib/locale/zh_CN';
import OAMSider from '../oam-sider';
import React, {useEffect, useRef, useState} from "react";
import {sendHttpPostRequest} from "../../../api/http-request";
import Cookies from 'js-cookie';


import * as constants from '../../../constants/constants';
const { Search } = Input;
const { TabPane } = Tabs;

export interface Props {}

/** OAM 讲堂编辑页面 */
export const OAMLectureEdit: React.FC<Props> = (props) => {
    const lectureId = parseInt(window.location.search.split("=")[1]);  // 节点的ID
    const [lecture, setLecture] = useState(null as any);  // 讲座对象
    const [title, setTitle] = useState(null as any);  // 
    const [teacher, setTeacher] = useState(null as any);  // 
    const [teacherTitle, setTeacherTitle] = useState(null as any);  // 
    const [teacherDescription, setTeacherDescription] = useState(null as any);  // 
    const [description, setDescription] = useState(null as any);  // 节点描述文本
    const [cover, setCover] = useState(null as any);  // 节点绑定的任务ID
    const [videoPath, setVideoPath] = useState(null as any);  // 节点绑定的任务ID


    const draggleRef = useRef<HTMLDivElement | null>(null)
  
    const [bounds,setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const [disabled,setDisabled] = useState(true)
  
    const onStart = (event:any, uiData:any) => {
      const { clientWidth, clientHeight } = window?.document?.documentElement;
      const targetRect= draggleRef?.current?.getBoundingClientRect();
      if(targetRect){
        setBounds( {
          left: -targetRect?.left + uiData?.x,
          right: clientWidth - (targetRect?.right - uiData?.x),
          top: -targetRect?.top + uiData?.y,
          bottom: clientHeight - (targetRect?.bottom - uiData?.y),
        }
      )
      }
    }
  



    useEffect(()=> {
        queryLectureInfo(lectureId);
    },[])
        
    // 查询讲座详情
    const queryLectureInfo = (lectureId) => {
        sendHttpPostRequest(
            'video/api/oam_get_lecture_by_id/',
            {lecture_id: lectureId, sessionid: Cookies.get("sessionid")},
            (response) => {
                message.destroy();
                if (response.code == -100) {
                    // 未登录
                    window.location.href = '/oam_login?next=oam_lecture_list';
                } else if (response.is_ok == true) {
                    // 成功
                    setLecture(response.query_info);
                    setTitle(response.query_info.title);
                    setTeacher(response.query_info.teacher);
                    setTeacherTitle(response.query_info.teacher_title);
                    setTeacherDescription(response.query_info.teacher_description);
                    setDescription(response.query_info.description);
                    setCover(response.query_info.cover);
                    setVideoPath(response.query_info.video_path);

                } else if (response.code == -501) {
                    // 权限不足
                    window.location.href = '/oam_login?next=oam_lecture_list';
                } else {
                    message.error(response.message);
                }
            }
        )
    }



        
   // 上传讲座封面传参
   const uploadData = {
    sessionid: Cookies.get("sessionid"),
    sid: Cookies.get("sessionid"),
    lecture_id: lectureId,
}


    // 上传图片
    const uploadImageProps = {
      accept: '.png,.jpg,jpeg,.gif,.svg',
      name: 'lecture_cover',
      multiple: false,
      showUploadList: false,
      data: uploadData,
      action: constants.BACKEND_API_URL + 'video/api/upload_lecture_cover/',
      onChange(info) {
          const { status } = info.file;
          if (status !== 'uploading') {
              console.log(info.file, info.fileList);
          }
          if (status === 'done') {
              if (info.file.response.is_ok == true) {
                  message.success(`图片${info.file.name}文件上传成功！`);
                  setCover(info.file.response.image_url);
                  
              } else {
                  if (info.file.response.code == -100) {
                      // 未登录
                      const localPath = window.location.href.split(window.location.host)[1];
                      window.location.href = `/oam_login?next=${localPath}`;
                  } else if (info.file.response.code == -501) {
                      // 权限不足
                      const localPath = window.location.href.split(window.location.host)[1];
                      window.location.href = `/oam_login?next=${localPath}`;
                  } else {
                      message.error(info.file.response.message);
                  }
              }

          } else if (status === 'error') {
              message.error(`图片${info.file.name}文件上传失败了:(`);
          }
      },
      onDrop(e) {
          console.log('Dropped files', e.dataTransfer.files);
      },
  };


    // 上传视频
    const uploadProps = {
        accept: '.mp4,.mov',
        name: 'lecture_file',
        multiple: false,
        showUploadList: true,
        data: uploadData,
        action: constants.BACKEND_API_URL + 'video/api/upload_lecture_video_file/',
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.destroy();
                if (info.file.response.is_ok == true) {
                    message.success(`文件${info.file.name}上传成功！`);
                    setVideoPath(info.file.response.video_url);
                    saveLecture(null as any);
                } else {
                    if (info.file.response.code == -100) {
                        // 未登录
                        const localPath = window.location.href.split(window.location.host)[1];
                        window.location.href = `/oam_login?next=${localPath}`;
                    } else if (info.file.response.code == -501) {
                        // 权限不足
                        const localPath = window.location.href.split(window.location.host)[1];
                        window.location.href = `/oam_login?next=${localPath}`;
                    } else {
                        message.error(info.file.response.message);
                    }
                }
            } else if (status === 'error') {
                message.error(`文件${info.file.name}上传失败了:(`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },

        progress: {
            strokeColor: {
              '0%': '#108ee9',
              '100%': '#87d068',
            },
            strokeWidth: 3,
            format: percent => `${parseFloat(percent.toFixed(2))}%`,
          },
    };


    // 点了预览按钮
    const onLookOverBtnClick = (event: any) => {
        window.open(`/lecture-vod?lecture_id=${lectureId}`)
    }


    // 保存讲座
    const saveLecture = (key: any) => {

        if(title?.trim() == ''){
            message.error("请输入讲座题目");
            return ''
        }
        if(teacher?.trim() == ''){
            message.error("请输入主讲人");
            return ''
        }
        if(cover?.trim() == ''){
            message.error("封面不能为空");
            return ''
        }

        message.destroy();

        const requestParams = {
            sessionid: Cookies.get("sessionid"),
            sid: Cookies.get("sessionid"),
            lecture_id: lectureId,
            title: title,
            teacher: teacher,
            teacher_title: teacherTitle,
            teacher_description: teacherDescription,
            description: description,
            cover: cover,
            video_path: videoPath,
            
         
        }
        sendHttpPostRequest(
            'video/api/oam_update_lecture/',
            requestParams,
            (response: any) => {
                if (response.code == -100) {
                    // 未登录
                    window.location.href = '/oam_login?next=oam_report_node';
                } else if (response.is_ok == true) {
                    // 成功
                    message.success("讲座信息保存成功~");
                } else if (response.code == -501) {
                    // 权限不足
                    window.location.href = '/oam_login?next=oam_report_node';
                } else {
                    message.error(response.message);
                }
            }
        )
    }


    const getVideoPreview =   ()=>{
        return <div style={{background:"#000"}}>
            { videoPath !== "" && 
            <video  style={{marginTop:25,marginBottom:25,height:320,width:500}}  preload="preload" 
            controls={true}
            controlsList="nodownload">
                <source 
                src= { videoPath?.startsWith("http") ? videoPath : constants.BACKEND_URL + videoPath}
                type={"video/mp4"} />
                您的浏览器不支持 HTML5 video 标签。
            </video>
            }
         </div>

    } 

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <OAMSider keys={['8']} openKeys={["sub-lecture"]}  />
                

            <Content style={{ backgroundColor:'#fff',borderRadius:5,minWidth:500,margin:50 ,paddingTop:20}}>
                <Breadcrumb style={{marginLeft:0,float:'left',marginTop: -50}}>
                    <Breadcrumb.Item>
                    <a href="/oam_lecture_list">讲座列表</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                    <span style={{fontWeight: 500}}> 讲座编辑 </span> 
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className={"css.search1"}  style={{paddingTop:20,paddingLeft:50,paddingRight:50,position:'relative'}}>
  
                <Row gutter={[8,8]} justify='start'  align='middle' style={{marginTop:-10,marginBottom:10}} >

                        <Col style={{marginTop:-10,textAlign:'right'}} span={24}>
                            <Button type="default" style={{width:80,borderRadius:20,marginRight:10}} onClick={onLookOverBtnClick}>预览</Button>
                            <Button type="primary" style={{width:80,borderRadius:20}} onClick={saveLecture}>保存</Button>
                        </Col>
                    </Row>  

            <div style={{padding:20,borderRadius:6,borderStyle:'solid',borderWidth:1,borderColor:"#e9e9e9"}}>
                    <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:20}} >
                        <Col flex="100px" style={{color:"#00000073",textAlign:'left'}}>讲座题目</Col>
                        <Col flex="auto" style={{textAlign:'left'}}>
                            <Input placeholder="请输入讲座名称" value={title} onChange={(e)=>{setTitle(e.target.value)}} style={{borderRadius:3,width:"100%"}}/>
                        </Col>
                        
                    </Row>

                    <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:20}} >
                        <Col flex="100px" style={{color:"#00000073",textAlign:'left'}}>视频封面</Col>
                        <Col flex="auto" style={{textAlign:'left'}}>
                            <Input placeholder="请输入视频封面url" value={cover} onChange={(e)=>{setCover(e.target.value)}} style={{borderRadius:3,width:"100%"}}/>
                        </Col>
                        
                    </Row>

                    <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:20}} >
                            <Col flex="100px" style={{color:"#00000073",textAlign:'left'}}></Col>
                            <Col flex="auto" style={{textAlign:'left'}}>
                                <Upload className={courseBasicInfoStyle.uploadImage} {...uploadImageProps}>
                                
                                    { cover?
                                        <img
                                        id="lecture-cover"
                                        width={250}
                                        height={160}
                                        src={ cover?.startsWith("http") ? cover : constants.BACKEND_URL + cover}
                                        /> : <div  style={{paddingTop:50,textAlign:"center",width:250,height:160,border:"1px solid #00000073"}}> <div className={courseBasicInfoStyle.uploadImageText} >+ 上传(点击或拖拽) </div></div>
                                    }  
                                </Upload>
                            </Col>
                        </Row>

                    <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:20}} >
                        <Col flex="100px" style={{color:"#00000073",textAlign:'left'}}>视频地址</Col>
                        <Col flex="auto" style={{textAlign:'left'}}>
                            <Input placeholder="请输入视频地址" value={videoPath} onChange={(e)=>{setVideoPath(e.target.value)}} style={{borderRadius:3,width:"100%"}}/>
                        </Col>   
                    </Row>
                    <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:20}} >

                        <Col flex="100px" style={{color:"#00000073",textAlign:'left'}}></Col>
                        <Col flex="auto" style={{textAlign:'left'}}>
                            <Upload       
                                listType="text" 
                                maxCount={1} 
                                defaultFileList={[]}
                                {...uploadProps}>
                                    <Button type='primary'  size="small" style={{width:80,height:32,color:"#FFF"}}>
                                        <UploadOutlined />上传
                                    </Button>
                                
                            </Upload>
                        </Col>
                    
                    </Row>

                    <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:20}} >
                            <Col flex="100px" style={{color:"#00000073",textAlign:'left'}}></Col>
                            <Col flex="auto" style={{textAlign:'left'}}>
                                <div className={selfCss.cardImageDiv}>
                                
                                    <Popover overlayStyle={{padding:0}} destroyTooltipOnHide={true} placement = "top" trigger= "click" content={getVideoPreview()}>
                                        <PlayCircleOutlined className={selfCss.imagePlayIcon}/>
                                    </Popover>
                                
                                    { cover?
                                        <img
                                        style={{cursor:"pointer"}}
                                        id="lecture-cover"
                                        width={250}
                                        height={160}
                                        src={ cover?.startsWith("http") ? cover : constants.BACKEND_URL + cover}
                                        /> :<div style={{background:"#666",height:160, width:250}}></div>
                                    }  
                                </div>
                            </Col>
                        </Row>

                    <Row gutter={[8,8]} justify='start'  align='top' style={{marginBottom:10}} >
                        <Col flex="100px" style={{color:"#00000073",textAlign:'left'}}>讲座简介</Col>
                        <Col flex="auto" style={{textAlign:'left'}}>
                            <Input.TextArea placeholder="请输入讲座简介" 
                            style={{borderRadius:3,width:"100%"}} 
                            autoSize={{minRows: 4, maxRows:8}} 
                            value={description} 
                            onChange={(e)=>{setDescription(e.target.value)}}/>
                        </Col>
                    </Row>

                    <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:20}} >
                        <Col flex="100px" style={{color:"#00000073",textAlign:'left'}}>主讲人</Col>
                        <Col flex="auto" style={{textAlign:'left'}}>
                            <Input placeholder="请输入主讲人" value={teacher} onChange={(e)=>{setTeacher(e.target.value)}} style={{borderRadius:3,width:"100%"}}/>
                        </Col>
                        
                    </Row>

                    <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:20}} >
                        <Col flex="100px" style={{color:"#00000073",textAlign:'left'}}>主讲人title</Col>
                        <Col flex="auto" style={{textAlign:'left'}}>
                            <Input placeholder="请输入主讲人title" value={teacherTitle} onChange={(e)=>{setTeacherTitle(e.target.value)}} style={{borderRadius:3,width:"100%"}}/>
                        </Col>
                       
                    </Row>

                    <Row gutter={[8,8]} justify='start'  align='top' style={{marginBottom:10}} >
                        <Col flex="100px" style={{color:"#00000073",textAlign:'left'}}>主讲人介绍</Col>
                        <Col flex="auto" style={{textAlign:'left'}}>
                            <Input.TextArea placeholder="请输入讲座简介" 
                            style={{borderRadius:3,width:"100%"}} 
                            autoSize={{minRows: 4, maxRows:8}} 
                            value={teacherDescription} 
                            onChange={(e)=>{setTeacherDescription(e.target.value)}}/>
                        </Col>
                    </Row>

  


                </div>

        
            </div>
            </Content>
                
            
        </Layout>

    )

}

export default OAMLectureEdit

