import React, { useEffect, useRef, useState } from 'react'
import {
  Input,
  Table,
  Switch,
  Layout,
  message,
  Card,
  Col,
  Row,
  ConfigProvider,
  Pagination,
  Tabs,
  Button,
  Tag,
  Popconfirm,
  Modal,
  Tooltip,
} from 'antd'
import { PageHeader } from '../../common/page-header/header'
import { sendHttpPostRequest } from '../../../api/http-request'
import logoSvg from '../../static/image/idclogo.png'
import pekinglogoSvg from '../../static/image/peking.png'
import courseMainStyles from '../course-main.module.less'
import '../course.module.css'
import zhCN from 'antd/lib/locale/zh_CN'
import * as constants from '../../../constants/constants'
import PageFooter from '../../common/page-footer/footer'
import LoginRegisterModal from '../../../component/modal/login-register/login-register'
import { checkUserLogin } from '../../common/user-center/user-info'
import formatIcon from '@/static/image/formatIcon.png'
import {
  ClockCircleOutlined,
  VideoCameraOutlined,
  CodeOutlined,
  FormOutlined,
  UserOutlined,
  InfoCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons'
const { TabPane } = Tabs
import CourseMainTeacherMenu from '../menu/course-main-teacher-menu'

import courseTeacherCasesStyles from './course-main-teacher-cases.module.less'
import Cookies from 'js-cookie'
import courseCreateStyles from '../course-create.module.less'
import moduleStyles from '../../oam/module/module.module.less'
import Draggable from 'react-draggable'
import shiyanEmpty from '../../../static/image/empty/queshengye-shiyan.png'
import { useHistory } from 'react-router-dom'

export interface Props {
  course_id: any
  is_course_teacher: any
  course?:any
}
/** 教师课程实验列表页面 */
export const CourseMainTeacherCases: React.FC<Props> = (props) => {
  const { course_id, is_course_teacher,course } = props
  const [courseResult, setCourseResult] = useState([] as any[])
  const [casesList, setCasesList] = useState([] as any[]) //课程的实验
  const [addCaseVisible, setAddCaseVisible] = useState(false)
  const caseQueryContent = useRef('') // 记录搜索串
  const casePage = useRef(1) // 记录当前的页数
  const casePageSize = useRef(10) // 记录当前每页展示数量
  const [caseTotalSum, setCaseTotalSum] = useState(0) // 搜索返回的结果数
  const queryCaseIsMy = useRef('2') // 搜索实验的来源， 来源 1 我的  2 系统
  const caseOrder = useRef('') // 排序升序降序
  const caseOrderBy = useRef('') // 排序列
  const [caseTotalCount, setCaseTotalCount] = useState(0) // 搜索返回的结果数
  const [caseData, setCaseData] = useState([] as any[])
  const selectedCaseRows = React.useRef([] as any) //选择的实验

  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
  const [disabled, setDisabled] = useState(true)
  const draggleRef = useRef<HTMLDivElement | null>(null)
  let history = useHistory()

  const onStart = (event: any, uiData: any) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement
    const targetRect = draggleRef?.current?.getBoundingClientRect()
    if (targetRect) {
      setBounds({
        left: -targetRect?.left + uiData?.x,
        right: clientWidth - (targetRect?.right - uiData?.x),
        top: -targetRect?.top + uiData?.y,
        bottom: clientHeight - (targetRect?.bottom - uiData?.y),
      })
    }
  }

  const queryCases = () => {
    sendHttpPostRequest(
      'course/api/user_query_course_info',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        course_id: course_id,
      },
      (response: any) => {
        if (response.is_ok) {
          setCasesList(response.query_info.case_list)
        }
      }
    )
  }

  const onPreviewCase = (id: string, event: any) => {
    if (!checkUserLogin()) {
      document.getElementById('show-login-modal')!.click()
    } else {
      event.preventDefault()
      window.open('/case-detail?case_id=' + id)
    }
  }

  const renderCaseActions = (text: any, record: any) => {
    return (
      <Button
        className={courseCreateStyles.modalBtn}
        type="primary"
        size="small"
        style={{ height: 28, width: 60 }}
        onClick={onPreviewCase.bind(this, record.case_id)}
      >
        预览
      </Button>
    )
  }

  const onDeleteCaseClick = (caseId: any) => {
    const newCaseList = casesList.filter((item) => item.id !== caseId)
    console.log(newCaseList)
    setCasesList(newCaseList)
    deleteCase(caseId)
  }

  const deleteCase = (caseId: any) => {
    sendHttpPostRequest(
      'course/api/user_delete_course_case',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        case: JSON.stringify([caseId]),
        course_id: course_id,
      },
      (response: any) => {
        if (response.code == -100) {
          // 没登录
          document.getElementById('show-login-modal')!.click()
          // window.location.href = '/login?next=course-main-teacher?course_id=' + course_id;
        } else if (response.code == -501) {
          // 没权限
          message.error('权限不足')
          // window.location.href = '/login?next=course';
        } else if (response.is_ok == true) {
          message.success('课件实验成功~')
        } else {
          message.error(response.message)
        }
      }
    )
  }

  // 查案例列表
  const queryCaseList = () => {
    sendHttpPostRequest(
      'case/api/query_case_list',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        query: caseQueryContent.current,
        page: casePage.current,
        pagesize: casePageSize.current,
        is_my: queryCaseIsMy.current,
        order: caseOrder.current,
        order_by: caseOrderBy.current,
      },
      (response: any) => {
        if (response.is_ok && response.code == 0) {
          setCaseData(response.case_list)
          setCaseTotalSum(response.total_sum)
          setCaseTotalCount(response.total_count)
        } else {
          console.error('查询案例出错了')
        }
      }
    )
  }

  // 点击开关
  const onSwitchClick = (slidesId: number, openStatus: number) => {
    const newCasesList = [...casesList]
    newCasesList.map((item) => {
      if (item.id == slidesId) {
        item.open = openStatus == 0 ? 1 : 0
      }
    })
    setCasesList(newCasesList)
    saveCourse(newCasesList)
  }

  // 案例点击了搜索
  const onCaseSearchBtn = (e: any) => {
    casePage.current = 1
    caseQueryContent.current = e.target.value
    queryCaseList()
  }

  // 实验结果表格发生变化
  const onCaseTableChange: any = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: { currentDataSource: []; action: any }
  ) => {
    if (sorter.order) {
      //如果选择了排序列
      caseOrder.current = sorter.order
      caseOrderBy.current = sorter.field
    }

    casePage.current = 1
    queryCaseList()
  }

  // 选择实验
  const caseRowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      selectedCaseRows.current = selectedRows
    },
    getCheckboxProps: (record: any) => ({
      disabled:
        casesList?.filter((item) => item.id == record.case_id).length > 0,
      //name: record.name,
    }),
  }

  /** 确认添加实验 */
  const handleAddCaseOk = () => {
    setAddCaseVisible(false)
    queryCaseIsMy.current = '2'
    const newCaseList = [...casesList]

    selectedCaseRows.current.map((row) => {
      newCaseList.push({
        id: row.case_id,
        name: row.name,
        open: 0,
        token: row.token,
      })
    })

    setCasesList(newCaseList)
    saveCourse(newCaseList)
  }

  // 搜索切换Tab页
  const onCaseTabKeyChange = (key: string) => {
    queryCaseIsMy.current = key
    casePage.current = 1
    queryCaseList()
  }

  // 案例页码和页数发生了变化
  const onCasePageChange = (
    newPage: number,
    newPageSize?: number | undefined
  ) => {
    casePage.current = newPage ? newPage : 1
    if (newPageSize) {
      casePageSize.current = newPageSize
    }
    queryCaseList()
  }

  /**
   * 创建课程回调
   * @param response
   */
  const saveCourseInfoCallback = (response: any) => {
    message.destroy()
    if (response.code == -100) {
      // 没登录
      document.getElementById('show-login-modal')!.click()
      // window.location.href = '/login?next=course';
    } else if (response.code == -501) {
      // 没权限
      message.error('权限不足')
      // window.location.href = '/login?next=course';
    } else if (response.is_ok == true) {
      //message.success("课程信息保存成功~")
      queryCases()
    } else {
      message.error(response.message)
    }
  }

  const caseModalCols: any = [
    {
      title: '序号',
      dataIndex: 'case_id',
      key: 'case_id',
      align: 'center',
      width: 80,
      render: (text, record, index) =>
        (casePage.current - 1) * casePageSize.current + index + 1,
    },
    {
      title: '实验名称',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: 200,
      ellipsis: true,
    },
    {
      title: '领域',
      dataIndex: 'domain',
      key: 'domain',
      align: 'center',
      width: 100,
    },
    {
      title: '创建时间',
      dataIndex: 'create_time',
      key: 'create_time',
      align: 'center',
      width: 150,
      sorter: true,
      ellipsis: true,
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'descend',
    },
    {
      title: '操作',
      dataIndex: 'actions',
      key: 'actions',
      width: 100,
      ellipsis: false,
      align: 'center',
      fixed: 'right',
      render: renderCaseActions,
    },
  ]

  /**
   * 点击保存课程按钮
   */
  const saveCourse = (caseList: any) => {
    sendHttpPostRequest(
      'course/api/user_update_course',
      {
        name: course?.name,
        course_id: course_id,
        case: JSON.stringify(caseList),
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
      },
      saveCourseInfoCallback
    )
  }

  const routeToCaseRun = (record: any, case_id: number, course_id?: any) => {
    if (!record?.is_system) {
      window.open(`/course-case-run?case_id=${case_id}?course_id=${course_id}`)
    } else {
      history.push(`/case-run?case_id=${case_id}`)
    }
  }

  const onRunCaseBtnClick = (record: any) => {
    if (!checkUserLogin()) {
      document.getElementById('show-login-modal')!.click()
    } else if (record.is_system) {
      let params = {
        case_id: record.id,
        course_id: course_id,
      }
      sendHttpPostRequest(
        'course/api/user_get_or_fork_course_case_by_id',
        params,
        (res) => {
          if (res.code == 0) {
            routeToCaseRun(record, res.case_info.case_id, course_id)
            // if (record.is_system) {
            //   window.open(`/case-run?case_id=${res.case_info.case_id}`)
            // } else {
            //   window.open(
            //     `/course-case-run?case_id=${res.case_info.case_id}?course_id=${course_id}`
            //   )
            // }

            queryCases()
          } else {
            message.error(res.message)
          }
        }
      )
    } else {
      routeToCaseRun(record, record.id, course_id)
    }
  }
  const onPreviewCaseBtnClick = (record: any) => {
    if (!checkUserLogin()) {
      document.getElementById('show-login-modal')!.click()
    } else {
      window.open(
        `/course-case-detail?case_id=${record.id}?course_id=${course_id}`
      )
    }
  }
  // 学生详情按钮
  const goStudetail = (id, isOpen) => {
    if (!isOpen) {
      return
    } else {
      history.push(
        `/course-main-teacher-studentdetail?course_id=${course_id}&case_id=${id}`
      )
    }
  }

  // 渲染操作
  const renderActions = (text: any, record: any) => {
    return (
      <div>
        {is_course_teacher && (
          <Switch
            style={{ marginRight: 10 }}
            size="small"
            checked={record.open ? true : false}
            onClick={onSwitchClick.bind(this, record.id, record.open)}
          />
        )}
        {/* {is_course_teacher && <Link  to={record.open?`/course-main-teacher-studentdetail?course_id=${course_id}&case_id=${record.id}`:window.location.pathname + window.location.search}><Button className={record.open ? courseTeacherCasesStyles.actBtn : courseTeacherCasesStyles.defaultBtn} style={{borderRadius:20,marginRight:5}} size='small' type='link'> 学生详情 </Button></Link>} */}
        {/* {is_course_teacher && (
          <Button
            onClick={() => goStudetail(record.id, record.open)}
            className={
              record.open
                ? courseTeacherCasesStyles.actBtn
                : courseTeacherCasesStyles.defaultBtn
            }
            style={{ borderRadius: 20, marginRight: 5 }}
            size="small"
            type="link"
          >
            {' '}
            学生详情{' '}
          </Button>
        )} */}
        <Button
          onClick={onPreviewCaseBtnClick.bind(this, record)}
          className={courseTeacherCasesStyles.actionBtn}
          style={{ borderRadius: 20, marginRight: 5 }}
          size="small"
          type="link"
        >
          {' '}
          查看{' '}
        </Button>
        {is_course_teacher && (
          <Button
            onClick={onRunCaseBtnClick.bind(this, record)}
            className={courseTeacherCasesStyles.actionBtn}
            style={{ borderRadius: 20, marginRight: 5 }}
            size="small"
            type="link"
          >
            {' '}
            {'运行'}{' '}
          </Button>
        )}

        {is_course_teacher && (
          <Popconfirm
            placement="top"
            title={'确认删除实验"' + record.name + '"吗？'}
            onConfirm={onDeleteCaseClick.bind(this, record.id)}
            okText="确认"
            cancelText="取消"
          >
            <Button
              className={courseTeacherCasesStyles.actionBtn}
              style={{ borderRadius: 20, marginRight: 5 }}
              size="small"
              type="link"
            >
              {' '}
              删除{' '}
            </Button>
          </Popconfirm>
        )}
      </div>
    )
  }
  const casesCols: any = [
    {
      title: '序号',
      dataIndex: 'id',
      key: 'id',
      align: 'left',
      width: 80,
      render: (text, record, index) => casesList.indexOf(record) + 1,
    },
    {
      title: '实验',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: 350,
      render: (text: any, record: any) => {
        return (
          <div
            style={{ cursor: 'pointer' }}
            onClick={onPreviewCaseBtnClick.bind(this, record)}
            className={courseTeacherCasesStyles.slideName}
          >
            {' '}
            {text}{' '}
          </div>
        )
      },
    },
    {
      title: (
        <Row
          align={'middle'}
          justify={'center'}
          wrap={false}
          style={{ gap: '10px' }}
        >
          <Col>
            <Tooltip
              placement="bottomLeft"
              title={
                '官方的实验，需要先Fork并运行；Fork过的实验，想要再次Fork,请点击查看按钮进预览页操作'
              }
            >
              {/* <InfoCircleOutlined
                style={{ color: '#4285F4', cursor: 'pointer' }}
              /> */}
              <img
                src={formatIcon}
                style={{ width: 16, height: 16, marginTop: -2 }}
                alt=""
              />
            </Tooltip>
          </Col>
          <Col>操作</Col>
        </Row>
      ),
      dataIndex: 'actions',
      key: 'actions',
      width: 200,
      ellipsis: false,
      align: 'center',
      render: renderActions,
    },
  ]

  useEffect(() => {
    queryCases()
  }, [])

  //页码和页数发生了变化
  const onPageChange = (newPage: number, newPageSize?: number | undefined) => {
    casePage.current = newPage ? newPage : 1
    if (newPageSize) {
      casePageSize.current = newPageSize
    }
    queryCaseList()
  }

  return (
    <div className={courseMainStyles.tabpaneWrapper}>
      <Row align="middle" style={{ marginTop: -10, marginBottom: 4 }}>
        <Col span={12} style={{ textAlign: 'left' }}></Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          {is_course_teacher && (
            <Button
              onClick={() => {
                queryCaseList()
                selectedCaseRows.current = []
                setAddCaseVisible(true)
              }}
              style={{ marginBottom: 10, width: 80, borderRadius: 20 }}
              type="primary"
            >
              {' '}
              + 添加
            </Button>
          )}
        </Col>
      </Row>

      <div className={courseTeacherCasesStyles.tableWrapper}>
        {casesList.length != 0 && (
          <ConfigProvider locale={zhCN}>
            <Table
              dataSource={casesList}
              columns={casesCols}
              size="small"
              rowKey={(record) => record.id}
              pagination={{
                position: ['bottomCenter'],
                pageSize: 10,
                size: 'default',
                hideOnSinglePage: true,
              }}
            />
          </ConfigProvider>
        )}

        {casesList.length == 0 && (
          <div style={{ textAlign: 'center', paddingTop: 20 }}>
            <img src={shiyanEmpty} width={120} height={120} />
            <div style={{ color: '#999', marginTop: 10 }}>
              您还没有添加实验哟~
            </div>
          </div>
        )}
      </div>
      <ConfigProvider locale={zhCN}>
        <Modal
          title={
            <div
              style={{ width: '100%', cursor: 'move' }}
              onMouseOver={() => {
                if (disabled) {
                  setDisabled(false)
                }
              }}
              onMouseOut={() => {
                setDisabled(true)
              }}
              onFocus={() => {}}
              onBlur={() => {}}
            >
              添加实验
            </div>
          }
          visible={addCaseVisible}
          width={800}
          bodyStyle={{ minHeight: 400, overflow: 'auto' }}
          onOk={handleAddCaseOk}
          onCancel={() => {
            queryCaseIsMy.current = '2'
            setAddCaseVisible(false)
          }}
          destroyOnClose={true}
          closable={false}
          modalRender={(modal) => (
            <div className={moduleStyles.addModal}>
              <Draggable
                disabled={disabled}
                bounds={bounds}
                onStart={(event, uiData) => onStart(event, uiData)}
              >
                <div ref={draggleRef}>{modal}</div>
              </Draggable>{' '}
            </div>
          )}
        >
          <div>
            <Input
              onPressEnter={onCaseSearchBtn}
              placeholder="搜索实验（名称或ID）"
              style={{ borderRadius: 20, width: 300, marginBottom: 10 }}
              suffix={<SearchOutlined style={{ color: '#666666' }} />}
            />
          </div>

          <Tabs defaultActiveKey="2" onChange={onCaseTabKeyChange}>
            <TabPane tab="平台" key="2" className="">
              <div className={courseCreateStyles.tableWrapper}>
                <ConfigProvider locale={zhCN}>
                  <Table
                    dataSource={caseData}
                    columns={caseModalCols}
                    rowKey={(record) => record.case_id}
                    pagination={false}
                    size="small"
                    //scroll={{ x: 600 }}
                    //bordered={true}
                    onChange={onCaseTableChange}
                    rowSelection={{
                      type: 'checkbox',
                      ...caseRowSelection,
                    }}
                  />
                </ConfigProvider>
              </div>
            </TabPane>
            <TabPane tab="我的" key="1" className="">
              <div className={courseCreateStyles.tableWrapper}>
                <ConfigProvider locale={zhCN}>
                  <Table
                    dataSource={caseData}
                    columns={caseModalCols}
                    rowKey={(record) => record.case_id}
                    pagination={false}
                    size="small"
                    //scroll={{ x: 600 }}
                    //bordered={true}
                    onChange={onCaseTableChange}
                    rowSelection={{
                      type: 'checkbox',
                      ...caseRowSelection,
                    }}
                  />
                </ConfigProvider>
              </div>
            </TabPane>
          </Tabs>

          <ConfigProvider locale={zhCN}>
            <div>
              <Pagination
                defaultCurrent={1}
                total={caseTotalSum}
                current={casePage.current}
                defaultPageSize={10}
                hideOnSinglePage={true}
                onChange={onPageChange}
              />
            </div>
          </ConfigProvider>
        </Modal>
      </ConfigProvider>
    </div>
  )
}

export default CourseMainTeacherCases
