import React, { useEffect, useRef, useState } from 'react'
import {
  Steps,
  Popconfirm,
  Input,
  InputNumber,
  Table,
  message,
  Col,
  Row,
  ConfigProvider,
  Pagination,
  Tabs,
  Button,
  Tag,
  Checkbox,
  Modal,
  Radio,
} from 'antd'
import { sendHttpPostRequest } from '../../../api/http-request'
import selectExeStyles from './course-teacher-select-exercises.module.less'
import selectedExeStyles from './course-teacher-selected-exercises.module.less'
import examEditStyles from './course-teacher-exam-edit.module.less'
import zhCN from 'antd/lib/locale/zh_CN'
import LoginRegisterModal from '../../../component/modal/login-register/login-register'
import { checkUserLogin } from '../../common/user-center/user-info'
import {
  PlayCircleOutlined,
  PieChartOutlined,
  UnorderedListOutlined,
  DownOutlined,
  UpOutlined,
  PlusCircleTwoTone,
  MinusCircleTwoTone,
  SearchOutlined,
  CheckOutlined,
  FileWordOutlined,
  TableOutlined,
} from '@ant-design/icons'
import ExerciseCard from '../../exercise/exercise-card'
import Cookies from 'js-cookie'
import 'echarts-wordcloud'
import * as echarts from 'echarts/core'
import {
  TitleComponent,
  TitleComponentOption,
  TooltipComponent,
  TooltipComponentOption,
  LegendComponent,
  LegendComponentOption,
} from 'echarts/components'
import { PieChart, PieSeriesOption } from 'echarts/charts'
import { LabelLayout } from 'echarts/features'
import { CanvasRenderer } from 'echarts/renderers'

import {
  DeleteOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
} from '@ant-design/icons'
import Draggable from 'react-draggable'

echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer,
  LabelLayout,
])

type EChartsOption = echarts.ComposeOption<
  | TitleComponentOption
  | TooltipComponentOption
  | LegendComponentOption
  | PieSeriesOption
>

const { CheckableTag } = Tag
const format = 'HH:mm'
const { Step } = Steps
const { TextArea } = Input
const { TabPane } = Tabs
const { Search } = Input
interface Course {
  course_id: number
  name: string
  cover: string
}

const typeMap = new Map([
  [1, '实操题'],
  [2, '单选题'],
  [3, '多选题'],
  [4, '排序题'],
  [5, '主观题'],
])
const difficultyMap = new Map([
  [1, '容易'],
  [2, '中等'],
  [3, '困难'],
])

export interface Props {
  course_id: any //当前课程ID
  menu_id: any //当前课程的大纲ID
  initExercises: any //初始的选题列表
  onExamExerciseChange: any //选题改变的回调函数
}
/** 教师组卷页面，包括已选题和添加题面板 */
export const CourseTeacherExamComposition: React.FC<Props> = (props) => {
  const { course_id, menu_id, initExercises, onExamExerciseChange } = props

  const [menu, setMenu] = useState(null as any)
  const [course, setCourse] = useState(null as any)

  const [examExerciseList, setExamExerciseList] = useState(initExercises) //存储当前试卷的考题列表

  const [activeTabkKey, setActiveTabkKey] = useState('1')

  useEffect(() => {
    queryCourse()
    queryMenu()
  }, [])

  const queryMenu = () => {
    sendHttpPostRequest(
      'course/api/user_query_menu_by_id',
      {
        sessionid: Cookies.get('sessionid'),
        menu_id: menu_id,
      },
      queryMenuCallback
    )
  }

  /**
   * 查询大纲回调
   * @param response
   */
  const queryMenuCallback = (response: any) => {
    console.log(response)
    //message.destroy();
    if (response.code == -100) {
      // 未登录
      document.getElementById('show-login-modal')!.click()
      // window.location.href = '/oam_login?next=oam_menu';
    } else if (response.is_ok == true) {
      // 成功
      setMenu(response.query_info)
      //console.log(response.query_info);
    } /*else if (response.code == -501) {
        权限不足
        window.location.href = '/oam_login?next=oam_menu';
    } */ else {
      message.error(response.message)
    }
  }

  // 查询课程信息
  const queryCourse = () => {
    const sessionid = Cookies.get('sessionid')
    const queryData = {
      source: 1,
      course_id: course_id,
      sessionid: sessionid,
      sid: sessionid,
    }
    sendHttpPostRequest(
      'course/api/user_query_course_by_id',
      queryData,
      courseCallback
    )
  }

  // 查询课程接口回调
  const courseCallback = (response: any) => {
    console.log(response)
    message.destroy()
    if (response.code == -100) {
      // 未登录
      document.getElementById('show-login-modal')!.click()
      // window.location.href = '/login?next=/course';
    } else if (response.is_ok == true) {
      // 成功
      setCourse(response.query_info)
    } else if (response.code == -501) {
      // 权限不足
      message.error('权限不足')
      // window.location.href = '/login?next=course';
    } else {
      message.error(response.message)
    }
  }

  interface Props2 {}
  interface PropsSelectCourse {
    currentCourse: any //当前课程对象
  }
  /** 教师选题面板 */
  const CourseTeacherSelectExercise: React.FC<PropsSelectCourse> = (props) => {
    const { currentCourse } = props //当前课程

    const [exerciseResult, setExerciseResult] = useState([] as any[]) // 当前显示的结果页

    const queryContent = useRef('') // 记录搜索串
    const source = useRef('1') //1/按照选择的知识点选择；2、查我创建的

    const order = useRef('') // 排序升序降序
    const order_by = useRef('') // 排序列
    const page = useRef(1) // 记录当前的页数
    const pageSize = useRef(10) // 记录当前每页展示数量
    const typeFilters = useRef([]) // 类型过滤选项
    const difficultyFilters = useRef([]) // 难易程度过滤选项

    const [courseExerciseHidden, setCourseExerciseHidden] = useState(false)

    const [courseAddHidden, setCourseAddHidden] = useState(true)

    const [resultSum, setResultSum] = useState(0) // 搜索返回的结果数

    const selectedModules = useRef([] as number[]) //已选择的知识点

    const collegeId = useRef(null as any) //记录当前机构ID

    const queryCourseSource = useRef('1') //搜索课程来源；1 平台；3 我的。
    const [queryCourseList, setQueryCourseList] = useState([] as any[]) //查询课程列表

    const [selectedMyCourses, setSelectedMyCourses] = useState([] as any[]) //记录已选择的我的课程
    const [selectedSystemCourses, setSelectedSystemCourses] = useState(
      [] as any[]
    ) //记录已选择的我的课程
    const [selectedCourses, setSelectedCourses] = useState([] as any[]) //记录已选择的课程

    const [courseMenuList, setCourseMenuList] = useState([] as any) // 保存课程和大纲的列表

    //选择了课程发生了改变
    const handleSelectMyCoursesChange = (checkedValue: any) => {
      setSelectedMyCourses(checkedValue)
      page.current = 1
      queryCourseMenuList([...checkedValue, ...selectedSystemCourses])
      setSelectedCourses([...checkedValue, ...selectedSystemCourses])
    }

    //选择了课程发生了改变
    const handleSelectSystemCoursesChange = (checkedValue: any) => {
      setSelectedSystemCourses(checkedValue)
      page.current = 1
      queryCourseMenuList([...selectedMyCourses, ...checkedValue])
      setSelectedCourses([...selectedMyCourses, ...checkedValue])
    }

    /** 查询的课程是否默认选中*/
    const courseDefaultChecked = (courseId: any) => {
      if (currentCourse?.id == courseId) {
        return true
      }
      if (
        selectedCourses.filter((course) => course.id == courseId).length > 0
      ) {
        return true
      } else {
        return false
      }
    }

    //选择了课程的知识点
    const handleSelectModulesChange = (tag: number, checked: boolean) => {
      const selectedTags = selectedModules.current
      const nextSelectedTags = checked
        ? [...selectedTags, tag]
        : selectedTags.filter((t) => t !== tag)
      selectedModules.current = nextSelectedTags
      page.current = 1
      queryExerciseList()
    }

    useEffect(() => {
      /** 获取机构ID */
      sendHttpPostRequest(
        'account/api/query_teacher_authenticate',
        {
          sessionid: Cookies.get('sessionid'),
          sid: Cookies.get('sessionid'),
        },
        (response: any) => {
          if (response?.college_info?.college_id) {
            collegeId.current = response.college_info.college_id
            queryCollegeCourseList(response.college_info.college_id)
          }
        }
      )
      queryExerciseList()
    }, [])
    /**
     * 查询习题列表
     */
    const queryExerciseList = () => {
      sendHttpPostRequest(
        'exam/api/user_query_exam_exercise_list/',
        {
          sessionid: Cookies.get('sessionid'),
          course_id: course_id,
          query: queryContent.current,
          page: page.current,
          pagesize: pageSize.current,

          order: order.current,
          order_by: order_by.current,
          type: JSON.stringify(typeFilters.current),
          difficulty: JSON.stringify(difficultyFilters.current),

          source: source.current,
          know_list: JSON.stringify(selectedModules.current),
        },
        queryExerciseListCallback
      )
    }

    /**
     * 查询习题列表回调
     * @param response
     */
    const queryExerciseListCallback = (response: any) => {
      console.log(response)
      message.destroy()
      if (response.code == -100) {
        // 未登录
        window.location.href = '/login?next=exercise'
      } else if (response.is_ok == true) {
        // 成功
        setExerciseResult(response.query_list)
        setResultSum(response.total_sum)
      } else if (response.code == -501) {
        // 权限不足
        window.location.href = '/login?next=exercise'
      } else {
        message.error(response.message)
      }
    }

    // 查询课程列表
    const queryCollegeCourseList = (collegeId: any) => {
      sendHttpPostRequest(
        'course/api/user_query_college_course_list',
        {
          sessionid: Cookies.get('sessionid'),
          sid: Cookies.get('sessionid'),
          college_id: collegeId,
          source: queryCourseSource.current,
          page: 1,
          pagesize: 500000, //基本不适用分页功能。设置一个很大的数
        },
        (response: any) => {
          message.destroy()
          if (response.code == -100) {
            // 没登录
            document.getElementById('show-login-modal')!.click()
          } else if (response.code == -501) {
            // 没权限
          } else if (response.is_ok == true) {
            if (response.query_list) {
              if (queryCourseSource.current == '1') {
                setQueryCourseList(
                  response.query_list.filter(
                    (item) => item.ispay == '已购买' && item.isoutdate == 0
                  )
                )
              } else {
                setQueryCourseList(response.query_list)
              }
            }
          }
        }
      )
    }

    // 查询课程列表的大纲信息
    const queryCourseMenuList = (course_id_list: any) => {
      sendHttpPostRequest(
        'course/api/user_query_course_menu',
        {
          sessionid: Cookies.get('sessionid'),
          sid: Cookies.get('sessionid'),
          course_id_list: JSON.stringify(course_id_list),
        },
        (response: any) => {
          message.destroy()
          if (response.code == -100) {
            // 没登录
            document.getElementById('show-login-modal')!.click()
          } else if (response.code == -501) {
            // 没权限
          } else if (response.is_ok == true) {
            if (response.query_list) {
              setCourseMenuList(response.query_list)
            }
          }
        }
      )
    }

    // 渲染类型
    const renderType = (text: any, record: any) => (
      <div>{typeMap.get(parseInt(text))}</div>
    )

    // 渲染难易程度
    const renderDifficulty = (text: any, record: any) => (
      <div>{difficultyMap.get(parseInt(text))}</div>
    )

    const [selectedRowKeys, setSelectedRowKeys] = useState([] as any[])

    const onSelectChange = (selectedRows: any) => {
      setSelectedRowKeys(selectedRows)
    }

    const rowSelection = {
      width: 60,
      selectedRowKeys,
      onChange: onSelectChange,
      preserveSelectedRowKeys: false,
      getCheckboxProps: (record) => {
        if (
          examExerciseList.filter((item) => item.id == record.id).length > 0
        ) {
          return { disabled: true }
        } else {
          return { disabled: false }
        }
      },
    }
    /** 将当前选中的题目添加到试卷 */
    const addExercises2Exam = () => {
      const selectedExercise = exerciseResult.filter((item) => {
        return selectedRowKeys.indexOf(item.id) >= 0
      })
      //去重
      const filteredSlectedExercise = [] as any[]
      selectedExercise.forEach((exercise) => {
        if (
          examExerciseList.filter((item) => item.id == exercise.id).length == 0
        ) {
          exercise.score = 5 //默认分数5分
          filteredSlectedExercise.push(exercise)
        }

        const newList = [...examExerciseList]
        const newExamList = newList.concat(filteredSlectedExercise)

        setExamExerciseList(newExamList)
        if (onExamExerciseChange) {
          /** 传给父组件 */
          onExamExerciseChange(newExamList)
        }
      })
    }
    const columns: any = [
      {
        title: '题目',
        dataIndex: 'title',
        key: 'title',
        align: 'left',
        width: 210,
        ellipsis: 'true',
      },
      {
        title: '题型',
        dataIndex: 'type',
        key: 'type',
        align: 'center',
        width: 100,
        render: renderType,
        filters: [
          {
            text: '实操题',
            value: 1,
          },
          {
            text: '单选题',
            value: 2,
          },
          {
            text: '多选题',
            value: 3,
          },
          {
            text: '排序题',
            value: 4,
          },
          {
            text: '主观题',
            value: 5,
          },
        ],
        onFilter: (value: any, record: any) => true,
      },
      {
        title: '难度',
        dataIndex: 'difficulty',
        key: 'difficulty',
        align: 'center',
        width: 80,
        render: renderDifficulty,
        filters: [
          {
            text: '容易',
            value: 1,
          },
          {
            text: '中等',
            value: 2,
          },
          {
            text: '困难',
            value: 3,
          },
        ],
        onFilter: (value: any, record: any) => true,
      },
    ]

    // 表格发生变化
    const onTableChange: any = (
      pagination: any,
      filters: any,
      sorter: any,
      extra: { currentDataSource: []; action: any }
    ) => {
      if (sorter.order) {
        //如果选择了排序列
        order.current = sorter.order
        order_by.current = sorter.field
      }
      if (filters.type) {
        //如果选择了过滤列
        typeFilters.current = filters.type
      } else {
        typeFilters.current = []
      }
      if (filters.difficulty) {
        difficultyFilters.current = filters.difficulty
      } else {
        difficultyFilters.current = []
      }
      page.current = 1
      queryExerciseList()
    }

    /** 渲染预览的习题 */
    const renderExercisePreview = (record: any) => {
      return (
        <ExerciseCard
          exercise={record}
          renderType="white"
          teacherOnly={true}
          editorMode={true}
          showAnser={false}
          showClean={true}
        />
      )
    }

    //页码和页数发生了变化
    const onPageChange = (
      newPage: number,
      newPageSize?: number | undefined
    ) => {
      page.current = newPage ? newPage : 1
      if (newPageSize) {
        pageSize.current = newPageSize
      }
      queryExerciseList()
    }

    return (
      <div style={{ marginTop: 10, marginBottom: 20 }}>
        <div className={examEditStyles.searchTabDivWrapper}>
          <Tabs
            defaultActiveKey={'1'}
            type="card"
            size="middle"
            tabPosition="top"
            onTabClick={(key, event) => {
              source.current = key
              page.current = 1
              queryExerciseList()
            }}
            style={{ position: 'relative' }}
          >
            <TabPane tab={<div>课程题库</div>} key="1">
              <div
                hidden={courseExerciseHidden}
                style={{
                  height: 200,
                  overflowY: 'auto',
                  backgroundColor: '#F5FAF7',
                  padding: 16,
                  paddingTop: 10,
                  marginBottom: 20,
                }}
              >
                <Input
                  onPressEnter={(event: any) => {
                    queryContent.current = event.target.value
                    page.current = 1
                    queryExerciseList()
                  }}
                  placeholder="搜索题目"
                  style={{
                    float: 'left',
                    borderRadius: 20,
                    width: 250,
                    borderColor: '#07c160',
                    position: 'absolute',
                    right: 18,
                    top: -40,
                  }}
                  suffix={<SearchOutlined style={{ color: '#07c160' }} />}
                />

                <Row>
                  <Col span={20}>
                    <div style={{ float: 'left' }}>
                      <span className={examEditStyles.courseOutLabel}>
                        {course.label}
                      </span>
                      <span
                        className={examEditStyles.courseOutSeparator}
                      ></span>
                      <span
                        style={{
                          display: 'inline-block',
                          textAlign: 'left',
                          color: '#333',
                          fontWeight: 700,
                          fontSize: 17,
                          marginRight: 10,
                        }}
                      >
                        {course?.name}
                      </span>
                      <span className={examEditStyles.courseOutExtra}>
                        （&nbsp;&nbsp;课程编号&nbsp;:&nbsp;{course.code}
                      </span>
                      <span className={examEditStyles.courseOutExtra}>
                        建课教师&nbsp;:&nbsp;
                        {course.teacher_real_name != ''
                          ? course.teacher_real_name
                          : course.teacher_name}
                        &nbsp;&nbsp;）
                      </span>
                    </div>
                  </Col>
                  <Col span={4}>
                    <Button
                      type="default"
                      style={{ borderRadius: 4, padding: '0 10px' }}
                      onClick={() => {
                        setCourseExerciseHidden(true)
                        setCourseAddHidden(false)
                      }}
                    >
                      添加课程
                    </Button>
                  </Col>
                </Row>
                <Row
                  style={{ fontSize: 12, marginTop: 10 }}
                  justify="start"
                  align="top"
                  gutter={[8, 8]}
                >
                  {menu?.json?.map((chapter, chapterInd) => (
                    <Col
                      style={{ textAlign: 'left' }}
                      span={8}
                      key={'chapter' + chapter.id}
                    >
                      <div style={{ marginBottom: 5 }}>
                        {' '}
                        {'第' + (chapterInd + 1) + '章 ' + chapter.name}
                      </div>
                      {chapter.tasks?.map((module, moduleInd) => (
                        <CheckableTag
                          style={{ marginBottom: 5, minWidth: 120, padding: 0 }}
                          key={module.id}
                          checked={
                            selectedModules.current.indexOf(module.id) > -1
                          }
                          onChange={(checked) =>
                            handleSelectModulesChange(module.id, checked)
                          }
                        >
                          <CheckOutlined
                            hidden={
                              !(selectedModules.current.indexOf(module.id) > -1)
                            }
                          />
                          <span style={{ margin: 0 }}> {module.name} </span>
                        </CheckableTag>
                      ))}
                    </Col>
                  ))}
                </Row>

                {courseMenuList?.map((item) => (
                  <div key={item.id}>
                    <Row>
                      <Col span={21}>
                        <h3
                          style={{
                            textAlign: 'left',
                            color: '#333',
                            fontWeight: 700,
                          }}
                        >
                          {item?.name}
                        </h3>
                      </Col>
                    </Row>
                    <Row
                      style={{ fontSize: 12, marginTop: 10 }}
                      justify="start"
                      align="top"
                      gutter={[8, 8]}
                    >
                      {item?.menu?.map((chapter, chapterInd) => (
                        <Col
                          style={{ textAlign: 'left' }}
                          span={8}
                          key={'chapter' + chapter.id}
                        >
                          <div style={{ marginBottom: 5 }}>
                            {' '}
                            {'第' + (chapterInd + 1) + '章 ' + chapter.name}
                          </div>
                          {chapter.tasks?.map((module, moduleInd) => (
                            <CheckableTag
                              style={{
                                marginBottom: 5,
                                minWidth: 120,
                                padding: 0,
                              }}
                              key={module.id}
                              checked={
                                selectedModules.current.indexOf(module.id) > -1
                              }
                              onChange={(checked) =>
                                handleSelectModulesChange(module.id, checked)
                              }
                            >
                              <CheckOutlined
                                hidden={
                                  !(
                                    selectedModules.current.indexOf(module.id) >
                                    -1
                                  )
                                }
                              />
                              <span style={{ margin: 0 }}> {module.name} </span>
                            </CheckableTag>
                          ))}
                        </Col>
                      ))}
                    </Row>{' '}
                  </div>
                ))}
              </div>

              <div
                hidden={courseAddHidden}
                style={{
                  height: 200,
                  overflowY: 'auto',
                  backgroundColor: '#F5FAF7',
                  padding: 16,
                  paddingTop: 10,
                  marginBottom: 20,
                }}
              >
                <Input
                  onPressEnter={(event: any) => {
                    queryContent.current = event.target.value
                    page.current = 1
                    queryExerciseList()
                  }}
                  placeholder="搜索题目"
                  style={{
                    float: 'left',
                    borderRadius: 20,
                    width: 250,
                    borderColor: '#07c160',
                    position: 'absolute',
                    right: 18,
                    top: -40,
                  }}
                  suffix={<SearchOutlined style={{ color: '#07c160' }} />}
                />
                {/* <Row>
                    <Col flex="auto" style={{textAlign:'right'}}>
                      <Button type="primary" style={{borderRadius:4,width:50,height:28,padding:0,marginRight:10}} onClick={()=>{setCourseExerciseHidden(false);setCourseAddHidden(true);}}> 确认 </Button>
                      <Button type="default" style={{borderRadius:4,width:50,height:28,padding:0}} onClick={()=>{setCourseExerciseHidden(false);setCourseAddHidden(true);}}> 关闭 </Button>
                    </Col>
                  </Row> */}
                <Row
                  style={{ fontSize: 14, marginTop: 0, position: 'relative' }}
                  justify="start"
                  align="top"
                  gutter={[8, 8]}
                >
                  <div
                    style={{
                      position: 'absolute',
                      right: 0,
                      top: 0,
                      width: 120,
                      height: 28,
                      zIndex: 99,
                    }}
                  >
                    <Button
                      type="primary"
                      style={{
                        borderRadius: 4,
                        width: 50,
                        height: 28,
                        padding: 0,
                        marginRight: 10,
                      }}
                      onClick={() => {
                        setCourseExerciseHidden(false)
                        setCourseAddHidden(true)
                      }}
                    >
                      {' '}
                      确认{' '}
                    </Button>
                    <Button
                      type="default"
                      style={{
                        borderRadius: 4,
                        width: 50,
                        height: 28,
                        padding: 0,
                      }}
                      onClick={() => {
                        setCourseExerciseHidden(false)
                        setCourseAddHidden(true)
                      }}
                    >
                      {' '}
                      关闭{' '}
                    </Button>
                  </div>
                  <Tabs
                    defaultActiveKey={'1'}
                    type="card"
                    size="middle"
                    tabPosition="top"
                    onTabClick={(key, event) => {
                      queryCourseSource.current = key
                      queryCollegeCourseList(collegeId.current)
                    }}
                    style={{ position: 'relative' }}
                  >
                    <TabPane tab={<div>平台课程</div>} key="1">
                      <div style={{ marginBottom: 10 }}>
                        <Checkbox.Group
                          onChange={handleSelectSystemCoursesChange}
                        >
                          {queryCourseList?.map((course) => (
                            <Checkbox
                              key={course.id}
                              value={course.id}
                              defaultChecked={courseDefaultChecked(course.id)}
                              disabled={currentCourse?.id == course.id}
                            >
                              {course.name}
                            </Checkbox>
                          ))}
                        </Checkbox.Group>
                      </div>
                    </TabPane>

                    <TabPane tab={<div>我的课程</div>} key="3">
                      <div style={{ marginBottom: 10 }}>
                        <Checkbox.Group onChange={handleSelectMyCoursesChange}>
                          {queryCourseList?.map((course) => (
                            <Checkbox
                              key={course.id}
                              value={course.id}
                              defaultChecked={courseDefaultChecked(course.id)}
                              disabled={currentCourse?.id == course.id}
                            >
                              <div className={examEditStyles.courseBasic}>
                                <span className={examEditStyles.courseLabel}>
                                  {course.label}
                                </span>
                                <span
                                  className={examEditStyles.courseSeparator}
                                ></span>
                                <span>
                                  {course.id}-{course.name}
                                </span>
                              </div>
                              <div className={examEditStyles.courseExtra}>
                                <span>课程编号</span>
                                <span>&nbsp;:&nbsp;</span>
                                <span>{course.code}</span>
                              </div>
                              {course.label == '共同管理' && (
                                <div className={examEditStyles.courseExtra}>
                                  <span>建课教师</span>
                                  <span>&nbsp;:&nbsp;</span>
                                  <span>
                                    {course.teacher_real_name != ''
                                      ? course.teacher_real_name
                                      : course.teacher_name}
                                  </span>
                                </div>
                              )}
                            </Checkbox>
                          ))}
                        </Checkbox.Group>
                      </div>
                    </TabPane>
                  </Tabs>
                </Row>
              </div>
            </TabPane>
            <TabPane tab={<div>我的题库</div>} key="2">
              <div style={{ marginBottom: 10 }}>
                <Input
                  onPressEnter={(event: any) => {
                    queryContent.current = event.target.value
                    page.current = 1
                    queryExerciseList()
                  }}
                  placeholder="搜索题目"
                  style={{
                    float: 'left',
                    borderRadius: 20,
                    width: 250,
                    borderColor: '#07c160',
                    position: 'absolute',
                    right: 18,
                    top: -50,
                  }}
                  suffix={<SearchOutlined style={{ color: '#07c160' }} />}
                />
              </div>
            </TabPane>
          </Tabs>
        </div>
        <div style={{ marginBottom: 10, float: 'left' }}>
          <span style={{ color: '#00000073' }}>已选：</span>{' '}
          <span style={{ fontWeight: 500, color: '#07C160', marginRight: 20 }}>
            {selectedRowKeys?.length}{' '}
          </span>
          <Button
            disabled={selectedRowKeys?.length == 0}
            onClick={addExercises2Exam}
            type="primary"
            size="small"
            style={{ borderRadius: 20 }}
          >
            {' '}
            + 加到试卷{' '}
          </Button>
        </div>
        <div className={selectExeStyles.tableWrapper}>
          <ConfigProvider locale={zhCN}>
            <Table
              dataSource={exerciseResult}
              columns={columns}
              size="small"
              rowKey={(record) => record.id}
              pagination={false}
              onChange={onTableChange}
              rowSelection={rowSelection}
              expandable={{
                columnWidth: 90,
                childrenColumnName: '预览',
                expandIconColumnIndex: 5,
                expandIcon: ({ expanded, onExpand, record }) =>
                  expanded ? (
                    <div
                      onClick={(e) => onExpand(record, e)}
                      style={{ cursor: 'pointer' }}
                    >
                      预览
                      <UpOutlined className={selectExeStyles.actionBtn} />
                    </div>
                  ) : (
                    <div
                      onClick={(e) => onExpand(record, e)}
                      style={{ cursor: 'pointer' }}
                    >
                      预览
                      <DownOutlined className={selectExeStyles.actionBtn} />
                    </div>
                  ),
                expandedRowRender: renderExercisePreview,
                rowExpandable: (record) => record.name !== 'Not Expandable',
              }}
            />

            <Pagination
              hideOnSinglePage={true}
              className={selectExeStyles.pagination}
              style={{ marginTop: 20 }}
              defaultCurrent={1}
              total={resultSum}
              current={page.current}
              onChange={onPageChange}
              showQuickJumper={true}
              // showTotal={total => `找到 ${total} 道习题`}
              pageSize={10}
            />
          </ConfigProvider>
        </div>
        {/* </TabPane>
  
        </Tabs> */}
      </div>
    )
  }

  /** 教师已选题编辑面板 */
  const CourseTeacherSelectedExercise: React.FC<Props2> = (props) => {
    const typeFilters = useRef([]) // 类型过滤选项
    const difficultyFilters = useRef([]) // 难易程度过滤选项
    const createTime = useRef('') // 创建时间

    const [settingScore, setSettingScore] = useState(5) //保存给选中题目统一设置的分数

    const singleExeId = useRef(null as any) //单道题设置分数记录题目Id
    const singleScore = useRef(0) //单道题设置分数记录题目分数

    const [showExamTableMode, setShowExamTableMode] = useState(true)
    const [showExamExpandMode, setShowExamExpandMode] = useState(false)
    const [showExamStatMode, setShowExamStatMode] = useState(false)

    const [selectedRowKeys, setSelectedRowKeys] = useState([] as number[])

    const handleExamTableModeChange = (checked) => {
      setShowExamTableMode(true)
      setShowExamExpandMode(false)
      setShowExamStatMode(false)
    }

    const handleExamExpandModeChange = (checked) => {
      setShowExamTableMode(false)
      setShowExamExpandMode(true)
      setShowExamStatMode(false)
    }

    const handleExamStatModeChange = (checked) => {
      setShowExamTableMode(false)
      setShowExamExpandMode(false)
      setShowExamStatMode(true)
    }

    useEffect(() => {}, [])

    const [isModalVisible, setIsModalVisible] = useState(false)

    const draggleRef = useRef<HTMLDivElement | null>(null)

    const [bounds, setBounds] = useState({
      left: 0,
      top: 0,
      bottom: 0,
      right: 0,
    })
    const [disabled, setDisabled] = useState(true)

    const onStart = (event: any, uiData: any) => {
      const { clientWidth, clientHeight } = window?.document?.documentElement
      const targetRect = draggleRef?.current?.getBoundingClientRect()
      if (targetRect) {
        setBounds({
          left: -targetRect?.left + uiData?.x,
          right: clientWidth - (targetRect?.right - uiData?.x),
          top: -targetRect?.top + uiData?.y,
          bottom: clientHeight - (targetRect?.bottom - uiData?.y),
        })
      }
    }

    const handleOk = () => {
      //确认统一设置试题分数
      const newExerciseList = [...examExerciseList]
      newExerciseList.map((item) => {
        if (selectedRowKeys.indexOf(item.id) >= 0) {
          item.score = settingScore
        }
      })
      setExamExerciseList(newExerciseList)
      setIsModalVisible(false)
    }

    const handleCancel = () => {
      setIsModalVisible(false)
    }
    // 渲染类型
    const renderType = (text: any, record: any) => (
      <div>{typeMap.get(parseInt(text))}</div>
    )

    // 渲染难易程度
    const renderDifficulty = (text: any, record: any) => (
      <div>{difficultyMap.get(parseInt(text))}</div>
    )

    // 点击删除按钮
    const onDeleteBtnClick = (exerciseTitle: string, exeId: number) => {
      const newExerciseResult = examExerciseList.filter(
        (item: any) => exeId !== item.id
      )
      setExamExerciseList(newExerciseResult)
      if (onExamExerciseChange) {
        /** 传给父组件 */
        onExamExerciseChange(newExerciseResult)
      }
    }

    // 点击上移按钮
    const onUpBtnClick = (exerciseTitle: string, exeId: number, event: any) => {
      //stopBubbling(event)
      const currentExe = examExerciseList.filter(
        (item: any) => exeId == item.id
      )[0]
      const index = examExerciseList.indexOf(currentExe)
      if (index > 0) {
        const removed = examExerciseList.splice(index, 1)[0]
        examExerciseList.splice(index - 1, 0, removed)
        setExamExerciseList(JSON.parse(JSON.stringify(examExerciseList)))
        if (onExamExerciseChange) {
          /** 传给父组件 */
          onExamExerciseChange(JSON.parse(JSON.stringify(examExerciseList)))
        }
      } else {
        message.warn('已经是第一题了，不能再往前了~')
      }
      console.log(event)
    }

    // 点击下移按钮
    const onDownBtnClick = (exerciseTitle: string, exeId: number) => {
      const currentExe = examExerciseList.filter(
        (item: any) => exeId == item.id
      )[0]
      const index = examExerciseList.indexOf(currentExe)
      if (index < examExerciseList.length - 1) {
        const removed = examExerciseList.splice(index, 1)[0]
        examExerciseList.splice(index + 1, 0, removed)
        setExamExerciseList(JSON.parse(JSON.stringify(examExerciseList)))
        if (onExamExerciseChange) {
          /** 传给父组件 */
          onExamExerciseChange(JSON.parse(JSON.stringify(examExerciseList)))
        }
      } else {
        message.warn('已经是最后一题了，不能再往后了~')
      }
    }

    // 渲染操作
    const renderActions = (text: any, record: any) => {
      return (
        <>
          <Popconfirm
            placement="top"
            title={'确认删除习题"' + record.title + '"吗？'}
            onConfirm={onDeleteBtnClick.bind(this, record.title, record.id)}
            okText="确认"
            cancelText="取消"
          >
            <DeleteOutlined className={selectedExeStyles.actionBtn} />
          </Popconfirm>
          <ArrowUpOutlined
            hidden={selectedRowKeys.indexOf(record.id) < 0}
            onClick={onUpBtnClick.bind(this, record.title, record.id)}
            className={selectExeStyles.actionBtn}
          />
          <ArrowDownOutlined
            hidden={selectedRowKeys.indexOf(record.id) < 0}
            onClick={onDownBtnClick.bind(this, record.title, record.id)}
            className={selectExeStyles.actionBtn}
          />
        </>
      )
    }

    const getTotalScore = () => {
      let sumScore = 0
      examExerciseList.map((item) => {
        if (parseInt(item.score) == 0) sumScore += 5
        else sumScore += parseInt(item.score)
      })
      return sumScore
    }

    //修改单个题目的分值
    const singleExerciseScoreChange = (exeId: number, value: number) => {
      singleExeId.current = exeId
      singleScore.current = value
      console.log(exeId)
      console.log(value)
    }

    //修改单个题目的分值,确认
    const singleExerciseScoreConfirm = () => {
      if (singleScore.current <= 0) {
        message.error('不能输入小于0的数。')
      } else {
        const newExerciseList = [...examExerciseList]
        newExerciseList.map((item) => {
          if (item.id == singleExeId.current) {
            item.score = singleScore.current as number
          }
        })

        setExamExerciseList(newExerciseList)
      }
    }

    const hasSelected = selectedRowKeys.length > 0
    const onSelectChange = (selectedRows: any) => {
      setSelectedRowKeys(selectedRows)
    }

    const rowSelection = {
      width: 60,
      selectedRowKeys,
      onChange: onSelectChange,
      preserveSelectedRowKeys: false,
    }

    // 渲染分值列
    const renderScore = (text: any, record: any) => {
      if (selectedRowKeys.indexOf(record.id) < 0) {
        singleScore.current = text
        return (
          <div style={{ width: 50, height: 30 }}>{text == 0 ? 5 : text}</div>
        )
      } else {
        return (
          <>
            <InputNumber
              min={0}
              max={100}
              onChange={(value) => {
                if (value !== null) {
                  singleExerciseScoreChange(record.id, value)
                }
              }}
              style={{ width: 60, height: 30 }}
              defaultValue={text}
            />
            <Button
              style={{ width: 50, marginLeft: 10 }}
              type="primary"
              size="small"
              onClick={singleExerciseScoreConfirm}
            >
              确定
            </Button>
          </>
        )
      }
    }

    const exercise_columns: any = [
      {
        title: '序号',
        dataIndex: 'id',
        key: 'id',
        align: 'left',
        width: 50,
        render: (text, record, index) => index + 1,
      },
      {
        title: '题目',
        dataIndex: 'title',
        key: 'title',
        align: 'left',
        width: 180,
        ellipsis: 'true',
      },
      {
        title: '题型',
        dataIndex: 'type',
        key: 'type',
        align: 'center',
        width: 80,
        render: renderType,
        /*filters: [
        {
            text: '实操题',
            value: 1,
        },
        {
            text: '单选题',
            value: 2,
        },
        {
            text: '多选题',
            value: 3,
        }
      ],
      onFilter: (value:any, record:any) => true,*/
      },
      {
        title: '难度',
        dataIndex: 'difficulty',
        key: 'difficulty',
        align: 'center',
        width: 80,
        render: renderDifficulty,
        /*filters: [
        {
            text: '容易',
            value: 1,
        },
        {
            text: '中等',
            value: 2,
        },
        {
            text: '困难',
            value: 3,
        }
      ],
      onFilter: (value:any, record:any) => true,*/
      },

      {
        title: '分值',
        dataIndex: 'score',
        key: 'score',
        align: 'left',
        width: 150,
        render: renderScore,
      },
      {
        title: '操作',
        dataIndex: 'id',
        key: 'actions',
        width: 100,
        ellipsis: false,
        align: 'center',
        //fixed: 'right',
        render: renderActions,
      },
    ]

    /** 渲染预览的习题 */
    const renderExercisePreview = (record: any) => {
      return (
        <ExerciseCard
          exercise={record}
          renderType="white"
          teacherOnly={true}
          editorMode={true}
          showAnser={false}
          showClean={true}
        />
      )
    }

    //计算试卷题型分布和难度分布
    //const typeMap = new Map([[1, '实操题'], [2, '单选题'], [3, '多选题'],[4,"排序题"],[5, "主观题"]]);
    //const difficultyMap = new Map([[1, '容易'], [2, '中等'], [3, '困难']]);

    const typeCountMap = new Map([
      ['实操题', 0],
      ['单选题', 0],
      ['多选题', 0],
      ['排序题', 0],
      ['主观题', 0],
    ])
    const difficultyCountMap = new Map([
      ['容易', 0],
      ['中等', 0],
      ['困难', 0],
    ])

    //console.log(examExerciseList)
    examExerciseList.map((item) => {
      const typeCount = Number(
        typeCountMap.get(typeMap.get(item.type) as string)
      )
      typeCountMap.set(typeMap.get(item.type) as string, typeCount + 1)

      const diffCount = Number(
        difficultyCountMap.get(difficultyMap.get(item.difficulty) as string)
      )
      difficultyCountMap.set(
        difficultyMap.get(item.difficulty) as string,
        diffCount + 1
      )
    })

    interface Props2 {}
    /** 试题难度分布面板 */
    const ExamExerciseStatDifficultPanel: React.FC<Props2> = (props) => {
      const difficult_option = {
        title: {
          text: '试题难度分布',
          textStyle: { fontSize: 14 },
          left: 'center',
          top: 'bottom',
        },
        tooltip: {
          trigger: 'item',
        },
        legend: {
          orient: 'vertical',
          left: 'right',
          top: 'center',
        },
        series: [
          {
            name: '难度',
            type: 'pie',
            radius: '50%',

            label: {
              show: true,
              formatter: '{b} ( {c} / {d}% ) ',
              position: 'outside',
            },
            data: [
              { value: difficultyCountMap.get('容易'), name: '容易' },
              { value: difficultyCountMap.get('中等'), name: '中等' },
              { value: difficultyCountMap.get('困难'), name: '困难' },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      }

      useEffect(() => {
        const chartDifficultDom = document.getElementById('difficult')!
        const myDifficultChart = echarts.init(chartDifficultDom)
        difficult_option && myDifficultChart.setOption(difficult_option)
      }, [])

      return (
        <div id="difficult" style={{ height: 400 }}>
          {' '}
        </div>
      )
    }

    /** 试题题型分布面板 */
    const ExamExerciseStatTypePanel: React.FC<Props2> = (props) => {
      const type_option = {
        title: {
          text: '试题题型分布',
          textStyle: { fontSize: 14 },
          left: 'center',
          top: 'bottom',
        },
        tooltip: {
          trigger: 'item',
        },
        legend: {
          orient: 'vertical',
          left: 'right',
          top: 'center',
        },
        series: [
          {
            name: '题型',
            type: 'pie',
            radius: '50%',

            label: {
              show: true,
              formatter: '{b} ( {c} / {d}% ) ',
              position: 'outside',
            },
            data: [
              { value: typeCountMap.get('实操题'), name: '实操题' },
              { value: typeCountMap.get('单选题'), name: '单选题' },
              { value: typeCountMap.get('多选题'), name: '多选题' },
              { value: typeCountMap.get('排序题'), name: '排序题' },
              { value: typeCountMap.get('主观题'), name: '主观题' },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      }

      useEffect(() => {
        const chartTypeDom = document.getElementById('type')!
        const myTypeChart = echarts.init(chartTypeDom)
        type_option && myTypeChart.setOption(type_option)
      }, [])

      return (
        <div id="type" style={{ height: 400 }}>
          {' '}
        </div>
      )
    }

    /** 试题知识点词云图面板 */
    const ExamExerciseModulesPanel: React.FC<Props2> = (props) => {
      const module_option = {
        title: {
          text: '试题知识点',
          textStyle: { fontSize: 14 },
          left: 'center',
          top: 'bottom',
        },
        tooltip: {
          trigger: 'item',
        },
        series: [
          {
            name: '知识点',
            type: 'wordCloud',
            shape: 'circle',
            sizeRange: [12, 24],
            rotationRange: [-90, 90],
            rotationStep: 30,
            // Global text style
            textStyle: {
              fontFamily: 'sans-serif',
              fontWeight: 'bold',
              // Color can be a callback function or a color string
              color: function () {
                // Random color
                return (
                  'rgb(' +
                  [
                    Math.round(Math.random() * 160),
                    Math.round(Math.random() * 160),
                    Math.round(Math.random() * 160),
                  ].join(',') +
                  ')'
                )
              },
            },
            emphasis: {
              focus: 'self',

              textStyle: {
                textShadowBlur: 10,
                textShadowColor: '#333',
              },
            },
            data: [
              { name: '饼图', value: 1 },
              { name: '线性回归', value: 2 },
              { name: '随机森林', value: 1 },
              { name: '支持向量机', value: 1 },
              { name: '模型训练', value: 1 },
              { name: '模型评估', value: 1 },
              { name: '降维', value: 1 },
              { name: '卷积神经网络', value: 1 },
              { name: '数据可视化', value: 3 },
            ],
          },
        ],
      }

      useEffect(() => {
        const chartModuletDom = document.getElementById('module')!
        const myModuleChart = echarts.init(chartModuletDom)
        module_option && myModuleChart.setOption(module_option)
      }, [])

      return (
        <div id="module" style={{ height: 250 }}>
          {' '}
        </div>
      )
    }

    if (examExerciseList.length == 0) {
      return (
        <div style={{ paddingTop: 130, textAlign: 'center', height: 300 }}>
          <Row justify="center" align="middle" gutter={[8, 8]}>
            <Col span={24}> 您还未添加题目哦~</Col>
            <Col>
              <Button
                onClick={() => {
                  setActiveTabkKey('2')
                }}
                type="primary"
                style={{ borderRadius: 5 }}
              >
                {' '}
                + 添加题{' '}
              </Button>{' '}
            </Col>
          </Row>
        </div>
      )
    } else {
      return (
        <>
          {/* <div
            style={{
              marginBottom: 20,
              marginTop: -45,
              textAlign: 'right',
              
            }}
          > */}
            <Row justify="end" align="middle" gutter={[4, 4]} style={{position:"absolute",top:"-45px",right:"0px"}}>
              <Col>
                <div className={selectedExeStyles.checkableTab}>
                  <CheckableTag
                    key={'table'}
                    checked={showExamTableMode}
                    onChange={handleExamTableModeChange}
                  >
                    {' '}
                    <UnorderedListOutlined /> 列表{' '}
                  </CheckableTag>{' '}
                </div>
              </Col>
              <Col>
                <div className={selectedExeStyles.checkableTab}>
                  <CheckableTag
                    key={'expand'}
                    checked={showExamExpandMode}
                    onChange={handleExamExpandModeChange}
                  >
                    {' '}
                    <PlayCircleOutlined rotate={90} /> 展开{' '}
                  </CheckableTag>
                </div>
              </Col>
              <Col style={{ textAlign: 'right', marginRight: 0 }}>
                <div className={selectedExeStyles.checkableTab}>
                  <CheckableTag
                    key={'stat'}
                    checked={showExamStatMode}
                    onChange={handleExamStatModeChange}
                  >
                    {' '}
                    <PieChartOutlined /> 统计{' '}
                  </CheckableTag>
                </div>
              </Col>
            </Row>
          {/* </div> */}

          {showExamTableMode && (
            <div className={selectedExeStyles.tableWrapper}>
              <Row align="middle" gutter={[4, 4]} style={{ marginBottom: 10 }}>
                {/*} <Col >
            <Input
                // onPressEnter={(event:any)=>{queryContent.current = event.target.value; page.current = 1;queryExerciseList();}}
                placeholder="题目名称/知识点" 
                style={{ borderRadius:20,width:250,borderColor:"#07C160"}} 
                suffix={<SearchOutlined  style={{color:"#07C160"}}/>}
            />
    </Col> */}
                <Col flex="auto" style={{ textAlign: 'right' }}>
                  <span style={{ color: '#333' }}>总分: </span>{' '}
                  <span style={{ color: '#333', marginRight: 10 }}>
                    {getTotalScore()}{' '}
                  </span>
                  <span style={{ color: '#333' }}>共 </span>{' '}
                  <span style={{ color: '#07C160' }}>
                    {examExerciseList.length}
                  </span>{' '}
                  <span style={{ color: '#333' }}>题 </span>
                  <span style={{ color: '#333' }}>实操 </span>{' '}
                  <span style={{ color: '#07C160' }}>
                    {typeCountMap.get('实操题')}{' '}
                  </span>
                  <span style={{ color: '#333' }}>单选 </span>{' '}
                  <span style={{ color: '#07C160' }}>
                    {typeCountMap.get('单选题')}{' '}
                  </span>
                  <span style={{ color: '#333' }}>多选 </span>{' '}
                  <span style={{ color: '#07C160' }}>
                    {typeCountMap.get('多选题')}{' '}
                  </span>
                  <span style={{ color: '#333' }}>排序 </span>{' '}
                  <span style={{ color: '#07C160' }}>
                    {typeCountMap.get('排序题')}{' '}
                  </span>
                  <span style={{ color: '#333' }}>主观 </span>{' '}
                  <span style={{ color: '#07C160', marginRight: 10 }}>
                    {typeCountMap.get('主观题')}{' '}
                  </span>
                </Col>
                {/* <Col flex="100px"><span style={{color:"#00000073"}}> 已选 {selectedRowKeys.length}</span></Col> */}
                <Col>
                  <Button
                    onClick={() => {
                      setIsModalVisible(true)
                    }}
                    disabled={!(showExamTableMode && hasSelected)}
                    size="small"
                    type="default"
                    style={{
                      color: '#07C160',
                      borderColor: '#07C160',
                      fontSize: 12,
                      marginRight: 10,
                      marginBottom: 0,
                      borderRadius: 5,
                    }}
                  >
                    {' '}
                    设置分值{' '}
                  </Button>
                </Col>
                {/* <Col><Button  onClick={()=>{}}  size="small"  type="default" style={{fontSize:12,marginBottom:0,borderRadius:5}}> 导出 </Button></Col> */}
              </Row>

              <ConfigProvider locale={zhCN}>
                <Table
                  dataSource={examExerciseList}
                  columns={exercise_columns}
                  size="small"
                  rowKey={(record) => record.id}
                  pagination={false}
                  //onChange={onTableChange}
                  rowSelection={rowSelection}
                  expandable={{
                    columnWidth: 90,
                    childrenColumnName: '预览',
                    expandIconColumnIndex: 7,
                    expandIcon: ({ expanded, onExpand, record }) =>
                      expanded ? (
                        <div
                          onClick={(e) => onExpand(record, e)}
                          style={{ cursor: 'pointer' }}
                        >
                          预览
                          <UpOutlined className={selectExeStyles.actionBtn} />
                        </div>
                      ) : (
                        <div
                          onClick={(e) => onExpand(record, e)}
                          style={{ cursor: 'pointer' }}
                        >
                          预览
                          <DownOutlined className={selectExeStyles.actionBtn} />
                        </div>
                      ),
                    expandedRowRender: renderExercisePreview,
                    rowExpandable: (record) => record.name !== 'Not Expandable',
                  }}
                />
              </ConfigProvider>
            </div>
          )}

          {showExamExpandMode && (
            <div>
              <Row style={{ height: 40 }}>
                <Col flex="50" style={{ textAlign: 'left' }}>
                  试卷预览
                </Col>
                <Col flex="auto" style={{ textAlign: 'right' }}>
                  <span style={{ color: '#333' }}>总分: </span>{' '}
                  <span style={{ color: '#333', marginRight: 10 }}>
                    {getTotalScore()}{' '}
                  </span>
                  <span style={{ color: '#333' }}>共 </span>{' '}
                  <span style={{ color: '#07C160' }}>
                    {examExerciseList.length}
                  </span>{' '}
                  <span style={{ color: '#333' }}>题 </span>
                  <span style={{ color: '#333' }}>实操 </span>{' '}
                  <span style={{ color: '#07C160' }}>
                    {typeCountMap.get('实操题')}{' '}
                  </span>
                  <span style={{ color: '#333' }}>单选 </span>{' '}
                  <span style={{ color: '#07C160' }}>
                    {typeCountMap.get('单选题')}{' '}
                  </span>
                  <span style={{ color: '#333' }}>多选 </span>{' '}
                  <span style={{ color: '#07C160' }}>
                    {typeCountMap.get('多选题')}{' '}
                  </span>
                  <span style={{ color: '#333' }}>排序 </span>{' '}
                  <span style={{ color: '#07C160' }}>
                    {typeCountMap.get('排序题')}{' '}
                  </span>
                  <span style={{ color: '#333' }}>主观 </span>{' '}
                  <span style={{ color: '#07C160', marginRight: 10 }}>
                    {typeCountMap.get('主观题')}{' '}
                  </span>
                </Col>
              </Row>
              {examExerciseList.map((exercise, index) => (
                <Row
                  key={exercise.id}
                  style={{
                    marginBottom: 10,
                    padding: 10,
                    borderColor: '#F5FAF7',
                    borderWidth: 1,
                    borderStyle: 'solid',
                  }}
                >
                  <Col
                    span={1}
                    style={{
                      marginTop: 3,
                      textAlign: 'right',
                      fontWeight: 700,
                    }}
                  >
                    {index + 1}.
                  </Col>
                  <Col span={23}>
                    <ExerciseCard
                      exercise={exercise}
                      renderType="white"
                      teacherOnly={true}
                      editorMode={true}
                      showAnser={false}
                      showClean={true}
                    />
                  </Col>
                </Row>
              ))}
            </div>
          )}

          {showExamStatMode && (
            <div style={{ textAlign: 'center' }}>
              <Row style={{ height: 40 }}>
                <Col flex="50" style={{ textAlign: 'left' }}>
                  试卷统计分析
                </Col>
                <Col flex="auto" style={{ textAlign: 'right' }}>
                  <span style={{ color: '#333' }}>总分: </span>{' '}
                  <span style={{ color: '#333', marginRight: 10 }}>
                    {getTotalScore()}{' '}
                  </span>
                  <span style={{ color: '#333' }}>共 </span>{' '}
                  <span style={{ color: '#07C160' }}>
                    {examExerciseList.length}
                  </span>{' '}
                  <span style={{ color: '#333' }}>题 </span>
                  <span style={{ color: '#333' }}>实操 </span>{' '}
                  <span style={{ color: '#07C160' }}>
                    {typeCountMap.get('实操题')}{' '}
                  </span>
                  <span style={{ color: '#333' }}>单选 </span>{' '}
                  <span style={{ color: '#07C160' }}>
                    {typeCountMap.get('单选题')}{' '}
                  </span>
                  <span style={{ color: '#333' }}>多选 </span>{' '}
                  <span style={{ color: '#07C160' }}>
                    {typeCountMap.get('多选题')}{' '}
                  </span>
                  <span style={{ color: '#333' }}>排序 </span>{' '}
                  <span style={{ color: '#07C160' }}>
                    {typeCountMap.get('排序题')}{' '}
                  </span>
                  <span style={{ color: '#333' }}>主观 </span>{' '}
                  <span style={{ color: '#07C160', marginRight: 10 }}>
                    {typeCountMap.get('主观题')}{' '}
                  </span>
                </Col>
              </Row>
              {/*<div>
          <ExamExerciseModulesPanel />
        </div> */}

              <div>
                <ExamExerciseStatDifficultPanel />
              </div>

              <div>
                <ExamExerciseStatTypePanel />
              </div>
            </div>
          )}

          <ConfigProvider locale={zhCN}>
            <Modal
              title={
                <div
                  style={{
                    width: '100%',
                    cursor: 'move',
                  }}
                  onMouseOver={() => {
                    if (disabled) {
                      setDisabled(false)
                    }
                  }}
                  onMouseOut={() => {
                    setDisabled(true)
                  }}
                  onFocus={() => {}}
                  onBlur={() => {}}
                  // end
                >
                  设置分值
                </div>
              }
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
              bodyStyle={{ height: '80px' }}
              destroyOnClose={true}
              width={300}
              modalRender={(modal) => (
                <div className={selectedExeStyles.miniModal}>
                  <Draggable
                    disabled={disabled}
                    bounds={bounds}
                    onStart={(event, uiData) => onStart(event, uiData)}
                  >
                    <div ref={draggleRef}>{modal}</div>
                  </Draggable>{' '}
                </div>
              )}
            >
              <Row justify="center" align="middle">
                <Col span={'auto'}>
                  已选{' '}
                  <span style={{ color: '#07C160' }}>
                    {selectedRowKeys.length}
                  </span>{' '}
                  题，统一设置分数为：
                </Col>

                <Col span={'auto'}>
                  <InputNumber
                    min={0}
                    max={100}
                    onChange={(value) => {
                      if (value !== null) {
                        setSettingScore(Number(value))
                      }
                    }}
                    style={{ width: 60 }}
                    value={settingScore}
                  ></InputNumber>
                </Col>
              </Row>
            </Modal>
          </ConfigProvider>
        </>
      )
    }
  }

  return (
    <div
      style={{ marginTop: 20, marginBottom: 20 }}
      className={examEditStyles.tabsDiv}
    >
      <Tabs
        activeKey={activeTabkKey}
        onTabClick={(key, event) => {
          setActiveTabkKey(key)
        }}
        type="line"
        size="middle"
        tabPosition="top"
      >
        <TabPane tab={<div>试卷({examExerciseList?.length})</div>} key="1">
          <CourseTeacherSelectedExercise />
        </TabPane>

        <TabPane tab={<div>题库</div>} key="2">
          {course && <CourseTeacherSelectExercise currentCourse={course} />}
        </TabPane>
      </Tabs>
    </div>
  )
}

export default CourseTeacherExamComposition
