import React, { useEffect, useRef, useState } from 'react'
import noteListStyle from './course-main-notelist.module.less'
import {
  Button,
  Col,
  ConfigProvider,
  Empty,
  Image,
  Menu,
  message,
  Pagination,
  Row,
  Tag,
} from 'antd'
import discussStyle from '../discussion/course-main-discuss.module.less'
import SearchInputCom from '../../../component/SearchInputCom/SearchInputCom'
import { number2text } from '../../../utils/numToZhUpper'
import { sendHttpPostRequest } from '../../../api/http-request'
import Cookies from 'js-cookie'
import DiscusItemStyle from '../../../component/DiscussItem/DiscussItem.module.less'
import { useLocation } from 'react-router'
import * as contants from '../../../constants/constants'
import CommonModal from '../../../component/modal/CommonModal/CommonModal'
import zhCN from 'antd/lib/locale/zh_CN'
import { mdParser } from '../../../component/MarkDownEditor/MarkDownEditor'
import katex from 'katex'
import MarkdownItKatex from '@iktakahiro/markdown-it-katex'
const emoji = require('markdown-it-emoji')
export interface NoteProps {
  menu_id: number
}

export const NoteItem = (props: any) => {
  const {
    note,
    knowledgeName,
    course_id,
    know_id,
    actionCallback,
    chapter_id,
  } = props
  mdParser.use(MarkdownItKatex, { katex })
  mdParser.use(emoji)
  const [deleteNoteModalVis, setDeleteNoteModalVis] = useState(false)
  const deleteNoteOk = () => {
    sendHttpPostRequest(
      'course/api/study_note/delete_note/',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        course_id,
        know_id,
        hash_id: note.hash_id,
        content: note.content,
        note_id: note.id,
        // image_id:note.image_id
      },
      (res) => {
        if (res.is_ok) {
          message.success('笔记删除成功！')
          actionCallback(know_id)
        } else {
          message.error(res.message)
        }
      }
    )
    setDeleteNoteModalVis(false)
  }
  return (
    <div className={noteListStyle.noteContain}>
      <Image
        width={157}
        height={100}
        className={noteListStyle.noteImg}
        src={contants.BACKEND_URL + note.image_src}
        alt=""
      />
      <h1 className={noteListStyle.noteTitle}>{note.title}</h1>
      <div
        className={noteListStyle.noteContent}
        dangerouslySetInnerHTML={{ __html: mdParser.render(note.content) }}
      ></div>
      <Row align="middle" justify="space-between">
        <Col>
          <Tag
            color={'#eefff6'}
            onClick={() =>
              window.open(
                `/course-learn?course/${course_id}/chapter/${chapter_id}/knowledge/${know_id}/`
              )
            }
            className={DiscusItemStyle.tag}
            style={{ cursor: 'pointer' }}
          >
            {knowledgeName}
          </Tag>
          <span className={DiscusItemStyle.commentTime}>
            {note.create_time}
          </span>
        </Col>
        <Col flex={'200px'}>
          <div
            className={DiscusItemStyle.actionContain}
            onClick={() => setDeleteNoteModalVis(true)}
          >
            <div className={DiscusItemStyle.iconBtn}>
              <span
                className={
                  'iconfont icon_shanchu' +
                  ' ' +
                  DiscusItemStyle.grayBtnTextColor
                }
              ></span>
              <span className={DiscusItemStyle.grayBtnTextColor}>删除</span>
            </div>
          </div>
        </Col>
      </Row>
      <CommonModal
        onOk={deleteNoteOk}
        onCancel={() => setDeleteNoteModalVis(false)}
        width={350}
        modalContent={<div>确认删除这条笔记吗？</div>}
        open={deleteNoteModalVis}
        modalTitle={'提示'}
      />
    </div>
  )
}

export const CourseMainNotelist: React.FC<NoteProps> = (props) => {
  const { menu_id } = props
  const location = useLocation()
  const course_id = location.search.split('&')[0].split('=')[1]
  const queryNote = useRef('')
  const [menu, setMenu] = useState([] as any)
  const [chapterId, setChapterId] = useState('')
  const [taskList, setTaskList] = useState([] as any)
  const [isAddEditorVis, setIsAddEditorVis] = useState(false)
  const [knowledgeSelected, setKnowledgeSelected] = useState('')
  const [knowledgeName, setKnowledgeName] = useState('')
  const [notelist, setNotelist] = useState([] as any)
  const [newKnowledge, setNewKnowledge] = useState({} as any)
  const [noteTotal, setNoteTotal] = useState(0)
  const page = useRef(1)
  const pageSize = useRef(10)
  useEffect(() => {
    queryMenu()
  }, [])

  const onNoteChange = (e) => {
    queryNote.current = e.target.value
  }

  const onSearchNote = () => {
    page.current = 1
    queryNoteList(knowledgeSelected)
  }

  const queryMenu = () => {
    sendHttpPostRequest(
      'course/api/user_query_menu_by_id',
      {
        sessionid: Cookies.get('sessionid'),
        menu_id: menu_id,
        // description: description,
        format: 1,
      },
      queryMenuCallback
    )
  }

  /**
   * 查询大纲回调
   * @param response
   */
  const queryMenuCallback = (response: any) => {
    if (response.code == -100) {
      // 未登录
      document.getElementById('show-login-modal')!.click()
    } else if (response.is_ok == true) {
      // 成功
      setMenu(response.query_info.json)
      let tasks = response.query_info.json
        .map((m) => m.tasks.map((t) => t))
        .flat()
      setTaskList([...tasks])
      setKnowledgeSelected(response.query_info.json[0].tasks[0].id.toString())
      getNewKnowledge(response.query_info.json[0].tasks[0].id)
      setKnowledgeName('1.1' + ' ' + response.query_info.json[0].tasks[0].name)
      queryNoteList(response.query_info.json[0].tasks[0].id)
      setChapterId(response.query_info.json[0].id)
    } else {
      message.error(response.message)
    }
  }
  const queryNoteList = (know_id) => {
    sendHttpPostRequest(
      'course/api/study_note/query_note_by_kc/',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        course_id: course_id,
        // position: shotScreenTime.current,
        know_id,
        page: page.current,
        pagesize: pageSize.current,
        query: queryNote.current,
        // image_content: base64data,
      },
      (res) => {
        if (res.is_ok) {
          const notelist = res.query_list.map((note) => {
            return {
              is_editor: false,
              ...note,
            }
          })
          setNotelist([...notelist])
          setNoteTotal(res.total_sum)
        }
      }
    )
  }
  const getNewKnowledge = (key) => {
    let newKnowledge = taskList.filter((t) => t.id == key)[0]
    setNewKnowledge(newKnowledge)
    // console.log("======",menu.filter(item=>item.tasks.filter(task=>task.id == knowledgeSelected)[0])[0]);
    const [i, j] = menu
      .map((m) => m.tasks.map((t) => t))
      .reduce(
        (acc, cur, i) => {
          const j = cur.indexOf(newKnowledge)
          return j !== -1 ? [i, j] : acc
        },
        [-1, -1]
      )
    if (i !== -1) {
      let newName = i + 1 + '.' + (j + 1 - 0) + ' ' + newKnowledge.name
      setKnowledgeName(newName)
    }
  }
  const knowledgeSelectChange = (info) => {
    setKnowledgeSelected(info.key)
    getNewKnowledge(info.key)
    queryNoteList(info.key)
    setChapterId(
      menu.filter(
        (item) => item.tasks.filter((task) => task.id == info.key)[0]
      )[0].id
    )
  }

  /**
   * 导出笔记
   */
  const exportStudyNote = () => {
    sendHttpPostRequest(
      'course/api/study_note/export_note_file/',
      {
        sessionid: Cookies.get('sessionid'),
        course_id: course_id,
      },
      (response: any) => {
        message.destroy()
        if (response.code == -100) {
          // 未登录
          document.getElementById('show-login-modal')!.click()
        } else if (response.code == 0) {
          // 成功
          window.open(contants.BACKEND_URL + response.url)
        } else if (response.code == -501) {
          // 权限不足
          window.location.href = '/course'
        } else {
          message.error(response.message)
        }
      }
    )
  }
  const onPageSizeChange = (newPage, newPageSize) => {
    page.current = newPage
    pageSize.current = newPageSize
    queryNoteList(knowledgeSelected)
  }

  return (
    <div className={noteListStyle.contentWapper}>
      <Row
        className={noteListStyle.rightActionBox}
        align="middle"
        justify={'space-between'}
      >
        <Col>
          <SearchInputCom
            placeholder={'搜笔记'}
            onSearch={onSearchNote}
            onChange={onNoteChange}
          />
        </Col>
        <Col>
          <Button
            className={noteListStyle.transparentBtn}
            onClick={() => {
              setIsAddEditorVis(true)
              exportStudyNote()
            }}
          >
            导出笔记
          </Button>
        </Col>
      </Row>
      <div
        className={discussStyle.discussContain}
        style={{ marginTop: '52px' }}
      >
        <div className={discussStyle.discussLeftMenu}>
          <Menu
            onClick={knowledgeSelectChange}
            selectedKeys={[knowledgeSelected]}
          >
            {menu?.map((sub: any, subIdx) => (
              <Menu.ItemGroup
                key={sub.id}
                title={number2text(subIdx + 1, 'lower') + '、' + sub.name}
              >
                {sub.tasks?.map((task, taskIdx) => (
                  <Menu.Item key={task.id}>
                    {subIdx + 1}.{taskIdx + 1} {task.name}
                  </Menu.Item>
                ))}
              </Menu.ItemGroup>
            ))}
          </Menu>
        </div>
        <div className={noteListStyle.noteRightContent}>
          <div className={noteListStyle.pageTitle}>{knowledgeName}</div>
          {notelist.length > 0 ? (
            <div className={noteListStyle.rigContent}>
              {notelist?.map((note) => (
                <NoteItem
                  knowledgeName={knowledgeName?.split(' ').splice(1).toString()}
                  key={note.id}
                  note={note}
                  course_id={course_id}
                  know_id={knowledgeSelected}
                  actionCallback={queryNoteList}
                  chapter_id={chapterId}
                />
              ))}
            </div>
          ) : (
            <div className={noteListStyle.rightEmptyContent}>
              <Empty
                style={{ color: '#333' }}
                description={'暂时还没有笔记，快去标注知识点创建笔记吧！'}
                image={Empty.PRESENTED_IMAGE_DEFAULT}
              />
            </div>
          )}
          {notelist.length > 0 && (
            <ConfigProvider locale={zhCN}>
              <Pagination
                total={noteTotal}
                current={page.current}
                pageSize={pageSize.current}
                onChange={onPageSizeChange}
                showQuickJumper
                pageSizeOptions={['10', '20', '50', '100']}
              />
            </ConfigProvider>
          )}
        </div>
      </div>
    </div>
  )
}

export default CourseMainNotelist
