import React, {useEffect, useState} from "react";
import { Popconfirm,Input,Table,Switch,Layout, message, Card, Col, Row, ConfigProvider, Pagination, Tabs, Button, Tag } from 'antd';
import { PageHeader } from "../../common/page-header/header";
import { sendHttpPostRequest } from "../../../api/http-request";
import logoSvg from '../../static/image/idclogo.png';
import pekinglogoSvg from '../../static/image/peking.png';
import testStyles from "./course-main-teacher-test-list.module.less";
import "../course.module.css";
import zhCN from 'antd/lib/locale/zh_CN'
import * as constants from '../../../constants/constants';
import PageFooter from "../../common/page-footer/footer";
import LoginRegisterModal from "../../../component/modal/login-register/login-register";
import {checkUserLogin} from "../../common/user-center/user-info";
import { EyeOutlined,DeleteOutlined } from '@ant-design/icons';
import CourseMainTeacherMenu from "../menu/course-main-teacher-menu";
import courseMainStyles from "../course-main.module.less";
import courseMainTestListStyles from './course-main-teacher-test-list.module.less';
import zuoyeEmpty from "../../../static/image/empty/queshengye-zuoye.png";
import Cookies from "js-cookie";

export interface Props {
    course_id : any,
    college_id: any,
    menu_id : any
}
/** 学生课程作业列表页面 */
export const CourseMainStudentTestList: React.FC<Props> = (props) => {
    const {course_id,college_id,menu_id} = props;
    const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
    const [homeworkList, setHomeworkList] = useState([] as any[]);
          


    /**
     * 查询作业回调
     * @param response
     */
    const queryHomeworkListCallback = (response:any) => {
        message.destroy();
        if (response.code == -100) {
            // 没登录
            document.getElementById("show-login-modal")!.click();
        } else if (response.code == -501) {
            // 没权限
            message.error("权限不足");
        } else if (response.is_ok == true) {
            setHomeworkList(response.query_list);
        }
    }

    // 查询作业列表
    const queryHomeworkList = () => {
        if (!checkUserLogin()) {
            document.getElementById("show-login-modal")!.click();
        } else {
            sendHttpPostRequest(
                'homework/api/user_query_homework_list/',
                {
                    sessionid: Cookies.get("sessionid"),
                    course_id: course_id,
                    college_id: college_id
                },
                queryHomeworkListCallback
            )
        }
    }

    /**
     * 删除作业回调
     * @param response
     */
    const deleteHomeworkCallback = (response:any) => {
        message.destroy();
        if (response.code == -100) {
            // 没登录
            document.getElementById("show-login-modal")!.click();
        } else if (response.code == -501) {
            // 没权限
            message.error("权限不足");
        } else if (response.is_ok == true) {
            //message.success("删除作业成功~");
            queryHomeworkList();
        } else {
            message.error("删除作业失败~");
        }
    }

    /** 删除作业 */
    const deleteHomework = (homeworkId:any) => {
        if (!checkUserLogin()) {
            document.getElementById("show-login-modal")!.click();
        } else {
            sendHttpPostRequest(
                'homework/api/user_delete_homework/',
                {
                    sessionid: Cookies.get("sessionid"),
                    sid: Cookies.get("sessionid"),
                    homework_id: homeworkId,
                },
                deleteHomeworkCallback
            )
        }
    }

    // 渲染操作
    const renderActions = (text:any, record:any) => {
        return <Row  align='middle' justify='center' gutter={[16,8]}> 
          <Col>  <EyeOutlined  value={record?.id} onClick={onPreviewTestClick.bind(this,record?.id,record?.status)}  className={courseMainTestListStyles.actionBtn} /> </Col>
        </Row>
    }

    const onPreviewTestClick = (test_id:string, test_status: any, event:any) => {
      if (!checkUserLogin()) {
          document.getElementById("show-login-modal")!.click();
      } else {

          window.open('/course-student-test-preview?test_id=' + test_id  + '&course_id=' + course_id + '&menu_id=' + menu_id);
      }
    }
    
    const rowSelection = {
      selectedRowKeys,
      onChange: (keys) => {
        setSelectedRowKeys(keys);
      },
    };



    const renderTitle = (text:any, record:any) => {
        let _jumpUrl = '/course-student-test-preview?test_id=' + record.id + '&course_id=' + course_id + '&menu_id=' + menu_id;
        if (record.name.length > 50) {
          return <>
            <a className={courseMainTestListStyles.slideName} href={_jumpUrl} target="_blank">{record.name.slice(0,50) + '...'}</a>
          </>
        } else {
          return <>
            <a className={courseMainTestListStyles.slideName} href={_jumpUrl} target="_blank">{record.name}</a>
          </>
        }
    }

    const testCols :any= [
      {
        title:'序号',
        dataIndex: 'id',
        key: 'id',
        align: 'left',
        width: '6%', 
        render: (text, record)=>(homeworkList?.indexOf(record) + 1),
      },
      {
        title: '作业',
        dataIndex: 'name',
        key: 'name',
        align: 'left',
        width: '20%',
        render:renderTitle,
      },
      {
        title: '状态',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        width: '15%',
      },
      {
        title: '截止时间',
        dataIndex: 'submit_time',
        key: 'submit_time',
        align: 'center',
        width: '20%',
        render:(text,record)=>(text?.slice(0,16))
      },
      /*{
        title: '延迟',
        dataIndex: 'deadline',
        key: 'deadline',
        align: 'center',
        width: '20%',
        render:(text, record)=>{
            if(record.allow_delay == 1){
                return <div>{text?.slice(0,16)}</div>
            }else{
                return <div>{"--"}</div>
            }
        }
      },*/
      {
        title: "操作",
        dataIndex: "actions",
        key: "actions",
        width: '8%',
        ellipsis: false,
        align: 'center',
        //fixed: 'right',
        render: renderActions,
    },
    ]

    useEffect(()=> {
        queryHomeworkList();
    },[])



    return (     
        <div className={courseMainStyles.tabpaneWrapper} >
          

            <div className={courseMainTestListStyles.tableWrapper}>
            {homeworkList.length!=0 &&
                <ConfigProvider locale={zhCN}>
                    <Table
                        dataSource={homeworkList}
                        columns={testCols}
                        size='small'
                        rowKey={record => record.id}
                        pagination={{position:["bottomCenter"], pageSize:10, size:"default",hideOnSinglePage:true}}
                    />
                </ConfigProvider>
            }
              
              {homeworkList.length==0 &&
                  <div style={{textAlign:'center',paddingTop:20}}>
                      <img src={zuoyeEmpty} width={120} height={120}/>
                      <div style={{color:'#999',marginTop:10}}>老师还没有布置作业哟~</div>
                  </div>
              }
            </div>
        </div>
    )

}

export default CourseMainStudentTestList

