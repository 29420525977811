import { Layout,Input,Select, message,Button,Radio,Checkbox,Row,Col,Popconfirm,Upload, Breadcrumb } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import OAMSider from '../oam-sider';
import {
    DragDropContext,
    Droppable,
    Draggable
} from 'react-beautiful-dnd';
import React, { useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom';
import { recordExpression } from '@babel/types';
import { sendHttpPostRequest } from '../../../api/http-request';
import Cookies from 'js-cookie';
import exerciseStyles from './oam-exercise.module.less';

import MonacoEditor from 'react-monaco-editor';
import gfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import {CloseCircleOutlined} from '@ant-design/icons';
import confirm from 'antd/lib/modal/confirm';
import * as constants from '../../../constants/constants';
import ExerciseCard from '../../exercise/exercise-card';
const { Option } = Select;
const { TextArea } = Input;

export interface Props {}
export interface Props2 {}

interface exerciseInfo {
    id: number,
    title: string,
    remarks: string,
    type: number,
    type_name: string,
    difficulty: number,
    difficulty_str: string,
    score: number,
    default_code: string,
    steps: string,
    preset_code: string,
    language: number,
    language_str: string,
    judgement_code: string,
    reference_answer: string,
    answer_parse: string,
    options: any,
    options_answer: any,
    datasets: any,
    images: any
}

export const OAMInstitutionPreview: React.FC<Props> = (props) => {
    const exerciseId = parseInt(window.location.search.split("=")[1]);  // 习题的ID
    const [exercise, setExercise] = useState((null as unknown) as exerciseInfo);  // 习题对象
    const [exerciseType, setExerciseType] = useState(1);  // 习题类型
 
    const queryExerciseCallback = (response) => {
        message.destroy();
        if (response.code == -100) {
            // 未登录
            window.location.href = '/oam_login?next=oam_exercise_list';
        } else if (response.code == 0) {
            // 成功
            console.log(response.exercise_info)
            setExercise(response.exercise_info);

        } else if (response.code == -501) {
            // 权限不足
            window.location.href = '/oam_login?next=oam_exercise_list';
        } else {
            message.error(response.message);
        }
    }


    //点击了编辑按钮
    const onEditBtnClick = (id:any,  event:any) => {
    window.open('/oam_exercise_edit?exercise_id='+id);
    }

    useEffect(()=> {
        queryExerciseInfo();
    },[])

    // 查询习题详情
    const queryExerciseInfo = () => {
        sendHttpPostRequest(
            'exercise/api/oam_query_exercise_by_id/',
            {
                exercise_id: exerciseId,
                sessionid: Cookies.get("sessionid"),
                source_page: 1,  // 习题模块1
                source_key: 1,  // OAM1
            },
            queryExerciseCallback
        )
    }


    return (
        <Layout style={{ minHeight: '100vh' }}>
            <OAMSider keys={['5']} openKeys={[]} />
            
            <Content style={{ backgroundColor:'#fff',minWidth:500,borderRadius:5,margin:40 ,paddingTop:20}}>
                <div style={{paddingBottom:30,paddingLeft:30,paddingRight:30,minWidth:500,minHeight:'calc(100vh - 100px)'}}>
                <Breadcrumb style={{marginLeft:-30,float:'left',marginTop: -50}}>
                    <Breadcrumb.Item>
                    <a href="/oam_exercise_list">题目列表</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                    <span style={{fontWeight: 500}}> 题目预览 </span> 
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Row>
                <Col style={{marginBottom:10,width:'100%'}}>
                    <Button onClick={onEditBtnClick.bind(this, exerciseId)} type="primary" style={{float:'right',borderRadius:20}}> 编辑题目 </Button>
                </Col>
                </Row>
                <div style={{padding:10,borderRadius:6,borderStyle:"dashed",borderWidth:1,borderColor:"#e9e9e9"}}>
                    <ExerciseCard exercise= {exercise} renderType = "white" teacherOnly={true} editorMode={true} showAnser={false} showClean={true} />
                </div>
                </div>
             </Content> 
                   
        </Layout>

    )

}

export default OAMInstitutionPreview
