import React, {useEffect, useRef, useState} from "react";
import {DatePicker,Steps,ConfigProvider,Input,Layout, message,Tabs, Button, Row, Col } from 'antd';
import { PageHeader } from "../../common/page-header/header";
import { sendHttpPostRequest } from "../../../api/http-request";
import examEditStyles from "./course-teacher-exam-edit.module.less";
import moment from 'moment';
import LoginRegisterModal from "../../../component/modal/login-register/login-register";
import {checkUserLogin} from "../../common/user-center/user-info";
import zhCN from 'antd/lib/locale/zh_CN'

import courseCreateExamStyles from "./course-create-exam.module.less";

const { RangePicker } = DatePicker;

import Cookies from 'js-cookie';

import 'echarts-wordcloud';

import * as echarts from 'echarts/core';
import {
  TitleComponent,
  TitleComponentOption,
  TooltipComponent,
  TooltipComponentOption,
  LegendComponent,
  LegendComponentOption
} from 'echarts/components';
import { PieChart, PieSeriesOption } from 'echarts/charts';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import CourseTeacherExamComposition from "./course-teacher-exam-composition";



echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer,
  LabelLayout
]);

type EChartsOption = echarts.ComposeOption<
  | TitleComponentOption
  | TooltipComponentOption
  | LegendComponentOption
  | PieSeriesOption
>;


const format = 'HH:mm';
const {Step} = Steps
const {TextArea} = Input 
const {TabPane} = Tabs
const { Search } = Input


  const typeMap = new Map([[1, '实操题'], [2, '单选题'], [3, '多选题'],[4,"排序题"],[5,"主观题"]]);
  const difficultyMap = new Map([[1, '容易'], [2, '中等'], [3, '困难']]);
  

  const dateFormat = 'YYYY-MM-DD HH:mm';

export interface Props {}
/** 教师编辑考试页面 */
export const CourseTeacherExamEdit: React.FC<Props> = (props) => {
  const examId = parseInt(window.location.search.split("&")[0].split("=")[1]);//考试ID
  const courseId = parseInt(window.location.search.split("&")[1].split("=")[1]);//课程
  const currentCourseMenuId = parseInt(window.location.search.split("&")[2].split("=")[1]);//课程大纲ID
  const [examName, setExamName] = useState('');//考试名称
  const [examDescription, setExamDescription] = useState('');//考试说明


  /** 考试试题列表 */
  const [examExercises,setExamExercises] = useState(null as any)

  const [startTime, setStartTime] = useState(moment().utc().zone(-8).format(dateFormat).toString() as any);//考试开始时间
  const [endTime, setEndTime] = useState(moment().utc().zone(-8).add(2, 'hours').format(dateFormat).toString() as any);//考试结束时间




  const disabledDate = (current:any) => {
    return current && current < moment().startOf('day');
  }
 


  useEffect(()=> {
    queryExam();
  },[])


  const onExamExerciseChange = (exercisesList)=>{
    setExamExercises(exercisesList);
  }


  const onDateRangeChange = (dates,dateStrings) => {
    setStartTime(dateStrings[0]);
    setEndTime(dateStrings[1]);
   
}

    // 查询考试回调
    const queryExamCallback = (response:any) => {
      message.destroy();
      if (response.code == -100) {
          // 未登录
          window.location.href = '/login?next=course-main-teacher?course_id=' + courseId + "&tabKey=5";
      } else if (response.is_ok == true) {
          // 成功

          setExamName(response.query_info.name);
          setExamDescription(response.query_info.description);
          setExamExercises(response.query_info.exercise);
          console.log(response.query_info.exercise);
          //setExamPublishStatus(response.query_info.status);
          //setExamPublishScore(response.query_info.publish_score);

          response.query_info.start_time.length > 0 ? setStartTime(response.query_info.start_time) : '';
          response.query_info.end_time.length > 0 ? setEndTime(response.query_info.end_time):'';

          //refreshExamStatus(response.query_info.status, response.query_info.publish_score,response.query_info.start_time,response.query_info.end_time);
 
      } else if (response.code == -501) {
       //权限不足
       window.location.href = '/login?next=course-main-teacher?course_id=' + courseId + "&tabKey=5";
      } else {
      //message.error(response.message);
      }
  }

  // 查询考试信息列表
  const queryExam = () => {
      sendHttpPostRequest(
          'exam/api/user_query_exam_by_id/',
          {
              sessionid: Cookies.get("sessionid"),
              exam_id: examId,
              course_id: courseId

          },
          queryExamCallback
      )
  }


    /**
   * 保存考试回调
   * @param response
   */
      const saveExamParamsInfoCallback = (response:any) => {
      message.destroy();
      if (response.code == -100) {
          // 没登录
          window.location.href = '/login?next=course-main-teacher?course_id=' + courseId + "&tabKey=5";
      } else if (response.code == -501) {
          // 没权限
          window.location.href = '/login?next=course-main-teacher?course_id=' + courseId + "&tabKey=5";
      } else if (response.is_ok == true) {
          message.success("考试信息保存成功~")
      }else {
          message.error(response.message);
      }
  }

  const saveExamParams = (params: any) => {
      sendHttpPostRequest(
          'exam/api/user_update_exam/',
          params,
          saveExamParamsInfoCallback
      )
  }
    
      /** 点击保存考试按钮 */
      const onSaveBtnClick = (e:any)=>{

        const params = {
          sessionid: Cookies.get("sessionid"),
          exam_id: examId,
          name: examName,
          description: examDescription,
          start_time: startTime ,
          end_time: endTime,
          exercise: JSON.stringify(examExercises)

        }
        saveExamParams(params);
    }



      /** 填写考试基本信息 */
      const ExamBasicInfo =  ()=> {
      
        return <ConfigProvider locale={zhCN}>
          <div style={{marginTop:10,marginBottom:20}}>
            <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:20}} >
                <Col flex="80px" style={{color:"#00000073", textAlign:'left'}}>考试标题</Col>                 
                <Col style={{textAlign:'left'}} flex="auto" > <Input maxLength={20} value={examName} onChange={(e:any)=>{setExamName(e.target.value)}}/> </Col>
            </Row>
  
            <Row gutter={[8,8]} justify='start'  align='middle' style={{marginBottom:20}}>
                <Col flex="80px" style={{color:"#00000073",textAlign:'left'}}>考试时间</Col>                        
                <Col style={{textAlign:'left'}}  flex="400px">
                { startTime && endTime &&
                <RangePicker
                  style={{width:"100%"}}
                  disabledDate={disabledDate}
                  placeholder={["开始时间","结束时间"]}
                  showTime={{ format: 'HH:mm' }}
                  format="YYYY-MM-DD HH:mm:ss"
                  onChange={onDateRangeChange}
                  onOk={()=>{}}
                  defaultValue={[moment(startTime,dateFormat), moment(endTime,dateFormat)]}
                  separator={<div style={{marginRight:20}}>到</div>}
                />
              }
                  </Col>
                
            </Row>
  
            <Row gutter={[8,8]} justify='start'  align='top' style={{marginBottom:10}} >
                <Col flex="80px" style={{color:"#00000073",textAlign:'left'}}>考试说明</Col>
                <Col flex="auto">
                  <TextArea 
                    value={examDescription}
                    rows={3}
                    //showCount 
                    maxLength={500}
                    onChange={(e: any)=>{setExamDescription(e.target.value)}}
                  >
                  </TextArea>
                  </Col>
                
            </Row>
  
        </div>
        </ConfigProvider>
      }



    return (
        <Layout style={{minHeight: 750,backgroundColor: '#f9f9f9'}}>
          
          <LoginRegisterModal></LoginRegisterModal>
          <div className={examEditStyles.courseMain}>
            
              <Row align="middle" style={{borderBottom: '1px solid #eee',height:60}}>
                <Col span={12} style={{textAlign:"left"}}> <div style={{fontWeight:700,fontSize:16}}>编辑考试信息</div> </Col>
                <Col span={12} style={{color:"#333333",textAlign:"right"}}>  <a style={{color:"#333333"}} href={'/course-teacher-exam-preview?exam_id=' + examId + "&course_id=" + courseId + "&menu_id=" + currentCourseMenuId }>返回{">"}</a> </Col>
              </Row>
              
              
              <ConfigProvider locale={zhCN}>
                  <div>
                    {ExamBasicInfo()}
                  </div>
                  { examExercises && 
                  <CourseTeacherExamComposition
                      course_id = {courseId}
                      menu_id={currentCourseMenuId}
                      initExercises={examExercises}
                      onExamExerciseChange={onExamExerciseChange}/> }
                  <div>
                      <Button
                          className={courseCreateExamStyles.stepsBtn}
                          style={{marginRight:20}}
                          type="default"
                          onClick={onSaveBtnClick}
                        >
                          保存
                        </Button>

                  </div>
            
              </ConfigProvider>
          
          </div>
       </Layout>
    )

}

export default CourseTeacherExamEdit

