import {
    Pagination,
    Layout,
    message,
    ConfigProvider,
    Input,
    Table,
    Modal,
    Button,
    Row,
    Statistic,
    Col,
    Popconfirm,
    Tabs,
    Radio,
    Popover,
    Card
} from 'antd';

import * as constants from '../../../constants/constants';
import {BACKEND_API_URL } from '../../../constants/constants';
import {SearchOutlined} from '@ant-design/icons';

import { Content } from 'antd/lib/layout/layout';
import React, { useState, useRef, useEffect } from "react";
import Cookies from 'js-cookie';
import zhCN from 'antd/lib/locale/zh_CN';
import {sendHttpPostRequest} from "../../../api/http-request";

import Draggable from 'react-draggable';

const { Search } = Input;
const { TabPane } = Tabs;


import TextArea from 'antd/lib/input/TextArea';
import OAMSider from "../oam-sider";

import idataCodingTableStyles from "../../common/global-styles/idatacourse-table.module.less";
import menuStyles from "../menu/menu-edit.module.less";
import Meta from 'antd/lib/card/Meta';
import UserCard from '../user/oam-userinfo-card';

export interface Props {}
const statusMap = new Map([[1, '下线'], [2, '在线']]);
const paidMap = new Map([[1, '未付费'], [2, '已付费']]);

/** 对某一个机构的用户进行管理 */

const sexMap = new Map([[1, '未设置'], [2, '男'], [3, '女']]);
const roleMap = new Map([[1, '学生'], [2, '教师']]);


export const OAMInstitutionUserManager: React.FC<Props> = (props) => {

    const collegeId = parseInt(window.location.search.split("=")[1]);  // 机构ID
    const [college, setCollege] = useState( null as any); //机构对象

    const [userResult, setUserResult] = useState([] as any[]);  // 当前显示的结果页
    
    const [resultSum, setResultSum] = useState(0);  // 搜索返回的结果数

    const [studentList, setStudentList] = useState([] as any[]);  // 机构学生列表
    const [teacherList, setTeacherList] = useState([] as any[]);  // 机构教师列表

    const queryContent = useRef('');  // 记录搜索串
    const order = useRef('');  // 排序升序降序
    const order_by = useRef('');  // 排序列
    const page = useRef(1);  // 记录当前的页数
    const pageSize = useRef(20);  // 记录当前每页展示数量
    const sexFilters = useRef([]);  // 性别过滤选项
    const roleFilters = useRef([]);  // 身份过滤选项
    const queryType = useRef("1");  // 记录搜索串
    const channelFilters = useRef([]);  // 渠道过滤项
    const userIsLogin = useRef(false);  // 用户是否登录

    const draggleRef = useRef<HTMLDivElement | null>(null)
  
    const [bounds,setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const [disabled,setDisabled] = useState(true)

    const [userPlatFormResult, setUserPlatFormResult] = useState([] as any[]);  // 当前显示的结果页
    const [platformUserResultSum, setPlatformUserResultSum] = useState(0);  // 搜索返回的结果数
    const [totalCount, setTotalCount] = useState(0);  // 搜索返回的结果数

    const queryUserContent = useRef('');  // 记录搜索串
    
    const pagePlatformUser = useRef(1);  // 记录当前的页数
    const pageSizePlatformUser = useRef(10);  // 记录当前每页展示数量
   
    const [selectedPlatformUser,setSelectedPlatformUser] = useState(null as any);//选择的用户
    const [selectedPlatformUserRole,setSelectedPlatformUserRole] = useState(null as any);//选择的用户加入机构的身份 1学生 2老师
    const [isSelectUserModalVisible, setIsSelectUserModalVisible] = useState(false);
    const onStart = (event:any, uiData:any) => {
        const { clientWidth, clientHeight } = window?.document?.documentElement;
        const targetRect= draggleRef?.current?.getBoundingClientRect();
        if(targetRect){
          setBounds( {
            left: -targetRect?.left + uiData?.x,
            right: clientWidth - (targetRect?.right - uiData?.x),
            top: -targetRect?.top + uiData?.y,
            bottom: clientHeight - (targetRect?.bottom - uiData?.y),
          }
        )
      }
  
      }
    


    useEffect(() => {
        queryInstitution();
        queryInstitutionUserList();
        queryPlatformUserList();
    }, [])
    // 查询机构信息
    const queryInstitution = () => {
        const sessionid = Cookies.get("sessionid");
        const queryData = {
        
            college_id: collegeId,
            sessionid: sessionid,
            sid: sessionid,
        };
        sendHttpPostRequest(
            'college/api/oam_query_college_by_id/',
            queryData,
            institutionCallback
        )
    }


    // 查询机构用户列表接口回调
    const institutionUserCallback = (response: any) => {
        // console.log(response);
        message.destroy();
        if (response.code == -100) {
            // 未登录
            window.location.href = '/oam_login?next=/oam_institution_list';
        } else if (response.is_ok == true) {
            // 成功
            setStudentList(response.student_list);
            setTeacherList(response.teacher_list);
            setUserResult(response.query_list);
            setResultSum(response.total_sum);
        
        } else if (response.code == -501) {
            // 权限不足
            window.location.href = '/oam_login?next=oam_institution_list';
        } else {
            message.error(response.message);
        }
    }

    // 查询机构用户列表信息
    const queryInstitutionUserList = () => {
        const sessionid = Cookies.get("sessionid");
        const queryData = {
        
            college_id: collegeId,
            type: queryType.current,
            query: queryContent.current, //搜索接口有些问题
            page:page.current,
            pagesize:pageSize.current,
            sessionid: sessionid,
            sid: sessionid,
        };
        sendHttpPostRequest(
            'college/api/oam_query_college_user/',
            queryData,
            institutionUserCallback
        )
    }


    // 查询机构接口回调
    const institutionCallback = (response: any) => {
        // console.log(response);
        message.destroy();
        if (response.code == -100) {
            // 未登录
            window.location.href = '/oam_login?next=/oam_institution_list';
        } else if (response.is_ok == true) {
            // 成功
            setCollege(response.college_info);
        
        } else if (response.code == -501) {
            // 权限不足
            window.location.href = '/oam_login?next=oam_institution_list';
        } else {
            message.error(response.message);
        }
    }



    // 查询平台用户列表
    const queryPlatformUserList = () => {
        const sessionid = Cookies.get("sessionid");
        const queryData = {
            query: queryUserContent.current,
            page: pagePlatformUser.current,
            pagesize: pageSizePlatformUser.current,
            sex: JSON.stringify([]),
            role:JSON.stringify([]),
            channel:JSON.stringify([]),
        
            sessionid: sessionid,
            sid: sessionid,
        };
        sendHttpPostRequest(
            'account/api/query_user_list/',
            queryData,
            (response: any) => {
                // console.log(response);
                message.destroy();
                if (response.code == -100) {
                    // 未登录
                    window.location.href = '/oam_login?next=user_list';
                } else if (response.code == 0) {
                    // 成功
                    setUserPlatFormResult(response.user_list);
                    setPlatformUserResultSum(response.total_sum);
                    
                } else if (response.code == -501) {
                    // 权限不足
                    window.location.href = '/oam_login?next=user_list';
                } else {
                    message.error(response.message);
                }
            }
        )
    }


    // 点了移出按钮
    const onDeleteUserBtnClick = (userId: string, event: any) => {
        const sessionid = Cookies.get("sessionid");
        const queryData = {

            college_id: collegeId,
            user_id: JSON.stringify([userId]),
            //query: queryContent.current, //搜索接口有些问题
            sessionid: sessionid,
            sid: sessionid,
        };
        sendHttpPostRequest(
            'college/api/oam_remove_college_user/',
            queryData,
            (response: any) => {
                // console.log(response);
                message.destroy();
                if (response.code == -100) {
                    // 未登录
                    window.location.href = '/oam_login?next=/oam_institution_list';
                } else if (response.is_ok == true) {
                    // 成功
                    setCollege(response.college_info);
                    queryInstitutionUserList();
                    queryInstitution();   
                } else if (response.code == -501) {
                    // 权限不足
                    //window.location.href = '/oam_login?next=oam_institution_list';
                } else {
                    message.error(response.message);
                }
            }
        )
        //window.open(`/oam_preview_institution?college_id=${collegeId}`);
    }


       // 删除回调
       const onDeleteBtnClickCallback = (response: any) => {
        message.destroy();
        if (response.code == -100) {
            // 没登录
            window.location.href = '/oam_login?next=oam_institution_list';
        } else if (response.code == -501) {
            // 没权限
            window.location.href = '/oam_login?next=oam_institution_list';
        } else if (response.code == 0) {
            message.success(response.message);
            //queryInstitutionList();
        } else {
            message.error(response.message);
        }
    }



    // 渲染操作
    const renderActions = (text:any, record:any) => {
        return (
            <Row justify="start" align="middle" gutter={[8, 8]} >
                <Col style={{width:50}}>
                    <Popconfirm onConfirm= {onDeleteUserBtnClick.bind(this, record.user_id)} title={"确认移出 " + (record.real_name==''?record.name:record.real_name) + " 吗？"}>
                    <Button
                        style={{borderRadius:20,fontSize:14}}
                        size='small' id="delete" value={record.id} danger type="default"
                       // onClick={onDeleteUserBtnClick.bind(this, record.user_id)}
                    > 移 出 </Button>
                    </Popconfirm>
                </Col>
                  
            </Row>
        )
    }

    

    //点击了搜索按钮
    const onSearchBtn = (value: string) => {
        page.current = 1;
        queryContent.current = value;
        queryInstitutionUserList();
    }

    // 表格发生变化
    const onTableChange:any = (pagination:any, filters:any, sorter:any, extra: { currentDataSource: [], action: any })=> {
        if (sorter.order) { //如果选择了排序列
            order.current = sorter.order;
            order_by.current = sorter.field;
        }
        if (filters.sex) { //如果选择了过滤列
            sexFilters.current = filters.sex;
        } else {
            sexFilters.current = [];
        }
        if (filters.role) {
            roleFilters.current = filters.role;
        } else {
            roleFilters.current = [];
        } 
        page.current = 1;
    }


    const onTabKeyChange:any = (key:string) => {
        queryType.current = key;
        page.current = 1;
        queryInstitutionUserList();
    }

        
    // 表格列
    const userCols: any = [
        {
            title: "序号",
            dataIndex: "user_id",
            key: "user_id",
            width: 60,
            align: 'left',
            render:(text, record, index)=>((page.current - 1)*pageSize.current + index + 1)
        },
        {
            title: "ID",
            dataIndex: "user_id",
            key: "user_id",
            width: 60,
            align: 'left',
        },
        {
            title: "用户名",
            dataIndex: "name",
            key: "name",
            width: 120,
            align: 'left',
            render:(text,record)=>{
                return <Row align="middle">
                <Col flex="40px"><Popover  arrowPointAtCenter={true} trigger="hover" placement="left" content={<UserCard user_id={record.user_id} />} title={null}>
                <img  onMouseOver={()=>{}} style={{width:30,height:30,borderRadius:15,cursor:"pointer"}} src={ record?.avatar?.startsWith("http") ? record?.avatar : BACKEND_API_URL + record?.avatar} />
                
            </Popover> </Col>
            <Col flex="80px"><span >{text}</span></Col></Row>
            }
        },
        {
            title: "姓名",
            dataIndex: "real_name",
            key: "real_name",
            width: 80,
            align: 'left',
        },
        {
            title: "手机",
            dataIndex: "phone",
            key: "phone",
            width: 80,
            align: 'left',
        },
        {
            title: "身份",
            dataIndex: "role",
            key: "role",
            width: 60,
            align: 'center',
            render: (text:any, record:any)=>{ return text == 2 ? "教师" : "学生"}
        },
        {
            title: "注册时间",
            dataIndex: "register_time",
            key: "register_time",
            width: 120,
            align: 'center',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: "操作",
            dataIndex: "actions",
            key: "actions",
            width: 50,
            ellipsis: false,
            align: 'center',
            fixed: 'right',
            render: renderActions,
        },
    ]



    /** 选择了用户 */
    const rowPlatformUserSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
            setSelectedPlatformUser(selectedRows[0]);
            //console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        getCheckboxProps: (record: any) => ({
            disabled: false,
            //name: record.name,
        }),
        };

    //点击了搜索按钮
    const onSearchPlatformUserBtn = (e: any) => {
        pagePlatformUser.current = 1;
        queryUserContent.current = e.target.value;
        queryPlatformUserList();
    }

        //页码和页数发生了变化
        const onPageChange = (newPage: number, newPageSize?: number | undefined) => {
            page.current = newPage ? newPage : 1;
            if (newPageSize) {
                pageSize.current = newPageSize;
            }
            queryInstitutionUserList();
        }


            //页码和页数发生了变化
    const onPlatformUserPageChange = (newPage: number, newPageSize?: number | undefined) => {
        pagePlatformUser.current = newPage ? newPage : 1;
        if (newPageSize) {
            pageSizePlatformUser.current = newPageSize;
        }
        queryPlatformUserList();
    }

    const handleSelectUserOk = () => { //选择了用户
        if(!selectedPlatformUser){
            message.error("请选择用户！")
        }else if(!selectedPlatformUserRole){
            message.error("请选择用户身份！")
        }else{
            setIsSelectUserModalVisible(false);

            const sessionid = Cookies.get("sessionid");
            const queryData = {
                user_id: selectedPlatformUser?.user_id,
                college_id: collegeId,
                role: selectedPlatformUserRole, //1xuesheng 2laoshi

                    
                sessionid: sessionid,
                sid: sessionid,
            };
            sendHttpPostRequest(
                'college/api/oam_add_college_user',
                queryData,
                (response: any) => {
                    // console.log(response);
                    message.destroy();
                    if (response.code == -100) {
                        // 未登录
                        window.location.href = '/oam_login?next=user_list';
                    } else if (response.is_ok == true) {
                        // 成功
                        message.success("添加成功");
                    
                        queryInstitutionUserList();
                        
                    } else if (response.code == -501) {
                        // 权限不足
                        window.location.href = '/oam_login?next=user_list';
                    } else {
                        message.error(response.message);
                    }
                }
            )

        }
        

    
    }



    const showSelectUserModal = () => {
       setIsSelectUserModalVisible(true);
    };

    const  handleSelectUserCancel = () => {
        setIsSelectUserModalVisible(false)
    }


    // 渲染性别
    const renderSex = (text: any, record: any) => (
        <div>{sexMap.get(parseInt(text))}</div>
    )

        // 渲染身份
        const renderRole = (text: any, record: any) => (
            <div>{roleMap.get(parseInt(text))}</div>
        )

        // 表格列
        const userPlatformCols: any = [
            {
                title: "序号",
                dataIndex: "user_id",
                key: "user_id",
                width: 60,
                align: 'left',
                render:(text, record, index)=>((pagePlatformUser.current - 1)*pageSizePlatformUser.current + index + 1)
            },
            
            {
                title: "ID",
                dataIndex: "user_id",
                key: "user_id",
                width: 60,
                align: 'left',
            },
            {
                title: "手机号",
                dataIndex: "phone",
                key: "phone",
                width: 80,
                align: 'center',
            },
            {
                title: "注册时间",
                dataIndex: "register_date",
                key: "register_date",
                width: 120,
                align: 'center',
                sorter: true,
                sortDirections: ['descend', 'ascend'],
                render: (text: any, record:any)=>{return text.slice(0,10)}
            },
            {
                title: "昵称",
                dataIndex: "nick_name",
                key: "nick_name",
                width: 120,
                align: 'left',
                render:(text,record)=>{
                    return <Row align="middle">
                    <Col flex="40px"><Popover  arrowPointAtCenter={true} trigger="hover" placement="left" content={<UserCard user_id={record.user_id} />} title={null}>
                    <img  onMouseOver={()=>{}} style={{width:30,height:30,borderRadius:15,cursor:"pointer"}} src={ record?.avatar?.startsWith("http") ? record?.avatar : BACKEND_API_URL + record?.avatar} />
                    
                </Popover> </Col>
                <Col flex="80px"><span >{text}</span></Col></Row>
                }
            },
            {
                title: "真实姓名",
                dataIndex: "real_name",
                key: "real_name",
                width: 60,
                align: 'left',
            },
            {
                title: "性别",
                dataIndex: "sex",
                key: "sex",
                width: 50,
                align: 'center',
                render: renderSex,
                filters: [
                    {
                        text: '未设置',
                        value: 1,
                    },
                    {
                        text: '男',
                        value: 2,
                    },
                    {
                        text: '女',
                        value: 3,
                    }
                ],
                onFilter: (value:any, record:any) => true,
            },
            /*{
                title: "邀请者",
                dataIndex: "inviter",
                key: "inviter",
                width: 80,
                align: 'left',
            },*/
            
        ]
    
    return (
        <Layout style={{ minHeight: '100vh' }}>
            <OAMSider keys={"2"} openKeys={["sub-user"]}></OAMSider>
            <Layout style={{backgroundColor:'#f9f9f9'}}>
                <Content style={{ backgroundColor:'#fff',borderRadius:5,minWidth:400,margin:30 ,paddingTop:20}}>
                    <div style={{paddingLeft:30,paddingRight:30}}>
                        <div className={"css.search1"} style={{textAlign:"left",paddingBottom:20}}>
                        <Row align="middle"  gutter={16} style={{marginBottom:10}} >
                        <Col>
                                <img
                                    width={200}
                                    height={50}
                                    src={ college?.logo_url?.startsWith("http") ? college?.logo_url : constants.BACKEND_URL + college?.logo_url}
                                    /> 
                            </Col>
                            <Col flex='200px'>
                                机构名 {college?.name}
                            </Col>
                           
                            <Col flex='200px'>
                                <Row align="middle">
                                    <Col style={{color:"#999",marginRight:10}}>管理员</Col>
                                    <Col flex="40px"><Popover  arrowPointAtCenter={true} trigger="hover" placement="left" content={<UserCard user_id={college?.manager_id} />} title={null}>
                                    <img  onMouseOver={()=>{}} style={{width:30,height:30,borderRadius:15,cursor:"pointer"}} src={ college?.manager_avatar?.startsWith("http") ? college?.manager_avatar : BACKEND_API_URL + college?.manager_avatar} />
                                    
                                </Popover> </Col>
                                <Col flex="80px"><span >{college?.manager_real_name == ''? college?.manager_name :college?.manager_real_name}</span></Col></Row>
                            
                            </Col>

                            <Col flex="auto" style={{textAlign:"right"}}><Button style={{borderRadius:20,marginRight:20}} type="primary" onClick={()=>{window.location.href=`/oam_institution_edit?college_id=${collegeId}`}}>编辑机构</Button></Col>
                        </Row>

                        


                        <Row align="middle"  gutter={16}  style={{marginBottom:10}} >
                            <Col flex='80px'>
                                <Statistic title="师生总数" value={college?.student_count + college?.teacher_count } />
                            </Col>
                           
                            <Col flex='80px'>
                                <Statistic title="学生数" value={college?.student_count} />
                            </Col>

                            <Col flex='80px'>
                                <Statistic title="教师数" value={college?.teacher_count} />
                            </Col>
                        </Row>

                        <Row align="middle"  gutter={16} style={{marginBottom:10}}  >
                            <Col flex='350px'>
                                <Search
                                    placeholder="搜索用户"
                                    style={{ width: 350 }}
                                    enterButton={true}
                                    onSearch={onSearchBtn}
                                />
                            </Col>

                            <Col flex='auto' style={{textAlign: 'right'}}>
                                <Button type="primary" style={{float: 'right', marginRight: 20, borderRadius: 20}} onClick={()=>{setSelectedPlatformUser(null); setSelectedPlatformUserRole(null);setIsSelectUserModalVisible(true)}}>添加用户</Button>
                            </Col>
                        </Row>
                            
                        </div>

                        <Tabs defaultActiveKey="1" onChange={onTabKeyChange}>
                            <TabPane tab="全部" key="1" className="">
                                <div className={idataCodingTableStyles.tableWrapper}>
                                <ConfigProvider locale={zhCN}>
                                    <Table
                                        dataSource={userResult}
                                        columns={userCols}
                                        rowKey={record => record.user_id}
                                        pagination={false}
                                        scroll={{ x: 800 }}
                                        //bordered={true}
                                        onChange={onTableChange}
                                    />
                                </ConfigProvider>
                                </div>
                            </TabPane>
                            <TabPane tab="学生" key="2" className="">
                                <div className={idataCodingTableStyles.tableWrapper}>
                                <ConfigProvider locale={zhCN}>
                                    <Table
                                        dataSource={userResult}
                                        columns={userCols}
                                        rowKey={record => record.user_id}
                                        pagination={false}
                                        scroll={{ x: 800 }}
                                        //bordered={true}
                                        onChange={onTableChange}
                                    />
                                </ConfigProvider>
                                </div>
                            </TabPane>
                            <TabPane tab="教师" key="3" className="">
                                <div className={idataCodingTableStyles.tableWrapper}>
                                <ConfigProvider locale={zhCN}>
                                    <Table
                                        dataSource={userResult}
                                        columns={userCols}
                                        rowKey={record => record.user_id}
                                        pagination={false}
                                        scroll={{ x: 800 }}
                                        //bordered={true}
                                        onChange={onTableChange}
                                    />
                                </ConfigProvider>
                                </div>

                            </TabPane>
                        </Tabs>
                        <ConfigProvider locale={zhCN}>
                            <div style={{marginBottom:30,paddingTop:30}} className={"css.pagination"} >
                                <Pagination
                                    defaultCurrent={1}
                                    total={resultSum}
                                    defaultPageSize={20}
                                    current={page.current}
                                    hideOnSinglePage={true}
                                    onChange={onPageChange}
                                    showQuickJumper={true}
                                    showTotal={total => `共 ${total} 用户`}
                                    pageSizeOptions={["3","20","30","50","100"]}
                                />
                            </div>
                        </ConfigProvider>


                    </div>

                    <ConfigProvider locale={zhCN}>
                    <Modal
                            title={<div
                            style={{
                            width: '100%',
                            cursor: 'move',
                            }}
                            onMouseOver={() => {
                            if (disabled) {
                                setDisabled(false)
                            }
                            }}
                            onMouseOut={() => {
                            setDisabled(true)
                            }}
                            onFocus={() => {}}
                            onBlur={() => {}}
                            // end
                        >
                            添加用户
                        </div>
                        }

                        closable={false}
                        visible={isSelectUserModalVisible}
                        onOk={handleSelectUserOk}
                        onCancel={handleSelectUserCancel}
                        bodyStyle={{ height:550,overflow:"auto"}}
                        destroyOnClose={true}
                        width={800}
                        modalRender={modal => (<div className={menuStyles.createDiv}>
                            <Draggable
                            disabled={disabled}
                            bounds={bounds}
                            onStart={(event, uiData) => onStart(event, uiData)}
                            >
                            <div ref={draggleRef}>{modal}</div>
                            </Draggable> </div>
                        )}
                        
                          
                    >
                    <Row>
                        <Col span={12} style={{textAlign:"left"}}>
                            <Input
                            onPressEnter={onSearchPlatformUserBtn} 
                            placeholder="名称/ID" 
                            style={{borderRadius:20,width:250,marginBottom:10}} 
                            suffix={<SearchOutlined  style={{color:"#666666"}}/>}
                            />

                        </Col>

                       { selectedPlatformUser &&  <Col span={12}  style={{textAlign:"right"}} >
                            <div ><span style={{marginRight:10,color:"#999"}}>加入身份:</span>
                            <Radio.Group onChange={(e)=>{setSelectedPlatformUserRole(e.target.value)}}>
                                <Radio value={2}> 教师 </Radio>
                                <Radio value={1}> 学生</Radio>
                            </Radio.Group>
                            </div>
                        </Col>}
                    </Row>


           
                    <div className={idataCodingTableStyles.tableWrapper}>
                        <ConfigProvider locale={zhCN}>
                            <Table
                                dataSource={userPlatFormResult}
                                columns={userPlatformCols}
                                rowKey={record => record.user_id}
                                pagination={false}
                                size="small"
                                //scroll={{ x: 600 }}
                                //bordered={true}
                                rowSelection={{
                                    type: "radio",
                                    ...rowPlatformUserSelection,
                                    }}
                            />
                        </ConfigProvider>
                    </div>
                    <ConfigProvider locale={zhCN}>
                        <div style={{marginBottom:10,paddingTop:10,textAlign:"center"}} className={"css.pagination"} >
                            <Pagination
                                defaultCurrent={1}
                                total={platformUserResultSum}
                                defaultPageSize={10}
                                hideOnSinglePage={true}
                                current={pagePlatformUser.current}
                                onChange={onPlatformUserPageChange}
                                //showQuickJumper={true}
                                showTotal={total => `找到 ${total} 用户`}
                                showSizeChanger={false}
                            />
                        </div>
                    </ConfigProvider>                         
                    
            </Modal>
                     </ConfigProvider>
                </Content>
            </Layout>
        </Layout>

    )

}

export default OAMInstitutionUserManager

