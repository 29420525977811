import {message,Col, Input, Layout, Row, Tooltip, Upload, Button } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Content } from 'antd/lib/layout/layout';
import { RouteComponentProps } from 'react-router';
import * as constants from '../../../constants/constants';

import React, {useEffect, useState} from "react";

const { Dragger } = Upload;

interface  Props extends  RouteComponentProps {
  
}

import myStyles from "./oam-video.module.less"
import {PlusOutlined,CheckCircleOutlined,InboxOutlined} from '@ant-design/icons';  

import { test_video_list } from './oam-video-list';

import OAMSider from '../oam-sider';
import Cookies from 'js-cookie';
import {sendHttpPostRequest} from "../../../api/http-request";

/** 视频上传页 */
export const OAMVideoUpload: React.FC<Props> = (props) => {
    //const video_id = props.location.search.split("=")[1]
    //const currentVideo = test_video_list.filter((item)=> item.id == video_id)[0]
    const id = 1 //视频ID

    const [lectureId, setLectureId] = useState("")// 视频id
    const [title, setTitle] = useState("")//视频标题
    const [description, setDescription] = useState("")//视频简介
    const [cover, setCover] = useState(null as any) //视频封面

    const [teacher, setTeacher] = useState("")  // 老师
    const [teacherTitle, setTeacherTitle] = useState("") //老师
    const [teacherDescription, setTeacherDescription] = useState("") //老师

    const [videoPath, setVideoPath] = useState("") // 视频 存储地址
    const [videoUrl, setVideoUrl] = useState("") // 视频 url地址
    const [duration, setDuration] = useState("") // 视频 时长

    const [coverFileList, setCoverfileList] = useState([])

    useEffect(() => {
        // 创建新的lecture对象
        sendHttpPostRequest(
        'video/api/oam_create_lecture/',
        {
            sessionid: Cookies.get("sessionid"),
            },
            onLectrueCreateCallback
        )
    }, [])
    const onLectrueCreateCallback = (response: any) => {
        message.destroy();
        if (response.code == -100) {
            // 未登录
            window.location.href = '/oam_login?next=user_list';
        } else if (response.code == 0) {
            // 成功
            setLectureId(response.lecture_id);
        } else if (response.code == -501) {
            // 权限不足
            window.location.href = '/oam_login?next=user_list';
        } else {
            message.error(response.message);
        }
    }

    // 上传视频封面
    const onCoverChange = (info: any) => {
        setCoverfileList(info.fileList)
        const { status } = info.file
        if (status !== 'uploading') {
            //上传中
            console.log(info.file, info.fileList)
        }
        if (status === 'done') {
            //上传成功
            let new_cover = info.file.response.img_url
            let code = info.file.response.code
            code == -100 ? ()=>{} : document.getElementById("video-cover")?.setAttribute("src", new_cover)
            // image.current = info.file.response.img_url
            setCover(constants.BACKEND_URL + info.file.response.image_url )
        } else if (status === 'error') {
            //上传失败
            message.error(`${info.file.name} file upload failed.`)
        }
    }


    // 上传视频文件
    const onVideoFileChange = (info: any) => {
      setCoverfileList(info.fileList)
      const { status } = info.file
      if (status !== 'uploading') {
        //上传中
        console.log(info.file, info.fileList)
      }
      if (status === 'done') {
        //上传成功
        let code = info.file.response.code

        setVideoPath(info.file.response.video_path);
        setVideoUrl(info.file.response.video_url);
        setDuration(info.file.response.duration);
        
      } else if (status === 'error') {
        //上传失败
        message.error(`${info.file.name} file upload failed.`)
      }
    }


    // 校验图片类型和大小
    function beforeCoverUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('请上传png或jpg格式的图片!');
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error('请上传小于5M的图片');
    }
    return isJpgOrPng && isLt5M;
    }

    // 上传cover传参
    const uploadCoverData = {
        sessionid: Cookies.get("sessionid"),
        sid: Cookies.get("sessionid"),
        video_id: lectureId,
    }

    const uploadCoverButton = (
        <div>
          {<PlusOutlined />}
          <div style={{ marginTop: 8 }}>上传封面</div>
        </div>
    );

    //点击发布按钮
    const onPublishBtnClick = (event:any) => {
        //message.success('编辑数据' + event.currentTarget.value)
        event.preventDefault();
        sendHttpPostRequest(
            'video/api/oam_update_lecture/',
            {
                sessionid: Cookies.get("sessionid"),
                lecture_id:lectureId,
                title: title,
                description: description,
                cover: cover,
                video_path: videoPath,
                video_url: videoUrl,
                duration: duration,
                teacher: teacher,
                teacher_title: teacherTitle,
                teacher_description : teacherDescription,
                //exercise_id: exerciseId
            },
            onPublishBtnClickCallback
        )

    }

    const onPublishBtnClickCallback = (response: any) => {
        message.destroy();
        if (response.code == -100) {
            // 未登录
            window.location.href = '/oam_login?next=user_list';
        } else if (response.code == 0) {
            // 成功
            //setLectureId(response.lecture_id);
            window.open( "/oam_video_upload-success");

        } else if (response.code == -501) {
            // 权限不足
            window.location.href = '/oam_login?next=user_list';
        } else {
            message.error(response.message);
        }
    }

  const uploadVideoProps = {
    accept: ".mp4,.mov",
    action : constants.BACKEND_API_URL + 'video/api/upload_video_file/',
    name : "upload_video_file",
    video_id:id,
    onChange : onVideoFileChange,
    multiple:false,
    maxCount:1,
  }

    const onTitleChange = (event: any) => {
        setTitle(event.target.value);
    }

    const onDescriptionChange = (event: any) => {
        setDescription(event.target.value);
    }

    const onTeacherChange = (event: any) => {
        setTeacher(event.target.value);
    }

    const onTeacherTitleChange = (event: any) => {
        setTeacherTitle(event.target.value);
    }

    const onTeacherDescriptionChange = (event: any) => {
        setTeacherDescription(event.target.value);
    }

    return (
        <Layout style={{ minHeight: '100vh' }}>
        <OAMSider keys={"34"} openKeys={["sub-course"]}></OAMSider>
        <div>
            <Content style={{ minHeight: '90vh',backgroundColor:'#fff',borderRadius:5,width:1000,margin:30 ,paddingTop:40}}>
            <div style={{paddingLeft:80,paddingRight:80}}>


                <Row gutter={[16,20]} justify='start'  align='top' style={{marginBottom:20}} >
                    <Col span={4}  style={{color:"#00000073",textAlign:'left'}}>视 频</Col>
                    <Col span={20}  style={{textAlign:'left'}}>
                        
                        <Dragger data={uploadCoverData} {...uploadVideoProps}>
                        <p >
                          <InboxOutlined  style={{fontSize:48,color:"#07c160"}}/>
                        </p>
                        <div style={{fontSize:14}}>点击上传或拖拽视频到此区域</div>
                        <div style={{fontSize:12,color:"#00000073"}}>
                          支持常见视频格式，推荐使用mp4、mov，大小不超过1G的视频
                        </div>
                        </Dragger>

                        <div >
                            { videoUrl ?
                            <video  style={{marginTop:25,marginBottom:25,height:'130px',width:"250px"}}  preload="preload"  controls={true}>
                                <source
                                src={videoUrl?.startsWith("http") ? videoUrl : (constants.BACKEND_URL + videoUrl)}
                                type={"video/mp4"} />
                                您的浏览器不支持 HTML5 video 标签。
                            </video>
                                : <span></span>
                            }
                        </div>
                        
                    </Col >

                    <Col span={4}  style={{color:"#00000073",textAlign:'left'}}>封 面</Col>
                    <Col span={20}  style={{textAlign:'left'}}>
                        <div className={myStyles.uploadCover}>
                          <Upload
                            beforeUpload={beforeCoverUpload}
                            accept={'image/*'}
                            action={constants.BACKEND_API_URL + 'video/api/upload_cover/'}
                            listType="picture-card"
                            name="video_cover"
                            fileList={coverFileList}
                            onChange={onCoverChange}
                            data={uploadCoverData}
                            showUploadList={false}
                        >
                        { cover ?
                            <img
                            id="video-cover"
                            style={{width:"100%",height:"100%"}}
                            src={cover}
                            /> : uploadCoverButton

                        }
                            
                        </Upload>
                        </div>
                    </Col>



                    <Col span={4} style={{color:"#00000073",textAlign:'left'}}>标 题</Col>
                    <Col span={20} style={{textAlign:'left'}}>
                        <Input placeholder="请输入视频题目" 
                            required={true}
                            value={title}
                            defaultValue = {title}
                            onChange={onTitleChange}
                            style={{borderRadius:6,width:"100%"}}/>
                    </Col>
               
                    <Col span={4}  style={{color:"#00000073",textAlign:'left'}}>简 介</Col>
                    <Col span={20}  style={{textAlign:'left'}}>
                        <TextArea placeholder="请输入视频简介" 
                            style = {{borderRadius:6,width:"100%"}}
                            autoSize = {{minRows: 5, maxRows: 5}}
                            defaultValue = {description}
                            value = {description}
                            onChange={onDescriptionChange}
                        />

                    </Col>

                    <Col span={4}  style={{color:"#00000073",textAlign:'left'}}>老师</Col>
                    <Col span={20}  style={{textAlign:'left'}}>
                        <TextArea placeholder="请输入老师"
                            style = {{borderRadius:6,width:"100%"}}
                            autoSize = {{minRows: 5, maxRows: 5}}
                            defaultValue = {teacher}
                            value = {teacher}
                            onChange={onTeacherChange}
                        />
                    </Col>

                    <Col span={4}  style={{color:"#00000073",textAlign:'left'}}>老师title</Col>
                    <Col span={20}  style={{textAlign:'left'}}>
                        <TextArea placeholder="请输入老师title"
                            style = {{borderRadius:6,width:"100%"}}
                            autoSize = {{minRows: 5, maxRows: 5}}
                            defaultValue = {teacherTitle}
                            value = {teacherTitle}
                            onChange={onTeacherTitleChange}
                        />
                    </Col>


                    <Col span={4}  style={{color:"#00000073",textAlign:'left'}}>老师简介</Col>
                    <Col span={20}  style={{textAlign:'left'}}>
                            <TextArea placeholder="请输入老师简介"
                                style = {{borderRadius:6,width:"100%"}}
                                autoSize = {{minRows: 5, maxRows: 5}}
                                defaultValue = {teacherDescription}
                                value = {teacherDescription}
                                onChange={onTeacherDescriptionChange}
                            />
                    </Col>



                    <Col span={24} style={{marginTop:20,textAlign:"center"}}>
                      <Button style={{borderRadius:20,width:100}} onClick={onPublishBtnClick} type="primary">发 布</Button>
                    </Col>
                </Row>

            </div>
  
            </Content>
        </div>
    </Layout>
    )

}

export default OAMVideoUpload

