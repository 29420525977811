import React, {useEffect, useRef, useState} from "react";
import {Tooltip,Progress,Tree,Drawer,Layout, message, Card, Col, Row, ConfigProvider, Pagination, Tabs, Input, Upload, Button, Popconfirm, Select, Menu, Table, Modal } from 'antd';
import { PageHeader } from "../common/page-header/header";
import { sendHttpPostRequest } from "../../api/http-request";
import logoSvg from '../../static/image/idclogo.png';
import logo from '../../static/image/college/logo.png';
import pekinglogoSvg from '../../static/image/peking.png';
import {SafetyOutlined,DownOutlined,ArrowLeftOutlined,FormOutlined,CodeOutlined,ProfileOutlined,LeftCircleOutlined,RightCircleOutlined} from '@ant-design/icons';
import Cookies from 'js-cookie';
import zhCN from 'antd/lib/locale/zh_CN'
import * as constants from '../../constants/constants';
import PageFooter from "../college/college-footer";
import LoginRegisterModal from "../../component/modal/login-register/login-register";
import UserInfo, {checkUserLogin} from "../common/user-center/user-info";
import { UserOutlined } from '@ant-design/icons';
import { Content, Footer } from "antd/lib/layout/layout";
import Sider from "antd/lib/layout/Sider";
import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';
import {UploadOutlined,FileImageOutlined} from '@ant-design/icons';
import collegeStyles from './college.module.less';


import courseBg1 from '../../static/image/college-oam/courseBg1.png';
import courseBg2 from '../../static/image/college-oam/courseBg2.png';
import courseBg3 from '../../static/image/college-oam/courseBg3.png';
import courseBg4 from '../../static/image/college-oam/courseBg4.png';

import idataCourseTableStyles from "../common/global-styles/idatacourse-table.module.less";
import courseIcon from '../../static/image/college/course.png';

// 通过路由组件额外添加参数信息到表中
import {RouteComponentProps} from "react-router";
import SplitPane from "react-split-pane";
import caseStyles from "./case.module.less";
import ReactDOM from "react-dom";
import { identifier } from "@babel/types";
import { Link } from "react-router-dom";
const { TextArea } = Input;
const { Option } = Select;
const { Header } = Layout;
const { Search } = Input;
const { TabPane } = Tabs;

import collegeManageStyles from "./college-oam.module.less";
import Draggable from "react-draggable";

interface Props  {
    college_id : any,
}


export const CollegeCourseList: React.FC<Props> = (props) => {
    const {college_id} = props; 

    const [removeModalVisible, setRemoveVisible] = useState(false);

    const draggleRef = useRef<HTMLDivElement | null>(null);
    const [bounds,setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const [disabled,setDisabled] = useState(true)
  
    const onStart = (event:any, uiData:any) => {
      const { clientWidth, clientHeight } = window?.document?.documentElement;
      const targetRect= draggleRef?.current?.getBoundingClientRect();
      if(targetRect){
        setBounds( {
          left: -targetRect?.left + uiData?.x,
          right: clientWidth - (targetRect?.right - uiData?.x),
          top: -targetRect?.top + uiData?.y,
          bottom: clientHeight - (targetRect?.bottom - uiData?.y),
        }
      )
      }
    }
  
  const queryContent = useRef('');  // 记录搜索串
  const order = useRef('');  // 排序升序降序
  const order_by = useRef('');  // 排序列
  const page = useRef(1);  // 记录当前的页数
  const pageSize = useRef(10);  // 记录当前每页展示数量
  const sexFilters = useRef([]);  // 性别过滤选项
  const roleFilters = useRef([]);  // 身份过滤选项
  const queryType = useRef("2");  // 2 平台  1  教师
  const channelFilters = useRef([]);  // 渠道过滤项
  const userIsLogin = useRef(false);  // 用户是否登录


  const [collegeInfo, setCollegeInfo] =  useState(null as any);  // 当前院校信息

  const [courseResult, setCourseResult] = useState([] as any[]);  // 当前显示的结果页
    
  const [resultSum, setResultSum] = useState(0);  // 搜索返回的结果数

  const [totalCount, setTotalCount] = useState(0);//当前机构总课程
  const [payedCourseCount, setPayedCourseCount] = useState(0);//当前机构已购买课程
  const [systemCourseCount, setSystemCourseCount] = useState(0);//平台上线总课程
  const [teacherCourseCount, setTeacherCourseCount] = useState(0);//当前机构教师总课程
  const [teacherPublishedCourseCount, setTeacherPublishedCourseCount] = useState(0);//当前机构教师已发布课程总数
  const [inTeachingCourseCount, setInTeachingCourseCount] = useState(0);//当前机构正在进行中课程





  useEffect(()=> {
    queryInstitution(college_id);
    queryInstitutionCourseList(college_id);

  },[])


  // 查询课程信息
  const queryInstitution = (collegeId: any) => {
      const sessionid = Cookies.get("sessionid");
      const queryData = {
          
          college_id: collegeId,
          sessionid: sessionid,
          sid: sessionid,
      };
      sendHttpPostRequest(
          'college/api/manager_query_college_by_id/',
          queryData,
          institutionCallback
      )
  }


  // 查询机构接口回调
  const institutionCallback = (response: any) => {
      // console.log(response);
      message.destroy();
      if (response.code == -100) {
          // 未登录
          window.location.href = '/login?next=/college-oam';
      } else if (response.is_ok == true) {
          // 成功
          setCollegeInfo(response.college_info);

         
      } else if (response.code == -501) {
          // 权限不足
          window.location.href = '/login?next=/college-oam';
      } else {
          message.error(response.message);
      }
  }


    // 查询机构课程列表接口回调
    const institutionCourseCallback = (response: any) => {
        // console.log(response);
        message.destroy();
        if (response.code == -100) {
            // 未登录
            window.location.href = '/login?next=/college-oam';
        } else if (response.is_ok == true) {
            // 成功
            setCourseResult(response.query_list);
            setResultSum(response.total_sum);
            setTotalCount(response.all_count);
            setPayedCourseCount(response.ispay_count);
            setSystemCourseCount(response.sys_count);
            setTeacherCourseCount(response.college_count);
            setTeacherPublishedCourseCount(response.publish_count);
            setInTeachingCourseCount(response.onair_count);
        
        } else if (response.code == -501) {
            // 权限不足
            window.location.href = '/login?next=/college-oam';
        } else {
            message.error(response.message);
        }
    }


    // 查询机构课程列表信息
    const queryInstitutionCourseList = (collegeId) => {
        const sessionid = Cookies.get("sessionid");
        const queryData = {
            college_id: collegeId,
            source: queryType.current,
            query: queryContent.current, //搜索接口有些问题
            page:page.current,
            pagesize: pageSize.current,
            sessionid: sessionid,
            sid: sessionid,
        };
        sendHttpPostRequest(
            'course/api/manager_query_course_list/',
            queryData,
            institutionCourseCallback
        )
    }




    //点击了搜索按钮
    const onSearchBtn = (value: string) => {
        page.current = 1;
        queryContent.current = value;
        queryInstitutionCourseList(college_id);
    }

    // 表格发生变化
    const onTableChange:any = (pagination:any, filters:any, sorter:any, extra: { currentDataSource: [], action: any })=> {
        if (sorter.order) { //如果选择了排序列
            order.current = sorter.order;
            order_by.current = sorter.field;
        }
        if (filters.sex) { //如果选择了过滤列
            sexFilters.current = filters.sex;
        } else {
            sexFilters.current = [];
        }
        if (filters.role) {
            roleFilters.current = filters.role;
        } else {
            roleFilters.current = [];
        }
        
        page.current = 1;
        //queryInstitutionList();
    }


    const onTabKeyChange:any = (key:string) => {
        console.log(key);
        queryType.current = key;
        page.current = 1;
        queryInstitutionCourseList(college_id);
    }



    // 表格列
    const systemCourseCols: any = [
        {
            title: '序号',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: 50,
            render:(text, record, index)=>((page.current - 1)*pageSize.current + index + 1)
          },
          {
            title: '课程名称',
            dataIndex: 'name',
            key: 'name',
            align: 'left',
            width: 200,
          },
          {
            title: '学习人数',
            dataIndex: 'user_count',
            key: 'user_count',
            align: 'center',
            width: 100,
            ellipsis: true,
          },
          {
            title: '过期时间',
            dataIndex: 'apply_end_time',
            key: 'apply_end_time',
            align: 'center',
            width: 120,
          },

          {
            title: '过期',
            dataIndex: 'isoutdate',
            key: 'isoutdate',
            align: 'left',  
            ellipsis: true,        
            width: 80,
            render:(text,record)=>(text ==1? <span style={{color:"red"}}>{"已过期"}</span>:"")
          },
          /*{
            title: '购买时间',
            dataIndex: 'register_time',
            key: 'register_time',
            align: 'center',
            width: 120,
          },
          {
            title: '过期时间',
            dataIndex: 'register_time',
            key: 'register_time',
            align: 'center',
            width: 120,
          },*/

         
          {
            title: '购买',
            dataIndex: 'ispay',
            key: 'ispay',
            align: 'left',  
            ellipsis: true,        
            width: 100,
            render:(text,record)=>(text ==1? "已购买":"未购买")
          }
      ];

    // 表格列
    const teacherCourseCols: any = [
        {
            title: '序号',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: 50,
            render:(text, record, index)=>((page.current - 1)*pageSize.current + index + 1)
          },
          {
            title: '课程名称',
            dataIndex: 'name',
            key: 'name',
            align: 'left',
            width: 150,
          },
          {
            title: '开课时间',
            dataIndex: 'start_time',
            key: 'start_time',
            align: 'center',
            width: 120,
            render:(text, record)=>(text?.slice(0,10))
          },
          {
            title: '结课时间',
            dataIndex: 'end_time',
            key: 'end_time',
            align: 'center',
            width: 120,
            render:(text, record)=>(text?.slice(0,10))
          },
          {
            title: '学生人数',
            dataIndex: 'user_count',
            key: 'user_count',
            align: 'center',
            width: 100,
            ellipsis: true,
          },
          {
            title: '教师',
            dataIndex: 'user_name',
            key: 'user_name',
            align: 'center',
            width: 100,
            ellipsis: true,
            render:(text,record)=>(record.real_name == ''? record.user_name : record.real_name)
          },
          {
            title: '状态',
            dataIndex: 'apply',
            key: 'apply',
            align: 'center',  
            ellipsis: true,        
            width: 60,
            render:(text, record)=>(text == 2?"开放":"未开放")
          }
      ];
    

        //页码和页数发生了变化
        const onPageChange = (newPage: number, newPageSize?: number | undefined) => {
            page.current = newPage ? newPage : 1;
            if (newPageSize) {
                pageSize.current = newPageSize;
            }
            queryInstitutionCourseList(college_id);
        }

        
        return <div style={{margin:20}}> 
            <Row className={collegeManageStyles.courseList} wrap={false}>
                <Col style={{backgroundImage:`url(${courseBg1})`,backgroundSize:'100% 100%'}}>
                    <div>课程总数</div>
                    <div style={{fontSize:30,marginTop:12}}>{totalCount}</div>
                </Col>
                <Col style={{backgroundImage:`url(${courseBg2})`,backgroundSize:'100% 100%'}}>
                    <div>平台课程</div>
                    <div style={{fontSize:30,marginTop:12}}>{payedCourseCount}/{systemCourseCount} <span style={{marginRight:5,fontSize:12,marginTop:12,float:"right"}}>已购买/全部</span></div>
                </Col>
                <Col style={{backgroundImage:`url(${courseBg3})`,backgroundSize:'100% 100%'}}>  
                    <div>教师课程</div>
                    <div style={{fontSize:30,marginTop:12}}>{teacherPublishedCourseCount}/{teacherCourseCount} <span style={{marginRight:5,fontSize:12,marginTop:12,float:"right"}}>已发布/全部</span></div>
                </Col>
                <Col style={{backgroundImage:`url(${courseBg4})`,backgroundSize:'100% 100%'}}>
                    <div>进行中课程</div>
                    <div style={{fontSize:30,marginTop:12}}>{inTeachingCourseCount}</div>
                </Col>
            </Row>

                <div className={collegeManageStyles.memberTableWrapper} style={{minHeight:"calc(100vh - 240px)"}}>
                    <Row align="middle"  gutter={16} style={{marginBottom:10}}  >
                        <Col flex='350px'>
                            <Search
                                placeholder="搜索课程"
                                style={{ width: 350 }}
                                enterButton={true}
                                onSearch={onSearchBtn}
                            />
                        </Col>
                    </Row>

                  <Tabs defaultActiveKey="2" onChange={onTabKeyChange}>
                      <TabPane tab="平台" key="2" className="">
                          <div className={idataCourseTableStyles.tableWrapper}>
                          <ConfigProvider locale={zhCN}>
                              <Table
                                  dataSource={courseResult}
                                  columns={systemCourseCols}
                                  rowKey={record => record.id}
                                  pagination={false}
                                  scroll={{ x: 800 }}
                                  //bordered={true}
                                  onChange={onTableChange}
                              />
                          </ConfigProvider>
                          </div>
                      </TabPane>

                      <TabPane tab="教师" key="1" className="">
                          <div className={idataCourseTableStyles.tableWrapper}>
                          <ConfigProvider locale={zhCN}>
                              <Table
                                  dataSource={courseResult}
                                  columns={teacherCourseCols}
                                  rowKey={record => record.id}
                                  pagination={false}
                                  scroll={{ x: 800 }}
                                  //bordered={true}
                                  onChange={onTableChange}
                              />
                          </ConfigProvider>
                          </div>

                      </TabPane>
                      
                  </Tabs>
               
                  <ConfigProvider locale={zhCN}>
                    <div style={{marginBottom:30,paddingTop:30}} className={"css.pagination"} >
                        <Pagination
                            defaultCurrent={1}
                            total={resultSum}
                            defaultPageSize={pageSize.current}
                            current={page.current}
                            hideOnSinglePage={true}
                            onChange={onPageChange}
                            showQuickJumper={true}
                            //showTotal={total => `共 ${total} 用户`}
                            pageSizeOptions={["3","20","30","50","100"]}
                        />
                    </div>
                </ConfigProvider>
                  <Modal title={<div style={{ width: '100%',cursor: 'move',}}
                      onMouseOver={() => {if (disabled) {setDisabled(false)}}}
                      onMouseOut={() => {setDisabled(true)}}
                      onFocus={() => {}}
                      onBlur={() => {}}
                  >
                      提示</div>
                  }
                  width={350}
                  closable={false}
                  visible={removeModalVisible}
                  className={collegeManageStyles.modal}
                  footer={
                      <div>
                        <Button className={collegeManageStyles.stepsBtn}
                        style={{marginRight:20}}
                        type="default"
                        onClick={()=>{setRemoveVisible(false)}}>取消</Button>
                        <Button className={collegeManageStyles.stepsBtn}
                        style={{marginRight:20,color:'#fff',backgroundColor:'#1a5aa3'}}
                        type="primary"
                        onClick={()=>{setRemoveVisible(false)}}>确认</Button>
                      </div>
                  }
                  modalRender={modal => (<div>
                      <Draggable
                        disabled={disabled}
                        bounds={bounds}
                        onStart={(event, uiData) => onStart(event, uiData)}
                      >
                        <div ref={draggleRef}>{modal}</div>
                      </Draggable> </div>
                    )}
                  >
                      <div style={{width:300,textAlign:'center'}}>
                        确认将<span style={{color:'#1a5aa3'}}>李丽</span>移出本校吗？
                      </div>
                  </Modal>
                  
                  {/*  <Pagination
                        defaultCurrent={1}
                        total={10}
                        defaultPageSize={2}
                        hideOnSinglePage={true}
                    /> */}
                </div>

            </div>
}


export default CollegeCourseList
