import { Button, Card, Col, Input, message, Row } from 'antd'
import React, { useState, useEffect } from 'react'
import articleStyle from './article.module.less'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'
import MarkDownEditor, {
  mdParser,
} from '../../../component/MarkDownEditor/MarkDownEditor'
import Cookies from 'js-cookie'
import loadingGif from '../../../static/image/loding.gif'
import { sendHttpPostRequest } from '../../../api/http-request'

export interface Props {
  articleId:any
}
function ArticleFooter(props:Props) {
  const {articleId} = props
  /**
   * 发布文章
   */
  const publishArticle = () => {
    sendHttpPostRequest(
      'community/api/publish_post/',
      {
        sessionid: Cookies.get('sessionid'),
        post_id: articleId
        // title: articleTitle,
        // content: articleContent
      },
      (response: any) => {
        message.destroy()
        if (response.code == -100) {
          // 未登录
          window.location.href = '/login?next=community'
        } else if (response.code == 0) {
          // 成功
          window.location.href = '/community'
        } else if (response.code == -501) {
          // 权限不足
          window.location.href = '/login?next=community'
        } else if (response.code == -509) {
          // 未阅读社区协议
          window.location.href = '/community?tab=3'
        } else {
          message.error(response.message)
        }
      }
    )
  }

  return (
    <Row
      className={articleStyle.actionFooter}
      justify={'center'}
      align={'middle'}
    >
      <Col>
        <Button onClick={() => history.back()} style={{ marginRight: '100px' }}>
          取消
        </Button>
      </Col>
      <Col>
        <Button type={'primary'} onClick={publishArticle}>
          发布
        </Button>
      </Col>
    </Row>
  )
}

const ArticleFrom = (props) => {
  const location = useLocation()
  const history = useHistory()
  const createMode = location.pathname.split('-')[2] === 'create'
  const articleId = !createMode ? location.pathname.split('/')[2] : null

  const [articleInfo, setArticleInfo] = useState({
    title: 'Python函数的参数',
    content: '# aaa  \n $(x^2 + x^y )^{x^y}+ x_1^2= y_1 - y_2^{x_1-y_1^2}$',
    author_name: '贾宝玉',
    avatar:
      'https://img1.baidu.com/it/u=2961575590,2057372040&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
    create_time: '2023-05-21 18:02',
    is_my_like: true,
    likes: 2,
    reply_nums: 3,
    is_my: true,
    replyVist: false,
  })
  const [articleTitle, setArticleTitle] = useState('')
  const [articleContent, setArticleContent] = useState('') // # aaa $(x^2 + x^y )^{x^y}+ x_1^2= y_1 - y_2^{x_1-y_1^2}$
  const [articleLoaded, setArticleLoaded] = useState(false) // 加载中
  const [uploadImageList, setUploadImageList] = useState([] as any [])

  useEffect(() => {
    createMode ? queryArticleContent() : queryArticleContentById()
  }, [])

  const onTitleChange = (e) => {
    setArticleTitle(e.target.value)
  }

  /**
   * 存文章草稿内容
   */
  const saveArticleContent = () => {
    sendHttpPostRequest(
      'community/api/save_post_draft/',
      {
        sessionid: Cookies.get('sessionid'),
        post_id: articleId,
        title: articleTitle,
        content: articleContent,
        upload_image_list: JSON.stringify(uploadImageList)
      },
      (response: any) => {
        message.destroy()
        if (response.code == -100) {
          // 未登录
          window.location.href = '/login?next=community'
        } else if (response.code == 0) {
          // 成功
          message.success('草稿更新成功')
          setUploadImageList([])
        } else if (response.code == -501) {
          // 权限不足
          window.location.href = '/login?next=community'
        } else if (response.code == -509) {
          // 未阅读社区协议
          window.location.href = '/community?tab=3'
        } else {
          message.error(response.message)
        }
      }
    )
  }

  /**
   * 查询文章内容
   */
  const queryArticleContent = () => {
    setArticleLoaded(false)
    sendHttpPostRequest(
      'community/api/query_post_draft/',
      {
        sessionid: Cookies.get('sessionid'),
      },
      (response: any) => {
        setArticleLoaded(true)
        message.destroy()
        if (response.code == -100) {
          // 未登录
          window.location.href = '/login?next=community'
        } else if (response.code == 0) {
          // 成功
          setArticleTitle(response.title)
          setArticleContent(response.content)
        } else if (response.code == -501) {
          // 权限不足
          window.location.href = '/login?next=community'
        } else if (response.code == -509) {
          // 未阅读社区协议
          window.location.href = '/community?tab=3'
        } else {
          message.error(response.message)
        }
      }
    )
  }

  /**
   * 查询文章内容by id
   */
  const queryArticleContentById = () => {
    setArticleLoaded(false)
    sendHttpPostRequest(
        'community/api/query_post_by_id/',
        {
          sessionid: Cookies.get('sessionid'),
          edit_mode: 2,
          post_id: articleId
        },
        (response: any) => {
          setArticleLoaded(true)
          message.destroy()
          if (response.code == -100) {
            // 未登录
            window.location.href = '/login?next=community'
          } else if (response.code == 0) {
            // 成功
            setArticleTitle(response.title)
            setArticleContent(response.content)
          } else if (response.code == -501) {
            // 权限不足
            window.location.href = '/login?next=community'
          } else if (response.code == -509) {
            // 未阅读社区协议
            window.location.href = '/community?tab=3'
          } else {
            message.error(response.message)
          }
        }
    )
  }

  const onEditorChange = ({ html, text }) => {
    // console.log("html:",html)
    // console.log("text:",text)
    setArticleContent(text)
  }

  const uploadOption = {
    name: "community_picture",
    actionUrl: "community/api/upload_picture/",
    uploadData: { sessionid: Cookies.get('sessionid') },
    uploadImageList: uploadImageList,
    setUploadImageList: setUploadImageList
  }

  return (
    <div className={articleStyle.articleMain}>
      <Card
        bodyStyle={{
          padding: '30px 60px 80px 60px',
          minHeight: '970px',
        }}
        extra={
          <span style={{ cursor: 'pointer' }} onClick={() => history.goBack()}>
            {'返回>'}
          </span>
        }
        className={articleStyle.articleCard}
        title={createMode ? '创建新文章' : '编辑文章'}
      >
        <Row className={articleStyle.row} align={'middle'} wrap={false}>
          <Col className={articleStyle.title}>标题</Col>
          <Col flex={'auto'}>
            <Input
              className={articleStyle.input}
              value={articleTitle}
              onChange={onTitleChange}
              onBlur={() => {
                articleTitle != '' ? saveArticleContent() : ''
              }}
              placeholder={'请输入文章标题'}
              autoFocus
            />
          </Col>
        </Row>
        <Row className={articleStyle.row} align={'top'} wrap={false}>
          <Col className={articleStyle.title}>内容</Col>
          <Col className={articleStyle.editorContain}>
            <MarkDownEditor
              onChange={onEditorChange}
              onBlur={() => {
                saveArticleContent()
              }}
              preview
              toolbar={'all'}
              height={720}
              value={articleContent}
              uploadOption={uploadOption}
            />
          </Col>
        </Row>
      </Card>
      <ArticleFooter articleId={articleId}/>
    </div>
  )
}

export default ArticleFrom
