import React, { useEffect, useRef, useState } from 'react'
import {
  Radio,
  Button,
  Popover,
  Tooltip,
  Progress,
  Tree,
  Drawer,
  Layout,
  message,
  Card,
  Col,
  Row,
  ConfigProvider,
  Pagination,
  Tabs,
  Modal,
} from 'antd'
import { sendHttpPostRequest } from '../../api/http-request'
import logoSvg from '../../static/image/idclogo.png'
import pekinglogoSvg from '../../static/image/peking.png'
import {
  CopyOutlined,
  TableOutlined,
  LogoutOutlined,
  DownOutlined,
  ArrowLeftOutlined,
  FormOutlined,
  CodeOutlined,
  ProfileOutlined,
  LeftCircleOutlined,
  RightCircleOutlined,
} from '@ant-design/icons'
import Cookies from 'js-cookie'
import './course.module.css'
import zhCN from 'antd/lib/locale/zh_CN'
import * as constants from '../../constants/constants'
import { RouteComponentProps } from 'react-router'
import courseLearnStyles from './course-learn.module.less'
import { CopyToClipboard } from 'react-copy-to-clipboard'
const { TabPane } = Tabs
import './course-split-pane.css'

import TeacherAuthenticate from '../../component/modal/teacher-authenticate/teacher-authenticate'

import SplitPane, { Pane } from 'react-split-pane'
import ExerciseCard from '../exercise/exercise-card'

import { Document, Page, pdfjs } from 'react-pdf'
import { BACKEND_URL, MEDIA_CORS_URL } from '../../constants/constants'
import { increaseModuleViewCount } from '../common/utils/util'
//pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js';
//pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

pdfjs.GlobalWorkerOptions.workerSrc = '/js/pdf.worker.min-2.12.313.js'

//pdfjs.GlobalWorkerOptions.workerSrc = 'http://idatacourse.cn/static/js/pdf.worker.min-2.10.377.js'

let url_prefix = constants.NOTEBOOK_BACKEND_URL
let globalExerciseId = 0
const { TreeNode } = Tree

interface moduleInfo {
  id: number
  name: string
  type: number
  description: string
  lecture_url: string
  video_url: string
  practice: any
  exam: any
  case: any
  chapter_id
  any
}

// 通过路由组件额外添加参数信息到表中
interface Props extends RouteComponentProps<{ module_id: string }> {
  module_id: string
}

/** 对单个知识点进行预览学习的页面 */
export const ModuleLearnPreview: React.FC<Props> = (props) => {
  const moduleId = parseInt(window.location.search.split('=')[1]) // 知识点ID
  // const moduleId = parseInt(props.location.search.split("&")[0].split("=")[1]);
  const [module, setModule] = useState(null as unknown as moduleInfo) // 知识点对象
  const [moduleName, setModuleName] = useState(null as any) //知识点名称
  const [moduleCase, setModuleCase] = useState(null as any) //知识点的实验
  const [moduleLectureUrl, setModuleLectureUrl] = useState(null as any) //pdf课件地址
  const [moduleVideoUrl, setModuleVideoUrl] = useState(null as any) //视频url地址
  const [moduleExercises, setModuleExercises] = useState([] as any[]) //当前知识点的练习题列表
  const [moduleExerciseIds, setModuleExerciseIds] = useState([] as any[]) //当前知识点的练习题id列表
  const isTeacher = useRef(false)
  //const percent = Math.round(100*Math.random()+0.1)

  const [userLogin, setUserLogin] = useState(false) // 用户登录状态
  const exerciseNum = moduleExercises.length //该知识点对应的测验习题数量
  const caseNum = 1 //该知识点对应的实验数量

  const [caseId, setCaseId] = useState(null as any)
  const [caseToken, setCaseToken] = useState(null as any)
  const [urlPrefix, setUrlPrefix] = useState(url_prefix)
  const [caseDataList, setCaseDataList] = useState([] as any[]) //实验包含的数据集列表
  const [currentCase, setCurrentCase] = useState(null as any)
  const [currentActiveTabKey, setCurrentActiveTabKey] = useState('1') //1 练习 2实验 ，右侧面板
  const [lecturePages, setLecturePages] = useState(-1) // 讲义页数
  const [practiceExercises, setPracticeExercises] = useState([] as any[]) // 知识点的习题需要单独查询，才会有提交记录之类的信息

  useEffect(() => {
    queryModule(moduleId)
    increaseModuleViewCount(moduleId)
  }, [])

  // 查询知识点列表回调
  const queryModuleCallback = (response: any) => {
    message.destroy()
    if (response.code == -100) {
      // 未登录
      window.location.href = `/login?next=course`
    } else if (response.is_ok == true) {
      // 成功
      setModule(response?.query_info)
      setModuleName(response?.query_info.name)
      setModuleExercises(response?.query_info.practice)
      setModuleCase(response?.query_info.case)

      setModuleLectureUrl(response?.query_info.lecture_url)

      setModuleVideoUrl(response?.query_info.video_url)
      console.log(isTeacher)
      setLecturePages(response?.query_info.lecture_pages)
      response?.query_info.case?.case_id >= 0
        ? setCaseId(response.query_info.case.case_id)
        : ''
      response?.query_info.case?.case_id >= 0
        ? setCaseToken(response.query_info.case.token)
        : ''
      response?.query_info.case?.case_id >= 0
        ? queryCase(
            response?.query_info.case.case_id,
            response?.query_info.case.token
          )
        : ''

      // 根据id查询习题
      if (response?.query_info.practice_ids.length > 0) {
        setModuleExerciseIds(response?.query_info.practice_ids)
        selecetedExerciseIndex.current = 0
        // queryExerciseSubmission();  // 查询所有题目的提交时间
        queryPracticesExercise(response?.query_info.practice_ids[0]) // 查询单个题目详情
      }
    } else if (response.code == -501) {
      // 权限不足
      window.location.href = `/login?next=course`
    } else {
      //message.error(response.message);
    }
  }

  /**
   * 查询实验信息
   */
  const queryCase = (caseid: any, token: any) => {
    sendHttpPostRequest(
      'course/api/user_get_or_fork_knowcell_case_by_id',
      {
        sessionid: Cookies.get('sessionid'),
        case_id: caseid,
        token: token,
        is_my: '2',
        knowcell_id: moduleId,
        //from_oam: fromOAM,
      },
      queryCaseCallback
    )
  }

  /**
   * 查询实验回调
   * @param response
   */
  const queryCaseCallback = (response: any) => {
    console.log(response)
    message.destroy()
    if (response.code == -100) {
      // 未登录
      //setUserLogin(false);
      document.getElementById('show-login-modal')!.click()
    } else if (response.is_ok == true) {
      // 成功
      setUserLogin(true)
      setCurrentCase(response.case_info)
      setCaseDataList(response.case_info.mounted_datasets)
      setCaseId(response.case_info.case_id)
      setUrlPrefix(response.case_info.run_url)

      // if (response.case_info.need_jump) {
      //    setCaseId(response.case_info.case_id);
      //   //  setCaseToken(response.case_info.token);
      // }else{
      //     setCaseId(response.case_info.module_case_id);
      //     // setCaseToken(response.case_info.module_case_token);
      // }
      // if (response.case_info.url_prefix) {
      //     console.log(response.case_info.url_prefix);
      //     setUrlPrefix(response.case_info.url_prefix);
      // }
    } else if (response.code == -501) {
      // 权限不足
      //setUserLogin(false);
      message.error('当前用户权限不足')
    } else {
      //setUserLogin(false);
      //message.error(response.message);
    }
  }

  // 查询知识点列表
  const queryModule = (knowledgeId: number) => {
    sendHttpPostRequest(
      'course/api/user_query_knowcell_by_id',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        know_id: knowledgeId,
        chapter_id: 1,
      },
      queryModuleCallback
    )
  }

  /**
   * @author yanhan
   * @date 2022-01-14 15:07
   * @param exerciseId
   */
  const queryPracticesExercise = (exerciseId: any) => {
    // 基本习题框架
    const baseExerciseInfo: any = {
      answer_parse: '',
      create_time: '',
      creator: '',
      creator_id: 1,
      dataset_path: '',
      datasets: [],
      default_code: '',
      difficulty: 1,
      difficulty_str: '',
      exam_id: 0,
      hint_submit_btn: 1,
      id: 1,
      images: [],
      is_delete: 1,
      judgement_code: '',
      language: 2,
      language_str: 'python3',
      options: [
        { id: '1', options: '1' },
        { id: '2', options: '2' },
        { id: '3', options: '3' },
        { id: '4', options: '4' },
      ],
      options_answer: [],
      preset_code: '',
      reference_answer: '',
      remarks: '',
      score: 0,
      source: 1,
      source_key: 4,
      source_page: 4,
      status: 1,
      steps: '',
      submission_info: {
        is_html: false,
        submission_answer: [],
        submission_code: '',
        submission_id: 0,
        submission_image: [],
        submission_output: '',
        submission_status: 3,
        submission_time: '',
        submission_variables: [],
      },
      title: '',
      type: 8,
      type_name: '',
      update_time: '',
    }
    setPracticeExercises(baseExerciseInfo)
    sendHttpPostRequest(
      'exercise/api/query_exercise_by_id/',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        exercise_id: exerciseId,
        source_page: 2, // 知识点学习
        source_key: 3, // 知识点学习
        // hint_submit_btn: moduleExerciseIds.current.length > 1 ? 2 : 1,  // 隐藏 习题卡片里的 测试 按钮
        hint_submit_btn: 1, // 1-显示 按钮
        // hint_submit_btn: 2,  // 1-隐藏 按钮
        course_id: 0,
        chapter_id: 0,
        knowcell_id: moduleId,
      },
      (response: any) => {
        setPracticeExercises(response.exercise_info)
        globalExerciseId = exerciseId
      }
    )
  }

  const selecetedExerciseIndex = useRef(0) //默认选中第一题

  const getExerciseBackColorByIndex = (index) => {
    // if(index == 0 || index == 2 || index == 3){
    //     return "#07c160"
    // }else if(index == 1){
    //     return "#ff4b50"
    // }else{
    return '#343434'
    // }
  }

  const caseDataContent = (
    <div>
      {' '}
      <Row
        style={{ padding: 0, marginBottom: 0 }}
        gutter={[0, 2]}
        justify="start"
        align="top"
      >
        {caseDataList?.map((data, index) => {
          return (
            <Col
              span={24}
              key={data.dataset_id}
              style={{ padding: 0, textAlign: 'left' }}
            >
              <Row
                className={courseLearnStyles.dataList}
                gutter={[0, 0]}
                align="middle"
                key={data.dataset_id}
                style={{ fontSize: 12 }}
              >
                <Col span={20} style={{ textAlign: 'left' }}>
                  <span className={courseLearnStyles.dataName}>
                    {/* {index + 1} {data.length > 50 ? data.slice(-30, -1) : data} */}
                    {index + 1}
                    {data.title}
                  </span>
                </Col>
                <Col span={4} style={{ textAlign: 'right' }}>
                  <CopyToClipboard
                    onCopy={() => {
                      message.success('复制成功！')
                    }}
                    text={data?.base_path}
                  >
                    <Tooltip title="复制">
                      <CopyOutlined className={courseLearnStyles.copyIcon} />
                    </Tooltip>
                  </CopyToClipboard>
                </Col>
              </Row>
            </Col>
          )
        })}
      </Row>
    </div>
  )

  const CourseLearnExerciseCase = (exerciseNum: number, caseNum: number) => {
    if (exerciseNum > 0 && caseNum > 0) {
      return (
        <div className={courseLearnStyles.exerciseCaseTabpanelWrapper}>
          <Tabs
            activeKey={currentActiveTabKey}
            onChange={(activeKey: any) => {
              setCurrentActiveTabKey(activeKey)
            }}
            type="card"
            size="middle"
            tabPosition="top"
          >
            <TabPane
              style={{ paddingBottom: 0 }}
              tab={
                <div style={{ width: '50%', fontSize: 16, fontWeight: 400 }}>
                  <FormOutlined /> 练习
                </div>
              }
              key="1"
            >
              <div
                className="module-preview-exercise-list"
                style={{
                  paddingLeft: 15,
                  paddingRight: 15,
                  height: 'calc(100vh - 116px)',
                }}
              >
                <Row className={courseLearnStyles.exerciseSelectWrapper}>
                  {moduleExercises?.map((exercise, index) => {
                    return (
                      <Col key={exercise?.id}>
                        <div
                          className={courseLearnStyles.exerciseIndexDiv}
                          style={{
                            backgroundColor: getExerciseBackColorByIndex(index),
                            border:
                              index == selecetedExerciseIndex.current
                                ? '1px solid #fff'
                                : 0,
                          }}
                          onClick={() => {
                            selecetedExerciseIndex.current = index
                            queryPracticesExercise(
                              moduleExerciseIds[selecetedExerciseIndex.current]
                            )
                          }}
                        >
                          {index + 1}
                        </div>
                      </Col>
                    )
                  })}
                </Row>

                <div style={{ marginTop: 0 }}>
                  <ExerciseCard
                    exercise={practiceExercises}
                    renderType="dark"
                    teacherOnly={false}
                    editorMode={true}
                    showAnser={false}
                    showClean={true}
                  />
                </div>
              </div>
            </TabPane>

            <TabPane
              tab={
                <div style={{ width: '50%', fontSize: 16, fontWeight: 400 }}>
                  <CodeOutlined />
                  实验
                </div>
              }
              key="2"
            >
              <iframe
                id="notebook-iframe"
                sandbox="allow-scripts allow-forms allow-same-origin"
                // security="restricted"
                src={urlPrefix + '?style=dark'}
                width="100%"
                style={{
                  overflowY: 'hidden',
                  backgroundColor: '#222222',
                  height: 'calc(100vh - 122px)',
                  borderRadius: 0,
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
                frameBorder="0"
              />
            </TabPane>
          </Tabs>
          {currentActiveTabKey == '1' && (
            <div style={{ height: 50, background: '#343434', bottom: 0 }}>
              <Row
                align="middle"
                justify="center"
                gutter={[16, 16]}
                style={{ height: 50 }}
              >
                <Col>
                  {/*<Button type="primary" style={{borderRadius:3,color:"#fff",height:30,width:80, backgroundColor:"#07c160"}}> 提 交 </Button>*/}
                  {exerciseNum > 1 && (
                    <Button
                      disabled={selecetedExerciseIndex.current == 0}
                      onClick={() => {
                        selecetedExerciseIndex.current =
                          selecetedExerciseIndex.current <
                          moduleExercises?.length
                            ? selecetedExerciseIndex.current - 1
                            : selecetedExerciseIndex.current
                        queryPracticesExercise(
                          moduleExerciseIds[selecetedExerciseIndex.current]
                        )
                      }}
                      className={courseLearnStyles.btnDefault}
                      style={{
                        marginLeft: 50,
                        borderRadius: 3,
                        height: 30,
                        width: 80,
                      }}
                    >
                      {' '}
                      上一题{' '}
                    </Button>
                  )}
                  {exerciseNum > 1 && (
                    <Button
                      disabled={
                        selecetedExerciseIndex.current ==
                        moduleExercises.length - 1
                      }
                      onClick={() => {
                        selecetedExerciseIndex.current =
                          selecetedExerciseIndex.current <
                          moduleExercises?.length - 1
                            ? selecetedExerciseIndex.current + 1
                            : selecetedExerciseIndex.current
                        queryPracticesExercise(
                          moduleExerciseIds[selecetedExerciseIndex.current]
                        )
                      }}
                      className={courseLearnStyles.btnDefault}
                      style={{
                        marginLeft: 10,
                        borderRadius: 3,
                        height: 30,
                        width: 80,
                      }}
                    >
                      {' '}
                      下一题{' '}
                    </Button>
                  )}
                </Col>
              </Row>
            </div>
          )}

          {currentActiveTabKey == '2' && (
            <div style={{ height: 50, background: '#343434', bottom: 0 }}>
              <Row
                align="middle"
                justify="start"
                gutter={[16, 16]}
                style={{ height: 50 }}
              >
                <Col
                  flex={'auto'}
                  className={courseLearnStyles.caseName}
                  style={{
                    paddingLeft: 20,
                    textAlign: 'left',
                    color: '#ffffff80',
                  }}
                >
                  {moduleCase?.name}
                </Col>
                <Col
                  flex={'100px'}
                  style={{ padding: 0, textAlign: 'left', color: '#ffffff80' }}
                >
                  <Popover
                    overlayClassName={courseLearnStyles.caseBottomWrapper}
                    placement="topRight"
                    content={caseDataContent}
                    title="实验数据"
                  >
                    <div className={courseLearnStyles.bottomDataDiv}>
                      <TableOutlined style={{ marginRight: 10 }} /> {1}
                    </div>
                  </Popover>
                </Col>
              </Row>
            </div>
          )}
        </div>
      )
    } else if (exerciseNum == 0 && caseNum == 0) {
      return <div />
    } else if (exerciseNum > 0) {
      return (
        <Tabs
          defaultActiveKey={'1'}
          type="card"
          size="middle"
          tabPosition="top"
        >
          <TabPane
            style={{ paddingBottom: 0 }}
            tab={
              <div style={{ width: '50%', fontSize: 16, fontWeight: 400 }}>
                <FormOutlined /> 练习
              </div>
            }
            key="1"
          >
            <div
              className="module-preview-exercise-list"
              style={{
                paddingLeft: 15,
                paddingRight: 15,
                height: 'calc(100vh - 116px)',
              }}
            >
              <Row className={courseLearnStyles.exerciseSelectWrapper}>
                {moduleExercises?.map((exercise, index) => {
                  return (
                    <Col key={exercise?.id}>
                      <div
                        className={courseLearnStyles.exerciseIndexDiv}
                        style={{
                          backgroundColor: getExerciseBackColorByIndex(index),
                          border:
                            index == selecetedExerciseIndex.current
                              ? '1px solid #fff'
                              : 0,
                        }}
                        onClick={() => {
                          selecetedExerciseIndex.current = index
                          queryPracticesExercise(
                            moduleExerciseIds[selecetedExerciseIndex.current]
                          )
                        }}
                      >
                        {index + 1}
                      </div>
                    </Col>
                  )
                })}
              </Row>

              <div style={{ marginTop: 0 }}>
                <ExerciseCard
                  exercise={practiceExercises}
                  renderType="dark"
                  teacherOnly={false}
                  editorMode={true}
                  showAnser={false}
                  showClean={true}
                />
              </div>

              <div
                style={{
                  height: 50,
                  background: '#343434',
                  bottom: 0,
                  position: 'fixed',
                  width: '50%',
                  right: 0,
                }}
              >
                <Row
                  align="middle"
                  justify="center"
                  gutter={[16, 16]}
                  style={{ height: 50 }}
                >
                  <Col>
                    {/*<Button type="primary" style={{borderRadius:3,color:"#fff",height:30,width:80, backgroundColor:"#07c160"}}> 提 交 </Button>*/}
                    {exerciseNum > 1 && (
                      <Button
                        disabled={selecetedExerciseIndex.current == 0}
                        onClick={() => {
                          selecetedExerciseIndex.current =
                            selecetedExerciseIndex.current <
                            moduleExercises?.length
                              ? selecetedExerciseIndex.current - 1
                              : selecetedExerciseIndex.current
                          queryPracticesExercise(
                            moduleExerciseIds[selecetedExerciseIndex.current]
                          )
                        }}
                        className={courseLearnStyles.btnDefault}
                        style={{
                          marginLeft: 50,
                          borderRadius: 3,
                          height: 30,
                          width: 80,
                        }}
                      >
                        {' '}
                        上一题{' '}
                      </Button>
                    )}
                    {exerciseNum > 1 && (
                      <Button
                        disabled={
                          selecetedExerciseIndex.current ==
                          moduleExercises.length - 1
                        }
                        onClick={() => {
                          selecetedExerciseIndex.current =
                            selecetedExerciseIndex.current <
                            moduleExercises?.length - 1
                              ? selecetedExerciseIndex.current + 1
                              : selecetedExerciseIndex.current
                          queryPracticesExercise(
                            moduleExerciseIds[selecetedExerciseIndex.current]
                          )
                        }}
                        className={courseLearnStyles.btnDefault}
                        style={{
                          marginLeft: 10,
                          borderRadius: 3,
                          height: 30,
                          width: 80,
                        }}
                      >
                        {' '}
                        下一题{' '}
                      </Button>
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </TabPane>
        </Tabs>
      )
    } else {
      return (
        <Tabs
          className={courseLearnStyles.exerciseCaseWrapper}
          defaultActiveKey={'1'}
          type="card"
          size="middle"
          tabPosition="top"
        >
          <TabPane
            tab={
              <div style={{ width: '50%', fontSize: 16, fontWeight: 400 }}>
                <CodeOutlined />
                实验
              </div>
            }
            key="2"
          >
            <iframe
              id="notebook-iframe"
              sandbox="allow-scripts allow-forms allow-same-origin"
              // security="restricted"
              src={urlPrefix + '?style=dark'}
              width="100%"
              style={{
                overflowY: 'hidden',
                backgroundColor: '#222222',
                height: 'calc(100vh - 122px)',
                borderRadius: 0,
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              frameBorder="0"
            />
          </TabPane>
        </Tabs>
      )
    }
  }

  const [numPages, setNumPages] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
    console.log(numPages)
    if (lecturePages <= 0) {
      sendHttpPostRequest(
        'course/api/user_update_lecture_pages',
        {
          sessionid: Cookies.get('sessionid'),
          sid: Cookies.get('sessionid'),
          lecture_pages: numPages,
          knowcell_id: moduleId,
        },
        (response: any) => {}
      )
    }
  }

  const handelOnChange = (page: any) => {
    setPageNumber(page)
    console.log(page)
    console.log(numPages)
  }

  return (
    <Layout className="course-learn-page">
      <SplitPane
        allowResize={true}
        split="vertical"
        defaultSize={exerciseNum + caseNum > 0 ? '50%' : 0}
        primary="second"
        maxSize={-300}
      >
        <div style={{ height: '100vh' }}>
          <div style={{ height: 50, background: '#343434' }}>
            <Row
              style={{ paddingTop: 15 }}
              justify="start"
              align="middle"
              gutter={[4, 4]}
            >
              <Col flex="100%">
                {' '}
                <div style={{ fontWeight: 600, color: '#f9f9f9' }}>
                  {moduleName}{' '}
                </div>{' '}
              </Col>
            </Row>
          </div>

          <div
            style={{
              height: 'calc(100vh - 100px)',
              background: '#000000',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div style={{ maxHeight: 'calc(100vh - 100px)' }}>
              {module?.type == 2 && moduleVideoUrl && (
                <video
                  style={{
                    marginTop: 25,
                    marginBottom: 25,
                    height: 'calc(100vh - 150px)',
                    width: '90%',
                  }}
                  preload="preload"
                  controls={true}
                  src={
                    moduleVideoUrl?.startsWith('http')
                      ? moduleVideoUrl
                      : constants.BACKEND_URL +
                        '/course/api/get_stream_video?path=' +
                        moduleVideoUrl
                  }
                  controlsList="nodownload"
                >
                  <source type={'video/mp4'} />
                  您的浏览器不支持 HTML5 video 标签。
                </video>
              )}

              {module?.type == 1 && moduleLectureUrl && (
                <div className={courseLearnStyles.PdfViewerWrapper}>
                  <Document
                    options={{
                      Headers: {
                        origin: false,
                      },
                    }}
                    style={{ maxHeight: '100%', maxWidth: '100%' }}
                    file={
                      // moduleLectureUrl.startsWith('http')
                      //   ? moduleLectureUrl
                      //   : window.location.host.startsWith('www')
                      //   ? constants.MEDIA_CORS_URL_WWW + moduleLectureUrl
                      //   : constants.BACKEND_URL + moduleLectureUrl
                      constants.BACKEND_URL + moduleLectureUrl + '/'
                    }
                    loading={'正在努力加载PDF课件~'}
                    onLoadSuccess={onDocumentLoadSuccess}
                    onLoadError={(err) => {
                      console.log(err)
                    }}
                  >
                    <Page pageNumber={pageNumber} />
                  </Document>

                  {/* <Pagination pageSize= {1} showSizeChanger={false} onChange={handelOnChange} total={numPages} current={pageNumber} 
                                    style={{maxWidth:"100%",height:50,marginBottom:-50}}/> */}
                </div>
              )}
            </div>
            {module?.type == 1 && moduleLectureUrl && (
              <div
                className={courseLearnStyles.pagination}
                style={{
                  width: '100%',
                  height: 50,
                  lineHeight: '50px',
                  position: 'absolute',
                  bottom: 0,
                }}
              >
                <Pagination
                  pageSize={1}
                  showSizeChanger={false}
                  onChange={handelOnChange}
                  total={numPages}
                  current={pageNumber}
                  style={{ maxWidth: '100%', height: 32, margin: '0 auto' }}
                />
              </div>
            )}
          </div>

          <div style={{ height: 50, background: '#343434' }}></div>
        </div>
        <div className={courseLearnStyles.setting} style={{ height: '100vh' }}>
          <div style={{ width: '100%', height: '100%' }}>
            {CourseLearnExerciseCase(
              moduleExercises?.length,
              moduleCase?.case_id >= 0 ? 1 : 0
            )}
          </div>
        </div>
      </SplitPane>
      <TeacherAuthenticate />
    </Layout>
  )
}

export default ModuleLearnPreview
