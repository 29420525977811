import {
  Button,
  Card,
  Col,
  ConfigProvider,
  Image,
  Input,
  message,
  Modal,
  Pagination,
  Row,
} from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import ADStyle from './article-detail.module.less'
import { useHistory, useParams } from 'react-router-dom'
import MarkDownEditor, {
  mdParser,
} from '../../../component/MarkDownEditor/MarkDownEditor'
import katex from 'katex'
import MarkdownItKatex from '@iktakahiro/markdown-it-katex'
import DiscusItemStyle from '../../../component/DiscussItem/DiscussItem.module.less'
import discussStyle from '../../course/discussion/course-main-discuss.module.less'
import { ReplyItem } from '../../../component/DiscussItem/ReplyItem'
import Cookies from 'js-cookie'
import loadingGif from '../../../static/image/loding.gif'
import { BACKEND_API_URL, BACKEND_URL } from '../../../constants/constants'
import { sendHttpPostRequest } from '../../../api/http-request'
import zhCN from 'antd/lib/locale/zh_CN'
import Draggable from 'react-draggable'

const emoji = require('markdown-it-emoji')

const ArticleDetail = (props) => {
  const history = useHistory()
  const params: any = useParams()
  const articleId = params?.id

  const articleContent = useRef()
  mdParser.use(MarkdownItKatex, { katex })
  mdParser.use(emoji)
  const [articleInfo, setArticleInfo] = useState(null as any)
  const [myAvatar, setMyAvatar] = useState(
    'static/image/avatar/default_avatar.png'
  ) // 我的头像
  const [editorVis, setEditorVis] = useState(false)
  const [myName, setMyName] = useState('') // 我的名字
  const [myArticleReply, setMyArticleReply] = useState('') // 我回复文章的内容
  //   title: 'Python函数的参数',
  //   content: '# aaa  \n $(x^2 + x^y )^{x^y}+ x_1^2= y_1 - y_2^{x_1-y_1^2}$',
  //   author_name: '贾宝玉',
  //   avatar:
  //     'https://img1.baidu.com/it/u=2961575590,2057372040&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
  //   create_time: '2023-05-21 18:02',
  //   is_my_like: true,
  //   likes: 2,
  //   reply_nums: 3,
  //   is_my: true,
  //   replyVist:false,
  // })
  const [replies, setReplies] = useState([] as any[])
  //   {
  //     username: '李四',
  //     avatar:
  //         'https://tse2-mm.cn.bing.net/th/id/OIP-C.C6sWABW_nlZFDV_e0zvUSAAAAA?w=204&h=204&c=7&r=0&o=5&pid=1.7',
  //     reply_content: '原来是这样我悟了！感谢大佬！',
  //     reply_time: '2023-05-20 09:21',
  //     likes: 10,
  //     is_my_like: true,
  //     is_my: false,
  //     reply_nums: 0,
  //     replyVist: false,
  //   },
  //   {
  //     username: '王五',
  //     avatar:
  //         'https://tse3-mm.cn.bing.net/th/id/OIP-C.Rvovz_xq8XWlW2iNbO7nuwAAAA?w=204&h=204&c=7&r=0&o=5&pid=1.7',
  //     reply_content: '原来是这样我悟了！感谢大佬！',
  //     reply_time: '2023-05-20 09:21',
  //     likes: 10,
  //     is_my_like: false,
  //     is_my: false,
  //     reply_nums: 0,
  //     replyVist: false,
  //   },
  //   {
  //     username: '贺六',
  //     avatar:
  //         'https://tse1-mm.cn.bing.net/th/id/OIP-C.DdjMZT7zLDAG3FfgGmjgtAAAAA?w=204&h=204&c=7&r=0&o=5&pid=1.7',
  //     reply_content:
  //         '原始数据集中含有文字数字混杂、量纲不同等等问题。经过数据清理，原始数据集已经适用于进一步的建模计算和可视化处理了。原始数据集中含有文字数字混杂、量纲不同等等问题。经过数据清理，原始数据集已经适用于进一步的建模计算和可视化处理了。原始数据集中含有文字数字混杂、量纲不同等等问题。经过数据清理，原始数据集已经适用于进一步的建模计算和可视化处理了。原始数据集中含有文字数字混杂、量纲不同等等问题。经过数据清理，原始数据集已经适用于进一步的建模计算和可视化处理了。原始数据集已经适用于进一步的建模计算和可视化处理了。',
  //     reply_time: '2023-05-20 09:21',
  //     likes: 10,
  //     is_my_like: false,
  //     is_my: false,
  //     reply_nums: 0,
  //     replyVist: false,
  //   },
  // ])
  const [isAllRepliesVis, setIsAllRepliesVis] = useState(false)
  const [articleLoaded, setArticleLoaded] = useState(false) // 加载中
  const [uploadImageList, setUploadImageList] = useState([] as any[])
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const draggleRef = useRef<HTMLDivElement | null>(null)
  const page = useRef(1) // 记录当前的页数
  const pageSize = useRef(10) // 记录当前每页展示数量
  const [resultSum, setResultSum] = useState(0) // 搜索返回的结果数
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
  const [disabled, setDisabled] = useState(true)

  const onStart = (event: any, uiData: any) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement
    const targetRect = draggleRef?.current?.getBoundingClientRect()
    if (targetRect) {
      setBounds({
        left: -targetRect?.left + uiData?.x,
        right: clientWidth - (targetRect?.right - uiData?.x),
        top: -targetRect?.top + uiData?.y,
        bottom: clientHeight - (targetRect?.bottom - uiData?.y),
      })
    }
  }

  const uploadOption = {
    name: 'community_picture',
    actionUrl: 'community/api/upload_picture/',
    uploadData: { sessionid: Cookies.get('sessionid') },
    uploadImageList: uploadImageList,
    setUploadImageList: setUploadImageList,
  }

  useEffect(() => {
    // editorStateInit()
    queryArticleContent()
  }, [])

  /**
   * 查询文章内容
   */
  const queryArticleContent = () => {
    setArticleLoaded(false)
    sendHttpPostRequest(
      'community/api/query_post_by_id/',
      {
        sessionid: Cookies.get('sessionid'),
        post_id: articleId,
      },
      (response: any) => {
        message.destroy()
        if (response.code == -100) {
          // 未登录
          document.getElementById('show-login-modal')!.click()
        } else if (response.code == 0) {
          // 成功
          setArticleInfo(response.query_content)
          queryArticleReplyList()
          setMyAvatar(response.query_content.my_avatar)
        } else if (response.code == -501) {
          // 权限不足
          window.location.href = '/community'
        } else if (response.code == -509) {
          // 未阅读社区协议
          window.location.href = '/community?tab=3'
        } else {
          message.error(response.message)
        }
      }
    )
  }

  /**
   * 查询文章回复列表
   */
  const queryArticleReplyList = () => {
    sendHttpPostRequest(
      'community/api/query_post_reply_list/',
      {
        sessionid: Cookies.get('sessionid'),
        post_id: articleId,
        page: page.current,
        pagesize: pageSize.current,
      },
      (response: any) => {
        setArticleLoaded(true)
        message.destroy()
        if (response.code == -100) {
          // 未登录
          document.getElementById('show-login-modal')!.click()
        } else if (response.code == 0) {
          // 成功
          setReplies(response.query_list)
          setResultSum(response.total_sum)
        } else if (response.code == -501) {
          // 权限不足
          window.location.href = '/community'
        } else if (response.code == -509) {
          // 未阅读社区协议
          window.location.href = '/community?tab=3'
        } else {
          message.error(response.message)
        }
      }
    )
  }

  /**
   * 查我的文章总数
   * @param url
   */
  const queryMyArticleCount = (url = 'community/api/query_my_post_count/') => {
    sendHttpPostRequest(
      url,
      {
        sessionid: Cookies.get('sessionid'),
      },
      (response: any) => {
        message.destroy()
        if (response.code == -100) {
          // 未登录
          // window.location.href = '/login?next=community';
        } else if (response.code == 0) {
          // 成功
          setMyName(response.myself.name)
          setMyAvatar(response.myself.avatar)
        } else if (response.code == -501) {
          // 权限不足
          window.location.href = '/community'
        } else {
          message.error(response.message)
        }
      }
    )
  }

  /**
   * 删除文章
   */
  const deleteArticle = () => {
    sendHttpPostRequest(
      'community/api/delete_post/',
      {
        sessionid: Cookies.get('sessionid'),
        post_id: articleId,
      },
      (response: any) => {
        message.destroy()
        if (response.code == -100) {
          // 未登录
          document.getElementById('show-login-modal')!.click()
        } else if (response.code == 0) {
          // 成功
          message.success('删除文章成功')
          setTimeout(function () {
            window.location.href = '/community'
          }, 1000)
        } else if (response.code == -501) {
          // 权限不足
          window.location.href = '/community'
        } else if (response.code == -509) {
          // 未阅读社区协议
          window.location.href = '/community?tab=3'
        } else {
          message.error(response.message)
        }
      }
    )
  }

  /**
   * 点赞文章
   */
  const praiseArticle = () => {
    if (articleInfo.praised) {
      articleInfo.praised_count -= 1
    } else {
      articleInfo.praised_count += 1
    }
    articleInfo.praised = !articleInfo.praised
    setArticleInfo(JSON.parse(JSON.stringify(articleInfo)))
    sendHttpPostRequest(
      'community/api/praise_post/',
      {
        sessionid: Cookies.get('sessionid'),
        post_id: articleId,
      },
      (response: any) => {
        message.destroy()
        if (response.code == -100) {
          // 未登录
          document.getElementById('show-login-modal')!.click()
        } else if (response.code == 0) {
          // 成功
          message.success(response.message)
        } else if (response.code == -501) {
          // 权限不足
          window.location.href = '/community'
        } else if (response.code == -509) {
          // 未阅读社区协议
          window.location.href = '/community?tab=3'
        } else {
          message.error(response.message)
        }
      }
    )
  }

  const onEditorChange = (obj) => {
    let ta: any = document.getElementById('md-editor-id')!
    ta.addEventListener('input', (e) => {
      ta.style.height = '249px'
      ta.style.height = e.target.scrollHeight + 'px'
      // ta.style.height = ta.target!.scrollHeight + 'px';
    })
    setMyArticleReply(obj.text)
  }

  /**
   * 发布文章回复
   */
  const publishArticleReply = () => {
    sendHttpPostRequest(
      'community/api/publish_post_reply/',
      {
        sessionid: Cookies.get('sessionid'),
        post_id: articleId,
        content: myArticleReply,
        upload_image_list: JSON.stringify(uploadImageList),
      },
      (response: any) => {
        message.destroy()
        if (response.code == -100) {
          // 未登录
          document.getElementById('show-login-modal')!.click()
        } else if (response.code == 0) {
          // 成功
          message.success('发表文章回复成功')
          // setTimeout(function () {
          setMyArticleReply('')
          setUploadImageList([])
          setEditorVis(false)
          queryArticleContent()
          // },1000)
        } else if (response.code == -501) {
          // 权限不足
          window.location.href = '/community'
        } else if (response.code == -509) {
          // 未阅读社区协议
          window.location.href = '/community?tab=3'
        } else {
          message.error(response.message)
        }
      }
    )
  }

  const onLikeChange = () => {
    if (articleInfo.is_my_like) {
      articleInfo.likes -= 1
    } else {
      articleInfo.likes += 1
    }
    articleInfo.is_my_like = !articleInfo.is_my_like
    setArticleInfo(JSON.parse(JSON.stringify(articleInfo)))
  }

  const editorStateInit = () => {
    // articleInfo['replyVist'] = false
    // replies.map((r) => {
    //   r.replyVist = false
    // })
    // setArticleInfo(JSON.parse(JSON.stringify(articleInfo)))
    // console.log(replies)
    // setReplies([...replies])
  }
  const onPageSizeChange = (newPage, newPageSize) => {
    page.current = newPage
    pageSize.current = newPageSize
    queryArticleReplyList()
  }

  return (
    <div className={ADStyle.detailMain}>
      <Card
        bodyStyle={{
          padding: '30px 100px 64px 100px',
        }}
        extra={
          <span style={{ cursor: 'pointer' }} onClick={() => history.goBack()}>
            {'返回>'}
          </span>
        }
        className={ADStyle.articleCard}
        title={'查看文章'}
      >
        <h1 className={ADStyle.articleTitle}>{articleInfo?.title}</h1>
        <Row align={'middle'} style={{ marginBottom: '20px' }}>
          <Col>
            <img
              className={ADStyle.avatar}
              src={BACKEND_API_URL + articleInfo?.creator_avatar}
              alt=""
            />
          </Col>
          <Col>{articleInfo?.creator_name}</Col>
        </Row>
        <div
          className={ADStyle.mdContain}
          dangerouslySetInnerHTML={{
            __html: mdParser.render(String(articleInfo?.content)),
          }}
          ref={articleContent.current}
        ></div>
        {/* <div className={DiscusItemStyle.commentImg}>
          <Image.PreviewGroup>
            {articleInfo?.image_list.map((img) => (
              <Image
                style={{ borderRadius: '6px' }}
                width={157}
                height={100}
                key={img}
                src={BACKEND_URL + img}
              ></Image>
            ))}
          </Image.PreviewGroup>
        </div> */}
        <Row align="middle" justify="space-between">
          <Col>
            <span className={DiscusItemStyle.commentTime}>
              {articleInfo?.publish_time}
            </span>
          </Col>
          <Col flex={'300px'}>
            <div className={DiscusItemStyle.actionContain}>
              <div
                className={DiscusItemStyle.iconBtn}
                onClick={praiseArticle}

                //  onClick={onLikeChange}
              >
                {articleInfo?.praised ? (
                  <span
                    className={
                      'iconfont icon_dianzan' +
                      ' ' +
                      DiscusItemStyle.heighLightBtnTextColor
                    }
                  ></span>
                ) : (
                  <span
                    className={
                      'iconfont icon_dianzan' +
                      ' ' +
                      DiscusItemStyle.grayBtnTextColor
                    }
                  ></span>
                )}{' '}
                {articleInfo?.praised_count <= 0 ? (
                  <span className={DiscusItemStyle.grayBtnTextColor}>赞</span>
                ) : (
                  <span className={DiscusItemStyle.commentTime}>
                    {articleInfo?.praised_count}
                  </span>
                )}
              </div>
              <div
                className={DiscusItemStyle.iconBtn}
                style={{ marginRight: '20px' }}
                onClick={() => {
                  editorStateInit()
                  setEditorVis(!editorVis)
                  // articleInfo.replyVist = true
                  // setArticleInfo(JSON.parse(JSON.stringify(articleInfo)))
                }}
              >
                <span
                  className={
                    'iconfont icon_huifu' +
                    ' ' +
                    DiscusItemStyle.grayBtnTextColor
                  }
                ></span>{' '}
                <span className={DiscusItemStyle.grayBtnTextColor}>回复</span>
                {articleInfo?.reply_count > 0 && (
                  <span className={DiscusItemStyle.grayBtnTextColor}>
                    ({articleInfo?.reply_count})
                  </span>
                )}
              </div>
              {articleInfo?.is_my && (
                <div
                  className={DiscusItemStyle.iconBtn}
                  onClick={() =>
                    history.push(`/community-article-edit/${articleId}`)
                  }
                >
                  <span
                    className={
                      'iconfont icon_bianji' +
                      ' ' +
                      DiscusItemStyle.grayBtnTextColor
                    }
                  ></span>
                  <span className={DiscusItemStyle.grayBtnTextColor}>编辑</span>
                </div>
              )}
              {articleInfo?.is_my && (
                <div className={DiscusItemStyle.iconBtn}>
                  <span
                    className={
                      'iconfont icon_shanchu' +
                      ' ' +
                      DiscusItemStyle.grayBtnTextColor
                    }
                  ></span>
                  <span
                    className={DiscusItemStyle.grayBtnTextColor}
                    onClick={() => {
                      setDeleteModalVisible(true)
                    }}
                  >
                    删除
                  </span>
                </div>
              )}
            </div>
          </Col>
        </Row>
        {editorVis && (
          <div className={DiscusItemStyle.editorContainer}>
            <Row align={'top'}>
              <Col>
                <img
                  className={ADStyle.avatar}
                  src={BACKEND_API_URL + myAvatar}
                  alt=""
                />
              </Col>
              <Col flex={'1000px'}>
                <MarkDownEditor
                  placeholder={`回复${articleInfo?.creator_name}:`}
                  onChange={onEditorChange}
                  uploadOption={uploadOption}
                />
                <Button
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    publishArticleReply()
                  }}
                  className={DiscusItemStyle.submitBtn}
                  type="primary"
                >
                  发送
                </Button>
              </Col>
            </Row>
          </div>
        )}
        {replies.length > 0 && (
          <div className={ADStyle.replyContain}>
            {replies.length > 2 && !isAllRepliesVis ? (
              JSON.parse(JSON.stringify(replies))
                .splice(0, 2)
                .map((reply, rpIdx) => (
                  <div key={rpIdx + 'reply'}>
                    <ReplyItem
                      type={{ from: 'community' }}
                      reply={reply}
                      replies={replies}
                      setReplies={setReplies}
                      index={rpIdx}
                      editorStateInit={editorStateInit}
                      submitCallBack={queryArticleContent}
                    />
                  </div>
                ))
            ) : (
              <div>
                {replies.map((reply, rpIdx) => (
                  <div key={rpIdx + 'reply'}>
                    <ReplyItem
                      type={{ from: 'community' }}
                      reply={reply}
                      replies={replies}
                      setReplies={setReplies}
                      index={rpIdx}
                      editorStateInit={editorStateInit}
                      submitCallBack={queryArticleContent}
                    />
                    {reply.comment_list?.map((comment, cmIdx) => (
                      <div key={comment.id} style={{ marginLeft: '37px' }}>
                        <ReplyItem
                          type={{ from: 'community' }}
                          reply={comment}
                          replies={replies}
                          setReplies={setReplies}
                          index={cmIdx}
                          editorStateInit={editorStateInit}
                          submitCallBack={queryArticleContent}
                        />
                        {comment.comment_reply_list?.map((cr, crIdx) => (
                          <ReplyItem
                            type={{ from: 'community' }}
                            key={cr.id}
                            reply={cr}
                            replies={replies}
                            setReplies={setReplies}
                            index={crIdx}
                            editorStateInit={editorStateInit}
                            submitCallBack={queryArticleContent}
                            replayToName={cr.reply_to_name}
                          />
                        ))}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            )}
            {replies.length > 2 && (
              <div
                onClick={() => {
                  setIsAllRepliesVis(!isAllRepliesVis)
                  editorStateInit()
                }}
              >
                <div className={discussStyle.showAllBtn}>
                  {isAllRepliesVis ? '收起' : '展开'}
                  {`全部${replies.length}条回复>`}
                </div>
              </div>
            )}
            <div style={{ margin: '20px auto', textAlign: 'center' }}>
              <ConfigProvider locale={zhCN}>
                <Pagination
                  total={resultSum}
                  current={page.current}
                  pageSize={pageSize.current}
                  onChange={onPageSizeChange}
                  showQuickJumper
                  pageSizeOptions={['10', '20', '50', '100']}
                />
              </ConfigProvider>
            </div>
          </div>
        )}
      </Card>

      <ConfigProvider locale={zhCN}>
        <Modal
          title={
            <div
              style={{
                width: '100%',
                cursor: 'move',
              }}
              onMouseOver={() => {
                if (disabled) {
                  setDisabled(false)
                }
              }}
              onMouseOut={() => {
                setDisabled(true)
              }}
              onFocus={() => {}}
              onBlur={() => {}}
              // end
            >
              提示
            </div>
          }
          visible={deleteModalVisible}
          onOk={deleteArticle}
          onCancel={() => {
            setDeleteModalVisible(false)
          }}
          bodyStyle={{ height: '120px' }}
          destroyOnClose={true}
          width={350}
          modalRender={(modal) => (
            <div className={DiscusItemStyle.miniModal}>
              <Draggable
                disabled={disabled}
                bounds={bounds}
                onStart={(event, uiData) => onStart(event, uiData)}
              >
                <div ref={draggleRef}>{modal}</div>
              </Draggable>{' '}
            </div>
          )}
        >
          <div>确认删除文章吗？</div>
        </Modal>
      </ConfigProvider>
    </div>
  )
}

export default ArticleDetail
