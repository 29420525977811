import {Button, Col, ConfigProvider, Layout, message, Pagination, Popconfirm, Row, Statistic, Table} from 'antd';
import { Content } from 'antd/lib/layout/layout';

import React, { useEffect, useRef, useState } from "react";
import OAMSider from '../oam-sider';
import Cookies from 'js-cookie';
import { sendHttpPostRequest } from '../../../api/http-request';
import Search from 'antd/lib/input/Search';
import zhCN from 'antd/lib/locale/zh_CN';

import idataCourseTableStyles from "../../common/global-styles/idatacourse-table.module.less";


//测试用视频数据
export const test_video_list = [
    {
        id: "1f5d1eaca26e41738916adbafecf3a5b",
        vod_user_id: "HSuh23Hl34h",
        cover: "http://video.idatascience.cn/image/cover/DFDD660BBD1643CCBB95B7D9EED573D9-6-2.png", //封面
        video_path:"http://video.idatascience.cn/sv/33b3d3cd-17dc6066fb2/33b3d3cd-17dc6066fb2.mp4",
        title: "图数据库与知识图谱", //标题
        teacher: "李文杰", //主讲人
        description:"图数据库与知识图谱",//简介
        duration:20, //视频时长
        status:"审核中",
        upload_time:"2021-09-23 18:00",//上传时间
        publish_time:"2021-09-23 18:00",//审核通过实践
        view_cnt:100,//观看次数
    },
    {
        id: "f8319cd84bba4f6fadfd7c436b20d423",
        vod_user_id: "HSuh23Hl34h",
        cover: "http://video.idatascience.cn/image/cover/5CB7D324F545484295B6CDD24309A1D5-6-2.png", //封面
        video_path:"http://video.idatascience.cn/sv/a29d903-17da1ba0ce1/a29d903-17da1ba0ce1.mp4",
        title: "隐私保护计算简介及应用探索", //标题
        teacher: "李琨", //主讲人
        description:"隐私保护计算简介及应用探索",//简介
        duration:20, //视频时长
        status:"审核中",
        upload_time:"2021-09-23 18:00",//上传时间
        publish_time:"2021-09-23 18:00",//审核通过实践
        view_cnt:100,//观看次数
    },
    {
        id: "e067732fcaf54ae5b6c4f9e3cbff41de",
        vod_user_id: "HSuh23Hl34h",
        cover: "http://video.idatascience.cn/image/cover/0A2EDA5E11C844D2ACE3B52ED6155AB7-6-2.png", //封面
        video_path:"http://video.idatascience.cn/sv/4ca859ce-17d7da0849c/4ca859ce-17d7da0849c.mp4",
        title: "计算生物学", //标题
        teacher: "龚新奇", //主讲人
        description:"计算生物学",//简介
        duration:20, //视频时长
        status:"审核中",
        upload_time:"2021-09-23 18:00",//上传时间
        publish_time:"2021-09-23 18:00",//审核通过实践
        view_cnt:100,//观看次数
    },
    {
        id: "3bff777fb08e43bcb176d6bec56e854b",
        vod_user_id: "HSuh23Hl34h",
        cover: "http://video.idatascience.cn/image/cover/5F388CE1CB4643E693F5FB8939A82B53-6-2.png", //封面
        video_path:"http://video.idatascience.cn/sv/2fd4f20-17d573c5c6b/2fd4f20-17d573c5c6b.mp4",
        title: "大数据智能模型驱动的B2B企业营销自动化增长实践", //标题
        teacher: "姚从磊", //主讲人
        description:"大数据智能模型驱动的B2B企业营销自动化增长实践",//简介
        duration:20, //视频时长
        status:"审核中",
        upload_time:"2021-09-23 18:00",//上传时间
        publish_time:"2021-09-23 18:00",//审核通过实践
        view_cnt:100,//观看次数
    },
    {
        id: "88d069cd4d7c4ea6a53b8b5ec7a15efc",
        vod_user_id: "HSuh23Hl34h",
        cover: "http://video.idatascience.cn/image/cover/70BA48688D5246D2B98934B01B049BF2-6-2.png", //封面
        video_path:"http://video.idatascience.cn/sv/586b97f-17d3584338a/586b97f-17d3584338a.mp4",
        title: "计算社会科学新进展--大数据与因果推论", //标题
        teacher: "孟天广", //主讲人
        description:"计算社会科学新进展--大数据与因果推论",//简介
        duration:20, //视频时长
        status:"审核中",
        upload_time:"2021-09-23 18:00",//上传时间
        publish_time:"2021-09-23 18:00",//审核通过实践
        view_cnt:100,//观看次数
    },

    {
        id: "cfd03ffeb72b484d89018f922c7b6bc6",
        vod_user_id: "HSuh23Hl34h",
        cover: "http://video.idatascience.cn/image/cover/5AC39F72750C4693846F3C6249D32AD3-6-2.png", //封面
        video_path:"http://video.idatascience.cn/sv/266f57df-17ceb39b21f/266f57df-17ceb39b21f.mp4",
        title: "保留相似性哈希算法", //标题
        teacher: "毛先领", //主讲人
        description:"保留相似性哈希算法",//简介
        duration:20, //视频时长
        status:"审核中",
        upload_time:"2021-09-23 18:00",//上传时间
        publish_time:"2021-09-23 18:00",//审核通过实践
        view_cnt:100,//观看次数
    },
    {
        id: "a0b13f8d288c4b57a34c0b992551ad77",
        vod_user_id: "HSuh23Hl34h",
        cover: "http://video.idatascience.cn/image/cover/48642073A1CA488981ABB4E5E1B37D9A-6-2.png", //封面
        video_path:"http://video.idatascience.cn/sv/3c4ee67f-17cc9cbf61e/3c4ee67f-17cc9cbf61e.mp4",
        title: "全国大数据和数字经济产业发展概览", //标题
        teacher: "程超", //主讲人
        description:"基于近期发布的2021中国大数据产业发展指数和2021中国数字经济产业发展指数结果，解读各地大数据和数字经济产业发展情况，并交流基于微观企业数据和大数据建模分析方法的产业咨询和决策。",//简介
        duration:20, //视频时长
        status:"审核中",
        upload_time:"2021-09-23 18:00",//上传时间
        publish_time:"2021-09-23 18:00",//审核通过实践
        view_cnt:100,//观看次数
    },
    {
        id: "7ea290078959468dbe716d70e98c81f1",
        vod_user_id: "HSuh23Hl34h",
        cover: "http://video.idatascience.cn/image/cover/649058C1C2DD4BF48A6A90DAB8C09DC0-6-2.png", //封面
        video_path:"http://video.idatascience.cn/sv/16924267-17c1c96fd89/16924267-17c1c96fd89.mp4",
        title: "如何开好数据科学通识导论课程", //标题
        teacher: "欧高炎", //主讲人
        description:"如何开好数据科学通识导论课程",//简介
        duration:20, //视频时长
        status:"审核中",
        upload_time:"2021-09-23 18:00",//上传时间
        publish_time:"2021-09-23 18:00",//审核通过实践
        view_cnt:100,//观看次数
    },
    {
        id: "24ebbed6b2c64ddab86e509fa4dac2da",
        vod_user_id: "HSuh23Hl34h",
        cover: "http://video.idatascience.cn/image/cover/CC41FC8716024F1BAE9B1336DF34A90C-6-2.png",
        video_path:"http://video.idatascience.cn/sv/48f31ced-17c1c96fd81/48f31ced-17c1c96fd81.mp4",
        title: "大数据基础与应用课程思考与探索",
        teacher: "郭熹崴",
        description:"大数据基础与应用课程思考与探索",
        duration:20,
        status:"已发布",
        upload_time:"2021-09-23 18:00",
        publish_time:"2021-09-23 18:00",//审核通过实践
        view_cnt:100,//观看次数
    },
    {
        id: "670eb7145efc4977a67649beccdb0d89",
        vod_user_id: "HSuh23Hl34h",
        cover: "http://video.idatascience.cn/image/cover/F19E8F6DED504E2FBEDF25E157651A46-6-2.png",
        video_path:"http://video.idatascience.cn/sv/33179000-17c1c96fd80/33179000-17c1c96fd80.mp4",
        title: "大数据技术与应用课程建设",
        teacher: "王建民",
        description:"大数据技术与应用课程建设",
        duration:20,
        status:"上传中",
        upload_time:"2021-09-23 18:00",
        publish_time:"2021-09-23 18:00",//审核通过实践
        view_cnt:100,//观看次数
    },
    {
        id: "fd245bccc809485e9e622cd4a841029b",
        vod_user_id: "HSuh23Hl34h",
        cover: "http://video.idatascience.cn/image/cover/E9F3D8C727104FFC9930616ED9306A0B-6-2.png",
        video_path:"http://video.idatascience.cn/sv/100965b5-17c1c96fd92/100965b5-17c1c96fd92.mp4",
        title: "商业大数据分析教与学",
        teacher: "叶龙",
        description:"商业大数据分析教与学",
        duration:20,
        status:"已发布",
        upload_time:"2021-09-23 18:00",
        publish_time:"2021-09-23 18:00",//审核通过实践
        view_cnt:100,//观看次数
    },
    {
        id: "13a9f1aa719347a2b11b9aaf5b688425",
        vod_user_id: "HSuh23Hl34h",
        cover: "http://video.idatascience.cn/image/cover/0B69D9C6A9A44A2CA976C05E97FB2F40-6-2.png",
        video_path:"http://video.idatascience.cn/sv/58dc68f7-17c1c96fdac/58dc68f7-17c1c96fdac.mp4",
        title: "大数据通识课教学研讨组介绍",
        teacher: "研讨组",
        description:"商业大数据分析教与学",
        duration:20,
        status:"已发布",
        upload_time:"2021-09-23 18:00",
        publish_time:"2021-09-23 18:00",//审核通过时间
        view_cnt:100,//观看次数
    },
    {
        id: "8b18c3c04f6e41319049de9f40da72c5",
        vod_user_id: "HSuh23Hl34h",
        cover: "http://video.idatascience.cn/image/cover/1EFF0B5264074BA7BF4E20BFDFFF25A3-6-2.png",
        video_path:"http://video.idatascience.cn/sv/5c3cb61a-17b2e99c0ac/5c3cb61a-17b2e99c0ac.mp4",
        title: "图表示学习",
        teacher: "宋国杰",
        description:"图表示学习",
        duration:20,
        status:"已发布",
        upload_time:"2021-09-23 18:00",
        publish_time:"2021-09-23 18:00",//审核通过实践
        view_cnt:100,//观看次数
    },
    {
        id: "1a3f2766a3ea47edb4456ee26137bd60",
        vod_user_id: "Ph9hsHuh8oa",
        cover: "http://video.idatascience.cn/image/cover/F4DC55CA5CEA44148BB9863FBBB86C72-6-2.png",
        video_path:"http://video.idatascience.cn/sv/1778dc6c-17b2e99c094/1778dc6c-17b2e99c094.mp4",
        title: "深度强化学习与智能体",
        teacher: "乔媛媛",
        description:"深度强化学习与智能体",
        duration:20,
        status:"已发布",
        upload_time:"2021-09-23 18:00",
        publish_time:"2021-09-23 18:00",//审核通过实践
        view_cnt:100,//观看次数
    },
    {
        id: "c8d091646e1a421dbc91d098a75d172c",
        vod_user_id: "MnafhlH1fls",
        cover: "http://video.idatascience.cn/image/cover/D255B28B74DA41678E1F05F20F4A852A-6-2.png",
        video_path:"http://video.idatascience.cn/sv/493a6fb5-17b2e99c044/493a6fb5-17b2e99c044.mp4",
        title: "机器学习在神策数据的应用和挑战",
        teacher: "胡士文",
        description:"机器学习在神策数据的应用和挑战",
        duration:20,
        status:"已发布",
        upload_time:"2021-09-23 18:00",
        publish_time:"2021-09-23 18:00",//审核通过实践
        view_cnt:100,//观看次数
    },
    {
        id: "70dd850561974c26a920b584070d18e1",
        vod_user_id: "MnafhlH1fls",
        cover: "http://video.idatascience.cn/image/cover/0C814557E4074912BD7D470F60BF08DB-6-2.png",
        video_path:"http://video.idatascience.cn/sv/5a546a2-17ccb3094ec/5a546a2-17ccb3094ec.mov",
        title: "爱数科平台介绍",
        teacher: "爱数科",
        description:"爱数科平台介绍",
        duration:20,
        status:"已发布",
        upload_time:"2021-09-23 18:00",
        publish_time:"2021-09-23 18:00",//审核通过实践
        view_cnt:100,//观看次数
    },
    {
        id: "27cdeb74d35f49c6a8f74e0d52e2ec27",
        vod_user_id: "MnafhlH1fls",
        cover: "http://video.idatascience.cn/image/cover/30AB308F0703474EA399619F50E84C5F-6-2.png",
        video_path:"http://video.idatascience.cn/sv/4c79bbe9-17ccb1cd0ce/4c79bbe9-17ccb1cd0ce.mp4",
        title: "爱数科案例：数据值替换",
        teacher: "爱数科",
        description:"爱数科案例：数据值替换",
        duration:20,
        status:"已发布",
        upload_time:"2021-09-23 18:00",
        publish_time:"2021-09-23 18:00",//审核通过实践
        view_cnt:100,//观看次数
    },
    {
        id: "f4d5769bffb9494ab915cd71869e23bc",
        vod_user_id: "MnafhlH1fls",
        cover: "http://video.idatascience.cn/image/cover/771E5F8E70E5473EB3304AF67594C0E7-6-2.png",
        video_path:"http://video.idatascience.cn/sv/5e90e29b-17ccb1c9191/5e90e29b-17ccb1c9191.mp4",
        title: "爱数科案例：数据筛选",
        teacher: "爱数科",
        description:"爱数科案例：数据筛选",
        duration:20,
        status:"已发布",
        upload_time:"2021-09-23 18:00",
        publish_time:"2021-09-23 18:00",//审核通过实践
        view_cnt:100,//观看次数
    },
]
const channelMap = new Map([['爱数科', 1], ['爱数课', 2]]);

export interface Props {}

export const OAMVideoList: React.FC<Props> = (props) => {
    const [videoResult, setVideoResult] = useState([] as any[]);  // 当前显示的结果页

    const [resultSum, setResultSum] = useState(0);  // 搜索返回的结果数
    const [totalCount, setTotalCount] = useState(0);  // 搜索返回的结果数
    const [onlineCount, setOnlineCount] = useState(0);  // 搜索返回的结果数


    const [isEditUserModalVisible, setIsEditUserModalVisible] = useState(false);  // 模态框是否显示
    //const [editUserId, setEditUserId] = useState(-1);  // 待编辑备注的用户ID
    //const [editUserRemark, setEditUserRemark] = useState("");  // 备注信息
    //const queryChannel = useRef(0);  // 来源渠道
    const queryContent = useRef('');  // 记录搜索串
    const order = useRef('');  // 排序升序降序
    const order_by = useRef('');  // 排序列
    const page = useRef(1);  // 记录当前的页数
    const pageSize = useRef(20);  // 记录当前每页展示数量
    const sexFilters = useRef([]);  // 性别过滤选项
    const channelFilters = useRef([]);  // 渠道过滤项
    const userIsLogin = useRef(false);  // 用户是否登录

    useEffect(() => {
        queryVideoList();
    }, [])

    // 查询用户列表
    const queryVideoList = () => {
        const sessionid = Cookies.get("sessionid");
        const queryData = {
            query: queryContent.current,
            page: page.current,
            pagesize: pageSize.current,
            order: order.current,
            order_by: order_by.current,

            //sex: JSON.stringify(sexFilters.current),
            //channel: JSON.stringify(channelFilters.current),

            sessionid: sessionid,
            sid: sessionid,
        };
        sendHttpPostRequest(
            'video/api/oam_query_lecture_list/',
            queryData,
            queryVideoListCallback
        )
    }

    const queryVideoListCallback = (response:any) => {
        // console.log(response);
        message.destroy();
        if (response.code == -100) {
            // 未登录
            window.location.href = '/oam_login?next=user_list';
        } else if (response.code == 0) {
            // 成功
            setVideoResult(response.query_list);

            setResultSum(response.total_sum);
            setTotalCount(response.total_count);
            setOnlineCount(response.online_count);
        } else if (response.code == -501) {
            // 权限不足
            window.location.href = '/oam_login?next=user_list';
        } else {
            message.error(response.message);
        }
    }

    //页码和页数发生了变化
    const onPageChange = (newPage: number, newPageSize?: number | undefined) => {
        page.current = newPage ? newPage : 1;
        if (newPageSize) {
            pageSize.current = newPageSize;
        }
       
    }

   
     //点击了查看按钮
  const onLookOverBtnClick = (event:any) => {
    //message.success('查看数据' + event.currentTarget.value)
    event.preventDefault()
    window.open('/lecture-vod?id=' + event.currentTarget.value)
  }

  //点击了编辑按钮
  const onEditBtnClick = (event:any) => {
    //message.success('编辑数据' + event.currentTarget.value)
    event.preventDefault()
    window.open('/oam_video_edit?video_id=' + event.currentTarget.value)
  }

    //点击上传按钮
    const onUploadBtnClick = (event:any) => {
        //message.success('编辑数据' + event.currentTarget.value)
        event.preventDefault()
        window.open('/oam_video_upload')
      }


    // 点击 删除
    const onDeleteBtnClick = (name:string, table_id:number) => {
        const sessionid = Cookies.get("sessionid");
        const queryData = {
            lecture_id : table_id,
            sessionid: sessionid,
            sid: sessionid,
        };
        sendHttpPostRequest(
            'video/api/oam_delete_lecture/',
            queryData,
            queryVideoList
        )
    }


    // 点上架按钮
    const onPublishBtnClick = (table_id:string, statusStr:string) => {
        const sessionid = Cookies.get("sessionid");
        const queryData = {
            lecture_id : table_id,
            status : 1,
            sessionid: sessionid,
            sid: sessionid,
        };
        sendHttpPostRequest(
            'video/api/oam_publish_lecture/',
            queryData,
            queryVideoList
        )
    }
    // 点下架按钮
    const onUnPublishBtnClick = (table_id:string, statusStr:string) => {
        const sessionid = Cookies.get("sessionid");
        const queryData = {
            lecture_id : table_id,
            status : 2,
            sessionid: sessionid,
            sid: sessionid,
        };
        sendHttpPostRequest(
            'video/api/oam_unpublish_lecture/',
            queryData,
            queryVideoList
        )
    }


    // 渲染操作
    const renderActions = (text:any, record:any) => {
        return <>

        <Row justify="start" align="middle" gutter={[8, 5]} >
        <Col style={{width:50}}>
            <Button style={{borderRadius:20,fontSize:12}} size='small' id="lookover"
                    value={record.id} type="default" onClick={onLookOverBtnClick} > 查 看 </Button>
        </Col>
        <Col style={{width:50}}>
            <Button style={{borderRadius:20,fontSize:12}} size='small'
                    id="edit" value={record.id} type="dashed" onClick={onEditBtnClick}> 编 辑 </Button>
        </Col>
        {
        <Col style={{width:50}}> { record.status == "1" ?
            <Button style={{borderRadius:20,fontSize:12}}  size='small' id="puton" value={record.id}
                    type="primary"  onClick={onPublishBtnClick.bind(this, record.id, record.status)}> 上 架 </Button>
            :  <Popconfirm placement="top" title={'确认下架视频"' + record.title + '"吗？'}
                           onConfirm={onUnPublishBtnClick.bind(this, record.id, record.status)}
                           okText="确认" cancelText="取消">
                <Button style={{borderRadius:20,fontSize:12}}  size='small'
                        id="putoff" value={record.id} type="primary"  danger> 下 架 </Button>
                </Popconfirm>}
        </Col>
        }
        {
        <Col style={{width:50}}>
            <Popconfirm placement="top" title={'确认删除视频"' + record.title + '"吗？'}
                        onConfirm={onDeleteBtnClick.bind(this, record.title,record.id)} okText="确认" cancelText="取消">
                <Button style={{borderRadius:20,fontSize:12}} size='small' id="delete" danger value={record.id} > 删 除 </Button>
            </Popconfirm>
        </Col>
        }
        </Row>

        </>
    }

 
  
    //点击了搜索按钮
    const onSearchBtn = (value: string) => {
        page.current = 1;
        queryContent.current = value;
        //queryUserInfo();
    }

    // 表格发生变化
    const onTableChange:any = (pagination:any, filters:any, sorter:any, extra: { currentDataSource: [], action: any })=> {
        if (sorter.order) { //如果选择了排序列
            //order.current = sorter.order;
            //order_by.current = sorter.field;
        }
        if (filters.sex) { //如果选择了过滤列
            //sexFilters.current = filters.sex;
        } else {
            //sexFilters.current = [];
        }
        if (filters.channel_name) {
            channelFilters.current = filters.channel_name.map(element=> {
                return channelMap.get(element);
            });
        } else {
            channelFilters.current = [];
        }
        page.current = 1;
        //queryUserInfo();
    }

    // 表格列
    const cols: any = [
        {
            title: "标题",
            dataIndex: "title",
            key: "title",
            width: 150,
            align: 'left',
            ellipsis: true,
            //sortDirections: ['descend', 'ascend'],
        },
        {
            title: "时长",
            dataIndex: "duration",
            key: "duration",
            width: 80,
            align: 'center',
        },
        {
            title: "观看次数",
            dataIndex: "view_cnt",
            key: "view_cnt",
            width: 80,
            align: 'center',
        },
        {
            title: "上传时间",
            dataIndex: "update_time",
            key: "update_time",
            width: 120,
            align: 'center',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: "状态",
            dataIndex: "status_str",
            key: "status_str",
            width: 80,
            align: 'center',
        },
        {
            title: "操作",
            dataIndex: "actions",
            key: "actions",
            width: 160,
            ellipsis: false,
            align: 'center',
            render: renderActions ,
        },
    ]

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <OAMSider keys={"34"} openKeys={["sub-course"]} ></OAMSider>
            <Layout style={{backgroundColor:'#f9f9f9'}}>
                <Content style={{ backgroundColor:'#fff',borderRadius:5,minWidth:400,margin:30 ,paddingTop:20}}>
                
                    <div style={{paddingLeft:30,paddingRight:30}}>
                    <Row align="middle"  gutter={16}>

                        <Col flex='80px'>
                            <Statistic title="视频总数" value={totalCount} />
                        </Col>
                        <Col flex='80px'>
                            <Statistic title="已上线" value={onlineCount} />
                        </Col>
                        <Col flex='250px'>
                            <Search
                                placeholder="搜索视频"
                                style={{ width: 250 }}
                                enterButton={true}
                                onSearch={onSearchBtn}
                            />

                        </Col>
                        <Col flex='auto' style={{textAlign:'right'}}>
                            <Button style={{borderRadius:20}} type={'primary'} onClick={onUploadBtnClick}>+上传视频</Button>
                        </Col>

                    </Row>
                      

                    <div className={idataCourseTableStyles.tableWrapper}>
                    <ConfigProvider locale={zhCN}>
                        <Table
                            dataSource={videoResult}
                            columns={cols}
                            rowKey={record => record.id}
                            pagination={false}
                           // bordered={true}
                            onChange={onTableChange}
                        />
                    </ConfigProvider>
                    </div>


                        <ConfigProvider locale={zhCN}>
                            <div style={{marginBottom:30,paddingTop:30}} className={"css.pagination"} >
                                <Pagination
                                    defaultCurrent={1}
                                    total={resultSum}
                                    current={page.current}
                                    defaultPageSize={20}
                                    hideOnSinglePage={true}
                                    onChange={onPageChange}
                                    showQuickJumper={true}
                                    showTotal={total => `共 ${total} 视频`}
                                    pageSizeOptions={["3","20","30","50","100"]}
                                />
                            </div>
                        </ConfigProvider>
                    </div>
                </Content>
            </Layout>
        </Layout>

    )

}

export default OAMVideoList


