import {
  Button,
  Col,
  Input,
  Layout,
  message,
  Popconfirm,
  Anchor,
  Row,
  Popover,
  Affix,
} from 'antd'
import React, { useEffect, useState } from 'react'

import idatacourseWechat from '../../../static/image/feedback/idatacourse_wechat.jpg'
import goole from '../../../static/image/goole-icon-72.png'

import {
  QuestionCircleOutlined,
  PhoneOutlined,
  WechatOutlined,
} from '@ant-design/icons'
import feedbackStyles from './feedback.module.less'
import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined'
import { sendHttpPostRequest } from '../../../api/http-request'
import Cookies from 'js-cookie'
import localStorage from 'localStorage'

export interface Props {}

export const Feedback: React.FC<Props> = (props) => {
  useEffect(() => {}, [])

  const content = (
    <>
      <div style={{ textAlign: 'left' }}>
        <PhoneOutlined style={{ color: '#07c176', fontSize: 18 }} />
        {'    ' + 13693290406}
      </div>
      <div style={{ textAlign: 'left' }}>
        <WechatOutlined style={{ color: '#07c176', fontSize: 18 }} />
        {'    ' + 'DataScienceBigData'}
      </div>
      <div>
        <img height={140} width={140} src={idatacourseWechat} />
        <div style={{ marginTop: 0, fontSize: 12, color: '#666666' }}>
          微信公众号
        </div>
      </div>
    </>
  )

  const advice = (
    <>
      <div style={{ fontSize: 13, textAlign: 'left' }}>
        您好，为了更好的体验，我们建议您使用谷歌浏览器。
      </div>
      <div>
        <img src={goole} alt="" style={{ margin: 12 }} />
      </div>
      <div style={{ fontSize: 13, textAlign: 'left' }}>下载链接：</div>
      <div style={{ fontSize: 13, textAlign: 'left' }}>
        https://chrome.xahuapu.net/
      </div>
    </>
  )

  return (
    <div>
      <span style={{ position: 'fixed', bottom: 80, right: 0 }}>
        <Popover
          placement="left"
          title={''}
          content={content}
          trigger="hover"
          overlayClassName={feedbackStyles.feedbackPopver}
          overlayStyle={{
            textAlign: 'center',
            padding: 0,
            margin: 0,
            width: 200,
          }}
          overlayInnerStyle={{ borderRadius: 5 }}
        >
          <span className={feedbackStyles.feedbackDiv} onClick={() => {}}>
            <QuestionCircleOutlined />
            <span>{'\n遇\n到\n问\n题'}</span>
          </span>
        </Popover>
      </span>

      <span style={{ position: 'fixed', bottom: 220, right: 0 }}>
        <Popover
          placement="left"
          title={''}
          content={advice}
          trigger="hover"
          overlayClassName={feedbackStyles.feedbackPopver}
          overlayStyle={{
            textAlign: 'center',
            padding: 0,
            margin: 0,
            width: 200,
          }}
          overlayInnerStyle={{ borderRadius: 5 }}
        >
          <span
            style={{ height: 218 }}
            className={feedbackStyles.feedbackDiv}
            onClick={() => {}}
          >
            <img src={goole} alt="" height={18} style={{ marginTop: -10 }} />
            <div style={{ lineHeight: 1.4 }}>
              <div>建</div>
              <div>议</div>
              <div>使</div>
              <div>用</div>
              <div>谷</div>
              <div>歌</div>
              <div>浏</div>
              <div>览</div>
              <div>器</div>
            </div>
          </span>
        </Popover>
      </span>
    </div>
  )
}

export default Feedback
