import React, { useEffect, useRef, useState } from 'react'
import {
  Tooltip,
  Progress,
  Tree,
  Drawer,
  Layout,
  message,
  Card,
  Col,
  Row,
  ConfigProvider,
  Pagination,
  Tabs,
  Input,
  Upload,
  Button,
  Popconfirm,
  Select,
  Menu,
  Table,
  Modal,
} from 'antd'
import { PageHeader } from '../common/page-header/header'
import { sendHttpPostRequest } from '../../api/http-request'
import logoSvg from '../../static/image/idclogo.png'
import logo from '../../static/image/college/logo.png'
import pekinglogoSvg from '../../static/image/peking.png'
import {
  SafetyOutlined,
  DownOutlined,
  ArrowLeftOutlined,
  FormOutlined,
  CodeOutlined,
  ProfileOutlined,
  LeftCircleOutlined,
  RightCircleOutlined,
} from '@ant-design/icons'
import Cookies from 'js-cookie'
import zhCN from 'antd/lib/locale/zh_CN'
import * as constants from '../../constants/constants'
import PageFooter from '../college/college-footer'
import LoginRegisterModal from '../../component/modal/login-register/login-register'
import UserInfo, { checkUserLogin } from '../common/user-center/user-info'
import { UserOutlined } from '@ant-design/icons'
import { Content, Footer } from 'antd/lib/layout/layout'
import Sider from 'antd/lib/layout/Sider'
import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined'
import { UploadOutlined, FileImageOutlined } from '@ant-design/icons'
import collegeStyles from './college.module.less'
import project1 from '../../static/image/college/project1.png'
import project2 from '../../static/image/college/project2.png'
import project3 from '../../static/image/college/project3.png'
import teacherStudentIcon from '../../static/image/college-oam/shisheng-22.png'
import teacherIcon from '../../static/image/college-oam/jiaoshi-22.png'
import studentIcon from '../../static/image/college-oam/xuesheng-22.png'
import memberBg1 from '../../static/image/college-oam/memberBg1.png'
import memberBg2 from '../../static/image/college-oam/memberBg2.png'
import memberBg3 from '../../static/image/college-oam/memberBg3.png'

import idataCourseTableStyles from '../common/global-styles/idatacourse-table.module.less'
import courseIcon from '../../static/image/college/course.png'

// 通过路由组件额外添加参数信息到表中
import { RouteComponentProps } from 'react-router'
import SplitPane from 'react-split-pane'
import caseStyles from './case.module.less'
import ReactDOM from 'react-dom'
import { identifier } from '@babel/types'
import { Link } from 'react-router-dom'
const { TextArea } = Input
const { Option } = Select
const { Header } = Layout
const { Search } = Input
const { TabPane } = Tabs

import collegeManageStyles from './college-oam.module.less'
import Draggable from 'react-draggable'
import AddCollegeStudentModal from './components/AddStudentModal/AddCollegeStudentModal'
import PublicTipModal from 'component/PublicTipModal/PublicTipModal'

interface Props {
  college_id: any
}

export const CollegeMemberList: React.FC<Props> = (props) => {
  const [removeModalVisible, setRemoveVisible] = useState(false)

  const draggleRef = useRef<HTMLDivElement | null>(null)
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
  const [disabled, setDisabled] = useState(true)

  const onStart = (event: any, uiData: any) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement
    const targetRect = draggleRef?.current?.getBoundingClientRect()
    if (targetRect) {
      setBounds({
        left: -targetRect?.left + uiData?.x,
        right: clientWidth - (targetRect?.right - uiData?.x),
        top: -targetRect?.top + uiData?.y,
        bottom: clientHeight - (targetRect?.bottom - uiData?.y),
      })
    }
  }

  const { college_id } = props

  const [studentCount, setStudentCount] = useState(0)
  const [teacherCount, setTeacherCount] = useState(0)
  const [userCount, setuserCount] = useState(0)

  const queryContent = useRef('') // 记录搜索串
  const order = useRef('') // 排序升序降序
  const order_by = useRef('') // 排序列
  const page = useRef(1) // 记录当前的页数
  const pageSize = useRef(10) // 记录当前每页展示数量
  const sexFilters = useRef([]) // 性别过滤选项
  const roleFilters = useRef([]) // 身份过滤选项
  const queryType = useRef('1') // 记录搜索串
  const channelFilters = useRef([]) // 渠道过滤项
  const userIsLogin = useRef(false) // 用户是否登录

  const [collegeInfo, setCollegeInfo] = useState(null as any) // 当前院校信息

  const [userResult, setUserResult] = useState([] as any[]) // 当前显示的结果页

  const [resultSum, setResultSum] = useState(0) // 搜索返回的结果数

  const [studentList, setStudentList] = useState([] as any[]) // 机构学生列表
  const [teacherList, setTeacherList] = useState([] as any[]) // 机构教师列表

  const [selectedRowKeys, setSelectedRowKeys] = useState([] as any)
  const [resetPassModalVis, setResetPassModalVis] = useState(false)
  const [addUserModalVis, setAddUserModalVis] = useState(false)

  useEffect(() => {
    queryInstitution(college_id)
    queryInstitutionUserList(college_id)
  }, [])

  // 查询课程信息
  const queryInstitution = (collegeId: any) => {
    const sessionid = Cookies.get('sessionid')
    const queryData = {
      college_id: collegeId,
      sessionid: sessionid,
      sid: sessionid,
    }
    sendHttpPostRequest(
      'college/api/manager_query_college_by_id/',
      queryData,
      institutionCallback
    )
  }

  // 查询机构接口回调
  const institutionCallback = (response: any) => {
    // console.log(response);
    message.destroy()
    if (response.code == -100) {
      // 未登录
      window.location.href = '/login?next=/college-oam'
    } else if (response.is_ok == true) {
      // 成功

      setCollegeInfo(response.college_info)
      setStudentCount(response.college_info.student_count)
      setTeacherCount(response.college_info.teacher_count)
      setuserCount(
        response.college_info.student_count +
          response.college_info.teacher_count
      )
    } else if (response.code == -501) {
      // 权限不足
      window.location.href = '/login?next=/college-oam'
    } else {
      message.error(response.message)
    }
  }

  // 查询机构用户列表接口回调
  const institutionUserCallback = (response: any) => {
    // console.log(response);
    message.destroy()
    if (response.code == -100) {
      // 未登录
      window.location.href = '/login?next=/college-oam'
    } else if (response.is_ok == true) {
      // 成功
      setStudentList(response.student_list)
      setTeacherList(response.teacher_list)
      setUserResult(response.query_list)
      setResultSum(response.total_sum)
    } else if (response.code == -501) {
      // 权限不足
      window.location.href = '/login?next=/college-oam'
    } else {
      message.error(response.message)
    }
  }

  // 查询机构用户列表信息
  const queryInstitutionUserList = (collegeId) => {
    const sessionid = Cookies.get('sessionid')
    const queryData = {
      college_id: collegeId,
      type: queryType.current,
      query: queryContent.current,
      page: page.current,
      pagesize: pageSize.current,
      sessionid: sessionid,
      sid: sessionid,
    }
    sendHttpPostRequest(
      'college/api/manager_query_college_user/',
      queryData,
      institutionUserCallback
    )
  }

  //点击了搜索按钮
  const onSearchBtn = (value: string) => {
    page.current = 1
    queryContent.current = value
    queryInstitutionUserList(college_id)
  }

  // 表格发生变化
  const onTableChange: any = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: { currentDataSource: []; action: any }
  ) => {
    if (sorter.order) {
      //如果选择了排序列
      order.current = sorter.order
      order_by.current = sorter.field
    }
    if (filters.sex) {
      //如果选择了过滤列
      sexFilters.current = filters.sex
    } else {
      sexFilters.current = []
    }
    if (filters.role) {
      roleFilters.current = filters.role
    } else {
      roleFilters.current = []
    }

    page.current = 1
    //queryInstitutionList();
  }

  const onTabKeyChange: any = (key: string) => {
    console.log(key)
    queryType.current = key
    page.current = 1
    queryInstitutionUserList(college_id)
  }

  const renderActions = (text: any, record: any) => {
    return (
      <Popconfirm
        overlayClassName={collegeManageStyles.popconfirm}
        placement="top"
        title={
          <div>
            确认将
            <span style={{ color: '#1a5aa3' }}>
              {record.real_name == '' ? record.name : record.real_name}
            </span>
            移出本院校吗？
          </div>
        }
        onConfirm={() => {
          managerRemoveUsers([record.user_id])
        }}
        okText="确认"
        cancelText="取消"
      >
        <Button type="primary" style={{ borderRadius: 20 }}>
          移出
        </Button>
      </Popconfirm>
    )
  }
  // 表格列
  const userCols: any = [
    {
      title: '序号',
      dataIndex: 'user_id',
      key: 'user_id',
      align: 'center',
      width: 50,
      render: (text, record, index) =>
        (page.current - 1) * pageSize.current + userResult.indexOf(record) + 1,
    },
    {
      title: '手机号',
      dataIndex: 'phone',
      key: 'phone',
      align: 'left',
      width: 100,
    },
    {
      title: '注册时间',
      dataIndex: 'register_time',
      key: 'register_time',
      align: 'center',
      width: 120,
    },
    {
      title: '昵称',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: 100,
      ellipsis: true,
    },
    {
      title: '真实姓名',
      dataIndex: 'real_name',
      key: 'real_name',
      align: 'left',
      ellipsis: true,
      width: 100,
    },
    {
      title: '学号/工号',
      dataIndex: 'number',
      key: 'number',
      align: 'left',
      ellipsis: true,
      width: 100,
    },
    {
      title: '性别',
      dataIndex: 'sex',
      key: 'sex',
      align: 'center',
      width: 50,
      render: (text, record) => {
        if (text == 1) {
          return '未知'
        } else if (text == 2) {
          return '男'
        } else {
          return '女'
        }
      },
    },
    {
      title: '身份',
      dataIndex: 'role',
      key: 'role',
      align: 'center',
      width: 50,
      render: (text, record) => (text == 2 ? '教师' : '学生'),
    },
    {
      title: '操作',
      dataIndex: 'actions',
      key: 'actions',
      width: 150,
      ellipsis: false,
      align: 'center',
      fixed: 'right',
      render: renderActions,
    },
  ]

  // 点了移出按钮
  const managerRemoveUsers = (userIdList: any[]) => {
    const sessionid = Cookies.get('sessionid')
    const queryData = {
      college_id: college_id,
      user_id: JSON.stringify(userIdList),
      sessionid: sessionid,
      sid: sessionid,
    }
    sendHttpPostRequest(
      'college/api/manager_remove_college_user/',
      queryData,
      (response: any) => {
        message.destroy()
        if (response.code == -100) {
          // 未登录
          window.location.href = '/login?next=/college-oam'
        } else if (response.is_ok == true) {
          // 成功
          message.success('用户移出院校成功~')
          queryInstitution(college_id)
          queryInstitutionUserList(college_id)
        } else if (response.code == -501) {
          // 权限不足
          //window.location.href = '/login?next=/college-oam';
        } else {
          message.error(response.message)
        }
      }
    )
  }

  //页码和页数发生了变化
  const onPageChange = (newPage: number, newPageSize?: number | undefined) => {
    page.current = newPage ? newPage : 1
    if (newPageSize) {
      pageSize.current = newPageSize
    }
    queryInstitutionUserList(college_id)
  }

  const handleResetPassOk = () => {
    let data = {
      user_id_list: JSON.stringify(selectedRowKeys),
    }
    sendHttpPostRequest(
      'college/api/manager_batch_reset_user_password',
      data,
      (res) => {
        if (res.code == 0) {
          message.success('重置密码成功')
          setSelectedRowKeys([])
        } else {
          message.error(res.msg)
        }
        setResetPassModalVis(false)
      }
    )
  }

  return (
    <div style={{ margin: 20 }}>
      <Row className={collegeManageStyles.memberList}>
        <Col
          style={{
            backgroundImage: `url(${memberBg1})`,
            backgroundSize: '100% 100%',
          }}
        >
          <div>
            <img
              src={teacherStudentIcon}
              alt=""
              style={{ marginRight: 3 }}
              className={collegeManageStyles.icon}
            />
            师生总数
          </div>
          <div style={{ fontSize: 30, marginTop: 12 }}>{userCount}</div>
        </Col>
        <Col
          style={{
            backgroundImage: `url(${memberBg2})`,
            backgroundSize: '100% 100%',
          }}
        >
          <div>
            <img
              src={teacherIcon}
              alt=""
              style={{ marginRight: 3 }}
              className={collegeManageStyles.icon}
            />
            教师人数
          </div>
          <div style={{ fontSize: 30, marginTop: 12 }}>{teacherCount}</div>
        </Col>
        <Col
          style={{
            backgroundImage: `url(${memberBg3})`,
            backgroundSize: '100% 100%',
          }}
        >
          <div>
            <img
              src={studentIcon}
              alt=""
              style={{ marginRight: 3 }}
              className={collegeManageStyles.icon}
            />
            学生人数
          </div>
          <div style={{ fontSize: 30, marginTop: 12 }}>{studentCount}</div>
        </Col>
      </Row>

      <div
        className={collegeManageStyles.memberTableWrapper}
        style={{ minHeight: 'calc(100vh - 240px)' }}
      >
        <Row align="middle" gutter={16} style={{ marginBottom: 10 }}>
          <Col flex="350px">
            <Search
              placeholder="搜索用户"
              style={{ width: 350 }}
              enterButton={true}
              onSearch={onSearchBtn}
            />
          </Col>

          <Col flex="auto" style={{ textAlign: 'right' }}>
            <Button
              type="primary"
              style={{ marginRight: 20, borderRadius: 20 }}
              onClick={() => {
                setResetPassModalVis(true)
              }}
              disabled={!selectedRowKeys.length}
            >
              重置密码
            </Button>
            {/* <Button
              type="primary"
              style={{ marginRight: 20, borderRadius: 20 }}
              onClick={() => {
                setAddUserModalVis(true)
              }}
            >
              导入用户
            </Button> */}
          </Col>
        </Row>

        <Tabs defaultActiveKey="1" onChange={onTabKeyChange}>
          <TabPane tab="全部" key="1" className="">
            <div className={idataCourseTableStyles.tableWrapper}>
              <ConfigProvider locale={zhCN}>
                <Table
                  dataSource={userResult}
                  columns={userCols}
                  rowKey={(record) => record.user_id}
                  rowSelection={{
                    selectedRowKeys,
                    onChange: (selectedRowKeys) => {
                      setSelectedRowKeys([...selectedRowKeys])
                    },
                  }}
                  pagination={false}
                  scroll={{ x: 800 }}
                  onChange={onTableChange}
                />
              </ConfigProvider>
            </div>
          </TabPane>

          <TabPane tab="教师" key="3" className="">
            <div className={idataCourseTableStyles.tableWrapper}>
              <ConfigProvider locale={zhCN}>
                <Table
                  dataSource={userResult}
                  columns={userCols}
                  rowKey={(record) => record.user_id}
                  pagination={false}
                  scroll={{ x: 800 }}
                  onChange={onTableChange}
                />
              </ConfigProvider>
            </div>
          </TabPane>
          <TabPane tab="学生" key="2" className="">
            <div className={idataCourseTableStyles.tableWrapper}>
              <ConfigProvider locale={zhCN}>
                <Table
                  dataSource={userResult}
                  columns={userCols}
                  rowKey={(record) => record.user_id}
                  pagination={false}
                  scroll={{ x: 800 }}
                  //bordered={true}
                  onChange={onTableChange}
                />
              </ConfigProvider>
            </div>
          </TabPane>
        </Tabs>

        <ConfigProvider locale={zhCN}>
          <div
            style={{ marginBottom: 30, paddingTop: 30 }}
            className={'css.pagination'}
          >
            <Pagination
              defaultCurrent={1}
              total={resultSum}
              defaultPageSize={pageSize.current}
              current={page.current}
              hideOnSinglePage={true}
              onChange={onPageChange}
              showQuickJumper={true}
              //showTotal={total => `共 ${total} 用户`}
              //pageSizeOptions={["3","20","30","50","100"]}
            />
          </div>
        </ConfigProvider>

        <Modal
          title={
            <div
              style={{ width: '100%', cursor: 'move' }}
              onMouseOver={() => {
                if (disabled) {
                  setDisabled(false)
                }
              }}
              onMouseOut={() => {
                setDisabled(true)
              }}
              onFocus={() => {}}
              onBlur={() => {}}
            >
              提示
            </div>
          }
          width={350}
          closable={false}
          visible={removeModalVisible}
          className={collegeManageStyles.modal}
          footer={
            <div>
              <Button
                className={collegeManageStyles.stepsBtn}
                style={{ marginRight: 20 }}
                type="default"
                onClick={() => {
                  setRemoveVisible(false)
                }}
              >
                取消
              </Button>
              <Button
                className={collegeManageStyles.stepsBtn}
                style={{
                  marginRight: 20,
                  color: '#fff',
                  backgroundColor: '#1a5aa3',
                }}
                type="primary"
                onClick={() => {
                  setRemoveVisible(false)
                }}
              >
                确认
              </Button>
            </div>
          }
          modalRender={(modal) => (
            <div>
              <Draggable
                disabled={disabled}
                bounds={bounds}
                onStart={(event, uiData) => onStart(event, uiData)}
              >
                <div ref={draggleRef}>{modal}</div>
              </Draggable>{' '}
            </div>
          )}
        >
          <div style={{ width: 300, textAlign: 'center' }}>
            确认将<span style={{ color: '#1a5aa3' }}>李丽</span>移出本校吗？
          </div>
        </Modal>

        {/*  <Pagination
                        defaultCurrent={1}
                        total={10}
                        defaultPageSize={2}
                        hideOnSinglePage={true}
                    /> */}
      </div>

      <PublicTipModal
        open={resetPassModalVis}
        setOpen={setResetPassModalVis}
        modalTitle={'提示'}
        theme="blue"
        modalContent={
          <div className={collegeManageStyles.FlexCol}>
            <div>
              确定要重置选中的{' '}
              <span className={collegeManageStyles.hlText}>
                {selectedRowKeys.length}
              </span>{' '}
              个成员的密码
            </div>
            <div>
              为默认密码{' '}
              <span className={collegeManageStyles.hlText}>123456</span> ？
            </div>
          </div>
        }
        handleCancel={() => {
          setResetPassModalVis(false)
        }}
        handleOk={handleResetPassOk}
      />
      <AddCollegeStudentModal
        college_id={college_id}
        onCancel={() => setAddUserModalVis(false)}
        theme="blue"
        open={addUserModalVis}
        setOpen={setAddUserModalVis}
        actionCallback={() => queryInstitutionUserList(college_id)}
      />
    </div>
  )
}

export default CollegeMemberList
