import React, { useState, useRef, useEffect } from 'react';
// import css from '../index.less'
import zhCN from 'antd/lib/locale/zh_CN';
import { Pagination, DatePicker, ConfigProvider, Layout, Table, Space, Statistic, Row, Col, Select, Input } from 'antd';
import 'antd/lib/style/index.css';
import Cookies from 'js-cookie';
import { CheckCircleOutlined, CloseCircleOutlined, SyncOutlined, UserAddOutlined, UserOutlined } from '@ant-design/icons';
import LoginRegisterModal from '../../../component/modal/login-register/login-register';
import OAMSider from '../oam-sider';
import { Content } from 'antd/lib/layout/layout';
import { getRangeDate } from '../../common/utils/util';
import moment from 'moment';

import idataCourseTableStyles from "../../common/global-styles/idatacourse-table.module.less";

import * as echarts from 'echarts/core';
import {
    DatasetComponent,
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    ToolboxComponent
} from 'echarts/components';
import {
    LineChart
} from 'echarts/charts';
import {
    CanvasRenderer
} from 'echarts/renderers';
import {sendHttpPostRequest} from "../../../api/http-request";

echarts.use(
    [TitleComponent,ToolboxComponent,DatasetComponent, TooltipComponent, GridComponent, LegendComponent, LineChart, CanvasRenderer]
);

const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

export interface Props {}
export const OAMUserStat: React.FC<Props> = (props: Props) => {
    const [resultSum, setResultSum] = useState(0);  // 搜索返回的结果数
    const [day0Total, setDay0Total] = useState(0);  // 搜索返回的结果数
    const [day1Total, setDay1Total] = useState(0);  // 搜索返回的结果数
    const [day2Total, setDay2Total] = useState(0);  // 搜索返回的结果数
    const [totalCount, setTotalCount] = useState(0);  // 搜索返回的结果数

    const userCountList = useRef({});  // 按天统计的用户数列表
    const startDate = useRef(getRangeDate(-30));  // 起始日期
    const endDate = useRef(getRangeDate(0));  // 截止日期

    const [countData, setCountData] = useState([]);  // 总数目
    const queryContent = useRef('');  // 记录搜索串
    const order = useRef('');  // 排序升序降序
    const order_by = useRef('');  // 排序列
    const page = useRef(1);  // 记录当前的页数
    const pageSize = useRef(20);  // 记录当前每页展示数量
    const sexFilters = useRef([]);  // 性别过滤
    const userIsLogin = useRef(false);  // 用户是否登录

    const dateFormat = 'YYYY-MM-DD';
    useEffect(() => {
        queryUserInfo();
        queryUserCountList();
    }, [])

    const onLastDaysChange = (value: string) => {
        if (value == 'last7days') {
            startDate.current = getRangeDate(-7);
            endDate.current = getRangeDate(0);
        } else if (value == 'last15days') {
            startDate.current = getRangeDate(-15);
            endDate.current = getRangeDate(0);
        } else {
            startDate.current = getRangeDate(-30);
            endDate.current = getRangeDate(0);
        }
        queryUserCountList();
    }

    const disabledDate = (current:any) => {
        return current && current > moment().endOf('day');
    }

    const onDateRangeChange = (dates,dateStrings) => {
        startDate.current = dateStrings[0];
        endDate.current = dateStrings[1];
        queryUserCountList();
    }

    const renderLine = (() => {
        if (userCountList.current && userCountList.current !== {}) {
            let chartDom:any = document.getElementById('user-count-line-plot');
            let myChart = echarts.init(chartDom);

            let data = [["日期", "新增用户"]];
            for (let item in userCountList.current) {
                data.push([userCountList.current[item]['date'], userCountList.current[item]['count']]);
            }
            let option = {
                title: {text: '趋势图', textStyle: {fontWeight: 'normal', fontSize: 14}},
                legend: {left: 'center', top: 'top'},
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'line'
                    }
                },

                toolbox: {
                    feature: {
                        // saveAsImage: {title:'下载',pixelRatio:5, name: '用户统计',type:'png'}
                    }
                },

                dataset: {
                    source: data
                },

                xAxis: {type: 'category', axisLabel: {rotate: 0}},
                yAxis: {type: 'value'},
                series: [{type: 'line', smooth: true, symbol: 'emptyCircle', symbolSize: 4, color: '#07C160', lineStyle: {color: '#07C160', width: 1.5}, areaStyle: {color: '#07C160', opacity: 0.4}}]
            };
            option && myChart.setOption(option);
        }
    })

    const setUserInfoCallback = (response:any) => {
        response.code == -100 ? userIsLogin.current = false : userIsLogin.current = true;
        if (response.code == -100) {
            document.getElementById('show-login-modal')!.click();
        } else if (response.code == -501) {
            window.location.href = '/oam_login';
        } else {
            setResultSum(response.total_sum);
            setDay0Total(response.day0);
            setDay1Total(response.day1);
            setDay2Total(response.day2);
            setTotalCount(response.total_count);
        }
    }

    //向后端请求数据列表
    const queryUserInfo = () => {
        const sessionid = Cookies.get("sessionid");
        sendHttpPostRequest(
            'account/api/query_user_list/',
            {
                query: queryContent.current,
                page: page.current,
                pagesize: pageSize.current,
                order: order.current,
                order_by: order_by.current,
                sex: JSON.stringify(sexFilters.current),
                sessionid: sessionid,
                sid: sessionid,
            },
            setUserInfoCallback,
        )

        // let _data = Qs.stringify()
        // let _headers = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}
        // axios
        //     .post(
        //
        //     )
        //     .then((response) => response.data)
        //     .then((data) => {
        //
        //     })
        //     .catch((e) => {
        //         console.log('获取用户列表失败', e);
        //     })
    }

    const setUserCountListCallback = (response: any) => {
        response.code == -100 ? userIsLogin.current = false : userIsLogin.current = true;
        if (response.code == -100) {
            document.getElementById('show-login-modal')!.click();
        } else if (response.code == -501) {
            window.location.href = '/oam_login';
        } else {
            userCountList.current = response.count_list;
            setCountData(response.count_list);
            renderLine();
        }
    }

    // 查询日期区间内用户数量信息
    const queryUserCountList = () => {
        const sessionid = Cookies.get("sessionid");
        sendHttpPostRequest(
            'account/api/query_user_count/',
            {
                start_time: startDate.current,
                end_time: endDate.current,
                sessionid: sessionid,
                sid: sessionid,
            },
            setUserCountListCallback
        )
        // let _data = Qs.stringify()
        // let _headers = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}
        // axios
        //     .post(
        //         ,
        //         _data,
        //         _headers,
        //     )
        //     .then((response) => response.data)
        //     .then((data) => {
        //
        //     })
        //     .catch((e) => {
        //         console.log('获取用户列表失败', e);
        //     })
    }

    const cols: any = [
        {
            title: "日期",
            dataIndex: "date",
            key: "date",
            width: 100,
            ellipsis: false,
            align: 'center',
        },
        {
            title: "新增用户",
            dataIndex: "count",
            key: "count",
            width: 100,
            ellipsis: false,
            align: 'center',
        }
    ]

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <OAMSider keys={['11']} openKeys={["sub-user"]} />
            <LoginRegisterModal></LoginRegisterModal>
            <Layout style={{backgroundColor:'#f9f9f9'}}>
                <Content style={{ backgroundColor:'#fff',borderRadius:5,minWidth:400,margin:30 ,paddingTop:20}}>
                    <Space size="small" direction="vertical"  >
                        <div style={{paddingLeft: 30, paddingRight: 30}}>
                            <Row gutter={16} style={{textAlign:"left",width: '100%', marginBottom: 30}}>
                                <Col span={2}>
                                </Col>
                                <Col span={5}>
                                    <Statistic title="总用户数" value={totalCount} prefix={<UserOutlined style={{color: 'gray'}}/>} />
                                </Col>
                                <Col span={5}>
                                    <Statistic title="今日新增" value={day0Total} prefix={<UserAddOutlined style={{color: 'gray'}} />} />
                                </Col>
                                <Col span={5}>
                                    <Statistic title="昨日新增" value={day1Total} prefix={<UserAddOutlined style={{color: 'gray'}} />} />
                                </Col>
                                <Col span={5}>
                                    <Statistic title="前天新增" value={day2Total} prefix={<UserAddOutlined style={{color: 'gray'}} />} />
                                </Col>
                                <Col span={2}>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={12}>
                                    <Select style={{width: 200, marginRight: 50}} defaultValue='last30days' onChange={onLastDaysChange}>
                                        <Option value="last7days">最近7天</Option>
                                        <Option value="last15days">最近15天</Option>
                                        <Option value="last30days">最近30天</Option>
                                    </Select>
                                </Col>

                                <Col span={12}>
                                    <ConfigProvider locale={zhCN}>
                                        <RangePicker onChange={onDateRangeChange}
                                                     defaultValue={[moment(startDate.current, dateFormat), moment(endDate.current, dateFormat)]}
                                                     disabledDate={disabledDate}
                                                     format={dateFormat}
                                        />
                                    </ConfigProvider>
                                </Col>
                            </Row>

                            <Space size="small" direction="vertical" align='center' style={{marginTop: 20, width: '100%'}}>
                                <div id={'user-count-line-plot'} style={{height: 400, width: 700}}></div>
                            </Space>

                            <ConfigProvider locale={zhCN}>
                             <div className={idataCourseTableStyles.tableWrapper}  style={{width:400,margin:'auto'}}>
                                <Table
                                    dataSource={JSON.parse(JSON.stringify(countData)).reverse()}
                                    columns={cols}
                                    
                                    rowKey={record => record.date}
                                    pagination={{pageSize:20,position:["bottomCenter"]}}
                                    size='small'
                                />
                                </div>
                            </ConfigProvider>
                        </div>
                    </Space>
                </Content>
                {/*<PageFooter/>*/}
            </Layout>
        </Layout>
    )
}

export default OAMUserStat
