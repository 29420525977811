import React, { useEffect, useRef, useState } from 'react'
import {
  Layout,
  message,
  Card,
  Col,
  Row,
  ConfigProvider,
  Pagination,
  Tabs,
  Button,
  Modal,
  Progress,
  Avatar,
  Input,
} from 'antd'
import { PageHeader } from '../common/page-header/header'
import { sendHttpPostRequest } from '../../api/http-request'

import courseMainStyles from './course-main.module.less'
import * as constants from '../../constants/constants'
import './course.module.css'
import { MenuPreview } from './menu/course-main-teacher-menu'
import {
  PlayCircleOutlined,
  ClockCircleOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons'
import zhCN from 'antd/lib/locale/zh_CN'
import PageFooter from '../common/page-footer/footer'
import LoginRegisterModal from '../../component/modal/login-register/login-register'
import Feedback from '../common/feedback-help/feedback'

import Draggable from 'react-draggable'
import CourseMainStudentSlides from './slides/course-main-student-slides'
import CourseMainStudentCases from './case/course-main-student-cases'
import CourseMainStudentTestList from './test/course-main-student-test-list'
import CourseMainStudentExamList from './exam/course-main-student-exam-list'
import Cookies from 'js-cookie'
import { checkUserLogin } from '../common/user-center/user-info'
import { BACKEND_API_URL } from '../../constants/constants'
import LockedMenuPreview from './menu/locked-course-menu-preview'
import CourseMainDiscuss from './discussion/course-main-discuss'
import CourseMainNotelist from './note/course-main-notelist'
import CourseMainMemberList from './member/course-main-memberlist'

const { TabPane } = Tabs

export interface Props {}

const default_avatar = '/static/image/avatar/default_avatar.png' // 默认用户头像

/** 学生课程主页面 */
export const CourseMainStudent: React.FC<Props> = (props) => {
  const courseId = parseInt(window.location.search.split('&')[0].split('=')[1]) // 课程ID
  const tabKey = window.location.search.split('&')[1]
    ? window.location.search.split('&')[1].split('=')[1]
    : '1' // 标签页key
  const [course, setCourse] = useState(null as any) //课程信息

  const [currentUser, setCurrentUser] = useState(null as any) //当前用户的信息

  const [userInfo, setUserInfo] = useState(null as any) //当前用户的信息

  const [teacherList, setTeacherList] = useState([] as any[]) //保存课程教师列表
  const [studentList, setStudentList] = useState(null as any) //保存课程学生列表
  const [totalSum, setTotalSum] = useState(0) // 成员数

  const [realName, setRealName] = useState(null as any) //真实姓名
  const [realNumber, setRealNumber] = useState(null as any) //学号

  const [collegeId, setCollegeId] = useState(null as any) //

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isImproveModalVisible, setIsImproveModalVisible] = useState(false)
  const [isApplyModalVisible, setIsApplyModalVisible] = useState(false)
  const [isCheckedModalVisible, setIsCheckedModalVisible] = useState(false)
  const [inApply, setInApply] = useState(false)

  useEffect(() => {
    queryCourse()
    queryCurrentUserInfo()
    queryUserInfo()
    /** 查询机构Id */
    sendHttpPostRequest(
      'account/api/query_teacher_authenticate',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
      },
      (response: any) => {
        if (response?.college_info?.college_id) {
          let _collegeId = response.college_info.college_id
          setCollegeId(_collegeId)
        }
      }
    )
  }, [])

  const queryCurrentUserInfo = () => {
    sendHttpPostRequest(
      'account/api/check_login/',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
      },
      (response: any) => {
        message.destroy()
        if (response.code == -100) {
          // 没登录
          document.getElementById('show-login-modal')!.click()
          // window.location.href = '/login?next=course';
        } else if (response.code == -501) {
          // 没权限
          message.error('权限不足')
          // window.location.href = '/login?next=course';
        } else if (response.is_ok == true) {
          setCurrentUser(response)
          queryCourseStudentList()
        } else {
          message.error(response.message)
        }
      }
    )
  }

  // 查询用户信息回调
  const queryUserInfoCallback = (response: any) => {
    message.destroy()
    if (response.code == -100) {
      // 未登录
      document.getElementById('show-login-modal')!.click()
    } else if (response.code == 0) {
      setUserInfo(response.data)
      setRealName(response.data.real_name)
      setRealNumber(response.data.number)
    } else {
      message.error(response.message)
    }
  }

  // 查询用户信息
  const queryUserInfo = () => {
    sendHttpPostRequest(
      'account/api/get_user_info/',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
      },
      queryUserInfoCallback
    )
  }

  // 保存用户信息回调
  const saveUserInfoCallback = (response: any) => {
    message.destroy()
    if (response.code == -100) {
      // 未登录
      document.getElementById('show-login-modal')!.click()
    } else if (response.code == 0) {
      message.success('更新用户信息成功')
      // 重设localStorage
      queryUserInfo()
    } else {
      message.error(response.message)
    }
  }

  // 保存用户信息
  const saveUserInfo = () => {
    sendHttpPostRequest(
      'account/api/update_user_info/',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        user_id: userInfo?.user_id,
        real_name: realName,
        number: realNumber,
      },
      saveUserInfoCallback
    )
  }

  const queryCourseStudentCallback = (response: any) => {
    message.destroy()
    if (response.code == -100) {
      // 没登录
      document.getElementById('show-login-modal')!.click()
    } else if (response.code == -501) {
      // 没权限
      message.error('权限不足')
    } else if (response.is_ok == true) {
      setStudentList(response.query_list.filter((item) => item.role == '学生'))
      setTeacherList(response.query_list.filter((item) => item.role == '教师'))
      setTotalSum(response.total_sum)
    } else {
      message.error(response.message)
    }
  }

  const queryCourseStudentList = () => {
    sendHttpPostRequest(
      'course/api/user_query_course_user',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        course_id: courseId,
        type: 0, //0查学生，1,查教师
      },
      queryCourseStudentCallback
    )
  }

  const queryCourseTeacherCallback = (response: any) => {
    message.destroy()
    if (response.code == -100) {
      // 没登录
      document.getElementById('show-login-modal')!.click()
      // window.location.href = '/login?next=course';
    } else if (response.code == -501) {
      // 没权限
      message.error('权限不足')
      // window.location.href = '/login?next=course';
    } else if (response.is_ok == true) {
      setTeacherList(response.query_list)
    } else {
      message.error(response.message)
    }
  }

  const queryCourseTeacherList = () => {
    sendHttpPostRequest(
      'course/api/user_query_course_user',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        course_id: courseId,
        type: 1, //0查学生，1,查教师
      },
      queryCourseTeacherCallback
    )
  }

  const sentApplyCourseRequest = () => {
    setInApply(true)
    sendHttpPostRequest(
      'course/api/user_apply_course',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        course_id: courseId,
      },
      (response: any) => {
        message.destroy()
        if (response.code == -100) {
          // 未登录
          document.getElementById('show-login-modal')!.click()
        } else if (response.is_ok == true) {
          // 成功
          setInApply(true)
          queryCourse()
          queryCurrentUserInfo()
          queryUserInfo()
        } else if (response.code == -501) {
          // 权限不足
          window.location.href = '/login?next=course'
        } else if (response.status == 1) {
          // 申请开关为开放
          //window.location.reload();
          queryCourse()
          queryCurrentUserInfo()
          queryUserInfo()
        } else if (response.status == 0) {
          //window.location.reload();
          // setIsCheckedModalVisible(true);
          queryCourse()
          queryCurrentUserInfo()
          queryUserInfo()
        } else if (response.status == 2) {
          //window.location.reload();
          setInApply(false)
          queryCourse()
          queryCurrentUserInfo()
          queryUserInfo()
        } else {
          message.error(response.message)
        }
      }
    )
  }
  /** 点击了申请加入按钮，点击了提交按钮 */
  const handleOk = () => {
    setIsModalVisible(false)
    //1.检查真实姓名和学号是否未设置
    if (realName == '' || realNumber == '') {
      setIsImproveModalVisible(true)
    } else {
      sentApplyCourseRequest()
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setIsApplyModalVisible(false)
    setIsCheckedModalVisible(false)
  }

  const handleImproveOk = () => {
    setIsModalVisible(false)
    setIsImproveModalVisible(false)
    saveUserInfo() //保存用户信息
    sentApplyCourseRequest()
  }

  const handleImproveCancel = () => {
    setIsModalVisible(false)
    setIsImproveModalVisible(false)
  }

  const handleApplyOk = () => {
    setIsModalVisible(false)
    setIsApplyModalVisible(false)
    setIsCheckedModalVisible(false)
  }

  const handleApplyCancel = () => {
    setIsModalVisible(false)
    setIsApplyModalVisible(false)
    setIsCheckedModalVisible(false)
    sendHttpPostRequest(
      'course/api/user_cancel_apply_course',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        course_id: courseId,
      },
      (response: any) => {
        message.destroy()
        if (response.code == -100) {
          // 未登录
          document.getElementById('show-login-modal')!.click()
          // window.location.href = '/login?next=/course';
        } else if (response.is_ok == true) {
          // 成功
          setInApply(false)
          if (response.status == 1) {
            // 申请开关为开放
            window.location.reload()
          } else {
            message.error(response.message)
          }
        } else if (response.code == -501) {
          // 权限不足
          // window.location.href = '/login?next=course';
        } else {
          message.error(response.message)
        }
      }
    )
  }

  const handleCheckedOk = () => {
    setIsModalVisible(false)
    setIsApplyModalVisible(false)
    setIsCheckedModalVisible(false)
  }

  const draggleRef = useRef<HTMLDivElement | null>(null)
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
  const [disabled, setDisabled] = useState(true)

  const onStart = (event: any, uiData: any) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement
    const targetRect = draggleRef?.current?.getBoundingClientRect()
    if (targetRect) {
      setBounds({
        left: -targetRect?.left + uiData?.x,
        right: clientWidth - (targetRect?.right - uiData?.x),
        top: -targetRect?.top + uiData?.y,
        bottom: clientHeight - (targetRect?.bottom - uiData?.y),
      })
    }
  }

  // 查询课程信息
  const queryCourse = () => {
    const sessionid = Cookies.get('sessionid')
    const queryData = {
      source: 2,
      course_id: courseId,
      sessionid: sessionid,
      sid: sessionid,
    }
    sendHttpPostRequest(
      'course/api/user_query_course_by_id',
      queryData,
      courseCallback
    )
  }

  // 查询课程接口回调
  const courseCallback = (response: any) => {
    console.log(response)
    message.destroy()
    if (response.code == -100) {
      // 未登录
      document.getElementById('show-login-modal')!.click()
      // window.location.href = '/login?next=/course';
    } else if (response.is_ok == true) {
      // 成功
      setCourse(response.query_info)
    } else if (response.code == -501) {
      // 权限不足
      // window.location.href = '/login?next=course';
    } else {
      message.error(response.message)
    }
  }

  const applyIn = () => {
    if (course?.apply_info?.status == 0) {
      setIsCheckedModalVisible(true)
    } else if (course?.apply_info?.status == 1) {
      setIsApplyModalVisible(true)
    } else {
      setIsModalVisible(true)
    }
  }

  const startStudy = () => {
    if (!checkUserLogin()) {
      document.getElementById('show-login-modal')!.click()
    } else {
      if (course.recent_knowledge_url != '') {
        window.location.href = course.recent_knowledge_url
      } else if (course.first_knowledge_url != '') {
        window.location.href = course.first_knowledge_url
      } else {
        message.destroy()
        message.error('课程暂时无法学习，请稍候...')
      }
    }
  }

  const waitChecked = () => {
    setIsCheckedModalVisible(true)
  }

  const BtnContent: React.FC<Props> = (props) => {
    if (
      course?.course_status == '未开始' &&
      !inApply &&
      course?.is_course_student == false
    ) {
      return (
        <ConfigProvider locale={zhCN}>
          <Button
            type="primary"
            style={{
              borderRadius: 20,
              width: 100,
              //  float: 'right',
              padding: 0,
            }}
            onClick={applyIn}
          >
            {'申请加入'}
          </Button>
        </ConfigProvider>
      )
    } else if (
      course?.course_status == '未开始' &&
      inApply &&
      course?.is_course_student == false
    ) {
      return (
        <ConfigProvider locale={zhCN}>
          <Button
            type="primary"
            style={{
              borderRadius: 20,
              width: 100,
              // float: 'right',
              padding: 0,
            }}
            onClick={applyIn}
          >
            等待审核
          </Button>
        </ConfigProvider>
      )
    } else if (
      course?.course_status == '未开始' &&
      course?.is_course_student == true
    ) {
      return (
        <ConfigProvider locale={zhCN}>
          <Button
            type="primary"
            style={{
              borderRadius: 20,
              width: 100,
              // float: 'right',
              padding: 0,
            }}
          >
            等待开课
          </Button>
        </ConfigProvider>
      )
    } else if (
      course?.course_status == '进行中' &&
      course?.is_course_student == true
    ) {
      return (
        <ConfigProvider locale={zhCN}>
          <Button
            type="primary"
            style={{
              borderRadius: 20,
              width: 100,
              // float: 'right',
              padding: 0,
            }}
            onClick={startStudy}
          >
            开始学习
          </Button>
        </ConfigProvider>
      )
    } else if (
      course?.course_status == '进行中' &&
      !inApply &&
      course?.is_course_student == false
    ) {
      return (
        <ConfigProvider locale={zhCN}>
          <Button
            type="primary"
            style={{
              borderRadius: 20,
              width: 100,
              // float: 'right',
              padding: 0,
            }}
            onClick={applyIn}
          >
            {'申请加入'}
          </Button>
        </ConfigProvider>
      )
    } else if (
      course?.course_status == '进行中' &&
      inApply &&
      course?.is_course_student == false
    ) {
      return (
        <ConfigProvider locale={zhCN}>
          <Button
            type="primary"
            style={{
              borderRadius: 20,
              width: 100,
              //  float: 'right',
              padding: 0,
            }}
            onClick={applyIn}
          >
            等待审核
          </Button>
        </ConfigProvider>
      )
    } else if (
      course?.course_status == '已结束' &&
      course?.is_course_student == false
    ) {
      return (
        <ConfigProvider locale={zhCN}>
          <Button
            type="primary"
            disabled={true}
            style={{
              borderRadius: 20,
              width: 100,
              //  float: 'right',
              padding: 0,
            }}
          >
            已结课
          </Button>
        </ConfigProvider>
      )
    } else if (
      course?.course_status == '已结束' &&
      course?.is_course_student == true
    ) {
      return (
        <ConfigProvider locale={zhCN}>
          <Button
            type="primary"
            disabled={true}
            style={{
              borderRadius: 20,
              width: 100,
              //  float: 'right',
              padding: 0,
            }}
            onClick={startStudy}
          >
            已结课
          </Button>
        </ConfigProvider>
      )
    } else {
      return <ConfigProvider locale={zhCN}></ConfigProvider>
    }
  }

  const CourseStatus = () => {
    if (
      course?.course_status == '未开始' &&
      course?.is_course_student == true
    ) {
      return (
        <div
          className={courseMainStyles.courseStatus}
          style={{ backgroundColor: '#ff780e' }}
        >
          {'未开课'}
        </div>
      )
    } else if (
      course?.course_status == '未开始' &&
      course?.is_course_student == false
    ) {
      return (
        <div
          className={courseMainStyles.courseStatus}
          style={{ backgroundColor: '#199ed8' }}
        >
          {'未加入'}
        </div>
      )
    } else if (
      course?.course_status == '进行中' &&
      course?.is_course_student == true
    ) {
      return (
        <div>
          <div
            className={courseMainStyles.courseStatus}
            style={{
              backgroundColor: '#07c160',
              display: 'inline-block',
              marginRight: 20,
            }}
          >
            {course?.course_status}
          </div>
          {/*<span style={{fontSize:12,color:'#333'}}>观看进度：</span><Progress type="circle" strokeColor='#07c160' trailColor='rgba(7, 193, 96, 0.3)' percent={course.progress} width={30} />           */}
        </div>
      )
    } else if (
      course?.course_status == '进行中' &&
      course?.is_course_student == false
    ) {
      return (
        <div
          className={courseMainStyles.courseStatus}
          style={{ backgroundColor: '#199ed8' }}
        >
          {'未加入'}
        </div>
      )
    } else if (course?.course_status == '已结束') {
      return (
        <div
          className={courseMainStyles.courseStatus}
          style={{
            backgroundColor: '#0ac6ae',
            display: 'inline-block',
            marginRight: 20,
          }}
        >
          {'已结课'}
        </div>
      )
    } else {
      return <div></div>
    }
  }

  const courseMainWidth = (
    course_status: string,
    is_course_student: boolean
  ) => {
    if (course_status == '未开始' && is_course_student == true) return 1280
    else if (course_status == '未开始' && is_course_student == false)
      return 1280 //未加入
    else if (course_status == '进行中' && is_course_student == true) return 1280
    else if (course_status == '进行中' && is_course_student == false)
      return 1280 //未加入
    else if (course_status == '已结束' && is_course_student == true)
      return 1280 //已结课
    else if (course_status == '已结束' && is_course_student == false)
      return 1280 //未加入
    else return 1280
  }

  return (
    <Layout style={{ minHeight: 750, backgroundColor: '#f9f9f9' }}>
      {/*<LoginRegisterModal></LoginRegisterModal>*/}
      <div className={courseMainStyles.courseHeaderWrapper}>
        <Row
          className={courseMainStyles.courseHeader}
          style={{
            width: 1220,
            marginRight: 'auto',
            marginLeft: 'auto',
            gap: '10px',
          }}
          align="middle"
          // gutter={[12, 0]}
          wrap={false}
        >
          <Col flex="300px" style={{ margin: 0, marginTop: 10 }}>
            <div className={courseMainStyles.courseStudentImage}>
              <img
                //{/*src={constants.BACKEND_API_URL + course.cover}*/}
                src={
                  course?.cover && course?.cover?.startsWith('http')
                    ? course?.cover
                    : constants.BACKEND_URL + course?.cover
                }
              />
            </div>
          </Col>

          <Col
            flex="auto"
            style={{
              textAlign: 'left',
              // overflow: 'hidden',
              margin: 0,
              // marginRight: 20,
              paddingLeft: 20,
            }}
          >
            <Row align="middle" gutter={[4, 4]}>
              <Col>
                <div className={courseMainStyles.courseName}>
                  {course?.name}
                </div>
              </Col>
              <Col> {CourseStatus()} </Col>
            </Row>

            <div
              className={courseMainStyles.courseIntroduction}
              style={{ width: '100%' }}
            >
              {' '}
              {course?.description}{' '}
            </div>
            <div
              style={{
                border: '1px solid #eee',
                paddingLeft: 20,
                paddingTop: 30,
                position: 'relative',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: -11,
                  left: 20,
                  backgroundColor: '#fff',
                }}
              >
                <div
                  style={{
                    float: 'left',
                    width: 3,
                    height: 22,
                    backgroundColor: '#07c160',
                    borderRadius: 2,
                    marginRight: 5,
                  }}
                ></div>
                <div
                  style={{
                    float: 'left',
                    color: '#fff',
                    backgroundColor: '#07c160',
                    width: 90,
                    textAlign: 'center',
                    borderRadius: 2,
                  }}
                >
                  {course?.teacher_real_name == ''
                    ? course?.teacher
                    : course?.teacher_real_name}
                </div>
              </div>
              <Row align="middle" style={{ marginBottom: 0 }} gutter={[4, 0]}>
                {/*<Col><span style={{color:'#52c8b9',fontSize:16}}><VideoCameraOutlined /></span></Col>*/}
                {/*<Col><div style={{color:'#333333',fontSize:12}}>  视频时长：16小时15分 </div></Col>*/}
              </Row>
              <Row align="middle" style={{ marginBottom: 10 }} gutter={[4, 0]}>
                <Col>
                  <span style={{ color: '#52c8b9', fontSize: 16 }}>
                    <ClockCircleOutlined />
                  </span>
                </Col>
                <Col>
                  <div style={{ color: '#333333', fontSize: 14 }}>
                    开课时间： {course?.start_time.slice(0, 10)} 至{' '}
                    {course?.end_time.slice(0, 10)}
                  </div>
                </Col>
              </Row>
              {course?.code !== '' && (
                <Row
                  align="middle"
                  style={{ marginBottom: 10 }}
                  gutter={[4, 0]}
                >
                  <Col flex="80px" style={{ color: '#333333', fontSize: 12 }}>
                    课程编号：
                  </Col>
                  <Col style={{ color: '#333333', fontSize: 12 }}>
                    {course?.code}
                  </Col>
                </Row>
              )}
              {course?.class !== '' && (
                <Row
                  align="middle"
                  style={{ marginBottom: 10 }}
                  gutter={[4, 0]}
                >
                  <Col flex="80px" style={{ color: '#333333', fontSize: 12 }}>
                    班级：
                  </Col>
                  <Col style={{ color: '#333333', fontSize: 12 }}>
                    {' '}
                    {course?.class}
                  </Col>
                </Row>
              )}
            </div>
          </Col>

          <Col style={{ margin: 0 }} flex="100px">
            <BtnContent />
          </Col>
        </Row>
      </div>

      <Modal
        title={
          <div
            style={{ width: '100%', cursor: 'move' }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false)
              }
            }}
            onMouseOut={() => {
              setDisabled(true)
            }}
            onFocus={() => {}}
            onBlur={() => {}}
          >
            申请加入
          </div>
        }
        width={450}
        closable={false}
        visible={isModalVisible}
        className={courseMainStyles.modal}
        footer={
          <div>
            <Button
              className={courseMainStyles.stepsBtn}
              style={{ marginRight: 20 }}
              type="default"
              onClick={handleCancel}
            >
              取消
            </Button>
            <Button
              className={courseMainStyles.stepsBtn}
              style={{ marginRight: 20, color: '#fff' }}
              type="primary"
              onClick={handleOk}
            >
              提交
            </Button>
          </div>
        }
        modalRender={(modal) => (
          <div>
            <Draggable
              disabled={disabled}
              bounds={bounds}
              onStart={(event, uiData) => onStart(event, uiData)}
            >
              <div ref={draggleRef}>{modal}</div>
            </Draggable>{' '}
          </div>
        )}
      >
        <div style={{ margin: '0 auto', width: 250 }}>
          <p>课程名称：{course?.name}</p>
          <p>
            授课老师：
            {course?.teacher_real_name == ''
              ? course?.teacher
              : course?.teacher_real_name}
          </p>
          <p>
            开课时间：{course?.start_time.slice(0, 10)}至
            {course?.end_time.slice(0, 10)}
          </p>
        </div>
      </Modal>

      <Modal
        title={
          <div
            style={{ width: '100%', cursor: 'move' }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false)
              }
            }}
            onMouseOut={() => {
              setDisabled(true)
            }}
            onFocus={() => {}}
            onBlur={() => {}}
          >
            完善个人信息
          </div>
        }
        width={450}
        closable={false}
        visible={isImproveModalVisible}
        className={courseMainStyles.modal}
        footer={
          <div>
            <Button
              className={courseMainStyles.stepsBtn}
              style={{ marginRight: 20 }}
              type="default"
              onClick={handleImproveCancel}
            >
              取消
            </Button>
            <Button
              className={courseMainStyles.stepsBtn}
              style={{ marginRight: 20, color: '#fff' }}
              type="primary"
              onClick={handleImproveOk}
            >
              提交
            </Button>
          </div>
        }
        modalRender={(modal) => (
          <div>
            <Draggable
              disabled={disabled}
              bounds={bounds}
              onStart={(event, uiData) => onStart(event, uiData)}
            >
              <div ref={draggleRef}>{modal}</div>
            </Draggable>{' '}
          </div>
        )}
      >
        <Row align="middle" style={{ margin: '0 auto 10px', width: 300 }}>
          <Col flex="80px">真实姓名</Col>
          <Col flex="auto">
            <Input
              value={realName}
              onChange={(e) => {
                setRealName(e.target.value)
              }}
              placeholder="请输入真实姓名"
              style={{ borderRadius: 4 }}
            />
          </Col>
        </Row>
        <Row align="middle" style={{ margin: '0 auto 20px', width: 300 }}>
          <Col flex="80px">学&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;号</Col>
          <Col flex="auto">
            <Input
              value={realNumber}
              onChange={(e) => {
                setRealNumber(e.target.value)
              }}
              placeholder="请输入学号"
              style={{ borderRadius: 4 }}
            />
          </Col>
        </Row>
        <div
          style={{
            color: '#999',
            margin: '0 auto',
            width: 300,
            textAlign: 'center',
          }}
        >
          便于教学，请输入真实姓名和学号
        </div>
      </Modal>

      <Modal
        title={
          <div
            style={{ width: '100%', cursor: 'move' }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false)
              }
            }}
            onMouseOut={() => {
              setDisabled(true)
            }}
            onFocus={() => {}}
            onBlur={() => {}}
          >
            提示
          </div>
        }
        width={450}
        closable={false}
        visible={isApplyModalVisible}
        className={courseMainStyles.modal}
        footer={
          <div>
            <Button
              className={courseMainStyles.stepsBtn}
              style={{ marginRight: 20 }}
              type="default"
              onClick={handleApplyCancel}
            >
              撤销
            </Button>
            <Button
              className={courseMainStyles.stepsBtn}
              style={{ marginRight: 20, color: '#fff' }}
              type="primary"
              onClick={handleApplyOk}
            >
              确认
            </Button>
          </div>
        }
        modalRender={(modal) => (
          <div>
            <Draggable
              disabled={disabled}
              bounds={bounds}
              onStart={(event, uiData) => onStart(event, uiData)}
            >
              <div ref={draggleRef}>{modal}</div>
            </Draggable>{' '}
          </div>
        )}
      >
        <div style={{ margin: '0 auto', textAlign: 'center', width: 235 }}>
          <p>已发出申请，等待老师审核</p>
          <p style={{ fontSize: 12, color: '#ccc' }}>5分钟内可撤回该申请</p>
        </div>
      </Modal>

      <Modal
        title={
          <div
            style={{ width: '100%', cursor: 'move' }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false)
              }
            }}
            onMouseOut={() => {
              setDisabled(true)
            }}
            onFocus={() => {}}
            onBlur={() => {}}
          >
            提示
          </div>
        }
        width={450}
        closable={false}
        visible={isCheckedModalVisible}
        className={courseMainStyles.modal}
        footer={
          <div>
            <Button
              className={courseMainStyles.stepsBtn}
              style={{ marginRight: 20, color: '#fff' }}
              type="primary"
              onClick={handleCheckedOk}
            >
              确认
            </Button>
          </div>
        }
        modalRender={(modal) => (
          <div>
            <Draggable
              disabled={disabled}
              bounds={bounds}
              onStart={(event, uiData) => onStart(event, uiData)}
            >
              <div ref={draggleRef}>{modal}</div>
            </Draggable>{' '}
          </div>
        )}
      >
        <div style={{ margin: '0 auto', textAlign: 'center', width: 235 }}>
          <p>老师已拒绝</p>
          <p>请先学习其他课程吧</p>
        </div>
      </Modal>

      <Row
        align="top"
        style={{
          marginBottom: 20,
          flexWrap: 'nowrap',
          whiteSpace: 'nowrap',
          overflow: 'visible',
          maxWidth: '100%',
        }}
      >
        <Col flex="auto">
          {' '}
          <div
            style={{
              borderBottomStyle: 'solid',
              borderBottomColor: '#07c160',
              borderBottomWidth: 1,
              height: 36,
              backgroundColor: '#fff',
            }}
          ></div>{' '}
        </Col>
        <Col
          flex="1220px"
          style={{ whiteSpace: 'nowrap', overflow: 'visible', maxWidth: 1220 }}
        >
          <div
            className={courseMainStyles.courseMain}
            style={{
              width: courseMainWidth(
                course?.course_status,
                course?.is_course_student
              ),
              minHeight: 'calc(100vh - 398px)',
              float: 'left',
            }}
          >
            {course?.course_status == '未开始' &&
              course?.is_course_student == true && (
                <Tabs
                  style={{ backgroundColor: '#f8f8f8' }}
                  defaultActiveKey={tabKey}
                  type="card"
                  size="middle"
                  tabPosition="top"
                >
                  <TabPane
                    tab={<div style={{ width: '80px!important' }}>大纲</div>}
                    key="1"
                  >
                    <LockedMenuPreview
                      menu_id={course?.syllabus_id}
                    ></LockedMenuPreview>
                  </TabPane>
                  {/* <TabPane tab={<div>考试</div>}   key="5">
                                    <CourseMainStudentExamList course_id={course?.id} />
                                </TabPane> */}
                  {/* <TabPane tab={<div></div>} disabled={true} key="2">
                                </TabPane>
                                <TabPane tab={<div></div>} disabled={true} key="3">
                                </TabPane>
                                <TabPane tab={<div></div>} disabled={true} key="4">
                                </TabPane>
                                <TabPane tab={<div></div>} disabled={true} key="5">
                                </TabPane> */}
                  <TabPane tab={<div></div>} disabled={true} key="6"></TabPane>
                  <TabPane tab={<div></div>} disabled={true} key="7"></TabPane>
                  <TabPane tab={<div></div>} disabled={true} key="8"></TabPane>
                </Tabs>
              )}
            {course?.course_status == '未开始' &&
              course?.is_course_student == false && (
                <Tabs
                  style={{ backgroundColor: '#f8f8f8' }}
                  defaultActiveKey={tabKey}
                  type="card"
                  size="middle"
                  tabPosition="top"
                >
                  <TabPane
                    tab={<div style={{ width: '80px!important' }}>大纲</div>}
                    key="1"
                  >
                    <LockedMenuPreview
                      menu_id={course?.syllabus_id}
                    ></LockedMenuPreview>
                  </TabPane>
                  <TabPane tab={<div></div>} disabled={true} key="2"></TabPane>
                  <TabPane tab={<div></div>} disabled={true} key="3"></TabPane>
                  <TabPane tab={<div></div>} disabled={true} key="4"></TabPane>
                  <TabPane tab={<div></div>} disabled={true} key="5"></TabPane>
                  <TabPane tab={<div></div>} disabled={true} key="6"></TabPane>
                  <TabPane tab={<div></div>} disabled={true} key="7"></TabPane>
                  <TabPane tab={<div></div>} disabled={true} key="8"></TabPane>
                </Tabs>
              )}
            {course?.course_status == '进行中' &&
              course?.is_course_student == true && (
                <Tabs
                  defaultActiveKey={tabKey}
                  type="card"
                  size="middle"
                  tabPosition="top"
                >
                  <TabPane tab={<div>大纲</div>} key="1">
                    <MenuPreview
                      menu_id={course?.syllabus_id}
                      course_id={course?.id}
                      course_user={'student'}
                      course_status={'进行中'}
                    />
                  </TabPane>
                  <TabPane tab={<div>课件</div>} key="2">
                    <CourseMainStudentSlides course_id={course?.id} />
                  </TabPane>
                  <TabPane tab={<div>实验</div>} key="3">
                    <CourseMainStudentCases course_id={course?.id} />
                  </TabPane>
                  <TabPane tab={<div>作业</div>} key="4">
                    {collegeId && (
                      <CourseMainStudentTestList
                        course_id={courseId}
                        college_id={collegeId}
                        menu_id={course?.syllabus_id}
                      />
                    )}
                  </TabPane>
                  <TabPane tab={<div>考试</div>} key="5">
                    <CourseMainStudentExamList course_id={course?.id} />
                  </TabPane>
                  <TabPane tab={<div>讨论</div>} key="9">
                    <CourseMainDiscuss
                      teacherInfo={{
                        teacher: course.teacher,
                        teacher_avatar: course.teacher_avatar,
                        teacher_id: course.teacher_id,
                        teacher_real_name: course.teacher_real_name,
                      }}
                      course_id={course?.id}
                      menu_id={course?.syllabus_id}
                    />
                  </TabPane>
                  <TabPane tab={<div>笔记</div>} key="10">
                    <CourseMainNotelist menu_id={course?.syllabus_id} />
                  </TabPane>
                  <TabPane tab={<div>成员</div>} key="11">
                    <CourseMainMemberList
                      actionCallback={queryCourseStudentList}
                      studentList={studentList}
                      teacherList={teacherList}
                    />
                  </TabPane>

                  {/*<TabPane tab={<div></div>} disabled={true} key="6">
                                </TabPane>
                                <TabPane tab={<div></div>} disabled={true} key="7">
                                </TabPane>
                                <TabPane tab={<div></div>} disabled={true} key="8">
                                </TabPane> */}
                </Tabs>
              )}
            {course?.course_status == '进行中' &&
              course?.is_course_student == false && (
                <Tabs
                  style={{ backgroundColor: '#f8f8f8' }}
                  defaultActiveKey={tabKey}
                  type="card"
                  size="middle"
                  tabPosition="top"
                >
                  <TabPane
                    tab={<div style={{ width: '80px!important' }}>大纲</div>}
                    key="1"
                  >
                    <LockedMenuPreview
                      menu_id={course?.syllabus_id}
                    ></LockedMenuPreview>
                  </TabPane>
                  <TabPane tab={<div></div>} disabled={true} key="2"></TabPane>
                  <TabPane tab={<div></div>} disabled={true} key="3"></TabPane>
                  <TabPane tab={<div></div>} disabled={true} key="4"></TabPane>
                  <TabPane tab={<div></div>} disabled={true} key="5"></TabPane>
                  <TabPane tab={<div></div>} disabled={true} key="6"></TabPane>
                  <TabPane tab={<div></div>} disabled={true} key="7"></TabPane>
                  <TabPane tab={<div></div>} disabled={true} key="8"></TabPane>
                </Tabs>
              )}
            {course?.course_status == '已结束' &&
              course?.is_course_student == false && (
                <Tabs
                  style={{ backgroundColor: '#f8f8f8' }}
                  defaultActiveKey={'1'}
                  type="card"
                  size="middle"
                  tabPosition="top"
                >
                  <TabPane tab={<div>大纲</div>} key="1">
                    <LockedMenuPreview
                      menu_id={course?.syllabus_id}
                    ></LockedMenuPreview>
                  </TabPane>
                  <TabPane tab={<div></div>} disabled={true} key="2"></TabPane>
                  <TabPane tab={<div></div>} disabled={true} key="3"></TabPane>
                  <TabPane tab={<div></div>} disabled={true} key="4"></TabPane>
                  <TabPane tab={<div></div>} disabled={true} key="5"></TabPane>
                  <TabPane tab={<div></div>} disabled={true} key="6"></TabPane>
                  <TabPane tab={<div></div>} disabled={true} key="7"></TabPane>
                  <TabPane tab={<div></div>} disabled={true} key="8"></TabPane>
                </Tabs>
              )}

            {course?.course_status == '已结束' &&
              course?.is_course_student == true && (
                <Tabs
                  style={{ backgroundColor: '#f8f8f8' }}
                  defaultActiveKey={tabKey}
                  type="card"
                  size="middle"
                  tabPosition="top"
                >
                  <TabPane tab={<div>大纲</div>} key="1">
                    <LockedMenuPreview
                      menu_id={course?.syllabus_id}
                    ></LockedMenuPreview>
                  </TabPane>
                  <TabPane tab={<div>课件</div>} key="2">
                    <CourseMainStudentSlides course_id={course?.id} />
                  </TabPane>
                  <TabPane tab={<div>实验</div>} key="3">
                    <CourseMainStudentCases course_id={course?.id} />
                  </TabPane>
                  <TabPane tab={<div>作业</div>} key="4">
                    {collegeId && (
                      <CourseMainStudentTestList
                        course_id={courseId}
                        college_id={collegeId}
                        menu_id={course?.syllabus_id}
                      />
                    )}
                  </TabPane>
                  <TabPane tab={<div>考试</div>} key="5">
                    <CourseMainStudentExamList course_id={course?.id} />
                  </TabPane>
                  <TabPane tab={<div>讨论</div>} key="9">
                    <CourseMainDiscuss
                      teacherInfo={{
                        teacher: course.teacher,
                        teacher_avatar: course.teacher_avatar,
                        teacher_id: course.teacher_id,
                        teacher_real_name: course.teacher_real_name,
                      }}
                      course_id={course?.id}
                      menu_id={course?.syllabus_id}
                    />
                  </TabPane>
                  <TabPane tab={<div>笔记</div>} key="10">
                    <CourseMainNotelist menu_id={course?.syllabus_id} />
                  </TabPane>
                  <TabPane tab={<div>成员</div>} key="11">
                    <CourseMainMemberList
                      actionCallback={queryCourseStudentList}
                      studentList={studentList}
                      teacherList={teacherList}
                    />
                  </TabPane>

                  {/*<TabPane tab={<div></div>} disabled={true} key="6">
                            </TabPane>
                            <TabPane tab={<div></div>} disabled={true} key="7">
                            </TabPane>
                            <TabPane tab={<div></div>} disabled={true} key="8">
                            </TabPane> */}
                </Tabs>
              )}
          </div>
        </Col>
        <Col flex="auto">
          <div
            style={{
              borderBottomStyle: 'solid',
              borderBottomColor: '#07c160',
              borderBottomWidth: 1,
              height: 36,
              backgroundColor: '#fff',
            }}
          ></div>
        </Col>
      </Row>

      <Feedback />
    </Layout>
  )
}

export default CourseMainStudent
