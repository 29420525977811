import React, { useEffect, useState } from 'react'
import {
  Input,
  Table,
  Switch,
  Layout,
  message,
  Card,
  Col,
  Row,
  ConfigProvider,
  Pagination,
  Tabs,
  Button,
  Tag,
} from 'antd'
import { PageHeader } from '../../common/page-header/header'
import { sendHttpPostRequest } from '../../../api/http-request'
import logoSvg from '../../static/image/idclogo.png'
import pekinglogoSvg from '../../static/image/peking.png'
import courseMainStyles from '../course-main.module.less'
import '../course.module.css'
import zhCN from 'antd/lib/locale/zh_CN'
import * as constants from '../../../constants/constants'
import PageFooter from '../../common/page-footer/footer'
import LoginRegisterModal from '../../../component/modal/login-register/login-register'
import { checkUserLogin } from '../../common/user-center/user-info'
import {
  ClockCircleOutlined,
  VideoCameraOutlined,
  CodeOutlined,
  FormOutlined,
  UserOutlined,
} from '@ant-design/icons'
import CourseMainTeacherMenu from '../menu/course-main-teacher-menu'
import CourseMainTeacherSlides from '../slides/course-main-teacher-slides'

import courseTeacherSettingStyles from './course-main-teacher-setting.module.less'
import Cookies from 'js-cookie'

const { TextArea } = Input
const { TabPane } = Tabs
const { Search } = Input

export interface Props {
  course: any
}
/** 教师课程主页面 */
export const CourseMainTeacherSetting: React.FC<Props> = (props) => {
  const { course } = props
  const [courseResult, setCourseResult] = useState([] as any[])

  const [coursePublished, setCoursePublished] = useState(course?.visible) //课程是否开放
  const [courseNeedApply, setCourseNeedApply] = useState(course?.apply) //课程是否需要申请才能加入

  const [courseNotice, setCourseNotice] = useState(course?.notice) //课程公告
  const [courseCode, setCourseCode] = useState(course?.code) //课程编码
  const [courseClassName, setCourseClassName] = useState(course?.class) //班级名

  useEffect(() => {
    // 示例代码请勿删除
    /*      sendHttpPostRequest(
            'case/api/get_case_list',
            {page: 1, pagesize: 16, type: 1, query: ""},
            renderCourse
        );*/
  }, [])

  const onCoursePublishedChange = (checked) => {
    const visible = checked ? 1 : 2
    setCoursePublished(visible)
    const params = {
      sessionid: Cookies.get('sessionid'),
      sid: Cookies.get('sessionid'),
      course_id: course?.id,
      visible: visible,
    }
    saveCourse(params)
  }

  const onCourseNeedApplyChange = (checked) => {
    const applySwitch = checked ? 2 : 1
    setCourseNeedApply(applySwitch)
    const params = {
      sessionid: Cookies.get('sessionid'),
      sid: Cookies.get('sessionid'),
      course_id: course?.id,
      apply: applySwitch,
    }
    saveCourse(params)
  }

  /**
   * 保存课程回调
   * @param response
   */
  const saveCourseInfoCallback = (response: any) => {
    message.destroy()
    if (response.code == -100) {
      // 没登录
      document.getElementById('show-login-modal')!.click()
      // window.location.href = '/login?next=course';
    } else if (response.code == -501) {
      // 没权限
      message.error('权限不足')
      // window.location.href = '/login?next=course';
    } else if (response.is_ok == true) {
      message.success('课程信息保存成功~')
    } else {
      message.error(response.message)
    }
  }

  const saveCourse = (params: any) => {
    sendHttpPostRequest(
      'course/api/user_update_course',
      params,
      saveCourseInfoCallback
    )
  }

  const saveCourseTextInfo = () => {
    const params = {
      sessionid: Cookies.get('sessionid'),
      sid: Cookies.get('sessionid'),
      course_id: course?.id,
      code: courseCode,
      classname: courseClassName,
      notice: courseNotice,
    }
    sendHttpPostRequest(
      'course/api/user_update_course',
      params,
      saveCourseInfoCallback
    )
  }

  return (
    <div className={courseTeacherSettingStyles.settingWapper}>
      <Row justify="start" align="middle">
        <Col
          style={{ textAlign: 'left' }}
          flex="100px"
          className={courseTeacherSettingStyles.settingTitle}
        >
          课程编号
        </Col>
        <Col style={{ textAlign: 'left' }} span={20}>
          <Input
            defaultValue={courseCode}
            onChange={(e) => {
              setCourseCode(e.target.value)
            }}
            style={{ width: 200, borderRadius: 4 }}
          />
        </Col>
      </Row>
      <Row justify="start" align="middle">
        <Col
          style={{ textAlign: 'left' }}
          flex="100px"
          className={courseTeacherSettingStyles.settingTitle}
        >
          班级
        </Col>
        <Col style={{ textAlign: 'left' }} span={20}>
          <Input
            defaultValue={courseClassName}
            onChange={(e) => {
              setCourseClassName(e.target.value)
            }}
            style={{ width: 200, borderRadius: 4 }}
          />
        </Col>
      </Row>
      <Row justify="start" align="middle">
        <Col
          style={{ textAlign: 'left' }}
          flex="100px"
          className={courseTeacherSettingStyles.settingTitle}
        >
          公开设置
        </Col>
        <Col style={{ textAlign: 'left' }} span={20}>
          {course?.is_course_teacher && (
            <Switch
              size="small"
              checked={coursePublished == 1 ? true : false}
              onClick={onCoursePublishedChange.bind(this)}
            />
          )}
          <div className={courseTeacherSettingStyles.settingDescription}>
            {coursePublished == 1
              ? '已开放，允许学生看到课程'
              : '未开放，学生无法看到课程'}
          </div>
        </Col>
      </Row>
      <Row justify="start" align="middle">
        <Col
          style={{ textAlign: 'left' }}
          flex="100px"
          className={courseTeacherSettingStyles.settingTitle}
        >
          申请设置
        </Col>
        <Col style={{ textAlign: 'left' }} span={20}>
          {course?.is_course_teacher && (
            <Switch
              size="small"
              checked={courseNeedApply == 2 ? true : false}
              onClick={onCourseNeedApplyChange.bind(this)}
            />
          )}
          <div className={courseTeacherSettingStyles.settingDescription}>
            {courseNeedApply == 2
              ? '已打开，学生加入课程需要审核'
              : '未打开，学生加入不需要审核'}
          </div>
        </Col>
      </Row>
      <Row justify="start" align="top">
        <Col
          style={{ textAlign: 'left' }}
          flex="100px"
          className={courseTeacherSettingStyles.settingTitle}
        >
          学员公告
        </Col>
        <Col span={16}>
          <TextArea
            defaultValue={courseNotice}
            onChange={(e) => {
              setCourseNotice(e.target.value)
            }}
            showCount
            maxLength={500}
            style={{ borderRadius: 4 }}
            rows={6}
          ></TextArea>
        </Col>
      </Row>
      <Row justify="center" align="middle">
        <Col style={{ textAlign: 'left' }} span={20}>
          <Button
            onClick={saveCourseTextInfo}
            style={{ borderRadius: 20, width: 80 }}
            type="primary"
          >
            确认
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default CourseMainTeacherSetting
