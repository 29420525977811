import {Pagination, Layout, message, ConfigProvider, Input, Table, Modal, Button, Tabs, Row, Col, Statistic} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useState, useRef, useEffect } from "react";
import Cookies from 'js-cookie';
import zhCN from 'antd/lib/locale/zh_CN';
import {sendHttpPostRequest} from "../../../api/http-request";
const { Search } = Input;
const { TabPane } = Tabs;
import TextArea from 'antd/lib/input/TextArea';
import OAMSider from "../oam-sider";

import idataCourseTableStyles from "../../common/global-styles/idatacourse-table.module.less";

export interface Props {}

export const OAMTeacherApplyList: React.FC<Props> = (props) => {
    const [userResult, setUserResult] = useState([] as any[]);  // 当前显示的结果页
    const [resultSum, setResultSum] = useState(0);  // 搜索返回的结果数
    const [totalCount, setTotalCount] = useState(0);  // 搜索返回的结果数
    const [treatedCount, setTreatedCount] = useState(0);  // 处理总数
    const [unTreatedCount, setUnTreatedCount] = useState(0);  // 待处理
    const [isEditUserModalVisible, setIsEditUserModalVisible] = useState(false);  // 模态框是否显示
    const [editUserId, setEditUserId] = useState(-1);  // 待处理的用户ID
    const [editApplyId, setEditApplyId] = useState(-1);  // 待处理的申请ID
    const [editHandlingOpinions, setEditHandlingOpinions] = useState("");  // 处理意见
    const queryType = useRef('1');  // 查询类型 1-未处理 2-已处理
    const queryContent = useRef('');  // 记录搜索串
    const order = useRef('');  // 排序升序降序
    const order_by = useRef('');  // 排序列
    const page = useRef(1);  // 记录当前的页数
    const pageSize = useRef(20);  // 记录当前每页展示数量
    const userIsLogin = useRef(false);  // 用户是否登录

    useEffect(() => {
        queryTeacherApplyList();
    }, [])

    // 查询用户列表
    const queryTeacherApplyList = () => {
        const sessionid = Cookies.get("sessionid");
        const queryData = {
            query: queryContent.current,
            query_type: parseInt(queryType.current),
            page: page.current,
            pagesize: pageSize.current,
            order: order.current,
            order_by: order_by.current,
            sessionid: sessionid,
            sid: sessionid,
        };
        sendHttpPostRequest(
            'account/api/oam_query_teacher_authenticate',
            queryData,
            userListCallback
        )
    }

    // 查询用户列表接口回调
    const userListCallback = (response: any) => {
        // console.log(response);
        message.destroy();
        if (response.code == -100) {
            // 未登录
            window.location.href = '/oam_login?next=user_list';
        } else if (response.code == 0) {
            // 成功
            setUserResult(response.apply_list);
            setResultSum(response.total_sum);
            setTotalCount(response.total_count);
            setTreatedCount(response.treated_count);
            setUnTreatedCount(response.untreated_count);
        } else if (response.code == -501) {
            // 权限不足
            window.location.href = '/oam_login?next=user_list';
        } else {
            message.error(response.message);
        }
    }

    //页码和页数发生了变化
    const onPageChange = (newPage: number, newPageSize?: number | undefined) => {
        page.current = newPage ? newPage : 1;
        if (newPageSize) {
            pageSize.current = newPageSize;
        }
        queryTeacherApplyList();
    }

    // 渲染操作
    const renderActions = (text:any, record:any) => {
        if ([1, 2].includes(record.deal_method)) {
            return <Button style={{borderRadius:20,fontSize:14}} size='small' id="lookover" type="dashed" onClick={onEditHandlingOpinionsBtnClick.bind(this, record.apply_user_id, record.apply_id, record.handling_opinions)} > {record.deal_method == 2 ? <span>延期</span> : ""}处理 </Button>;
        } else if (record.deal_method == 3) {
            return <span>已通过</span>;
        } else if (record.deal_method == 4) {
            return <span>已驳回</span>;
        }
    }

    //点击了处理按钮
    const onEditHandlingOpinionsBtnClick = (user_id:any, apply_id:any, handling_opinions:string, event:any) => {
        console.log(user_id);
        setEditUserId(user_id);
        console.log(apply_id);
        setEditApplyId(apply_id);
        setEditHandlingOpinions(handling_opinions);
        setIsEditUserModalVisible(true);
    }

    // 处理意见改变
    const onHandlingOpinionsChange = async (e: any) => {
        setEditHandlingOpinions(e.target.value);
    }

    const handlingOpinionsCallback = (response: any) => {
        message.destroy();
        if (response.code == -100) {
            // 未登录
            window.location.href = '/oam_login?next=oam_teacher_apply';
        } else if (response.code == 0) {
            // 成功
            message.success("处理成功");
            queryTeacherApplyList();
        } else if (response.code == -501) {
            // 权限不足
            window.location.href = '/oam_login?next=oam_teacher_apply';
        } else {
            message.error(response.message);
        }
    }

    // 处理意见确认
    const handleHandlingOpinionsOk = (key: number) => {
        console.log(editApplyId, editUserId);
        setIsEditUserModalVisible(false);
        sendHttpPostRequest(
            'account/api/deal_teacher_authenticate/',
            {
                apply_id: editApplyId,
                apply_user_id: editUserId,
                deal_method: key,
                handling_opinions: editHandlingOpinions,
                sessionid: Cookies.get("sessionid"),
                sid: Cookies.get("sessionid"),
            },
            handlingOpinionsCallback
        )
    }

    // 处理意见取消
    const handleHandlingOpinionsCancel = () => {
        setIsEditUserModalVisible(false);
    }

    // 切换Tab页
    const onTabKeyChange = (key:string) => {
        console.log(key);
        queryType.current = key;
        page.current = 1;
        queryTeacherApplyList();
    }

    //点击了搜索按钮
    const onSearchBtn = (value: string) => {
        page.current = 1;
        queryContent.current = value;
        queryTeacherApplyList();
    }

    // 表格发生变化
    const onTableChange:any = (pagination:any, filters:any, sorter:any, extra: { currentDataSource: [], action: any })=> {
        if (sorter.order) { //如果选择了排序列
            order.current = sorter.order;
            order_by.current = sorter.field;
        }

        page.current = 1;
        queryTeacherApplyList();
    }

    // 表格列
    const cols: any = [
        {
            title: "用户id",
            dataIndex: "apply_user_id",
            key: "apply_user_id",
            width: 80,
            align: 'center',
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: "手机号",
            dataIndex: "apply_user_phone",
            key: "apply_user_phone",
            width: 80,
            align: 'center',
        },
        {
            title: "申请时间",
            dataIndex: "apply_time",
            key: "apply_time",
            width: 120,
            align: 'center',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'descend',
        },
        {
            title: "昵称",
            dataIndex: "apply_user_nick_name",
            key: "apply_user_nick_name",
            width: 80,
            align: 'left'
        },
        {
            title: "真实姓名",
            dataIndex: "apply_user_real_name",
            key: "apply_user_real_name",
            width: 80,
            align: 'left',
        },
        {
            title: "单位/院校",
            dataIndex: "college",
            key: "college",
            width: 120,
            align: 'left',
        },
        {
            title: "院系/职务",
            dataIndex: "title",
            key: "title",
            width: 100,
            align: 'left',
        },
        {
            title: "处理意见",
            dataIndex: "handling_opinions",
            key: "handling_opinions",
            width: 120,
            align: 'left',
            ellipsis: false,
        },
        {
            title: "处理人",
            dataIndex: "deal_user_nick_name",
            key: "deal_user_nick_name",
            width: 80,
            align: 'left',
            ellipsis: false,
        },
        {
            title: "处理时间",
            dataIndex: "deal_time",
            key: "deal_time",
            width: 80,
            align: 'left',
            ellipsis: false,
        },
        {
            title: "处理",
            dataIndex: "actions",
            key: "actions",
            width: 50,
            ellipsis: false,
            align: 'center',
            fixed:"right",
            render: renderActions ,
        },
    ]

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <OAMSider keys={"14"} openKeys={["sub-user"]}></OAMSider>
            <Layout style={{backgroundColor:'#f9f9f9'}}>
                <Content style={{ backgroundColor:'#fff',borderRadius:5,minWidth:400,margin:30 ,paddingTop:20}}>
                    <div style={{paddingLeft: 30, paddingRight: 30, paddingBottom: 30}}>
                        <Row align="middle"  gutter={16} >
                            <Col flex='80px'>
                                <Statistic title="总申请" value={totalCount} />
                            </Col>
                            <Col flex='80px'>
                                <Statistic title="已处理" value={treatedCount} />
                            </Col>
                            <Col flex='80px'>
                                <Statistic title="待处理" value={unTreatedCount} />
                            </Col>
                            <Col flex='250px'>
                                <Search
                                    placeholder="搜索用户"
                                    style={{ width: 350 }}
                                    enterButton={true}
                                    onSearch={onSearchBtn}
                                />
                            </Col>
                        </Row>
                        <Tabs defaultActiveKey={"1"} onChange={onTabKeyChange}>
                            <TabPane tab="未处理" key={"1"} className="">
                                <div className={idataCourseTableStyles.tableWrapper}>
                                    <ConfigProvider locale={zhCN}>
                                        <Table
                                            dataSource={userResult}
                                            scroll={{ x: 800 }}
                                            columns={cols}
                                            rowKey={record => record.apply_user_id}
                                            pagination={false}
                                            //bordered={true}
                                            onChange={onTableChange}
                                        />
                                    </ConfigProvider>
                                </div>
                            </TabPane>
                            <TabPane tab="已处理" key={"2"} className="">
                                <div className={idataCourseTableStyles.tableWrapper}>
                                    <ConfigProvider locale={zhCN}>
                                        <Table
                                            dataSource={userResult}
                                            columns={cols}
                                            scroll={{ x: 1200 }}
                                            rowKey={record => record.apply_user_id}
                                            pagination={false}
                                            //bordered={true}
                                            onChange={onTableChange}
                                        />
                                    </ConfigProvider>
                                </div>
                            </TabPane>
                            <ConfigProvider locale={zhCN}>
                                <div style={{marginBottom:30,paddingTop:30}} className={"css.pagination"} >
                                    <Pagination
                                        defaultCurrent={1}
                                        total={resultSum}
                                        defaultPageSize={20}
                                        current={page.current}
                                        hideOnSinglePage={true}
                                        onChange={onPageChange}
                                        showQuickJumper={true}
                                        showTotal={total => `共 ${total} 申请`}
                                        pageSizeOptions={["3","20","30","50","100"]}
                                    />
                                </div>
                            </ConfigProvider>
                        </Tabs>
                        <ConfigProvider locale={zhCN}>
                            <Modal
                                title="处理意见"
                                visible={isEditUserModalVisible}
                                // onOk={handleHandlingOpinionsOk}
                                // onCancel={handleHandlingOpinionsCancel}
                                footer={[
                                    <Button key="back" onClick={handleHandlingOpinionsCancel}>
                                        取消
                                    </Button>,
                                    <Button key="submit" type="primary" onClick={handleHandlingOpinionsOk.bind(this, 2)}>
                                        延期处理
                                    </Button>,
                                    <Button key="reject" type="ghost" onClick={handleHandlingOpinionsOk.bind(this, 4)}>
                                        驳回
                                    </Button>,
                                    <Button key="pass" type="primary" onClick={handleHandlingOpinionsOk.bind(this, 3)}>
                                        通过
                                    </Button>,
                                ]}
                            >
                                <div> 用户ID: {editUserId}</div>
                                <TextArea
                                    style={{marginTop:20}}
                                    value={editHandlingOpinions}
                                    rows={6}
                                    maxLength={300}
                                    placeholder={"请输入处理意见（可选）"}
                                    onChange={onHandlingOpinionsChange} > </TextArea>
                            </Modal>
                        </ConfigProvider>

                    </div>
                </Content>
            </Layout>
        </Layout>

    )

}

export default OAMTeacherApplyList

