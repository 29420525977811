import exerciseCardWhiteStyles from './exercise-card-white.module.less'
import exerciseCardDarkStyles from './exercise-card-dark.module.less'
import {
  Image,
  message,
  Button,
  Col,
  ConfigProvider,
  Row,
  Space,
  Popconfirm,
} from 'antd'

import React, { useEffect, useState } from 'react'

import {
  UpOutlined,
  DownOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons'

import rehypeHighlight from 'rehype-highlight'

import zhCN from 'antd/lib/locale/zh_CN'
import Cookies from 'js-cookie'
import { Checkbox } from 'antd'
import { Radio } from 'antd'

import * as constants from '../../constants/constants'

import remarkMath from 'remark-math'
import gfm from 'remark-gfm'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import rehypeKatex from 'rehype-katex'
import 'katex/dist/katex.min.css' // `rehype-katex` does not import the CSS for you

import { sendHttpPostRequest } from '../../api/http-request'
import { checkIsTeacher } from '../../component/modal/teacher-authenticate/teacher-authenticate'
import { stringify } from 'querystring'

/* 单选题 */

export interface Props {
  exercise: any
  renderType: string //渲染的主题类型，"dark","white"
  teacherOnly: boolean // 仅老师
  editorMode: boolean //是否可编辑模式，
  showAnser: boolean //是否默认显示答案
}
export const ExerciseCardSingleSelect: React.FC<Props> = (props) => {
  const { exercise, renderType, teacherOnly, editorMode, showAnser } = props

  const options =
    typeof exercise.options === 'string'
      ? JSON.parse(exercise.options)
      : exercise.options
  // const [answerList, setAnswerList] = useState([] as any[]);  // 选择的答案
  const [running, setRunning] = useState(false) // 运行中状态
  const [answerList, setAnswerList] = useState(
    exercise?.submission_info?.submission_answer.length == 0
      ? [-2]
      : [exercise?.submission_info?.submission_answer[0]]
  ) // 选择的答案
  const [answervisible, setAnswerVisible] = useState(showAnser)

  const [currentStatus, setCurrentStatus] = useState(null as any)

  const exerciseCardStyles =
    renderType == 'white' ? exerciseCardWhiteStyles : exerciseCardDarkStyles

  useEffect(() => {}, [])

  // 习题运行回调
  const runCodeCallback = (response: any) => {
    message.destroy()
    if (response.code == -100) {
      // 未登录
      document.getElementById('show-login-modal')!.click()
      // window.location.href = `/oam_login?next=oam_preview_exercise?exercise_id=${exercise.id}`;
    } else if (response.code == 0) {
      // 成功
      // TODO: 答题卡片渲染执行结果，不在这里弹提示
      if (response.data.status == true) {
        //message.success(`回答正确`);
        setCurrentStatus(true)
      } else {
        //message.error(`回答错误`);
        setCurrentStatus(false)
      }
    } else if (response.code == -501) {
      // 权限不足
      document.getElementById('show-login-modal')!.click()
      // window.location.href = `/oam_login?next=oam_preview_exercise?exercise_id=${exercise.id}`;
    } else {
      message.error(response.message)
    }
    setRunning(false)
  }

  //点击了提交按钮
  const exerciseSubmitBtnClick = () => {
    //if (teacherOnly && !checkIsTeacher()) {
    //document.getElementById("show-teacher-authenticate")!.click();
    //} else {
    // console.log(answerList)
    let _params = {
      sessionid: Cookies.get('sessionid'),
      exercise_id: exercise.id,
      answer_list: JSON.stringify(answerList),
      source_page: exercise.source_page,
      source_key: exercise.source_key,
      course_id: exercise.course_id,
      chapter_id: exercise.chapter_id,
      knowcell_id: exercise.knowcell_id,
    }
    if ([3, 4].includes(exercise.source_page)) {
      // 作业、考试
      if (!answerList.includes(-2)) {
        setRunning(true)

        if (exercise.exam_id) {
          _params['exam_id'] = exercise.exam_id // 考试传考试id
        } else if (exercise.homework_id) {
          _params['homework_id'] = exercise.homework_id // 作业传作业id
        }

        sendHttpPostRequest(
          'exercise/api/exercise_run/',
          _params,
          runCodeCallback
        )
      } else {
        // console.log("单选题未勾选")
      }
    } else {
      setRunning(true)

      sendHttpPostRequest(
        'exercise/api/exercise_run/',
        _params,
        runCodeCallback
      )
    }
    //}
  }

  // 点了单选按钮
  const onOptionsChange = (event: any) => {
    setCurrentStatus(null as any)
    setAnswerList([parseInt(event.target.value)])
  }

  // 看答案回调
  const queryExerciseAnswerCallback = (response: any) => {
    message.destroy()
    if (response.code == -100) {
      // 未登录
      document.getElementById('show-login-modal')!.click()
    } else if (response.code == 0) {
      // 成功
      if (response.exercise_answer) {
        exercise.options_answer = response.exercise_answer.options_answer
        exercise.answer_parse = response.exercise_answer.answer_parse
        setAnswerVisible(true)
      }
    } else if (response.code == -501) {
      // 权限不足
      // document.getElementById("show-login-modal")!.click();
    } else {
      message.error(response.message)
    }
  }

  //点击了参考答案按钮
  const exerciseAnswerBtnClick = () => {
    if (exercise.options_answer.length == 0) {
      // 查询答案
      sendHttpPostRequest(
        'exercise/api/query_exercise_answer/',
        {
          sessionid: Cookies.get('sessionid'),
          sid: Cookies.get('sessionid'),
          exercise_id: exercise.id,
        },
        queryExerciseAnswerCallback
      )
    } else {
      setAnswerVisible(!answervisible)
    }
  }

  // 渲染 查看答案按钮
  const renderAnswerButton = () => {
    if (0 == exercise.options_answer.length) {
      if (![3, 4].includes(exercise.source_page)) {
        return (
          <Popconfirm
            placement="bottom"
            title={'您确定查看答案吗？'}
            onCancel={(e: any) => {
              e.stopPropagation()
            }}
            onConfirm={(e: any) => {
              e.stopPropagation()
              exerciseAnswerBtnClick()
            }}
            okText="确认"
            cancelText="取消"
          >
            <Button
              type="link"
              style={{ width: 80, marginRight: 10, borderRadius: 20 }}
            >
              <span>答案解析</span>
              <DownOutlined />{' '}
            </Button>
          </Popconfirm>
        )
      }
    } else {
      if (![3, 4].includes(exercise.source_page)) {
        return (
          <Button
            onClick={exerciseAnswerBtnClick}
            type="link"
            style={{ width: 80, marginRight: 10, borderRadius: 20 }}
          >
            <span>答案解析</span>
            {!answervisible ? <DownOutlined /> : <UpOutlined />}
          </Button>
        )
      }
    }
  }

  const getRunnigStatusBtn = () => {
    if (currentStatus == true) {
      return (
        <Button
          hidden={exercise?.hint_submit_btn == 2}
          id={'exercise-run-' + exercise.id}
          onClick={exerciseSubmitBtnClick}
          type="default"
          style={{
            paddingLeft: 5,
            borderRadius: 3,
            width: 100,
            color: '#07c160',
            borderColor: '#07c160',
          }}
          className="exercise-run-button"
        >
          {' '}
          <CheckOutlined />
          {[3, 4].includes(exercise?.source_page) ? '已提交' : ' 正 确 '}
        </Button>
      )
    } else if (currentStatus == false) {
      return (
        <Button
          hidden={exercise?.hint_submit_btn == 2}
          id={'exercise-run-' + exercise.id}
          onClick={exerciseSubmitBtnClick}
          danger
          type="default"
          style={{
            paddingLeft: 5,
            color: '#ff4b50',
            borderRadius: 3,
            width: 100,
          }}
          className="exercise-run-button"
        >
          {' '}
          <CloseOutlined />错 误
        </Button>
      )
    } else {
      return (
        <Button
          hidden={exercise?.hint_submit_btn == 2}
          id={'exercise-run-' + exercise.id}
          onClick={exerciseSubmitBtnClick}
          loading={running}
          type="primary"
          style={{ borderRadius: 3, width: 100 }}
          className="exercise-run-button"
        >
          {[3, 4].includes(exercise.source_page) ? ' 提 交 ' : ' 测 试 '}
        </Button>
      )
    }
  }

  return (
    <div
      style={{ marginBottom: 20, textAlign: 'left' }}
      className={exerciseCardStyles.wrapper}
    >
      <div
        style={{
          fontSize: 14,
          textOverflow: 'ellipsis',
          wordWrap: 'break-word',
        }}
      >
        <ReactMarkdown
          className={exerciseCardStyles.reactmarkdownTitle}
          remarkPlugins={[gfm, remarkMath]}
          rehypePlugins={[rehypeHighlight, rehypeKatex]}
        >
          {'【单选】' + exercise?.steps}
        </ReactMarkdown>
        <div style={{ marginTop: 10, marginBottom: 10 }}>
          <ConfigProvider locale={zhCN}>
            <Row justify="center" align="middle" gutter={[10, 10]}>
              {exercise?.images?.map((image: any) => {
                return (
                  <Col key={image.name}>
                    <Image
                      width={360}
                      height={270}
                      preview={false}
                      src={constants.BACKEND_URL + image.path + image.name}
                    />
                  </Col>
                )
              })}
            </Row>
          </ConfigProvider>
        </div>
      </div>
      <div>
        <Radio.Group value={String(answerList[0])}>
          <Space size="small" direction="vertical">
            {options.map((option) => {
              return answervisible == true ? (
                <div
                  style={{
                    width: '100%',
                    backgroundColor:
                      option.id == String(exercise?.options_answer[0])
                        ? '#E5F9EF'
                        : String(answerList[0]) == option.id
                        ? '#ffeced'
                        : '#fff',
                  }}
                  key={option.id}
                >
                  <Radio
                    style={{
                      padding: 5,
                      marginLeft: 20,
                      minHeight: 30,
                      display: 'flex',
                      alignItems: 'center',
                      alignContent: 'center',
                    }}
                    key={option.id}
                    value={option.id}
                    onChange={editorMode == false ? () => {} : onOptionsChange}
                  >
                    <div
                      style={{
                        textOverflow: 'ellipsis',
                        wordWrap: 'break-word',
                      }}
                    >
                      <ReactMarkdown
                        className={exerciseCardStyles.reactmarkdownOption}
                        remarkPlugins={[gfm, remarkMath]}
                        rehypePlugins={[rehypeKatex, rehypeRaw]}
                      >
                        {option.id + '. ' + option.options}
                      </ReactMarkdown>
                    </div>
                  </Radio>
                </div>
              ) : (
                <div
                  style={{ width: '100%' }}
                  className={exerciseCardStyles.option}
                  key={option.id}
                >
                  <Radio
                    style={{
                      padding: 5,
                      marginLeft: 20,
                      minHeight: 30,
                      display: 'flex',
                      alignItems: 'center',
                      alignContent: 'center',
                    }}
                    key={option.id}
                    value={option.id}
                    onChange={editorMode == false ? () => {} : onOptionsChange}
                  >
                    <div
                      style={{
                        textOverflow: 'ellipsis',
                        wordWrap: 'break-word',
                      }}
                    >
                      <ReactMarkdown
                        className={exerciseCardStyles.reactmarkdownOption}
                        remarkPlugins={[gfm, remarkMath]}
                        rehypePlugins={[rehypeKatex, rehypeRaw]}
                      >
                        {option.id + '. ' + option.options}
                      </ReactMarkdown>
                    </div>
                  </Radio>
                </div>
              )
            })}
          </Space>
        </Radio.Group>
      </div>

      <Row
        justify="start"
        align="middle"
        gutter={[8, 8]}
        style={{ marginTop: 20 }}
      >
        <Col flex={'100px'} style={{ textAlign: 'left' }}>
          {getRunnigStatusBtn()}
        </Col>
        <Col flex={'auto'} style={{ textAlign: 'left' }}></Col>
        <Col flex={'100px'} style={{ textAlign: 'right' }}>
          {renderAnswerButton()}
        </Col>
      </Row>

      {answervisible && (
        <div
          style={{ marginTop: 20, padding: 10, borderRadius: 5 }}
          className={exerciseCardStyles.answerParse}
        >
          <ReactMarkdown
            className={exerciseCardStyles.reactmarkdown}
            remarkPlugins={[gfm, remarkMath]}
            rehypePlugins={[rehypeKatex, rehypeRaw]}
          >
            {exercise.answer_parse}
          </ReactMarkdown>
        </div>
      )}
    </div>
  )
}

export default ExerciseCardSingleSelect
