import React, { useEffect, useState } from 'react'
import './App.less'
import { Route } from 'react-router-dom'
// const history = createHashHistory();
import CollegeOAM from './pages/college-oam/college-oam'
import CollegeProject from './pages/college/college-project'
import CollegeTraffic from './pages/college/college-traffic'
import CollegeFinance from './pages/college/college-finance'
import CollegeMarketing from './pages/college/college-marketing'
import CollegeLoginRegister from './pages/college/college-login-register'
import Index from './pages/index/index'
import Course from './pages/course/course'
import CaseStatic from './pages/case/case-static'
import CaseCreate from './pages/case/case-create'

import Case from './pages/case/case'
import VodList from './pages/lecture/vod-list'
import VodPlay from './pages/lecture/vod-play'
import LoginRegister from './pages/common/login-register/login-register'
import OamLogin from './pages/oam/oam-login/oam-login'
import OAMUserList from './pages/oam/user/oam-user-list'
import ExerciseList from './pages/exercise/exercise'
import ModuleList from './pages/module/module'
import OAMUserStat from './pages/oam/user/oam-user-stat'
import OAMMenuList from './pages/oam/menu/oam-menu-list'
import OAMMenuDetail from './pages/oam/menu/oam-menu-detail'
import OAMMenuEdit from './pages/oam/menu/oam-menu-edit'
import OAMExerciseList from './pages/oam/exercise/oam-exercise-list'
import OAMExerciseEdit from './pages/oam/exercise/oam-exercise-edit'
import CourseLearn from './pages/course/course-learn'
import CoursePPT from './pages/course/course-ppt'
import CoursePPTPreview from './pages/course/course-ppt-preview'
import CourseMain from './pages/course/course-main'
import CourseMainTeacher from './pages/course/course-main-teacher'
import CourseCommonDetail from './pages/course/statistics/course-main-stats-common-detail/index'
import CourseMainTeacherEdit from './pages/course/course-main-teacher-edit'
import CourseMainTeacherStuDetail from './pages/course/case/course-main-teacher-studentdetail'
import CourseMainStudent from './pages/course/course-main-student'
import CourseCreateTest from './pages/course/test/course-create-test'
import CourseTeacherTestPreview from './pages/course/test/course-teacher-test-preview'
import CourseStudentTestPreview from './pages/course/test/course-student-test-preview'
import CourseCreateExam from './pages/course/exam/course-create-exam'
import CourseCreate from './pages/course/course-create'
import CourseCreateKnowledge from './pages/course/course-edit-knowledge'
import CourseTeacherExamPreview from './pages/course/exam/course-teacher-exam-preview'
import CourseStudentExamPreview from './pages/course/exam/course-student-exam-preview'
import CourseTeacherExamEdit from './pages/course/exam/course-teacher-exam-edit'
import ModuleEdit from './pages/module/module-edit'
import OAMExercisePreview from './pages/oam/exercise/oam-exercise-preview'
import ExerciseEdit from './pages/exercise/exercise-edit'
import ExercisePreview from './pages/exercise/exercise-preview'
import OAMInstitutionList from './pages/oam/institution/oam-institution-list'
import OAMCourseList from './pages/oam/course/oam-course-list'
import OAMModuleList from './pages/oam/module/oam-module-list'
import OAMSlidesList from './pages/oam/slides/oam-slides-list'
import OAMVideoList from './pages/oam/video/oam-video-list'

import OAMExamsList from './pages/oam/exam/oam-exam-list'

import OAMCaseList from './pages/oam/case/oam-case-list'
import OAMCaseEdit from './pages/oam/case/oam-case-edit'

import OAMTestList from './pages/oam/test/oam-test-list'
import OAMVideoEdit from './pages/oam/video/oam-video-edit'
import OAMVideoCreate from './pages/oam/video/oam-video-upload'
import OAMVideoUploadSuccess from './pages/oam/video/oam-video-upload-success'
import OAMUserApply from './pages/oam/user/oam-user-apply'
import OAMLectureEdit from './pages/oam/lecture/oam-lecture-edit'
import OAMLectureList from './pages/oam/lecture/oam-lecture-list'

import UserCenter from './pages/common/user-center/user-center'
import OAMTeacherApplyList from './pages/oam/user/oam-teacher-apply-list'
import OAMCourseEdit from './pages/oam/course/oam-course-edit'
import OAMModuleEdit from './pages/oam/module/oam-module-edit'
import OAMInstitutionEdit from './pages/oam/institution/oam-institution-edit'
import OAMInstitutionPreview from './pages/oam/institution/oam-institution-preview'
import ModuleLearnPreview from './pages/course/module-learn-preview'
import OAMExamEdit from './pages/oam/exam/oam-exam-edit'
import OAMExamPreview from './pages/oam/exam/oam-exam-preview'
import OAMInstitutionUserManager from './pages/oam/institution/oam-institution-user-manage'
import OAMTestEdit from './pages/oam/test/oam-test-edit'
import OAMTestPreview from './pages/oam/test/oam-test-preview'
import OAMLectureBannerList from './pages/oam/lecture/oam-lecture-banner-list'
import OAMLectureBannerEdit from './pages/oam/lecture/oam-lecture-banner-edit'
import PageHeader from './pages/common/page-header/header'
import PageFooter from './pages/common/page-footer/footer'
import Feedback from './pages/common/feedback-help/feedback'
import Community from './pages/community/community'
import ArticleFrom from './pages/community/article-form/ArticleFrom'
import ArticleDetail from './pages/community/article-detail/ArticleDetail'
import CaseDetail from './pages/case/CaseDetail/CaseDetail'
import CaseRun from './pages/case/CaseRun/CaseRun'
import OAMCasePreview from './pages/oam/case/oam-case-preview'

function App() {
  const ENV = process.env

  const [isFootDis, setIsFootDis] = useState(true)
  const [isHeadDis, setIsHeadDis] = useState(true)
  const [isFeedbackDis, setIsFeedbackDis] = useState<boolean>(
    ENV.REACT_APP_FEED_BACK == 'true'
  )
  let n = window.location.pathname
  console.log(ENV.REACT_APP_FEED_BACK == 'true')

  useEffect(() => {
    //  不要 头部和脚部  在这里添加路由
    if (
      n === '/login' ||
      n === '/course-ppt-preview' ||
      n === '/course-student-test-preview' ||
      n.startsWith('/college') ||
      n.startsWith('/oam') ||
      n.includes('case-run')
    ) {
      setIsFootDis(false)
      setIsHeadDis(false)
      //  只不要脚部  在这里添加路由
    } else if (
      n === '/course-new-exam' ||
      n === '/course-teacher-exam-preview' ||
      n === '/course-student-exam-preview' ||
      n === '/course-teacher-exam-edit' ||
      n === '/course-student-test-preview' ||
      n === '/course-new-test' ||
      n === '/community-article-create' ||
      n === '/community-article-detail' ||
      n.split('/')[1] === 'community-article-edit'
    ) {
      setIsFootDis(false)
    } else if (
      n === '/course-learn' ||
      n === '/lecture-vod' ||
      n === '/module-learn-preview'
    ) {
      setIsFeedbackDis(false)
      setIsFootDis(false)
      setIsHeadDis(false)
    } else {
      setIsFootDis(true)
      setIsHeadDis(true)
    }
  }, [n])
  return (
    <div className="App">
      <div className={'router'}>
        {isHeadDis ? (
          <PageHeader
            keys={
              n == '/'
                ? '0'
                : n == '/user-center'
                ? ''
                : n.startsWith('/case')
                ? '2'
                : n.startsWith('/module')
                ? '3'
                : n.startsWith('/exercise')
                ? '4'
                : n.startsWith('/lecture')
                ? '5'
                : n.startsWith('/community')
                ? '6'
                : '1'
            }
          />
        ) : (
          ''
        )}
        <Route path={'/college-project'} exact component={CollegeProject} />
        <Route path={'/college-traffic'} exact component={CollegeTraffic} />
        <Route path={'/college-finance'} exact component={CollegeFinance} />
        <Route path={'/college-marketing'} exact component={CollegeMarketing} />
        <Route path={'/college-login'} exact component={CollegeLoginRegister} />

        <Route path={'/college-oam'} exact component={CollegeOAM} />

        <Route path={'/course'} exact component={Course} />
        {/*<Route path={"/course-student"} exact component={CourseStudent} />*/}
        <Route path={'/course-learn'} exact component={CourseLearn} />
        <Route path={'/course-create'} exact component={CourseCreate} />
        <Route
          path={'/course-edit-knowledge'}
          exact
          component={CourseCreateKnowledge}
        />
        <Route path={'/case-create'} exact component={CaseCreate} />
        <Route path={'/case-static'} exact component={CaseStatic} />
        <Route path={'/case-detail'} exact component={CaseDetail} />
        <Route path={'/case-run'} exact component={CaseRun} />
        <Route path={'/oam-case-run'} exact component={CaseRun} />
        <Route path={'/oam-case-edit'} exact component={OAMCaseEdit} />
        <Route path={'/oam-case-preview'} exact component={OAMCasePreview} />
        <Route path={'/course-ppt'} exact component={CoursePPT} />
        <Route
          path={'/course-ppt-preview'}
          exact
          component={CoursePPTPreview}
        />

        <Route path={'/course-main'} exact component={CourseMain} />
        <Route
          path={'/course-main-teacher'}
          exact
          component={CourseMainTeacher}
        />
        <Route
          path={'/course-main-teacher-exercise-detail'}
          exact
          component={CourseCommonDetail}
        />
        <Route
          path={'/course-main-teacher-exam-detail'}
          exact
          component={CourseCommonDetail}
        />
        <Route
          path={'/course-main-teacher-edit'}
          exact
          component={CourseMainTeacherEdit}
        />
        <Route
          path={'/course-main-teacher-studentdetail'}
          exact
          component={CourseMainTeacherStuDetail}
        />
        <Route path={'/course-new-test'} exact component={CourseCreateTest} />
        <Route
          path={'/course-teacher-test-preview'}
          exact
          component={CourseTeacherTestPreview}
        />
        <Route path={'/course-new-exam'} exact component={CourseCreateExam} />
        <Route
          path={'/course-teacher-exam-preview'}
          exact
          component={CourseTeacherExamPreview}
        />
        <Route
          path={'/course-teacher-exam-edit'}
          exact
          component={CourseTeacherExamEdit}
        />

        <Route
          path={'/course-main-student'}
          exact
          component={CourseMainStudent}
        />
        <Route
          path={'/course-student-exam-preview'}
          exact
          component={CourseStudentExamPreview}
        />
        <Route
          path={'/course-student-test-preview'}
          exact
          component={CourseStudentTestPreview}
        />
        {/* <Route
          path={'/course-student-test-preview'}
          exact
          component={CourseStudentTestPreview}
        /> */}

        <Route path={'/course-case-detail'} exact component={CaseDetail} />
        <Route path={'/course-case-run'} exact component={CaseRun} />

        <Route path={'/case'} exact component={Case} />

        <Route path={'/community'} exact component={Community} />
        <Route
          path={'/community-article-create'}
          exact
          component={ArticleFrom}
        />
        <Route
          path={'/community-article-edit/:id'}
          exact
          component={ArticleFrom}
        />
        <Route
          path={'/community-article-detail/:id'}
          exact
          component={ArticleDetail}
        />

        <Route path={'/lecture'} exact component={VodList} />
        <Route path={'/lecture-vod'} exact component={VodPlay} />

        <Route path={'/user-center'} exact component={UserCenter} />

        <Route path={'/module'} exact component={ModuleList} />
        <Route path={'/module-edit'} exact component={ModuleEdit} />
        <Route
          path={'/module-learn-preview'}
          exact
          component={ModuleLearnPreview}
        />


        <Route path={'/exercise'} exact component={ExerciseList} />
        <Route path={'/exercise-edit'} exact component={ExerciseEdit} />
        <Route path={'/exercise-preview'} exact component={ExercisePreview} />

        <Route path={'/login'} exact component={LoginRegister} />
        <Route path={'/oam_login'} exact component={OamLogin} />
        <Route path={'/oam_user_list'} exact component={OAMUserList} />
        <Route path={'/oam_user_stat'} exact component={OAMUserStat} />
        <Route path={'/oam_user_apply'} exact component={OAMUserApply} />
        <Route
          path={'/oam_teacher_apply'}
          exact
          component={OAMTeacherApplyList}
        />

        <Route
          path={'/oam_institution_list'}
          exact
          component={OAMInstitutionList}
        />
        <Route
          path={'/oam_institution_edit'}
          exact
          component={OAMInstitutionEdit}
        />
        <Route
          path={'/oam_preview_institution'}
          exact
          component={OAMInstitutionPreview}
        />
        <Route
          path={'/oam_institution_user_manager'}
          exact
          component={OAMInstitutionUserManager}
        />

        <Route path={'/oam_course_list'} exact component={OAMCourseList} />
        <Route path={'/oam_course_edit'} exact component={OAMCourseEdit} />

        <Route path={'/oam_module_list'} exact component={OAMModuleList} />
        <Route path={'/oam_module_create'} exact component={OAMModuleEdit} />
        <Route path={'/oam_module_edit'} exact component={OAMModuleEdit} />
        <Route path={'/oam_slides_list'} exact component={OAMSlidesList} />

        <Route path={'/oam_video_list'} exact component={OAMVideoList} />
        <Route path={'/oam_video_edit'} exact component={OAMVideoEdit} />
        <Route path={'/oam_video_upload'} exact component={OAMVideoCreate} />
        <Route
          path={'/oam_video_upload-success'}
          exact
          component={OAMVideoUploadSuccess}
        />

        <Route path={'/oam_exam_list'} exact component={OAMExamsList} />
        <Route path={'/oam_exam_edit'} exact component={OAMExamEdit} />
        <Route path={'/oam_exam_preview'} exact component={OAMExamPreview} />

        <Route path={'/oam_test_list'} exact component={OAMTestList} />
        <Route path={'/oam_test_edit'} exact component={OAMTestEdit} />
        <Route path={'/oam_test_preview'} exact component={OAMTestPreview} />

        <Route path={'/oam_case_list'} exact component={OAMCaseList} />

        <Route path={'/oam_exercise_list'} exact component={OAMExerciseList} />
        <Route path={'/oam_exercise_edit'} exact component={OAMExerciseEdit} />
        <Route
          path={'/oam_preview_exercise'}
          exact
          component={OAMExercisePreview}
        />

        <Route path={'/oam_lecture_list'} exact component={OAMLectureList} />
        <Route path={'/oam_lecture_edit'} exact component={OAMLectureEdit} />

        <Route
          path={'/oam_lecture_banner_list'}
          exact
          component={OAMLectureBannerList}
        />
        <Route
          path={'/oam_lecture_banner_edit'}
          exact
          component={OAMLectureBannerEdit}
        />

        <Route path={'/oam_menu_list'} exact component={OAMMenuList} />
        <Route path={'/oam_menu_detail'} exact component={OAMMenuDetail} />
        <Route path={'/oam_menu_edit'} exact component={OAMMenuEdit} />

        <Route path={'/oam'} exact component={OAMUserStat} />

        <Route path={'/'} exact component={Index} />

        {isFeedbackDis && ENV.REACT_APP_FEED_BACK == 'true' && <Feedback />}
        {isFootDis ? <PageFooter></PageFooter> : ''}
      </div>
      {/*<header className="App-header">*/}
      {/*  <img src={logo} className="App-logo" alt="logo" />*/}
      {/*  <p>*/}
      {/*    Edit <code>src/App.tsx</code> and save to reload.*/}
      {/*  </p>*/}
      {/*  <a*/}
      {/*    className="App-link"*/}
      {/*    href="https://reactjs.org"*/}
      {/*    target="_blank"*/}
      {/*    rel="noopener noreferrer"*/}
      {/*  >*/}
      {/*    Learn React*/}
      {/*  </a>*/}
      {/*</header>*/}
    </div>
  )
}

export default App
