import React, {useEffect, useState,useRef} from "react";
import {Tooltip,Popconfirm,Table, Col, Row, ConfigProvider, Button, Checkbox, message, Modal} from 'antd';
import { sendHttpPostRequest } from "../../../api/http-request";
import zhCN from 'antd/lib/locale/zh_CN'

import {checkUserLogin} from "../../common/user-center/user-info";
import { EyeOutlined,EditOutlined,FileTextOutlined,DeleteOutlined } from '@ant-design/icons';

import courseMainStyles from "../course-main.module.less";
import courseExamListStyles from "./course-main-teacher-exam-list.module.less";
import { renderIntoDocument } from "react-dom/test-utils";
import kaoshiEmpty from "../../../static/image/empty/queshengye-kaoshi.png";
import Cookies from "js-cookie";
import moment from 'moment';
import Draggable from "react-draggable";

export interface Props {
  course_id : any,
  college_id: any,
  enable_exam: any,
  menu_id : any
}

const dateFormat = 'YYYY-MM-DD HH:mm:ss';

const statusMap = new Map([[1, '未发布'], [2, '已发布']]);
/** 教师课程考试列表页面 */
export const CourseMainTeacherExamList: React.FC<Props> = (props) => {
    const {course_id,college_id,enable_exam,menu_id} = props;

    const [examList, setExamList] = useState([] as any[]);
    const [isDeleteVisible, setIsDeleteVisible] = useState(false);
    const [isDeleteCheckedVisible, setIsDeleteCheckedVisible] = useState(false);
    // 点击删除按钮
    const onDeleteBtnClick = (examName:string, examId:number) => {

      deleteExam(examId);
    }


    useEffect(()=> {
      queryExamList();
    },[])


  const queryExamList = () => {
    if (!checkUserLogin()) {
        document.getElementById("show-login-modal")!.click();
    }
    
    sendHttpPostRequest(
      'exam/api/user_query_exam_list/',
      {
          sessionid: Cookies.get("sessionid"),
          course_id: course_id,
          college_id: college_id
      },
      queryExamListCallback
  )
  
}

    const [disabled,setDisabled] = useState(true)
    const [bounds,setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const draggleRef = useRef<HTMLDivElement | null>(null)
    const onStart = (event:any, uiData:any) => {
      const { clientWidth, clientHeight } = window?.document?.documentElement;
      const targetRect= draggleRef?.current?.getBoundingClientRect();
      if(targetRect){
        setBounds( {
          left: -targetRect?.left + uiData?.x,
          right: clientWidth - (targetRect?.right - uiData?.x),
          top: -targetRect?.top + uiData?.y,
          bottom: clientHeight - (targetRect?.bottom - uiData?.y),
        }
      )
      }
    }

    /**
     * 创建考试回调
     * @param response
     */
     const queryExamListCallback = (response:any) => {
      message.destroy();
      if (response.code == -100) {
          // 没登录
          window.location.href = '/login?next=course-main-teacher?course_id=' + course_id + "&tabKey=5";
      } else if (response.code == -501) {
          // 没权限
          window.location.href = '/login?next=course-main-teacher?course_id=' + course_id + "&tabKey=5";
      } else if (response.is_ok == true) {
          setExamList(response.query_list);
          //console.log(response.query_list);
      }
  }

  /** 删除考试 */
  const deleteExam = (examId:any) => {
    if (!checkUserLogin()) {
        document.getElementById("show-login-modal")!.click();
    }
    
    sendHttpPostRequest(
      'exam/api/user_delete_exam/',
      {
          sessionid: Cookies.get("sessionid"),
          exam_id: examId,
      },
      deleteExamCallback
  )
  
}

    /**
     * 删除考试回调
     * @param response
     */
     const deleteExamCallback = (response:any) => {
      message.destroy();
      if (response.code == -100) {
          // 没登录
          window.location.href = '/login?next=course-main-teacher?course_id=' + course_id + "&tabKey=5";
      } else if (response.code == -501) {
          // 没权限
          window.location.href = '/login?next=course-main-teacher?course_id=' + course_id + "&tabKey=5";
      } else if (response.is_ok == true) {
          //message.success("删除考试成功~");
          queryExamList();
      }else{
        message.error("删除考试失败~");
      }
  }



    const onPreviewExamClick = (exam_id:string, event:any) => {
          if (!checkUserLogin()) {
              document.getElementById("show-login-modal")!.click();
          }
          window.open('/course-teacher-exam-preview?exam_id=' + exam_id + "&course_id=" + course_id + "&menu_id=" + menu_id)
      }

    const onEditExamClick = (exam_id:string, event:any) => {
        if (!checkUserLogin()) {
            document.getElementById("show-login-modal")!.click();
        }
        
        window.open('/course-teacher-exam-edit?exam_id=' + exam_id + "&course_id=" + course_id + "&menu_id=" + menu_id)
    }
      

    const renderStatus = (text: any, record: any) => {
      const currentTime = moment().utc().zone(-8);
      const status = record.status;//1未发布；2已发布
      const publish_score = record.publish_score;//1未公布；2已公布
      const start = moment(record.start_time).utc().zone(-8);
      const end = moment(record.end_time).utc().zone(-8);
      if(status == 1){//未发布
        return <div>{"未发布"}</div>
      }else if(status == 2 && currentTime < start){
        return <div>{"未开考"}</div>
      }else if(status == 2 && currentTime > start && currentTime < end){
        return <div>{"考试中"}</div>
      }else if(status == 2 && currentTime > end && publish_score == 1){
        return <div>{"阅卷中"}</div>
      }else if(status == 2 && currentTime > end && publish_score == 2){
        return <div>{"已完成"}</div>
      }
     
    }
      
    
    // 渲染操作
    const renderActions = (text:any, record:any) => {
      
      const status = record.status;//1未发布；2已发布
        return <Row align='middle' justify='center' gutter={[16,8]}> 

              <Col>  <EyeOutlined  value={record.id} onClick={onPreviewExamClick.bind(this,record.id)}  className={courseExamListStyles.actionBtn} /> </Col>

                  <Col> 
                    <Popconfirm placement="top" title={(record.status==2 ? "考试已发布," : "") + '确认删除考试"' + record.name + '"吗？'} onConfirm={onDeleteBtnClick.bind(this, record.name,record.id)} okText="确认" cancelText="取消">
                    <DeleteOutlined  className={courseExamListStyles.actionBtn}/>
                    </Popconfirm>
                  </Col>                 
        </Row>
    }

    const renderName = (text:any, record:any) => {
        if (text.length > 50) {
          return <>
            <a className={courseExamListStyles.slideName} href={'/course-teacher-exam-preview?exam_id=' + record.id + "&course_id=" + course_id + "&menu_id=" + menu_id} target="_blank">{record.name.slice(0,50) + '...'}</a>
          </>
        } else {
          return <>
            <a  className={courseExamListStyles.slideName}  href={'/course-teacher-exam-preview?exam_id=' + record.id + "&course_id=" + course_id + "&menu_id=" + menu_id} target="_blank">{record.name}</a>
          </>
        }
      }
    const examCols :any= [
     {
        title:'序号',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: '9%',
        render:(text,record,index)=>(examList.indexOf(record) + 1)
      },
      {
        title: '考试',
        dataIndex: 'name',
        key: 'name',
        align: 'left',
        width: '20%',
        render:renderName,
      },
      {
        title: '状态',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        width: '10%',
        render: renderStatus,

      },
      {
        title: '开始时间',
        dataIndex: 'start_time',
        key: 'start_time',
        align: 'center',
        width: '20%',
        render: (text: any, record: any) => (
          <div>{text?.slice(0,16)}</div>
        )
      },
      {
        title: '结束时间',
        dataIndex: 'end_time',
        key: 'end_time',
        align: 'center',
        width: '20%',
        render: (text: any, record: any) => (
          <div>{text?.slice(0,16)}</div>
        )
      },
      {
        title: "操作",
        dataIndex: "actions",
        key: "actions",
        width: '15%',
        ellipsis: false,
        align: 'center',
        //fixed: 'right',
        render: renderActions,
    },
    ]




  const onNewExamClick = () => {
    if (!checkUserLogin()) {
        document.getElementById("show-login-modal")!.click();
    }
    
    //1.获取college_id, course_id,
    //2.调用新建开始接口获取，exam_id
    //3.成功创建跳转到新建考试页面。
    //window.open('/course-new-exam');

    sendHttpPostRequest(
      'exam/api/user_create_exam/',
      {
          sessionid: Cookies.get("sessionid"),
          course_id: course_id,
          college_id: college_id
      },
      newExamCallback
  )
  
}

    /**
     * 创建考试回调
     * @param response
     */
     const newExamCallback = (response:any) => {
      message.destroy();
      if (response.code == -100) {
          // 没登录
          window.location.href = '/login?next=course-main-teacher?course_id=' + course_id;
      } else if (response.code == -501) {
          // 没权限
          window.location.href = '/login?next=course-main-teacher?course_id=' + course_id;
      } else if (response.is_ok == true) {
          if (response.exam_id) {
              window.open(`/course-new-exam?exam_id=${response.exam_id}&course_id=${course_id}&menu_id=${menu_id}`);
          } else {
              message.error(response.message);
          }
      }
  }

 


    return (
        <div className={courseMainStyles.tabpaneWrapper}>
          
            <Row align="middle" style={{marginTop:-10,marginBottom:4}}>
                <Col span={12} style={{textAlign:"left"}}></Col>
                { enable_exam && <Col span={12} style={{textAlign:"right"}}><Button onClick={onNewExamClick} style={{marginBottom:10,width:100,borderRadius:20}} type='primary'> + 新建考试 </Button></Col>}
            </Row>
            <div className={courseExamListStyles.tableWrapper}>
            {examList.length!=0 && 
                <ConfigProvider locale={zhCN}>
                    <Table
                        dataSource={examList}
                        columns={examCols}
                        size='small'
                        rowKey={record => record.id}
                        pagination={{position:["bottomCenter"], pageSize:10, size:"default",hideOnSinglePage:true}}
                        //rowSelection={rowSelection}
                        //onChange={onTableChange}
                    />
                </ConfigProvider>
            }
            
          
              {examList.length==0 &&
                <div style={{textAlign:'center',paddingTop:20}}>
                    <img src={kaoshiEmpty} width={120} height={120}/>
                    <div style={{color:'#999',marginTop:10}}>您还没有创建考试哟~</div>
                </div>
              }
            </div>
        </div>
    )

}

export default CourseMainTeacherExamList

