import React, {useEffect, useRef, useState} from "react";
import {Statistic,Steps,Breadcrumb,Input,Table,Layout, message, Col, Row,Image, ConfigProvider, Pagination, Tabs, Button, Tag, DatePicker, Tooltip, Modal, Popconfirm, Divider, Upload, Popover } from 'antd';
import { PageHeader } from "../../common/page-header/header";
import { sendHttpPostRequest } from "../../../api/http-request";
import testPreviewStyles from "./course-teacher-test-preview.module.less";
import zhCN from 'antd/lib/locale/zh_CN'
import LoginRegisterModal from "../../../component/modal/login-register/login-register";
import {checkUserLogin} from "../../common/user-center/user-info";
import { DeleteOutlined,FilePdfOutlined,DownloadOutlined,FundProjectionScreenOutlined,ExportOutlined,PieChartOutlined,FileWordOutlined,TableOutlined,ClockCircleOutlined,EyeOutlined,FileTextOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';
import ExerciseCard from "../../exercise/exercise-card";
import { BACKEND_API_URL, BACKEND_URL } from '../../../constants/constants';

import moment from 'moment';

import 'echarts-wordcloud';

import * as echarts from 'echarts/core';
import {
  TitleComponent,
  TitleComponentOption,
  TooltipComponent,
  TooltipComponentOption,
  LegendComponent,
  LegendComponentOption
} from 'echarts/components';
import { PieChart, PieSeriesOption } from 'echarts/charts';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

import formatIcon from '../../../static/image/formatIcon.png';
import Draggable from "react-draggable";
import PageFooter from "../../common/page-footer/footer";
import img1 from "../../../static/image/course/计算机视觉.jpg";
import img2 from "../../../static/image/course/深度学习.jpg";
import img3 from "../../../static/image/course/数据科学导论.jpg";
import img4 from "../../../static/image/course/数据可视化.jpg";
import * as constants from "../../../constants/constants";

echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer,
  LabelLayout
]);

const { Countdown } = Statistic;//倒计时控件

export interface Props {
  test: any;
  exerciseList: any;
}

const typeMap = new Map([[1, '实操题'], [2, '单选题'], [3, '多选题'],[4,'排序题'],[5,'主观题']]);
const difficultyMap = new Map([[1, '容易'], [2, '中等'], [3, '困难']]);
   
export interface Props {}
/** 学生查看作业详情页面 */
export const CourseStudentTestPreview: React.FC<Props> = (props) => {
  const homeworkId = parseInt(window.location.search.split("&")[0].split("=")[1]);//作业ID
  const courseId = parseInt(window.location.search.split("&")[1].split("=")[1]);//课程
  const [homework, setHomework] = useState(null as any);  // 作业
  const [homeworkName, setHomeworkName] = useState('');  // 作业名称
  const [homeworkDescription, setHomeworkDescription] = useState('');  // 作业说明
  const [homeworkExercises,setHomeworkExercises] = useState([] as any[]);

  const [submitTime, setSubmitTime] = useState(null as any);  // 作业提交时间
  const [deadline, setDeadline] = useState(null as any);  // 作业最晚时间
  const [allowDelay, setAllowDelay] = useState(null as any);  // 接受作业延迟提交

  const nowTime = moment().format('YYY-MM-DD HH:mm:ss').slice(2);



    const exerciseId = useRef(0);  // 题目id
    const [exerciseIdList, setExerciseIdList] = useState([] as any []) // 题目id列表


    const [exerciseAnswerPublished, setExerciseAnswerPublished] = useState(null as any);//老师是否公布了考试答案

    const [exerciseScores, setExerciseScores] = useState(null as any);//学生考题得分

    const [exerciseResult, setExerciseResult] = useState(null as any); 
    const [selecetedExerciseIndex, setSelecetedExerciseIndex] = useState(0);//默认选中第一题
    const [completeStatus, setCompleteStatus] = useState([] as any []);
    const [rightStatus, setRightStatus] = useState([] as any []);  // 做对的列表
    const [wrongStatus, setWrongStatus] = useState([] as any []);  // 做对的列表
    const [myScore, setMyScore] = useState(null as any);//我的考试分数
    const [totalScore, setTotalScore] = useState(null as any);//考试总分

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isSuccessVisible, setIsSuccessVisible] = useState(false);
    const [isFinishedVisible, setIsFinishedVisible] = useState(false);


    const [disabled,setDisabled] = useState(true)
    const [bounds,setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const draggleRef = useRef<HTMLDivElement | null>(null)
    const onStart = (event:any, uiData:any) => {
      const { clientWidth, clientHeight } = window?.document?.documentElement;
      const targetRect= draggleRef?.current?.getBoundingClientRect();
      if(targetRect){
        setBounds( {
          left: -targetRect?.left + uiData?.x,
          right: clientWidth - (targetRect?.right - uiData?.x),
          top: -targetRect?.top + uiData?.y,
          bottom: clientHeight - (targetRect?.bottom - uiData?.y),
        }
      )
      }
    }

    // const imgs = [
    //   {
    //     id:1,
    //     src: img1,
    //   },{
    //     id:2,
    //     src:img2,
    //   },{
    //     id:3,
    //     src:img3,
    //   },{
    //     id:4,
    //     src:img4
    //   }
    // ]
    const [imgs, setImgs] = useState([] as any []);
    const [ppt, setPpt] = useState([] as any []);
    const [isUploading, setIsUploading] = useState(false);  // 是否正在上传文件
    // const ppt =[{name:'大数据与人工智能.pdf',size:'30M'},{name:'数据可视化.pdf',size:'20M'}]

    useEffect(()=>{
      queryHomework();
    },[])


    // 基本习题框架
    const baseExerciseInfo:any = {
      answer_parse: "",
      create_time: "",
      creator: "",
      creator_id: 1,
      dataset_path: "",
      datasets: [],
      default_code: "",
      difficulty: 1,
      difficulty_str: "",
      exam_id: 0,
      hint_submit_btn: 1,
      id: 1,
      images: [],
      is_delete: 1,
      judgement_code: "",
      language: 2,
      language_str: "python3",
      options: [{id: "1", options: "1"}, {id: "2", options: "2"}, {id: "3", options: "3"}, {id: "4", options: "4"}],
      options_answer: [],
      preset_code: "",
      reference_answer: "",
      remarks: "",
      score: 0,
      source: 1,
      source_key: 3,
      source_page: 3,
      status: 1,
      steps: "",
      submission_info: {
          is_html: false,
          submission_answer: [],
          submission_code: "",
          submission_id: 0,
          submission_image: [],
          submission_output: "",
          submission_status: 3,
          submission_time: "",
          submission_variables: []
      },
      title: "",
      type: 8,
      type_name: "",
      update_time: ""
  }


        // 查询作业回调
        const queryHomeworkCallback = (response:any) => {
          message.destroy();
          if (response.code == -100) {
              // 未登录
              document.getElementById("show-login-modal")!.click();
          } else if (response.is_ok == true) {
              // 成功
              setHomework(response.query_info);
              setHomeworkName(response.query_info.name);
              setHomeworkDescription(response.query_info.description);
              setHomeworkExercises(response.query_info.exercise);
  
              response.query_info.submit_time.length > 0 ? setSubmitTime(response.query_info.submit_time) : '';
              response.query_info.deadline.length > 0 ? setDeadline(response.query_info.deadline) : '';
              response.query_info.allow_delay ? setAllowDelay(response.query_info.allow_delay) : '';


              setExerciseAnswerPublished(response.query_info.publish_answer == 2 ? true : false);

              if(response.query_info.status == '已完成') {//作业截至时间已到
                  queryHomeworkExerciseScore();
              }

              let _exerciseIdList = [] as any [];
              response.query_info?.exercise?.map(item => {
                  _exerciseIdList.push(item.id);
              })
              setExerciseIdList(_exerciseIdList);

              if (response.query_info?.exercise.length > 0) {
                  exerciseId.current = response.query_info?.exercise[0]["id"];
                  queryExerciseById(response.query_info.status, response.query_info.publish_answer == 2 ? true : false);
                  queryHomeworkSubmission();
              }

              //refreshExamStatus(response.query_info.status, response.query_info.publish_score,response.query_info.start_time,response.query_info.end_time,response.query_info.submit_exam);


          } else if (response.code == -501) {
              // 权限不足
              message.error("权限不足")
          } else {
              message.error(response.message);
          }
      }
  
      // 查询作业信息列表
      const queryHomework = () => {
          sendHttpPostRequest(
              'homework/api/user_query_homework_by_id/',
              {
                  sessionid: Cookies.get("sessionid"),
                  homework_id: homeworkId,
                  course_id: courseId
              },
              queryHomeworkCallback
          )
      }

          // 根据id查询习题得分,只有主观题才需要额外查询
          const queryHomeworkExerciseScore = () => {
            sendHttpPostRequest(
                'homework/api/user_query_homework_score',
                {
                    sessionid: Cookies.get("sessionid"),
                    sid: Cookies.get("sessionid"),
                    homework_id: homeworkId,
                },
                (response: any) => {
                    if (response.code == -100) {
                        // 未登录
                        document.getElementById("show-login-modal")!.click();
                    } else if (response.is_ok) { 
                      setExerciseScores(response.query_info.exercise_list);
                      console.log(response.query_info.exercise_list);
                    } else {
                        console.error(response.message);
                    }
                }
            )
        }
        /**
     * 查询习题
     */
         const queryExerciseById = (homeworkStatus,showAnswer) => {
          setExerciseResult(baseExerciseInfo);
          sendHttpPostRequest('exercise/api/query_exercise_by_id/',
              {
                  sessionid: Cookies.get("sessionid"),
                  sid: Cookies.get("sessionid"),
                  exercise_id: exerciseId.current,
                  source_page: 3,  // 作业3
                  source_key: 3,   // 学生作业3
                  homework_id: homeworkId,   // 作业id
                  hint_submit_btn: homeworkStatus == "进行中" ? 1 : 2,   // 隐藏提交按钮
                  show_answer: showAnswer == true ? 1 : 0,//返回答案信息,
              },
              (response: any) => {
                  message.destroy();
                  if (response.code == -100) {
                      // 未登录
                      document.getElementById("show-login-modal")!.click();
                  } else if (response.is_ok) {
                      console.log(response)
                      setExerciseResult(response.exercise_info);
                      console.log(exerciseResult);
                      queryHomeworkSubmission();
                      if (response.exercise_info.type == 5) {
                          // 主观题 查询 学生上传的附件（图片）列表
                          queryStudentAttachmentList(response.exercise_info.id);
                      }
                  } else {
                      message.error(response.message);
                  }
              }
          )
      }

      // 查询 主观题 学生 上传的 附件（图片） 列表
        const queryStudentAttachmentList = (exerciseId: number) => {
             sendHttpPostRequest(
                 'homework/api/user_query_student_homework_attachment',
                 {
                     sessionid: Cookies.get("sessionid"),
                     sid: Cookies.get("sessionid"),
                     homework_id: homeworkId,
                     exercise_id: exerciseId,
                 },
                 (response: any) => {
                     if (response.is_ok) {
                         setImgs(response.image_list);
                         setPpt(response.attachment_list);
                         setCurrentExerciseId(response.exercise_id);
                     }
                 }
             )
        }

        const [currentExerciseId, setCurrentExerciseId] = useState(0);  // 上传附件时的题目ID
        const onUploadBtnClick = (exerciseId:number) => {
            setCurrentExerciseId(exerciseId);
        }

    // 上传文件传参
    const uploadData = {
        sessionid: Cookies.get("sessionid"),
        sid: Cookies.get("sessionid"),
        homework_id: homeworkId,
        exercise_id: currentExerciseId,
    }

    // 上传图片文件
    const uploadImageProps = {
        accept: ".png,.jpeg,.jpg,.gif",
        name: 'data_file',
        multiple: false,
        maxCount:1,
        showUploadList: isUploading == false ? false:
            { showPreviewIcon: false, showRemoveIcon: false, showDownloadIcon: false,previewIcon: false},
        data: uploadData,
        progress: {strokeColor:'#07c160',strokeWidth:2,showInfo:false},
        action: constants.BACKEND_API_URL + 'homework/api/user_upload_exercise_image/',
        beforeUpload: file => {
            if (file.size > ((1024 * 1024 * 1024) / 100) * 3) {
                message.error("请上传小于30M的文件");
                return false;
            }
        },
        onChange(info) {
            const { status } = info.file;
            if (status == 'uploading') {
                setIsUploading(true);
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                setIsUploading(false);
                message.destroy();
                if (info.file.response.is_ok) {
                    message.success(`文件${info.file.name}上传成功！`);
                    if (info.file.response.file_repeat) {
                        // 传过，修改原来的

                    } else {
                        // 未传过，append
                        const newImageList = [...imgs];
                        newImageList.push({id:info.file.response.id, name: info.file.name, url: info.file.response.url, size: info.file.response.file_size});
                        setImgs(newImageList);
                    }
                } else {
                    if (info.file.response.code == -100) {
                        // 未登录
                        document.getElementById("show-login-modal")!.click();
                    } else if (info.file.response.code == -501) {
                        // 权限不足
                    } else {
                        message.error(info.file.response.message);
                    }
                }
            } else if (status === 'error') {
                message.error(`文件${info.file.name}上传失败了:(`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    // 上传附件
    const uploadAttachmentProps = {
        accept: ".pptx,.pdf,.docx",
        name: 'data_file',
        multiple: false,
        maxCount:1,
        showUploadList: isUploading == false ? false:
            { showPreviewIcon: false, showRemoveIcon: false, showDownloadIcon: false,previewIcon: false},
        data: uploadData,
        progress: {strokeColor:'#07c160',strokeWidth:2,showInfo:false},
        action: constants.BACKEND_API_URL + 'homework/api/user_upload_exercise_attachment/',
        beforeUpload: file => {
            if (file.size > ((1024 * 1024 * 1024) / 100) * 5) {
                message.error("请上传小于50M的文件");
                return false;
            }
        },
        onChange(info) {
            const { status } = info.file;
            if (status == 'uploading') {
                setIsUploading(true);
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                setIsUploading(false);
                message.destroy();
                if (info.file.response.is_ok) {
                    message.success(`文件${info.file.name}上传成功！`);
                    if (info.file.response.file_repeat) {
                        // 传过，修改原来的

                    } else {
                        // 未传过，append
                        const newPptList = [...ppt];
                        newPptList.push({id:info.file.response.id, name: info.file.name, url: info.file.response.url, size: info.file.response.file_size});
                        setPpt(newPptList);
                    }
                } else {
                    if (info.file.response.code == -100) {
                        // 未登录
                        document.getElementById("show-login-modal")!.click();
                    } else if (info.file.response.code == -501) {
                        // 权限不足
                    } else {
                        message.error(info.file.response.message);
                    }
                }
            } else if (status === 'error') {
                message.error(`文件${info.file.name}上传失败了:(`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

        // 删除附件
        const deleteAttachment = (attachmentId: any, attachmentName: any) => {
            // console.log(attachmentId, attachmentName);
            sendHttpPostRequest(
                'homework/api/user_delete_exercise_attachment',
                {
                    sessionid: Cookies.get("sessionid"),
                    sid: Cookies.get("sessionid"),
                    homework_id: homeworkId,
                    exercise_id: currentExerciseId,
                    file_id: attachmentId,
                    file_name: attachmentName,
                },
                (response: any) => {
                    if (response.is_ok) {
                        message.destroy();
                        message.success(`文件${attachmentName}删除成功`);
                        setPpt(response.attachment_list);
                    }
                }
            )
        }

    // 删除图片
    const deleteImage = (imageId: any, imageName: any) => {
        // console.log(imageId, imageName);
        sendHttpPostRequest(
            'homework/api/user_delete_exercise_image',
            {
                sessionid: Cookies.get("sessionid"),
                sid: Cookies.get("sessionid"),
                homework_id: homeworkId,
                exercise_id: currentExerciseId,
                file_id: imageId,
                file_name: imageName,
            },
            (response: any) => {
                if (response.is_ok) {
                    message.destroy();
                    message.success(`图片${imageName}删除成功`);
                    setImgs(response.image_list);
                }
            }
        )
    }


    // 查询作业提交情况
        const queryHomeworkSubmission = () => {
          sendHttpPostRequest(
              'homework/api/user_query_self_homework_submission/',
              {
                  sessionid: Cookies.get("sessionid"),
                  homework_id: homeworkId,
                  course_id: courseId
              },
              (response: any) => {
                  message.destroy();
                  if (response.code == -100) {
                      // 未登录
                      document.getElementById("show-login-modal")!.click();
                  } else if (response.is_ok) {
                      setCompleteStatus(response.query_list);
                      let _rightStatus = [] as any [];
                      let _wrongStatus = [] as any [];
                      response.query_list?.map(item => {
                          [1, 3].includes(item) ? _wrongStatus.push(item) : _rightStatus.push(item);
                      })
                      setRightStatus(_rightStatus);
                      setWrongStatus(_wrongStatus);

                      setMyScore(response.person_score);
                      setTotalScore(response.total_score);
                  } else {
                      message.error(response.message);
                  }
              }
          )
      }
    /** 计算作业总分 */
    const getTotalScore = ()=>{
      let sumScore = 0;
      homeworkExercises.map(item=>{
          if (parseInt(item.score) == 0)
              sumScore += 5;
          else
              sumScore += parseInt(item.score);
      })
  
      return sumScore
    }

    const isFinishedOk = () => {  
      setIsFinishedVisible(false);
      setIsModalVisible(true);
      setIsSuccessVisible(false);
    };
  
    const isFinishedCancel = () => {
      setIsFinishedVisible(false);
    };
  

    const handleOk = () => {
      try {
          sendHttpPostRequest(
              'homework/api/user_submit_homework/',
              {
                  sessionid: Cookies.get("sessionid"),
                  sid: Cookies.get("sessionid"),
                  homework_id: homeworkId,
                  type: 1
              },
              (response: any) => {
                  console.error(response.message);
              }
          )
      } catch (e) {
          console.error(e);
      }
      setIsFinishedVisible(false);
      setIsModalVisible(false);
      setIsSuccessVisible(true);
      setTimeout(function() {
        setIsSuccessVisible(false);
        // window.location.href=`/course-student-test-preview?homework_id=${homeworkId}&course_id=${courseId}`;
        window.location.href=`course-main-student?course_id=${courseId}&tabKey=4`;

      },2000)
    };

    const handleCancel = () => {
      setIsFinishedVisible(false);
      setIsModalVisible(false);
    };


    //未完成习题
    const notFinished= completeStatus.filter((value)=> {
      return value==1;
    });

    /** 答题状态和颜色的映射 */
    const getColorByStatus = (status:number)=>{
      if (status == 1) {
        return "#eee"
      }else {
        return "#38CD7F"
      }
    }
      /** 答题正误状态和颜色的映射 */
      const getColorBySuccess = (status:number, type: number)=>{
        if (status == 1) {//未做
          return "#fff"
        }else {
          if(type == 5 ){//做了，是主观题
            return "#47B1DF"
          }else {
              if (status == 2){ //做了，做对了
                return "#38CD7F"
              }else {
                return "#FF6F73" //做了，做错了
              }
          }
        }
      }

    /** 答题状态和题号颜色的映射 */
    const getFontColorByStatus = (status:number)=>{
      if (status == 1) {
        return "#666"
      }else{
        return "#fff"
      }
    }

    /** 选中题号和答题状态与题号颜色的映射 */
    const  getBorderByStatus = (index: number,selecetedExerciseIndex: number,status: number)=> {
      if(index == selecetedExerciseIndex && status==1) {
          return "1px solid #38CD7F"
      }
      else 
          return 0;
    }

    const  getLineHeight = (index: number,selecetedExerciseIndex: number,status: number)=> {
      if(index == selecetedExerciseIndex && status==1) {
          return "26px"
      }
      else 
          return "30px";
    }

    const  getOpacity = (index: number,selecetedExerciseIndex: number,status: number)=> {
      if(index != selecetedExerciseIndex && status!=1) {
          return 0.75
      }
      else 
          return 1;
    }



    const typeCountMap = new Map([['实操题', 0], ['单选题', 0], ['多选题', 0],['排序题',0],['主观题', 0]]);
    const difficultyCountMap = new Map([['容易', 0], ['中等', 0], ['困难', 0]]);
  
    homeworkExercises.map(item => {
      const typeCount = Number(typeCountMap.get(typeMap.get(item.type) as string));
      typeCountMap.set(typeMap.get(item.type) as string, typeCount + 1);
  
  
      const diffCount = Number(difficultyCountMap.get(difficultyMap.get(item.difficulty) as string));
      difficultyCountMap.set(difficultyMap.get(item.difficulty) as string, diffCount + 1);
  
    })

  /** 点击了下一题按钮 */
  const onNextExerciseBtnClick = () => {
    if (homework?.status == "进行中") {
        try {
            // 先提交再切题
            document.getElementById(`exercise-run-${exerciseId.current}`)!.click();
        } catch (e) {
            console.error(e);
        }
    }
  selecetedExerciseIndex < exerciseIdList?.length - 1 ?setSelecetedExerciseIndex(selecetedExerciseIndex + 1):setSelecetedExerciseIndex(0);
  selecetedExerciseIndex < exerciseIdList?.length - 1 ? exerciseId.current = exerciseIdList[selecetedExerciseIndex + 1] : exerciseId.current = exerciseIdList[0];
  queryExerciseById(homework?.status, exerciseAnswerPublished);
  }
  /** 点击了上一题按钮 */
  const onPrevExerciseBtnClick = () => {
    if (homework?.status == "进行中") {
        try {
            // 先提交再切题
            document.getElementById(`exercise-run-${exerciseId.current}`)!.click();
        } catch (e) {
            console.error(e);
        }
    }
    selecetedExerciseIndex < exerciseIdList?.length ?setSelecetedExerciseIndex(selecetedExerciseIndex - 1):setSelecetedExerciseIndex(exerciseIdList?.length - 1);
  selecetedExerciseIndex < exerciseIdList?.length ? exerciseId.current = exerciseIdList[selecetedExerciseIndex - 1] : exerciseId.current = exerciseIdList[exerciseIdList?.length - 1];
  queryExerciseById(homework?.status, exerciseAnswerPublished);
  }
    /** 点击了习题方块按钮 */
    const onExerciseBtnClick = (index) => {
      if (homework?.status == "进行中") {
        try {
            // 先提交再切题
            document.getElementById(`exercise-run-${exerciseId.current}`)!.click();
        } catch (e) {
            console.error(e);
        }
      }
    setSelecetedExerciseIndex(index);
      exerciseId.current = exerciseIdList[index];
      queryExerciseById(homework?.status,exerciseAnswerPublished);
    }
    /** 点击了提交按钮 */
    const submitPractice =() => {
      console.log(notFinished);
      try {
          // 先提交再算
          document.getElementById(`exercise-run-${exerciseId.current}`)!.click();
      } catch (e) {
          console.error(e);
      }
      

      setTimeout(function() {
        queryHomeworkSubmission();
        if (notFinished.length !==  0) {
            setIsFinishedVisible(true);
            setIsModalVisible(false);
        } else {
            setIsModalVisible(true);
            setIsFinishedVisible(false);
        }
    }, 2000);
      
    
    }

    return (
        <Layout style={{minHeight: 750,backgroundColor: '#f9f9f9',position:'relative'}}>
          <LoginRegisterModal></LoginRegisterModal>

            { homework?.status== '进行中' && 
            <Row style={{margin:'0 auto',flexWrap:'nowrap',overflow:'visible',maxWidth:'100%'}}>
              <Col style={{width:250,marginRight:20,float:'left',backgroundColor:'#fff',minHeight:580,marginTop:40}}>
                <div style={{height:60,lineHeight:'60px',fontSize:16,color:'#333'}}>答题卡</div>
                <Row style={{padding:'0 20px',fontSize:12}}>
                    <Col span={4}>题数<span style={{color:'#07c160',margin:4}}> {homeworkExercises?.length} </span></Col>
                    <Col span={4}>实操<span style={{color:'#07c160',margin:4}}>{typeCountMap.get("实操题")}</span></Col>
                    <Col span={4}>单选<span style={{color:'#07c160',margin:4}}> {typeCountMap.get("单选题")} </span></Col>
                    <Col span={4}>多选<span style={{color:'#07c160',margin:4}}> {typeCountMap.get("多选题")} </span></Col>
                    <Col span={4}>排序<span style={{color:'#07c160',margin:4}}> {typeCountMap.get("排序题")} </span></Col>
                    <Col span={4}>主观<span style={{color:'#07c160',margin:4}}> {typeCountMap.get("主观题")}  </span></Col>
                </Row>
                <Divider style={{marginTop:10}}/>
                <Row align='middle' justify= {'start'} gutter={[2,2]} style={{textAlign:"left",width:"100%",padding:'0 25px'}}> 
                  { completeStatus?.map((status:any,index:number)=>(
                    <Col key={index}>
                      <div onClick={()=>{onExerciseBtnClick(index)}}
                        className={testPreviewStyles.coursePractice}
                        style={{backgroundColor:getColorByStatus(status),color:getFontColorByStatus(status),border: getBorderByStatus(index,selecetedExerciseIndex,status),lineHeight:getLineHeight(index,selecetedExerciseIndex,status),opacity:getOpacity(index,selecetedExerciseIndex,status)}}>
                        {index+1}
                      </div>
                    </Col>
                  ))      
                  }
                </Row>
              </Col>
              <Col className={testPreviewStyles.courseMain} style={{width:800,float:'left',padding:0,overflowY:'auto',height:"calc(100vh - 185px)"}}>
                <div style={{height:60,lineHeight:'60px',fontSize:20,fontWeight:700,color:'#333'}}>{homeworkName}</div>
                <div style={{position:'absolute',right:0,top:0,width:100,height:60,lineHeight:'60px'}}><a style={{color:"#333333"}} href={"/course-main-student?course_id="+ courseId + "&tabKey=4"}>返回{">"}</a></div>
                <Row style={{paddingLeft:30}}>
                  <Col style={{textAlign:'left'}} span={16}>     
                    <div>
                      截止时间:{submitTime?.slice(0,16)} 
                    </div>   
                    { allowDelay == 1 && <div>
                      延迟提交时间:{deadline?.slice(0,16)} 
                    </div>}

                    <div style={{marginTop:10}}>共 <span style={{color:'#07C160'}}>{homeworkExercises?.length}</span> 道题 总分 <span style={{marginLeft:3, marginRight:3,color:'#07C160'}}>{getTotalScore()}</span> 分</div>       
                    <div style={{fontWeight:500,textAlign:'left',marginTop:20,color:'#666'}}>作业说明:</div>
                    <div style={{marginTop:5}}>{homeworkDescription}</div>
                  </Col>                
                </Row>                 
                <Divider/>
                <Row style={{padding:'0 20px 0 10px'}} wrap={false}>
                  <Col flex="50px" style={{marginTop:3,textAlign:'right',fontWeight:600}}>{selecetedExerciseIndex+1}.</Col>
                  <Col flex="auto"><ExerciseCard key={selecetedExerciseIndex} exercise={exerciseResult} renderType="white" teacherOnly={false} editorMode={true} showAnser={false} showClean={true}/></Col>
                  { <Col flex="80px" > （{homeworkExercises[selecetedExerciseIndex]?.score}分） </Col>}
                </Row>

                {exerciseResult?.type == 5   && <div>
                    <div style={{textAlign:'right',marginRight:100,marginBottom:20}}>
                        <Popover overlayStyle={{marginTop:-5,width:300}} content={<div>请上传png,jpeg,jpg,gif等格式的图片，图片大小不得超过30M。<br/>请上传pptx,pdf,docx等格式的文件，文件大小不得超过50M。</div>} placement="bottom" title={""} trigger="hover">
                            <span style={{cursor:'pointer',marginRight:10,fontSize:12}}><img src={formatIcon} style={{width:16,height:16,marginTop:-2}} alt="" />文件格式</span>
                        </Popover>
                        <Upload {...uploadImageProps}>
                            <Button onClick={onUploadBtnClick.bind(this, exerciseResult?.id)} style={{color:'#07c160',borderColor:'#07c160',marginRight:10}}>+上传图片</Button>
                        </Upload>
                        <Upload {...uploadAttachmentProps}>
                            <Button onClick={onUploadBtnClick.bind(this, exerciseResult?.id)} style={{color:'#07c160',borderColor:'#07c160',marginRight:0}}>+上传附件</Button>
                        </Upload>
                      {/* <span style={{color:'#ccc'}}>请上传png,jpeg,jpg,gif等格式的图片，图片大小不得超过30M。</span> */}
                    </div>
                    <Row className={testPreviewStyles.imgList} style={{width:600,marginLeft:60,marginBottom:20,justifyContent:'center'}}>
                      {imgs.map(img=>{
                        return <Col key={img.id} flex="140px">
                          <div style={{position:'absolute',top:5,right:5,width:20,height:20,borderRadius:10,backgroundColor:'#fff',display:'none'}}>
                            <Popconfirm placement="top" title={'确认删除图片"' + img.name + '"吗？'} onConfirm={deleteImage.bind(this, img.id, img.name)}  okText="确认" cancelText="取消">
                              <DeleteOutlined style={{color:'#07c160'}}/>
                            </Popconfirm>
                          </div>
                          <img src={img.url?.startsWith("http") ? img.url : constants.BACKEND_URL + img.url} alt="" style={{width:140,height:90,borderRadius:4}}/>
                        </Col>
                      })
                      }
                    </Row>
                  {/* </div>
                }
                
                {exerciseResult?.type == 5   &&<div> */}
                    {/* <div style={{textAlign:'left',width:600,marginLeft:60,marginBottom:20}}>
                        <Upload {...uploadAttachmentProps}>
                            <Button onClick={onUploadBtnClick.bind(this, exerciseResult?.id)} style={{color:'#07c160',borderColor:'#07c160',marginRight:10}}>+上传附件</Button>
                        </Upload>
                      <span style={{color:'#ccc'}}>请上传pptx,pdf,docx等格式的文件，文件大小不得超过50M。</span>
                    </div> */}
                    <div style={{width:600,marginLeft:60,marginBottom:30,textAlign:'left'}}>
                      {ppt.map(PPT=> {
                        return <Row key={PPT.name} style={{backgroundColor:'#FBFBFB',paddingLeft:10,borderRadius:3,marginBottom:10}}>
                          <Col flex="auto">
                            <FilePdfOutlined style={{color:'#999',marginRight:6}}/>{PPT.name}
                            <span style={{textAlign:'center',color:'#999',fontSize:12,marginLeft:10}}>{PPT.size}</span>
                          </Col>
                          <Col flex="50px" style={{textAlign:'center'}}>
                            <Popconfirm placement="top" title={'确认删除"' + PPT.name + '"吗？'} onConfirm={deleteAttachment.bind(this, PPT.id, PPT.name)} okText="确认" cancelText="取消">
                              <DeleteOutlined style={{color:'#999'}}/>
                            </Popconfirm> 
                          </Col>
                        </Row>
                      })
                      }
                    </div>
                  </div>
                }
              </Col>
            </Row>
            }

            {homework?.status== '已完成' && 
              <Row style={{margin:'0 auto',flexWrap:'nowrap',overflow:'visible',maxWidth:'100%'}}>
                <Col style={{width:250,marginRight:20,float:'left',backgroundColor:'#fff',minHeight:580,marginTop:40}}>
                  <div style={{height:60,lineHeight:'60px',fontSize:16,color:'#333'}}>答题卡</div>
                  <Row style={{padding:'0 20px',fontSize:12}}>
                    <Col span={4}>题数<span style={{color:'#07c160',margin:4}}> {homeworkExercises?.length} </span></Col>
                    <Col span={4}>实操<span style={{color:'#07c160',margin:4}}>{typeCountMap.get("实操题")}</span></Col>
                    <Col span={4}>单选<span style={{color:'#07c160',margin:4}}> {typeCountMap.get("单选题")} </span></Col>
                    <Col span={4}>多选<span style={{color:'#07c160',margin:4}}> {typeCountMap.get("多选题")} </span></Col>
                    <Col span={4}>排序<span style={{color:'#07c160',margin:4}}> {typeCountMap.get("排序题")} </span></Col>
                    <Col span={4}>主观<span style={{color:'#07c160',margin:4}}> {typeCountMap.get("主观题")}  </span></Col>                
                  </Row>
                  <Divider style={{marginTop:10}}/>
                  <Row align='middle' justify= {'start'} gutter={[2,2]} style={{textAlign:"left",width:"100%",padding:'0 25px',marginBottom:20}}> 
                    { completeStatus?.map((status:any,index:number)=>(
                      <Col key={index}>
                        <div onClick={()=>{onExerciseBtnClick(index)}}
                          style={{backgroundColor:homework?.publish_answer == 2 ? getColorBySuccess(status,homework?.exercise[index].type) : getColorByStatus(status) ,color:getFontColorByStatus(status),border: getBorderByStatus(index,selecetedExerciseIndex,status),opacity:getOpacity(index,selecetedExerciseIndex,status),cursor:'pointer',
                          margin:4,textAlign:"center",height:30,lineHeight:'30px',minWidth:30,fontSize:16,borderRadius:2}}>
                          {index+1}
                        </div>
                      </Col>
                    ))      
                    }
                  </Row>
                  { exerciseAnswerPublished == true && <div style={{width:'100%',float:'left',textAlign:'center'}}>
                      <div style={{display:'inline-block',width:12,height:12,borderRadius:6,backgroundColor:'#07c160',marginRight:5}}/>正确 <span style={{color:'#07c160',marginRight:20}}>{completeStatus?.filter(item=>item == 2).length}</span> 
                      <div style={{display:'inline-block',width:12,height:12,borderRadius:6,backgroundColor:'#ff4b50',marginRight:5}}/>错误 <span style={{color:'#ff4b50'}}>{completeStatus?.filter(item=>item == 3).length}</span>
                  </div>
                  }
                </Col>
                <Col className={testPreviewStyles.courseMain} style={{width:800,float:'left',padding:0,overflowY:'auto',height:"calc(100vh - 120px)"}}>
                  <div style={{height:60,lineHeight:'60px',fontSize:20,fontWeight:700,color:'#333'}}>{homeworkName}</div>
                  <div style={{position:'absolute',right:0,top:0,width:100,height:60,lineHeight:'60px'}}><a style={{color:"#333333"}} href={"/course-main-student?course_id="+ courseId + "&tabKey=4"}>返回{">"}</a></div>
                  <Row style={{paddingLeft:30}}>
                  <Col style={{textAlign:'left'}} span={16}>     
                    <div>
                      截止时间:{submitTime?.slice(0,16)} 
                    </div>   
                    { allowDelay == 1 && <div>
                      延迟提交时间:{deadline?.slice(0,16)} 
                    </div>}

                    <div style={{marginTop:10}}>共 <span style={{color:'#07C160'}}>{homeworkExercises?.length}</span> 道题 总分 <span style={{marginLeft:3, marginRight:3,color:'#07C160'}}>{getTotalScore()}</span> 分</div>       
                    <div style={{fontWeight:500,textAlign:'left',marginTop:20,color:'#666'}}>作业说明:</div>
                    <div style={{marginTop:5}}>{homeworkDescription}</div>
                  </Col> 

                    <Col span={8}>
                        <div>作业成绩:<span style={{fontSize:20,color:'#07c160',margin:5}}>{myScore}</span>分</div>
                        { exerciseAnswerPublished == true && <div style={{ marginTop:40,borderRadius:20,width:120,height:36}}>答案已公布</div>}
                    </Col>
                  </Row>                 
                  <Divider/>

                  <Row style={{padding:'0 20px 0 10px'}} wrap={false}>
                        <Col flex="50px" style={{marginTop:3,textAlign:'right',fontWeight:600}}>{selecetedExerciseIndex+1}.</Col>
                        <Col flex="auto"><ExerciseCard key={selecetedExerciseIndex} exercise={exerciseResult} renderType="white" teacherOnly={false} editorMode={false} showAnser={exerciseAnswerPublished} showClean={false}/></Col>
                        { exerciseScores && <Col flex="80px" > （
                        {exerciseAnswerPublished == true && 
                            <span>{exerciseScores[selecetedExerciseIndex].score}/</span>}
                        
                        {exerciseScores[selecetedExerciseIndex].full_score}分） </Col>}
                  </Row>

                {exerciseResult?.type == 5   &&
                  <Row className={testPreviewStyles.imgList} style={{width:600,marginLeft:80,marginBottom:10}}>
                    {imgs.map(img=>{
                      return <Col key={img.id} flex="140px" className={testPreviewStyles.previewImageWrap}>
                         <Image src={img.url?.startsWith("http") ? img.url : BACKEND_URL + img.url} style={{width:140,height:90,borderRadius:4}} preview={{mask:<EyeOutlined/>}}/>
                       </Col>
                    })
                    }
                  </Row>
                }
                
                {exerciseResult?.type == 5   &&
                  <div style={{width:600,marginLeft:80,marginBottom:30}}>
                    {ppt.map(PPT=> {
                      return <Row key={PPT.name} style={{backgroundColor:'#FBFBFB',paddingLeft:10,borderRadius:3,marginBottom:10,textAlign:'left'}}>
                        <Col flex="auto">
                          {PPT.name}
                          <span style={{textAlign:'center',color:'#999',fontSize:12,marginLeft:10}}>{PPT.size}</span>
                        </Col>
                        <Col flex="50px" style={{textAlign:'center'}}><a href={PPT.url?.startsWith("http") ? PPT.url : BACKEND_URL + PPT.url} target={"_blank"}><DownloadOutlined style={{color:'#999'}}/></a></Col>
                      </Row>
                    })
                    }
                  </div>
                }
                </Col>
              </Row>
            }
            
            {homework?.status== '进行中' && <div  style={{position:'fixed',bottom:0,textAlign:"center",width:'100%',height:80,lineHeight:'80px',backgroundColor:"#fff",borderRadius:'0 0 10px 10px',boxShadow:'0px -2px 15px rgba(0, 0, 0, 0.12)'}}>
                <Button style={{marginTop:10,marginRight:40,width:100,borderRadius:5,color:'#07c160',borderColor:'#07c160'}}
                  onClick={onPrevExerciseBtnClick} disabled={selecetedExerciseIndex == 0}
                >上一题</Button>
                <Button  style={{marginTop:10,marginRight:40,width:100,borderRadius:5,color:'#07c160',borderColor:'#07c160'}}
                  onClick={onNextExerciseBtnClick}
                >下一题</Button>
                <Button type="primary" style={{marginTop:10,width:100,borderRadius:5,backgroundColor:'#07c160'}}
                  onClick={submitPractice}
                >交作业</Button>
              </div>
            }


            <Modal closable={false} width={400} title="提示" visible={isFinishedVisible} 
            bodyStyle={{borderBottom:0,textAlign:'center',height:150,paddingTop:40}}
            className={testPreviewStyles.announceModal}
            footer={
              <div style={{textAlign:'center',height:50}}>
                <Button onClick={isFinishedCancel} style={{width:80,borderRadius:5,borderColor:'#07c160',color:'#07c160',marginRight:20}}>取消</Button>
                <Button type="primary" onClick={isFinishedOk} style={{width:80,borderRadius:5,backgroundColor:'#07c160',color:'#fff'}}>确认</Button>
              </div>
            }>
              <p style={{marginTop:20}}>还有<span style={{color:'#07c160',margin:5}}>{notFinished.length}</span>题未答，你确定要交作业吗 ？</p>
            </Modal>

            <Modal closable={false} width={400} title="提示" visible={isModalVisible} 
            bodyStyle={{borderBottom:0,textAlign:'center',height:150,paddingTop:40}}
            className={testPreviewStyles.announceModal}
            footer={
              <div style={{textAlign:'center',height:50}}>
                <Button onClick={handleCancel} style={{width:80,borderRadius:5,borderColor:'#07c160',color:'#07c160',marginRight:20}}>取消</Button>
                <Button type="primary" onClick={handleOk} style={{width:80,borderRadius:5,backgroundColor:'#07c160',color:'#fff'}}>确认</Button>
              </div>
            }>
              <p>你确定要交作业吗？</p>
              <p>交作业后，在作业截止前还可以继续作答，成绩以最后一次交作业为准。</p>
            </Modal>

            <Modal closable={false} width={400} title="提示" visible={isSuccessVisible} 
            bodyStyle={{borderBottom:0,textAlign:'center',height:150,paddingTop:70}}
            className={testPreviewStyles.announceModal}
            footer={[]}>
              <p>已成功交作业！</p>
            </Modal>
       </Layout>
    )

}

export default CourseStudentTestPreview

