import React from 'react'
import { PluginComponent } from 'react-markdown-editor-lite'
import * as constants from '../../../constants/constants'
import type { UploadProps } from 'antd'
import { KeyboardEventListener } from 'react-markdown-editor-lite/cjs/share/var'



interface EnterControlInsertPluginProps {
  
}

export default class EnterControlInsertPlugin extends PluginComponent<EnterControlInsertPluginProps> {
  // 这里定义插件名称，注意不能重复
  static pluginName = 'control-enter'
  // 定义按钮被放置在哪个位置，默认为左侧，还可以放置在右侧（right）
  static align = 'left'
  // 如果需要的话，可以在这里定义默认选项
  // static defaultConfig = {
  //   start: 0,
  // }
  private handleKeyboard: KeyboardEventListener;
  constructor(props: any) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.handleKeyboard = {
      key: 'Enter',
      keyCode: 108,
      // aliasCommand: true,
      withKey: ["ctrlKey"],
      // callback: () => this.editor.insertMarkdown('enter', "\n"),
      callback: () => {
        // this.editor.insertMarkdown('enter', "\n")
        // this.editor.insertText('\n',false)
        document.getElementById("submit_chat")!.click()
      },
    };
    // this.state = {

    // }
  }

  handleClick() {
    // 调用API，往编辑器中插入一个数字
    // this.editor.insertText(":wink:");
    // 更新一下自身的state
  }

  componentDidMount() {
      
      this.editor.onKeyboard(this.handleKeyboard);
  }

  componentWillUnmount() {
    this.editor.offKeyboard(this.handleKeyboard);
  }

  render() {
    return (
      <span></span>
    )
  }
}
