import React, {useEffect, useState} from "react";
import { Input,Table,Switch,Layout, message, Card, Col, Row, ConfigProvider, Pagination, Tabs, Button, Tag } from 'antd';
import { PageHeader } from "../../common/page-header/header";
import { sendHttpPostRequest } from "../../../api/http-request";
import logoSvg from '../../static/image/idclogo.png';
import pekinglogoSvg from '../../static/image/peking.png';
import courseMainStyles from "../course-main.module.less";
import "../course.module.css";
import zhCN from 'antd/lib/locale/zh_CN'
import * as constants from '../../../constants/constants';
import PageFooter from "../../common/page-footer/footer";
import LoginRegisterModal from "../../../component/modal/login-register/login-register";
import {checkUserLogin} from "../../common/user-center/user-info";
import { DownloadOutlined } from '@ant-design/icons';


import kejianEmpty from "../../../static/image/empty/queshengye-kejian.png";
import slidesStudentStyles from './course-main-student-slides.module.less'
import Cookies from "js-cookie";

export interface Props {
    course_id:any;
}
/** 教师课程主页面 */
export const CourseMainStudentSlides: React.FC<Props> = (props) => {
    const {course_id} = props;
    const [courseResult, setCourseResult] = useState([] as any[]);
    const [slides, setSlides] = useState([] as any[]);    //课程的课件

    // 点击下载
    const onDownloadSlidesClick = (url:any, event:any) => {
        event.preventDefault();
        window.open(url?.startsWith("http") ? url : constants.BACKEND_URL + url);
    }

    // 渲染操作
    const renderActions = (text:any, record:any) => {
        return <div> 
            <Button onClick={onDownloadSlidesClick.bind(this, record.url)} className={slidesStudentStyles.actionBtn} style={{borderRadius:20,marginRight:5}} size='small' type='link'> <DownloadOutlined />下载 </Button>
        </div>
    }
    const slidesCols :any= [
    {
        title:'序号',
        dataIndex: 'id',
        key: 'id',
        align: 'left',
        width: 80,
        render:(text,record,index)=>(slides.indexOf(record) + 1)
        },
      {
        title: '课件',
        dataIndex: 'name',
        key: 'name',
        align: 'left',
        width: 350,
        render:(text:any, record:any)=>{return <div className={slidesStudentStyles.slideName}> {text} </div>}
      },
      {
        title: "操作",
        dataIndex: "actions",
        key: "actions",
        width: 120,
        ellipsis: false,
        align: 'center',
        //fixed: 'right',
        render: renderActions,
    },
    ]


    useEffect(()=> {
        // 示例代码请勿删除
  /*      sendHttpPostRequest(
            'case/api/get_case_list',
            {page: 1, pagesize: 16, type: 1, query: ""},
            renderCourse
        );*/
        querySlides();
  },[])


    const querySlides = () => {
        sendHttpPostRequest(
            'course/api/user_query_course_info',
            {
                sessionid: Cookies.get("sessionid"),
                sid: Cookies.get("sessionid"),
                course_id: course_id
            },
            (response: any) => {
                if (response.is_ok) {
                    setSlides(response.query_info.lecture_list);
                }
            }
        )
    }

    return (
        <div className={courseMainStyles.tabpaneWrapper}>
            <div className={slidesStudentStyles.tableWrapper}>
            {slides.length!=0 &&
            <ConfigProvider locale={zhCN}>
                <Table
                    dataSource={slides}
                    columns={slidesCols}
                    size='small'
                    rowKey={record => record.id}
                    pagination={{position:["bottomCenter"], pageSize:10, size:"default",hideOnSinglePage:true}}
                           // onChange={onTableChange}
                />
            </ConfigProvider>
            }
            {slides.length==0 &&
                <div style={{textAlign:'center',paddingTop:40}}>
                    <img src={kejianEmpty} width={120} height={120}/>
                    <div style={{color:'#999',marginTop:10}}>老师还未开放课件哟~</div>
                </div>
            }
            </div>
        </div>
    )

}

export default CourseMainStudentSlides

