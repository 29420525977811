import { Button, Col, ConfigProvider, message, Popover, Row, } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import zhCN from 'antd/lib/locale/zh_CN';
import axios from 'axios';
import Qs from 'qs';

import { UserOutlined, LogoutOutlined,DownOutlined} from '@ant-design/icons';

import Cookies from 'js-cookie';
import headerStyles from '../page-header/header.module.less';
import userStyles from './user.module.less';

import './user-center.css';
import localStorage from 'localStorage';
import {sendHttpPostRequest} from "../../../api/http-request";
import {delCookie, getCookie} from "../login-register/login-register";
import { BACKEND_API_URL } from '../../../constants/constants';
import * as constants from "../../../constants/constants";
export interface Props {}
let globalUserLogin = false;

/**
 * UserInfo
 * 用户信息
 */
export const UserInfo: React.FC<Props> = (props) => {
  const [userLogin, setUserLogin] = useState(false); // 登录 false / true
  const [userId, setUserId] = useState(0); // 用户id
  const [userName, setUserName] = useState(''); // 用户昵称
  const [userAvatar, setUserAvatar] = useState('/static/image/avatar/default_avatar.png'); // 用户头像

  useEffect(() => {
    !getUserInfoFromLocal() ? checkLogin() : "local";
  }, [])

  // 从本地获取用户信息
  const getUserInfoFromLocal = () => {
    try {
      const user_info = JSON.parse(localStorage.getItem("user_info"));
      if (!user_info) {
        return false;
      } else {
        setUserLogin(true);
        setUserId(user_info.login_user_id);
        setUserName(user_info.login_user_name);
        setUserAvatar(user_info.login_user_avatar);
        console.log("已登录");
        globalUserLogin = true;
        return true;
      }
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  /**
   * 校验用户是否登录
   */
  const checkLogin = () => {
    const sessionid = Cookies.get("sessionid");
    if (sessionid) {
      const _data = Qs.stringify({
        sessionid: sessionid
      })
      const _headers = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}};
      axios
        .post(
          constants.BACKEND_API_URL + 'account/api/check_login/',
          _data,
          _headers
        )
        .then((response) => response.data)
        .then((data) => {
          setUserLogin(true);
          setUserId(data.login_user_id);
          setUserName(data.login_user_name);
          setUserAvatar(data.login_user_avatar);
          globalUserLogin = true;
          // localStorage.setItem("user_info", JSON.stringify(data));
          console.log("登录成功");
          // console.log(data);
        })
        .catch((e) => {
          console.error(':)校验失败了', e);
        })
    } else {
      console.log("用户未登录");
    }
  }

  const content = (<>
      <div className={userStyles.useCenterContentActionDiv} style={{textAlign:"center"}}  > 
       {userName}
     </div>
     <div style={{textAlign:"center",marginBottom:10}} > 
      <img  style={{height:36,width:36,borderRadius:18}} src={BACKEND_API_URL + userAvatar} />
     </div>

    <div className={userStyles.useCenterContentActionDiv} onClick={handleGoUserCenter.bind(userId)} > 
      <Row  align="middle" gutter={[0,0]} style={{paddingLeft:20,paddingRight:20,height:36,margin:0}} >
        <Col style={{margin:0}}  >
          <UserOutlined style={{marginRight:10}}/>
        </Col>
        <Col style={{margin:0}}> 个人中心 </Col>
       </Row>
     </div>

     <div className={userStyles.useCenterContentActionDiv} onClick={handleGoLogout} > 
      <Row align="middle" gutter={[0,0]} style={{paddingLeft:20,paddingRight:20,height:36,margin:0}}  >
        <Col style={{margin:0}} >
          <LogoutOutlined style={{marginRight:10}}/> 
        </Col  >
        <Col style={{margin:0}} > 退出登录 </Col>
       </Row>
     </div>
  </>)
  // console.log(userLogin)
  if (userLogin) {
    return (
    
      <Popover placement="bottomRight" title={""} content={content} trigger="hover"
      overlayClassName = {userStyles.userPopver}
      overlayStyle={{margin:0,width:200}}
      overlayInnerStyle={{marginTop:-10,paddingTop:10,borderRadius:5}}
      >
        <div>
          <img id="header-user-avatar" src={BACKEND_API_URL + userAvatar} />
          <DownOutlined />
        </div>
      </Popover>

      
    );
  } else {
    return (
        <div >
           <Button type="link" onClick={handleGoLogin}>登录</Button>
        </div>
    );
  }
}

export default UserInfo

/**
 * 登录
 */
export function handleGoLogin() {
  const pathname = window.location.pathname;
  const location = window.location.href;
  const host = window.location.host + "/";
  const search = window.location.search;
  // const jumpUrl = location.split(host)[1];
  const jumpUrl = pathname + search;
  // console.log(window.location);
  // console.log(jumpUrl);
  // return;
  pathname != "/" ? window.location.href = "/login?next=" + jumpUrl : window.location.href = "/login";
}

export function logoutCallback() {
  delCookie("sessionid" );
  localStorage.removeItem("user_info");
  localStorage.removeItem("college_info");
  try {
    if(localStorage.getItem("already_setting")){
      localStorage.removeItem("already_setting")
    }
  } catch (error) {
    
  }
  window.location.reload();
}

/**
 * 退出登录
 */
export function handleGoLogout() {
  const cookie = getCookie("sessionid");
  try {
    sendHttpPostRequest(
        'account/api/logout/',
        {sessionid: cookie},
        logoutCallback
    );
  } catch (e) {
    logoutCallback
  }

}

/**
 * 个人中心
 */
export function handleGoUserCenter() {
  window.location.href = '/user-center';
}

/**
 * 外部调用判断是否登录了
 */
export function checkUserLogin() {
  return globalUserLogin;
}
