import {
    Layout,
    Button,
    Input,
    message,
    Table,
    Space,
    ConfigProvider,
    Tabs,
    Row,
    Col,
    Popconfirm,
    Statistic,
    Pagination,
    Tooltip
} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import zhCN from 'antd/lib/locale/zh_CN';
import OAMSider from '../oam-sider';
import React, {useEffect, useRef, useState} from "react";
import {sendHttpPostRequest} from "../../../api/http-request";
import Cookies from 'js-cookie';

import idataCourseTableStyles from "../../common/global-styles/idatacourse-table.module.less";

const { Search } = Input;
const { TabPane } = Tabs;

interface Exercise {
    id: number,
    name: string,
    author: string,
    difficulty: number, //难度
    channel: number, //
    type: number, //题型
    status: number, //状态
    create_time: string,//创建时间
    modify_time: string,//修改时间
}

export interface Props {}
const typeMap = new Map([[1, '实操题'], [2, '单选题'], [3, '多选题'],[4, '排序题'], [5, '主观题']]);
const difficultyMap = new Map([[1, '容易'], [2, '中等'], [3, '困难']]);
const statusMap = new Map([[1,'未上架'],[2,"已上架"]]);

export const OAMExerciseList: React.FC<Props> = (props) => {
    const [exerciseResult, setExerciseResult] = useState([] as any[]);  // 当前显示的结果页
    const [resultSum, setResultSum] = useState(0);  // 搜索返回的结果数
    const [totalCount, setTotalCount] = useState(0);  // 搜索返回的结果数
    const queryContent = useRef('');  // 记录搜索串
    const querySource = useRef('2');  // 来源 1-用户  2-OAM创建（系统）
    const order = useRef('');  // 排序升序降序
    const order_by = useRef('');  // 排序列
    const page = useRef(1);  // 记录当前的页数
    const pageSize = useRef(20);  // 记录当前每页展示数量
    const userIsLogin = useRef(false);  // 用户是否登录
    const typeFilters = useRef([]);  // 类型过滤选项
    const difficultyFilters = useRef([]);  // 难易程度过滤选项
    const statusFilters = useRef([]);  //状态
    const createTime = useRef('');  // 创建时间

    const [sysCount, setSysCount] = useState(0) //搜索返回的结果数
    const [userCount, setUserCount] = useState(0) //搜索返回的结果数
    const [onlineCount, setOnlineCount] = useState(0) //搜索返回的结果数

    useEffect(() => {
        queryExerciseList();
    }, [])

    /**
     * 查询习题列表
     */
    const queryExerciseList = () => {
        sendHttpPostRequest(
            'exercise/api/oam_query_exercise_list/',
            {
                sessionid: Cookies.get("sessionid"),
                query: queryContent.current,
                page: page.current,
                pagesize: pageSize.current,
                order: order.current,
                order_by: order_by.current,
                type: JSON.stringify(typeFilters.current),
                difficulty: JSON.stringify(difficultyFilters.current),
                status:JSON.stringify(statusFilters.current),
                create_time: createTime.current,
                source: querySource.current,
                source_page: 1,  // 习题模块1
                source_key: 1,  // OAM1
            },
            queryExerciseListCallback
        )
    }

    /**
     * 查询习题列表回调
     * @param response
     */
    const queryExerciseListCallback = (response: any) => {
        // console.log(response)
        message.destroy();
        if (response.code == -100) {
            // 未登录
            window.location.href = '/oam_login?next=oam_exercise_list';
        } else if (response.code == 0) {
            // 成功
            setExerciseResult(response.exercise_list);
            setResultSum(response.total_sum);
            setTotalCount(response.total_count);
            setSysCount(response.sys_count);
            setUserCount(response.user_count);
            setOnlineCount(response.online_count);
        } else if (response.code == -501) {
            // 权限不足
            window.location.href = '/oam_login?next=oam_exercise_list';
        } else {
            message.error(response.message);
        }
    }

    /**
     * 创建习题回调
     * @param response
     */
    const setUserInfoCallback = (response:any) => {
        message.destroy();
        if (response.code == -100) {
            // 没登录
            window.location.href = '/oam_login?next=oam_exercise_list';
        } else if (response.code == -501) {
            // 没权限
            window.location.href = '/oam_login?next=oam_exercise_list';
        } else if (response.code == 0) {
            if (response.exercise_id) {
                window.open(`/oam_exercise_edit?exercise_id=${response.exercise_id}`);
            } else {
                message.error(response.message);
            }
        }
    }

    /**
     * 点击创建习题
     */
    const createExercise = () => {
        sendHttpPostRequest(
            'exercise/api/oam_create_exercise/',
            {
                sessionid: Cookies.get("sessionid")
            },
            setUserInfoCallback
        )
    }

    //页码和页数发生了变化
    const onPageChange = (newPage: number, newPageSize?: number | undefined) => {
        page.current = newPage ? newPage : 1;
        if (newPageSize) {
            pageSize.current = newPageSize;
        }
        queryExerciseList();
    }

    // 渲染类型
    const renderType = (text: any, record: any) => (
        <div>{typeMap.get(parseInt(text))}</div>
    )

    // 渲染难易程度
    const renderDifficulty = (text: any, record: any) => (
        <div>{difficultyMap.get(parseInt(text))}</div>
    )

    // 渲染状态
    const renderStatus = (text: any, record: any) => (
        <div>{statusMap.get(parseInt(text))}</div>
    )
    // 删除回调
    const onDeleteBtnClickCallback = (response: any) => {
        message.destroy();
        if (response.code == -100) {
            // 没登录
            window.location.href = '/oam_login?next=oam_exercise_list';
        } else if (response.code == -501) {
            // 没权限
            window.location.href = '/oam_login?next=oam_exercise_list';
        } else if (response.code == 0) {
            message.success(response.message);
            queryExerciseList();
        } else {
            message.error(response.message);
        }
    }

    // 点击 删除
    const onDeleteBtnClick = (exerciseId: string) => {
        sendHttpPostRequest(
            'exercise/api/oam_delete_exercise/',
            {
                sessionid: Cookies.get("sessionid"),
                exercise_id: exerciseId
            },
            onDeleteBtnClickCallback
        )
    }

    // 上下架回调
    const onPutOnOffBtnClickCallback = (response: any) => {
        message.destroy();
        if (response.code == -100) {
            // 没登录
            window.location.href = '/oam_login?next=oam_exercise_list';
        } else if (response.code == -501) {
            // 没权限
            window.location.href = '/oam_login?next=oam_exercise_list';
        } else if (response.code == 0) {
            message.success(response.message);
            queryExerciseList();
        } else {
            message.error(response.message);
        }
    }

    // 点了上下架按钮
    const onPutOnOffBtnClick = (exerciseId:string, statusStr:string) => {
        sendHttpPostRequest(
            'exercise/api/oam_update_exercise_status/',
            {
                sessionid: Cookies.get("sessionid"),
                exercise_id: exerciseId
            },
            onPutOnOffBtnClickCallback
        )
    }

    // 点了预览按钮
    const onLookOverBtnClick = (exerciseId: string, event: any) => {
        window.open(`/oam_preview_exercise?exercise_id=${exerciseId}`);
    }

    // 渲染操作
    const renderActions = (text:any, record:any) => {
        if (record.source == 2) {
            return (
                <Row justify="start" align="middle" gutter={[8, 4]} >
                    <Col style={{width:50}}><Button style={{borderRadius:20,fontSize:14}} size='small' id="lookover" value={record.id} type="default" onClick={onLookOverBtnClick.bind(this, record.id)} > 预 览 </Button></Col>
                    <Col style={{width:50}}>
                        <Button
                            style={{borderRadius:20,fontSize:14}}
                            size='small'
                            id="editit"
                            type="dashed"
                            onClick={onEditBtnClick.bind(this,record.id)} > 编辑
                        </Button>
                    </Col>
                    {<Col style={{width:50}}> { parseInt(record.status) == 1 ?
                        <Button style={{borderRadius:20,fontSize:12}} size='small' id="puton" value={record.id} type="primary"  onClick={onPutOnOffBtnClick.bind(this, record.id, record.status)}> 上 架 </Button>
                        :  <Popconfirm placement="top" title={'确认下架习题"' + record.title + '"吗？'} onConfirm={onPutOnOffBtnClick.bind(this, record.id, record.status)} okText="确认" cancelText="取消">
                            <Button style={{borderRadius:20,fontSize:12}} size='small' id="putoff" value={record.id} type="primary" danger> 下 架 </Button>
                        </Popconfirm>}
                    </Col> }
                    <Col style={{width:50}}>
                        {parseInt(record.status) == 1 ?
                            <Popconfirm placement="top" title={'确认删除习题"' + record.title + '"吗？'} onConfirm={onDeleteBtnClick.bind(this, record.id)} okText="确认" cancelText="取消">
                                <Button
                                    style={{borderRadius:20,fontSize:14}}
                                    size='small'
                                    id="deleteexe"
                                    type="dashed"
                                    danger
                                > 删除
                                </Button>
                            </Popconfirm>
                            :
                            ""
                        }
                    </Col>
                </Row>
            )
        } else {
            return (
                <Row justify="start" align="middle" gutter={[8, 4]} >
                    <Col span={24}><Button style={{borderRadius:20,fontSize:14}} size='small' id="lookover" value={record.id} type="default" onClick={onLookOverBtnClick.bind(this, record.id)} > 预 览 </Button></Col>
                </Row>
            )
        }
    }

    //点击了编辑按钮
    const onEditBtnClick = (id:any,  event:any) => {
        window.open('/oam_exercise_edit?exercise_id='+id);
    }

    //点击了搜索按钮
    const onSearchBtn = (value: string) => {
        page.current = 1;
        queryContent.current = value;
        queryExerciseList();
    }

    // 切换Tab页
    const onTabKeyChange = (key:string) => {
        console.log(key);
        querySource.current = key;
        page.current = 1;
        queryExerciseList();
    }

    // 表格发生变化
    const onTableChange:any = (pagination:any, filters:any, sorter:any, extra: { currentDataSource: [], action: any })=> {
        if (sorter.order) { //如果选择了排序列
            order.current = sorter.order;
            order_by.current = sorter.field;
        }
        if (filters.type) { //如果选择了过滤列
            typeFilters.current = filters.type;
        } else {
            typeFilters.current = [];
        }
        if (filters.difficulty) {
            difficultyFilters.current = filters.difficulty;
        } else {
            difficultyFilters.current = [];
        }
        if (filters.status) {
            statusFilters.current = filters.status;
        } else {
            statusFilters.current = [];
        }
        page.current = 1;
        queryExerciseList();
    }

    const columns:any = [
        {
            title: "序号",
            dataIndex: "id",
            key: "id",
            width: 50,
            align: 'left',
            render:(text, record, index)=>((page.current - 1)*pageSize.current + index + 1)
        },
          {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            align: 'left',
            width: 50,
          },
          {
            title: '题目名称',
            dataIndex: 'title',
            key: 'title',
            ellipsis: true,
            align: 'left',
            width: 100,
          },
          {
            title: '备注',
            dataIndex: 'remarks',
            key: 'remarks',
            align: 'center',
            width: 100,
            ellipsis: true,
          },
          {
            title: '题型',
            dataIndex: 'type',
            key: 'type',
            align: 'center',
            width: 80,
            render: renderType,
            filters: [
                {
                    text: '实操题',
                    value: 1,
                },
                {
                    text: '单选题',
                    value: 2,
                },
                {
                    text: '多选题',
                    value: 3,
                },
                {
                    text: '排序题',
                    value: 4,
                },
                {
                    text: '主观题',
                    value: 5,
                }
            ],
            onFilter: (value:any, record:any) => true,
          },
         
          {
            title: '难度',
            dataIndex: 'difficulty',
            key: 'difficulty',
            align: 'center',
            width: 80,
            render: renderDifficulty,
            filters: [
                {
                    text: '容易',
                    value: 1,
                },
                {
                    text: '中等',
                    value: 2,
                },
                {
                    text: '困难',
                    value: 3,
                }
            ],
            onFilter: (value:any, record:any) => true,
          },
          {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            width: 80,
            render: renderStatus,
            filters: [
                {
                    text: '未上架',
                    value: 1,
                },
                {
                    text: '已上架',
                    value: 2,
                }
            ],
            onFilter: (value:any, record:any) => true,
          },
          {
            title: '创建者',
            dataIndex: 'creator',
            key: 'creator',
            align: 'center',  
            ellipsis: true,        
            width: 100,
          },
          {
            title: '创建时间',
            dataIndex: 'create_time',
            key: 'create_time',
            align: 'center',
            width: 120,
            sorter: true,
            ellipsis: true,
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'descend',
          },
          {
            title: "操作",
            dataIndex: "id",
            key: "actions",
            width: 150,
            ellipsis: false,
            align: 'center',
            render: renderActions ,
        },
      ];

    return (
             
        <Layout style={{ minHeight: '100vh' }}>
            <OAMSider keys={['5']}  openKeys={[]} />
            <Layout style={{backgroundColor:'#f9f9f9'}}>
                <Content style={{ backgroundColor:'#fff',borderRadius:5,minWidth:400,margin:30 ,paddingTop:20}}>
                  <div style={{paddingLeft: 30, paddingRight: 30, paddingBottom: 30}}>
                        <Row align="middle"  gutter={16} >
                            <Col flex='80px'>
                                <Statistic title="总习题" value={totalCount} />
                            </Col>
                            <Col flex='80px'>
                                <Statistic title="系统" value={sysCount} />
                            </Col>
                            <Col flex='80px'>
                                <Statistic title="用户" value={userCount} />
                            </Col>
                            <Col flex='80px'>
                                <Statistic title="已上架" value={onlineCount} />
                            </Col>
                            <Col flex='250px'>
                                <Search
                                    placeholder="搜索习题"
                                    style={{ width: 350 }}
                                    enterButton={true}
                                    onSearch={onSearchBtn}
                                />
                            </Col>
                            <Col flex='auto' style={{textAlign: 'right'}}>
                                <Button type="primary" style={{float: 'right', marginRight: 20, borderRadius: 20}} onClick={createExercise}>创建习题</Button>
                            </Col>
                        </Row>
                        <Tabs defaultActiveKey="2" onChange={onTabKeyChange}>
                            <TabPane tab="系统" key="2" className="">
                            <div className={idataCourseTableStyles.tableWrapper}>
                                <ConfigProvider locale={zhCN}>
                                    <Table
                                        dataSource={exerciseResult}
                                        columns={columns}
                                        rowKey={record => record.id}
                                        pagination={false}
                                        scroll={{ x: 800 }}
                                        //bordered={true}
                                        onChange={onTableChange}
                                    />
                                </ConfigProvider>
                                </div>
                            </TabPane>
                            <TabPane tab="用户" key="1" className="">
                            <div className={idataCourseTableStyles.tableWrapper}>
                                <ConfigProvider locale={zhCN}>
                                    <Table
                                        dataSource={exerciseResult}
                                        columns={columns}
                                        rowKey={record => record.id}
                                        pagination={false}
                                        //bordered={true}
                                        scroll={{ x: 800 }}
                                        onChange={onTableChange}
                                    />
                                </ConfigProvider>
                                </div>
                            </TabPane>
                        </Tabs>

                        <ConfigProvider locale={zhCN}>
                            <div style={{marginBottom:30,paddingTop:30}} className={"css.pagination"} >
                                <Pagination
                                    defaultCurrent={1}
                                    total={resultSum}
                                    defaultPageSize={20}
                                    hideOnSinglePage={true}
                                    current={page.current}
                                    onChange={onPageChange}
                                    showQuickJumper={true}
                                    showTotal={total => `共 ${total} 习题`}
                                    pageSizeOptions={["3","20","30","50","100"]}
                                />
                            </div>
                        </ConfigProvider>
                    </div>
                </Content>
            </Layout>
            
        </Layout>

    )

}

export default OAMExerciseList

