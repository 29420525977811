import React, { lazy } from 'react';
import { Router, Route, Switch, BrowserRouter} from 'react-router-dom';
import { createHashHistory } from "history";
// const history = createHashHistory();
import App from '../App';

const RouterConfig = () => (
    <BrowserRouter>
        <Route path={"/*"} component={App} />
    </BrowserRouter>
);
export default RouterConfig;


