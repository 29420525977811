import React, { Component } from 'react';
import { List, Input, Button, message } from 'antd';
import { Link } from 'react-router-dom';
import { UserOutlined, LockOutlined, MobileOutlined } from '@ant-design/icons';
import axios from 'axios';
import loginRegisterStyles from './oam-login.module.less';
import './oam-login.css';
import localStorage from 'localStorage';
import logoSvg from '../../../static/image/logo.svg';
import {sendHttpPostRequest} from "../../../api/http-request";
import {setCookie} from "../../common/login-register/login-register";

class OamLogin extends Component {
    state = {
        idens: '', //帐号
        password: '', // 密码
        passwordConfirm: '', // 密码确认
        vcode: '', // 验证码
        errorMessage: '', // 错误码
        sendVcodeTip: '发送验证码', // 验证码发送提示
        sendBtnDisabled: false, // 发送按钮不可用
        inviter: '', // 邀请者
    }

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="login-register-wrapper login">
                <div className={loginRegisterStyles.login_register_logo}>
                    <Link to={"/"} title={"返回爱数课首页"}>
                        <img src={logoSvg}
                             alt=""
                            // height={36}
                             width={180}
                             style={{display: "inline-block"}}
                            // style={{paddingTop:10, paddingLeft:10}}
                        />
                    </Link>
                    <div><span>管理员登录</span></div>
                </div>
                <div className={loginRegisterStyles.pageLogin}>
                    {/*<div className="semi-globe">*/}
                    {/*    <img src={ imgUrl } alt="" />*/}
                    {/*</div>*/}
                    <List className="">
                        <div id="error-message" className="error-message">{this.state.errorMessage}</div>
                        <Input id="phone" name="phone" className="login-form phone" value={this.state.idens}
                               placeholder={"请输入手机号"} required={true} prefix={<UserOutlined />}
                               onChange={this.handleChange.bind(this, "idens")} />
                        <Input.Password id="password" name="password" type="password" prefix={<LockOutlined />}
                                        className="login-form password" placeholder="请输入密码，6-20位"
                                        maxLength={20} onChange={this.handleChange.bind(this,"password")} />
                        <div onClick={this.handleLogin.bind(this)} className="button btn-register">登 &nbsp;&nbsp;录</div>
                    </List>
                </div>
            </div>
        )
    }

    // 绑定表单值
    handleChange(key, event) {
        this.setState({
            [key]: event.target.value
        })
    }

    // 登录
    handleLogin() {
        doLogin(this);
    }

    /**
     * 登录回调
     * @param response
     */
    loginCallback(response) {
        const location = window.location.href.split("next=")[1];
        if (response.is_ok) {
            setCookie("sessionid", response.token);
            localStorage.setItem("user_info", JSON.stringify(response.user_info.user_info));
            window.location.href = '/oam_user_stat';
            // location ? window.location.href = location : window.location.href = "/";
        } else {
            this.setState({errorMessage: response.message});
        }
    }
}

export default OamLogin


/**
 * 调后端登录接口
 */
export function doLogin(_this: any) {
    const phone = _this.state.idens;
    const password = _this.state.password;
    const is_mobile = /^(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[0-9]{1})|(18[0-9]{1})|(19[0-9]{1}))+\d{8})$/;
    if (!phone) {
        _this.setState({errorMessage: "请输入手机号"});
        return false;
    } else if (!phone.match(is_mobile)) {
        _this.setState({errorMessage: "请输入正确的手机号"});
        return false;
    } else if (!password) {
        _this.setState({errorMessage: "请输入密码"});
        return false;
    } else if (password.length < 6 || password.length > 20) {
        _this.setState({errorMessage: "密码长度应为6-20位"});
        return false;
    }

    _this.setState({errorMessage: ""});
    try {
        sendHttpPostRequest(
            'account/api/oam_login/',
            {idens: phone, password: password},
            _this.loginCallback.bind(_this)
        );
    } catch (e) {
        console.error(e);
    }
}

/**
 * 设置cookie
 * @param key
 * @param value
 */
// export function setCookie(key, value) {
//     const date: any = new Date();
//     date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000));
//     const expires = "expires=" + date.toGMTString();
//     document.cookie = key + "=" + value + "; " + expires;
// }

/**
 * 删除cookie
 * @param key
 */
// export function delCookie(key) {
//     const date: any= new Date();
//     date.setTime(date.getTime() - 10000);
//     const expires = "=; expires=; " + date.toGMTString();
//     document.cookie = key + expires;
// }

/**
 * 获取cookie
 * @param key
 */
// export function getCookie(key) {
//     let arr, reg = new RegExp("(^| )" + key + "=([^;]*)(;|$)");
//     if (arr=document.cookie.match(reg))
//         return unescape(arr[2]);
//     else
//         return null;
// }


