import {Pagination, Layout, message, ConfigProvider, Input, Table, Modal, Button, Card, Row, Col, Popover} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useState, useRef, useEffect } from "react";
import Cookies from 'js-cookie';
import zhCN from 'antd/lib/locale/zh_CN';
import {sendHttpPostRequest} from "../../../api/http-request";
const { Search } = Input;
import TextArea from 'antd/lib/input/TextArea';
import OAMSider from "../oam-sider";
import {BACKEND_API_URL } from '../../../constants/constants';
import idataCourseTableStyles from "../../common/global-styles/idatacourse-table.module.less";
import Meta from 'antd/lib/card/Meta';
import UserCard from './oam-userinfo-card';

export interface Props {}
const sexMap = new Map([[1, '未设置'], [2, '男'], [3, '女']]);
const roleMap = new Map([[1, '学生'], [2, '教师']]);
const channelMap = new Map([['爱数科', 1], ['爱数课', 2],['数据实验楼',3]]);


export const OAMUserList: React.FC<Props> = (props) => {
    const [userResult, setUserResult] = useState([] as any[]);  // 当前显示的结果页
    const [resultSum, setResultSum] = useState(0);  // 搜索返回的结果数
    const [totalCount, setTotalCount] = useState(0);  // 搜索返回的结果数
    const [isEditUserModalVisible, setIsEditUserModalVisible] = useState(false);  // 模态框是否显示
    const [editUserId, setEditUserId] = useState(-1);  // 待编辑备注的用户ID
    const [editUserRemark, setEditUserRemark] = useState("");  // 备注信息
    const queryChannel = useRef(0);  // 来源渠道
    const queryContent = useRef('');  // 记录搜索串
    const order = useRef('');  // 排序升序降序
    const order_by = useRef('');  // 排序列
    const page = useRef(1);  // 记录当前的页数
    const pageSize = useRef(20);  // 记录当前每页展示数量
    const sexFilters = useRef([]);  // 性别过滤选项
    const roleFilters = useRef([]);  // 身份过滤选项
    const channelFilters = useRef([]);  // 渠道过滤项
    const userIsLogin = useRef(false);  // 用户是否登录

    const [currentUser, setCurrentUser] = useState(null as any);

    useEffect(() => {
        queryUserList();
    }, [])

    // 查询用户列表
    const queryUserList = () => {
        const sessionid = Cookies.get("sessionid");
        const queryData = {
            query: queryContent.current,
            page: page.current,
            pagesize: pageSize.current,
            order: order.current,
            order_by: order_by.current,
            sex: JSON.stringify(sexFilters.current),
            role: JSON.stringify(roleFilters.current),
            channel: JSON.stringify(channelFilters.current),
            sessionid: sessionid,
            sid: sessionid,
        };
        sendHttpPostRequest(
            'account/api/query_user_list/',
            queryData,
            userListCallback
        )
    }

    // 查询用户列表接口回调
    const userListCallback = (response: any) => {
        // console.log(response);
        message.destroy();
        if (response.code == -100) {
            // 未登录
            window.location.href = '/oam_login?next=user_list';
        } else if (response.code == 0) {
            // 成功
            setUserResult(response.user_list);
            setResultSum(response.total_sum);
            setTotalCount(response.total_count);
        } else if (response.code == -501) {
            // 权限不足
            window.location.href = '/oam_login?next=user_list';
        } else {
            message.error(response.message);
        }
    }



    //页码和页数发生了变化
    const onPageChange = (newPage: number, newPageSize?: number | undefined) => {
        page.current = newPage ? newPage : 1;
        if (newPageSize) {
            pageSize.current = newPageSize;
        }
        queryUserList();
    }

    // 渲染性别
    const renderSex = (text: any, record: any) => (
        <div>{sexMap.get(parseInt(text))}</div>
    )

    // 渲染身份
    const renderRole = (text: any, record: any) => (
        <div>{roleMap.get(parseInt(text))}</div>
    )


    //点击了编辑备注按钮
    const onEditRemarkBtnClick = (user_id:any, remark:string, event:any) => {
        setEditUserId(user_id[0]);
        setEditUserRemark(remark);
        setIsEditUserModalVisible(true);
    }

    // 用户备注改变
    const onUserRemarkChange = async (e: any) => {
        setEditUserRemark(e.target.value);
    }

    // 用户备注确认
    const handleRemarkOk = () => {

    }

    // 用户备注取消
    const handleRemarkCancel = () => {

    }

    //点击了搜索按钮
    const onSearchBtn = (value: string) => {
        page.current = 1;
        queryContent.current = value;
        queryUserList();
    }

    // 表格发生变化
    const onTableChange:any = (pagination:any, filters:any, sorter:any, extra: { currentDataSource: [], action: any })=> {
        if (sorter.order) { //如果选择了排序列
            order.current = sorter.order;
            order_by.current = sorter.field;
        }
        if (filters.sex) { //如果选择了过滤列
            sexFilters.current = filters.sex;
        } else {
            sexFilters.current = [];
        }
        if (filters.role) {
            roleFilters.current = filters.role;
        } else {
            roleFilters.current = [];
        }
        if (filters.channel_name) {
            channelFilters.current = filters.channel_name.map(element=> {
                return channelMap.get(element);
            });
        } else {
            channelFilters.current = [];
        }
        page.current = 1;
        queryUserList();
    }

    // 表格列
    const cols: any = [
        {
            title: "序号",
            dataIndex: "user_id",
            key: "user_id",
            width: 50,
            align: 'left',
            render:(text, record, index)=>((page.current - 1)*pageSize.current + index + 1)
        },
        /*{
            title: "ID",
            dataIndex: "user_id",
            key: "user_id",
            width: 60,
            align: 'left',
            sortDirections: ['descend', 'ascend'],
        },*/
        {
            title: "昵称",
            dataIndex: "nick_name",
            key: "nick_name",
            width: 140,
            align: 'left',
            render:(text,record)=>{
                return <Row align="middle">
                <Col flex="40px"><Popover  arrowPointAtCenter={true} trigger="hover" placement="left" content={<UserCard user_id={record.user_id} />} title={null}>
                <img  onMouseOver={()=>{}} style={{width:30,height:30,borderRadius:15,cursor:"pointer"}} src={ record?.avatar?.startsWith("http") ? record?.avatar : BACKEND_API_URL + record?.avatar} />
                
            </Popover> </Col>
            <Col flex="80px"><span >{record.real_name == '' ? text : record.real_name} </span></Col></Row>
            }
        },
        {
            title: "手机号",
            dataIndex: "phone",
            key: "phone",
            width: 80,
            align: 'center',
        },
        {
            title: "注册时间",
            dataIndex: "register_date",
            key: "register_date",
            width: 120,
            align: 'center',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },
        /*{
            title: "真实姓名",
            dataIndex: "real_name",
            key: "real_name",
            width: 60,
            align: 'left',
        },*/
        {
            title: "性别",
            dataIndex: "sex",
            key: "sex",
            width: 50,
            align: 'center',
            render: renderSex,
            filters: [
                {
                    text: '未设置',
                    value: 1,
                },
                {
                    text: '男',
                    value: 2,
                },
                {
                    text: '女',
                    value: 3,
                }
            ],
            onFilter: (value:any, record:any) => true,
        },
        /* {
           title: "个人简介",
           dataIndex: "introduction",
           key: "introduction",
           width: 200,
           align: 'left',
         },*/
        {
            title: "邀请者",
            dataIndex: "inviter",
            key: "inviter",
            width: 80,
            align: 'left',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: "备注",
            dataIndex: "remark",
            key: "remark",
            width: 80,
            align: 'left',
            ellipsis: false,
        },
        {
            title: "来源",
            dataIndex: "channel_name",
            key: "channel_name",
            width: 80,
            align: 'left',
            filters: [
                {
                    text: '爱数科',
                    value: '爱数科',
                },
                {
                    text: '爱数课',
                    value: '爱数课',
                },
                {
                    text: '数据实验楼',
                    value: '数据实验楼',
                }
            ],
            onFilter: (value:any, record:any) => true,
        },
        {
            title: "身份",
            dataIndex: "role",
            key: "role",
            width: 50,
            align: 'center',
            render: renderRole,
            filters: [
                {
                    text: '学生',
                    value: 1,
                },
                {
                    text: '教师',
                    value: 2,
                }
            ],
            onFilter: (value:any, record:any) => true,
        },
        /*{
            title: "操作",
            dataIndex: "id",
            key: "actions",
            width: 80,
            ellipsis: false,
            align: 'center',
            fixed: 'right',
            render: renderActions ,
        },*/
    ]


    return (
        <Layout style={{ minHeight: '100vh' }}>
            <OAMSider keys={"12"} openKeys={["sub-user"]}></OAMSider>
            <Layout style={{backgroundColor:'#f9f9f9'}}>
                <Content style={{ backgroundColor:'#fff',borderRadius:5,minWidth:400,margin:30 ,paddingTop:20}}>
                    <div style={{paddingLeft:30,paddingRight:30}}>
                        <div className={"css.search1"} style={{textAlign:"left",paddingBottom:20}}>
                            <Search
                                placeholder="搜索用户"
                                style={{ width: 350 }}
                                enterButton={true}
                                onSearch={onSearchBtn}
                            />
                        </div>
                        <div className={idataCourseTableStyles.tableWrapper}>
                        <ConfigProvider locale={zhCN}>
                            <Table
                                dataSource={userResult}
                                columns={cols}
                                rowKey={record => record.user_id}
                                pagination={false}
                                scroll={{ x: 800 }}
                                //bordered={true}
                                onChange={onTableChange}
                            />
                        </ConfigProvider>
                        </div>

                        <ConfigProvider locale={zhCN}>
                            <div style={{marginBottom:30,paddingTop:30}} className={"css.pagination"} >
                                <Pagination
                                    defaultCurrent={1}
                                    total={resultSum}
                                    defaultPageSize={20}
                                    hideOnSinglePage={true}
                                    onChange={onPageChange}
                                    current={page.current}
                                    showQuickJumper={true}
                                    showTotal={total => `共 ${total} 用户`}
                                    pageSizeOptions={["3","20","30","50","100"]}
                                />
                            </div>
                        </ConfigProvider>
                        <ConfigProvider locale={zhCN}>
                            <Modal title="用户备注" visible={isEditUserModalVisible} onOk={handleRemarkOk} onCancel={handleRemarkCancel}>
                                <div> 用户ID: {editUserId}</div>
                                <TextArea
                                    style={{marginTop:20}}
                                    value={editUserRemark}
                                    rows={6}
                                    maxLength={300}
                                    onChange={onUserRemarkChange} > </TextArea>
                            </Modal>
                        </ConfigProvider>

                    </div>
                </Content>
            </Layout>
        </Layout>

    )

}

export default OAMUserList

