import React, { useEffect, useRef, useState } from 'react'
import {
  Badge,
  Input,
  Table,
  Switch,
  Layout,
  message,
  Card,
  Col,
  Row,
  ConfigProvider,
  Pagination,
  Tabs,
  Button,
  Tag,
  DatePicker,
  Modal,
} from 'antd'
import { PageHeader } from '../common/page-header/header'
import { sendHttpPostRequest } from '../../api/http-request'
import logoSvg from '../../static/image/idclogo.png'
import pekinglogoSvg from '../../static/image/peking.png'
import courseMainStyles from './course-main.module.less'
import './course.module.css'
import zhCN from 'antd/lib/locale/zh_CN'
import * as constants from '../../constants/constants'
import PageFooter from '../common/page-footer/footer'
import LoginRegisterModal from '../../component/modal/login-register/login-register'
import { checkUserLogin } from '../common/user-center/user-info'
import {
  PlaySquareOutlined,
  PlayCircleOutlined,
  ClockCircleOutlined,
  VideoCameraOutlined,
  CodeOutlined,
  FormOutlined,
  UserOutlined,
} from '@ant-design/icons'
import CourseMainTeacherMenu, {
  MenuPreview,
} from './menu/course-main-teacher-menu'
import CourseMainTeacherSlides from './slides/course-main-teacher-slides'
import CourseMainTeacherSetting from './setting/course-main-teacher-setting'
import CourseMainTeacherStudent from './student-list/course-main-teacher-student'
import { CourseMainTeacherTestList } from './test/course-main-teacher-test-list'
import CourseMainTeacherExamList from './exam/course-main-teacher-exam-list'
import CourseMainTeacherCases from './case/course-main-teacher-cases'
import CourseMainTeacherStats from './statistics/course-main-teacher-stats'

const { TextArea } = Input
const { TabPane } = Tabs
const { Search } = Input

import Feedback from '../common/feedback-help/feedback'
import Cookies from 'js-cookie'
import Draggable from 'react-draggable'
import LockedMenuPreview from './menu/locked-course-menu-preview'
import CourseMainDiscuss from './discussion/course-main-discuss'
import CourseMainNotelist from './note/course-main-notelist'
import { useHistory } from 'react-router'

export interface Props {}

/** 教师课程主页面 */
export const CourseMainTeacher: React.FC<Props> = (props) => {
  const courseId = parseInt(window.location.search.split('&')[0].split('=')[1]) // 课程ID
  const tabKey = window.location.search.split('&')[1]
    ? window.location.search.split('&')[1].split('=')[1]
    : '1' // 标签页key

  const history = useHistory()

  const [course, setCourse] = useState(null as any) //课程信息
  const [courseName, setCourseName] = useState('') //课程名称
  const [courseDescription, setCourseDescription] = useState('') //课程介绍
  const [courseCover, setCourseCover] = useState('') //课程封面路径
  const [courseStatus, setCourseStatus] = useState(null as any) //课程封面路径

  const [startTime, setStartTime] = useState(null as any) //课程开始时间
  const [endTime, setEndTime] = useState(null as any) //课程结束时间

  const [menuId, setMenuId] = useState(null as any) //课程大纲;

  const [collegeId, setCollegeId] = useState(null as any) //

  const [activeTabkKey, setActiveTabkKey] = useState(tabKey as string) //1大纲，2课件，3实验，4作业，5考试，6统计，7学员，8设置

  useEffect(() => {
    queryCourse()
    /** 查询机构Id */
    sendHttpPostRequest(
      'account/api/query_teacher_authenticate',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
      },
      (response: any) => {
        if (response.code == -100 || !response.is_ok) {
          history.push('/login?next=/course')
        }
        if (response?.college_info?.college_id) {
          let _collegeId = response.college_info.college_id
          setCollegeId(_collegeId)
        }
      }
    )
  }, [])

  // 查询课程信息
  const queryCourse = () => {
    const sessionid = Cookies.get('sessionid')
    const queryData = {
      source: 1,
      course_id: courseId,
      sessionid: sessionid,
      sid: sessionid,
    }
    sendHttpPostRequest(
      'course/api/user_query_course_by_id',
      queryData,
      courseCallback
    )
  }

  // 查询课程接口回调
  const courseCallback = (response: any) => {
    console.log(response)
    message.destroy()
    if (response.code == -100) {
      // 未登录
      document.getElementById('show-login-modal')!.click()
      // window.location.href = '/login?next=/course';
    } else if (response.is_ok == true) {
      // 成功
      setCourse(response.query_info)
      setCourseName(response.query_info.name)
      setCourseDescription(response.query_info.description)
      setMenuId(response.query_info.syllabus_id)
      setStartTime(response.query_info.start_time)
      setEndTime(response.query_info.end_time)
      //setCourseVisible(response.query_info.visible);
      //setLectureList(response.query_info.lecture_list);
      //setCaseList(response.query_info.case);
      setCourseCover(response.query_info.cover)
      setCourseStatus(
        response.query_info.status != 2
          ? '未发布'
          : response.query_info.course_status == '已结束'
          ? '已结束'
          : '已发布'
      )

      //setStartTime(response.query_info.start_time);
      //setEndTime(response.query_info.end_time);
      //setOnlineCount(response.online_count);
    } else if (response.code == -501) {
      // 权限不足
      message.error('权限不足')
      // window.location.href = '/login?next=course';
    } else {
      message.error(response.message)
    }
  }

  /**
   * 发布课程回调
   * @param response
   */
  const publishCourseInfoCallback = (response: any) => {
    message.destroy()
    if (response.code == -100) {
      // 没登录
      document.getElementById('show-login-modal')!.click()
      // window.location.href = '/login?next=course';
    } else if (response.code == -501) {
      // 没权限
      message.error('权限不足')
      // window.location.href = '/login?next=course';
    } else if (response.is_ok == true) {
      message.success('课程发布成功~')
      setCourseStatus('已发布')
    } else {
      message.error(response.message)
    }
  }

  /**
   * 点击发布课程按钮
   */
  const pushlishCourse = () => {
    sendHttpPostRequest(
      'course/api/user_publish_course',
      {
        course_id: courseId,
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
      },
      publishCourseInfoCallback
    )
  }

  const onCourseLearnClick = () => {
    if (!checkUserLogin()) {
      document.getElementById('show-login-modal')!.click()
    } else {
      if (course.recent_knowledge_url != '') {
        window.open(course.recent_knowledge_url)
      } else if (course.first_knowledge_url != '') {
        window.open(course.first_knowledge_url)
      } else {
        message.destroy()
        message.error('课程暂时无法学习，请稍候...')
      }
    }
  }

  const onCourseEditClick = () => {
    if (!checkUserLogin()) {
      document.getElementById('show-login-modal')!.click()
    } else {
      window.location.href = '/course-main-teacher-edit?course_id=' + courseId
    }
  }

  const statusBackGroundColor = (courseStatus: string) => {
    return courseStatus == '已发布' ? '#07c160' : '#199ed8'
  }

  return (
    <Layout style={{ minHeight: 750, backgroundColor: '#f8f8f8' }}>
      <LoginRegisterModal></LoginRegisterModal>

      <div className={courseMainStyles.courseHeaderWrapper}>
        <Row
          className={courseMainStyles.courseHeader}
          style={{ marginRight: 'auto', marginLeft: 'auto' }}
          align="middle"
          justify="start"
          wrap={false}
          // gutter={[12, 0]}
        >
          <Col flex="300px">
            <div
              className={courseMainStyles.courseImage}
              style={{ textAlign: 'left' }}
            >
              {/*<PlayCircleOutlined  className={ courseMainStyles.imagePlayIcon} />*/}
              {courseCover && (
                <img
                  //{/*src={constants.BACKEND_API_URL + course.cover}*/}
                  src={
                    courseCover?.startsWith('http')
                      ? courseCover
                      : constants.BACKEND_URL + courseCover
                  }
                  width={300}
                  height={190}
                  style={{ borderRadius: 6 }}
                />
              )}
            </div>
          </Col>

          <Col
            style={{
              position: 'relative',
              textAlign: 'left',
              overflow: 'hidden',
              margin: 0,
              marginRight: 20,
              paddingLeft: 40,
              // height: '240px',
            }}
            flex="auto"
          >
            {/* <Row align='middle' gutter={[4,0]}>
                                <Col></Col>
                                <Col></Col>
                            </Row> */}
            <div className={courseMainStyles.courseName}>{courseName}</div>
            <div
              className={courseMainStyles.courseStatus}
              style={{ backgroundColor: statusBackGroundColor(courseStatus) }}
            >
              {courseStatus}
            </div>
            <div className={courseMainStyles.courseIntroduction}>
              {' '}
              {courseDescription}{' '}
            </div>

            <Row align="middle" style={{ marginBottom: 10 }} gutter={[4, 0]}>
              {/*<Col><span style={{color:'#52c8b9',fontSize:16}}><VideoCameraOutlined /></span></Col>*/}
              {/*<Col><div style={{color:'#333333',fontSize:12}}>  视频时长： </div></Col>*/}
            </Row>

            <Row align="middle" style={{ marginBottom: 10 }} gutter={[8, 0]}>
              <Col>
                <span style={{ color: '#52c8b9', fontSize: 16 }}>
                  <ClockCircleOutlined />
                </span>
              </Col>
              <Col>
                <div style={{ color: '#333333', fontSize: 14 }}>
                  {' '}
                  开课时间：{startTime?.slice(0, 10)} 至 {endTime?.slice(0, 10)}
                </div>
              </Col>
            </Row>

            {course?.code !== '' && (
              <Row align="middle" style={{ marginBottom: 10 }} gutter={[4, 0]}>
                <Col flex="80px" style={{ color: '#333333', fontSize: 12 }}>
                  课程编号：
                </Col>
                <Col style={{ color: '#333333', fontSize: 12 }}>
                  {course?.code}
                </Col>
              </Row>
            )}
            {course?.class !== '' && (
              <Row align="middle" style={{ marginBottom: 10 }} gutter={[4, 0]}>
                <Col flex="80px" style={{ color: '#333333', fontSize: 12 }}>
                  班级：
                </Col>
                <Col style={{ color: '#333333', fontSize: 12 }}>
                  {' '}
                  {course?.class}
                </Col>
              </Row>
            )}
          </Col>

          <Col style={{ margin: 0, alignSelf: 'center' }} flex="100px">
            {/* <Button type='default' style={{borderRadius:20,width:100,marginBottom:10}} onClick={onCourseLearnClick}> 预览 </Button> */}
            {/*<Button type='primary' style={{borderRadius:20,width:100,float:'right',padding:0}} onClick={onCourseLearnClick}> 编辑课程 </Button>*/}
            <div>
              <Button
                type="default"
                style={{
                  borderRadius: 20,
                  width: 100,
                  color: '#07c160',
                  borderColor: '#07c160',
                }}
                onClick={onCourseLearnClick}
              >
                {' '}
                预览{' '}
              </Button>
            </div>
            <div>
              {course?.is_course_teacher == true && (
                <Button
                  type="primary"
                  style={{ borderRadius: 20, width: 100, marginTop: 20 }}
                  onClick={onCourseEditClick}
                >
                  {' '}
                  编辑{' '}
                </Button>
              )}
            </div>
            <div>
              {courseStatus == '未发布' && (
                <Button
                  type="primary"
                  style={{
                    borderColor: '#FF780E',
                    backgroundColor: '#FF780E',
                    borderRadius: 20,
                    width: 100,
                    marginTop: 20,
                  }}
                  onClick={() => {
                    pushlishCourse()
                  }}
                >
                  {' '}
                  发布{' '}
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </div>

      <Row align="top" justify="start">
        <Col flex="auto">
          {' '}
          <div
            style={{
              borderBottomStyle: 'solid',
              borderBottomColor: '#07c160',
              borderBottomWidth: 1,
              height: 36,
              backgroundColor: '#fff',
            }}
          ></div>{' '}
        </Col>
        <Col flex="900px">
          <div
            className={courseMainStyles.courseMain}
            style={{ minHeight: '800px' }}
          >
            <Tabs
              onTabClick={(key, event) => {
                setActiveTabkKey(key)
              }}
              activeKey={activeTabkKey}
              type="card"
              size="middle"
              tabPosition="top"
            >
              <TabPane tab={<div>大纲</div>} key="1">
                {course?.syllabus_id > 0 &&
                  course?.is_course_teacher == true && (
                    <MenuPreview
                      menu_id={course?.syllabus_id}
                      course_id={course?.id}
                      course_user={'teacher'}
                      course_status={''}
                    />
                  )}
                {course?.syllabus_id > 0 &&
                  course?.is_course_teacher == false && (
                    <LockedMenuPreview menu_id={course?.syllabus_id} />
                  )}
              </TabPane>

              {course?.is_course_teacher && (
                <TabPane tab={<div>课件</div>} key="2">
                  <CourseMainTeacherSlides
                  course={course}
                    course_id={course?.id}
                    is_course_teacher={course?.is_course_teacher}
                  />
                </TabPane>
              )}

              {course?.is_course_teacher && (
                <TabPane tab={<div>实验</div>} key="3">
                  <CourseMainTeacherCases
                    course={course}
                    course_id={course?.id}
                    is_course_teacher={course?.is_course_teacher}
                  />
                </TabPane>
              )}

              {course?.is_course_teacher && (
                <TabPane tab={<div>作业</div>} key="4">
                  {collegeId && (
                    <CourseMainTeacherTestList
                      course_id={courseId}
                      college_id={collegeId}
                      menu_id={course?.syllabus_id}
                    />
                  )}
                </TabPane>
              )}

              {course?.is_course_teacher && (
                <TabPane tab={<div>考试</div>} key="5">
                  {collegeId && (
                    <CourseMainTeacherExamList
                      course_id={courseId}
                      college_id={collegeId}
                      enable_exam={course?.enable_exam}
                      menu_id={course?.syllabus_id}
                    />
                  )}
                </TabPane>
              )}

              {/* {course?.is_course_teacher &&
                    <TabPane tab={<div>统计</div>}   key="6">
                        <CourseMainTeacherStats course_id={course?.id} is_course_teacher={course?.is_course_teacher} />
                    </TabPane>} */}

              {course?.is_course_teacher && (
                <TabPane
                  tab={
                    <Badge
                      style={{ backgroundColor: '#ff4b50' }}
                      count={course?.apply_count}
                      size="small"
                      offset={[10, 0]}
                    >
                      <div>学员</div>
                    </Badge>
                  }
                  key="7"
                >
                  <CourseMainTeacherStudent
                    course_id={course?.id}
                    is_course_teacher={course?.is_course_teacher}
                  />
                </TabPane>
              )}

              {course?.is_course_teacher && (
                <TabPane tab={<div>设置</div>} key="8">
                  <CourseMainTeacherSetting course={course} />
                </TabPane>
              )}

              {course?.is_course_teacher && (
                <TabPane tab={<div>讨论</div>} key="9">
                  <CourseMainDiscuss
                    teacherInfo={{
                      teacher: course?.teacher,
                      teacher_avatar: course?.teacher_avatar,
                      teacher_id: course?.teacher_id,
                      teacher_real_name: course?.teacher_real_name,
                    }}
                    course_id={course?.id}
                    menu_id={course?.syllabus_id}
                  />
                </TabPane>
              )}
              {course?.is_course_teacher && (
                <TabPane tab={<div>笔记</div>} key="10">
                  <CourseMainNotelist menu_id={course?.syllabus_id} />
                </TabPane>
              )}
            </Tabs>
          </div>
        </Col>
        <Col flex="auto">
          <div
            style={{
              borderBottomStyle: 'solid',
              borderBottomColor: '#07c160',
              borderBottomWidth: 1,
              height: 36,
              backgroundColor: '#fff',
            }}
          ></div>
        </Col>
      </Row>
    </Layout>
  )
}

export default CourseMainTeacher
