

import React, {useEffect, useRef, useState} from "react";
import { Input,Table,Switch,Layout, message, Card, Col, Row, ConfigProvider, Pagination, Tabs, Button, Tag, Collapse , Modal, Tooltip} from 'antd';
import courseTeacherMenuStyles from "./course-main-teacher-menu.module.less";
import "../course.module.css";
import zhCN from 'antd/lib/locale/zh_CN'
import * as constants from '../../../constants/constants';

import courseMainStyles from "../course-main.module.less";

import LoginRegisterModal from "../../../component/modal/login-register/login-register";
import {checkUserLogin} from "../../common/user-center/user-info";
import { FilePdfOutlined ,BarsOutlined,VideoCameraOutlined,CodeOutlined,FormOutlined,UserOutlined,LockOutlined } from '@ant-design/icons';
import Cookies from "js-cookie";
import { sendHttpPostRequest } from "../../../api/http-request";
const { Panel } = Collapse;

import Draggable from "react-draggable";

export interface Props {
    menu_id:any,
    course_id: number,
    ispay:any,
    is_teacher: any,
    is_in_college: any
    is_outdate:any
}

/** 平台课程大纲标签页 */
export const PlatformMenuPreview: React.FC<Props> = (props) => {
    const {menu_id} = props;
    const {course_id} = props;
    const {ispay} = props;
    const {is_teacher} = props;
    const {is_in_college} = props;
    const {is_outdate} =props;
    
    console.log(is_in_college);
    console.log(is_teacher);

    const [menu,setMenu] = useState(null as any);
   
    const [isBuyModalVisible, setIsBuyModalVisible] = useState(false);
    const draggleRef = useRef<HTMLDivElement | null>(null);
    const [bounds,setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const [disabled,setDisabled] = useState(true)
  
    const onStart = (event:any, uiData:any) => {
      const { clientWidth, clientHeight } = window?.document?.documentElement;
      const targetRect= draggleRef?.current?.getBoundingClientRect();
      if(targetRect){
        setBounds( {
          left: -targetRect?.left + uiData?.x,
          right: clientWidth - (targetRect?.right - uiData?.x),
          top: -targetRect?.top + uiData?.y,
          bottom: clientHeight - (targetRect?.bottom - uiData?.y),
        }
      )
      }
    }


    /**
     * 查询大纲
     */

    useEffect(() => {
        queryMenu();  
    }, [])

     const queryMenu = () => {
        sendHttpPostRequest(
            'course/api/user_query_menu_by_id',
            {
                sessionid: Cookies.get("sessionid"),
                menu_id: menu_id,
                format : 1
            },
            queryMenuCallback
        )
    }
  
    /**
     * 查询大纲回调
     * @param response
     */
    const queryMenuCallback = (response: any) => {
        if (response.code == -100) {
            // 未登录
            document.getElementById("show-login-modal")!.click();;
        } else if (response.is_ok == true) {
            // 成功
            setMenu(response.query_info);
            
        } else {
            message.error(response.message);
        }
    }

    const onKnowledgeClick = (chapter_id: number, knowledge_id: number,chapterInd:number, knowledgeInd:number) => {
        console.log(chapterInd);
        console.log(knowledgeInd);
        if (!checkUserLogin()) {
            document.getElementById("show-login-modal")!.click();
        } else {
            console.log(ispay);
            if(ispay == false){
                if(chapterInd == 0 && knowledgeInd == 0){
                    window.open(`/module-learn-preview?module_id=${knowledge_id}`);
                }else{
                    setIsBuyModalVisible(true);
                }
            }else  if(is_outdate == true){
                if(chapterInd == 0 && knowledgeInd == 0){
                    window.open(`/module-learn-preview?module_id=${knowledge_id}`);
                }else{
                    setIsBuyModalVisible(true);
                }
            } else {
                window.open(`/course-learn?course/${course_id}/chapter/${chapter_id}/knowledge/${knowledge_id}/`);
            }    
        }
    }

    return (
        <div className={courseTeacherMenuStyles.tabpaneWrapper} style={{width:1220}}>

            {
            menu?.json?.map((chapter, chapterInd)=>(
                <div key={'chapter' + chapter.id}>  
                <div className={courseTeacherMenuStyles.chapterName} style={{width:"100%",textAlign:'left',fontWeight: 700}} > { "第" +  (chapterInd + 1)  + "章 " + chapter.name}</div>

                {chapter?.tasks?.map((module, moduleIndex) => (
                    <div key={module.id} style={{cursor:"pointer",width:"100%"}} className={courseTeacherMenuStyles.modules} onClick={onKnowledgeClick.bind(this, chapter.id, module.id,chapterInd, moduleIndex)}>
                        <Row  gutter={[4, 4]} justify='start' align='middle' style={{width:'100%'}}>
                            <Col style={{marginLeft:30,textAlign:'left'}} flex="auto"> {(chapterInd + 1) + "." + (moduleIndex+1) + " " + module.name}</Col>     
                            { (ispay == false  &&  chapterInd == 0 && moduleIndex == 0) &&
                            <Col flex="100px" style={{textAlign:"right"}}>    
                            {module.if_practice && <Tooltip placement="top" title={"练习"}> <BarsOutlined  className={courseTeacherMenuStyles.iconbuttons}/></Tooltip> }
                        {module.if_case  &&  <Tooltip placement="top" title={"实验"}>  <CodeOutlined className={courseTeacherMenuStyles.iconbuttons}/> </Tooltip>}
                        { module?.type == 1?  <Tooltip placement="top" title={"PDF"}> <FilePdfOutlined className={courseTeacherMenuStyles.iconbuttons}/> </Tooltip>: <Tooltip placement="top" title={"视频"}> <VideoCameraOutlined className={courseTeacherMenuStyles.iconbuttons}/> </Tooltip> }
                        </Col>
                            }


                            { ispay == false  &&  !(chapterInd == 0 && moduleIndex == 0) &&

                            <Col flex="100px" style={{textAlign:'right'}}>    
                                <LockOutlined className={courseTeacherMenuStyles.iconbuttons}/>                      
                           
                            </Col>

                            }

                            { ispay == true  && is_outdate == true && (chapterInd == 0 && moduleIndex == 0) &&
                            <Col flex="100px" style={{textAlign:"right"}}>    
                            {module.practice?.length > 0 && <Tooltip placement="top" title={"练习"}> <BarsOutlined  className={courseTeacherMenuStyles.iconbuttons}/></Tooltip> }
                        {module.case?.case_id  &&  <Tooltip placement="top" title={"实验"}>  <CodeOutlined className={courseTeacherMenuStyles.iconbuttons}/> </Tooltip>}
                        { module?.type == 1?  <Tooltip placement="top" title={"PDF"}> <FilePdfOutlined className={courseTeacherMenuStyles.iconbuttons}/> </Tooltip>: <Tooltip placement="top" title={"视频"}> <VideoCameraOutlined className={courseTeacherMenuStyles.iconbuttons}/> </Tooltip> }
                        </Col>
                            }

                        { ispay == true  && is_outdate == true && !(chapterInd == 0 && moduleIndex == 0) &&
                            <Col flex="100px" style={{textAlign:'right'}}>    
                            <LockOutlined className={courseTeacherMenuStyles.iconbuttons}/>                      
                       
                        </Col>
                            }

                            {ispay == true  && is_outdate == false &&
                            <Col flex="100px" style={{textAlign:"right"}}>    
                            {module.practice?.length > 0 && <Tooltip placement="top" title={"练习"}> <BarsOutlined  className={courseTeacherMenuStyles.iconbuttons}/></Tooltip> }
                        {module.case?.case_id  &&  <Tooltip placement="top" title={"实验"}>  <CodeOutlined className={courseTeacherMenuStyles.iconbuttons}/> </Tooltip>}
                        { module?.type == 1?  <Tooltip placement="top" title={"PDF"}> <FilePdfOutlined className={courseTeacherMenuStyles.iconbuttons}/> </Tooltip>: <Tooltip placement="top" title={"视频"}> <VideoCameraOutlined className={courseTeacherMenuStyles.iconbuttons}/> </Tooltip> }
                        </Col>
                            }


                        </Row>  
                    </div>
                ))}
                </div> 
            ))                 
         }

                <Modal 
                    title={<div style={{ width: '100%',cursor: 'move',}}
                    onMouseOver={() => {if (disabled) {setDisabled(false)}}}
                    onMouseOut={() => {setDisabled(true)}}
                    onFocus={() => {}}
                    onBlur={() => {}}
                    >
                        提示</div>
                    }
                    width={350}
                    closable={false}
                    visible={isBuyModalVisible}
                    className={courseMainStyles.miniModal}
                    footer={              
                        <div>
                        {is_in_college == true &&
                            <Button className={courseMainStyles.stepsBtn}
                            style={{marginRight:20,color:'#fff'}}
                            type="primary"
                            onClick={()=>{setIsBuyModalVisible(false)}}>确认</Button>
                        }
                        {is_in_college == false &&
                        <div>
                            <Button className={courseMainStyles.stepsBtn}
                            style={{marginRight:20,color:'#07c160'}}
                            onClick={()=>{setIsBuyModalVisible(false)}}>取消</Button>
                            <Button className={courseMainStyles.stepsBtn}
                            style={{marginRight:20,color:'#fff'}}
                            type="primary"
                            onClick={()=>{window.open("/user-center")}}>填写</Button></div>
                        }
                        </div>                    
                    }
                    modalRender={modal => (<div>
                        <Draggable
                        disabled={disabled}
                        bounds={bounds}
                        onStart={(event, uiData) => onStart(event, uiData)}
                        >
                        <div ref={draggleRef}>{modal}</div>
                        </Draggable> </div>
                    )}
                    >
                        {is_in_college == true && is_teacher == 1 && ispay == false &&
                         <div style={{width:300,textAlign:'center',paddingTop:20}}>
                             <p>院校还未购买该课程哦~</p> 
                            <p>合作咨询：136 9329 0406</p> 
                        </div>}
                        {is_in_college == true && is_teacher == 1 && ispay == true && is_outdate == true &&
                         <div style={{width:300,textAlign:'center',paddingTop:20}}>
                             <p>该课程已过服务期，</p> 
                            <p>继续使用请联系136 9329 0406。</p> 
                        </div>}

                        {is_in_college == true && is_teacher == 0 &&
                         <div style={{width:300,textAlign:'center',paddingTop:20}}>
                             <p>院校还未购买该课程哦~</p> 
                            <p>请联系老师吧~</p> 
                        </div>}

                        {is_in_college == false &&
                         <div style={{width:320,textAlign:'center',paddingTop:20}}>
                              您还没加入任何院校，继续学习，请填写院校码。
                              {/* <span><Button type="link" onClick={()=>{window.open("/user-center")}}>填写</Button> </span> 院校码。 */}
                             <p>获取院校码，请咨询：136 9329 0406</p>
                        </div>}

            </Modal>

     
        </div>
    )

}

export default PlatformMenuPreview

