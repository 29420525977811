import React, { useRef, useState } from 'react'
import { Button, ConfigProvider, Modal } from 'antd'
import type { DraggableData, DraggableEvent } from 'react-draggable'
import css from './modaldrag.module.less'
import Draggable from 'react-draggable'
import zhCN from 'antd/lib/locale/zh_CN'

interface ModalProps {
  modalContent: any
  open: boolean
  modalTitle: any
  onCancel?: any
  onOk?: any
  footer?: any
  bodyStyle?: any
  width?: number
  okText?: string
  titleCenter?: boolean
  footerPosition?: boolean
  maskClosable?: boolean
  destroyOnClose?: boolean
  noMask?: boolean
  centered?: boolean
  style?: any
  theme?: any
}

export const ModalDrag: React.FC<ModalProps> = (props: ModalProps) => {
  const {
    modalTitle,
    open,
    modalContent,
    onCancel,
    onOk,
    footer,
    bodyStyle,
    width,
    okText,
    titleCenter,
    footerPosition,
    maskClosable,
    destroyOnClose,
    noMask,
    centered,
    style,
    theme,
  } = props
  const [disabled, setDisabled] = useState(true)
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
  const draggleRef = useRef<HTMLDivElement>(null)

  const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
    const { clientWidth, clientHeight } = window.document.documentElement
    const targetRect = draggleRef.current?.getBoundingClientRect()
    if (!targetRect) {
      return
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    })
  }

  return (
    <ConfigProvider locale={zhCN}>
      <Modal
        style={style}
        className={
          titleCenter
            ? css.titleCenterModal
            : footerPosition
            ? css.ModalStyleFooterPosition
            : theme == 'dark'
            ? css.darkModal
            : css.ModalStyle
        }
        bodyStyle={bodyStyle ? bodyStyle : null}
        width={width}
        okText={okText}
        maskClosable={maskClosable}
        mask={noMask ? false : true}
        title={
          <div
            style={{
              width: '100%',
              cursor: 'move',
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false)
              }
            }}
            onMouseOut={() => {
              setDisabled(true)
            }}
            onFocus={() => {}}
            onBlur={() => {}}
            // end
          >
            {modalTitle}
          </div>
        }
        visible={open}
        onOk={onOk}
        onCancel={onCancel}
        centered={!centered ? centered : true}
        destroyOnClose={destroyOnClose}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
        footer={footer}
      >
        {modalContent}
      </Modal>
    </ConfigProvider>
  )
}

export default ModalDrag
