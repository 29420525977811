import moduleStyles from './module.module.less'
import {
  Rate,
  message,
  Button,
  Radio,
  Col,
  ConfigProvider,
  Layout,
  Pagination,
  Row,
  Tabs,
  Table,
  Input,
  Modal,
  Card,
  Popconfirm,
  Tooltip,
} from 'antd'
import {
  HeartOutlined,
  VideoCameraOutlined,
  FilePdfOutlined,
  DeleteOutlined,
  EditOutlined,
  CheckCircleOutlined,
  CheckSquareOutlined,
  SearchOutlined,
  EyeOutlined,
  ForkOutlined,
  CodeOutlined,
  SortDescendingOutlined,
} from '@ant-design/icons'
import { PageHeader } from '../common/page-header/header'
import React, { useEffect, useRef, useState } from 'react'
import PageFooter from '../common/page-footer/footer'
import Search from 'antd/lib/input/Search'
import loadingGif from '../../static/image/loding.gif'
import zhCN from 'antd/lib/locale/zh_CN'
import { Tag } from 'antd'
import Cookies from 'js-cookie'
import { Checkbox } from 'antd'
const CheckboxGroup = Checkbox.Group

import remarkMath from 'remark-math'
import gfm from 'remark-gfm'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import rehypeKatex from 'rehype-katex'
import 'katex/dist/katex.min.css' // `rehype-katex` does not import the CSS for you

import pic from '../../static/image/course/Python机器学习实战.jpg'
import icon1 from '../../static/image/module/icon1.png'
import icon2 from '../../static/image/module/icon2.png'
import icon3 from '../../static/image/module/icon3.png'
import emptyExercise from '../../static/image/empty/queshengye-timu.png'

import Meta from 'antd/lib/card/Meta'
import { sendHttpPostRequest } from '../../api/http-request'
import { checkUserLogin } from '../common/user-center/user-info'
import Draggable from 'react-draggable'
import { url } from 'inspector'
import { checkIsTeacher } from '../../component/modal/teacher-authenticate/teacher-authenticate'
import Feedback from '../common/feedback-help/feedback'
const { CheckableTag } = Tag

const { Content } = Layout
const { TabPane } = Tabs

export interface Props {}
export const ModuleList: React.FC<Props> = (props) => {
  const [totalCount, setTotalCount] = useState(0) // 搜索返回的结果数
  const [courseNameList, setCourseNameList] = useState([] as any[]) // 平台知识点课程名称列表
  const [myTagList, setMyTagList] = useState([] as any[]) //  我的知识点 tag 列表
  const [resultSum, setResultSum] = useState(0) // 搜索返回的结果数
  const currentTag = useRef('') // 当前选中的tag
  const [selectedTags, setSelectedTags] = useState([0] as number[]) // 筛选的tag
  const typeFilters = useRef([] as any[]) // 类型过滤选项
  const [isDeleteVisible, setIsDeleteVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  const draggleRef = useRef<HTMLDivElement | null>(null)
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
  const [disabled, setDisabled] = useState(true)
  const querySource = useRef('2')
  const queryContent = useRef('') // 记录搜索串
  const page = useRef(1)
  const pageSize = useRef(5)
  const [moduleResult, setModuleResult] = useState([] as any[])

  const [deleteKnowledgeCellId, setDeleteKnowledgeCellId] = useState(
    null as any
  ) // 删除的知识点ID
  const [deleteKnowledgeCellName, setDeleteKnowledgeCellName] = useState(
    null as any
  ) // 删除的知识点名称

  const onStart = (event: any, uiData: any) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement
    const targetRect = draggleRef?.current?.getBoundingClientRect()
    if (targetRect) {
      setBounds({
        left: -targetRect?.left + uiData?.x,
        right: clientWidth - (targetRect?.right - uiData?.x),
        top: -targetRect?.top + uiData?.y,
        bottom: clientHeight - (targetRect?.bottom - uiData?.y),
      })
    }
  }

  // const moduleResult = [
  //     {
  //         src: pic,title: '023-Python的安装',type: 'video',dataType: 'Python',videoDuration: 30,pdfSize: 0,exercise: 5,testExam: 5,case: 'Python字符串操作',createTime: '2022-03-15 16:02'
  //     },{
  //         src: pic,title: '023-Python的安装',type: 'pdf',dataType: 'Python',videoDuration: 0,pdfSize: 30,exercise: 5,testExam: 5,case: 'Python字符串操作',createTime: '2022-03-15 16:02'
  //     },{
  //         src: pic,title: '023-Python的安装',type: 'video',dataType: 'Python',videoDuration: 30,pdfSize: 0,exercise: 5,testExam: 5,case: 'Python字符串操作',createTime: '2022-03-15 16:02'
  //     },{
  //         src: pic,title: '023-Python的安装',type: 'pdf',dataType: 'Python',videoDuration: 0,pdfSize: 15,exercise: 5,testExam: 5,case: 'Python字符串操作',createTime: '2022-03-15 16:02'
  //     },{
  //         src: pic,title: '023-Python的安装',type: 'video',dataType: 'Python',videoDuration: 20,pdfSize: 0,exercise: 5,testExam: 5,case: 'Python字符串操作',createTime: '2022-03-15 16:02'
  //     },
  // ]

  useEffect(() => {
    queryModuleList(true)
  }, [])

  // 查知识点列表
  const queryModuleList = (firstTime) => {
    setLoading(true)
    setModuleResult([])
    setResultSum(0)
    const _source = parseInt(querySource.current)
    let _params = {
      sessionid: Cookies.get('sessionid'),
      sid: Cookies.get('sessionid'),
      source: _source,
      is_my: _source,
      page: page.current,
      pagesize: pageSize.current,
      query: queryContent.current,
      tag: currentTag.current,
    }

    sendHttpPostRequest(
      'course/api/user_query_simple_knowcell_list',
      _params,
      (response: any) => {
        message.destroy()
        setLoading(false)
        if (response.code == -100) {
          // 未登录
          setModuleResult([])
          setResultSum(0)
          setTotalCount(0)
          setMyTagList([])
          setCourseNameList([])
          document.getElementById('show-login-modal')!.click()
          // window.location.href = '/oam_login?next=oam_exercise_list';
        } else if (response.code == 0) {
          // 成功
          setModuleResult(response.query_list)
          setResultSum(response.total_sum)
          setTotalCount(response.total_count)

          console.log(firstTime == true)
          firstTime == true ? setMyTagList(response.my_tag_list) : ''
          firstTime == true ? setCourseNameList(response.course_name_list) : ''
        } else if (response.code == -501) {
          // 权限不足
          window.location.href = '/'
          // document.getElementById("show-login-modal")!.click();
          // window.location.href = '/oam_login?next=oam_exercise_list';
        } else {
          message.error(response.message)
        }
      }
    )
  }

  // 删除 知识点
  const deleteKnowledgeCell = () => {
    const totalPages = Math.ceil(resultSum / pageSize.current)
    const currentPage =
      resultSum % pageSize.current == 1 &&
      totalPages == page.current &&
      totalPages > 1
        ? page.current - 1
        : page.current
    // console.log(currentPage, totalPages);
    sendHttpPostRequest(
      'course/api/user_delete_knowcell_by_id',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
        knowcell_id: deleteKnowledgeCellId,
      },
      (response: any) => {
        message.destroy()

        if (response.code == -100) {
          // 未登录
          document.getElementById('show-login-modal')!.click()
          // window.location.href = '/oam_login?next=oam_exercise_list';
        } else if (response.code == 0) {
          // 成功
          message.success('知识点删除成功')
          page.current = currentPage
          // TODO: 标签要是连带删除了，也要刷新标签
          // if (response.tag_remove) {
          //     // 标签也删除了，要初始化查询条件
          //     page.current = 1;
          //     currentTag.current = "";
          //     setSelectedTags([0]);
          // }
          queryModuleList(false)
        } else if (response.code == -501) {
          // 权限不足
          // document.getElementById("show-login-modal")!.click();
          // window.location.href = '/oam_login?next=oam_exercise_list';
        } else {
          message.error(response.message)
        }
      }
    )
  }

  // 切换tab页
  const onTabsChange = (key: string) => {
    querySource.current = key
    page.current = 1
    currentTag.current = ''
    setSelectedTags([0])
    queryModuleList(true)
  }

  //点击了搜索按钮
  const onSearchBtn = (event: any) => {
    page.current = 1
    queryContent.current = event.target.value
    queryModuleList(false)
  }

  //页码和页数发生了变化
  const onPageChange = (newPage: number, newPageSize?: number | undefined) => {
    page.current = newPage ? newPage : 1
    if (newPageSize) {
      pageSize.current = newPageSize
    }
    queryModuleList(false)
  }

  const handleKnowledgeCellTagChange = (tag: number, checked: boolean) => {
    if (tag == 0) {
      setSelectedTags([0])
    } else {
      setSelectedTags([tag])
    }
    currentTag.current = myTagList[tag]
    page.current = 1
    queryModuleList(false)
  }

  const handleKnowledgeCellCourseNameChange = (
    tag: number,
    checked: boolean
  ) => {
    if (tag == 0) {
      setSelectedTags([0])
    } else {
      setSelectedTags([tag])
    }
    currentTag.current = courseNameList[tag]
    page.current = 1
    queryModuleList(false)
  }

  // 点击创建知识点
  const onCreateModuleBtnClick = (event: any) => {
    event.preventDefault()
    sendHttpPostRequest(
      'course/api/user_create_knowcell',
      {
        sessionid: Cookies.get('sessionid'),
        sid: Cookies.get('sessionid'),
      },
      (response: any) => {
        if (response.is_ok && response.know_id) {
          window.open(`/module-edit?module_id=${response.know_id}`)
        } else {
          message.destroy()
          if (response.code == -100) {
            document.getElementById('show-login-modal')!.click()
          } else {
            message.error(`创建知识点失败：${response.message}`)
          }
        }
      }
    )
  }

  return (
    <Layout style={{ minHeight: 750, backgroundColor: '#f9f9f9' }}>
      <div className={moduleStyles.maincontent}>
        <div
          className={moduleStyles.moduleList}
          style={{ width: '100%', maxWidth: 1086 }}
        >
          <Row gutter={[16, 16]} style={{ marginBottom: 20 }}>
            <Col span={24}>
              <div>
                <Input
                  onPressEnter={onSearchBtn}
                  placeholder="搜索名称/ID"
                  style={{ float: 'left', borderRadius: 20, width: 300 }}
                  suffix={<SearchOutlined style={{ color: '#666666' }} />}
                />

                <Button
                  style={{ float: 'right', borderRadius: 20 }}
                  type="primary"
                  onClick={onCreateModuleBtnClick}
                >
                  创建知识点
                </Button>
              </div>
            </Col>
          </Row>

          <Tabs
            defaultActiveKey="2"
            size="small"
            style={{ width: '100%', maxWidth: 1086 }}
            onChange={onTabsChange}
          >
            <TabPane tab="平台" key="2">
              {/*<div hidden={!loading} style={{position:"absolute", left:"50%", top:"50%", transform: "translate(-50%,-50%)", textAlign:"center"}}>
                                <img  height={60} width={60}  src={loadingGif}></img>
                            </div>*/}
              <div style={{ textAlign: 'left', marginBottom: 16 }}>
                {courseNameList?.map((value, index) => {
                  return (
                    <CheckableTag
                      className={moduleStyles.moduleTag}
                      key={index}
                      checked={selectedTags.indexOf(index) > -1}
                      onChange={(checked) =>
                        handleKnowledgeCellCourseNameChange(index, checked)
                      }
                    >
                      {value}
                    </CheckableTag>
                  )
                })}
              </div>

              <div className={moduleStyles.moduleListWarpper}>
                {moduleResult?.map((module, index) => {
                  return (
                    <Row key={index} className={moduleStyles.module}>
                      <Col hidden={true} flex="240px">
                        <img
                          src={module.src}
                          alt=""
                          style={{ width: 240, height: 135, borderRadius: 4 }}
                        />
                      </Col>
                      <Col
                        flex="auto"
                        style={{ paddingLeft: 20, textAlign: 'left' }}
                      >
                        <div style={{ marginBottom: 8 }}>
                          {module.type == 2 ? (
                            <VideoCameraOutlined
                              style={{
                                fontSize: 18,
                                color: '#07c160',
                                marginRight: 6,
                              }}
                            />
                          ) : (
                            <FilePdfOutlined
                              style={{
                                fontSize: 18,
                                color: '#07c160',
                                marginRight: 6,
                              }}
                            />
                          )}
                          <span
                            className={moduleStyles.moduleTitle}
                            onClick={() => {
                              window.open(
                                `/module-learn-preview?module_id=${module.id}`
                              )
                            }}
                          >
                            {' '}
                            {module.name}
                          </span>
                        </div>
                        {/* <div className={moduleStyles.moduleType}>{module.dataType}</div> */}
                        {module.type == 2 ? (
                          <div style={{ height: 32, marginTop: 20 }}>
                            <div>
                              {' '}
                              视频时长：
                              <span style={{ marginRight: 4 }}>
                                {module.video_duration}
                              </span>{' '}
                            </div>
                          </div>
                        ) : (
                          <div style={{ marginTop: 20 }}>
                            PDF页数：
                            <span style={{ marginRight: 4 }}>
                              {module.lecture_pages}
                            </span>
                          </div>
                        )}
                        <div style={{ position: 'absolute', bottom: 0 }}>
                          <Tooltip title="练习题">
                            <span style={{ marginRight: 40 }}>
                              <img
                                src={icon1}
                                style={{
                                  width: 15,
                                  height: 15,
                                  marginTop: -2,
                                  marginRight: 5,
                                }}
                              />
                              <span className={moduleStyles.moduleCaseName}>
                                {module?.practice_count}
                              </span>
                            </span>
                          </Tooltip>
                          <Tooltip title="作业考试题">
                            <span style={{ marginRight: 40 }}>
                              <img
                                src={icon2}
                                style={{
                                  width: 15,
                                  height: 15,
                                  marginTop: -2,
                                  marginRight: 5,
                                }}
                              />
                              <span className={moduleStyles.moduleCaseName}>
                                {module?.exam_count}
                              </span>
                            </span>
                          </Tooltip>
                          {module?.case_id > 0 && (
                            <Tooltip title="实验">
                              <span style={{ marginRight: 40 }}>
                                <img
                                  src={icon3}
                                  style={{
                                    width: 15,
                                    height: 15,
                                    marginTop: -2,
                                    marginRight: 5,
                                  }}
                                />
                                <span>
                                  <a
                                    className={moduleStyles.moduleCaseName}
                                    href={`/case-run?id=${module?.case_id}&token=${module?.case_token}`}
                                    target={'_blank'}
                                  >
                                    {module?.case_name}
                                  </a>
                                </span>
                              </span>
                            </Tooltip>
                          )}
                        </div>
                        {/*<div style={{fontSize:12,marginTop:6}}>创建时间：{module.create_time}</div>*/}
                      </Col>
                      <Col flex="100px" style={{ lineHeight: '135px' }}>
                        <div className={moduleStyles.moduleBtn}>
                          <Button type="primary" style={{ borderRadius: 20 }}>
                            <a
                              href={`/module-learn-preview?module_id=${module.id}`}
                              target={'_blank'}
                            >
                              查看
                            </a>
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  )
                })}
              </div>
            </TabPane>

            <TabPane tab="我的" key="1">
              {/*  <div hidden={!loading} style={{position:"absolute", left:"50%", top:"50%", transform: "translate(-50%,-50%)", textAlign:"center"}}>
                                <img  height={60} width={60}  src={loadingGif}></img>
                            </div> */}
              <div style={{ textAlign: 'left', marginBottom: 16 }}>
                {myTagList?.map((value, index) => {
                  return (
                    <CheckableTag
                      className={moduleStyles.moduleTag}
                      key={index}
                      checked={selectedTags.indexOf(index) > -1}
                      onChange={(checked) =>
                        handleKnowledgeCellTagChange(index, checked)
                      }
                    >
                      {value}
                    </CheckableTag>
                  )
                })}
              </div>

              {moduleResult?.length != 0 && (
                <div className={moduleStyles.moduleListWarpper}>
                  {moduleResult?.map((module, index) => {
                    return (
                      <Row key={index} className={moduleStyles.module}>
                        <Col hidden={true} flex="240px">
                          <img
                            src={module.src}
                            alt=""
                            style={{ width: 240, height: 135, borderRadius: 4 }}
                          />
                        </Col>
                        <Col
                          flex="auto"
                          style={{ paddingLeft: 20, textAlign: 'left' }}
                        >
                          <Row>
                            <Col>
                              {module.type == 2 ? (
                                <VideoCameraOutlined
                                  style={{
                                    fontSize: 18,
                                    color: '#07c160',
                                    marginRight: 6,
                                  }}
                                />
                              ) : (
                                <FilePdfOutlined
                                  style={{
                                    fontSize: 18,
                                    color: '#07c160',
                                    marginRight: 6,
                                  }}
                                />
                              )}
                              <span
                                className={moduleStyles.moduleTitle}
                                onClick={() => {
                                  window.open(
                                    `/module-learn-preview?module_id=${module.id}`
                                  )
                                }}
                              >
                                {module.name}
                              </span>
                            </Col>
                            <Col style={{ height: 34, marginLeft: 20 }}>
                              <Row align={'middle'} justify={'start'} wrap={false} style={{gap:"5px"}}>
                                {module?.tag_list.map(
                                  (tagName: string, index: number) => {
                                    return (
                                      <Col
                                        key={index}
                                        className={moduleStyles.moduleType}
                                      >
                                        <span>{tagName}</span>
                                      </Col>
                                    )
                                  }
                                )}
                              </Row>
                            </Col>
                          </Row>
                          {module.type == 2 ? (
                            <div style={{ height: 32, marginTop: 20 }}>
                              <div>
                                视频时长：
                                <span style={{ marginRight: 4 }}>
                                  {module.video_duration}
                                </span>{' '}
                              </div>
                            </div>
                          ) : (
                            <div style={{ marginTop: 20 }}>
                              PDF页数：
                              <span style={{ marginRight: 4 }}>
                                {module.lecture_pages}
                              </span>
                            </div>
                          )}
                          <div style={{ position: 'absolute', bottom: 0 }}>
                            <Tooltip title="练习题">
                              <span style={{ marginRight: 40 }}>
                                <img
                                  src={icon1}
                                  style={{
                                    width: 15,
                                    height: 15,
                                    marginTop: -2,
                                    marginRight: 5,
                                  }}
                                />
                                <span className={moduleStyles.moduleCaseName}>
                                  {module?.practice_count}
                                </span>
                              </span>
                            </Tooltip>
                            <Tooltip title="作业考试题">
                              <span style={{ marginRight: 40 }}>
                                <img
                                  src={icon2}
                                  style={{
                                    width: 15,
                                    height: 15,
                                    marginTop: -2,
                                    marginRight: 5,
                                  }}
                                />
                                <span className={moduleStyles.moduleCaseName}>
                                  {module?.exam_count}
                                </span>
                              </span>
                            </Tooltip>
                            {module?.case_id > 0 && (
                              <Tooltip title="实验">
                                <span style={{ marginRight: 40 }}>
                                  <img
                                    src={icon3}
                                    style={{
                                      width: 15,
                                      height: 15,
                                      marginTop: -2,
                                      marginRight: 5,
                                    }}
                                  />
                                  <span>
                                    <a
                                      className={moduleStyles.moduleCaseName}
                                      href={`/case-run?id=${module?.case_id}&token=${module?.case_token}`}
                                      target={'_blank'}
                                    >
                                      {module?.case_name}
                                    </a>
                                  </span>
                                </span>
                              </Tooltip>
                            )}
                            {/* <div style={{fontSize:12,float:'right'}}>创建时间：{module.create_time.slice(0, -3)}</div> */}
                          </div>
                        </Col>
                        <Col flex="250px" style={{ paddingTop: 6 }}>
                          <div className={moduleStyles.moduleBtn}>
                            <Button
                              style={{
                                borderRadius: 20,
                                color: '#07c160',
                                borderColor: '#07c160',
                              }}
                              onClick={() => {
                                setDeleteKnowledgeCellId(module.id)
                                setDeleteKnowledgeCellName(module.name)
                                setIsDeleteVisible(true)
                              }}
                            >
                              删除
                            </Button>
                            <Button
                              type="primary"
                              style={{ borderRadius: 20, marginLeft: 20 }}
                            >
                              <a href={`/module-edit?module_id=${module.id}`}>
                                编辑
                              </a>
                            </Button>
                            <Button
                              type="primary"
                              style={{ borderRadius: 20, marginLeft: 20 }}
                            >
                              <a
                                href={`/module-learn-preview?module_id=${module.id}`}
                                target={'_blank'}
                              >
                                预览
                              </a>
                            </Button>
                          </div>
                          <div
                            style={{
                              color: '#666',
                              fontSize: 12,
                              width: '100%',
                              textAlign: 'right',
                              height: 20,
                              lineHeight: '20px',
                              position: 'absolute',
                              bottom: 0,
                              right: 14,
                            }}
                          >
                            创建时间：{module.create_time.slice(0, -3)}
                          </div>
                        </Col>
                      </Row>
                    )
                  })}
                </div>
              )}

              {moduleResult?.length == 0 && (
                <div hidden={loading}>
                  <img src={emptyExercise} height={150} />
                  <div style={{ color: '#999999' }}>您还没有创建知识点哟~</div>
                  <Button
                    size="small"
                    style={{
                      border: '1px solid #07c176',
                      color: '#07c176',
                      marginTop: 10,
                      borderRadius: 3,
                    }}
                    type="default"
                    onClick={onCreateModuleBtnClick}
                  >
                    {' '}
                    立即创建
                  </Button>
                </div>
              )}
            </TabPane>
          </Tabs>

          <ConfigProvider locale={zhCN}>
            <div className={moduleStyles.pagination} style={{ marginTop: 20 }}>
              <Pagination
                defaultCurrent={1}
                current={page.current}
                total={resultSum}
                defaultPageSize={5}
                hideOnSinglePage={true}
                onChange={onPageChange}
                showSizeChanger={false}
              />
            </div>
          </ConfigProvider>

          <Modal
            title={
              <div
                style={{ width: '100%', cursor: 'move' }}
                onMouseOver={() => {}}
                onMouseOut={() => {}}
                onFocus={() => {}}
                onBlur={() => {}}
              >
                提示
              </div>
            }
            width={350}
            closable={false}
            visible={isDeleteVisible}
            className={moduleStyles.miniModal}
            footer={
              <div>
                <Button
                  className={moduleStyles.stepsBtn}
                  style={{ marginRight: 20, color: '#07c160' }}
                  onClick={() => {
                    setIsDeleteVisible(false)
                  }}
                >
                  取消
                </Button>
                <Button
                  className={moduleStyles.stepsBtn}
                  style={{ marginRight: 20, color: '#fff' }}
                  type="primary"
                  onClick={() => {
                    setIsDeleteVisible(false)
                    deleteKnowledgeCell()
                  }}
                >
                  确认
                </Button>
              </div>
            }
            modalRender={(modal) => (
              <div>
                <Draggable
                  disabled={disabled}
                  bounds={bounds}
                  onStart={(event, uiData) => onStart(event, uiData)}
                >
                  <div ref={draggleRef}>{modal}</div>
                </Draggable>{' '}
              </div>
            )}
          >
            <div style={{ width: 250, margin: '0 auto', textAlign: 'center' }}>
              确认删除知识点
              <span style={{ color: '#07c160', margin: 4 }}>
                {deleteKnowledgeCellId}-{deleteKnowledgeCellName}
              </span>
              吗？ 删除后不可恢复。
            </div>
          </Modal>
        </div>
      </div>
    </Layout>
  )
}

export default ModuleList
